import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Loader from "components/UI/Loader";
import { TScreenStatus } from "domains/creative/types";
import css from './styles.module.scss';

interface Props {
    status?: TScreenStatus['status']
}

const LoaderView: FC<Props> = ({status}) => {
    const { t } = useTranslation();

    const checkStatus = (statusVal: TScreenStatus['status'] | undefined) => {
        if(statusVal === 'PENDING') return t('creative.screenshot.pending')
        if(statusVal === 'PROCESSING') return t('creative.screenshot.processing')
        return t('creative.screenshot.sended')
    }
    return (
        <div className={css.loader_wrap}>
        <Loader radius={14} stroke={5}/>
        <div className={css.text_content}>
          <h3>
            {checkStatus(status)}
          </h3>
          <p>{t('creative.screenshot.wait')}</p>
        </div>
        </div>
    );
  };
  
  export default LoaderView;