import history from 'lib/history';
import { toast, Id } from 'react-toastify';
import i18next from '../i18n';
import { store } from '../redux/rootReducer';
import { ErrorDescription, ErrorsMap } from '../shared/dictionaryErrors';
import { toastError } from './ToastUtils/toastUtils';

interface ErrorProps {
  code: number;
  description: string;
  data: {
    mnemonic: string;
    fields: string[];
    description: string;
    location: {
      item: string[];
    };
  };
  message: string;
}

// eslint-disable-next-line no-shadow
enum EPathsToHideErrorAlert {
  campaign = "/main",
  campaigns = "/campaigns",
  creative = "/basic_settings",
  creatives = "/creatives",
  contract = "/contract",
}

const ordApiErrCodes = [99, 1228, 3200]

let tostErrSesionID: Id;
export default (
  error: ErrorProps | undefined,
  method: string,
  showError = true,
): void => {
  const errSession = [100, 101, 102, 103, 104, 105, 109, 121];

  if ((error && error?.data?.mnemonic === 'AccessDenied') || !error) {
    toastError('Нет доступа. Обратитесь к администратору');
    return;
  }
  const erMessage = ErrorsMap.get(error?.code);

  const erDescMessage = ErrorDescription.get(error?.data.description);

  const isOrdError = error.message === 'Ошибка ORD';

  const checkPathsToShowToast = (
    str: string,
    errMessage: string,
    errDescMessage: string | undefined,
  ) => {
    const keys = Object.keys(EPathsToHideErrorAlert);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      if (str.includes(EPathsToHideErrorAlert[key])) {
        return;
      }
    }
    toastError(`${i18next.t(errMessage)} ${errDescMessage ?? ''}`);
  };

  if (errSession.includes(error?.code)) {
    store.dispatch({ type: 'AUTH_LOGOUT_SUCCESS' });
    store.dispatch({ type: 'wss/closeConnect' });
    history.push('/');
    if (!toast.isActive(tostErrSesionID))
      tostErrSesionID = toast.error(`${erMessage && i18next.t(erMessage)}`);
    return;
  }

  if (error?.code === undefined) {
    toastError(`${i18next.t('errors.unknown_error')} ${method}`);
    return;
  }
  if (!showError) return;
  if (error?.code === 90) return;

  if (error.code === 223) {
    toastError(
      `${i18next.t('errors.err223')}: ${error?.data?.location?.item ?? ''}`,
    );
    return;
  }

  if (error.code === 224) {
    toastError(
      `${i18next.t('errors.err224')}: ${error?.data?.location?.item ?? ''}`,
    );
    return;
  }

  if (error.code === 225) {
    toastError(
      `${i18next.t('errors.err225')} ${error?.data?.description ?? ''}`,
    );
    return;
  }

  if (typeof erMessage === 'string') {
    let currentPathname = window.location.pathname;
    currentPathname = currentPathname.replace('/main', '');

    checkPathsToShowToast(currentPathname, erMessage, erDescMessage);
    return;
  }

  if (ordApiErrCodes.includes(error.code) && erDescMessage) {
    toastError(i18next.t(`${erDescMessage}`));
    return;
  }

  if (isOrdError) {
    const description: { error: string } = JSON.parse(error.data.description)
    const message = ErrorDescription.get(description.error);
    if (message) {
      toastError(i18next.t(`${message}`));
      return
    }
    toastError(description.error);
    return;
  }

  toastError(`${i18next.t('errors.unknown_error')} ${method}`);
};
