import React, { FC } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  useLocation,
} from 'react-router-dom';
import { getToken } from 'lib/helpers/token';
import { useSocket } from '../../wss/model/hooks';
import { useUploadStorage } from '../../storage/model/hooks';

type Props = {
  component: FC<RouteComponentProps>;
  exact?: boolean;
  path: string;
};

const PrivateRoute: React.FC<Props> = ({
  component: Component,
  path,
  exact,
}) => {
  const location = useLocation();

  useSocket();
  useUploadStorage();

  return (
    <>
      <Route
        path={path}
        exact={exact}
        render={(param) => {
          if (getToken()) {
            return <Component {...param} />;
          }

          sessionStorage.setItem('$from', JSON.stringify(location));

          return <Redirect to="/auth" />;
        }}
      />
    </>
  );
};

export default PrivateRoute;
