import React, { CSSProperties, FC } from 'react';
import cn from 'classnames';
import css from './styles.module.scss';

type Props = {
  className?: string;
  style?: CSSProperties;
};

const CardsList: FC<Props> = ({ children, className, style }) => (
  <div className={cn(css.list, className)} style={style}>
    {children}
  </div>
);

export default CardsList;
