import requestOld from 'lib/helpers/request';
import { ResponseTranslation } from 'domains/campaigns/types';
import { TParams } from 'domains/target/types';
import request from '../../lib/helpers/requestApi';
import { THASH, THASH_LIST, TID, TInternalList } from '../../types/general';
import {
  CreativeDetail,
  CreativeList,
  Moderation,
  ParamRequestClone,
  PChangeStatusCreative,
  PFormatting,
  PGetCreative,
  PGetCreativeDetail,
  PsetExternalId,
  RCreativeClone,
  RFormatting,
  RModerationResult,
  RPreviewCreative,
  TFormikCreativeAddSaga,
  TPCreativeEdit,
  TPModerationSentAPI,
  RAlgorithmCreativeList,
  RCreativeTags,
  PSetCreativeTags,
  TPCreativesAllStatus,
  TPCreativeGroupStatus,
  TPSetCreativePretargeting,
  TRCreativeTypes,
  TScreenStatus,
  MassEditLinkParams,
  getCustomerByEridParams,
} from './types';
import { RPreTargeting } from '../campaign/types';

/** Получение списка крео */
export const getCreativeList = request<CreativeList, PGetCreative>({
  method: 'creative.getList',
});
/** Получение детального описания крео */
export const getCreativeDetail = request<CreativeDetail, PGetCreativeDetail>({
  method: 'creative.getInfo',
});

/** Изменение статуса креатива */
export const creativeSetStatusApi = request<boolean, PChangeStatusCreative>({
  method: 'creative.setStatus',
});
/** Очистка таргетов креатива */
export const cleanTargetCreative = request<boolean, THASH>({
  method: 'targeting.removeAll',
});
/** Дублирование креатива */
export const cloneCreative = request<RCreativeClone[], ParamRequestClone>({
  method: 'creative.cloneCreative',
  showError: false,
});
/** Функция редактирования креатива */
export const setCreativeEdit = request<boolean, TPCreativeEdit>({
  method: 'creative.edit',
});

/** Получение статуса модерации */
export const statusModeration = request<Moderation, { creative_id: string }>({
  method: 'moderation.allStatus',
});

/** Отправка на модерацию */
export const sendExternalModeration = request<
  RModerationResult,
  TPModerationSentAPI
>({
  method: 'moderation.send',
});

/** Получение Preview для креатива */
export const getPreviewCreative = request<RPreviewCreative, THASH>({
  method: 'creative.getPersonalPreview',
});
/** Создание кратива */
export const addCreative = request<TID, TFormikCreativeAddSaga>({
  method: 'creative.add',
});

/** Обработка кода */
export const formattingShowMethodsThisContext = request<
  RFormatting,
  PFormatting
>({
  method: 'formatting.showMethodsForThisContext',
});

/** Отправка ExternalId  */
export const setExternalId = request<boolean, PsetExternalId>({
  method: 'creative.setExternalId',
});

/** Метод получения алгоритмов в креативах */
export const getCreativeAlgorithms = request<RAlgorithmCreativeList>({
  method: 'creative.getAlgorithmsCpc',
});

/** метод для получения тегов креатива */
export const getCreativeTags = request<RCreativeTags, THASH>({
  method: 'creative.getTags',
});
/** метод изменения тегов в креативе */
export const setCreativeTagsAPI = request<boolean, PSetCreativeTags>({
  method: 'creative.setTags',
});
/** метод получениия списка тегов всех креативов кампании */
export const getAllCreativeTags = request<RCreativeTags, THASH>({
  method: 'campaign.getAllCreativesTags',
});

export const getInternalList = request<TInternalList, { key: string }>({
  method: 'internalList.getByKey',
});

export const setAllStatuses = request<boolean, TPCreativesAllStatus>({
  method: 'campaign.setStatusesInCampaignCreatives',
});

export const setGroupStatuses = request<boolean, TPCreativeGroupStatus>({
  method: 'campaign.setStatusesInCreativeList',
});

export const clearTargetAllCreativesByCampaign = request<boolean, THASH>({
  method: 'targeting.removeAllInCreativesByCampaign',
});

export const clearTargetToList = request<boolean, THASH_LIST>({
  method: 'targeting.removeAllToList',
});

export const unsetCreativeTargeting = request<
  boolean,
  TParams
>({
  method: 'targeting.removeByKey',
  showError: false,
});

export const getCreativePretargeting = request<RPreTargeting | [], THASH>({
  method: 'creative.getPretargeting',
});

export const setCreativePretargetingApi = request<
  boolean,
  TPSetCreativePretargeting
>({
  method: 'creative.setPretargeting',
});

export const removeCreativePretargetingApi = request<boolean, THASH>({
  method: 'creative.removePretargetings',
});

export const fetchCreativesTranslationApi = request<
  Record<string, ResponseTranslation>,
  THASH
>({
  method: 'campaign.checkCreativesExport',
});

export const fetchCreativeTranslationApi = request<ResponseTranslation, THASH>({
  method: 'creative.checkExport',
});

export const getAllCreativeTypesApi = request<TRCreativeTypes>({
  method: 'campaign.getAllCreativeTypes',
});

export const createScreenAPI = request<boolean, THASH>({
  method: 'screenshot.create',
});

export const getScreenStatusAPI = request<TScreenStatus, THASH>({
  method: 'screenshot.getStatus',
});

export const massEditLinkAPI = request<boolean, MassEditLinkParams>({
  method: 'massEdit.creative',
});

export const getCustomerByEridAPI = request<getCustomerByEridParams | string[], {erid: string}>({
  method: 'creative.getCustomerByErid',
});

/** Следующие методы следует отрефакторить  */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getFormatting = (params: { html: string; method: string }) =>
  requestOld<{ html: string }, string>({
    method: `formatting.${params.method}`,
    params: { html: params.html },
  });
