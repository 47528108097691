import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import errorServeApi from 'utils/errorServeApi';
import { getToken } from './token';
import history from '../history';
import { RAuthToken } from '../../domains/user/types';

const getApi = () => {
  if (process.env.REACT_APP_DEV_API === 'true') {
    const customApi =
      localStorage.getItem('CUSTOM_API_URL') || process.env.REACT_APP_API;
    return customApi;
  }

  return process.env.REACT_APP_API;
};

const axiosOptions: AxiosRequestConfig = {
  headers: { 'Content-Type': 'text/plain;charset=UTF-8' },
  baseURL: getApi(),
};

type TPRequest = {
  method: string;
  showError?: boolean;
};

function request<R>({ method, showError }: TPRequest): () => Promise<R>;
function request<R, P extends Record<string, unknown>>({
  method,
  showError,
}: TPRequest): (params: P) => Promise<R>;

function request<R, P extends Record<string, unknown> | undefined = undefined>({
  method,
  showError,
}: TPRequest): (params?: P) => Promise<R> {
  return async function (params?: P) {
    const instance: AxiosInstance = axios.create(axiosOptions);
    instance.interceptors.request.use((config: AxiosRequestConfig) => {
      const token: RAuthToken | null = getToken();
      const clonedConfig = config;

      if (token) {
        clonedConfig.headers.common = {
          ...clonedConfig.headers,
          Authorization: `Bearer ${token.access_token}`,
        };
      }

      return clonedConfig;
    });
    try {
      const response = await instance.post(`?${method}`, {
        id: 1,
        method,
        jsonrpc: '2.0',
        params: params ?? {},
      });

      if (response?.data?.error) {
        throw response.data.error;
      }

      return response.data.result;
    } catch (err) {
      if (window.navigator.onLine === false) {
        history.push('/main/error/nc');
        throw err;
      }
      errorServeApi(err, method, showError);
      throw err;
    }
  };
}

export default request;
