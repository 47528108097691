import React, { FC, useEffect } from 'react';
import cn from 'classnames';
import i18next from 'i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Button from 'components/UI/Buttons/buttons';
import img from 'assets/errors/404.svg';
import css from './styles.module.scss';
import { useDispatchApp } from '../../../../redux/rootSelectors';
import { setError404 } from '../../../project/reducer';

interface Props extends RouteComponentProps {
  onClick?: () => void;
}

const Error404: FC<Props> = ({ onClick, history }) => {
  const dispatch = useDispatchApp();

  useEffect(() => {
    dispatch(setError404(true));
    return () => {
      dispatch(setError404(false));
    };
  }, []);

  const GoBack = () => {
    const doc = document.referrer;

    if (!doc) {
      return history.replace('/main');
    }

    return history.goBack();
  };

  return (
    <div className={cn(css['error-404'], 'fullscreen-error')}>
      <div className={css['error-404__form']}>
        <div className={css['error-404__code']}>
          <img className="fullscreen-error__img" src={img} alt="Ошибка 404" />
        </div>
        <div className={css['error-404__title']}>
          <span>{i18next.t('error_page.err404.title')}</span>
        </div>
        <div className={css['error-404__text']}>
          <span>{i18next.t('error_page.err404.text')}</span>
        </div>
        <div className={css['error-404__link']}>
          <Button
            title={i18next.t('error_page.err404.button')}
            buttonAttributes={{
              style: {
                fontWeight: 700,
                height: 48,
              },
              onClick: onClick || GoBack,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(Error404);
