import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FiAlertCircle } from 'react-icons/fi';
import css from './styles.module.scss';

const Targeting: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={css.targeting}>

      <div className={css.empty_cap}>
        <div className={css.title}>
          <FiAlertCircle size={24} color="#0a0a0a" />
          <span>{t('campaigns_page.pretarg_text')}</span>
        </div>
        <div className={css.actions} />
      </div>
    </div>
  );
};

export default Targeting;
