import React, { FC, useEffect, useState, useRef, useCallback } from 'react';
import cn from 'classnames';
import {
  FiMoreHorizontal,
  FiTrash2,
  FiCopy,
  FiRefreshCw,
  FiEdit,
} from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/UI/Tooltip';
import ToggleSwitch from 'components/UI/ToggleSwitch';
import { ReactSVG } from 'react-svg';
import copy from 'copy-to-clipboard';
import { useDispatchApp } from 'redux/rootSelectors';
import { useUserInfo, useUserTheme } from 'domains/user/model/selectors';
import { someGroups } from 'utils/statics/everyGroups';
import {
  deleteAPIUser,
  editAPIUser,
  refreshTokenAPIUser,
} from 'domains/usersAPI/model/actions';
import throttle from 'lodash/throttle';
import { useHistory } from 'react-router';
import { useFixedHeightItem } from 'utils/useFixedHeightItem';
import css from './styles.module.scss';
import { TAPIUser } from '../../model/types';
import avatarColors from '../../../../utils/avatarColors';
import lockSvg from '../../../../assets/lock.svg';
import unlockSvg from '../../../../assets/unlock.svg';
import GroupHint from './GroupHint';
import DeleteModal from '../modal/DeleteModal';
import { PartnerList } from './PartnersList';

interface Props {
  /** параметры пользователя */
  user: TAPIUser;
}

const Item: FC<Props> = ({ user }) => {
  const { t } = useTranslation();
  const { isThemeExtended, isThemeFull } = useUserTheme();
  const [toggle, setToggle] = useState(false);
  const itemRef = useRef<HTMLDivElement>(null);
  const { email, groups, partners, auth_token, isActive } = user;
  const [activeStatus, setStatus] = useState(isActive);
  const groupsCollapsedDisplay = isThemeExtended || isThemeFull ? 3 : 2;
  const { data: userInfo } = useUserInfo();
  const dispatch = useDispatchApp();
  const mainRef = useRef<HTMLDivElement | null>(null);
  const [openGroups, setOpenGroups] = useState(false);
  const [copied, setCopied] = useState<boolean>(false);
  const [delModal, setDelModal] = useState<boolean>(false);

  const counter = groups.length - groupsCollapsedDisplay > 0;

  const history = useHistory();

  const toggleHandler = () => {
    dispatch(
      editAPIUser({
        blockRefresh: true,
        email: user.email,
        data: {
          isActive: !activeStatus,
        },
        callbacks: {
          onToggle: setStatus,
        },
      }),
    );
  };

  const trottleRefreshToken = useCallback(
    throttle(() => dispatch(refreshTokenAPIUser({ email })), 1000),
    [],
  );

  useEffect(() => {
    if (isActive) {
      setStatus(true);
    }
  }, [isActive]);

  /* Фиксируем высоту элемента, чтобы при открытии бокового меню не скакала высота */
  const heightItem = useFixedHeightItem(itemRef);

  return (
    <div className={cn(css.wrapper)}>
      <div className={css.indicator} />
      <div
        className={cn(css.item, {
          _isApi: true,
          _isOpen: toggle,
        })}
        style={{
          height: toggle ? `${heightItem}px` : `auto`,
        }}
        ref={itemRef}
        onClick={() => history.push(`users_api/edit?email=${email}`)}
      >
        <div className={css.item__header}>
          <div
            className={css.img}
            style={{
              backgroundColor: avatarColors(email.slice(0, 1).toLowerCase()),
            }}
          >
            {email.slice(0, 1).toUpperCase()}
          </div>
          <div className={css.text}>
            <Tooltip
              className={css.tooltip}
              hintWidth={300}
              leftArrowPosition
              title={<strong>{email}</strong>}
            >
              <p>{email}</p>
            </Tooltip>
          </div>
        </div>

        <div
          className={css.value}
          ref={mainRef}
          onMouseEnter={() => setOpenGroups(true)}
          onMouseLeave={() => setOpenGroups(false)}
        >
          {groups.map(
            (group, index) =>
              index < groupsCollapsedDisplay && (
                <span key={group.id} className={css.group}>
                  {group.title}
                </span>
              ),
          )}
          {counter && (
            <>
              <span className={css.group}>
                +{groups.length - groupsCollapsedDisplay}
              </span>
            </>
          )}
        </div>

        <PartnerList partners={partners} />

        <div className={css.token}>
          <strong>Bearer </strong>
          <span>{auth_token}</span>
          <Tooltip
            title={
              <FiCopy
                size={20}
                className={cn(css.copy_icon, {
                  isDisabled: copied,
                })}
                onClick={(e) => {
                  e.stopPropagation();
                  setCopied(true);
                  copy(`Bearer ${auth_token}`);
                  setTimeout(() => {
                    setCopied(false);
                  }, 1500);
                }}
              />
            }
          >
            {copied ? t(`alerts.copied`) : t(`copy_btn`)}
          </Tooltip>
        </div>

        <div className={cn(css.value, 'isStatus')}>
          {activeStatus ? (
            <ReactSVG src={unlockSvg} />
          ) : (
            <ReactSVG src={lockSvg} />
          )}
        </div>

        <div
          className={cn('toggle-dropdown', css.dropdown, { _isOpen: toggle })}
          onMouseEnter={() => {
            setToggle(true);
          }}
          onMouseLeave={() => {
            setToggle(false);
          }}
        >
          <button type="button" className={css.dropdown__show}>
            <FiMoreHorizontal size={24} />
          </button>

          <div
            className={css.dropdown__menu}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Tooltip
              isDisabled={!someGroups(userInfo, 'owner', 'self_user')}
              title={<FiEdit size={24} />}
              onClick={() => history.push(`users_api/edit?email=${email}`)}
            >
              <span>{t('users_page.table.edit_user')}</span>
            </Tooltip>
            <Tooltip
              isDisabled={!someGroups(userInfo, 'owner', 'self_user')}
              title={<FiRefreshCw size={24} />}
              onClick={trottleRefreshToken}
            >
              <span>{t(`users_page.update_token`)}</span>
            </Tooltip>

            <Tooltip
              title={<FiTrash2 size={24} />}
              onClick={() => setDelModal(true)}
            >
              <span>{t('users_page.table.delete_user')}</span>
            </Tooltip>

            <Tooltip
              isDisabled={!someGroups(userInfo, 'owner', 'self_user')}
              title={
                <ToggleSwitch
                  disabled={!someGroups(userInfo, 'owner', 'self_user')}
                  onChange={toggleHandler}
                  checked={activeStatus}
                  toggleOnly
                />
              }
            >
              <span>
                {activeStatus
                  ? t('users_page.statuses.active')
                  : t('users_page.statuses.blocked')}
              </span>
            </Tooltip>
          </div>
        </div>
      </div>
      {delModal && (
        <DeleteModal
          onClose={() => setDelModal(false)}
          onDel={() => dispatch(deleteAPIUser({ email }))}
          email={email}
        />
      )}
      {openGroups && counter && (
        <GroupHint
          groups={groups.map((group) => group.title)}
          mainRef={mainRef}
        />
      )}
    </div>
  );
};

export default Item;
