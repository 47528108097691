import request from '../../../lib/helpers/requestApi';
import { CounteragentItem, CreateCounteragentParams, GetCounteragentParams, UpdateCounteragentParams } from './types';

export const getCounteragentAPI = request<CounteragentItem, GetCounteragentParams>({
  method: 'counteragent.get',
});

export const createCounteragentAPI = request<boolean, CreateCounteragentParams>({
  method: 'counteragent.create',
});

export const updateCounteragentAPI = request<boolean, UpdateCounteragentParams>({
  method: 'counteragent.update',
});
