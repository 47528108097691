import { SagaIterator } from '@redux-saga/core';
import { put, call } from 'redux-saga/effects';
import { Action } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from './fetchedData';
import updateToken from '../utils/updateToken';
import { wssCloseConnect } from '../domains/wss/model/actions';

/** Возврощает тип из промисов */
export type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;

/**
 * Описывает тип аргумента для makeReqWithRD
 * @fetcher - функция запрашивающая данные
 * @fill - креате экшен который отправляет полученные данные в редюсер
 * @fillSuccess - креате экшен об успешной отправке данных в редюсер
 * @fillFinally - креате экшен об финальной отправке данных в редюсер
 * @parameters - параметры для запроса данных
 * @isToken - отправка токена в запросе
 */

export type OptionsType<T extends (params: Parameters<T>[0]) => ReturnType<T>> =
  T extends () => ReturnType<T>
    ? {
        fetcher: T;
        fill: (v: FetchedData<ThenArg<ReturnType<T>>>) => Action<string>;
        fillSuccess?: (
          v: FetchedData<ThenArg<ReturnType<T>>>,
        ) => Action<string>;
        fillFinally?: (
          v: FetchedData<ThenArg<ReturnType<T>>>,
        ) => Action<string>;
        parameters?: undefined;
        isToken?: boolean;
        preData?: ThenArg<ReturnType<T>> | null;
      }
    : {
        fetcher: T;
        fill: (v: FetchedData<ThenArg<ReturnType<T>>>) => Action<string>;
        fillSuccess?: (
          v: FetchedData<ThenArg<ReturnType<T>>>,
        ) => Action<string>;
        fillFinally?: (
          v: FetchedData<ThenArg<ReturnType<T>>>,
        ) => Action<string>;
        parameters: Parameters<T>[0];
        isToken?: boolean;
        preData?: ThenArg<ReturnType<T>> | null;
      };

/**
 * Описывает тип для функции итератора makeReqWithRD
 * нужен для ефектов саги чтобы указывать параметр дженерика
 */
export type TMakeReqWithRD<T extends (v: Parameters<T>[0]) => ReturnType<T>> = (
  options: OptionsType<T>,
) => SagaIterator;

export function* makeReqWithRD<
  T extends (params: Parameters<T>[0]) => ReturnType<T>,
>(options: OptionsType<T>): SagaIterator {
  const {
    fetcher,
    fill,
    parameters,
    fillSuccess,
    fillFinally,
    isToken = true,
    preData,
  } = options;
  type TreceivedData = FetchedData<ThenArg<ReturnType<T>>>;

  let receivedData: TreceivedData = yield call<
    (data: ThenArg<ReturnType<T>> | null) => TreceivedData
  >(genFetchedData, preData || null);

  try {
    if (isToken) {
      yield call(updateToken);
    }
    receivedData = receivedData.set('isLoading', true);
    yield put(fill(receivedData));

    const result: ThenArg<ReturnType<T>> = yield call<
      (params: Parameters<T>[0]) => ReturnType<T>
    >(fetcher, parameters);

    receivedData = receivedData.set('data', result);
    if (typeof fillSuccess !== 'undefined') {
      yield put(fillSuccess(receivedData));
    } else {
      yield put(fill(receivedData));
    }
  } catch (error) {
    receivedData = receivedData.set('error', {
      isError: true,
      message: error?.message,
      code: error?.code,
    });
    if (error?.code === 91) {
      yield put({ type: 'AUTH_LOGOUT_SUCCESS' });
      yield put(wssCloseConnect());
      document.location.href = '/';
    }
    yield put(fill(receivedData));
  } finally {
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    if (typeof fillFinally !== 'undefined') {
      yield put(fillFinally(receivedData));
    } else {
      yield put(fill(receivedData));
    }
  }
}

/** Функция позволяет прокинуть значение loading, error, LTS в время обработки fill */
interface PFetchedDataProps<T, S> {
  /** Объект FetchData для извлечения атрибутов */
  fillFetchedData: FetchedData<T>;
  /** Новые данные какие необходимо сохранить */
  newData: S;
}

export function updateFillFetchedData<T, S>({
  fillFetchedData,
  newData,
}: PFetchedDataProps<T, S>): FetchedData<S> {
  const loading = fillFetchedData.get('isLoading');
  const error = fillFetchedData.get('error');
  const LTU = fillFetchedData.get('LTU');
  let fetchedData = genFetchedData<S>(null).set('data', newData);
  fetchedData = fetchedData.set('isLoading', loading);
  fetchedData = fetchedData.set('error', error);
  fetchedData = fetchedData.set('LTU', LTU);
  return fetchedData;
}
