import React, { FC, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import cn from 'classnames';
import BlackHeader from 'components/BlackHeader';
import ScrollToTopButton from 'components/ScrollToTopButton';
import { useStateValue } from 'utils/context';
import { useTranslation } from 'react-i18next';
import Loader from 'components/UI/Loader';
import Containers from 'domains/containers';
import ContainersDetail from 'domains/containerDetail';
import { fetchPersonalInfo } from 'domains/user/actions';
import Campaigns from 'domains/campaigns';
import Offers from 'domains/offers/ui';
import ClientsList from 'domains/clients';
import CreateCampaign from 'domains/campaigns/CreateCampaign';
import Agencies from 'domains/agencies';
import DetailNew from 'domains/campaign';
import { everyGroups, someGroups } from 'utils/statics/everyGroups';
import Client from 'domains/client';
import Agency from 'domains/agency';
import {
  NotificationState,
  setBrowserErrorNotification,
} from 'domains/notification/reducer';
import ProfileNew from 'domains/profile';
import Error404 from 'domains/app/Errors/Error404';
import NC from 'domains/app/Errors/NoConnection';
import { useDispatchApp } from 'redux/rootSelectors';
import { userNotificationPush } from 'domains/user/model/hooks';
import NoAccess from 'domains/app/Errors/noAccess';
import AgencyReport from 'domains/agency/ui/Reports';
import Offer from 'domains/offer/ui';
import Counteragent from 'domains/counteragent/ui';
import Counteragents from 'domains/counteragents/ui';
import Contracts from 'domains/contracts/ui';
import Contract from 'domains/contract/ui';
import { useProjectInfo } from 'domains/project/hooks';
import css from './styles.module.scss';
import { AppState } from '../../redux/rootReducer';
import { sentPushNotificationRequest } from '../../domains/user/reduser';
import { useIsGroup } from '../../domains/user/hooks';
import i18n from '../../i18n';
import SearchByIdPopup from '../../components/SearchByIdPopup';
import { ResponseUser } from '../../domains/user/types';
import { useUserInfo, useUserTheme } from '../../domains/user/model/selectors';
import { setIsShowModal } from '../../domains/search/model/reducer';

export const RedirectCustom = (user: ResponseUser | null): string => {
  if (user && user.partner.type === 'ORGANIZATION') {
    return 'agencies';
  }
  if (user && user.partner.type === 'CLIENT') {
    if (
      !someGroups(user, 'default', 'owner', 'self_user') &&
      someGroups(user, 'reports_client')
    ) {
      return `client/${user.partner.xxhash}/reports`;
    }
    return 'campaigns';
  }
  return 'clients';
};

const Main: FC<RouteComponentProps> = ({ match }) => {
  const { data: user, isLoading, LTU, error: userError } = useUserInfo();
  const { data: project } = useProjectInfo();
  const { getValueFromSettingForKey } = useUserTheme();
  const { isOwner, isSelfUser } = useIsGroup();
  const dispatch = useDispatchApp();
  const { t } = useTranslation();

  const { isErrorBrowserNotification } = useSelector<
    AppState,
    NotificationState
  >(({ notificationReducer }) => notificationReducer, shallowEqual);
  document.addEventListener('keydown', (event) => {
    if (event.code === 'KeyK' && (event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      dispatch(setIsShowModal(true));
    }
  });

  useEffect(() => {
    const viewModeHide = localStorage.getItem('$viewModeHide');
    const Text = () => (
      <div>
        {t('alerts.view_mode')}
        <br />
        {t('alerts.view_mode_cont')}
      </div>
    );

    if (!viewModeHide) {
      toast(<Text />, {
        type: 'info',
        delay: 500,
        autoClose: 5000,
        onClose: () => {
          localStorage.setItem('$viewModeHide', 'true');
        },
      });
    }

    if (user === null) {
      dispatch(fetchPersonalInfo());
    }

    return () => localStorage.setItem('$viewModeHide', 'true');
  }, []);

  useEffect(() => {
    if (user) {
      const localLang = localStorage.getItem('lang');
      if (localLang !== user.lang) {
        localStorage.setItem('lang', user.lang);
        i18n.changeLanguage(user.lang);
      }
    }
  }, [LTU]);

  const { state } = useStateValue();

  useEffect(() => {
    if (getValueFromSettingForKey('notification')) {
      const { messaging } = userNotificationPush();
      if (messaging) {
        if (Notification.permission === 'denied') {
          dispatch(setBrowserErrorNotification(true));
        }
        if (Notification.permission === 'granted') {
          if (isErrorBrowserNotification) {
            dispatch(setBrowserErrorNotification(false));
          }
        }
        const IntitalizeFireBaseMessaging = () => {
          messaging
            .requestPermission()
            .then(() =>
              messaging.getToken(),
            )
            .then((token) => {
              dispatch(sentPushNotificationRequest({ token }));
              localStorage.setItem('$push-token', token);
            })
            .catch((reason) => {
              console.log(reason);
            });
        };
        messaging.onMessage((payload) => {
          const notificationOption = {
            body: payload.notification.body,
            icon: payload.notification.icon,
          };

          if (Notification.permission === 'granted') {
            const notification = new Notification(
              payload.notification.title,
              notificationOption,
            );

            notification.onclick = function (ev) {
              ev.preventDefault();
              window.open(payload.notification.click_action, '_blank');
              notification.close();
            };
          }
        });
        messaging.onTokenRefresh(() => {
          messaging
            .getToken()
            .then((newtoken) => {
              dispatch(sentPushNotificationRequest({ token: newtoken }));
            })
            .catch((reason) => {
              console.log(reason);
            });
        });
        IntitalizeFireBaseMessaging();
      }
    }
  }, [LTU]);

  useEffect(() => {
    if (userError.isError) {
      const { messaging } = userNotificationPush();
      if (messaging) {
        localStorage.removeItem('$push-token');
        messaging.deleteToken();
      }
    }
  }, [LTU]);

  return (
    <div className={cn(css.wrapper, { extend: state.theme.containerToggle })}>
      {isLoading && <Loader />}
      {!isLoading && user && (
        <>
          <BlackHeader />
          <SearchByIdPopup />
          {user.group.length ? (
            <Switch>
              <Route
                exact
                path={`${match.path}/profile`}
                component={ProfileNew}
              />
              <Route
                exact
                path={`${match.path}/campaigns`}
                component={
                  someGroups(user, 'default', 'owner', 'self_user')
                    ? Campaigns
                    : NoAccess
                }
              />
              {(isOwner || isSelfUser) && (
                <Route
                  path={`${match.path}/campaigns/create-campaign`}
                  component={CreateCampaign}
                />
              )}
              <Route
                exact
                path={`${match.path}/containers`}
                component={
                  everyGroups(user, 'container') ? Containers : Error404
                }
              />
              {(user.partner.type === 'AGENCY' ||
                user.partner.type === 'ORGANIZATION') && (
                <Route exact path={`${match.path}/clients`}>
                  <ClientsList />
                </Route>
              )}
              <Route
                path={`${match.path}/campaign/:id`}
                component={DetailNew}
              />
              <Route
                path={`${match.path}/counteragents`}
                component={
                  someGroups(user, 'owner', 'self_user') &&
                  (user.partner.type === 'AGENCY' ||
                    user.partner.type === 'ORGANIZATION') &&
                  project?.ord_registration
                    ? Counteragents
                    : NoAccess
                }
              />
              <Route
                path={`${match.path}/counteragent`}
                component={
                  someGroups(user, 'owner', 'self_user') &&
                  (user.partner.type === 'AGENCY' ||
                    user.partner.type === 'ORGANIZATION') &&
                  project?.ord_registration
                    ? Counteragent
                    : NoAccess
                }
              />
              <Route
                path={`${match.path}/contracts`}
                component={
                  someGroups(user, 'owner', 'self_user') &&
                  (user.partner.type === 'AGENCY' ||
                    user.partner.type === 'ORGANIZATION') &&
                  project?.ord_registration
                    ? Contracts
                    : NoAccess
                }
              />
              <Route
                path={`${match.path}/contract`}
                component={
                  someGroups(user, 'owner', 'self_user') &&
                  (user.partner.type === 'AGENCY' ||
                    user.partner.type === 'ORGANIZATION') &&
                  project?.ord_registration
                    ? Contract
                    : NoAccess
                }
              />
              <Route
                path={`${match.path}/offers`}
                component={
                  someGroups(user, 'owner', 'self_user') ? Offers : NoAccess
                }
              />
              <Route path={`${match.path}/offer`} component={Offer} />
              <Route
                path={`${match.path}/container-detail/:id`}
                component={ContainersDetail}
              />
              <Route path={`${match.path}/client/:id`} component={Client} />
              <Route path={`${match.path}/agency/:id`} component={Agency} />
              {user.partner.type === 'ORGANIZATION' && (
                <Route path={`${match.path}/agencies`} component={Agencies} />
              )}
              <Route
                path={`${match.path}/agency/:id/reports`}
                component={
                  everyGroups(user, 'reports_client') &&
                  (user.partner.type === 'ORGANIZATION' ||
                    user.partner.type === 'AGENCY')
                    ? AgencyReport
                    : Error404
                }
              />
              <Redirect
                exact
                from={match.path}
                to={`${match.path}/${RedirectCustom(user)}`}
              />
              <Route path={`${match.path}/error/nc`} component={NC} />
              <Route component={Error404} />
            </Switch>
          ) : (
            <Switch>
              <Route
                exact
                path={`${match.path}/profile`}
                component={ProfileNew}
              />
              <Route component={NoAccess} />
            </Switch>
          )}

          <ScrollToTopButton />
        </>
      )}
    </div>
  );
};

export default Main;
