import firebase from 'firebase';

type Messaging = firebase.messaging.Messaging;
type TPush = {
  messaging: false | Messaging;
};

export function userNotificationPush(): TPush {
  return {
    messaging: firebase.messaging.isSupported() ? firebase.messaging() : false,
  };
}
