import React, { FC } from 'react';
import cn from 'classnames';
import css from '../styles.module.scss';

const LoadingBreadcrumbs: FC = () => (
  <div className={css.breadcrumbs}>
    <div className={cn('load-item', css.loading)} />
  </div>
);

export default LoadingBreadcrumbs;
