import moment from 'moment';
import { parsingDate } from '../momentParse';

type TFGenTimePeriod = (count: number, format?: string) => string[];
export type TTwoMonthsDateReturn = {from: string, to: string};
/** генерирует массив дат начиная с сегодня */
export const genTimePeriod: TFGenTimePeriod = (
  count,
  format = 'YYYY-MM-DD',
) => {
  let localCount = 1;
  const result: string[] = [parsingDate(new Date()).format(format)];
  while (localCount < count) {
    result.unshift(
      parsingDate(moment().subtract(localCount, 'days')).format(format),
    );
    localCount += 1;
  }
  return result;
};

const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const isLeapYear = (year: number) =>
  (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

const getDaysInFebruary = (year: number) => (isLeapYear(year) ? 29 : 28);

// Возвращает последние 2 месяца
export const getLastTwoMonthsDate = (): TTwoMonthsDateReturn => {
  const currentDate = new Date();
  const twoMonthsAgoDate = new Date(currentDate);

  // Устанавливаем месяц на два месяца назад
  twoMonthsAgoDate.setMonth(twoMonthsAgoDate.getMonth() - 2);

  // Учитываем количество дней в феврале
  const daysInFebruary = getDaysInFebruary(twoMonthsAgoDate.getFullYear());
  if (twoMonthsAgoDate.getDate() > daysInFebruary) {
    twoMonthsAgoDate.setDate(daysInFebruary);
  }

  return { from: formatDate(twoMonthsAgoDate), to: formatDate(currentDate) };
};
