import Card from 'components/UI/Card';
import React, { FC, useMemo } from 'react';
import { Field, FieldProps } from 'formik';
import { useUserTheme } from 'domains/user/model/selectors';
import LabelField from 'components/UI/LabelField';
import cn from 'classnames';
import { getCurrentVariableField } from 'utils/getCurrentVariableField';
import { createOptionsForConfigSelect } from 'utils/createOptionsForConfigSelect';
import { checkedLocalization } from 'utils/checkedLocalization';
import NewSelect from 'components/UI/NewSelect';
import i18n from '../../../../../../i18n';
import css from '../styles.module.scss';
import Help from '../../../../../../components/Help';
import PriceInput from '../../../../../../components/UI/PriceInput';
import { priceOptions } from '../../../../model/shared/consts';
import { selectDefault } from '../../../../../../utils/selectDefault';
import { isFieldInObject } from '../../../../../../utils/isFieldInObject';
import {
  CampaignGeneralComponentProps,
  TExperimentalInfoField,
} from '../../../../types';
import { useCardHint } from './model/useCardHint';
import { useOptionsData } from './model/useOptionsData';

type OptimizationProps = Pick<
  CampaignGeneralComponentProps,
  'formik' | 'currency' | 'setField' | 'savedField' | 'autoSubmit'
> & {
  experimentalInfo?: TExperimentalInfoField[] | null;
};

const Optimization: FC<OptimizationProps> = ({
  formik,
  currency,
  setField,
  savedField,
  autoSubmit,
  experimentalInfo,
}) => {
  const { showHint, closeHint } = useCardHint(formik);
  const { getValueFromSettingForKey } = useUserTheme();
  const { isCPA, optionsList } = useOptionsData({
    payType: formik.values.pay_type,
  });

  const coef_name = useMemo(
    () => getCurrentVariableField(experimentalInfo, 'coef_name'),
    [experimentalInfo],
  );

  const coefOptions = useMemo(
    () => createOptionsForConfigSelect(coef_name),
    [coef_name],
  );

  return (
    <Card
      noHover
      style={{ padding: '28px 24px' }}
      className={cn(css.optimization, css.card)}
      isUseAccess
      showHint={showHint}
      closeHint={closeHint}
      textHint={
        <>
          <strong>
            {i18n.t(
              'campaigns_page.campaign_settings.hint_for_card.optim_part1',
            )}
          </strong>
          {i18n.t('campaigns_page.campaign_settings.hint_for_card.optim_part2')}
        </>
      }
    >
      <h3>
        {i18n.t('campaigns_page.campaign_settings.Optimization')}
        {getValueFromSettingForKey('HideHelp') && (
          <Help
            width={150}
            title={i18n.t('campaigns_page.campaign_settings.Optimization')}
            helpattributes={{
              link: i18n.t(
                'campaigns_page.campaign_settings.campaign_prompt.optimization.link',
              ),
              sizeIcon: 18,
            }}
          />
        )}
      </h3>
      <div className={css.form}>
        <div className={css.row}>
          <PriceInput
            classname={css.optimization_price}
            isWatchEnter={autoSubmit}
            setField={setField}
            isSaved={isFieldInObject(savedField, 'pay_price')}
            maxLength={18}
            label={`${i18n.t(
              `campaigns_page.campaign_settings.cost`,
            )}, ${currency}`}
            placeholder={i18n.t(
              'campaigns_page.campaign_settings.Please_enter',
            )}
            value={formik.values.pay_price ?? ''}
            error={!!formik.errors.pay_price}
            decimalScale={3}
            errortext={`${i18n.t('errors.limitsym')}`}
            onValueChange={({ floatValue }) => {
              formik.setFieldValue('pay_price', floatValue);
            }}
            theme="horizontal"
            errorAbsolte
          />
          <Field name="pay_type">
            {({ field, form }: FieldProps) => (
              <NewSelect
                className={css.shortSelect}
                autoSubmit={autoSubmit}
                form={form}
                field={field}
                selectAttributes={{
                  options: priceOptions,
                  value: selectDefault(formik.values.pay_type, priceOptions),
                }}
                heightCustom={32}
                viewStyle="Horizontal"
              />
            )}
          </Field>
        </div>
        {isCPA && (
          <LabelField label={i18n.t(`creative.create_creative.Actions`)}>
            <Field name="pay_optimization_field_id">
              {({ field, form }: FieldProps) => (
                <NewSelect
                  autoSubmit={autoSubmit}
                  form={form}
                  field={field}
                  error={!!formik.errors.pay_optimization_field_id}
                  errorText={formik.errors.pay_optimization_field_id || ''}
                  selectAttributes={{
                    options: optionsList,
                    placeholder: i18n.t(`campaigns_page.choose_action`),
                    value: optionsList?.find(
                      (optionItem) =>
                        optionItem.value ===
                        formik.values.pay_optimization_field_id,
                    ),
                  }}
                  heightCustom={32}
                  viewStyle="Horizontal"
                />
              )}
            </Field>
          </LabelField>
        )}
        {coef_name && !isCPA && (
          <LabelField
            label={checkedLocalization(
              `campaigns_page.${coef_name.field}`,
              coef_name.title,
            )}
          >
            <Field name={`config_values.${coef_name.id}`}>
              {({ field, form }: FieldProps) => (
                <NewSelect
                  className={css.choose_action}
                  autoSubmit={autoSubmit}
                  form={form}
                  field={field}
                  selectAttributes={{
                    options: coefOptions,
                    placeholder: i18n.t(`campaigns_page.choose_action`),
                    value:
                      formik.values.config_values &&
                      coefOptions &&
                      selectDefault(
                        String(
                          formik.values.config_values[String(coef_name.id)],
                        ),
                        coefOptions,
                      ),
                  }}
                  heightCustom={32}
                  viewStyle="Horizontal"
                />
              )}
            </Field>
          </LabelField>
        )}
      </div>
    </Card>
  );
};

export default Optimization;
