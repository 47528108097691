import React, { CSSProperties, FC, ReactNode } from 'react';
import cn from 'classnames';
import { MdInfoOutline } from 'react-icons/md';
import css from './styles.module.scss';

type ErrorTextProps = {
  text: string | ReactNode;
  style?: CSSProperties;
  classname?: string;
  hideIcon?: boolean;
};

const ErrorText: FC<ErrorTextProps> = ({
  text,
  style,
  classname,
  hideIcon = false,
}) => (
  <span className={cn(css.error, classname, 'ErrorText')} style={{ ...style }}>
    {!hideIcon && <MdInfoOutline size="20" />}
    <span>{text}</span>
  </span>
);

export default ErrorText;
