import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { Element, scroller } from 'react-scroll';
import FixedTableHeader from 'components/UI/FixedTableHeader';
import TableFilter from 'components/UI/TableFilter';
import debounce from 'lodash/debounce';
import { useDispatchApp } from 'redux/rootSelectors';
import { setTagCampaignActiveDefault } from 'domains/search/model/actions';
import ModalSettings from 'components/ModalSettings';
import ToggleSwitch from 'components/UI/ToggleSwitch';
import { FiExternalLink, FiTrash2 } from 'react-icons/fi';
import Checkbox from 'components/UI/Checkbox/checkbox';
import { ReactSVG } from 'react-svg';
import Tooltip from 'components/UI/Tooltip';
import TranslationsListFilter from 'components/TranslationsListFilter';
import { useFilterCreoListByTranslation } from 'domains/creative/creatives/hooks/useFilterCreoListByTranslation';
import ReportChart from 'components/ReportChart';
import LoadingReportChart from 'components/ReportChart/components/LoadingReportChart';
import CampaignsTotal from 'components/CampaignsTotal';
import { useReportChart } from 'components/ReportChart/hook/useReportChart';
import ElementsNone from 'components/ElementsNone';
import { ScrollSync, ScrollSyncNode } from 'scroll-sync-react';
import { checkedLocalization } from 'utils/checkedLocalization';
import { PeriodEnum } from 'types/containers';
import allSvg from './icons/all.svg';
import NavigationHeader from '../../components/NavigationHeader';
import { KeysSettings, useStateValue } from '../../utils/context';
import emptyTranslationResult from '../../assets/errors/empty-creo-translation.svg';
import {
  fetchCampaigns,
  fetchCampaignsStatistic,
  fetchCampaignsStatisticWeek,
  fetchChartStatistic,
  resetStatisticFieldList,
  setListSort,
} from './reducer';
import PreloaderList from '../../components/Preloaders/List';
import css from './styles.module.scss';
import toArchiveSvg from '../../assets/toarchive.svg';
import fromArchiveSvg from '../../assets/fromarchive.svg';
import Item from './Item';
import useSearchField from '../../hooks/useSearchField';
import Breadcrumbs from '../../components/Breadcrumbs/breadcrumbs';
import {
  ElementsNoneType,
  TOnSetDateRange,
  TPropsSelector,
} from '../../types/general';
import { useHighlights } from './model/hooks/useLastID';
import { useFetchDataCampaigns } from './model/hooks/useFetchDataCampaigns';
import ErrorReport from '../../components/Errors/Reports';
import TableHeader from './TableHeader';
import { TStatusCampaign } from '../campaign/types';
import {
  selectorCampaigns,
  useCampaignsInfo,
  useCampaignsTotal,
  useCampaignsTranslation,
} from './model/selectors';
import { setCampaignsTabs } from '../storage/model/reducer';
import { RCampaignsArrayStata } from './types';
import { useUserTheme } from '../user/model/selectors';
import { useCampaignsTags } from '../search/model/hooks';
import FilterResult from '../../components/Filter/FilterResult';
import { useCampaignsFilterSettings } from './model/hooks/useCampaignsFilterSettings';
import { useCampaignListSortInfo } from './model/hooks/useCampaignListSortInfo';
import OnlyOwner from '../../components/OnlyOwner';
import ModalWithQuestion from '../modals/modalWithQuestion';
import CopySvg from '../../assets/copy-icon.svg';
import CopyModal from '../../components/CopyModal';
import DuplicateSvg from '../../assets/duplicate-icon.svg';
import { useIsGroup } from '../user/hooks';
import filterSvg from '../../components/TranslationsListFilter/all.svg';
import { useGetChartPreData } from './model/hooks/useGetChartPreData';
import LoadingCampaignsTotal from '../../components/CampaignsTotal/LoadingCampaignsTotal';
import { useDataCampaignsStatisticAndTranslation } from './model/hooks/useDataCampaignsStatisticAndTranslation';
import { fetchStatisticFieldList } from './model/actions';
import { useGetFilterForCampaigns } from './model/hooks/useGetFilterForCampaigns';

type Props = RouteComponentProps & TPropsSelector<RCampaignsArrayStata>;
const Campaigns: FC<Props> = ({
  LTU,
  isLoading,
  list,
  error,
  serverList,
  isBlocked,
  isLoadingView,
  ...params
}) => {
  const { t } = useTranslation();

  const { filterColumns } = useGetFilterForCampaigns();

  const searchRef = useRef<HTMLInputElement>(null);
  const headerRef = useRef(null);

  const { onChangeSearch, search, onCloseSearch, onOpenSearch, toggleSearch } =
    useSearchField(searchRef);

  const { state, dispatch: dispatchContext } = useStateValue();
  const settings = state.settings[KeysSettings.ALL_CAMPAIGNS];
  const param = new URLSearchParams(params.location.search);
  const idFromURL = param.get('client_id');
  const [fixed, setFixed] = useState(false);

  const statAndTranlationData = useDataCampaignsStatisticAndTranslation();
  const { isLoadingStata, isLoadingWeek } = statAndTranlationData;
  const dispatch = useDispatchApp();
  const updateDateCampaigns: TOnSetDateRange = (dataPeriod, dataType) => {
    dispatchContext({
      type: 'setSettings',
      payload: {
        item: {
          type: dataType,
          period: dataPeriod,
        },
        key: KeysSettings.ALL_CAMPAIGNS,
      },
    });
  };

  const { data: campaignsList, isLoading: isLoadingCampaigns } =
    useCampaignsInfo();

  const { data: campaignsTranslationData, isLoading: isLoadingTranslation } =
    useCampaignsTranslation();
  const { currentFilter, currentTranslationXxhashArr, filterCreoTranslation } =
    useFilterCreoListByTranslation(
      campaignsList,
      campaignsTranslationData,
      'campaign',
      search,
      settings.period,
    );

  const {
    showReport,
    chartLegend,
    chartData,
    chartDataIsLoading,
    onClickChartLegend,
    showReportHandler,
  } = useGetChartPreData({ filterColumns, fixed });

  const {
    filter,
    user,
    toggle,
    setToggle,
    isOpenModalArchive,
    setOpenModalArchive,
    handleChangeArchive,
    setOpenDelete,
    openDelete,
    handleChangeCampaignStatus,
    handleRemoveCampaigns,
    checkedGroup,
    defineCheckedCampaign,
    handlerCampaignChecked,
    getCampaignsChecked,
    handlerAllCheckedCampaigns,
    handlerClickStatusCampaignEdit,
    isModalAllSettingGroup,
    setIsModalAllSettingGroup,
    generateTextIsModalText,
    clientTitle,
    handlerUpdateCampaignsGroup,
    setCheckedGroup,
    setClientInfo,
    xxhashWithCopyModal,
    handlerCopyCampaignsOtherClient,
    isLoadingSearch,
  } = useFetchDataCampaigns({
    partner_xxhash: idFromURL,
    dates: {
      type: settings.type,
      period: settings.period,
    },
    updateDates: updateDateCampaigns,
    serverList,
    error,
    LTU,
    isLoading,
    list,
    isBlocked,
    search,
    showReport,
    currentFilter,
    currentTranslationXxhashArr,
  });
  const { tagCampaignsActive } = useCampaignsTags();

  const { data: campaignTotal, isLoading: isLoadingTotal } =
    useCampaignsTotal();

  const {
    filters,
    handleApplyFilter,
    handleRemoveFilterItem,
    handleClearAllFilterItem,
  } = useCampaignsFilterSettings();

  const [isModalCopy, setIsModalCopy] = useState(false);

  const { isThemeExtended, isThemeFull, getValueFromSettingForKey } =
    useUserTheme();

  const { isOwner, isSelfUser } = useIsGroup();

  const onChange = debounce((filterParam: TStatusCampaign) => {
    dispatch(setCampaignsTabs(filterParam));
    dispatch(setTagCampaignActiveDefault(true));
    if (user) {
      dispatch(setListSort({ key: null, sort: null }));
      dispatch(
        fetchCampaigns({
          partner_xxhash: idFromURL || user.partner.xxhash,
          dates: {
            type: settings.type,
            period: settings.period,
          },
          filters: {
            status: filterParam,
          },
        }),
      );
    }
  }, 50);

  const {
    handlerSort: handlerCampaign,
    key: activeKeyCampaign,
    sort: sortCampaign,
  } = useCampaignListSortInfo();

  const searchFilter = (
    serverListData: RCampaignsArrayStata | null,
    searchVal: string,
  ): string[] | null => {
    if (!serverListData) return null;
    if (search)
      return serverListData
        .filter((item) =>
          item.title.toLowerCase().includes(searchVal.toLowerCase()),
        )
        .map((e) => e.xxhash);
    return serverListData.map((e) => e.xxhash);
  };

  const translationFilter = (
    translationData: string[],
    xxhashListData: string[] | null,
  ) => {
    if (!xxhashListData) return null;
    if (translationData.length)
      return xxhashListData.filter((item) => translationData.includes(item));
    return xxhashListData;
  };

  const onUpdateStatistic: TOnSetDateRange = (period, type) => {
    const xxhash_list = serverList?.map((e) => e.xxhash) || [];
    const xxhash_list_total = translationFilter(
      currentTranslationXxhashArr,
      searchFilter(serverList, search),
    );
    dispatchContext({
      type: 'setSettings',
      payload: {
        item: {
          type,
          period,
        },
        key: KeysSettings.ALL_CAMPAIGNS,
      },
    });
    if (!xxhash_list?.length) return;
    if (showReport) {
      dispatch(
        fetchChartStatistic({
          xxhash_list,
          period,
        }),
      );
    }
    if (type !== PeriodEnum.WEEK) {
      dispatch(
        fetchCampaignsStatistic({
          xxhash_list,
          xxhash_list_total,
          period,
        }),
      );
      return;
    }
    dispatch(
      fetchCampaignsStatisticWeek({
        xxhash_list,
        xxhash_list_total,
        period,
      }),
    );
  };

  const onlyCurrent = useMemo(() => {
    const days = Math.floor(
      moment
        .duration(moment(new Date()).diff(moment(settings.period.from)))
        .asDays(),
    );

    return days === 6 || days === 7;
  }, [settings]);

  const lastId = useHighlights();

  useEffect(() => {
    setCheckedGroup([]);
    setClientInfo([]);
    setTimeout(() => {
      scroller.scrollTo(lastId, {
        duration: 0,
        delay: 100,
        smooth: false,
        offset: -400,
      });
    }, 1000);
  }, [LTU]);

  useEffect(() => {
    const currentXxhash = idFromURL || user?.partner.xxhash;
    if (currentXxhash) {
      /* Обязательно очищать стейт при размонтировании списка */
      dispatch(
        fetchStatisticFieldList({
          xxhash: currentXxhash,
          types: ['All', 'Campaign'],
        }),
      );
    }
  }, [user, idFromURL]);

  useEffect(
    () => () => {
      dispatch(resetStatisticFieldList());
    },
    [],
  );

  useEffect(() => {
    if (tagCampaignsActive.length) {
      dispatch(setTagCampaignActiveDefault(false));
    }
  }, [search]);

  const [isContent, setIsContent] = useState(false);
  const filtersContainerRef = useRef<HTMLDivElement>(null);
  const scrollBlockRef = useRef<HTMLDivElement>(null);

  /* При фильрации списка кампаний, принудительно скроллим влево значения таблицы */
  const [curentScrollPosition, setCurentScrollPosition] = useState<number>(0);

  useEffect(() => {
    if (filtersContainerRef && filtersContainerRef.current) {
      filtersContainerRef.current.scrollLeft = curentScrollPosition;
    }
  }, [sortCampaign, settings.period]);

  const { dataForChart, series, namingItem, isLoadingChartView } =
    useReportChart({
      chartData,
      chartDataLegend: chartLegend,
      columns: filterColumns,
    });

  useEffect(() => {
    const listOnLoad = serverList?.length !== 0 && !search && list.length === 0;
    setIsContent(listOnLoad || list.length !== 0 || isLoadingView || isLoading);
  }, [isLoadingView, isLoading, serverList, list, search]);

  if (error && error.isError) {
    return <ErrorReport error={error} />;
  }

  return (
    <ScrollSync>
      <div className={css.campaigns}>
        <Helmet>
          <title>{t('campaigns_page.campaigns')}</title>
        </Helmet>

        <NavigationHeader
          back={
            user?.partner.type !== 'partner'
              ? undefined
              : { to: '/main/clients' }
          }
        >
          <button
            type="button"
            className={cn('tabs__item', {
              isActive: filter === 'LAUNCHED',
            })}
            onClick={() => onChange('LAUNCHED')}
            disabled={isLoading}
          >
            {t('campaigns_page.campaigns_black_header_main.act')}
          </button>
          <button
            type="button"
            className={cn('tabs__item', {
              isActive: filter === 'STOPPED',
            })}
            onClick={() => onChange('STOPPED')}
            disabled={isLoading}
          >
            {t('campaigns_page.campaigns_black_header_main.pause')}
          </button>
          <button
            type="button"
            className={cn('tabs__item', {
              isActive: filter === 'ARCHIVE',
            })}
            onClick={() => onChange('ARCHIVE')}
            disabled={isLoading}
          >
            {t('clients_page.archive_clients')}
          </button>
          <button
            type="button"
            className={cn('tabs__item', {
              isActive: filter === 'DELETED',
            })}
            onClick={() => onChange('DELETED')}
            disabled={isLoading}
          >
            {t('campaigns_page.campaigns_black_header_main.del')}
          </button>
          <button
            type="button"
            className={cn('tabs__item', {
              isActive: filter === '',
            })}
            onClick={() => onChange('')}
            disabled={isLoading}
          >
            {t('campaigns_page.campaigns_black_header_main.all')}
          </button>
        </NavigationHeader>

        <FixedTableHeader
          handlerAllCheckedCampaigns={handlerAllCheckedCampaigns}
          getCampaignsChecked={getCampaignsChecked}
          filters={filterColumns}
          refObject={headerRef}
          fixed={fixed}
          setFixed={setFixed}
          tab={filter}
          currentFilter={currentFilter}
          filterCreoTranslation={filterCreoTranslation}
          isLoadingTranslationFilter={
            isLoadingTranslation || isLoadingCampaigns
          }
          onlyCurrent={onlyCurrent}
          scrollH={showReport ? 500 : 100}
        >
          <div
            className={cn(css.header, {
              _isToggleSearch: toggleSearch,
              _isFixed: fixed,
            })}
            ref={headerRef}
          >
            <div
              className={cn('container resized', {
                extend: isThemeExtended,
                full: isThemeFull,
              })}
            >
              <Breadcrumbs type="Client" xxhash={idFromURL} />
              <TableHeader
                filtersLabel={filterColumns}
                onUpdateStatistic={onUpdateStatistic}
                onChangeSearch={onChangeSearch}
                onCloseSearch={onCloseSearch}
                onOpenSearch={onOpenSearch}
                search={search}
                params={params}
                searchMatch={list.length}
                isShowMatch={!isLoading && !isLoadingSearch}
                settings={settings}
                searchRef={searchRef}
                toggleSearch={toggleSearch}
                handleApplyFilter={handleApplyFilter}
                filters={filters}
                showReport={showReport}
                setShowReport={showReportHandler}
              />
            </div>
          </div>
        </FixedTableHeader>
        {!isContent ? (
          <ElementsNone
            type={ElementsNoneType.CAMPAIGNS}
            search={list.length === 0 && serverList && serverList.length > 0}
            campaignFilters={
              <FilterResult
                data={filters}
                removeItem={handleRemoveFilterItem}
                clearAll={handleClearAllFilterItem}
              />
            }
          />
        ) : (
          <>
            <div className={css.content}>
              <div
                className={cn('container resized', {
                  extend: isThemeExtended,
                  full: isThemeFull,
                  _isStatus:
                    getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
                    !getValueFromSettingForKey('ShowBroadcastStatus'),
                })}
              >
                <div
                  className={cn(css.table, {
                    _isWheek: onlyCurrent,
                  })}
                >
                  <FilterResult
                    data={filters}
                    removeItem={handleRemoveFilterItem}
                    clearAll={handleClearAllFilterItem}
                  />
                  {showReport && (
                    <div
                      className={cn(css.report, {
                        _isShow: showReport,
                      })}
                    >
                      {isLoadingCampaigns ||
                      chartDataIsLoading ||
                      isLoadingChartView ? (
                        <LoadingReportChart />
                      ) : (
                        <ReportChart
                          chartDataLegend={chartLegend}
                          onClickChartLegend={onClickChartLegend}
                          dataForChart={dataForChart}
                          series={series}
                          namingItem={namingItem}
                          showReportHandler={showReportHandler}
                        />
                      )}
                    </div>
                  )}
                  <div
                    className={cn(css.tableHeader, {
                      extend: isThemeExtended || isThemeFull,
                      _isStatus: !getValueFromSettingForKey(
                        'ShowBroadcastStatus',
                      ),
                    })}
                  >
                    <div className={css.tabelHeaderLeft}>
                      <OnlyOwner>
                        <Checkbox
                          label=""
                          className={css.checkbox}
                          inputAttributes={{
                            type: 'checkbox',
                            checked: getCampaignsChecked.all,
                            onChange: () =>
                              handlerAllCheckedCampaigns(
                                getCampaignsChecked.all ||
                                  getCampaignsChecked.partially,
                              ),
                            disabled: filter === 'DELETED',
                          }}
                          onlyBox
                          isPartiallyChecked={
                            getCampaignsChecked.partially &&
                            !getCampaignsChecked.all
                          }
                        />
                      </OnlyOwner>
                      <div className={css.filter}>
                        <img src={allSvg} alt="all" />
                      </div>
                      <TableFilter
                        title={filterColumns[0].name}
                        data={filterColumns[0].data}
                        handleSort={handlerCampaign}
                        sort={sortCampaign}
                        activeKey={activeKeyCampaign}
                        isShow
                      />
                    </div>
                    <ScrollSyncNode
                      group="a"
                      onScroll={(e: any) => {
                        setCurentScrollPosition(e.target.scrollLeft);
                      }}
                    >
                      <div
                        className={cn(
                          css.statBlock,
                          `length-${filterColumns.length - 1}`,
                        )}
                        ref={filtersContainerRef}
                      >
                        {filterColumns.map(
                          ({ data, name, lengthTitle }, index) =>
                            index > 0 && (
                              <div
                                key={name}
                                className={cn(
                                  css.statBlock_item,
                                  `size-${lengthTitle}`,
                                  {
                                    isHide:
                                      name.trim().toLocaleLowerCase() === 'bid',
                                  },
                                )}
                              >
                                <TableFilter
                                  key={name}
                                  title={checkedLocalization(
                                    `campaigns_page.campaigns_header_main.${data}`,
                                    name,
                                  )}
                                  data={data}
                                  handleSort={handlerCampaign}
                                  sort={sortCampaign}
                                  activeKey={activeKeyCampaign}
                                  isShow
                                />
                              </div>
                            ),
                        )}
                      </div>
                    </ScrollSyncNode>
                    <div className={css.tableHeaderRight}>
                      {getValueFromSettingForKey('ShowBroadcastStatus') !==
                        null &&
                        !fixed &&
                        !getValueFromSettingForKey('ShowBroadcastStatus') && (
                          <div className={cn(css.item, 'status')}>
                            <Tooltip
                              title={
                                isLoadingTranslation || isLoadingCampaigns ? (
                                  <img
                                    src={filterSvg}
                                    className={cn('icon', css.filterList)}
                                    alt="filter all"
                                  />
                                ) : (
                                  <TranslationsListFilter
                                    currentFilter={currentFilter}
                                    filterHandler={filterCreoTranslation}
                                    showReport={showReport}
                                  />
                                )
                              }
                              white
                              hintWidth={260}
                              bottom={false}
                              hideHint={!getValueFromSettingForKey('HideHelp')}
                            >
                              <span> {t(`translations.title_hint_camp`)}</span>
                              <a
                                href="https://terratraf.readme.io/docs/td-translation-status"
                                target="_blank"
                                rel="noreferrer"
                              >
                                {t(`translations.title_hint_link`)}
                                <FiExternalLink color="#025DFF" />
                              </a>
                            </Tooltip>
                          </div>
                        )}
                      <div className={css.actions} />
                    </div>
                  </div>
                  <div
                    className={cn(css.totalStats, {
                      extend: isThemeExtended || isThemeFull,
                      _isStatus: !getValueFromSettingForKey(
                        'ShowBroadcastStatus',
                      ),
                    })}
                  >
                    {isLoading ||
                    isLoadingCampaigns ||
                    isLoadingStata ||
                    isLoadingWeek ||
                    isLoadingTotal ? (
                      <LoadingCampaignsTotal filterColumns={filterColumns} />
                    ) : (
                      <>
                        <CampaignsTotal
                          totalData={campaignTotal?.total}
                          filterColumns={filterColumns}
                          sortCampaign={sortCampaign}
                          settings={settings}
                        />
                        <div className={css.actions} />
                      </>
                    )}
                  </div>
                  {isLoading || isLoadingSearch ? (
                    <div className={css.body}>
                      <PreloaderList type="campaign" filterColumns={filterColumns}/>
                    </div>
                  ) : (
                    <div
                      className={cn(css.body, 'table-body', {
                        full: isThemeExtended || isThemeFull,
                      })}
                    >
                      {list.map((e) => (
                        <Element name={e.xxhash} key={e.xxhash}>
                          <Item
                            item={e}
                            client_id={idFromURL || ''}
                            key={e.xxhash + currentFilter}
                            onlyCurrent={onlyCurrent}
                            paramsCampaignsStatus={filter}
                            isLastEdited={lastId === e.xxhash}
                            setToggle={setToggle}
                            toggle={toggle}
                            periodType={settings.type}
                            isOpenModalArchive={isOpenModalArchive}
                            setOpenModalArchive={setOpenModalArchive}
                            handleChangeArchive={handleChangeArchive}
                            openDelete={openDelete}
                            setOpenDelete={setOpenDelete}
                            statAndTranlationData={statAndTranlationData}
                            handleChangeCampaignStatus={
                              handleChangeCampaignStatus
                            }
                            handleRemoveCampaigns={handleRemoveCampaigns}
                            isFull={isThemeExtended || isThemeFull}
                            handlerCampaignChecked={handlerCampaignChecked}
                            defineCheckedCampaign={defineCheckedCampaign}
                            filterColumns={filterColumns}
                            sortCampaign={sortCampaign}
                          />
                        </Element>
                      ))}
                      {list.length === 0 &&
                        currentFilter !== 'isAll' &&
                        filters.length === 0 && (
                          <ElementsNone
                            customImg={emptyTranslationResult}
                            customTitle={t(`translations.no_results_status`)}
                          />
                        )}
                      {list.length === 0 && !!filters.length && (
                        <ElementsNone
                          type={ElementsNoneType.CAMPAIGNS}
                          search
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
              {list.length !== 0 && !filters.length && (
                <div
                  className={cn(css.scroll, {
                    _isFullScreen: isThemeExtended || isThemeFull,
                  })}
                >
                  <div
                    className={cn('container resized', {
                      extend: isThemeExtended,
                      full: isThemeFull,
                    })}
                  >
                    <div className={css.scroll_wrapper}>
                      <div className={css.scroll_left} />
                      <ScrollSyncNode group="a">
                        <div
                          className={cn(
                            css.scroll_line,
                            `length-${filterColumns.length - 1}`,
                          )}
                          ref={scrollBlockRef}
                        >
                          {filterColumns.map(
                            (item, index) =>
                              index > 0 && (
                                <div
                                  key={item.data}
                                  className={`size-${item.lengthTitle}`}
                                />
                              ),
                          )}
                        </div>
                      </ScrollSyncNode>
                      <div className={css.scroll_right} />
                    </div>
                  </div>
                </div>
              )}

              {filter !== 'DELETED' && !!checkedGroup.length && (
                <ModalSettings closeOutside={!!checkedGroup.length}>
                  {filter !== 'LAUNCHED' && (
                    <div
                      onClick={() => handlerClickStatusCampaignEdit('LAUNCHED')}
                    >
                      <ToggleSwitch
                        checked
                        activateText={t('start')}
                        deactivateText={t('start')}
                        mini
                      />
                    </div>
                  )}

                  {filter !== 'STOPPED' && filter !== 'ARCHIVE' && (
                    <div
                      onClick={() => handlerClickStatusCampaignEdit('STOPPED')}
                    >
                      <ToggleSwitch
                        checked={false}
                        activateText={t('stop')}
                        deactivateText={t('stop')}
                        mini
                      />
                    </div>
                  )}

                  {filter !== 'ARCHIVE' && (
                    <div
                      onClick={() => handlerClickStatusCampaignEdit('ARCHIVE')}
                    >
                      <ReactSVG src={toArchiveSvg} size={18} className="icon" />
                      {t('archive_title_to')}
                    </div>
                  )}

                  {filter === 'ARCHIVE' && (
                    <div
                      onClick={() => handlerClickStatusCampaignEdit('STOPPED')}
                    >
                      <ReactSVG
                        src={fromArchiveSvg}
                        size={18}
                        className="icon"
                      />
                      {t('archive_title_from')}
                    </div>
                  )}

                  <div
                    onClick={() => handlerClickStatusCampaignEdit('DELETED')}
                  >
                    <FiTrash2 size={18} />
                    {t(`delete_btn`)}
                  </div>
                  {user?.partner.type !== 'CLIENT' && (
                    <>
                      <div onClick={() => setIsModalCopy(true)}>
                        <ReactSVG className="icon" src={CopySvg} />
                        {t(`campaigns_page.mass_copy`)}
                      </div>
                    </>
                  )}
                  {idFromURL && (
                    <div
                      className={css.settings__item}
                      onClick={() => {
                        if (isOwner || isSelfUser) {
                          handlerClickStatusCampaignEdit('DUPLICATE');
                        }
                      }}
                    >
                      <ReactSVG src={DuplicateSvg} />
                      {t(`duplicate_btn`)}
                    </div>
                  )}
                </ModalSettings>
              )}
            </div>
          </>
        )}

        {isModalAllSettingGroup && (
          <OnlyOwner>
            <ModalWithQuestion
              onClose={() => setIsModalAllSettingGroup(false)}
              onClick={handlerUpdateCampaignsGroup}
              subText={generateTextIsModalText().text}
              title={clientTitle}
              btnTitle={generateTextIsModalText().button}
              modalName={t('just_words.confirm_action')}
            />
          </OnlyOwner>
        )}
        {isModalCopy && user && (
          <CopyModal
            from={checkedGroup}
            xxhashParent={idFromURL ?? ''}
            copyStep="Agency"
            xxhash={xxhashWithCopyModal}
            onCloseModal={() => setIsModalCopy(false)}
            subTitle={t(`campaigns_page.campaigns_copy_modal.select_comp`)}
            handlerCopy={handlerCopyCampaignsOtherClient}
          />
        )}
      </div>
    </ScrollSync>
  );
};
export default connect(selectorCampaigns)(Campaigns);
