import Portal from 'components/Portal';
import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import css from './styles.module.scss';
import { useShowMorePopup } from '../hook/useShowMorePopup';

interface IShowMorePopupProps {
  mainRef: React.MutableRefObject<HTMLDivElement | null>;
  list?: ReactNode;
}

const ShowMorePopup: FC<IShowMorePopupProps> = ({ mainRef, list }) => {
  const { hintRef, fixStyles, positionLeft, positionBottom } = useShowMorePopup(
    { mainRef },
  );

  return (
    <Portal className="ShowMorePopup">
      <div
        ref={hintRef}
        className={cn(css.showMorePopup, {
          fixTopPadding: fixStyles,
        })}
        style={{
          left: `${positionLeft}px`,
          top: `${positionBottom}px`,
        }}
      >
        <div className={css.showMorePopup_list}>{list}</div>
      </div>
    </Portal>
  );
};

export default ShowMorePopup;
