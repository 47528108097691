import React, { FC } from 'react';
import NavigationHeader from 'components/NavigationHeader';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router';
import { TID } from 'types/general';
import { someGroups } from 'utils/statics/everyGroups';
import NoAccess from 'domains/app/Errors/noAccess';
import { useUserInfo } from 'domains/user/model/selectors';
import { useProjectInfo } from 'domains/project/hooks';
import CreateContract from './CreateContract';
import EditContract from './EditContract';

const Contract: FC = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch<TID>();
  const { data: user } = useUserInfo();
  const { data: project } = useProjectInfo()

  return (
    <>
      <NavigationHeader
        back={{
          title: t('campaigns_page.create_campaign.black_header.back'),
          to: '/main/contracts',
        }}
      />
      <Switch>
        <Route
          path={`${path}/create`}
          component={
            someGroups(user, 'owner', 'self_user') &&
            (user?.partner.type === 'AGENCY' ||
              user?.partner.type === 'ORGANIZATION') &&
            project?.ord_registration
              ? CreateContract
              : NoAccess
          }
        />
        <Route
          path={`${path}/:id/edit`}
          component={
            someGroups(user, 'owner', 'self_user') &&
            (user?.partner.type === 'AGENCY' ||
              user?.partner.type === 'ORGANIZATION') &&
            project?.ord_registration
              ? EditContract
              : NoAccess
          }
        />
      </Switch>
    </>
  );
};

export default Contract;
