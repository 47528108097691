import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyImg from './icons/emptyLink.svg';
import css from './styles.module.scss';

const EmptyLink: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={css.EmptyLink}>
      <img src={EmptyImg} alt="EmptyLink" />
      <span>{t(`common.empty_data`)}</span>
    </div>
  );
};

export default EmptyLink;
