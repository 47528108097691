import { shallowEqual, useSelector } from 'react-redux';
import { useDispatchApp } from 'redux/rootSelectors';
import { SearchState, setIsShowModal, setTempSearchDefault } from '../reducer';
import { AppState } from '../../../../redux/rootReducer';
import { fetchSearchRequest } from '../actions';
import { useSearchInfo, useSearchShowModalInfo } from '../selectors';

type TFUseSearchStateInfo = () => {
  isShowModal: boolean;
  closeModal: () => void;
  search: ReturnType<typeof useSearchInfo>['data'];
  isLoading: ReturnType<typeof useSearchInfo>['isLoading'];
  error: ReturnType<typeof useSearchInfo>['error'];
  LTU: ReturnType<typeof useSearchInfo>['LTU'];
  fetchSearch: (p: string) => void;
  tempSearchDefault: () => void;
};

type TFUseCampaignsTags = () => {
  tagsCampaigns: string[];
  tagCampaignsActive: string[];
};

export const useSearchStateInfo: TFUseSearchStateInfo = () => {
  const dispatch = useDispatchApp();

  const { LTU, error, data: search, isLoading } = useSearchInfo();

  const isShowModal = useSearchShowModalInfo();

  const closeModal = () => {
    dispatch(setIsShowModal(false));
  };

  const fetchSearch: ReturnType<TFUseSearchStateInfo>['fetchSearch'] = (id) => {
    dispatch(fetchSearchRequest({ id }));
  };
  const tempSearchDefault = () => {
    dispatch(setTempSearchDefault());
  };

  return {
    isShowModal,
    closeModal,
    search,
    isLoading,
    error,
    LTU,
    fetchSearch,
    tempSearchDefault,
  };
};

/** Возвращает состояние тегов */
export const useCampaignsTags: TFUseCampaignsTags = () => {
  const { tagCampaignsActive, tagsCampaigns } = useSelector<
    AppState,
    SearchState
  >(({ searchReducer }) => searchReducer, shallowEqual);
  return {
    tagsCampaigns,
    tagCampaignsActive,
  };
};
