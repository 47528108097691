/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { components } from 'react-select';
import css from './styles.module.scss';

const LimitedChipsContainer = ({
  children,
  hasValue,
  ...props
}: any): JSX.Element => {
  if (!hasValue) {
    return (
      <components.ValueContainer
        {...props}
        className="multi_select__value-container-empty"
      >
        {children}
      </components.ValueContainer>
    );
  }

  const CHIPS_LIMIT = props.selectProps.chipsLimit || 2;
  const [chips, otherChildren] = children;
  const overflowCounter = chips.slice(CHIPS_LIMIT).length;

  const displayChips = chips.slice(
    overflowCounter,
    overflowCounter + CHIPS_LIMIT,
  );

  return (
    <components.ValueContainer {...props} className={css.container}>
      {displayChips}

      {overflowCounter > 0 && (
        <span className={css.incriment}>+{`${overflowCounter}`}</span>
      )}

      {otherChildren}
    </components.ValueContainer>
  );
};

export default LimitedChipsContainer;
