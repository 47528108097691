import React, { useMemo, FC, ChangeEvent, ChangeEventHandler } from 'react';
import RSelect from 'react-select';
import { Form, FormikProvider } from 'formik';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Button from 'components/UI/Buttons/buttons';
import Input from 'components/UI/Input/input';
import Loader from 'components/UI/Loader';
import CardsList from 'components/UI/CardList';
import ToggleSwitch from 'components/UI/ToggleSwitch';
import Card from 'components/UI/Card';
import Checkbox from 'components/UI/Checkbox/checkbox';
import { isDisableEridToggle } from 'utils/isDisableEridToggle';
import { getCurrentVariableField } from 'utils/getCurrentVariableField';
import Help from 'components/Help';
import PriceInput from 'components/UI/PriceInput';
import CollapseCard from 'components/UI/CollapseCard';
import SelectV2 from '../../../components/UI/SelectV2';
import css from './styles.module.scss';
import { useSRMethod, useCampaignSettigns } from './hooks';
import ErrorReport from '../../../components/Errors/Reports';
import HeaderCampaign from '../HeaderCampaign';
import { TExperimentalInfoField, TReportItem } from '../types';
import { useCampaignInfo } from '../model/selectors';
import { useCampaignHint } from '../model/hooks/useCampaignHint';
import { useCampaignExperimental } from '../Experimental/hooks';
import VariableNumberField from './VariableNumberField';
import Widget from './Widget';
import ExtendedReport from './ExtendedReport';
import Reports from './Reports';
import LinkingExternal from './LinkingExternal';
import './style.scss';

const Settings: FC = () => {
  const { t } = useTranslation();

  const { data: campaign, isLoading, error } = useCampaignInfo();

  const {
    SRMethod,
    listSRMethods,
    onChangeSRMethod,
    optTag,
    onChangeText,
    aggregationTag,
    onChangeAggregation,
  } = useSRMethod(campaign);

  const { experimentalInfo } = useCampaignExperimental();

  const cpcData = useMemo(
    () => getCurrentVariableField(experimentalInfo, 'max_cpc'),
    [experimentalInfo],
  );

  const marginalityData = useMemo(
    () => getCurrentVariableField(experimentalInfo, 'marginality'),
    [experimentalInfo],
  );

  const createConfigIdArr = (
    arr: (TExperimentalInfoField | undefined)[],
  ): number[] => {
    const resultArr: number[] = [];
    arr.forEach((item) => item && resultArr.push(item.id));
    return resultArr;
  };

  const configValuesIds = useMemo(
    () => createConfigIdArr([cpcData, marginalityData]),
    [cpcData, marginalityData],
  );

  const {
    formik,
    isLoadingEdit,
    commissionAgencyData,
    commissionSelfServiceData,
    audiences,
    setCurrentAudit,
  } = useCampaignSettigns({
    SRMethod,
    aggregationTag,
    configValuesIds,
    marginalityId: marginalityData?.id,
  });

  const { showHint, closeHint } = useCampaignHint(
    formik.values.hidden_statistic,
  );

  const componentsSelect: {
    IndicatorSeparator: FC;
    DropdownIndicator: FC;
    Menu?: FC;
  } = useMemo(() => {
    if (optTag.length > 0) {
      return {
        IndicatorSeparator: () => null,
        DropdownIndicator: () => null,
      };
    }
    return {
      IndicatorSeparator: () => null,
      DropdownIndicator: () => null,
      Menu: () => null,
    };
  }, [optTag.length]);

  if (error.isError) {
    return <ErrorReport error={error} />;
  }

  if (isLoading && !error.isError) {
    return <Loader />;
  }

  const convertReports = ({ is_enabled }: TReportItem) => {
    if (is_enabled) {
      return t('clients_page.client_edit.included');
    }

    return t('clients_page.client_edit.turned_off');
  };

  const onKeyHandler = (keyboardEvent: React.KeyboardEvent) => {
    const { keyCode } = keyboardEvent;
    if (keyCode === 13) {
      keyboardEvent.preventDefault();
    }
  };

  // eslint-disable-next-line consistent-return
  const handlerAdditionalMetrics: ChangeEventHandler<HTMLInputElement> = (
    e,
    // eslint-disable-next-line consistent-return
  ) => {
    const { value } = e.target;
    const pattern = /^[0-9\s.\n]*$/;
    if (pattern.test(value)) {
      return formik.setFieldValue('additional_metrics', value);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {t('campaigns_page.campaign_settings.additional.set_tit')}
        </title>
      </Helmet>
      <HeaderCampaign campaign={campaign} isLoading={isLoading} />
      <div className={cn(css.container, 'container')}>
        <FormikProvider value={formik}>
          <CardsList>
            <Form>
              <Card
                noHover
                className={css.card}
                showHint={showHint}
                closeHint={closeHint}
                positionHint={250}
                textHint={
                  <>
                    <strong>
                      {t(
                        'campaigns_page.campaign_settings.additional.hide_real_statistics.hintStrong',
                      )}
                    </strong>{' '}
                    <span>
                      {t(
                        'campaigns_page.campaign_settings.additional.hide_real_statistics.hintSpan',
                      )}
                    </span>
                  </>
                }
              >
                <CollapseCard
                  closedStart
                  noUpdateHeight
                  title={
                    <h3 className={css.title}>
                      {t('campaigns_page.campaign_settings.additional.method')}
                    </h3>
                  }
                >
                  <div className={css.form}>
                    {campaign && (
                      <div>
                        <div className={css.label}>
                          {t(
                            'campaigns_page.campaign_settings.additional.type',
                          )}
                        </div>
                        <div className={cn(css.info, 'isUppercase')}>
                          {campaign.pay_type}
                        </div>
                      </div>
                    )}
                    <SelectV2
                      value={SRMethod}
                      label={t(
                        'campaigns_page.campaign_settings.ListSRMethods.label',
                      )}
                      placeholder={t(
                        'campaigns_page.campaign_settings.additional.ddmm',
                      )}
                      options={listSRMethods || []}
                      customChange={onChangeSRMethod}
                    />
                    {cpcData && SRMethod === 'MAX_FIX_CPC_MARGINALITY' && (
                      <VariableNumberField
                        decimalScale={cpcData.type === 'float' ? 3 : 0}
                        fieldData={cpcData}
                        formik={formik}
                        onKeyHandler={onKeyHandler}
                      />
                    )}
                    {SRMethod === 'SELF_SERVICE_MARKUP' && (
                      <VariableNumberField
                        decimalScale={4}
                        fieldData={commissionAgencyData}
                        formik={formik}
                        onKeyHandler={onKeyHandler}
                      />
                    )}
                    {SRMethod === 'SELF_SERVICE_MARKUP' && (
                      <VariableNumberField
                        decimalScale={4}
                        fieldData={commissionSelfServiceData}
                        formik={formik}
                        onKeyHandler={onKeyHandler}
                      />
                    )}

                    {marginalityData &&
                      SRMethod === 'MAX_FIX_CPC_MARGINALITY' && (
                        <VariableNumberField
                          decimalScale={
                            marginalityData.type === 'float' ? 3 : 0
                          }
                          fieldData={marginalityData}
                          formik={formik}
                          onKeyHandler={onKeyHandler}
                        />
                      )}
                    {SRMethod === 'MAX_FIX_CPC_MARGINALITY' && (
                      <div className={css.field_item}>
                        <PriceInput
                          classname={css.number_format}
                          decimalScale={0}
                          theme="horizontal"
                          label={t(
                            'campaigns_page.campaign_settings.ListSRMethods.hidden_coef.label',
                          )}
                          value={formik.values.hidden_commissions}
                          onKeyDown={onKeyHandler}
                          error={!!formik.errors.hidden_commissions}
                          errortext={formik.errors.hidden_commissions}
                          onValueChange={({ floatValue }) => {
                            formik.setFieldValue(
                              'hidden_commissions',
                              floatValue || 0,
                            );
                          }}
                        />
                        <Help
                          title=""
                          helpattributes={{
                            text: `Процент скрытой комиссии. Влияет на расчет маржи \nMargin= (spent - minus_win_raw- spent*СК(в процентах))`,
                          }}
                        />
                      </div>
                    )}
                    <Checkbox
                      label={t(
                        'campaigns_page.campaign_settings.additional.hide_real_statistics.title',
                      )}
                      inputAttributes={{
                        type: 'checkbox',
                        name: 'hidden_statistic',
                        checked: formik.values.hidden_statistic,
                        onChange: () =>
                          formik.setFieldValue(
                            'hidden_statistic',
                            !formik.values.hidden_statistic,
                          ),
                      }}
                    />
                    {SRMethod === 'COEFFICIENTS' && (
                      <Input
                        label={t(
                          'campaigns_page.campaign_settings.additional.coefficient',
                        )}
                        isTextarea
                        inputAttributes={{
                          type: 'textarea',
                          name: 'statistic_coefficient',
                          // eslint-disable-next-line consistent-return
                          onChange: (e: ChangeEvent<HTMLTextAreaElement>) => {
                            const { value } = e.target;
                            const pattern = /^[0-9\s.\n]*$/;
                            if (pattern.test(value)) {
                              return formik.setFieldValue(
                                'statistic_coefficient',
                                value,
                              );
                            }
                          },
                          placeholder: t(
                            'campaigns_page.campaign_settings.ListSRMethods.enter_data',
                          ),
                          value: formik.values.statistic_coefficient,
                          style: { minHeight: 256 },
                        }}
                        tooltip={{
                          text: (
                            <span style={{ color: '#828282' }}>
                              <span style={{ color: '#222222' }}>
                                {t(
                                  'campaigns_page.campaign_settings.click_hint.hint1',
                                )}
                              </span>{' '}
                              {t(
                                'campaigns_page.campaign_settings.click_hint.hint2',
                              )}{' '}
                              <span style={{ color: '#222222' }}>
                                {t(
                                  'campaigns_page.campaign_settings.click_hint.hint4',
                                )}{' '}
                              </span>
                              <br />
                              <span style={{ color: '#222222' }}>
                                {t(
                                  'campaigns_page.campaign_settings.click_hint.hint6',
                                )}
                              </span>
                            </span>
                          ),
                        }}
                      />
                    )}
                    {SRMethod !== 'MAX_FIX_CPC_MARGINALITY' && (
                      <Input
                        label={t(
                          'campaigns_page.campaign_settings.additional.clicks',
                        )}
                        isTextarea
                        inputAttributes={{
                          name: 'additional_metrics',
                          placeholder: t(
                            'campaigns_page.campaign_settings.enter_data_tip',
                          ),
                          onChange: handlerAdditionalMetrics,
                          style: { minHeight: 246 },
                          value: formik.values.additional_metrics,
                        }}
                        tooltip={{
                          text: (
                            <span style={{ color: '#828282' }}>
                              <span style={{ color: '#222222' }}>
                                {t(
                                  'campaigns_page.campaign_settings.click_hint.hint1',
                                )}
                              </span>{' '}
                              {t(
                                'campaigns_page.campaign_settings.click_hint.hint2',
                              )}{' '}
                              <span style={{ color: '#222222' }}>
                                {t(
                                  'campaigns_page.campaign_settings.click_hint.hint3',
                                )}{' '}
                              </span>
                              {t(
                                'campaigns_page.campaign_settings.click_hint.hint2',
                              )}{' '}
                              <span style={{ color: '#222222' }}>
                                {t(
                                  'campaigns_page.campaign_settings.click_hint.hint4',
                                )}{' '}
                              </span>
                              <br />
                              <span style={{ color: '#222222' }}>
                                {t(
                                  'campaigns_page.campaign_settings.click_hint.hint5',
                                )}
                              </span>
                            </span>
                          ),
                        }}
                      />
                    )}
                    <div className={css.tags}>
                      <div className={css.label}>
                        {t(
                          'campaigns_page.campaign_settings.additional.aggregation',
                        )}
                      </div>
                      <RSelect
                        placeholder={t(
                          'campaigns_page.campaign_settings.additional.ddmm',
                        )}
                        classNamePrefix="tags-select"
                        value={aggregationTag}
                        onChange={onChangeAggregation}
                        isSearchable
                        name="tags"
                        options={optTag}
                        onInputChange={onChangeText}
                        isClearable
                        components={componentsSelect}
                      />
                    </div>
                    <div>
                      <div className={css.label}>
                        {t('clients_page.client_edit.moderation_creatives')}
                      </div>
                      <ToggleSwitch
                        disabled={isDisableEridToggle(campaign, 'CAMPAIGN')}
                        onChange={() =>
                          formik.setFieldValue(
                            'check_ERID',
                            !formik.values.check_ERID,
                          )
                        }
                        checked={formik.values.check_ERID}
                        activateText={t('creative.widget_text.check_ERID')}
                        deactivateText={t('creative.widget_text.check_ERID')}
                      />
                    </div>
                  </div>
                </CollapseCard>
              </Card>
              <LinkingExternal formik={formik} />
              <Reports
                formik={formik}
                convertReports={convertReports}
                audiences={audiences}
                setCurrentAudit={setCurrentAudit}
                campaign={campaign}
              />
              <ExtendedReport formik={formik} />
              <div className={css.actions}>
                <Button
                  title={t('save_btn')}
                  height={40}
                  buttonAttributes={{
                    type: 'submit',
                    disabled: isLoadingEdit || !formik.isValid,
                  }}
                />
              </div>
            </Form>
          </CardsList>
        </FormikProvider>
        <Widget />
      </div>
    </>
  );
};

export default Settings;
