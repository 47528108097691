import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ElementsNoneType } from 'types/general';
import css from './styles.module.scss';
import { ElementsNoneImages } from './Images';
import ClientNone from './ClientNone';
import AgenciesNone from './AgenciesNone';
import СampaignsNone from './СampaignsNone';
import CreativeNone from './CreativeNone';
import OffersNone from './OffersNone';
import ContractorsNone from './ContractorsNone';
import ContractsNone from './ContractsNone';

type TElementsNoneProps = {
  /* От этого параметра меняетсятся тип отображения */
  type?: string;
  /* От этого параметра меняетсятся текст */
  search?: boolean | null;
  /* Фильтры перед списком кампаний */
  campaignFilters?: React.ReactNode;
  customTitle?: string;
  customImg?: string;
  status?: string;
};

const switchEmptyImage = (type?: string, search?: boolean | null) => {
  if (search) {
    return ElementsNoneImages.no_search;
  }
  if (type === ElementsNoneType.HISTORY) {
    return ElementsNoneImages.history_search;
  }
  if (type === ElementsNoneType.CONTAINERS) {
    return ElementsNoneImages.containers_empty;
  }

  return ElementsNoneImages.general;
};

const ElementsNone: FC<TElementsNoneProps> = ({
  type,
  search,
  customTitle,
  customImg,
  campaignFilters,
  status = '',
}) => {
  const { t } = useTranslation();

  const switchEmptyTitle = (target?: string) => {
    if (target === ElementsNoneType.CONTAINERS) {
      return t('containers_page.containers_none');
    }

    return t(`common.empty_data`);
  };

  if (type === ElementsNoneType.CLIENTS) {
    return <ClientNone search={search} />;
  }

  if (type === ElementsNoneType.CAMPAIGNS) {
    return <СampaignsNone search={search} campaignFilters={campaignFilters} />;
  }

  if (type === ElementsNoneType.AGENCIES) {
    return <AgenciesNone search={search} />;
  }

  if (type === ElementsNoneType.CREATIVE) {
    return <CreativeNone search={search} />;
  }

  if (type === ElementsNoneType.OFFERS) {
    return <OffersNone search={search} status={status} />;
  }

  if (type === ElementsNoneType.CONTRACTS) {
    return <ContractsNone search={search} status={status} />;
  }

  if (type === ElementsNoneType.COUNTERAGENTS) {
    return <ContractorsNone search={search} />;
  }

  return (
    <div className={css.empty}>
      <div className={css.img}>
        <img src={customImg || switchEmptyImage(type, search)} alt={type} />
      </div>
      {search ? (
        <div className={css.search}>
          <div className={css.header}>{`${t('search_none.none_1')}`}</div>
          <div className={css.text}>
            <span>{`${t('search_none.none_2')}`}</span>
            <span>{`${t('search_none.none_3')}`}</span>
          </div>
        </div>
      ) : (
        <div className={css.title}>
          <span>{customTitle || switchEmptyTitle(type)}</span>
        </div>
      )}
    </div>
  );
};

export default ElementsNone;
