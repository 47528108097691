import request from '../../../lib/helpers/requestApi';
import { TInternalList, TKEY } from '../../../types/general';

export type TTypeImage = {
  type:
    | 'project'
    | 'teaser'
    | 'templateImage'
    | 'push'
    | 'multiFormat'
    | 'client'
    | 'zip'
    | 'zip_banner'
    | 'zip_yandex_htm'
    | 'yandex_html'
    | 'pretarget'
    | 'video';
};

export const getUploadFileUrl = request<string, TTypeImage>({
  method: 'upload.getUploadFileUrl',
});

export const getBusinessSphere = request<TInternalList, TKEY>({
  method: 'internalList.getByKey',
});

type TReplacementObj = {
  width: number;
  height: number;
  imageType: string;
  resolution: string;
  size: number;
  originalName: string;
  extension: string;
  type: string;
  name: string;
  rawName: string;
  file_url: string;
};

type TRepalcementField = Record<string, TReplacementObj>;

type TTemplateParams = {
  template_id: number;
  replacement_fields?: TRepalcementField;
};

export const buildTemplate = request<string, TTemplateParams>({
  method: 'creative.buildByTemplate',
});
