import React, { FC } from 'react';
import LoadingItem from './LoadingItem';
import css from '../../Group/styles.module.scss';

const Loadinggroup: FC = () => (
  <div className={css.loading}>
    <div className={css.loading_title} />
    <div className={css.loading_parametrs} />
    <div className={css.loading_items}>
      <LoadingItem />
      <LoadingItem />
    </div>
    <div className={css.loading_actions} />
  </div>
);

export default Loadinggroup;
