import { SagaIterator } from '@redux-saga/core';
import { takeLatest } from 'redux-saga/effects';
import {
  fetchActivityUsers,
  fetchManagementUsers,
  fetchUsersOnline,
  createNewUser,
  editUser,
  resetPasswordRequest,
  sendConfirmationEmail,
} from '../actions';
import {
  sortActiveUsersWorker,
  workerFetchActivityUsers,
  workerFetchManagementUsers,
  workerFetchUserOnline,
  workerUserCreateSaga,
  workerUserEditSaga,
  resetPasswordWorker,
  sendConfirmationEmailWorker,
} from './workers';
import { setUsersActivitySort } from '../reducer';

export default function* watchManagementUsers(): SagaIterator {
  yield takeLatest(fetchManagementUsers, workerFetchManagementUsers);
  yield takeLatest(fetchUsersOnline, workerFetchUserOnline);
  yield takeLatest(fetchActivityUsers, workerFetchActivityUsers);
  yield takeLatest(setUsersActivitySort, sortActiveUsersWorker);
  yield takeLatest(createNewUser, workerUserCreateSaga);
  yield takeLatest(editUser, workerUserEditSaga);
  yield takeLatest(resetPasswordRequest, resetPasswordWorker);
  yield takeLatest(sendConfirmationEmail, sendConfirmationEmailWorker);
}
