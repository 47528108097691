import React, { useState, useRef, FC } from 'react';
import cn from 'classnames';
import { FiSearch, FiX } from 'react-icons/fi';
import css from './styles.module.scss';

type Props = {
  placeholder?: string;
  className?: string;
  value: string;
  toggleSearch: (val: boolean) => void;
  setSearch: (val: string) => void;
};

const Search: FC<Props> = ({
  placeholder,
  className,
  toggleSearch,
  setSearch,
  value,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const onChange = (event: React.ChangeEvent) => {
    setSearch((event.target as HTMLInputElement).value);
  };

  const onSetOpen = (val: boolean) => {
    setOpen(val);
    toggleSearch(val);
    if (inputRef !== null) {
      if (val && inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  return (
    <div className={cn(css.search, className, { isOpen: open })}>
      <FiSearch
        className={css.open}
        size={20}
        color="#0a0a0a"
        onClick={() => onSetOpen(true)}
      />
      <input
        className={css.input}
        type="text"
        autoComplete="off"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        ref={inputRef}
      />
      <FiX
        className={css.close}
        size={24}
        color="#828282"
        onClick={() => {
          onSetOpen(false);
          setSearch('');
        }}
      />
    </div>
  );
};

export default Search;
