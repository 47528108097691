import { SagaIterator } from '@redux-saga/core';
import { call, put, SagaReturnType, select } from 'redux-saga/effects';
import i18next from 'i18next';
import { toastSuccessBold } from 'utils/ToastUtils/toastSuccessBold';
import {
  fetchAgencyInfo,
  fetchLoadAgencyTempFile,
  setTempImage,
  setAgencyInfo,
  createAgency,
  setLastAddAgency,
  editAgency,
  setEditAgency,
  setClientsStat,
  fetchClientsStat,
  fetchAuditDictionaryList,
  setAuditDictionaryList,
  fetchAddAuditDictionary,
  fetchEditAuditDictionary,
  fetchStatisticByAudit,
  setStatisticByAudit,
} from '../reducer';
import { genFetchedData } from '../../../redux/fetchedData';
import uploadImage, {
  isTUploadErr,
  TResult,
  TUploadErr,
  TUploadFile,
} from '../../../utils/upload';
import updateToken from '../../../utils/updateToken';
import { errors4Image } from '../../modals/linkToImage/sagas/workers';
import { makeReqWithRD, TMakeReqWithRD } from '../../../redux/makeReqWithRD';
import { addAuditDictionary, createAgencyApi, editAgencyApi, editAuditDictionary, getAgencyInfo, getAuditDictionaryList, getClientsStatApi, getStatisticByAudit } from '../api';
import { fetchAgencies } from '../../agencies/reducer';
import { selectorAgenciesTabs } from '../../storage/model/selectors';
import {
  AgencyState,
  ParamAgencyInfo,
  ParamCreateAgency,
  ParamEditAgency,
  TClientStat,
  TGetStatisticByAudit,
} from '../types';
import { selectorAgencyGetTempImage } from '../model/selectors';
import { toastSuccess } from '../../../utils/ToastUtils/toastUtils';
import { getUploadFileUrl } from '../../uploads/model/api';

export function* workerCreateAgency({
  payload,
}: ReturnType<typeof createAgency>): SagaIterator<void> {
  let fetchedData = genFetchedData<ParamAgencyInfo>(null).set(
    'isLoading',
    true,
  );
  yield put(setLastAddAgency(fetchedData));
  try {
    const tempImage: AgencyState['tempImage'] = yield select(
      selectorAgencyGetTempImage,
    );
    const tImgData = tempImage.get('data');
    const parameters: ParamCreateAgency = { title: payload.title };
    if (payload.site) {
      parameters.site = payload.site;
    }
    if (payload.site) {
      parameters.site = payload.site;
    }
    if (tImgData !== null) {
      parameters.icon = tImgData.name;
    }
    yield call(updateToken);

    const res = yield call(createAgencyApi, parameters);
    fetchedData = fetchedData.set('data', res);

    toastSuccess(
      `${i18next.t('agencies_page.agency_create.hint1')}${i18next.t(
        'agencies_page.agency_create.hint3',
      )}`,
    );

    const status = yield select(selectorAgenciesTabs);

    yield put(fetchAgencies({ status }));
  } catch (e) {
    const code = e?.code ?? 0;
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: errors4Image.get(code) ?? e?.message ?? '',
      code,
    });
    yield put(setLastAddAgency(fetchedData));
  } finally {
    fetchedData = fetchedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setLastAddAgency(fetchedData));
  }
}

export function* workerEditAgency({
  payload,
}: ReturnType<typeof editAgency>): SagaIterator<void> {
  let fetchedData = genFetchedData<boolean>(null).set('isLoading', true);
  const { callbacks, ...restPayload } = payload
  yield put(setEditAgency(fetchedData));
  try {
    if (callbacks?.setIsLoading) {
      callbacks?.setIsLoading(true)
    }
    const tempImage: AgencyState['tempImage'] = yield select(
      selectorAgencyGetTempImage,
    );
    const tImgData = tempImage.get('data');
    yield call(updateToken);

    const params: ParamEditAgency = restPayload;
    if (params.remove_icon) {
      params.remove_icon = true;
      params.removeIcon = true;
    }
    if (tImgData !== null) {
      params.icon = tImgData.name;
    }
    const resp: SagaReturnType<typeof editAgencyApi> = yield call(
      editAgencyApi,
      params,
    );
    if (callbacks?.setIsLoading) {
      callbacks?.setIsLoading(false)
    }
    fetchedData = fetchedData.set('data', resp);
    yield put(setEditAgency(fetchedData));

    const status = yield select(selectorAgenciesTabs);

    yield put(fetchAgencies({ status }));

    toastSuccess(
      `${i18next.t('agencies_page.agency_create.hint1')}${i18next.t(
        'agencies_page.agency_create.hint2',
      )}`,
    );
  } catch (e) {
    console.error({ e });
    const code = e?.code ?? 0;
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: errors4Image.get(code) ?? e?.message ?? '',
      code,
    });
    yield put(setEditAgency(fetchedData));
  } finally {
    fetchedData = fetchedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setEditAgency(fetchedData));
  }
}

export function* workerFetchLoadTempFileAgency({
  payload,
}: ReturnType<typeof fetchLoadAgencyTempFile>): SagaIterator<void> {
  let fetchedData = genFetchedData<TUploadFile>(null).set('isLoading', true);
  yield put(setTempImage(fetchedData));
  try {
    if (payload.size > 1000000) {
      const er = { code: -20001, message: '' };
      throw er;
    }
    yield call(updateToken);
    const requestUpload: SagaReturnType<typeof getUploadFileUrl> = yield call(
      getUploadFileUrl,
      { type: 'client' },
    );
    const formData = new FormData();
    formData.set('logo', payload);

    const data: TResult<Record<string, TUploadFile>> | TUploadErr = yield call(
      uploadImage,
      requestUpload,
      formData,
    );
    const error = isTUploadErr(data) ? data.error : undefined;
    const result = isTUploadErr(data) ? undefined : data.result.logo;

    if (error !== undefined) {
      throw error;
    }

    if ((result?.size ?? 0) > 1024) {
      const er = { code: -20001, message: '' };
      throw er;
    }

    if ((result?.width ?? 0) > 2000 || (result?.height ?? 0) > 2000) {
      const er = { code: -20002, message: '' };
      throw er;
    }

    if (result !== undefined) {
      result.size *= 1000;
      fetchedData = fetchedData.set('data', result);
    }
  } catch (e) {
    console.error({ e });
    const code = e?.code ?? 0;
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: errors4Image.get(code) ?? '',
      code,
    });
    yield put(setTempImage(fetchedData));
  } finally {
    fetchedData = fetchedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setTempImage(fetchedData));
  }
}

export function* workerFetchAgencyInfo({
  payload,
}: ReturnType<typeof fetchAgencyInfo>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getAgencyInfo>>(makeReqWithRD, {
      fetcher: getAgencyInfo,
      fill: setAgencyInfo,
      parameters: payload,
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* workerFetchClientsStat({
  payload,
}: ReturnType<typeof fetchClientsStat>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getClientsStatApi>>(makeReqWithRD, {
      fetcher: getClientsStatApi,
      fill: setClientsStat,
      parameters: payload,
    });
  } catch (e) {
    if (e.code === 90) {
      const newRD = genFetchedData<TClientStat>(null)
      newRD.set('error', {
        isError: true,
        message: 'Not found',
        code: e.code
      })
      yield put(setClientsStat(newRD))
    }
    console.error({ e });
  }
}

export function* workerFetchAuditDictionaryList({
  payload,
}: ReturnType<typeof fetchAuditDictionaryList>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getAuditDictionaryList>>(makeReqWithRD, {
      fetcher: getAuditDictionaryList,
      fill: setAuditDictionaryList,
      parameters: payload,
    })
  } catch (e) {
    console.error({ e });
  }
}

export function* workerFetchAddAuditDictionary({
  payload,
}: ReturnType<typeof fetchAddAuditDictionary>): SagaIterator<void> {
  try {
    const response = yield call(addAuditDictionary, payload);
    if (response) {
      yield put(fetchAuditDictionaryList({ partner_xxhash: payload.data.partner_xxhash }));
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* workerFetchEditAuditDictionary({
  payload,
}: ReturnType<typeof fetchEditAuditDictionary>): SagaIterator<void> {
  try {
    const response = yield call(editAuditDictionary, payload);
    if (response) {
      const isDelete = localStorage.getItem('deleteAudience')
      if (isDelete) {
        toastSuccessBold(`agencies_page.report.success_del`, {
          title: payload.data.title
        });
        localStorage.removeItem('deleteAudience');
      };
      yield put(fetchAuditDictionaryList({ partner_xxhash: payload.data.partner_xxhash }));
    }

  } catch (e) {
    console.error({ e });
  }
}

export function* workerFetchStatisticByAudit({
  payload,
}: ReturnType<typeof fetchStatisticByAudit>): SagaIterator<void> {
  let fetchedData = genFetchedData<TGetStatisticByAudit>(null).set(
    'isLoading',
    true,
  );
  yield put(setStatisticByAudit(fetchedData));
  try {
    const res = yield call(getStatisticByAudit, payload)
    fetchedData = fetchedData.set('data', res);
  } catch (e) {
    console.error({ e });
  } finally {
    fetchedData = fetchedData.set('isLoading', false);
    yield put(setStatisticByAudit(fetchedData));
  }
}