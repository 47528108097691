import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchBreadcrumbsInfo } from 'domains/search/model/selectors';
import { Bread } from 'domains/search/model/types';
import { useTranslation } from 'react-i18next';
import { useDispatchApp } from '../../../redux/rootSelectors';
import { resetBalance, setPartner } from '../../../domains/campaigns/reducer';
import {
  usePartnerBalanceInfo,
  usePartnerInfo,
} from '../../../domains/campaigns/model/selectors';
import { fetchPartnerBalance } from '../../../domains/campaigns/model/actions';
import { formatCurrency } from '../../../utils/formatCurrency';

type TFUsePartnerBalance = () => {
  value: null | number;
  currency: null | string;
  isLoading: ReturnType<typeof usePartnerBalanceInfo>['isLoading'];
  name: string;
  handleFetcher: () => void;
};

export const usePartnerBalance: TFUsePartnerBalance = () => {
  const dispatch = useDispatchApp();
  const location = useLocation();

  const { data: bread } = useSearchBreadcrumbsInfo()

  const { t } = useTranslation();

  const createPartnerObj = (agency?: Bread) => ({
    name: `${t('just_words.w5')} ${agency?.title}` || '',
    xxhash: agency?.xxhash || ''
  })

  const client = usePartnerInfo();

  const partner = location.pathname.indexOf('clients') + 1 ? createPartnerObj(bread?.Agency) : client

  const { data: balance, isLoading } = usePartnerBalanceInfo();

  const handleFetcher = () => {
    if (partner) {
      dispatch(fetchPartnerBalance({ partner_xxhash: partner.xxhash }));
    }
  };

  useEffect(() => {
    if (partner) {
      if (
        !(
          location.pathname.indexOf('campaign') + 1 ||
          location.pathname.indexOf('reports') + 1 ||
          location.pathname.indexOf('clients') + 1
        )
      ) {
        dispatch(resetBalance());
        dispatch(setPartner({ name: '', xxhash: '' }));
      }
    }
  }, [location, balance]);

  return {
    value: balance ? balance.balance : null,
    currency: balance ? formatCurrency(balance.currency) : null,
    handleFetcher,
    name: partner.name,
    isLoading,
  };
};
