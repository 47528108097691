import React, { FC } from 'react';
import Load from 'components/UI/Load';
import Card from 'components/UI/Card';
import css from './styles.module.scss';

const LoadingOptimisationWidget: FC = () => (
  <Card noHover className={css.card}>
    <div className={css.optimisation_btns}>
      <Load
        style={{
          flex: '1',
          height: '48px',
        }}
      />
      <Load
        style={{
          flex: '1',
          height: '48px',
        }}
      />
    </div>
  </Card>
);

export default LoadingOptimisationWidget;
