import {
  THookSelectorFetchDataInfo,
  TSelector,
  useGenFetchDataSelector,
} from 'redux/rootSelectors';
import { HistoryState } from './reducer';
import { THistoryResponse } from './types';

export const selectorHistoryData: TSelector<HistoryState['historyData']> = ({
  historyReducer,
}) => historyReducer.historyData;

export const useHistoryDataSelector: THookSelectorFetchDataInfo<
  THistoryResponse[]
> = () => useGenFetchDataSelector(selectorHistoryData);
