import {
  THookStateInfo,
  TSelector,
  useSelectorTypes,
} from '../../../redux/rootSelectors';
import { CommentState } from './reducer';

export const selectorCommentEdited: TSelector<
  CommentState['commentEdited']
> = ({ commentReducer }) => commentReducer.commentEdited;

export const selectorCommentDeleted: TSelector<
  CommentState['commentDeleted']
> = ({ commentReducer }) => commentReducer.commentDeleted;

export const selectorIsAllComments: TSelector<
  CommentState['isShowAllComments']
> = ({ commentReducer }) => commentReducer.isShowAllComments;

export const selectorIsErrorComments: TSelector<CommentState['isError']> = ({
  commentReducer,
}) => commentReducer.isError;

/** Список хуков */
export const useCommentEditedInfo: THookStateInfo<
  CommentState['commentEdited']
> = () => useSelectorTypes(selectorCommentEdited);

export const useCommentDeletedInfo: THookStateInfo<
  CommentState['commentDeleted']
> = () => useSelectorTypes(selectorCommentDeleted);

export const useCommentIsAllCommentInfo: THookStateInfo<
  CommentState['isShowAllComments']
> = () => useSelectorTypes(selectorIsAllComments);

export const useCommentIsErrorInfo: THookStateInfo<
  CommentState['isError']
> = () => useSelectorTypes(selectorIsErrorComments);
