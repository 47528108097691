import request from '../../lib/helpers/requestApi';

export type ParamAgencies = {
  /** Статус Агентсва */
  status?: Status;
};
export type Status = 'STOPPED' | 'LAUNCHED' | 'DELETED' | 'ARCHIVE' | null;

export type ParamSetStatusAgencies = {
  /** ID клиента или организации */
  id: string;
  /** Статус клиента или организации */
  status: Status;
};

export type RAgencies = {
  /** ID креатива */
  id: string;
  /** Внутренний текстовый ID */
  internal_id: string;
  /** Тип клиента: */
  type: 'AGENCY';
  /** Удален ли клиент */
  is_deleted: boolean;
  /** Название клиента или агентства */
  title: string;
  /** URL иконки клиента */
  icon: string;
  /** Является ли иконка дефолтной? */
  default_icon: boolean;
  /** Сайт клиента */
  site: string;
  /** Статус Агентсва */
  status: Status;
};
export type AgenciesList = RAgencies[];

export const getAgencies = request<AgenciesList, ParamAgencies>({
  method: 'partner.getAgencies',
});
export const setStatusAgencies = request<boolean, ParamSetStatusAgencies>({
  method: 'partner.setStatus',
});
