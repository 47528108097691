import Portal from 'components/Portal';
import React, { FC, useEffect, useRef, useState } from 'react';
import { GroupUser } from 'domains/user/types';
import cn from 'classnames';
import css from './styles.module.scss';

interface IGroupHintProps {
  groups: GroupUser;
  mainRef: React.MutableRefObject<HTMLDivElement | null>;
}

const GroupHint: FC<IGroupHintProps> = ({ groups, mainRef }) => {
  const hintRef = useRef<HTMLDivElement | null>(null);
  const [positionLeft, setPositionLeft] = useState<number>(0);
  const [positionRight, setPositionRight] = useState<number>(0);
  const [positionTop, setPositionTop] = useState<number>(0);
  const [fixTopPadding, setFixTopPadding] = useState<boolean>(false);

  const calcPosition = () => {
    if (mainRef && mainRef.current && hintRef && hintRef.current) {
      setPositionTop(
        mainRef.current?.getBoundingClientRect().top -
          hintRef.current?.clientHeight -
          12,
      );
      setPositionLeft(mainRef.current?.getBoundingClientRect().left);
    }
  };

  useEffect(() => {
    calcPosition();
  }, []);

  useEffect(() => {
    if (mainRef && mainRef.current && hintRef && hintRef.current) {
      if (hintRef.current?.getBoundingClientRect().top < 0) {
        setFixTopPadding(true);
        setPositionRight(mainRef.current?.getBoundingClientRect().right);
      } else setFixTopPadding(false);
    }
  }, [positionTop]);

  return (
    <Portal className="GroupHint">
      <div
        ref={hintRef}
        className={cn(css.groupHint, {
          fixTopPadding,
        })}
        style={{
          left: fixTopPadding ? `${positionRight}px` : `${positionLeft}px`,
          top: `${positionTop}px`,
        }}
      >
        {groups.map((group) => (
          <span key={`hint${group}`} className={css.group}>
            {group}
          </span>
        ))}
      </div>
    </Portal>
  );
};

export default GroupHint;
