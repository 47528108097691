import { SagaIterator } from '@redux-saga/core';
import { takeEvery } from '@redux-saga/core/effects';
import { takeLatest } from 'redux-saga/effects';
import {
  workerCreateAgency,
  workerEditAgency,
  workerFetchAddAuditDictionary,
  workerFetchAgencyInfo,
  workerFetchAuditDictionaryList,
  workerFetchClientsStat,
  workerFetchEditAuditDictionary,
  workerFetchLoadTempFileAgency,
  workerFetchStatisticByAudit
} from './workers';
import {
  createAgency,
  fetchAgencyInfo,
  fetchLoadAgencyTempFile,
  editAgency,
  fetchClientsStat,
  fetchAuditDictionaryList,
  fetchAddAuditDictionary,
  fetchEditAuditDictionary,
  fetchStatisticByAudit,
} from '../reducer';

export default function* watchAgency(): SagaIterator {
  yield takeEvery(fetchLoadAgencyTempFile, workerFetchLoadTempFileAgency);
  yield takeEvery(fetchAgencyInfo, workerFetchAgencyInfo);
  yield takeEvery(fetchAuditDictionaryList, workerFetchAuditDictionaryList);
  yield takeEvery(fetchAddAuditDictionary, workerFetchAddAuditDictionary);
  yield takeEvery(fetchEditAuditDictionary, workerFetchEditAuditDictionary);
  yield takeEvery(fetchStatisticByAudit, workerFetchStatisticByAudit);
  yield takeLatest(createAgency, workerCreateAgency);
  yield takeLatest(editAgency, workerEditAgency);
  yield takeLatest(fetchClientsStat, workerFetchClientsStat);
}
