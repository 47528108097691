import { useCampaignOptimisationList } from "domains/campaign/model/selectors";
import { TOptimizationItem } from "domains/campaign/types";
import { useUserInfo } from "domains/user/model/selectors";
import { everyGroups } from "utils/statics/everyGroups";

type UseOptionsDataParams = {
    payType: string,
}

type UseOptionsDataReturn = {
    isCPA: boolean,
    optionsList: {
        label: string;
        value: number;
    }[] | undefined,
}

type TUseOptionsData = (args: UseOptionsDataParams) => UseOptionsDataReturn
 
export const useOptionsData: TUseOptionsData = ({payType}) => {
    const { data: optionsData } = useCampaignOptimisationList();
    const { data: user } = useUserInfo();
  
    const filterByGroups = (item: TOptimizationItem) => {
      const itemGroups = item.groups.map((groupData) => groupData.title);
      return everyGroups(user, ...itemGroups);
    };
  
    const optionsList = optionsData
      ?.filter((item) => filterByGroups(item))
      ?.map(({ title, id }) => ({
        label: title,
        value: id,
      }));
  
    const isCPA = payType === 'cpa';

    return {
        isCPA,
        optionsList,
    }
}