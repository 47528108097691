import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  MouseEvent,
  TouchEventHandler,
} from 'react';
import cn from 'classnames';
import { FiCode } from 'react-icons/fi';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { useUserTheme } from 'domains/user/model/selectors';
import css from './styles.module.scss';
import { TypesTable } from '../../../../types/general';
import { TSortType } from '../../model/types';
import defaultIcon from '../../../../assets/filterIcons/default.svg';
import ascIcon from '../../../../assets/filterIcons/asc.svg';
import descIcon from '../../../../assets/filterIcons/desc.svg';

interface Props {
  columnKey?: string;
  title: string | JSX.Element;
  sortType?: TSortType;
  setSortType: (v: TSortType) => void;
  className?: string;
  style?: React.CSSProperties;
  notSort?: boolean;
  expand: number;
  setExpand: (v: number) => void;
  isShowExpand?: boolean;
  isFixed?: boolean;
  width?: number;
  additionalClass?: string;
}

const SortHeader: React.FC<Props> = ({
  title,
  sortType,
  setSortType,
  className,
  style,
  notSort,
  expand,
  setExpand,
  isShowExpand,
  isFixed,
  width,
  columnKey,
  additionalClass,
}: Props) => {
  const dropdownMenu = useRef<HTMLDivElement>(null);

  const [expandStart, setExpandStart] = useState<boolean>(false);
  const [expandX, setExpandX] = useState<number>(0);

  const expandMouseDown = useCallback((e: MouseEvent) => {
    setExpandStart(true);
    setExpandX(e.clientX || e.nativeEvent.pageX);
  }, []);

  const expandTouchDownMobile: TouchEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      setExpandStart(true);
      setExpandX(e.touches[0].clientX);
    },
    [],
  );

  const expandMouseUp = useCallback(() => {
    setExpandStart(false);
  }, []);

  const { isThemeFull, isThemeExtended } = useUserTheme();

  useEffect(() => {
    document.addEventListener('mouseup', expandMouseUp);

    return () => document.removeEventListener('mouseup', expandMouseUp);
  }, []);

  const expandMouseMove = useCallback(
    (e: MouseEvent) => {
      const sizeLeftFixedPanel = isThemeFull || isThemeExtended ? 320 : 250;
      if (expandStart) {
        const newExpand = expand - (expandX - e.clientX);
        if (newExpand > sizeLeftFixedPanel && newExpand < 600) {
          setExpand(expand - (expandX - e.clientX));
        } else if (newExpand >= 600) setExpand(600);
        else setExpand(sizeLeftFixedPanel);
      }
    },
    [expandX, expandStart],
  );

  const expandMouseMoveMobile: TouchEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      const sizeLeftFixedPanel = isThemeFull || isThemeExtended ? 320 : 240;
      if (expandStart) {
        const newExpand = expand - (expandX - e.touches[0].clientX);
        if (newExpand > sizeLeftFixedPanel && newExpand < 600) {
          setExpand(expand - (expandX - e.touches[0].clientX));
        } else if (newExpand >= 600) setExpand(600);
        else setExpand(sizeLeftFixedPanel);
      }
    },
    [expandX, expandStart],
  );

  useEffect(() => {
    if (columnKey === 'show') {
      setSortType({
        type: TypesTable.DESC,
        column: typeof title === 'string' ? title : '',
      });
    }
  }, []);

  const sortFunction = useCallback(() => {
    if (sortType?.column === title || sortType?.column === columnKey) {
      if (sortType?.type === TypesTable.DESC) {
        return setSortType({
          type: TypesTable.ASC,
          column:
            // eslint-disable-next-line no-nested-ternary
            typeof title === 'string'
              ? title
              : // eslint-disable-next-line no-self-compare
              columnKey === columnKey
              ? 'title'
              : '',
        });
      }

      if (sortType?.type === TypesTable.ASC) {
        return setSortType({
          type: TypesTable.DEFAULT,
          column: '',
        });
      }
    }

    return setSortType({
      type: TypesTable.DESC,
      column:
        // eslint-disable-next-line no-nested-ternary
        typeof title === 'string'
          ? title
          : // eslint-disable-next-line no-self-compare
          columnKey === columnKey
          ? 'title'
          : '',
    });
  }, [sortType, TypesTable, columnKey, title]);

  const { t } = useTranslation();

  return (
    <div
      className={cn(css.header, className, additionalClass, {
        _isCenter: additionalClass && notSort,
      })}
      ref={dropdownMenu}
      style={{
        ...style,
        width: isShowExpand ? `${expand}px` : `${width}px`,
        left: isShowExpand && isFixed ? `-${expand}px` : undefined,
      }}
    >
      <div className={css.button} onClick={sortFunction}>
        <span className={css.text}>
          {title === 'utm_tags' ? t('charts.utm_tags') : title}
        </span>

        <div className={css.spacer} />

        {!notSort && (
          <div className={css.sortIcon}>
            {(sortType?.type === TypesTable.ASC ||
              sortType?.type === TypesTable.DESC) &&
            (sortType?.column === title || sortType.column === columnKey) ? (
              <>
                {sortType?.type === TypesTable.DESC &&
                  (sortType?.column === title ||
                    sortType.column === columnKey) && (
                    <ReactSVG src={descIcon} className="icon" />
                  )}
                {sortType?.type === TypesTable.ASC &&
                  (sortType?.column === title ||
                    sortType.column === columnKey) && (
                    <ReactSVG src={ascIcon} className="icon" />
                  )}
              </>
            ) : (
              <ReactSVG src={defaultIcon} className="icon" />
            )}
          </div>
        )}
      </div>

      {isShowExpand && (
        <div
          className={cn(css.expand, {
            _isExpandStart: expandStart,
          })}
          onMouseDown={expandMouseDown}
          onMouseUp={expandMouseUp}
          onMouseMove={expandMouseMove}
          onTouchStart={expandTouchDownMobile}
          onTouchEnd={expandMouseUp}
          onTouchMove={expandMouseMoveMobile}
        >
          <div className={css.button}>
            <FiCode size={12} />
          </div>
        </div>
      )}
    </div>
  );
};

export default SortHeader;
