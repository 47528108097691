import { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { AppState } from '../../redux/rootReducer';
import { CreativeState, setLastId, delLastId, setListSort } from './reduser';
import { useDispatchApp } from '../../redux/rootSelectors';

type TFUseHighlights = () => string;
type TFUseSetLastIdCreative = () => void;
type TFUseDelLastIdCreative = () => () => void;
type TFUseCreativeListFilter = () => {
  listFilter: CreativeState['listFilter'];
};
type TFUseCreativeSortInfo = () => {
  sort: CreativeState['listSort']['sort'];
  key: CreativeState['listSort']['key'];
  isShow: boolean;
  handlerSort: (p: CreativeState['listSort']) => void;
};

/** возврощает последний посещенный ид */
export const useHighlights: TFUseHighlights = () => {
  const dispatch = useDispatchApp();
  const [id, setId] = useState<string>('');
  const history = useHistory();
  const lastId = useSelector<AppState, CreativeState['lastId']>(
    ({ creativeReducer }) => creativeReducer.lastId,
    shallowEqual,
  );
  useEffect(() => {
    if (!lastId) return;
    history.push(`?highlights=${lastId}`);
  }, [lastId]);
  useEffect(() => {
    const url = new URL(window.location.href);
    const highlights = url.searchParams.get('highlights') ?? '';
    dispatch(setLastId(highlights));
    setId(highlights);
  }, [window.location.href]);
  return id;
};

/** устанвливает последний посещенный ид из сылки */
export const useSetLastIdCreative: TFUseSetLastIdCreative = () => {
  const dispatch = useDispatchApp();
  const { id } = useParams<{ id?: string }>();
  useEffect(() => {
    if (!id) return;
    dispatch(setLastId(id));
  }, [id]);
};

/** удаляет последний посещеный ид */
export const useDelLastIdCreative: TFUseDelLastIdCreative = () => {
  const dispatch = useDispatchApp();
  return useCallback(() => {
    dispatch(delLastId());
  }, []);
};

/** возвращает состояние фильтра списка креативов */
export const useCreativeListFilter: TFUseCreativeListFilter = () => {
  const listFilter = useSelector<AppState, CreativeState['listFilter']>(
    ({ creativeReducer }) => creativeReducer.listFilter,
    shallowEqual,
  );
  return {
    listFilter,
  };
};

export const useCreativeSortInfo: TFUseCreativeSortInfo = () => {
  const dispatch = useDispatchApp();

  const {
    listSort: { sort, key },
    isShowSortFilter,
  } = useSelector<AppState, CreativeState>(
    ({ creativeReducer }) => creativeReducer,
    shallowEqual,
  );

  const handlerSort: ReturnType<TFUseCreativeSortInfo>['handlerSort'] = (p) => {
    dispatch(setListSort(p));
  };

  return { sort, key, isShow: isShowSortFilter, handlerSort };
};
