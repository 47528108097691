import { put, call, select, fork } from 'redux-saga/effects';
import i18next from 'i18next';
import { SagaIterator } from 'redux-saga';
import {
  fetchProjectAction,
  fetchProject,
  setLogoType,
  editProjectRequest,
  fetchProjectTechnicalWorks,
  setLang,
  changeLangRequest,
  setUsersGroups,
} from '../reducer';
import {
  makeReqWithRD,
  TMakeReqWithRD,
  updateFillFetchedData,
} from '../../../redux/makeReqWithRD';
import {
  editProject,
  getGroups,
  getPartnerIcon,
  getProjectInfo,
  setFilesProject,
  getBlock,
} from '../api';
import { setLogoInTheProject } from '../../../utils/logoBrandWithPrioject';
import { setFormDefault } from '../../uploads/reducer';
import updateToken from '../../../utils/updateToken';
import { AppState } from '../../../redux/rootReducer';
import { TLang } from '../types';
import i18n from '../../../i18n';
import { SelectField } from '../../../types/general';
import { toastSuccess } from '../../../utils/ToastUtils/toastUtils';
import { setFilesProjectAction } from '../model/actions';

export function* workerFetchProjectSaga({
  payload,
}: ReturnType<typeof fetchProjectAction>): SagaIterator<void> {
  try {
    const { xxhashPartner, domain } = payload;
    const techWorks = yield call(getBlock);
    yield call<TMakeReqWithRD<typeof getProjectInfo>>(makeReqWithRD, {
      fetcher: getProjectInfo,
      fill: (d) => {
        const data = d.get('data');
        if (!data?.technical_works && data) {
          data.technical_works = techWorks;
        }
        return fetchProject(d.set('data', data));
      },
      isToken: false,
      parameters: { domain },
    });
    if (xxhashPartner) {
      yield call<TMakeReqWithRD<typeof getPartnerIcon>>(makeReqWithRD, {
        fetcher: getPartnerIcon,
        fill: (v) => {
          const result = v.get('data');
          if (result) {
            setLogoInTheProject(xxhashPartner, result);
          }
          return setLogoType(v);
        },
        parameters: { partner_xxhash: xxhashPartner },
      });
    }
  } catch (e) {
    console.log(e);
  }
}

export function* editProjectWorker({
  payload,
}: ReturnType<typeof editProjectRequest>): SagaIterator<void> {
  try {
    yield call(updateToken);
    const { data, setter, value, alertText } = payload;

    const result = yield call(editProject, data);
    if (result) {
      if (setter) setter(value);
      toastSuccess({
        before: `${i18next.t('targetings.saveblock.hint1')}`,
        title: ` "${alertText}" `,
        after: `${i18next.t('targetings.saveblock.hint2')}`,
      });
      yield put(
        fetchProjectAction({ domain: data.domain, xxhashPartner: null }),
      );
      yield put(setFormDefault(true));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* workerFetchTechnicalWorks({
  payload,
}: ReturnType<typeof fetchProjectTechnicalWorks>): SagaIterator<void> {
  try {
    // yield call(updateToken);
    const NewData = yield call(getProjectInfo, payload);
    const oldDataTemp = yield select(
      ({ projectReducer }: AppState) => projectReducer.tempProject,
    );
    const oldData = oldDataTemp.get('data');
    if (!oldData) {
      yield put(fetchProject(NewData));
    }
    if (!NewData.technical_works) {
      NewData.technical_works = yield call(getBlock);
    }
    if (oldData.technical_works !== NewData.technical_works) {
      oldData.technical_works = NewData.technical_works;
      yield put(
        fetchProject(oldDataTemp.set('data', oldData).set('LTU', Date.now())),
      );
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* setLocalLangSaga(payload: string): SagaIterator<void> {
  let lang = payload;
  if (lang === null) {
    lang = localStorage.getItem('lang') || '';
  }

  const url = new URL(window.location.href);
  const lng = url.searchParams.get('lng');
  const { whitelist } = i18n.options;

  if (Array.isArray(whitelist)) {
    if (typeof lng === 'string' && whitelist.includes(lng)) {
      // yield call(effects.setLang, actions.setLangRequest(lng));
      return;
    }
    lang = whitelist.includes(lang) ? lang : 'en';
  }

  i18n.changeLanguage(lang);
  localStorage.setItem('lang', lang);
  yield put(setLang(lang as TLang));
}

export function* getLangWorker(): SagaIterator<void> {
  const lang = () => {
    const getItem = localStorage.getItem('lang');

    if (getItem) {
      return getItem;
    }

    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0];
    }

    return navigator.language || 'en';
  };

  const payload = lang().split('-')[0];
  yield fork(setLocalLangSaga, payload);
}

export function* setLangProject({
  payload,
}: ReturnType<typeof changeLangRequest>): SagaIterator<void> {
  yield put(setLang(payload));
  localStorage.setItem('lang', payload);
  i18n.changeLanguage(payload);
}

export function* fetchGroupsWorker(): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getGroups>>(makeReqWithRD, {
      fetcher: getGroups,
      fill: (v) => {
        const data = v.get('data');
        let newData: SelectField[] = [];
        if (data) {
          newData = data.map(({ title, id }) => ({
            label: title,
            value: `${id}`,
          }));
        }
        return setUsersGroups(
          updateFillFetchedData({ fillFetchedData: v, newData }),
        );
      },
      parameters: { domain: window.location.host },
    });
  } catch (e) {
    console.error(e);
  }
}

export function* setFileProjectWorker({
  payload,
}: ReturnType<typeof setFilesProjectAction>): SagaIterator<void> {
  try {
    yield call(updateToken);
    const { setEdit, ...rest } = payload;
    const result = yield call(setFilesProject, rest);
    if (result) {
      toastSuccess({
        before: `${i18next.t('targetings.saveblock.hint1')}`,
        title: `${i18next.t('project_page.forms.documents_title')}`,
        after: `${i18next.t('targetings.saveblock.hint2')}`,
      });
      yield put(
        fetchProjectAction({ domain: payload.domain, xxhashPartner: null }),
      );
      setEdit(false);
      yield put(setFormDefault(true));
    }
  } catch (e) {
    console.error(e);
  }
}
