import React, { FC } from 'react';
import { FiX } from 'react-icons/fi';
import moment from 'moment';
import { SelectField } from 'types/general';
import Button from '../../UI/Buttons/buttons';
import i18next from '../../../i18n';
import { TCampaignsFilters } from '../../../domains/campaigns/types';
import { useFilterResult } from './hook';
import css from './styles.module.scss';
import { selectLabel } from '../../../utils/selectDefault';
import { filterOptionsCompare } from '../../../domains/campaigns/model/shared';

type FilterResultProps = {
  data: TCampaignsFilters;
  clearAll?: () => void;
  removeItem: (id: number) => void;
};

const FilterResult: FC<FilterResultProps> = ({
  data,
  clearAll,
  removeItem,
}) => {
  const { showBtnCollapse, isCollapse, items, toggleCollapse } =
    useFilterResult({ data });

  const filterOptions: SelectField[] = [
    {
      label: i18next.t('filter.date__start'),
      value: 'start',
    },
    {
      label: i18next.t('filter.date__end'),
      value: 'end',
    },
  ];

  return (
    <div className={css.filterResults}>
      {items.map(({ value, key, compare, id }, index) => (
        <div key={id} className={css.item__wrap}>
          <div className={css.item}>
            <span>{selectLabel(key, filterOptions)} </span>
            <span> {selectLabel(compare, filterOptionsCompare)} </span>
            <span>{moment(value).format('DD.MM.YYYY')}</span>
            <FiX
              size={20}
              className={css.icon}
              onClick={() => removeItem(id)}
            />
          </div>
          {index + 1 !== items.length && (
            <span className={css.filterMerger}>{i18next.t(`filter.and`)}</span>
          )}
        </div>
      ))}
      {showBtnCollapse && (
        <Button
          lightblue
          title={
            !isCollapse
              ? i18next.t(`collapse_btn`)
              : `+ ${data.length - items.length}`
          }
          height={40}
          buttonAttributes={{
            className: css.collapse,
            onClick: () => toggleCollapse(!isCollapse),
            style: {
              minWidth: 'auto',
              marginLeft: isCollapse ? '0' : '12px',
            },
          }}
        />
      )}
      {!isCollapse && clearAll && !!data.length && (
        <Button
          lightblue
          title={i18next.t(`clean_btn`)}
          height={40}
          buttonAttributes={{
            onClick: clearAll,
            className: css.clear,
          }}
        />
      )}
    </div>
  );
};
export default FilterResult;
