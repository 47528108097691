import React from 'react';
import { useDispatchApp } from 'redux/rootSelectors';
import { TBreadcrumbsTarget } from '../types';
import { TStepLiteral } from '../../../domains/creative/creatives/CopyCreativeModal/useCopy';
import { fetchClients } from '../../../domains/clients/reducer';
import { fetchAgencies } from '../../../domains/agencies/reducer';
import css from './styles.module.scss';
import { fetchModalBreadcrumbs } from '../../../domains/search/model/actions';
import { fetchCampaignsListModal } from '../../../domains/campaigns/model/actions';
import { fetchCreativeListModal } from '../../../domains/creative/model/actions';

type Props = {
  breadCrumbs: TBreadcrumbsTarget[];
  setStep: (v: TStepLiteral) => void;
};

const Breadcrumbs: React.FC<Props> = ({ setStep, breadCrumbs }) => {
  const dispatch = useDispatchApp();
  return (
    <div className={css.breadcrumbs}>
      {breadCrumbs.length > 0 &&
        breadCrumbs.map(({ title, xxhash, type }) => (
          <div
            className={css.item}
            key={xxhash}
            onClick={() => {
              dispatch(fetchModalBreadcrumbs({ xxhash }));
              if (type === 'Agency') {
                setStep('clients');
                dispatch(fetchClients({ status: null, idAgency: xxhash }));
              }
              if (type === 'Organization') {
                setStep('agencies');
                dispatch(fetchAgencies({ status: null }));
              }
              if (type === 'Client') {
                setStep('campaigns');
                dispatch(
                  fetchCampaignsListModal({
                    partner_xxhash: xxhash,
                    status: '',
                  }),
                );
              }
              if (type === 'Campaign') {
                dispatch(fetchCreativeListModal({ campaign_xxhash: xxhash }));
              }
            }}
          >
            {title}
          </div>
        ))}
    </div>
  );
};

export default Breadcrumbs;
