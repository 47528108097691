import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatchApp } from 'redux/rootSelectors';
import { RBreadCrumbs } from 'domains/search/model/types';
import { setCampaignsTabs } from 'domains/storage/model/reducer';
import { useSelector } from 'react-redux';
import { PeriodEnum } from 'types/containers';
import moment from 'moment';
import { useUserInfo, useUserTheme } from 'domains/user/model/selectors';
import {
  useSearchBreadcrumbsInfo,
  useSearchCampaignsTagsActive,
} from 'domains/search/model/selectors';
import {
  PSagaCopyElement,
  TDates,
  TGroup,
  TOnSetDateRange,
  TPropsSelector,
} from 'types/general';
import { RCampaignsArrayStata } from 'domains/campaigns/types';
import { useStorageCampaignsTabs } from 'domains/storage/model/selectors';
import {
  fetchCampaigns,
  fetchCampaignsTranslation,
  fetchChartStatistic,
  setCampaignsFilter,
  setListSort,
  setToggleStatusCampaign,
} from 'domains/campaigns/reducer';
import { ResponseUser } from 'domains/user/types';
import { RProject } from 'domains/project/types';
import { useProjectInfo } from 'domains/project/hooks';
import { RGetPersonal, TStatusCampaign } from 'domains/campaign/types';
import { fetchClients } from 'domains/clients/reducer';
import {
  changeGroupCampaigns,
  cloneCampaignsToClient,
  doubleCampaigns,
  loadCampaignsListView,
  searchCampaignListView,
} from '../actions';
import { decodeUrlFilter } from '../utils/encodeurlFilter';
import {
  selectorFiltredCampaignsTranslation,
  selectorListSort,
  useCampaignsInfo,
  useCampaignsPeriodInfo,
  usePartnerBalanceInfo,
} from '../selectors';
import { useGetStatusFromUrl } from './useLastID';

export type TGetCampaignsChecked = {
  all: boolean;
  partially: boolean;
};

type IFetchDataCampaigns = {
  filter: TStatusCampaign;
  user: ResponseUser | null;
  project: RProject | null;
  toggle: string;
  setToggle: (v: string) => void;
  isOpenModalArchive: string | boolean;
  setOpenModalArchive: (v: string | boolean) => void;
  handleChangeArchive: (v: THandleChangeArchive) => void;
  openDelete: string | boolean;
  setOpenDelete: (v: string | boolean) => void;
  handleChangeCampaignStatus: (v: THandleChangeCampaignStatus) => void;
  handleRemoveCampaigns: (v: THandleRemoveCampaigns) => void;
  isModalAllSettingGroup: boolean;
  checkedGroup: TGroup;
  handlerCampaignChecked: (
    value: boolean,
    xxhash: string,
    title: string,
    bread_crumbs: RBreadCrumbs | null,
    internal_id?: string,
  ) => void;
  defineCheckedCampaign: (xxhash: string) => boolean;
  getCampaignsChecked: TGetCampaignsChecked;
  handlerAllCheckedCampaigns: (checkedStatus: boolean) => void;
  handlerClickStatusCampaignEdit: (
    value: RGetPersonal['status'] | 'DUPLICATE',
  ) => void;
  generateTextIsModalText: () => { text: string; button: string };
  setIsModalAllSettingGroup: (value: boolean) => void;
  clientTitle: string;
  handlerUpdateCampaignsGroup: () => void;
  setCheckedGroup: (value: TGroup) => void;
  setClientInfo: React.Dispatch<React.SetStateAction<TClientInfo[]>>;
  isLoadingGroup: boolean;
  xxhashWithCopyModal: string;
  handlerCopyCampaignsOtherClient: (
    value: Omit<PSagaCopyElement, 'from'>,
  ) => void;
  isLoadingSearch: boolean;
};
type IParamUseFetchDataCampaigns = TPropsSelector<RCampaignsArrayStata> & {
  partner_xxhash?: string | null;
  dates?: TDates;
  search: string;
  updateDates: TOnSetDateRange;
  showReport: boolean;
  currentFilter: string;
  currentTranslationXxhashArr: string[];
};

type TClientInfo = {
  clientName: string;
  clientXxhash: string;
  copyData: TGroup;
};

export type THandleChangeArchive = {
  xxhash: string;
  title: string;
  client_id: string;
  paramsCampaignsStatus: TStatusCampaign;
  status: TStatusCampaign;
};
export type THandleChangeCampaignStatus = {
  xxhash: string;
  activeStatus: boolean | string;
  setStatus: (v: boolean | string) => void;
};
export type THandleRemoveCampaigns = {
  xxhash: string;
  paramsCampaignsStatus: TStatusCampaign;
  title: string;
  client_id: string;
};

/** Функция возвращает компании при загрузке страницы */
export const useFetchDataCampaigns = ({
  partner_xxhash,
  dates,
  list,
  serverList,
  search,
  updateDates,
  isBlocked,
  showReport,
  currentFilter,
  currentTranslationXxhashArr,
}: IParamUseFetchDataCampaigns): IFetchDataCampaigns => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatchApp();

  const { isLoading: balanceIsLoading } = usePartnerBalanceInfo();

  const filter = useStorageCampaignsTabs();

  const { data: project } = useProjectInfo();
  const { data: user } = useUserInfo();
  const { data: campaigns } = useCampaignsInfo();
  const { getValueFromSettingForKey } = useUserTheme();

  const currentSort = useSelector(selectorListSort);

  const handlerClearUrl = () => {
    if (location.search.includes('highlights')) {
      const url = location.search.split('&')[0];
      history.push(url);
    }
  };

  const translationFiltredData = useSelector(
    selectorFiltredCampaignsTranslation,
  );
  
  const location = useLocation();
  const param = new URLSearchParams(location.search);

  const isDefaultTranslationFilter = currentFilter === 'isAll';

  /** открытие при наведении */
  const [toggle, setToggle] = useState<string>('');
  /** состояние модального окна добавление в архив */
  const [isOpenModalArchive, setOpenModalArchive] = useState<string | boolean>(
    '',
  );
  /** состояние модального окна для удаления */
  const [openDelete, setOpenDelete] = useState<string | boolean>('');

  const campaignStatus = useGetStatusFromUrl();

  /** функция добавления и извлечения из архива */
  const handleChangeArchive = ({
    xxhash,
    title,
    client_id,
    paramsCampaignsStatus,
    status,
  }: THandleChangeArchive) => {
    handlerClearUrl();
    dispatch(
      setToggleStatusCampaign({
        xxhash,
        status: status === 'ARCHIVE' ? 'STOPPED' : 'ARCHIVE',
        setter: setOpenModalArchive,
        value: isOpenModalArchive,
        reRenderParams: {
          isRerender: true,
          client_id,
          statusCampaigns: paramsCampaignsStatus,
          alertText: {
            before: `${t('just_words.w1')} `,
            title: ` "${title}" `,
            after:
              status === 'ARCHIVE'
                ? ` ${t('campaigns_page.camp_archive_from_alert')}`
                : ` ${t('campaigns_page.camp_archive_to_alert')}`,
          },
        },
      }),
    );
  };

  /** функция изменения статуса */
  const handleChangeCampaignStatus = ({
    xxhash,
    activeStatus,
    setStatus,
  }: THandleChangeCampaignStatus) => {
    dispatch(
      setToggleStatusCampaign({
        xxhash,
        status: activeStatus ? 'STOPPED' : 'LAUNCHED',
        setter: setStatus,
        value: activeStatus,
        translation:
          getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
          !getValueFromSettingForKey('ShowBroadcastStatus'),
      }),
    );
  };

  /** функция удаление креатива */
  const handleRemoveCampaigns = ({
    paramsCampaignsStatus,
    xxhash,
    client_id,
    title,
  }: THandleRemoveCampaigns) => {
    handlerClearUrl();
    dispatch(
      setToggleStatusCampaign({
        xxhash,
        status: 'DELETED',
        setter: setOpenDelete,
        value: openDelete,
        reRenderParams: {
          client_id,
          isRerender: true,
          alertText: {
            before: `${t('just_words.w1')} `,
            title: ` "${title}" `,
            after: ` ${t('campaigns_page.camp_is_deleted')}`,
          },
          statusCampaigns: paramsCampaignsStatus,
        },
      }),
    );
  };

  useEffect(() => {
    if (campaignStatus) {
      dispatch(setCampaignsTabs(campaignStatus));
    }
  }, [campaignStatus]);

  useEffect(() => {
    if (user) {
      const url = new URL(window.location.href);
      const filterURL = url.searchParams.get('filter') ?? '';
      const statusInfo =
        (url.searchParams.get('status') as TStatusCampaign) ?? '';

      if (filterURL) {
        const filterURLInfo = decodeUrlFilter(filterURL);
        if (Array.isArray(filterURLInfo)) {
          dispatch(setCampaignsFilter(filterURLInfo));
        }
      }
      dispatch(
        fetchCampaigns({
          partner_xxhash: partner_xxhash || user.partner.xxhash,
          dates,
          updateDates,
          filters: {
            status: statusInfo || filter,
          },
        }),
      );
    }
  }, [partner_xxhash, user, location]);

  useEffect(() => {
    if(partner_xxhash) return
    dispatch(fetchClients({}))
  }, []) 

  const campaignsPeriod = useCampaignsPeriodInfo();

  useEffect(() => {
    if (showReport && campaigns?.length) {
      if (dates?.type === PeriodEnum.ALL_TIME) {
        if (campaignsPeriod.from) {
          dispatch(
            fetchChartStatistic({
              xxhash_list: campaigns.map((e) => e.xxhash),
              period: {
                from: moment(campaignsPeriod.from).format('YYYY-MM-DD'),
                to: moment(campaignsPeriod.to).format('YYYY-MM-DD'),
              },
            }),
          );
          return;
        }
      }
      dispatch(
        fetchChartStatistic({
          xxhash_list: campaigns.map((e) => e.xxhash),
          period: dates?.period,
        }),
      );
    }
  }, [campaigns?.length, showReport, campaignsPeriod]);

  useEffect(() => {
    if (
      campaigns?.length &&
      !balanceIsLoading &&
      getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
      !getValueFromSettingForKey('ShowBroadcastStatus')
    ) {
      dispatch(
        fetchCampaignsTranslation({
          xxhash_list: campaigns.map((e) => e.xxhash),
        }),
      );
    }
  }, [campaigns?.length, balanceIsLoading]);

  const scrollHandler = ({ target }: Event) => {
    const scroll = target as HTMLDocument;
    if (
      !isBlocked &&
      serverList &&
      scroll.documentElement.scrollHeight -
        (scroll.documentElement.scrollTop + window.innerHeight) <
        100
    ) {
      dispatch(loadCampaignsListView({}));
    }
  };

  const [isModalAllSettingGroup, setIsModalAllSettingGroup] = useState(false);
  const [checkedGroup, setCheckedGroup] = useState<TGroup>([]);
  // Стейт для дублирования кампаний на общей странице
  const [clientInfo, setClientInfo] = useState<TClientInfo[]>([]);

  const handlerCampaignChecked: IFetchDataCampaigns['handlerCampaignChecked'] =
    useCallback(
      (value, xxhash, title, bread_crumbs, internal_id) => {
        const addXxhashToClient = (item: TClientInfo) => {
          if (item.clientXxhash === bread_crumbs?.Client?.xxhash) {
            return { ...item, copyData: [...item.copyData, { title, xxhash }] };
          }
          return item;
        };
        const removeXxhashFromClient = (item: TClientInfo) => {
          if (
            item.clientXxhash === bread_crumbs?.Client?.xxhash &&
            item.copyData.some((item2) => item2.xxhash === xxhash)
          ) {
            return {
              ...item,
              copyData: item.copyData.filter((x) => x.xxhash !== xxhash),
            };
          }
          return item;
        };
        if (checkedGroup.some((item) => item.xxhash === xxhash)) {
          setClientInfo(clientInfo.map((item) => removeXxhashFromClient(item)));
          setCheckedGroup((prev) => [
            ...prev.filter((item) => item.xxhash !== xxhash),
          ]);
        } else {
          setCheckedGroup((prev) => [...prev, { xxhash, title, internal_id }]);
          if (
            clientInfo.some(
              (item) => item.clientXxhash === bread_crumbs?.Client?.xxhash,
            )
          ) {
            setClientInfo(clientInfo.map((item) => addXxhashToClient(item)));
          } else {
            setClientInfo((prev) => [
              ...prev,
              {
                clientXxhash: bread_crumbs?.Client?.xxhash || '',
                clientName: bread_crumbs?.Client?.title || '',
                copyData: [{ title, xxhash }],
              },
            ]);
          }
        }
      },
      [checkedGroup],
    );

  const defineCheckedCampaign: IFetchDataCampaigns['defineCheckedCampaign'] =
    useCallback(
      (xxhash) => checkedGroup.some((item) => item.xxhash === xxhash),
      [checkedGroup],
    );

  const getCampaignsChecked = useMemo<{
    all: boolean;
    partially: boolean;
  }>(() => {
    let partially = false;
    let all = false;
    if (serverList) {
      let listHash: string[];
      if (search || !isDefaultTranslationFilter) {
        listHash = list.map(({ xxhash }) => xxhash);
      } else {
        listHash = serverList.map(({ xxhash }) => xxhash);
      }
      const listHashFilter = listHash.filter((value) =>
        checkedGroup.some((item) => item.xxhash === value),
      );
      all =
        listHashFilter.length > 0 && listHashFilter.length === listHash.length;
      partially = listHashFilter.length > 0;
    }
    return {
      all,
      partially,
    };
  }, [search, serverList, list, checkedGroup, currentFilter]);

  const mappedList = (listArr: RCampaignsArrayStata) =>
    listArr.map(({ xxhash, title, internal_id }) => ({
      xxhash,
      title,
      internal_id,
    }));

  const handlerAllCheckedCampaigns: IFetchDataCampaigns['handlerAllCheckedCampaigns'] =
    (isSomeChecked) => {
      if (!serverList) return;
      let hashList: TGroup = [];
      if (search) {
        hashList = [...checkedGroup, ...mappedList(list)];
      } else if (!isDefaultTranslationFilter) {
        hashList = [
          ...mappedList(
            serverList.filter((item) =>
              currentTranslationXxhashArr.includes(item.xxhash),
            ),
          ),
          ...checkedGroup,
        ];
      } else {
        hashList = mappedList(serverList);
      }

      setCheckedGroup(() => {
        if (isSomeChecked) {
          if (search || !isDefaultTranslationFilter) {
            const currentList = search
              ? list
              : serverList.filter((item) =>
                  currentTranslationXxhashArr.includes(item.xxhash),
                );

            return checkedGroup.filter(
              (campaign) =>
                !currentList.some((item) => item.xxhash === campaign.xxhash),
            );
          }
          return [];
        }
        return hashList;
      });
    };

  const allCheckedStatus = useRef<RGetPersonal['status'] | 'DUPLICATE'>('');

  const handlerClickStatusCampaignEdit: IFetchDataCampaigns['handlerClickStatusCampaignEdit'] =
    useCallback((status) => {
      setIsModalAllSettingGroup(true);
      allCheckedStatus.current = status;
    }, []);

  const BUTTON_TEXT: Record<
    Exclude<RGetPersonal['status'], ''> | 'DUPLICATE',
    string
  > = {
    STOPPED: filter === 'ARCHIVE' ? '' : t('stop'),
    LAUNCHED: t('start'),
    DELETED: t('delete_btn'),
    ARCHIVE: t('archive_title_to'),
    DUPLICATE: t('duplicate_btn'),
  };

  const AlERT_TEXT: Record<Exclude<RGetPersonal['status'], ''>, string> = {
    STOPPED: filter === 'ARCHIVE' ? t(`retrieved`) : t('stopped'),
    LAUNCHED: t('launched'),
    DELETED: t('deleted'),
    ARCHIVE: t(`archived`),
  };

  const { data: breadcrumbs, LTU: LTUBreadcrumbs } = useSearchBreadcrumbsInfo();
  const campaignsTagsActive = useSearchCampaignsTagsActive();

  const [isLoadingGroup, setIsLoadingGroup] = useState(false);

  const clientTitle = useMemo<string>(() => {
    if (!breadcrumbs || !('Client' in breadcrumbs)) {
      return '';
    }
    return breadcrumbs.Client.title;
  }, [breadcrumbs, LTUBreadcrumbs]);

  const xxhashWithCopyModal = useMemo<string>(() => {
    if (breadcrumbs) {
      if (param.get('client_id')) {
        return breadcrumbs?.Agency?.xxhash || '';
      }
      return breadcrumbs?.Organization?.xxhash || '';
    }
    return '';
  }, [breadcrumbs]);

  const generateTextIsModalText = () => {
    let resultText = `${t(`campaigns_page.bulk_action_alert`)} ${BUTTON_TEXT[
      allCheckedStatus.current
    ]?.toLowerCase()} ${t(`campaigns_page.client_company_alert`)}`;
    let button = BUTTON_TEXT[allCheckedStatus.current];
    switch (allCheckedStatus.current) {
      case 'DELETED':
        resultText = `${t(`campaigns_page.bulk_action_alert`)} ${BUTTON_TEXT[
          allCheckedStatus.current
        ]?.toLowerCase()} ${t(`campaigns_page.client_company_alert_for_del`)}`;
        break;
      case 'STOPPED':
        if (filter === 'ARCHIVE') {
          resultText = `
            ${t(`campaigns_page.bulk_action_alert`)}
            ${t(`campaigns_page.client_company_expect_to`)}`;
          button = `${t('archive_title_from')}`;
        }
        break;
      case 'DUPLICATE':
        resultText = `${t(`campaigns_page.client_company_alert_for_double`)}`;
        break;
      default:
        break;
    }

    return {
      text: resultText,
      button,
    };
  };

  const handlerUpdateCampaignsGroup = () => {
    if (allCheckedStatus.current) {
      const client = breadcrumbs?.Client;
      if (allCheckedStatus.current === 'DUPLICATE') {
        if (client) {
          dispatch(
            doubleCampaigns({
              from: checkedGroup,
              to: [{ title: client.title, xxhash: client.xxhash }],
              callback: () => {
                setIsModalAllSettingGroup(false);
              },
            }),
          );
          return;
        }
        //  Логика дублирования кампаний на общей странице
        const doubleGroup = (item: {
          clientXxhash: string;
          clientName: string;
          copyData: TGroup;
        }) => {
          if (item.copyData.length) {
            dispatch(
              doubleCampaigns({
                from: item.copyData,
                to: [{ title: item.clientName, xxhash: item.clientXxhash }],
                partner_xxhash: user && (partner_xxhash || user.partner.xxhash),
                callback: () => {
                  setIsModalAllSettingGroup(false);
                },
              }),
            );
          }
        };
        clientInfo.forEach((item) => doubleGroup(item));
        setClientInfo([]);
        return;
      }

      const isModeGroup =
        checkedGroup.length !== serverList?.length ||
        filter ||
        !!campaignsTagsActive.length;
      setIsLoadingGroup(true);
      dispatch(
        changeGroupCampaigns({
          filter,
          status: allCheckedStatus.current as TStatusCampaign,
          campaign_xxhash_list: checkedGroup.map((item) => item.xxhash),
          client_xxhash:
            breadcrumbs && 'Client' in breadcrumbs
              ? breadcrumbs.Client.xxhash
              : '',
          callback: () => {
            setIsModalAllSettingGroup(false);
            setCheckedGroup([]);
            setClientInfo([]);
            setIsLoadingGroup(false);
          },
          mode: isModeGroup ? 'GROUP' : 'ALL',
          alertText: `${t(`campaigns_page.mass_actions_success`)} ${
            AlERT_TEXT[allCheckedStatus.current]
          } ${t(`campaigns_page.mass_actions_success_eng`)}`,
        }),
      );
    }
  };

  const handlerCopyCampaignsOtherClient: IFetchDataCampaigns['handlerCopyCampaignsOtherClient'] =
    useCallback(
      (payload) => {
        dispatch(cloneCampaignsToClient({ from: checkedGroup, ...payload }));
      },
      [checkedGroup],
    );

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);
    return function () {
      document.removeEventListener('scroll', scrollHandler);
    };
  }, [list, isBlocked]);

  useEffect(() => {
    dispatch(setListSort({ key: null, sort: null }));
    return () => {
      document.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  useEffect(() => {
    dispatch(searchCampaignListView({ search, period: dates?.period }));
  }, [search]);

  const [isLoadingSearch, setIsLoadingSearch] = useState(false);

  useEffect(() => {
    if (search) {
      dispatch(
        searchCampaignListView({
          search,
          callbacks: { setIsLoadingSearch },
          period: dates?.period,
        }),
      );
    }
  }, [translationFiltredData]);

  useEffect(() => {
    if (search) {
      dispatch(
        searchCampaignListView({
          search,
          callbacks: { setIsLoadingSearch },
          period: dates?.period,
          blockTotalRequest: true,
        }),
      );
    }
  }, [currentSort]);

  return {
    filter,
    user,
    project,
    toggle,
    setToggle,
    isOpenModalArchive,
    setOpenModalArchive,
    handleChangeArchive,
    openDelete,
    setOpenDelete,
    handleChangeCampaignStatus,
    handleRemoveCampaigns,
    isModalAllSettingGroup,
    setIsModalAllSettingGroup,
    checkedGroup,
    handlerCampaignChecked,
    defineCheckedCampaign,
    getCampaignsChecked,
    handlerAllCheckedCampaigns,
    handlerClickStatusCampaignEdit,
    generateTextIsModalText,
    clientTitle,
    handlerUpdateCampaignsGroup,
    setCheckedGroup,
    setClientInfo,
    isLoadingGroup,
    xxhashWithCopyModal,
    handlerCopyCampaignsOtherClient,
    isLoadingSearch,
  };
};
