import cn from 'classnames';
import { FiImage } from 'react-icons/fi';
import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';
import Icons from './Icons';
import Info from './Info';
import { useUploadImage } from './hooks';
import { PUploadImage } from '../types';

const UploadImage: FC<PUploadImage> = ({
  onDelLoadImg,
  remove_icon,
  imgData,
  withSubmit,
  uploadHandler,
  disabled,
}) => {
  const { t } = useTranslation();
  const {
    upload,
    error,
    complete,
    imgUrl,
    onChangeFile,
    openMLinkCallback,
    tempImage,
    loadImg,
    errorImage,
    setLoadImg,
  } = useUploadImage(remove_icon, imgData, withSubmit, uploadHandler);
  const uploaderRef = useRef<HTMLInputElement>(null);

  return (
    <div
      className={cn(css.upload, {
        loadImg,
        isDisabled: disabled,
      })}
    >
      <div
        className={cn(css['file-loader'], {
          isUpload: upload,
          isError: error,
          isComplete: complete && !error,
        })}
      >
        {imgUrl ? (
          <div
            className={css['file-loader__image']}
            style={{
              backgroundImage: `url('${imgUrl}')`,
            }}
          />
        ) : (
          <div className={cn(css['file-loader__image'], 'empty')}>
            <FiImage size={24} />
          </div>
        )}
        <Icons />
      </div>
      <div className={css.text}>
        <div className={css.links}>
          <label className={css.file} htmlFor="file">
            <input
              id="file"
              onChange={onChangeFile}
              accept="image/jpeg, image/jpg, image/png, image/svg"
              type="file"
            />
            {t('clients_page.client_create.client_load_img')}
          </label>
          <span>·</span>
          <button type="button" onClick={openMLinkCallback}>
            {t('clients_page.client_create.client_img_link')}
          </button>
        </div>

        <Info
          uploaderRef={uploaderRef}
          errorCode={errorImage.code}
          tempFile={tempImage || undefined}
          loadFile={loadImg}
          onDelLoadImg={onDelLoadImg}
          setLoadImg={setLoadImg}
        />
      </div>
    </div>
  );
};

export default UploadImage;
