import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import { workerCreateCounteragent, workerGetCounteragent, workerUpdateCounteragent } from './workers';
import { createCounteragent, getCounteragent, updateCounteragent } from '../actions';

export default function* watchCounteragent(): SagaIterator {
  yield takeLatest(getCounteragent, workerGetCounteragent);
  yield takeLatest(createCounteragent, workerCreateCounteragent);
  yield takeLatest(updateCounteragent, workerUpdateCounteragent);
}
