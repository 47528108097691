import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData } from 'redux/fetchedData';
import { genFetchedData } from '../../redux/fetchedData';
import { THistoryResponse } from './types';

export interface HistoryState {
  historyData: FetchedData<THistoryResponse[]>;
}

export const initialState: HistoryState = {
  historyData: genFetchedData<THistoryResponse[]>(null),
};

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    setHistoryData(state, action: PayloadAction<HistoryState['historyData']>) {
      state.historyData = action.payload;
    },
  },
});

export const { setHistoryData } = historySlice.actions;

export const historyReducer = historySlice.reducer;
