import React, { FC } from 'react';
import cn from 'classnames';
import { useUserTheme } from 'domains/user/model/selectors';
import { FiX, FiXCircle } from 'react-icons/fi';
import { FetchedDataObj } from 'redux/fetchedData';
import { Link } from 'react-router-dom';
import { ErrorDescription, ErrorsMap } from 'shared/dictionaryErrors';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';

interface ITargetItemAlertProps {
  error?: FetchedDataObj<boolean>['error'];
  handlerClose: () => void;
  errorValidateText?: string;
}

const TargetItemAlert: FC<ITargetItemAlertProps> = ({
  error,
  handlerClose,
  errorValidateText,
}) => {
  const { isThemeFull } = useUserTheme();
  const erDescMessage = error
    ? ErrorDescription.get(`${error.description}`)
    : null;
  const erMessage = error ? ErrorsMap.get(Number(error.code)) : null;
  const { t } = useTranslation();

  return (
    <div
      className={cn(css.alert, {
        isExtend: isThemeFull,
      })}
    >
      <div className={css.alert__data}>
        <div className={css.alert__data_icon}>
          <FiXCircle size={22} />
        </div>
        <div className={css.alert__data_text}>
          {errorValidateText}
          {erMessage && t(erMessage)}
          <br />
          {erDescMessage && t(erDescMessage)}
          {error?.creatives?.map(
            ({ title, xxhash, campaign_xxhash }, index) => (
              <Link
                to={`/main/campaign/${campaign_xxhash}/creatives/${xxhash}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <strong>
                  {title} {error?.creatives?.length !== index + 1 && ', '}
                </strong>
              </Link>
            ),
          )}
        </div>
      </div>
      <div className={css.alert__close} onClick={handlerClose}>
        <FiX size={22} />
      </div>
    </div>
  );
};

export default TargetItemAlert;
