import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import './styles.scss';
import { FiChevronDown, FiLogOut, FiUser } from 'react-icons/fi';
import userPng from 'assets/ava.png';
import { wssCloseConnect } from '../../domains/wss/model/actions';
import { useUserInfo } from '../../domains/user/model/selectors';
import { useDispatchApp } from '../../redux/rootSelectors';
import { userNotificationPush } from '../../domains/user/model/hooks';

const HeaderAccount: FC = () => {
  const [toggle, setToggle] = React.useState(false);
  const dispatch = useDispatchApp();
  const { data: user } = useUserInfo();
  const history = useHistory();

  const { t } = useTranslation();

  const onHide = (event: Event) => {
    const targetDropdown = (event.target as HTMLElement).closest(
      '.header-account__dropdown',
    );
    const targetBtn = (event.target as HTMLElement).closest(
      '.header-account__text',
    );

    if (targetDropdown || targetBtn) {
      return;
    }

    setToggle(false);
  };

  useEffect(() => {
    document.addEventListener('click', onHide);

    return () => document.removeEventListener('click', onHide);
  }, []);

  const onToggle = () => setToggle(!toggle);

  const logOut = () => {
    const { messaging } = userNotificationPush();
    if (messaging) {
      localStorage.removeItem('$push-token');
      messaging.deleteToken();
    }
    dispatch(wssCloseConnect());
    dispatch({ type: 'AUTH_LOGOUT_SUCCESS' });
    history.push('/');
  };

  const goToProfile = () => {
    history.push('/main/profile/');
    setToggle(false);
  };

  const onCloseProfile = () => {
    setToggle(false);
  };

  useEffect(() => {
    document.addEventListener('scroll', onCloseProfile);

    return () => {
      document.removeEventListener('scroll', onCloseProfile);
    };
  }, []);

  return (
    <div className={cn('header-account', { isOpened: toggle })}>
      <div
        className={cn(
          'header-account__icon',
          user && user.partner.icon ? '' : 'isDefault',
        )}
        style={{
          backgroundImage: `url(${
            user && user.partner.icon ? user && user.partner.icon : userPng
          })`,
        }}
      />

      <div className="header-account__text" onClick={onToggle}>
        <div className="header-account__login">
          <span>{user && user.email}</span>
          <FiChevronDown
            size={18}
            color="#fff"
            className="header-account__chevron"
          />
        </div>
        <div className="header-account__balance">
          {window.location.hostname !== 'my.turbotargeting.io' && (
            <span>
              {user && user.partner.type === 'ORGANIZATION'
                ? t('top.partner_organization')
                : t('top.partner_managed')}
            </span>
          )}
        </div>
      </div>

      <div className="header-account__dropdown">
        <div className="item" onClick={goToProfile}>
          <FiUser size={24} color="#222" />
          <span>{t('profile.profile_word')}</span>
        </div>
        <div className="item" onClick={logOut}>
          <FiLogOut size={24} />
          <span>{t('top.exit')}</span>
        </div>
      </div>
    </div>
  );
};

export default HeaderAccount;
