import { SagaIterator } from 'redux-saga';
import { takeEvery, takeLatest } from 'redux-saga/effects';
import { fetchBlDomains, fetchChangeStatusFields } from '../reducer';
import {
  workerFetchChangeStatusFields,
  workerFetchGetBlDomainsList,
} from './workers';

export default function* watchBlDomains(): SagaIterator {
  yield takeEvery(fetchBlDomains, workerFetchGetBlDomainsList);
  yield takeLatest(fetchChangeStatusFields, workerFetchChangeStatusFields);
}
