import { FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import { TFormikProps } from '../types';

type ValidationType = (
  val: TFormikProps,
) => FormikErrors<Record<keyof TFormikProps, string>>;

interface IUseValidateOfferReturn {
  validate: ValidationType;
}

export const useValidateOffer = (): IUseValidateOfferReturn => {
  const { t } = useTranslation();

  const validate: ValidationType = (values) => {
    const errors: FormikErrors<Record<keyof TFormikProps, string>> = {};
    const url =
      /^((https?:\/\/|www\.))[a-z0-9а-я]+([\-_.]{1}[a-z0-9а-я]+)*\.[a-zа-я]{2,20}(:[0-9]{1,5})?(\/?.*)?$/m;
    const macrosReg = /^(\{\w+\}\s?)+$/gi;
    const domainFormat =
      /[a-z0-9а-я]+([\-_.]{1}[a-z0-9а-я]+)*\.[a-zа-я]{2,20}(:[0-9]{1,5})?(\/.*)?$/m;

    if (!(values.title.length > 0)) {
      errors.title = t('creative.creative_requare');
    }

    if (values.title && values.title.length >= 255) {
      errors.title = t('errors.limitsym');
    }

    if (!(values.clients.length > 0)) {
      errors.clients = t('creative.creative_requare');
    }

    if (values.link && !url.test(values.link)) {
      if (!macrosReg.test(values.link)) {
        errors.link = t('errors.err_http_lorem');
      }
    }
    if (values.link && values.link.length >= 128) {
      errors.link = t('errors.limitsym');
    }

    if (values.adomain && values.adomain.length >= 1024) {
      errors.adomain = t('errors.limitsym');
    }

    if (values.adomain && !domainFormat.test(values.adomain)) {
      errors.adomain = t('errors.err223');
    }

    if (values.pixel && values.pixel.length > 0 && !url.test(values.pixel)) {
      errors.pixel = t('errors.err_http_lorem2');
    }

    if (
      values.pixel &&
      values.pixel.length > 0 &&
      values.pixel.length >= 1024
    ) {
      errors.pixel = t('errors.limitsym');
    }

    return errors;
  };

  return {
    validate,
  };
};
