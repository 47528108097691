import BaseModal from 'components/UI/BaseModal';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import cn from 'classnames';
import ModalOverlay from 'components/Modals/ModalOverlay1';
import Card from 'components/UI/Card';
import Button from 'components/UI/Buttons/buttons';
import { FiX } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';
import { ReactComponent as SucessSvg } from './icons/success.svg';
import { ReactComponent as ErrorSvg } from './icons/error.svg';

interface ISaveChangeModalProps {
  onClose: () => void;
  goBack: () => void;
  title: string;
  success: boolean;
  baseContent: boolean;
  savingProcess: boolean;
  showCreoCount: boolean;
  error: string;
  innerText: string;
  creoCount: number;
  actions: ReactNode;
  infoBlockText?: string | ReactNode;
}

const SaveChangeModal: FC<ISaveChangeModalProps> = ({
  onClose,
  goBack,
  title,
  error,
  innerText,
  actions,
  infoBlockText,
  creoCount,
  success,
  showCreoCount,
  baseContent,
  savingProcess,
}) => {
  const [loading, setLoading] = useState<number>(1);
  const { t } = useTranslation();

  useEffect(() => {
    if (savingProcess) {
      setTimeout(() => {
        if (loading < 90) {
          setLoading(loading + 1);
        }
      }, 200);
    }
  }, [savingProcess, loading]);
  
  return (
    <>
      {baseContent && (
        <BaseModal onClose={onClose} isOpen title={title} actions={actions}>
          <div
            className={cn(css.saveModal, {
              infoBlockText,
            })}
          >
            {innerText}
          </div>
{/*           {infoBlockText && !!creoCount && (
            <InfoBlock color="BLUE" text={infoBlockText} />
          )} */}
        </BaseModal>
      )}
      {savingProcess && (
        <ModalOverlay onCloseModal={onClose} isOpen>
          <Card className={css.card} noHover>
            <div className={cn(css.result, css.saving)}>
              <div className={css.title}>{title}</div>
              <div className={css.saving_line}>
                <div
                  className={css.saving_lineColor}
                  style={{
                    width: `${loading}%`,
                  }}
                />
              </div>
              <div className={css.text}>{t(`offers_page.some_time`)}</div>
            </div>
          </Card>
        </ModalOverlay>
      )}
      {success && (
        <ModalOverlay onCloseModal={onClose} isOpen>
          <Card className={css.card} noHover>
            <div className={cn(css.result, css.success)}>
              <SucessSvg />
              <div className={css.title}>{t(`offers_page.chanes_saved`)}</div>
              {!!creoCount && showCreoCount && (
                <div className={css.text}>
                  {t(`offers_page.links_chaned_1`)}{' '}
                  <strong>
                    {creoCount} {t(`offers_page.links_chaned_2`)}
                  </strong>
                </div>
              )}
              <Button
                title={t(`offers_page.back_to_list`)}
                buttonAttributes={{ onClick: goBack }}
              />
            </div>
            <div className={css.card_close} onClick={onClose}>
              <FiX size={24} />
            </div>
          </Card>
        </ModalOverlay>
      )}
      {error && (
        <ModalOverlay onCloseModal={onClose} isOpen>
          <Card className={css.card} noHover>
            <div className={cn(css.result, css.success)}>
              <ErrorSvg />
              <div className={css.title}>{t(`offers_page.somthing_wrong`)}</div>
              <div className={css.text}>{error}</div>
            </div>
            <div className={css.card_close} onClick={onClose}>
              <FiX size={24} />
            </div>
          </Card>
        </ModalOverlay>
      )}
    </>
  );
};

export default SaveChangeModal;
