import { CreativeDetail } from 'domains/creative/types';
import { CSSProperties } from 'react';

type IDataModalSize = {
  size?: CreativeDetail['size'];
};

type IDataModal = (p: IDataModalSize) => {
  width: number;
  height: number;
  iFrameProps: CSSProperties;
};

export const useDataModal: IDataModal = ({ size }) => {
  const defaultSize = '160x600'
  const sizeAr = size ? size.split('x') : defaultSize.split('x');
  const width = Number(sizeAr[0] ?? 0);
  const height = Number(sizeAr[1] ?? 0);

  const window_w = (window.innerWidth / 100) * 70 - 308;
  const window_h = (window.innerHeight / 100) * 75 - 56;

  const iWidth = window_w / width;
  const iHeight = window_h / height;

  let iFrameProps = {};

  if (window_w < width || window_h < height) {
    if (iWidth < iHeight) {
      iFrameProps = {
        transform: `scale(${iWidth})`,
      };
    } else {
      iFrameProps = {
        transform: `scale(${iHeight})`,
      };
    }
  }

  return {
    width,
    height,
    iFrameProps,
  };
};
