import { SagaIterator } from '@redux-saga/core';
import { takeLatest, takeEvery } from 'redux-saga/effects';
import {
  changeLangRequest,
  editProjectRequest,
  fetchLangRequest,
  fetchProjectAction,
  fetchProjectTechnicalWorks,
} from '../reducer';
import {
  editProjectWorker,
  fetchGroupsWorker,
  getLangWorker,
  setFileProjectWorker,
  setLangProject,
  workerFetchProjectSaga,
  workerFetchTechnicalWorks,
} from './workers';
import { fetchGroups, setFilesProjectAction } from '../model/actions';

export default function* watcherProject(): SagaIterator {
  yield takeEvery(fetchProjectAction, workerFetchProjectSaga);
  yield takeLatest(editProjectRequest, editProjectWorker);
  yield takeEvery(fetchProjectTechnicalWorks, workerFetchTechnicalWorks);
  yield takeEvery(fetchLangRequest, getLangWorker);
  yield takeEvery(changeLangRequest, setLangProject);
  yield takeEvery(fetchGroups, fetchGroupsWorker);
  yield takeLatest(setFilesProjectAction, setFileProjectWorker);
}
