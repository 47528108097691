import Card from 'components/UI/Card';
import Tooltip from 'components/UI/Tooltip';
import React, { FC } from 'react';
import { FiAlertCircle, FiRefreshCcw } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { TScreenStatus } from 'domains/creative/types';
import cn from 'classnames';
import css from './styles.module.scss';
import ScreenSvg from '../../assets/screen.svg';
import NoScreenshot from './NoScreenshot';
import ErrorScreenshot from './ErrorScreenshot';
import SuccessScreenshot from './SuccessScreenshot';
import LoaderView from './LoaderView';

interface Props {
  updateScreenStatusHandler: () => void;
  createScreenHandler: () => void;
  isDisableCreate: boolean;
  screenStatus: TScreenStatus | null;
  creoTitle: string | undefined;
  textError: string;
}

const ScreenshotView: FC<Props> = ({
  creoTitle,
  screenStatus,
  textError,
  isDisableCreate,
  updateScreenStatusHandler,
  createScreenHandler,
}) => {
  const { t } = useTranslation();

  const checkCurrentView = (screenData: TScreenStatus) => {
    const { status, reason, screenshot } = screenData;
    if (status === 'ERROR' || textError)
      return <ErrorScreenshot errorData={reason} textError={textError} />;
    if (status === 'NO SENT')
      return <NoScreenshot createScreenHandler={createScreenHandler} />;
    if (status === 'SUCCESS')
      return (
        <SuccessScreenshot screenLink={screenshot} creoTitle={creoTitle} />
      );
    return <LoaderView status={status} />;
  };

  return (
    <Card noHover className={css.widget}>
      <header className={css.widget_header}>
        <div className={css.title}>
          <span>{t('creative.screenshot.screen')}</span>
          {screenStatus?.status === 'SUCCESS' && (
            <Tooltip
              title={<FiAlertCircle size={24} />}
              className={css.alertCircle}
              hintWidth={267}
            >
              <p>{t('creative.screenshot.hint')}</p>
            </Tooltip>
          )}
        </div>
        <div className={css.actions}>
          <Tooltip
            title={
              <div className={css.update}>
                <FiRefreshCcw size={20} onClick={updateScreenStatusHandler} />
              </div>
            }
          >
            {t(`creative.screenshot.refresh`)}
          </Tooltip>
          <Tooltip
            isDisabled={isDisableCreate}
            onClick={createScreenHandler}
            title={
              <div
                className={cn(css.screen, {
                  [css.disabled]: isDisableCreate,
                })}
              >
                <ReactSVG src={ScreenSvg} />
              </div>
            }
          >
            {t('creative.screenshot.create')}
          </Tooltip>
        </div>
      </header>
      <main className={css.widget_body}>
        {screenStatus && checkCurrentView(screenStatus)}
      </main>
    </Card>
  );
};

export default ScreenshotView;
