import Button from 'components/UI/Buttons/buttons';
import Tooltip from 'components/UI/Tooltip';
import React, { FC } from 'react';
import cn from 'classnames';
import { FiDownload } from 'react-icons/fi';
import css from '../styles.module.scss';
import i18n from '../../../../i18n';

type Props = {
  submitOnClickHandler: () => void;
  onGetReport: () => void;
  checkCurrentColumn: () => void;
  disGetReport: boolean;
};

const Actions: FC<Props> = ({
  submitOnClickHandler,
  onGetReport,
  disGetReport,
  checkCurrentColumn,
}) => (
  <div className={css.actions}>
    <Button
      title={i18n.t(`show_btn`)}
      buttonAttributes={{
        onClick: () => {
          checkCurrentColumn();
          submitOnClickHandler();
        },
      }}
    />
    <div
      className={cn(css.download_btn)}
      onClick={() => {
        if (!disGetReport) {
          onGetReport();
        }
      }}
    >
      <Tooltip title={<FiDownload size={24} />} left>
        {i18n.t(`download_btn`)}
      </Tooltip>
    </div>
  </div>
);

export default Actions;
