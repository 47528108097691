import { useRouteMatch } from 'react-router-dom';
import { useEffect } from 'react';
import { useCampaignCommentsInfo } from '../selectors';
import { useDispatchApp } from '../../../../redux/rootSelectors';
import { TID } from '../../../../types/general';
import {
  addCampaignComment,
  deleteCampaignComment,
  editCampaignComment,
  fetchCampaignComments,
} from '../actions';
import { setIsShowAllComments } from '../../../comment/model/reducer';

type TFUseCampaignComments = () => {
  comments: ReturnType<typeof useCampaignCommentsInfo>['data'];
  isLoading: ReturnType<typeof useCampaignCommentsInfo>['isLoading'];
  LTU: ReturnType<typeof useCampaignCommentsInfo>['LTU'];
  handleAddComment: (text: string) => void;
  handleEditComment: (
    comment_id: number,
    text: string,
    callback: () => void,
  ) => void;
  handleDeleteComment: (comment_id: number, callback: () => void) => void;
  handleFetchComments: () => void;
};

export const useCampaignComments: TFUseCampaignComments = () => {
  const { params } = useRouteMatch<TID>();
  const dispatch = useDispatchApp();

  const isShowAllComment = useCampaignCommentsInfo();

  const handleFetchComments = () => {
    dispatch(fetchCampaignComments({ xxhash: params.id }));
  };

  useEffect(() => {
    handleFetchComments();
    return () => {
      if (isShowAllComment) {
        dispatch(setIsShowAllComments(false));
      }
    };
  }, [params.id]);

  const { data, isLoading, LTU } = useCampaignCommentsInfo();

  const handleAddComment: ReturnType<TFUseCampaignComments>['handleAddComment'] =
    (text) => {
      dispatch(addCampaignComment({ xxhash: params.id, text }));
    };

  const handleEditComment: ReturnType<TFUseCampaignComments>['handleEditComment'] =
    (comment_id, text, callback) => {
      dispatch(
        editCampaignComment({ text, comment_id, xxhash: params.id, callback }),
      );
    };

  const handleDeleteComment: ReturnType<TFUseCampaignComments>['handleDeleteComment'] =
    (comment_id, callback) => {
      dispatch(
        deleteCampaignComment({ comment_id, xxhash: params.id, callback }),
      );
    };

  return {
    comments: data,
    isLoading,
    handleAddComment,
    handleEditComment,
    handleDeleteComment,
    handleFetchComments,
    LTU,
  };
};
