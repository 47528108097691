import { createAction } from '@reduxjs/toolkit';
import {
  CreateCounteragentAction,
  GetCounteragentParams,
  UpdateCounteragentAction,
} from './types';

export const getCounteragent = createAction<GetCounteragentParams>(
  'counteragent/getCounteragent',
);

export const createCounteragent = createAction<CreateCounteragentAction>(
  'counteragent/createCounteragent',
);

export const updateCounteragent = createAction<UpdateCounteragentAction>(
  'counteragent/updateCounteragent',
);
