import { SagaIterator } from 'redux-saga';
import { takeEvery, takeLatest } from '@redux-saga/core/effects';
import { fetchContainerByIDSaga, onDownloadContainerByIDSaga } from './workers';
import {
  fetchContainerDetailByID,
  onDownloadContainerRequest,
} from '../reducer';

export default function* watcherContainerById(): SagaIterator {
  yield takeEvery(fetchContainerDetailByID, fetchContainerByIDSaga);
  yield takeLatest(onDownloadContainerRequest, onDownloadContainerByIDSaga);
}
