import { useCallback } from 'react';
import { setIsShowMCreate } from 'domains/agency/reducer';
import { useDispatchApp } from 'redux/rootSelectors';

type TFUseIsShowMCreate = (props: boolean) => () => void;

export const useIsShowMCreate: TFUseIsShowMCreate = (value) => {
  const dispatch = useDispatchApp();
  return useCallback(() => {
    dispatch(setIsShowMCreate(value));
  }, []);
};
