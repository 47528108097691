import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import css from './styles.module.scss';
import { useToolTip } from './hooks/useToolTip';

interface Props {
  /** Заголовок */
  title: ReactNode;
  /** Заголовок Hinit */
  children?: ReactNode;
  className?: string;
  classNameHint?: string;
  /** Hinit будет справа */
  right?: boolean;
  /** Hinit будет внизу */
  bottom?: boolean;
  /** Hinit будет слева */
  left?: boolean;
  /** Белый цвет Hinit (по умолчанию черный) */
  white?: boolean;
  /** Ширина Hinit */
  hintWidth?: number;
  /* Тултип не по центру, а в самомо начале эдлемента слева */
  leftArrowPosition?: boolean;
  /** Функция клика */
  onClick?: (e?: React.MouseEvent) => void;
  isDisabled?: boolean;
  /** Если есть ссылка добавить ее к Hinit */
  link?: string;
  /** Скрывает Hint с описанием */
  hideHint?: boolean;
}

const Tooltip: React.FC<Props> = ({
  title,
  children,
  className,
  classNameHint,
  right,
  bottom,
  left,
  white,
  hintWidth,
  onClick,
  isDisabled = false,
  link,
  leftArrowPosition,
  hideHint = false,
}) => {
  const {
    fixTopPadding,
    fixLeftPadding,
    fixRightPadding,
    fixbottomPadding,
    setSidePadding,
    hintRef,
  } = useToolTip();

  return (
    <div
      className={cn(css.tooltip, className, 'tooltipComponent', {
        _isWhite: white,
        _isRight: right,
        _isBottom: bottom,
        _isLeft: left,
        _isFixLeftPadding: fixLeftPadding,
        _isFixRightPadding: fixRightPadding,
        _isFixTopPadding: fixTopPadding,
        _isFixBottomPadding: fixbottomPadding,
        _isPositionLeft: leftArrowPosition,
        isDisabled,
      })}
      onClick={() => {
        if (!isDisabled && typeof onClick === 'function') {
          onClick();
        }
      }}
      onMouseEnter={setSidePadding}
    >
      <div className={cn(css.tooltip__title, 'tooltipComponentTitle')}>
        {title}
      </div>
      {!hideHint &&
        (link ? (
          <Link to={`${link}`}>
            <div
              className={cn(css.tooltip__hint, classNameHint)}
              ref={hintRef}
              style={{ maxWidth: `${hintWidth}px` }}
            >
              {children && children}
            </div>
          </Link>
        ) : (
          <div
            className={cn(
              css.tooltip__hint,
              classNameHint,
              'tooltipComponentHint',
              `${fixbottomPadding}`,
            )}
            ref={hintRef}
            style={{ maxWidth: `${hintWidth}px` }}
          >
            {children && children}
          </div>
        ))}
    </div>
  );
};

export default Tooltip;
