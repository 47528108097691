import React, { FC } from 'react';
import cn from 'classnames';
import NavigationHeader from 'components/NavigationHeader';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';

type Props = {
  title: string;
};

const Header: FC<Props> = ({ title }) => {
  const { t } = useTranslation();
  return (
    <>
      <NavigationHeader
        back={{
          title: t('creative.header_menu.back'),
          to: '/control_panel/project',
        }}
      >
        <NavLink
          to="/control_panel/project/settings"
          activeClassName="isActive"
        >
          {t('project_page.main_settings')}
        </NavLink>
        <NavLink
          to="/control_panel/project/additional"
          activeClassName="isActive"
        >
          {t('campaigns_page.campaign_settings.black_menu.Settings_aditional')}
        </NavLink>
      </NavigationHeader>
      <div className={css.header}>
        <div className={cn(css.container, 'container')}>
          <div className={css.info}>
            <div className={css.status}>
              <h3 className={css.title}>{title}</h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
