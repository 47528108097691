import { useManagementActivitySortUsersInfo } from '../selectors';
import { useDispatchApp } from '../../../../redux/rootSelectors';
import { setUsersActivitySort } from '../reducer';

type TFUseSortActivityUsers = () => ReturnType<
  typeof useManagementActivitySortUsersInfo
> & {
  handleSort: (
    p: ReturnType<typeof useManagementActivitySortUsersInfo>,
  ) => void;
};

export const useSortActivityUsers: TFUseSortActivityUsers = () => {
  const dispatch = useDispatchApp();

  const { sort, key } = useManagementActivitySortUsersInfo();

  const handleSort: ReturnType<TFUseSortActivityUsers>['handleSort'] = (p) => {
    dispatch(setUsersActivitySort(p));
  };

  return {
    sort,
    key,
    handleSort,
  };
};
