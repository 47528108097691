import { select, call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { fetchPartnerBalance } from 'domains/campaigns/model/actions';
import {
  setPartnerBalance,
  setPartnersBalance,
  setTempPartners,
} from '../reducer';
import {
  makeReqWithRD,
  TMakeReqWithRD,
  updateFillFetchedData,
} from '../../../redux/makeReqWithRD';
import {
  getOrganizationStructure,
  getPartnerBalance,
  getPartnersBalance,
  getPaymentLink,
  transferMoneyApi,
} from '../api';
import { extractGenFetchData, FetchedData } from '../../../redux/fetchedData';
import { RIPartners, IPartnersList, TPartnersBalance } from '../types';
import {
  editPartnerAction,
  fetchAgencyList,
  fetcherPartnerBalance,
  fetcherPartnersBalance,
  fetchLinkBalance,
  transferMoney,
} from '../model/actions';
import updateToken from '../../../utils/updateToken';
import { editAgencyApi } from '../../agency/api';
import { selectorPartnersList } from '../model/selector';
import { toastSuccess } from '../../../utils/ToastUtils/toastUtils';

export function* workerGetAgencySaga({
  payload,
}: ReturnType<typeof fetchAgencyList>): SagaIterator<void> {
  const {fetchBalanceBlock, ...restPayload} = payload
  try {
    yield call<TMakeReqWithRD<typeof getOrganizationStructure>>(makeReqWithRD, {
      fetcher: getOrganizationStructure,
      fill: (res: FetchedData<RIPartners>) => {
        const data = res.get('data');
        const newData: IPartnersList = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < Object.keys(data || {}).length; i++) {
          const country = Object.keys(data || {})[i];
          if (data) {
            newData.push(...data[country]);
          }
        }
        const fetchedData = updateFillFetchedData<RIPartners, IPartnersList>({
          fillFetchedData: res,
          newData,
        });
        return setTempPartners(fetchedData);
      },
      parameters: restPayload,
    });

    const { data } = extractGenFetchData<IPartnersList>(
      yield select(selectorPartnersList),
    );
    if (data && !fetchBalanceBlock) {
      const partner_xxhash_list = data
        .filter(({ type }) => type === 'CLIENT' || 'AGENCY')
        .map(({ xxhash }) => xxhash);
      yield put(fetcherPartnersBalance({ partner_xxhash_list }));
    }
  } catch (e) {
    console.log({ e });
  }
}

export function* editPartnerWorker({
  payload,
}: ReturnType<typeof editPartnerAction>): SagaIterator<void> {
  try {
    const { data, visual } = payload;
    let result = false;
    yield call(updateToken);
    if (data.type === 'AGENCY') {
      result = yield call(editAgencyApi, {
        id: data.xxhash,
        registration: data.registration,
      });
    }
    if (result && visual) {
      const { alertText, setter, value } = visual;
      if (setter) setter(value);
      toastSuccess(alertText);
    }
  } catch (e) {
    console.error(e);
  }
}

export function* fetcherPartnersBalanceWorker({
  payload,
}: ReturnType<typeof fetcherPartnersBalance>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getPartnersBalance>>(makeReqWithRD, {
      fetcher: getPartnersBalance,
      fill: (v) => {
        const oldData = v.get('data');
        const newData: TPartnersBalance = [];
        if (oldData) {
          Object.entries(oldData).forEach(([k, value]) => {
            newData.push({ ...value, xxhash: k });
          });
        }
        return setPartnersBalance(
          updateFillFetchedData({ fillFetchedData: v, newData }),
        );
      },
      parameters: payload,
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* fetchPartnerBalanceWorker({
  payload,
}: ReturnType<typeof fetcherPartnerBalance>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getPartnerBalance>>(makeReqWithRD, {
      fetcher: getPartnerBalance,
      fill: setPartnerBalance,
      parameters: payload,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* fetchPaymentLinkWorker({
  payload,
}: ReturnType<typeof fetchLinkBalance>): SagaIterator<void> {
  try {
    const result = yield call(getPaymentLink, payload);
    const link = document.createElement('a');
    link.href = result;
    // link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.click();
  } catch (e) {
    console.error(e);
  }
}

export function* transferMoneyWorker({
  payload,
}: ReturnType<typeof transferMoney>): SagaIterator<void> {

  const { callbacks, alertText, partner_xxhash, ...restPayload } = payload

  try {
    if(callbacks?.setisLoading) {
      callbacks?.setisLoading(true)
    }
    const result = yield call(transferMoneyApi, restPayload);

    if(result) {

      toastSuccess(alertText)
      if(partner_xxhash) {
        yield put(fetchPartnerBalance({ partner_xxhash }))
      }

      if(callbacks?.onClose) {
        callbacks.onClose()
      }
    }
    
  } catch (e) {
    console.error(e);
  } finally {
    if(callbacks?.setisLoading) {
      callbacks?.setisLoading(false)
    }
  }
}
