import React, { CSSProperties, FC, useRef } from 'react';
import cn from 'classnames';
import { FiChevronDown, FiHelpCircle } from 'react-icons/fi';
import useDropDown from 'hooks/useDropDown';
import { FieldProps } from 'formik';
import Tooltip from '../Tooltip';
import css from './styles.module.scss';
import ErrorText from '../ErrorText';

type Props = {
  label: string;
  error?: boolean | undefined;
  errortext?: string;
  tooltip?: JSX.Element;
  disabled?: boolean | undefined;
  options: string[];
  uppercase?: boolean;
  placeholder?: string;
  form: FieldProps['form'];
  field: FieldProps['field'];
  notField?: boolean;
  className?: string;
  customChange?: (v: string) => void;
  style?: CSSProperties;
};

/**
 * @deprecated используй inputSelect
 */

const Select: FC<Props> = ({
  label,
  error,
  errortext,
  disabled,
  tooltip,
  options,
  placeholder,
  uppercase,
  field: { name, value },
  form: { setFieldValue },
  notField,
  className,
  customChange,
  style,
}) => {
  const dropdownMenu = useRef<HTMLDivElement | null>(null);
  const { toggle, isOpened, toggleOff } = useDropDown(dropdownMenu);

  const onChange = (val: string) => {
    if (customChange) {
      customChange(val);
    } else {
      setFieldValue(name, val);
    }

    toggleOff();
  };

  return (
    <div
      className={cn(css['form-group'], error ? css['form-group_error'] : '', {
        isDisabled: disabled,
        isUppercase: uppercase,
      })}
      ref={dropdownMenu}
      style={style}
    >
      {label && (
        <label htmlFor={label}>
          {label}
          {tooltip && (
            <Tooltip
              className={css['form-group__tooltip']}
              title={<FiHelpCircle size={16} color="#222" />}
              white
              hintWidth={300}
            >
              <p>{tooltip}</p>
            </Tooltip>
          )}
        </label>
      )}

      {notField ? (
        <div className={css.title}>{value}</div>
      ) : (
        <div className={cn(css['form-group__select'], className)}>
          <div
            className={cn(css['form-group__active'], {
              _isOpened: isOpened,
              _isError: error,
            })}
            onClick={isOpened ? toggleOff : toggle}
          >
            <span>{value && value.length > 0 ? value : placeholder}</span>
            <FiChevronDown size={24} />
          </div>

          <div
            className={cn(css['form-group__dropdown'], {
              _isOpened: isOpened,
            })}
          >
            {options.map((item) => (
              <div
                className={cn(css['form-group__item'], {
                  _current: value === item,
                })}
                key={item}
                onClick={() => onChange(item)}
              >
                <span>{item}</span>
              </div>
            ))}
          </div>
        </div>
      )}

      {error && <ErrorText text={errortext} />}
    </div>
  );
};

export default Select;
