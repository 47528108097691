import React, { FC } from 'react';
import cn from 'classnames';
import { useUserTheme } from 'domains/user/model/selectors';
import css from './styles.module.scss';

const ResizedCard: FC = ({ children }) => {
  const { isThemeExtended, isThemeFull } = useUserTheme();
  return (
    <div
      className={cn('container resized', {
        extend: isThemeExtended,
        full: isThemeFull,
      })}
    >
      <div className={css.card}>
        <div className={css.children}>{children}</div>
      </div>
    </div>
  );
};

export default ResizedCard;
