import ModalOverlay from 'components/Modals/ModalOverlay1';
import Button from 'components/UI/Buttons/buttons';
import Input from 'components/UI/Input/input';
import { TCommentList } from 'domains/comment/model/types';
import React, { FC, RefObject } from 'react';
import { FiX } from 'react-icons/fi';
import i18next from '../../../../i18n';
import css from '../Comments/styles.module.scss';
import { useCommentModal } from '../../model/hook/useCommentsModal';

type ModalCommentProps = {
  isOpen: boolean;
  onCloseModal: () => void;
  type: 'add' | 'edit';
  comment_id: RefObject<number | null>;
  handlerAddComment: (v: string) => void;
  handleEditComment: (
    comment_id: number,
    text: string,
    callback: () => void,
  ) => void;
  comments: TCommentList;
  onOpenModalDelete: () => void;
  updateTimeoutCommentList: (id: number, type: 'edited' | 'deleted') => void;
};

const ModalComment: FC<ModalCommentProps> = ({
  isOpen,
  onCloseModal,
  type,
  comment_id,
  handlerAddComment,
  handleEditComment,
  comments,
  onOpenModalDelete,
  updateTimeoutCommentList,
}) => {
  const { isDisabled, handlerSendForm, onChangeInput, value, isError } =
    useCommentModal({
      updateTimeoutCommentList,
      comment_id,
      onCloseModal,
      type,
      comments,
      handlerAddComment,
      handleEditComment,
    });

  return (
    <ModalOverlay onCloseModal={onCloseModal} isOpen={isOpen}>
      <div className={css.card}>
        <div className={css.header}>
          <div className={css.title}>
            <h1>
              {type === 'edit'
                ? i18next.t(`campaigns_page.campaigns_comments.edit_comment`)
                : i18next.t(
                    `campaigns_page.campaigns_comments.add_comment_tit`,
                  )}
            </h1>
          </div>
          <div className={css.close}>
            <FiX size={24} onClick={onCloseModal} />
          </div>
        </div>
        <div className={css.content}>
          <Input
            isTextarea
            classname={css.textarea}
            inputAttributes={{
              autoFocus: true,
              name: 'comments',
              onChange: onChangeInput,
              value,
              autoComplete: 'off',
              maxLength: 500,
            }}
          />
        </div>

        <div className={css.actions}>
          <div className={css.info}>
            <div className={css.symbols}>
              {value.length}
              {'/ 500 '}
              {i18next.t(`campaigns_page.campaigns_comments.symbols`)}
            </div>
            {isError && (
              <div className={css.error}>
                {i18next.t(`campaigns_page.campaigns_comments.save_error`)}
              </div>
            )}
          </div>
          <div className={css.buttons}>
            <Button
              red={type === 'edit'}
              transparent
              title={
                type === 'edit'
                  ? i18next.t(`delete_btn`)
                  : i18next.t(`cancel_btn`)
              }
              lightblue
              buttonAttributes={{
                onClick: type === 'edit' ? onOpenModalDelete : onCloseModal,
              }}
            />
            <Button
              title={
                type === 'edit' ? i18next.t(`save_btn`) : i18next.t(`add_btn`)
              }
              buttonAttributes={{
                disabled: isDisabled(),
                onClick: handlerSendForm,
              }}
            />
          </div>
        </div>
      </div>
    </ModalOverlay>
  );
};
export default ModalComment;
