import React, { FC, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import cn from 'classnames';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import { useTranslation } from 'react-i18next';
import SwitchItem from 'components/UI/SwitchItem';
import { useOnScroll } from 'hooks/useOnScroll';
import { useUserTheme } from 'domains/user/model/selectors';
import ToggleSwitch from 'components/UI/ToggleSwitch';
import { KeysSettings, useStateValue } from 'utils/context';
import InternalId from 'components/InternalId';
import { useSwitchNavigation } from 'hooks/useSwitchNavigation';
import { fetchPDataByTarget } from 'domains/campaign/model/actions';
import Item from './item';
import DateRangeFilter from '../../components/DateRangeFilter';
import { parsingDate } from '../../utils/momentParse';
import OnlyOwner from '../../components/OnlyOwner';
import Options from './Options';
import Loader from '../../components/UI/Loader';
import { fetchClearTarget, fetchGetList } from './actions';
import ErrorReport from '../../components/Errors/Reports';
import css from './styles.module.scss';
import { checkedLocalization } from '../../utils/checkedLocalization';
import { useStorageIsShowStatistics } from '../storage/model/selectors';
import { setIsShowTargetStatistics } from '../storage/model/reducer';
import { useDispatchApp } from '../../redux/rootSelectors';
import { TID, TOnSetDateRange } from '../../types/general';
import { useCampaignInfo } from '../campaign/model/selectors';
import { useTargetListInfo } from './model/selectors';

const Targets: FC = () => {
  const dispatch = useDispatchApp();
  const { t } = useTranslation();

  const { state, dispatch: dispatchContext } = useStateValue();
  const settings = state.settings[KeysSettings.CAMPAIGN];

  const isShowStatisticsStorage = useStorageIsShowStatistics();
  const [title, setTitle] = useState('');
  const [showStatistic, setShowStatistic] = useState(
    () => isShowStatisticsStorage,
  );

  const { fixed } = useOnScroll(70);

  useEffect(() => {
    setShowStatistic(() => isShowStatisticsStorage);
  }, [isShowStatisticsStorage]);

  const { id } = useParams<TID>();

  const { isThemeExtended, getValueFromSettingForKey, isThemeFull } =
    useUserTheme();
  const { data: dataCampaign, error } = useCampaignInfo();

  const { data: listTarget, isLoading: isLoadingTargetList } =
    useTargetListInfo();

  const onSetIsShowStatistics = useCallback(() => {
    dispatch(setIsShowTargetStatistics(!showStatistic));
    setShowStatistic(!showStatistic);
  }, [showStatistic]);

  const onSetDateRange: TOnSetDateRange = (periodDates, type) => {
    dispatchContext({
      type: 'setSettings',
      payload: {
        item: {
          type,
          period: periodDates,
        },
        key: KeysSettings.CAMPAIGN,
      },
    });
  };
  const clearTargetingsCampaignRequest = () => {
    dispatch(
      fetchClearTarget({
        xxhash: id,
        alert: {
          before: `${t('common.targetings.clean_all_targeting_campaign')} `,
          title: ` "${dataCampaign && dataCampaign.title}" `,
          after: ` ${t('common.targetings.clean_all_targeting_success')}`,
        },
      }),
    );
  };

  useEffect(() => {
    dispatch(fetchPDataByTarget({id}))
  }, [id]);
  
  useEffect(() => {
    if (dataCampaign && id) {
      setTitle(`${dataCampaign.title}`);
      dispatch(fetchGetList(id));
    }
  }, [dataCampaign, id]);

  const {
    nextLink,
    isLoading: isLoadingNavigation,
    prevLink,
    showNavigation,
    count,
    isDisableLink,
  } = useSwitchNavigation({mode: 'campaign'});

  if (error.isError) {
    return <ErrorReport error={error} />;
  }

  return (
    <div className={css.targeting}>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div
        className={cn(css.header__wrapper, {
          _isSmall: isThemeFull,
          _isFixed: fixed
        })}
      >
        <div className={css.header}>
          <div
            className={cn(css.header__container, 'container resized', {
              extend: isThemeExtended,
              full: isThemeFull,
            })}
          >
            {dataCampaign && (
              <Breadcrumbs type="Campaign" xxhash={dataCampaign.xxhash} />
            )}
            <div className={css.data}>
              <div className={css.status}>
                <h3 className={css.title}>
                  {dataCampaign !== null && dataCampaign.internal_id && (
                    <InternalId internal_id={dataCampaign.internal_id} />
                  )}

                  {title}
                </h3>
              </div>

              <div className={css.actions}>
                {dataCampaign && (
                  <>
                    <ToggleSwitch
                      onChange={onSetIsShowStatistics}
                      checked={showStatistic}
                      activateText={t(`targetings.show_statictics`)}
                      deactivateText={t(`targetings.show_statictics`)}
                    />
                    <DateRangeFilter
                      range={settings.period}
                      dateStart={parsingDate(
                        dataCampaign.date_start.date ||
                          moment().subtract(365, 'days'),
                      ).toDate()}
                      dateEnd={parsingDate(
                        dataCampaign.date_end.date || new Date(),
                      ).toDate()}
                      onSetDateRange={onSetDateRange}
                      initialTitleDay={settings.type}
                    />
                    <OnlyOwner>
                      <Options
                        title={dataCampaign.title}
                        clearTargetings={clearTargetingsCampaignRequest}
                        id={dataCampaign.xxhash}
                      />
                    </OnlyOwner>
                    {(getValueFromSettingForKey('HideNavigationCampaign') ||
                      getValueFromSettingForKey('HideNavigationCampaign') ===
                        null) && (
                      <SwitchItem
                        count={count}
                        isDisableLink={isDisableLink}
                        showNavigation={showNavigation}
                        prevLink={prevLink}
                        nextLink={nextLink}
                        isLoading={isLoadingNavigation}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {!dataCampaign && isLoadingTargetList && (
        <div className={css.center}>
          <Loader />
        </div>
      )}
      <div
        className={cn(css.content, {
          _isSmall: isThemeFull,
        })}
      >
        <div
          className={cn('container resized', {
            extend: isThemeExtended,
            full: isThemeFull,
          })}
        >
          {dataCampaign &&
            listTarget?.map((target) => (
              <div key={target.key} className={css.form}>
                <Item
                  title={checkedLocalization(
                    `common.targetings.${target.key}`,
                    target.title,
                  )}
                  type={target.key}
                  view={target.type}
                  isShowStatistic={showStatistic}
                  id={id}
                  isCreative={false}
                  isCompain
                  campaign_xxhash={dataCampaign.xxhash}
                  period={settings.period}
                  initCommonTypePeriod={settings.type}
                  is_wo_available={target.is_wo_available}
                  is_dynamic_catalog={target.is_dynamic_catalog}
                  isSourceLikeLogic={target.is_tree_target}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
export default Targets;
