import React, { FC, useRef, useEffect, useCallback, useState } from 'react';
import cn from 'classnames';
import { FiChevronDown } from 'react-icons/fi';
import useDropDown from 'hooks/useDropDown';
import { Creative } from 'domains/creative/types';
import { useCreativeListFilter } from 'domains/creative/hooks';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import i18next from 'i18next';
import ToggleSwitch from 'components/UI/ToggleSwitch';
import Portal from 'components/Portal';
import css from './styles.module.scss';
import { AppDispatch } from '../../redux/rootReducer';
import {
  fetchCreativesList,
  setListFilter,
  setListSort,
} from '../../domains/creative/reduser';
import { TID } from '../../types/general';
import { KeysSettings, useStateValue } from '../../utils/context';

type Props = {
  onFilterToggleDropdown?: (value: boolean) => void;
  className?: string;
};

type SelectItem = {
  key: string;
  status: Creative['status'] | '';
  title: string;
};

const CreativeListFilter: FC<Props> = ({
  onFilterToggleDropdown,
  className,
}) => {
  const { state } = useStateValue();
  const settings = state.settings[KeysSettings.CAMPAIGN];
  const dispatch = useDispatch<AppDispatch>();
  const dropdownMenu = useRef<HTMLDivElement | null>(null);
  const dropdownMenuList = useRef<HTMLDivElement | null>(null);
  const { toggle, isOpened, toggleOff } = useDropDown(dropdownMenuList, false);

  const selectedCreativeList: SelectItem[] = [
    {
      key: 'isAll',
      title: i18next.t('targetings.all'),
      status: '',
    },
    {
      key: 'isActive',
      title: i18next.t('campaigns_page.campaigns_black_header_main.act'),
      status: 'LAUNCHED',
    },
    {
      key: 'isStopped',
      title: i18next.t('campaigns_page.campaigns_black_header_main.pause'),
      status: 'STOPPED',
    },
    {
      key: 'isDeleted',
      title: i18next.t('campaigns_page.campaigns_black_header_main.del'),
      status: 'DELETED',
    },
  ];

  const {
    params: { id },
  } = useRouteMatch<TID>();

  const { listFilter } = useCreativeListFilter();

  const onItemClickHandler = () => {
    toggleOff();
  };

  useEffect(() => {
    if (onFilterToggleDropdown) onFilterToggleDropdown(isOpened);
  }, [isOpened]);

  const bodyRect = document.body.getBoundingClientRect();
  const positionListLeft = dropdownMenu.current?.getBoundingClientRect().left;
  const offsetLeft = positionListLeft ? positionListLeft - bodyRect.left : 0;
  const [offsetTop, setOffsetTop] = useState<number>(0);

  const calcOffsetTop = useCallback(() => {
    const positionListTop = dropdownMenu.current?.getBoundingClientRect().top;
    setOffsetTop(positionListTop || 0);
  }, []);

  useEffect(() => {
    if (isOpened) {
      document.addEventListener('scroll', onItemClickHandler);
    }
    document.addEventListener('scroll', calcOffsetTop);

    return () => {
      document.removeEventListener('scroll', onItemClickHandler);
      document.removeEventListener('scroll', calcOffsetTop);
    };
  }, [isOpened]);

  useEffect(() => {
    /* Рассчет с задержкой из-за анимации появления графика */
    setTimeout(() => {
      calcOffsetTop();
    }, 200);
  }, []);

  return (
    <div className={cn(css.filter, className)} ref={dropdownMenu}>
      <div
        className={cn(css.active, { isOpened })}
        onClick={isOpened ? toggleOff : toggle}
      >
        <div className={cn(css.type, { [`type-${listFilter}`]: true })} />
        <div className={css.chevron}>
          <FiChevronDown size={24} strokeWidth={3} />
        </div>
      </div>

      <Portal className="filterList1">
        <div
          ref={dropdownMenuList}
          className={cn(css.list, { isOpened })}
          style={{
            left: `${offsetLeft - 20}px`,
            top: `${offsetTop + 30}px`,
          }}
          onClick={onItemClickHandler}
        >
          {selectedCreativeList.map(({ key, title, status }) => (
            <div
              key={key}
              className={cn(
                css.item,
                key,
                status === listFilter ? 'isSelected' : '',
              )}
              onClick={() => {
                dispatch(setListSort({key: null, sort: null}))
                dispatch(setListFilter(status));
                dispatch(
                  fetchCreativesList({
                    campaign_xxhash: id,
                    filters: { status },
                    period: settings && settings.period,
                  }),
                );
                toggleOff();
              }}
            >
              {key === 'isAll' ? (
                <>
                  <div className={css.type} />
                  <div className={css.text}>{title}</div>
                </>
              ) : (
                <div className={css.toggle}>
                  <ToggleSwitch
                    checked={key === 'isActive'}
                    mini
                    activateText={title}
                    deactivateText={title}
                    disabled={key === 'isDeleted'}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </Portal>
    </div>
  );
};

export default CreativeListFilter;
