import { FormikContextType, FormikProps } from 'formik';
import { RefObject } from 'react';
import { FetchedDataObj } from '../redux/fetchedData';

export type Period = {
  from: string;
  to?: string;
};

// eslint-disable-next-line no-shadow
export enum TypesTable {
  ASC = 'ASC',
  DESC = 'DESC',
  DEFAULT = '',
}

export type ToastUtilsSuccessParams = {
  before: string;
  title: string;
  after: string;
};

// eslint-disable-next-line no-shadow
export enum ElementsNoneType {
  AGENCIES = 'agencies',
  CAMPAIGNS = 'campaigns',
  CLIENTS = 'clients',
  CONTAINERS = 'containers',
  CREATIVE = 'creative',
  HISTORY = 'history',
  OFFERS = 'offers',
  CONTRACTS = 'contracts',
  COUNTERAGENTS = 'counteragents',
}

export type FetchedDataErrors = FetchedDataObj<null>['error'];
export type PID = {
  xxhash: string;
};
export type THASH = {
  xxhash: string;
};

export type PartnerHash = {
  partner_xxhash: string;
};

export type TKEY = {
  key: string;
};

export type THASH_LIST = {
  xxhash_list: string[];
};

export type PartnerHashList = {
  partner_xxhash_list: string[];
};

export type TCallback = { callback: () => void };

export type TID = {
  id: string;
};

export type TItem = {
  xxhash: string;
  title: string;
  campaign_xxhash?: string;
};

export type SagaUpdateVisial<T = undefined, V = boolean> = {
  /** значание локального стате */
  value: V;
  /** функция обновления локального состоянияи */
  setter?: (param: V) => void;
  /** текст алерта */
  alertText?: string | ToastUtilsSuccessParams;
  /** парамерты для обновления страницы */
  paramRender?: T;
  translation?: boolean;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
};
export type ErrorResponse = {
  code: number;
  message: string;
};

export type DateType = {
  date: string;
  timezone_type: number;
  timezone: string;
};

type TFileExt =
  | '.zip'
  | '.png'
  | '.jpg'
  | '.gif'
  | '.jpeg'
  | '.svg'
  | '.mp4'
  | '.pdf';
export type TFileParam = {
  /** Максимальная величина файла в МБ */
  maxSizeFile: number;
  /** 80 минимальное разрешение */
  minResolution: number;
  /** 4096 максимальное разрешение */
  maxResolution: number;
  /** Расширения файлов */
  file_ext: TFileExt[];
};

export type SelectField = {
  value: string;
  label: string;
  status?: string;
};

export type HistorySelectedField = {
  value: string | null;
  label: string;
};

export type HistorySelectedFieldWithId = {
  value: string | null;
  label: string;
  id: string;
};

export type RResultSuccess = {
  result: boolean;
};

/** Тип для  деструкторизации Formik */
export type TFormikExtract<T> = FormikProps<T> & {
  formikContext: FormikContextType<T>;
};

export type TLoadList = {
  isHard?: boolean;
  saveLength?: boolean;
};

export type TPropsSelector<T> = {
  serverList: T | null;
  list: T;
  isLoadingView?: boolean;
  isLoading: boolean;
  error?: FetchedDataErrors;
  LTU?: number;
  isBlocked?: boolean;
};

export type RNavigation = {
  isLoading: boolean;
  showNavigation: boolean;
  prevLink: string;
  nextLink: string;
  isDisableLink: {
    next: boolean;
    prev: boolean;
  };
  count: number;
  onCloseSearch?: () => void;
};

export type TGoBack = {
  title?: string;
  to: string;
  back?: boolean;
};

export type TWarnList = {
  id: string;
  message: string;
};

export type TDates = {
  period: Period;
  type:
  | '30days'
  | 'week'
  | 'yesterday'
  | 'month'
  | 'day'
  | 'all_time'
  | 'period'
  | '2months'
  | '';
};

export type TOnSetDateRange = (
  period: TDates['period'],
  type: TDates['type'],
) => void;

export type TonSetActive = (val: boolean, ref: RefObject<HTMLElement>) => void;

export type TInternalItem = {
  id: number;
  key: string;
  title: string;
};

export type TInternalList = {
  id: number;
  key: string;
  title: string;
  type: string;
  items: TInternalItem[];
};

export type TIconsList = string[];

export type TTableSort = {
  key: string | null;
  sort: 'ASC' | 'DESC' | null;
};

export type TagsParam = THASH & {
  tags: string[];
};

export type TGroup = {
  title: string;
  xxhash: string;
  internal_id?: string;
}[];

export type TCloneTarget = {
  xxhash: string;
  status: boolean;
  message: string;
  data: Record<string, string[]>;
};

export type PSagaCopyElement = {
  from: TGroup;
  to: string[];
  setWarningList: (param: TCloneTarget[]) => void;
  setCopying: (param: boolean) => void;
  setProgress: (param: number) => void;
  setIsWarning: (param: boolean) => void;
  forced: boolean;
};

export type PSagaDoubleElement = {
  from: TGroup;
  to: TGroup;
  partner_xxhash?: string | null;
  callback?: () => void;
};

export type CustomOptionsType = {
  label: string;
  value: string;
  additional: any;
};

export type TPartnersInfo = {
  internal_id: 'string';
  xxhash: 'string';
  title: 'string';
  status: 'string';
  type: 'string';
};

export type TGetTechnicalInfo = {
  email: 'string';
  partners: TPartnersInfo[];
  auth_token: 'string';
  tags: { title: string }[];
  groups: {
    id: 0;
    title: 'string';
  }[];
  methods: string[];
  isActive: true;
  date_create: {
    date: 'string';
    timezone_type: 0;
    timezone: 'string';
  };
};