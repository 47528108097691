import React, { FC, useState } from 'react';
import cn from 'classnames';
import { FiMaximize2 } from 'react-icons/fi';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import increaseFS from './increaseFS.svg';
import decreaseFS from './decreaseFS.svg';
import copyCode from './copy.svg';
import Tooltip from '../Tooltip';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/javascript/javascript';
import Loader from '../Loader';
import FullscreenCodeModal from './FullscreenCodeModal';
import ActionsButton from './ActionsButton';
import code from './styles.module.scss';
import { useFormResetUploadDefault } from '../../../domains/uploads/hooks';
import { TCodeComponentProps } from './types';
import ErrorText from '../ErrorText';
import Label from '../Input/Label';

type Props = TCodeComponentProps;

const Code: FC<Props> = (props) => {
  const {
    label,
    error,
    errorText,
    loader,
    readonly,
    field,
    notField,
    useFullscreen,
    modalView,
    maxCount,
    valueCount,
    style,
    form,
    disabled,
    handlerCopy,
    tooltip,
  } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [sizeFont, setSizeFont] = useState<number>(14);

  const { isReset } = useFormResetUploadDefault('zip');

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onFontSize = (val: number) => {
    if (val < 10 || val > 18) {
      return;
    }
    setSizeFont(val);
  };
  const { t } = useTranslation();
  return (
    <div
      className={cn(code['form-group'], error ? code['form-group_error'] : '', {
        _isDisabled: disabled,
        _isModalView: modalView,
      })}
      style={style}
    >
      {label && <Label forInput="#" tooltip={tooltip} label={label} />}
      {!notField && !!maxCount && valueCount !== undefined && (
        <div className={code.maxCount}>
          {`${valueCount}/${maxCount} ${t('creative.create_creative.symbols')}`}
        </div>
      )}
      <div className={cn(code.wrapper, { _error: error })}>
        <CodeMirror
          className={cn(code.code, {
            fz10: sizeFont === 10,
            fz12: sizeFont === 12,
            fz14: sizeFont === 14,
            fz16: sizeFont === 16,
            fz18: sizeFont === 18,
          })}
          value={field.value}
          onBeforeChange={(editor, data, value) => {
            isReset();
            form?.setFieldValue(field.name, value);
          }}
          options={{
            mode: disabled ? '' : 'xml',
            lineNumbers: true,
            lineWrapping: true,
            readOnly: readonly,
          }}
        />

        <div className={code.actions}>
          <div className={cn(code.copy)} onClick={handlerCopy}>
            <Tooltip
              className={code.tooltip}
              left
              title={
                <div className={code.button}>
                  <ReactSVG src={copyCode} />
                </div>
              }
            >
              <span>{t('expand_role.ex4')}</span>
            </Tooltip>
          </div>
          <div
            className={cn(code.decreaseFS, {
              isDisabled: disabled || sizeFont === 10,
            })}
            onClick={() => onFontSize(sizeFont - 2)}
          >
            <Tooltip
              className={code.tooltip}
              left
              title={
                <div className={code.button}>
                  <ReactSVG src={decreaseFS} />
                </div>
              }
            >
              <span>{t('expand_role.ex1')}</span>
            </Tooltip>
          </div>
          <div
            className={cn(code.increaseFS, {
              isDisabled: disabled || sizeFont === 18,
            })}
            onClick={() => onFontSize(sizeFont + 2)}
          >
            <Tooltip
              className={code.tooltip}
              left
              title={
                <div className={code.button}>
                  <ReactSVG src={increaseFS} />
                </div>
              }
            >
              <span>{t('expand_role.ex2')}</span>
            </Tooltip>
          </div>

          {!disabled && (
            <ActionsButton
              html={field.value}
              setFieldValue={(html: string) =>
                form?.setFieldValue(field.name, html)
              }
            />
          )}

          {useFullscreen && (
            <div
              onClick={() => setOpenModal(true)}
              className={cn(code.fullscreen, {})}
            >
              <Tooltip
                className={code.tooltip}
                title={
                  <div className={code.button}>
                    <FiMaximize2 size={20} />
                  </div>
                }
              >
                <span>{t('expand_btn')}</span>
              </Tooltip>
            </div>
          )}
        </div>

        {loader && (
          <div className={cn(code.loader)}>
            <Loader />
          </div>
        )}
      </div>
      {!!errorText && error && <ErrorText text={errorText} />}
      {openModal && (
        <FullscreenCodeModal
          isOpen={openModal}
          onClose={onCloseModal}
          {...props}
        />
      )}
    </div>
  );
};

export default Code;
