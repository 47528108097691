import React, { FC } from 'react';
import cn from 'classnames';
import Button from 'components/UI/Buttons/buttons';
import Input from 'components/UI/Input/input';
import { FiGrid, FiPlusCircle } from 'react-icons/fi';
import ModalOverlay from 'components/Modals/ModalOverlay1';
import NewSelect from 'components/UI/NewSelect';
import css from './styles.module.scss';
import i18n from '../../../i18n';
import { useConstructor } from './hooks/useConstructor';
import SelectStyle from './components/SelectStyle';
import { optionsButtonCreativeMulti } from '../../creative/model/shared';
import EnterImage from './components/EnterImage';
import { iconsArrayIllustration } from './shared/consts';

type TConstructorProps = Parameters<typeof useConstructor>[0] & {
  constructorTitle?: string;
};

const Constructor: FC<TConstructorProps> = ({
  onClose,
  width,
  height,
  handlerUploadFileLink,
  constructorTitle,
}) => {
  const {
    iconsArray,
    openStyle,
    openStyleOn,
    openStyleOff,
    canvasData,
    container,
    handlerChangeBackground,
    textRef,
    buttonRef,
    handlerAddText,
    onChangeButtons,
    mode,
    isOpenStyleIllustration,
    openStyleIllustrationOff,
    openStyleIllustrationOn,
    handlerAddIllustration,
    handleRemoveBackgroundWithCanvas,
    illustrationRef,
    handlerClearIllustration,
    handlerUploadImageLogo,
    logoRef,
    uploadInputRef,
    triggerUpload,
    clearLogo,
    businessList,
    handlerSaveCanvas,
  } = useConstructor({ width, height, handlerUploadFileLink, onClose });

  const showSettingLeftPanel = !openStyle && !isOpenStyleIllustration;

  return (
    <ModalOverlay onCloseModal={onClose} isOpen className={css.modal__wrapper}>
      <div className={css.card}>
        <div className={css.body}>
          {showSettingLeftPanel && (
            <div className={cn(css.settings, css.leftPanel)}>
              {constructorTitle && (
                <Input
                  label={i18n.t(`creative.constructor.name`)}
                  inputAttributes={{
                    name: 'data.text',
                    style: { height: 36, marginBottom: '48px' },
                    value: constructorTitle,
                  }}
                  disabled
                />
              )}
              <NewSelect
                className={css.selectInput}
                label={i18n.t(`creative.constructor.business_area`)}
                selectAttributes={{
                  name: 'data.button',
                  placeholder: i18n.t('creative.create_creative.Please_select'),
                  options: businessList,
                  defaultValue: businessList[0],
                }}
                heightCustom={36}
                viewStyle="Vertical"
              />

              <input
                style={{ display: 'none' }}
                type="file"
                id="img"
                name="img"
                accept="image/*"
                ref={uploadInputRef}
                onChange={handlerUploadImageLogo}
              />

              <SelectStyle
                label={i18n.t(`creative.constructor.logo`)}
                openStyle={triggerUpload}
                text={
                  canvasData.logoName
                    ? canvasData.logoName
                    : i18n.t(`creative.constructor.upload_logo`)
                }
                url={canvasData.logo}
                error={canvasData.logoError}
                handlerClear={clearLogo}
                logoDefault={<FiPlusCircle size={24} />}
              />
              <SelectStyle
                label={i18n.t(`creative.constructor.style`)}
                openStyle={openStyleOn}
                text={i18n.t(`creative.constructor.select_style`)}
                url={canvasData.backgroundImage}
                logoDefault={<FiGrid size={24} />}
                handlerClear={handleRemoveBackgroundWithCanvas}
              />

              {mode === 'big' && (
                <SelectStyle
                  label={i18n.t(`creative.constructor.illustrations`)}
                  openStyle={openStyleIllustrationOn}
                  text={i18n.t(`creative.constructor.illustrations`)}
                  url={canvasData.illustration}
                  logoDefault={<FiGrid size={24} />}
                  handlerClear={handlerClearIllustration}
                />
              )}
              <NewSelect
                label={i18n.t(`btn`)}
                onCustomChange={onChangeButtons}
                selectAttributes={{
                  name: 'data.button',
                  placeholder: i18n.t('creative.create_creative.Please_select'),
                  options: optionsButtonCreativeMulti,
                  value: optionsButtonCreativeMulti.filter(
                    (item) => item.label === canvasData.buttonLabel,
                  ),
                }}
                heightCustom={36}
                viewStyle="Vertical"
              />
            </div>
          )}
          {openStyle && (
            <EnterImage
              label={i18n.t(`creative.constructor.select_a_style`)}
              isOpen={openStyle}
              images={iconsArray}
              onClose={openStyleOff}
              handler={handlerChangeBackground}
            />
          )}

          {isOpenStyleIllustration && (
            <EnterImage
              label={i18n.t(`creative.constructor.select_illustration`)}
              isOpen={isOpenStyleIllustration}
              images={iconsArrayIllustration}
              onClose={openStyleIllustrationOff}
              handler={handlerAddIllustration}
            />
          )}

          <div className={css.main}>
            <div className={css.main_container}>
              <div className={css.size}>{`${width}px x ${height}px`}</div>
              <div
                className={css.lego}
                style={{
                  width,
                  height,
                }}
              >
                <canvas
                  id="canvas"
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  ref={container}
                />

                <div
                  className={cn(css.uploadItem, css.uploadItemIcon, {
                    isMode: mode === 'big',
                  })}
                  ref={logoRef}
                  onClick={triggerUpload}
                >
                  <strong>
                    {i18n.t(`creative.constructor.upload_your_logo`)}
                  </strong>
                  <span>{i18n.t(`creative.constructor.formats`)}</span>
                </div>

                <div
                  className={cn(css.uploadItem, css.uploadItemText, {
                    isMode: mode === 'big',
                  })}
                  ref={textRef}
                  onClick={handlerAddText}
                >
                  <strong>{i18n.t(`creative.constructor.add_text`)}</strong>
                  <span>{i18n.t(`creative.constructor.text_limit`)}</span>
                </div>

                <div
                  className={cn(css.uploadItem, css.uploadItemButton, {
                    isMode: mode === 'big',
                  })}
                  ref={buttonRef}
                >
                  <strong>{i18n.t(`creative.constructor.text_of_btn`)}</strong>
                </div>

                {mode === 'big' && (
                  <div
                    className={cn(css.uploadItem, css.uploadItemIllustration)}
                    ref={illustrationRef}
                    onClick={openStyleIllustrationOn}
                  >
                    <strong>
                      {i18n.t(`creative.constructor.illustration`)}
                    </strong>
                  </div>
                )}
              </div>
            </div>
            <div className={css.buttons}>
              <Button
                title={i18n.t(`cancel_btn`)}
                transparent
                buttonAttributes={{
                  onClick: onClose,
                }}
              />
              <Button
                title={i18n.t(`save_btn`)}
                buttonAttributes={{
                  onClick: handlerSaveCanvas,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </ModalOverlay>
  );
};

export default Constructor;
