import { createSelector } from '@reduxjs/toolkit';
import { TStatisticsFields } from 'domains/campaign/types';
import { extractMakeGenList } from '../../../redux/makeGenList';
import { extractGenFetchData } from '../../../redux/fetchedData';
import { CampaignsState } from '../reducer';
import {
  RCampaignsArray,
  RCampaignsArrayStata,
  ResponseTranslation,
  TBalance,
  TotalResponse,
  WeekStatistic,
} from '../types';
import {
  THookSelectorFetchDataInfo,
  THookStateInfo,
  TSelector,
  useGenFetchDataSelector,
  useSelectorTypes,
} from '../../../redux/rootSelectors';

/** список селекторов */
export const selectorCampaignsLink: TSelector<CampaignsState> = ({
  campaignsReducer,
}) => campaignsReducer;

export const selectorCampaignsTranslation: TSelector<
  CampaignsState['tempTranslation']
> = ({ campaignsReducer }) => campaignsReducer.tempTranslation;

export const selectorCampaignsList: TSelector<
  CampaignsState['tempCampaigns']
> = ({ campaignsReducer }) => campaignsReducer.tempCampaigns;

export const selectorCampaignLastID: TSelector<CampaignsState['lastId']> = ({
  campaignsReducer,
}) => campaignsReducer.lastId;

export const selectorCampaignTotal: TSelector<CampaignsState['total']> = ({
  campaignsReducer,
}) => campaignsReducer.total;

export const selectorCampaignsView: TSelector<CampaignsState['view']> = ({
  campaignsReducer,
}) => campaignsReducer.view;

export const selectorCampaignsLastID: TSelector<CampaignsState['lastId']> = ({
  campaignsReducer,
}) => campaignsReducer.lastId;

export const selectorCampaignsPeriod: TSelector<
  CampaignsState['campaignsPeriod']
> = ({ campaignsReducer }) => campaignsReducer.campaignsPeriod;

export const selectorCampaignsListModal: TSelector<
  CampaignsState['tempCampaignsModal']
> = ({ campaignsReducer }) => campaignsReducer.tempCampaignsModal;

export const selectorPartner: TSelector<CampaignsState['partner']> = ({
  campaignsReducer,
}) => campaignsReducer.partner;

export const selectorPartnerBalance: TSelector<CampaignsState['balance']> = ({
  campaignsReducer,
}) => campaignsReducer.balance;

export const selectorPartnerStatisticFieldList: TSelector<
  CampaignsState['statisticFieldList']
> = ({ campaignsReducer }) => campaignsReducer.statisticFieldList;

export const selectorCampaignsFilters: TSelector<CampaignsState['filter']> = ({
  campaignsReducer,
}) => campaignsReducer.filter;

export const selectorCampaignsStatisticsWeek: TSelector<
  CampaignsState['tempStatisticWeek']
> = ({ campaignsReducer }) => campaignsReducer.tempStatisticWeek;

export const selectorChartStatistics: TSelector<
  CampaignsState['chartStatistic']
> = ({ campaignsReducer }) => campaignsReducer.chartStatistic;

export const selectorCampaignTranslation: TSelector<
  CampaignsState['tempTranslation']
> = ({ campaignsReducer }) => campaignsReducer.tempTranslation;

export const selectorFiltredCampaignsTranslation: TSelector<
  CampaignsState['campaignTranslationFilter']
> = ({ campaignsReducer }) => campaignsReducer.campaignTranslationFilter;

export const selectorListSort: TSelector<CampaignsState['listSort']> = ({
  campaignsReducer,
}) => campaignsReducer.listSort;

export const selectorCampaignsIcons: TSelector<
  CampaignsState['campaignsIcons']
> = ({ campaignsReducer }) => campaignsReducer.campaignsIcons;

/** список хуков */
export const selectorCampaigns = createSelector(
  selectorCampaignsList,
  selectorCampaignsView,
  (tempCampaigns, view) => {
    const {
      isBlocked,
      listView,
      isLoading: isLoadingView,
    } = extractMakeGenList<RCampaignsArrayStata>(view);
    const { LTU, error, isLoading, data } =
      extractGenFetchData<RCampaignsArrayStata>(tempCampaigns);
    return {
      error,
      LTU,
      list: listView,
      isBlocked,
      isLoadingView,
      isLoading,
      serverList: data,
    };
  },
);

export const useCampaignsPeriodInfo: THookStateInfo<
  CampaignsState['campaignsPeriod']
> = () => useSelectorTypes(selectorCampaignsPeriod);

export const useCampaignsInfo: THookSelectorFetchDataInfo<
  RCampaignsArrayStata
> = () => useGenFetchDataSelector(selectorCampaignsList);

export const useCampaignsListModalInfo: THookSelectorFetchDataInfo<
  RCampaignsArray
> = () => useGenFetchDataSelector(selectorCampaignsListModal);

export const useChartStatistic: THookSelectorFetchDataInfo<
  WeekStatistic
> = () => useGenFetchDataSelector(selectorChartStatistics);

export const usePartnerInfo: THookStateInfo<CampaignsState['partner']> = () =>
  useSelectorTypes(selectorPartner);

export const usePartnerBalanceInfo: THookSelectorFetchDataInfo<TBalance> = () =>
  useGenFetchDataSelector(selectorPartnerBalance);

export const usePartnerStatisticFieldList: THookSelectorFetchDataInfo<
  TStatisticsFields
> = () => useGenFetchDataSelector(selectorPartnerStatisticFieldList);

export const useCampaignsFilterInfo: THookStateInfo<
  CampaignsState['filter']
> = () => useSelectorTypes(selectorCampaignsFilters);

export const useCampaignsViewList: THookStateInfo<
  CampaignsState['view']
> = () => useSelectorTypes(selectorCampaignsView);

export const useCampaignsTranslation: THookSelectorFetchDataInfo<
  Record<string, ResponseTranslation>
> = () => useGenFetchDataSelector(selectorCampaignTranslation);

export const useCampaignsTotal: THookSelectorFetchDataInfo<
  TotalResponse
> = () => useGenFetchDataSelector(selectorCampaignTotal);
