import { useHistory } from 'react-router-dom';
import { TReportItem } from '../types';
import { KeysSettings, useStateValue } from '../../../utils/context';
import { fetchDownloadReport } from '../model/actions';
import { useDispatchApp } from '../../../redux/rootSelectors';
import { useCampaignInfo } from '../model/selectors';

type TFUseCampaignReports = () => {
  handlerDownloadReportByID: (type: TReportItem['type']) => void;
  handlerReportByID: (type: TReportItem['type']) => void;
  campaign: ReturnType<typeof useCampaignInfo>['data'];
  isLoading: ReturnType<typeof useCampaignInfo>['isLoading'];
  error: ReturnType<typeof useCampaignInfo>['error'];
};

export const useCampaignReports: TFUseCampaignReports = () => {
  const dispatchRedux = useDispatchApp();
  const { data: campaign, isLoading, error } = useCampaignInfo();
  const history = useHistory();
  const { state } = useStateValue();
  const settings = state.settings[KeysSettings.CAMPAIGN];

  /** переход на внутреннюю страницу отчета */
  const handlerReportByID: ReturnType<TFUseCampaignReports>['handlerReportByID'] =
    (type) => {
      if (!campaign) {
        return;
      }
      history.push({
        pathname: `/main/campaign/${campaign.xxhash}/general-reports/general`,
        search: `?date_from=${settings.period.from}&date_to=${settings.period.to}&type=${type}`,
      });
    };

  /** Скачивание отчета */
  const handlerDownloadReportByID: ReturnType<TFUseCampaignReports>['handlerDownloadReportByID'] =
    (type) => {
      if (!campaign) {
        return;
      }
      dispatchRedux(
        fetchDownloadReport({
          xxhash: campaign.xxhash,
          type,
          mode: 'xls',
          period: settings.period,
        }),
      );
    };

  return {
    handlerDownloadReportByID,
    handlerReportByID,
    campaign,
    isLoading,
    error,
  };
};
