import React, { FC } from 'react';
import { Field, FieldProps, Form, FormikProvider } from 'formik';
import NewSelect from 'components/UI/NewSelect';
import { useEditMultiFormat } from './hook';
import { TCreativeMultiFormat } from '../../../../../types';
import EditCard from '../../../../../../../components/UI/EditCard';
import Input from '../../../../../../../components/UI/Input/input';
import i18next from '../../../../../../../i18n';
import css from '../styles.module.scss';
import { optionsButtonCreativeMulti } from '../../../../../model/shared';
import { getSizes } from '../../../../../../uploads/helpers';
import UploadImage from '../../../../../../uploads/UploadImage';

type MultiFormatProps = {
  data: TCreativeMultiFormat;
  xxhash: string;
  creativeName: string;
};

const MultiFormat: FC<MultiFormatProps> = ({ data, xxhash, creativeName }) => {
  const {
    edit,
    setEdit,
    handlerFormikReset,
    handlerFormikSubmit,
    formikContext,
    errors,
    values,
    setFieldValue,
    handleChange,
  } = useEditMultiFormat({
    data,
    xxhash,
  });

  return (
    <FormikProvider value={formikContext}>
      <Form>
        <EditCard
          className={css.card}
          edited={edit}
          noHover
          noShadow
          hasErrors={Object.keys(errors).length > 0}
          onResetForm={handlerFormikReset}
          onSave={handlerFormikSubmit}
          setEdit={setEdit}
        >
          <div className={css.form}>
            <UploadImage
              keyUploadFile="multiFormat"
              preview={getSizes(values.images.icon, ['256x256'])}
              name="images.icon"
              setFieldValue={setFieldValue}
              label={i18next.t('creative.creasmall_set.multiformat.icon')}
              customLinkTitle={i18next.t(
                'creative.creasmall_set.linktoicon_word',
              )}
              hintText={
                <span>
                  {i18next.t('creative.creasmall_set.multuformat_formats1')}
                  <br />
                  {i18next.t('creative.creasmall_set.multuformat_formats2')}
                </span>
              }
              hasEdit
              minResolution={256}
              edit={edit}
              maxSizeFile={2}
              file_ext={['.png', '.jpeg', '.jpg']}
              typeSection="multiFormat"
            />
            <UploadImage
              keyUploadFile="multiFormat"
              preview={getSizes(values.images.image_1, ['600x600'])}
              name="images.image_1"
              setFieldValue={setFieldValue}
              label={i18next.t('creative.creasmall_set.multiformat.image_1')}
              customLinkTitle={i18next.t(
                'creative.creasmall_set.linktoimage_word',
              )}
              hintText={
                <span>
                  {i18next.t('creative.creasmall_set.multuformat_formats1')}
                  <br />
                  {i18next.t('creative.creasmall_set.multuformat_formats3')}
                </span>
              }
              hasEdit
              edit={edit}
              minResolution={600}
              maxSizeFile={2}
              file_ext={['.png', '.jpeg', '.jpg']}
              typeSection="multiFormat"
              constructorImage
              constructorTitle={creativeName}
              imageWidth={600}
              imageHeight={600}
            />
            <UploadImage
              keyUploadFile="multiFormat"
              preview={getSizes(values.images.image_2, ['1080x607'])}
              name="images.image_2"
              setFieldValue={setFieldValue}
              label={i18next.t('creative.creasmall_set.multiformat.image_2')}
              customLinkTitle={i18next.t(
                'creative.creasmall_set.linktoimage_word',
              )}
              hintText={
                <span>
                  {i18next.t('creative.creasmall_set.multuformat_formats1')}
                  <br />
                  {i18next.t('creative.creasmall_set.multuformat_formats4')}
                </span>
              }
              hasEdit
              edit={edit}
              minResolution={607}
              maxSizeFile={2}
              file_ext={['.png', '.jpeg', '.jpg']}
              typeSection="multiFormat"
              constructorImage
              constructorTitle={creativeName}
              imageWidth={1080}
              imageHeight={607}
            />
            <Input
              notField={edit}
              label={i18next.t('creative.creasmall_set.title_word')}
              showValueCount
              inputAttributes={{
                name: 'title',
                placeholder: '',
                maxLength: 25,
                style: { height: 48 },
                value: values.title,
                onChange: handleChange,
              }}
              tooltip={{
                text: i18next.t(
                  'creative.creasmall_set.multiformat.title_hint',
                ),
              }}
            />
            <Input
              notField={edit}
              label={i18next.t('creative.creasmall_set.text_word')}
              showValueCount
              isTextarea
              inputAttributes={{
                name: 'text',
                style: { height: 128 },
                maxLength: 90,
                value: values.text,
                onChange: handleChange,
              }}
              tooltip={{
                text: i18next.t('creative.creasmall_set.multiformat.text_hint'),
              }}
            />
            <Input
              notField={edit}
              label={i18next.t(
                'creative.creasmall_set.multiformat.legal_info_title',
              )}
              showValueCount
              isTextarea
              inputAttributes={{
                name: 'legal_information',
                style: { height: 128 },
                maxLength: 115,
                value: values.legal_information,
                onChange: handleChange,
              }}
              tooltip={{
                text: i18next.t(
                  'creative.creasmall_set.multiformat.legal_info',
                ),
              }}
            />
            <Field name="button">
              {({ form, field }: FieldProps) => (
                <NewSelect
                  viewingOnly={!edit}
                  label={i18next.t(
                    'creative.creasmall_set.multiformat.type_of_btn',
                  )}
                  form={form}
                  field={field}
                  selectAttributes={{
                    name: 'button',
                    placeholder: i18next.t(
                      'creative.create_creative.Please_select',
                    ),
                    options: optionsButtonCreativeMulti,
                    value: optionsButtonCreativeMulti.filter(
                      ({ value }) => value === values.button,
                    ),
                    onChange: () => {
                      setFieldValue('button', values.button);
                    },
                  }}
                  labelTooltip={{
                    text: i18next.t(
                      'creative.creasmall_set.multiformat.type_btn_hint',
                    ),
                  }}
                  viewStyle="Vertical"
                />
              )}
            </Field>
          </div>
        </EditCard>
      </Form>
    </FormikProvider>
  );
};

export default MultiFormat;
