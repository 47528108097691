import { TExperimentalInfoField } from 'domains/campaign/types';
import {
  CheckAvalibleTargetTypesParams,
  CheckVariableFieldsParams,
  GetVariableFieldValReturn,
  LinkOption,
  MassEditLinkParams,
} from 'domains/creative/types';

export const getVariableFieldVal = ({
  valName,
  types,
  config,
}: CheckVariableFieldsParams): GetVariableFieldValReturn => {
  const avalibleForCreoTypes: string[] = [];
  let resultVal: TExperimentalInfoField | null = null;
  if (!config) return { resultVal, avalibleForCreoTypes };
  types.forEach((creoType) => {
    const currentConfigItem = config?.find(
      (configItem) => configItem.key === creoType,
    );
    if (!currentConfigItem) return;
    const currentTypeConfigFields = Object.values(
      currentConfigItem.config_fields,
    );
    const currentFieldData = currentTypeConfigFields.find(
      (fieldData) => fieldData.field === valName,
    );
    if (currentFieldData) {
      resultVal = currentFieldData;
      avalibleForCreoTypes.push(currentConfigItem.key);
    }
  });
  return { resultVal, avalibleForCreoTypes };
};

export const checkAvalibleFieldsCount = (
  linkOptions: LinkOption[],
  checkedCreoTypes: string[],
  activeFields: string[],
): boolean => {
  const activeLength = activeFields.length;
  const linkOptionsForCheckedTypes = linkOptions.filter((linkItem) =>
    linkItem.targetTypes.some((type) => checkedCreoTypes.includes(type)),
  );
  const linkOptionsLength = linkOptionsForCheckedTypes.length;
  return activeLength < linkOptionsLength;
};

export const checkVariableFields = (
  formikVal: string | null,
  fieldData: TExperimentalInfoField | null,
  params: MassEditLinkParams,
): void => {
  if (fieldData) {
    if (formikVal !== '' && formikVal !== undefined) {
      // eslint-disable-next-line no-param-reassign
      params.massEditLinkCaster.config_values[fieldData.id] =
        formikVal === null ? '' : formikVal;
    }
  }
};

export const checkAvalibleTargetTypes = ({
  optionsList,
  targetKey,
  targetNames,
}: CheckAvalibleTargetTypesParams): string[] => {
  if (!targetKey) return [];
  const currentItem = optionsList.find((item) => item.value === targetKey);
  if (!currentItem) return [];
  return currentItem?.targetTypes?.map((targetKeys) => targetNames[targetKeys]);
};

export const createCurrentOptions = (
  linksOptions: LinkOption[],
  checkedTypes: string[],
): LinkOption[] =>
  linksOptions
    .filter((item) =>
      item.targetTypes.some((type) => checkedTypes.includes(type)),
    )
    .map((item) => ({
      ...item,
      targetTypes: item.targetTypes.filter((targetType) =>
        checkedTypes.includes(targetType),
      ),
    }));
