import { THASH, TID } from 'types/general';
import { RBreadCrumbs, RSearch } from './types';
import request from '../../../lib/helpers/requestApi';

/** Получение сущтности по InternalID */
export const getSearchByInternalID = request<RSearch, TID>({
  method: 'search.byInternalId',
});

/** Получение хлебных крошек */
export const getBreadcrumbs = request<RBreadCrumbs, THASH>({
  method: 'search.getBreadCrumbs',
});
