import React, { useState, useEffect, useMemo } from 'react';
import { useDispatchApp } from 'redux/rootSelectors';
import getChartData from 'utils/getChartData';
import {
  TNamingItem,
  GeneralPParent,
  TChartItems,
  TChartDate,
  SortEvent,
  TItem,
  TItemIndx,
  TOnSetFilterTable,
  TSeries,
} from '../../../types';
// import { useStateValue } from '../../../../../utils/context';
// import usePrevious from '../../../../../utils/usePrevious';
import { chartColors } from '../chartColors';
import { setDefChartItemsGenDefault } from '../../../reduser';
import { TFilter } from './useIntegrationFilter';

export interface PTreeNodes {
  nodes: TItemIndx[];
  namingItem: TNamingItem;
  firstCW: number;
  onSetFilterTable?: TOnSetFilterTable;
  filterTable: TFilter['activeColumns'];
  isUtmTags: boolean;
}

type TProps = {
  chartData: TChartDate[] | undefined;
  defChartItems: ReadonlyArray<string>;
  namingItem: TNamingItem;
  filtredChartData?: any;
  statistics: TItem[];
};

type TFUseReportGeneral = (p: TProps) => {
  memoizedStatistics: TItemIndx[];
  expand: number;
  isRenderChart: boolean;
  chartItems: ReadonlyArray<TChartItems>;
  series: TSeries;
  dataForChart: ReturnType<typeof getChartData>;
  setSortEvent: (v: SortEvent) => void;
  sortEvent: SortEvent;
  setExpand: (v: number) => void;
  setChartItems: (v: ReadonlyArray<TChartItems>) => void;
  chartData: TChartDate[];
  onClickChartLegend: (v: string) => () => void;
};

function setId(statistics: TItem[], trKey: string | null): TItemIndx[] {
  return statistics.map((item, index) => {
    const id: string = trKey ? `${item[trKey]}_${index}` : `_${index}`;

    let child: TItemIndx[] = [];
    let keyItem = '';
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (keyItem in item) {
      const i = item[keyItem];
      if (Array.isArray(i)) {
        child = setId(i, trKey);
      }
    }

    const sIndx: TItemIndx = { ...item, _ID: id };

    if (child.length && keyItem) {
      sIndx[keyItem] = child;
    }
    return sIndx;
  });
}

function changeStatistics(
  statistics: TItemIndx[],
  sortEvent: SortEvent,
): TItemIndx[] {
  if (statistics === undefined) {
    return [];
  }

  if (sortEvent.event === 'DEL_SORT') {
    return [...statistics];
  }

  const clone = [...statistics];

  if (sortEvent.event === 'ASCENDING' && sortEvent.item) {
    clone.sort((a, b) => {
      const i = a[sortEvent.item] ?? 0;
      const j = b[sortEvent.item] ?? 0;
      if (i > j) return 1;
      if (i === j) return 0;
      if (i < j) return -1;
      return 0;
    });
  }

  if (sortEvent.event === 'DESCENDING' && sortEvent.item) {
    clone.sort((a, b) => {
      const i = a[sortEvent.item] ?? 0;
      const j = b[sortEvent.item] ?? 0;
      if (i < j) return 1;
      if (i === j) return 0;
      if (i > j) return -1;
      return 0;
    });
  }

  let chald: TItemIndx[];
  let index;
  let objKey: string;
  clone.forEach((item, i) => {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in item) {
      const j = item[key];
      if (Array.isArray(j)) {
        chald = changeStatistics(j, sortEvent);
        index = i;
        objKey = key;
        if (typeof index === 'number' && Array.isArray(chald)) {
          clone[index][objKey] = chald;
        }
      }
    }
  });

  return clone;
}

export const useReportGeneral: TFUseReportGeneral = ({
  chartData = [],
  defChartItems,
  namingItem,
  statistics,
}) => {
  const dispatch = useDispatchApp();

  const [sortEvent, setSortEvent] = useState<SortEvent>({
    item: '',
    event: 'DEL_SORT',
  });

  // const [loading, setLoading] = useState<boolean>(false);
  /** Ширина содержимого таблицы */
  const [expand, setExpand] = useState<number>(240);
  /** Проверка на существует ли статистика для отрисовки графика */
  const isRenderChart = !!(chartData && chartData.length);

  function defchartItems2ChartItems(
    defchartItems: GeneralPParent['defchartItems'],
  ): ReadonlyArray<TChartItems> {
    if (defchartItems === undefined) return [];
    return defchartItems.map((i) => ({ name: i, isOff: false }));
  }

  const [chartItems, setChartItems] = useState<ReadonlyArray<TChartItems>>(
    defchartItems2ChartItems(defChartItems),
  );

  useEffect(() => {
    dispatch(
      setDefChartItemsGenDefault([...chartItems.map(({ name }) => name)]),
    );
  }, [chartItems]);

  const dataForChart = useMemo(
    () => getChartData(chartData, chartItems, namingItem),
    [chartData, chartItems, namingItem],
  );

  // const { state } = useStateValue();

  // const prevProps = usePrevious({ resize: state.theme.containerToggle });

  const series = useMemo<TSeries>(() => {
    const chartShow = chartItems.filter((i) => !i.isOff);
    return chartShow.map((i, j) => {
      const obj = namingItem.get(i.name);
      const color = obj === undefined ? chartColors[j] : obj.color;
      return {
        color,
        areaOpacity: 0.05,
        targetAxisIndex: 0,
      };
    });
  }, [chartItems]);

  // useEffect(() => {
  //   let removeLoading: ReturnType<typeof setTimeout>;
  //   if (prevProps && prevProps.resize !== state.theme.containerToggle) {
  //     setLoading(true);
  //     removeLoading = setTimeout(() => setLoading(false), 100);
  //   }
  //
  //   return () => {
  //     clearTimeout(removeLoading);
  //   };
  // }, [state.theme]);

  const onClickChartLegend: ReturnType<TFUseReportGeneral>['onClickChartLegend'] =
    (item) => () => {
      setChartItems(
        chartItems.map((i) => {
          if (i.name === item) return { name: i.name, isOff: !i.isOff };
          return i;
        }),
      );
    };

  const trKey: string | null = React.useMemo(() => {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of namingItem.keys()) {
      const obj = namingItem.get(key);
      // eslint-disable-next-line no-continue
      if (typeof obj === 'undefined') continue;
      if (obj.isIndex === true) {
        return key;
      }
    }
    return null;
  }, [namingItem]);

  const statisticsIndx: TItemIndx[] = useMemo(() => {
    if (!statistics) return [];

    return setId(statistics, trKey);
  }, [statistics, trKey]);

  const memoizedStatistics: TItemIndx[] = useMemo(
    () => changeStatistics(statisticsIndx, sortEvent),
    [statisticsIndx, sortEvent],
  );

  return {
    memoizedStatistics,
    expand,
    isRenderChart,
    chartItems,
    series,
    dataForChart,
    setSortEvent,
    sortEvent,
    setExpand,
    setChartItems,
    chartData: chartData || [],
    onClickChartLegend,
  };
};
