/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import { FiAlertCircle} from 'react-icons/fi';
import Dropdown from 'domains/creative/creatives/CreativeEdit/Moderation/Dropdown';
import css from '../styles.module.scss';
import { IDSPReturnData } from '../../hooks/useIsTranslation';
import TranslationInfoWrapper from '../TranslationInfoWrapper';
import i18n from "../../../../i18n";

interface Props {
  DSPTranslationData: IDSPReturnData;
  getModerationChecklistIcon: (condition: boolean) => JSX.Element;
  onClickHandler: () => void;
  time: string | boolean;
}

const DSPTranslationInfo: FC<Props> = ({
  DSPTranslationData: { isDSPCamp, isDSPCreative, isSDPPartner },
  getModerationChecklistIcon,
  onClickHandler,
  time,
}) => (
  <TranslationInfoWrapper onClickHandler={onClickHandler}>
    <Dropdown
      title={
        <div className={css.title}>
          <FiAlertCircle
            size={20}
            color="#FF9C08"
            className={css.title__icon}
          />
          <span>{i18n.t('translations.no_impressions')}</span>
        </div>
      }
    >
      <div className={css.not_uploaded}>
        <div className={css.date}>{time}</div>
        <div className={css.checklist}>
          {!isDSPCamp && (
            <div className={css.item}>
              <div className={css.icon}>
                {getModerationChecklistIcon(false)}
              </div>
              <div className={css.text}>
                <strong>{i18n.t('translations.camp_stop')}</strong>
                <span>{i18n.t('translations.call_admin')}</span>
              </div>
            </div>
          )}
          {!isSDPPartner && (
            <div className={css.item}>
              <div className={css.icon}>
                {getModerationChecklistIcon(false)}
              </div>
              <div className={css.text}>
                <strong>{i18n.t('translations.client_stop')}</strong>
                <span>{i18n.t('translations.call_admin')}</span>
              </div>
            </div>
          )}
          {!isDSPCreative && (
            <div className={css.item}>
              <div className={css.icon}>
                {getModerationChecklistIcon(false)}
              </div>
              <div className={css.text}>
                <strong>{i18n.t('translations.ad_stop')}</strong>
                <span>{i18n.t('translations.call_admin')}</span>
              </div>
            </div>
          )}
          {isDSPCreative && isDSPCamp && isSDPPartner && (
            <div className={css.item}>
              <div className={css.icon}>
                {getModerationChecklistIcon(false)}
              </div>
              <div className={css.text}>
                <strong>{i18n.t('translations.dsp_problem')}</strong>
                <span>{i18n.t('translations.fixed_soon')}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </Dropdown>
  </TranslationInfoWrapper>
);

export default DSPTranslationInfo;
