import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { getValueByName } from 'domains/project/model/utils/getbyLinkFile';
import { TFormikExtract } from 'types/general';
import { useDispatchApp } from 'redux/rootSelectors';
import { useProjectInfo } from 'domains/project/hooks';
import { objectOptimizationWithFormik } from 'utils/objectOptimizationWithFormik/objectOptimizationWithFormik';
import { setFilesProjectAction } from 'domains/project/model/actions';
import { setFormDefault } from 'domains/uploads/reducer';

type TProjectOfferForm = {
  offer: string;
  offer_en: string;
};

type TUseProjectOffer = TFormikExtract<TProjectOfferForm> & {
  edit: boolean;
  setEdit: (p: boolean) => void;
  handleFormikSubmit: () => void;
  handleFormikReset: () => void;
};

export const useProjectOffer = (): TUseProjectOffer => {
  const dispatch = useDispatchApp();

  const { data: project, LTU } = useProjectInfo();

  const [edit, setEdit] = useState(false);

  const formik = useFormik<TProjectOfferForm>({
    enableReinitialize: true,
    initialValues: {
      offer: '',
      offer_en: '',
    },
    onSubmit: (values) => {
      if (project) {
        const param = objectOptimizationWithFormik<TProjectOfferForm>(
          {
            offer: getValueByName(project.files, 'offer') || '',
            offer_en: getValueByName(project.files, 'offer_en') || '',
          },
          values,
        );
        if (param) {
          dispatch(
            setFilesProjectAction({
              setEdit,
              domain: window.location.host,
              files: [
                {
                  name: 'offer',
                  link: values.offer,
                },
                {
                  name: 'offer_en',
                  link: values.offer_en,
                },
              ],
            }),
          );
        } else {
          setEdit(false);
          dispatch(setFormDefault(true));
        }
      }
    },
  });

  useEffect(() => {
    if (project) {
      formik.setValues({
        offer: getValueByName(project.files, 'offer') || '',
        offer_en: getValueByName(project.files, 'offer_en') || '',
      });
    }
  }, [LTU]);

  /** функция сброса формы */
  const handleFormikReset = () => {
    dispatch(setFormDefault(true));
    if (project) {
      formik.setValues({
        offer: getValueByName(project.files, 'offer') || '',
        offer_en: getValueByName(project.files, 'offer_en') || '',
      });
      setEdit(false);
    }
  };

  /** функция отправки формы */
  const handleFormikSubmit = () => {
    formik.handleSubmit();
  };

  return {
    edit,
    setEdit,
    handleFormikSubmit,
    handleFormikReset,
    formikContext: formik,
    ...formik,
  };
};
