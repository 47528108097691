import {
  THookSelectorFetchDataInfo,
  THookStateInfo,
  TSelector,
  useGenFetchDataSelector,
  useSelectorTypes,
} from '../../../../redux/rootSelectors';
import { MlinkToImageState } from '../reduser';
import { TUploadFile } from '../../../../utils/upload';

/** Список селекторов */
export const selectorLinkToImage: TSelector<MlinkToImageState['loadImage']> = ({
  MlinkToImageR,
}) => MlinkToImageR.loadImage;

export const selectorLinkToIsShow: TSelector<MlinkToImageState['isShow']> = ({
  MlinkToImageR,
}) => MlinkToImageR.isShow;

export const selectorLinkToOnCanseled: TSelector<
  MlinkToImageState['onCanseled']
> = ({ MlinkToImageR }) => MlinkToImageR.onCanseled;

/** Список хуков */

export const useLinkToOnCanseledInfo: THookStateInfo<
  MlinkToImageState['onCanseled']
> = () => useSelectorTypes(selectorLinkToOnCanseled);

export const useLinkToIsShowInfo: THookStateInfo<
  MlinkToImageState['isShow']
> = () => useSelectorTypes(selectorLinkToIsShow);

export const useLinkToImageInfo: THookSelectorFetchDataInfo<TUploadFile> = () =>
  useGenFetchDataSelector(selectorLinkToImage);
