import Card from 'components/UI/Card';
import UploadImage from 'domains/client/UploadImage';
import React, { FC } from 'react';

interface Props {
  uploadHandler?: ((url: string | null) => void) | undefined,
  imgData?: string
}

const UploadImg: FC<Props> = ({ uploadHandler, imgData }) => (
    <Card noHover style={{ padding: '28px 24px' }} isUseAccess>
      <UploadImage uploadHandler={uploadHandler} imgData={imgData}/>
    </Card>
  );

export default UploadImg;
