import ModalOverlay from 'components/Modals/ModalOverlay1';
import Card from 'components/UI/Card';
import React, { FC } from 'react';
import { FiX } from 'react-icons/fi';
import Button from 'components/UI/Buttons/buttons';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';

interface IDeleteModalProps {
  onClose: () => void;
  onDel: () => void;
  email: string;
}

const DeleteModal: FC<IDeleteModalProps> = ({ onClose, onDel, email }) => {
  const { t } = useTranslation();

  return (
    <ModalOverlay onCloseModal={onClose} isOpen>
      <Card className={css.card} noHover style={{ padding: '28px' }}>
        <div className={css.card_header}>
          <h3 className={css.card_title}>
            {t(`users_page.delete_modal.title`)}
          </h3>
          <div className={css.card_close} onClick={onClose}>
            <FiX size={24} />
          </div>
        </div>
        <div className={css.card_body}>
          {t(`users_page.delete_modal.text`)}{' '}
          <strong>{email}</strong>?
        </div>
        <div className={css.card_actions}>
          <Button
            title={t(`cancel_btn`)}
            transparent
            height={40}
            buttonAttributes={{
              onClick: onClose,
            }}
          />
          <Button
            title={t(`delete_btn`)}
            height={40}
            buttonAttributes={{
              onClick: onDel,
            }}
          />
        </div>
      </Card>
    </ModalOverlay>
  );
};

export default DeleteModal;
