import { useEffect, useState } from 'react';
import { useDispatchApp } from 'redux/rootSelectors';
import { useBlFiles } from '../model/selectors';
import { TFileList } from '../types';
import { fetchBlFiles } from '../reducer';

interface IReturnData {
  blFiles: TFileList[];
  isLoading: boolean;
  setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setType: React.Dispatch<React.SetStateAction<string>>;
}

export const useBlackLists = (): IReturnData => {
  const dispatch = useDispatchApp();
  const { data, isLoading } = useBlFiles();
  const currentType = localStorage.getItem('currentType');
  const [type, setType] = useState<string>(currentType || '');
  const [blFiles, setBlFiles] = useState<TFileList[]>([]);
  const [update, setUpdate] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchBlFiles({ type }));
  }, []);

  useEffect(() => {
    if (update) {
      dispatch(fetchBlFiles({ type }));
      setUpdate(false);
    }
  }, [update, type])

  useEffect(() => {
    if (data) {
      setBlFiles(data);
    }
  }, [data]);

  return {
    blFiles,
    isLoading,
    setUpdate,
    setType,
  };
};
