export default (
  number: undefined | number | null | string | Date,
  inCorrect: undefined | string = '–',
): string => {
  if (typeof number === 'string' && number.length > 0) {
    return number;
  }

  if (typeof number !== 'number') return inCorrect;

  if (number === null || number === undefined) {
    return '-';
  }
  if (number === 0) {
    return '-';
  }

  if (typeof number === 'number') {
    return number.toLocaleString('ru');
  }

  return '-';
};
