import { TExperimentalInfoField } from 'domains/campaign/types';
import { fetchGridEridInfoAction } from 'domains/creative/model/actions';
import { setCurrentGridEridInfo } from 'domains/creative/reduser';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { genFetchedData } from 'redux/fetchedData';
import { useDispatchApp } from 'redux/rootSelectors';

interface Params {
  eridData: TExperimentalInfoField | null;
  eridVal?: string;
}

interface Return {
  isDisableSaveButton: boolean,
  setIsDisableSaveButton: React.Dispatch<React.SetStateAction<boolean>>
}

const debounceTimeout = 500

export const useCheckGridEridInfo = ({ eridData, eridVal }: Params): Return => {
  const dispatch = useDispatchApp();

  const [isDisableSaveButton, setIsDisableSaveButton] = useState(false);

  const debouncedFetchGridErid = debounce(
    (erid: string) => dispatch(fetchGridEridInfoAction({ erid })),
    debounceTimeout,
  );

  const fetchGridErid = useCallback(
    (erid: string) => debouncedFetchGridErid(erid),
    [],
  );

  useEffect(() => {
    if (!eridData || !eridVal) return;
    fetchGridErid(eridVal);
  }, [eridData, eridVal]);

  useEffect(() => {
    if (eridVal) {
      const clearData: any = genFetchedData(null).set('isLoading', false);
      dispatch(setCurrentGridEridInfo(clearData));
    }
  }, [eridVal]);

  return {
    isDisableSaveButton,
    setIsDisableSaveButton
  }
};
