import { useCallback, useEffect, useRef, useState } from "react";

interface IUseExtendProps {
    ready: boolean;
}

type IUseExtend = ({
    ready
}: IUseExtendProps) => {
    extendWidth: number,
    onMouseDown: () => void,
    tableRef: React.RefObject<HTMLTableSectionElement>,
    positionTop: number;
    positionLeft: number;
}

export const useExtend: IUseExtend = ({ ready }) => {
    const tableRef = useRef<HTMLTableSectionElement>(null);
    const [extendWidth, setExtendWidth] = useState<number>(240);
    const [positionTop, setPositionTop] = useState<number>(0);
    const [positionLeft, setPositionLeft] = useState<number>(0);
    /* Для определения желания раздвинуть колонку */
    const [extandStart, setExtandStart] = useState<boolean>(false);
    /* Для отслеживания положения мыши по оси Х */
    const [extendOffsetX, setExtendOffsetX] = useState<number>(0);

    /* Рассчитываем новую ширину исходи из положения мыши и устанавливаем */
    const onMouseMove = (e: MouseEvent) => {
        const newValue = e.clientX - extendOffsetX;
        if (newValue <= 700 && newValue >= 240) {
            setExtendWidth(newValue);
            setPositionLeft(e.clientX);
        }

    };

    /* Если клавиша мыши будет отпущена, то завершаем движение и удаляем обработчик события движения мыши */
    const onMouseUp = () => {
        document.addEventListener('mouseup', () => {
            setExtandStart(false);
            document.removeEventListener('mousemove', onMouseMove);
        });
    };

    /* Если начато движение, вешаем обработчик события - движение мыши */
    /* и обработчик отжатой мыши для завершения движения */
    useEffect(() => {
        if (extandStart) {
            document.addEventListener('mousemove', onMouseMove);
            onMouseUp();
        }
    }, [extandStart]);

    /* Обозначили, что движение начато. И отсчитали отсут от края экрана слева */
    const onMouseDown = useCallback(() => {
        setExtandStart(true);
        if (tableRef && tableRef.current) {
            // расстояние от края экрана - размер левого столбца. Находим отсуп до таблицы
            setExtendOffsetX(tableRef.current.offsetLeft);
        }
    }, []);

    useEffect(() => {
        if (tableRef.current) {
            setPositionTop(tableRef.current.getBoundingClientRect().top);
            setPositionLeft(tableRef.current.getBoundingClientRect().left + extendWidth);
        }
    }, [ready])

    return {
        extendWidth,
        onMouseDown,
        tableRef,
        positionTop,
        positionLeft,
    }
}