import Tooltip from 'components/UI/Tooltip';
import copy from 'copy-to-clipboard';
import React, { FC, useState } from 'react';
import i18n from '../../i18n';

interface InternalIdProp {
  internal_id: string;
}

const InternalId: FC<InternalIdProp> = ({ internal_id }) => {
  const [copied, setCopied] = useState(false);
  const handlerCopy = () => {
    copy(internal_id);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  return (
    <span className={copied ? 'copied' : ''} onClick={handlerCopy}>
      {!copied && `[${internal_id}] `}
      {copied && (
        <>
          <Tooltip title={`[${internal_id}]`} bottom>
            <span>{i18n.t('alerts.copied')}</span>
          </Tooltip>
          {` `}
        </>
      )}
    </span>
  );
};

export default InternalId;
