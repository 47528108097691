import React, { useState } from 'react';
import img from 'assets/errors/report-error.svg';
import Button from 'components/UI/Buttons/buttons';
import history from 'lib/history';
import BlackHeader from 'components/BlackHeader';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';

interface Props {
  error?: Error;
  changeHasErrorState: () => void;
}

export default function Error({
  error,
  changeHasErrorState,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [errorHash] = useState(history.location.pathname);

  // Кликхендлер для страницы с ошибкой, при каждом клике сверяем хеш, если он изменился, то меняем стейт ошибки на false.
  // Если не менять стейт, то возможность хождения по страницам блокирована

  const errorPageClickHandler = () => {
    const currentHash = history.location.pathname;
    if (errorHash !== currentHash) {
      changeHasErrorState();
    }
  };

  // Кликхендлер для кнопки "Вернуться"

  const GoBack = () => {
    const doc = document.referrer;

    if (!doc) {
      return history.replace('/main');
    }

    return history.goBack();
  };

  return (
    <div onClick={errorPageClickHandler}>
      <BlackHeader />
      <div className={css['error-boundary']}>
        <div className={css['error-boundary__form']}>
          <div className={css['error-boundary__code']}>
            <img
              className="fullscreen-error__img"
              src={img}
              alt={t(`offers_page.somthing_wrong`)}
            />
          </div>
          <div className={css['error-boundary__title']}>
            <span>{t(`offers_page.somthing_wrong`)}</span>
          </div>
          <div className={css['error-boundary__text']}>
            <span>{error?.message}</span>
          </div>
          <div className={css['error-boundary__link']}>
            <Button
              title="ВЕРНУТЬСЯ"
              buttonAttributes={{
                style: {
                  fontWeight: 700,
                  height: 48,
                },
                onClick: GoBack,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
