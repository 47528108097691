import { useEffect, useRef, useState } from "react";

type PropsUseShowMorePopup = {
    mainRef: React.MutableRefObject<HTMLDivElement | null>;
};

type TFUseShowMorePopup = (props: PropsUseShowMorePopup) => {
    hintRef: React.MutableRefObject<HTMLDivElement | null>;
    fixStyles: boolean;
    positionLeft: number;
    positionBottom: number;
};

export const useShowMorePopup: TFUseShowMorePopup = ({ mainRef }) => {
    const hintRef = useRef<HTMLDivElement | null>(null);
    const [positionLeft, setPositionLeft] = useState<number>(0);
    const [positionBottom, setPositionBottom] = useState<number>(0);
    const [fixStyles, setFixedStyles] = useState(false);
    const [fixTopPadding, setFixTopPadding] = useState<boolean>(false);
    const bodyRect = document.body.getBoundingClientRect();

    const calcPosition = () => {
        if (mainRef && mainRef.current && hintRef && hintRef.current) {
            setPositionBottom(mainRef.current?.getBoundingClientRect().bottom + 12);
            setPositionLeft(mainRef.current?.getBoundingClientRect().left + (mainRef.current.clientWidth / 2) - 5);
        }
    };

    useEffect(() => {
        calcPosition();
    }, []);

    useEffect(() => {
        if (mainRef && mainRef.current && hintRef && hintRef.current) {
            if (hintRef.current?.getBoundingClientRect().bottom > bodyRect.height) {
                setFixTopPadding(true);
            } else setFixTopPadding(false);
        }
    }, [positionBottom]);

    useEffect(() => {
        if (fixTopPadding) {
            if (mainRef && mainRef.current && hintRef && hintRef.current) {
                setPositionBottom(
                    mainRef.current?.getBoundingClientRect().top -
                    hintRef.current.getBoundingClientRect().height -
                    20,
                );
                setFixedStyles(true);
            }
        }
    }, [fixTopPadding]);

    return {
        hintRef,
        fixStyles,
        positionLeft,
        positionBottom,
    };
}