import React, { FC } from 'react';
import cn from 'classnames';
import { FiCheck } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import css from '../../../styles.module.scss';
import { TGroup } from '../../../../../types/general';
import i18n from '../../../../../i18n';

type AlertSuccessProps = {
  fromList: TGroup;
  toList: TGroup;
};

const AlertSuccess: FC<AlertSuccessProps> = ({ fromList, toList }) => (
  <div className={css.toast}>
    <div className={cn(css.icon, '_isSuccess')}>
      <FiCheck size={16} color="#fff" />
    </div>
    <div className={css.text}>
      {fromList.length === 1 &&
        `${i18n.t(
          `campaigns_page.campaigns_copy_modal.de_start_predix_one`,
        )} ${i18n.t(`just_words.w1`)} `}
      {fromList.length > 1 &&
        `${i18n.t(
          `campaigns_page.campaigns_copy_modal.de_start_predix_many`,
        )} ${i18n.t(`just_words.w6`)} `}
      {fromList.map((item) => (
        <b key={item.xxhash}>{item.title} </b>
      ))}
      {fromList.length === 1 &&
        ` ${i18n.t(
          `campaigns_page.campaigns_copy_modal.mass_copy_success_one`,
        )} `}
      {fromList.length > 1 &&
        ` ${i18n.t(
          `campaigns_page.campaigns_copy_modal.mass_copy_success_many`,
        )} `}
      {toList.length === 1 &&
        ` ${i18n.t(
          `campaigns_page.campaigns_copy_modal.mass_copy_in_one_client`,
        )} `}
      {toList.length > 1 &&
        ` ${i18n.t(
          `campaigns_page.campaigns_copy_modal.mass_copy_in_many_clients`,
        )} `}
      <div className={css.links}>
        {toList.map((e, index) => (
          <Link
            to={`/main/campaigns?client_id=${e.xxhash}`}
            key={e.xxhash}
            rel="noopener noreferrer"
            target="_blank"
          >
            <strong>{e.title}</strong>
            {toList.length - 1 !== index && <span>, </span>}
          </Link>
        ))}
      </div>
    </div>
  </div>
);
export default AlertSuccess;
