import { useCallback } from 'react';
import {
  initialState as stateAgency,
  setTempImage as setAgency,
} from '../../agency/reducer';
import {
  initialState as stateClient,
  setTempImage as setClient,
} from '../../client/reduser';
import { useDispatchApp } from '../../../redux/rootSelectors';

type TFUseClearLinkImage = () => () => void;

export const useClearLinkImage: TFUseClearLinkImage = () => {
  const dispatch = useDispatchApp();
  return useCallback(() => {
    dispatch(setAgency(stateAgency.tempImage));
    dispatch(setClient(stateClient.tempImage));
  }, [dispatch]);
};
