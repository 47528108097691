import React from 'react';
import cn from 'classnames';
import Card from 'components/UI/Card';
import css from './styles.module.scss';

type Props = {
  noTopLine?: boolean;
};

const HintWidget: React.FC<Props> = ({ noTopLine, children }) => (
  <Card
    noHover
    style={{ padding: '28px 22px' }}
    className={cn(css.widget, { noTopLine })}
  >
    {children}
  </Card>
);

export default HintWidget;
