import {
  THookSelectorFetchDataInfo,
  TSelector,
  useGenFetchDataSelector,
} from 'redux/rootSelectors';
import { AnalysisState } from './reducer';
import { TTargetItemsResponse, TGetCreativeListResponse } from './types';

export const selectorTargetItemsData: TSelector<
  AnalysisState['targetItemsData']
> = ({ analysisReducer }) => analysisReducer.targetItemsData;

export const selectorCreativeList: TSelector<AnalysisState['creativeList']> = ({
  analysisReducer,
}) => analysisReducer.creativeList;

export const useTargetItemsDataSelector: THookSelectorFetchDataInfo<
  TTargetItemsResponse
> = () => useGenFetchDataSelector(selectorTargetItemsData);

export const useCreativeListSelector: THookSelectorFetchDataInfo<
  TGetCreativeListResponse
> = () => useGenFetchDataSelector(selectorCreativeList);
