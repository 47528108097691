import { useState } from 'react';
import { useFormik } from 'formik';
import deepEqual from 'deep-equal';
import { useUserTheme } from 'domains/user/model/selectors';
import { TCreativeMultiFormat } from '../../../../../types';
import { TFormikExtract } from '../../../../../../../types/general';
import { setFormDefault } from '../../../../../../uploads/reducer';
import { useDispatchApp } from '../../../../../../../redux/rootSelectors';
import { setEditCreativeRequest } from '../../../../../reduser';
import i18n from '../../../../../../../i18n';

type TFUseEditMultiFormatProps = {
  data: TCreativeMultiFormat;
  xxhash: string;
};

type TFUseEditMultiFormat = (
  props: TFUseEditMultiFormatProps,
) => TFormikExtract<TCreativeMultiFormat> & {
  edit: boolean;
  setEdit: (p: boolean) => void;
  handlerFormikReset: () => void;
  handlerFormikSubmit: () => void;
};
export const useEditMultiFormat: TFUseEditMultiFormat = ({
  data: { text, title, button, legal_information, images },
  xxhash,
}) => {
  const dispatch = useDispatchApp();
  const { getValueFromSettingForKey } = useUserTheme();
  const [edit, setEdit] = useState(false);

  const formik = useFormik<TCreativeMultiFormat>({
    initialValues: {
      images,
      title,
      button,
      legal_information,
      text,
    },
    onSubmit: (values) => {
      if (
        !deepEqual(
          {
            images,
            title,
            button,
            legal_information,
            text,
          },
          values,
        )
      ) {
        dispatch(
          setEditCreativeRequest({
            data: { xxhash, data: values },
            visual: {
              setter: setEdit,
              translation:
                getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
                !getValueFromSettingForKey('ShowBroadcastStatus'),
              value: false,
              alertText: `${i18n.t('creative.type.type')}${i18n.t(
                'creative.create_menu.MULTI_FORMAT',
              )}`,
            },
          }),
        );
      } else {
        dispatch(setFormDefault(true));
        setEdit(false);
      }
    },
  });

  /** Функция сбрасывает состояние формы */
  const handlerFormikReset = () => {
    setEdit(false);
    dispatch(setFormDefault(true));
    formik.resetForm();
  };

  /** Функция отправляет форму */
  const handlerFormikSubmit = () => {
    formik.handleSubmit();
  };

  return {
    handlerFormikReset,
    handlerFormikSubmit,
    edit,
    setEdit,
    formikContext: formik,
    ...formik,
  };
};
