import Input, { IinputValue, TAllProps } from 'components/UI/Input/input';
import React, { FC, useState } from 'react';
import { ReactComponent as ShowPassSvg } from '../../assets/show_pass.svg';
import { ReactComponent as HidePassSvg } from '../../assets/hide_pass.svg';
import css from './styles.module.scss'

const InputWithPass: FC<TAllProps & IinputValue> = ({ inputAttributes, ...restProps }) => {
  const [showPass, setShowPass] = useState(false);
  return (
    <Input
      icon={
        <div onClick={() => setShowPass((prev) => !prev)}>
          {inputAttributes?.value &&
            (showPass ? <HidePassSvg /> : <ShowPassSvg />)}
        </div>
      }
      icon_classname={css.toggle_pass}
      inputAttributes={{
        ...inputAttributes,
        type: showPass ? 'text' : 'password',
      }}
      {...restProps}
    />
  );
};

export default InputWithPass;
