import React, { FC, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { FiSearch, FiX } from 'react-icons/fi';
import css from './styles.module.scss';
import { useSearchTags, useSearchTagsCreative } from './hooks';
import { RSearch } from '../../domains/search/model/types';
import avatarColors from '../../utils/avatarColors';
import { useDispatchApp } from '../../redux/rootSelectors';
import { setTagCampaignActiveDefault } from '../../domains/search/model/actions';
import { setTagCampaignActive } from '../../domains/search/model/reducer';
import { useSearchStateInfo } from '../../domains/search/model/hooks';

interface ItemProps {
  data: RSearch;
}

const Preloader: FC = () => (
  <div className={css.preloader}>
    <div className={css.dot} />
    <div className={css.dot} />
    <div className={css.dot} />
  </div>
);

const EmptyList: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={css.empty}>
      <span>{t('search_by_id_popup.empty_list')}</span>
    </div>
  );
};

const Item: FC<ItemProps> = ({
  data: {
    title,
    type,
    xxhash,
    bread_crumbs: { Campaign },
  },
}) => {
  const url = (): string => {
    if (type === 'Campaign') {
      return `/main/campaign/${xxhash}/main`;
    }
    if (type === 'Creative') {
      return `/main/campaign/${Campaign.xxhash}/creatives/${xxhash}/basic_settings`;
    }
    if (type === 'Partner') {
      return `/main/campaigns?client_id=${xxhash}`;
    }
    return '/main/clients';
  };

  return (
    <Link to={url()} className={css.item}>
      <div
        className={css.icon}
        style={{
          backgroundColor: avatarColors(title.slice(0, 1).toLowerCase()),
        }}
      >
        <span>{title.slice(0, 1).toUpperCase()}</span>
      </div>
      <div className={css.name}>
        <span>{title}</span>
      </div>
      <div className={css.id} />
    </Link>
  );
};

const SearchByIdPopup: FC = () => {
  const dispatch = useDispatchApp();
  const searchRef = useRef<HTMLInputElement>(null);

  const location = useLocation();

  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState<string>('');

  const {
    isShowModal,
    closeModal,
    error,
    isLoading,
    search,
    fetchSearch,
    tempSearchDefault,
  } = useSearchStateInfo();

  const { tagsCampaigns, tagCampaignsActive } = useSearchTags(isShowModal);

  const {
    handleActiveTag,
    allTags,
    activeTags,
    isLoading: isLoadingAllCreativeTag,
  } = useSearchTagsCreative({ isShowModal });

  const handlerSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const handlerKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      fetchSearch(searchValue);
    }
  };

  const clearSearchValue = () => {
    tempSearchDefault();
    setSearchValue('');
  };

  const onHide = (event: MouseEvent) => {
    if (event.target instanceof Element) {
      const targetContainer = event.target.closest(`.${css.popup}`);
      if (targetContainer) {
        return;
      }
      closeModal();
    }
  };

  useEffect(() => {
    if (searchValue === '') {
      clearSearchValue();
    }
  }, [searchValue]);

  useEffect(() => {
    if (isShowModal) {
      document.addEventListener('click', onHide);
    }
    clearSearchValue();
    if (isShowModal && searchRef.current) {
      searchRef.current.focus();
    }
    return function () {
      document.removeEventListener('click', onHide);
    };
  }, [isShowModal]);

  useEffect(() => {
    dispatch(setTagCampaignActiveDefault(true));
    closeModal();
  }, [location.pathname]);

  return (
    <div className={css.wrapper}>
      <div className={cn(css.popup, { isOpen: isShowModal })}>
        <div className={css.header}>
          <div className={css.filter}>
            <div className={css.input}>
              <input
                type="text"
                autoComplete="off"
                placeholder={t('search_by_id_popup.filter.placeholder')}
                value="Jump to"
                onChange={() => 'Jump to'}
              />
            </div>
            <div className={css.close}>
              <FiX size={18} />
            </div>
          </div>
          <div className={css.search}>
            <FiSearch size={24} className={css.icon} />
            <div className={css.input}>
              <input
                ref={searchRef}
                type="text"
                autoComplete="off"
                placeholder={t('search_by_id_popup.search.placeholder')}
                value={searchValue}
                onChange={handlerSearch}
                onKeyDown={handlerKeyDown}
              />
            </div>
            <div className={css.clear} onClick={clearSearchValue}>
              {t('search_by_id_popup.search.clear')}
            </div>
          </div>
          <div className={css.close} onClick={closeModal}>
            <FiX size={18} />
          </div>
        </div>
        <div className={css.result}>
          {isLoading && <Preloader />}
          {error.isError && <EmptyList />}
          {search && <Item data={search} />}
        </div>
        {!!tagsCampaigns.length && (
          <div className={css.tags}>
            {tagsCampaigns.map((tag) => (
              <span
                className={cn(css.tag, {
                  _isActive: tagCampaignsActive.includes(tag),
                })}
                onClick={() => {
                  if (tagCampaignsActive.includes(tag)) {
                    dispatch(
                      setTagCampaignActive([
                        ...tagCampaignsActive.filter((el) => el !== tag),
                      ]),
                    );
                  } else {
                    dispatch(
                      setTagCampaignActive([...tagCampaignsActive, tag]),
                    );
                  }
                }}
                key={tag}
              >
                {tag}
              </span>
            ))}
          </div>
        )}
        {isLoadingAllCreativeTag && <Preloader />}
        {allTags && !!allTags.length && (
          <div className={css.tags}>
            {allTags.map((tag) => (
              <span
                className={cn(css.tag, {
                  _isActive: activeTags.includes(tag),
                })}
                onClick={() => {
                  handleActiveTag(tag);
                }}
              >
                {tag}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchByIdPopup;
