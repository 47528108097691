import { SagaIterator } from 'redux-saga';
import { put, call, SagaReturnType } from 'redux-saga/effects';
import i18next from 'i18next';
import { setTempImage } from 'domains/client/reduser';
import { setTempImage as setTempImageAgency } from 'domains/agency/reducer';
import { fetchImage } from '../actions';
import { setLoadImage } from '../reduser';
import updateToken from '../../../../utils/updateToken';
import uploadImage, {
  isTUploadErr,
  TResult,
  TUploadErr,
  TUploadFile,
} from '../../../../utils/upload';
import { genFetchedData } from '../../../../redux/fetchedData';
import { getUploadFileUrl } from '../../../uploads/model/api';

export const errors4Image: Map<number, string> = new Map<number, string>([
  [2032, i18next.t('clients_page.client_create.hint2')],
  [2033, i18next.t('clients_page.client_create.hint11')],
  [2034, i18next.t('clients_page.client_create.hint4')],
  [2035, i18next.t('clients_page.client_create.hint3')],
  [2040, i18next.t('clients_page.client_create.hint5')],
  [2041, i18next.t('errors.err2041')],
  [-20001, i18next.t('clients_page.client_create.hint2')],
  [-20002, i18next.t('clients_page.client_create.hint3')],
]);

export function* workerFetchImage({
  payload,
}: ReturnType<typeof fetchImage>): SagaIterator<void> {
  let fetchedData = genFetchedData<TUploadFile>(null).set('isLoading', true);
  yield put(setLoadImage(fetchedData));
  try {
    yield call(updateToken);
    const requestUpload: SagaReturnType<typeof getUploadFileUrl> = yield call(
      getUploadFileUrl,
      { type: 'project' },
    );
    const formData = new FormData();
    formData.set('logo', payload);
    const data: TResult<Record<string, TUploadFile>> | TUploadErr = yield call(
      uploadImage,
      requestUpload,
      formData,
    );
    const error = isTUploadErr(data) ? data.error : undefined;
    const result = isTUploadErr(data) ? undefined : data.result.logo;

    if (error !== undefined) {
      throw error;
    }

    if ((result?.size ?? 0) > 1024) {
      const er = { code: -20001, message: '' };
      throw er;
    }

    if ((result?.width ?? 0) > 2000 || (result?.height ?? 0) > 2000) {
      const er = { code: -20002, message: '' };
      throw er;
    }

    if (result !== undefined) {
      result.size *= 1000;
      fetchedData = fetchedData.set('data', result);
    }
  } catch (e) {
    console.error({ e });
    const code = e?.code ?? 0;
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: errors4Image.get(code) ?? '',
      code,
    });
    yield put(setLoadImage(fetchedData));
  } finally {
    fetchedData = fetchedData.set('isLoading', false).set('LTU', Date.now());

    if (fetchedData.get('data') !== null) {
      yield put(setTempImage(fetchedData));
      yield put(setTempImageAgency(fetchedData));
    }

    yield put(setLoadImage(fetchedData));
  }
}
