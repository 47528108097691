import { SelectField } from 'types/general';
import { ProjectState } from '../reducer';
import {
  THookSelectorFetchDataInfo,
  THookStateInfo,
  TSelector,
  useGenFetchDataSelector,
  useSelectorTypes,
} from '../../../redux/rootSelectors';

type TFUseIsError404 = () => {
  isError: ProjectState['isError404'];
};

export const selectorProject: TSelector<ProjectState['tempProject']> = ({
  projectReducer,
}) => projectReducer.tempProject;

export const selectorLangGroup: TSelector<ProjectState['langGroup']> = ({
  projectReducer,
}) => projectReducer.langGroup;

export const selectorLang: TSelector<ProjectState['lang']> = ({
  projectReducer,
}) => projectReducer.lang;

export const selectorProjectLogoType: TSelector<ProjectState['logoType']> = ({
  projectReducer,
}) => projectReducer.logoType;

export const selectorIsError404: TSelector<ProjectState['isError404']> = ({
  projectReducer,
}) => projectReducer.isError404;

export const useIsError404: TFUseIsError404 = () => ({
  isError: useSelectorTypes(selectorIsError404),
});

export const useLangGroupInfo: THookStateInfo<ProjectState['langGroup']> = () =>
  useSelectorTypes(selectorLangGroup);

export const useLangInfo: THookStateInfo<ProjectState['lang']> = () =>
  useSelectorTypes(selectorLang);

export const useProjectLogoTypeInfo: THookSelectorFetchDataInfo<string> = () =>
  useGenFetchDataSelector(selectorProjectLogoType);

export const selectorUsersGroups: TSelector<ProjectState['userGroups']> = ({
  projectReducer,
}) => projectReducer.userGroups;

export const useUsersGroupsInfo: THookSelectorFetchDataInfo<
  SelectField[]
> = () => useGenFetchDataSelector(selectorUsersGroups);
