/* eslint-disable no-nested-ternary */
import {
  THistoryResponse,
  IHistoryLimitsStat,
  IHistoryReportsItems,
  TTargetingItem,
  IHistoryReports,
  IHistoryData,
  IHistoryLimits,
  ICategories,
  IHistoryCreativeData,
  IHistoryAlgorithmCpc,
} from 'domains/historyChanges/types';
import { format } from 'date-fns';
import { languageDescription } from '../utils/languageDescription';
import { historyDescription } from '../utils/historyDescription';
import { targetingDescription } from '../utils/targetingDescription';
import i18n from '../../../../i18n';

interface ILocalHistoryData {
  key: string,
  value: string | ILocalHistoryData | ILocalHistoryData[] | string[];
}

interface IReturnTypes {
  transformedDate: string;
  transformedParametrs: string;
  transformedChanges: string;
  historyDataForLocalHistory: ILocalHistoryData[];
}
interface IChanges<T> {
  isCreative: T | null;
  changes: T;
}
export interface IUpdatedHistory<T> extends THistoryResponse {
  text: T;
}

export const useTransformHistoryData = (
  data: THistoryResponse,
): IReturnTypes => {
  const { date } = data.datetime;
  const { item_type: type, action } = data;
  const transformedDate = format(new Date(date), 'dd.MM.yyyy HH:mm');
  const resultStatusArr: string[] = [];
  const resultChangesArr: string[] = [];
  const historyDataForLocalHistory: ILocalHistoryData[] = [];
  const isGlobalHistory = window.location.pathname.includes('/control_panel/history_changes');
  // Общая функция для заполнения массивов 'Включено' и 'Выключено'
  const fillOnOffArrs = (
    arr: [
      string,
      IHistoryReportsItems | Record<string, IHistoryReportsItems> | string,
    ],
    reportsOnArr: string[],
    reportsOffArr: string[],
    mode?: string,
    bidDataArr?: string[],
  ) => {
    const reportsItemName =
      mode === 'reports'
        ? historyDescription[arr[0]]
          ? historyDescription[arr[0]]
          : 'Неизвестный отчёт'
        : arr[0];
    if (arr[0] === 'disallow_group') {
      const brandSafetyData = Object.entries(arr[1]);
      brandSafetyData.forEach((item) =>
        fillOnOffArrs(item, reportsOnArr, reportsOffArr),
      );
      return;
    }
    if (arr[1] === 'null') {
      reportsOffArr.push(reportsItemName);
      return;
    }
    if (typeof arr[1] !== 'string') {
      if (arr[1].is_enabled || arr[1].is_checked || arr[1].checked) {
        reportsOnArr.push(reportsItemName);
      }
      if (
        arr[1].is_enabled === false ||
        arr[1].is_checked === false ||
        arr[1].checked === false
      ) {
        reportsOffArr.push(reportsItemName);
      }
      if (bidDataArr) {
        const bidDataRow: string[] = [];
        if (arr[1].bid_rate || arr[1].bid_rate === 0) {
          bidDataRow.push(`BIDRATE - ${arr[1].bid_rate}`);
        }
        if (arr[1].hour || arr[1].hour === 0) {
          bidDataRow.push(`АКТ.Ч - ${arr[1].hour}`);
        }
        if (arr[1].bid_start || arr[1].bid_start === 0) {
          bidDataRow.push(`BID - ${arr[1].bid_start}`);
        }
        if (arr[1].maxbid || arr[1].maxbid === 0) {
          bidDataRow.push(`MAXBID - ${arr[1].maxbid}`);
        }
        if (bidDataRow.length) {
          bidDataArr.push(`${reportsItemName}: ${bidDataRow.join(', ')}`);
        }
      }
    }
  };
  // Функции расшифровки отчетов
  const transformeReportsRow = (
    obj: Record<string, IHistoryReportsItems> | string,
    onArr: string[],
    offArr: string[],
    mode?: string,
    cleanArr?: string[],
    name?: string,
  ) => {
    if (mode === 'targetings' && name) {
      if (obj === 'null' && cleanArr) {
        cleanArr.push(`"${targetingDescription[name] ?? name}"\n`);
        return;
      }
      const onTargetingArr: string[] = [];
      const offTargetingArr: string[] = [];
      const bidDataArr: string[] = [];
      const targetingDataArr: [string, IHistoryReportsItems][] =
        Object.entries(obj);
      targetingDataArr.forEach((item) =>
        fillOnOffArrs(item, onTargetingArr, offTargetingArr, mode, bidDataArr),
      );
      const returnChangedBidsOrHourKeys = (
        targetData: [string, IHistoryReportsItems][],
      ): string[][] => {
        const bidChanges = [];
        const hourChanges = [];
        for (let i = 0; i <= targetData.length; i += 1) {
          if (targetData[i]) {
            if (
              Object.keys(targetData[i][1]).includes('bid_start') ||
              Object.keys(targetData[i][1]).includes('bid_rate') ||
              Object.keys(targetData[i][1]).includes('maxbid')
            ) {
              bidChanges.push(targetData[i][0]);
            }
            if (Object.keys(targetData[i][1]).includes('hour')) {
              hourChanges.push(targetData[i][0]);
            }
          }
        }
        return [bidChanges, hourChanges];
      };
      const [bidChanges, hourChanges] = returnChangedBidsOrHourKeys(targetingDataArr);
      if (
        onTargetingArr.length ||
        offTargetingArr.length ||
        bidDataArr.length
      ) {
        historyDataForLocalHistory.push({
          key: '',
          value: `В таргете "${targetingDescription[name] ?? name}:" ${
            bidChanges.length
              ? `\nИзменен BID: ${bidChanges.join(', ')},` 
              : ''
          }${
            hourChanges.length
              ? `\nИзменен АКТ.Ч: ${hourChanges.join(', ')},`
              : ''
          }${
            onTargetingArr.length
              ? `\nВключены элементы: ${onTargetingArr.join(', ')},`
              : ''
          }${
            offTargetingArr.length
              ? `\nВыключены элементы: ${offTargetingArr.join(', ')},`
              : ''
          }`,
        });
        resultChangesArr.push(
          `В таргете "${targetingDescription[name] ?? name}":
                ${
                  onTargetingArr.length
                    ? `Включены элементы: ${onTargetingArr.join(', ')},`
                    : ''
                }${
            offTargetingArr.length
              ? `Выключены элементы: ${offTargetingArr.join(', ')},`
              : ''
          }${bidDataArr.join(',')}`.trim(),
        );
        return;
      }
    }
    const reportsDataArr: [string, IHistoryReportsItems][] =
      Object.entries(obj);
    reportsDataArr.forEach((item) => fillOnOffArrs(item, onArr, offArr, mode));
  };

  const describeReports = (obj: IHistoryReports) => {
    const onReportsArr: string[] = [];
    const offReportsArr: string[] = [];
    if (obj.items)
      transformeReportsRow(obj.items, onReportsArr, offReportsArr, 'reports');
    let reportsInheritSettings = '';
    if (obj.use_inherit_settings || obj.use_inherit_settings === false) {
      reportsInheritSettings = obj.use_inherit_settings
        ? '\nВключено использование настроек клиента'
        : '\nВыключено использование настроек клиента';
    }
    historyDataForLocalHistory.push({
      key: 'Отчеты',
      value: `${
        onReportsArr.length
          ? `\nВключены отчеты: \n${onReportsArr.join('; ')},`
          : ''
      }${
        offReportsArr.length
          ? `\nВыключены отчеты: \n${offReportsArr.join('; ')},`
          : ''
      }${reportsInheritSettings}`,
    });
    resultChangesArr.push(
      `${
        onReportsArr.length
          ? `\nВключены отчеты: \n${onReportsArr.join('; ')},`
          : ''
      }${
        offReportsArr.length
          ? `\nВыключены отчеты: \n${offReportsArr.join('; ')},`
          : ''
      }${reportsInheritSettings}`,
    );
  };
  // Функции расшифровки таргетов
  const fillClearedTargetingsArr = (arr: string[], targetName: string) => {
    arr.push(`"${targetName}"`);
  };

  const transformTargetingsData = (
    tData: [string, string | TTargetingItem],
    onTargetsArr: string[],
    offTargetsArr: string[],
    cleanArr: string[],
    invertMode?: string[],
    onInheritSettings?: string[],
    offInheritSettings?: string[],
  ) => {
    if (typeof tData[1] !== 'string') {
      if (
        invertMode &&
        (tData[1].settings?.is_invert_mode ||
          tData[1].settings?.is_invert_mode === false)
      ) {
        invertMode.push(
          `Для таргета "${targetingDescription[tData[0]] ?? tData[0]}" включен режим ${
            tData[1].settings?.is_invert_mode ? 'blacklist' : 'whitelist'
          }`,
        );
      }
      if (
        onInheritSettings &&
        offInheritSettings &&
        (tData[1].settings?.use_inherit_settings ||
          tData[1].settings?.use_inherit_settings === false)
      ) {
        if (tData[1].settings?.use_inherit_settings) {
          onInheritSettings.push(`"${targetingDescription[tData[0]] ?? tData[0]}"`);
        } else {
          offInheritSettings.push(`"${targetingDescription[tData[0]] ?? tData[0]}"`);
        }
      }
      if (tData[1].items) {
        transformeReportsRow(
          tData[1].items,
          onTargetsArr,
          offTargetsArr,
          'targetings',
          cleanArr,
          tData[0],
        );
      }
    }
    if (tData[1] === 'null') {
      const targetName = targetingDescription[tData[0]]
        ? targetingDescription[tData[0]]
        : tData[0];
      fillClearedTargetingsArr(cleanArr, targetName);
    }
  };

  const describeTargetings = (obj: Record<string, TTargetingItem>) => {
    const targetingsData = Object.entries(obj);
    const onTargetsArr: string[] = [];
    const offTargetsArr: string[] = [];
    const cleanTargetArr: string[] = [];
    const invertMode: string[] = [];
    const onInheritSettings: string[] = [];
    const offInheritSettings: string[] = [];
    const targetingsNames = targetingsData.map((tData) =>
      targetingDescription[tData[0]]
        ? targetingDescription[tData[0]]
        : tData[0],
    );
    targetingsData.forEach((tData) =>
      transformTargetingsData(
        tData,
        onTargetsArr,
        offTargetsArr,
        cleanTargetArr,
        invertMode,
        onInheritSettings,
        offInheritSettings,
      ),
    );
    if (isGlobalHistory) {
      resultStatusArr.push(
        `${targetingsNames.length > 1 ? 'Таргетинги' : 'Таргетинг'}`,
      );
    }
    if (cleanTargetArr.length) {
      historyDataForLocalHistory.push({
        key: 'Таргет очищен',
        value: `${
          cleanTargetArr.length > 1
            ? cleanTargetArr.join(', ')
            : cleanTargetArr[0]
        }`
      })
      resultChangesArr.push(
        `Таргет очищен: ${
          cleanTargetArr.length > 1
            ? cleanTargetArr.join(', ')
            : cleanTargetArr[0]
        }`,
      );
    }
    if (onInheritSettings.length) {
      historyDataForLocalHistory.push({
        key: '',
        value: `Для таргета ${onInheritSettings.join(', ')} используются настройки таргетинга кампании`,
      });
      resultChangesArr.push(
        `Для таргета ${onInheritSettings.join(
          ', ',
        )} используются настройки таргетинга кампании`,
      );
    }
    if (offInheritSettings.length) {
      historyDataForLocalHistory.push({
        key: ``,
        value: `Для таргета ${offInheritSettings.join(', ')} используются настройки таргетинга креатива`,
      });
      resultChangesArr.push(
        `Для таргета ${offInheritSettings.join(
          ', ',
        )} используются настройки таргетинга креатива`,
      );
    }
    if (invertMode.length) {
      historyDataForLocalHistory.push({
        key: `${targetingsNames.length > 1 ? 'Таргетинги' : 'Таргетинг'}`,
        value: `${invertMode.join(',\n')}`,
      });
      resultChangesArr.push(`${invertMode.join(',\n')}`);
    }
  };
  // функции расшифровки лимитов
  const transformeLimitsRow = (
    obj: IHistoryLimitsStat,
    key: string,
    translate: string,
  ) => {
    if (obj[key])
      return `${translate}: ${obj[key] === 'null' ? '-' : `${obj[key]}`},`;
    return '';
  };

  const transformeLimitsRowData = (
    obj: IHistoryLimits,
    key: string,
    limitsTitle?: string,
  ) => {
    if (obj) {
      if (key === 'use_adriver_frequency_cap' && obj[key])
        return obj[key] === 'null'
          ? 'Выключено ограничение частоты показа Adriver'
          : 'Включено ограничение частоты показа Adriver';
      if (obj[key] === 'null') return `${limitsTitle}: - \n`;
      if (obj[key]) {
        return `${limitsTitle}: ${transformeLimitsRow(
          obj[key],
          'hour',
          'Час',
        )} ${transformeLimitsRow(
          obj[key],
          'day',
          'День',
        )} ${transformeLimitsRow(obj[key], 'total', 'Всего')}\n`;
      }
    }
    return '';
  };
  // функции расшифровки претаргетов и бренд сефти
  const fillBrandSafetyRu = (
    item: [string, IHistoryReportsItems],
    arr: string[],
  ) =>
    arr.push(
      item[1].checked
        ? 'Ротируем на сайты, где встретился русский текст'
        : 'Ротируем на все сайты',
    );

  const describePretargetings = (
    name: string,
    pretargetData:
      | Record<
          string,
          IHistoryReportsItems | Record<string, IHistoryReportsItems>
        >
      | string,
    jsonData: IHistoryData,
  ) => {
    const dataObj = {key: '', value: ''};
    if (name === 'pretargeting') {
      resultStatusArr.push(historyDescription[name][type]);
      dataObj.key = `${historyDescription[name][type]}`;
      if (pretargetData === 'null' && jsonData.brand_safety === 'null') {
        dataObj.value = '\nИспользуются настройки претаргетинга кампании'
        historyDataForLocalHistory.push(dataObj);
        resultChangesArr.push('Используются настройки претаргетинга кампании');
        return;
      }
    } else {
      dataObj.key = historyDescription[name];
      resultStatusArr.push(historyDescription[name]);
      if (pretargetData === 'null' && jsonData.pretargeting === 'null') {
        return;
      }
    }
    const pretargetingRu: string[] = [];
    const onPretargeting: string[] = [];
    const offPretargeting: string[] = [];
    const dataArr = Object.entries(pretargetData);
    dataArr.forEach((item) =>
      item[0] === 'Сайты с русским текстом'
        ? fillBrandSafetyRu(
            item as [string, IHistoryReportsItems],
            pretargetingRu,
          )
        : fillOnOffArrs(item, onPretargeting, offPretargeting),
    );
    dataObj.value = `\n${
      pretargetingRu.length ? `${pretargetingRu.join(', ')}\n` : ''
    }${onPretargeting.length ? `Включен: ${onPretargeting.join(', ')}\n` : ''}${
      offPretargeting.length ? `Выключен: ${offPretargeting.join(', ')}\n` : ''
    }`;
    resultChangesArr.push(
      `${pretargetingRu.length ? `${pretargetingRu.join(', ')}\n` : ''}${
        onPretargeting.length ? `Включен: ${onPretargeting.join(', ')}\n` : ''
      }${
        offPretargeting.length
          ? `Выключен: ${offPretargeting.join(', ')}\n`
          : ''
      }`,
    );
    historyDataForLocalHistory.push(dataObj);
  };
  // заполнение массива тегов
  const fillTagsArr = (item: string[], arr: string[]) => {
    if (item[1]) {
      arr.push(
        item[1] === 'null'
          ? `Удален тег: ${item[0]}`
          : `Добавлен тег: ${item[0]}`,
      );
    }
  };
  // расшифровка категорий
  const categoriesDescription = (categData: ICategories): ILocalHistoryData[] => {
    const categoriesArr = [];
    if (categData.iab) {
      resultChangesArr.push(`IAB: ${categData.iab}`);
      categoriesArr.push({
        key: 'IAB',
        value: `${categData.iab}`
      })
    }
    if (categData.is_animated || categData.is_animated === false) {
      resultChangesArr.push(
        `Анимация: ${categData.is_animated ? 'включена' : 'выключена'}`,
      );
      categoriesArr.push({
        key: 'Анимация',
        value: `${categData.is_animated ? 'включена' : 'выключена'}`
      })
    }
    if (categData.violence_rank || categData.violence_rank === 0) {
      resultChangesArr.push(
        `Тип жести: ${historyDescription.violence[categData.violence_rank]}`,
      );
      categoriesArr.push({
        key: 'Тип жести',
        value: `${historyDescription.violence[categData.violence_rank]}`
      })
    }
    if (categData.erotic_rank || categData.erotic_rank === 0) {
      resultChangesArr.push(
        `Тип эротики: ${historyDescription.erotic[categData.erotic_rank]}`,
      );
      categoriesArr.push({
        key: 'Тип эротики',
        value: `${historyDescription.erotic[categData.erotic_rank]}`
      })
    }
    return categoriesArr;
  };
  // расшифровка creative data объекта
  const creativeDataDescription = (obj: IHistoryCreativeData) => {
    const creativeData = Object.entries(obj);
    creativeData.forEach((item) => {
      if (historyDescription.dataValues[item[0]]) {
        if (item[0] === 'use_vpaid') {
          historyDataForLocalHistory.push({
            key: `Галка ${historyDescription.dataValues[item[0]]}`,
            value: item[1] ? 'включена' : 'выключена'
          })
          resultChangesArr.push(
            `Галка ${historyDescription.dataValues[item[0]]} ${
              item[1] ? 'включена' : 'выключена'
            }`,
          );
          return;
        }
        if (item[0] === 'html_code') {
          resultChangesArr.push(
            `${historyDescription.dataValues[item[0]]}: ${
              item[1] === 'changed' ? 'изменён' : item[1]
            }`,
          );
          historyDataForLocalHistory.push({
            key: `${historyDescription.dataValues[item[0]]}`,
            value: `${item[1] === 'changed' ? 'изменён' : item[1]}`,
          });
          return;
        }
        resultChangesArr.push(
          `${historyDescription.dataValues[item[0]]}: ${
            item[1] ? item[1] : 'Поле очищено'
          }`,
        );
        historyDataForLocalHistory.push({
          key: `${historyDescription.dataValues[item[0]]}`,
          value: `${item[1] ? item[1] : 'Поле очищено'}`,
        });
      }
    });
  };
  // расшифровка algorithm_cpc
  const algorithmCpcDescription = (obj: IHistoryAlgorithmCpc) => {
    const algorithmCpcData = Object.entries(obj);
    const value: any[] = [];
    algorithmCpcData.forEach((item) => {
      if (item[0] === 'id') {
        value.push(historyDescription.cpcData[item[1]]);
        resultChangesArr.push(historyDescription.cpcData[item[1]]);
        return;
      }
      if (historyDescription.cpcData[item[0]]) {
        value.push(`${historyDescription.cpcData[item[0]]}: ${item[1]}`);
        resultChangesArr.push(
          `${historyDescription.cpcData[item[0]]}: ${item[1]}`,
        );
      }
    });
    historyDataForLocalHistory.push({
      key: `Алгоритм оптимизации`,
      value,
    })
  };
  // функция расшифровки условия в зависимости от пришедших в запросе полей
  const describeJsonItemData = (item: [string, any]) => {
    if (historyDescription[item[0]]) {
      if (item[0] === 'container') {
        if (item[1].cid || item[1].cid === '') {
          historyDataForLocalHistory.push({
            key: historyDescription[item[0]].cid,
            value: item[1].cid === '' ? 'Очищено' : item[1].cid
          })
          resultStatusArr.push(historyDescription[item[0]].cid);
          resultChangesArr.push(item[1].cid === '' ? 'Очищено' : item[1].cid);
          return;
        }
        if (item[1].save_strk_to_cookie || item[1].save_strk_to_cookie === 0) {
          historyDataForLocalHistory.push({
            key: historyDescription[item[0]].save_strk_to_cookie,
            value: item[1].save_strk_to_cookie === 1 ? 'Да' : 'Нет'
          })
          resultStatusArr.push(historyDescription[item[0]].save_strk_to_cookie);
          resultChangesArr.push(
            item[1].save_strk_to_cookie === 1 ? 'Да' : 'Нет',
          );
          return;
        }
      }
      if (item[0] === 'targetings') {
        describeTargetings(item[1]);
        return;
      }
      if (item[0] === 'pretargeting' || item[0] === 'brand_safety') {
        describePretargetings(item[0], item[1], data.data);
        return;
      }
      if (item[0] === 'title') {
        historyDataForLocalHistory.push({
          key: historyDescription[item[0]][type],
          value: item[1]
        })
        resultStatusArr.push(historyDescription[item[0]][type]);
        resultChangesArr.push(item[1]);
        return;
      }
      if (item[0] === 'status') {
        historyDataForLocalHistory.push({
          key: '',
          value: historyDescription.statusData[type][item[1]]
        })
        resultStatusArr.push(historyDescription[item[0]][type]);
        resultChangesArr.push(historyDescription.statusData[type][item[1]]);
        return;
      }
      if (item[0] === 'tags') {
        const tagsArr: string[] = [];
        const tagsData = Object.entries(item[1]);

        resultStatusArr.push(historyDescription[item[0]][type]);

        tagsData.forEach((tag) => {
          fillTagsArr(tag as string[], resultChangesArr);
          fillTagsArr(tag as string[], tagsArr);
        });
        historyDataForLocalHistory.push({
          key: historyDescription[item[0]][type],
          value: tagsArr,
        });
        return;
      }
      if (item[0] === 'config_values') {
        if (item[1]) {
          const objKeys = Object.keys(item[1])
          objKeys.forEach(key => {
            resultStatusArr.push(key);
            let value = item[1][key]
            if(value === 'true') {
              value = 'Включено'
            }
            if(value === 'false') {
              value = 'Выключено'
            }
            resultChangesArr.push(`${value === '' ? 'Поле очищено' : value}`)
            historyDataForLocalHistory.push({
              key,
              value: `${value === '' ? 'Поле очищено' : value}`
            })
          })
        }
        return;
      }
      // Костыль для вывода полей show и show2 вне объекта data.data(Данные креатива)
      if(item[0] === 'data' && item[1].audit) {
        Object.entries(item[1].audit).forEach(([key, value]) => {
          let status;
          let changes;

          if(key === 'show') {
            status = 'URL трекера показа';
            resultStatusArr.push('URL трекера показа');
          }
          if(key === 'show2') {
            status = 'Доп. URL трекера показа';
            resultStatusArr.push('Доп. URL трекера показа');
          }
          if(typeof value === 'string') {
            const currentVal = value === '' ? 'Поле очищено' : value
            changes = currentVal;
            resultChangesArr.push(currentVal);
          }

          historyDataForLocalHistory.push({
            key: status || '',
            value: changes || ''
          })
        })
        return
      }

      resultStatusArr.push(historyDescription[item[0]]);

      if (item[0] === 'method_recalculating_statistics') {
        historyDataForLocalHistory.push({
          key: historyDescription[item[0]],
          value: historyDescription[item[1]]
        })
        resultChangesArr.push(historyDescription[item[1]]);
        return;
      }
      if (item[0] === 'limits') {
        resultChangesArr.push(
          `${transformeLimitsRowData(
            item[1],
            'click',
            i18n.t(`campaigns_page.campaign_settings.Clicks`),
          )}${transformeLimitsRowData(
            item[1],
            'click_per_user',
            'Клики (пользователь)',
          )}${transformeLimitsRowData(
            item[1],
            'show',
            'Показы',
          )}${transformeLimitsRowData(
            item[1],
            'show_per_user',
            i18n.t(`campaigns_page.campaign_settings.per_user`),
          )}${transformeLimitsRowData(
            item[1],
            'budget_raw',
            i18n.t(`campaigns_page.campaign_settings.Budget_RAW`),
          )}${transformeLimitsRowData(
            item[1],
            'budget',
            i18n.t(`campaigns_page.campaign_settings.Budget`),
          )}${transformeLimitsRowData(
            item[1],
            'budget_per_user',
            'Бюджет (пользователь)',
          )}${transformeLimitsRowData(item[1], 'use_adriver_frequency_cap')}`,
        );
        return;
      }
      if (item[0] === 'date_start' || item[0] === 'date_end') {
        if (item[1].date) {
          historyDataForLocalHistory.push({
            key: item[0] === 'date_start' ? 'Дата начала кампании' : 'Дата окончания кампании',
            value: format(new Date(item[1].date), 'dd.MM.yyyy')
          })
          resultChangesArr.push(format(new Date(item[1].date), 'yyyy.MM.dd'));
        }
        return;
      }
      if (item[0] === 'cpa_optimizations') {
        historyDataForLocalHistory.push({
          key: historyDescription[item[0]],
          value: item[1]
            ? 'Включена'
            : 'Выключена',
        });
        resultChangesArr.push(
          item[1]
            ? 'Включена'
            : 'Выключена',
        );
        return;
      }
      if (item[0] === 'check_ERID') {
        historyDataForLocalHistory.push({
          key: historyDescription[item[0]],
          value: item[1] ? 'Включена' : 'Выключена'
        })
        resultChangesArr.push(
          item[1]
            ? 'Включена'
            : 'Выключена',
        );
        return;
      }
      if (item[0] === 'reports') {
        describeReports(item[1]);
        return;
      }
      if (item[0] === 'categories') {
        historyDataForLocalHistory.push({
          key: '',
          value: categoriesDescription(item[1]),
        })
        categoriesDescription(item[1]);
        return;
      }
      if (item[0] === 'language') {
        historyDataForLocalHistory.push({
          key: historyDescription[item[0]],
          value: languageDescription[item[1]]
        })
        resultChangesArr.push(languageDescription[item[1]]);
        return;
      }
      if (item[0] === 'data') {
        creativeDataDescription(item[1]);
        return;
      }
      if (item[0] === 'algorithm_cpc') {
        if (item[1]) {
          algorithmCpcDescription(item[1]);
        }
        return;
      }
      if (item[1] === '' || item[1] === null) {
        historyDataForLocalHistory.push({
          key: historyDescription[item[0]],
          value: 'Поле очищено'
        })
        resultChangesArr.push('Поле очищено');
        return;
      }
      historyDataForLocalHistory.push({
        key: historyDescription[item[0]],
        value: `${String(item[1])}`
      })
      resultChangesArr.push(String(item[1]));
      return;
    }

    if(action === 'EDIT' && item[0] === 'commission_agency'){
      historyDataForLocalHistory.push({
        key: 'Комиссия агентства %',
        value: ` ${item[1]}`
      })
      resultChangesArr.push(` ${item[1]}`);
      resultStatusArr.push('Комиссия агентства %')
      return;
    }

    if(action === 'EDIT' && item[0] === 'commission_self_service'){
      historyDataForLocalHistory.push({
        key: 'Комиссия Self-Service %',
        value: ` ${item[1]}`
      })
      resultChangesArr.push(` ${item[1]}`)
      resultStatusArr.push('Комиссия Self-Service %')
      return;
    }

    resultStatusArr.push('EDIT');
  };

  // преобразуем полученный объект в массив из массивов [ключ, значение]
  const describeJsonData = (jsonData: IHistoryData) => {
    const itemData = Object.entries(jsonData);
    itemData.forEach((item) => describeJsonItemData(item));
  };

  function transformStatus(): string[] {
    if (action === 'ADD' || action === 'CLONE' || action === 'TARGET_CLONE') {
      historyDataForLocalHistory.push({
        key: historyDescription.status[type],
        value: historyDescription[action][type]
      })
      resultStatusArr.push(historyDescription.status[type]);
      resultChangesArr.push(historyDescription[action][type]);
    } else {
      if(action === 'MASS_EDIT') {
        historyDataForLocalHistory.push({
          key: '',
          value: historyDescription[action][type]
        })
        resultStatusArr.push(historyDescription[action][type]);
        resultChangesArr.push(' ');
      }
      describeJsonData(data.data);
    }

    const transformedParametrs =
      resultStatusArr.length > 1
        ? resultStatusArr.join('\n')
        : resultStatusArr[0];
    const transformedChanges =
      resultChangesArr.length > 1
        ? window.location.pathname.includes('/control_panel/history_changes')
          ? resultChangesArr.join('\n')
          : resultChangesArr.join('\n')
        : resultChangesArr[0];

    return [transformedParametrs, transformedChanges];
  }

  const [transformedParametrs, transformedChanges] = transformStatus();

  return {
    transformedDate,
    transformedParametrs,
    transformedChanges,
    historyDataForLocalHistory,
  };
};

const formatObjectsArray = (arr: ILocalHistoryData[]) => {
  const formatValue = (value: any): string => {
    if (Array.isArray(value)) {
      return value
        .map((obj) => {
          if (typeof obj === 'string') {
            return obj;
          }
          if (typeof obj.value === 'object') {
            return `${obj.key}: ${formatValue(obj.value)}`;
          }
          if (Array.isArray(obj.value)) {
            return `${obj.key}: ${obj.value[0]}`;
          }
          return `${obj.key}: ${obj.value}`;
        })
        .join('\n');
    }
    if (typeof value === 'object') {
      return Object.entries(value)
        .map(([key, val]) => `${key}: ${formatValue(val)}`)
        .join('\n');
    }
    if (typeof value === 'string') {
      const updatedValue =
        value.replace(/,\n/g, '\n');
      return updatedValue.endsWith(',') ? updatedValue.slice(0, -1) : updatedValue; 
    }
    return value;
  };

  return arr
    .map((obj: ILocalHistoryData) => {
      if (obj.key === '' && obj.value !== '') {
        return formatValue(obj.value);
      }
      if (obj.value === '' && obj.key !== '') {
        return obj.key;
      }
      if (Array.isArray(obj.value)) {
        return `${obj.key}: \n${formatValue(obj.value)}`;
      }
      return obj.key.includes('Внешний ID') ||
        obj.key.includes('Дополнительная метрика') ||
        obj.key.includes('Коэффициенты')
        ? `${obj.key}: \n${obj.value}`
        : `${obj.key}: ${obj.value}`;
    })
    .join('\n');
};

// Создаем объект с изменениями на каждый объект истории
export const changesArray = (
  currCampHistory: THistoryResponse[],
): IChanges<string>[] =>
  currCampHistory?.map((camp: THistoryResponse) => {
    const { transformedParametrs, transformedChanges, historyDataForLocalHistory } =
      useTransformHistoryData(camp);

    const stringifiedText = formatObjectsArray(historyDataForLocalHistory);
    
    const isLikeGlobalText = `${transformedParametrs}:\n${transformedChanges}`;

    return {
      isCreative: camp.creative ? camp.creative.internal_id : null,
      changes:
      transformedParametrs && (transformedParametrs.includes('Лимиты') ||
        transformedParametrs.includes('Таргетинг'))
          ? isLikeGlobalText
          : stringifiedText,
    };
  });
// Добавляем объект с изменениями к каждому объекту истории
export const campaignHistoryWithTextData = (
  currentCampaign: THistoryResponse[],
  changArr: IChanges<string>[],
): IUpdatedHistory<string>[] | null => {
  if (currentCampaign.length !== changArr.length) {
    return null;
  }
  const updatedCampaigns: IUpdatedHistory<string>[] = currentCampaign.map(
    (camp, index) => {
      const isCreativeLocation = window.location.pathname.includes('creatives');
      const isCreative = camp.item_type === 'CREATIVE' && !isCreativeLocation;
      const changes = changArr[index];
      const text = `${isCreative ? `[${camp.creative?.internal_id}]\n` : ''}${changes.changes}`;
      return {
        ...camp,
        datetime: {
          ...camp.datetime,
          date: camp.datetime.date.replace('.000000', ''),
        },
        text,
      };
    },
  );
  return updatedCampaigns;
};
