import { FormikProps, useFormik } from 'formik';
import deepEqual from 'deep-equal';
import { useTranslation } from 'react-i18next';
import { checkResize } from 'domains/uploads/helpers';
import { useUserTheme } from 'domains/user/model/selectors';
import { TCreativePush } from '../../../../../types';
import { setEditCreativeRequest } from '../../../../../reduser';
import { setFormDefault } from '../../../../../../uploads/reducer';
import { useDispatchApp } from '../../../../../../../redux/rootSelectors';

type Props = {
  data: TCreativePush;
  xxhash: string;
  setEdit: (param: boolean) => void;
};

type TFUseEditPush = (p: Props) => {
  formik: FormikProps<TCreativePush>;
  handlerFormikReset: () => void;
  handlerFormikSubmit: () => void;
};

export const useEditPush: TFUseEditPush = ({
  xxhash,
  data: { iconMask, imgMask, imgFormats, iconFormats, text, title },
  setEdit,
}) => {
  const dispatch = useDispatchApp();
  const { t } = useTranslation();
  const { getValueFromSettingForKey } = useUserTheme();
  const formik = useFormik<TCreativePush>({
    initialValues: {
      text,
      imgMask,
      imgFormats,
      iconFormats,
      title,
      iconMask,
    },
    onSubmit: (values) => {
      if (
        !deepEqual(
          {
            text,
            imgMask,
            imgFormats,
            iconFormats,
            title,
            iconMask,
          },
          values,
        )
      ) {
        dispatch(
          setEditCreativeRequest({
            data: {
              xxhash,
              data: { ...values, imgFormats: checkResize('push') },
            },
            visual: {
              setter: setEdit,
              translation:
                getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
                !getValueFromSettingForKey('ShowBroadcastStatus'),
              value: false,
              alertText: t('creative.crea_type.push'),
            },
          }),
        );
      } else {
        dispatch(setFormDefault(true));
        setEdit(false);
      }
    },
    enableReinitialize: true,
  });
  /** Функция сбрасывает форму */
  const handlerFormikReset = () => {
    dispatch(setFormDefault(true));
    formik.resetForm();
    setEdit(false);
  };

  /** Функция отправляет форму */
  const handlerFormikSubmit = () => {
    formik.handleSubmit();
  };

  return { formik, handlerFormikReset, handlerFormikSubmit };
};
