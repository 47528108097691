import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from '../../redux/fetchedData';
import { IPartnersList, TPartnersBalance } from './types';
import { TBalance } from '../campaigns/types';

export interface PartnersState {
  tempPartners: FetchedData<IPartnersList>;
  balances: FetchedData<TPartnersBalance>;
  balance: FetchedData<TBalance>;
}
const initialState: PartnersState = {
  tempPartners: genFetchedData<IPartnersList>(null),
  balances: genFetchedData<TPartnersBalance>(null),
  balance: genFetchedData<TBalance>(null),
};

const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    setTempPartners(
      state,
      action: PayloadAction<PartnersState['tempPartners']>,
    ) {
      state.tempPartners = action.payload;
    },
    setPartnersBalance(
      state,
      action: PayloadAction<PartnersState['balances']>,
    ) {
      state.balances = action.payload;
    },
    setPartnerBalance(state, action: PayloadAction<PartnersState['balance']>) {
      state.balance = action.payload;
    },
  },
});

export const { setTempPartners, setPartnersBalance, setPartnerBalance } =
  partnersSlice.actions;
export const partnersReducer = partnersSlice.reducer;
