import { useProjectInfo } from 'domains/project/hooks';
import { editProjectRequest } from 'domains/project/reducer';
import { RProject } from 'domains/project/types';
import { useUserInfo } from 'domains/user/model/selectors';
import { FormikErrors, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/rootReducer';
import { TFormikExtract } from 'types/general';
import { objectOptimizationWithFormik } from 'utils/objectOptimizationWithFormik/objectOptimizationWithFormik';

type TProjectForm = {
  code: RProject['code'];
  title: RProject['title'];
  ext_domain: RProject['ext_domain'];
  currency: RProject['currency'];
  partner: string;
  ord_registration: RProject['ord_registration']
};

type TUseProjectSettings = TFormikExtract<TProjectForm> & {
  edit: boolean;
  setEdit: (p: boolean) => void;
  handleFormikSubmit: () => void;
  handleFormikReset: () => void;
};

export const useProjectSettings = (): TUseProjectSettings => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);

  const { data: user, LTU: userLTU } = useUserInfo();
  const { data: project, LTU: projectLTU } = useProjectInfo();

  const formik = useFormik<TProjectForm>({
    enableReinitialize: true,
    initialValues: {
      code: '',
      title: '',
      ext_domain: '',
      currency: 'RUB',
      partner: '',
      ord_registration: false,
    },
    onSubmit: (values) => {
      if (project && user) {
        const param = objectOptimizationWithFormik<TProjectForm>(
          {
            code: project.code,
            title: project.title,
            ext_domain: project.ext_domain,
            currency: project.currency,
            partner: user.partner.title,
            ord_registration: project.ord_registration
          },
          values,
        );
        if (param) {
          dispatch(
            editProjectRequest({
              data: { domain: window.location.host, ...param },
              value: false,
              setter: setEdit,
              alertText: t('project_page.forms.project_title'),
            }),
          );
        } else {
          setEdit(false);
        }
      }
    },
    validate: (values: TProjectForm) => {
      const errors: FormikErrors<TProjectForm> = {};

      if (values.title?.length === 0) {
        errors.title = t('errors.err45');
      }

      return errors;
    },
  });

  useEffect(() => {
    if (project && user) {
      formik.setValues({
        code: project.code,
        title: project.title,
        currency: project.currency,
        ext_domain: project.ext_domain,
        partner: user.partner.title,
        ord_registration: project.ord_registration
      });
    }
  }, [userLTU, projectLTU]);

  /** функция отправки формы */
  const handleFormikSubmit = () => {
    formik.handleSubmit();
  };

  /** функция сброса формы */
  const handleFormikReset = () => {
    if (project && user) {
      formik.setValues({
        code: project.code,
        title: project.title,
        currency: project.currency,
        ext_domain: project.ext_domain,
        partner: user.partner.title,
        ord_registration: project.ord_registration
      });
      setEdit(false);
    }
  };

  return {
    edit,
    setEdit,
    handleFormikSubmit,
    handleFormikReset,
    formikContext: formik,
    ...formik,
  };
};
