import Card from 'components/UI/Card';
import React, { FC } from 'react';
import InputAutoSave from 'components/UI/InputAutoSave';
import LabelField from 'components/UI/LabelField';
import MultiSelectCheckbox from 'components/UI/MultiSelectCheckbox';
import { TFormikProps } from 'domains/offer/model/types';
import { FormikProps } from 'formik';
import { useClientsAllInfo } from 'domains/clients/model/selectors';
import { useTranslation } from 'react-i18next';
import css from '../styles.module.scss';

interface Props {
  formik: FormikProps<TFormikProps>;
}

const MainSettings: FC<Props> = ({ formik }) => {
  const { t } = useTranslation();
  const { data: clientsList } = useClientsAllInfo();

  const offerOptions =
    clientsList?.map((client) => ({
      label: client.title,
      value: client.id,
    })) || null;

  return (
    <Card
      noHover
      style={{ padding: '28px 24px' }}
      isUseAccess
      className={css.card}
    >
      <h3 className={css.title}>{t(`clients_page.nav_menu.basic_settings`)}</h3>
      <div className={css.form}>
        <InputAutoSave
          label={`${t(`clients_page.client_name`)} *`}
          value={formik.values.title}
          name="title"
          placeholder={t(`offers_page.enter_name`)}
          setFieldValue={formik.setFieldValue}
          isError={!!(formik.touched.title && formik.errors.title)}
          errorText={formik.errors.title}
        />
        <LabelField label={`${t(`clients_page.clients_tit`)} *`}>
          <MultiSelectCheckbox
            fieldName="clients"
            formik={formik}
            propsValues={offerOptions}
            placeholder={t(`offers_page.select_cliient`)}
            isError={!!(formik.touched.clients && formik.errors.clients)}
            errorText={
              !Array.isArray(formik.errors.clients) ? formik.errors.clients : ''
            }
          />
        </LabelField>
      </div>
    </Card>
  );
};

export default MainSettings;
