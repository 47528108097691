import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Helmet } from 'react-helmet';
import { Form, FormikProvider } from 'formik';
import Loader from 'components/UI/Loader';
import { FiCheckCircle, FiAlertCircle } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import Button from 'components/UI/Buttons/buttons';
import Input from 'components/UI/Input/input';
import Card from 'components/UI/Card';
import { ReactComponent as ShowPassSvg } from '../../../assets/show_pass.svg';
import { ReactComponent as HidePassSvg } from '../../../assets/hide_pass.svg';
import css from '../styles.module.scss';
import { useSignInDetail } from '../hooks/useSignInDetail';
import LogoType from '../LogoType';

const SingIn: FC = () => {
  const { t } = useTranslation();

  const {
    isRegistration,
    isLoading,
    notificationTitle,
    confirm,
    error,
    errors,
    touched,
    submitCount,
    values,
    handleChange,
    handleBlur,
    formikContext,
    authLoading,
    openPass,
    handleToggleOpenPass,
  } = useSignInDetail();

  return (
    <>
      <Helmet>
        <title>{t('form.common.authorization')}</title>
      </Helmet>
      {isRegistration && (
        <div className={css.changeform}>
          {t('form.common.new_user')}{' '}
          <Link to="/auth/sign-up">{t('form.common.register')}</Link>
        </div>
      )}

      {isLoading && (
        <div className={css.loading}>
          <Loader />
        </div>
      )}
      <div className={css.content}>
        <Card noHover className={css.card}>
          <FormikProvider value={formikContext}>
            <Form className={css.form}>
              <LogoType />
              <h1 className={css.title}>{t('form.common.signin')}</h1>
              {confirm && (
                <div className={css.notifications}>
                  <div>
                    <FiCheckCircle size={24} />
                    <span>{notificationTitle}</span>
                  </div>
                </div>
              )}

              {!!error && (
                <div className={css.error}>
                  <FiAlertCircle size={20} />
                  <span>{error}</span>
                </div>
              )}
              <Input
                label={t('form.common.email')}
                error={!!submitCount && !!touched.email && !!errors.email}
                errortext={errors.email}
                inputAttributes={{
                  name: 'email',
                  placeholder: 'admin@gmail.com',
                  value: values.email,
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
              />

              <div className={css.forgotPass}>
                <Input
                  label={t('form.common.password')}
                  icon={
                    <div onClick={handleToggleOpenPass}>
                      {values.password &&
                        (openPass ? <HidePassSvg /> : <ShowPassSvg />)}
                    </div>
                  }
                  icon_classname={css.toggle_pass}
                  error={
                    !!submitCount && !!touched.password && !!errors.password
                  }
                  errortext={errors.password}
                  inputAttributes={{
                    name: 'password',
                    type: openPass ? 'text' : 'password',
                    value: values.password,
                    onChange: handleChange,
                    onBlur: handleBlur,
                    placeholder: t('form.common.password'),
                  }}
                />
                <Link to="/auth/forgot-password">
                  {t('login_page.forgotPass')}
                </Link>
              </div>
              <Button
                title={t('form.common.signin')}
                buttonAttributes={{
                  type: 'submit',
                  style: {
                    fontWeight: 700,
                    marginTop: 16,
                  },
                  disabled: authLoading,
                }}
              />
              {isRegistration && (
                <div className={cn(css.changeform, 'nodesktop')}>
                  {t('form.common.new_user')}{' '}
                  <Link to="/auth/sign-up">{t('form.common.register')}</Link>
                </div>
              )}
            </Form>
          </FormikProvider>
        </Card>
      </div>
    </>
  );
};

export default SingIn;
