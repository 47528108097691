import { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CampaignsState,
  clearCampaignsIcon,
  fetchCampaignsIcons,
  setCampaignsIcon,
} from '../../../reducer';
import { AppState } from '../../../../../redux/rootReducer';
import { useDispatchApp } from '../../../../../redux/rootSelectors';
import { getLastIcons } from '../../../localStorage/icons';

interface IIseIconsModal {
  positionRight: number;
  positionTop: number;
  campaignsIcons: string[];
  onClickIcons: (icon: string) => () => void;
  lastIcons: string[];
  onClearIcon(): void;
}

interface IUseIconsModalProps {
  hintRef: React.MutableRefObject<HTMLDivElement | null>;
  imgRef: React.MutableRefObject<HTMLDivElement | null>;
  closeModal: () => void;
  open: boolean;
  xxhash: string;
}

export const useIconsModal = ({
  hintRef,
  imgRef,
  closeModal,
  open,
  xxhash,
}: IUseIconsModalProps): IIseIconsModal => {
  const [positionRight, setpositionRight] = useState<number>(0);
  const [positionTop, setPositionTop] = useState<number>(0);

  const dispatch = useDispatchApp();
  useEffect(() => {
    dispatch(fetchCampaignsIcons());
  }, []);

  const { campaignsIcons } = useSelector<AppState, CampaignsState>(
    (data) => data.campaignsReducer,
  );

  const calcPosition = () => {
    if (imgRef && imgRef.current && hintRef && hintRef.current) {
      setpositionRight(imgRef.current?.getBoundingClientRect().right);
      setPositionTop(imgRef.current?.getBoundingClientRect().top);
    }
  };

  useEffect(() => {
    calcPosition();
  }, []);

  function handleClickOutside(event: MouseEvent) {
    const { target } = event;
    if (!(target instanceof Element)) return;
    if (
      hintRef?.current &&
      !hintRef.current.contains(target) &&
      imgRef?.current &&
      !imgRef.current.contains(target)
    ) {
      closeModal();
    }
  }

  useLayoutEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [hintRef, open]);

  const onClickIcons = (icon: string) => () => {
    dispatch(setCampaignsIcon({ icon, xxhash }));
    closeModal();
  };

  const onClearIcon = () => {
    dispatch(clearCampaignsIcon({ xxhash }));
    closeModal();
  };
  const lastIcons = getLastIcons();
  return {
    positionRight,
    positionTop,
    campaignsIcons,
    onClickIcons,
    lastIcons,
    onClearIcon,
  };
};
