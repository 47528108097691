import React, { FC, SyntheticEvent } from 'react';
import Checkbox from 'components/UI/Checkbox/checkbox';
import { TGetListDataItem } from 'domains/blackList/types';
import moment from 'moment';
import css from '../styles.module.scss';
// eslint-disable-next-line import/no-cycle
import { ICheck } from '..';

interface IItemProps {
  data: TGetListDataItem;
  checked: ICheck | null;
  handleCheckboxChange: (
    id: number,
  ) => (event: SyntheticEvent<HTMLInputElement>) => void;
  deleted?: boolean;
  type: string | null;
}

const Item: FC<IItemProps> = ({
  data,
  checked,
  handleCheckboxChange,
  deleted,
  type,
}) => (
  <div className={css.item}>
    <Checkbox
      label=""
      onlyBox
      className={css.checkbox}
      inputAttributes={{
        type: 'checkbox',
        checked: (checked && checked[data.id]) || false,
        onChange: handleCheckboxChange(data.id),
      }}
    />
    <div className={css.source}>{data.source}</div>
    <div className={css.publisher}>{data.publisher}</div>
    <div className={css.domain}>{data.domain}</div>
    <div className={css.date}>
      {deleted
        ? moment(data.date_delete).format('DD.MM.YY')
        : moment(data.date_create).format('DD.MM.YY')}
      {deleted ? (
        <span>{moment(data.date_delete).format('HH:mm')}</span>
      ) : (
        <span>{moment(data.date_create).format('HH:mm')}</span>
      )}
    </div>
    {type === 'client' && (
      <div className={css.partner}>{data.partner_prefix}</div>
    )}
    {deleted && <div className={css.deleted}>{data.email}</div>}
  </div>
);

export default Item;
