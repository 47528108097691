import {
  call,
  fork,
  put,
  select,
  take,
  all,
  SagaReturnType,
  delay,
} from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import moment from 'moment';
import i18n from 'i18next';
import {
  ResponseTranslation,
  RStatisticsCampaigns,
  ResponseStatisticsCampaigns,
} from 'domains/campaigns/types';
import { fetchStatisticsApi, getTotalListStat } from 'domains/campaigns/api';
import { selectorPartnerStatisticFieldList } from 'domains/campaigns/model/selectors';
import { setStatisticFieldList } from 'domains/campaigns/reducer';
import { selectorBreadcrumbs } from 'domains/search/model/selectors';
import { RBreadCrumbs } from 'domains/search/model/types';
import { setTempBreadcrumbs } from 'domains/search/model/reducer';
import {
  getSingleTargetUserSettingsAPI,
  removeUserSettingAPI,
  setTargeting,
} from 'domains/target/api';
import { fetchPDataByTarget } from 'domains/campaign/model/actions';
import {
  addCreativeRequest,
  cleanCreativeTargeting,
  creativeChangeSetStatus,
  creativeCloneRequest,
  fetchCreativeDetail,
  fetchCreativesList,
  fetchCreativeStatisticsList,
  fetchStatusModeration,
  fetchCreativesTranslation,
  sentModerationRequest,
  setEditCreativeRequest,
  setExternalIdRequest,
  setTargetingsRequest,
  setTempCreativeDetail,
  setTempCreativeList,
  setTempModeration,
  setTempModerationResult,
  setTempStatisticsList,
  setTempStatisticsSumList,
  setResponseResult,
  resetFillCreativeList,
  setTempAlgorithms,
  fetchCreativeAlgorithmsList,
  setListSort,
  setIsShowListFilter,
  fetchCreativeTagsRequest,
  setCreativeTags,
  fetchCreativeAllTagsRequest,
  setCreativeAllTags,
  addActiveTagRequest,
  setActiveTags,
  setTempStatisticsListSuccess,
  setTempCreativeListSuccess,
  setListView,
  setListModal,
  setVendorLinks,
  setLastId,
  setCreativePretargeting,
  setCreativesTranslation,
  setCreativeTranslation,
  fetchCreativeTranslation,
  setCreoTranslationFilter,
  setFetchCreativeTypes,
  fetchCreativesTotal,
  setCreativeStatus,
  setCurrentGridEridInfo,
} from '../reduser';
import {
  extractGenFetchData,
  FetchedData,
  genFetchedData,
} from '../../../redux/fetchedData';
import {
  addCreative,
  cleanTargetCreative,
  cloneCreative,
  creativeSetStatusApi,
  getCreativeDetail,
  getCreativeList,
  sendExternalModeration,
  setCreativeEdit,
  setExternalId,
  statusModeration,
  unsetCreativeTargeting,
  getCreativeAlgorithms,
  getCreativeTags,
  setCreativeTagsAPI,
  getAllCreativeTags,
  getInternalList,
  setAllStatuses,
  setGroupStatuses,
  clearTargetToList,
  clearTargetAllCreativesByCampaign,
  getCreativePretargeting,
  setCreativePretargetingApi,
  removeCreativePretargetingApi,
  fetchCreativesTranslationApi,
  fetchCreativeTranslationApi,
  getAllCreativeTypesApi,
  createScreenAPI,
  getScreenStatusAPI,
  massEditLinkAPI,
  getCustomerByEridAPI,
} from '../api';
import updateToken from '../../../utils/updateToken';
import {
  makeReqWithRD,
  TMakeReqWithRD,
  updateFillFetchedData,
} from '../../../redux/makeReqWithRD';
import {
  CreativeDetail,
  CreativeItemListWithStatistics,
  CreativeList,
  ModerationResultData,
  PGetCreative,
  RModerationResult,
  TPCreativeEdit,
  TRCreativeTypes,
} from '../types';
import {
  RResultSuccess,
  TInternalItem,
  TInternalList,
} from '../../../types/general';
import { setFormDefault } from '../../uploads/reducer';
import {
  changeAllCreativeStatuses,
  changeGroupCreativesStatuses,
  clearGroupCreativesTargeting,
  createScreenAction,
  fetchCreativeListModal,
  fetchCreativePretargeting,
  fetchGridEridInfoAction,
  getScreenStatus,
  loadCreativeList,
  massEditLinkAction,
  removeCreativePretargeting,
  revertCreativeTags,
  searchCreativeList,
  setCreativePretargetingAction,
  setCreoExperimentalAction,
  updateCreativeStatistics,
  updateTagCreative,
} from '../model/actions';
import {
  selectorCreativeLastID,
  selectorCreativeList,
  selectorCreativeListView,
  selectorCreativePretargeting,
  selectorCreativeTags,
  selectorCreativeTranslation,
  selectorTranslationFilter,
  selectorCreativeTypes,
  selectorCreativeDetail,
} from '../model/selectors';
import {
  extractMakeGenList,
  makeGenList,
  TMakeGenList,
} from '../../../redux/makeGenList';
import { selectorCampaign } from '../../campaign/model/selectors';
import { setPersonalDataSuccess } from '../../campaign/reduser';
import { RGetPersonal, TStatisticsFields, Ttarget } from '../../campaign/types';
import {
  selectorSetTargetingRequest,
  selectorTargetUserSettings,
} from '../../target/model/selectors';
import {
  setTargetUserSettingsData,
  setTargetingRequest,
} from '../../target/reduser';
import { RGetStatisticTarget, TargetKey } from '../../target/model/types';
import { toastSuccess } from '../../../utils/ToastUtils/toastUtils';

export function* updateCreativeStatisticsWorker({
  payload,
}: ReturnType<typeof updateCreativeStatistics>): SagaIterator<void> {
  if (payload) {
    yield all([take(setTempStatisticsListSuccess)]);
  }
  try {
    const { list: tempList } = yield select(
      ({ creativeReducer }) => creativeReducer,
    );
    const {
      statisticsList: tempStatisticsList,
    }: { statisticsList: FetchedData<RStatisticsCampaigns> } = yield select(
      ({ creativeReducer }) => creativeReducer,
    );
    const list: CreativeItemListWithStatistics[] = tempList.get('data');
    const statisticsList: RStatisticsCampaigns | null =
      tempStatisticsList.get('data');
    if (statisticsList) {
      let keys: string[] = [];
      if (Array.isArray(statisticsList)) {
        const fieldsData: FetchedData<TStatisticsFields> = yield select(
          selectorPartnerStatisticFieldList,
        );
        let fields = fieldsData.get('data');
        if (!fields || fields.length === 0) {
          yield take(setStatisticFieldList);
          const fieldsDataRe: FetchedData<TStatisticsFields> = yield select(
            selectorPartnerStatisticFieldList,
          );
          fields = fieldsDataRe.get('data');
        }
        if (fields) {
          const emptyStatObj: ResponseStatisticsCampaigns =
            {} as ResponseStatisticsCampaigns;
          keys = fields.map((field) => field.key);
          keys.forEach((key) => {
            emptyStatObj[key] = 0;
          });
        }
      } else {
        keys = Object.keys(statisticsList.total);
      }
      const newData = list.map((e: CreativeItemListWithStatistics) => {
        const obj = {};
        const stata = statisticsList[e.xxhash];
        if (stata) {
          keys.forEach((k) => {
            obj[k] = stata[k] || 0;
          });
        } else {
          keys.forEach((k) => {
            obj[k] = 0;
          });
        }
        return { ...e, statistics: { ...obj } };
      });
      yield put(setTempCreativeList(tempList.set('data', newData)));
      yield put(setIsShowListFilter(true));
    } else {
      yield put(setIsShowListFilter(false));
    }
  } catch (e) {
    console.log({ e });
  }
}

export function* fetchTotalListCreoStatWorker({
  payload,
}: ReturnType<typeof fetchCreativesTotal>): SagaIterator<void> {
  const { debounce, ...parameters } = payload;

  try {
    // debounce effect
    if (debounce) {
      yield delay(debounce);
    }
    yield call<TMakeReqWithRD<typeof getTotalListStat>>(makeReqWithRD, {
      fetcher: getTotalListStat,
      fill: setTempStatisticsSumList,
      parameters,
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* fetchCreativesListSagaWorker({
  payload,
}: ReturnType<typeof fetchCreativesList>): SagaIterator {
  try {
    const { campaign_xxhash, period, filters, typePeriod } = payload;

    const stateFilters: string[] = yield select(
      ({ creativeReducer }) => creativeReducer.activeTags,
    );
    let param: PGetCreative = { campaign_xxhash };
    if (filters) {
      if (filters && filters.status) {
        param = {
          ...param,
          filters: { status: filters.status, tags: stateFilters },
        };
      }
      if (filters && filters.tags?.length) {
        param = { ...param, filters: { ...param.filters, tags: filters.tags } };
      }
      if (filters && !filters.status && !filters.tags?.length) {
        param = { ...param, filters: { tags: stateFilters } };
      }
    } else {
      param = { ...param, filters: { tags: stateFilters } };
    }

    yield call<TMakeReqWithRD<typeof getCreativeList>>(makeReqWithRD, {
      fetcher: getCreativeList,
      fill: (v) => {
        const data = v.get('data');
        const newData: CreativeItemListWithStatistics[] = data
          ? data.map((e) => ({
              ...e,
              statistics: {},
            }))
          : [];
        return setTempCreativeList(
          updateFillFetchedData<CreativeList, CreativeItemListWithStatistics[]>(
            {
              fillFetchedData: v,
              newData,
            },
          ),
        );
      },
      fillSuccess: (v) => {
        const data = v.get('data');
        const newData: CreativeItemListWithStatistics[] = data
          ? data.map((e) => ({ ...e, statistics: {} }))
          : [];
        return setTempCreativeListSuccess(
          updateFillFetchedData<CreativeList, CreativeItemListWithStatistics[]>(
            {
              fillFetchedData: v,
              newData,
            },
          ),
        );
      },
      parameters: param,
    });

    yield put(loadCreativeList({ isHard: true }));

    const state = yield select(({ creativeReducer }) => creativeReducer.list);
    const list = state.get('data');

    const ids = list ? list.map(({ xxhash }: CreativeDetail) => xxhash) : [];

    if (period) {
      if (typePeriod === 'all_time') {
        const { isLoading: isLoadingCampaign } =
          extractGenFetchData<RGetPersonal>(yield select(selectorCampaign));
        if (isLoadingCampaign) {
          yield take(setPersonalDataSuccess);
        }
        const { data: campaign } = extractGenFetchData<RGetPersonal>(
          yield select(selectorCampaign),
        );
        if (campaign) {
          // eslint-disable-next-line no-shadow
          const period = {
            from: moment(campaign.date_start.date).format('YYYY-MM-DD'),
            to: moment(campaign.date_end.date).format('YYYY-MM-DD'),
          };
          yield put(
            fetchCreativeStatisticsList({
              xxhash_list: ids,
              period,
            }),
          );
          yield put(
            fetchCreativesTotal({
              xxhash_list: ids,
              period,
            }),
          );
        }
      } else {
        yield put(fetchCreativeStatisticsList({ xxhash_list: ids, period }));
        yield put(fetchCreativesTotal({ xxhash_list: ids, period }));
      }
      yield take(setTempStatisticsListSuccess);
      yield put(updateCreativeStatistics(false));
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* fetchCreativesTranslationWorker({
  payload,
}: ReturnType<typeof fetchCreativesTranslation>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof fetchCreativesTranslationApi>>(
      makeReqWithRD,
      {
        fetcher: fetchCreativesTranslationApi,
        fill: setCreativesTranslation,
        parameters: { ...payload },
      },
    );
  } catch (e) {
    console.error({ e });
  }
}

export function* fetchCreativeTranslationWorker({
  payload,
}: ReturnType<typeof fetchCreativeTranslation>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof fetchCreativeTranslationApi>>(
      makeReqWithRD,
      {
        fetcher: fetchCreativeTranslationApi,
        fill: setCreativeTranslation,
        parameters: { ...payload },
      },
    );
  } catch (e) {
    console.error({ e });
  }
}

export function* fetchCreativeStatisticsListSagaWorker({
  payload,
}: ReturnType<typeof fetchCreativeStatisticsList>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof fetchStatisticsApi>>(makeReqWithRD, {
      fetcher: fetchStatisticsApi,
      fill: setTempStatisticsList,
      fillSuccess: setTempStatisticsListSuccess,
      parameters: payload,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* cleanCreativeAllTargetsSagaWorker({
  payload,
}: ReturnType<typeof cleanCreativeTargeting>): SagaIterator<void> {
  try {
    yield call(updateToken);
    const result = yield call(cleanTargetCreative, { xxhash: payload.xxhash });
    if (result) {
      if (payload.visual) {
        const { setter, alertText } = payload.visual;
        if (setter) setter('');
        toastSuccess(alertText);
      }
      if (payload.isUpdateList) {
        const oldList: TMakeGenList<CreativeItemListWithStatistics[]> =
          yield select(selectorCreativeListView);
        const dataOld = oldList.get('listView');
        const updateList = dataOld.map((item) =>
          item.xxhash === payload.xxhash
            ? { ...item, use_inherit_settings: true }
            : item,
        );
        const newData = oldList.set('listView', updateList);
        yield put(setListView(newData));
      }
      yield put(fetchPDataByTarget({ id: payload.xxhash }));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* changeCreativeStatusSagaWorker({
  payload,
}: ReturnType<typeof creativeChangeSetStatus>): SagaIterator<void> {
  try {
    yield call(updateToken);
    const { status, visual, xxhash } = payload;
    const result = yield call(creativeSetStatusApi, {
      xxhash,
      status,
    });
    if (result) {
      if (visual) {
        const { value, translation, setter, paramRender, alertText } = visual;
        if (setter) setter(!value);
        if (paramRender) {
          yield put(
            fetchCreativesList({
              campaign_xxhash: paramRender.campaign_xxhash,
              period: paramRender.period,
            }),
          );
        } else {
          const creativeAllTemp = yield select(selectorCreativeList);
          const creatives = creativeAllTemp.get(
            'data',
          ) as CreativeItemListWithStatistics[];
          yield put(
            setTempCreativeList(
              creativeAllTemp.set(
                'data',
                creatives.map((val) =>
                  val.xxhash === xxhash ? { ...val, status } : val,
                ),
              ),
            ),
          );
          if (translation) {
            const creativesTranslationTemp = yield select(
              selectorCreativeTranslation,
            );
            const creativesTranslationData = creativesTranslationTemp.get(
              'data',
            ) as Record<string, ResponseTranslation>;
            creativesTranslationData[xxhash] = yield call(
              fetchCreativeTranslationApi,
              { xxhash },
            );
            const data = creativesTranslationData;
            if (status === 'LAUNCHED' || status === 'STOPPED') {
              yield put(setCreativesTranslation(genFetchedData(data)));
            }
          }
        }
        if (alertText) {
          toastSuccess(alertText);
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
}

export function* cloneCreativeSagaWorker({
  payload,
}: ReturnType<typeof creativeCloneRequest>): SagaIterator<void> {
  const {
    campaign_xxhash_list,
    creative_xxhash_list,
    paramRerender: { period, campaign_xxhash: campaign_xxhashList },
    setResult,
    setResponse,
    callbackFinally,
    alertText,
    translation,
  } = payload;
  try {
    yield call(updateToken);
    const result = yield call(cloneCreative, {
      campaign_xxhash_list,
      creative_xxhash_list,
    });
    if (creative_xxhash_list.length === 1) {
      setResult('SUCCESS');
      setResponse(result);
    }
    if (alertText && creative_xxhash_list.length > 1) {
      toastSuccess(alertText);
    }
    yield put(
      fetchCreativesList({
        campaign_xxhash: campaign_xxhashList,
        period,
      }),
    );
    if (translation) {
      yield put(
        fetchCreativesTranslation({
          xxhash: campaign_xxhashList,
        }),
      );
    }
    if (result) {
      yield put(setLastId(result[0]?.id));
    }
  } catch (e) {
    setResponse(e);
    setResult('ERROR');
  } finally {
    if (callbackFinally) {
      callbackFinally();
    }
  }
}

export function* fetchCreativeDetailSagaWorker({
  payload,
}: ReturnType<typeof fetchCreativeDetail>): SagaIterator {
  try {
    yield call<TMakeReqWithRD<typeof getCreativeDetail>>(makeReqWithRD, {
      fetcher: getCreativeDetail,
      fill: setTempCreativeDetail,
      parameters: payload,
    });
    /* yield put(fetchCreativeTranslation({ xxhash: payload.xxhash })); */
    yield put(fetchCreativeTagsRequest({ xxhash: payload.xxhash }));
    yield put(
      fetchStatusModeration({
        creative_id: payload.xxhash,
      }),
    );
    yield put(fetchCreativeAlgorithmsList());
  } catch (e) {
    console.error(e);
  }
}

export function* fetchCreativeTagsWorker({
  payload,
}: ReturnType<typeof fetchCreativeTagsRequest>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getCreativeTags>>(makeReqWithRD, {
      fetcher: getCreativeTags,
      fill: setCreativeTags,
      parameters: payload,
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* setEditCreativeDetailSagaWorker({
  payload,
}: ReturnType<typeof setEditCreativeRequest>): SagaIterator<void> {
  let fetchedData = genFetchedData<RResultSuccess>(null).set('isLoading', true);
  yield put(setResponseResult(fetchedData));
  const { data, visual } = payload;
  const {
    erid,
    js_code_audit,
    iurl,
    click_audit_pixel,
    config_values: configValuesPayload,
    ...restData
  } = data;
  try {
    yield call(updateToken);

    if (visual.setIsLoading) {
      visual.setIsLoading(true);
    }

    const configInfo: FetchedData<TRCreativeTypes> = yield select(
      selectorCreativeTypes,
    );
    const configData = configInfo.get('data');
    const configObj =
      configData &&
      Object.values(configData).filter((item) => item.key === restData.type)[0];
    const configFields = configObj?.config_fields;
    const config_values: Record<string, string | boolean | number> =
      configValuesPayload || {};
    if (erid !== undefined && configFields) {
      const eridId = String(
        Object.values(configFields).filter((item) => item.field === 'erid')[0]
          .id,
      );
      config_values[eridId] = erid;
    }
    if (js_code_audit !== undefined && configFields) {
      const jsCodeAuditId = Object.values(configFields).filter(
        (item) => item.field === 'js_code_audit',
      )[0].id;
      config_values[jsCodeAuditId] = js_code_audit;
    }
    if (iurl !== undefined && configFields) {
      const iurlId = Object.values(configFields).filter(
        (item) => item.field === 'iurl',
      )[0].id;
      config_values[iurlId] = iurl;
    }
    if (click_audit_pixel !== undefined && configFields) {
      const clickAuditPixelId = Object.values(configFields).filter(
        (item) => item.field === 'click_audit_pixel',
      )[0].id;
      config_values[clickAuditPixelId] = click_audit_pixel;
    }
    const finalData = { ...restData, config_values: { ...config_values } };
    const result = yield call(setCreativeEdit, finalData);
    fetchedData = fetchedData.set('data', result);
    if (result) {
      if (restData.title) {
        const breadCrumbsMap: FetchedData<RBreadCrumbs> = yield select(
          selectorBreadcrumbs,
        );
        const breadCrumbsData = breadCrumbsMap.get('data');
        if (breadCrumbsData) {
          breadCrumbsData.Creative.title = restData.title;
          const newBreadCrumbsMap = breadCrumbsMap
            .set('data', breadCrumbsData)
            .set('LTU', Date.now());
          yield put(setTempBreadcrumbs(newBreadCrumbsMap));
        }
      }

      if (visual.setter) visual.setter(visual.value);
      if (visual.alertText) {
        toastSuccess({
          before: `${i18n.t('common.savedBlock_part1')}`,
          title: `"${visual.alertText}"`,
          after: `${i18n.t('common.savedBlock_part2')}`,
        });
      }

      const oldDataTemp: FetchedData<CreativeDetail> = yield select(
        selectorCreativeDetail,
      );
      const oldData = oldDataTemp.get('data');
      const oldPast = oldDataTemp.get('past');

      if (oldData) {
        oldPast.data.push(oldData);
        oldPast.LTU = Date.now();
      }

      const creo = yield call(getCreativeDetail, { xxhash: restData.xxhash });

      yield put(
        setTempCreativeDetail(
          oldDataTemp
            .set('data', creo)
            .set('past', oldPast)
            .set('LTU', Date.now())
            .set('error', { isError: false, message: '', code: 0 }),
        ),
      );

      if (visual.translation) {
        yield put(fetchCreativeTranslation({ xxhash: restData.xxhash }));
      }
      yield put(
        fetchStatusModeration({
          creative_id: restData.xxhash,
        }),
      );
    }
  } catch (e) {
    console.error({ e });
    const code = e?.code ?? 0;
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: e.message ?? '',
      code,
      fields: e.data.fields,
    });
  } finally {
    fetchedData = fetchedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setResponseResult(fetchedData));
    if (visual.setIsLoading) {
      visual.setIsLoading(false);
    }
  }
}

export function* fetchStatusModerationSaga({
  payload,
}: ReturnType<typeof fetchStatusModeration>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof statusModeration>>(makeReqWithRD, {
      fetcher: statusModeration,
      fill: setTempModeration,
      parameters: payload,
    });
  } catch (e) {
    console.log(e);
  }
}

export function* sentModerationSagaWorker({
  payload,
}: ReturnType<typeof sentModerationRequest>): SagaIterator<void> {
  try {
    const { type, creative_id } = payload;
    yield call<TMakeReqWithRD<typeof sendExternalModeration>>(makeReqWithRD, {
      fetcher: sendExternalModeration,
      fill: (res) => {
        const data = res.get('data');
        const newResult = updateFillFetchedData<
          RModerationResult,
          ModerationResultData
        >({
          fillFetchedData: res,
          newData: { data: data as RModerationResult, type },
        });
        return setTempModerationResult(newResult);
      },
      parameters: {
        creative_id: payload.creative_id,
        services: payload.services,
      },
    });
    yield put(fetchStatusModeration({ creative_id }));
  } catch (e) {
    console.error({ e });
  }
}

export function* addCreativeSagaWorker({
  payload,
}: ReturnType<typeof addCreativeRequest>): SagaIterator<void> {
  const { callback, data, tags } = payload;
  let fetchedData = genFetchedData<RResultSuccess>(null).set('isLoading', true);
  yield put(setResponseResult(fetchedData));
  try {
    yield call(updateToken);
    const { erid, js_code_audit, iurl, click_audit_pixel, ...restData } = data;
    const configInfo: FetchedData<TRCreativeTypes> = yield select(
      selectorCreativeTypes,
    );
    const configData = configInfo.get('data');
    const configObj =
      configData &&
      Object.values(configData).filter((item) => item.key === restData.type)[0];
    const configFields = configObj?.config_fields;
    const config_values: Record<string, string> = {};
    if (erid && configFields) {
      const eridId = String(
        Object.values(configFields).filter((item) => item.field === 'erid')[0]
          .id,
      );
      config_values[eridId] = erid;
    }
    if (js_code_audit && configFields) {
      const jsCodeAuditId = Object.values(configFields).filter(
        (item) => item.field === 'js_code_audit',
      )[0].id;
      config_values[jsCodeAuditId] = js_code_audit;
    }
    if (iurl && configFields) {
      const iurlId = Object.values(configFields).filter(
        (item) => item.field === 'iurl',
      )[0].id;
      config_values[iurlId] = iurl;
    }
    if (click_audit_pixel && configFields) {
      const clickAuditPixelId = Object.values(configFields).filter(
        (item) => item.field === 'click_audit_pixel',
      )[0].id;
      config_values[clickAuditPixelId] = click_audit_pixel;
    }
    const finalData = { ...restData, config_values: { ...config_values } };
    const result = yield call(addCreative, finalData);
    fetchedData = fetchedData.set('data', result);
    if (result) {
      if (tags.length) {
        yield call(setCreativeTagsAPI, { xxhash: result.id, tags });
      }
      toastSuccess(
        `${i18n.t('creative.create_creative.success.t1')}${i18n.t(
          'creative.create_creative.success.t2',
        )}`,
      );
      yield put(setFormDefault(true));
      callback(result.id);
    }
  } catch (e) {
    console.error({ e });
    const code = e?.code ?? 0;
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: e.message ?? '',
      code,
      fields: e.data.fields,
    });
  } finally {
    fetchedData = fetchedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setResponseResult(fetchedData));
  }
}

export function* setExternalIdWorker({
  payload,
}: ReturnType<typeof setExternalIdRequest>): SagaIterator<void> {
  try {
    yield call(updateToken);
    const result = yield call(setExternalId, {
      xxhash: payload.id,
      external_ids: payload.Ids.replace(/ /g, '\n'),
    });
    if (result !== true) {
      console.error('Error setExternalId');
    }
    yield fork(
      fetchCreativeDetailSagaWorker,
      fetchCreativeDetail({ xxhash: payload.id }),
    );
    if (payload.blockTitle) {
      toastSuccess({
        before: i18n.t('campaigns_page.campaign_settings.on_edit.hint1'),
        title: `"${payload.blockTitle}"`,
        after: i18n.t('campaigns_page.campaign_settings.on_edit.hint2'),
      });
    }
  } catch (e) {
    console.error({ e });
  }
}

// ТУТ
export function* setCreativeTargetingWorker({
  payload,
}: ReturnType<typeof setTargetingsRequest>): SagaIterator<void> {
  const {
    use_inherit_settings,
    blockTitle,
    deletedItemsArr,
    setEdit,
    setLoading,
    clearDeletedArr,
    ...rest
  } = payload;
  let receivedData = genFetchedData<RGetStatisticTarget>(null).set(
    'isLoading',
    true,
  );
  try {
    yield call(updateToken);

    const oldSavedSettings: FetchedData<Record<TargetKey, Ttarget>> =
      yield select(selectorTargetUserSettings);
    const oldSavedData = oldSavedSettings.get('data');

    const params = {
      xxhash: payload.xxhash,
      target_key: payload.target_key,
    };

    const promises = []

    const isChangeInheriSettings =
      oldSavedData?.[payload.target_key].settings.use_inherit_settings !==
      !use_inherit_settings;

    if (deletedItemsArr?.length) {
      const removeParams = {
        ...params,
        targeting_data: deletedItemsArr,
      };
      promises.push(yield call(removeUserSettingAPI, removeParams));
    }

    const isChangeInverMode =
      oldSavedData?.[payload.target_key].settings.is_invert_mode !==
      payload.targeting_data.is_invert_mode;

    const isChangeTargetItem =
      Object.keys(payload.targeting_data.items).length !== 0;

    if (isChangeInverMode || isChangeTargetItem || isChangeInheriSettings) {
      if (!use_inherit_settings) {
        const { xxhash, target_key } = payload;
        promises.push(call(unsetCreativeTargeting, { xxhash, target_key }));
      } else {
        promises.push(call(setTargeting, {
          ...rest,
          xxhash: payload.xxhash,
        }));
      }
    }

    const results: boolean[] = yield all(promises)

    const isSomeResultTrue = results.some(item => item)

    if (isSomeResultTrue) {
      const targetUserSettingsData: Ttarget = yield call(
        getSingleTargetUserSettingsAPI,
        params,
      );
      const savedSettings: FetchedData<Record<TargetKey, Ttarget>> =
        yield select(selectorTargetUserSettings);
      const savedData = savedSettings.get('data');
      if (savedData) {
        savedData[payload.target_key] = targetUserSettingsData;
      }
      const newData = savedSettings
        .set('data', savedData)
        .set('LTU', Date.now());
      yield put(setTargetUserSettingsData(newData));

      toastSuccess({
        before: `${i18n.t('common.savedBlock_part1')}`,
        title: `"${blockTitle}"`,
        after: `${i18n.t('common.savedBlock_part2')}`,
      });
      if(setEdit) {
        setEdit(false);
      }
    }
  } catch (e) {
    console.log({ e });
    const old = yield select(selectorSetTargetingRequest);
    receivedData = receivedData.set('error', {
      isError: true,
      message: e.message,
      code: e?.code,
      description: e?.data.description,
      mnemonic: e?.data.mnemonic,
    });
    yield put(
      setTargetingRequest({ ...old, [payload.target_key]: receivedData }),
    );
  } finally {
    if (deletedItemsArr && clearDeletedArr) {
      clearDeletedArr();
    }
    const old = yield select(selectorSetTargetingRequest);
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    yield put(
      setTargetingRequest({ ...old, [payload.target_key]: receivedData }),
    );
    setLoading(false);
  }
}

export function* resetCreativeListWorker(): SagaIterator<void> {
  yield put(resetFillCreativeList());
}

export function* setCreativeAlgorithmsListWorker(): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getCreativeAlgorithms>>(makeReqWithRD, {
      fetcher: getCreativeAlgorithms,
      fill: setTempAlgorithms,
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* sortCreativeListWorker({
  payload,
}: ReturnType<typeof setListSort>): SagaIterator<void> {
  try {
    const { sort, key } = payload;
    const state = yield select(({ creativeReducer }) => creativeReducer.list);
    const allCreoList: CreativeItemListWithStatistics[] | null =
      state.get('data');
    const translationFilterData: CreativeItemListWithStatistics[] | null =
      yield select(selectorTranslationFilter);
    const list =
      translationFilterData && allCreoList
        ? [...translationFilterData].map((item) => ({
            ...item,
            statistics: {
              ...allCreoList.find((statItem) => statItem.xxhash === item.xxhash)
                ?.statistics,
            },
          }))
        : allCreoList && [...allCreoList];
    let newData: CreativeItemListWithStatistics[] = [];
    if (list) {
      if (key === 'name' && sort) {
        if (sort === 'ASC') {
          newData = list.sort((a, b) => {
            const titleA = a.title.toLowerCase();
            const titleB = b.title.toLowerCase();
            return titleA < titleB ? -1 : 1;
          });
        } else {
          newData = list.sort((a, b) => {
            const titleA = a.title.toLowerCase();
            const titleB = b.title.toLowerCase();
            return titleA > titleB ? -1 : 1;
          });
        }
      } else if (sort === 'ASC' && list && typeof key === 'string') {
        newData = list.sort(
          (
            a: { statistics: { [x: string]: number } },
            b: { statistics: { [x: string]: number } },
          ) => (a.statistics[key] > b.statistics[key] ? 1 : -1),
        );
      } else if (sort === 'DESC' && list && typeof key === 'string') {
        newData = list.sort(
          (
            a: { statistics: { [x: string]: number } },
            b: { statistics: { [x: string]: number } },
          ) => (a.statistics[key] < b.statistics[key] ? 1 : -1),
        );
      } else {
        newData = list.sort(
          (a: { creative_id: number }, b: { creative_id: number }) =>
            a.creative_id > b.creative_id ? 1 : -1,
        );
      }
    }
    if (translationFilterData) {
      yield put(setCreoTranslationFilter(newData));
    } else {
      yield put(
        setTempCreativeListSuccess(
          state.set('data', newData) /* .set('LTU', Date.now()) */,
        ),
      );
    }
    yield put(loadCreativeList({ isHard: true }));
  } catch (e) {
    console.log({ e });
  }
}

export function* fetchCreativeAllTagsWorker({
  payload,
}: ReturnType<typeof fetchCreativeAllTagsRequest>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getAllCreativeTags>>(makeReqWithRD, {
      fetcher: getAllCreativeTags,
      fill: setCreativeAllTags,
      parameters: payload,
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* changeActiveTagWorker({
  payload,
}: ReturnType<typeof addActiveTagRequest>): SagaIterator<void> {
  try {
    const { tag, filters, campaign_xxhash, period } = payload;
    const state: string[] = yield select(
      ({ creativeReducer }) => creativeReducer.activeTags,
    );
    if (state.includes(tag)) {
      yield put(setActiveTags(state.filter((el) => el !== tag)));
    } else {
      yield put(setActiveTags([...state, tag]));
    }

    const stateNew: string[] = yield select(
      ({ creativeReducer }) => creativeReducer.activeTags,
    );

    yield all([
      take(stateNew),
      put(
        fetchCreativesList({
          campaign_xxhash,
          period,
          filters: { ...filters, tags: stateNew },
        }),
      ),
    ]);
  } catch (e) {
    console.error({ e });
  }
}

export function* loadCreativeListWorker({
  payload,
}: ReturnType<typeof loadCreativeList>): SagaIterator<void> {
  try {
    const { isHard = false } = payload;
    const lastID = yield select(selectorCreativeLastID);
    const { data: serverList, isLoading } = extractGenFetchData<
      CreativeItemListWithStatistics[]
    >(yield select(selectorCreativeList));
    if (isHard) {
      const genList = makeGenList<CreativeItemListWithStatistics[]>();
      yield put(setListView(genList));
    }
    if (isLoading) {
      yield take(setTempCreativeListSuccess);
    }
    const translationFilterData: CreativeItemListWithStatistics[] | null =
      yield select(selectorTranslationFilter);
    const currentData = translationFilterData || serverList;
    if (currentData) {
      const { listView } = extractMakeGenList<CreativeItemListWithStatistics[]>(
        yield select(selectorCreativeListView),
      );
      const startIndex = listView.length;
      const finishIndex = startIndex + 35;
      let newList = makeGenList<CreativeItemListWithStatistics[]>();
      const indexLastID =
        currentData.findIndex(
          ({ xxhash }: { xxhash: string }) => xxhash === lastID,
        ) + 5;
      if (lastID && indexLastID > finishIndex) {
        newList = newList.set('listView', [
          ...listView,
          ...currentData.filter(
            (item, index) => index >= startIndex && index <= indexLastID,
          ),
        ]);
      } else {
        newList = newList.set('listView', [
          ...listView,
          ...currentData.filter(
            (item, index) => index >= startIndex && index < finishIndex,
          ),
        ]);
      }
      yield put(setListView(newList));
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* searchCreativeListWorker({
  payload,
}: ReturnType<typeof searchCreativeList>): SagaIterator<void> {
  const { search, period, callbacks, blockTotalRequest } = payload;
  try {
    let genList = makeGenList<CreativeItemListWithStatistics[]>();
    if (callbacks?.setIsLoadingSearch) {
      callbacks.setIsLoadingSearch(true);
    }
    const { data } = extractGenFetchData<CreativeItemListWithStatistics[]>(
      yield select(selectorCreativeList),
    );

    const translationData: CreativeItemListWithStatistics[] | null =
      yield select(selectorTranslationFilter);

    const currentData = translationData || data;
    const { listView } = extractMakeGenList<CreativeItemListWithStatistics[]>(
      yield select(selectorCreativeListView),
    );
    genList = genList.set('listView', listView || []);
    yield put(setListView(genList));
    yield delay(100);
    if (search) {
      if (currentData) {
        const creoData = currentData.filter(({ title }) =>
          title.toLowerCase().includes(search.toLowerCase()),
        );
        const filterList = genList
          .set('listView', creoData)
          .set('isBlocked', true);
        yield put(setListView(filterList));
        if (!blockTotalRequest) {
          yield put(
            fetchCreativesTotal({
              xxhash_list: creoData.map((item) => item.xxhash),
              period,
              debounce: 1000,
            }),
          );
        }
      }
    } else {
      yield put(loadCreativeList({ isHard: true }));
      if (currentData) {
        yield put(
          fetchCreativesTotal({
            xxhash_list: currentData.map((item) => item.xxhash),
            period,
            debounce: 1000,
          }),
        );
      }
    }
    if (callbacks?.setIsLoadingSearch) {
      callbacks.setIsLoadingSearch(false);
    }
  } catch (e) {
    console.error({ e });
    if (callbacks?.setIsLoadingSearch) {
      callbacks.setIsLoadingSearch(false);
    }
  }
}

export function* fetchCreativeListModalSagaWorker({
  payload,
}: ReturnType<typeof fetchCreativeListModal>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getCreativeList>>(makeReqWithRD, {
      fetcher: getCreativeList,
      fill: setListModal,
      parameters: { ...payload, filters: {} },
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* fetchVendorLinkDataSagaWorker(): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getInternalList>>(makeReqWithRD, {
      fetcher: getInternalList,
      fill: (v) => {
        const data: TInternalList = v.get('data') as TInternalList;
        const newData = data?.items as TInternalItem[];
        return setVendorLinks(
          updateFillFetchedData<TInternalList, TInternalItem[]>({
            fillFetchedData: v,
            newData,
          }),
        );
      },
      parameters: { key: 'TVerificationLink' },
    });
  } catch (e) {
    console.log({ e });
  }
}

export function* setCreativeTagsWorker({
  payload,
}: ReturnType<typeof updateTagCreative>): SagaIterator<void> {
  try {
    yield call(updateToken);
    const result = yield call(setCreativeTagsAPI, payload);
    if (result) {
      const oldDataTemp = yield select(selectorCreativeTags);
      const oldData = oldDataTemp.get('data');
      const oldPast = oldDataTemp.get('past');
      oldPast.data.push(oldData);
      oldPast.LTU = Date.now();
      const tags: SagaReturnType<typeof getCreativeTags> = yield call(
        getCreativeTags,
        { xxhash: payload.xxhash },
      );
      yield put(
        setCreativeTags(
          oldDataTemp
            .set('data', tags)
            .set('past', oldPast)
            .set('LTU', Date.now())
            .set('error', { isError: false, message: '', code: 0 }),
        ),
      );
    }
  } catch (e) {
    let oldDataTemp = yield select(selectorCreativeTags);
    oldDataTemp = oldDataTemp.set('error', {
      isError: true,
      message: e.message,
      code: e?.code,
      fields: Object.entries(e.data.fields).map(([, tag]) => tag),
    });
    yield put(setCreativeTags(oldDataTemp));
    console.error(e);
  }
}

export function* revertCreativeTagsWorker({
  payload,
}: ReturnType<typeof revertCreativeTags>): SagaIterator<void> {
  try {
    const oldDataTemp = yield select(selectorCreativeTags);
    const oldPast = oldDataTemp.get('past');
    const oldPastData = oldPast.data as string[][];
    if (oldPastData.length) {
      const old = oldPastData.pop() as string[];
      const result = yield call(setCreativeTagsAPI, {
        xxhash: payload.xxhash,
        tags: old,
      });
      if (result) {
        const tags: SagaReturnType<typeof getCreativeTags> = yield call(
          getCreativeTags,
          { xxhash: payload.xxhash },
        );
        oldPast.data = oldPastData;
        yield put(
          setCreativeTags(
            oldDataTemp
              .set('data', tags)
              .set('past', oldPast)
              .set('LTU', Date.now()),
          ),
        );
      }
    }
  } catch (e) {
    console.error(e);
  }
}

export function* changeAllCreativeStatusesWorker({
  payload,
}: ReturnType<typeof changeAllCreativeStatuses>): SagaIterator<void> {
  try {
    const { callback, ...data } = payload;
    const creativeViewTemp = yield select(selectorCreativeListView);
    const creativeAllTemp = yield select(selectorCreativeList);
    yield put(setTempCreativeList(creativeAllTemp.set('isLoading', true)));
    yield put(setListView(creativeAllTemp.set('isLoading', true)));
    const result = yield call(setAllStatuses, data);
    if (result) {
      callback(false);
      const creatives = creativeAllTemp.get(
        'data',
      ) as CreativeItemListWithStatistics[];
      const creativesView = creativeViewTemp.get(
        'listView',
      ) as CreativeItemListWithStatistics[];

      yield put(
        setTempCreativeList(
          creativeAllTemp
            .set(
              'data',
              creatives.map((value) => ({ ...value, status: data.status })),
            )
            .set('LTU', Date.now())
            .set('isLoading', false),
        ),
      );

      yield put(
        setListView(
          creativeViewTemp
            .set(
              'listView',
              creativesView.map((value) => ({ ...value, status: data.status })),
            )
            .set('isLoading', false),
        ),
      );
    }
  } catch (e) {
    console.error(e);
  }
}

export function* changeGroupStatusesCreativesWorker({
  payload,
}: ReturnType<typeof changeGroupCreativesStatuses>): SagaIterator<void> {
  try {
    const {
      status,
      creative_xxhash_list,
      campaign_xxhash,
      callback,
      listFilter,
      mode,
      translation,
      alertText,
    } = payload;
    let result = false;
    if (mode === 'GROUP') {
      result = yield call(setGroupStatuses, { status, creative_xxhash_list });
    }
    if (mode === 'ALL') {
      result = yield call(setAllStatuses, { status, xxhash: campaign_xxhash });
    }
    if (result) {
      callback();
      yield put(fetchCreativesList({ campaign_xxhash, filters: listFilter }));
      if (translation) {
        yield put(fetchCreativesTranslation({ xxhash: campaign_xxhash }));
      }
      toastSuccess(alertText);
    }
  } catch (e) {
    console.error(e);
  }
}

export function* clearGroupTargetingWorker({
  payload,
}: ReturnType<typeof clearGroupCreativesTargeting>): SagaIterator {
  try {
    const {
      listFilter,
      callback,
      alertText,
      campaign_xxhash,
      mode,
      xxhash_list,
    } = payload;
    let result = false;
    if (mode === 'GROUP') {
      result = yield call(clearTargetToList, { xxhash_list });
    }
    if (mode === 'ALL') {
      result = yield call(clearTargetAllCreativesByCampaign, {
        xxhash: campaign_xxhash,
      });
    }

    if (result) {
      callback();
      yield put(fetchCreativesList({ campaign_xxhash, filters: listFilter }));
      toastSuccess(alertText);
    }
  } catch (e) {
    console.error(e);
  }
}

export function* fetchCreativePretargetingWorker({
  payload,
}: ReturnType<typeof fetchCreativePretargeting>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getCreativePretargeting>>(makeReqWithRD, {
      fetcher: getCreativePretargeting,
      fill: setCreativePretargeting,
      parameters: payload,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* setCreativePretargetingWorker({
  payload,
}: ReturnType<typeof setCreativePretargetingAction>): SagaIterator<void> {
  try {
    const result = yield call(setCreativePretargetingApi, payload);
    if (result) {
      const newData = yield call(getCreativePretargeting, {
        xxhash: payload.xxhash,
      });
      let oldData = yield select(selectorCreativePretargeting);
      oldData = oldData.set('data', newData).set('LTU', Date.now());
      yield put(setCreativePretargeting(oldData));
    }
  } catch (e) {
    console.error(e);
  }
}

export function* removeCreativePretargetingWorker({
  payload,
}: ReturnType<typeof removeCreativePretargeting>): SagaIterator<void> {
  try {
    const result = yield call(removeCreativePretargetingApi, payload);
    if (result) {
      yield put(fetchCreativePretargeting(payload));
    }
  } catch (e) {
    console.error(e);
  }
}

export function* getAllCreativeTypesWorker(): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getAllCreativeTypesApi>>(makeReqWithRD, {
      fetcher: getAllCreativeTypesApi,
      fill: setFetchCreativeTypes,
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* setCreoExperimentalWorker({
  payload,
}: ReturnType<typeof setCreoExperimentalAction>): SagaIterator<void> {
  try {
    const { callbacks, ...rest } = payload;
    const config_values: Record<string, string | number | boolean> = {};
    config_values[rest.id] = rest.value;
    let result = null;
    if (rest.xxhash) {
      const params: TPCreativeEdit = {
        xxhash: rest.xxhash,
        config_values,
      };
      result = yield call(setCreativeEdit, params);
    }
    if (result) {
      callbacks.onSuccess(true);
      const creoInfo = yield select(selectorCreativeDetail);
      const creoData: RGetPersonal = creoInfo.get('data');
      const newData = {
        ...creoData,
        config_values: { ...creoData.config_values, [rest.id]: rest.value },
      };
      yield put(setTempCreativeDetail(creoInfo.set('data', newData)));
      setTimeout(() => {
        callbacks.onSuccess(false);
      }, 4000);
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* createScreenWorker({
  payload,
}: ReturnType<typeof createScreenAction>): SagaIterator<void> {
  const { callbacks, ...params } = payload;
  try {
    const result = yield call(createScreenAPI, params);
    if (result) {
      yield put(getScreenStatus(payload));
    }
  } catch (e) {
    callbacks.setTextError(e.message);
    console.error(e);
  }
}

export function* screenStatusAPIWorker({
  payload,
}: ReturnType<typeof getScreenStatus>): SagaIterator<void> {
  const { callbacks, ...params } = payload;
  try {
    yield call<TMakeReqWithRD<typeof getScreenStatusAPI>>(makeReqWithRD, {
      fetcher: getScreenStatusAPI,
      fill: setCreativeStatus,
      parameters: params,
    });
  } catch (e) {
    callbacks.setTextError(e.message);
    console.error(e);
  }
}

export function* massEditLinkWorker({
  payload,
}: ReturnType<typeof massEditLinkAction>): SagaIterator<void> {
  const { alertText, closeMassEditModal, clearCheckedList, ...params } = payload
  try {
    const result = yield call(massEditLinkAPI, params);
    if (result) {
      toastSuccess(alertText)
    }
  } catch (e) {
    console.error(e);
  } finally {
    closeMassEditModal()
    clearCheckedList()
  }
}

export function* fetchGridEridInfoWorker({
  payload,
}: ReturnType<typeof fetchGridEridInfoAction>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getCustomerByEridAPI>>(makeReqWithRD, {
      fetcher: getCustomerByEridAPI,
      fill: setCurrentGridEridInfo,
      parameters: payload,
    });
  } catch (e) {
    console.error(e);
  }
}
