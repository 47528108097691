import React, { FC } from 'react';
import Load from '../Load';
import css from './styles.module.scss';

const SwitchItemLoading: FC = () => (
  <div className={css.switch}>
    <Load
      style={{
        width: '40px',
        height: '40px',
      }}
    />
    <Load
      style={{
        width: '40px',
        height: '40px',
      }}
    />
  </div>
);

export default SwitchItemLoading;
