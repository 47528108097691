import React, { FC } from 'react';
import Card from 'components/UI/Card';
import PriceInput from 'components/UI/PriceInput';
import Help from 'components/Help';
import { useTranslation } from 'react-i18next';
import { Form, FormikProvider } from 'formik';
import { isFieldInObject } from 'utils/isFieldInObject';
import CollapseCard from 'components/UI/CollapseCard';
import css from './styles.module.scss';
import { useSpecial } from './hooks';

const Special: FC = () => {
  const { t } = useTranslation();

  const { savedField, formik, onKeyDown } = useSpecial();

  return (
    <Card className={css.card} noHover>
      <FormikProvider value={formik}>
        <Form onKeyDown={onKeyDown}>
          <CollapseCard
            title={
              <h3>{t(`campaigns_page.campaign_additional.special.title`)}</h3>
            }
          >
            <div className={css.body}>
              <div>
                <PriceInput
                  label={t(
                    `campaigns_page.campaign_additional.special.lifetime_label`,
                  )}
                  setField={formik.setFieldValue}
                  theme="horizontal"
                  onValueChange={({ value }) => {
                    let val = value;
                    if (+value < 1) {
                      val = '1';
                    }
                    if (+value > 60) {
                      val = '60';
                    }
                    formik.setFieldValue('lead_wait_time', val);
                  }}
                  isSaved={isFieldInObject(savedField, 'lead_wait_time')}
                  decimalScale={0}
                  isWatchEnter
                  placeholder={t(
                    `campaigns_page.campaign_settings.Please_enter`,
                  )}
                  value={formik.values.lead_wait_time}
                />
                <Help
                  title=""
                  helpattributes={{
                    text: t(
                      `campaigns_page.campaign_additional.special.lifetime_help`,
                    ),
                  }}
                />
              </div>
            </div>
          </CollapseCard>
        </Form>
      </FormikProvider>
    </Card>
  );
};

export default Special;
