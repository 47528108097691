import {
  CSSProperties,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSpring } from 'react-spring';
import moment from 'moment';
import { TCampaignsFilter } from '../../../domains/campaigns/types';
import {
  CampaignFilter,
  FilterCompare,
} from '../../../domains/campaigns/model/shared/enums';
import { useOuterElementClick } from '../../../hooks/useOuterElementClick';

type TFUseFilterProps = {
  data?: TCampaignsFilter[];
  handleApplyFilter?: (v: TCampaignsFilter[]) => void;
  applyHistoryFilters?: () => void;
};

type TFUseFilter = (props: TFUseFilterProps) => {
  filters: TCampaignsFilter[];
  handleClearFilter: () => void;
  handleAddFilter: () => void;
  handleRemoveItemFilter: (id: number) => void;
  open: boolean;
  togglePanel: (v: boolean) => void;
  applyFilter: () => void;
  changeItem: (id: number, value: string, k: keyof TCampaignsFilter) => void;
  isValid: (ar: TCampaignsFilter[], isCount?: boolean) => boolean;
  isValidItem: (
    id: number,
    ar: TCampaignsFilter[],
    isCount?: boolean,
  ) => boolean;
  fieldRef: RefObject<HTMLDivElement>;
  showFilter: CSSProperties;
  wrapperRef: RefObject<HTMLDivElement>;
};

export const useFilter: TFUseFilter = ({
  data,
  handleApplyFilter,
  applyHistoryFilters,
}) => {
  const [filters, setFilters] = useState<TCampaignsFilter[]>(() => data || []);
  const [open, setOpen] = useState(false);

  const fieldRef = useRef<HTMLDivElement>(null);

  const [counterClick, setCounterClick] = useState(0);

  /** очищает все фильтры */
  const handleClearFilter: ReturnType<TFUseFilter>['handleClearFilter'] =
    () => {
      setFilters([]);
    };

  /** Скроллим вниз при добавлении фильра */
  const scrollingButton = () => {
    if (fieldRef && fieldRef.current) {
      fieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  /** Добавляет новый фильтр */
  const handleAddFilter: ReturnType<TFUseFilter>['handleAddFilter'] = () => {
    setFilters((prev) => [
      ...prev,
      {
        id: prev.length + 1,
        value: '',
        compare: FilterCompare.LESS,
        key: CampaignFilter.CAMPAIGN_START,
      },
    ]);
  };

  /** удалает один фильтр по ключу */
  const handleRemoveItemFilter: ReturnType<TFUseFilter>['handleRemoveItemFilter'] =
    (id) => {
      setFilters((prev) => [...prev.filter((item) => item.id !== id)]);
    };

  /** функиця изменяет поле фильтрации по ключу */
  const changeItem: ReturnType<TFUseFilter>['changeItem'] = useCallback(
    (id, value, k) => {
      setFilters((prev) => {
        const index = prev.findIndex((v) => v.id === id);
        if (index < 0) return prev;
        const temp = { ...prev[index] };
        const copy = [...prev];
        copy[index] = { ...temp, [k]: value };
        return copy;
      });
    },
    [filters],
  );

  const isValidItem: ReturnType<TFUseFilter>['isValidItem'] = (
    id,
    ar,
    isCount = false,
  ) => {
    const testDate = (str: string) => /^\d{2}[.]\d{2}[.]\d{4}$/.test(str);
    let res = false;
    if (counterClick === 0 && isCount) {
      return res;
    }
    const element = ar.find((v) => v.id === id);
    if (element) {
      if (!element.value) {
        res = true;
      }
      if (!testDate(moment(element.value).format('DD.MM.YYYY'))) {
        res = true;
      }
      if (element.key === CampaignFilter.CAMPAIGN_FINISH) {
        ar.forEach((item) => {
          if (
            item.key === CampaignFilter.CAMPAIGN_START &&
            (element.compare === FilterCompare.LESS ||
              element.compare === FilterCompare.EQUALS) &&
            moment(item.value).isAfter(element.value)
          ) {
            res = true;
          }
        });
      }
    }
    return res;
  };

  const isValid: ReturnType<TFUseFilter>['isValid'] = (ar, isCount) => {
    let valid = false;

    ar.forEach(({ id }) => {
      if (isValidItem(id, ar, isCount)) {
        valid = true;
      }
    });

    return valid;
  };

  /** подтверждает применение фильтра */
  const applyFilter = () => {
    if (applyHistoryFilters) {
      applyHistoryFilters();
      setOpen(false);
      setCounterClick(1);
    } else if (!isValid(filters) && handleApplyFilter) {
      handleApplyFilter(filters);
      setOpen(false);
      setCounterClick(1);
    } else {
      setCounterClick((prev) => prev + 1);
    }
  };

  const { wrapperRef } = useOuterElementClick({
    isShow: open,
    closeModal: () => {
      setOpen(false);
      setFilters(() => data || []);
    },
  });

  const togglePanel: ReturnType<TFUseFilter>['togglePanel'] = (v) => {
    setOpen(v);
  };

  useEffect(() => {
    setFilters(() => data || []);
  }, [data]);

  useEffect(() => {
    if (filters.length > 4) {
      scrollingButton();
    }
  }, [filters]);

  const showFilter = useSpring<CSSProperties>(
    open
      ? {
        height: 'auto',
        visibility: 'visible',
        opacity: 1,
        transform: 'translateY(0)',
        config: { duration: 120 },
        delay: 0,
        zIndex: 500,
        position: 'absolute',
        top: '0',
        right: '100%',
      }
      : {
        opacity: 0,
        height: 0,
        visibility: 'hidden',
        transform: 'translateY(-50px)',
        delay: 30,
        position: 'absolute',
        top: '0',
        right: '100%',
      },
  );

  return {
    filters,
    handleRemoveItemFilter,
    handleAddFilter,
    handleClearFilter,
    open,
    applyFilter,
    changeItem,
    isValid,
    isValidItem,
    togglePanel,
    fieldRef,
    showFilter,
    wrapperRef,
  };
};
