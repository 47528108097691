import React, { FC } from 'react';
import cn from 'classnames';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import i18next from 'i18next';
import Label, { PLabel } from '../Input/Label';
import input from '../Input/styles.module.scss';
import toLocaleStringSupporte from '../../../utils/toLocaleStringSupporte';
import ErrorText from '../ErrorText';
import { toolTipType } from '../Input/types';

type PInput = {
  /** подкрашивает как ошибку  =false */
  error?: boolean;
  /** Текст лейбла =undefined */
  label?: string;
  /** текст ошибки =undefined */
  errorText?: string;
  /** отключение текста ошибки */
  errorTextNull?: boolean;
  /** устанавливает disabled в инпут применяется к стилям =false */
  disabled?: boolean;
  /** включает режим редактирования */
  notField?: boolean;
  inCorrect?: string;
  /** атрибуты инпута NumberFormat =undefined */
  numberFormatAttributes?: NumberFormatProps;
  /** тултип со ссылкой */
  tooltip?: toolTipType;
  /* Дополнительный класс */
  classname?: string;
};
type TAllProps = PInput & Partial<PLabel>;

const PriceInput: FC<TAllProps> = ({
  error,
  disabled,
  label,
  notField,
  inCorrect,
  errorTextNull = false,
  numberFormatAttributes,
  errorText = i18next.t('errors.limitsym'),
  tooltip,
  classname,
}) => (
  <div
    className={cn(
      'input-form-group',
      input['form-group'],
      error ? input['form-group_error'] : '',
      disabled ? input['form-group_disabled'] : '',
      classname,
    )}
  >
    {label && <Label label={label} tooltip={tooltip} />}
    {notField ? (
      <div className={input.title}>
        {typeof numberFormatAttributes?.value === 'number' &&
          toLocaleStringSupporte(numberFormatAttributes.value, inCorrect)}
      </div>
    ) : (
      <div>
        <NumberFormat
          isNumericString
          thousandSeparator
          decimalSeparator="."
          fixedDecimalScale
          placeholder={i18next.t(
            'campaigns_page.campaign_settings.Please_enter',
          )}
          disabled={disabled}
          maxLength={13}
          isAllowed={({ value }) => {
            if (value.indexOf('-') + 1) {
              return false;
            }
            return true;
          }}
          {...numberFormatAttributes}
        />
      </div>
    )}
    {!errorTextNull && error && errorText && <ErrorText text={errorText} />}
  </div>
);

export default PriceInput;
