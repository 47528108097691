import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { TMakeReqWithRD, makeReqWithRD } from 'redux/makeReqWithRD';
import { setContractsList } from '../reducer';
import { getContractsListAPI } from '../api'
import { getContractsList } from '../actions';

export function* workerGetContractsList({
  payload,
}: ReturnType<typeof getContractsList>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getContractsListAPI>>(makeReqWithRD, {
      fetcher: getContractsListAPI,
      fill: setContractsList,
      parameters: payload,
    });
  } catch (e) {
    console.error({ e });
  }
}