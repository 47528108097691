import {
  THookSelectorFetchDataInfo,
  TSelector,
  useGenFetchDataSelector,
} from '../../../redux/rootSelectors';
import { BlState, TFileList } from '../types';

export const selectorBlFiles: TSelector<BlState['blFile']> = ({ blReducer }) =>
  blReducer.blFile;

/** список хуков */
export const useBlFiles: THookSelectorFetchDataInfo<TFileList[]> = () =>
  useGenFetchDataSelector(selectorBlFiles);
