import React, { FC } from 'react';
import cn from 'classnames';
import i18n from '../../../../../../i18n';
import css from './styles.module.scss';
import { RClients } from '../../../../../../domains/clients/types';
import avatarColors from '../../../../../../utils/avatarColors';
import { RBreadCrumbsKey } from '../../../../../../domains/search/model/types';
import { THandlerForItem } from '../../../../types';

type ClientProps = THandlerForItem & {
  client: RClients;
  type: Extract<RBreadCrumbsKey, 'Organization' | 'Agency'>;
  isToggle?: boolean;
};

const Client: FC<ClientProps> = ({
  client: { title, id, icon, default_icon },
  type,
  isToggle = false,
  handlerToggle,
  getValueToggle,
  handlerClick,
}) => (
  <div
    className={css.client}
    onClick={() => {
      if (isToggle) {
        handlerToggle(id, title);
      } else {
        handlerClick(id);
      }
    }}
  >
    {isToggle && (
      <div className={css.checkboxWrapper}>
        <div
          className={cn(css.checkbox, {
            isActive: getValueToggle(id),
          })}
        />
      </div>
    )}
    {!default_icon ? (
      <div className={css.icon} style={{ backgroundImage: `url(${icon})` }} />
    ) : (
      <div
        className={cn(css.icon, '_isDefault')}
        style={{
          backgroundColor: avatarColors(title.slice(0, 1).toLowerCase()),
        }}
      >
        {title.slice(0, 1).toUpperCase()}
      </div>
    )}
    <div className={css.data}>
      <div className={css.title}>{title}</div>
      <div className={css.type}>
        {type === 'Organization' && i18n.t('just_words.w5')}
        {type === 'Agency' && i18n.t('just_words.w3')}
      </div>
    </div>
  </div>
);

export default Client;
