import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  children: ReactNode;
  modalRoot: HTMLElement;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MainPortal = ({ children, modalRoot }: Props) =>
  createPortal(children, modalRoot);

MainPortal.defaultProps = {
  modalRoot: document.getElementById('modal-portal'),
};

export default MainPortal;
