import { createAction } from '@reduxjs/toolkit';
import { CounteragentsListParams } from './types';

export const getCounteragentsList = createAction<CounteragentsListParams>(
  'counteragents/getCounteragentsList',
);

export const getAllCounteragentsList = createAction(
  'counteragents/getAllCounteragentsList',
);

export const getOrdList = createAction(
  'counteragents/getOrdList',
);
