import { useState } from 'react';
import {
  RCampaignsArrayStata,
  ResponseCampaignsStata,
} from 'domains/campaigns/types';
import { IGroupItem } from 'domains/client/types';

interface searchCampaignsReturn {
  campaignsFilter: (
    campaignsData: RCampaignsArrayStata | null,
  ) => RCampaignsArrayStata | undefined;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  search: string;
}

export function useSearchCampaigns(
  groupInfo?: IGroupItem,
): searchCampaignsReturn {
  const [search, setSearch] = useState('');

  const campaignWithoutGroupFilter = (item: ResponseCampaignsStata) => {
    if (groupInfo)
      return !item.group || item.group.title === groupInfo.group.title;
    return !item.group;
  };

  const filterSearch = (item: ResponseCampaignsStata) =>
    item.title.toLowerCase().includes(search.toLowerCase()) ||
    item.internal_id?.toLowerCase().includes(search.toLowerCase());

  const campaignsFilter = (campaignsData: RCampaignsArrayStata | null) =>
    campaignsData
      ?.filter((camp) => campaignWithoutGroupFilter(camp))
      .filter((camp) => filterSearch(camp));

  return { campaignsFilter, setSearch, search };
}
