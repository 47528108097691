import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';
import avatarColors from '../../../../../../utils/avatarColors';

type Props = {
  title: string;
  xxhash: string;
  internal_id: string;
  isActives: { title: string; xxhash: string }[];
  setIsActives: (p: Props['isActives']) => void;
  icon: string;
};

const Campaign: FC<Props> = ({
  title,
  xxhash,
  internal_id,
  setIsActives,
  isActives,
  icon,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={css.campaign}
      onClick={() => {
        if (isActives.some((hash) => hash.xxhash === xxhash)) {
          return setIsActives(
            isActives.filter((hash) => hash.xxhash !== xxhash),
          );
        }

        return setIsActives([...isActives, { xxhash, title }]);
      }}
    >
      <div
        className={cn(css.checkbox, {
          isActive: isActives.some((hash) => hash.xxhash === xxhash),
        })}
      />
      <div
        className={cn(css.icon)}
        style={{
          backgroundColor: icon
            ? '#E3ECFF'
            : avatarColors(title.slice(0, 1).toLowerCase()),
        }}
      >
        {icon ? <img src={icon} alt={icon} /> : title.slice(0, 1).toUpperCase()}
      </div>
      <div className={css.data}>
        <div className={css.title}>{title}</div>
        <div className={css.info}>
          <span>{`${t('just_words.w1')} ${internal_id}`}</span>
        </div>
      </div>
    </div>
  );
};

export default Campaign;
