import React, { FC } from 'react';
import cn from 'classnames';
import './styles.scss';
import { FiArrowUp } from 'react-icons/fi';
import { useOnScroll } from '../../hooks/useOnScroll';

const ScrollToTopButton: FC = () => {
  const { fixed } = useOnScroll(58);

  return (
    <div
      className={cn('scroll-to-top-button', { isVisible: fixed })}
      onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }}
    >
      <FiArrowUp size={24} />
    </div>
  );
};
export default ScrollToTopButton;
