import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getLogoInTheProject } from '../../../utils/logoBrandWithPrioject';
import { useLogoProject } from '../../project/hooks';

type TFUseLogoTypeInfo = () => {
  logo: string | null;
  isLoading: boolean;
};

export const useLogoTypeInfo: TFUseLogoTypeInfo = () => {
  const [logo, setLogo] = useState<string | null>(null);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { logoType, isLoading } = useLogoProject();

  useEffect(() => {
    setLogo(() => getLogoInTheProject());
  }, []);

  useEffect(() => {
    if (params.get('u')) {
      setLogo(() => logoType);
    } else {
      setLogo(() => getLogoInTheProject());
    }
  }, [logoType]);

  return { logo, isLoading };
};
