import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { FiChevronUp } from 'react-icons/fi';
import cn from 'classnames';
import css from './styles.module.scss';

interface ICollapseCardProps {
  title: string | ReactNode;
  children: ReactNode;
  // блок свернут на старте. При рендере.
  closedStart?: boolean;
  /* Нужна, чтобы точно рассчитать высоту раскрытия карточки когда меняется содержимое карточки  */
  updateHeight?: boolean[];
  /* Не обновлять высоту по содержимому */
  noUpdateHeight?: boolean;
}

const CollapseCard: FC<ICollapseCardProps> = ({
  title,
  children,
  closedStart = false,
  updateHeight = [],
  noUpdateHeight = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(!closedStart);
  const bodyRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState<number | undefined>(20000);

  useEffect(() => {
    if (bodyRef && bodyRef.current && !noUpdateHeight && isOpen) {
      setTimeout(() => {
        setMaxHeight(bodyRef.current?.children[0].clientHeight);
      }, 50);
    }
  }, [...updateHeight, isOpen]);

  return (
    <div
      className={cn(css.collapseCard, {
        _isOpen: isOpen,
      })}
    >
      <div className={css.title} onClick={() => setIsOpen(!isOpen)}>
        {title}
        <FiChevronUp size={20} className={css.arrow} />
      </div>
      <div
        className={css.body}
        ref={bodyRef}
        style={{
          maxHeight: isOpen ? `${maxHeight}px` : `0px`,
        }}
      >
        <div className={css.content}>{children}</div>
      </div>
    </div>
  );
};

export default CollapseCard;
