export const oksm = [
  {
    guid: 'B00B31E7-5758-48C0-98CA-B608E4ED9563',
    startdate: '2009-01-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '895',
    shortname: 'АБХАЗИЯ',
    fullname: 'Республика Абхазия',
    alfacode2: 'AB',
    alfacode3: 'ABH',
    clarification: '',
    lastchangetype: '3',
    changedate: '2009-01-01 00:00:00.0',
    introductiondate: '2009-01-01 00:00:00.0',
    lastChangeNum: '8',
    filedate: '2020-05-07 12:02:09.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '0C452F30-CA53-4476-884D-3FA58B7238B2',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '036',
    shortname: 'АВСТРАЛИЯ',
    fullname: 'АВСТРАЛИЯ',
    alfacode2: 'AU',
    alfacode3: 'AUS',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:18.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '1600BC3F-680C-4E14-891F-5A6A746BA2D1',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '040',
    shortname: 'АВСТРИЯ',
    fullname: 'Австрийская Республика',
    alfacode2: 'AT',
    alfacode3: 'AUT',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:51.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'F8902DE4-E6E0-4D5C-AD05-90528F29FB8C',
    startdate: '2003-10-15 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '031',
    shortname: 'АЗЕРБАЙДЖАН',
    fullname: 'Республика Азербайджан',
    alfacode2: 'AZ',
    alfacode3: 'AZE',
    clarification: '',
    lastchangetype: '2',
    changedate: '2003-10-15 00:00:00.0',
    introductiondate: '2003-10-15 00:00:00.0',
    lastChangeNum: '1',
    filedate: '2020-05-07 12:02:12.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '63A544C3-10BA-4F5C-B11B-7AA2C5C8DD5D',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '008',
    shortname: 'АЛБАНИЯ',
    fullname: 'Республика Албания',
    alfacode2: 'AL',
    alfacode3: 'ALB',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:17.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '5D84EF6A-314D-413F-945F-10AB8DFD9FFD',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '012',
    shortname: 'АЛЖИР',
    fullname: 'Алжирская Народная Демократическая Республика',
    alfacode2: 'DZ',
    alfacode3: 'DZA',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:51.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '07EA9156-00A2-4EF1-8D7E-AD22E3172CC6',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '016',
    shortname: 'АМЕРИКАНСКОЕ САМОА',
    fullname: 'АМЕРИКАНСКОЕ САМОА',
    alfacode2: 'AS',
    alfacode3: 'ASM',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:51.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'C96DCDC3-6919-4E50-A558-7FFA1DC74A8C',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '660',
    shortname: 'АНГИЛЬЯ',
    fullname: 'АНГИЛЬЯ',
    alfacode2: 'AI',
    alfacode3: 'AIA',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:32.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '8598D22F-9455-485C-999E-A71577FF0E0D',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '024',
    shortname: 'АНГОЛА',
    fullname: 'Республика Ангола',
    alfacode2: 'AO',
    alfacode3: 'AGO',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:33.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'A76A8668-8C8E-42DD-8237-7DC9635F79E5',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '020',
    shortname: 'АНДОРРА',
    fullname: 'Княжество Андорра',
    alfacode2: 'AD',
    alfacode3: 'AND',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:18.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '94DD867E-8C1A-4C1A-BF72-FCBD12B4B520',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '010',
    shortname: 'АНТАРКТИДА',
    fullname: 'АНТАРКТИДА',
    alfacode2: 'AQ',
    alfacode3: 'ATA',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:18.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '80E8EE71-63F5-45D3-8401-D810C462491A',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '028',
    shortname: 'АНТИГУА И БАРБУДА',
    fullname: 'АНТИГУА И БАРБУДА',
    alfacode2: 'AG',
    alfacode3: 'ATG',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:03.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'A533534D-11CB-42B4-A3AF-E2B0BDCD5E81',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '032',
    shortname: 'АРГЕНТИНА',
    fullname: 'Аргентинская Республика',
    alfacode2: 'AR',
    alfacode3: 'ARG',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:38.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'FC08898B-4A10-438C-8083-9D4BC7A5077D',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '051',
    shortname: 'АРМЕНИЯ',
    fullname: 'Республика Армения',
    alfacode2: 'AM',
    alfacode3: 'ARM',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:11.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '2437D4B0-9223-4631-8ED6-E7C645109F45',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '533',
    shortname: 'АРУБА',
    fullname: 'АРУБА',
    alfacode2: 'AW',
    alfacode3: 'ABW',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:52.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '83608342-901D-4B73-9D13-9B179F8D044A',
    startdate: '2004-10-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '004',
    shortname: 'АФГАНИСТАН',
    fullname: 'Переходное Исламское Государство Афганистан',
    alfacode2: 'AF',
    alfacode3: 'AFG',
    clarification: '',
    lastchangetype: '2',
    changedate: '2004-10-01 00:00:00.0',
    introductiondate: '2004-10-01 00:00:00.0',
    lastChangeNum: '4',
    filedate: '2019-06-05 11:48:22.0',
    loaddate: '2019-06-07 22:42:26.0',
  },
  {
    guid: 'A0991E3A-DB21-4E3A-81CB-579E4375EE24',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '044',
    shortname: 'БАГАМЫ',
    fullname: 'Содружество Багамы',
    alfacode2: 'BS',
    alfacode3: 'BHS',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:09.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '9881D5A2-483B-4652-A749-11850FCE27FE',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '050',
    shortname: 'БАНГЛАДЕШ',
    fullname: 'Народная Республика Бангладеш',
    alfacode2: 'BD',
    alfacode3: 'BGD',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:52.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'FB1D9DCE-FFBF-4001-B298-49719E7F8A04',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '052',
    shortname: 'БАРБАДОС',
    fullname: 'БАРБАДОС',
    alfacode2: 'BB',
    alfacode3: 'BRB',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:26.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '978432C0-EE2A-4365-A86F-1E05A7834904',
    startdate: '2003-10-15 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '048',
    shortname: 'БАХРЕЙН',
    fullname: 'Королевство Бахрейн',
    alfacode2: 'BH',
    alfacode3: 'BHR',
    clarification: '',
    lastchangetype: '2',
    changedate: '2003-10-15 00:00:00.0',
    introductiondate: '2003-10-15 00:00:00.0',
    lastChangeNum: '1',
    filedate: '2020-05-07 12:01:23.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '8A02EAE3-C745-4E30-AA6E-593F739FBDF0',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '112',
    shortname: 'БЕЛАРУСЬ',
    fullname: 'Республика Беларусь',
    alfacode2: 'BY',
    alfacode3: 'BLR',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:54.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'E553AD61-BA3C-47AB-8DAA-1DF477CC48ED',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '084',
    shortname: 'БЕЛИЗ',
    fullname: 'БЕЛИЗ',
    alfacode2: 'BZ',
    alfacode3: 'BLZ',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:27.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'D66C6406-5B4A-4CEB-88A4-784BDDBDD4FA',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '056',
    shortname: 'БЕЛЬГИЯ',
    fullname: 'Королевство Бельгии',
    alfacode2: 'BE',
    alfacode3: 'BEL',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:53.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'FF15B265-787B-49F5-9B69-7C1907927C77',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '204',
    shortname: 'БЕНИН',
    fullname: 'Республика Бенин',
    alfacode2: 'BJ',
    alfacode3: 'BEN',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:16.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '1E6DB07B-E4DD-4EEC-B82E-32CFCCA17302',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '060',
    shortname: 'БЕРМУДЫ',
    fullname: 'БЕРМУДЫ',
    alfacode2: 'BM',
    alfacode3: 'BMU',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:41.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'A5FDC830-CFE5-4491-AFCB-07F30BE3F2D1',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '100',
    shortname: 'БОЛГАРИЯ',
    fullname: 'Республика Болгария',
    alfacode2: 'BG',
    alfacode3: 'BGR',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:01.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '7568D240-34D7-4279-9A56-2756C0EF1E9D',
    startdate: '2009-11-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '068',
    shortname: 'БОЛИВИЯ, МНОГОНАЦИОНАЛЬНОЕ ГОСУДАРСТВО',
    fullname: 'Многонациональное Государство Боливия',
    alfacode2: 'BO',
    alfacode3: 'BOL',
    clarification: '',
    lastchangetype: '2',
    changedate: '2009-11-01 00:00:00.0',
    introductiondate: '2009-11-01 00:00:00.0',
    lastChangeNum: '9',
    filedate: '2020-05-07 12:01:26.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'DFD1B102-DE61-4F21-9552-BB57E6EAA22A',
    startdate: '2011-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '535',
    shortname: 'БОНЭЙР, СИНТ-ЭСТАТИУС И САБА',
    fullname: 'БОНЭЙР, СИНТ-ЭСТАТИУС И САБА',
    alfacode2: 'BQ',
    alfacode3: 'BES',
    clarification: '',
    lastchangetype: '3',
    changedate: '2011-07-01 00:00:00.0',
    introductiondate: '2011-07-01 00:00:00.0',
    lastChangeNum: '11',
    filedate: '2020-05-07 12:01:38.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'BE5379DE-5293-4680-881D-36EE7D4EDB62',
    startdate: '2003-10-15 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '070',
    shortname: 'БОСНИЯ И ГЕРЦЕГОВИНА',
    fullname: 'БОСНИЯ И ГЕРЦЕГОВИНА',
    alfacode2: 'BA',
    alfacode3: 'BIH',
    clarification: '',
    lastchangetype: '2',
    changedate: '2003-10-15 00:00:00.0',
    introductiondate: '2003-10-15 00:00:00.0',
    lastChangeNum: '1',
    filedate: '2020-05-07 12:01:37.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '93729672-DDA5-4FFD-AEBC-FEF7A1D63A80',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '072',
    shortname: 'БОТСВАНА',
    fullname: 'Республика Ботсвана',
    alfacode2: 'BW',
    alfacode3: 'BWA',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2019-06-05 11:48:32.0',
    loaddate: '2019-06-07 22:42:26.0',
  },
  {
    guid: 'F91D962F-2DB9-48B5-9FF8-FC89813CBADC',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '076',
    shortname: 'БРАЗИЛИЯ',
    fullname: 'Федеративная Республика Бразилия',
    alfacode2: 'BR',
    alfacode3: 'BRA',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:52.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '3C60737D-D5ED-4BD2-9A9D-14AF1688E252',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '086',
    shortname: 'БРИТАНСКАЯ ТЕРРИТОРИЯ В ИНДИЙСКОМ ОКЕАНЕ',
    fullname: 'БРИТАНСКАЯ ТЕРРИТОРИЯ В ИНДИЙСКОМ ОКЕАНЕ',
    alfacode2: 'IO',
    alfacode3: 'IOT',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:47.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '6CAA4E4E-15FA-4437-BD08-D232BF038AA0',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '096',
    shortname: 'БРУНЕЙ-ДАРУССАЛАМ',
    fullname: 'БРУНЕЙ-ДАРУССАЛАМ',
    alfacode2: 'BN',
    alfacode3: 'BRN',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:41.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '0EB369FF-29EE-4C83-9578-D404B0AEAB8E',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '854',
    shortname: 'БУРКИНА-ФАСО',
    fullname: 'БУРКИНА-ФАСО',
    alfacode2: 'BF',
    alfacode3: 'BFA',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:46.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'DD4F792C-8B44-46E1-9335-8CACC0A268B2',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '108',
    shortname: 'БУРУНДИ',
    fullname: 'Республика Бурунди',
    alfacode2: 'BI',
    alfacode3: 'BDI',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:06.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'AFC1BCC6-728F-4FA6-962E-7E3828572D2B',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '064',
    shortname: 'БУТАН',
    fullname: 'Королевство Бутан',
    alfacode2: 'BT',
    alfacode3: 'BTN',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:46.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '6FC143C2-D808-41E5-87AB-38665C479E90',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '548',
    shortname: 'ВАНУАТУ',
    fullname: 'Республика Вануату',
    alfacode2: 'VU',
    alfacode3: 'VUT',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:35.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '32F95649-8235-4F0B-9F99-2F69FFC0CE7F',
    startdate: '2012-09-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '348',
    shortname: 'ВЕНГРИЯ',
    fullname: 'Венгрия',
    alfacode2: 'HU',
    alfacode3: 'HUN',
    clarification: '',
    lastchangetype: '2',
    changedate: '2012-09-01 00:00:00.0',
    introductiondate: '2012-09-01 00:00:00.0',
    lastChangeNum: '15',
    filedate: '2020-05-07 12:02:46.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'DBF17DAD-178B-4AC6-976C-7FCE1AD36019',
    startdate: '2009-11-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '862',
    shortname: 'ВЕНЕСУЭЛА, БОЛИВАРИАНСКАЯ РЕСПУБЛИКА',
    fullname: 'Боливарианская Республика Венесуэла',
    alfacode2: 'VE',
    alfacode3: 'VEN',
    clarification: '',
    lastchangetype: '2',
    changedate: '2009-11-01 00:00:00.0',
    introductiondate: '2009-11-01 00:00:00.0',
    lastChangeNum: '9',
    filedate: '2020-05-07 12:01:35.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'FF3AEFE7-AD98-4C2E-B152-6D630244887B',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '092',
    shortname: 'ВИРГИНСКИЕ ОСТРОВА, БРИТАНСКИЕ',
    fullname: 'Британские Виргинские острова',
    alfacode2: 'VG',
    alfacode3: 'VGB',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:26.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '7481CC3A-8114-4988-A46C-98DFC7BBDCD1',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '850',
    shortname: 'ВИРГИНСКИЕ ОСТРОВА, США',
    fullname: 'Виргинские острова Соединенных Штатов',
    alfacode2: 'VI',
    alfacode3: 'VIR',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:35.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '248270F6-5613-4168-A245-0A40BA68F26E',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '704',
    shortname: 'ВЬЕТНАМ',
    fullname: 'Социалистическая Республика Вьетнам',
    alfacode2: 'VN',
    alfacode3: 'VNM',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:44.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '4A841A7D-1C5C-4FA3-8471-A2ECD0E776DD',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '266',
    shortname: 'ГАБОН',
    fullname: 'Габонская Республика',
    alfacode2: 'GA',
    alfacode3: 'GAB',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:45.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '8F54FEDD-8170-4608-A787-70C23E53393E',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '332',
    shortname: 'ГАИТИ',
    fullname: 'Республика Гаити',
    alfacode2: 'HT',
    alfacode3: 'HTI',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:27.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '88A7F6A3-9CB4-4BC7-A422-2BCB72F4AA0D',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '328',
    shortname: 'ГАЙАНА',
    fullname: 'Республика Гайана',
    alfacode2: 'GY',
    alfacode3: 'GUY',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:19.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '9C494808-8623-4C88-B77B-BE106CB8EEF5',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '270',
    shortname: 'ГАМБИЯ',
    fullname: 'Республика Гамбия',
    alfacode2: 'GM',
    alfacode3: 'GMB',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:02.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '6AE5B861-7951-4A27-95F9-5248702B876C',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '288',
    shortname: 'ГАНА',
    fullname: 'Республика Гана',
    alfacode2: 'GH',
    alfacode3: 'GHA',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:45.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '653F9FE8-32C8-4CEA-BAA6-42BA8A82F61F',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '312',
    shortname: 'ГВАДЕЛУПА',
    fullname: 'ГВАДЕЛУПА',
    alfacode2: 'GP',
    alfacode3: 'GLP',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:50.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '72E37AAE-38BB-48D3-8103-D53084F9C905',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '320',
    shortname: 'ГВАТЕМАЛА',
    fullname: 'Республика Гватемала',
    alfacode2: 'GT',
    alfacode3: 'GTM',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:05.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '485EBB23-32C6-4E77-A99F-4669431881E7',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '324',
    shortname: 'ГВИНЕЯ',
    fullname: 'Гвинейская Республика',
    alfacode2: 'GN',
    alfacode3: 'GIN',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-04-23 16:17:57.0',
    loaddate: '2020-04-24 22:42:12.0',
  },
  {
    guid: '30A323F4-762F-45B9-9026-6387CBF77B0D',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '624',
    shortname: 'ГВИНЕЯ-БИСАУ',
    fullname: 'Республика Гвинея-Бисау',
    alfacode2: 'GW',
    alfacode3: 'GNB',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:27.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '679AF1D3-B03F-4DEE-93F6-8EB4FF5DCAD8',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '276',
    shortname: 'ГЕРМАНИЯ',
    fullname: 'Федеративная Республика Германия',
    alfacode2: 'DE',
    alfacode3: 'DEU',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:54.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'D58164D0-9219-40A3-BCBA-DBFC07F2E384',
    startdate: '2006-10-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '831',
    shortname: 'ГЕРНСИ',
    fullname: 'ГЕРНСИ',
    alfacode2: 'GG',
    alfacode3: 'GGY',
    clarification: '',
    lastchangetype: '3',
    changedate: '2006-10-01 00:00:00.0',
    introductiondate: '2006-10-01 00:00:00.0',
    lastChangeNum: '6',
    filedate: '2020-05-07 12:01:57.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'C44B79C6-7464-4B0A-9344-B9A7B03EB0D5',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '292',
    shortname: 'ГИБРАЛТАР',
    fullname: 'ГИБРАЛТАР',
    alfacode2: 'GI',
    alfacode3: 'GIB',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:03.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '85417B12-3C58-42F1-B858-FF0C223E8E98',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '340',
    shortname: 'ГОНДУРАС',
    fullname: 'Республика Гондурас',
    alfacode2: 'HN',
    alfacode3: 'HND',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:54.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '3476B435-49EA-4BE2-A023-781BEE984CE3',
    startdate: '2003-10-15 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '344',
    shortname: 'ГОНКОНГ',
    fullname: 'Специальный административный регион Китая Гонконг',
    alfacode2: 'HK',
    alfacode3: 'HKG',
    clarification: '',
    lastchangetype: '2',
    changedate: '2003-10-15 00:00:00.0',
    introductiondate: '2003-10-15 00:00:00.0',
    lastChangeNum: '1',
    filedate: '2020-05-07 12:01:54.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '25321921-A8B8-4A12-B2CD-9AC387D05B34',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '308',
    shortname: 'ГРЕНАДА',
    fullname: 'ГРЕНАДА',
    alfacode2: 'GD',
    alfacode3: 'GRD',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:46.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'F7B1655A-752B-4AD8-B13F-AFA89D1A00EA',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '304',
    shortname: 'ГРЕНЛАНДИЯ',
    fullname: 'ГРЕНЛАНДИЯ',
    alfacode2: 'GL',
    alfacode3: 'GRL',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:25.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '6B12B088-A494-43DD-816F-0DEDC5BB5BC3',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '300',
    shortname: 'ГРЕЦИЯ',
    fullname: 'Греческая Республика',
    alfacode2: 'GR',
    alfacode3: 'GRC',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:52.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '8DF75608-C0CF-4D18-8158-452C6DB8D4BF',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '268',
    shortname: 'ГРУЗИЯ',
    fullname: 'ГРУЗИЯ',
    alfacode2: 'GE',
    alfacode3: 'GEO',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:14.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'A8F3B2F1-DA99-43AD-8AD2-A2ADEC675423',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '316',
    shortname: 'ГУАМ',
    fullname: 'ГУАМ',
    alfacode2: 'GU',
    alfacode3: 'GUM',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:01.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '1C72A42A-09CF-4C54-8FE9-18FCC4ED881A',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '208',
    shortname: 'ДАНИЯ',
    fullname: 'Королевство Дания',
    alfacode2: 'DK',
    alfacode3: 'DNK',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:54.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '8EE78579-AF1F-4E26-A4C4-47CFB99027F5',
    startdate: '2006-10-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '832',
    shortname: 'ДЖЕРСИ',
    fullname: 'ДЖЕРСИ',
    alfacode2: 'JE',
    alfacode3: 'JEY',
    clarification: '',
    lastchangetype: '3',
    changedate: '2006-10-01 00:00:00.0',
    introductiondate: '2006-10-01 00:00:00.0',
    lastChangeNum: '6',
    filedate: '2020-05-07 12:01:47.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '8A665884-537A-4C14-8F75-B6CBB1E9CE4E',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '262',
    shortname: 'ДЖИБУТИ',
    fullname: 'Республика Джибути',
    alfacode2: 'DJ',
    alfacode3: 'DJI',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:45.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '214BCA21-72E9-4777-8BB5-85A9BD9D25B0',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '212',
    shortname: 'ДОМИНИКА',
    fullname: 'Содружество Доминики',
    alfacode2: 'DM',
    alfacode3: 'DMA',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:36.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'D19B60B7-FB47-4DE0-A179-118B7D53047E',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '214',
    shortname: 'ДОМИНИКАНСКАЯ РЕСПУБЛИКА',
    fullname: 'ДОМИНИКАНСКАЯ РЕСПУБЛИКА',
    alfacode2: 'DO',
    alfacode3: 'DOM',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-09-18 09:56:59.0',
    loaddate: '2020-12-17 09:45:55.0',
  },
  {
    guid: '80029F06-7460-42CA-AD3B-EF16D1F890E9',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '818',
    shortname: 'ЕГИПЕТ',
    fullname: 'Арабская Республика Египет',
    alfacode2: 'EG',
    alfacode3: 'EGY',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:27.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '7F89BC23-D5EF-4EF3-9DA2-70599776722A',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '894',
    shortname: 'ЗАМБИЯ',
    fullname: 'Республика Замбия',
    alfacode2: 'ZM',
    alfacode3: 'ZMB',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:18.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'BD18F890-811B-4109-88F8-CBC9733675C9',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '732',
    shortname: 'ЗАПАДНАЯ САХАРА',
    fullname: 'ЗАПАДНАЯ САХАРА',
    alfacode2: 'EH',
    alfacode3: 'ESH',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:20.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '6E560381-159E-4460-AE90-AE294ECE344D',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '716',
    shortname: 'ЗИМБАБВЕ',
    fullname: 'Республика Зимбабве',
    alfacode2: 'ZW',
    alfacode3: 'ZWE',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:38.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '5A949C49-ABB4-45D0-AEA1-0C503C8E49FB',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '376',
    shortname: 'ИЗРАИЛЬ',
    fullname: 'Государство Израиль',
    alfacode2: 'IL',
    alfacode3: 'ISR',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:53.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '35FE1EE9-EDBD-480A-8A70-63A86BBC708A',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '356',
    shortname: 'ИНДИЯ',
    fullname: 'Республика Индия',
    alfacode2: 'IN',
    alfacode3: 'IND',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:55.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'A114EDB3-EF92-4D4A-A464-1CF8DDF99D25',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '360',
    shortname: 'ИНДОНЕЗИЯ',
    fullname: 'Республика Индонезия',
    alfacode2: 'ID',
    alfacode3: 'IDN',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:02.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '8B931E07-7E6E-4EF1-A62B-E8E02EC14A0F',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '400',
    shortname: 'ИОРДАНИЯ',
    fullname: 'Иорданское Хашимитское Королевство',
    alfacode2: 'JO',
    alfacode3: 'JOR',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:23.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '47253219-A748-4D5E-BE7C-F0805DF04A9B',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '368',
    shortname: 'ИРАК',
    fullname: 'Республика Ирак',
    alfacode2: 'IQ',
    alfacode3: 'IRQ',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:56.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '2AA12545-9CF3-4B88-99CF-3FCACC5D13DE',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '364',
    shortname: 'ИРАН, ИСЛАМСКАЯ РЕСПУБЛИКА',
    fullname: 'Исламская Республика Иран',
    alfacode2: 'IR',
    alfacode3: 'IRN',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:54.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'F07DE170-F7B6-469E-A122-527A1DC0FBEE',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '372',
    shortname: 'ИРЛАНДИЯ',
    fullname: 'ИРЛАНДИЯ',
    alfacode2: 'IE',
    alfacode3: 'IRL',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:31.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '72C45467-DF6F-46C2-BC46-2A5BF04CF06B',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '352',
    shortname: 'ИСЛАНДИЯ',
    fullname: 'Республика Исландия',
    alfacode2: 'IS',
    alfacode3: 'ISL',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2019-06-05 11:48:19.0',
    loaddate: '2019-06-07 22:42:26.0',
  },
  {
    guid: '662B64CA-6A41-43DA-8488-8684D3EFB95E',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '724',
    shortname: 'ИСПАНИЯ',
    fullname: 'Королевство Испания',
    alfacode2: 'ES',
    alfacode3: 'ESP',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:53.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '8A3900CD-5666-4429-83FA-B6BBAB6D2634',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '380',
    shortname: 'ИТАЛИЯ',
    fullname: 'Итальянская Республика',
    alfacode2: 'IT',
    alfacode3: 'ITA',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:23.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '78C306DF-481C-4730-9CFD-30457FC41681',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '887',
    shortname: 'ЙЕМЕН',
    fullname: 'Йеменская Республика',
    alfacode2: 'YE',
    alfacode3: 'YEM',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:26.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '5F202FAF-44A4-40AF-BF91-D31449E0B084',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '132',
    shortname: 'КАБО-ВЕРДЕ',
    fullname: 'Республика Кабо-Верде',
    alfacode2: 'CV',
    alfacode3: 'CPV',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:38.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'B05FA508-7B16-4410-BA46-4FB81A6B1D92',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '398',
    shortname: 'КАЗАХСТАН',
    fullname: 'Республика Казахстан',
    alfacode2: 'KZ',
    alfacode3: 'KAZ',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:00.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '6A8494C3-4578-4AB5-8B65-3F3EFA37F164',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '116',
    shortname: 'КАМБОДЖА',
    fullname: 'Королевство Камбоджа',
    alfacode2: 'KH',
    alfacode3: 'KHM',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:19.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '4ADDC74F-992B-4597-8023-D0BB26E8EA15',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '120',
    shortname: 'КАМЕРУН',
    fullname: 'Республика Камерун',
    alfacode2: 'CM',
    alfacode3: 'CMR',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:36.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '1F36D3F4-5BC9-41BC-9F34-52F31746B459',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '124',
    shortname: 'КАНАДА',
    fullname: 'КАНАДА',
    alfacode2: 'CA',
    alfacode3: 'CAN',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:55.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '43DD97C5-AA79-45E4-9A72-B59D0C30B27C',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '634',
    shortname: 'КАТАР',
    fullname: 'Государство Катар',
    alfacode2: 'QA',
    alfacode3: 'QAT',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:38.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'F9145291-A3F4-490F-B305-1AC9EE42CA74',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '404',
    shortname: 'КЕНИЯ',
    fullname: 'Республика Кения',
    alfacode2: 'KE',
    alfacode3: 'KEN',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:53.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'C8F5D45F-7811-4F4A-8443-F14192DB3E30',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '196',
    shortname: 'КИПР',
    fullname: 'Республика Кипр',
    alfacode2: 'CY',
    alfacode3: 'CYP',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:08.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '0DADC793-8EA4-46F8-84D1-1FBEE0E01EF4',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '417',
    shortname: 'КИРГИЗИЯ',
    fullname: 'Киргизская Республика',
    alfacode2: 'KG',
    alfacode3: 'KGZ',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:13.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '2ABC3A18-045E-41FF-AE20-500EC103A795',
    startdate: '2003-10-15 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '296',
    shortname: 'КИРИБАТИ',
    fullname: 'Республика Кирибати',
    alfacode2: 'KI',
    alfacode3: 'KIR',
    clarification: '',
    lastchangetype: '2',
    changedate: '2003-10-15 00:00:00.0',
    introductiondate: '2003-10-15 00:00:00.0',
    lastChangeNum: '1',
    filedate: '2020-05-07 12:01:53.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'C97A4BFD-A255-4C9D-9E19-B280AAD22A2C',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '156',
    shortname: 'КИТАЙ',
    fullname: 'Китайская Народная Республика',
    alfacode2: 'CN',
    alfacode3: 'CHN',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:53.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'BE985D4F-8E39-4312-90D9-C73C2711F8A2',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '166',
    shortname: 'КОКОСОВЫЕ (КИЛИНГ) ОСТРОВА',
    fullname: 'КОКОСОВЫЕ (КИЛИНГ) ОСТРОВА',
    alfacode2: 'CC',
    alfacode3: 'CCK',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:20.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'A0E6C3DE-BC94-42F1-A58B-45E5C01DC537',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '170',
    shortname: 'КОЛУМБИЯ',
    fullname: 'Республика Колумбия',
    alfacode2: 'CO',
    alfacode3: 'COL',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:21.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '1DFBE9CF-D97D-4518-B430-D12F0A57983C',
    startdate: '2004-01-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '174',
    shortname: 'КОМОРЫ',
    fullname: 'Союз Коморы',
    alfacode2: 'KM',
    alfacode3: 'COM',
    clarification: '',
    lastchangetype: '2',
    changedate: '2004-01-01 00:00:00.0',
    introductiondate: '2004-01-01 00:00:00.0',
    lastChangeNum: '2',
    filedate: '2020-05-07 12:02:15.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '611FA0CC-3373-41FD-95C7-D46D4E42DD63',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '178',
    shortname: 'КОНГО',
    fullname: 'Республика Конго',
    alfacode2: 'CG',
    alfacode3: 'COG',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:13.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'B4E66BF4-205C-45D8-B5B4-D97000D3E98D',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '180',
    shortname: 'КОНГО, ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
    fullname: 'Демократическая Республика Конго',
    alfacode2: 'CD',
    alfacode3: 'COD',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:07.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '754D144D-7AEF-493D-AADF-1B09C929E3D3',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '408',
    shortname: 'КОРЕЯ, НАРОДНО-ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
    fullname: 'Корейская Народно-Демократическая Республика',
    alfacode2: 'KP',
    alfacode3: 'PRK',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:04.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'E0971A84-4682-417D-88B7-18C33C9B6418',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '410',
    shortname: 'КОРЕЯ, РЕСПУБЛИКА',
    fullname: 'Республика Корея',
    alfacode2: 'KR',
    alfacode3: 'KOR',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:33.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'F3898E17-889C-4E2F-9647-5D9BACEA7667',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '188',
    shortname: 'КОСТА-РИКА',
    fullname: 'Республика Коста-Рика',
    alfacode2: 'CR',
    alfacode3: 'CRI',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:47.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'DA6966D1-12B6-40DD-99BA-4DE07127C73E',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '384',
    shortname: "КОТ Д'ИВУАР",
    fullname: "Республика Кот д'Ивуар",
    alfacode2: 'CI',
    alfacode3: 'CIV',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:08.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '4BEBA13A-214A-432D-AC04-C94C44321744',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '192',
    shortname: 'КУБА',
    fullname: 'Республика Куба',
    alfacode2: 'CU',
    alfacode3: 'CUB',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:27.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '00A3646F-F7BA-4CCA-88A4-530D2A89F2FF',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '414',
    shortname: 'КУВЕЙТ',
    fullname: 'Государство Кувейт',
    alfacode2: 'KW',
    alfacode3: 'KWT',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:25.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'B3BE561E-3B09-4852-B113-CF567DFF9F53',
    startdate: '2011-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '531',
    shortname: 'КЮРАСАО',
    fullname: 'КЮРАСАО',
    alfacode2: 'CW',
    alfacode3: 'CUW',
    clarification: '',
    lastchangetype: '3',
    changedate: '2011-07-01 00:00:00.0',
    introductiondate: '2011-07-01 00:00:00.0',
    lastChangeNum: '11',
    filedate: '2020-05-07 12:02:41.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'C60D3610-5013-4512-9AB9-79149E119BF1',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '418',
    shortname: 'ЛАОССКАЯ НАРОДНО-ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
    fullname: 'ЛАОССКАЯ НАРОДНО-ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
    alfacode2: 'LA',
    alfacode3: 'LAO',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:34.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'D44A23D4-193C-4971-8FA5-316A430901DF',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '428',
    shortname: 'ЛАТВИЯ',
    fullname: 'Латвийская Республика',
    alfacode2: 'LV',
    alfacode3: 'LVA',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:59.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '19D6D190-8920-41E4-B2F5-A8EDD4D7263A',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '426',
    shortname: 'ЛЕСОТО',
    fullname: 'Королевство Лесото',
    alfacode2: 'LS',
    alfacode3: 'LSO',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:03.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'FEB053D3-9463-4E97-A78F-71AA078721DE',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '430',
    shortname: 'ЛИБЕРИЯ',
    fullname: 'Республика Либерия',
    alfacode2: 'LR',
    alfacode3: 'LBR',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:18.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '79975CCE-914E-466B-9130-8AA80614D610',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '422',
    shortname: 'ЛИВАН',
    fullname: 'Ливанская Республика',
    alfacode2: 'LB',
    alfacode3: 'LBN',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:55.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '665E700F-32F7-40D8-A4BE-C490DF99357B',
    startdate: '2012-03-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '434',
    shortname: 'ЛИВИЯ',
    fullname: 'Ливия',
    alfacode2: 'LY',
    alfacode3: 'LBY',
    clarification: '',
    lastchangetype: '2',
    changedate: '2012-03-01 00:00:00.0',
    introductiondate: '2012-03-01 00:00:00.0',
    lastChangeNum: '14',
    filedate: '2020-05-07 12:02:41.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'BC02D0F0-C174-4E6D-AA0B-ED5C2C5307C4',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '440',
    shortname: 'ЛИТВА',
    fullname: 'Литовская Республика',
    alfacode2: 'LT',
    alfacode3: 'LTU',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:36.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'A8294808-897E-461E-ABDA-0912669DE488',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '438',
    shortname: 'ЛИХТЕНШТЕЙН',
    fullname: 'Княжество Лихтенштейн',
    alfacode2: 'LI',
    alfacode3: 'LIE',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:52.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'EADE3E13-49A2-404F-A7B3-2D6BE8650E23',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '442',
    shortname: 'ЛЮКСЕМБУРГ',
    fullname: 'Великое Герцогство Люксембург',
    alfacode2: 'LU',
    alfacode3: 'LUX',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:17.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '5309EEC0-4C0D-42A7-8E9B-7F5182CB7980',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '480',
    shortname: 'МАВРИКИЙ',
    fullname: 'Республика Маврикий',
    alfacode2: 'MU',
    alfacode3: 'MUS',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:12.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'B8D56A6A-10FE-464A-A9C9-61753FBF6B91',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '478',
    shortname: 'МАВРИТАНИЯ',
    fullname: 'Исламская Республика Мавритания',
    alfacode2: 'MR',
    alfacode3: 'MRT',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:42.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'A62E8458-26C7-47FF-B8BB-3DEB91698E62',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '450',
    shortname: 'МАДАГАСКАР',
    fullname: 'Республика Мадагаскар',
    alfacode2: 'MG',
    alfacode3: 'MDG',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:50.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '22F5AC17-0E43-404F-8F0E-AE766211C37E',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '175',
    shortname: 'МАЙОТТА',
    fullname: 'МАЙОТТА',
    alfacode2: 'YT',
    alfacode3: 'MYT',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:58.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '16F680F9-5053-46D2-95F6-FD1C85F1FA58',
    startdate: '2003-10-15 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '446',
    shortname: 'МАКАО',
    fullname: 'Специальный административный регион Китая Макао',
    alfacode2: 'MO',
    alfacode3: 'MAC',
    clarification: '',
    lastchangetype: '2',
    changedate: '2003-10-15 00:00:00.0',
    introductiondate: '2003-10-15 00:00:00.0',
    lastChangeNum: '1',
    filedate: '2020-05-07 12:01:18.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'DE75B55C-2099-45B9-9D4F-3F8557BBF3E3',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '454',
    shortname: 'МАЛАВИ',
    fullname: 'Республика Малави',
    alfacode2: 'MW',
    alfacode3: 'MWI',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-09-18 09:56:59.0',
    loaddate: '2020-12-17 09:45:55.0',
  },
  {
    guid: '22599B6C-3605-4B19-9170-A51AB6DD6E7B',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '458',
    shortname: 'МАЛАЙЗИЯ',
    fullname: 'МАЛАЙЗИЯ',
    alfacode2: 'MY',
    alfacode3: 'MYS',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:43.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'DD673A00-0EDF-4B88-B377-31D0839718F6',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '466',
    shortname: 'МАЛИ',
    fullname: 'Республика Мали',
    alfacode2: 'ML',
    alfacode3: 'MLI',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:37.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'A8BD7416-EAB2-4486-A541-6E5935EADCBB',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '581',
    shortname: 'МАЛЫЕ ТИХООКЕАНСКИЕ ОТДАЛЕННЫЕ ОСТРОВА СОЕДИНЕННЫХ ШТАТОВ',
    fullname: 'МАЛЫЕ ТИХООКЕАНСКИЕ ОТДАЛЕННЫЕ ОСТРОВА СОЕДИНЕННЫХ ШТАТОВ',
    alfacode2: 'UM',
    alfacode3: 'UMI',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:32.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'FEDF3F08-2821-4778-BCC8-CD16B813BADF',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '462',
    shortname: 'МАЛЬДИВЫ',
    fullname: 'Мальдивская Республика',
    alfacode2: 'MV',
    alfacode3: 'MDV',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:57.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'EA5E6252-E288-41EF-BA02-F808226568B6',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '470',
    shortname: 'МАЛЬТА',
    fullname: 'Республика Мальта',
    alfacode2: 'MT',
    alfacode3: 'MLT',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:22.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '0624593D-248B-4C1F-9BD1-2C715159E51F',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '504',
    shortname: 'МАРОККО',
    fullname: 'Королевство Марокко',
    alfacode2: 'MA',
    alfacode3: 'MAR',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:59.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '54C41797-8BF9-40BC-96E3-522492D5B4CE',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '474',
    shortname: 'МАРТИНИКА',
    fullname: 'МАРТИНИКА',
    alfacode2: 'MQ',
    alfacode3: 'MTQ',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:02.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '259185BB-0149-49B2-993C-F9E5F15EC8FF',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '584',
    shortname: 'МАРШАЛЛОВЫ ОСТРОВА',
    fullname: 'Республика Маршалловы Острова',
    alfacode2: 'MH',
    alfacode3: 'MHL',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:26.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '16EA8E48-124C-436E-801E-FA99E9CC5254',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '484',
    shortname: 'МЕКСИКА',
    fullname: 'Мексиканские Соединенные Штаты',
    alfacode2: 'MX',
    alfacode3: 'MEX',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:06.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '2F1020E4-E1F6-4049-9733-9EEC5543AAAB',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '583',
    shortname: 'МИКРОНЕЗИЯ, ФЕДЕРАТИВНЫЕ ШТАТЫ',
    fullname: 'Федеративные Штаты Микронезии',
    alfacode2: 'FM',
    alfacode3: 'FSM',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:04.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '8DD90AB4-3162-49B9-8D82-4A1082F0D9EA',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '498',
    shortname: 'МОЛДОВА, РЕСПУБЛИКА',
    fullname: 'Республика Молдова',
    alfacode2: 'MD',
    alfacode3: 'MDA',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:00.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '43A84F44-4795-4E9A-B1C8-D659FC26D737',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '492',
    shortname: 'МОНАКО',
    fullname: 'Княжество Монако',
    alfacode2: 'MC',
    alfacode3: 'MCO',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:40.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '24C0A3BD-2327-4161-B9C4-712C13ABE001',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '496',
    shortname: 'МОНГОЛИЯ',
    fullname: 'МОНГОЛИЯ',
    alfacode2: 'MN',
    alfacode3: 'MNG',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:45.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '6D027347-4BA0-4472-8F48-E5F4746B2899',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '500',
    shortname: 'МОНТСЕРРАТ',
    fullname: 'МОНТСЕРРАТ',
    alfacode2: 'MS',
    alfacode3: 'MSR',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:05.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'C917A1E8-1030-4860-9822-2E75F0DD3111',
    startdate: '2012-03-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '104',
    shortname: 'МЬЯНМА',
    fullname: 'Республика Союза Мьянма',
    alfacode2: 'MM',
    alfacode3: 'MMR',
    clarification: '',
    lastchangetype: '2',
    changedate: '2012-03-01 00:00:00.0',
    introductiondate: '2012-03-01 00:00:00.0',
    lastChangeNum: '12',
    filedate: '2020-05-07 12:02:01.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '8C4DD72E-8D86-44E2-AF43-E3520F8212AA',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '516',
    shortname: 'НАМИБИЯ',
    fullname: 'Республика Намибия',
    alfacode2: 'NA',
    alfacode3: 'NAM',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:49.0',
    loaddate: '2020-07-09 14:23:39.0',
  },
  {
    guid: '73CE0F0E-4426-492C-837B-F100791B115A',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '520',
    shortname: 'НАУРУ',
    fullname: 'Республика Науру',
    alfacode2: 'NR',
    alfacode3: 'NRU',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:58.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '2BC7AE1A-FBC4-44E8-A247-76D02AE76140',
    startdate: '2009-11-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '524',
    shortname: 'НЕПАЛ',
    fullname: 'Федеративная Демократическая Республика Непал',
    alfacode2: 'NP',
    alfacode3: 'NPL',
    clarification: '',
    lastchangetype: '2',
    changedate: '2009-11-01 00:00:00.0',
    introductiondate: '2009-11-01 00:00:00.0',
    lastChangeNum: '9',
    filedate: '2020-05-07 12:01:47.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'B1DC57BA-ADF7-4FB5-AD6D-641D1E1A7C30',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '562',
    shortname: 'НИГЕР',
    fullname: 'Республика Нигер',
    alfacode2: 'NE',
    alfacode3: 'NER',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:38.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'F820487A-019E-4C01-AC29-F2FBC354B2B4',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '566',
    shortname: 'НИГЕРИЯ',
    fullname: 'Федеративная Республика Нигерия',
    alfacode2: 'NG',
    alfacode3: 'NGA',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:54.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '007A4DD3-4B57-477C-BF62-C5C3E258AF96',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '528',
    shortname: 'НИДЕРЛАНДЫ',
    fullname: 'Королевство Нидерландов',
    alfacode2: 'NL',
    alfacode3: 'NLD',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:27.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '0E12091C-91BE-43CE-85EB-EF9A3EF041E3',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '558',
    shortname: 'НИКАРАГУА',
    fullname: 'Республика Никарагуа',
    alfacode2: 'NI',
    alfacode3: 'NIC',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:45.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '2E7DF8EC-7733-497B-95D4-A8B8FE3D2192',
    startdate: '2012-03-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '570',
    shortname: 'НИУЭ',
    fullname: 'Ниуэ',
    alfacode2: 'NU',
    alfacode3: 'NIU',
    clarification: '',
    lastchangetype: '2',
    changedate: '2012-03-01 00:00:00.0',
    introductiondate: '2012-03-01 00:00:00.0',
    lastChangeNum: '12',
    filedate: '2020-05-07 12:02:18.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '97F140DD-1D37-4033-8241-DA50FDD23216',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '554',
    shortname: 'НОВАЯ ЗЕЛАНДИЯ',
    fullname: 'НОВАЯ ЗЕЛАНДИЯ',
    alfacode2: 'NZ',
    alfacode3: 'NZL',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:44.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'DD73A2EA-98E2-4B74-8A46-B98CCBDACE97',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '540',
    shortname: 'НОВАЯ КАЛЕДОНИЯ',
    fullname: 'НОВАЯ КАЛЕДОНИЯ',
    alfacode2: 'NC',
    alfacode3: 'NCL',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:52.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '0902D5D5-A389-4E32-BB73-A96637F009C1',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '578',
    shortname: 'НОРВЕГИЯ',
    fullname: 'Королевство Норвегия',
    alfacode2: 'NO',
    alfacode3: 'NOR',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:48.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '2C2EE648-BE2C-41B8-B53D-CE48BDFEEF6E',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '784',
    shortname: 'ОБЪЕДИНЕННЫЕ АРАБСКИЕ ЭМИРАТЫ',
    fullname: 'ОБЪЕДИНЕННЫЕ АРАБСКИЕ ЭМИРАТЫ',
    alfacode2: 'AE',
    alfacode3: 'ARE',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:38.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '611C631E-0D49-4C8D-B227-9CB7DA1E6405',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '512',
    shortname: 'ОМАН',
    fullname: 'Султанат Оман',
    alfacode2: 'OM',
    alfacode3: 'OMN',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:01.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '46EFC3B5-F90D-408E-BCB7-37471E884CB0',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '074',
    shortname: 'ОСТРОВ БУВЕ',
    fullname: 'ОСТРОВ БУВЕ',
    alfacode2: 'BV',
    alfacode3: 'BVT',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:03.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '009770E5-3FF5-4FBB-B1CF-93671CE7F5F8',
    startdate: '2006-10-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '833',
    shortname: 'ОСТРОВ МЭН',
    fullname: 'ОСТРОВ МЭН',
    alfacode2: 'IM',
    alfacode3: 'IMN',
    clarification: '',
    lastchangetype: '3',
    changedate: '2006-10-01 00:00:00.0',
    introductiondate: '2006-10-01 00:00:00.0',
    lastChangeNum: '6',
    filedate: '2020-05-07 12:01:58.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '2A403CD4-074B-48D7-9BAA-D2597FA12716',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '574',
    shortname: 'ОСТРОВ НОРФОЛК',
    fullname: 'ОСТРОВ НОРФОЛК',
    alfacode2: 'NF',
    alfacode3: 'NFK',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:38.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '5B448F48-0283-4111-8EFA-2DB1A16F91B0',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '162',
    shortname: 'ОСТРОВ РОЖДЕСТВА',
    fullname: 'ОСТРОВ РОЖДЕСТВА',
    alfacode2: 'CX',
    alfacode3: 'CXR',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:13.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'AB56CE44-68E8-4D47-98BE-0FC393E751B8',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '334',
    shortname: 'ОСТРОВ ХЕРД И ОСТРОВА МАКДОНАЛЬД',
    fullname: 'ОСТРОВ ХЕРД И ОСТРОВА МАКДОНАЛЬД',
    alfacode2: 'HM',
    alfacode3: 'HMD',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:59.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '8237CE4F-C597-4C3B-8D29-A6E43491A05B',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '136',
    shortname: 'ОСТРОВА КАЙМАН',
    fullname: 'ОСТРОВА КАЙМАН',
    alfacode2: 'KY',
    alfacode3: 'CYM',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:37.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '859617A2-022C-4AB4-9FAC-C6AB1CC8B62D',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '184',
    shortname: 'ОСТРОВА КУКА',
    fullname: 'ОСТРОВА КУКА',
    alfacode2: 'CK',
    alfacode3: 'COK',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:18.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '5F6FF4E8-3D74-4AD8-8E25-858C54C57CC1',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '796',
    shortname: 'ОСТРОВА ТЕРКС И КАЙКОС',
    fullname: 'ОСТРОВА ТЕРКС И КАЙКОС',
    alfacode2: 'TC',
    alfacode3: 'TCA',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:26.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '8C130B2C-69CE-4083-B6FC-0F1BF955C6F5',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '586',
    shortname: 'ПАКИСТАН',
    fullname: 'Исламская Республика Пакистан',
    alfacode2: 'PK',
    alfacode3: 'PAK',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:52.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'AF24E9DD-4612-4CD5-BAB5-9EFEC9279EF8',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '585',
    shortname: 'ПАЛАУ',
    fullname: 'Республика Палау',
    alfacode2: 'PW',
    alfacode3: 'PLW',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:15.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '2C2EF378-CBF9-4AB3-990B-F65C4E7D34B2',
    startdate: '2013-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '275',
    shortname: 'ПАЛЕСТИНА, ГОСУДАРСТВО',
    fullname: 'Государство Палестина',
    alfacode2: 'PS',
    alfacode3: 'PSE',
    clarification: '',
    lastchangetype: '2',
    changedate: '2013-07-01 00:00:00.0',
    introductiondate: '2013-07-01 00:00:00.0',
    lastChangeNum: '17',
    filedate: '2020-05-07 12:01:32.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'EF918055-D41A-4F9B-BEB3-2487436DDF50',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '591',
    shortname: 'ПАНАМА',
    fullname: 'Республика Панама',
    alfacode2: 'PA',
    alfacode3: 'PAN',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:54.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '5CC377A1-FBF6-4C84-9C32-4F5B72969D07',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '336',
    shortname: 'ПАПСКИЙ ПРЕСТОЛ (ГОСУДАРСТВО-ГОРОД ВАТИКАН)',
    fullname: 'ПАПСКИЙ ПРЕСТОЛ (ГОСУДАРСТВО-ГОРОД ВАТИКАН)',
    alfacode2: 'VA',
    alfacode3: 'VAT',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:52.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '9DA319BD-5908-4737-B4D6-611163546FB1',
    startdate: '2013-09-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '598',
    shortname: 'ПАПУА НОВАЯ ГВИНЕЯ',
    fullname: 'Независимое Государство Папуа Новая Гвинея',
    alfacode2: 'PG',
    alfacode3: 'PNG',
    clarification: '',
    lastchangetype: '2',
    changedate: '2013-09-01 00:00:00.0',
    introductiondate: '2013-09-01 00:00:00.0',
    lastChangeNum: '18',
    filedate: '2020-05-07 12:01:58.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '4E521802-AB68-4039-9420-4ADBB82DC043',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '600',
    shortname: 'ПАРАГВАЙ',
    fullname: 'Республика Парагвай',
    alfacode2: 'PY',
    alfacode3: 'PRY',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2019-06-05 11:48:48.0',
    loaddate: '2019-06-07 22:42:26.0',
  },
  {
    guid: '1FFC4C3F-F20C-4B84-9019-E2C435767CBB',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '604',
    shortname: 'ПЕРУ',
    fullname: 'Республика Перу',
    alfacode2: 'PE',
    alfacode3: 'PER',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:27.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '868FB52B-1CCF-49AF-95A2-58562F319B64',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '612',
    shortname: 'ПИТКЕРН',
    fullname: 'ПИТКЕРН',
    alfacode2: 'PN',
    alfacode3: 'PCN',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:14.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'E02E8C34-8F53-4AB2-9298-A947FDB63394',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '616',
    shortname: 'ПОЛЬША',
    fullname: 'Республика Польша',
    alfacode2: 'PL',
    alfacode3: 'POL',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:18.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '3CEA5E31-EE74-4AE3-9380-40A4C0F66F26',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '620',
    shortname: 'ПОРТУГАЛИЯ',
    fullname: 'Португальская Республика',
    alfacode2: 'PT',
    alfacode3: 'PRT',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:53.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '5FBACE25-4C08-493F-B656-FF06469DA028',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '630',
    shortname: 'ПУЭРТО-РИКО',
    fullname: 'ПУЭРТО-РИКО',
    alfacode2: 'PR',
    alfacode3: 'PRI',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:47.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '345CF9A4-BEF6-42D3-9F5E-FD76D3A36DF4',
    startdate: '2006-01-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '807',
    shortname: 'РЕСПУБЛИКА МАКЕДОНИЯ',
    fullname: 'РЕСПУБЛИКА МАКЕДОНИЯ',
    alfacode2: 'MK',
    alfacode3: 'MKD',
    clarification:
      'Примечание: принятые в Организации Объединенных Наций - ООН и Международной организации по стандартизации - ИСО (международный стандарт ИСО 3166) краткое и полное наименования этой страны - "Македония, бывшая Югославская Республика" и "Бывшая Югослав,и органами ООН, с секретариатом и комитетами ИСО, с другими международными организациями и странами, не принявшими конституционного наименования данной страны - "Республика Македония",ская Республика Македония" используются органами государственной власти, органами местного самоуправления, организациями, индивидуальными предпринимателями и физическими лицами Российской Федерации только во взаимоотношениях с организациями и рабочим',
    lastchangetype: '2',
    changedate: '2006-01-01 00:00:00.0',
    introductiondate: '2006-01-01 00:00:00.0',
    lastChangeNum: '5',
    filedate: '2020-05-07 12:02:05.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'EAE4575D-12A3-490E-8E72-673F5F4CE424',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '638',
    shortname: 'РЕЮНЬОН',
    fullname: 'РЕЮНЬОН',
    alfacode2: 'RE',
    alfacode3: 'REU',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:45.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '45A4BE0D-4C6F-438A-8F0A-DE6C350DC02C',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '643',
    shortname: 'РОССИЯ',
    fullname: 'Российская Федерация',
    alfacode2: 'RU',
    alfacode3: 'RUS',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:47.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'D0DE3F8F-4207-4809-80B1-2FCCB3182DD9',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '646',
    shortname: 'РУАНДА',
    fullname: 'Руандийская Республика',
    alfacode2: 'RW',
    alfacode3: 'RWA',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:50.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '3F514636-424F-466C-9CBA-F85B01A6ADFC',
    startdate: '2003-10-15 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '642',
    shortname: 'РУМЫНИЯ',
    fullname: 'РУМЫНИЯ',
    alfacode2: 'RO',
    alfacode3: 'ROU',
    clarification: '',
    lastchangetype: '2',
    changedate: '2003-10-15 00:00:00.0',
    introductiondate: '2003-10-15 00:00:00.0',
    lastChangeNum: '1',
    filedate: '2020-05-07 12:02:12.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'CBABAAE8-9EC4-4182-954C-A58CFF5B9459',
    startdate: '2003-10-15 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '882',
    shortname: 'САМОА',
    fullname: 'Независимое Государство Самоа',
    alfacode2: 'WS',
    alfacode3: 'WSM',
    clarification: '',
    lastchangetype: '2',
    changedate: '2003-10-15 00:00:00.0',
    introductiondate: '2003-10-15 00:00:00.0',
    lastChangeNum: '1',
    filedate: '2020-05-07 12:01:41.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '32D74A24-DA6B-4F1F-B881-E9ABECA6677B',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '674',
    shortname: 'САН-МАРИНО',
    fullname: 'Республика Сан-Марино',
    alfacode2: 'SM',
    alfacode3: 'SMR',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:19.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '7598061E-4E18-4283-ADC6-93D8AFDEB1E7',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '678',
    shortname: 'САН-ТОМЕ И ПРИНСИПИ',
    fullname: 'Демократическая Республика Сан-Томе и Принсипи',
    alfacode2: 'ST',
    alfacode3: 'STP',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:23.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'BD2206F4-7613-493B-AC89-1BCAF419BCD4',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '682',
    shortname: 'САУДОВСКАЯ АРАВИЯ',
    fullname: 'Королевство Саудовская Аравия',
    alfacode2: 'SA',
    alfacode3: 'SAU',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:17.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '016485CE-F0B7-47C0-A439-14B44F839530',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '748',
    shortname: 'СВАЗИЛЕНД',
    fullname: 'Королевство Свазиленд',
    alfacode2: 'SZ',
    alfacode3: 'SWZ',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:46.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'E8C2BFA2-A5A8-4613-BB36-6BE4BCA9B8F7',
    startdate: '2010-09-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '654',
    shortname: 'СВЯТАЯ ЕЛЕНА, ОСТРОВ ВОЗНЕСЕНИЯ, ТРИСТАН-ДА-КУНЬЯ',
    fullname: 'СВЯТАЯ ЕЛЕНА, ОСТРОВ ВОЗНЕСЕНИЯ, ТРИСТАН-ДА-КУНЬЯ',
    alfacode2: 'SH',
    alfacode3: 'SHN',
    clarification: '',
    lastchangetype: '2',
    changedate: '2010-09-01 00:00:00.0',
    introductiondate: '2010-09-01 00:00:00.0',
    lastChangeNum: '10',
    filedate: '2020-05-07 12:02:22.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'BC3CD0F4-2D70-4506-B1D5-35AB16702C6B',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '580',
    shortname: 'СЕВЕРНЫЕ МАРИАНСКИЕ ОСТРОВА',
    fullname: 'Содружество Северных Марианских островов',
    alfacode2: 'MP',
    alfacode3: 'MNP',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:01.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '4FE9C3BE-4153-45BF-9682-5B6853787AF3',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '690',
    shortname: 'СЕЙШЕЛЫ',
    fullname: 'Республика Сейшелы',
    alfacode2: 'SC',
    alfacode3: 'SYC',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:49.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '1295AFB6-9AC0-4798-BFE4-3EB0A5A3DCB4',
    startdate: '2009-11-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '652',
    shortname: 'СЕН-БАРТЕЛЕМИ',
    fullname: 'СЕН-БАРТЕЛЕМИ',
    alfacode2: 'BL',
    alfacode3: 'BLM',
    clarification: '',
    lastchangetype: '3',
    changedate: '2009-11-01 00:00:00.0',
    introductiondate: '2009-11-01 00:00:00.0',
    lastChangeNum: '9',
    filedate: '2020-05-07 12:01:56.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '4949D730-E7BA-4CA5-B5F0-C4B134F9C05B',
    startdate: '2009-11-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '663',
    shortname: 'СЕН-МАРТЕН',
    fullname: 'СЕН-МАРТЕН',
    alfacode2: 'MF',
    alfacode3: 'MAF',
    clarification: '',
    lastchangetype: '3',
    changedate: '2009-11-01 00:00:00.0',
    introductiondate: '2009-11-01 00:00:00.0',
    lastChangeNum: '9',
    filedate: '2020-05-07 12:01:19.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '97405AD0-386D-4C94-BA5D-A849D5491ACF',
    startdate: '2011-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '534',
    shortname: 'СЕН-МАРТЕН (нидерландская часть)',
    fullname: 'СЕН-МАРТЕН (нидерландская часть)',
    alfacode2: 'SX',
    alfacode3: 'SXM',
    clarification: '',
    lastchangetype: '3',
    changedate: '2011-07-01 00:00:00.0',
    introductiondate: '2011-07-01 00:00:00.0',
    lastChangeNum: '11',
    filedate: '2020-05-07 12:02:15.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'E5710FD0-AFFF-4C2E-A111-CAEB18B24F32',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '686',
    shortname: 'СЕНЕГАЛ',
    fullname: 'Республика Сенегал',
    alfacode2: 'SN',
    alfacode3: 'SEN',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:17.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '73E807D8-5FEE-4041-AF87-2DDD70580D13',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '670',
    shortname: 'СЕНТ-ВИНСЕНТ И ГРЕНАДИНЫ',
    fullname: 'СЕНТ-ВИНСЕНТ И ГРЕНАДИНЫ',
    alfacode2: 'VC',
    alfacode3: 'VCT',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:22.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '00428D9F-5649-41E8-B69D-8476FC04E07E',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '659',
    shortname: 'СЕНТ-КИТС И НЕВИС',
    fullname: 'СЕНТ-КИТС И НЕВИС',
    alfacode2: 'KN',
    alfacode3: 'KNA',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:28.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'CA129C83-F6B9-4B80-B0F0-F2F17D0D14C7',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '662',
    shortname: 'СЕНТ-ЛЮСИЯ',
    fullname: 'СЕНТ-ЛЮСИЯ',
    alfacode2: 'LC',
    alfacode3: 'LCA',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:30.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '30A4908E-969B-449C-85DF-36FCCB54CB62',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '666',
    shortname: 'СЕНТ-ПЬЕР И МИКЕЛОН',
    fullname: 'СЕНТ-ПЬЕР И МИКЕЛОН',
    alfacode2: 'PM',
    alfacode3: 'SPM',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:36.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '1FADE5E6-A28F-4B91-888C-EE2DC50314CE',
    startdate: '2007-02-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '688',
    shortname: 'СЕРБИЯ',
    fullname: 'Республика Сербия',
    alfacode2: 'RS',
    alfacode3: 'SRB',
    clarification: '',
    lastchangetype: '3',
    changedate: '2007-02-01 00:00:00.0',
    introductiondate: '2007-02-01 00:00:00.0',
    lastChangeNum: '7',
    filedate: '2020-05-07 12:02:31.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'CB433FBB-32B0-469E-BCFF-F53043274370',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '702',
    shortname: 'СИНГАПУР',
    fullname: 'Республика Сингапур',
    alfacode2: 'SG',
    alfacode3: 'SGP',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:26.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'BBC2371C-9057-45A0-B236-52747BDE0D4D',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '760',
    shortname: 'СИРИЙСКАЯ АРАБСКАЯ РЕСПУБЛИКА',
    fullname: 'СИРИЙСКАЯ АРАБСКАЯ РЕСПУБЛИКА',
    alfacode2: 'SY',
    alfacode3: 'SYR',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:26.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '684F82CB-154E-483F-B95C-28E7B8ABA635',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '703',
    shortname: 'СЛОВАКИЯ',
    fullname: 'Словацкая Республика',
    alfacode2: 'SK',
    alfacode3: 'SVK',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:09.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'B47FF95A-F021-4FD3-895A-1F8770E0C324',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '705',
    shortname: 'СЛОВЕНИЯ',
    fullname: 'Республика Словения',
    alfacode2: 'SI',
    alfacode3: 'SVN',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:17.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '014EB644-2C47-45CA-ADCB-C7990E03BC71',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '826',
    shortname: 'СОЕДИНЕННОЕ КОРОЛЕВСТВО',
    fullname: 'Соединенное Королевство Великобритании и Северной Ирландии',
    alfacode2: 'GB',
    alfacode3: 'GBR',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2019-06-05 11:48:43.0',
    loaddate: '2019-06-07 22:42:26.0',
  },
  {
    guid: '6DE7DFFF-441E-4F45-A3DE-F1724F52EE8A',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '840',
    shortname: 'СОЕДИНЕННЫЕ ШТАТЫ',
    fullname: 'Соединенные Штаты Америки',
    alfacode2: 'US',
    alfacode3: 'USA',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:37.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'D5F8DE7A-A1F7-47B9-8706-D8F91BA023F2',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '090',
    shortname: 'СОЛОМОНОВЫ ОСТРОВА',
    fullname: 'СОЛОМОНОВЫ ОСТРОВА',
    alfacode2: 'SB',
    alfacode3: 'SLB',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:39.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '93B92948-6BF9-4262-938E-17C1A5A12FCF',
    startdate: '2014-01-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '706',
    shortname: 'СОМАЛИ',
    fullname: 'Федеративная Республика Сомали',
    alfacode2: 'SO',
    alfacode3: 'SOM',
    clarification: '',
    lastchangetype: '2',
    changedate: '2014-01-01 00:00:00.0',
    introductiondate: '2014-01-01 00:00:00.0',
    lastChangeNum: '19',
    filedate: '2020-05-07 12:01:37.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '59859E7D-593A-4368-B9F9-B6F17C68DAF2',
    startdate: '2012-03-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '729',
    shortname: 'СУДАН',
    fullname: 'Республика Судан',
    alfacode2: 'SD',
    alfacode3: 'SDN',
    clarification: '',
    lastchangetype: '3',
    changedate: '2012-03-01 00:00:00.0',
    introductiondate: '2012-03-01 00:00:00.0',
    lastChangeNum: '13',
    filedate: '2020-05-07 12:01:48.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'DFB2423A-D1FA-4B50-9758-E18DD1F8C901',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '740',
    shortname: 'СУРИНАМ',
    fullname: 'Республика Суринам',
    alfacode2: 'SR',
    alfacode3: 'SUR',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:29.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'D9F73EEF-7479-4E66-90AB-49FA468359EA',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '694',
    shortname: 'СЬЕРРА-ЛЕОНЕ',
    fullname: 'Республика Сьерра-Леоне',
    alfacode2: 'SL',
    alfacode3: 'SLE',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:20.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '44F37952-C818-474E-8848-B0B3605B9DFE',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '762',
    shortname: 'ТАДЖИКИСТАН',
    fullname: 'Республика Таджикистан',
    alfacode2: 'TJ',
    alfacode3: 'TJK',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:19.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '3F5FCD38-865F-494B-8DC2-3452490C7D7B',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '764',
    shortname: 'ТАИЛАНД',
    fullname: 'Королевство Таиланд',
    alfacode2: 'TH',
    alfacode3: 'THA',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:24.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'B0EE1CBE-DDA2-4EFC-8E86-8B0D9325CEB6',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '158',
    shortname: 'ТАЙВАНЬ (КИТАЙ)',
    fullname: 'ТАЙВАНЬ (КИТАЙ)',
    alfacode2: 'TW',
    alfacode3: 'TWN',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:51.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'B8F6F8DB-8896-443D-AB37-9ED90EBF9BFF',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '834',
    shortname: 'ТАНЗАНИЯ, ОБЪЕДИНЕННАЯ РЕСПУБЛИКА',
    fullname: 'Объединенная Республика Танзания',
    alfacode2: 'TZ',
    alfacode3: 'TZA',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:18.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'A985AB83-061E-4645-A2C4-5685E015249B',
    startdate: '2003-10-15 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '626',
    shortname: 'ТИМОР-ЛЕСТЕ',
    fullname: 'Демократическая Республика Тимор-Лесте',
    alfacode2: 'TL',
    alfacode3: 'TLS',
    clarification: '',
    lastchangetype: '2',
    changedate: '2003-10-15 00:00:00.0',
    introductiondate: '2003-10-15 00:00:00.0',
    lastChangeNum: '1',
    filedate: '2020-05-07 12:01:42.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'E9016B43-2422-4BCC-947A-8FA6623500E1',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '768',
    shortname: 'ТОГО',
    fullname: 'Тоголезская Республика',
    alfacode2: 'TG',
    alfacode3: 'TGO',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:19.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '0292FCF9-41CF-4953-81F4-AAC7D396FFCA',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '772',
    shortname: 'ТОКЕЛАУ',
    fullname: 'ТОКЕЛАУ',
    alfacode2: 'TK',
    alfacode3: 'TKL',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:21.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '7CB3A799-7B4A-479A-B90E-85555E610EC8',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '776',
    shortname: 'ТОНГА',
    fullname: 'Королевство Тонга',
    alfacode2: 'TO',
    alfacode3: 'TON',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:21.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '011C677B-1EC9-46AA-BA9A-C055C3AD5400',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '780',
    shortname: 'ТРИНИДАД И ТОБАГО',
    fullname: 'Республика Тринидад и Тобаго',
    alfacode2: 'TT',
    alfacode3: 'TTO',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:08.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '801D545D-B297-4199-84B0-828FAFAE021B',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '798',
    shortname: 'ТУВАЛУ',
    fullname: 'ТУВАЛУ',
    alfacode2: 'TV',
    alfacode3: 'TUV',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:40.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '2F7DD276-619D-4BE3-92CA-B7BAA7291F01',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '788',
    shortname: 'ТУНИС',
    fullname: 'Тунисская Республика',
    alfacode2: 'TN',
    alfacode3: 'TUN',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:18.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'F4361DB3-8B29-4363-B9C1-57D589D193EF',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '795',
    shortname: 'ТУРКМЕНИЯ',
    fullname: 'Туркменистан',
    alfacode2: 'TM',
    alfacode3: 'TKM',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:39.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '21186099-ACFB-46C8-B455-C5FB1AD56F79',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '792',
    shortname: 'ТУРЦИЯ',
    fullname: 'Турецкая Республика',
    alfacode2: 'TR',
    alfacode3: 'TUR',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:02.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '12BE18B1-54DA-4FDD-B0B7-7110239F33FA',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '800',
    shortname: 'УГАНДА',
    fullname: 'Республика Уганда',
    alfacode2: 'UG',
    alfacode3: 'UGA',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:14.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'D69B0E7B-A4B0-4EC0-82B5-310DA9C00147',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '860',
    shortname: 'УЗБЕКИСТАН',
    fullname: 'Республика Узбекистан',
    alfacode2: 'UZ',
    alfacode3: 'UZB',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:52.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'C55F7411-E0EF-4A42-9068-85138762C667',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '804',
    shortname: 'УКРАИНА',
    fullname: 'УКРАИНА',
    alfacode2: 'UA',
    alfacode3: 'UKR',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:19.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '59029C7D-1FA7-4E48-B73B-A7238806A3F7',
    startdate: '2003-10-15 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '876',
    shortname: 'УОЛЛИС И ФУТУНА',
    fullname: 'УОЛЛИС И ФУТУНА',
    alfacode2: 'WF',
    alfacode3: 'WLF',
    clarification: '',
    lastchangetype: '2',
    changedate: '2003-10-15 00:00:00.0',
    introductiondate: '2003-10-15 00:00:00.0',
    lastChangeNum: '1',
    filedate: '2020-05-07 12:02:14.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '8A313570-4862-457D-B4E0-A3D95731FF40',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '858',
    shortname: 'УРУГВАЙ',
    fullname: 'Восточная Республика Уругвай',
    alfacode2: 'UY',
    alfacode3: 'URY',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:18.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '1DF4BB30-C901-46B2-B2F6-BD62118968CD',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '234',
    shortname: 'ФАРЕРСКИЕ ОСТРОВА',
    fullname: 'ФАРЕРСКИЕ ОСТРОВА',
    alfacode2: 'FO',
    alfacode3: 'FRO',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:47.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'C6D929B7-1B2E-46EB-AD05-703EA8FDEC68',
    startdate: '2012-03-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '242',
    shortname: 'ФИДЖИ',
    fullname: 'Республика Фиджи',
    alfacode2: 'FJ',
    alfacode3: 'FJI',
    clarification: '',
    lastchangetype: '2',
    changedate: '2012-03-01 00:00:00.0',
    introductiondate: '2012-03-01 00:00:00.0',
    lastChangeNum: '12',
    filedate: '2020-05-07 12:02:07.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'E8803630-38A4-49F5-A268-DCE63B4C3088',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '608',
    shortname: 'ФИЛИППИНЫ',
    fullname: 'Республика Филиппины',
    alfacode2: 'PH',
    alfacode3: 'PHL',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:34.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '168AEBC6-4E9E-49E1-A7CF-453015E83858',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '246',
    shortname: 'ФИНЛЯНДИЯ',
    fullname: 'Финляндская Республика',
    alfacode2: 'FI',
    alfacode3: 'FIN',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:19.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'F9692665-B10F-4AE8-B103-C7C78C94C066',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '238',
    shortname: 'ФОЛКЛЕНДСКИЕ ОСТРОВА (МАЛЬВИНСКИЕ)',
    fullname: 'ФОЛКЛЕНДСКИЕ ОСТРОВА (МАЛЬВИНСКИЕ)',
    alfacode2: 'FK',
    alfacode3: 'FLK',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:47.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '14E5920E-4692-4474-A3FC-F0D0F23B2037',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '250',
    shortname: 'ФРАНЦИЯ',
    fullname: 'Французская Республика',
    alfacode2: 'FR',
    alfacode3: 'FRA',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:45.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '10AE28EF-469A-4D9E-8528-0DE0B89E23E6',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '254',
    shortname: 'ФРАНЦУЗСКАЯ ГВИАНА',
    fullname: 'ФРАНЦУЗСКАЯ ГВИАНА',
    alfacode2: 'GF',
    alfacode3: 'GUF',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:21.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '19E63F8F-F5EC-452F-9CE5-D1C7CE1DAE52',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '258',
    shortname: 'ФРАНЦУЗСКАЯ ПОЛИНЕЗИЯ',
    fullname: 'ФРАНЦУЗСКАЯ ПОЛИНЕЗИЯ',
    alfacode2: 'PF',
    alfacode3: 'PYF',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:14.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '51CA443C-46D3-46E2-9C5E-71C24A8B4F43',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '260',
    shortname: 'ФРАНЦУЗСКИЕ ЮЖНЫЕ ТЕРРИТОРИИ',
    fullname: 'ФРАНЦУЗСКИЕ ЮЖНЫЕ ТЕРРИТОРИИ',
    alfacode2: 'TF',
    alfacode3: 'ATF',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:12.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '3E78F950-3E63-4E90-B327-D14230B4DAAC',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '191',
    shortname: 'ХОРВАТИЯ',
    fullname: 'Республика Хорватия',
    alfacode2: 'HR',
    alfacode3: 'HRV',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:32.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'BB59D103-552A-4217-93C3-D1CD4DDDE443',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '140',
    shortname: 'ЦЕНТРАЛЬНО-АФРИКАНСКАЯ РЕСПУБЛИКА',
    fullname: 'ЦЕНТРАЛЬНО-АФРИКАНСКАЯ РЕСПУБЛИКА',
    alfacode2: 'CF',
    alfacode3: 'CAF',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:34.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '702EB6D5-4D3D-410E-B3F0-D55B629C1F5A',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '148',
    shortname: 'ЧАД',
    fullname: 'Республика Чад',
    alfacode2: 'TD',
    alfacode3: 'TCD',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:34.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '84AC0CA4-DF11-401A-8D21-9ABEB4C2D2E7',
    startdate: '2009-11-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '499',
    shortname: 'ЧЕРНОГОРИЯ',
    fullname: 'ЧЕРНОГОРИЯ',
    alfacode2: 'ME',
    alfacode3: 'MNE',
    clarification: '',
    lastchangetype: '2',
    changedate: '2009-11-01 00:00:00.0',
    introductiondate: '2009-11-01 00:00:00.0',
    lastChangeNum: '9',
    filedate: '2020-05-07 12:01:20.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '7A33AB9A-F7A4-4736-8C32-E54CDD8903ED',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '203',
    shortname: 'ЧЕШСКАЯ РЕСПУБЛИКА',
    fullname: 'ЧЕШСКАЯ РЕСПУБЛИКА',
    alfacode2: 'CZ',
    alfacode3: 'CZE',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:34.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '6DE42008-5ECB-4B98-B1FA-70AE012F5D0B',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '152',
    shortname: 'ЧИЛИ',
    fullname: 'Республика Чили',
    alfacode2: 'CL',
    alfacode3: 'CHL',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:13.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '07E36F90-2A11-4E9E-AD7F-F67D524BFCE8',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '756',
    shortname: 'ШВЕЙЦАРИЯ',
    fullname: 'Швейцарская Конфедерация',
    alfacode2: 'CH',
    alfacode3: 'CHE',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:17.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'B7B7C9D4-C500-45A4-AC9A-8DE9C7A44C51',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '752',
    shortname: 'ШВЕЦИЯ',
    fullname: 'Королевство Швеция',
    alfacode2: 'SE',
    alfacode3: 'SWE',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:50.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'C37D0A9A-723F-45AE-A4CB-B9885338CBF3',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '744',
    shortname: 'ШПИЦБЕРГЕН И ЯН МАЙЕН',
    fullname: 'ШПИЦБЕРГЕН И ЯН МАЙЕН',
    alfacode2: 'SJ',
    alfacode3: 'SJM',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:31.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '28C8C156-11B2-45A7-B80A-F74D00566298',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '144',
    shortname: 'ШРИ-ЛАНКА',
    fullname: 'Демократическая Социалистическая Республика Шри-Ланка',
    alfacode2: 'LK',
    alfacode3: 'LKA',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:45.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'CACD1C1E-CD67-47D2-AE0A-7AB819AFE54C',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '218',
    shortname: 'ЭКВАДОР',
    fullname: 'Республика Эквадор',
    alfacode2: 'EC',
    alfacode3: 'ECU',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:51.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'AE49E07F-4F50-44FA-9321-219ED17220C7',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '226',
    shortname: 'ЭКВАТОРИАЛЬНАЯ ГВИНЕЯ',
    fullname: 'Республика Экваториальная Гвинея',
    alfacode2: 'GQ',
    alfacode3: 'GNQ',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:36.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'B9A76B8D-9F89-4F0E-A8A4-9FA4577C0ED3',
    startdate: '2004-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '248',
    shortname: 'ЭЛАНДСКИЕ ОСТРОВА',
    fullname: 'ЭЛАНДСКИЕ ОСТРОВА',
    alfacode2: 'AX',
    alfacode3: 'ALA',
    clarification: '',
    lastchangetype: '3',
    changedate: '2004-07-01 00:00:00.0',
    introductiondate: '2004-07-01 00:00:00.0',
    lastChangeNum: '3',
    filedate: '2020-05-07 12:02:32.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '65728689-EFE4-45EE-921E-F677190B5CF7',
    startdate: '2003-10-15 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '222',
    shortname: 'ЭЛЬ-САЛЬВАДОР',
    fullname: 'Республика Эль-Сальвадор',
    alfacode2: 'SV',
    alfacode3: 'SLV',
    clarification: '',
    lastchangetype: '2',
    changedate: '2003-10-15 00:00:00.0',
    introductiondate: '2003-10-15 00:00:00.0',
    lastChangeNum: '1',
    filedate: '2020-05-07 12:01:22.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '0C3225E3-0D02-421E-BC0F-DE3C7AEB9042',
    startdate: '2013-01-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '232',
    shortname: 'ЭРИТРЕЯ',
    fullname: 'Государство Эритрея',
    alfacode2: 'ER',
    alfacode3: 'ERI',
    clarification: '',
    lastchangetype: '2',
    changedate: '2013-01-01 00:00:00.0',
    introductiondate: '2013-01-01 00:00:00.0',
    lastChangeNum: '16',
    filedate: '2020-05-07 12:01:50.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '44E2877C-3A30-42E0-BA5F-02FF2FD8FD9E',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '233',
    shortname: 'ЭСТОНИЯ',
    fullname: 'Эстонская Республика',
    alfacode2: 'EE',
    alfacode3: 'EST',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-09-18 09:56:59.0',
    loaddate: '2020-12-17 09:45:55.0',
  },
  {
    guid: '377BCE64-EA5C-4A9C-88B5-3EC267BCF0E8',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '231',
    shortname: 'ЭФИОПИЯ',
    fullname: 'Федеративная Демократическая Республика Эфиопия',
    alfacode2: 'ET',
    alfacode3: 'ETH',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:53.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '027FADE4-7898-4914-A650-A4FDCACCE02C',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '710',
    shortname: 'ЮЖНАЯ АФРИКА',
    fullname: 'Южно-Африканская Республика',
    alfacode2: 'ZA',
    alfacode3: 'ZAF',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:00.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '884F0ACE-113D-4941-8E85-9CC69394B3FB',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '239',
    shortname: 'ЮЖНАЯ ДЖОРДЖИЯ И ЮЖНЫЕ САНДВИЧЕВЫ ОСТРОВА',
    fullname: 'ЮЖНАЯ ДЖОРДЖИЯ И ЮЖНЫЕ САНДВИЧЕВЫ ОСТРОВА',
    alfacode2: 'GS',
    alfacode3: 'SGS',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:16.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '5B0A3A72-D365-430F-8AA4-AEE07C866483',
    startdate: '2009-01-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '896',
    shortname: 'ЮЖНАЯ ОСЕТИЯ',
    fullname: 'Республика Южная Осетия',
    alfacode2: 'OS',
    alfacode3: 'OST',
    clarification: '',
    lastchangetype: '3',
    changedate: '2009-01-01 00:00:00.0',
    introductiondate: '2009-01-01 00:00:00.0',
    lastChangeNum: '8',
    filedate: '2020-05-07 12:01:43.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '94A93233-2963-43A3-BB0B-BD110117AE85',
    startdate: '2012-03-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '728',
    shortname: 'ЮЖНЫЙ СУДАН',
    fullname: 'Республика Южный Судан',
    alfacode2: 'SS',
    alfacode3: 'SSD',
    clarification: '',
    lastchangetype: '3',
    changedate: '2012-03-01 00:00:00.0',
    introductiondate: '2012-03-01 00:00:00.0',
    lastChangeNum: '13',
    filedate: '2020-05-07 12:02:26.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: 'F328D2B2-C1CE-4D73-A0C4-667197AF3E8A',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '388',
    shortname: 'ЯМАЙКА',
    fullname: 'ЯМАЙКА',
    alfacode2: 'JM',
    alfacode3: 'JAM',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:02:40.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
  {
    guid: '1DAF9276-460D-4588-9E4E-44691D54D209',
    startdate: '2002-07-01 00:00:00.0',
    enddate: '',
    status: 'ACTIVE',
    code: '392',
    shortname: 'ЯПОНИЯ',
    fullname: 'ЯПОНИЯ',
    alfacode2: 'JP',
    alfacode3: 'JPN',
    clarification: '',
    lastchangetype: '0',
    changedate: '2002-07-01 00:00:00.0',
    introductiondate: '2002-07-01 00:00:00.0',
    lastChangeNum: '0',
    filedate: '2020-05-07 12:01:23.0',
    loaddate: '2020-05-08 22:42:59.0',
  },
];
