import { TCommentList } from 'domains/comment/model/types';
import {
  THookSelectorFetchDataInfo,
  THookStateInfo,
  TSelector,
  useGenFetchDataSelector,
  useGenFetchDataShallowEqualSelector,
  useShallowEqualSelector,
} from '../../../redux/rootSelectors';
import { CampaignState, RAdvancedOptimizationLists } from './types';
import {
  ListSRMethods,
  RFrontStatistics,
  RGetPersonal,
  RPreTargeting,
  TOptimizationList,
  TReportData,
} from '../types';
import { RExternalModeration } from '../../creative/types';

/** список селекторов */
export const CompanyTags: TSelector<CampaignState['companyTags']> = ({
  campaignReducer,
}) => campaignReducer.companyTags;

const PartnerTags: TSelector<CampaignState['partnerTags']> = ({
  campaignReducer,
}) => campaignReducer.partnerTags;

export const selectorCampaign: TSelector<CampaignState['personalData']> = ({
  campaignReducer,
}) => campaignReducer.personalData;

export const selectorAdvancedOptimization: TSelector<
  CampaignState['advancedOptimizationLists']
> = ({ campaignReducer }) => campaignReducer.advancedOptimizationLists;

export const selectorCampaignStatistics: TSelector<
  CampaignState['personalStatistics']
> = ({ campaignReducer }) => campaignReducer.personalStatistics;

export const selectorPreTargeting: TSelector<CampaignState['preTargeting']> = ({
  campaignReducer,
}) => campaignReducer.preTargeting;

export const selectorReport: TSelector<CampaignState['viewReport']> = ({
  campaignReducer,
}) => campaignReducer.viewReport;

export const selectorDefChartItem: TSelector<
  CampaignState['defChartItemsGen']
> = ({ campaignReducer }) => campaignReducer.defChartItemsGen;

export const selectorExternalModeration: TSelector<
  CampaignState['externalModeration']
> = ({ campaignReducer }) => campaignReducer.externalModeration;

export const selectorCampaignListSRMethods: TSelector<
  CampaignState['listSRMethods']
> = ({ campaignReducer }) => campaignReducer.listSRMethods;

export const selectorCampaignComments: TSelector<CampaignState['comments']> = ({
  campaignReducer,
}) => campaignReducer.comments;

export const selectorOptimisationList: TSelector<CampaignState['optimisationList']> = ({
  campaignReducer,
}) => campaignReducer.optimisationList;

/** список хуков */
export const useSelectorCampaignCompanyTagsInfo: THookSelectorFetchDataInfo<
  string[]
> = () =>
  useGenFetchDataSelector<CampaignState['companyTags'], string[]>(CompanyTags);

export const useSelectorCampaignPartnerTagsInfo: THookSelectorFetchDataInfo<
  string[]
> = () =>
  useGenFetchDataSelector<CampaignState['partnerTags'], string[]>(PartnerTags);

export const useCampaignAdvancedOptimizationInfo: THookSelectorFetchDataInfo<
  RAdvancedOptimizationLists
> = () => useGenFetchDataSelector(selectorAdvancedOptimization);

export const useCampaignStatisticsInfo: THookSelectorFetchDataInfo<
  RFrontStatistics
> = () => useGenFetchDataShallowEqualSelector(selectorCampaignStatistics);

export const useCampaignInfo: THookSelectorFetchDataInfo<RGetPersonal> = () =>
  useGenFetchDataShallowEqualSelector(selectorCampaign);

export const useCampaignPreTargeting: THookSelectorFetchDataInfo<
  RPreTargeting
> = () => useGenFetchDataShallowEqualSelector(selectorPreTargeting);

export const useCampaignReportInfo: THookSelectorFetchDataInfo<
  TReportData
> = () => useGenFetchDataShallowEqualSelector(selectorReport);

export const useDefChartItemInfo: THookStateInfo<
  CampaignState['defChartItemsGen']
> = () => useShallowEqualSelector(selectorDefChartItem);

export const useExternalModerationInfo: THookSelectorFetchDataInfo<
  RExternalModeration
> = () => useGenFetchDataShallowEqualSelector(selectorExternalModeration);

export const useListSRMethodsInfo: THookSelectorFetchDataInfo<
  ListSRMethods
> = () => useGenFetchDataSelector(selectorCampaignListSRMethods);

export const useCampaignCommentsInfo: THookSelectorFetchDataInfo<
  TCommentList
> = () => useGenFetchDataSelector(selectorCampaignComments);

export const useCampaignOptimisationList: THookSelectorFetchDataInfo<
  TOptimizationList
> = () => useGenFetchDataSelector(selectorOptimisationList);


