import {
  THookSelectorFetchDataInfo,
  THookStateInfo,
  TSelector,
  useGenFetchDataSelector,
  useGenFetchDataShallowEqualSelector,
  useSelectorTypes,
} from '../../../redux/rootSelectors';
import { ClientState } from '../reduser';
import { TUploadFile } from '../../../utils/upload';
import { ResponseInfoClient } from '../types';
import { TID } from '../../../types/general';

/** Список селекторов */
export const selectorClientTempImage: TSelector<ClientState['tempImage']> = ({
  сlientReducer,
}) => сlientReducer.tempImage;

export const selectorClientInfo: TSelector<
  ClientState['clientPersonalInfoById']
> = ({ сlientReducer }) => сlientReducer.clientPersonalInfoById;

export const selectorClientIsShowMChange: TSelector<
  ClientState['isShowMChange']
> = ({ сlientReducer }) => сlientReducer.isShowMChange;

export const selectorClientIdChange: TSelector<ClientState['idChange']> = ({
  сlientReducer,
}) => сlientReducer.idChange;

export const selectorClientEdit: TSelector<ClientState['editClient']> = ({
  сlientReducer,
}) => сlientReducer.editClient;

export const selectorLastAddClient: TSelector<ClientState['lastAddClient']> = ({
  сlientReducer,
}) => сlientReducer.lastAddClient;

export const selectorIsShowMCreate: TSelector<ClientState['isShowMCreate']> = ({
  сlientReducer,
}) => сlientReducer.isShowMCreate;

export const selectorGroupList: TSelector<ClientState['groupList']> = ({
  сlientReducer,
}) => сlientReducer.groupList;

/** Список хуков */
export const useClientImageInfo: THookSelectorFetchDataInfo<TUploadFile> = () =>
  useGenFetchDataSelector(selectorClientTempImage);

export const useClientInfo: THookSelectorFetchDataInfo<
  ResponseInfoClient
> = () => useGenFetchDataShallowEqualSelector(selectorClientInfo);

export const useClientIsShowMChangeInfo: THookStateInfo<
  ClientState['isShowMChange']
> = () => useSelectorTypes(selectorClientIsShowMChange);

export const useClientIdChangeInfo: THookStateInfo<
  ClientState['idChange']
> = () => useSelectorTypes(selectorClientIdChange);

export const useClientEditInfo: THookSelectorFetchDataInfo<boolean> = () =>
  useGenFetchDataSelector(selectorClientEdit);

export const useLastAddClientInfo: THookSelectorFetchDataInfo<TID> = () =>
  useGenFetchDataSelector(selectorLastAddClient);

export const useIsShowMCreateInfo: THookStateInfo<
  ClientState['isShowMCreate']
> = () => useSelectorTypes(selectorIsShowMCreate);

export const useGroupList: THookStateInfo<ClientState['groupList']> = () =>
  useSelectorTypes(selectorGroupList);
