import React, { FC } from 'react';
import cn from 'classnames';
import { FiPlus } from 'react-icons/fi';
import css from './styles.module.scss';
import useCreatorTag from './hook';
import Input from '../Input';

type CreatorTagProps = {
  handlerAction: (value: string) => void;
  isDisable?: boolean;
  maxLength?: number;
};

const CreatorTag: FC<CreatorTagProps> = ({ handlerAction, isDisable, maxLength }) => {
  const { isToggle, handlerOpenInput, handlerHideInput, wrapperRef } =
    useCreatorTag();
  return (
    <div ref={wrapperRef}>
      <div
        className={cn(css.add, { isToggle })}
        onClick={!isDisable ? handlerOpenInput : () => null}
      >
        <FiPlus size={24} />
      </div>
      <div className={cn(css.addInputWrapper, { isToggle })}>
        <Input
          type="created"
          maxLength={maxLength}
          handlerClose={handlerHideInput}
          handlerAction={handlerAction}
          showEvent={isToggle}
          valueDefault=""
        />
      </div>
    </div>
  );
};

export default CreatorTag;
