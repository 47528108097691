import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import NavigationHeader from 'components/NavigationHeader';
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import { TID } from 'types/general';
import { Helmet } from 'react-helmet';
import Settings from './Settings';
import Targeting from './Targeting';
import css from './styles.module.scss';

type Props = RouteComponentProps<TID>;

const CreateCampaign: React.FC<Props> = (router) => {
  const { location } = router;
  const [tab, setTab] = useState('settings');
  const params = new URLSearchParams(location.search);
  const { t } = useTranslation();
  return (
    <div className={css['create-campaign']}>

      <Helmet>
        <title>{tab === 'settings' ? t(`campaigns_page.campaign_settings.black_menu.Main_settings`) : t('campaigns_page.targeting_word')}</title>
      </Helmet>

      <>
        <NavigationHeader
          back={{
            title: t('campaigns_page.create_campaign.black_header.back'),
            to: location.search
              ? `/main/campaigns${location.search}`
              : '/main/campaigns',
          }}
        >
          <button
            type="button"
            className={cn({ isActive: tab === 'settings' })}
            onClick={() => setTab('settings')}
          >
            {t('campaigns_page.campaign_settings.black_menu.Main_settings')}
          </button>

          <button
            type="button"
            className={cn({ isActive: tab === 'targeting' })}
            onClick={() => setTab('targeting')}
          >
            {t('campaigns_page.create_campaign.black_header.targ')}
          </button>
        </NavigationHeader>

        <div className={css.header}>
          <div className={cn(css.container, 'container')}>
            <Breadcrumbs type="Client" xxhash={params.get('client_id')} />
            <div className={css.status}>
              <h1 className={css.title}>{t('campaigns_page.create_campaign.tit')}</h1>
            </div>
          </div>
        </div>
      </>

      <div className={cn(css.tab, { isShow: tab === 'settings' })}>
        <Settings {...router} />
      </div>

      <div className={cn(css.tab, { isShow: tab === 'targeting' })}>
        <Targeting />
      </div>
    </div>
  );
};

export default CreateCampaign;
