import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';
import { ReactComponent as ScreenErrorSvg } from '../icons/screenError.svg';

interface Props {
  errorData: string;
  textError: string;
}

const ErrorScreenshot: FC<Props> = ({ errorData, textError }) => {
  const { t } = useTranslation();
  return (
    <div className={css.error_wrapper}>
      <ScreenErrorSvg />
      <div className={css.text_content}>
        <h4>{t('creative.screenshot.error')}</h4>
        <p>{errorData || textError}</p>
      </div>
    </div>
  );
};

export default ErrorScreenshot;
