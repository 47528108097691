/* eslint-disable no-useless-concat */
import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { FiChevronDown } from 'react-icons/fi';
import { THistoryResponse } from 'domains/historyChanges/types';
import { useTransformHistoryData } from 'domains/historyChanges/model/hooks/useTransformHistoryData';
import css from '../styles.module.scss';

type TFHistoryItem = {
  data: THistoryResponse;
  index: number;
  isCollapseAll: boolean;
};

const HistoryItem: FC<TFHistoryItem> = ({ data, index, isCollapseAll }) => {
  const COL_LENGTH = 150;
  const ROW_HEIGHT = 120;
  // стейт для индивидуального раскрытия\закрытия каждого итема истории
  const [isСollapsible, setIsСollapsible] = useState(false);
  const [wideItem, setWideItem] = useState(false);

  // Преобразование данных истории пришедших с бэка
  const { transformedDate, transformedParametrs, transformedChanges } =
    useTransformHistoryData(data);

  const toggleItem = (text: string) => {
    if (isСollapsible) {
      const newText = text?.slice(0, COL_LENGTH);

      return newText;
    }
    return text;
  };

  // укорачиваем если текст в столбце слишком длинный и делаем итем сворачиваемым
  const changes = toggleItem(transformedChanges) || toggleItem(JSON.stringify(data.data));

  useEffect(() => {
    if (isCollapseAll) {
      setIsСollapsible(false);
    } else {
      setIsСollapsible(true);
    }
  }, [isCollapseAll]);

  useEffect(() => {
    if (JSON.stringify(changes).length >= COL_LENGTH) {
      setIsСollapsible(true);
      setWideItem(true);
    }
  }, []);

  return (
    <div
      className={cn(css.table__row, {
        _isWideItem: wideItem && isСollapsible,
        _isNoCollapse: !isСollapsible && wideItem,
      })}
      style={{
        maxHeight: wideItem ? `${ROW_HEIGHT}px` : 'fit-content',
      }}
    >
      <div>{index + 1}</div>
      <div>{transformedDate}</div>
      <div>{data.user_email}</div>
      <div className={css.campaign}>
        <div>{data.campaign.internal_id}</div>
        <div>{data.campaign.xxhash}</div>
      </div>
      <div className={css.creo}>
        <div>{data.creative?.title || ''}</div>
        <div>{data.creative?.internal_id || ''}</div>
      </div>
      <div>{transformedParametrs}</div>
      <div>{changes}</div>

      {wideItem && (
        <div
          className={cn(css.collapseBtn, {
            _isOpen: !isСollapsible,
          })}
          onClick={() => {
            setIsСollapsible(!isСollapsible);
          }}
        >
          <FiChevronDown size={24} />
        </div>
      )}
    </div>
  );
};

export default HistoryItem;
