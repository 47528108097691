import React, { FC } from 'react';
import cn from 'classnames';
import { FiChevronRight } from 'react-icons/fi';
import Card from '../UI/Card';
import { useCollapse } from './hook';
import css from './styles.module.scss';
import { TCollapseKey } from '../../domains/storage/model/types';

type CollapseWrapperProps = {
  title: string;
  isLoading?: boolean;
  fetcher?: () => void;
  nameWrapper: TCollapseKey;
  classname?: string;
};

const CollapseWrapper: FC<CollapseWrapperProps> = ({
  title,
  isLoading,
  fetcher,
  nameWrapper,
  children,
  classname,
}) => {
  const { isCollapse, handleCollapse } = useCollapse({
    fetcher,
    nameWrapper,
    isLoading,
  });

  return (
    <Card noHover style={{ padding: '20px 14px' }} className={classname}>
      <div
        className={cn(css.title, {
          _openWidget: !isCollapse,
        })}
        onClick={() => {
          handleCollapse(!isCollapse);
        }}
      >
        <span className={css.collapse}>
          <FiChevronRight size={24} />
        </span>
        {title}
      </div>
      <div
        className={cn(css.childrenWrapper, 'childrenWrapper', {
          _isOpen: isCollapse,
        })}
      >
        {children}
      </div>
    </Card>
  );
};
export default CollapseWrapper;
