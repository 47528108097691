import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CardsList from 'components/UI/CardList';
import { useProjectInfo } from 'domains/project/hooks';
import Header from '../components/Header';
import css from "./styles.module.scss";
import Project from './Forms/Project';
import Branding from './Forms/Branding';
import Registration from './Forms/Registration';
import Offer from './Forms/Offer';

const ProjectSettings: FC = () => {
  const { t } = useTranslation();
  const { data: project } = useProjectInfo();

  return (
    <div>
      <Helmet>
        <title>{`${t('project_page.settings')} ${
          project && project.title
        }`}</title>
      </Helmet>

      {project && <Header title={project.title} />}

      <div className="container">
        <CardsList className={css.cards}>
          <Project />
          <Branding />
          <Registration />
          <Offer />
        </CardsList>
      </div>
    </div>
  );
};
export default ProjectSettings;
