import React, { useState } from 'react';
import cn from 'classnames';
import i18next from 'i18next';
import { FiChevronRight } from 'react-icons/fi';
import { numFormat } from 'utils/toFixedNumber';
import { TNamingItem, TOnSetFilterTable } from '../../../../types';
import css from '../styles.module.scss';
import { PTreeNodes } from '../../hooks/useReportGeneral';
import { TFilter } from '../../hooks/useIntegrationFilter';

type PTreeNode = {
  node: PTreeNodes['nodes'][0];
  namingItem: TNamingItem;
  level: number;
  firstCW: number;
  onSetFilterTable?: TOnSetFilterTable;
  filterTable?: TFilter['activeColumns'];
  backgroundColor?: string;
  isUtmTags?: boolean;
};

const TreeNode: React.FC<PTreeNode> = ({
  node,
  namingItem,
  level = 0,
  firstCW,
  onSetFilterTable,
  filterTable,
  backgroundColor,
  isUtmTags,
}) => {
  const styleTh: React.CSSProperties = React.useMemo(
    () => ({
      maxWidth: `${firstCW}px`,
      width: `${firstCW}px`,
    }),
    [firstCW],
  );

  const [toggle, setToggle] = useState(false);

  const children = Array.isArray(node.children) ? node.children : [];

  const row: React.ReactElement[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const key of namingItem.keys()) {
    const cellData = namingItem.get(key);
    const value = node[key];
    // if (typeof value === 'undefined') continue;
    // eslint-disable-next-line no-continue
    if (typeof cellData === 'undefined') continue;
    // eslint-disable-next-line no-continue
    if (cellData.noRender) continue;
    if (Array.isArray(value)) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const data =
      typeof cellData.formatCell === 'function'
        ? cellData.formatCell(node)
        : value;

    if (cellData.isIndex) {
      const anyTitle = value;

      const title: string = typeof anyTitle === 'string' ? anyTitle : '';

      // eslint-disable-next-line consistent-return
      const handleIsUtmTags = () => {
        let newItems = filterTable;

        if (newItems && newItems.includes(title)) {
          newItems = newItems.filter((e) => e !== title);
        } else if (newItems) {
          newItems = [...newItems, title];
        }
        if (newItems && onSetFilterTable) {
          return onSetFilterTable(newItems.filter((e) => e !== 'total'));
        }
      };

      row.push(
        <div className={css.cell} title={title} key={key} style={styleTh}>
          <div
            className="chart-color"
            style={{
              backgroundColor:
                filterTable && filterTable.includes(title)
                  ? backgroundColor
                  : 'transparent',
            }}
          />
          {isUtmTags && (
            <div
              className={cn('checkbox', {
                isChecked: filterTable && filterTable.includes(title),
              })}
              onClick={handleIsUtmTags}
            />
          )}
          {children.length > 0 && <FiChevronRight size={16} color="#222" />}
          <span>{data}</span>
        </div>,
      );
    } else {
      row.push(
        <div className={cn(css.cell, '_isAlignRight')} key={key}>
          {numFormat(i18next.language, data, '-')}
        </div>,
      );
    }
  }

  return (
    <>
      <div
        className={cn(
          css.row,

          {
            toggle: children.length > 0,
            isToggled: toggle,
          },
          level ? `level-${level}` : '',
        )}
        onClick={() => setToggle(!toggle)}
      >
        {row.map((item) => item)}
      </div>
      {children.length > 0 &&
        toggle &&
        children.map((e) => (
          <TreeNode
            node={e}
            namingItem={namingItem}
            level={level + 1}
            key={JSON.stringify(e)}
            firstCW={firstCW}
          />
        ))}
    </>
  );
};

export default TreeNode;
