/* eslint-disable import/no-named-as-default */
import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { ScrollSync, ScrollSyncNode } from 'scroll-sync-react';
import cn from 'classnames';
import { useSortTableData } from './hooks/useSortTableData';
import css from './styles.module.scss';
import TableHeader from './TableHeader';
import TableBody from './tableBody';
import Loader from './Loader';
import { tableProps } from './types';

const GeneralReportTable: FC<tableProps> = ({
  tableTitle,
  isLoadingTableData,
  columns,
  totalValues,
  transformedStatistic,
  mode,
  expandable,
  showAuTree = false,
}) => {
  const { currentSort, currentColumn, tableStatistic, handleSort } =
    useSortTableData({
      transformedStatistic,
    });

  const [curentScrollPosition, setCurentScrollPosition] = useState<number>(0);
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollLeft = 0;
      scrollRef.current.scrollLeft = curentScrollPosition;
    }
  }, [currentSort]);

  const [extend, setExtend] = useState<number>(240);

  return (
    <div className={css.table}>
      <div className={css.table_title}>
        <h3>{tableTitle}</h3>
      </div>
      {isLoadingTableData ? (
        <Loader />
      ) : (
        <ScrollSync>
          <div className={css.table_body}>
            <TableHeader
              currentSort={currentSort}
              currentColumn={currentColumn}
              handleSort={handleSort}
              columns={columns}
              mode={mode}
              extendWidth={extend}
              setExtendWidth={setExtend}
              extandableColumn={expandable}
            />
            <TableBody
              columns={columns}
              mode={mode}
              totalValues={totalValues}
              tableStatistic={tableStatistic}
              extend={extend}
              showAuTree={showAuTree}
            />
            <div className={cn(css.table_row, css.table_row_bar)}>
              <div
                className={css.table_row_static}
                style={{
                  width: extend,
                }}
              >
                <div className={css.cell}>
                  <span />
                </div>
              </div>
              <ScrollSyncNode
                group="a"
                onScroll={(e: any) => {
                  setCurentScrollPosition(e.target.scrollLeft);
                }}
              >
                <div className={css.table_row_scroll} ref={scrollRef}>
                  {mode === 'agency' && (
                    <div
                      className={cn(css.cell, {
                        _isLongForAgency: mode === 'agency',
                      })}
                    />
                  )}
                  {columns.map((column) => (
                    <div className={css.cell} key={column.data} />
                  ))}
                </div>
              </ScrollSyncNode>
            </div>
          </div>
        </ScrollSync>
      )}
    </div>
  );
};

export default memo(GeneralReportTable);
