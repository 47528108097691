import Load from 'components/UI/Load';
import React, { FC } from 'react';

const LoadingRow: FC = () => (
  <>
    <Load
      style={{
        width: '197px',
        height: '20px',
      }}
    />
    <Load
      style={{
        width: '83px',
        height: '20px',
      }}
    />
    <Load
      style={{
        width: '197px',
        height: '20px',
      }}
    />
    <Load
      style={{
        width: '83px',
        height: '20px',
      }}
    />
    <Load
      style={{
        width: '146px',
        height: '20px',
      }}
    />
  </>
);

export default LoadingRow;
