import { SagaIterator } from '@redux-saga/core';
import { takeLatest } from '@redux-saga/core/effects';

import {
  fetchBusinessSphere,
  fetchImageRequest,
  fetchZipRequest,
} from '../model/actions';
import {
  fetchBusinessSphereWorker,
  setFileSagaWorker,
  setFileZipWorker,
} from './workers';

export default function* watchUploads(): SagaIterator {
  yield takeLatest(fetchImageRequest, setFileSagaWorker);
  yield takeLatest(fetchZipRequest, setFileZipWorker);
  yield takeLatest(fetchBusinessSphere, fetchBusinessSphereWorker);
}
