import {
  THookSelectorFetchDataInfo,
  TSelector,
  useGenFetchDataSelector,
} from '../../../redux/rootSelectors';
import { ClientsState } from '../reducer';
import { ClientsList } from '../types';

/** Список селекторов */
export const selectorClients: TSelector<ClientsState['tempClients']> = ({
  clientsReducer,
}) => clientsReducer.tempClients;

export const selectorAllClients: TSelector<ClientsState['tempAllClients']> = ({
  clientsReducer,
}) => clientsReducer.tempAllClients;

/** Список хуков */
export const useClientsInfo: THookSelectorFetchDataInfo<ClientsList> = () =>
  useGenFetchDataSelector(selectorClients);

export const useClientsAllInfo: THookSelectorFetchDataInfo<ClientsList> = () =>
  useGenFetchDataSelector(selectorAllClients);
