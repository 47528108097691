import React, { FC } from 'react';
import Card from 'components/UI/Card';
import CardList from 'components/UI/CardList';
import Button from 'components/UI/Buttons/buttons';
import { FormikProps } from 'formik';
import { FormikValues } from 'domains/usersAPI/model/types';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';
import Methods from '../../components/Methods';
import Main from '../../components/Main';

interface Props {
  formik: FormikProps<FormikValues>;
  tagValidationError: string;
  mod: 'create' | 'edit';
}

const Settings: FC<Props> = ({ formik, mod, tagValidationError }) => {
  const { t } = useTranslation();

  const errorEmail = !!(
    formik.touched.data?.email && formik.errors?.data?.email
  );
  const errorPassword =
    formik.touched.data?.password && !!formik.errors?.data?.password;
  const errorPartners =
    formik.touched.data?.partners && !!formik.errors.data?.partners;

  return (
    <CardList>
      <div>
        <Card noHover>
          <Main formik={formik} mod={mod} tagValidationError={tagValidationError} />
        </Card>
        <Card noHover>
          <Methods formik={formik} />
        </Card>
      </div>
      <div className={css.create}>
        <Button
          title={t(`save_btn`)}
          height={44}
          autoWidth
          buttonAttributes={{
            type: 'submit',
            onClick: () => formik.handleSubmit(),
            disabled: errorEmail || errorPassword || errorPartners,
          }}
        />
      </div>
    </CardList>
  );
};

export default Settings;
