import { THookSelectorFetchDataInfo, TSelector, useGenFetchDataSelector } from 'redux/rootSelectors';
import { offersState } from './reducer';
import { TOffersList } from './types';

export const selectorOffersList: TSelector<offersState['offersList']> = ({
  offersReducer,
}) => offersReducer.offersList;

export const useOffersList: THookSelectorFetchDataInfo<TOffersList> = () =>
  useGenFetchDataSelector(selectorOffersList);
