import { useEffect } from 'react';
import { FetchedDataErrors } from 'types/general';
import { useDispatchApp } from 'redux/rootSelectors';
import { AgenciesList, Status } from '../api';
import { fetchAgencies } from '../reducer';
import { useStorageAgenciesTabs } from '../../storage/model/selectors';
import { setAgenciesTabs } from '../../storage/model/reducer';
import { useAgenciesInfo } from '../model/selectors';

type TFUseDataAgencies = () => {
  isLoading: boolean;
  status: Status;
  agencies: AgenciesList | null;
  error: FetchedDataErrors;
  setStatusAction: (param: Status) => void;
};
export const useDataAgencies: TFUseDataAgencies = () => {
  const dispatch = useDispatchApp();

  const { data, error, isLoading } = useAgenciesInfo();

  const status = useStorageAgenciesTabs();

  useEffect(() => {
    if (status) {
      dispatch(fetchAgencies({ status }));
    } else {
      dispatch(fetchAgencies({ status: null }));
    }
  }, [status]);

  const setStatusAction: ReturnType<TFUseDataAgencies>['setStatusAction'] = (
    param,
  ) => {
    dispatch(setAgenciesTabs(param));
  };

  return {
    status,
    agencies: data,
    isLoading,
    setStatusAction,
    error,
  };
};
