import icon1Svg from '../icons/styleIcons/pic1.svg';
import icon2Svg from '../icons/styleIcons/pic2.svg';
import icon3Svg from '../icons/styleIcons/pic3.svg';
import icon4Svg from '../icons/styleIcons/pic4.svg';
import icon5Svg from '../icons/styleIcons/pic5.svg';
import icon6Svg from '../icons/styleIcons/pic6.svg';
import icon7Svg from '../icons/styleIcons/pic7.svg';
import icon8Svg from '../icons/styleIcons/pic8.svg';
import icon9Svg from '../icons/styleIcons/pic9.svg';
import bigIcon1Svg from '../icons/bigIcons/bannerBig1.png';
import bigIcon2Svg from '../icons/bigIcons/bannerBig2.png';
import bigIcon3Svg from '../icons/bigIcons/bannerBig3.png';
import bigIcon4Svg from '../icons/bigIcons/bannerBig4.png';
import bigIcon5Svg from '../icons/bigIcons/bannerBig5.png';
import bigIcon6Svg from '../icons/bigIcons/bannerBig6.png';
import bigIcon7Svg from '../icons/bigIcons/bannerBig7.png';
import bigIcon8Svg from '../icons/bigIcons/bannerBig8.png';
import bigIcon9Svg from '../icons/bigIcons/bannerBig9.png';
import bigIcon10Svg from '../icons/bigIcons/bannerBig10.png';
import smallIcon1Svg from '../icons/smallIcons/bannerSmall1.png';
import smallIcon2Svg from '../icons/smallIcons/bannerSmall2.png';
import smallIcon3Svg from '../icons/smallIcons/bannerSmall3.png';
import smallIcon4Svg from '../icons/smallIcons/bannerSmall4.png';
import smallIcon5Svg from '../icons/smallIcons/bannerSmall5.png';
import smallIcon6Svg from '../icons/smallIcons/bannerSmall6.png';
import smallIcon7Svg from '../icons/smallIcons/bannerSmall7.png';
import smallIcon8Svg from '../icons/smallIcons/bannerSmall8.png';
import smallIcon9Svg from '../icons/smallIcons/bannerSmall9.png';
import smallIcon10Svg from '../icons/smallIcons/bannerSmall10.png';

export type TArrayImages = { id: number; url: string }[];

export const BUTTON_KEY = 'button';
export const ILLUSTRATION_KEY = 'illustration';
export const LOGO_KEY = 'logoType';
export const TEXT_KEY = 'text';

export const iconsArrayIllustration: TArrayImages = [
  { id: 1, url: icon1Svg },
  { id: 2, url: icon2Svg },
  { id: 3, url: icon3Svg },
  { id: 4, url: icon4Svg },
  { id: 5, url: icon5Svg },
  { id: 6, url: icon6Svg },
  { id: 7, url: icon7Svg },
  { id: 8, url: icon8Svg },
  { id: 9, url: icon9Svg },
];

export const iconsBigArray: TArrayImages = [
  { id: 1, url: bigIcon1Svg },
  { id: 2, url: bigIcon2Svg },
  { id: 3, url: bigIcon3Svg },
  { id: 4, url: bigIcon4Svg },
  { id: 5, url: bigIcon5Svg },
  { id: 6, url: bigIcon6Svg },
  { id: 7, url: bigIcon7Svg },
  { id: 8, url: bigIcon8Svg },
  { id: 9, url: bigIcon9Svg },
  { id: 10, url: bigIcon10Svg },
];

export const iconsSmallArray: TArrayImages = [
  { id: 1, url: smallIcon1Svg },
  { id: 2, url: smallIcon2Svg },
  { id: 3, url: smallIcon3Svg },
  { id: 4, url: smallIcon4Svg },
  { id: 5, url: smallIcon5Svg },
  { id: 6, url: smallIcon6Svg },
  { id: 7, url: smallIcon7Svg },
  { id: 8, url: smallIcon8Svg },
  { id: 9, url: smallIcon9Svg },
  { id: 10, url: smallIcon10Svg },
];
