import request from '../../lib/helpers/requestApi';
import {
  TGetCreativeListParams,
  TGetCreativeListResponse,
  TTargetItemsParams,
  TTargetItemsResponse,
} from './types';

export const getTargetItems = request<TTargetItemsResponse, TTargetItemsParams>(
  {
    method: 'targeting.getTargetItemsInPartner',
  },
);

export const getCreativeList = request<
  TGetCreativeListResponse,
  TGetCreativeListParams
>({
  method: 'creative.getListByPartnerAndTargets',
});
