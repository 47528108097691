import { SagaIterator } from '@redux-saga/core';
import { takeEvery } from 'redux-saga/effects';
import { takeLatest } from '@redux-saga/core/effects';
import {
  editPartnerWorker,
  fetcherPartnersBalanceWorker,
  fetchPartnerBalanceWorker,
  fetchPaymentLinkWorker,
  transferMoneyWorker,
  workerGetAgencySaga,
} from './workers';
import {
  editPartnerAction,
  fetchAgencyList,
  fetcherPartnerBalance,
  fetcherPartnersBalance,
  fetchLinkBalance,
  transferMoney,
} from '../model/actions';

export default function* watchPartners(): SagaIterator {
  yield takeEvery(fetchAgencyList, workerGetAgencySaga);
  yield takeLatest(editPartnerAction, editPartnerWorker);
  yield takeLatest(fetcherPartnersBalance, fetcherPartnersBalanceWorker);
  yield takeLatest(fetcherPartnerBalance, fetchPartnerBalanceWorker);
  yield takeLatest(fetchLinkBalance, fetchPaymentLinkWorker);
  yield takeLatest(transferMoney, transferMoneyWorker);
}
