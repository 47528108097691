import React, { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiCode } from 'react-icons/fi';
import cn from 'classnames';
import Loader from 'components/GeneralReportTable/Loader';
import css from './styles.module.scss';
import Tr from './Tr';
import Th from './Th';
import { useExtend } from './hooks/useExtend';
import ErrorReport from '../Errors/Reports';

interface IReportTableAuProps {
  tableTitle: string | ReactNode;
  totalData: (string[] | number[])[];
  transformReportsAu: (string | number)[][];
  getAuditTitles: string[];
  getInternalIdObj: { [key: string]: string }[];
  loadingReports: boolean;
}

const ReportTableAu: FC<IReportTableAuProps> = ({
  tableTitle,
  totalData,
  transformReportsAu,
  getAuditTitles,
  getInternalIdObj,
  loadingReports,
}) => {
  const { extendWidth, onMouseDown, tableRef, positionTop, positionLeft } =
    useExtend({
      ready: loadingReports,
    });
  const { t } = useTranslation();
  const settingsFromStorage: string[] = JSON.parse(
    localStorage.getItem('reportAuSettings') ?? '[]',
  );

  const findKeyBySettingsTitle = (
    arr: string[],
    searchValue: string | number,
  ) => {
    if (typeof searchValue === 'number') {
      return searchValue;
    }
    const obj = arr.find(
      (item) => Object.values(item)[0].toLowerCase() === searchValue,
    );
    return obj ? Object.keys(obj)[0] : null;
  };

  const createRow = useMemo(() => {
    let name: string | number = '';
    // переменная для окрашивания в шахматном порядке строк.
    let coloringGrey = 0;
    return transformReportsAu.map((report) => {
      const currentReport = settingsFromStorage.some(
        (s) => Object.values(s)[0] === report[1],
      );
      if (currentReport) {
        if (name === report[0]) {
          return (
            <Tr
              key={JSON.stringify(report)}
              classname={cn({
                [css.grey]: coloringGrey % 2 === 0,
              })}
            >
              {report.slice(1).map((r) => (
                <Th key={r[1]}>
                  {findKeyBySettingsTitle(settingsFromStorage, r)}
                </Th>
              ))}
            </Tr>
          );
        }

        // eslint-disable-next-line prefer-destructuring
        name = report[0];
        coloringGrey += 1;
        return (
          <Tr
            key={JSON.stringify(report)}
            classname={cn({
              [css.grey]: coloringGrey % 2 === 0,
            })}
          >
            <Th unityRows={settingsFromStorage.length} name>
              <div className={css.name}>{report[0]}</div>
              <div className={css.id}>
                {getInternalIdObj.map((id) =>
                  Object.keys(id).map((k) => {
                    if (k === report[0]) {
                      return id[k];
                    }
                    return null;
                  }),
                )}
              </div>
            </Th>
            {report.slice(1).map((r) => (
              <Th key={r[1]}>
                {findKeyBySettingsTitle(settingsFromStorage, r)}
              </Th>
            ))}
          </Tr>
        );
      }
      return null;
    });
  }, [transformReportsAu, settingsFromStorage]);

  const createTotalRow = useMemo(
    () =>
      settingsFromStorage.map((item, index) => (
        <Tr total key={JSON.stringify(item)}>
          {index === 0 && (
            <Th unityRows={settingsFromStorage.length} name>
              {t(`charts.total`)}
            </Th>
          )}
          {totalData.map((totalitem) => {
            if (Object.values(item).includes(totalitem[0] as string)) {
              return totalitem.map((el) => (
                <Th key={el}>
                  {findKeyBySettingsTitle(settingsFromStorage, el)}
                </Th>
              ));
            }
            return null;
          })}
        </Tr>
      )),
    [totalData, settingsFromStorage],
  );

  if (loadingReports) {
    return (
      <div className={css.table}>
        <div className={css.table_title}>
          <h3>{tableTitle}</h3>
        </div>
        <Loader />
      </div>
    );
  }

  if (!transformReportsAu.length && !loadingReports) {
    return <ErrorReport error={null} type="Reports" />;
  }

  return (
    <div className={css.table}>
      <div className={css.table_title}>
        <h3>{tableTitle}</h3>
      </div>
      <div className={css.table_body}>
        <table
          style={
            {
              '--widthStaticPart': `${extendWidth}px`,
            } as React.CSSProperties
          }
        >
          <thead ref={tableRef}>
            <Tr>
              <Th name>{t(`agencies_page.report.table_title`)}</Th>
              <Th empty />
              {getAuditTitles.map((name) => (
                <Th title={name} key={name}>
                  {name}
                </Th>
              ))}
            </Tr>
            <tr
              className={cn(css.extend, {
                _isHide: settingsFromStorage.length === 0,
              })}
              onMouseDown={onMouseDown}
              style={{
                top: `${positionTop + 3}px`,
                left: `${positionLeft}px`,
              }}
            >
              <FiCode size={12} />
            </tr>
          </thead>
          <tbody>
            {createTotalRow}
            {createRow}
          </tbody>
        </table>
      </div>
      {settingsFromStorage.length === 0 && (
        <ErrorReport error={null} type="Reports" />
      )}
    </div>
  );
};

export default ReportTableAu;
