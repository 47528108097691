import LabelField from 'components/UI/LabelField';
import { CampaignGeneralComponentProps } from 'domains/campaign/types';
import { Field, FieldProps } from 'formik';
import React, { memo } from 'react';
import { SelectField } from 'types/general';
import { selectDefault } from 'utils/selectDefault';
import NewSelect from 'components/UI/NewSelect';
import i18n from '../../../../../../../i18n';

type TPartnerFieldProps = Pick<
  CampaignGeneralComponentProps,
  'formik' | 'autoSubmit'
> & {
  clients: SelectField[];
  clientDisable: boolean;
};

const PartnerField: React.FC<TPartnerFieldProps> = ({
  formik,
  clients,
  clientDisable,
  autoSubmit,
}) => (
  <LabelField label={`${i18n.t('campaigns_page.create_campaign.client')} *`}>
    <Field name="partner_xxhash">
      {({ field, form }: FieldProps) => (
        <NewSelect
          autoSubmit={autoSubmit}
          form={form}
          field={field}
          selectAttributes={{
            isDisabled: clientDisable,
            options: clients,
            placeholder: i18n.t('targetings.search'),
            value: selectDefault(formik.values.partner_xxhash, clients),
            isClearable: true,
          }}
          heightCustom={32}
          viewStyle="Horizontal"
          error={!!formik.errors.partner_xxhash}
          errorText={formik.errors.partner_xxhash}
        />
      )}
    </Field>
  </LabelField>
);

export default memo(PartnerField);
