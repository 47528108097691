import React, { FC } from 'react';
import Load from 'components/UI/Load';
import css from '../styles.module.scss';

const LoadingItem: FC = () => (
  <div className={css.list_item}>
    <Load
      style={{
        width: '22px',
        height: '22px',
        borderRadius: '100%',
      }}
    />
    <Load
      style={{
        width: '100%',
      }}
    />
  </div>
);

export default LoadingItem;
