import { TFilesProject } from '../../types';

export const getValueByName = (
  obj: Record<string, TFilesProject>,
  key: TFilesProject['name'],
): string | undefined => {
  if (key in obj) {
    return obj[key].link;
  }
  return undefined;
};
