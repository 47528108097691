import React, { FC } from 'react';
import { FiX } from 'react-icons/fi';

type Props = {
  closeToast?: () => void;
};

const CloseToastButton: FC<Props> = ({ closeToast }) => (
  <FiX size={24} color="#222" onClick={closeToast} />
);

export default CloseToastButton;
