import {
  THookSelectorFetchDataInfo,
  TSelector,
  useGenFetchDataSelector,
} from 'redux/rootSelectors';
import { counteragentsState } from './reducer';
import { AllCounteragetnList, CounteragentsList, OrdList } from './types';

export const selectorCounteragentsList: TSelector<
  counteragentsState['counteragentsList']
> = ({ counteragentsReducer }) => counteragentsReducer.counteragentsList;

export const useCounteragentsList: THookSelectorFetchDataInfo<
  CounteragentsList
> = () => useGenFetchDataSelector(selectorCounteragentsList);

export const selectorAllCounteragentsList: TSelector<
  counteragentsState['counteragentAllList']
> = ({ counteragentsReducer }) => counteragentsReducer.counteragentAllList;

export const useCounteragentAllList: THookSelectorFetchDataInfo<
  AllCounteragetnList
> = () => useGenFetchDataSelector(selectorAllCounteragentsList);

export const selectorOrdList: TSelector<counteragentsState['ordList']> = ({
  counteragentsReducer,
}) => counteragentsReducer.ordList;

export const useOrdList: THookSelectorFetchDataInfo<OrdList> = () =>
  useGenFetchDataSelector(selectorOrdList);
