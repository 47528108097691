import React, { FC, useEffect } from 'react';
import { Switch, Redirect, Route, useLocation } from 'react-router-dom';
import Auth from 'domains/auth';
import { useDelLastIdCampaigns } from 'domains/campaigns/model/hooks/useLastID';
import PublicRoute from 'domains/app/PublicRouter';
import PrivateRoute from 'domains/app/PrivateRoutes';
import ControlPanel from 'views/ControlPanel';
import Error404 from '../Errors/Error404';
import AdBlocker from '../Errors/AdBlocker';
import UC from '../Errors/UnderConstruction';
import Main from '../../../views/Main';
import ConfigWrap from '../ConfigWrap';
import { useDelLastIdCreative } from '../../creative/hooks';
import NC from '../Errors/NoConnection';
import { useBlockedAd, useProjectInfo } from '../../project/hooks';
import history from '../../../lib/history';

const RootRoutes: FC = () => {
  const location = useLocation();
  const onDelLastIdCreative = useDelLastIdCreative();
  const onDelLastIdCampaigns = useDelLastIdCampaigns();

  const { data: project, LTU } = useProjectInfo();
  useEffect(() => {
    if (!location.pathname.includes('creatives')) {
      onDelLastIdCreative();
    }
    if (!location.pathname.includes('campaign')) {
      onDelLastIdCampaigns();
    }
  }, [location]);

  useEffect(() => {
    if (
      location.pathname === '/error/uc' &&
      project &&
      !project.technical_works
    ) {
      history.push('/main');
    }
    if (project && project.technical_works) {
      history.push('/error/uc');
    }
  }, [LTU]);

  const { isBlocked } = useBlockedAd();

  if (isBlocked) {
    return (
      <ConfigWrap>
        <Switch>
          <Route path="/error/ad_blocker" component={AdBlocker} />
          <Redirect to="/error/ad_blocker" />
        </Switch>
      </ConfigWrap>
    );
  }

  return (
    <ConfigWrap>
      {project && project.technical_works ? (
        <Switch>
          <Route path="/error/uc" component={UC} />
          <Redirect to="/error/uc" />
        </Switch>
      ) : (
        <Switch>
          <PublicRoute path="/auth" component={Auth} />
          <PrivateRoute path="/main" component={Main} />
          <PrivateRoute path="/control_panel" component={ControlPanel} />
          <Route path="/error/404" component={Error404} />
          <Route path="/error/uc" component={UC} />
          <Route path="/error/nc" component={NC} />
          <Redirect exact from="/" to="/main" />
          <Route component={Error404} />
        </Switch>
      )}
    </ConfigWrap>
  );
};
export default RootRoutes;
