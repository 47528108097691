import Help from 'components/Help';
import PriceInput from 'components/UI/PriceInput';
import {
  TExperimentalInfoField,
  TFormikSettigns,
} from 'domains/campaign/types';
import { FormikProps } from 'formik';
import React, { FC } from 'react';
import { checkedLocalization } from 'utils/checkedLocalization';
import css from '../styles.module.scss';

interface Props {
  fieldData: TExperimentalInfoField;
  formik: FormikProps<TFormikSettigns>;
  decimalScale: number;
  onKeyHandler?: (keyboardEvent: React.KeyboardEvent) => void;
}

const VariableNumberField: FC<Props> = ({
  fieldData,
  formik,
  decimalScale,
  onKeyHandler,
}) => (
  <div className={css.field_item}>
    <PriceInput
      classname={css.number_format}
      decimalScale={decimalScale}
      theme="horizontal"
      label={checkedLocalization(
        `campaigns_page.campaign_settings.ListSRMethods.${fieldData.field}.label`,
        fieldData.title,
      )}
      error={!!formik.errors[fieldData.id]}
      errortext={formik.errors[fieldData.id]}
      value={
        formik.values.config_values && formik.values.config_values[fieldData.id]
      }
      onKeyDown={onKeyHandler}
      onValueChange={({ floatValue }) => {
        formik.setFieldValue(`config_values.${fieldData.id}`, floatValue || 0);
      }}
    />
    {fieldData?.description !== '' && (
      <Help
        title=""
        helpattributes={{
          text: checkedLocalization(
            `campaigns_page.campaign_settings.ListSRMethods.${fieldData.field}.hint`,
            fieldData.description,
          ),
        }}
      />
    )}
  </div>
);

export default VariableNumberField;
