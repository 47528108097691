import { Ttarget } from 'domains/campaign/types';
import { TargetState } from '../reduser';
import {
  THookSelectorFetchDataInfo,
  TSelector,
  useGenFetchDataSelector,
  useGenFetchDataShallowEqualSelector,
} from '../../../redux/rootSelectors';
import {
  RGetList,
  RGetSettings,
  RGetStatisticTarget,
  RSearchGeo,
  TPopular,
  TargetKey,
  Tree,
} from './types';

/** список селекторов */
export const selectorTargetList: TSelector<TargetState['list']> = ({
  targetReducer,
}) => targetReducer.list;

export const selectorGeoTree: TSelector<TargetState['geoTree']> = ({
  targetReducer,
}) => targetReducer.geoTree;

export const selectorGeoPopular: TSelector<TargetState['geoPopular']> = ({
  targetReducer,
}) => targetReducer.geoPopular;

export const selectorGeoSearch: TSelector<TargetState['geoSearchGeoData']> = ({
  targetReducer,
}) => targetReducer.geoSearchGeoData;

export const selectorGetSettingsR: TSelector<TargetState['settings']> = ({
  targetReducer,
}) => targetReducer.settings;

export const selectorModalAuCatalog: TSelector<
  TargetState['auModalCatalog']
> = ({ targetReducer }) => targetReducer.auModalCatalog;

export const selectorGetStatisticTargetR: TSelector<
  TargetState['statisticTarget']
> = ({ targetReducer }) => targetReducer.statisticTarget;

/** список хуков */
export const selectorTargetStatistics: TSelector<
  TargetState['statisticTarget']
> = ({ targetReducer }) => targetReducer.statisticTarget;

export const selectorTargetSettings: TSelector<TargetState['settings']> = ({
  targetReducer,
}) => targetReducer.settings;

export const selectorTargetUserSettings: TSelector<TargetState['targetUserSettingsData']> = ({
  targetReducer,
}) => targetReducer.targetUserSettingsData;

export const selectorSetTargetingRequest: TSelector<
  TargetState['setRequest']
> = ({ targetReducer }) => targetReducer.setRequest;

/** Список хуков */
export const useGeoTreeInfo: THookSelectorFetchDataInfo<Tree[]> = (key) =>
  useGenFetchDataSelector(selectorGeoTree, key);

export const useGeoPopularInfo: THookSelectorFetchDataInfo<TPopular[]> = (
  key,
) => useGenFetchDataSelector(selectorGeoPopular, key);

export const useGeoSearch: THookSelectorFetchDataInfo<RSearchGeo> = () =>
  useGenFetchDataSelector(selectorGeoSearch);

export const useSettingsTargetInfo: THookSelectorFetchDataInfo<RGetSettings> = (
  key,
) => useGenFetchDataShallowEqualSelector(selectorGetSettingsR, key);

export const useModalAuCatalog: THookSelectorFetchDataInfo<RGetSettings> = () =>
  useGenFetchDataShallowEqualSelector(selectorModalAuCatalog, 'au');

export const useStatisticsTargetInfo: THookSelectorFetchDataInfo<
  RGetStatisticTarget
> = (key) =>
  useGenFetchDataShallowEqualSelector(selectorGetStatisticTargetR, key);

export const useTargetListInfo: THookSelectorFetchDataInfo<RGetList> = () =>
  useGenFetchDataShallowEqualSelector(selectorTargetList);

export const useTargetRequestInfo: THookSelectorFetchDataInfo<boolean> = (
  key,
) => useGenFetchDataSelector(selectorSetTargetingRequest, key);

export const useTargetUserSettings: THookSelectorFetchDataInfo<Record<TargetKey, Ttarget>> = () =>
  useGenFetchDataShallowEqualSelector(selectorTargetUserSettings);
