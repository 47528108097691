import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';
import avatarColors from '../../../../utils/avatarColors';
import { RGetPersonal } from '../../../../domains/campaign/types';

type Props = {
  campaignProps: RGetPersonal;
  onClick: ({
    type,
    params,
  }: {
    type: string;
    params: { campaign_xxhash: string };
  }) => void;
};

const Campaign: FC<Props> = ({
  campaignProps: { title, xxhash, internal_id },
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={css.client}
      onClick={() => {
        onClick({ type: 'creatives', params: { campaign_xxhash: xxhash } });
      }}
    >
      <div
        className={cn(css.icon)}
        style={{
          backgroundColor: avatarColors(title.slice(0, 1).toLowerCase()),
        }}
      >
        {title.slice(0, 1).toUpperCase()}
      </div>
      <div className={css.data}>
        <div className={css.title}>{title}</div>
        <div className={css.info}>
          <span>{`${t('just_words.w1')} ${internal_id}`}</span>
        </div>
      </div>
    </div>
  );
};

export default Campaign;
