import {
  THookSelectorFetchDataInfo,
  TSelector,
  useGenFetchDataSelector,
} from '../../../redux/rootSelectors';
import { AgenciesState } from '../reducer';
import { AgenciesList } from '../api';

export const selectorAgenciesList: TSelector<AgenciesState['tempAgencies']> = ({
  agenciesReducer,
}) => agenciesReducer.tempAgencies;

export const useAgenciesInfo: THookSelectorFetchDataInfo<AgenciesList> = () =>
  useGenFetchDataSelector(selectorAgenciesList);
