import React, { FC } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { ReactSVG } from 'react-svg';
import CheckCircle from 'assets/check-circle.svg';
import css from '../styles.module.scss';
import i18n from '../../../../i18n';
import TranslationInfoWrapper from '../TranslationInfoWrapper';

interface Props {
  isCreo: boolean | null;
  isTranslation: boolean | null;
  onClickHandler: () => void;
}

const ApiTranslationOn: FC<Props> = ({
  isCreo,
  isTranslation,
  onClickHandler,
}) => (
  <TranslationInfoWrapper onClickHandler={onClickHandler}>
    <>
      <div className={css.title}>
        {isTranslation ? (
          <ReactSVG src={CheckCircle} className={css.title__icon} />
        ) : (
          <FiAlertCircle
            size={20}
            color="#FF9C08"
            className={css.title__icon}
          />
        )}
        <span>
            {isCreo && isTranslation && i18n.t(`translations.adv_uploaded`)}
            {isCreo && !isTranslation && i18n.t(`translations.no_impressions`)}
            {!isCreo && isTranslation && i18n.t(`translations.campaign_ready`)}
            {!isCreo && !isTranslation && i18n.t(`translations.no_impressions`)}
          .
        </span>
      </div>
      {isCreo ? (
        <div className={css.info}>{i18n.t(`translations.see_statistics`)}</div>
      ) : (
        <div className={css.info}>{i18n.t(`translations.prepare_creo`)}</div>
      )}
    </>
  </TranslationInfoWrapper>
);

export default ApiTranslationOn;
