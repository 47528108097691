import React, { FC, useCallback, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { FiSearch, FiX } from 'react-icons/fi';
import ElementsNone from 'components/ElementsNone';
import css from './styles.module.scss';
import useSearchField from '../../hooks/useSearchField';
import PreloaderList from '../../components/Preloaders/List';
import Item from './Item';
import { useDataPartners } from './hooks';
import { IPartnersList } from './types';

const tableColumnNames = ['name', 'type', 'login', 'balance'] as const;

const Partners: FC = () => {
  const { t } = useTranslation();
  const searchRef = useRef<HTMLInputElement>(null);
  const { onChangeSearch, search, onCloseSearch, onOpenSearch, toggleSearch } =
    useSearchField(searchRef);

  const { partners, isLoadingPartners, isLoadingBalance, filterBalance } =
    useDataPartners();

  const filterAgency = useCallback(
    (data: IPartnersList): IPartnersList =>
      // eslint-disable-next-line array-callback-return,consistent-return
      data.filter((value) => {
        if (value.title.toLowerCase().includes(search.toLowerCase())) {
          return value;
        }
        if (value.internal_id.toLowerCase().includes(search.toLowerCase())) {
          return value;
        }
        if (value.xxhash.toLowerCase().includes(search.toLowerCase())) {
          return value;
        }
      }),
    [search],
  );

  const filterData = useMemo(
    () => partners && filterAgency(partners),
    [partners, filterAgency],
  );

  return (
    <div className={css.agency}>
      <Helmet>
        <title>{t('partners_page.page_title')}</title>
      </Helmet>

      <div className={cn(css.header, toggleSearch ? 'isToggleSearch' : '')}>
        <div className="container">
          <div className={css.status}>
            <h1 className={css.title}>{t('partners_page.page_title')}</h1>
            <div className={css.actions}>
              <div className={cn(css.search, toggleSearch ? 'isActive' : '')}>
                <div className={css.icon} onClick={onOpenSearch}>
                  <FiSearch size={24} />
                </div>
                <div className={css.input}>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder={t('partners_page.search_placeholder')}
                    value={search}
                    onChange={onChangeSearch}
                    ref={searchRef}
                  />
                </div>
                <div className={css.close} onClick={onCloseSearch}>
                  <FiX size={24} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!isLoadingPartners && filterData && filterData.length === 0 ? (
        <ElementsNone
          search={partners && partners.length > 0 && filterData.length === 0}
        />
      ) : (
        <div className={css.content}>
          <div className="container">
            <div className={css.table}>
              <div className={css.header}>
                {tableColumnNames.map((key) => (
                  <div key={key}>{t(`partners_page.table.${key}`)}</div>
                ))}
              </div>
              {isLoadingPartners ? (
                <div className={css.body}>
                  <PreloaderList type="partner" />
                </div>
              ) : (
                <div className={cn(css.body, 'table-body')}>
                  {filterData &&
                    filterData.map((e) => (
                      <Item
                        key={e.xxhash}
                        item={e}
                        filterBalance={filterBalance}
                        isLoadingBalance={isLoadingBalance}
                      />
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Partners;
