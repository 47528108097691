import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Button from "components/UI/Buttons/buttons";
import css from './styles.module.scss';
import { ReactComponent as GetScreenSvg } from '../icons/getScreen.svg';

interface Props {
  createScreenHandler: () => void
}

const NoScreenshot: FC<Props> = ({createScreenHandler}) => {
    const { t } = useTranslation();
    return (
          <div className={css.no_screen_wrapper}>
            <GetScreenSvg />
            <Button
              title={t('creative.screenshot.create')}
              height={36}
              buttonAttributes={{
                onClick: createScreenHandler,
              }}
            />
          </div>
    );
  };
  
  export default NoScreenshot;