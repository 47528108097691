import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from '../../../redux/fetchedData';
import { AllCounteragetnList, CounteragentsList, OrdList } from './types';

export type counteragentsState = {
  counteragentsList: FetchedData<CounteragentsList>;
  counteragentAllList: FetchedData<AllCounteragetnList>;
  ordList: FetchedData<OrdList>;
};

export const initialState: counteragentsState = {
  counteragentsList: genFetchedData<CounteragentsList>(null),
  counteragentAllList: genFetchedData<AllCounteragetnList>(null),
  ordList: genFetchedData<OrdList>(null),
};

const counteragentsSlice = createSlice({
  name: 'Counteragents',
  initialState,
  reducers: {
    setCounteragentsList(
      state,
      action: PayloadAction<counteragentsState['counteragentsList']>,
    ) {
      state.counteragentsList = action.payload;
    },
    setAllCounteragentList(
      state,
      action: PayloadAction<counteragentsState['counteragentAllList']>,
    ) {
      state.counteragentAllList = action.payload;
    },
    setOrdList(
      state,
      action: PayloadAction<counteragentsState['ordList']>,
    ) {
      state.ordList = action.payload;
    },
  },
});

export const { setCounteragentsList, setAllCounteragentList, setOrdList } = counteragentsSlice.actions;
export const counteragentsReducer = counteragentsSlice.reducer;
