/* eslint-disable jsx-a11y/label-has-associated-control */
import ModalOverlay from 'components/Modals/ModalOverlay1';
import Button from 'components/UI/Buttons/buttons';
import Card from 'components/UI/Card';
import InputAutoSave from 'components/UI/InputAutoSave';
import MultiSelectCheckbox from 'components/UI/MultiSelectCheckbox';
import React, { FC } from 'react';
import { FiX } from 'react-icons/fi';
import { GName } from 'domains/user/types/'
import { GetManagementUsersList, POptions, TFormRegistration } from 'domains/users/model/types';
import InputSelectPartners from 'components/UI/InputSelectPartners';
import css from './styles.module.scss';
import { useCreateUser } from './hooks/useCreateUser';
import i18n from '../../../../../i18n';

type TCreateUser = {
  state: boolean;
  onClose: () => void;
  mode: string;
  item?: GetManagementUsersList;
};

export interface IOption {
  label: GName,
  value: string,
}

const index: FC<TCreateUser> = ({ state, mode, onClose, item }) => {
  const {
    partnerOptions,
    roleOptions,
    selectedPartner,
    formik,
    isLoading,
    resetPassword,
  } = useCreateUser(item, onClose, mode);

  return (
    <ModalOverlay onCloseModal={onClose} isOpen={state}>
      <Card className={css.card} noHover style={{ padding: '28px' }}>
        <div className={css.card_header}>
          <div className={css.card_title}>
            {item?.partner ? (
              <h1>{i18n.t(`users_page.settings_modal.edit_title`)}</h1>
            ) : (
              <h1>{i18n.t(`users_page.settings_modal.create_title`)}</h1>
            )}
          </div>
          <div className={css.card_close} onClick={onClose}>
            <FiX size={24} />
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className={css.card_form}>
            <div className={css.card_row}>
              <InputAutoSave
                label={`${i18n.t(`email`)}*`}
                value={formik.values.email}
                isDisable={!!item?.email}
                name="email"
                maxLength={120}
                setFieldValue={formik.setFieldValue}
                onBlur={formik.handleBlur}
                handlerInputEnter={() => null}
                isError={
                  Boolean(formik.errors.email) &&
                  !item?.email &&
                  !!formik.touched.email
                }
                errorText={formik.errors.email}
                placeholder={i18n.t(`users_page.settings_modal.email`)}
              />
            </div>
            <div className={css.card_row}>
              <div className={css.card_row_label}>{`${i18n.t(
                `users_page.settings_modal.roles_title`,
              )}*`}</div>
              <div className={css.card_row_field}>
                <MultiSelectCheckbox
                  fieldName="groups"
                  isError={Boolean(
                    formik.errors.groups && !!formik.touched.groups,
                  )}
                  errorText={formik.errors.groups as string}
                  formik={formik}
                  propsValues={roleOptions}
                  placeholder={i18n.t(`users_page.settings_modal.select_roles`)}
                />
              </div>
            </div>
            <div className={css.card_row}>
              <div className={css.card_row_label}>
                {i18n.t(`users_page.settings_modal.parthner_title`)}
              </div>
              <div className={css.card_row_field}>
                <InputSelectPartners<TFormRegistration, POptions[]>
                  fieldName="partner_xxhash"
                  formik={formik}
                  propsValues={partnerOptions}
                  placeholder={i18n.t(
                    `users_page.settings_modal.select_parthner`,
                  )}
                  customValue={selectedPartner.label ? selectedPartner : null}
                />
              </div>
            </div>
            {mode === 'edit' && (
              <div className={css.card_row}>
                <div className={css.card_row_label}>
                  {i18n.t(`form.common.password`)}
                </div>
                <div className={css.reset_passwords} onClick={resetPassword}>
                  {i18n.t(`login_page.resetPass`)}
                </div>
              </div>
            )}
          </div>
          <div className={css.card_actions}>
            <Button
              title={i18n.t(`cancel_btn`)}
              transparent
              height={40}
              buttonAttributes={{
                onClick: onClose,
              }}
            />
            <Button
              title={
                mode === 'edit' ? i18n.t(`edit_btn`) : i18n.t(`create_btn`)
              }
              height={40}
              buttonAttributes={{
                disabled: isLoading,
                onClick: () => formik.handleSubmit(),
              }}
            />
          </div>
        </form>
      </Card>
    </ModalOverlay>
  );
};

export default index;
