import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useValidateSchemeCreatives = () => {
  const { t } = useTranslation();

  const mainCreative = {
    title: Yup.string().min(1, t('creative.creative_requare')),
  };

  const yandexSchema = {
    yandex_brand: Yup.string()
      .matches(/^(^(?!,)(?!.*,,))[0-9\\,]*[0-9]$/gi)
      .nullable(),
    yandex_category: Yup.string()
      .matches(/^(^(?!,)(?!.*,,)(?!.*--))[-0-9\\,]*[0-9]$/gi)
      .nullable(),
  };

  const companionSchema = {
    show_html: Yup.string().max(1024, t('errors.limitsym')),
  };

  const additional = {
    adomain: Yup.string()
      .matches(
        /[a-z0-9а-я]+([\-_.]{1}[a-z0-9а-я]+)*\.[a-zа-я]{2,20}(:[0-9]{1,5})?(\/.*)?$/m,
        t('errors.err223'),
      )
      .max(128, t('errors.limitsym'))
      .nullable(),
    pixel: Yup.string().max(1024, t('errors.limitsym')).nullable(),
    pixel_additional: Yup.string().max(257, t('errors.limitsym')).nullable(),
    show: Yup.string()
      .matches(
        /^(https:\/\/|www\.)[a-z0-9\а-я]+([\-\.]{1}[a-z0-9\а-я]+)*\.[a-z\а-я]{2,10}(:[0-9]{1,5})?(\/.*)?$/m,
        t('errors.err223_https'),
      )
      .max(1024, t('errors.limitsym')).nullable(),
    show2: Yup.string()
      .matches(
        /^(https:\/\/|www\.)[a-z0-9\а-я]+([\-\.]{1}[a-z0-9\а-я]+)*\.[a-z\а-я]{2,10}(:[0-9]{1,5})?(\/.*)?$/m,
        t('errors.err223_https'),
      )
      .max(1024, t('errors.limitsym')).nullable(),
    js_code_audit: Yup.string().max(1024, t('errors.limitsym')).nullable(),
    language: Yup.string().matches(
      /^(aa|ab|ae|af|ak|am|an|ar|as|av|ay|az|ba|be|bg|bh|bi|bm|bn|bo|br|bs|ca|ce|ch|co|cr|cs|cu|cv|cy|da|de|dv|dz|ee|el|en|eo|es|et|eu|fa|ff|fi|fj|fo|fr|fy|ga|gd|gl|gn|gu|gv|ha|he|hi|ho|hr|ht|hu|hy|hz|ia|id|ie|ig|ii|ik|io|is|it|iu|ja|jv|ka|kg|ki|kj|kk|kl|km|kn|ko|kr|ks|ku|kv|kw|ky|la|lb|lg|li|ln|lo|lt|lu|lv|mg|mh|mi|mk|ml|mn|mr|ms|mt|my|na|nb|nd|ne|ng|nl|nn|no|nr|nv|ny|oc|oj|om|or|os|pa|pi|pl|ps|pt|qu|rm|rn|ro|ru|rw|sa|sc|sd|se|sg|si|sk|sl|sm|sn|so|sq|sr|ss|st|su|sv|sw|ta|te|tg|th|ti|tk|tl|tn|to|tr|ts|tt|tw|ty|ug|uk|ur|uz|ve|vi|vo|wa|wo|xh|yi|yo|za|zh|zu)$/,
      t('errors.err47'),
    ),
    categories: Yup.object().shape({
      iab: Yup.string().matches(
        /^(^(?!.*--))[0-9\\-]*[0-9]$/gi,
        t('errors.err47'),
      ),
    }),
    algorithm_cpc: Yup.object().shape({
      min: Yup.number().max(999999, t('errors.limitsym')).nullable(),
      max: Yup.number().max(999999, t('errors.limitsym')).nullable(),
    }),
  };

  const dataCreativeVideoInline = {
    video_bitrate: Yup.number()
      .integer()
      .max(999999999, t('errors.limitsym'))
      .nullable(),
    duration: Yup.number().max(300, t('errors.limitsym')).nullable(),
    skip_duration: Yup.number()
      .integer()
      .max(300, t('errors.limitsym'))
      .nullable(),
    vast_url: Yup.string()
      .matches(
        /^(https:\/\/|www\.)[a-z0-9\а-я]+([\-\.]{1}[a-z0-9\а-я]+)*\.[a-z\а-я]{2,10}(:[0-9]{1,5})?(\/.*)?$/m,
        t('errors.err223_https'),
      )
      .nullable(),
    audit: Yup.object()
      .shape({
        show: Yup.string()
          .matches(
            /^(https:\/\/|www\.)[a-z0-9\а-я]+([\-\.]{1}[a-z0-9\а-я]+)*\.[a-z\а-я]{2,10}(:[0-9]{1,5})?(\/.*)?$/m,
            t('errors.err223_https'),
          )
          .max(1024, t('errors.limitsym')),
        show2: Yup.string()
          .matches(
            /^(https:\/\/|www\.)[a-z0-9\а-я]+([\-\.]{1}[a-z0-9\а-я]+)*\.[a-z\а-я]{2,10}(:[0-9]{1,5})?(\/.*)?$/m,
            t('errors.err223_https'),
          )
          .max(1024, t('errors.limitsym')),
        click: Yup.string()
          .matches(
            /^(https:\/\/|www\.)[a-z0-9\а-я]+([\-\.]{1}[a-z0-9\а-я]+)*\.[a-z\а-я]{2,10}(:[0-9]{1,5})?(\/.*)?$/m,
            t('errors.err223_https'),
          )
          .max(1024, t('errors.limitsym')),
        view_0: Yup.string()
          .matches(
            /^(https:\/\/|www\.)[a-z0-9\а-я]+([\-\.]{1}[a-z0-9\а-я]+)*\.[a-z\а-я]{2,10}(:[0-9]{1,5})?(\/.*)?$/m,
            t('errors.err223_https'),
          )
          .max(1024, t('errors.limitsym')),
        view_25: Yup.string()
          .matches(
            /^(https:\/\/|www\.)[a-z0-9\а-я]+([\-\.]{1}[a-z0-9\а-я]+)*\.[a-z\а-я]{2,10}(:[0-9]{1,5})?(\/.*)?$/m,
            t('errors.err223_https'),
          )
          .max(1024, t('errors.limitsym')),
        view_50: Yup.string()
          .matches(
            /^(https:\/\/|www\.)[a-z0-9\а-я]+([\-\.]{1}[a-z0-9\а-я]+)*\.[a-z\а-я]{2,10}(:[0-9]{1,5})?(\/.*)?$/m,
            t('errors.err223_https'),
          )
          .max(1024, t('errors.limitsym')),
        view_75: Yup.string()
          .matches(
            /^(https:\/\/|www\.)[a-z0-9\а-я]+([\-\.]{1}[a-z0-9\а-я]+)*\.[a-z\а-я]{2,10}(:[0-9]{1,5})?(\/.*)?$/m,
            t('errors.err223_https'),
          )
          .max(1024, t('errors.limitsym')),
        view_100: Yup.string()
          .matches(
            /^(https:\/\/|www\.)[a-z0-9\а-я]+([\-\.]{1}[a-z0-9\а-я]+)*\.[a-z\а-я]{2,10}(:[0-9]{1,5})?(\/.*)?$/m,
            t('errors.err223_https'),
          )
          .max(1024, t('errors.limitsym')),
        skip: Yup.string()
          .matches(
            /^(https:\/\/|www\.)[a-z0-9\а-я]+([\-\.]{1}[a-z0-9\а-я]+)*\.[a-z\а-я]{2,10}(:[0-9]{1,5})?(\/.*)?$/m,
            t('errors.err223_https'),
          )
          .max(1024, t('errors.limitsym')),
        mute: Yup.string()
          .matches(
            /^(https:\/\/|www\.)[a-z0-9\а-я]+([\-\.]{1}[a-z0-9\а-я]+)*\.[a-z\а-я]{2,10}(:[0-9]{1,5})?(\/.*)?$/m,
            t('errors.err223_https'),
          )
          .max(1024, t('errors.limitsym')),
        pause: Yup.string()
          .matches(
            /^(https:\/\/|www\.)[a-z0-9\а-я]+([\-\.]{1}[a-z0-9\а-я]+)*\.[a-z\а-я]{2,10}(:[0-9]{1,5})?(\/.*)?$/m,
            t('errors.err223_https'),
          )
          .max(1024, t('errors.limitsym')),
        unmute: Yup.string()
          .matches(
            /^(https:\/\/|www\.)[a-z0-9\а-я]+([\-\.]{1}[a-z0-9\а-я]+)*\.[a-z\а-я]{2,10}(:[0-9]{1,5})?(\/.*)?$/m,
            t('errors.err223_https'),
          )
          .max(1024, t('errors.limitsym')),
        rewind: Yup.string()
          .matches(
            /^(https:\/\/|www\.)[a-z0-9\а-я]+([\-\.]{1}[a-z0-9\а-я]+)*\.[a-z\а-я]{2,10}(:[0-9]{1,5})?(\/.*)?$/m,
            t('errors.err223_https'),
          )
          .max(1024, t('errors.limitsym')),
        resume: Yup.string()
          .matches(
            /^(https:\/\/|www\.)[a-z0-9\а-я]+([\-\.]{1}[a-z0-9\а-я]+)*\.[a-z\а-я]{2,10}(:[0-9]{1,5})?(\/.*)?$/m,
            t('errors.err223_https'),
          )
          .max(1024, t('errors.limitsym')),
        expand: Yup.string()
          .matches(
            /^(https:\/\/|www\.)[a-z0-9\а-я]+([\-\.]{1}[a-z0-9\а-я]+)*\.[a-z\а-я]{2,10}(:[0-9]{1,5})?(\/.*)?$/m,
            t('errors.err223_https'),
          )
          .max(1024, t('errors.limitsym')),
        collapse: Yup.string()
          .matches(
            /^(https:\/\/|www\.)[a-z0-9\а-я]+([\-\.]{1}[a-z0-9\а-я]+)*\.[a-z\а-я]{2,10}(:[0-9]{1,5})?(\/.*)?$/m,
            t('errors.err223_https'),
          )
          .max(1024, t('errors.limitsym')),
        close: Yup.string()
          .matches(
            /^(https:\/\/|www\.)[a-z0-9\а-я]+([\-\.]{1}[a-z0-9\а-я]+)*\.[a-z\а-я]{2,10}(:[0-9]{1,5})?(\/.*)?$/m,
            t('errors.err223_https'),
          )
          .max(1024, t('errors.limitsym')),
        fullscreen: Yup.string()
          .matches(
            /^(https:\/\/|www\.)[a-z0-9\а-я]+([\-\.]{1}[a-z0-9\а-я]+)*\.[a-z\а-я]{2,10}(:[0-9]{1,5})?(\/.*)?$/m,
            t('errors.err223_https'),
          )
          .max(1024, t('errors.limitsym')),
        exitfs: Yup.string()
          .matches(
            /^(https:\/\/|www\.)[a-z0-9\а-я]+([\-\.]{1}[a-z0-9\а-я]+)*\.[a-z\а-я]{2,10}(:[0-9]{1,5})?(\/.*)?$/m,
            t('errors.err223_https'),
          )
          .max(1024, t('errors.limitsym')),
        sprogresshow: Yup.string()
          .matches(
            /^(https:\/\/|www\.)[a-z0-9\а-я]+([\-\.]{1}[a-z0-9\а-я]+)*\.[a-z\а-я]{2,10}(:[0-9]{1,5})?(\/.*)?$/m,
            t('errors.err223_https'),
          )
          .max(1024, t('errors.limitsym')),
      })
      .nullable(),
  };

  const creativeValidateScheme = Yup.object().shape({
    ...mainCreative,
    ...additional,
    ...dataCreativeVideoInline,
    data: Yup.object().shape({
      ...yandexSchema,
      ...dataCreativeVideoInline,
      ...companionSchema,
    }),
  });

  return {
    yandexSchema: Yup.object().shape(yandexSchema),
    additional: Yup.object().shape(additional),
    creativeValidateScheme,
    dataCreativeVideoInline: Yup.object().shape({
      data: Yup.object().shape(dataCreativeVideoInline),
    }),
  };
};
