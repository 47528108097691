import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/UI/Tooltip';
import avatarColors from 'utils/avatarColors';
import css from './styles.module.scss';
import { useProjectInfo } from './hooks';

const Project: FC = () => {
  const { t } = useTranslation();

  const { data: project } = useProjectInfo();

  return (
    <div className={css.project}>
      <Helmet>
        <title>{t('project_page.page_title')}</title>
      </Helmet>

      <div className={cn(css.header)}>
        <div className="container">
          <div className={css.status}>
            <h1 className={css.title}>{t('project_page.page_title')}</h1>
          </div>
        </div>
      </div>
      {project && (
        <div className={css.content}>
          <div className="container">
            <div className={css.table}>
              <div className={css.header}>
                <div>{t('project_page.table.name')}</div>
                <div>{t('project_page.table.code')}</div>
                <div>{t('project_page.table.tech_domain')}</div>
                <div>{t('project_page.table.ext_domain')}</div>
              </div>
              <div className={cn(css.body, 'table-body')}>
                <div className={cn(css.item)}>
                  <Link to="/control_panel/project/settings" />
                  <div className={css.item__header}>
                    <div
                      className={css.img}
                      style={
                        project.logotype
                          ? {
                              backgroundImage: `url(${project.logotype})`,
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'cover',
                            }
                          : {
                              backgroundColor: avatarColors(
                                project.title.slice(0, 1).toLowerCase(),
                              ),
                            }
                      }
                    >
                      {project.logotype
                        ? ''
                        : project.title.slice(0, 1).toUpperCase()}
                    </div>
                    <div className={css.text}>
                      <Tooltip
                        className={css.tooltip}
                        hintWidth={300}
                        leftArrowPosition
                        title={<strong>{project.title}</strong>}
                      >
                        <p>{project.title}</p>
                      </Tooltip>
                    </div>
                  </div>

                  <div className={cn(css.value)}>
                    <span>{project.code}</span>
                  </div>
                  <div className={cn(css.value)}>
                    <span>{project.tech_domain}</span>
                  </div>
                  <div className={cn(css.value)}>
                    <span>{project.ext_domain}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Project;
