import { FormikErrors } from 'formik/dist/types';
import { Tlimits } from '../../types';

export function hasErrorsLimits(
  obj: FormikErrors<{ limits: Tlimits }>,
): boolean {
  const tabs = [
    'click',
    'show',
    'show_per_user',
    'budget',
    'budget_raw',
  ] as const;
  let result = false;
  // eslint-disable-next-line no-restricted-syntax
  for (const v of tabs) {
    if (Object.values(obj.limits?.[v] ?? {}).filter(Boolean).length) {
      result = true;
      break;
    }
  }
  return result;
}
