import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from '../../redux/fetchedData';
import {
  NotificationMessages,
  SubscripbeRequest,
  Subscriptions,
} from './types';

export type NotificationState = {
  messages: FetchedData<NotificationMessages>;
  subscriptions: FetchedData<Subscriptions>;
  isErrorBrowserNotification: boolean;
};
const initialState: NotificationState = {
  messages: genFetchedData<NotificationMessages>(null),
  subscriptions: genFetchedData<Subscriptions>(null),
  isErrorBrowserNotification: false,
};

const NotificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotificationMessages(
      state,
      action: PayloadAction<NotificationState['messages']>,
    ) {
      state.messages = action.payload;
    },
    setNotificationSubscriptions(
      state,
      action: PayloadAction<NotificationState['subscriptions']>,
    ) {
      state.subscriptions = action.payload;
    },
    setBrowserErrorNotification(
      state,
      action: PayloadAction<NotificationState['isErrorBrowserNotification']>,
    ) {
      state.isErrorBrowserNotification = action.payload;
    },
  },
});

export const fetchNotificationMessageRequest = createAction(
  'notification/fetchNotificationMessageRequest',
);

export const fetchNotificationSubscriptionsRequest = createAction(
  'notification/fetchNotificationSubscriptionsRequest',
);
export const editSubscriptionRequest = createAction<SubscripbeRequest>(
  'notification/editSubscriptionRequest',
);

export const {
  setBrowserErrorNotification,
  setNotificationMessages,
  setNotificationSubscriptions,
} = NotificationSlice.actions;
export const notificationReducer = NotificationSlice.reducer;
