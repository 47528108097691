import { createSelector } from '@reduxjs/toolkit';
import {
  ResponseTranslation,
  RStatisticsCampaigns,
  TotalResponse,
} from 'domains/campaigns/types';
import {
  THookSelectorFetchDataInfo,
  THookStateInfo,
  TSelector,
  useGenFetchDataSelector,
  useGenFetchDataShallowEqualSelector,
  useSelectorTypes,
  useShallowEqualSelector,
} from '../../../redux/rootSelectors';
import {
  RResultSuccess,
  TInternalItem,
  TPropsSelector,
} from '../../../types/general';
import {
  CreativeDetail,
  CreativeItemListWithStatistics,
  CreativeList,
  getCustomerByEridParams,
  Moderation,
  ModerationResultData,
  RAlgorithmCreativeList,
  RCreativeTags,
  RGetCreativeStatistics,
  TRCreativeTypes,
  TScreenStatus,
} from '../types';
import { CreativeState } from '../reduser';
import { extractGenFetchData } from '../../../redux/fetchedData';
import { extractMakeGenList } from '../../../redux/makeGenList';
import { RPreTargeting, TExperimentalInfoField } from '../../campaign/types';

/** список селекторов */
export const selectorCreative: TSelector<CreativeState> = ({
  creativeReducer,
}) => creativeReducer;

export const selectorCreativeTranslation: TSelector<
  CreativeState['creativesTranslation']
> = ({ creativeReducer }) => creativeReducer.creativesTranslation;

export const selectorCreativeList: TSelector<CreativeState['list']> = ({
  creativeReducer,
}) => creativeReducer.list;

export const selectorCreativeSumStatistics: TSelector<
  CreativeState['statisticsSumList']
> = ({ creativeReducer }) => creativeReducer.statisticsSumList;

export const selectorCreativesTranslation: TSelector<
  CreativeState['creativesTranslation']
> = ({ creativeReducer }) => creativeReducer.creativesTranslation;

export const selectorCreativeStatisticsList: TSelector<
  CreativeState['statisticsList']
> = ({ creativeReducer }) => creativeReducer.statisticsList;

export const selectorCreativeListView: TSelector<CreativeState['listView']> = ({
  creativeReducer,
}) => creativeReducer.listView;

export const selectorCreativeLastID: TSelector<CreativeState['lastId']> = ({
  creativeReducer,
}) => creativeReducer.lastId;

export const selectorModeration: TSelector<CreativeState['moderation']> = ({
  creativeReducer,
}) => creativeReducer.moderation;

export const selectorModerationResult: TSelector<
  CreativeState['moderationResult']
> = ({ creativeReducer }) => creativeReducer.moderationResult;

export const selectorCreativeTags: TSelector<CreativeState['tags']> = ({
  creativeReducer,
}) => creativeReducer.tags;

export const selectorCreativeDetail: TSelector<CreativeState['element']> = ({
  creativeReducer,
}) => creativeReducer.element;

export const selectorStatisticsCreative: TSelector<
  CreativeState['statisticsCreative']
> = ({ creativeReducer }) => creativeReducer.statisticsCreative;

export const selectorCreativeResponse: TSelector<CreativeState['response']> = ({
  creativeReducer,
}) => creativeReducer.response;

export const selectorAllTags: TSelector<CreativeState['allTags']> = ({
  creativeReducer,
}) => creativeReducer.allTags;

export const selectorActiveTag: TSelector<CreativeState['activeTags']> = ({
  creativeReducer,
}) => creativeReducer.activeTags;

export const selectorCreativeAlgorithmsCPA: TSelector<
  CreativeState['algorithmsCPA']
> = ({ creativeReducer }) => creativeReducer.algorithmsCPA;

export const selectorIsOpenPreviewModal: TSelector<
  CreativeState['isOpenPreviewModal']
> = ({ creativeReducer }) => creativeReducer.isOpenPreviewModal;

export const selectorCreativeModalList: TSelector<
  CreativeState['listModal']
> = ({ creativeReducer }) => creativeReducer.listModal;

export const selectorCreativePretargeting: TSelector<
  CreativeState['preTargeting']
> = ({ creativeReducer }) => creativeReducer.preTargeting;

export const selectorConfig: TSelector<CreativeState['config']> = ({
  creativeReducer,
}) => creativeReducer.config;

export const selectorCreativeTypes: TSelector<
  CreativeState['creativeTypes']
> = ({ creativeReducer }) => creativeReducer.creativeTypes;

export const selectorTranslationFilter: TSelector<
  CreativeState['creoTranslationFilter']
> = ({ creativeReducer }) => creativeReducer.creoTranslationFilter;

export const selectorListSort: TSelector<CreativeState['listSort']> = ({
  creativeReducer,
}) => creativeReducer.listSort;

export const selectorScreenStatus: TSelector<CreativeState['screenStatus']> = ({
  creativeReducer,
}) => creativeReducer.screenStatus;

export const selectorCurrentGridEridInfo: TSelector<CreativeState['currentGridEridInfo']> = ({
  creativeReducer,
}) => creativeReducer.currentGridEridInfo;

/** список хуков */

export const useCreativeStatisticsListInfo: THookSelectorFetchDataInfo<
  RStatisticsCampaigns
> = () =>
  useGenFetchDataSelector<
    CreativeState['statisticsList'],
    RStatisticsCampaigns
  >(selectorCreativeStatisticsList);

export const useCreativeListViewInfo: THookStateInfo<
  CreativeState['listView']
> = () =>
  useShallowEqualSelector<CreativeState['listView']>(selectorCreativeListView);

export const selectorVendorLinks: TSelector<CreativeState['vendorLinks']> = ({
  creativeReducer,
}) => creativeReducer.vendorLinks;

export const selectCreativeView = createSelector(
  selectorCreative,
  ({ listView, list }): TPropsSelector<CreativeItemListWithStatistics[]> => {
    const {
      listView: dataList,
      isLoading: isLoadingView,
      isBlocked,
    } = extractMakeGenList<CreativeItemListWithStatistics[]>(listView);
    const { data, isLoading, LTU, error } = extractGenFetchData(list);
    return {
      list: dataList,
      isLoadingView,
      isBlocked,
      serverList: data,
      isLoading,
      error,
      LTU,
    };
  },
);

export const useModerationInfo: THookSelectorFetchDataInfo<Moderation> = () =>
  useGenFetchDataShallowEqualSelector(selectorModeration);

export const useModerationResultInfo: THookSelectorFetchDataInfo<
  ModerationResultData
> = () => useGenFetchDataShallowEqualSelector(selectorModerationResult);

export const useCreativeListInfo: THookSelectorFetchDataInfo<
  CreativeItemListWithStatistics[]
> = () => useGenFetchDataShallowEqualSelector(selectorCreativeList);

export const useCreativeTagsInfo: THookSelectorFetchDataInfo<
  RCreativeTags
> = () => useGenFetchDataShallowEqualSelector(selectorCreativeTags);

export const useCreativeDetailInfo: THookSelectorFetchDataInfo<
  CreativeDetail
> = () => useGenFetchDataShallowEqualSelector(selectorCreativeDetail);

export const useCreativesInfo: THookSelectorFetchDataInfo<
  CreativeItemListWithStatistics[]
> = () => useGenFetchDataShallowEqualSelector(selectorCreativeList);

export const useCreativeStatisticsInfo: THookSelectorFetchDataInfo<
  RGetCreativeStatistics[]
> = () => useGenFetchDataShallowEqualSelector(selectorStatisticsCreative);

export const useCreativeResponseInfo: THookSelectorFetchDataInfo<
  RResultSuccess
> = () => useGenFetchDataSelector(selectorCreativeResponse);

export const useCreativeAllTagsInfo: THookSelectorFetchDataInfo<
  RCreativeTags
> = () => useGenFetchDataShallowEqualSelector(selectorAllTags);

export const useCreativeActiveTag: THookStateInfo<
  CreativeState['activeTags']
> = () => useSelectorTypes(selectorActiveTag);

export const useCreativeAlgorithmsCPAInfo: THookSelectorFetchDataInfo<
  RAlgorithmCreativeList
> = () => useGenFetchDataShallowEqualSelector(selectorCreativeAlgorithmsCPA);

export const useCreativeListModalInfo: THookSelectorFetchDataInfo<
  CreativeList
> = () => useGenFetchDataSelector(selectorCreativeModalList);

export const useCreativeVendorLinksInfo: THookSelectorFetchDataInfo<
  TInternalItem[]
> = () => useGenFetchDataSelector(selectorVendorLinks);

export const useCreativePretargetingInfo: THookSelectorFetchDataInfo<
  RPreTargeting | []
> = () => useGenFetchDataSelector(selectorCreativePretargeting);

export const useCreativeConfig: THookSelectorFetchDataInfo<
  TExperimentalInfoField[] | []
> = () => useGenFetchDataSelector(selectorConfig);

export const useCreativeTypes: THookSelectorFetchDataInfo<
  TRCreativeTypes
> = () => useGenFetchDataSelector(selectorCreativeTypes);

export const useCreativesTranslation: THookSelectorFetchDataInfo<
  Record<string, ResponseTranslation>
> = () => useGenFetchDataSelector(selectorCreativesTranslation);

export const useCreativesTotal: THookSelectorFetchDataInfo<
  TotalResponse
> = () => useGenFetchDataSelector(selectorCreativeSumStatistics);

export const useScreenStatus: THookSelectorFetchDataInfo<TScreenStatus> = () =>
  useGenFetchDataSelector(selectorScreenStatus);

export const useCurrentGridEridInfo: THookSelectorFetchDataInfo<string[] | getCustomerByEridParams> = () =>
  useGenFetchDataSelector(selectorCurrentGridEridInfo); 
