/** Функция управляет состоянием модального окна */
import { useCallback } from 'react';
import { isShowModalChangeBwListUpdate, setCleanLoadList } from '../../reducer';
import { useDispatchApp } from '../../../../redux/rootSelectors';
import { useBwListModalInfo } from '../../model/selectors';

type TFuseChangeOpenModalBwListUpdate = (props: boolean) => () => void;
type TFUseModalBwListUpdate = () => {
  isShowModal: boolean;
  closeModal: () => void;
  openModal: () => void;
  closeReset: () => void;
};

export const useChangeOpenModalBwListUpdate: TFuseChangeOpenModalBwListUpdate =
  (param) => {
    const dispatch = useDispatchApp();
    return useCallback(() => {
      dispatch(isShowModalChangeBwListUpdate(param));
    }, [dispatch]);
  };

export const useModalBwListUpdate: TFUseModalBwListUpdate = () => {
  const dispatch = useDispatchApp();
  const isShowModal = useBwListModalInfo();

  const closeModal: ReturnType<TFUseModalBwListUpdate>['closeModal'] =
    useChangeOpenModalBwListUpdate(false);
  const openModal: ReturnType<TFUseModalBwListUpdate>['openModal'] =
    useChangeOpenModalBwListUpdate(true);
  const closeReset: ReturnType<TFUseModalBwListUpdate>['closeReset'] = () => {
    closeModal();
    dispatch(setCleanLoadList());
  };

  return {
    isShowModal,
    closeModal,
    openModal,
    closeReset,
  };
};
