import React, { FC } from 'react';
import cn from 'classnames';
import { Field, FormikHelpers } from 'formik';
import { MdInfoOutline } from 'react-icons/md';
import css from './styles.module.scss';

interface Options {
  title: string;
  value: boolean | string | number | null;
}

type Props = {
  edit?: boolean;
  label: string;
  name: string;
  options: Options[];
  errorText?: string;
  hasError?: boolean | undefined;
  checked?: Options['value'];
  setFieldValue: FormikHelpers<string>['setFieldValue'];
  handleSubmit?: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  notField?: boolean;
  className?: string;
  autoSubmit?: boolean;
};

const Radio: FC<Props> = ({
  label,
  options,
  errorText,
  hasError,
  checked,
  setFieldValue,
  handleSubmit,
  notField,
  className,
  edit = true,
  autoSubmit = false,
  ...rest
}) => (
  <div
    className={cn(
      css.radio,
      'radio_group',
      { _error: hasError, _notField: notField, _isNoEdit: !edit },
      className,
    )}
  >
    {label && (
      <div className={css.header}>
        <span>{label}</span>
      </div>
    )}
    <div className={cn(css.list, 'radio_group-list')}>
      {options.map(({ title, value }) => (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label key={title} className={cn(css.item, 'item')}>
          <Field
            type="radio"
            {...rest}
            value={value}
            onChange={() => {
              if(edit) setFieldValue(rest.name, value)
              if(autoSubmit && handleSubmit) handleSubmit()
            }}
            checked={checked === value}
          />
          <span>{title}</span>
        </label>
      ))}
    </div>
    {errorText && (
      <div className={css.errorText}>
        <MdInfoOutline size={20} />
        {errorText}
      </div>
    )}
  </div>
);

export default Radio;
