import React, { FC } from 'react';
import cn from 'classnames';
import Card from 'components/UI/Card';
import Load from 'components/UI/Load';
import css from '../styles.module.scss';

const LoaderData: FC = () => (
  <Card isUseAccess className={cn(css.card, css.data)} noHover>
    <Load
      style={{
        width: '140px',
      }}
      classname={css.title}
    />
    <Load
      style={{
        height: '42px',
        width: '100%',
      }}
    />
    <Load
      style={{
        height: '42px',
        width: '100%',
      }}
    />
    <Load
      style={{
        height: '42px',
        width: '100%',
      }}
    />
    <Load
      style={{
        height: '42px',
        width: '100%',
      }}
    />
    <Load
      style={{
        height: '42px',
        width: '100%',
      }}
    />
  </Card>
);

export default LoaderData;
