import { fetchAgencies } from 'domains/agencies/reducer';
import { useUserInfo } from 'domains/user/model/selectors';
import { FormikProps, useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatchApp } from 'redux/rootSelectors';
import * as Yup from 'yup';
import { ResponseUser } from 'domains/user/types';
import deepEqual from 'deep-equal';
import i18next from 'i18next';
import { getOrdList } from 'domains/counteragents/model/actions';
import { CounteragentFormikProps, ObjectCuster } from '../types';
import { createCounteragent } from '../actions';

const validPair: [string, string][] = [
  ['mobilePhone', 'epayNumber'],
  ['regNumber', 'alternativeInn'],
];

type KeysFields = keyof ObjectCuster;

interface UseCreateCounteragentReturn {
  links: {
    title: string;
    url: string;
    type: string;
  }[];
  formik: FormikProps<CounteragentFormikProps>;
  userData: ResponseUser | null;
  isLoading: boolean;
  isPrompt: boolean;
  goBack: () => void;
  handleChangeInput: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
}

export const useCreateCounteragent = (): UseCreateCounteragentReturn => {
  const { i18n } = useTranslation();

  const { data: userData } = useUserInfo();

  const [isLoading, setIsLoading] = useState(false);
  const [disablePrompt, setDisablePrompt] = useState(false);

  const isOrganization = userData?.partner.type === 'ORGANIZATION';

  const dispatch = useDispatchApp();

  useEffect(() => {
    dispatch(getOrdList());
  }, [])
  

  useEffect(() => {
    if (isOrganization) {
      dispatch(fetchAgencies({}));
    }
  }, [userData]);

  const history = useHistory();

  const goBack = () => {
    history.push('/main/counteragents');
  };

  const goBackSubmit = () => {
    setDisablePrompt(true);
    goBack();
  };

  const removeEmptyFields = (
    obj: ObjectCuster,
    keysToRemove: KeysFields[],
  ): ObjectCuster => {
    const newObj = { ...obj };
    const keys = Object.keys(obj) as KeysFields[];
    keys.forEach((key) => {
      if (keysToRemove.includes(key) && obj[key] === '') {
        delete newObj[key];
      }
    });
    return newObj;
  };

  const formik = useFormik<CounteragentFormikProps>({
    initialValues: {
      agency_xxhash_list: null,
      name: '',
      aliases: '',
      exportName: '',
      inn: '',
      type: '',
      ord: 4,
      mobilePhone: '',
      epayNumber: '',
      regNumber: '',
      alternativeInn: '',
      oksmNumber: '',
      directClient: false,
    },
    validationSchema: Yup.object().shape(
      {
        agency_xxhash_list: Yup.array()
        .when([], (array, schema) => {
          if (isOrganization) {
            return schema
              .min(1, i18next.t(`ord.errors.required_field`))
              .required(i18next.t(`ord.errors.required_field`));
          }
          return schema;
        })
        .nullable(),
        name: Yup.string()
          .required(i18next.t(`ord.errors.required_field`))
          .trim()
          .min(1, i18next.t(`ord.errors.min_1_character`))
          .max(255, i18next.t(`ord.errors.max_255_characters`)),
        aliases: Yup.string().max(
          1000,
          i18next.t(`ord.errors.max_1000_characters`),
        ),
        exportName: Yup.string(),
        type: Yup.string().required(i18next.t(`ord.errors.required_field`)),
        inn: Yup.string().when('type', {
          is: (val: string) => ['ul', 'fl', 'ip'].includes(val),
          then: (schema) =>
            schema
              .required(i18next.t(`ord.errors.required_field`))
              .matches(/^\d+$/, i18next.t(`ord.errors.field_only_digits`))
              .min(6, i18next.t(`ord.errors.min_6_characters`))
              .max(40, i18next.t(`ord.errors.max_40_characters`)),
          otherwise: (schema) => schema.notRequired(),
        }),

        mobilePhone: Yup.string().when('type', {
          is: (type: string) => type === 'ffl',
          then: (schema) =>
            schema.when('epayNumber', {
              is: (epayNumber: string) => !epayNumber,
              then: (schemaE) =>
                schemaE
                  .required(i18next.t(`ord.errors.fill_field_or_epay`))
                  .matches(/^\d+$/, i18next.t(`ord.errors.field_only_digits`)),
              otherwise: (schemaOE) => schemaOE.notRequired(),
            }),
          otherwise: (schema) => schema.notRequired(),
        }),

        epayNumber: Yup.string().when('type', {
          is: (type: string) => type === 'ffl',
          then: (schema) =>
            schema.when('mobilePhone', {
              is: (mobilePhone: string) => !mobilePhone,
              then: (schemaM) =>
                schemaM
                  .required(i18next.t(`ord.errors.fill_field_or_phone`))
                  .matches(
                    /^[a-zA-Z0-9]*$/,
                    i18next.t(`ord.errors.field_only_latin_letters`),
                  ),
              otherwise: (schemaM) => schemaM.notRequired(),
            }),

          otherwise: (schema) => schema.notRequired(),
        }),

        regNumber: Yup.string().when('type', {
          is: (type: string) => type === 'ful',
          then: (schema) =>
            schema.when('alternativeInn', {
              is: (alternativeInn: string) => !alternativeInn,
              then: (schemaA) =>
                schemaA
                  .required(i18next.t(`ord.errors.fill_filed_or_equivalent`))
                  .matches(
                    /^[a-zA-Z0-9]*$/,
                    i18next.t(`ord.errors.field_only_latin_letters`),
                  ),
              otherwise: (schemaA) => schemaA.notRequired(),
            }),
          otherwise: (schema) => schema.notRequired(),
        }),

        alternativeInn: Yup.string().when('type', {
          is: (type: string) => type === 'ful',
          otherwise: (schema) => schema.notRequired(),
          then: (schema) =>
            schema.when('regNumber', {
              is: (regNumber: string) => !regNumber,
              then: (schemaR) =>
                schemaR
                  .required(i18next.t(`ord.errors.fill_field_or_number`))
                  .matches(
                    /^[a-zA-Z0-9]*$/,
                    i18next.t(`ord.errors.field_only_latin_letters`),
                  ),
            }),
        }),
        ord: Yup.number().required(i18next.t(`ord.errors.required_field`)),
        oksmNumber: Yup.string(),
        directClient: Yup.boolean(),
      },
      validPair,
    ),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: (values) => {
      if (!userData) return;

      const { agency_xxhash_list, ...objectCuster } = values;

      const data = removeEmptyFields(objectCuster, [
        'inn',
        'alternativeInn',
        'regNumber',
        'epayNumber',
        'oksmNumber',
        'mobilePhone',
      ]);

      const params = {
        agency_xxhash_list: isOrganization
          ? agency_xxhash_list?.map((agency) => agency.value) || []
          : [userData.partner.xxhash],
        objectCuster: data,
        callbacks: {
          setIsLoading,
          goBack: goBackSubmit,
        },
      };

      if (params) {
        dispatch(createCounteragent(params));
      }
    },
  });

  // очищение вариативных полей
  useEffect(() => {
    const updatedErrors = { ...formik.errors };
    validPair.forEach((item) =>
      item.forEach((fieldName) => {
        formik.setFieldValue(fieldName, '');
        delete updatedErrors[fieldName];
      }),
    );
    formik.setFieldValue('inn', '');
    delete updatedErrors.inn;
    formik.setFieldValue('oksmNumber', '');
    formik.setErrors(updatedErrors);
  }, [formik.values.type]);

  const handleChangeInput = (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => {
    formik
      .setFieldValue(field, value, shouldValidate)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .then(async () => {
        const validPairFlat = validPair.flat();

        if (validPairFlat.includes(field)) {
          validPairFlat.forEach(formik.validateField);
          return;
        }
        formik.validateField(field);
      });
  };

  const links = useMemo(
    () => [
      {
        title: i18n.t(`ord.сounterparties_title`),
        url: `/main/counteragents`,
        type: 'home',
      },
      {
        title: i18n.t(`ord.counterparte_creating`),
        url: `заглушка`,
        type: 'end',
      },
    ],
    [i18n.language],
  );

  const isPrompt =
    !disablePrompt && !deepEqual(formik.values, formik.initialValues);

  return {
    links,
    formik,
    isLoading,
    userData,
    isPrompt,
    goBack,
    handleChangeInput,
  };
};
