import React, { FC } from 'react';
import { TGroup } from 'types/general';
import css from '../../styles.module.scss';
import i18n from '../../../../i18n';

type HeaderProps = {
  isWarning: boolean;
  subTitle?: string;
  from: TGroup;
};

const Header: FC<HeaderProps> = ({ isWarning, subTitle, from }) => {
  if (isWarning) {
    return (
      <div className={css.title}>
        <h1>{i18n.t(`campaigns_page.campaigns_copy_modal.error_copy`)}</h1>
      </div>
    );
  }
  
  return (
    <div className={css.title}>
      <h1>{i18n.t('campaigns_page.campaigns_copy_modal.title')}</h1>
      {from.length === 1 && from[0].internal_id && (
        <div className={css.name}>
          {`[${from[0].internal_id}] ${from[0].title}`}
        </div>
      )}

      {subTitle && <p>{subTitle}</p>}
    </div>
  );
};

export default Header;
