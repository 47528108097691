import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { TGetTechnicalInfo } from 'types/general';
import { fetchGetTechnicalInfo, refreshTokenAPIUser } from '../actions';
import { useGetTechnicalInfo } from '../selectors';
import useFetchUserFormData from './useFetchUserFormData';

interface RUseFetchUserFormData {
  isLoading: boolean;
  currentUser: TGetTechnicalInfo | null;
  email: string;
  updateToken: () => void;
}

const useFetchUserEditFormData = (): RUseFetchUserFormData => {
  const dispatch = useDispatch();
  const location = useLocation();
  const paramHash = new URLSearchParams(location.search);
  const email = paramHash.get('email') || '';

  const { data: currentUser, isLoading: usersIsLoading } =
    useGetTechnicalInfo();

  const { isLoading: isLoadingPartners } = useFetchUserFormData();

  const updateToken = () => {
    dispatch(refreshTokenAPIUser({ email }));
  };

  useEffect(() => {
    dispatch(fetchGetTechnicalInfo({ email }));
  }, []);

  return {
    isLoading: isLoadingPartners || usersIsLoading,
    currentUser,
    email,
    updateToken,
  };
};

export default useFetchUserEditFormData;
