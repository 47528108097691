import { ReactSVG } from 'react-svg';
import React from 'react';
import sourcesSvg from '../../../../components/Target/Item/icons/sources.svg';
import geoSvg from '../../../../components/Target/Item/icons/geo.svg';
import domainsSvg from '../../../../components/Target/Item/icons/domains.svg';
import actionsDomainsSvg from '../../../../components/Target/Item/icons/actions_domain.svg';
import auditorySvg from '../../../../components/Target/Item/icons/auditory.svg';
import osSvg from '../../../../components/Target/Item/icons/os.svg';
import directSvg from '../../../../components/Target/Item/icons/direct.svg';
import appSvg from '../../../../components/Target/Item/icons/app.svg';
import viewabilitySvg from '../../../../components/Target/Item/icons/viewability.svg';
import uniqShowSvg from '../../../../components/Target/Item/icons/uniqshow.svg';
import uniqShowHourSvg from '../../../../components/Target/Item/icons/uniqshowhour.svg';
import uniqShowDaySvg from '../../../../components/Target/Item/icons/uniqshowday.svg';
import actionsSvg from '../../../../components/Target/Item/icons/actions.svg';
import ispSvg from '../../../../components/Target/Item/icons/isp.svg';
import clockSvg from '../../../../components/Target/Item/icons/clock.svg';
import binAuSvg from '../../../../components/Target/Item/icons/bin_au.svg';
import videoTypeSvg from '../../../../components/Target/Item/icons/videoType.svg';
import browserSvg from '../../../../components/Target/Item/icons/browser.svg';
import daySvg from '../../../../components/Target/Item/icons/day.svg';
import sitelangSvg from '../../../../components/Target/Item/icons/sitelang.svg';
import adgroupSvg from '../../../../components/Target/Item/icons/adgroup.svg';
import keywordsSvg from '../../../../components/Target/Item/icons/keywords.svg';
import videoSizeSvg from '../../../../components/Target/Item/icons/video_size.svg';
import pagecontextSvg from '../../../../components/Target/Item/icons/page_context.svg';
import ageSvg from '../../../../components/Target/Item/icons/age.svg';
import sexSvg from '../../../../components/Target/Item/icons/sex.svg';
import socdemSvg from '../../../../components/Target/Item/icons/socdem.svg';
import uniqCampaignSvg from '../../../../components/Target/Item/icons/uniq_campaign.svg';
import adnSvg from '../../../../components/Target/Item/icons/adn.svg';
import publisherSvg from '../../../../components/Target/Item/icons/publisher.svg';
import aushopSvg from '../../../../components/Target/Item/icons/au_shop.svg';
import categoriesRtbSvg from '../../../../components/Target/Item/icons/categories_rtb.svg';
import spotidSvg from '../../../../components/Target/Item/icons/spotid.svg';
import rewardedSvg from '../../../../components/Target/Item/icons/rewarded.svg';
import fullscreenSvg from '../../../../components/Target/Item/icons/fullscreen.svg';
import traffic_type_Svg from '../../../../components/Target/Item/icons/traffic_type.svg';
import { TargetKey } from '../types';

export const icons: Map<TargetKey, JSX.Element> = new Map([
  ['keywords', <ReactSVG src={keywordsSvg} />],
  ['source', <ReactSVG src={sourcesSvg} />],
  ['geo_id', <ReactSVG src={geoSvg} />],
  ['geo_raw', <ReactSVG src={geoSvg} />],
  ['au', <ReactSVG src={auditorySvg} />],
  ['os', <ReactSVG src={osSvg} />],
  ['private_deals', <ReactSVG src={directSvg} />],
  ['app', <ReactSVG src={appSvg} />],
  ['last_bundle', <ReactSVG src={appSvg} />],
  ['viewability', <ReactSVG src={viewabilitySvg} />],
  ['viewability_city', <ReactSVG src={viewabilitySvg} />],
  ['uniq_show_creative', <ReactSVG src={uniqShowSvg} />],
  ['uniq_show_creative_hour', <ReactSVG src={uniqShowHourSvg} />],
  ['uniq_show_creative_day', <ReactSVG src={uniqShowDaySvg} />],
  ['isp', <ReactSVG src={ispSvg} />],
  ['site_uri', <ReactSVG src={domainsSvg} />],
  ['last_domain', <ReactSVG src={domainsSvg} />],
  ['site_lang', <ReactSVG src={sitelangSvg} />],
  ['site_domain', <ReactSVG src={domainsSvg} />],
  ['actions_domain', <ReactSVG src={actionsDomainsSvg} />],
  ['hours_of_day', <ReactSVG src={clockSvg} />],
  ['hours_of_day_user', <ReactSVG src={clockSvg} />],
  ['video_type', <ReactSVG src={videoTypeSvg} />],
  ['au_bin_8', <ReactSVG src={binAuSvg} />],
  ['au_bin_15', <ReactSVG src={binAuSvg} />],
  ['au_bin_19', <ReactSVG src={binAuSvg} />],
  ['au_bin_20', <ReactSVG src={binAuSvg} />],
  ['au_bin_21', <ReactSVG src={binAuSvg} />],
  ['au_bin_info', <ReactSVG src={binAuSvg} />],
  ['au_bin_lada', <ReactSVG src={binAuSvg} />],
  ['browser', <ReactSVG src={browserSvg} />],
  ['browser_lang', <ReactSVG src={sitelangSvg} />],
  ['days_of_week', <ReactSVG src={daySvg} />],
  ['days_of_week_user', <ReactSVG src={daySvg} />],
  ['adgroup', <ReactSVG src={adgroupSvg} />],
  ['page_context', <ReactSVG src={pagecontextSvg} />],
  ['socdem', <ReactSVG src={socdemSvg} />],
  ['categories_rtb', <ReactSVG src={categoriesRtbSvg} />],
  ['uroven_dohoda', <ReactSVG src={socdemSvg} />],
  ['semeinoe_polozhenie', <ReactSVG src={socdemSvg} />],
  ['interesy', <ReactSVG src={socdemSvg} />],
  ['uniq_show_camp', <ReactSVG src={uniqCampaignSvg} />],
  ['uniq_show_camp_city', <ReactSVG src={uniqCampaignSvg} />],
  ['uniq_show_camp_hour', <ReactSVG src={uniqShowHourSvg} />],
  ['uniq_show_camp_day', <ReactSVG src={uniqShowDaySvg} />],
  ['actions', <ReactSVG src={actionsSvg} />],
  ['actions_city', <ReactSVG src={actionsSvg} />],
  ['video_size', <ReactSVG src={videoSizeSvg} />],
  ['sex', <ReactSVG src={sexSvg} />],
  ['age', <ReactSVG src={ageSvg} />],
  ['sex_rtb', <ReactSVG src={sexSvg} />],
  ['age_rtb', <ReactSVG src={ageSvg} />],
  ['adn', <ReactSVG src={adnSvg} />],
  ['publisher', <ReactSVG src={publisherSvg} />],
  ['au_shop', <ReactSVG src={aushopSvg} />],
  ['spotid', <ReactSVG src={spotidSvg} />],
  ['rewarded', <ReactSVG src={rewardedSvg} />],
  ['video_size_site_domain', <ReactSVG src={videoSizeSvg} />],
  ['fullscreen', <ReactSVG src={fullscreenSvg} />],
  ['network', <ReactSVG src={sourcesSvg} />],
  ['traffic_type', <ReactSVG src={traffic_type_Svg} />],
]);
