import React, { FC, useState } from 'react';
import { Form, FormikProvider } from 'formik';
import equal from 'deep-equal';
import EditCard from 'components/UI/EditCard';
import Input from 'components/UI/Input/input';
import { useGetFieldConfigValue } from 'domains/creative/creatives/hooks/useGetFieldConfigValue';
import { useCheckGridEridInfo } from 'domains/creative/creatives/hooks/useCheckGridEridInfo';
import EridWarning from 'components/EridWarning';
import css from '../styles.module.scss';
import { CreativeDetail } from '../../../../../types';
import { useFormClickunder } from './hooks';

interface Props {
  xxhash: CreativeDetail['xxhash'];
  data: CreativeDetail['data'];
  config_values: CreativeDetail['config_values'];
}

const FormType: FC<Props> = ({ xxhash, data, config_values }) => {
  const [edit, setEdit] = useState(false);
  const erid = useGetFieldConfigValue('erid', 'CLICKUNDER');

  const { formik, handlerFormikReset, handlerFormikSubmit } = useFormClickunder(
    {
      xxhash,
      erid: (erid && config_values[erid.id]) || '',
      setEdit,
      edit,
    },
  );

  const { isDisableSaveButton, setIsDisableSaveButton } = useCheckGridEridInfo({
    eridData: erid,
    eridVal: formik.values.erid,
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <EditCard
          className={css.card}
          edited={edit}
          noHover
          noShadow
          hasErrors={Object.keys(formik.errors).length > 0 || isDisableSaveButton}
          onResetForm={handlerFormikReset}
          onSave={handlerFormikSubmit}
          notEqual={!equal(formik.values, data)}
          setEdit={setEdit}
        >
          {erid && (
            <>
              <Input
                label={erid.title}
                notField={edit}
                showValueCount
                error={!!formik.errors.erid}
                errortext={formik.errors.erid}
                inputAttributes={{
                  name: 'erid',
                  disabled: !edit,
                  maxLength: 80,
                  value: formik.values.erid,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('erid', e.target.value.trim()),
                }}
              />
              {formik.values.erid && (
                <EridWarning
                  withAccept={edit}
                  setIsDisableSaveButton={setIsDisableSaveButton}
                  isChangeErid={config_values[erid.id] !== formik.values.erid}
                />
              )}
            </>
          )}
        </EditCard>
      </Form>
    </FormikProvider>
  );
};

export default FormType;
