import { createAction } from '@reduxjs/toolkit';
import { THASH, TID } from '../../../types/general';

export const fetchSearchRequest = createAction<TID>(
  'search/search.byInternalId',
);

export const fetchParentList = createAction<THASH>('search/fetchParentList');

export const fetchBreadcrumbs = createAction<THASH>('search/fetchBreadcrumbs');

export const fetchModalBreadcrumbs = createAction<THASH>(
  'search/fetchModalBreadcrumbs',
);

export const setTagCampaignActiveDefault = createAction<boolean>(
  'search/setTagCampaignActiveDefault',
);
