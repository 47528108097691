import { createAction } from '@reduxjs/toolkit';
import {
  CreateApiUserPayload,
  TUserManipulationParams,
  editPayload,
} from './types';

export const fetchAPIUsers = createAction<{ isSavePreData?: boolean }>(
  'APIUsers/fetchAPIUsers',
);

export const deleteAPIUser = createAction<TUserManipulationParams>(
  'APIUsers/deleteAPIUsers',
);

export const refreshTokenAPIUser = createAction<TUserManipulationParams>(
  'APIUsers/refreshTokenAPIUsers',
);

export const createNewAPIUser = createAction<CreateApiUserPayload>(
  'user/createNewAPIUser',
);

export const editAPIUser = createAction<editPayload>('APIUsers/editAPIUser');

export const getAPIUsersMethods = createAction('APIUsers/getAPIUsersMethods');

export const fetchGetTechnicalInfo = createAction<{ email: string }>(
  'user/getTechnicalInfo',
);
