/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC } from 'react';
import { components } from 'react-select';
import css from './styles.module.scss';

const index: FC = (props: any) => (
  <components.Option {...props}>
    <input
      className={css.input}
      type="checkbox"
      checked={props.isSelected}
      onChange={() => null}
    />
    <label className={css.label}>
      {!Object.prototype.hasOwnProperty.call(props.data, 'isAdditional')
        ? props.label
        : `${props.value} (${props.label})`}
    </label>
  </components.Option>
);

export default index;
