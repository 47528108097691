import {
  RouteComponentProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { useDispatchApp } from 'redux/rootSelectors';
import { ParamsCampaignsStatistics, ResponseStatisticsCampaigns } from 'domains/campaigns/types';
import {
  cleanCreativeTargeting,
  fetchCreativeStatisticsList,
} from '../../reduser';
import { useProjectInfo } from '../../../project/hooks';
import {
  useCreativeListInfo,
  useCreativesTotal
} from '../../model/selectors';
import { TID } from '../../../../types/general';
import { updateCreativeStatistics } from '../../model/actions';
import { useCampaignInfo } from '../../../campaign/model/selectors';

type TFUseCreativeComponentState = () => RouteComponentProps<TID> & {
  fetchStatistics: (v: ParamsCampaignsStatistics) => void;
  sumStatistics: ResponseStatisticsCampaigns | null;
  creativeList: ReturnType<typeof useCreativeListInfo>['data'];
  isLoadingCreativeList: ReturnType<typeof useCreativeListInfo>['isLoading'];
  campaign: ReturnType<typeof useCampaignInfo>['data'];
  isLoadingCampaign: ReturnType<typeof useCampaignInfo>['isLoading'];
  currency: string;
  cleanCreativeTargeting: typeof cleanCreativeTargeting;
  errorCreativeList: ReturnType<typeof useCreativeListInfo>['error'];
  LTU: number;
  updateStatisticsAction: () => void;
};

export const useCreativeComponentState: TFUseCreativeComponentState = () => {
  const dispatchRedux = useDispatchApp();

  const { data: project } = useProjectInfo();

  const { data: campaign, isLoading: isLoadingCampaign } = useCampaignInfo();

  const {
    data: creativeList,
    isLoading: isLoadingCreativeList,
    error: errorCreativeList,
    LTU,
  } = useCreativeListInfo();

  const { data: totalStatistics } = useCreativesTotal();
  const sumStatistics = totalStatistics?.total || null

  const fetchStatistics: ReturnType<TFUseCreativeComponentState>['fetchStatistics'] =
    (param) => {
      dispatchRedux(fetchCreativeStatisticsList(param));
    };

  const updateStatisticsAction = () => {
    dispatchRedux(updateCreativeStatistics(true));
  };

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch<TID>();
  return {
    location,
    history,
    match,
    fetchStatistics,
    sumStatistics,
    creativeList,
    isLoadingCreativeList,
    campaign,
    isLoadingCampaign,
    currency: project ? project.currency : '',
    cleanCreativeTargeting,
    errorCreativeList,
    LTU,
    updateStatisticsAction,
  };
};
