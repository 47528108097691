import React, { FC, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import NavigationHeader from 'components/NavigationHeader';
import cn from 'classnames';
import { FiSearch, FiX } from 'react-icons/fi';
import AccessUserRight from 'components/AccessUserRight';
import Button from 'components/UI/Buttons/buttons';
import Filter from 'components/Filter';
import useSearchField from 'hooks/useSearchField';
import { useUserTheme } from 'domains/user/model/selectors';
import ElementsNone from 'components/ElementsNone';
import PreloaderList from 'components/Preloaders/List';
import TableFilter from 'components/UI/TableFilter';
import { RouteComponentProps } from 'react-router';
import { useDispatchApp } from 'redux/rootSelectors';
import { editOffer } from 'domains/offer/model/actions';
import { TOfferItem } from 'domains/offer/model/types';
import { fetchAllClients } from 'domains/clients/reducer';
import { useFormik } from 'formik';
import { SelectField } from 'types/general';
import css from './styles.module.scss';
import Item from './Item';
import OfferModal from './OfferModal';
import { useOffersList } from '../model/selectors';
import { getOffersList } from '../model/actions';

type Props = RouteComponentProps;

const Offers: FC<Props> = ({ ...params }) => {
  const { t } = useTranslation();

  const searchRef = useRef<HTMLInputElement>(null);
  const searchWrapperRef = useRef<HTMLDivElement>(null);
  const { onChangeSearch, search, onCloseSearch, onOpenSearch, toggleSearch } =
    useSearchField(searchRef, searchWrapperRef);

  const onSearch = (offer: TOfferItem) =>
    offer.title.toLowerCase().includes(search.toLowerCase());

  const { isThemeExtended, isThemeFull } = useUserTheme();
  const [currentOffer, setCurrentOffer] = useState<TOfferItem | null>(null);

  const dispatch = useDispatchApp();
  const { data: offersList, isLoading } = useOffersList();
  const [currentTab, setCurrentTab] = useState<'LAUNCHED' | 'ARCHIVE'>(
    'LAUNCHED',
  );

  const offerListView = offersList
    ?.filter((offer) => offer.status === currentTab)
    ?.filter(onSearch);

  useEffect(() => {
    dispatch(getOffersList({}));
    dispatch(fetchAllClients({}));
  }, []);

  const formik = useFormik<{ partner_xxhash_list: SelectField[] }>({
    initialValues: {
      partner_xxhash_list: [],
    },
    onSubmit: (values) => {
      const partner_xxhash_list = values.partner_xxhash_list.map(
        (partner) => partner.value,
      );
      dispatch(
        getOffersList({
          partner_xxhash_list: partner_xxhash_list.length
            ? partner_xxhash_list
            : undefined,
        }),
      );
    },
  });

  const clearFilters = () => {
    formik.setFieldValue('partner_xxhash_list', []);
  };

  return (
    <div className={css.offers}>
      <Helmet>
        <title>{t(`offers_page.offers`)}</title>
      </Helmet>

      <NavigationHeader>
        <button
          type="button"
          className={cn('tabs__item', {
            isActive: currentTab === 'LAUNCHED',
          })}
          onClick={() => setCurrentTab('LAUNCHED')}
          disabled={false}
        >
          {t(`agencies_page.agencies_active`)}
        </button>
        <button
          type="button"
          className={cn('tabs__item', {
            isActive: currentTab === 'ARCHIVE',
          })}
          onClick={() => setCurrentTab('ARCHIVE')}
          disabled={false}
        >
          {t(`offers_page.archive`)}
        </button>
      </NavigationHeader>
      <div
        className={cn(css.header, {
          isToggleSearch: toggleSearch,
        })}
      >
        <div
          className={cn('container resized', {
            extend: isThemeExtended,
            full: isThemeFull,
          })}
        >
          <div className={css.status}>
            <h1 className={css.title}>{t(`offers_page.offers`)}</h1>
            <div className={css.actions}>
              <div
                ref={searchWrapperRef}
                className={cn(css.search, {
                  isActive: toggleSearch,
                })}
              >
                <div className={css.icon} onClick={onOpenSearch}>
                  <FiSearch size={24} />
                </div>
                <div className={css.input}>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder={t(`offers_page.search_with_name`)}
                    value={search}
                    onChange={onChangeSearch}
                    ref={searchRef}
                  />
                </div>

                <div className={css.close} onClick={onCloseSearch}>
                  <FiX size={24} />
                </div>
              </div>
              <Filter
                classname={css.filter}
                mode="offers"
                formik={formik}
                clearOfferFilters={clearFilters}
              />
              <AccessUserRight>
                {({ isDisable }) => (
                  <Button
                    title={t(`offers_page.create_offer`)}
                    buttonAttributes={{
                      disabled: isDisable,
                      style: {
                        height: 40,
                      },
                      onClick: () =>
                        params.history.push({
                          pathname: `offer/create`,
                        }),
                    }}
                  />
                )}
              </AccessUserRight>
            </div>
          </div>
        </div>
      </div>
      {!offerListView?.length && !isLoading ? (
        <ElementsNone
          type="offers"
          search={!!search || !!formik.values.partner_xxhash_list.length}
          status={
            offerListView?.length === 0 && offersList?.length === 0
              ? ''
              : currentTab
          }
        />
      ) : (
        <div className={css.content}>
          <div
            className={cn('container resized', {
              extend: isThemeExtended,
              full: isThemeFull,
            })}
          >
            <div className={css.table}>
              <div className={css.table_header}>
                <TableFilter
                  data=""
                  title={t(`containers_page.name`)}
                  handleSort={() => null}
                  sort={null}
                  activeKey={null}
                />
                <TableFilter
                  data=""
                  title={t(`top.menu.clients`)}
                  handleSort={() => null}
                  sort={null}
                  activeKey={null}
                />
              </div>
              {isLoading && <PreloaderList type="offer" />}
              {!isLoading && offerListView?.length && (
                <div className={css.table_body}>
                  {offerListView?.map((offer) => (
                    <Item
                      offer={offer}
                      key={offer.id}
                      openModal={() => {
                        setCurrentOffer(offer);
                      }}
                      currentTab={currentTab}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {currentOffer && (
        <OfferModal
          onClose={() => setCurrentOffer(null)}
          title={
            currentTab === 'LAUNCHED'
              ? t(`clients_page.add_arc_icon`)
              : t(`clients_page.rem_arc_icon`)
          }
          innerText={
            currentTab === 'LAUNCHED' ? (
              <Trans
                i18nKey="offers_page.add_in_archive"
                values={{ offer: currentOffer.title }}
                components={{ bold: <strong /> }}
              />
            ) : (
              <Trans
                i18nKey="offers_page.from_in_archive"
                values={{ offer: currentOffer.title }}
                components={{ bold: <strong /> }}
              />
            )
          }
          customWidth={554}
          actions={
            <>
              <Button
                title={t(`cancel_btn`)}
                buttonAttributes={{ onClick: () => setCurrentOffer(null) }}
                height={40}
                transparent
              />
              <Button
                title={
                  currentTab === 'LAUNCHED'
                    ? t(`agencies_page.archive_modal.btn_to`)
                    : t(`agencies_page.archive_modal.btn_from`)
                }
                buttonAttributes={{
                  onClick: () => {
                    dispatch(
                      editOffer({
                        id: currentOffer.id,
                        mode: 'list',
                        currentFilter: formik.values.partner_xxhash_list.map(
                          (partner) => partner.value,
                        ),
                        data: {
                          title: currentOffer.title,
                          status:
                            currentTab === 'LAUNCHED' ? 'ARCHIVE' : 'LAUNCHED',
                        },
                      }),
                    );
                    setCurrentOffer(null);
                  },
                }}
                height={40}
              />
            </>
          }
        />
      )}
    </div>
  );
};

export default Offers;
