import Load from 'components/UI/Load';
import React, { FC } from 'react';

interface Props {
  count: number;
}

const Params: FC<Props> = ({ count }) => {
  const params = new Array(count);

  for (let i = 0; i < params.length; i += 1) {
    params[i] = <Load key={i} />;
  }

  return <>{params.map((param) => param)}</>;
};

export default Params;
