import { IHistoryFilter, IHistoryPeriod } from 'domains/historyChanges/types';
import { useHistory } from 'react-router';

interface IMakeUrlReturn {
  decodeHistoryUrl: (historyFilters: IHistoryFilter) => IHistoryFilter;
  setHistoryUrlFilters: (
    historyFilters: IHistoryFilter,
    settingsPeriod: IHistoryPeriod,
    settingsType: string,
    periodFilter?: IHistoryPeriod,
  ) => void;
  removeHistoryUrlFilters: () => void;
}

export const useHistoryMakeUrl = (): IMakeUrlReturn => {
  const history = useHistory();
  const url = new URL(window.location.href);

  const filterIsNull = (value: string) => (value === 'null' ? null : value);

  const decodeHistoryUrl = (historyFilters: IHistoryFilter) => {
    const filterURL = url.searchParams.get('filter') ?? '';
    let resultFilters: IHistoryFilter = historyFilters;
    if (filterURL) {
      const minAr = decodeURI(filterURL).split('/');
      minAr.forEach((minValue) => {
        if (minValue.includes('item=')) {
          const prefixLength = 'item='.length;
          resultFilters = {
            ...resultFilters,
            item_type: filterIsNull(
              minValue.slice(prefixLength, minValue.length),
            ),
          };
        }
        if (minValue.includes('action=')) {
          const prefixLength = 'action='.length;
          resultFilters = {
            ...resultFilters,
            action: filterIsNull(minValue.slice(prefixLength, minValue.length)),
          };
        }
        if (minValue.includes('email=')) {
          const prefixLength = 'email='.length;
          resultFilters = {
            ...resultFilters,
            user_email: filterIsNull(
              minValue.slice(prefixLength, minValue.length),
            ),
          };
        }
        if (minValue.includes('field=')) {
          const prefixLength = 'field='.length;
          resultFilters = {
            ...resultFilters,
            field:
              minValue.slice(prefixLength, minValue.length) === ''
                ? []
                : [minValue.slice(prefixLength, minValue.length)],
          };
        }
        if (minValue.includes('search=')) {
          const prefixLength = 'search='.length;
          resultFilters = {
            ...resultFilters,
            search: filterIsNull(minValue.slice(prefixLength, minValue.length)),
          };
        }
        if (minValue.includes('from=')) {
          const prefixLength = 'from='.length;
          resultFilters = {
            ...resultFilters,
            period: {
              ...resultFilters.period,
              from: minValue.slice(prefixLength, minValue.length),
            },
          };
        }
        if (minValue.includes('to=')) {
          const prefixLength = 'to='.length;
          resultFilters = {
            ...resultFilters,
            period: {
              ...resultFilters.period,
              to: minValue.slice(prefixLength, minValue.length),
            },
          };
        }
      });
    }
    return resultFilters;
  };

  const setHistoryUrlFilters = (
    historyFilters: IHistoryFilter,
    settingsPeriod: IHistoryPeriod,
    settingsType: string,
    periodFilter?: IHistoryPeriod,
  ) => {
    const filters = encodeURI(
      `${
        historyFilters.item_type || historyFilters.item_type === null
          ? `item=${historyFilters.item_type}/`
          : ''
      }${
        historyFilters.action || historyFilters.action === null
          ? `action=${historyFilters.action}/`
          : ''
      }${
        historyFilters.user_email ? `email=${historyFilters.user_email}/` : ''
      }${historyFilters.field ? `field=${historyFilters.field}/` : ''}${
        historyFilters.search ? `search=${historyFilters.search}/` : ''
      }/from=${periodFilter ? periodFilter.from : settingsPeriod.from}/to=${
        periodFilter ? periodFilter.to : settingsPeriod.to
      }${settingsType ? `/type=${settingsType}` : ''}`,
    );
    url.searchParams.set('filter', filters);
    history.push(url.search);
  };

  const removeHistoryUrlFilters = () => {
    url.searchParams.delete('filter');
    history.push(url);
  };

  return {
    decodeHistoryUrl,
    setHistoryUrlFilters,
    removeHistoryUrlFilters,
  };
};
