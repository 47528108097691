import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResponseContainers } from '../../types/containers';
import { FetchedData, genFetchedData } from '../../redux/fetchedData';

export interface ContainersState {
  tempContainersList: FetchedData<ResponseContainers[]>;
}

const initialState: ContainersState = {
  tempContainersList: genFetchedData<ResponseContainers[]>(null),
};

const containerSlice = createSlice({
  name: 'containers',
  initialState,
  reducers: {
    setTempContainersList(
      state,
      action: PayloadAction<ContainersState['tempContainersList']>,
    ) {
      state.tempContainersList = action.payload;
    },
  },
});
export const fetchContainersAction = createAction(
  'containers/fetchContainersAction',
);
export const { setTempContainersList } = containerSlice.actions;
export const containerReducer = containerSlice.reducer;
