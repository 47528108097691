import React, { FC } from 'react';
import { Field, FieldProps, Form, FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from 'components/UI/Input/input';
import EditCard from 'components/UI/EditCard';
import LabelField from 'components/UI/LabelField';
import ToggleSwitch from 'components/UI/ToggleSwitch';
import NewSelect from 'components/UI/NewSelect';
import css from './styles.module.scss';
import { useProjectSettings } from './hook';

const Project: FC = () => {
  const {
    formikContext,
    edit,
    setEdit,
    errors,
    values,
    handleChange,
    handleBlur,
    touched,
    handleFormikReset,
    handleFormikSubmit,
    setFieldValue,
  } = useProjectSettings();

  const inputs = ['title', 'ext_domain'] as const;

  const { t } = useTranslation();

  return (
    <FormikProvider value={formikContext}>
      <Form>
        <EditCard
          className={css.card}
          edited={edit}
          hasErrors={Object.keys(errors).length > 0}
          noHover
          onResetForm={handleFormikReset}
          onSave={handleFormikSubmit}
          setEdit={setEdit}
        >
          <h3>{t('project_page.forms.project_title')}</h3>

          <div className={css.card__form}>
            <Input
              notField={edit}
              disabled
              label={t('project_page.forms.code')}
              error={!!touched.code && !!errors.code}
              errortext={errors.code}
              inputAttributes={{
                name: 'code',
                placeholder: '',
                value: values.code,
                onChange: handleChange,
                onBlur: handleBlur,
              }}
            />
            {inputs.map((key) => (
              <Input
                key={key}
                disabled={key === 'ext_domain'}
                notField={edit}
                label={t(`project_page.forms.${key}`)}
                error={!!touched[key] && !!errors[key]}
                errortext={errors[key]}
                showValueCount
                inputAttributes={{
                  name: key,
                  placeholder: '',
                  value: values[key],
                  onChange: handleChange,
                  onBlur: handleBlur,
                  maxLength: 120,
                }}
              />
            ))}

            <Field name="currency">
              {({ field, form }: FieldProps) => (
                <NewSelect
                  className={css.currencySelect}
                  label={t('project_page.forms.currency')}
                  viewingOnly={!edit}
                  form={form}
                  field={field}
                  selectAttributes={{
                    placeholder: t('project_page.forms.currency'),
                    options: [
                      { label: 'RUB', value: 'RUB' },
                      { label: 'USD', value: 'USD' },
                    ],
                    value: [
                      {
                        label: `${values.currency}`,
                        value: `${values.currency}`,
                      },
                    ],
                    isDisabled: true,
                  }}
                  viewStyle="Vertical"
                />
              )}
            </Field>

            <Input
              notField={edit}
              disabled
              label={t('project_page.forms.partner')}
              error={!!touched.partner && !!errors.partner}
              errortext={errors.partner}
              inputAttributes={{
                name: 'partner',
                placeholder: '',
                value: values.partner,
                onChange: handleChange,
              }}
            />

            <LabelField label={t(`ord.registration_in_ord`)}>
              <ToggleSwitch
                checked={values.ord_registration}
                disabled={!edit}
                onChange={() =>
                  setFieldValue('ord_registration', !values.ord_registration)
                }
                activateText={t(`clients_page.client_edit.included`)}
                deactivateText={t(`clients_page.client_edit.turned_off`)}
              />
            </LabelField>
          </div>
        </EditCard>
      </Form>
    </FormikProvider>
  );
};

export default Project;
