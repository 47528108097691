import React, { FC, useState } from 'react';
import BaseModal from 'components/UI/BaseModal';
import Button from 'components/UI/Buttons/buttons';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useTranslation } from 'react-i18next';
import { MassEditLinkParams } from 'domains/creative/types';
import { useMassEditFormikData } from 'domains/creative/creatives/hooks/MassEdit/useMassEditFormikData';
import { FiAlertCircle } from 'react-icons/fi';
import css from './styles.module.scss';
import Creative from './Creative';
import LinksView from './LinksView';

export interface IMassEditModalProps {
  creativeCheckedList: string[];
  checkedTypes: string[];
  onClose: () => void;
  setIsSaveMassEditData: React.Dispatch<
    React.SetStateAction<MassEditLinkParams | null>
  >;
}

const MassEditModal: FC<IMassEditModalProps> = ({
  creativeCheckedList,
  checkedTypes,
  onClose,
  setIsSaveMassEditData,
}) => {
  const { t } = useTranslation();

  const [activeFields, setActiveFields] = useState<string[]>([]);

  const { formik, linksOptions, eridData, eridAvalibleCreoTypes } =
    useMassEditFormikData({
      creativeCheckedList,
      checkedTypes,
      onClose,
      setIsSaveMassEditData,
    });

  return (
    <BaseModal
      onClose={onClose}
      isOpen
      actions={
        <div className={css.actions}>
          <Button
            title={t(`cancel_btn`)}
            transparent
            height={40}
            buttonAttributes={{
              onClick: () => {
                onClose();
              },
            }}
          />
          <Button
            title={t(`form.common.saveChanges`)}
            height={40}
            buttonAttributes={{
              disabled:
                !formik.isValid ||
                activeFields.some(fieldName => fieldName === '') ||
                activeFields.filter(item => item !== '').some(key => formik.values[key] === undefined || formik.values[key] === '') ||
                !Object.values(formik.values).length,
              onClick: () => formik.handleSubmit(),
            }}
          />
        </div>
      }
      title={t(`creative.mass_editing`)}
      customWidth={1174}
    >
      <Tabs className={css.tabs}>
        <TabList className={css.tablist}>
          <Tab className={css.tab}>
            {Object.entries(formik.errors).length !== 0 && !formik.errors.erid && (
              <div className={css.warning}>
                <FiAlertCircle size={16} color="#ff3636" />
              </div>
            )}
            <span>{t(`creative.create_creative.URL_settings`)}</span>
          </Tab>
          <Tab className={css.tab}>
            {formik.errors.erid && (
              <div className={css.warning}>
                <FiAlertCircle size={16} color="#ff3636" />
              </div>
            )}
            {formik.values.iab === '24' && !formik.errors.erid && (
              <div className={css.warning}>
                <FiAlertCircle
                  size={16}
                  color="#FF9C08"
                  className="warning-icon"
                />
              </div>
            )}
            <span>{t(`creative.creative_text`)}</span>
          </Tab>
        </TabList>
        <TabPanel className={css.panel}>
          <LinksView
            checkedTypes={checkedTypes}
            formik={formik}
            activeFields={activeFields}
            setActiveFields={setActiveFields}
            linksOptions={linksOptions}
          />
        </TabPanel>
        <TabPanel className={css.panel}>
          <Creative
            formik={formik}
            eridData={eridData}
            isShowErid={eridAvalibleCreoTypes.some((type) =>
              checkedTypes.includes(type),
            )}
          />
        </TabPanel>
      </Tabs>
    </BaseModal>
  );
};

export default MassEditModal;
