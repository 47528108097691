import React from 'react';
import Select, { OptionsType, StylesConfig } from 'react-select';
import { FormikProps } from 'formik';
import get from 'lodash/get';
import cn from 'classnames';
import { TResult } from 'utils/removeTopLevelsOfJson/removeTopLevelsOfJson';
import LimitedChipsContainer from './LimitedChipsContainer';
import Option from './Option';
import MenuList from './MenuList';
import './styles.scss';
import i18n from '../../../i18n';
import ErrorText from '../ErrorText';
import DropdownIndicator from './DropdownIndicator';

interface Props<T> {
  propsValues: TResult[] | Record<string, string>[] | null;
  isError: boolean;
  errorText?: string;
  placeholder: string;
  /* customValue?: GOptions[]; */
  formik: FormikProps<T>;
  fieldName: string;
  hideIcon?: boolean;
  isDisabled?: boolean;
  customHandleChange?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  customContainer?: boolean;
  chipsLimit?: number;
}

interface IOption2 {
  label: string;
  value: string;
  isFixed?: boolean;
}

function MultiSelect<N>({
  propsValues,
  placeholder,
  formik,
  fieldName,
  isError,
  errorText,
  isDisabled,
  customHandleChange,
  hideIcon = true,
  customContainer = false,
  chipsLimit = 5,
}: Props<N>): JSX.Element {
  const styles: StylesConfig<IOption2, true> = {
    multiValue: (base, state) =>
      state.data.isFixed
        ? { ...base, backgroundColor: 'gray' }
        : { ...base, backgroundColor: '#e3ecff' },
    multiValueLabel: (base, state) =>
      state.data.isFixed ? { ...base, color: 'white' } : base,
    multiValueRemove: (base, state) =>
      state.data.isFixed ? { ...base, display: 'none' } : base,
  };

  const ValueContainer = customContainer
    ? {
        ValueContainer: LimitedChipsContainer,
      }
    : {};

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={cn('selectWrapper', {
        isError,
        isNotAllowed: isDisabled,
      })}
    >
      <Select
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
          MenuList,
          ...ValueContainer,
          DropdownIndicator,
        }}
        classNamePrefix="multi_select"
        noOptionsMessage={() => i18n.t(`no_result`)}
        onChange={(
          select: OptionsType<{
            label: string;
            value: string;
            isAdditional?: boolean;
          }>,
        ) => {
          if (customHandleChange) {
            customHandleChange(fieldName, select);
            return;
          }
          formik.setFieldValue(fieldName, select);
        }}
        allowSelectAll
        isClearable={false}
        isDisabled={isDisabled}
        value={get(formik.values, fieldName)}
        options={propsValues || undefined}
        styles={styles}
        placeholder={placeholder}
        chipsLimit={chipsLimit}
      />
      {isError && (
        <div className="errorText">
          <ErrorText hideIcon={hideIcon} text={errorText} />
        </div>
      )}
    </div>
  );
}

export default MultiSelect;
