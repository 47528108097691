import { RefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { fetchAgencyList } from 'domains/partners/model/actions';
import { fetchGroups } from 'domains/project/model/actions';
import { ResponseUser } from 'domains/user/types';
import { RGetManagementUsersSList, TActivityUser, TUsersTab } from '../types';
import {
  fetchActivityUsers,
  fetchManagementUsers,
  fetchUsersOnline,
} from '../actions';
import {
  useManagementActivityUsersInfo,
  useManagementUsersInfo,
  useManagementUsersOnline,
} from '../selectors';
import { useDispatchApp } from '../../../../redux/rootSelectors';
import {
  setStorageActivityUsersDate,
  setUsersTabs,
} from '../../../storage/model/reducer';
import {
  useStorageActivityUsersInfo,
  useStorageUserListTabs,
} from '../../../storage/model/selectors';
import { useUserInfo } from '../../../user/model/selectors';
import { TDates, TOnSetDateRange } from '../../../../types/general';
import { tabsCollection } from '../shared';
import { useSortActivityUsers } from './useSortActiviesUsers';
import useSearchField from '../../../../hooks/useSearchField';

export type TFUseDataUsers = () => ReturnType<typeof useSortActivityUsers> &
  TDates & {
    user: ResponseUser | null;
    users: RGetManagementUsersSList | null;
    isLoading: boolean;
    isLoadingOnline: boolean;
    tabs: TUsersTab;
    tabsCollection: TUsersTab[];
    changeTab: (p: TUsersTab) => void;
    getStatisticsUser: (
      user_email: string,
      keyStatistics: keyof Omit<TActivityUser, 'user_email'>,
    ) => number | true;
    onSetDateRange: TOnSetDateRange;
    searchRef: RefObject<HTMLInputElement>;
    search: string;
    onOpenSearch: ReturnType<any>;
    onCloseSearch: ReturnType<typeof useSearchField>['onCloseSearch'];
    onChangeSearch: ReturnType<typeof useSearchField>['onChangeSearch'];
    toggleSearch: ReturnType<typeof useSearchField>['toggleSearch'];
    filterData: ReturnType<typeof useManagementUsersInfo>['data'];
  };
/** Функция для получения массива пользователей */
export const useDataUsers: TFUseDataUsers = () => {
  const { data: usersAll, isLoading, LTU: LTUAll } = useManagementUsersInfo();
  const {
    data: usersActivity,
    LTU: LTUActivity,
    isLoading: isLoadingActivity,
  } = useManagementActivityUsersInfo();

  const {
    data: usersOnline,
    isLoading: isLoadingOnline,
    LTU: LTUOnline,
  } = useManagementUsersOnline();

  const { data: user } = useUserInfo();

  const searchRef = useRef<HTMLInputElement>(null);
  const { onChangeSearch, search, onCloseSearch, onOpenSearch, toggleSearch } =
    useSearchField(searchRef);

  const tabs = useStorageUserListTabs();

  const { type, period } = useStorageActivityUsersInfo();

  const { key, sort, handleSort } = useSortActivityUsers();

  const dispatch = useDispatchApp();

  const changeTab = (p: TUsersTab) => {
    dispatch(setUsersTabs(p));
  };

  const getStatisticsUser: ReturnType<TFUseDataUsers>['getStatisticsUser'] = (
    user_email,
    keyStatistics,
  ) => {
    if (isLoadingActivity) {
      return true;
    }
    if (usersActivity) {
      const element = usersActivity.find((v) => v.user_email === user_email);
      if (element && keyStatistics in element) {
        return element[keyStatistics];
      }
    }
    return 0;
  };

  const onSetDateRange: ReturnType<TFUseDataUsers>['onSetDateRange'] = (
    LPeriod,
    LType,
  ) => {
    if (usersAll) {
      dispatch(
        fetchActivityUsers({
          users_email: [...usersAll.map(({ email }) => email)],
          period: LPeriod,
        }),
      );
    }
    dispatch(setStorageActivityUsersDate({ type: LType, period: LPeriod }));
  };

  useEffect(() => {
    if (user?.partner.xxhash && tabs === 'allUsers') {
      dispatch(fetchManagementUsers({ partner_id: user?.partner.xxhash }));
      if (user?.group.includes('owner') || user?.group.includes('self_user')) {
        dispatch(fetchAgencyList({ partner_id: user?.partner.xxhash }));
        dispatch(fetchGroups());
      }
    }
  }, [user?.partner.xxhash, tabs]);

  useEffect(() => {
    if (user?.partner.xxhash) {
      dispatch(fetchManagementUsers({ partner_id: user?.partner.xxhash }));
    }
  }, [tabs]);

  useEffect(() => {
    if (tabs === 'online') {
      dispatch(fetchUsersOnline());
    }
    if (usersAll && !!usersAll.length && tabs === 'statistics') {
      dispatch(
        fetchActivityUsers({
          users_email: [...usersAll.map(({ email }) => email)],
          period,
        }),
      );
    }
  }, [tabs]);

  const users = useMemo<RGetManagementUsersSList | null>(() => {
    if (['allUsers', 'statistics'].includes(tabs) && usersAll) {
      return usersAll;
    }
    if (tabs === 'online' && usersAll && usersOnline) {
      return usersAll.filter(({ email }) =>
        usersOnline.some((v) => v === email),
      );
    }
    return null;
  }, [LTUOnline, LTUAll, tabs, LTUActivity]);

  const filterUsers = useCallback(
    (data: RGetManagementUsersSList): RGetManagementUsersSList =>
      data.filter(({ email }) =>
        email.toLowerCase().includes(search.toLowerCase()),
      ),
    [search],
  );

  const filterData = useMemo(
    () => users && filterUsers(users),
    [usersAll, filterUsers, LTUOnline, LTUAll],
  );

  return {
    user,
    users,
    isLoading,
    tabs,
    tabsCollection,
    changeTab,
    isLoadingOnline,
    LTUOnline,
    LTUAllUser: LTUAll,
    getStatisticsUser,
    type,
    period,
    onSetDateRange,
    key,
    sort,
    handleSort,
    searchRef,
    onOpenSearch,
    onCloseSearch,
    onChangeSearch,
    toggleSearch,
    search,
    filterData,
  };
};
