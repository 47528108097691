import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { FiSearch } from 'react-icons/fi';
import Button from 'components/UI/Buttons/buttons';
import { useTranslation } from 'react-i18next';
import { RStatisticsField, TStatisticsFields } from 'domains/campaign/types';
import Checkbox from 'components/UI/Checkbox/checkbox';
import css from './styles.module.scss';

interface ISettingsPanelProps {
  openSettings: boolean;
  positionY: number;
  positionX: number;
  panelRef: React.RefObject<HTMLDivElement>;
  statisticFieldList: TStatisticsFields | null;
  setOpenSettings: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ISettings {
  [key: string]: string;
}

const SettingsPanel: FC<ISettingsPanelProps> = ({
  openSettings,
  positionY,
  positionX,
  panelRef,
  statisticFieldList,
  setOpenSettings,
}) => {
  const { t } = useTranslation();
  const settingsFromStorage: ISettings[] = JSON.parse(
    localStorage.getItem('reportAuSettings') ?? '[]',
  );
  const [checked, setChecked] = useState<ISettings[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [checkedPickAll, setCheckedPickAll] = useState<boolean>(false);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleCheckPickAll = () => {
    setCheckedPickAll(!checkedPickAll);
    if (!checkedPickAll) {
      setChecked([]);
      statisticFieldList?.forEach((s) => {
        const obj = {
          [s[Object.keys(s)[1]].toUpperCase()]: s[Object.keys(s)[2]],
        };
        setChecked((prev) => [...prev, obj]);
      });
    } else {
      setChecked([]);
    }
  };

  const filteredList = statisticFieldList?.filter((item) =>
    item.title.toLowerCase().includes(searchTerm),
  );

  const handleSaveSettings = () => {
    localStorage.setItem('reportAuSettings', JSON.stringify([...checked]));
    setOpenSettings(false);
  };

  const handleClosePannel = () => {
    setSearchTerm('');
    setOpenSettings(false);
    setChecked(settingsFromStorage);
    if (checked.length !== settingsFromStorage.length) {
      setCheckedPickAll(false);
    }
  };

  const changeCheckboxValues = (
    e: React.ChangeEvent<HTMLInputElement>,
    val: RStatisticsField,
  ) => {
    setCheckedPickAll(false);
    const isChecked = e.target.checked;
    setChecked((prev) => {
      if (isChecked) {
        return [...prev, { [val.title.toUpperCase()]: val.key }];
      }
      return prev.filter((p) => val.key !== Object.values(p)[0]);
    });
  };

  useEffect(() => {
    if (!openSettings) {
      setSearchTerm('');
      setOpenSettings(false);
      setChecked(settingsFromStorage);
      if (checked.length !== settingsFromStorage.length) {
        setCheckedPickAll(false);
      }
    }
  }, [openSettings]);

  useEffect(() => {
    localStorage.setItem(
      'reportAuSettings',
      JSON.stringify([
        { IMPS: 'show' },
        { CLICK: 'click' },
        { CTR: 'ctr' },
        { CPC: 'cpc' },
        { CPO: 'cpo' },
      ]),
    );
    const currentSettings: ISettings[] = JSON.parse(
      localStorage.getItem('reportAuSettings') ?? '[]',
    );
    setChecked(currentSettings);

    return () => {
      localStorage.removeItem('reportAuSettings');
      setCheckedPickAll(false);
      setChecked([]);
    };
  }, []);

  return (
    <div
      className={cn(css.settings_panel, {
        _isOpen: openSettings,
      })}
      style={{
        top: `${positionY + 6}px`,
        left: `${positionX}px`,
      }}
      ref={panelRef}
    >
      <div className={css.settings_panel_search}>
        <div>
          <input
            type="text"
            placeholder={t(`analysis_page.search`)}
            value={searchTerm}
            onChange={handleSearch}
          />
          <FiSearch size={20} />
        </div>
      </div>
      <div className={css.settings_panel_body}>
        {filteredList?.length === 0 && (
          <div className={css.empty}>
            {t(`campaigns_page.campaigns_comments.no_search`)}
          </div>
        )}
        {!searchTerm && (
          <Checkbox
            label={t(
              `campaigns_page.campaign_settings.additional.statistics.select_all`,
            )}
            inputAttributes={{
              type: 'checkbox',
              checked: checkedPickAll,
              onChange: handleCheckPickAll,
            }}
          />
        )}
        {filteredList?.map((item) => (
          <Checkbox
            label={item.title.toUpperCase()}
            key={item.key}
            inputAttributes={{
              type: 'checkbox',
              checked: !!checked.find(
                (data) => Object.keys(data)[0] === item.title.toUpperCase(),
              ),
              onChange: (e) => {
                changeCheckboxValues(e, item);
              },
            }}
          />
        ))}
      </div>
      <div className={css.settings_panel_actions}>
        <Button
          title={t('cancel_btn')}
          transparent
          height={40}
          buttonAttributes={{
            onClick: () => handleClosePannel(),
          }}
        />
        <Button
          title={t(`apply_btn`)}
          height={40}
          buttonAttributes={{
            onClick: () => handleSaveSettings(),
          }}
        />
      </div>
    </div>
  );
};

export default SettingsPanel;
