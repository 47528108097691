import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WSSUserList } from './types';

export type WSSState = {
  userList: WSSUserList;
};
export const initialState: WSSState = {
  userList: {
    notActive: [],
    active: [],
  },
};

const wssSlice = createSlice({
  name: 'wss',
  initialState,
  reducers: {
    setUserList(state, action: PayloadAction<WSSState['userList']>) {
      state.userList = action.payload;
    },
  },
});

export const { setUserList } = wssSlice.actions;
export const wssReducer = wssSlice.reducer;
