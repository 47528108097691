import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { TID } from 'types/general';
import { useClientInfo, useGroupList } from '../../model/selectors';
import { fetchClientInfoById, fetchGroupList } from '../../reduser';
import { IError, ResponseInfoClient, TGroupList } from '../../types';

interface groupReturn {
  groups: TGroupList | null;
  clientInfo: ResponseInfoClient | null;
  isLoading: boolean;
  error?: IError;
}

export function useGetGroupList(): groupReturn {
  const dispatch = useDispatch();
  const { params } = useRouteMatch<TID>();
  const { data: clientInfo } = useClientInfo();
  const groups = useGroupList().get('data');
  const isLoading = useGroupList().get('isLoading');
  const error = useGroupList().get('error');

  useEffect(() => {
    dispatch(fetchClientInfoById(params.id));
    dispatch(fetchGroupList({ xxhash: params.id }));
  }, []);

  return { groups, clientInfo, isLoading, error };
}
