import React, { FC } from 'react';
import { FiSettings, FiX } from 'react-icons/fi';
import cn from 'classnames';
import {
  TFUseModalSettingsProps,
  useModalSettings,
} from './hooks/useMassActions';
import css from './styles.module.scss';

const ModalSettings: FC<TFUseModalSettingsProps> = ({
  children,
  closeOutside,
}) => {
  const { open, openAction, closeAction, wrapperRef } = useModalSettings({
    closeOutside,
  });

  return (
    <div
      ref={wrapperRef}
      className={cn(css.settings, {
        isOpen: open,
      })}
    >
      <div
        className={css.settings__btn}
        onClick={open ? closeAction : openAction}
      >
        <FiSettings size="24" color="#025DFF" />
      </div>
      <div className={css.settings__body}>
        <div className={css.settings__header}>
          <div className={css.settings__close} onClick={closeAction}>
            <FiX size="16" color="#222222" />
          </div>
        </div>
        <div onClick={closeAction} className={css.settings__points}>{children}</div>
      </div>
    </div>
  );
};

export default ModalSettings;
