import React, { FC } from 'react';
import { ReactComponent as UnavailableSVG } from '../../icons/unavailable.svg';
import css from '../../styles.module.scss';

interface IProps {
  text: string;
}

const Unavailable: FC<IProps> = ({text}) => (
  <div className={css.plug}>
    <UnavailableSVG />
    <span>{text}</span>
  </div>
);

export default Unavailable;
