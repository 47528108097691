import { useEffect } from 'react';
import {
  fetchCampaignExperimental,
} from '../model/actions';
import { useDispatchApp } from '../../../redux/rootSelectors';
import { useCampaignInfo } from '../model/selectors';
import { useCampainExperimentalInfo } from '../model/hooks/useCampaingExperimentalInfo';
import {
  TExperimentalInfoField,
} from '../types';

type TFUseCampaignAdditional = () => {
  isLoading: ReturnType<typeof useCampaignInfo>['isLoading'];
  campaign: ReturnType<typeof useCampaignInfo>['data'];
  experimentalInfo: TExperimentalInfoField[] | null;
};

export const useCampaignExperimental: TFUseCampaignAdditional = () => {
  const dispatch = useDispatchApp();
  const { data: campaign, isLoading } = useCampaignInfo();

  useEffect(() => {
    if (campaign) {
      dispatch(fetchCampaignExperimental());
    }
  }, [isLoading]);

  const { experimentalInfo } = useCampainExperimentalInfo();

  return {
    isLoading,
    campaign,
    experimentalInfo,
  };
};
