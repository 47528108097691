import { RefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { ResponseUser } from 'domains/user/types';
import { TAPIUser } from '../types';
import {
  fetchAPIUsers,
} from '../actions';
import {
  useAPIUsersInfo,
} from '../selectors';
import { useDispatchApp } from '../../../../redux/rootSelectors';
import { useUserInfo } from '../../../user/model/selectors';
import useSearchField from '../../../../hooks/useSearchField';

export type TFUseDataUsers = () => {
    user: ResponseUser | null;
    users: TAPIUser[] | null;
    isLoading: boolean;
    searchRef: RefObject<HTMLInputElement>;
    search: string;
    onOpenSearch: ReturnType<any>;
    onCloseSearch: ReturnType<typeof useSearchField>['onCloseSearch'];
    onChangeSearch: ReturnType<typeof useSearchField>['onChangeSearch'];
    toggleSearch: ReturnType<typeof useSearchField>['toggleSearch'];
    filterData: ReturnType<typeof useAPIUsersInfo>['data'];
  };
/** Функция для получения массива пользователей */
export const useDataUsers: TFUseDataUsers = () => {
  const { data: usersAll, isLoading, LTU: LTUAll } = useAPIUsersInfo();

  const { data: user } = useUserInfo();

  const searchRef = useRef<HTMLInputElement>(null);
  const { onChangeSearch, search, onCloseSearch, onOpenSearch, toggleSearch } =
    useSearchField(searchRef);

  const dispatch = useDispatchApp();

  useEffect(() => {
    dispatch(fetchAPIUsers({}));
  }, []);


  const users = usersAll

  const filterUsers = useCallback(
    (data: TAPIUser[]): TAPIUser[] =>
      data.filter(({ email }) =>
        email.toLowerCase().includes(search.toLowerCase()),
      ),
    [search],
  );

  const filterData = useMemo(
    () => users && filterUsers(users),
    [usersAll, filterUsers, LTUAll],
  );

  return {
    user,
    users,
    isLoading,
    LTUAllUser: LTUAll,
    searchRef,
    onOpenSearch,
    onCloseSearch,
    onChangeSearch,
    toggleSearch,
    search,
    filterData,
  };
};
