import {
  THookSelectorFetchDataInfo,
  TSelector,
  useGenFetchDataSelector,
} from '../../../redux/rootSelectors';
import { UploadState } from '../reducer';
import { TInternalList } from '../../../types/general';

export const selectorBusiness: TSelector<UploadState['business']> = ({
  uploadReducer,
}) => uploadReducer.business;

export const selectorTempHTML: TSelector<UploadState['tempHTML']> = ({
  uploadReducer,
}) => uploadReducer.tempHTML;

export const useBusinessInfo: THookSelectorFetchDataInfo<TInternalList> = () =>
  useGenFetchDataSelector(selectorBusiness);
