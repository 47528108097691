import React, { FC, useRef } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import useDropDown from 'hooks/useDropDown';
import { FieldProps } from 'formik';
import css from './styles.module.scss';

const TypeSelect: FC<FieldProps> = ({ field: { value } }) => {
  const dropdownMenu = useRef<HTMLDivElement | null>(null);
  const { toggle, isOpened, toggleOff } = useDropDown(dropdownMenu);
  const { t } = useTranslation();

  return (
    <div className={cn(css['form-group'])} ref={dropdownMenu}>
      <div className={cn(css['form-group__select'])}>
        <div
          className={cn(css['form-group__active'], {
            _isOpened: isOpened,
          })}
          onClick={isOpened ? toggleOff : toggle}
        >
          <span style={{ textTransform: 'initial' }}>
            {t('creative.create_creative.type_tit')}
            &nbsp;·&nbsp;
          </span>
          <span>{t(`creative.create_menu.${value}`)}</span>
        </div>
      </div>
    </div>
  );
};

export default TypeSelect;
