import { useCreativeListSelector } from 'domains/Analysis/selectors';
import { useState, useEffect, useMemo } from 'react';
import get from 'lodash/get';
import { TGetCreativeListResponse } from 'domains/Analysis/types';
import i18n from '../../../../i18n';

interface IUseCreoDataR {
  currentSort: 'ASC' | 'DESC' | null;
  currentTarget: string;
  currentColumn: string | null;
  creativeListView: TGetCreativeListResponse | null;
  isLoadingCreativeList: boolean;
  handleSort: (p: { key: string; sort: 'ASC' | 'DESC' | null }) => void;
  checkCurrentColumn: () => void;
  setCurrentTarget: React.Dispatch<React.SetStateAction<string>>;
  creativeList: TGetCreativeListResponse | null;
  currentAdditionalColumn: string;
  headerTitles: Record<string, string>[];
}

export const useCreoList = (): IUseCreoDataR => {
  const { data: creativeList, isLoading: isLoadingCreativeList } =
    useCreativeListSelector();

  const [currentSort, setCurrentSort] = useState<'ASC' | 'DESC' | null>(null);
  const [currentColumn, setCurrentColumn] = useState<string | null>(null);
  const [currentTarget, setCurrentTarget] = useState<string>('au');
  const [currentAdditionalColumn, setCurrentAdditionalColumn] =
    useState(currentTarget);
  const [creativeListMiddleData, setCreativeListMiddleData] =
    useState(creativeList);
  const [creativeListView, setCreativeListView] = useState(
    creativeListMiddleData,
  );

  const headerTitles = useMemo(
    () => [
      {
        data: 'campaign.title',
        title: i18n.t(`analysis_page.header_table_nameCamp`),
      },
      {
        data: 'campaign.internal_id',
        title: i18n.t(`analysis_page.header_table_idCamp`),
      },
      {
        data: 'title',
        title: i18n.t(`analysis_page.header_table_nameCreo`),
      },
      {
        data: 'internal_id',
        title: i18n.t(`analysis_page.header_table_idCreo`),
      },
      {
        data: `targets.${currentAdditionalColumn}`,
        title: currentAdditionalColumn === 'au' ? i18n.t(`analysis_page.au`) : i18n.t(`analysis_page.dir`),
      },
    ],
    [currentAdditionalColumn],
  );

  useEffect(() => {
    setCreativeListMiddleData(createMiddleData(creativeList));
  }, [creativeList]);

  useEffect(() => {
    setCreativeListView(creativeListMiddleData);
  }, [creativeListMiddleData]);

  const createMiddleData = (baseData: TGetCreativeListResponse | null) => {
    const middleDataArr: TGetCreativeListResponse = [];
    baseData?.forEach((dataItem) => {
      Object.keys(dataItem.targets[currentTarget]).forEach((itemKey) => {
        middleDataArr.push({
          ...dataItem,
          targets: { ...dataItem.targets, [currentTarget]: { [itemKey]: {} } },
        });
      });
    });
    return middleDataArr;
  };

  const handleSort = (p: { key: string; sort: 'ASC' | 'DESC' | null }) => {
    const { sort, key } = p;
    setCurrentColumn(key);
    setCurrentSort(sort);
    let sortedArr = creativeListMiddleData && [...creativeListMiddleData];
    if (sort === 'ASC') {
      sortedArr?.sort((a, b) => {
        const valA = get(a, key);
        const valB = get(b, key);
        if (typeof valA === 'object') {
          return Object.keys(valA)[0].toLowerCase() >
            Object.keys(valB)[0].toLowerCase()
            ? 1
            : -1;
        }
        return valA.toLowerCase() > valB.toLowerCase() ? 1 : -1;
      });
    } else if (sort === 'DESC') {
      sortedArr?.sort((a, b) => {
        const valA = get(a, key);
        const valB = get(b, key);
        if (typeof valA === 'object') {
          return Object.keys(valA)[0].toLowerCase() <
            Object.keys(valB)[0].toLowerCase()
            ? 1
            : -1;
        }
        return valA.toLowerCase() < valB.toLowerCase() ? 1 : -1;
      });
    } else {
      sortedArr = creativeListMiddleData && [...creativeListMiddleData];
    }
    if (sortedArr?.length) {
      setCreativeListView(sortedArr);
    }
  };

  const checkCurrentColumn = () => {
    setCurrentAdditionalColumn(currentTarget);
  };

  return {
    currentSort,
    currentColumn,
    currentTarget,
    headerTitles,
    currentAdditionalColumn,
    creativeList,
    creativeListView,
    isLoadingCreativeList,
    handleSort,
    checkCurrentColumn,
    setCurrentTarget,
  };
};
