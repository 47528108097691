import { call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

export function* diffTimeToken(): SagaIterator<boolean> {
  const { expire_access_token } = yield call([localStorage, 'getItem'], '$token');
  const startTime = new Date(expire_access_token).getTime();
  const currentTime = new Date(Date.now()).getTime() / 1000;
  const diff = +currentTime - +startTime;

  return diff >= 0;
}
