import { THookSelectorFetchDataInfo, TSelector, useGenFetchDataSelector } from 'redux/rootSelectors';
import { counteragentsState } from './reducer';
import { CounteragentItem } from './types';

export const selectorCounteragent: TSelector<counteragentsState['counteragentData']> = ({
  counteragentReducer,
}) => counteragentReducer.counteragentData;

export const useCounteragentData: THookSelectorFetchDataInfo<CounteragentItem> = () =>
  useGenFetchDataSelector(selectorCounteragent);
