import { useHistoryFiltersClear } from "domains/historyChanges/model/hooks/useHistoryFiltersClear";
import { IHistoryFilter } from "domains/historyChanges/types";
import { useOuterElementClick } from "hooks/useOuterElementClick";
import { RefObject, useEffect, useRef, useState } from "react";

type TuseHistoryFilter = {
    isOpenPanel: boolean,
    setIsOpenPanel: React.Dispatch<React.SetStateAction<boolean>>,
    topPosition: number,
    heightPanel: number,
    url: URL,
    typeSelect: React.MutableRefObject<any>,
    actionSelect: React.MutableRefObject<any>,
    fieldSelect: React.MutableRefObject<any>,
    emailSelect: React.MutableRefObject<any>,
    searchSelect: React.MutableRefObject<any>,
    clearFilters: () => void,
    launchApplyFunc: () => void,
    wrapperRef: RefObject<HTMLDivElement>,
};

export const useHistoryFilter = (
    topPositionRef: React.RefObject<HTMLDivElement>,
    commonSearch: boolean,
    setHistoryFilters: (value: React.SetStateAction<IHistoryFilter>) => void,
    setLastHistoryFilters?: React.Dispatch<React.SetStateAction<IHistoryFilter>>,
    applyHistoryFilters?: () => void,
    changeTopPositionParametr?: boolean,
    changeTopPositionRef?: React.RefObject<HTMLDivElement>,
): TuseHistoryFilter => {
    const url = new URL(window.location.href);

    const [isOpenPanel, setIsOpenPanel] = useState<boolean>(false);
    const [topPosition, setTopPosition] = useState<number>(0);
    const [heightPanel, setHeightPanel] = useState<number>(0);

    // ссылки на селект поля фильтров
    const typeSelect = useRef<any>(null);
    const actionSelect = useRef<any>(null);
    const fieldSelect = useRef<any>(null);
    const emailSelect = useRef<HTMLInputElement>(null);
    const searchSelect = useRef<HTMLInputElement>(null);

    // функционал очистки фильтров истории
    const { clearFilters } = useHistoryFiltersClear(
        typeSelect,
        actionSelect,
        fieldSelect,
        emailSelect,
        searchSelect,
        setHistoryFilters,
        setLastHistoryFilters,
        applyHistoryFilters,
        commonSearch,
    );

    // функционал применения фильтров истории
    const launchApplyFunc = () => {
        if (applyHistoryFilters) {
            applyHistoryFilters();
        }
        setIsOpenPanel(false);
    };

    const calcTopPositionPanel = () => {
        if (topPositionRef.current) {
            setTopPosition(topPositionRef.current?.getBoundingClientRect().bottom);
            setHeightPanel(
                document.documentElement.clientHeight -
                topPositionRef.current?.getBoundingClientRect().bottom,
            );
        }
    }

    // контроль клика вне фильтра
    const { wrapperRef } = useOuterElementClick({
        isShow: isOpenPanel,
        closeModal: () => setIsOpenPanel(false),
    });

    // рассчет положения панели на экране
    useEffect(() => {
        calcTopPositionPanel()
    }, []);

    useEffect(() => {
        if (changeTopPositionRef?.current && changeTopPositionParametr) {
            setTopPosition(changeTopPositionRef.current?.getBoundingClientRect().bottom);
            setHeightPanel(
                document.documentElement.clientHeight -
                changeTopPositionRef.current?.getBoundingClientRect().bottom,
            );
        } else {
            setTimeout(() => {
                calcTopPositionPanel()
            }, 300)
        }
    }, [changeTopPositionParametr, changeTopPositionRef]);

    return {
        isOpenPanel,
        setIsOpenPanel,
        topPosition,
        heightPanel,
        url,
        typeSelect,
        actionSelect,
        fieldSelect,
        emailSelect,
        searchSelect,
        clearFilters,
        launchApplyFunc,
        wrapperRef,
    }
}