import { TExperimentalInfoField } from 'domains/campaign/types';
import { useCreativeTypes } from 'domains/creative/model/selectors';
import { useEffect, useState } from 'react';
import { isFieldInConfig } from 'utils/isFieldInConfig';

export const useGetFieldConfigValue = (
  fieldName: string,
  type?:
    | 'HTML'
    | 'PUSH'
    | 'YANDEX'
    | 'TEASER'
    | 'VIDEO_INLINE'
    | 'VIDEO_VAST'
    | 'CLICKUNDER'
    | 'MULTI_FORMAT'
    | 'COMPANION',
): TExperimentalInfoField | null => {
  /*   const { data: creativeConfigEdit } = useCreativeInfo(); */
  const { data: creativeConfigAdd } = useCreativeTypes();
  const [fieldValue, setFieldValue] = useState<TExperimentalInfoField | null>(
    null,
  );

  useEffect(() => {
    const config =
      creativeConfigAdd &&
      Object.values(creativeConfigAdd).find((item) => item.key === type);
    if (config?.config_fields) {
      setFieldValue(
        isFieldInConfig(Object.values(config.config_fields), fieldName),
      );
    }
  }, [creativeConfigAdd]);

  return fieldValue;
};
