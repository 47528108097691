import { takeEvery, takeLatest } from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/core';
import { setTagCampaignActive } from 'domains/search/model/reducer';
import { setTagCampaignActiveDefault } from 'domains/search/model/actions';
import { fetcherPartnerBalanceWorker } from 'domains/search/model/sagas/workers';
import {
  onToggleStatusCampaignSaga,
  workerFetchCampaignsStatisticsSaga,
  workerFetchCampaignsStatisticsWeekSaga,
  workerCreateCampaignSaga,
  workerFetchCampaignsSaga,
  workerSearchTagsCampaign,
  sortCampaignListWorker,
  filterCampaignsTagsWorker,
  fetchCampaignsListModalSagaWorker,
  setCampaignsFilterWorker,
  changeStatusGroupCampaignsWorker,
  copyCampaignsToOtherClientWorker,
  doubleCampaignsWorker,
  exportItemsToExcelWorker,
  workerFetchCampaignsTranslationSaga,
  workerFetchChartStatisticsSaga,
  fetcherPartnerStatisticFieldListWorker,
  setPartnerStatisticFieldsWorker,
  fetchTotalListStatWorker,
  fetchVendorLinkDataSagaWorker,
  workerSetCampaignsIcon,
  workerClearCampaignsIcon,
} from './workers';
import {
  createCampaignAction,
  fetchCampaigns,
  fetchCampaignsStatistic,
  fetchCampaignsStatisticWeek,
  setCampaignsFilter,
  setListSort,
  setToggleStatusCampaign,
  fetchCampaignsTranslation,
  fetchChartStatistic,
  fetchCampaignsTotal,
  fetchCampaignsIcons,
  setCampaignsIcon,
  clearCampaignsIcon,
} from '../reducer';
import {
  changeGroupCampaigns,
  cloneCampaignsToClient,
  doubleCampaigns,
  exportItemsToExcel,
  fetchCampaignsListModal,
  fetchPartnerBalance,
  fetchStatisticFieldList,
  setPartnerStatisticsFields,
} from '../model/actions';

export default function* watcher(): SagaIterator {
  yield takeLatest(fetchCampaigns, workerFetchCampaignsSaga);
  yield takeLatest(fetchCampaignsStatistic, workerFetchCampaignsStatisticsSaga);
  yield takeLatest(fetchChartStatistic, workerFetchChartStatisticsSaga);
  yield takeEvery(
    fetchCampaignsStatisticWeek,
    workerFetchCampaignsStatisticsWeekSaga,
  );
  yield takeLatest(
    fetchCampaignsTranslation,
    workerFetchCampaignsTranslationSaga,
  );
  yield takeEvery(setToggleStatusCampaign, onToggleStatusCampaignSaga);
  yield takeLatest(createCampaignAction, workerCreateCampaignSaga);
  yield takeEvery(setTagCampaignActiveDefault, workerSearchTagsCampaign);
  yield takeLatest(setListSort, sortCampaignListWorker);
  yield takeLatest(setTagCampaignActive, filterCampaignsTagsWorker);
  yield takeEvery(fetchCampaignsListModal, fetchCampaignsListModalSagaWorker);
  yield takeLatest(fetchPartnerBalance, fetcherPartnerBalanceWorker);
  yield takeLatest(
    fetchStatisticFieldList,
    fetcherPartnerStatisticFieldListWorker,
  );
  yield takeEvery(setCampaignsFilter, setCampaignsFilterWorker);
  yield takeLatest(changeGroupCampaigns, changeStatusGroupCampaignsWorker);
  yield takeLatest(cloneCampaignsToClient, copyCampaignsToOtherClientWorker);
  yield takeLatest(doubleCampaigns, doubleCampaignsWorker);
  yield takeLatest(exportItemsToExcel, exportItemsToExcelWorker);
  yield takeLatest(setPartnerStatisticsFields, setPartnerStatisticFieldsWorker);
  yield takeLatest(fetchCampaignsTotal, fetchTotalListStatWorker);
  yield takeLatest(fetchCampaignsIcons, fetchVendorLinkDataSagaWorker);

  yield takeEvery(setCampaignsIcon, workerSetCampaignsIcon);
  yield takeEvery(clearCampaignsIcon, workerClearCampaignsIcon);
}
