import { useLocation, useRouteMatch } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatchApp } from 'redux/rootSelectors';
import { TID } from 'types/general';
import { useHistoryDataSelector } from 'domains/historyChanges/selectors';
import { fetchHistoryData } from 'domains/historyChanges/actions';
import { ResponseUser } from 'domains/user/types';
import { THistoryResponse } from 'domains/historyChanges/types';
import {
  IUpdatedHistory,
  campaignHistoryWithTextData,
  changesArray,
} from 'domains/historyChanges/model/hooks/useTransformHistoryData';
import { getLastTwoMonthsDate } from 'utils/genTimePeriod';
import {
  featchAllCreativeTypesAction,
  fetchStatusModeration,
  resetCreativeTags,
  resetModeration,
} from '../../../reduser';
import {
  useCreativeDetailInfo,
  useModerationInfo,
  useModerationResultInfo,
} from '../../../model/selectors';
import { CreativeDetail, Moderation } from '../../../types';

type TUseBasicSettingsProps = {
  user: ResponseUser | null;
};

type TFUseBasicSettings = ({
  user,
}: {
  user: TUseBasicSettingsProps['user'];
}) => {
  isLoadingCreativeDetail: boolean;
  creativeDetail: CreativeDetail | null;
  isLoading: boolean;
  moderation: Moderation | null;
  handlerFetchModeration: () => void;
  allStatusModerationIsLoading: boolean;
  pastCreativeDetail: {
    data: CreativeDetail[];
    LTU: number;
  };
  getCreativeHistory: () => void;
  isCreativeHistoryLoading: boolean;
  updatedCreativeHistory: IUpdatedHistory<string>[] | null;
};

export const useBasicSettings: TFUseBasicSettings = ({
  user,
}: {
  user: TUseBasicSettingsProps['user'];
}) => {
  const dispatch = useDispatchApp();
  const { params } = useRouteMatch<TID>();
  const location = useLocation();
  const { data: currentCreativeHistory, isLoading: isCreativeHistoryLoading } =
    useHistoryDataSelector();
  const [updatedCreativeHistory, setUpdatedCreativeHistory] = useState<
    IUpdatedHistory<string>[] | null
  >([]);

  const {
    isLoading: isLoadingCreativeDetail,
    data: creativeDetail,
    past: pastCreativeDetail,
  } = useCreativeDetailInfo();
  const prevDetail = useRef<CreativeDetail | null>(null);

  const { data: moderation, isLoading: allStatusModerationIsLoading } =
    useModerationInfo();

  const { isLoading: isLoadingResult } = useModerationResultInfo();

  const getCreativeHistory = (): void => {
    dispatch(
      fetchHistoryData({
        partner_xxhash: user?.partner.xxhash,
        filter: {
          period: getLastTwoMonthsDate(),
          search: creativeDetail?.internal_id,
          limit: { from: 0, to: 6 },
        },
      }),
    );
  };

  const updatedCampaignHistoryCallback = useCallback(
    (currHistory: THistoryResponse[]) => {
      const changes = changesArray(currHistory);
      setUpdatedCreativeHistory(
        campaignHistoryWithTextData(currHistory, changes),
      );
    },
    [currentCreativeHistory],
  );

  useEffect(() => {
    if (moderation) {
      dispatch(resetModeration());
    }
    return () => {
      dispatch(resetCreativeTags());
    };
  }, []);

  useEffect(() => {
    if (prevDetail.current !== creativeDetail && user?.group.includes('owner')) {
      getCreativeHistory();
    }
    prevDetail.current = creativeDetail;
  }, [creativeDetail]);

  useEffect(() => {
    if (currentCreativeHistory) {
      updatedCampaignHistoryCallback(currentCreativeHistory);
    }
  }, [currentCreativeHistory]);

  useEffect(() => {
    dispatch(featchAllCreativeTypesAction());
  }, [location]);

  /** запрашивает статус модерации */
  const handlerFetchModeration: ReturnType<TFUseBasicSettings>['handlerFetchModeration'] =
    () => {
      if (creativeDetail) {
        dispatch(
          fetchStatusModeration({
            creative_id: params.id,
          }),
        );
      }
    };

  return {
    isLoadingCreativeDetail,
    creativeDetail,
    isLoading: isLoadingResult,
    moderation,
    handlerFetchModeration,
    allStatusModerationIsLoading,
    pastCreativeDetail,
    getCreativeHistory,
    isCreativeHistoryLoading,
    updatedCreativeHistory,
  };
};
