import { TCurrency } from '../../domains/project/types';

type TFFormatCurrency = (val: TCurrency) => string;
export const formatCurrency: TFFormatCurrency = (val) => {
  let result: string = val;
  if (result === 'RUB') {
    result = '₽';
  }
  if (result === 'USD') {
    result = '$';
  }
  return result;
};
