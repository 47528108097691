import React, { FC, useMemo } from 'react';
import { FiRefreshCcw } from 'react-icons/fi';
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import Tooltip from 'components/UI/Tooltip';
import DateRangeFilter from 'components/DateRangeFilter';
import { parsingDate } from 'utils/momentParse';
import moment from 'moment';
import { useUserTheme } from 'domains/user/model/selectors';
import cn from 'classnames';
import { KeysSettings, useStateValue } from 'utils/context';
import { Period } from 'types/general';
import { TGetListDataItem } from 'domains/blackList/types';
import css from '../styles.module.scss';
import i18n from '../../../../i18n';
import Filter from '../Filter';

interface IHeaderProps {
  fakeHeader: React.ReactNode;
  fakeHeaderRef: React.RefObject<HTMLDivElement>;
  fixed: boolean;
  updateData: () => void;
  setCount: React.Dispatch<React.SetStateAction<number>>;
  total: number;
  setPeriod: React.Dispatch<React.SetStateAction<Period>>;
  period: Period;
  setFileStatus: React.Dispatch<React.SetStateAction<number>>;
  setSource: React.Dispatch<React.SetStateAction<string[]>>;
  setPublisher: React.Dispatch<React.SetStateAction<string[]>>;
  setDomain: React.Dispatch<React.SetStateAction<string[]>>;
  setPartnerXxhash: React.Dispatch<React.SetStateAction<string[]>>;
  setNeedUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  blDomains: TGetListDataItem[];
}

const Header: FC<IHeaderProps> = ({
  fakeHeader,
  fakeHeaderRef,
  fixed,
  updateData,
  setCount,
  total,
  setPeriod,
  period,
  setFileStatus,
  setSource,
  setPublisher,
  setDomain,
  setPartnerXxhash,
  setNeedUpdate,
  blDomains,
}) => {
  const { state } = useStateValue();
  const searchParams = new URLSearchParams(window.location.search);
  const title = searchParams.get('title');

  const settings = state.settings[KeysSettings.BLACK_LIST];

  const onUpdateDate = (currentPeriod: Period) => {
    setCount(0);
    setNeedUpdate(true);
    setPeriod(currentPeriod);
  };

  const links = useMemo(
    () => [
      {
        title: i18n.t(`blackLists.title`),
        url: `/control_panel/blackLists`,
        type: 'home',
      },
      {
        title: `${title}`,
        url: `заглушка`,
        type: 'end',
      },
    ],
    [i18n.language],
  );

  const { isThemeExtended, isThemeFull } = useUserTheme();

  return (
    <>
      {fakeHeader}
      <div className={css.header} ref={fakeHeaderRef}>
        <div
          className={cn('container resized', {
            extend: isThemeExtended,
            full: isThemeFull,
          })}
        >
          <Breadcrumbs type="Custom" linksCustom={links} />
          <div className={css.headeRow}>
            <h1 className={css.title}>{title}</h1>
            <div className={css.actions}>
              <div>
                <div className={css.uploaded}>
                  {i18n.t(`blackLists.exported`)}:{' '}
                  {`${blDomains.length}/${total}`}
                </div>
              </div>
              <div className={css.btns}>
                <div className={css.update}>
                  <Tooltip
                    bottom
                    title={
                      <FiRefreshCcw
                        size={22}
                        onClick={() => {
                          setCount(0);
                          updateData();
                        }}
                      />
                    }
                  >
                    {i18n.t(`update_btn`)}
                  </Tooltip>
                </div>
                <Filter
                  fixed={fixed}
                  setFileStatus={setFileStatus}
                  setSource={setSource}
                  setPublisher={setPublisher}
                  setDomain={setDomain}
                  setPartnerXxhash={setPartnerXxhash}
                  setNeedUpdate={setNeedUpdate}
                  setCount={setCount}
                />
              </div>
              <div className={css.range}>
                <DateRangeFilter
                  range={period}
                  dateStart={parsingDate(
                    moment().subtract(365, 'days'),
                  ).toDate()}
                  dateEnd={parsingDate(new Date()).toDate()}
                  initialTitleDay={settings.type}
                  onSetDateRange={onUpdateDate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
