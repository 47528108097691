import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Form, FormikProvider } from 'formik';
import cn from 'classnames';
import { FiUser, FiLock, FiAlertCircle } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import Button from 'components/UI/Buttons/buttons';
import Input from 'components/UI/Input/input';
import Card from 'components/UI/Card';
import Loader from 'components/UI/Loader';
import css from '../styles.module.scss';
import { useSignUpDetail } from '../hooks/useSignUpDetail';
import PhoneInput from '../../../components/UI/Input/phone';
import { getValueByName } from '../../project/model/utils/getbyLinkFile';

const SignUp: FC = () => {
  const userUpdate = localStorage.getItem('$user_update') || '';
  const params = new URLSearchParams(userUpdate);
  const method = params.get('a');
  const {
    error,
    formikContext,
    registrationKeyList,
    submitCount,
    values,
    touched,
    errors,
    setFieldValue,
    handleChange,
    handleBlur,
    isLoadingResponse,
    project,
  } = useSignUpDetail(userUpdate, method);

  const profileAdditional = ['skype', 'telegram'] as const;

  const { t, i18n } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('form.common.signup')}</title>
      </Helmet>

      {isLoadingResponse && (
        <div className={css.loading}>
          <Loader />
        </div>
      )}

      <div className={css.changeform}>
        {t('form.common.have_acc')}{' '}
        <Link to="/auth/sign-in">{t('form.common.signin')}</Link>
      </div>
      <FormikProvider value={formikContext}>
        <div className={css.content}>
          <Card noHover className={css.card}>
            <Form className={css.form}>
              <h1 className={css.title}>{t('form.common.signup')}</h1>
              {!!error && (
                <div className={css.error}>
                  <FiAlertCircle size={20} />
                  <span>{error}</span>
                </div>
              )}

              {registrationKeyList.some((e) =>
                ['login', 'phone', 'skype', 'telegram'].includes(e),
              ) && (method !== 'confirmationCreate') && (
                <div className={cn(css.input_title, 'mr40')}>
                  {t('top.menu.account')}
                </div>
              )}

              <Input
                label={t('form.common.email')}
                icon={<FiUser size={24} />}
                error={!!submitCount && !!touched.email && !!errors.email}
                errortext={errors.email}
                disabled={method === 'confirmationCreate'}
                inputAttributes={{
                  name: 'email',
                  placeholder: 'admin@gmail.com',
                  value: values.email,
                  onChange: handleChange,
                  onBlur: handleBlur,
                  maxLength: 255,
                }}
              />
              {['password', 'confirm'].map((keyPass) => (
                <Input
                  key={keyPass}
                  label={t(`form.common.${keyPass}`)}
                  icon={<FiLock size={24} />}
                  error={
                    !!submitCount && !!touched[keyPass] && !!errors[keyPass]
                  }
                  errortext={errors[keyPass]}
                  inputAttributes={{
                    type: 'password',
                    name: keyPass,
                    value: values[keyPass],
                    onBlur: handleBlur,
                    onChange: handleChange,
                  }}
                />
              ))}

              {registrationKeyList.some((e) =>
                ['login', 'phone', 'skype', 'telegram'].includes(e),
              ) && (method !== 'confirmationCreate') && (
                <div className={css.input_title}>
                  {t('profile.profile_word')}
                </div>
              )}

              {registrationKeyList.includes('login')  && (method !== 'confirmationCreate') && (
                <Input
                  label={t('form.common.login_lab')}
                  error={!!submitCount && !!touched.login && !!errors.login}
                  errortext={errors.login}
                  inputAttributes={{
                    placeholder: 'username',
                    name: 'login',
                    value: values.login,
                    onChange: handleChange,
                    onBlur: handleBlur,
                  }}
                />
              )}

              {registrationKeyList.includes('phone') && (method !== 'confirmationCreate') && (
                <PhoneInput
                  mask="+7 (999) 999-99-99"
                  placeholder="+7 (999) 999-99-99"
                  label={t('form.common.mob_phone')}
                  name="phone"
                  onChange={(e) => {
                    const value = e.target.value || '';
                    setFieldValue('phone', value);
                  }}
                  error={!!submitCount && !!touched.phone && !!errors.phone}
                  errortext={errors.phone}
                />
              )}

              {profileAdditional.map(
                (key) =>
                  registrationKeyList.includes(key) && (method !== 'confirmationCreate') && (
                    <Input
                      key={key}
                      label={t(`form.common.${key}`)}
                      error={!!submitCount && !!touched[key] && !!errors[key]}
                      errortext={errors[key]}
                      inputAttributes={{
                        name: key,
                        value: values[key],
                        onChange: handleChange,
                        onBlur: handleBlur,
                      }}
                    />
                  ),
              )}
              {project && getValueByName(project.files, 'offer') && (
                <div className={css.offer}>
                  {t('form.common.offer')}
                  <a
                    href={i18n.language === 'ru' ? getValueByName(project.files, 'offer') : getValueByName(project.files, 'offer_en') || getValueByName(project.files, 'offer')}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('form.common.offer__link')}
                  </a>
                </div>
              )}
              <Button
                title={t('form.common.register')}
                buttonAttributes={{
                  type: 'submit',
                  style: {
                    fontWeight: 700,
                    marginTop: 16,
                  },
                  disabled: isLoadingResponse,
                }}
              />
              <div className={cn(css.changeform, 'nodesktop')}>
                {t('form.common.have_acc')}{' '}
                <Link to="/auth/sign-in">{t('form.common.signin')}</Link>
              </div>
            </Form>
          </Card>
        </div>
      </FormikProvider>
    </>
  );
};

export default SignUp;
