import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useCreativeTypes } from 'domains/creative/model/selectors';
import css from './styles.module.scss';
import { Creative } from '../../../../domains/creative/types';
import { TItem } from '../../../../types/general';

type Props = {
  creative: Creative;
  isActives: TItem[];
  setIsActives: (p: Props['isActives']) => void;
};

const CreativeComponent: FC<Props> = ({
  creative: { title, xxhash, type, campaign_xxhash, creative_id, internal_id },
  isActives,
  setIsActives,
}) => {
  const { t } = useTranslation();
  const { data: creativeTypes } = useCreativeTypes();
  const creativeTypesArr = creativeTypes && Object.values(creativeTypes);
  return (
    <div
      className={css.creative}
      onClick={() => {
        if (isActives.some((item) => item.xxhash === xxhash)) {
          return setIsActives(
            isActives.filter((item) => item.xxhash !== xxhash),
          );
        }

        return setIsActives([...isActives, { xxhash, title, campaign_xxhash }]);
      }}
    >
      <div
        className={cn(css.checkbox, {
          isActive: isActives.some((item) => item.xxhash === xxhash),
        })}
      />
      <div className={css.id}>{creative_id}</div>
      {creativeTypesArr
        ?.filter(({ key }) => key === type)
        .map(({ key, icon }) => (
          <div className={cn(css.icon)}>
            <img src={icon} alt={key} />
          </div>
        ))}
      <div className={css.data}>
        <div className={css.title}>{title}</div>
        <div className={css.info}>
          <span>
            {`${t('just_words.w2')}`}
            {creativeTypesArr
              ?.filter(({ key }) => key === type)
              .map(({ key }) =>
                t(`creative.create_menu.${key}`),
              )}
          </span>
          <span>{`${internal_id}`}</span>
        </div>
      </div>
    </div>
  );
};

export default CreativeComponent;
