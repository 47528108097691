/* eslint-disable prefer-const */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Button from '../UI/Buttons/buttons';
import css from './styles.module.scss';
import AccessUserRight from '../AccessUserRight';
import { ElementsNoneImages } from './Images';

type TContractsNoneProps = {
  /* От этого параметра меняетсятся текст */
  search?: boolean | null;
  status: string;
};

const ContractsNone: FC<TContractsNoneProps> = ({ search, status }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const createOffers = () =>
    history.replace({
      pathname: `contract/create`,
    });


  return (
    <>
      <div className={css.empty}>
        <div className={css.img}>
          {search ? (
            <img
              src={ElementsNoneImages.no_search}
              alt={ElementsNoneImages.no_search}
            />
          ) : (
            <>
              {status !== 'DELETED' && (
                <img
                  src={ElementsNoneImages.contracts_empty_all}
                  alt={ElementsNoneImages.contracts_empty_all}
                />
              )}
              {status === 'DELETED' && (
                <img
                  src={ElementsNoneImages.contracts_empty_del}
                  alt={ElementsNoneImages.contracts_empty_del}
                />
              )}
            </>
          )}
        </div>
        {search ? (
          <div className={css.search}>
            <div className={css.header}>{`${t('search_none.none_1')}`}</div>
            <div className={css.text}>
              <span>{`${t('search_none.none_2')}`}</span>{' '}
              <span>{`${t('search_none.none_3')}`}</span>
            </div>
          </div>
        ) : (
          <div className={css.title}>
            {status === 'DELETED' && t(`ord.no_del_contracts`)}
            {status !== 'DELETED' && (
              <>
                <span
                  style={{
                    fontWeight: 400,
                  }}
                >
                  {t(`ord.contacts_none`)}
                </span>
                <div className={css.actions}>
                  <AccessUserRight>
                    {(props) => (
                      <Button
                        title={t(`ord.contract_create`)}
                        buttonAttributes={{
                          className: css.link,
                          onClick: createOffers,
                          disabled: props.isDisable,
                        }}
                      />
                    )}
                  </AccessUserRight>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default ContractsNone;
