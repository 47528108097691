import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Form, FormikProvider } from 'formik';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from 'components/UI/Buttons/buttons';
import Input from 'components/UI/Input/input';
import Card from 'components/UI/Card';
import { FiAlertCircle } from 'react-icons/fi';
import cn from 'classnames';
import { ReactComponent as ShowPassSvg } from '../../../assets/show_pass.svg';
import { ReactComponent as HidePassSvg } from '../../../assets/hide_pass.svg';
import css from '../styles.module.scss';
import { useChangePasswordDetail } from '../hooks/useChangePasswordDetail';

const ChangePassword: FC = () => {
  const { t } = useTranslation();

  const {
    error,
    isLoading,
    formikContext,
    submitCount,
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    formFields,
    openPass,
    handleToggleOpenPass,
  } = useChangePasswordDetail();

  return (
    <>
      <Helmet>
        <title>{t('form.common.authorization')}</title>
      </Helmet>

      <div className={css.changeform}>
        {t('form.common.new_user')}{' '}
        <Link to="/auth/sign-up">{t('form.common.register')}</Link>
      </div>

      <FormikProvider value={formikContext}>
        <div className={css.content}>
          <Card noHover className={css.card}>
            <Form className={css.form}>
              <h1 className={css.title}>{t('login_page.changeYourPass')}</h1>

              {!!error.length && (
                <div className={css.error}>
                  <FiAlertCircle size={20} />
                  <span>{error}</span>
                </div>
              )}
              {formFields.map((key) => (
                <Input
                  key={key}
                  label={t(`form.common.${key}`)}
                  icon={
                    <div onClick={() => handleToggleOpenPass(key)}>
                      {values[key] &&
                        (openPass[key] ? <HidePassSvg /> : <ShowPassSvg />)}
                    </div>
                  }
                  icon_classname={css.toggle_pass}
                  error={!!submitCount && !!touched[key] && !!errors[key]}
                  errortext={errors[key]}
                  inputAttributes={{
                    type: openPass[key] ? 'text' : 'password',
                    value: values[key],
                    name: key,
                    onChange: handleChange,
                    onBlur: handleBlur,
                  }}
                />
              ))}

              <Button
                title={t('form.common.saveChanges')}
                buttonAttributes={{
                  type: 'submit',
                  style: {
                    fontWeight: 700,
                    marginTop: 16,
                  },
                  disabled: isLoading,
                }}
              />

              <div className={cn(css.changeform, 'nodesktop')}>
                {t('form.common.have_acc')}{' '}
                <Link to="/auth/sign-in">{t('form.common.signin')}</Link>
              </div>
            </Form>
          </Card>
        </div>
      </FormikProvider>
    </>
  );
};

export default ChangePassword;
