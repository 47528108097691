import React, { FC, useEffect, useRef, useState } from 'react';
import { useSubstitutionOfVoids } from 'utils/SubstitutionOfVoids';
import { useOnScroll } from 'hooks/useOnScroll';
import cn from 'classnames';
import { FiRefreshCcw, FiSearch, FiX } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { TFileList } from 'domains/blackLists/types';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import { useUserTheme } from 'domains/user/model/selectors';
import css from '../styles.module.scss';
import ListFilter from '../ListFilter';

interface IProps {
  setFilteredData: React.Dispatch<React.SetStateAction<any[]>>;
  listData: TFileList[];
  setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setType: React.Dispatch<React.SetStateAction<string>>;
  search: string | null;
  setSearch: React.Dispatch<React.SetStateAction<string | null>>;
}

const Header: FC<IProps> = ({
  setFilteredData,
  listData,
  setUpdate,
  setType,
  search,
  setSearch,
}) => {
  const { t } = useTranslation();
  const { fixed } = useOnScroll(110);
  // заполняет пустоту, когда фиксируется header и заголовок таблицы, чтобы не скакал скролл
  const { fakeHeader, fakeHeaderRef } = useSubstitutionOfVoids({
    fixed,
  });

  const searchRef = useRef<HTMLInputElement>(null);
  const { isThemeExtended, isThemeFull } = useUserTheme();
  const [toggleSearch, setToggleSearch] = useState(false);
  const [focus, setFocus] = useState<boolean>(false);

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearch(searchTerm);
    const filteredList = listData.filter((item) =>
      item.title.toLowerCase().includes(searchTerm),
    );
    setFilteredData(filteredList);
  };

  const updateBlFiles = () => {
    setUpdate(true);
  };

  const focuseOnInput = () => {
    searchRef.current?.focus();
  };

  useEffect(() => {
    focuseOnInput();
  }, [focus]);

  useEffect(() => {
    function handleClickOutside(e: any) {
      if (
        searchRef.current &&
        !searchRef.current.contains(e.target) &&
        !search
      ) {
        setSearch(null);
        setToggleSearch(false);
        setFilteredData(listData);
        setFocus(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchRef, listData, search]);

  return (
    <>
      {fakeHeader}
      <div className={css.header} ref={fakeHeaderRef}>
        <div
          className={cn('container resized', {
            extend: isThemeExtended,
            full: isThemeFull,
          })}
        >
          <div
            className={cn(css.headeRow, {
              _isActiveSearch: toggleSearch,
            })}
          >
            <h1 className={css.title}>{t(`blackLists.title`)}</h1>
            <div className={css.actions}>
              <div className={css.search}>
                <div
                  className={css.icon}
                  onClick={() => {
                    setToggleSearch(true);
                    setFocus(true);
                  }}
                >
                  <TooltipPoratal
                    hint={t(
                      `campaigns_page.campaign_settings.additional.pre_targ_search`,
                    )}
                  >
                    <FiSearch size={24} />
                  </TooltipPoratal>
                </div>
                <div className={css.input}>
                  <input
                    type="text"
                    placeholder={t(`offers_page.enter_name`)}
                    value={search || ''}
                    onChange={onChangeSearch}
                    ref={searchRef}
                  />
                </div>
                <div
                  className={css.close}
                  onClick={() => {
                    setSearch(null);
                    setToggleSearch(false);
                    setFocus(false);
                    setFilteredData(listData);
                  }}
                >
                  <FiX size={24} />
                </div>
              </div>
              <div className={css.update}>
                <TooltipPoratal hint={t(`update_btn`)}>
                  <FiRefreshCcw size={22} onClick={updateBlFiles} />
                </TooltipPoratal>
              </div>
              <div className={css.filter}>
                <ListFilter fixed={fixed} setType={setType} setUpdate={setUpdate}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
