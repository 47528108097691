import React from 'react';
import Help from 'components/Help';
import cn from 'classnames';
import { useUserTheme } from 'domains/user/model/selectors';
import { toolTipType } from './types';
import css from './styles.module.scss';

export type PLabel = {
  /** Текст лейбла */
  label: string;
  /** id или имя инпута */
  forInput?: string;
  /** тултип со ссылкой */
  tooltip?: toolTipType;
  isError?: boolean;
};

export default function Label({
  label,
  forInput = '#',
  tooltip,
  isError,
}: PLabel): JSX.Element {
  const { getValueFromSettingForKey } = useUserTheme();
  return (
    <label
      htmlFor={forInput}
      className={cn(css.label, 'label', {
        error: isError,
      })}
    >
      {label}
      {tooltip && getValueFromSettingForKey('HideHelp') && (
        <Help
          title={tooltip?.title || ''}
          helpattributes={{
            text: tooltip?.text,
            link: tooltip?.link,
            sizeIcon: tooltip.sizeIcon ? tooltip.sizeIcon : 16,
            colorIcon: tooltip.colorIcon ? tooltip.colorIcon : '#828282',
          }}
        />
      )}
    </label>
  );
}
