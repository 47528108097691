/* eslint-disable guard-for-in */
import { shallowEqual, useSelector } from 'react-redux';
import { AppState } from 'redux/rootReducer';
import { useDispatchApp } from 'redux/rootSelectors';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { KeysSettings, useStateValue } from 'utils/context';
import { fetchStatisticFieldList } from 'domains/campaigns/model/actions';
import { usePartnerStatisticFieldList } from 'domains/campaigns/model/selectors';
import { Period } from 'types/general';
import { TStatisticsFields } from 'domains/campaign/types';
import { AgencyState, EClientsStatuses, TGetStatisticByAudit } from '../types';
import { fetchAgencyInfo, fetchStatisticByAudit } from '../reducer';

export interface IReportsAuData {
  show: number;
  click: number;
  cpc: number;
  cpm: number;
  ctr: number;
  spent: number;
  bid_statistic: number;
  minus_bid: number;
  fclick: number;
  accept: number;
  convs_val: number;
  cpo: number;
  win_rate: number;
  minus_win_raw_tt: number;
  margin: number;
  cpm_bid: number;
  client_title: string;
  audit_dictionary_title: string;
}
interface IReportsAuArr {
  [x: string]: {
    clientId: IReportsAuData;
  };
}

type TUseReportsAu = () => {
  setStatuses: React.Dispatch<React.SetStateAction<EClientsStatuses[]>>;
  statuses: EClientsStatuses[];
  settings: any;
  onUpdateDate: (period: Period, type: string) => void;
  totalData: (string[] | number[])[];
  setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  transformReportsAu: (string | number)[][];
  getAuditTitles: string[];
  statisticFieldList: TStatisticsFields | null;
  getInternalIdObj: {[key: string]: string}[];
  loadingReports: boolean;
};

export const useReportsAu: TUseReportsAu = () => {
  const dispatch = useDispatchApp();
  const { id: partner_xxhash } = useParams<{ id: string }>();
  const reportsAuData = useSelector<AppState, AgencyState['statisticByAudit']>(
    ({ agencyReducer }) => agencyReducer.statisticByAudit,
    shallowEqual,
  );
  const reportsAu = reportsAuData.get('data');
  const isReportLoading = reportsAuData.get('isLoading');
  const { state, dispatch: dispatchContext } = useStateValue();
  const settings = state.settings[KeysSettings.AGENCY_REPORT];

  const { data: statistic } = usePartnerStatisticFieldList();
  const statisticFieldList =
    statistic?.filter((s) =>
      Object.keys(s).every(
        () => !(s.default === false && s.checked === false),
      ),
    ) || [];

  const [statuses, setStatuses] = useState<EClientsStatuses[]>([
    EClientsStatuses.STOPPED,
    EClientsStatuses.LAUNCHED,
  ]);
  const [currentPeriod, setCurrentPeriod] = useState<Period>(settings.period);
  const [totalData, setTotalData] = useState<(string[] | number[])[]>([]);
  const [update, setUpdate] = useState<boolean>(false);
  const [loadingReports, setLoadingReports] = useState<boolean>(false);

  useEffect(() => {
    setLoadingReports(isReportLoading);
  }, [isReportLoading]);

  // Превращаем объект, пришедший с бэка в массив
  const reportsAuArr: IReportsAuArr[] | null = useMemo(
    () =>
      reportsAu &&
      Object.entries(reportsAu).map((item) => ({ [item[0]]: item[1] })),
    [reportsAu],
  );

  const getInternalIdObj = useMemo(() => {
    let internalIdObjArr: { [key: string]: string }[] = [];

    if (reportsAuArr) {
      reportsAuArr?.forEach((item) =>
        Object.values(item).forEach((it) =>
          Object.keys(it).forEach((i) => {
            if (i !== 'total') {
              internalIdObjArr.push({
                [it[i].client_title]: it[i].internal_id,
              });
            }
          }),
        ),
      );
    }
    internalIdObjArr = internalIdObjArr.filter(
      (obj, index, self) =>
        index ===
        self.findIndex((t) => JSON.stringify(t) === JSON.stringify(obj)),
    );
    return internalIdObjArr;
  }, [reportsAuArr]);

  // Получаем массив названий аудиторий
  const getAuditTitles = useMemo(() => {
    let audiencesNamesForReportsAu: string[] = [];

    if (reportsAuArr) {
      reportsAuArr?.forEach((item) =>
        Object.values(item).forEach((it) =>
          Object.keys(it).forEach((i) => {
            if (i !== 'total') {
              audiencesNamesForReportsAu.push(it[i].audit_dictionary_title);
            }
          }),
        ),
      );
    }

    // Удаляем дубликаты
    audiencesNamesForReportsAu = [...new Set(audiencesNamesForReportsAu)];

    return audiencesNamesForReportsAu;
  }, [reportsAuArr]);

  const changeEmptyToZero = (arr1: (string | number)[][], arr2: string[]) => {
    const tempArr = arr1;
    for (let i = 0; i < tempArr.length; i += 1) {
      while (tempArr[i].length - 2 < arr2.length) {
        tempArr[i].push(0);
      }
    }
    return tempArr;
  };

  // Формируем массив с данными подходящий под таблицу ReportTableAu
  const transformReportsAu = useMemo(() => {
    let data: (string | number)[][] = [];

    if (reportsAuArr) {
      reportsAuArr.forEach((item) => {
        Object.values(item).forEach((it) => {
          Object.keys(it).forEach((i) => {
            if (i !== 'total') {
              const items = data.filter((d) => d[0] === it[i].client_title);

              if (!items.length) {
                Object.keys(it[i]).forEach((key) => {
                  if (
                    key !== 'audit_dictionary_title' &&
                    key !== 'internal_id' &&
                    key !== 'client_title'
                  ) {
                    const index = getAuditTitles.findIndex(
                      (name) => name === it[i].audit_dictionary_title,
                    );
                    const tempEl = [it[i].client_title, key];
                    if (index > 0) {
                      let ind = 0;
                      while (index - ind !== 0) {
                        tempEl.push(0);
                        ind += 1;
                      }
                      tempEl[index + 2] = it[i][key];
                    } else {
                      tempEl[index + 2] = it[i][key];
                    }
                    data.push(tempEl);
                  }
                });
              }
              if (items) {
                Object.keys(it[i]).forEach((key) => {
                  if (
                    key !== 'audit_dictionary_title' &&
                    key !== 'internal_id' &&
                    key !== 'client_title'
                  ) {
                    const index = getAuditTitles.findIndex(
                      (name) => name === it[i].audit_dictionary_title,
                    );

                    items.map((elem) => {
                      if (elem[1] === key) {
                        const tempEl = elem;
                        tempEl[index + 2] = it[i][key];
                        return tempEl;
                      }
                      return elem;
                    });
                  }
                });
              }
            }
          });
        });
      });
    }

    data = changeEmptyToZero(data, getAuditTitles);
    return data;
  }, [reportsAuArr]);

  // Функция помогает превратить total в массив подходящий под формат таблицы
  const convertArrayOfObjectsToArrayOfArrays = (arr: IReportsAuData[]) => {
    if (arr.length) {
      const keys = Object.keys(arr[0]);
      const result: (string[] | number[])[] = [];
      keys.forEach((key) => {
        const temp = [key];
        arr.forEach((obj) => {
          temp.push(obj[key]);
        });
        result.push(temp);
      });
      return result;
    }
    return [];
  };

  const getTotalData = (object: TGetStatisticByAudit) => {
    const totals: IReportsAuData[] = [];

    Object.keys(object).forEach((key) => {
      const currentObject = object[key];
      if (currentObject.total !== undefined) {
        totals.push(currentObject.total);
      }
    });

    const completeTotalData = convertArrayOfObjectsToArrayOfArrays(totals);
    return [completeTotalData];
  };

  useEffect(() => {
    if (reportsAu) {
      const [completeTotalData] = getTotalData(reportsAu);
      setTotalData(completeTotalData);
    }
  }, [reportsAu]);

  const onUpdateDate = useCallback(
    (period: Period, type: string) => {
      setUpdate(true);
      setCurrentPeriod(period);
      dispatchContext({
        type: 'setSettings',
        payload: {
          item: {
            type,
            period,
          },
          key: KeysSettings.AGENCY_REPORT,
        },
      });
    },
    [currentPeriod],
  );

  useEffect(() => {
    if (partner_xxhash === '') return;
    dispatch(fetchAgencyInfo({ id: partner_xxhash }));
  }, [partner_xxhash]);

  useEffect(() => {
    dispatch(
      fetchStatisticByAudit({
        partner_xxhash,
        period: currentPeriod,
        status_list_clients: statuses,
      }),
    );
  }, [statuses]);

  useEffect(() => {
    if (update) {
      dispatch(
        fetchStatisticByAudit({
          partner_xxhash,
          period: currentPeriod,
          status_list_clients: statuses,
        }),
      );
      dispatch(
        fetchStatisticFieldList({
          xxhash: partner_xxhash,
          types: ['All', 'Report'],
        }),
      );
    }
    setUpdate(false);
  }, [update]);

  useEffect(() => {
    dispatch(
      fetchStatisticFieldList({
        xxhash: partner_xxhash,
        types: ['All', 'Report'],
      }),
    );
  }, []);

  return {
    setStatuses,
    statuses,
    settings,
    onUpdateDate,
    totalData,
    setUpdate,
    transformReportsAu,
    getAuditTitles,
    statisticFieldList,
    getInternalIdObj,
    loadingReports,
  };
};
