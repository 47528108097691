import React, { FC } from 'react';
import Card from 'components/UI/Card';
import Load from 'components/UI/Load';
import css from '../styles.module.scss';

interface Props {
  mode: 'create' | 'edit';
}

const LoaderInfo: FC<Props> = ({ mode }) => (
  <Card isUseAccess className={css.card} noHover>
    <div>
      <Load
        style={{
          width: '100%',
          height: mode === 'create' ? '74px' : '88px',
        }}
      />
    </div>
  </Card>
);

export default LoaderInfo;
