import { FormikProps, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import deepEqual from 'deep-equal';
import { useUserTheme } from 'domains/user/model/selectors';
import { validate } from '../validateOther';
import { CreativeDetail, TCreativeTeaser } from '../../../../../types';
import { setEditCreativeRequest } from '../../../../../reduser';
import { setFormDefault } from '../../../../../../uploads/reducer';
import { useDispatchApp } from '../../../../../../../redux/rootSelectors';

type TParam = {
  xxhash: CreativeDetail['xxhash'];
  data: TCreativeTeaser;
  setEdit: (param: boolean) => void;
  erid: string;
};

type TFUseFormTeaser = (p: TParam) => {
  formik: FormikProps<TCreativeTeaser>;
  handlerFormikReset: () => void;
  handlerFormikSubmit: () => void;
};

export const useFormTeaser: TFUseFormTeaser = ({
  xxhash,
  data: { imgFormats, text, iconMask, imgMask, title, iconFormats },
  setEdit,
  erid: eridValue,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatchApp();
  const { getValueFromSettingForKey } = useUserTheme();

  const formik = useFormik<TCreativeTeaser>({
    enableReinitialize: true,
    initialValues: {
      iconFormats,
      imgMask,
      text,
      iconMask,
      imgFormats,
      title,
      erid: eridValue as string,
    },
    validate,
    onSubmit: (values) => {
      const { erid, ...restValues } = values;
      if (
        !(
          deepEqual(
            {
              iconFormats,
              imgMask,
              text,
              iconMask,
              imgFormats,
              title,
            },
            restValues,
          ) && values.erid === eridValue
        )
      ) {
        dispatch(
          setEditCreativeRequest({
            data: { xxhash, erid, data: restValues, type: 'TEASER' },
            visual: {
              setter: setEdit,
              translation:
                getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
                !getValueFromSettingForKey('ShowBroadcastStatus'),
              value: false,
              alertText: `${t('creative.type.type')}${t(
                'creative.type.teaser',
              )}`,
            },
          }),
        );
      } else {
        dispatch(setFormDefault(true));
        setEdit(false);
      }
    },
  });

  /** Функция сбрасывает состояние формы */
  const handlerFormikReset = () => {
    setEdit(false);
    dispatch(setFormDefault(true));
    formik.resetForm();
  };

  /** Функция отправляет форму */
  const handlerFormikSubmit = () => {
    formik.handleSubmit();
  };

  return { formik, handlerFormikReset, handlerFormikSubmit };
};
