import { useEffect } from 'react';
import { fetchAgencyList } from './model/actions';
import { usePartnersBalanceInfo, usePartnersListInfo } from './model/selector';
import { useDispatchApp } from '../../redux/rootSelectors';
import { useUserInfo } from '../user/model/selectors';
import { formatCurrency } from '../../utils/formatCurrency';

export type TFFilterBalance = (hash: string) => {
  value: number | null;
  currency: string | null;
};

type TFUseDataPartners = () => {
  isLoadingPartners: ReturnType<typeof usePartnersListInfo>['isLoading'];
  partners: ReturnType<typeof usePartnersListInfo>['data'];
  balances: ReturnType<typeof usePartnersBalanceInfo>['data'];
  isLoadingBalance: ReturnType<typeof usePartnersBalanceInfo>['isLoading'];
  filterBalance: TFFilterBalance;
};
/** Функция получает массив Партнеров */
export const useDataPartners: TFUseDataPartners = () => {
  const { data: partners, isLoading: isLoadingPartners } =
    usePartnersListInfo();
  const { data: user } = useUserInfo();
  const { data: balances, isLoading: isLoadingBalance } =
    usePartnersBalanceInfo();
  const dispatch = useDispatchApp();
  useEffect(() => {
    if (user?.partner.xxhash) {
      dispatch(fetchAgencyList({ partner_id: user?.partner.xxhash }));
    }
  }, [user?.partner.xxhash]);

  const filterBalance: ReturnType<TFUseDataPartners>['filterBalance'] = (
    hash,
  ) => {
    if (balances) {
      const element = balances.find(({ xxhash }) => xxhash === hash);
      if (element) {
        return {
          value: element.balance,
          currency: formatCurrency(element.currency),
        };
      }
    }
    return {
      value: null,
      currency: null,
    };
  };

  return {
    balances,
    isLoadingBalance,
    isLoadingPartners,
    partners,
    filterBalance,
  };
};
