import React, { FC, useRef } from 'react';
import cn from 'classnames';
import css from './styles.module.scss';

type Props = {
  poster: string;
  src: string;
  link?: string;
};

const VideoPlayer: FC<Props> = ({ poster, src, link }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const onClickRedirect = (e: React.MouseEvent) => {
    e.preventDefault();
    videoRef.current?.pause();
    if (link) {
      window.open(link, '_blank');
    }
  };
  return (
    <div className={cn(css.player, 'VideoPlayer')}>
      <figure id="videoContainer" data-fullscreen="false">
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          id="video"
          ref={videoRef}
          controls
          preload="metadata"
          poster={poster}
          autoPlay
          onClick={onClickRedirect}
        >
          <source src={src} type="video/mp4" />
        </video>
      </figure>
    </div>
  );
};

export default VideoPlayer;
