import Card from 'components/UI/Card';
import CardsList from 'components/UI/CardList';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';

const Widget: FC = () => {
  const { t } = useTranslation();
  return (
    <CardsList>
      <Card noHover style={{ padding: '28px 22px' }} className={css.widget}>
        <p>{t('campaigns_page.campaign_settings.additional.help_text')}</p>
      </Card>
    </CardsList>
  );
};

export default Widget;
