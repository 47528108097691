import { useEffect, useState } from "react";

type fixedHeightItemProps = (itemRef: React.RefObject<HTMLDivElement>) => number;

export const useFixedHeightItem: fixedHeightItemProps = (itemRef) => {
    const [heightItem, setHeightItem] = useState<number>(0);

    useEffect(() => {
        if (itemRef && itemRef.current) {
            setHeightItem(itemRef.current.clientHeight);
        }
    }, []);
    return heightItem;
};
