import React, { FC, useState } from 'react';
import cn from 'classnames';
import { FiArrowLeft } from 'react-icons/fi';
import css from '../../styles.module.scss';
import GridItem from '../GridItem';
import Button from '../../../../../components/UI/Buttons/buttons';
import { TArrayImages } from '../../shared/consts';
import i18n from '../../../../../i18n';

type EnterImageProps = {
  label?: string;
  isOpen: boolean;
  images: TArrayImages;
  onClose: () => void;
  handler: (value: string) => void;
};

const EnterImage: FC<EnterImageProps> = ({
  label,
  isOpen,
  images,
  handler,
  onClose,
}) => {
  const [activeImage, setActiveImage] = useState('');
  return (
    <div
      className={cn(css.stylePanel, css.leftPanel, {
        isOpen,
      })}
    >
      <div className={css.header}>
        <FiArrowLeft size={24} color="#025DFF" onClick={onClose} />
        {label && <span>{label}</span>}
      </div>
      <div className={css.grid}>
        {images.map(({ url, id }) => (
          <GridItem
            key={id}
            icon={url}
            stateActive={activeImage === url}
            onclick={() => setActiveImage(url)}
          />
        ))}
      </div>
      <Button
        title={i18n.t(`select_btn`)}
        buttonAttributes={{
          style: {
            width: '100%',
          },
          onClick: () => handler(activeImage),
        }}
      />
    </div>
  );
};
export default EnterImage;
