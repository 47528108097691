import { useState, useEffect, FC, ReactNode } from 'react';
import ReactDOM from 'react-dom';

type TPortal = {
  children: ReactNode;
  className?: string;
};

const Portal: FC<TPortal> = ({ children, className }) => {
  const [container] = useState(() => document.createElement('div'));

  useEffect(() => {
    if (container && className && !container.classList.contains(className)) {
      container.classList.add(className);
    }
  }, [className, container]);

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, []);

  return ReactDOM.createPortal(children, container);
};

export default Portal;
