import { SettingConfig, TSettingConfigValue } from '../../types';

type TSearchField = (
  array: SettingConfig[],
  name: SettingConfig['field'],
) => number;

const searchFieldIDbyName: TSearchField = (array, name) => {
  const element = array.find(({ field }) => field === name);
  return Number(element?.id);
};

type TGetSettingsValue = (
  array: SettingConfig[] | null,
  key: SettingConfig['field'],
) => TSettingConfigValue | null;

export const getSettingsValue: TGetSettingsValue = (array, key) => {
  if (!array) {
    return null;
  }
  const element = array.find(({ field }) => field === key);
  if (element) {
    return element.value;
  }
  return null;
};

export default searchFieldIDbyName;
