import React, { FC, ReactNode, useEffect, useState } from 'react';
import cn from 'classnames';
import { FiAlertCircle, FiX } from 'react-icons/fi';
import Button from 'components/UI/Buttons/buttons';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';

interface IWarningHtmlProps {
  children?: ReactNode;
  close?: 'circle' | 'button';
  setIsDisableSaveButton?: React.Dispatch<React.SetStateAction<boolean>>;
}

const WarningHtml: FC<IWarningHtmlProps> = ({
  children,
  close,
  setIsDisableSaveButton,
}) => {
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (setIsDisableSaveButton) {
      setIsDisableSaveButton(true);
    }
  }, []);

  useEffect(() => {
    if(open) return
    setOpen(true)
  }, [])
  

  return (
    <>
      {open && (
        <div
          className={cn(css.warning, {
            _isWarningHtml: close === 'circle',
            _isWarningErid: close === 'button',
          })}
        >
          <FiAlertCircle size={20} color="#FF9C08" />
          {children && <div className={css.warning_text}>{children}</div>}
          {close === 'circle' && (
            <div className={css.warning_close} onClick={() => setOpen(false)}>
              <FiX size={12} color="#FF9C08" />
            </div>
          )}
          {close === 'button' && (
            <Button
              title={t(`offers_page.confirm`)}
              transparent
              height={36}
              buttonAttributes={{
                onClick: () => {
                  setOpen(false);
                  if (setIsDisableSaveButton) {
                    setIsDisableSaveButton(false);
                  }
                },
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

export default WarningHtml;
