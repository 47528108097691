import React, { FC } from 'react';
import { FiAlertCircle, FiGlobe, FiUser } from 'react-icons/fi';
import { useHistory } from 'react-router';
import { TFileList } from 'domains/blackLists/types';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import css from '../styles.module.scss';

interface IItemProps {
  data: TFileList;
}

const Item: FC<IItemProps> = ({ data }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const isDateAfter = (date: string) => {
    const inputDate = new Date(date);
    const currentDate = new Date(
      new Date().toLocaleString('en-US', { timeZone: 'Europe/Moscow' }),
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const differenceInMilliseconds = currentDate - inputDate;
    const thirtyMinutesInMilliseconds = 30 * 60 * 1000;

    return differenceInMilliseconds > thirtyMinutesInMilliseconds;
  };

  return (
    <div
      className={css.list_item}
      onClick={() =>
        history.push(
          `blackLists/${data.id}?type=${data.type}&title=${data.title}`,
        )
      }
    >
      {data.type === 'global' ? (
        <FiGlobe color="#CFCFCF" size={20} />
      ) : (
        <FiUser color="#CFCFCF" size={20} />
      )}
      <div className={css.list_itemName}>{data.title}</div>
      <div className={css.right}>
        {data.date_update ? (
          <>
            {isDateAfter(data.date_update.date) && (
              <TooltipPoratal
                hint={t(`blackLists.something_wrong`)}
                hintWidth={200}
              >
                <FiAlertCircle color="#FF3636" />
              </TooltipPoratal>
            )}
            <div className={css.date}>
              {moment(data.date_update.date).format('DD.MM.YYYY')}
              <span>{moment(data.date_update.date).format('HH:mm')}</span>
            </div>
          </>
        ) : (
          <>
            {isDateAfter(data.date_create.date) && (
              <TooltipPoratal
                hint={t(`blackLists.something_wrong`)}
                hintWidth={200}
              >
                <FiAlertCircle color="#FF3636" />
              </TooltipPoratal>
            )}
            <div className={css.date}>
              {moment(data.date_create.date).format('DD.MM.YYYY')}
              <span>{moment(data.date_create.date).format('HH:mm')}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Item;
