import { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { RPreTargeting } from '../../../domains/campaign/types';
import { TFormikExtract } from '../../../types/general';

type TFUsePretargetingProps = {
  preTargeting: RPreTargeting | null;
  handlerSave: (value: RPreTargeting) => void;
  /** показывает состояние наследования */
  isExtend?: boolean;
};

type TFUsePretargeting = (props: TFUsePretargetingProps) => {
  modeFilter: boolean;
  setModeFilter: (val: boolean) => void;
  hasValues: (arr: RPreTargeting['pretargeting']) => boolean;
  mainSearch: string;
  setMainSearch: (val: string) => void;
  setBrandSearch: (val: string) => void;
  brandSearch: string;
  isDmn_wl_bitbsf: boolean;
  localExtend: boolean;
  setLocalExtend: (val: boolean) => void;
} & TFormikExtract<RPreTargeting>;

export const usePretargeting: TFUsePretargeting = ({
  preTargeting,
  handlerSave,
  isExtend,
}) => {
  const [localExtend, setLocalExtend] = useState(false);

  const [modeFilter, setModeFilter] =
    useState<ReturnType<TFUsePretargeting>['modeFilter']>(false);

  const [mainSearch, setMainSearch] = useState<string>('');
  const [brandSearch, setBrandSearch] = useState<string>('');

  const formik = useFormik<RPreTargeting>({
    initialValues: {
      currentValue: {},
      pretargeting: [],
      brand_safety: {
        disallow_group: [],
        '0': { key: '', checked: false, title: '', order: 0 },
      },
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      if (values.currentValue) {
        handlerSave(values.currentValue);
      }
    },
  });

  const hasValues: ReturnType<TFUsePretargeting>['hasValues'] = (arr) =>
    Array.isArray(arr) && arr.length > 0 && arr.some((e) => e.checked);

  /** Какая-то странная проверка на какой-то ключ */
  const isDmn_wl_bitbsf = useMemo<boolean>(() => {
    const element = formik.values.pretargeting?.find(
      ({ key }) => key === 'dmn_wl_bitbsf',
    );
    if (element) {
      return element.checked;
    }
    return false;
  }, [formik]);

  useEffect(() => {
    if (preTargeting) {
      formik.setValues(preTargeting);
    }
  }, [preTargeting]);

  useEffect(() => {
    if (isExtend && localExtend) {
      setLocalExtend(false);
    }
  }, [isExtend]);

  return {
    hasValues,
    setModeFilter,
    modeFilter,
    mainSearch,
    setMainSearch,
    brandSearch,
    setBrandSearch,
    isDmn_wl_bitbsf,
    setLocalExtend,
    localExtend,
    formikContext: formik,
    ...formik,
  };
};
