import moment from 'moment';
import { parsingDate } from '../momentParse';
import { TDates } from '../../types/general';

export const dateFilter = (type: TDates['type'], data: TDates): TDates => {
  const rangeWeek = {
    from: parsingDate(moment(new Date()).subtract(6, 'days')).format(
      'YYYY-MM-DD',
    ),
    to: parsingDate(new Date()).format('YYYY-MM-DD'),
  };

  const rangeDay = {
    from: parsingDate(new Date()).format('YYYY-MM-DD'),
    to: parsingDate(new Date()).format('YYYY-MM-DD'),
  };

  const rangeYesterday = {
    from: parsingDate(moment().subtract(1, 'days')).format('YYYY-MM-DD'),
    to: parsingDate(moment().subtract(1, 'days')).format('YYYY-MM-DD'),
  };

  const range30Days = {
    from: parsingDate(moment().subtract(29, 'days')).format('YYYY-MM-DD'),
    to: parsingDate(new Date()).format('YYYY-MM-DD'),
  };

  const rangeMonth = {
    from: parsingDate(new Date()).startOf('month').format('YYYY-MM-DD'),
    to: parsingDate(new Date()).format('YYYY-MM-DD'),
  };

  const dates = new Map<TDates['type'], TDates['period']>([
    ['day', rangeDay],
    ['yesterday', rangeYesterday],
    ['week', rangeWeek],
    ['month', rangeMonth],
    ['30days', range30Days],
  ]);
  const newRange = dates.get(type);
  if (newRange) {
    return {
      type,
      period: newRange,
    };
  }
  return data;
};
