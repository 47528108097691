import { ResponseTranslation } from 'domains/campaigns/types';
import { useSelector } from 'react-redux';
import { useDispatchApp } from 'redux/rootSelectors';
import { AppState } from 'redux/rootReducer';
import moment from 'moment';
import { useRouteMatch } from 'react-router';
import { TID } from 'types/general';
import { useCreativeDetailInfo } from '../selectors';
import { CreativeState, fetchCreativeTranslation } from '../../reduser';

interface ReturnType {
  translationInfo: ResponseTranslation | null;
  isLoading: boolean;
  handlerFetchTranslation: () => void;
  time: string | boolean;
  isError: boolean;
}

export const useDataCreativeTranslation = (): ReturnType => {
  const dispatch = useDispatchApp();
  const { params } = useRouteMatch<TID>();

  const { data: dataTime } = useCreativeDetailInfo();
  const time = dataTime
    ? moment(dataTime?.date_edit?.date).format('hh:mm/ DD.MM.YYYY')
    : false;

  const handlerFetchTranslation = () => {
    dispatch(fetchCreativeTranslation({ xxhash: params.id }));
  };

  const { creativeTranslation } = useSelector<AppState, CreativeState>(
    (state) => state.creativeReducer,
  );
  const { isError } = creativeTranslation.get('error');
  const translationInfo = creativeTranslation.get('data');
  const isLoading = creativeTranslation.get('isLoading');

  return { translationInfo, isLoading, handlerFetchTranslation, time, isError };
};
