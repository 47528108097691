/* eslint-disable no-unused-expressions */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import TableFilter from 'components/UI/TableFilter';
import { ScrollSyncNode } from 'scroll-sync-react';
import { useTranslation } from 'react-i18next';
import { FiCode } from 'react-icons/fi';
import css from './styles.module.scss';

interface tableProps {
  currentSort: 'ASC' | 'DESC' | null;
  currentColumn: string | null;
  columns: {
    name: string;
    data: string;
  }[];
  mode: 'client' | 'agency';
  handleSort: (p: { key: string; sort: 'ASC' | 'DESC' | null }) => void;
  /* Ширина первого столбца */
  extendWidth: number;
  /* Изменение ширины столбца */
  setExtendWidth: React.Dispatch<React.SetStateAction<number>>;
  /* Наличие кнопки для расширения */
  extandableColumn?: boolean;
}

const TableHeader: FC<tableProps> = ({
  currentSort,
  currentColumn,
  handleSort,
  columns,
  mode,
  extendWidth,
  setExtendWidth,
  extandableColumn,
}) => {
  const tableRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  /* Для определения желания раздвинуть колонку */
  const [extandStart, setExtandStart] = useState<boolean>(false);
  /* Для отслеживания положения мыши по оси Х */
  const [extendOffsetX, setExtendOffsetX] = useState<number>(0);

  /* Рассчитываем новую ширину исходи из положения мыши и устанавливаем */
  const onMouseMove = (e: MouseEvent) => {
    const newValue = e.clientX - extendOffsetX;
    setExtendWidth(newValue);
  };

  /* Если клавиша мыши будет отпущена, то завершаем движение и удаляем обработчик события движения мыши */
  const onMouseUp = () => {
    document.addEventListener('mouseup', () => {
      setExtandStart(false);
      document.removeEventListener('mousemove', onMouseMove);
    });
  };

  /* Если начато движение, вешаем обработчик события - движение мыши */
  /* и обработчик отжатой мыши для завершения движения */
  useEffect(() => {
    if (extandStart) {
      document.addEventListener('mousemove', onMouseMove);
      onMouseUp();
    }
  }, [extandStart]);

  /* Обозначили, что движение начато. И отсчитали отсут от края экрана слева */
  const onMouseDown = useCallback(() => {
    setExtandStart(true);

    if (tableRef && tableRef.current) {
      // расстояние от края экрана - размер левого столбца. Находим отсуп до таблицы
      setExtendOffsetX(tableRef.current.getBoundingClientRect().left);
    }
  }, []);

  return (
    <div className={cn(css.table_row, css.table_header)} ref={tableRef}>
      {mode === 'client' && (
        <div
          className={css.table_row_static}
          style={{
            width: extendWidth,
          }}
        >
          <div className={css.cell}>
            <TableFilter
              key="date"
              data="date"
              title={t(`common.reports.general.t2`)}
              activeKey={currentColumn}
              sort={currentSort}
              handleSort={handleSort}
              isShow
            />
          </div>
        </div>
      )}
      {mode === 'agency' && (
        <div
          className={css.table_row_static}
          style={{
            width: extendWidth,
          }}
        >
          <div className={css.cell}>
            <TableFilter
              key="title"
              data="title"
              title={t(`clients_page.report_client_name`)}
              activeKey={currentColumn}
              sort={currentSort}
              handleSort={handleSort}
            />
            {extandableColumn && (
              <div
                className={cn(css.expand, {
                  isMove: extandStart,
                })}
                onMouseDown={onMouseDown}
              >
                <div className={css.button}>
                  <FiCode size={12} />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <ScrollSyncNode group="a">
        <div className={css.table_row_scroll}>
          {mode === 'agency' && (
            <div
              className={cn(css.cell, {
                _isLongForAgency: mode === 'agency',
              })}
            >
              <TableFilter
                key="client_id"
                data="client_id"
                title={t(`clients_page.report_client_id`)}
                activeKey={currentColumn}
                sort={currentSort}
                handleSort={handleSort}
              />
            </div>
          )}

          {columns.map((column) => (
            <div className={cn(css.cell, '_isAlignRight')} key={column.name}>
              <TableFilter
                data={column.data}
                title={column.name}
                activeKey={currentColumn}
                sort={currentSort}
                handleSort={handleSort}
                isShow
              />
            </div>
          ))}
        </div>
      </ScrollSyncNode>
    </div>
  );
};

export default TableHeader;
