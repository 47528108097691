import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';
import avatarColors from '../../../../utils/avatarColors';
import { TItem } from '../../../../types/general';
import { RGetPersonal } from '../../../../domains/campaign/types';

type Props = {
  campaign: RGetPersonal;
  isActives: TItem[];
  setIsActives: (p: Props['isActives']) => void;
};

const CampaignToggle: FC<Props> = ({
  campaign: { title, xxhash, internal_id, icon },
  isActives,
  setIsActives,
}) => {
  const { t } = useTranslation();
  const colorForImg = {
    '--color-for-img': !icon
      ? avatarColors(title.trim().slice(0, 1).toLowerCase())
      : '',
  } as React.CSSProperties;

  return (
    <div
      className={css.creative}
      onClick={() => {
        if (isActives.some((item) => item.xxhash === xxhash)) {
          return setIsActives(
            isActives.filter((item) => item.xxhash !== xxhash),
          );
        }

        return setIsActives([...isActives, { xxhash, title }]);
      }}
    >
      <div
        className={cn(css.checkbox, {
          isActive: isActives.some((item) => item.xxhash === xxhash),
        })}
      />
      <div className={cn(css.icon,{
         _thereIsNoIcon: !icon,
      })} style={colorForImg}>
        {icon ? (
          <img src={icon} alt={icon} />
        ) : (
          <span>{title.trim().slice(0, 1).toUpperCase()}</span>
        )}
      </div>
      <div className={css.data}>
        <div className={css.title}>{title}</div>
        <div className={css.info}>
          <span>{`${t('just_words.w1')} ${internal_id}`}</span>
        </div>
      </div>
    </div>
  );
};

export default CampaignToggle;
