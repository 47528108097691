import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  initialState,
  setLastAddClient,
  fetchCreateClient,
} from '../../reduser';
import { useIsShowMCreate } from '../hooks';
import { useClearLinkImage } from '../../../modals/linkToImage/useClearLinkImage';
import { useUserInfo } from '../../../user/model/selectors';
import { useDispatchApp } from '../../../../redux/rootSelectors';
import {
  useIsShowMCreateInfo,
  useLastAddClientInfo,
} from '../../model/selectors';

type TForm = {
  title: string;
  site: string;
  idAgency: string;
};

type TFUseCreateClientModal = () => {
  closeReset: () => void;
  isShowMCreate: ReturnType<typeof useIsShowMCreateInfo>;
  formik: FormikProps<TForm>;
  user: ReturnType<typeof useUserInfo>['data'];
};

export const useCreateClientModal: TFUseCreateClientModal = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatchApp();
  const clearImage = useClearLinkImage();

  const formik = useFormik<TForm>({
    initialValues: {
      title: t('clients_page.client_create.client_default_title'),
      site: '',
      idAgency: '',
    },
    onSubmit: (values) => {
      dispatch(fetchCreateClient(values));
    },
    validationSchema: Yup.object({
      title: Yup.string().required(t('clients_page.client_create.hint1')),
      idAgency: Yup.string().required(t('clients_page.client_create.hint1')),
    }),
  });

  const isShowMCreate = useIsShowMCreateInfo();
  const {
    data: lastAddClient,
    LTU: LTUAddClient,
    error: errorAddClient,
  } = useLastAddClientInfo();
  const { data: user } = useUserInfo();

  const onClose = useIsShowMCreate(false);

  const closeReset = useCallback(() => {
    formik.resetForm();
    onClose();
    clearImage();
    dispatch(setLastAddClient(initialState.lastAddClient));
  }, [formik]);

  useEffect(() => {
    if (LTUAddClient === 0) return;
    if (errorAddClient.isError) {
      formik.setSubmitting(false);
    }
    closeReset();
  }, [lastAddClient]);
  useEffect(() => {
    formik.setFieldValue(
      'title',
      t('clients_page.client_create.client_default_title'),
    );
  }, [i18n.language]);

  return {
    closeReset,
    isShowMCreate,
    formik,
    user,
  };
};
