import { useSelector } from 'react-redux';
import { AppState } from 'redux/rootReducer';
import { CampaignsState } from '../../reducer';
import {
  ResponseStatisticsCampaignsWeek,
  ResponseTranslation,
  RStatisticsCampaigns,
} from '../../types';

export type DataCampaignsStatisticType = {
  isLoadingStata: boolean;
  isLoadingWeek: boolean;
  statisticsWeek: ResponseStatisticsCampaignsWeek[] | null;
  statistics: RStatisticsCampaigns | null;
  translation: Record<string, ResponseTranslation> | null;
  isError: boolean;
};
export const useDataCampaignsStatisticAndTranslation =
  (): DataCampaignsStatisticType => {
    const { tempStatistic, tempStatisticWeek, tempTranslation } = useSelector<
      AppState,
      CampaignsState
    >((state) => state.campaignsReducer);
    const statistics = tempStatistic.get('data');
    const isLoadingStata = tempStatistic.get('isLoading');
    const statisticsWeek = tempStatisticWeek.get('data');
    const isLoadingWeek = tempStatisticWeek.get('isLoading');
    const translation = tempTranslation.get('data');
    const { isError } = tempTranslation.get('error');

    return {
      isLoadingStata,
      isLoadingWeek,
      statistics,
      statisticsWeek,
      translation,
      isError,
    };
  };
