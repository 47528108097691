import React from 'react';
import { FiX } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import ModalOverlay from 'components/Modals/ModalOverlay1';
import Card from 'components/UI/Card';
import Button from 'components/UI/Buttons/buttons';
import Input from 'components/UI/Input/input';
import css from './styles.module.scss';
import { useDataMCreate } from './hooks/useDataMCreate';
import UploadImage from '../UploadImage';

export default function CreateAgencyModal(): JSX.Element {
  const { t } = useTranslation();

  const { isShowMCreate, closeReset, formik } = useDataMCreate();

  return (
    <ModalOverlay onCloseModal={closeReset} isOpen={isShowMCreate}>
      <Card className={cn(css.container)} noHover style={{ padding: '28px' }}>
        <div className={css.header}>
          <div className={css.title}>
            <h1>{t('agencies_page.modal.title')}</h1>
          </div>
          <div className={css.close}>
            <FiX size={24} onClick={closeReset} />
          </div>
        </div>
        <div className={css.form}>
          <form onSubmit={formik.handleSubmit}>
            <UploadImage mode='create'/>
            <Input
              label={t('agencies_page.modal.name')}
              error={!!formik.errors.title}
              errortext={
                typeof formik.errors?.title === 'string'
                  ? formik.errors?.title
                  : ''
              }
              showValueCount
              inputAttributes={{
                name: 'title',
                onChange: formik.handleChange,
                value: formik.values.title,
                autoComplete: 'off',
                maxLength: 128,
              }}
            />

            <Input
              label={t('agencies_page.modal.site')}
              showValueCount
              inputAttributes={{
                name: 'site',
                maxLength: 128,
                onChange: formik.handleChange,
                value: formik.values.site,
                autoComplete: 'off',
              }}
            />

            <div className={css.buttons}>
              <Button
                transparent
                title={t('cancel_btn')}
                height={36}
                autoWidth
                buttonAttributes={{
                  onClick: closeReset,
                }}
              />
              <Button
                title={t('save_btn')}
                height={36}
                autoWidth
                buttonAttributes={{
                  type: 'submit',
                  disabled: !formik.isValid || formik.isSubmitting,
                }}
              />
            </div>
          </form>
        </div>
      </Card>
    </ModalOverlay>
  );
}
