import React, { FC, InputHTMLAttributes } from 'react';
import cn from 'classnames';
import { MdInfoOutline } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import Button from 'components/UI/Buttons/buttons';
import Loader from 'components/UI/Loader';
import { FormikHelpers } from 'formik';
import { toolTipType } from 'components/UI/Input/types';
import Label from 'components/UI/Input/Label';
import input from './styles.module.scss';
import { useUploadZipHtml } from './hook';
import { TVariationNameFileZip } from '../types';
import ErrorDictionary from '../../../components/Errors/ErrorDictionary';

type Props = {
  name: TVariationNameFileZip;
  /** Label для инпута */
  label?: string;
  /** отображение ошибки */
  error?: boolean;
  /** Текст ошибки */
  errorText?: string;
  /** Предупреждающий текст */
  hintText?: React.ReactNode | string;
  /** изменением состояния в форме */
  setFieldValue: FormikHelpers<string>['setFieldValue'];
  /** Максимальный размер файла */
  maxSizeFile?: number;
  /** список размеров */
  sizes?: string[];
  /** обертка в data */
  isData?: boolean;
  /** текст ошибки при большом файле */
  sizeForError?: string;
  /** Атрибуты инпута */
  inputAttributes?: InputHTMLAttributes<HTMLInputElement>;
  /** Вывод тултипа со ссылкой */
  tooltip?: toolTipType;
  /** тип архтва */
  type: 'zip' | 'zip_banner';
};

const UploadZip: FC<Props> = ({
  name,
  label,
  hintText,
  setFieldValue,
  sizes = [],
  isData = false,
  maxSizeFile = 6,
  sizeForError = `${maxSizeFile} Mb`,
  inputAttributes,
  tooltip,
  type,
}) => {
  const { handlerChangeInput, isLoading, error } = useUploadZipHtml({
    name,
    maxSizeFile,
    setFieldValue,
    isData,
    sizes,
    type,
  });

  const { t } = useTranslation();
  return (
    <div className={cn(input['form-group'], error.isError ? '_isError' : '')}>
      {isLoading && (
        <div className={input.loader}>
          <Loader />
        </div>
      )}

      {label && (
        <Label
          label={label}
          tooltip={tooltip}
          forInput={inputAttributes?.id || name}
        />
      )}

      <div
        className={cn(input.button, {
          isDisabled: inputAttributes?.disabled || false,
        })}
      >
        <Button
          title={t('creative.create_creative.Add_archive')}
          buttonAttributes={{
            style: {
              height: 40,
            },
            disabled: inputAttributes?.disabled || false,
          }}
        />
        <label htmlFor={name}>
          <input
            type="file"
            onChange={handlerChangeInput}
            accept=".zip"
            name={name}
            id={name}
            {...inputAttributes}
          />
        </label>
      </div>

      {!error.isError && (
        <span className={input.hintText}>
          <MdInfoOutline size="20" />
          {hintText}
        </span>
      )}

      {error.isError && (
        <span className={input.errorText}>
          <MdInfoOutline size="20" />
          <ErrorDictionary code={error.code === 2034 ? 20340 : error.code} />
          {error.code === 200032 && ` ${sizeForError}`}
        </span>
      )}
    </div>
  );
};

export default UploadZip;
