import React, { FC, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import ElementsNone from 'components/ElementsNone';
import MainSearch from 'components/MainSearch';
import { useOnScroll } from 'hooks/useOnScroll';
import { useIsShowMCreate } from 'domains/agency/ui/modal/hooks/useIsShowMCreate';
import css from './styles.module.scss';
import useSearchField from '../../hooks/useSearchField';
import PreloaderList from '../../components/Preloaders/List';
import Item from './Item';
import NavigationHeader from '../../components/NavigationHeader';
import { useDataAgencies } from './hooks/useDataAgencies';
import { ElementsNoneType } from '../../types/general';
import Button from '../../components/UI/Buttons/buttons';
import Breadcrumbs from '../../components/Breadcrumbs/breadcrumbs';
import AccessUserRight from '../../components/AccessUserRight';
import ErrorReport from '../../components/Errors/Reports';

const AgencyPage: FC = () => {
  const searchRef = useRef<HTMLInputElement>(null);
  const { onChangeSearch, search, onCloseSearch, onOpenSearch, toggleSearch } =
    useSearchField(searchRef);
  const { t } = useTranslation();
  const { isLoading, agencies, status, setStatusAction, error } =
    useDataAgencies();

  const { fixed: isFixed } = useOnScroll(120);

  const isShowMCreate = useIsShowMCreate(true);

  const filterData = useMemo(() => {
    if (!agencies) return [];
    if (status) {
      return agencies.filter((agency) =>
        agency.title.toLowerCase().includes(search.toLowerCase()),
      );
    }
    return agencies.filter(
      (agency) =>
        agency.title.toLowerCase().includes(search.toLowerCase()) &&
        agency.status !== 'DELETED',
    );
  }, [agencies, search]);

  if (error.isError) {
    return <ErrorReport error={error} />;
  }

  return (
    <div className={css.users}>
      <Helmet>
        <title>{t('agencies_page.agency_title')}</title>
      </Helmet>

      <NavigationHeader>
        <button
          type="button"
          className={cn('tabs__item', {
            isActive: status && status === 'LAUNCHED',
          })}
          onClick={() => setStatusAction('LAUNCHED')}
          disabled={isLoading}
        >
          {t('agencies_page.agencies_active')}
        </button>
        <button
          type="button"
          className={cn('tabs__item', {
            isActive: status && status === 'STOPPED',
          })}
          onClick={() => setStatusAction('STOPPED')}
          disabled={isLoading}
        >
          {t('clients_page.clients_stopped')}
        </button>
        <button
          type="button"
          className={cn('tabs__item', {
            isActive: status && status === 'ARCHIVE',
          })}
          onClick={() => setStatusAction('ARCHIVE')}
          disabled={isLoading}
        >
          {t('clients_page.archive_clients')}
        </button>
        <button
          type="button"
          className={cn('tabs__item', {
            isActive: status && status === 'DELETED',
          })}
          onClick={() => setStatusAction('DELETED')}
          disabled={isLoading}
        >
          {t('clients_page.clients_deleted')}
        </button>
        <button
          type="button"
          className={cn('tabs__item', { isActive: status === null })}
          onClick={() => setStatusAction(null)}
          disabled={isLoading}
        >
          {t('agencies_page.agencies_all')}
        </button>
      </NavigationHeader>

      <div
        className={cn(css.header, {
          isToggleSearch: toggleSearch,
          isFixed,
        })}
      >
        <div className="container">
          <Breadcrumbs type="Organization" />
          <div className={css.status}>
            <h1 className={css.title}>{t('agencies_page.agency_title')}</h1>
            <div className={css.actions}>
              <MainSearch
                toggleSearch={toggleSearch}
                onOpenSearch={onOpenSearch}
                value={search}
                match={filterData.length}
                onChangeSearch={onChangeSearch}
                searchRef={searchRef}
                onCloseSearch={onCloseSearch}
                placeholder={t('agencies_page.search_placeholder_agency')}
                isShowMatch={!isLoading}
              />
              <AccessUserRight>
                {({ isDisable }) => (
                  <Button
                    height={40}
                    title={t('agencies_page.create_btn')}
                    buttonAttributes={{
                      onClick: isShowMCreate,
                      disabled: isDisable,
                    }}
                  />
                )}
              </AccessUserRight>
            </div>
          </div>
        </div>
      </div>

      <div className={css.content}>
        <div className="container">
          <div className={css.table}>
            {!isLoading && filterData.length === 0 ? (
              <ElementsNone
                type={ElementsNoneType.AGENCIES}
                search={
                  filterData.length === 0 && agencies && agencies.length > 0
                }
              />
            ) : (
              <>
                <div className={css.header}>
                  <div>{t('users_page.table.name')}</div>
                </div>
                {isLoading ? (
                  <div className={css.body}>
                    <PreloaderList type="agency" />
                  </div>
                ) : (
                  <div className={cn(css.body, 'table-body')}>
                    {filterData.map((agency) => (
                      <Item
                        key={agency.id}
                        agency={agency}
                        paramsAgencies={{ status }}
                      />
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgencyPage;
