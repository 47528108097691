import React, { useRef, useState } from 'react';
import useDropDown from 'hooks/useDropDown';
import cn from 'classnames';
import { FiChevronDown } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';

interface Props<T> {
  params: string[];
  disabled?: boolean;
  className?: string;
  setOnlyChecked: (params: T) => void;
  onlyChecked: T;
}

const Filter: React.FC<Props<boolean>> = ({
  params,
  disabled,
  className,
  onlyChecked,
  setOnlyChecked
}) => {
  const dropdownMenu = useRef<HTMLDivElement | null>(null);
  const { toggle, isOpened, toggleOff } = useDropDown(dropdownMenu);
  const [currentFilter, setCurrentFilter] = useState(params[0]);

  const onClickChecked = (title: string) => {
    setOnlyChecked(true);
    setCurrentFilter(title)
    toggleOff();
  };

  const onClickAll = (title: string) => {
    setOnlyChecked(false);
    setCurrentFilter(title)
    toggleOff();
  };

  const { t } = useTranslation();

  return (
    <div
      className={cn(css.filter, className, {
        isToggle: toggle,
        isDisabled: disabled,
        isOpened,
      })}
      ref={dropdownMenu}
    >
      <div className={css.active} onClick={isOpened ? toggleOff : toggle}>
        <span>
          {t('targetings.show')}
          {currentFilter}
        </span>
        <FiChevronDown size={22} />
      </div>
      <div className={cn(css.list, { isOpened })}>
        <div
          className={cn(css.item, { isActive: !onlyChecked})}
          onClick={() => onClickAll(params[0])}
        >
          {params[0]}
        </div>

        <div
          className={cn(css.item, { isActive: onlyChecked })}
          onClick={() => onClickChecked(params[1])}
        >
          {params[1]}
        </div>
      </div>
    </div>
  );
};

export default Filter;
