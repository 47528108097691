import { useFormik } from 'formik';
import { useEffect, useMemo } from 'react';
import { useDataPartners } from 'domains/partners/hooks';
import { useTranslation } from 'react-i18next';
import { ProjectFields, TSelectedFieldPartners } from 'domains/project/types';
import { useUsersGroupsInfo } from 'domains/project/model/selectors';
import { SelectField, TFormikExtract } from 'types/general';
import { useProjectInfo } from 'domains/project/hooks';
import { useDispatchApp } from 'redux/rootSelectors';
import { fetchGroups } from 'domains/project/model/actions';
import { objectOptimizationWithFormik } from 'utils/objectOptimizationWithFormik/objectOptimizationWithFormik';
import { editProjectRequest } from 'domains/project/reducer';

type TForm = {
  default_registration_partner_xxhash: string;
  registration_groups: string;
  registration_fields: Record<ProjectFields['name'], ProjectFields['value']>;
};

type TFUseProjectRegistrationEdit = (p: {
  setEdit: (v: boolean) => void;
}) => TFormikExtract<TForm> & {
  roles: ReturnType<typeof useUsersGroupsInfo>['data'];
  partners: TSelectedFieldPartners[];
  partnerValue: TSelectedFieldPartners[];
  project: ReturnType<typeof useProjectInfo>['data'];
  optionFieldsRegistration: SelectField[];
  rolesValue: SelectField[];
  handleResetForm: () => void;
};

export const useProjectRegistrationEdit: TFUseProjectRegistrationEdit = ({
  setEdit,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatchApp();

  useEffect(() => {
    dispatch(fetchGroups());
  }, []);

  const { data: project, LTU } = useProjectInfo();

  const registration_fields = useMemo<TForm['registration_fields']>(() => {
    const obj: TForm['registration_fields'] =
      {} as TForm['registration_fields'];
    if (project) {
      project.registration_fields.forEach(({ name, value }) => {
        obj[name] = value;
      });
    }
    return obj;
  }, [project, LTU]);

  const { partners: agency } = useDataPartners();

  const optionFieldsRegistration: SelectField[] = [
    {
      label: t(`project_page.forms.field.required`),
      value: 'required',
    },
    {
      label: t(`project_page.forms.field.off`),
      value: 'hidden',
    },
    {
      label: t(`project_page.forms.field.optional`),
      value: 'optional',
    },
  ];

  const partners = useMemo<
    ReturnType<TFUseProjectRegistrationEdit>['partners']
  >(() => {
    let data: TSelectedFieldPartners[] = [];
    if (agency) {
      data = agency
        .filter((v) => v.type === 'AGENCY')
        .map((val) => ({
          label: val.title,
          value: val.xxhash,
          id: val.internal_id,
          registration: val.registration,
          icon: val.icon,
        }));
    }
    return data;
  }, [agency]);

  const { data: roles } = useUsersGroupsInfo();

  const formik = useFormik<TForm>({
    initialValues: {
      default_registration_partner_xxhash:
        project?.default_registration_partner_xxhash || '',
      registration_groups:
        project?.registration_groups.map((v) => `${v}`).join(',') || '',
      registration_fields,
    },
    onSubmit: (values) => {
      if (project) {
        const param = objectOptimizationWithFormik<TForm>(
          {
            default_registration_partner_xxhash:
              project.default_registration_partner_xxhash,
            registration_groups: project.registration_groups.join(','),
            registration_fields,
          },
          values,
        );
        if (param) {
          dispatch(
            editProjectRequest({
              data: {
                domain: window.location.host,
                ...param,
                registration_groups: values.registration_groups
                  .split(',')
                  .map((v) => +v),
                registration_fields: Object.entries(
                  values.registration_fields,
                ).map(([key, value]) => ({
                  name: key as ProjectFields['name'],
                  value,
                  verification: false,
                })),
              },
              value: false,
              setter: setEdit,
              alertText: t(`form.common.signup`),
            }),
          );
        } else {
          setEdit(false);
        }
      }
    },
  });
  useEffect(() => {
    if (project) {
      formik.setValues({
        default_registration_partner_xxhash:
          project.default_registration_partner_xxhash || '',
        registration_groups:
          project.registration_groups.map((v) => `${v}`).join(',') || '',
        registration_fields,
      });
    }
  }, [LTU]);

  const partnerValue = useMemo<
    ReturnType<TFUseProjectRegistrationEdit>['partnerValue']
  >(() => {
    if (!formik.values.default_registration_partner_xxhash || !partners.length)
      return [];
    const data = partners.filter(
      (v) => v.value === formik.values.default_registration_partner_xxhash,
    );
    if (data.length) {
      return data;
    }
    return [];
  }, [formik.values, partners, LTU]);

  const rolesValue = useMemo<SelectField[]>(() => {
    let data: SelectField[] = [];
    if (!roles || !formik.values.registration_groups) {
      return data;
    }
    data = roles.filter((e) =>
      formik.values.registration_groups.split(',').includes(e.value),
    );
    return data;
  }, [formik.values, LTU, roles, partners]);

  const handleResetForm = () => {
    if (project) {
      formik.setValues({
        default_registration_partner_xxhash:
          project.default_registration_partner_xxhash || '',
        registration_groups:
          project.registration_groups.map((v) => `${v}`).join(',') || '',
        registration_fields,
      });
    }
    setEdit(false);
  };

  return {
    handleResetForm,
    partners,
    partnerValue,
    rolesValue,
    roles,
    optionFieldsRegistration,
    project,
    formikContext: formik,
    ...formik,
  };
};
