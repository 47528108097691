import { useCallback, useEffect, useMemo, useState } from 'react';
import { featchAllCreativeTypesAction } from 'domains/creative/reduser';
import {
  useCreativeDetailInfo,
  useCreativePretargetingInfo,
} from '../../../model/selectors';
import { useDispatchApp } from '../../../../../redux/rootSelectors';
import {
  fetchCreativePretargeting,
  removeCreativePretargeting,
  setCreativePretargetingAction,
} from '../../../model/actions';
import { RPreTargeting } from '../../../../campaign/types';
import { fetchCampaignPreTargeting } from '../../../../campaign/model/actions';
import { useCampaignPreTargeting } from '../../../../campaign/model/selectors';

type TFUseCreativeSettings = () => {
  pretargeting: RPreTargeting | null;
  isExtend: boolean;
  handlerSetCreativePretargeting: (data: RPreTargeting) => void;
  handlerRemoveCreativePretargeting: () => void;
};

export const useCreativeSettings: TFUseCreativeSettings = () => {
  const dispatch = useDispatchApp();
  const { data: pretargetingCreative, LTU: LTUCreativePretargeting } =
    useCreativePretargetingInfo();
  const { data: pretargetingCampaign } = useCampaignPreTargeting();
  const { data: creativeDetail, LTU: LTUCreativeDetail } =
    useCreativeDetailInfo();

  const [isExtend, setIsExtend] = useState(false);

  useEffect(() => {
    if (creativeDetail) {
      dispatch(fetchCreativePretargeting({ xxhash: creativeDetail.xxhash }));
    }
  }, [LTUCreativeDetail]);

  useEffect(() => {
    dispatch(featchAllCreativeTypesAction())
  }, [])

  const pretargeting = useMemo<
    ReturnType<TFUseCreativeSettings>['pretargeting']
  >(() => {
    if (pretargetingCreative && !Array.isArray(pretargetingCreative)) {
      if (isExtend) {
        setIsExtend(false);
      }
      return pretargetingCreative;
    }
    if (pretargetingCampaign) {
      return pretargetingCampaign;
    }
    return null;
  }, [pretargetingCreative, pretargetingCampaign]);

  useEffect(() => {
    if (
      pretargetingCreative &&
      Array.isArray(pretargetingCreative) &&
      creativeDetail
    ) {
      setIsExtend(true);
      dispatch(
        fetchCampaignPreTargeting({ xxhash: creativeDetail.campaign_xxhash }),
      );
    }
  }, [LTUCreativePretargeting]);

  const handlerSetCreativePretargeting: ReturnType<TFUseCreativeSettings>['handlerSetCreativePretargeting'] =
    useCallback(
      (data) => {
        if (creativeDetail) {
          dispatch(
            setCreativePretargetingAction({
              xxhash: creativeDetail.xxhash,
              data,
            }),
          );
        }
      },
      [creativeDetail],
    );

  const handlerRemoveCreativePretargeting = useCallback(() => {
    if (creativeDetail) {
      dispatch(removeCreativePretargeting({ xxhash: creativeDetail.xxhash }));
    }
  }, [creativeDetail]);

  return {
    handlerRemoveCreativePretargeting,
    handlerSetCreativePretargeting,
    pretargeting,
    isExtend,
  };
};
