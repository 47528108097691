import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { FiDownload, FiEye } from 'react-icons/fi';
import ElementsNone from 'components/ElementsNone';
import css from './styles.module.scss';
import HeaderCampaign from '../HeaderCampaign';
import { useCampaignReports } from '../hooks/useCampaignReports';
import Loader from '../../../components/UI/Loader';
import ErrorReport from '../../../components/Errors/Reports';
import Button from '../../../components/UI/Buttons/buttons';
import { TReportItem } from '../types';
import emptyReports from '../../../assets/errors/reportsOff.svg';

type PReportItem = TReportItem;

const Reports: FC = () => {
  const { t } = useTranslation();
  const {
    campaign,
    isLoading,
    error,
    handlerDownloadReportByID,
    handlerReportByID,
  } = useCampaignReports();

  /** Item отчета в списке */
  const ReportItem: FC<PReportItem> = ({ type }) => (
    <div className={css.report} key={type}>
      <div className={css.report__title}>
        <span>{t(`common.reports.${type}`)}</span>
      </div>
      <div className={css.reports__btns}>
        {type !== 'extended' && (
          <Button
            transparent
            title={t('campaigns_page.campaign_settings.reports.see')}
            icon={<FiEye size={24} />}
            buttonAttributes={{
              onClick: () => handlerReportByID(type),
              style: {
                height: 36,
              },
            }}
          />
        )}
        <Button
          transparent
          title={t('campaigns_page.campaign_settings.reports.donwload')}
          icon={<FiDownload size={24} />}
          buttonAttributes={{
            style: {
              height: 36,
            },
            onClick: () => handlerDownloadReportByID(type),
          }}
        />
      </div>
    </div>
  );

  if (isLoading) {
    return <Loader />;
  }
  if (error.isError) {
    return <ErrorReport error={error} />;
  }

  return (
    <div className={css.reports}>
      <Helmet>
        <title>
          {t('campaigns_page.campaign_settings.reports.reports_tit')}
        </title>
      </Helmet>
      <HeaderCampaign
        campaign={campaign}
        isLoading={isLoading}
        isShowDataFilter
      />
      <div className={css.reports__table}>
        <div className="container">
          {campaign &&
          campaign.reports.items.some((e) => e.is_enabled === true) ? (
            campaign.reports.items
              .filter(({ is_enabled }) => is_enabled)
              .map(({ type, is_enabled }) => (
                <ReportItem type={type} is_enabled={is_enabled} key={type} />
              ))
          ) : (
            <ElementsNone
              customImg={emptyReports}
              customTitle={t(`common.reports.no_reports`)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Reports;
