import React from 'react';
import { FetchedDataObj } from '../../../redux/fetchedData';

type ErrorText = {
  title: string;
  desc?: string;
};

interface ErrorProps {
  error: FetchedDataObj<null>['error'];
  text: {
    err90: ErrorText;
    err154: ErrorText;
    errAll: ErrorText;
  };
}

export const ErrorCodeReports: React.FC<ErrorProps> = ({ error, text }) => {
  if (error.code === 154) {
    return (
      <>
        <h2>{text.err154.title}!</h2>
        <p>{text.err154.desc}</p>
      </>
    );
  }

  if (error.code === 90) {
    return <h2>{text.err90.title}</h2>;
  }

  return <h2>{text.errAll.title}</h2>;
};
