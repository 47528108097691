import { useEffect, useState } from "react";

interface IUseTooltipPoratal {
    positionLeft: number;
    correctLeft: number;
    positionTop: number;
    correctTop: boolean;
}

interface IUseTooltipPoratalProps {
    hintRef: React.MutableRefObject<HTMLDivElement | null>;
    mainRef: React.MutableRefObject<HTMLDivElement | null>;
    _isOpen: boolean;
    showFirst?: boolean;
}

export const useTooltipPoratal = ({ hintRef, mainRef, _isOpen, showFirst }: IUseTooltipPoratalProps): IUseTooltipPoratal => {
    const [positionLeft, setPositionLeft] = useState<number>(0);
    const [positionTop, setPositionTop] = useState<number>(0);
    const [correctLeft, setCorrectLeft] = useState<number>(0);
    const [correctTop, setCorrentTop] = useState<boolean>(false);

    const calcPosition = () => {
        if (mainRef && mainRef.current && hintRef && hintRef.current) {
            // обрабатываем кейс, когда у нас вылезает за правую границу подсказка
            if ((mainRef.current?.getBoundingClientRect().left + (mainRef.current?.clientWidth / 2) + (hintRef.current?.clientWidth / 2)) >= document.body.clientWidth) {
                setCorrectLeft(document.body.clientWidth - mainRef.current?.getBoundingClientRect().right)
                setPositionLeft(document.body.clientWidth - hintRef.current?.clientWidth)
            } else {
                setPositionLeft(mainRef.current?.getBoundingClientRect().left + (mainRef.current?.clientWidth / 2) - hintRef.current?.clientWidth)
            }
            // обрабатываем кейс, когда у нас подсказка выходит верхним краем за экран
            if (mainRef.current?.getBoundingClientRect().top - hintRef.current?.clientHeight - 12 < 0) {
                setCorrentTop(true)
                setPositionTop(mainRef.current?.getBoundingClientRect().bottom + 12 + 12)
            } else {
                setCorrentTop(false)
                setPositionTop(mainRef.current?.getBoundingClientRect().top - hintRef.current?.clientHeight)
            }
        }
    }

    useEffect(() => {
        calcPosition();
    }, [_isOpen, showFirst])

    return {
        positionLeft,
        correctLeft,
        positionTop,
        correctTop,
    }
}