import React, { FC } from 'react';
import Load from 'components/UI/Load';
import Card from 'components/UI/Card';
import css from '../styles.module.scss';

const LoaderCounteragents: FC = () => (
  <Card isUseAccess className={css.card} noHover>
    <Load
      style={{
        width: '140px',
        height: '22px',
      }}
      classname={css.title}
    />
    <Load
      style={{
        height: '42px',
        width: '100%',
      }}
    />
    <Load
      style={{
        height: '42px',
        width: '100%',
      }}
    />
    <Load
      style={{
        height: '42px',
        width: '100%',
      }}
    />
  </Card>
);

export default LoaderCounteragents;
