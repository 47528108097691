import { useCreativeTypes } from 'domains/creative/model/selectors';
import { useFormik, FormikProps } from 'formik';
import {
  CreateListOptionsParams,
  LinkOption,
  MassEditLinkParams,
} from 'domains/creative/types';
import { useMemo } from 'react';
import { IMassEditModalProps } from 'domains/creative/ui/MassEditModal';
import { TExperimentalInfoField } from 'domains/campaign/types';
import { useValidateSchemeCreatives } from '../../validate/validate';
import { validate } from './validate';
import i18n from '../../../../../i18n';
import { checkVariableFields, getVariableFieldVal } from '.';

type UseMassEditFormikDataParams = IMassEditModalProps;

type UseMassEditFormikDataReturn = {
  formik: FormikProps<Record<string, string | null>>;
  eridData: TExperimentalInfoField | null;
  linksOptions: LinkOption[];
  eridAvalibleCreoTypes: string[];
};

export const useMassEditFormikData = ({
  checkedTypes,
  creativeCheckedList,
  onClose,
  setIsSaveMassEditData,
}: UseMassEditFormikDataParams): UseMassEditFormikDataReturn => {
  const { data: creativeConfig } = useCreativeTypes();

  const { additional } = useValidateSchemeCreatives();

  const {
    resultVal: clickAuditPixelData,
    avalibleForCreoTypes: clickAuditAvalibleCreoTypes,
  } = getVariableFieldVal({
    valName: 'click_audit_pixel',
    types: checkedTypes,
    config: creativeConfig,
  });

  const {
    resultVal: jsAuditData,
    avalibleForCreoTypes: jsAuditAvalibleCreoTypes,
  } = getVariableFieldVal({
    valName: 'js_code_audit',
    types: checkedTypes,
    config: creativeConfig,
  });

  const { resultVal: eridData, avalibleForCreoTypes: eridAvalibleCreoTypes } =
    getVariableFieldVal({
      valName: 'erid',
      types: checkedTypes,
      config: creativeConfig,
    }); 

  const createListOptions = ({
    checkedTypesArr,
  }: CreateListOptionsParams): LinkOption[] => {
    const defaultOptions = [
      {
        label: i18n.t(`creative.widget_text.link`),
        value: 'link',
        targetTypes: checkedTypesArr,
      },
      {
        label: i18n.t(`creative.create_creative.Extension_link`),
        value: 'extension_link',
        targetTypes: ['HTML', 'VIDEO_VAST', 'VIDEO_INLINE', 'COMPANION'],
      },
      {
        label: i18n.t(`creative.widget_text.adomain`),
        value: 'adomain',
        targetTypes: [
          'HTML',
          'VIDEO_VAST',
          'VIDEO_INLINE',
          'COMPANION',
          'TEASER',
          'CLICKUNDER',
        ],
      },
      {
        label: i18n.t(`creative.create_creative.Pixel_audit`),
        value: 'pixel',
        targetTypes: ['HTML', 'TEASER'],
      },
      {
        label: i18n.t(`creative.create_creative.Optional_pixel`),
        value: 'pixel_additional',
        targetTypes: ['HTML', 'TEASER'],
      },
      {
        label: i18n.t(`creative.video.fields.video_show`),
        value: 'show',
        targetTypes: ['COMPANION', 'VIDEO_VAST', 'VIDEO_INLINE'],
      },
      {
        label: i18n.t(`creative.video.fields.video_show2`),
        value: 'show2',
        targetTypes: ['COMPANION', 'VIDEO_VAST', 'VIDEO_INLINE'],
      },
    ];
    if (clickAuditPixelData) {
      defaultOptions.push({
        label: i18n.t(`creative.${clickAuditPixelData.field}`),
        value: clickAuditPixelData.field,
        targetTypes: clickAuditAvalibleCreoTypes,
      });
    }
    if (jsAuditData) {
      defaultOptions.push({
        label: i18n.t(`creative.${jsAuditData.field}`),
        value: jsAuditData.field,
        targetTypes: jsAuditAvalibleCreoTypes,
      });
    }
    return defaultOptions;
  };

  const linksOptions = useMemo(
    () =>
      createListOptions({
        checkedTypesArr: checkedTypes,
      }),
    [i18n.language],
  );

  const formik = useFormik<Record<string, string | null>>({
    enableReinitialize: true,
    initialValues: {},
    validationSchema: additional,
    validate,
    onSubmit: (values) => {
      const params: MassEditLinkParams = {
        creatives_xxhashes: creativeCheckedList,
        massEditLinkCaster: {},
      };
      const { click_audit_pixel, erid, js_code_audit, ...restValues } = values;

      if (
        erid ||
        click_audit_pixel ||
        js_code_audit ||
        click_audit_pixel === null ||
        erid === null ||
        js_code_audit === null
      ) {
        params.massEditLinkCaster.config_values = {};
      }

      checkVariableFields(click_audit_pixel, clickAuditPixelData, params);
      checkVariableFields(erid, eridData, params);
      checkVariableFields(js_code_audit, jsAuditData, params);

      Object.keys(restValues).forEach((key) => {
        const currentVal = restValues[key];
        if (currentVal === '') return;
        if (currentVal === null) {
          params.massEditLinkCaster[key] = '';
          return;
        }
        params.massEditLinkCaster[key] = currentVal;
      });
      setIsSaveMassEditData(params);
      onClose();
    },
  });

  return {
    formik,
    eridData,
    eridAvalibleCreoTypes,
    linksOptions,
  };
};
