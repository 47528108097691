import { format } from 'date-fns';
import {
  useCampaignsInfo,
  useCampaignsPeriodInfo,
} from 'domains/campaigns/model/selectors';
import {
  clearCampaignsPeriod,
  fetchCampaigns,
  fetchChartStatistic,
  setClearCampaignsList,
  clearChartStatistic,
} from 'domains/campaigns/reducer';
import { RCampaignsArrayStata } from 'domains/campaigns/types';
import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useDispatchApp } from 'redux/rootSelectors';
import { Period } from 'types/general';
import { KeysSettings, useStateValue } from 'utils/context';

interface IRUseUpdateChartData {
  partner_xxhash: string;
  isLoadingCampaigns: boolean;
  settings: {
    period: Period;
    type?:
      | ''
      | 'period'
      | '30days'
      | 'week'
      | 'yesterday'
      | 'month'
      | 'day'
      | 'all_time';
  };
  onUpdateDate: (period: Period, type: string) => void;
  onUpdateStatistic: (period: Period) => void;
  campaignsList: RCampaignsArrayStata | null;
}

export const useUpdateChartData = (): IRUseUpdateChartData => {
  const { state, dispatch: dispatchContext } = useStateValue();
  const settings = state.settings[KeysSettings.CLIENT_REPORT];
  const history = useHistory();

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const { id: partner_xxhash }: { id: string } = useParams();

  const { data: campaignsList, isLoading: isLoadingCampaigns } =
    useCampaignsInfo();

  const dispatch = useDispatchApp();

  useEffect(() => {
    dispatch(
      fetchCampaigns({
        partner_xxhash,
      }),
    );
  }, [partner_xxhash]);

  const onUpdateStatistic = (period: Period) => {
    if (campaignsList && !isLoadingCampaigns) {
      const xxhash_list = campaignsList.map((item) => item.xxhash);
      dispatch(
        fetchChartStatistic({
          xxhash_list,
          period,
        }),
      );
    }
  };

  const pushDataRange = (period: Period, id: string, type: string) => {
    history.push({
      pathname: `/main/client/${id}/reports`,
      search: `?date_from=${period.from}&date_to=${period.to}&type=${
        type === 'all_time' ? type : 'week'
      }`,
    });
  };

  const onUpdateDate = (period: Period, type: string) => {
    if (campaignsList && !isLoadingCampaigns) {
      onUpdateStatistic(period);
      pushDataRange(period, partner_xxhash, type);
      dispatchContext({
        type: 'setSettings',
        payload: {
          item: {
            type,
            period,
          },
          key: KeysSettings.CLIENT_REPORT,
        },
      });
    }
  };

  const campaignsPeriod = useCampaignsPeriodInfo();

  useEffect(() => {
    const campaignPeriodTo = campaignsPeriod.to;
    const campaignPeriodFrom = campaignsPeriod.from;
    if (
      campaignsList &&
      !isLoadingCampaigns &&
      campaignPeriodFrom &&
      campaignPeriodTo
    ) {
      const xxhash_list = campaignsList.map((item) => item.xxhash);
      const paramsPeriod = {
        to: params.get('date_to'),
        from: params.get('date_from'),
      };
      if (settings.type === 'all_time') {
        const transformedCampaignPeriod = {
          from: format(new Date(campaignPeriodFrom), 'yyyy-MM-dd'),
          to: format(new Date(campaignPeriodTo), 'yyyy-MM-dd'),
        };
        pushDataRange(transformedCampaignPeriod, partner_xxhash, settings.type);
        dispatch(
          fetchChartStatistic({
            xxhash_list,
            period: transformedCampaignPeriod,
          }),
        );
        return;
      }
      pushDataRange(
        paramsPeriod.from ? paramsPeriod : settings.period,
        partner_xxhash,
        settings.type,
      );
      dispatch(
        fetchChartStatistic({
          xxhash_list,
          period: paramsPeriod.from ? paramsPeriod : settings.period,
        }),
      );
    }
  }, [campaignsList, campaignsPeriod]);

  useEffect(() => {
    const campaignPeriodTo = campaignsPeriod.to;
    const campaignPeriodFrom = campaignsPeriod.from;
    if (
      settings.type === 'all_time' &&
      campaignPeriodFrom &&
      campaignPeriodTo
    ) {
      const transformedCampaignPeriod = {
        from: format(new Date(campaignPeriodFrom), 'yyyy-MM-dd'),
        to: format(new Date(campaignPeriodTo), 'yyyy-MM-dd'),
      };
      dispatchContext({
        type: 'setSettings',
        payload: {
          item: {
            type: settings.type,
            period: transformedCampaignPeriod,
          },
          key: KeysSettings.CLIENT_REPORT,
        },
      });
    }
  }, [campaignsPeriod]);

  useEffect(() => {
    const paramsPeriod = {
      from: params.get('date_from'),
      to: params.get('date_to'),
    };
    const paramsType = params.get('type');
    if (paramsPeriod.from && paramsType === 'period') {
      dispatchContext({
        type: 'setSettings',
        payload: {
          item: {
            type: params.get('type'),
            period: paramsPeriod,
          },
          key: KeysSettings.CLIENT_REPORT,
        },
      });
    }
  }, []);

  useEffect(
    () => () => {
      dispatch(setClearCampaignsList());
      dispatch(clearCampaignsPeriod());
      dispatch(clearChartStatistic());
    },
    [],
  );

  return {
    onUpdateDate,
    onUpdateStatistic,
    settings,
    isLoadingCampaigns,
    partner_xxhash,
    campaignsList,
  };
};
