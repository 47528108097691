import React, { FC, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { components, IndicatorProps } from 'react-select';
import { FieldProps } from 'formik';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { FiChevronDown } from 'react-icons/fi';
import { OptionTypeBase } from 'react-select/src/types';
import css from './styles.module.scss';
import {
  IsMulti,
  MultiSelectType,
  ParamTypeMyltiSelect,
  useDataMultiSelect,
} from './hooks';
import { RClients } from '../../../domains/clients/types';
import Input from '../Input/input';

interface Props extends RouteComponentProps {
  /** Получение функции для вывода в formik */
  form: FieldProps['form'];
  /** Получение name от поля в formik */
  field: FieldProps['field'];
  /** label для инпута */
  label: string;
  /** Тип Клинеты или Агенства */
  type: ParamTypeMyltiSelect['type'];
  /** ID Агенства или клиента при редактированиии */
  parent_id?: string;
  /** Блокировка нажатия */
  isDisabled?: boolean;
  /** включить архивные элемент */
  isArchive?: boolean;
  /** Вывод только содержания инпута */
  cleanView?: boolean;
  /** Горизонтальный вид */
  theme?: string;
}

const DropdownIndicator = (props: IndicatorProps<OptionTypeBase, IsMulti>) => (
  <components.DropdownIndicator {...props}>
    <FiChevronDown
      size={24}
      style={{
        // eslint-disable-next-line react/destructuring-assignment
        transform: props.isFocused ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: 'transform .1s',
      }}
    />
  </components.DropdownIndicator>
);

const MultiSelect: FC<Props> = ({
  field: { name },
  form: { setFieldValue },
  label,
  type,
  parent_id,
  isDisabled,
  isArchive = false,
  cleanView,
  theme,
}) => {
  const { t } = useTranslation();
  const {
    data,
    isLoading,
    defaultValue,
    autoDisable,
    parent_url_xxhash,
    selectStyles,
  } = useDataMultiSelect({ type, parent_id, isArchive, theme });

  const [inputValue, setInputValue] = useState<MultiSelectType>(() => ({
    ...defaultValue,
  }));

  useEffect(() => {
    setFieldValue(name, inputValue.id);
  }, [inputValue]);

  useEffect(() => {
    if (data.length > 0) {
      setInputValue(() => ({ ...defaultValue }));
    }
    if (type === 'CLIENTS' && !data.length) {
      setFieldValue('partner_xxhash', parent_url_xxhash);
    }
  }, [isLoading, parent_id]);

  const filterClients = (filter: string) =>
    data.filter((i) => i.title.toLowerCase().includes(filter.toLowerCase()));

  const promiseOptions = (search: string) =>
    new Promise((resolve) => {
      resolve(filterClients(search));
    }) as any;

  if (data.length > 0 && !isLoading) {
    return (
      <div
        className={cn(css['form-group'], 'async-select', {
          horizontal: theme === 'horizontal',
        })}
      >
        <div className={css.title}>{label}</div>
        {cleanView && isDisabled ? (
          <div className={css.titleText}>{inputValue.title}</div>
        ) : (
          <AsyncSelect
            isDisabled={isDisabled || autoDisable}
            components={{ DropdownIndicator, IndicatorSeparator: null }}
            placeholder={t('targetings.search')}
            noOptionsMessage={() => t(`no_result`)}
            styles={selectStyles}
            defaultOptions={data}
            loadOptions={promiseOptions}
            tabSelectsValue={false}
            value={inputValue}
            onChange={(selectOption) => {
              const { title, id } = selectOption as RClients;
              setInputValue((prevState) => ({
                ...prevState,
                label: title,
                id,
              }));
            }}
          />
        )}
      </div>
    );
  }
  if (data.length === 0 && !isLoading) {
    return <Input disabled label={label} error errortext={t('errors.err90')} />;
  }
  return <></>;
};

export default withRouter(MultiSelect);
