import { TExperimentalInfoField } from 'domains/campaign/types';

export const isFieldInConfig = (
  config: TExperimentalInfoField[] | [] | null,
  fieldName: string,
): TExperimentalInfoField | null => {
  const filtredConfig = config?.filter((item) => item.field === fieldName);
  if (filtredConfig?.length) {
    return filtredConfig[0];
  }
  return null;
};
