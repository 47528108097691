import { SagaIterator } from 'redux-saga';
import { select, call, take, put } from 'redux-saga/effects';
import { toastError, toastSuccess } from 'utils/ToastUtils/toastUtils';
import updateToken from 'utils/updateToken';
import { selectorUser } from 'domains/user/model/selectors';
import { userForGot } from 'domains/user/api';
import {
  setActivityUsers,
  setTempManagementUsers,
  setTempManagementUsersSuccess,
  setUsersActivitySort,
  setUsersOnline,
} from '../reducer';
import {
  makeReqWithRD,
  TMakeReqWithRD,
  updateFillFetchedData,
} from '../../../../redux/makeReqWithRD';
import {
  getActivesUsers,
  getUserList,
  getUserListOnline,
  userCreate,
  userEdit,
  sendConfirmation,
} from '../api';
import {
  createNewUser,
  fetchActivityUsers,
  fetchManagementUsers,
  editUser,
  resetPasswordRequest,
  sendConfirmationEmail,
} from '../actions';
import {
  GetManagementUsersList,
  RGetManagementUsersSList,
  TActivityUsers,
} from '../types';
import { selectorManagementUsers, selectorsActivityUsers } from '../selectors';
import { extractGenFetchData } from '../../../../redux/fetchedData';
import { arraySortUtils } from '../../../../utils/arraySortUtils';
import {
  selectorActivityUsersDate,
  selectorUserListTabs,
} from '../../../storage/model/selectors';
import { StorageState } from '../../../storage/model/types';
import i18n from '../../../../i18n';

export function* workerFetchManagementUsers({
  payload,
}: ReturnType<typeof fetchManagementUsers>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getUserList>>(makeReqWithRD, {
      fetcher: getUserList,
      fill: (v) => {
        const data = v.get('data');
        let newData: RGetManagementUsersSList = [];
        if (data) {
          newData = data.map((item, i) => ({
            ...item,
            id: i,
            statistics: null,
          }));
        }

        return setTempManagementUsers(
          updateFillFetchedData({ fillFetchedData: v, newData }),
        );
      },
      fillSuccess: (v) => {
        const data = v.get('data');
        let newData: null | RGetManagementUsersSList = null;
        if (data) {
          newData = data.map((item, i) => ({
            ...item,
            id: i,
            statistics: null,
          }));
        }

        return setTempManagementUsersSuccess(
          updateFillFetchedData({
            fillFetchedData: v,
            newData: newData as RGetManagementUsersSList,
          }),
        );
      },
      parameters: payload,
    });
    const { data: users } = extractGenFetchData<GetManagementUsersList[]>(
      yield select(selectorManagementUsers),
    );
    const date: StorageState['activityUsersDate'] = yield select(
      selectorActivityUsersDate,
    );
    const tabs: StorageState['usersTabs'] = yield select(selectorUserListTabs);
    if (tabs === 'statistics' && users && !!users.length) {
      yield put(
        fetchActivityUsers({
          users_email: [...users.map(({ email }) => email)],
          period: date.period,
        }),
      );
    }
  } catch (e) {
    console.log({ e });
  }
}

export function* workerFetchUserOnline(): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getUserListOnline>>(makeReqWithRD, {
      fetcher: getUserListOnline,
      fill: setUsersOnline,
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* workerFetchActivityUsers({
  payload,
}: ReturnType<typeof fetchActivityUsers>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getActivesUsers>>(makeReqWithRD, {
      fetcher: getActivesUsers,
      fill: (value) => {
        const newData: TActivityUsers = [];
        const oldData = value.get('data');
        if (oldData) {
          Object.entries(oldData).forEach(([, user]) => {
            newData.push(user);
          });
        }
        return setActivityUsers(
          updateFillFetchedData({ fillFetchedData: value, newData }),
        );
      },
      parameters: payload,
    });
    let tempUserList = yield select(selectorManagementUsers);
    const userList = tempUserList.get('data') as RGetManagementUsersSList;
    if (tempUserList.get('isLoading')) {
      yield take(setTempManagementUsersSuccess);
    }
    const { data: statistics } = extractGenFetchData<TActivityUsers>(
      yield select(selectorsActivityUsers),
    );
    if (userList && statistics) {
      const keys = statistics.map(({ user_email }) => user_email);
      const newData = userList.map((user) => {
        if (keys.includes(user.email)) {
          const element = statistics.find(
            ({ user_email }) => user.email === user_email,
          );
          return {
            ...user,
            statistics: {
              active_time: element?.active_time ?? 0,
              count_edit: element?.count_edit ?? 0,
              count_get: element?.count_get ?? 0,
              count_pages: element?.count_pages ?? 0,
            },
          };
        }
        return {
          ...user,
          statistics: {
            active_time: 0,
            count_edit: 0,
            count_get: 0,
            count_pages: 0,
          },
        };
      });
      tempUserList = tempUserList.set('data', newData);
      yield put(setTempManagementUsersSuccess(tempUserList));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* sortActiveUsersWorker({
  payload,
}: ReturnType<typeof setUsersActivitySort>): SagaIterator<void> {
  try {
    const { sort, key } = payload;
    let tempUserList = yield select(selectorManagementUsers);
    const { data } =
      extractGenFetchData<GetManagementUsersList[]>(tempUserList);

    if (data) {
      const arraySort = arraySortUtils<
        Pick<GetManagementUsersList, 'id' | 'email' | 'statistics'>[]
      >({
        sort,
        key,
        dataArray: data,
      });
      tempUserList = tempUserList.set('data', arraySort).set('LTU', Date.now());
      yield put(setTempManagementUsersSuccess(tempUserList));
    }
  } catch (e) {
    console.error(e);
  }
}

export function* workerUserCreateSaga({
  payload,
}: ReturnType<typeof createNewUser>): SagaIterator<void> {
  try {
    const { callbacks, ...rest } = payload;
    const { email } = payload;
    if (callbacks?.setIsLoading) {
      callbacks?.setIsLoading(true);
    }
    yield call(updateToken);
    let result: boolean | null = null;
    result = yield call(userCreate, rest);

    if (result) {
      const user = yield select(selectorUser);
      const userInfo = user.get('data');

      toastSuccess({
        before: `${i18n.t(`just_words.w7`)} `,
        title: email,
        after: ` ${i18n.t(`just_words.one_is_successful_create`)}`,
      });

      if (callbacks?.onClose) {
        callbacks?.onClose();
      }
      yield put(fetchManagementUsers({ partner_id: userInfo.partner.xxhash }));
    }
  } catch (e) {
    console.error({ e });
    const { callbacks } = payload;
    if (callbacks?.setIsLoading) {
      callbacks?.setIsLoading(false);
    }
  }
}

export function* workerUserEditSaga({
  payload,
}: ReturnType<typeof editUser>): SagaIterator<void> {
  try {
    const { callbacks, ...rest } = payload;
    const { email } = payload;
    if (callbacks?.setIsLoading) {
      callbacks?.setIsLoading(true);
    }
    yield call(updateToken);
    let result: boolean | null = null;
    result = yield call(userEdit, rest);

    if (result) {
      if (!Object.keys(rest).includes('is_active')) {
        const user = yield select(selectorUser);
        const userInfo = user.get('data');
        toastSuccess({
          before: 'Пользователь ',
          title: email,
          after: ' успешно изменен',
        });
        if (callbacks?.onClose) {
          callbacks?.onClose();
        }
        yield put(
          fetchManagementUsers({ partner_id: userInfo.partner.xxhash }),
        );
        return;
      }
      if (callbacks?.onToggle) {
        callbacks?.onToggle((prev) => !prev);
      }
    }
  } catch (e) {
    console.error({ e });
    const { callbacks } = payload;
    if (callbacks?.setIsLoading) {
      callbacks?.setIsLoading(false);
    }
  }
}

export function* resetPasswordWorker({
  payload,
}: ReturnType<typeof resetPasswordRequest>): SagaIterator<void> {
  try {
    const result = yield call(userForGot, payload);
    if (result) {
      toastSuccess({
        before: 'Письмо с инструкцией успешно отправлено на почту ',
        title: payload.email,
        after: '',
      });
    }
  } catch (err) {
    if (err?.code === 90) {
      toastError(`Ошибка сброса пароля`);
    }
    console.error({ err });
  }
}

export function* sendConfirmationEmailWorker({
  payload,
}: ReturnType<typeof sendConfirmationEmail>): SagaIterator<void> {
  try {
    const { callbacks, ...rest } = payload;
    const result = yield call(sendConfirmation, rest);
    if (result) {
      toastSuccess({
        before: 'Письмо с инструкцией успешно отправлено на почту ',
        title: payload.email,
        after: '',
      });
      callbacks.onConfirm(true);
    }
  } catch (err) {
    console.error({ err });
  }
}
