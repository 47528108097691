import { call, put, select } from 'redux-saga/effects';
import moment from 'moment';
import { SagaIterator } from 'redux-saga';
import { getContainerStatistics } from '../services';
import {
  fetchContainerDetailByID,
  onDownloadContainerRequest,
  setTempContainerById,
} from '../reducer';
import updateToken from '../../../utils/updateToken';
import { genFetchedData } from '../../../redux/fetchedData';
import { ContainerByID } from '../../../types/containers';
import { selectorContainerDetail } from '../model/selectors';

export function* fetchContainerByIDSaga({
  payload,
}: ReturnType<typeof fetchContainerDetailByID>): SagaIterator<void> {
  let fetchedData = genFetchedData<ContainerByID>(null).set('isLoading', true);
  yield put(setTempContainerById(fetchedData));
  try {
    yield call(updateToken);
    const state = yield select(selectorContainerDetail);
    const { result } = yield call(getContainerStatistics, {
      id: payload.id,
      period: {
        from: payload.from,
        to: payload.to,
      },
      mode: 'return',
      source: 'adsniper',
    });

    const range = state;

    if (range) {
      result.range = {
        from: payload.from,
        to: payload.to,
      };
    } else {
      result.range = {
        from: moment(moment().subtract(6, 'days')).format('YYYY-MM-DD'),
        to: moment(new Date()).format('YYYY-MM-DD'),
      };
    }
    fetchedData = fetchedData.set('data', result);
    yield put(setTempContainerById(fetchedData));
  } catch (e) {
    const code = e?.code ?? 0;
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: e.message ?? '',
      code,
    });
    yield put(setTempContainerById(fetchedData));
  } finally {
    fetchedData = fetchedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setTempContainerById(fetchedData));
  }
}

export function* onDownloadContainerByIDSaga({
  payload,
}: ReturnType<typeof onDownloadContainerRequest>): SagaIterator<void> {
  try {
    yield call(updateToken);
    const container = yield select(selectorContainerDetail);

    const data = container.get('data');
    const { result } = yield call(getContainerStatistics, {
      id: payload.id,
      period: {
        from: data.range.from,
        to: data.range.to,
      },
      mode: 'tsv',
      source: 'adsniper',
    });

    const link = document.createElement('a');
    link.download = 'file';
    link.href = result.report_url;
    link.click();
  } catch (e) {
    let fetchedData = genFetchedData<ContainerByID>(null);
    const code = e?.code ?? 0;
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: e.message ?? '',
      code,
    });
    yield put(setTempContainerById(fetchedData));
  }
}
