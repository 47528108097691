import React, { FC, useRef, useState } from 'react';
import cn from 'classnames';
import { FiSettings } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import Portal from 'components/Modals/MainPortal';
import CopyTargetingsModal from 'components/CopyTargetingsModal';
import { useDispatch } from 'react-redux';
import css from './styles.module.scss';
import { AppDispatch } from '../../../../../../redux/rootReducer';
import { cleanCreativeTargeting } from '../../../../reduser';
import useDropDown from '../../../../../../hooks/useDropDown';
import { CreativeDetail } from '../../../../types';
import OnlyOwner from '../../../../../../components/OnlyOwner';
import ModalWithQuestion from '../../../../../modals/modalWithQuestion';

interface Props {
  disabled?: boolean | undefined;
  className?: string;
  style?: Record<string, unknown>;
  creative: CreativeDetail;
}

const Options: FC<Props> = ({
  disabled,
  className,
  style,
  creative: { xxhash, type, title },
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const dropdownMenu = useRef<HTMLDivElement | null>(null);
  const { toggle, isOpened, toggleOff } = useDropDown(dropdownMenu);
  const [openCopyTargetingsModal, setOpenCopyTargetingsModal] =
    useState<boolean>(false);

  const [isModalClear, setIsModalClear] = useState(false);

  const copyTargets = () => {
    setOpenCopyTargetingsModal(true);
  };

  const clearTargets = () => {
    dispatch(
      cleanCreativeTargeting({
        isUpdateList: false,
        xxhash,
        visual: {
          value: '',
          setter: () => {
            toggleOff();
            setIsModalClear(false);
          },
          alertText: {
            before: `${t('common.targetings.clean_all_targeting_creative')} `,
            title: ` "${title}" `,
            after: ` ${t('common.targetings.clean_all_targeting_success')}`,
          },
        },
      }),
    );
  };
  return (
    <div
      className={cn(css.options, className, {
        _isDisabled: disabled,
      })}
      ref={dropdownMenu}
      style={style}
    >
      <div
        className={cn(css.button, {
          _isOpened: isOpened,
        })}
        onClick={isOpened ? toggleOff : toggle}
      >
        <FiSettings size={24} />
      </div>

      <div className={cn(css.list, { _isOpened: isOpened })}>
        {type !== 'YANDEX' && (
          <div className={cn(css.item)} onClick={copyTargets}>
            {t('creative.cop')}
          </div>
        )}
        <div className={cn(css.item)} onClick={() => setIsModalClear(true)}>
          {t('creative.clea')}
        </div>
      </div>

      {openCopyTargetingsModal && (
        <Portal>
          <CopyTargetingsModal
            onClose={() => setOpenCopyTargetingsModal(false)}
            id={xxhash}
          />
        </Portal>
      )}

      {isModalClear && (
        <OnlyOwner>
          <ModalWithQuestion
            btnTitle={t('clean_btn')}
            onClose={() => setIsModalClear(false)}
            onClick={clearTargets}
            title={title}
            subText={t('creative.clear_done_targ')}
            modalName={t('just_words.confirm_action')}
          />
        </OnlyOwner>
      )}
    </div>
  );
};

export default Options;
