import React, { FC } from 'react';
import InputMask, { Props as PropsPhoneInput } from 'react-input-mask';
import cn from 'classnames';
import { TAllProps } from './input';
import input from './styles.module.scss';
import Label from './Label';
import ErrorText from '../ErrorText';

type TPhoneInput = PropsPhoneInput & TAllProps;

const PhoneInput: FC<TPhoneInput> = (props) => (
  <InputMask {...props} mask="+7 (999) 999-99-99">
    <>
      {(inputProps: TAllProps) => {
        const { error, label, name, errortext } = props
        return <div
          className={cn(
            'input-form-group',
            input['form-group'],
            error ? input['form-group_error'] : '',
          )}
        >
          {label && (
            <Label label={`${label}`} forInput={name} />
          )}
          <input {...inputProps} />
          {error && errortext && (
            <ErrorText text={errortext} />
          )}
        </div>
      }}
    </>
  </InputMask>
);

export default PhoneInput;
