import { useCallback, useEffect, useMemo, useState } from 'react';
import { ChartStatisticItem, WeekStatisticItem } from 'domains/campaigns/types';
import { format } from 'date-fns';
import { useSearchBreadcrumbsInfo } from 'domains/search/model/selectors';
import { Column } from './useGetColumns';

type TGetClientsStatReportReturn = {
  disGetReport: boolean;
  onGetReport: () => void;
};

type TGetClientsStatReport = (
  data: ChartStatisticItem[],
  total: WeekStatisticItem | null,
  columns: Column[],
  isLoadingData?: boolean,
) => TGetClientsStatReportReturn;

export const useGetClientReport: TGetClientsStatReport = (
  data,
  total,
  columns,
) => {
  function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  const { data: breadcrumbs } = useSearchBreadcrumbsInfo();

  const worker = useMemo(
    () => new Worker('/workers/getClientReport.js?v=1.0'),
    [],
  );

  const [disGetReport, setDisGetReport] = useState(false);

  useEffect(() => {
    if (data.length) {
      setDisGetReport(false);
      return;
    }
    setDisGetReport(true);
  }, [data]);  

  const onGetReport: () => void = useCallback(() => {
    setDisGetReport(true);
    const dataForPost = data.map((item) => ({
      ...item,
      date: item.date ? format(new Date(item.date), 'dd.MM.yyyy') : '',
    }));
    worker.postMessage([dataForPost, total, columns]);
    // eslint-disable-next-line no-param-reassign
    worker.onmessage = function (e) {
      const type = isSafari() ? 'application/csv' : 'text/csv';
      const blob = new Blob(['', e.data], { type });

      const URL = window.URL || window.webkitURL;

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', `${breadcrumbs?.Client.title}.tsv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setDisGetReport(false);
    };
    // eslint-disable-next-line no-param-reassign
    worker.onerror = function (e) {
      console.error({ e });
      setDisGetReport(false);
    };
  }, [data]);

  return {
    disGetReport,
    onGetReport,
  };
};
