import {
    THookSelectorFetchDataInfo,
    TSelector,
    useGenFetchDataSelector,
  } from '../../../redux/rootSelectors';
  import { TBlDomainsState, TGetListData } from '../types';
  
  export const selectorBlDomains: TSelector<TBlDomainsState['blDomains']> = ({ blDomainsReducer }) =>
    blDomainsReducer.blDomains;
  
  /** список хуков */
  export const useBlDomains: THookSelectorFetchDataInfo<TGetListData> = () =>
    useGenFetchDataSelector(selectorBlDomains);
  