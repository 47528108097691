import Load from 'components/UI/Load';
import React, { FC } from 'react';
import css from './styles.module.scss';

const LoadingModeration: FC = () => (
  <div className={css.loading_moderation}>
    <Load
      style={{
        width: `195px`,
        height: '22px',
        marginBottom: '24px',
      }}
    />
    <Load
      style={{
        width: `300px`,
        height: '24px',
        marginBottom: '32px',
      }}
    />
    <Load
      style={{
        width: `180px`,
        height: '18px',
        marginBottom: '16px',
      }}
    />
    <Load
      style={{
        width: `100%`,
        height: '17px',
        marginBottom: '25px',
      }}
    />
    <div className={css.checkbox}>
      <Load
        style={{
          width: `18px`,
          height: '18px',
          marginRight: '14px',
        }}
      />
      <Load
        style={{
          width: `100px`,
          height: '20px',
        }}
      />
    </div>
    <div className={css.checkbox}>
      <Load
        style={{
          width: `18px`,
          height: '18px',
          marginRight: '14px',
        }}
      />
      <Load
        style={{
          width: `100px`,
          height: '20px',
        }}
      />
    </div>
    <div className={css.checkbox}>
      <Load
        style={{
          width: `18px`,
          height: '18px',
          marginRight: '14px',
        }}
      />
      <Load
        style={{
          width: `100px`,
          height: '20px',
        }}
      />
    </div>
  </div>
);

export default LoadingModeration;
