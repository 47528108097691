import React, { FC } from 'react';
import css from '../../styles.module.scss';
import breadcrumbsStyle from './styles.module.scss';
import { useBreadcrumbsCopyModal } from './hook';

const Breadcrumbs: FC = () => {
  const { breadcrumbsList, handlerClickBreadcrumbs } =
    useBreadcrumbsCopyModal();
  return (
    <div className={css.breadcrumbs}>
      <div className={breadcrumbsStyle.breadcrumbs}>
        {!!breadcrumbsList.length &&
          breadcrumbsList.map(({ title, xxhash }) => (
            <div
              className={breadcrumbsStyle.item}
              key={xxhash}
              onClick={() => handlerClickBreadcrumbs(xxhash)}
            >
              {title}
            </div>
          ))}
      </div>
    </div>
  );
};
export default Breadcrumbs;
