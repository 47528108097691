import React, { FC } from 'react';
import cn from 'classnames';
import { FiCheck } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import css from '../../../styles.module.scss';
import { TGroup } from '../../../../../types/general';
import i18n from '../../../../../i18n';

type AlertSuccessDoubleProps = {
  campaigns: TGroup;
  newCampaigns: string[];
};

const AlertSuccessDouble: FC<AlertSuccessDoubleProps> = ({
  campaigns,
  newCampaigns,
}) => (
  <div className={css.toast}>
    <div className={cn(css.icon, '_isSuccess')}>
      <FiCheck size={16} color="#fff" />
    </div>
    <div className={css.text}>
      {campaigns.length === 1 &&
        `${i18n.t(`just_words.one_duplicate`)} ${i18n.t(
          `creative.dubl_creative.add_eng_prefix_one`,
        )} `}
      {campaigns.length > 1 &&
        `${i18n.t(`just_words.several_duplicates`)} ${i18n.t(
          `creative.dubl_creative.add_eng_prefix_many`,
        )} `}
      {campaigns.map((item, index) => (
        <Link
          to={`/main/campaign/${item.xxhash}/main`}
          key={item.xxhash}
          rel="noopener noreferrer"
          target="_blank"
        >
          <strong>{item.title}</strong>
          {campaigns.length - 1 !== index && <span>, </span>}
        </Link>
      ))}
      {campaigns.length === 1 &&
        ` ${i18n.t(`just_words.one_is_successful_create`)} `}
      {campaigns.length > 1 &&
        ` ${i18n.t(`just_words.several_successful_create`)} `}
      <div className={css.links}>
        {newCampaigns.map((hash, index) => (
          <Link
            to={`/main/campaign/${hash}/main`}
            key={hash}
            rel="noopener noreferrer"
            target="_blank"
          >
            <strong>{`${campaigns[index]?.title} copy`}</strong>
            {newCampaigns.length - 1 !== index && <span>, </span>}
          </Link>
        ))}
      </div>
    </div>
  </div>
);
export default AlertSuccessDouble;
