import { createAction } from '@reduxjs/toolkit';
import { TParamSetFile, TParamSetFiles } from '../types';
import { SagaUpdateVisial } from '../../../types/general';

export const fetchGroups = createAction('project/fetchGroups');

export const setFileProjectAction = createAction<
  SagaUpdateVisial & { data: TParamSetFile }
>('project/setFileProjectAction');

export const setFilesProjectAction = createAction<
  TParamSetFiles & { setEdit: React.Dispatch<React.SetStateAction<boolean>> }
>('project/setFilesProjectAction');
