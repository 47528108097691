import {
  useState,
  useCallback,
  useEffect,
  RefObject,
  SyntheticEvent,
  ChangeEvent,
  useRef,
} from 'react';
import { setAndReturnParamsPeriod } from 'domains/historyChanges/model/utils/setAndReturnParamsPeriod';
import { useDispatchApp } from 'redux/rootSelectors';
import { fetchHistoryData } from 'domains/historyChanges/actions';
import { IHistoryFilter } from 'domains/historyChanges/types';
import { useUserInfo } from 'domains/user/model/selectors';
import { KeysSettings, useStateValue } from 'utils/context';
import { debounce } from 'lodash';
import { RGetPersonal } from '../domains/campaign/types';

export type RUseSearchField = {
  search: string;
  toggleSearch: boolean;
  onChangeSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  onOpenSearch: () => void;
  onCloseSearch: () => void;
  filterData: (data: Array<RGetPersonal>, field: string) => Array<RGetPersonal>;
  onSetSearch: (v: string) => void,
};

type TFUseSearchField = (
  ref: RefObject<HTMLInputElement>,
  wrapperRef?: RefObject<HTMLDivElement>,
  historyFilters?: IHistoryFilter,
  limitItems?: number,
) => RUseSearchField;

type Clicked = Event | SyntheticEvent;

const useSearchField: TFUseSearchField = (ref, wrapperRef, historyFilters, limitItems) => {
  const [search, setSearch] = useState('');
  const [toggle, setToggle] = useState<boolean>(false);
  const { paramsPeriod } = setAndReturnParamsPeriod();
  const { data: user } = useUserInfo();
  const { state } = useStateValue();
  const settings = state.settings[KeysSettings.HISTORY];
  const dispatch = useDispatchApp();

  useEffect(() => {
    const escapeClose = ({ code }: KeyboardEvent) => {
      if (code === 'Escape') {
        onCloseSearch();
        setSearch('');
      }
    };

    document.addEventListener('keydown', escapeClose);

    return () => document.removeEventListener('keydown', escapeClose);
  }, []);

  const hideSearch = (event: Clicked) => {
    if (wrapperRef) {
      if (
        !search &&
        toggle &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setToggle(false);
      }
    } else if (
        // убрать это условие когда поправлю все поля с инпутом
        !search &&
        toggle &&
        ref.current &&
        !ref.current.contains(event.target as Node)
      ) {
        setToggle(false);
      }
  };  

  useEffect(() => {
    document.addEventListener('click', hideSearch);

    return () => {
      document.removeEventListener('click', hideSearch);
    }
  }, [toggle, search]);

  const filterData: RUseSearchField['filterData'] = useCallback(
    (data, field) =>
      data.filter((e) => e[field].toLowerCase().includes(search.toLowerCase())),
    [search],
  );

  const dispatchSearchData = (value: string) => {
    dispatch(fetchHistoryData({
      partner_xxhash: user?.partner.xxhash,
      filter: {
        ...historyFilters,
        search: value || null,
        period: paramsPeriod.from ? paramsPeriod : settings.period,
        limit: { 'from': 0, 'to': limitItems || 200 }
      },
    }));
  }

  const delayedDispatchSearchDataRef = useRef<ReturnType<typeof debounce> | null>(null);

  const checkIsActiveDebounceDelay = ( value: string) => {
    if (delayedDispatchSearchDataRef.current) {
      delayedDispatchSearchDataRef.current.cancel();
    }
  
    delayedDispatchSearchDataRef.current = debounce(() => {
      dispatchSearchData(value);
    }, 700);
  
    delayedDispatchSearchDataRef.current();
  }
  
  const onChangeSearch: RUseSearchField['onChangeSearch'] = (event) => {
    const { value } = event.target;
    setSearch(value); 
    checkIsActiveDebounceDelay(value)
  };

  const onOpenSearch: RUseSearchField['onOpenSearch'] = () => {
    setToggle(true);
    setTimeout(() => {
      if (ref && ref.current) ref.current.focus();
    }, 50);
  };

  const onCloseSearch: RUseSearchField['onCloseSearch'] = () => {
    setToggle(false);
    setSearch('');
    dispatchSearchData('');
  };

  const onSetSearch = (v: string) => {
    setSearch(v)
  }

  return {
    filterData,
    onChangeSearch,
    onSetSearch,
    search,
    onOpenSearch,
    onCloseSearch,
    toggleSearch: toggle,
  };
};

export default useSearchField;
