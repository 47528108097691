import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContainerByID, FetchByIDParam } from '../../types/containers';
import { FetchedData, genFetchedData } from '../../redux/fetchedData';
import { TID } from '../../types/general';

export interface IContainerDetail {
  tempDetail: FetchedData<ContainerByID>;
}

const initialState: IContainerDetail = {
  tempDetail: genFetchedData<ContainerByID>(null),
};

const containerDetailSlice = createSlice({
  name: 'containerDetail',
  initialState,
  reducers: {
    setTempContainerById(
      state,
      action: PayloadAction<IContainerDetail['tempDetail']>,
    ) {
      state.tempDetail = action.payload;
    },
  },
});
export const fetchContainerDetailByID = createAction<FetchByIDParam>(
  'containerDetail/fetchContainerByIDAction',
);
export const onDownloadContainerRequest = createAction<TID>(
  'containerDetail/onDownloadContainerRequest',
);

export const { setTempContainerById } = containerDetailSlice.actions;
export const containerDetailReducer = containerDetailSlice.reducer;
