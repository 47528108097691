import React, { FC } from 'react';
import { FiRotateCcw } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import css from './styles.module.scss';

interface IRevertBlockProps {
  counter: number;
  onClick: () => void;
}

const RevertBlock: FC<IRevertBlockProps> = ({ counter, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className={cn(css.revertBlock, 'revert_block')}>
      <div className={cn(css.revertBlock_body, 'revert_block-body')} onClick={onClick}>
        <FiRotateCcw size={18} />
        {`${t('common.tags.revert_change')} (${counter}${t(
          'common.tags.second',
        )})`}
      </div>
    </div>
  );
};

export default RevertBlock;
