import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
  FC,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { FiPlus, FiX } from 'react-icons/fi';
import { MdInfoOutline } from 'react-icons/md';
import Help from 'components/Help';
import Loader from 'components/UI/Loader';
import i18next from 'i18next';
import css from './styles.module.scss';
import Button from '../../../../components/UI/Buttons/buttons';
import ModalOverlay from '../../../../components/Modals/ModalOverlay1';
import { checkedLocalization } from '../../../../utils/checkedLocalization';
import { PAddAjaxListModal } from '../../model/types';
import { countLimit, includeValidLimit } from '../../model/shared';
import { exportItemsToExcel } from '../../../campaigns/model/actions';
import { useDispatchApp } from '../../../../redux/rootSelectors';
import { useCampaignInfo } from '../../../campaign/model/selectors';
import RepeatItem from './RepeatItem';
import { useGeoIdSearchList } from './hooks/useGeoIdSearchList';
import HandleErrors from './HandleErrors';
import ModalHeader from './ModalHeader';
import { mapKeys } from '../../const';

const AddAjaxListModal: FC<PAddAjaxListModal> = ({
  isOpen,
  onCloseModal,
  type,
  is_wo_available,
  checkedCount = 0,
  onHandleSubmit,
  onHandleGeo,
}) => {
  const dispatch = useDispatchApp();
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const { data: campaign } = useCampaignInfo();

  const {
    searchData,
    repeatedTitles,
    cleanSearchData,
    repeatedItems,
    isShowRepeated,
    isLoading,
    isGeo,
    repeatedCheckboxHandler,
    onSubmitGeo,
  } = useGeoIdSearchList({
    type,
    value,
    onHandleGeo,
    onCloseModal,
    dispatch,
    setError,
  });

  const downloadExelCatalog = () => {
    if (campaign) {
      dispatch(
        exportItemsToExcel({
          key: type,
          campaign_xxhash: campaign.xxhash,
        }),
      );
    }
  };

  useEffect(() => {
    if (repeatedTitles.length) {
      setError(`${repeatedTitles.join(', ')}`);
    }
  }, [repeatedTitles]);

  /*   const locationErrorList = useMemo(
    () =>
      value
        .trim()
        .split('\n'),
    [value],
  ); */

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (type === 'site_domain' || type === 'site_uri') {
      const val = e.target.value;
      const rex = /[^ ]+$/;
      if (val.length) {
        if (rex.test(val)) {
          setValue(e.target.value);
        }
      } else {
        setValue(e.target.value);
      }
    } else if (type === 'adgroup') {
      const val = e.target.value;
      const rex = /[a-z0-9\n]+$/;
      if (val.length) {
        if (rex.test(val)) {
          setValue(e.target.value);
        }
      } else {
        setValue(e.target.value);
      }
    } else if (type === 'private_deals') {
      const val = e.target.value;
      const rex = /[A-z0-9-\n]+$/;
      if (val.length) {
        if (rex.test(val)) {
          setValue(e.target.value);
        }
      } else {
        setValue(e.target.value);
      }
    } else if (type === 'au') {
      const val = e.target.value;
      const rex = /[0-9\n]+$/;
      if (val.length) {
        if (rex.test(val)) {
          setValue(e.target.value);
        }
      } else {
        setValue(e.target.value);
      }
    } else {
      setValue(e.target.value);
    }
  };

  const onSubmit = useCallback(() => {
    if (value && value.trim()) {
      const dataList = value
        .trim()
        .split('\n')
        .filter((e: string) => e.length > 0);
      let data: { key: string; title: string }[] = [];

      data = dataList.map((item) => ({
        key: mapKeys.get(item) || item,
        title: item,
      }));

      onHandleSubmit(data);
      onCloseModal();
    }
  }, [value]);

  useEffect(() => {
    setValue('');
  }, [isOpen]);

  const isValidDisable = useMemo<boolean>(() => {
    if (type === 'geo_id') {
      return (
        !!repeatedTitles.length &&
        !repeatedTitles.every((item) =>
          cleanSearchData.find((cleanItem) => cleanItem.name_ru === item),
        )
      );
    }
    if (type === 'adgroup') {
      let res = true;
      value
        .trim()
        .split('\n')
        .forEach((v) => {
          if (v.length < 2) {
            res = false;
          }
        });
      return !res;
    }
    if (includeValidLimit.findIndex((v) => v === type) > -1) {
      if (
        value.trim().split('\n').length >
        countLimit[`${type}`] - checkedCount
      ) {
        setError(t(`common.targetings_error.${type}.countModal`));
        return true;
      }
      if (error) {
        setError('');
      }
      return false;
    }
    return !value.trim().length;
  }, [value, cleanSearchData, checkedCount]);

  return (
    <ModalOverlay onCloseModal={onCloseModal} isOpen={isOpen}>
      <div className={css.card}>
        <div className={css.header}>
          <div className={css.title}>
            <h1>
              {checkedLocalization(
                `targetings.add_popap_tit.${type}`,
                `targetings.add_popap_tit.general`,
              )}
            </h1>
          </div>
          <div className={css.close}>
            <FiX size={24} onClick={onCloseModal} />
          </div>
        </div>
        <div className={css.content}>
          <div
            className={cn(css.grid, {
              _isGeo: isShowRepeated,
              _isDisabled: isLoading,
            })}
          >
            <div className={css.list}>
              <ModalHeader
                type={type}
                downloadExelCatalog={downloadExelCatalog}
              />
              <div className={css.textarea}>
                <textarea
                  className={css.field}
                  value={value}
                  onChange={onChange}
                />
              </div>
            </div>
            {isShowRepeated && (
              <div className={css.match}>
                <div className={css.label}>
                  {t(`targetings.geo_id.matches`)}
                  <Help
                    classname={css.marginNull}
                    title=""
                    helpattributes={{
                      text: t(`targetings.geo_id.added_matches`),
                      sizeIcon: 18,
                    }}
                  />
                </div>
                <div className={css.match_content}>
                  {repeatedTitles.map((title) => (
                    <RepeatItem
                      key={title}
                      title={title}
                      repeatedCheckboxHandler={repeatedCheckboxHandler}
                      itemsArr={repeatedItems.filter((item) =>
                        i18next.language === 'ru'
                          ? item.name_ru === title
                          : item.name_en === title,
                      )}
                    />
                  ))}
                </div>
              </div>
            )}
            {isLoading && (
              <div className={css.loader}>
                <Loader />
              </div>
            )}
          </div>
          {error && (
            <HandleErrors isShowRepeated={isShowRepeated} error={error} />
          )}
          {
            /* (!!locationErrorList.length) || */
            type === 'geo_id' && searchData?.notFound && (
              <span className={cn(css.error, 'isLocation')}>
                <MdInfoOutline size="20" />
                <span>
                  {t(
                    `targetings.add_popap_tit.add_popap_prompt.title.${type}.errorPartOne`,
                  )}
                  <div className={css.geo}>
                    {searchData?.notFound?.join(',')}
                  </div>
                  {t(
                    `targetings.add_popap_tit.add_popap_prompt.title.${type}.errorPartTwo`,
                  )}
                  <span className={css.link} onClick={downloadExelCatalog}>
                    {t(
                      `targetings.add_popap_tit.add_popap_prompt.title.${type}.partTwo`,
                    )}
                  </span>
                  {t(
                    `targetings.add_popap_tit.add_popap_prompt.title.${type}.errorPartThree`,
                  )}
                </span>
              </span>
            )
          }
        </div>

        {is_wo_available && (
          <div className={css.tags}>
            <div
              className={cn(css.item, {
                isDisabled: value.includes(
                  t(`targetings.add_popap_tit.add_popap_prompt.title.${type}`),
                ),
              })}
              onClick={() => {
                const newValue = value.length > 0 ? `${value}\n` : value;

                return setValue(
                  newValue +
                    t(
                      `targetings.add_popap_tit.add_popap_prompt.title.general`,
                    ),
                );
              }}
            >
              <span>
                {t(`targetings.add_popap_tit.add_popap_prompt.title.general`)}
              </span>
              <FiPlus size={20} />
            </div>
            {type === 'app' && (
              <div
                className={css.item}
                onClick={() => {
                  const newValue = value.length > 0 ? `${value}\n` : value;

                  return setValue(
                    newValue +
                      t(
                        `targetings.add_popap_tit.add_popap_prompt.title.all_app`,
                      ),
                  );
                }}
              >
                <span>
                  {t(`targetings.add_popap_tit.add_popap_prompt.title.all_app`)}
                </span>
                <FiPlus size={20} />
              </div>
            )}
          </div>
        )}
        <div className={css.actions}>
          <Button
            transparent
            title={t('cancel_btn')}
            height={40}
            autoWidth
            buttonAttributes={{
              onClick: onCloseModal,
            }}
          />
          <Button
            title={t('add_btn')}
            height={40}
            autoWidth
            buttonAttributes={{
              disabled:
                isValidDisable ||
                value === '' ||
                (type === 'private_deals' &&
                  value.split('\n').some((item) => item.length <= 1)) ||
                isLoading,
              onClick: isGeo ? onSubmitGeo : onSubmit,
            }}
          />
        </div>
      </div>
    </ModalOverlay>
  );
};

export default AddAjaxListModal;
