import { takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
  fetchTargetItemsData,
  fetchCreativeList,
} from 'domains/Analysis/actions';
import { workerFetchTargetItemsData, workerFetchCreativeList } from './workers';

export default function* watchAnalysis(): SagaIterator {
  yield takeLatest(fetchTargetItemsData, workerFetchTargetItemsData);
  yield takeLatest(fetchCreativeList, workerFetchCreativeList);
}
