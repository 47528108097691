import { parse } from 'date-fns';
import { getGenerateNamingItem } from 'domains/campaign/reports/reportStatististic/getNamingItem';
import { TNamingItem, TSeries } from 'domains/campaign/types';
import { Column } from 'domains/campaigns/Reports/model/useGetColumns';
import {
  ChartStatisticItem,
  WeekStatistic,
  WeekStatisticItem,
} from 'domains/campaigns/types';
import { useCurrency } from 'domains/project/hooks';
import { useEffect, useMemo, useState } from 'react';
import getChartData from 'utils/getChartData';
import { chartColors } from '../model/chartColors';

type TParamUseReportChart = {
  chartData: WeekStatistic | null;
  columns?: Column[];
  chartDataLegend: {
    name: string;
    isOff: boolean;
  }[];
};

type TFUseReportChart = (p: TParamUseReportChart) => {
  dataForChart: ReturnType<typeof getChartData>;
  series: TSeries;
  namingItem: TNamingItem;
  isLoadingChartView: boolean;
  transformedStatistic: ChartStatisticItem[];
  totalValues: WeekStatisticItem | null;
};

export const useReportChart: TFUseReportChart = ({
  chartData,
  chartDataLegend,
  columns,
}) => {
  const { currency } = useCurrency();
  const [isLoadingChartView, setIsLoadingChartView] = useState(false);
  const [transformedStatistic, setTransformedStatistic] = useState<
    ChartStatisticItem[]
  >([]);
  const [totalValues, setTotalValues] = useState<WeekStatisticItem | null>(
    null,
  );

  useEffect(() => {
    if (chartData) {
      const tempKeys = Object.keys(chartData)
        .filter((key) => key !== 'total')
        .sort(
          (a, b) =>
            +parse(a, 'yyyy-MM-dd', new Date()) -
            +parse(b, 'yyyy-MM-dd', new Date()),
        );
      setTotalValues(chartData.total);
      const transformedData = tempKeys.map((item) => ({
        ...chartData[item],
        date: item,
      }));
      setTransformedStatistic(transformedData);
    }
  }, [chartData]);

  const createTitleDictionary = (columnsArr: Column[] | undefined): Map<Column[keyof Column], keyof Column> => {
    const dictionary = new Map()
    columnsArr?.forEach(col => dictionary.set(col.data, col.name))
    return dictionary
  }

  const namingItem = useMemo(
    () => getGenerateNamingItem({priceType: '', typeReport: null, total: totalValues, currency, columnsDict: createTitleDictionary(columns)}),
    [totalValues],
  );

  const dataForChart = useMemo(
    () => getChartData(transformedStatistic, chartDataLegend, namingItem),
    [transformedStatistic, chartDataLegend],
  );

  const series = useMemo<TSeries>(() => {
    const chartShow = chartDataLegend.filter((i) => !i.isOff);
    return chartShow.map((i, j) => {
      const obj = namingItem.get(i.name);
      const color = obj === undefined ? chartColors[j] : obj.color;
      return {
        color,
        areaOpacity: 0.05,
        targetAxisIndex: 0,
      };
    });
  }, [chartDataLegend, transformedStatistic, namingItem]);

  useEffect(() => {
    const emptyStat = !transformedStatistic.length && !dataForChart;
    if (dataForChart || emptyStat) {
      setIsLoadingChartView(false);
      return;
    }
    if (isLoadingChartView === false) {
      setIsLoadingChartView(true);
    }
  }, [dataForChart, transformedStatistic]);

  return {
    dataForChart,
    series,
    namingItem,
    isLoadingChartView,
    transformedStatistic,
    totalValues,
  };
};
