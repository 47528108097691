import React, { FC } from 'react';
import ScreenshotView from 'components/ScreenshotView';
import { CreativeDetail } from 'domains/creative/types';
import { useScreenShotData } from '../hooks/useScreenShotData';

interface Props {
  creativeDetail: CreativeDetail | null;
}

const ScreenshotWidget: FC<Props> = ({ creativeDetail }) => {
  const {
    createScreenHandler,
    screenStatus,
    updateScreenStatusHandler,
    isDisableCreate,
    creoTitle,
    textError,
  } = useScreenShotData({ creativeDetail });

  return (
    <>
      <ScreenshotView
        screenStatus={screenStatus}
        textError={textError}
        creoTitle={creoTitle}
        isDisableCreate={isDisableCreate}
        createScreenHandler={createScreenHandler}
        updateScreenStatusHandler={updateScreenStatusHandler}
      />
    </>
  );
};

export default ScreenshotWidget;
