import React, { FC, useCallback, useState } from 'react';
import Card from 'components/UI/Card';
import cn from 'classnames';
import { useDispatchApp } from 'redux/rootSelectors';
import { setEditPersonalCampaign } from 'domains/campaign/model/actions';
import {
  PSetEditPersonalCampaign,
  TExperimentalInfoField,
} from 'domains/campaign/types';
import { ReactComponent as ChangeBatterySvg } from 'assets/charge-battery.svg';
import debounce from 'lodash/debounce';
import css from './styles.module.scss';

interface Props {
  configValues: Record<string, string | boolean>;
  xxhash: string;
  optimus?: TExperimentalInfoField;
  optimus_hb?: TExperimentalInfoField;
}

const Optimisation: FC<Props> = ({
  configValues,
  xxhash,
  optimus,
  optimus_hb,
}) => {
  const dispatch = useDispatchApp();

  const [on, setOn] = useState(optimus && Boolean(configValues[optimus.id]));
  const [onHB, setOnHB] = useState(
    optimus_hb && Boolean(configValues[optimus_hb.id]),
  );

  const debounceEdit = debounce(
    (arg: PSetEditPersonalCampaign) => dispatch(setEditPersonalCampaign(arg)),
    500,
  );

  const debounceHandleEdit = useCallback(
    (arg: PSetEditPersonalCampaign) => debounceEdit(arg),
    [],
  );

  const clickHandler = (fieldType: 'optimus' | 'optimus_hb') => {
    if (!optimus && fieldType === 'optimus' || !optimus_hb && fieldType === 'optimus_hb') return;
    let currentId = null
    if(fieldType === 'optimus') {
      setOn((prev) => !prev);
      currentId = optimus?.id
    } else {
      setOnHB((prev) => !prev)
      currentId = optimus_hb?.id
    }

    // eslint-disable-next-line no-param-reassign
    if(currentId) configValues[currentId] = fieldType === 'optimus' ? !on : !onHB;

    const params = {
      xxhash,
      config_values: configValues,
      /* blockGetQuery: true, */
    };
    debounceHandleEdit(params);
  };

  return (
    <Card noHover className={css.card}>
      <div className={css.optimisation_btns}>
        {optimus && (
          <button
            type="button"
            className={cn(css.button, 'green', {
              _isOn: on,
            })}
            onClick={() => clickHandler('optimus')}
          >
            <div className={css.button_body}>
              <ChangeBatterySvg />
              <span>OPTIMUS</span>
            </div>
          </button>
        )}
        {optimus_hb && (
          <button
            type="button"
            className={cn(css.button, 'blue', {
              _isOn: onHB,
            })}
            onClick={() => clickHandler('optimus_hb')}
          >
            <div className={css.button_body}>
              <ChangeBatterySvg />
              <span>OPTIMUS HB</span>
            </div>
          </button>
        )}
      </div>
    </Card>
  );
};

export default Optimisation;
