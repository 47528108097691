import { checkedLocalization } from 'utils/checkedLocalization';
import { useTranslation } from 'react-i18next';
import { useCurrency } from 'domains/project/hooks';
import { useEffect, useState } from 'react';
import { useCampaignsTotal, usePartnerStatisticFieldList } from '../selectors';

export interface IFilterColumns {
  name: string;
  data: string;
  lengthTitle: number;
}

interface IUseGetFilterForCampaigns {
  filterColumns: IFilterColumns[];
}

export const useGetFilterForCampaigns = (): IUseGetFilterForCampaigns => {
  const { t } = useTranslation();
  const { currency } = useCurrency();
  const { data: campaignTotal } = useCampaignsTotal();

  /* Столбцы для которых надо добавлять в заголовок таблицы валюту */
  const columnsForCurrency = ['cpc', 'cpm', 'spent', 'budget'];

  const { data: statisticFieldList } = usePartnerStatisticFieldList();

  /* Пока что по умолчанию выводится столбец - название -  всегда */
  const initialColumns = [
    {
      name: t('campaigns_page.campaigns_header_main.name'),
      data: 'name',
      lengthTitle: t('campaigns_page.campaigns_header_main.name').length,
    },
  ];

  const [filterColumns, setFilterColumns] = useState(initialColumns);

  useEffect(() => {
    if (statisticFieldList) {
      const fieldsArr = statisticFieldList
        .filter((item) => item.checked)
        .map((item) => {
          const title = `${checkedLocalization(
            `campaigns_page.campaigns_header_main.${item.key}`,
            item.title,
          )}`;
          /* Если в columnsForCurrency есть item.key тогда добавляем валюту */
          const name = `${title}${columnsForCurrency.includes(item.key) ? ` ,${currency}` : ''}`;
          return {
            name,
            data: item.key,
            lengthTitle: title.length,
          };
        });
      setFilterColumns([...initialColumns, ...fieldsArr])
    }
  }, [statisticFieldList]);

  /* Если общяя статистика длиннее названия, то название класса берем исходя из длины значения общей статистики */
  useEffect(() => {
    if (campaignTotal?.total) {
      const tempArr = filterColumns.map(item => {
        if (campaignTotal.total[item.data]) {
          if (campaignTotal.total[item.data].toFixed().length > item.lengthTitle) {
            return {
              ...item,
              lengthTitle: campaignTotal.total[item.data].toFixed().length
            }
          }
        }
        return item;
      })
      setFilterColumns(tempArr)
    }
  }, [campaignTotal])

  return {
    filterColumns,
  };
};
