import React, { FC } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { FiCheck } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';
import { RCreativeClone } from '../../../../domains/creative/types';

type Props = {
  targetingInfo: RCreativeClone;
  title: string;
  campaign_id: string;
};

export const isCloneResponseSuccess = (
  v: unknown | RCreativeClone,
): v is RCreativeClone => true;

const ToastSuccess: FC<Props> = ({ targetingInfo, title, campaign_id }) => {
  const { t } = useTranslation();

  return (
    <div className={css.toast}>
      <div className={cn(css.icon, '_isSuccess')}>
        <FiCheck size={16} color="#fff" />
      </div>
      <div className={css.text}>
        {t('creative.dubl_creative.t1')} <b>{`"${title}"`}</b>{' '}
        {t('just_words.one_is_successful_create')}
        <div className={css.links}>
          <Link
            to={`/main/campaign/${campaign_id}/creatives/${
              targetingInfo.xxhash_new_creative
            }`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <strong>{title} copy</strong>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default ToastSuccess;
