import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import {
  FiMoreHorizontal,
  FiTrash2,
  FiEdit,
  FiUserX,
  FiUserCheck,
} from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/UI/Tooltip';
import ToggleSwitch from 'components/UI/ToggleSwitch';
import Balance from 'components/Balance';
import ShowMoreList from 'components/ShowMoreList';
import css from './styles.module.scss';
import avatarColors from '../../../utils/avatarColors';
import { IPartners } from '../types';
import { useDispatchApp } from '../../../redux/rootSelectors';
import { editPartnerAction } from '../model/actions';
import { TFFilterBalance } from '../hooks';

interface Props {
  /** Параметры Agency */
  item: IPartners;
  isLoadingBalance: boolean;
  filterBalance: TFFilterBalance;
}

const Item: React.FC<Props> = ({ item, isLoadingBalance, filterBalance }) => {
  const {
    title,
    type,
    users,
    internal_id,
    xxhash,
    status,
    icon,
    registration: registrationServer,
  } = item;

  const dispatch = useDispatchApp();

  const [activeStatus, setActiveStatus] = useState(false);
  const [registration, setRegistration] = useState(() => registrationServer);
  const [toggle, setToggle] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (status === 'STOPPED') {
      setActiveStatus(false);
    }
    if (status === 'LAUNCHED') {
      setActiveStatus(true);
    }
    if (status === 'DELETED') {
      setActiveStatus(false);
    }
  }, [status]);

  const handlerChangeRegistration = () => {
    dispatch(
      editPartnerAction({
        data: { xxhash, registration: !registration, type },
        visual: {
          value: !registration,
          setter: setRegistration,
          alertText: t('partners_page.table.change_registration'),
        },
      }),
    );
  };

  return (
    <div className={cn(css.wrapper)}>
      <div className={css.indicator} />
      <div
        className={cn(css.item, {
          _isOpen: toggle,
        })}
      >
        {/* <Link to={`/main/campaign/${campaign_id}/main`} /> */}
        <div className={css.item__header}>
          <div
            className={css.img}
            style={
              icon
                ? {
                    backgroundImage: `url(${icon})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                  }
                : {
                    backgroundColor: avatarColors(
                      title.slice(0, 1).toLowerCase(),
                    ),
                  }
            }
          >
            {icon ? '' : title.slice(0, 1).toUpperCase()}
            <div className={cn(css.status, [`bg-${status}`])} />
          </div>
          <div className={css.text}>
            <Tooltip
              className={css.tooltip}
              hintWidth={300}
              leftArrowPosition
              title={<strong>{title}</strong>}
            >
              <p>{title}</p>
            </Tooltip>
            <div className={css.hint}>
              <span className={css.type}>{`${internal_id} ${xxhash}`}</span>
            </div>
          </div>
        </div>

        <div className={cn(css.value, 'isType')}>
          <span>{t(`type_parther.${type}`)}</span>
        </div>

        <div className={cn(css.value, 'isLogins')}>
          {!users.length && '-'}
          <ShowMoreList
            data={users}
            leftPart={<div>{users && users[0] && users[0].email}</div>}
            list={users.map((user) => (
              <div className="showMorePopup_item" key={user.email}>
                {t(`just_words.w3`)} <strong>{user.email}</strong>
              </div>
            ))}
          />
        </div>

        <div className={cn(css.value, 'isBalance')}>
          <Balance
            classname={css.balance}
            transparent
            isLoading={isLoadingBalance}
            {...filterBalance(item.xxhash)}
          />
        </div>

        <div
          className={cn('toggle-dropdown', css.dropdown, { _isOpen: toggle })}
          onMouseEnter={() => {
            setToggle(true);
          }}
          onMouseLeave={() => {
            setToggle(false);
          }}
        >
          <button type="button" className={css.dropdown__show}>
            <FiMoreHorizontal size={24} />
          </button>

          <div className={css.dropdown__menu}>
            {type === 'AGENCY' && (
              <>
                {registration ? (
                  <Tooltip
                    title={<FiUserCheck size={24} color="#149C08" />}
                    onClick={handlerChangeRegistration}
                    className={css.registration}
                    hintWidth={286}
                  >
                    <span>{t('partners_page.table.enable_registration')}</span>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={<FiUserX size={24} color="#FF3636" />}
                    onClick={handlerChangeRegistration}
                    className={css.registration}
                    hintWidth={286}
                  >
                    <span>{t('partners_page.table.deny_registration')}</span>
                  </Tooltip>
                )}
              </>
            )}

            <Tooltip isDisabled title={<FiEdit size={24} />}>
              <span>{t('partners_page.table.edit')}</span>
            </Tooltip>

            <Tooltip isDisabled title={<FiTrash2 size={24} />}>
              <span>{t('partners_page.table.delete')}</span>
            </Tooltip>

            <ToggleSwitch
              disabled
              onChange={() => console.log('')}
              checked={activeStatus}
              toggleOnly
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
