const icons = '_CAMPAIGN_ICONS';
type Obj = Record<string, number>;
export const getStorages = (): string | null =>
  window.localStorage.getItem(icons);
export const createObj = (icon: string): Obj => ({ [icon]: 1 });
export const objToJson = (obj: Obj): string => JSON.stringify(obj);
export const setStorages = (str: string): void =>
  window.localStorage.setItem(icons, str);
export const createStorage = (icon: string): void => {
  const str = objToJson(createObj(icon));
  setStorages(str);
};

export const jsonToObj = (json: string): Obj => {
  try {
    return JSON.parse(json) as Obj;
  } catch (e) {
    return {} as Obj;
  }
};
export const setObjToStorage = (obj: Obj): void => setStorages(objToJson(obj));
export function setStoragesIcons(icon: string): void {
  const storage = getStorages();
  if (!storage) {
    createStorage(icon);
    return;
  }

  const data = jsonToObj(storage);
  if (!data[icon]) {
    data[icon] = 1;
    setObjToStorage(data);
    return;
  }
  data[icon] += data[icon];
  setObjToStorage(data);
}

export function getLastIcons(): string[] {
  const storage = getStorages();
  if (!storage) return [];
  const data = jsonToObj(storage);
  const arr = Object.entries(data);
  return arr
    .sort((a, b) => b[1] - a[1])
    .slice(0, 10)
    .map(([key]) => key);
}
