import React, { FC } from 'react';
import Checkbox from 'components/UI/Checkbox/checkbox';
import { FormikProps } from 'formik';
import { FormikValues } from 'domains/usersAPI/model/types';
import css from './styles.module.scss';

interface IItemProps {
  method: string;
  name: string;
  formik: FormikProps<FormikValues>;
}

const Item: FC<IItemProps> = ({ method, name, formik }) => {
  const onChangeHandler = (methodName: string) => {
    let methodsArr = formik.values.data.methods
      ? [...formik.values.data.methods]
      : [];

    if (methodsArr.includes(methodName)) {
      methodsArr = methodsArr.filter((item) => item !== methodName);
    } else {
      methodsArr.push(methodName);
    }
    formik.setFieldValue('data.methods', methodsArr);
  };

  return (
    <div className={css.item}>
      <Checkbox
        label={`${method} / ${name}`}
        onlyBox
        inputAttributes={{
          type: 'checkbox',
          checked: formik.values.data.methods?.includes(method),
          onChange: () => onChangeHandler(method),
        }}
      />
      <div className={css.label} onClick={() => onChangeHandler(method)}>
        <strong>{method}</strong> / <span>{name}</span>
      </div>
    </div>
  );
};

export default Item;
