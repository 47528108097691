import { FormikContextType, FormikProps, useFormik } from 'formik';
import { FormikErrors } from 'formik/dist/types';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { genFetchedData } from 'redux/fetchedData';
import {
  setAuthResponseReset,
  setInfoByHash,
  singUpRequest,
} from 'domains/user/reduser';
import { fetchConfirmCreate, fetchUserInfo } from 'domains/user/actions';
import { toastError } from 'utils/ToastUtils/toastUtils';
import { TInfoByHash } from 'domains/user/types';
import { ProjectFields, TLang } from '../../project/types';
import { useProjectInfo } from '../../project/hooks';
import { objectOptimizationWithFormik } from '../../../utils/objectOptimizationWithFormik/objectOptimizationWithFormik';
import { getError } from '../../user/helpers';
import {
  useAuthResponseData,
  useUserInfoByHash,
} from '../../user/model/selectors';
import { useDispatchApp } from '../../../redux/rootSelectors';

type TFormRegistration = {
  email: string;
  password: string;
  confirm: string;
  login: string;
  skype: string;
  telegram: string;
  phone: string;
};

type RUseSingUpDetail = FormikProps<TFormRegistration> & {
  registrationKeyList: ProjectFields['name'][];
  registrationRequiredKeyList: ProjectFields['name'][];
  formikContext: FormikContextType<TFormRegistration>;
  isLoadingResponse: boolean;
  error: string;
  project: ReturnType<typeof useProjectInfo>['data'];
};

export const useSignUpDetail = (
  userUpdate: string,
  method: string | null,
): RUseSingUpDetail => {
  const {
    isLoading: isLoadingResponse,
    error: errorResponse,
    LTU,
  } = useAuthResponseData();
  const dispatch = useDispatchApp();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { data: project } = useProjectInfo();
  const { data: userInfo, error: userError } = useUserInfoByHash();
  const hash = new URLSearchParams(userUpdate).get('hash');

  const [error, setError] = useState('');

  /** список ключей регистрации */
  const registrationKey = (): ProjectFields['name'][] => {
    if (!project || !project.registration_fields.length) {
      return [];
    }
    return project.registration_fields
      .filter(({ value }) => value === 'optional' || value === 'required')
      .map(({ name }) => name);
  };
  const registrationKeyList = registrationKey();

  /** список обязательных ключей */
  const registrationRequiredKey = (): ProjectFields['name'][] => {
    if (!project || !project.registration_fields.length) {
      return [];
    }
    return project.registration_fields
      .filter(({ value }) => value === 'required')
      .map(({ name }) => name);
  };
  const registrationRequiredKeyList = registrationRequiredKey();

  /** валидация для формы регистрации */
  const validate = (values: TFormRegistration) => {
    const errors: FormikErrors<TFormRegistration> = {};
    const regSkype = /[a-z][a-z0-9\\-_]/gi;

    if (!values.email) {
      errors.email = t(`form.common.enter_email`);
    } else if (
      !/^[A-ZА-Я0-9._%+-]+@[A-ZА-Я0-9.-]+\.[A-ZА-Я]{2,4}$/i.test(values.email)
    ) {
      errors.email = t(`form.common.wrong_format`);
    } else if (values.email.includes('@')) {
      const subDomain = values.email.split('@');
      if (subDomain[0].length > 64) {
        errors.email = 'Субдомен не может быть больше 64 символов';
      }
    }

    if (values.password.length < 6) {
      errors.password = t('form.common.password_short');
    }

    if (values.password.length > 99) {
      errors.password = t('form.common.password_long');
    }

    if (values.confirm !== values.password) {
      errors.confirm = t('form.common.passesMiss');
    }
    if (method === 'confirmationCreate') return errors;
    if (values.login.length && values.login.length < 3) {
      errors.login = `${t('form.common.login_short')}. ${t(
        'form.common.login_rules',
      )}`;
    }
    if (values.login.length && values.login.length > 100) {
      errors.login = `${t('form.common.login_long')}. ${t(
        'form.common.login_rules',
      )}`;
    }

    if (
      values.phone.length &&
      (values.phone.length < 5 || values.phone.length > 20)
    ) {
      errors.phone = t('form.common.phone_rules');
    }
    registrationRequiredKeyList.forEach((value) => {
      if (value === 'skype') {
        if (values.skype.length < 3) {
          errors.skype = t('form.common.field_short');
        }
        if (values.skype.length > 40) {
          errors.skype = t('form.common.field_short');
        }
        if (!regSkype.test(values.skype)) {
          errors.skype = t('errors.err47');
        }
      } else if (value === 'telegram') {
        if (values[value].length > 99) {
          errors[value] = t('form.common.field_long');
        }
        if (values[value].length < 6) {
          errors[value] = t('form.common.field_short');
        }
      }
    });

    return errors;
  };

  const formik = useFormik<TFormRegistration>({
    initialValues: {
      email: userInfo?.email || '',
      password: '',
      confirm: '',
      login: '',
      skype: '',
      telegram: '',
      phone: '',
    },
    validate,
    onSubmit: (values) => {
      const activeFields = objectOptimizationWithFormik<TFormRegistration>(
        {
          email: '',
          password: '',
          confirm: '',
          login: '',
          skype: '',
          telegram: '',
          phone: '',
        },
        values,
      );
      if (method === 'confirmationCreate' && hash) {
        dispatch(
          fetchConfirmCreate({
            hash,
            password: (activeFields as TFormRegistration).password,
          }),
        );
        return;
      }
      dispatch(
        singUpRequest({
          domain: window.location.host,
          fields: { ...(activeFields as TFormRegistration) },
          language: i18n.language as TLang,
        }),
      );
    },
  });

  useEffect(() => {
    if (errorResponse.isError) {
      setError(() => getError(errorResponse));
    } else if (error) {
      setError('');
    }
  }, [LTU, i18n.language]);

  useEffect(() => {
    if (error) {
      setError('');
    }
  }, [formik.values]);

  useEffect(
    () => () => {
      if (errorResponse) {
        setError('');
        dispatch(setAuthResponseReset());
      }
    },
    [],
  );

  useEffect(() => {
    if (
      project &&
      !project.is_available_registration &&
      method !== 'confirmationCreate'
    ) {
      history.push('/auth/sign-in');
    }
  }, [project]);

  useEffect(() => {
    if (method === 'confirmationCreate' && hash) {
      dispatch(fetchUserInfo({ hash }));
    }
  }, [userUpdate]);

  useEffect(() => {
    if (userInfo) {
      formik.setFieldValue('email', userInfo.email);
    }
  }, [userInfo]);

  useEffect(() => {
    if (userError.code === 90) {
      toastError(t('form.common.not_valid_link'));
      localStorage.removeItem('$user_update');
      dispatch(setInfoByHash(genFetchedData<TInfoByHash>(null)));
    }
  }, [userError]);

  return {
    error,
    isLoadingResponse,
    registrationKeyList,
    project,
    registrationRequiredKeyList,
    formikContext: formik,
    ...formik,
  };
};
