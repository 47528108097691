export const chartColors = [
  '#025DFF',
  '#40BAFF',
  '#0DD3C7',
  '#E82C59',
  '#3B29A8',
  '#52C94F',
  '#DF51A6',
  '#F6AC55',
  '#A043E9',
  '#6443E9',
  '#251A50',
  '#3d1a50',
  '#504b1a',
  '#6137ff',
  '#db4785',
  '#9982f6',
  '#ffab02',
  '#40BAFF',
  '#6befe8',
  '#ec1a4b',
  '#3B29A8',
  '#3B29A8',
];

export const checkedColors = [
  '#0a0a0a',
  '#E82C59',
  '#3B29A8',
  '#52C94F',
  '#DF51A6',
  '#F6AC55',
  '#A043E9',
  '#EEE061',
  '#6443E9',
  '#B5D62F',
  '#E244BF',
  '#DDB271',
  '#593D87',
  '#E7431F',
  '#251A50',
];
