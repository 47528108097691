import React, { ChangeEvent, ComponentProps, FC } from 'react';
import Loader from 'components/UI/Loader';
import Checkbox from 'components/UI/Checkbox/checkbox';
import { FiSearch } from 'react-icons/fi';
import { RBreadCrumbsKey } from 'domains/search/model/types';
import i18n from '../../../../i18n';
import css from '../../../CopyTargetingsModal/styles.module.scss';
import Breadcrumbs from '../Breadcrumbs';
import List from '../List';

type ContentProps = ComponentProps<typeof List> & {
  search: string;
  handlerChangeSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  handlerToggleAll: () => void;
  getCheckedCount: () => {
    checkedCount: number;
    allItemsCount: number;
  };
  isLoading: boolean;
  step: RBreadCrumbsKey;
};

const Content: FC<ContentProps> = ({
  search,
  handlerChangeSearch,
  listElement,
  isLoading,
  defaultStep,
  handlerClick,
  getValueToggle,
  handlerToggle,
  handlerToggleAll,
  getCheckedCount,
  step,
}) => (
  <div className={css.content}>
    <Breadcrumbs />
    <div className={css.select}>
      {step === 'Agency' && (
        <Checkbox
          className={css.select_checkbox}
          label={`${i18n.t(
            'campaigns_page.campaign_settings.additional.statistics.selected',
          )} ${getCheckedCount().checkedCount} ${i18n.t(
            'campaigns_page.campaign_settings.additional.statistics.of',
          )} ${getCheckedCount().allItemsCount}`}
          isPartiallyChecked={
            getCheckedCount().checkedCount !== 0 &&
            getCheckedCount().checkedCount !== getCheckedCount().allItemsCount
          }
          inputAttributes={{
            type: 'checkbox',
            checked:
              getCheckedCount().allItemsCount !== 0 &&
              getCheckedCount().checkedCount ===
                getCheckedCount().allItemsCount,
            onChange: handlerToggleAll,
          }}
        />
      )}

      <div className={css.search}>
        <label htmlFor="search-targeting">
          <input
            id="search-targeting"
            type="text"
            placeholder={`${i18n.t('targetings.search')}...`}
            autoComplete="off"
            onChange={handlerChangeSearch}
            value={search}
          />
          <div className={css.search_icon}>
            <FiSearch size={24} />
          </div>
        </label>
      </div>
    </div>

    <div className={css.list}>
      {!isLoading && listElement.data ? (
        <List
          listElement={listElement}
          defaultStep={defaultStep}
          handlerToggle={handlerToggle}
          handlerClick={handlerClick}
          getValueToggle={getValueToggle}
        />
      ) : (
        <Loader />
      )}
    </div>
  </div>
);
export default Content;
