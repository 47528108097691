import React, { FC } from 'react';
import { MdInfoOutline } from 'react-icons/md';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import css from '../styles.module.scss';

interface Props {
  isShowRepeated: boolean;
  error: string;
}

const HandleErrors: FC<Props> = ({ isShowRepeated, error }) => {
  const { t } = useTranslation();
  return (
  <span
    className={cn(css.error, {
      isLocation: isShowRepeated,
    })}
  >
    <MdInfoOutline size="20" />
    {isShowRepeated ? (
      <span>
        {t(
          `targetings.add_popap_tit.add_popap_prompt.title.city.error_dubl`,
        )}
        <div className={css.geo}>{error}</div>
      </span>
    ) : (
      <div>{error}</div>
    )}
  </span>
)};

export default HandleErrors;
