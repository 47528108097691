import React, {
  ComponentProps,
  FC,
  InputHTMLAttributes,
  ReactNode,
  useRef,
  useState,
} from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { FiExternalLink, FiTrash2 } from 'react-icons/fi';
import { TFileVariationName, TKeyUploadFile } from 'domains/uploads/types';
import { MdInfoOutline } from 'react-icons/md';
import { FormikHelpers } from 'formik';
import ErrorDictionary from 'components/Errors/ErrorDictionary';
import { videoStringDuring } from 'utils/duringVideo/countSec';
import Button from 'components/UI/Buttons/buttons';
import Label, { PLabel } from 'components/UI/Input/Label';
import Loader from 'components/UI/Loader';
import { toolTipType } from 'components/UI/Input/types';
import input from './styles.module.scss';
import {
  isGardPreviewVideo,
  isGardVideo,
  isTPreviewState,
  useCreativeUpload,
} from './hook';
import { TSizes, TSizesZip, TParamVideo } from '../helpers';
import iconZip from './icon-zip.svg';
import { TFileParam } from '../../../types/general';
import { TTypeImage } from '../model/api';
import Constructor from '../Constructor';

type Props = Partial<PLabel> & {
  /** PlaceHolder для ссылки */
  customLinkTitle?: string;
  /** Атрибуты для инпутов */
  inputAttributes?: InputHTMLAttributes<HTMLInputElement>;
  /** Атрибуты для Preview */
  preview: TSizes | TSizesZip | TParamVideo;
  /** имя инпута */
  name: TFileVariationName;
  /** Режим редактирования */
  edit?: boolean;
  /** Включить Редактирование */
  hasEdit?: boolean;
  fieldType?: 'zip' | 'video' | 'image';
  /** Предупреждающий текст */
  hintText?: React.ReactNode | string;
  /** изменением состояния в форме */
  setFieldValue: FormikHelpers<string>['setFieldValue'];
  /** Максимальная величина файла в МБ */
  maxSizeFile?: number;
  /** 80 минимальное разрешение */
  minResolution?: number;
  /** 4096 максимальное разрешение */
  maxResolution?: number;
  /** текст ошибки при большом файле */
  sizeForError?: string;
  /** Помещает поля в объект data */
  isData?: boolean;
  /** расширения файла */
  file_ext?: TFileParam['file_ext'];
  typeSection?: TTypeImage['type'];
  keyUploadFile: TKeyUploadFile;
  /** Вывод тултипа со ссылкой */
  tooltip?: toolTipType;
  /** Наличие конструктора */
  constructorImage?: boolean;
  imageWidth?: number;
  imageHeight?: number;
  /* Доп вкладка */
  addTabLabel?: string;
  addTabContent?: ReactNode;
} & Pick<ComponentProps<typeof Constructor>, 'constructorTitle'>;

const UploadImage: FC<Props> = ({
  name,
  label,
  customLinkTitle,
  preview = null,
  edit,
  fieldType = 'image',
  hasEdit,
  setFieldValue,
  hintText,
  inputAttributes,
  maxSizeFile = 6,
  minResolution = 80,
  maxResolution = 4096,
  sizeForError = `${maxSizeFile} Mb`,
  isData = false,
  file_ext = ['.zip', '.png', '.jpg', '.gif', '.jpeg', '.svg', '.mp4'],
  typeSection = 'project',
  keyUploadFile,
  tooltip,
  constructorImage,
  imageWidth = 600,
  imageHeight = 600,
  constructorTitle,
  addTabLabel,
  addTabContent,
}) => {
  const { t } = useTranslation();

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [url, setUrl] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const {
    onChangeFile,
    error,
    isLoading,
    previewImage,
    handleClearStateFile,
    handlerUploadFileLink,
    constructorModal,
    toggleConstructorModal,
  } = useCreativeUpload({
    keyUploadFile,
    typeSection,
    tabIndex,
    isData,
    preview,
    nameInput: name,
    setFieldValue,
    url,
    setUrl,
    fileLimits: {
      minResolution,
      maxSizeFile,
      maxResolution,
      file_ext,
    },
    fileInputRef,
  });

  return (
    <div
      className={cn(input['form-group'], 'UploadImage', {
        isDisabled: hasEdit && !edit,
      })}
    >
      {previewImage && previewImage.file_url ? (
        <div className={input.preview}>
          <div className={input.title}>
            {label && <Label label={label} tooltip={tooltip} />}
          </div>
          <div
            className={cn(input.params, {
              _yandex: fieldType === 'zip',
              _center: name === 'img_yandex_html',
            })}
          >
            {fieldType === 'video' &&
              isGardPreviewVideo(previewImage) &&
              previewImage.image_width && (
                <>
                  <div
                    className={cn(input.video, { isLarge: false })}
                    style={{
                      backgroundImage: `url('${previewImage.video_poster_url}')`,
                    }}
                  >
                    <div className={input.video__during}>
                      {videoStringDuring(Math.round(previewImage.duration))}
                    </div>
                  </div>
                  <div className={input.description}>
                    <div className={input.size}>
                      {`${previewImage.image_width || 0}px x ${
                        previewImage.image_height || 0
                      } px • ${previewImage.file_size} Mb`}
                    </div>
                    <a
                      href={`${previewImage.file_url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={input.filename}
                    >
                      <span>{previewImage.client_name}</span>
                      <FiExternalLink size={16} color="#025DFF" />
                    </a>
                  </div>
                </>
              )}
            {fieldType === 'video' &&
              isGardVideo(previewImage) &&
              previewImage.width && (
                <>
                  <div
                    className={cn(input.video, { isLarge: false })}
                    style={{
                      backgroundImage: `url(${previewImage.video_poster_url})`,
                    }}
                  >
                    <div className={input.video__during}>
                      {previewImage.duration}
                    </div>
                  </div>
                  <div className={input.description}>
                    <div className={input.size}>
                      {`${previewImage.width || 0}px x ${
                        previewImage.height || 0
                      } px • ${Math.round(previewImage.size / 1024 / 1024)} Mb`}
                    </div>
                    <a
                      href={`${previewImage.file_url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={input.filename}
                    >
                      <span>{previewImage.client_name}</span>
                      <FiExternalLink size={16} color="#025DFF" />
                    </a>
                  </div>
                </>
              )}
            {fieldType === 'zip' && (
              <>
                <div className={input.zip}>
                  <img src={iconZip} alt="iconZip" />
                </div>
                <a href={previewImage.client_name} className={input.filename}>
                  {previewImage.client_name}
                </a>
              </>
            )}
            {fieldType === 'image' && (
              <>
                <div
                  className={cn(input.image, { isLarge: false })}
                  style={{
                    backgroundImage: `url('${previewImage.file_url}')`,
                  }}
                />
                <div className={input.description}>
                  {name !== 'img_yandex_html' &&
                    typeSection !== 'project' &&
                    isTPreviewState(previewImage) && (
                      <div className={input.size}>
                        {`${previewImage.image_width || '0'}px × ${
                          previewImage.image_height || '0'
                        }px`}
                      </div>
                    )}
                  <a
                    href={`${previewImage.file_url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={input.filename}
                  >
                    <span>{previewImage.client_name}</span>
                    <FiExternalLink size={16} color="#025DFF" />
                  </a>
                </div>
              </>
            )}
            {hasEdit && edit && (
              <div className={input.actions}>
                <span
                  className={input.remove}
                  onClick={() => {
                    handleClearStateFile(true, false, true);
                  }}
                >
                  <FiTrash2 size="20" />
                  {t('clients_page.client_edit.client_edit_delete_avatar')}
                </span>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          {isLoading && (
            <div className={input.loader}>
              <Loader />
            </div>
          )}
          <Tabs onSelect={(index) => setTabIndex(index)}>
            <TabList>
              {addTabLabel && (
                <Tab
                  className={cn('react-tabs__tab')}
                  selectedClassName="react-tabs__tab--selected"
                >
                  <span>{addTabLabel}</span>
                </Tab>
              )}
              <Tab
                className={cn('react-tabs__tab')}
                selectedClassName="react-tabs__tab--selected"
              >
                <span>
                  {customLinkTitle ||
                    t('creative.creasmall_set.linktoicon_word')}
                </span>
              </Tab>
              <Tab
                className={cn('react-tabs__tab')}
                selectedClassName="react-tabs__tab--selected"
              >
                <span>{t('creative.creasmall_set.upload_word')}</span>
              </Tab>
              {constructorImage && (
                <Tab
                  className={cn('react-tabs__tab')}
                  selectedClassName="react-tabs__tab--selected"
                >
                  <span>{t(`creative.constructor.create`)}</span>
                </Tab>
              )}
            </TabList>
            {addTabContent && <TabPanel>{addTabContent}</TabPanel>}
            <TabPanel>
              <div className={cn(input.link, { _isError: error.isError })}>
                {label && (
                  <Label label={label} tooltip={tooltip} forInput="#" />
                )}

                <div className={input.row}>
                  <input
                    type="text"
                    autoComplete="off"
                    value={url}
                    onChange={(event) => {
                      setUrl(event.target.value);
                    }}
                    disabled={hasEdit && !edit}
                  />

                  <Button
                    height={40}
                    title={t('add_btn')}
                    autoWidth
                    buttonAttributes={{
                      style: {
                        height: 40,
                      },
                      onClick: () => handlerUploadFileLink(),
                      disabled: !edit || !url,
                    }}
                  />
                </div>
              </div>

              {hintText && !error.isError && (
                <span className={input.hintText}>
                  <MdInfoOutline size="20" />
                  {hintText}
                </span>
              )}
              {error.isError && (
                <span className={input.errorText}>
                  <MdInfoOutline size="20" />
                  <ErrorDictionary
                    code={error.code === 2034 ? 20340 : error.code}
                  />
                  {(error.code === 200032 || error.code === 2032) &&
                    ` ${sizeForError}`}
                  {(error.code === 2034 || error.code === 20340) &&
                    ` ${file_ext?.join(', ')}`}
                </span>
              )}
            </TabPanel>
            <TabPanel>
              <div
                className={cn(input.upload, {
                  _isDisabled: hasEdit && !edit,
                })}
              >
                <Button
                  height={40}
                  title={t('creative.creasmall_set.upload_comp')}
                  buttonAttributes={{
                    disabled: hasEdit && !edit,
                  }}
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>
                  <input
                    {...inputAttributes}
                    name={name}
                    type="file"
                    autoComplete="off"
                    disabled={hasEdit && !edit}
                    onChange={onChangeFile}
                    ref={fileInputRef}
                  />
                </label>
              </div>

              {hintText && !error.isError && (
                <span className={input.hintText}>
                  <MdInfoOutline size="20" />
                  {hintText}
                </span>
              )}

              {error.isError && (
                <span className={input.errorText}>
                  <MdInfoOutline size="20" />
                  <ErrorDictionary
                    code={error.code === 2034 ? 20340 : error.code}
                  />
                  {(error.code === 200032 || error.code === 2032) &&
                    ` ${sizeForError}`}
                  {(error.code === 2034 || error.code === 20340) &&
                    ` ${file_ext?.join(', ')}`}
                </span>
              )}
            </TabPanel>
            {constructorImage && (
              <TabPanel>
                {label && (
                  <Label label={label} tooltip={tooltip} forInput="#" />
                )}
                <Button
                  title={t(`create_btn`)}
                  buttonAttributes={{
                    disabled: !edit,
                    onClick: toggleConstructorModal,
                  }}
                />
              </TabPanel>
            )}
          </Tabs>
        </>
      )}
      {constructorModal && (
        <Constructor
          constructorTitle={constructorTitle}
          onClose={toggleConstructorModal}
          width={imageWidth}
          height={imageHeight}
          handlerUploadFileLink={handlerUploadFileLink}
        />
      )}
    </div>
  );
};
export default UploadImage;
