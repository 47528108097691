import React, { FC } from 'react';
import { FiX } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import ModalOverlay from 'components/Modals/ModalOverlay1';
import Card from 'components/UI/Card';
import Button from 'components/UI/Buttons/buttons';
import MultipleUpload from 'components/UI/MultipleUpload';
import { AppState } from 'redux/rootReducer';
import { useSelector } from 'react-redux';
import css from './styles.module.scss';
import { useModalBwListUpdate } from './hooks/useModal';
import { BwListUpdateState } from '../reducer';

const UploadBWListModal: FC = () => {
  const { t } = useTranslation();

  const { isShowModal, closeReset } = useModalBwListUpdate();
  const tempLoadFiles = useSelector<AppState, BwListUpdateState['loadFiles']>(
    ({ bwListUpdateReducer }) => bwListUpdateReducer.loadFiles,
  );
  const isLoading = tempLoadFiles.get('isLoading');

  return (
    <ModalOverlay onCloseModal={closeReset} isOpen={isShowModal}>
      <Card className={cn(css.container)} noHover>
        <div className={css.header}>
          <div className={css.title}>
            <h1>{t('bwlist_modal.title')}</h1>
          </div>
          <div
            className={cn(css.close, {
              isDisable: isLoading,
            })}
          >
            <FiX size={24} onClick={closeReset} />
          </div>
        </div>
        <div className={css.form}>
          <form>
            <div className={css.dropzone}>
              <MultipleUpload
                dropzoneText={t('bwlist_modal.dropzone_text')}
                acceptedTypes=""
              />
            </div>
            <div className={css.buttons}>
              <Button
                transparent
                title={t('bwlist_modal.button_ok')}
                height={36}
                autoWidth
                buttonAttributes={{
                  onClick: closeReset,
                  disabled: isLoading,
                }}
              />
            </div>
          </form>
        </div>
      </Card>
    </ModalOverlay>
  );
};
export default UploadBWListModal;
