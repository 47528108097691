/* eslint-disable @typescript-eslint/no-unused-vars */
import { TERIDObject } from 'domains/client/types';

interface IDataInfo {
  check_ERID: TERIDObject;
}

export function isDisableEridToggle(
  dataInfo: IDataInfo | null,
  mode: 'AGENCY' | 'CLIENT' | 'CAMPAIGN' | 'CREATIVE',
): boolean {
  if (dataInfo) {
    let parentsData: TERIDObject | null = null;
    if (mode === 'AGENCY') {
      const { AGENCY, ...parentsEridData } = dataInfo.check_ERID;
      parentsData = parentsEridData;
    }
    if (mode === 'CLIENT') {
      const { CLIENT, ...parentsEridData } = dataInfo.check_ERID;
      parentsData = parentsEridData;
    }
    if (mode === 'CAMPAIGN') {
      const { CAMPAIGN, ...parentsEridData } = dataInfo.check_ERID;
      parentsData = parentsEridData;
    }
    if (mode === 'CREATIVE') {
      const { CREATIVE, ...parentsEridData } = dataInfo.check_ERID;
      parentsData = parentsEridData;
    }

    return Boolean(
      parentsData && Object.values(parentsData).some((item) => !item),
    );
  }
  return false;
}
