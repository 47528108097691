import React, { FC, useEffect, useRef } from 'react';
import cn from 'classnames';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import toLocaleStringSupporte from 'utils/toLocaleStringSupporte';
import { FormikProps } from 'formik';
import { FormProps } from 'domains/agency/ui/Settings/hook/useSettings';
import css from './styles.module.scss';
import ErrorText from '../ErrorText';
import { toolTipType } from '../Input/types';
import Label from '../Input/Label';

interface Props {
  formik?: FormikProps<FormProps>;
  label?: string;
  icon?: JSX.Element;
  error?: boolean | undefined | string;
  placeholder?: string;
  errortext?: string;
  link?: string;
  name?: string;
  value?: number | string;
  onValueChange?: (v: NumberFormatValues) => void;
  disabled?: boolean;
  decimalScale?: number;
  notField?: boolean;
  inCorrect?: string;
  hasZero?: boolean;
  maxLength?: number;
  /** Вывод тултипа со ссылкой */
  tooltip?: toolTipType;
  /** Горизонтальный стиль */
  theme?: 'horizontal';
  isWatchEnter?: boolean;
  setField?: (name: string, value: string | number | undefined) => void;
  isSaved?: boolean;
  errorAbsolte?: boolean;
  allowNegative?: boolean;
  classname?: string;
  onKeyDown?: (keyboardEvent: React.KeyboardEvent) => void;
  handleBlur?: () => void;
}

const Input: FC<Props> = ({
  formik,
  label,
  icon,
  error,
  errortext,
  link,
  name,
  placeholder,
  decimalScale = 2,
  onValueChange,
  handleBlur,
  notField,
  allowNegative = false,
  value,
  inCorrect,
  hasZero,
  maxLength,
  tooltip,
  theme,
  isWatchEnter = false,
  setField,
  isSaved,
  errorAbsolte,
  classname,
  ...rest
}) => {
  const refNumberInput = useRef<NumberFormat>(null);

  const refValueDefault = useRef<string>('');

  useEffect(() => {
    refValueDefault.current = `${value}`;
  }, []);

  return (
    <div>
      <div
        className={cn(css['form-group'], 'PriceInput', classname, {
          error,
          errorAbsolte,
          horizontal: theme === 'horizontal',
        })}
      >
        {label && <Label label={label} forInput="#" tooltip={tooltip} />}
        {notField ? (
          <div className={cn(css.title, { isSaved })}>
            {toLocaleStringSupporte(value, inCorrect)}
          </div>
        ) : (
          <div className={cn(css.body, 'PriceInput_body', { isSaved })}>
            <NumberFormat
              {...rest}
              getInputRef={refNumberInput}
              allowNegative={allowNegative}
              isNumericString
              thousandSeparator
              decimalSeparator="."
              decimalScale={decimalScale}
              fixedDecimalScale
              value={value === '' ? '' : Number(value)}
              name={name}
              maxLength={maxLength}
              placeholder={placeholder}
              onValueChange={onValueChange}
              onKeyUp={(event) => {
                if (theme === 'horizontal') {
                  if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                    if (setField) {
                      if (error && onValueChange) {
                        onValueChange({
                          floatValue: +refValueDefault.current,
                          value: refValueDefault.current,
                          formattedValue: refValueDefault.current,
                        });
                        return;
                      }
                      refValueDefault.current = `${value}`;
                      setField(`${name}`, value);
                    }
                    if (formik) {
                      formik.handleSubmit()
                    }
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    refNumberInput.current?.blur();
                  }
                }
              }}
              onBlur={() => {
                if (handleBlur) {
                  handleBlur();
                }
                if (onValueChange && Number(value) === 0 && !hasZero) {
                  onValueChange({
                    floatValue: undefined,
                    value: '0',
                    formattedValue: '0',
                  });
                }
                if (isWatchEnter && onValueChange) {
                  onValueChange({
                    floatValue: +refValueDefault.current,
                    value: refValueDefault.current,
                    formattedValue: refValueDefault.current,
                  });
                }
              }}
            />
            {icon}
          </div>
        )}

        {link && <div className={css['form-group__link']}>{link}</div>}
      </div>
      {error && errortext && <ErrorText text={errortext} />}
    </div>
  );
};

export default Input;
