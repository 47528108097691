import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Card from 'components/UI/Card';
import Button from 'components/UI/Buttons/buttons';
import Input from 'components/UI/Input/input';
import css from './styles.module.scss';
import BalanceData from '../../../../components/Balance';
import { useUserBalance } from '../../model/hooks/useUserBalance';

const Balance: FC = () => {
  const { t } = useTranslation();
  const {
    isShowBalance,
    handleFetchBalance,
    isLoading,
    balance,
    amount,
    onChangeAmount,
    handleAmount,
  } = useUserBalance();

  return (
    <Card noHover style={{ padding: '28px 22px' }} className={css.balance}>
      <div className={css.header}>
        <div className={css.title}>
          <h3>{t('profile.balance')}</h3>
        </div>
        <div className={css.actions} />
      </div>
      <div className={css.content}>
        <div className={css.item}>
          <div className={css.name}>
            <span>{t('profile.ava_funds')}</span>
          </div>
          {isShowBalance ? (
            <div className={cn(css.value, 'data')}>
              <BalanceData
                value={balance ? balance.balance : null}
                currency={balance ? balance.currency : null}
                isLoading={isLoading}
                white
                classname={css.value__item}
                fetcher={handleFetchBalance}
                revert
              />
            </div>
          ) : (
            <div className={cn(css.value, 'partner')}>
              <span>{t('profile.partner_managed')}</span>
            </div>
          )}
        </div>
      </div>

      {isShowBalance && (
        <div className={css.refill}>
          <Input
            label={t('profile.balance_refill')}
            inputAttributes={{
              value: amount,
              maxLength: 11,
              onChange: onChangeAmount,
            }}
            classname={css.refill__input}
          />
          <Button
            title="Пополнить"
            buttonAttributes={{
              className: css.refill__btn,
              disabled: +amount < 1,
              onClick: handleAmount,
            }}
          />
        </div>
      )}
    </Card>
  );
};

export default Balance;
