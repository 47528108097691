import React, { FC } from 'react';
import cn from 'classnames';
import css from '../../styles.module.scss';
import LoadingRow from './LoadingRow';

interface ILoadingTable {
  extend: boolean;
  full: boolean;
}

const LoadingTable: FC<ILoadingTable> = ({ extend, full }) => (
  <>
    <div className={css.table_header}>
      <div
        className={cn(css.wrapper, {
          extend,
          full,
        })}
      >
        <LoadingRow />
      </div>
    </div>
    <div className={css.table_body}>
      <div className={css.table_row}>
        <LoadingRow />
      </div>
      <div className={css.table_row}>
        <LoadingRow />
      </div>
      <div className={css.table_row}>
        <LoadingRow />
      </div>
    </div>
  </>
);

export default LoadingTable;
