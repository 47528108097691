import { all, call, put, select, take } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
  refreshTokenRequest,
  setUserInfoFinally,
} from '../domains/user/reduser';
import { wssChangeStatus } from '../domains/wss/model/actions';
import { selectorToken } from '../domains/user/model/selectors';
import { diffTimeToken } from './timeToken';

export default function* (): SagaIterator<void> {
  try {
    const data = yield select(selectorToken);
    const isLoading = data.get('isLoading');
    if (isLoading) {
      yield take(setUserInfoFinally);
    }

    const diff = yield call(diffTimeToken);
    if (diff && !isLoading) {
      yield all([put(refreshTokenRequest()), take(setUserInfoFinally)]);
    }
    yield put(wssChangeStatus(true));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield true;
  } catch (err) {
    console.error(err);
  }
}
