import React, { useRef, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { MdKeyboardArrowDown } from 'react-icons/md';
import useDropDown from 'hooks/useDropDown';
import langIcon from 'assets/langicon.svg';
import deIcon from './icons/de.svg';
import ruIcon from './icons/ru.svg';
import enIcon from './icons/en.svg';
import trIcon from './icons/tr.svg';
import css from './styles.module.scss';
import { useLangGroup } from '../../domains/project/hooks';
import { TLang } from '../../domains/project/types';

const LoginLanguage: FC = () => {
  const { lang, langGroup, fetchLang, changeLang } = useLangGroup();

  const dropdownMenu = useRef<HTMLDivElement | null>(null);
  const { toggle, isOpened, toggleOff } = useDropDown(dropdownMenu);

  const { t, i18n } = useTranslation();
  useEffect(() => {
    fetchLang();
  }, []);

  const onChangeLang = (val: TLang) => {
    i18n.changeLanguage(val, () => {
      changeLang(val);
    });
  };

  const getIcon = (val: TLang) => {
    let value = '';

    if (val === 'ru') {
      value = ruIcon;
    }

    if (val === 'en') {
      value = enIcon;
    }

    if (val === 'de') {
      value = deIcon;
    }

    if (val === 'tr') {
      value = trIcon;
    }

    return value;
  };

  const langTitle = () => {
    const langActive = langGroup.find(({ value }) => value === lang);
    if (langActive) {
      return langActive.label;
    }
    return '';
  };

  return (
    <div className={css.language} ref={dropdownMenu}>
      <img src={langIcon} className={css.langIcon} alt="language" />
      <span>{t('login_page.language_tit')} ·</span>
      <span
        className={cn(css.container, {
          isOpened,
        })}
        onClick={isOpened ? toggleOff : toggle}
      >
        <div className={css.btn}>
          <span>{langTitle()}</span>
          <MdKeyboardArrowDown size={24} />
        </div>
        <div className={css.dropdown}>
          {langGroup.map(({ value, label }) => (
            <button
              className={cn(css.item, { isActive: lang === value })}
              key={value}
              onClick={() => onChangeLang(value)}
              type="button"
            >
              <span>{label}</span>
              <img src={getIcon(value)} alt={label} />
            </button>
          ))}
        </div>
      </span>
    </div>
  );
};

export default LoginLanguage;
