import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import Tooltip from 'components/UI/Tooltip';
import i18next from 'i18next';
import css from './styles.module.scss';
import { WSSUserList } from '../../model/selectors';
import randomAvatar from '../../model/utils/random';
import Avatar from './Avatar';
import UsersModal from './Modal';
import { objLiteralWSS, WSSUserInfo } from '../../model/types';
import { useIsError404 } from '../../../project/model/selectors';

type Props = {
  users: WSSUserInfo[];
};

const UserList: FC<Props> = ({ users }) => {
  const { isError } = useIsError404();

  const [openModal, setOpenModal] = useState(false);

  const setModal = () => {
    setOpenModal(!openModal);
  };

  const usersList = () => {
    if (users.length < 5) {
      return users;
    }
    return users.filter((user, index) => index < 3);
  };

  if (isError) {
    return <></>;
  }

  return (
    <div className={css.list}>
      {usersList().map(({ email, type, isActive }) => (
        <Tooltip
          key={email}
          bottom
          title={
            <Avatar
              key={email}
              style={usersList().length === 1 ? { marginLeft: '0px' } : {}}
              online={isActive ? 'online' : 'offline'}
              from={type}
              img={randomAvatar(email.slice(0, 1))}
            />
          }
          white
        >
          <p>
            {email}
            <br /> {i18next.t(`users_activity.${objLiteralWSS[type]}`)}
          </p>
        </Tooltip>
      ))}
      {users.length > 4 && (
        <div className={css.more} onClick={setModal}>
          <span>+{users.length - 3}</span>
        </div>
      )}
      {openModal && <UsersModal users={users} toggleModal={setModal} />}
    </div>
  );
};
export default connect(WSSUserList)(UserList);
