import { shallowEqual, useSelector } from 'react-redux';
import { FormikErrors, FormikProps, useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useUserInfo, useUserTheme } from 'domains/user/model/selectors';
import { useTranslation } from 'react-i18next';
import { AppState } from '../../redux/rootReducer';
import { editPasswordAction, setEditPersonal } from '../user/reduser';
import {
  ParamUserEdit,
  ResponseUser,
  SettingConfig,
  TSettingConfigValue,
} from '../user/types';
import { objectOptimizationWithFormik } from '../../utils/objectOptimizationWithFormik/objectOptimizationWithFormik';
import { NotificationState } from '../notification/reducer';
import { useDispatchApp } from '../../redux/rootSelectors';
import { useLangGroupInfo } from '../project/model/selectors';
import { ProjectState } from '../project/reducer';
import searchFieldIDbyName, {
  getSettingsValue,
} from '../user/model/utils/searhFieldIDbyName';

type ProfileForm = Record<SettingConfig['field'], TSettingConfigValue> & {
  email: ResponseUser['email'];
  group: ResponseUser['group'];
  lang: ResponseUser['lang'];
  passwordData: {
    new_password: string,
    old_password: string,
    confirm_password: string,
  }
};
type TFuseSettingsProfile = (currentTab: number) => {
  account: ResponseUser | null;
  formik: FormikProps<ProfileForm>;
  onSwitchNotification: () => void;
  onSwitchNavigationInCompaign: () => void;
  onSwitchNavigationInCreative: () => void;
  onSwitchGroupsLimits: () => void;
  onSwitcHideHelp: () => void;
  onSwitchAppNotification: () => void;
  onSwitchAnimated: () => void;
  onSwitchHintInCompaign: () => void;
  onSwitchBroadcast: () => void;
  langData: ProjectState['langGroup'];
  defaultLang: (props: ResponseUser['lang']) => ProjectState['langGroup'];
  isErrorBrowserNotification: boolean;
  handlerResetForm: () => void;
  handleEditPassword: () => void;
};

type TTransformUtils = (
  p: SettingConfig[],
) => Record<SettingConfig['field'], TSettingConfigValue>;

const transformUtils: TTransformUtils = (array) => {
  const temp: SettingConfig[] = [...array];
  return temp.reduce((acc, value) => {
    // eslint-disable-next-line no-param-reassign
    acc[value.field] = value.value;
    return acc;
  }, {} as ReturnType<TTransformUtils>);
};

export const useSettingsProfile: TFuseSettingsProfile = (currentTab: number) => {
  const dispatch = useDispatchApp();

  const { data: user } = useUserInfo();

  const { settings } = useUserTheme();

  const { t } = useTranslation();

  const { isErrorBrowserNotification } = useSelector<
    AppState,
    NotificationState
  >(({ notificationReducer }) => notificationReducer, shallowEqual);

  const [lastState, setLastState] = useState<Omit<ProfileForm, 'passwordData'>>({
    email: user?.email || '',
    group: user?.group || [],
    notification: getSettingsValue(settings, 'notification'),
    lang: user?.lang || '',
    theme: getSettingsValue(settings, 'theme') || 'NORMAL',
    app_notification: getSettingsValue(settings, 'app_notification'),
    animation: getSettingsValue(settings, 'animation'),
    HideNavigationCampaign:
      getSettingsValue(settings, 'HideNavigationCampaign'),
    HideNavigationCreative:
      getSettingsValue(settings, 'HideNavigationCreative'),
    groups_limits: getSettingsValue(settings, 'groups_limits'),
    ShowHint: getSettingsValue(settings, 'ShowHint'),
    ShowBroadcastStatus: getSettingsValue(settings, 'ShowBroadcastStatus'),
    HideHelp: getSettingsValue(settings, 'HideHelp'),
  });

  const validate = (values: ProfileForm) => {
    const errors: FormikErrors<ProfileForm> = {};
    if(values.passwordData && currentTab === 1) {
      errors.passwordData = {}

      if (values.passwordData.old_password.length < 6 && values.passwordData.old_password.length !== 0) {
        errors.passwordData.old_password = t('form.common.password_rules');
      }
  
      if (values.passwordData.old_password.length > 99) {
        errors.passwordData.old_password = t('form.common.password_long');
      }

      if (values.passwordData.new_password.length < 6 && values.passwordData.new_password.length !== 0) {
        errors.passwordData.new_password = t('form.common.password_rules');
      }
  
      if (values.passwordData.new_password.length > 99) {
        errors.passwordData.new_password = t('form.common.password_long');
      }
  
      if (values.passwordData.confirm_password !== values.passwordData.new_password) {
        errors.passwordData.confirm_password = t('form.common.passesMiss');
      }
    }
    return errors.passwordData && Object.keys(errors.passwordData).length !== 0 ? errors : {};
  };

  const formik = useFormik<ProfileForm>({
    enableReinitialize: true,
    validate,
    initialValues: {
      email: user?.email || '',
      group: user?.group || [],
      notification: getSettingsValue(settings, 'notification'),
      lang: user?.lang || '',
      theme: getSettingsValue(settings, 'theme'),
      app_notification: getSettingsValue(settings, 'app_notification'),
      animation: getSettingsValue(settings, 'animation'),
      HideNavigationCampaign:
        getSettingsValue(settings, 'HideNavigationCampaign'),
      HideNavigationCreative:
        getSettingsValue(settings, 'HideNavigationCreative'),
      groups_limits: getSettingsValue(settings, 'groups_limits'),
      ShowHint: getSettingsValue(settings, 'ShowHint'),
      ShowBroadcastStatus: getSettingsValue(settings, 'ShowBroadcastStatus'),
      HideHelp: getSettingsValue(settings, 'HideHelp'),
      passwordData: {
        old_password: '',
        new_password: '',
        confirm_password: '',
      }
    },
    onSubmit: (values) => {
      if (user && settings) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {passwordData, ...restParams} = values
        const param = objectOptimizationWithFormik<Omit<ProfileForm, 'passwordData'>>(
          lastState,
          restParams,
        )
        setLastState(restParams)
        if (param) {
          const userParam: ParamUserEdit['user'] = {};
          const userKeys = ['group', 'lang', 'email']
          const designParam: ParamUserEdit['design'] = {};
          const keys: SettingConfig['field'][] = settings.map(item => item.field);
          (
            Object.entries(param) as [keyof ProfileForm, TSettingConfigValue][]
          ).forEach(([key, value]) => {
            if (keys.includes(key as SettingConfig['field'])) {
              designParam[
                searchFieldIDbyName(settings, key as SettingConfig['field'])
              ] = value;
            }
            if (userKeys.includes(key)) {
              userParam[key] = value;
            }
          });
          dispatch(setEditPersonal({ user: userParam, design: designParam }));
        }
      }
    },
  });

  useEffect(() => {
    if (user && settings) {
      formik.setValues({
        lang: user.lang,
        group: user.group,
        email: user.email,
        passwordData: {
          old_password: '',
          new_password: '',
          confirm_password: '',
        },
        ...transformUtils(settings),
      });
    }
  }, [user, settings]);

  const langData = useLangGroupInfo();

  const resetPasswordData = () => {
    formik.setFieldValue('passwordData' ,{
        old_password: '',
        new_password: '',
        confirm_password: '',
      });
    formik.setTouched({}, false);
  }

  useEffect(() => {
    resetPasswordData()
  }, [currentTab])
  

  const handleEditPassword = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {confirm_password, ...restPasswordData} = formik.values.passwordData
    dispatch(editPasswordAction({
      data: restPasswordData,
      callbacks: {
        resetPasswordData,
      }
    }))
  }

  const defaultLang: ReturnType<TFuseSettingsProfile>['defaultLang'] = (lang) =>
    langData.filter((el) => el.value === lang);

  const onSwitchNotification: ReturnType<TFuseSettingsProfile>['onSwitchNotification'] =
    useCallback(() => {
      formik.setFieldValue('notification', !formik.values.notification);
      formik.handleSubmit()
    }, [formik.values.notification]);

  const onSwitchNavigationInCompaign: ReturnType<TFuseSettingsProfile>['onSwitchNavigationInCompaign'] =
    useCallback(() => {
      formik.setFieldValue(
        'HideNavigationCampaign',
        !formik.values.HideNavigationCampaign,
      );
      formik.handleSubmit()
    }, [formik.values.HideNavigationCampaign]);

  const onSwitchNavigationInCreative: ReturnType<TFuseSettingsProfile>['onSwitchNavigationInCreative'] =
    useCallback(() => {
      formik.setFieldValue(
        'HideNavigationCreative',
        !formik.values.HideNavigationCreative,
      );
      formik.handleSubmit()
    }, [formik.values.HideNavigationCreative]);

  const onSwitchGroupsLimits: ReturnType<TFuseSettingsProfile>['onSwitchGroupsLimits'] =
    useCallback(() => {
      formik.setFieldValue('groups_limits', !formik.values.groups_limits);
      formik.handleSubmit()
    }, [formik.values.groups_limits]);

  const onSwitcHideHelp: ReturnType<TFuseSettingsProfile>['onSwitcHideHelp'] =
    useCallback(() => {
      formik.setFieldValue('HideHelp', !formik.values.HideHelp);
      formik.handleSubmit()
    }, [formik.values.HideHelp]);

  const onSwitchHintInCompaign: ReturnType<TFuseSettingsProfile>['onSwitchHintInCompaign'] =
    useCallback(() => {
      formik.setFieldValue('ShowHint', !formik.values.ShowHint);
      formik.handleSubmit()
    }, [formik.values.ShowHint]);

  const onSwitchBroadcast: ReturnType<TFuseSettingsProfile>['onSwitchBroadcast'] =
    useCallback(() => {
      formik.setFieldValue(
        'ShowBroadcastStatus',
        !formik.values.ShowBroadcastStatus,
      );
      formik.handleSubmit()
    }, [formik.values.ShowBroadcastStatus]);

  const onSwitchAnimated: ReturnType<TFuseSettingsProfile>['onSwitchAnimated'] =
    useCallback(() => {
      formik.setFieldValue('animation', !formik.values.animation);
      formik.handleSubmit()
    }, [formik.values.animation]);

  const onSwitchAppNotification: ReturnType<TFuseSettingsProfile>['onSwitchAppNotification'] =
    useCallback(() => {
      formik.setFieldValue('app_notification', !formik.values.app_notification);
      formik.handleSubmit()
    }, [formik.values.app_notification]);

  const handlerResetForm = () => {
    if (user && settings) {
      formik.setValues({
        lang: user.lang,
        group: user.group,
        email: user.email,
        passwordData: {
          old_password: '',
          new_password: '',
          confirm_password: '',
        },
        ...transformUtils(settings),
      });
    }
  };

  return {
    account: user,
    formik,
    onSwitchNotification,
    onSwitchNavigationInCompaign,
    onSwitchNavigationInCreative,
    onSwitchGroupsLimits,
    onSwitcHideHelp,
    onSwitchAppNotification,
    onSwitchAnimated,
    onSwitchHintInCompaign,
    onSwitchBroadcast,
    langData,
    defaultLang,
    isErrorBrowserNotification,
    handlerResetForm,
    handleEditPassword,
  };
};
