import React, { FC, useState } from 'react';
import cn from 'classnames';
import { FiX } from 'react-icons/fi';
import PriceInput from 'components/UI/PriceInput';
import { IAddLimitsModalProp } from 'domains/target/model/types';
import css from './styles.module.scss';
import ModalOverlay from '../../../../components/Modals/ModalOverlay1';
import Button from '../../../../components/UI/Buttons/buttons';
import i18n from '../../../../i18n';
import { useLimitsFormik } from './hooks/useLimitsFormik';

const AddLimitsModal: FC<IAddLimitsModalProp> = ({
  isOpen,
  onCloseModal,
  currentItemData,
  onChangeLimits,
  onRemoveLimits,
  checkItemLimits,
  limits,
}) => {
  const [del, setDel] = useState<boolean>(false);
  const { formik } = useLimitsFormik(
    onChangeLimits,
    currentItemData.key,
    limits,
  );

  return (
    <ModalOverlay onCloseModal={onCloseModal} isOpen={isOpen}>
      <div
        className={cn(css.card, {
          _isDel: del,
        })}
      >
        <form>
          <div className={css.header}>
            <div className={css.title}>
              {del ? (
                <h1>{i18n.t(`targetings.limits.del_set`)}</h1>
              ) : (
                <h1>{i18n.t(`targetings.limits.title`)}: {currentItemData.title}</h1>
              )}
            </div>
            <div className={css.close}>
              <FiX size={24} onClick={onCloseModal} />
            </div>
          </div>

          <div className={css.content}>
            {del ? (
              <>{i18n.t(`targetings.limits.del_set_limit`)} {currentItemData.title}?</>
            ) : (
              <>
                {/*                 <div className={css.row}>
                  <div
                    className={cn(css.col, css.fixWidth, {
                      error: false,
                    })}
                  >
                    <span className={css.title}>Частота</span>
                  </div>
                  <div className={css.col}>
                    <PriceInput
                      theme="horizontal"
                      label="День"
                      placeholder={i18n.t(
                        'campaigns_page.campaign_settings.Please_enter',
                      )}
                      decimalScale={0}
                      hasZero
                      error={formik.errors.show_per_user}
                      value={formik.values.show_per_user?.day}
                      onValueChange={({ floatValue }) => {
                        formik.setFieldValue('show_per_user.day', floatValue);
                      }}
                    />
                  </div>
                  <div className={css.col}>
                    <PriceInput
                      theme="horizontal"
                      label="Всего"
                      placeholder={i18n.t(
                        'campaigns_page.campaign_settings.Please_enter',
                      )}
                      decimalScale={0}
                      hasZero
                      error={formik.errors.show_per_user}
                      value={formik.values.show_per_user?.total}
                      onValueChange={({ floatValue }) => {
                        formik.setFieldValue('show_per_user.total', floatValue);
                      }}
                    />
                  </div>
                </div> */}
                <div className={css.row}>
                  <div
                    className={cn(css.col, css.fixWidth, {
                      error: false,
                    })}
                  >
                    <span className={css.title}>{i18n.t(
                        'campaigns_page.create_campaign.shows',
                      )}</span>
                  </div>
                  <div className={css.col}>
                    <PriceInput
                      theme="horizontal"
                      label={i18n.t(
                        'campaigns_page.create_campaign.day',
                      )}
                      placeholder={i18n.t(
                        'campaigns_page.campaign_settings.Please_enter',
                      )}
                      decimalScale={0}
                      error={formik.errors.show}
                      value={formik.values.show?.day}
                      onValueChange={({ floatValue }) => {
                        formik.setFieldValue('show.day', floatValue);
                      }}
                    />
                  </div>
                  <div className={css.col}>
                    <PriceInput
                      theme="horizontal"
                      label={i18n.t(
                        'campaigns_page.create_campaign.all',
                      )}
                      placeholder={i18n.t(
                        'campaigns_page.campaign_settings.Please_enter',
                      )}
                      decimalScale={0}
                      error={formik.errors.show}
                      value={formik.values.show?.total}
                      onValueChange={({ floatValue }) => {
                        formik.setFieldValue('show.total', floatValue);
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className={css.actions}>
            {del ? (
              <>
                <Button
                  transparent
                  title={i18n.t('cancel_btn')}
                  height={40}
                  autoWidth
                  buttonAttributes={{
                    onClick: () => setDel(false),
                  }}
                />
                <Button
                  title={i18n.t('delete_btn')}
                  height={40}
                  autoWidth
                  buttonAttributes={{
                    onClick: () => {
                      onCloseModal();
                      onRemoveLimits(currentItemData.key);
                    },
                  }}
                />
              </>
            ) : (
              <>
                <Button
                  transparent
                  title={i18n.t(`targetings.limits.del_limits`)}
                  height={40}
                  autoWidth
                  buttonAttributes={{
                    disabled: !checkItemLimits(limits),
                    onClick: () => setDel(true),
                  }}
                />
                <Button
                  title="Ok"
                  height={40}
                  autoWidth
                  buttonAttributes={{
                    disabled: !formik.isValid,
                    onClick: () => {
                      formik.handleSubmit();
                      onCloseModal();
                    },
                  }}
                />
              </>
            )}
          </div>
        </form>
      </div>
    </ModalOverlay>
  );
};

export default AddLimitsModal;
