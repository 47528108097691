import { SagaIterator } from 'redux-saga';
import { put, call, SagaReturnType, select } from 'redux-saga/effects';
import i18next from 'i18next';
import { fetchClients } from 'domains/clients/reducer';
import { ParamClients } from 'domains/clients/types';
import { makeReqWithRD, TMakeReqWithRD } from 'redux/makeReqWithRD';
import { setTempFetchCampaigns } from 'domains/campaigns/reducer';
import { selectorBreadcrumbs } from 'domains/search/model/selectors';
import { setTempBreadcrumbs } from 'domains/search/model/reducer';
import {
  fetchClientEdit,
  fetchClientInfoById,
  fetchCreateClient,
  fetchLoadTempFile,
  ClientState,
  setTempImage,
  setLastAddClient,
  setClientPersonalInfoById,
  setEditClient,
  fetchGroupList,
  setGroupList,
  createGroup,
  deleteGroup,
  editClientSettings,
} from '../reduser';
import updateToken from '../../../utils/updateToken';
import { genFetchedData } from '../../../redux/fetchedData';
import uploadImage, {
  isTUploadErr,
  TResult,
  TUploadErr,
  TUploadFile,
} from '../../../utils/upload';
import {
  addClient,
  editClient,
  getClient,
  getGroupList,
  setGroup,
  removeGroup,
  editGroup,
} from '../api';
import { errors4Image } from '../../modals/linkToImage/sagas/workers';
import { selectorClientsTabs } from '../../storage/model/selectors';
import {
  PAddClient,
  PeditClient,
  RAddClient,
  ResponseInfoClient,
  TGroupList,
} from '../types';
import {
  selectorClientInfo,
  selectorClientTempImage,
} from '../model/selectors';
import { toastSuccess } from '../../../utils/ToastUtils/toastUtils';
import { getUploadFileUrl } from '../../uploads/model/api';

export function* workerCreateClient({
  payload,
}: ReturnType<typeof fetchCreateClient>): SagaIterator<void> {
  let fetchedData = genFetchedData<RAddClient>(null).set('isLoading', true);
  yield put(setLastAddClient(fetchedData));
  try {
    const tempImage: ClientState['tempImage'] = yield select(
      selectorClientTempImage,
    );
    const tImgData = tempImage.get('data');
    const parameters: PAddClient = {
      title: payload.title,
      idAgency: payload.idAgency,
    };
    if (payload.site) {
      parameters.site = payload.site;
    }
    if (payload.site) {
      parameters.site = payload.site;
    }
    if (tImgData !== null) {
      parameters.icon = tImgData.name;
    }
    yield call(updateToken);
    const resp: SagaReturnType<typeof addClient> = yield call(
      addClient,
      parameters,
    );
    fetchedData = fetchedData.set('data', resp);
    toastSuccess(
      `${i18next.t('clients_page.client_create.hint6')}${i18next.t(
        'clients_page.client_create.hint8',
      )}`,
    );

    const status = yield select(selectorClientsTabs);
    const param: ParamClients = {};
    if (status) {
      param.status = status;
    }
    if (payload.idAgency) {
      param.idAgency = payload.idAgency;
    }
    yield put(fetchClients({ ...param }));
  } catch (e) {
    console.error({ e });
    const code = e?.code ?? 0;
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: errors4Image.get(code) ?? e?.message ?? '',
      code,
    });
    yield put(setLastAddClient(fetchedData));
  } finally {
    fetchedData = fetchedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setLastAddClient(fetchedData));
  }
}

export function* workerfetchClientInfoById({
  payload,
}: ReturnType<typeof fetchClientInfoById>): SagaIterator<void> {
  let fetchedData = genFetchedData<ResponseInfoClient>(null).set(
    'isLoading',
    true,
  );
  yield put(setClientPersonalInfoById(fetchedData));
  try {
    yield call(updateToken);
    const resp: SagaReturnType<typeof getClient> = yield call(getClient, {
      id: payload,
    });
    fetchedData = fetchedData.set('data', resp);
  } catch (e) {
    console.error({ e });
    const code = e?.code ?? 0;
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: errors4Image.get(code) ?? e?.message ?? '',
      code,
    });
    yield put(setClientPersonalInfoById(fetchedData));
  } finally {
    fetchedData = fetchedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setClientPersonalInfoById(fetchedData));
  }
}

export function* workerfetchClientEdit({
  payload,
}: ReturnType<typeof fetchClientEdit>): SagaIterator<void> {
  let fetchedData = genFetchedData<boolean>(null).set('isLoading', true);
  yield put(setEditClient(fetchedData));
  try {
    const tempImage: ClientState['tempImage'] = yield select(
      selectorClientTempImage,
    );
    const tImgData = tempImage.get('data');
    yield call(updateToken);
    const { pastValues, setSavedValues, setPastValues } = payload;
    const params = { ...payload.values };
    if (params.remove_icon === true) {
      params.removeIcon = true;
    }
    if (tImgData !== null) {
      params.icon = tImgData.name;
    }
    const resp = yield call(editClient, params);

    let clientData = yield select(selectorClientInfo);
    const clientInfo: PeditClient = clientData.get('data');
    const newClientInfo = { ...clientInfo, title: params.title };
    clientData = clientData.set('data', newClientInfo);
    yield put(setClientPersonalInfoById(clientData));

    let breadcrumbsData = yield select(selectorBreadcrumbs);
    const breadcrumbsInfo = breadcrumbsData.get('data');
    const newBreadcrumbsInfo = {
      ...breadcrumbsInfo,
      Client: { ...breadcrumbsInfo.Client, title: params.title },
    };
    breadcrumbsData = breadcrumbsData.set('data', newBreadcrumbsInfo);
    yield put(setTempBreadcrumbs(breadcrumbsData));

    if (resp && pastValues) {
      if (
        params.reports.use_inherit_settings &&
        pastValues.use_inherit_settings !== params.reports.use_inherit_settings
      ) {
        yield put(fetchClientInfoById(clientInfo.id));
      }
      const keys = Object.keys(pastValues);
      keys.forEach((key) => {
        if (pastValues[key] !== params[key]) {
          setSavedValues({
            [key]: true,
          });
          setTimeout(() => {
            setSavedValues({});
          }, 4000);
        }
      });
    }
    fetchedData = fetchedData.set('data', resp);

    setPastValues({
      use_inherit_settings: params.reports.use_inherit_settings,
      title: params.title,
      site: params.site,
    });
  } catch (e) {
    console.error({ e });
    const code = e?.code ?? 0;
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: errors4Image.get(code) ?? e?.message ?? '',
      code,
    });
    yield put(setEditClient(fetchedData));
  } finally {
    fetchedData = fetchedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setEditClient(fetchedData));
  }
}

export function* workerEditClient({
  payload,
}: ReturnType<typeof editClientSettings>): SagaIterator<void> {
  const { callbacks, ...params } = payload;
  try {
    yield call(updateToken);
    callbacks.setIsLoading(true);
    const resp = yield call(editClient, params);
    if (resp) {
      toastSuccess({
        before: i18next.t(`campaigns_page.campaign_settings.on_edit.hint1`),
        title: i18next.t(`campaigns_page.campaign_settings.additional.method`),
        after: i18next.t(`campaigns_page.campaign_settings.on_edit.hint2`),
      });
    }
  } catch (e) {
    console.error({ e });
  } finally {
    callbacks.setIsLoading(false);
  }
}

export function* workerfetchLoadTempFile({
  payload,
}: ReturnType<typeof fetchLoadTempFile>): SagaIterator<void> {
  let fetchedData = genFetchedData<TUploadFile>(null).set('isLoading', true);
  yield put(setTempImage(fetchedData));
  try {
    if (payload.size > 1000000) {
      const er = { code: -20001, message: '' };
      throw er;
    }
    yield call(updateToken);
    const requestUpload: SagaReturnType<typeof getUploadFileUrl> = yield call(
      getUploadFileUrl,
      { type: 'client' },
    );
    const formData = new FormData();
    formData.set('logo', payload);

    const data: TResult<Record<string, TUploadFile>> | TUploadErr = yield call(
      uploadImage,
      requestUpload,
      formData,
    );
    const error = isTUploadErr(data) ? data.error : undefined;
    const result = isTUploadErr(data) ? undefined : data.result.logo;

    if (error !== undefined) {
      throw error;
    }

    if ((result?.size ?? 0) > 1024) {
      const er = { code: -20001, message: '' };
      throw er;
    }

    if ((result?.width ?? 0) > 2000 || (result?.height ?? 0) > 2000) {
      const er = { code: -20002, message: '' };
      throw er;
    }

    if (result !== undefined) {
      result.size *= 1000;
      fetchedData = fetchedData.set('data', result);
    }
  } catch (e) {
    console.error({ e });
    const code = e?.code ?? 0;
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: errors4Image.get(code) ?? '',
      code,
    });
    yield put(setTempImage(fetchedData));
  } finally {
    fetchedData = fetchedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setTempImage(fetchedData));
  }
}

export function* workerGetGroupList({
  payload,
}: ReturnType<typeof fetchGroupList>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getGroupList>>(makeReqWithRD, {
      fetcher: getGroupList,
      fill: setGroupList,
      parameters: { xxhash: payload.xxhash },
    });
  } catch (e) {
    console.error(e);
  }
}

export function* workerCreateGroupSaga({
  payload,
}: ReturnType<typeof createGroup>): SagaIterator<void> {
  let fetchedData = yield select(
    ({ сlientReducer }) => сlientReducer.groupList,
  );
  try {
    const { callbacks, ...rest } = payload;
    yield call(updateToken);
    let result: string | null = null;
    if (rest.id) {
      result = yield call(editGroup, rest);
    } else {
      result = yield call(setGroup, rest);
    }

    if (result) {
      callbacks?.onClose();
      yield put(fetchGroupList({ xxhash: rest.xxhash }));
      toastSuccess({
        before: `${i18next.t(`clients_page.limit_groups.group`)} `,
        title: ` "${rest.title}" `,
        after: rest.id
          ? ` ${i18next.t(`clients_page.limit_groups.changed`)}`
          : ` ${i18next.t(`clients_page.limit_groups.created`)}`,
      });
    }
  } catch (e) {
    const code = e?.code === 48 ? 1 : e?.code;
    console.error({ e });
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: code ?? '',
      code,
      fields: e.data.fields,
    });
    yield put(setGroupList(fetchedData));
  }
}

export function* workerRemoveGroupSaga({
  payload,
}: ReturnType<typeof deleteGroup>): SagaIterator<void> {
  let fetchedData = yield select(
    ({ campaignsReducer }) => campaignsReducer.tempCampaigns,
  );
  fetchedData = fetchedData.set('isLoading', true);
  yield put(setTempFetchCampaigns(fetchedData));
  try {
    yield call(updateToken);
    const result: boolean = yield call(removeGroup, payload);

    if (result) {
      let groupsList = yield select(
        ({ сlientReducer }) => сlientReducer.groupList,
      );
      const groupData: TGroupList = groupsList.get('data');
      const newGroupData = groupData.filter(
        (item) => item.group.title !== payload.title_list[0],
      );
      groupsList = groupsList.set('data', newGroupData);
      yield put(setGroupList(groupsList));
    }
  } catch (e) {
    const code = e?.code ?? 0;
    console.error({ e });
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: e.message ?? '',
      code,
      fields: e.data.fields,
    });
    yield put(setTempFetchCampaigns(fetchedData));
  } finally {
    fetchedData = fetchedData.set('isLoading', false);
    yield put(setTempFetchCampaigns(fetchedData));
  }
}
