import BaseModal from 'components/UI/BaseModal';
import React, { FC, ReactNode } from 'react';

interface IOfferModalProps {
  onClose: () => void;
  title: string;
  innerText: string | ReactNode;
  actions: ReactNode;
  customWidth?: number;
}

const OfferModal: FC<IOfferModalProps> = ({
  onClose,
  title,
  innerText,
  actions,
  customWidth,
}) => (
  <BaseModal
    onClose={onClose}
    isOpen
    title={title}
    actions={actions}
    customWidth={customWidth}
  >
    {innerText}
  </BaseModal>
);

export default OfferModal;
