/* eslint-disable no-nested-ternary */
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import cn from 'classnames';
import Card from 'components/UI/Card';
import { FormikProvider } from 'formik';
import ToggleSwitch from 'components/UI/ToggleSwitch';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { TID } from 'types/general';
import Help from 'components/Help';
import { useUserTheme } from 'domains/user/model/selectors';
import StatiscticFields from 'components/StatisticFields';
import css from './styles.module.scss';
import i18n from '../../../i18n';
import { item2Opt, useChangeClient } from '../model/hooks';
import Select from '../../../components/UI/SelectV2';
import { useClientInfo } from '../model/selectors';
import { fetchClientInfoById } from '../reduser';
import Loading from './ui/Loading';

const Additional: FC = () => {
  const { data: clientInfo, isLoading } = useClientInfo();
  const dispatch = useDispatch();
  const { params } = useRouteMatch<TID>();
  const { formik, onSwitchInheritSettings, reportsOpt, onChangeReports } =
    useChangeClient();
  const { getValueFromSettingForKey } = useUserTheme();
  

  useEffect(() => {
    dispatch(fetchClientInfoById(params.id));
  }, []);

  return (
    <div className={css.Additional}>
      <Helmet>
        <title>{i18n.t(`clients_page.nav_menu.reports`)}</title>
      </Helmet>
      <div className={cn(css.header)}>
        <div className="container">
          {clientInfo && !isLoading && (
            <Breadcrumbs type="Client" xxhash={clientInfo.id} />
          )}
          <div className={css.status}>
            <h1 className={css.title}>{clientInfo?.title}</h1>
          </div>
        </div>
      </div>
      <div className={css.body}>
        <div className={cn(css.container, 'container')}>
          <Card noHover isUseAccess>
            <h3 className={css.card_title}>
              {i18n.t('campaigns_page.campaign_stat_icon')}
            </h3>
            <StatiscticFields
              descriptionText={i18n.t('clients_page.show_columns')}
              mode="client"
            />
          </Card>
          <>
            {isLoading ? (
              <Card noHover isUseAccess>
                <Loading />
              </Card>
            ) : (
              <>
                <Card noHover isUseAccess>
                  <h3 className={css.card_title}>
                    {i18n.t('clients_page.client_edit.client_edit_reports')}
                    {getValueFromSettingForKey('HideHelp') && (
                      <Help
                        title=""
                        helpattributes={{
                          link: 'https://terratraf.readme.io/docs/td-general-reports',
                        }}
                      />
                    )}
                  </h3>
                  <div className={css.form}>
                    <FormikProvider value={formik}>
                      {!isLoading && (
                        <>
                          <div className={css.reports}>
                            <ToggleSwitch
                              onChange={onSwitchInheritSettings}
                              checked={
                                formik.values.reports.use_inherit_settings
                              }
                              activateText={i18n.t(
                                'clients_page.client_edit.client_edit_use_settings',
                              )}
                              deactivateText={i18n.t(
                                'clients_page.client_edit.client_edit_use_settings',
                              )}
                            />

                            <div
                              className={cn(css.list, {
                                _disabled:
                                  formik.values.reports.use_inherit_settings,
                              })}
                            >
                              {formik.values.reports.items.map((e, i) => (
                                <div className={css.group} key={e.type}>
                                  <span>
                                    {i18n.t(`common.reports.${e.type}`)}
                                  </span>
                                  <Select
                                    value={item2Opt(e)}
                                    customChange={onChangeReports(i)}
                                    placeholder={i18n.t(
                                      'campaigns_page.campaign_settings.additional.ddmm',
                                    )}
                                    options={reportsOpt}
                                    className={css.select}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      )}
                    </FormikProvider>
                  </div>
                </Card>
              </>
            )}
          </>
          <div className={css.widget} />
        </div>
      </div>
    </div>
  );
};

export default Additional;
