export type TWssChangePageAction = {
  path: string;
  location: string;
};

export type WSSDataChangePage = {
  path: string;
  token: string;
  attributes: {
    campaign_xxhash?: string;
    creative_xxhash?: string;
  };
};

export type WSSUser = {
  email: string;
  type: 'client' | 'agency' | 'organization';
};

export const objLiteralWSS: Record<WSSUser['type'], string> = {
  agency: 'agency',
  client: 'client',
  organization: 'organisation',
};

export type WSSUserList = {
  active: WSSUser[];
  notActive: WSSUser[];
};

export type WSSUserInfo = WSSUser & {
  isActive: boolean;
};
