import VideoPlayer from 'components/UI/VideoPlayer';
import React, { FC } from 'react';

type TVideoProps = {
  src?: string;
  poster?: string;
  link?: string;
};

const Video: FC<TVideoProps> = ({ src, poster, link }) => (
  <VideoPlayer poster={poster || ''} src={src || ''} link={link} />
);

export default Video;
