import React, { FC } from 'react';
import { FiDownload } from 'react-icons/fi';
import cn from 'classnames';
import css from './style.module.scss';
import Tooltip from '../../Tooltip';
import Button from '../buttons';
import i18n from '../../../../i18n';

type DownloadButtonProps = {
  onClick: () => void;
  disabled: boolean;
  classname?: string;
};

const DownloadButton: FC<DownloadButtonProps> = ({
  onClick,
  disabled,
  classname,
}) => (
  <div
    className={cn(css.download, classname, {
      disabled,
    })}
  >
    <Tooltip
      hintWidth={150}
      bottom
      isDisabled={disabled}
      title={
        <Button
          title=""
          noBorder
          height={40}
          icon={<FiDownload size={24} />}
          white
          buttonAttributes={{
            className: css.download__btn,
            onClick,
          }}
        />
      }
    >
      <span>{i18n.t('report.download')}</span>
    </Tooltip>
  </div>
);

export default DownloadButton;
