import EditCard from 'components/UI/EditCard';
import { FiUserX, FiUserCheck } from 'react-icons/fi';
import React, { FC, useState } from 'react';
import { MdInfoOutline } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { Field, FieldProps, FormikProvider } from 'formik';
import Checkbox from 'components/UI/Checkbox/checkbox';
import { NamedProps } from 'react-select';
import avatarColors from 'utils/avatarColors';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { SelectField } from 'types/general';
import { TSelectedFieldPartners } from 'domains/project/types';
import { checkedLocalization } from 'utils/checkedLocalization';
import NewSelect from 'components/UI/NewSelect';
import css from './styles.module.scss';
import { useProjectRegistrationEdit } from './hook';

const Registration: FC = () => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);

  const {
    formikContext,
    handleSubmit,
    roles,
    partners,
    partnerValue,
    values,
    project,
    optionFieldsRegistration,
    rolesValue,
    handleResetForm,
  } = useProjectRegistrationEdit({
    setEdit,
  });

  const partnersOptions: NamedProps<
    TSelectedFieldPartners,
    boolean
  >['components'] = {
    Option: ({ innerRef, innerProps, data, children }) => (
      <div ref={innerRef} {...innerProps} className={css.partnersOptions}>
        <div className={css.partnersOptions__title}>
          <div
            className={css.partnersOptions__avatar}
            style={
              data.icon
                ? {
                    backgroundImage: `url(${data.icon})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                  }
                : {
                    backgroundColor: avatarColors(
                      data.label.slice(0, 1).toLowerCase(),
                    ),
                  }
            }
          >
            {data.icon ? '' : data.label.slice(0, 1).toUpperCase()}
          </div>
          <span className={css.partnersOptions__text}>{children}</span>
        </div>
        <span className={css.partnersOptions__id}>
          {data.id} {data.value}
        </span>
      </div>
    ),
    SingleValue: ({ innerProps, data, children }) => (
      <div {...innerProps} className={css.partnersSingleValue}>
        <div className={css.partnersOptions__title}>
          <div
            className={css.partnersOptions__avatar}
            style={
              data.icon
                ? {
                    backgroundImage: `url(${data.icon})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                  }
                : {
                    backgroundColor: avatarColors(
                      data.label.slice(0, 1).toLowerCase(),
                    ),
                  }
            }
          >
            {data.icon ? '' : data.label.slice(0, 1).toUpperCase()}
          </div>
          <span className={css.partnersOptions__text}>{children}</span>
        </div>
        <span className={css.partnersOptions__id}>
          {data.id} {data.value}
        </span>
      </div>
    ),
  };

  const rolesOptions: NamedProps<SelectField, boolean>['components'] = {
    Option: ({ innerProps, innerRef, label, isSelected }) => (
      <div ref={innerRef} {...innerProps} className={css.rolesOptions}>
        <Checkbox
          label={label}
          inputAttributes={{
            name: label,
            type: 'checkbox',
            onChange: () => null,
            checked: isSelected,
          }}
        />
      </div>
    ),
  };

  return (
    <FormikProvider value={formikContext}>
      <EditCard
        className={css.card}
        edited={edit}
        hasErrors={false}
        noHover
        onResetForm={handleResetForm}
        onSave={() => handleSubmit()}
        setEdit={setEdit}
      >
        <h3 className={css.card__title}>
          {t(`project_page.forms.registration_title`)}
        </h3>
        <div className={css.card__form}>
          <div className={css.partner}>
            <h4 className={css.partner__title}>
              {t(`project_page.forms.registration_partner_title`)}
            </h4>
            <Field name="default_registration_partner_xxhash">
              {({ field, form }: FieldProps) =>
                edit ? (
                  <NewSelect
                    form={form}
                    field={field}
                    selectAttributes={{
                      placeholder: t(
                        `project_page.forms.registration_partner_placeholder`,
                      ),
                      options: partners,
                      value: partnerValue,
                      isDisabled: !edit,
                      isClearable: true,
                    }}
                    customOptions={partnersOptions}
                    viewStyle="Vertical"
                  />
                ) : (
                  <div
                    className={cn(css.partnersOptions, {
                      isShow: !edit,
                    })}
                  >
                    {partnerValue[0]?.label ? (
                      <div className={css.partnersOptions__title}>
                        <div
                          className={css.partnersOptions__avatar}
                          style={
                            !!partnerValue.length && partnerValue[0]?.icon
                              ? {
                                  backgroundImage: `url(${partnerValue[0]?.icon})`,
                                  backgroundPosition: 'center',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundSize: 'cover',
                                }
                              : {
                                  backgroundColor: avatarColors(
                                    partnerValue[0]?.label
                                      .slice(0, 1)
                                      .toLowerCase(),
                                  ),
                                }
                          }
                        >
                          {partnerValue[0]?.icon
                            ? ''
                            : partnerValue[0]?.label.slice(0, 1).toUpperCase()}
                        </div>
                        <span className={css.partnersOptions__text}>
                          {partnerValue[0]?.label}
                        </span>
                      </div>
                    ) : t(`project_page.forms.not_selected`)}

                    <span className={css.partnersOptions__id}>
                      {partnerValue[0]?.id} {partnerValue[0]?.value}
                    </span>
                  </div>
                )
              }
            </Field>
            <span className={css.partner__hint}>
              {!partnerValue.length && (
                <span className={css.partner__hint}>
                  <MdInfoOutline size="20" className={css.partner__hint_icon} />
                  {t(`project_page.forms.registration_partner_hint`)}
                </span>
              )}
              {!!partnerValue.length && (
                <>
                  {partnerValue[0].registration ? (
                    <>
                      <FiUserCheck
                        size="20"
                        color="#149C08"
                        className={css.partner__hint_icon}
                      />{' '}
                      {t(`project_page.forms.registration_allowed`)}
                    </>
                  ) : (
                    <>
                      <FiUserX
                        size={24}
                        color="#FF3636"
                        className={css.partner__hint_icon}
                      />{' '}
                      {t(`project_page.forms.registration_prohibited`)}
                    </>
                  )}
                  <span>{t(`project_page.forms.registration_changed`)}</span>
                  <NavLink
                    to="/control_panel/partners"
                    target="_blank"
                    className={css.partner__link}
                  >
                    {t(`project_page.forms.registrations_changed_link`)}
                  </NavLink>
                </>
              )}
            </span>
          </div>
          <div className={css.form_registration}>
            <h4 className={css.form_registration__title}>
              {t(`project_page.forms.registration_form`)}
            </h4>
            <div className={css.form_registration__fields}>
              {project &&
                Object.entries(project.registration_fields).map(([, data]) => (
                  <div
                    className={cn(css.fields__raw, {
                      isViewing: !edit,
                    })}
                    key={data.name}
                  >
                    <div className={css.fields__title}>
                      {checkedLocalization(
                        `form.common.${data.name}`,
                        data.name,
                      )}
                    </div>
                    <Field name={`registration_fields.${data.name}`}>
                      {({ field, form }: FieldProps) =>
                        edit ? (
                          <NewSelect
                            form={form}
                            field={field}
                            selectAttributes={{
                              options: optionFieldsRegistration,
                              value:
                                optionFieldsRegistration.filter(
                                  (item) =>
                                    item.value ===
                                    values.registration_fields[`${data.name}`],
                                ) || [],
                              isDisabled: !edit,
                            }}
                            viewStyle="Vertical"
                          />
                        ) : (
                          <div className={css.fields__show}>
                            {optionFieldsRegistration.map(
                              (item) =>
                                item.value ===
                                  values.registration_fields[
                                    `${data.name}`
                                  ] && (
                                  <span
                                    className={cn({
                                      fields__show_on: item.value !== 'hidden',
                                    })}
                                    key={item.value}
                                  >
                                    {item.label}
                                  </span>
                                ),
                            )}
                          </div>
                        )
                      }
                    </Field>
                  </div>
                ))}
            </div>
          </div>
          <div className={css.roles}>
            <h4 className={css.roles__title}>
              {t(`project_page.forms.registration_rules`)}
            </h4>
            <Field name="registration_groups">
              {({ field, form }: FieldProps) =>
                edit ? (
                  <NewSelect
                    form={form}
                    field={field}
                    selectAttributes={{
                      options: roles || [],
                      isDisabled: !edit,
                      placeholder: t(`creative.create_creative.Please_select`),
                      value: rolesValue,
                      isMulti: true,
                    }}
                    customOptions={rolesOptions}
                    viewStyle="Vertical"
                  />
                ) : (
                  <div
                    className={cn(css.rolesOptions, {
                      isShow: !edit,
                      isEmpty: !rolesValue.length,
                    })}
                  >
                    {rolesValue.length ? (
                      rolesValue.map((role) => (
                        <span
                          className={css.rolesOptions__show}
                          key={role.value}
                        >
                          {role.label}
                        </span>
                      ))
                    ) : (
                      <span>{t(`project_page.forms.not_selected`)}</span>
                    )}
                  </div>
                )
              }
            </Field>
          </div>
        </div>
      </EditCard>
    </FormikProvider>
  );
};
export default Registration;
