import React, { FC, useState } from 'react';
import cn from 'classnames';
import {
  FiDelete,
  FiMoreHorizontal,
  FiTrash2,
} from 'react-icons/fi';
import { ReactSVG } from 'react-svg';
import i18next from 'i18next';
import { useUserTheme } from 'domains/user/model/selectors';
import Load from 'components/UI/Load';
import Translation from 'components/Translation/TranslationTooltip';
import css from '../styles.module.scss';
import OnlyOwner from '../../../../../components/OnlyOwner';
import DuplicateSvg from '../../../../../assets/duplicate-icon.svg';
import CopySvg from '../../../../../assets/copy-icon.svg';
import { PHandlerCloneCreative } from '../../hooks/useCreativesList';
import { CreativeDetail } from '../../../types';
import Tooltip from '../../../../../components/UI/Tooltip';
import { useDataCreativesTranslation } from '../../../model/hooks/useDataCreativesTranslation';

type Props = {
  xxhash: string;
  campaign_xxhash: string;
  setToggle: (p: Props['xxhash']) => void;
  setOpenCopyModal: (p: Props['xxhash']) => void;
  setOpenCleanModal: (p: Props['xxhash']) => void;
  // setOpenStatisticsModal: (p: boolean) => void;
  setOpenDeleteModal: (p: Props['xxhash']) => void;
  toggle: string;
  /** функция дублирования креатива */
  handlerCloneCreative: (P: PHandlerCloneCreative) => void;
  type: CreativeDetail['type'];
};

const Options: FC<Props> = ({
  xxhash,
  campaign_xxhash,
  setToggle,
  // setOpenStatisticsModal,
  setOpenCleanModal,
  setOpenCopyModal,
  setOpenDeleteModal,
  toggle,
  handlerCloneCreative,
  type,
}) => {
  const [disableDuplicateCreativeBtn, setDisableDuplicateCreativeBtn] =
    useState(false);
  const { getValueFromSettingForKey } = useUserTheme();
  const { translation, isError } = useDataCreativesTranslation();
  const getTranslation = () => translation && translation[xxhash];
  const checkIsErrorToShowLoadOrToltip = () =>
    isError ? (
      <Translation data={translation && translation[xxhash]} error={isError}/>
    ) : (
      <Load style={{ width: '30px', margin: '0 auto' }} />
    );
  return (
    <div className="options">
      <div className={css.actions}>
        {getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
          !getValueFromSettingForKey('ShowBroadcastStatus') &&
          (translation ? (
            <Translation
              data={getTranslation()}
              toggle={toggle}
              xxhash={xxhash}
            />
          ) : (
            checkIsErrorToShowLoadOrToltip()
          ))}
        <div
          className={cn(css.dropdown, {
            isActive: toggle === xxhash,
            _isNoAnimate: getValueFromSettingForKey('animation'),
          })}
          onMouseEnter={() => setToggle(xxhash)}
          onMouseLeave={() => setToggle('')}
        >
          <button type="button" className={css.dropdown__show}>
            <FiMoreHorizontal size={24} />
          </button>

          <div className={css.dropdown__menu}>
            <OnlyOwner>
              <Tooltip
                onClick={() => setOpenCleanModal(xxhash)}
                title={<FiDelete size={24} />}
              >
                <span>{i18next.t('creative.cleartarg')}</span>
              </Tooltip>
            </OnlyOwner>
            <OnlyOwner>
              <Tooltip
                isDisabled={type === 'YANDEX' || disableDuplicateCreativeBtn}
                onClick={() => {
                  setDisableDuplicateCreativeBtn(true);
                  handlerCloneCreative({ xxhash, campaign_xxhash });
                }}
                title={<ReactSVG src={DuplicateSvg} />}
              >
                <span>{i18next.t('duplicate_btn')}</span>
              </Tooltip>
            </OnlyOwner>

            <OnlyOwner>
              <Tooltip
                isDisabled={type === 'YANDEX'}
                onClick={() => setOpenCopyModal(xxhash)}
                title={<ReactSVG src={CopySvg} />}
              >
                <span>{i18next.t('copy_to_another_campaign_btn')}</span>
              </Tooltip>
            </OnlyOwner>

            <OnlyOwner>
              <Tooltip
                isDisabled={type === 'YANDEX'}
                onClick={() => setOpenDeleteModal(xxhash)}
                title={<FiTrash2 size={24} />}
              >
                <span>{i18next.t('delete_btn')}</span>
              </Tooltip>
            </OnlyOwner>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Options;
