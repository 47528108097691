import React, { FC } from 'react';
import { components } from 'react-select';
import { Virtuoso } from 'react-virtuoso';

const index: FC = (props: any) => {
  if (props.focusedOption === undefined) {
    return (
      <components.ValueContainer {...props}>
        {props.children}
      </components.ValueContainer>
    );
  }

  return (
    <Virtuoso
      style={{ height: '200px' }}
      data={props.children}
      totalCount={props.children.length}
      itemContent={(i) => props.children[i]}
    />
  );
};

export default index;
