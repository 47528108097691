import { useEffect, useState } from 'react';
import { FormikContextType, FormikProps, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormikErrors } from 'formik/dist/types';
import { useDispatch } from 'react-redux';
import { useAuthResponseData } from 'domains/user/model/selectors';
import { AppDispatch } from '../../../redux/rootReducer';
import { forGotPasswordRequest } from '../../user/reduser';
import { getError } from '../../user/helpers';

type TFormForget = {
  email: string;
};
type RUseForgotPasswordDetail = FormikProps<TFormForget> & {
  formikContext: FormikContextType<TFormForget>;
  confirm: boolean | string;
};

export const useForgotPasswordDetail = (): RUseForgotPasswordDetail => {
  const { i18n } = useTranslation();
  const [confirm, setConfirm] = useState<string | boolean>(false);

  const { error } = useAuthResponseData();

  useEffect(() => {
    if (error.isError) {
      setConfirm(() => getError(error));
    }
    return () => {
      setConfirm(() => false);
    };
  }, [error, i18n.language]);

  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const validate = (values: TFormForget) => {
    const errors: FormikErrors<TFormForget> = {};

    if (!values.email) {
      errors.email = t('form.common.enter_email');
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = t('form.common.wrong_format');
    }

    return errors;
  };

  const formik = useFormik<TFormForget>({
    initialValues: {
      email: '',
    },
    onSubmit: ({ email }) => {
      if (email) {
        dispatch(
          forGotPasswordRequest({ email, domain: window.location.host }),
        );
      }
    },
    validate,
  });

  useEffect(() => {
    if (!formik.isValid) {
      formik.validateForm();
    }
  }, [i18n.language]);

  return {
    confirm,
    formikContext: formik,
    ...formik,
  };
};
