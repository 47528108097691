import React, { ChangeEvent, FC, useEffect, useMemo } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import cn from 'classnames';
import Loader from 'components/UI/Loader';
import { FiCheck, FiSearch, FiX } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Button from 'components/UI/Buttons/buttons';
import { useAppNotification, useUserInfo } from 'domains/user/model/selectors';
import { useCampaignInfo } from 'domains/campaign/model/selectors';
import { fetchModalBreadcrumbs } from 'domains/search/model/actions';
import { featchAllCreativeTypesAction } from 'domains/creative/reduser';
import Checkbox from 'components/UI/Checkbox/checkbox';
import { RCopyTarget } from '../../domains/target/model/types';
import Client from './Item/Client';
import Creative from './Item/Creative';
import Campaign from './Item/Campaign';
import useTargets, {
  GeneralType,
  isGardCampaign,
  isGardClient,
  isGardCreative,
  isGardTypeOnClick,
} from './useTargets';
import Breadcrumbs from './Breadcrumbs';
import useClone from './useClone';
import css from './styles.module.scss';
import Error from './Error';
import { fetchClients } from '../../domains/clients/reducer';
import { useAgenciesInfo } from '../../domains/agencies/model/selectors';
import { THASH, TID } from '../../types/general';
import CampaignToggle from './Item/CampaignToggle';
import { TBreadcrumbsTarget } from './types';
import ModalOverlay from '../Modals/ModalOverlay1';
import { useDispatchApp } from '../../redux/rootSelectors';
import {
  useCreativeDetailInfo,
  useCreativeListModalInfo,
} from '../../domains/creative/model/selectors';
import { useClientsInfo } from '../../domains/clients/model/selectors';
import { useSearchModalBreadcrumbs } from '../../domains/search/model/selectors';
import { setTempModalBreadcrumbsDefault } from '../../domains/search/model/reducer';
import { fetchCreativeListModal } from '../../domains/creative/model/actions';
import { fetchCampaignsListModal } from '../../domains/campaigns/model/actions';
import { useCampaignsListModalInfo } from '../../domains/campaigns/model/selectors';

type Props = {
  id: string;
  onClose: () => void;
  isCampaign?: boolean;
};

const CopyTargetingsModal: FC<Props> = ({
  onClose,
  id,
  isCampaign = false,
}) => {
  const dispatch = useDispatchApp();
  const { data: modalBreadcrumbs, LTU } = useSearchModalBreadcrumbs();

  const { id: idCreative } = useRouteMatch<TID>().params;

  const { data: creativeDetail } = useCreativeDetailInfo();

  const { data: campaign } = useCampaignInfo();

  const { data: user } = useUserInfo();

  const isAppNotification = useAppNotification();
  const {
    data: clientsGeneral,
    isLoading: isLoadingClients,
    LTU: clientsLTU,
  } = useClientsInfo();
  const {
    data: campaignsGeneral,
    isLoading: isLoadingCampaigns,
    LTU: campaignsLTU,
  } = useCampaignsListModalInfo();
  const {
    data: agenciesGeneral,
    isLoading: isLoadingAgencies,
    LTU: agenciesLTU,
  } = useAgenciesInfo();

  const {
    data: creativesGeneral,
    isLoading: isLoadingCreatives,
    LTU: creativesLTU,
  } = useCreativeListModalInfo();

  const agencies =
    agenciesGeneral &&
    agenciesGeneral.filter(
      (camp) => camp.status === 'LAUNCHED' || camp.status === 'STOPPED',
    );

  const campaigns =
    campaignsGeneral &&
    campaignsGeneral.filter((camp) => {
      if (isCampaign) {
        return (
          (camp.status === 'LAUNCHED' || camp.status === 'STOPPED') &&
          camp.xxhash !== idCreative
        );
      }
      return camp.status === 'LAUNCHED' || camp.status === 'STOPPED';
    });

  const clients =
    clientsGeneral &&
    clientsGeneral.filter(
      (cli) => cli.status === 'STOPPED' || cli.status === 'LAUNCHED',
    );

  const creatives =
    creativesGeneral &&
    creativesGeneral.filter((creo) => creo.xxhash !== idCreative);

  const breadcrumbs = useMemo<TBreadcrumbsTarget[]>(() => {
    let arLinks: TBreadcrumbsTarget[] = [];
    let keys = (modalBreadcrumbs && Object.keys(modalBreadcrumbs)) || [];
    if (user) {
      const keyIndex = keys.findIndex(
        (key) =>
          key.toLocaleLowerCase() === user.partner.type.toLocaleLowerCase(),
      );
      if (keyIndex + 1) {
        keys = keys.slice(keyIndex, keys.length);
      }
    }
    if (keys && modalBreadcrumbs) {
      keys.forEach((key) => {
        if (key === 'Organization') {
          arLinks = [
            {
              title: modalBreadcrumbs.Organization.title,
              xxhash: modalBreadcrumbs.Organization.xxhash,
              type: key,
            },
          ];
        }
        if (key === 'Agency') {
          arLinks = [
            ...arLinks,
            {
              title: modalBreadcrumbs.Agency.title,
              xxhash: modalBreadcrumbs.Agency.xxhash,
              type: key,
            },
          ];
        }
        if (key === 'Client') {
          arLinks = [
            ...arLinks,
            {
              title: modalBreadcrumbs.Client.title,
              xxhash: modalBreadcrumbs.Client.xxhash,
              type: key,
            },
          ];
        }
        if (key === 'Campaign' && !isCampaign) {
          arLinks = [
            ...arLinks,
            {
              title: modalBreadcrumbs.Campaign.title,
              xxhash: modalBreadcrumbs.Campaign.xxhash,
              type: key,
            },
          ];
        }
      });
    }
    return arLinks;
  }, [LTU, user]);

  const {
    onRequestClone,
    copying,
    setCopying,
    progress,
    warning,
    onRequestForcedClone,
    setIsCopyPretargets,
    isCopyPretargets,
    warnList,
  } = useClone();

  const {
    data,
    setStep,
    step,
    isActives,
    setIsActives,
    search,
    setSearch,
    handlerToggleAll,
    getCheckedCount,
  } = useTargets({
    campaigns,
    clients,
    creatives,
    agencies,
    isCampaign,
    agenciesLTU,
    creativesLTU,
    clientsLTU,
    campaignsLTU,
  });

  useEffect(() => {
    dispatch(fetchModalBreadcrumbs({ xxhash: id }));

    if (creativeDetail && !isCampaign) {
      dispatch(featchAllCreativeTypesAction());
      dispatch(
        fetchCreativeListModal({
          campaign_xxhash: creativeDetail.campaign_xxhash,
        }),
      );
    }

    if (user && user.partner.type === 'AGENCY') {
      dispatch(fetchClients({ status: null, idAgency: null }));
    }

    return () => {
      dispatch(setTempModalBreadcrumbsDefault());
    };
  }, []);

  useEffect(() => {
    if (modalBreadcrumbs?.Client && isCampaign) {
      dispatch(
        fetchCampaignsListModal({
          partner_xxhash: modalBreadcrumbs.Client.xxhash,
        }),
      );
    }
  }, [modalBreadcrumbs]);

  const { t } = useTranslation();

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onClick = ({
    type,
    params,
  }: {
    type: string;
    params:
      | { campaign_id: string }
      | { id: string }
      | { partner_id: string }
      | { campaign_xxhash: string };
  }) => {
    setSearch('');

    if (type === 'campaigns') {
      dispatch(
        fetchCampaignsListModal({
          ...params,
          status: '',
        }),
      );
      setStep(type);
      return;
    }

    if (type === 'creatives' && isGardTypeOnClick(params)) {
      dispatch(fetchModalBreadcrumbs({ xxhash: params.campaign_xxhash }));
      dispatch(fetchCreativeListModal(params));
      setStep(type);
      return;
    }

    setStep('clients');
  };
  const handlerParentClick = (param: THASH, type: 'CLIENT' | 'AGENCY') => {
    setStep(type === 'AGENCY' ? 'clients' : 'campaigns');
    if (type === 'AGENCY') {
      dispatch(fetchClients({ idAgency: param.xxhash }));
    }
    if (type === 'CLIENT') {
      dispatch(
        fetchCampaignsListModal({
          partner_xxhash: param.xxhash,
          status: '',
        }),
      );
    }
    dispatch(fetchModalBreadcrumbs(param));
  };

  const getLoading = () => {
    if (step === 'agencies') {
      return !isLoadingAgencies;
    }
    if (step === 'campaigns') {
      return !isLoadingCampaigns;
    }

    if (step === 'creatives') {
      return !isLoadingCreatives;
    }

    return !isLoadingClients;
  };

  const renderData = (): JSX.Element => {
    if (!data.length) return <p>{t('search_none.none_1')}</p>;

    return (
      <>
        {/* eslint-disable-next-line array-callback-return,consistent-return */}
        {data.map((e: GeneralType) => {
          if ((step === 'clients' || step === 'agencies') && isGardClient(e)) {
            return (
              <Client
                key={e.id}
                client={e}
                type={e.type as 'AGENCY' | 'CLIENT'}
                handleClick={handlerParentClick}
              />
            );
          }

          if (step === 'campaigns' && isGardCampaign(e) && !isCampaign) {
            return (
              <Campaign key={e.xxhash} campaignProps={e} onClick={onClick} />
            );
          }
          if (step === 'campaigns' && isGardCampaign(e) && isCampaign) {
            return (
              <CampaignToggle
                key={e.xxhash}
                campaign={e}
                isActives={isActives}
                setIsActives={setIsActives}
              />
            );
          }

          if (isGardCreative(e) && !isCampaign) {
            return (
              <Creative
                key={e.xxhash}
                creative={e}
                isActives={isActives}
                setIsActives={setIsActives}
              />
            );
          }
        })}
      </>
    );
  };

  const onCopyClick = async () => {
    setCopying(true);

    const response = await onRequestClone({
      from_xxhash: id,
      to_xxhash_list: isActives.map(({ xxhash }) => xxhash),
    });

    if (response && response.success.length > 0) {
      if (!response.errors.length) {
        onClose();
      }
      if (isAppNotification) {
        toast(<ToastSuccess ids={response.success} copyPretargets={isCopyPretargets}/>, {
          className: 'wide',
          type: 'success',
          delay: 500,
          autoClose: 10000,
        });
      }
    }
  };

  const onForcedClone = async () => {
    const response = await onRequestForcedClone({
      from_xxhash: id,
      to_xxhash_list: warnList.map((e) => e.xxhash),
    });

    setIsActives([]);
    if (response && response.length) {
      onClose();
      if (isAppNotification) {
        toast(<ToastSuccess ids={response} copyPretargets={isCopyPretargets}/>, {
          className: 'wide',
          type: 'success',
          delay: 500,
          autoClose: 10000,
        });
      }
      return;
    }

    if (isAppNotification) {
      toast(<ToastError />, {
        type: 'error',
        delay: 500,
        autoClose: 5000,
      });
    }
  };

  const ToastSuccess = ({ ids, copyPretargets }: { ids: RCopyTarget[], copyPretargets: boolean }) => {
    const filtredCreatives =
      isActives &&
      isActives.filter(({ xxhash }) => ids.some((el) => xxhash === el.xxhash));

    return (
      <div className={css.toast}>
        <div className={cn(css.icon, '_isSuccess')}>
          <FiCheck size={16} color="#fff" />
        </div>
        {!isCampaign && (
          <div className={css.text}>
            {copyPretargets ? t('creative.copy_creative.copy_set.t5_2') : t('creative.copy_creative.copy_set.t5')}{' '}
            <b>{creativeDetail && `"${creativeDetail.title}"`}</b>{' '}
            {t('creative.copy_creative.copy_set.t6')}
            <div className={css.links}>
              {filtredCreatives &&
                filtredCreatives.map((e, index) => (
                  <Link
                    to={`/main/campaign/${e.campaign_xxhash}/creatives/${e.xxhash}/targetings`}
                    key={e.xxhash}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <strong>{e.title}</strong>
                    {filtredCreatives &&
                      filtredCreatives.length - 1 !== index && <span>, </span>}
                  </Link>
                ))}
            </div>
          </div>
        )}
        {isCampaign && (
          <div className={css.text}>
            {copyPretargets ? t('campaigns_page.copy_campaign.t1_2') : t('campaigns_page.copy_campaign.t1')}{' '}
            <b>{campaign && `"${campaign.title}"`}</b>{' '}
            {t('campaigns_page.copy_campaign.t2')}
            <div className={css.links}>
              {filtredCreatives &&
                filtredCreatives.map((e, index) => (
                  <Link
                    to={`/main/campaign/${e.xxhash}/target`}
                    key={e.xxhash}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <strong>{e.title}</strong>
                    {filtredCreatives &&
                      filtredCreatives.length - 1 !== index && <span>, </span>}
                  </Link>
                ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const ToastError = () => (
    <div className={css.toast}>
      <div className={cn(css.icon, '_isError')}>
        <FiX size={16} color="#fff" />
      </div>
      <div className={cn(css.text, '_isError')}>
        {t(`creative.copy_creative.errors.err1`)}
      </div>
    </div>
  );

  const getHeader = () => {
    if (warning) {
      return (
        <div className={css.title}>
          <h1> {t(`creative.copy_creative.errors.tit`)}</h1>
          <p>{t(`creative.copy_creative.errors.text`)}</p>
        </div>
      );
    }

    return (
      <div className={css.title}>
        <h1>
          {isCampaign
            ? t(`targetings.copy_modal.campaign`)
            : t(`targetings.copy_modal.creo`)}
        </h1>
        {modalBreadcrumbs &&
          (isCampaign ? (
            <div className={css.name}>
              {`[${campaign?.internal_id}]`} {campaign?.title}
            </div>
          ) : (
            <div className={css.name}>
              {`${campaign?.title} / ${`[${creativeDetail?.internal_id}]`} ${
                creativeDetail?.title
              }`}
            </div>
          ))}
        <p>
          {isCampaign
            ? t('campaigns_page.copy_campaign.t3')
            : t('creative.copy_creative.copy_set.t2')}
        </p>
      </div>
    );
  };

  return (
    <ModalOverlay onCloseModal={onClose} isOpen>
      <div className={css.card}>
        <div
          className={cn(css.copying, {
            isActive: copying,
          })}
        >
          <div className={css.title}>
            {t('creative.copy_creative.copy_set.t3')}
          </div>
          <div className={css.progress}>
            <div className={css.bar} style={{ width: `${progress}%` }} />
          </div>
          <div className={css.hint}>
            {t('creative.copy_creative.copy_set.t4')}
          </div>
        </div>

        <div className={css.form}>
          <div className={css.header}>
            {getHeader()}
            <div className={css.close}>
              <FiX size={24} onClick={onClose} />
            </div>
          </div>

          {warning ? (
            <div className={css.content}>
              <div className={css.errors}>
                <div className={css.list}>
                  {warnList.length > 0 &&
                    warnList.map((e) => {
                      const findTitle = isActives.some(
                        ({ xxhash }) => xxhash === e.xxhash,
                      )
                        ? isActives.filter(
                            ({ xxhash }) => xxhash === e.xxhash,
                          )[0].title
                        : e.xxhash;
                      return (
                        <Error
                          title={findTitle}
                          text={e.message}
                          data={e.data}
                          key={e.xxhash}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          ) : (
            <div className={css.content}>
              <div className={css.breadcrumbs}>
                <Breadcrumbs setStep={setStep} breadCrumbs={breadcrumbs} />
              </div>
              <div className={css.select}>
                {((step === 'campaigns' && isCampaign) ||
                  (step === 'creatives' && !isCampaign)) && (
                  <Checkbox
                    className={css.select_checkbox}
                    label={`${t(
                      'campaigns_page.campaign_settings.additional.statistics.selected',
                    )} ${getCheckedCount().checkedCount} ${t(
                      'campaigns_page.campaign_settings.additional.statistics.of',
                    )} ${getCheckedCount().allItemsCount}`}
                    isPartiallyChecked={
                      getCheckedCount().checkedCount !== 0 &&
                      getCheckedCount().checkedCount !==
                        getCheckedCount().allItemsCount
                    }
                    inputAttributes={{
                      type: 'checkbox',
                      checked:
                        getCheckedCount().allItemsCount !== 0 &&
                        getCheckedCount().checkedCount ===
                          getCheckedCount().allItemsCount,
                      onChange: handlerToggleAll,
                    }}
                  />
                )}
                <div className={css.search}>
                  <label htmlFor="search-targeting">
                    <input
                      id="search-targeting"
                      type="text"
                      placeholder={`${t('targetings.search')}...`}
                      autoComplete="off"
                      onChange={onSearchChange}
                      value={search}
                    />
                    <div className={css.search_icon}>
                      <FiSearch size={24} />
                    </div>
                  </label>
                </div>
              </div>
              <div className={css.list}>
                {getLoading() ? renderData() : <Loader />}
              </div>
            </div>
          )}

          <div className={css.actions}>
            <Checkbox
              label={t('creative.copy_creative.copy_set.t7')}
              className={css.checkbox_pretarget}
              inputAttributes={{
                type: 'checkbox',
                checked: isCopyPretargets,
                onChange: () => setIsCopyPretargets((prev) => !prev),
              }}
            />
            <Button
              transparent
              title={t('cancel_btn')}
              height={40}
              autoWidth
              buttonAttributes={{
                onClick: onClose,
              }}
            />
            {!warning && (
              <Button
                title={t('copy_btn')}
                height={40}
                autoWidth
                buttonAttributes={{
                  onClick: onCopyClick,
                  disabled: !isActives.length,
                }}
              />
            )}
            {warning && (
              <Button
                title={t('continue_btn')}
                height={40}
                autoWidth
                buttonAttributes={{
                  onClick: onForcedClone,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </ModalOverlay>
  );
};

export default CopyTargetingsModal;
