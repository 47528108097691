import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import { workerCreateContract, workerGetContract, workerSendErir, workerUpdateContract } from './workers';
import { createContract, getContract, sendErir, updateContract } from '../actions';

export default function* watchContract(): SagaIterator {
  yield takeLatest(getContract, workerGetContract);
  yield takeLatest(createContract, workerCreateContract);
  yield takeLatest(updateContract, workerUpdateContract);
  yield takeLatest(sendErir, workerSendErir);
}
