import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { VariableSizeList } from 'react-window';
import { FiRefreshCcw, FiX } from 'react-icons/fi';
import { checkedLocalization } from 'utils/checkedLocalization';
import Tooltip from 'components/UI/Tooltip';
import Loader from 'components/UI/Loader';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Help from 'components/Help';
import ErrorText from 'components/UI/ErrorText';
import css from './styles.module.scss';
import ModalOverlay from '../../../../components/Modals/ModalOverlay1';
import Dropdown from '../Dropdown';
import Checkbox from '../../../../components/UI/Checkbox/checkbox';
import Button from '../../../../components/UI/Buttons/buttons';
import { RGetSettings } from '../../model/types';
import { useCheckBoxM } from './hook/useCheckBoxM';

const itemKey = (i: number, d: RGetSettings['catalog']) => d[i].key + i;
type TmapCatalog = {
  key: string;
  title: string;
  additional?: string[];
  hight: number;
  toggle: boolean;
  selected: boolean;
  sort: number;
};
export type TRenderRow = {
  index: number;
  style: React.CSSProperties;
  data: TmapCatalog[];
};
type PCheckBoxM = {
  /** открыто */
  isOpen: boolean;
  /** при закрытии */
  onCloseModal: () => void;
  /** тип таргета */
  type: string;
  /** католог */
  catalog: RGetSettings['catalog'];
  /** католог в виде дерева */
  isFree: boolean;
  /** вызывается по кнопки добавить */
  onHandleSubmit: (v: { key: string; title: string }[]) => void;
  isUpdateTarget?: boolean | null;
  campaign_xxhash: string;
};

export default function CheckBoxM({
  isOpen,
  onCloseModal,
  type,
  isFree,
  catalog,
  campaign_xxhash,
  isUpdateTarget,
  onHandleSubmit,
}: PCheckBoxM): JSX.Element {
  const { t } = useTranslation();

  const isAu = type === 'au';

  const {
    search,
    changeSearch,
    getItemSize,
    mapCatalogF,
    refList,
    onSubmit,
    onToggle,
    onOpen,
    onCheked,
    onUpdateAu,
    isLoadingUpdateAu,
    value,
    notFound,
    onChange,
  } = useCheckBoxM({
    catalog,
    onHandleSubmit,
    type,
    isOpen,
    campaign_xxhash,
    onCloseModal,
  });

  const RenderRow: FC<TRenderRow> = useCallback(
    ({ index, style, data }) => {
      const { toggle, title, key, selected, additional } = data[index];

      if (isFree && title !== 'OTHER') {
        return (
          <Dropdown
            key={key}
            style={style}
            className={cn(css.item, 'context')}
            onToggle={onToggle(index)}
            toggle={toggle}
            onClick={onOpen(index, !toggle)}
            title={
              <Checkbox
                label={title}
                inputAttributes={{
                  name: key,
                  type: 'checkbox',
                  onChange: onCheked,
                  checked: selected,
                }}
              />
            }
          >
            <div>
              {additional?.map((text) => (
                <span key={text}>{text}, </span>
              ))}
            </div>
          </Dropdown>
        );
      }
      return (
        <div className={css.item} key={key} style={style}>
          <Checkbox
            label={title}
            inputAttributes={{
              name: key,
              type: 'checkbox',
              onChange: onCheked,
              checked: selected,
            }}
          />
        </div>
      );
    },
    [isOpen, isFree],
  );

  return (
    <ModalOverlay onCloseModal={onCloseModal} isOpen={isOpen}>
      <div className={css.card}>
        <form>
          <div className={css.header}>
            <div className={css.title}>
              <h1>
                {checkedLocalization(
                  `targetings.add_popap_tit.${type}`,
                  `targetings.add_popap_tit.general`,
                )}
              </h1>
            </div>
            <div className={css.close}>
              <FiX size={24} onClick={onCloseModal} />
            </div>
          </div>
          <div className={css.tab_container}>
            <Tabs className={css.tabs}>
              <TabList className={css.tablist}>
                <Tab>{t(`analysis_page.select_list`)}</Tab>
                {isAu && <Tab>{t(`analysis_page.add_list`)}</Tab>}
              </TabList>

              <TabPanel className={css.panel}>
                <div className={css.content}>
                  <div className={css.search_wrapper}>
                    <div className={css.search}>
                      <label htmlFor="search">
                        <input
                          id="search"
                          type="search"
                          placeholder={`${t('targetings.search')}...`}
                          autoComplete="off"
                          value={search}
                          onChange={changeSearch}
                        />
                      </label>
                    </div>
                    {isAu && isUpdateTarget && (
                      <Tooltip
                        title={<FiRefreshCcw size={20} />}
                        onClick={onUpdateAu}
                        className={cn(css.update_btn, {
                          _isDisabled: isLoadingUpdateAu,
                        })}
                      >
                        {t('targetings.update_guide')}
                      </Tooltip>
                    )}
                  </div>
                </div>
                {isLoadingUpdateAu ? (
                  <Loader />
                ) : (
                  <VariableSizeList
                    className={css.list}
                    itemSize={getItemSize}
                    height={365}
                    itemCount={mapCatalogF.length}
                    itemData={mapCatalogF}
                    layout="vertical"
                    width="100%"
                    itemKey={itemKey}
                    ref={refList}
                  >
                    {RenderRow}
                  </VariableSizeList>
                )}
              </TabPanel>
              {isAu && (
                <TabPanel className={css.panel}>
                  <div className={css.add_elem}>
                    <div className={css.add_elemLabel}>
                      <span>{t(`targetings.au.enter_au`)}</span>
                      <Help
                        title=""
                        helpattributes={{
                          text: t(`targetings.au.add_several`),
                        }}
                      />
                    </div>
                    {isUpdateTarget && (
                      <Tooltip
                        title={<FiRefreshCcw size={20} />}
                        onClick={onUpdateAu}
                        className={cn(css.update_btn, {
                          _isDisabled: isLoadingUpdateAu,
                        })}
                      >
                        {t('targetings.update_guide')}
                      </Tooltip>
                    )}
                  </div>
                  <div className={css.textarea}>
                    <textarea
                      className={css.field}
                      value={value}
                      onChange={onChange}
                    />
                  </div>
                  {!!notFound.length && (
                    <div className={css.errorBox}>
                      <ErrorText
                        text={
                          <div className={css.errorText}>
                           {`${t(`targetings.au.following_au`)} `}
                            <span>{notFound.join(', ')}</span>
                            <div>
                            {t(`targetings.au.au_numbers`)}
                            </div>
                          </div>
                        }
                      />
                    </div>
                  )}
                </TabPanel>
              )}
            </Tabs>
          </div>

          <div className={css.actions}>
            <Button
              transparent
              title={t('cancel_btn')}
              height={40}
              autoWidth
              buttonAttributes={{
                onClick: onCloseModal,
              }}
            />
            <Button
              title={t('add_btn')}
              height={40}
              autoWidth
              buttonAttributes={{
                onClick: onSubmit,
              }}
            />
          </div>
        </form>
      </div>
    </ModalOverlay>
  );
}
