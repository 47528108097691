import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { makeReqWithRD, TMakeReqWithRD } from 'redux/makeReqWithRD';
import i18n from '../../../../i18n';
import {
  setTempSearch,
  setTempBreadcrumbs,
  setTempAccessParent,
  setTempModalBreadcrumbs,
} from '../reducer';
import { getSearchByInternalID, getBreadcrumbs } from '../api';
import {
  fetchBreadcrumbs,
  fetchModalBreadcrumbs,
  fetchParentList,
  fetchSearchRequest,
} from '../actions';
import { selectorBreadcrumbs } from '../selectors';
import { selectorPartner } from '../../../campaigns/model/selectors';
import {
  resetBalance,
  setBalance,
  setPartner,
} from '../../../campaigns/reducer';
import { fetchPartnerBalance } from '../../../campaigns/model/actions';
import { partnerGetBalance } from '../../../campaigns/api';
import { selectorUser } from '../../../user/model/selectors';
import { GroupUser, ResponseUser } from '../../../user/types';
import { extractGenFetchData } from '../../../../redux/fetchedData';

export function* fetchSearchWorker({
  payload,
}: ReturnType<typeof fetchSearchRequest>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getSearchByInternalID>>(makeReqWithRD, {
      fetcher: getSearchByInternalID,
      fill: setTempSearch,
      parameters: payload,
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* fetcherBreadcrumbsWorker({
  payload,
}: ReturnType<typeof fetchBreadcrumbs>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getBreadcrumbs>>(makeReqWithRD, {
      fetcher: getBreadcrumbs,
      fill: setTempBreadcrumbs,
      parameters: payload,
    });
    const userTemp = yield select(selectorUser);
    const { data: user } = extractGenFetchData<ResponseUser>(userTemp);
    const partner = yield select(selectorPartner);
    const tempBreadcrumbs = yield select(selectorBreadcrumbs);
    const breadcrumbs = tempBreadcrumbs.get('data');
    const listRole: GroupUser = ['self_user', 'owner'];
    if (user && user.group.some((role) => listRole.includes(role))) {
      if (breadcrumbs && 'Client' in breadcrumbs) {
        if (partner.xxhash !== breadcrumbs.Client.xxhash) {
          yield put(
            setPartner({
              xxhash: breadcrumbs.Client.xxhash,
              name: `${i18n.t(`just_words.w3`)} ${breadcrumbs.Client.title}`,
            }),
          );
          yield put(fetchPartnerBalance({ partner_xxhash: breadcrumbs.Client.xxhash }));
        }
      } else if (breadcrumbs && 'Agency' in breadcrumbs) {
        if (partner.xxhash !== breadcrumbs.Agency.xxhash) {
          yield put(
            setPartner({
              xxhash: breadcrumbs.Agency.xxhash,
              name: breadcrumbs.Agency.title,
            }),
          );
          yield put(fetchPartnerBalance({ partner_xxhash: breadcrumbs.Agency.xxhash }));
        }
      } else if (partner) {
        yield put(setPartner({ name: '', xxhash: '' }));
        yield put(resetBalance());
      }
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* fetcherParentListWorker({
  payload,
}: ReturnType<typeof fetchParentList>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getBreadcrumbs>>(makeReqWithRD, {
      fetcher: getBreadcrumbs,
      fill: setTempAccessParent,
      parameters: payload,
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* fetcherModalBreadcrumbsWorker({
  payload,
}: ReturnType<typeof fetchModalBreadcrumbs>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getBreadcrumbs>>(makeReqWithRD, {
      fetcher: getBreadcrumbs,
      fill: setTempModalBreadcrumbs,
      parameters: payload,
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* fetcherPartnerBalanceWorker({
  payload,
}: ReturnType<typeof fetchPartnerBalance>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof partnerGetBalance>>(makeReqWithRD, {
      fetcher: partnerGetBalance,
      fill: setBalance,
      parameters: payload,
    });
  } catch (e) {
    console.error({ e });
  }
}
