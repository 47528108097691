import { ResponseTranslation } from 'domains/campaigns/types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../redux/rootReducer';
import { CampaignState } from '../types';
import { useCampaignInfo } from '../selectors';

interface ReturnType {
  translationInfo: ResponseTranslation | null;
  isLoading: boolean;
  time: string | boolean;
  isError: boolean;
}

export const useCampaingTranslationInfo = (): ReturnType => {
  const { data: dataTime } = useCampaignInfo();
  const time = dataTime
    ? moment(dataTime?.date_edit?.date).format('hh:mm/ DD.MM.YYYY')
    : false;

  const { translation } = useSelector<AppState, CampaignState>(
    (state) => state.campaignReducer,
  );
  const translationInfo = translation.get('data');
  const { isError } = translation.get('error');
  const isLoading = translation.get('isLoading');

  return { translationInfo, isLoading, time, isError };
};
