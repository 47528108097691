/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, {
  FC,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import Checkbox from 'components/UI/Checkbox/checkbox';
import TableFilter from 'components/UI/TableFilter';
import Button from 'components/UI/Buttons/buttons';
import { FiTrash2 } from 'react-icons/fi';
import BaseModal from 'components/UI/BaseModal';
import { Trans, useTranslation } from 'react-i18next';
import ElementsNone from 'components/ElementsNone';
import cn from 'classnames';
import { useUserTheme } from 'domains/user/model/selectors';
import { TableVirtuoso } from 'react-virtuoso';
import { TGetListDataItem } from 'domains/blackList/types';
import { TTableSort } from 'types/general';
import css from '../styles.module.scss';
// eslint-disable-next-line import/no-cycle
import Item from '../Item';
// eslint-disable-next-line import/no-cycle
import { ICheck } from '..';
import LoadingItem from '../Item/loadingItem';
import { useSortDomains } from './useSortDomains';

interface IListProps {
  fakePart: ReactNode;
  fakePartRef: React.RefObject<HTMLDivElement>;
  checked: ICheck | null;
  setChecked: React.Dispatch<React.SetStateAction<ICheck | null>>;
  blDomains: TGetListDataItem[];
  loadData: () => void;
  changeStatus: (checked_items: ICheck) => void;
  isLoading: boolean;
  fileStatus: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
}

const List: FC<IListProps> = ({
  fakePart,
  fakePartRef,
  checked,
  setChecked,
  blDomains,
  loadData,
  changeStatus,
  isLoading,
  fileStatus,
  setCount,
}) => {
  const { t } = useTranslation();

  const searchParams = new URLSearchParams(window.location.search);
  const type = searchParams.get('type');

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [sortType, setSortType] = useState<TTableSort['sort']>('DESC');

  const { isThemeExtended, isThemeFull } = useUserTheme();
  const [globalChecked, setGlobalChecked] = useState<boolean>(false);
  const [partially, setPartially] = useState<boolean>(false);

  const changeDomainStatus = (checked_items: ICheck | null) => {
    if (checked_items) {
      changeStatus(checked_items);
    };
    setGlobalChecked(false);
    setChecked(null);
  };

  const { sortedDomains } = useSortDomains({ blDomains, fileStatus, sortType });

  const handleCheckboxChange =
    (item_id: number) => (event: SyntheticEvent<HTMLInputElement>) => {
      const newChecked = { ...checked };
      const target = event.target as HTMLInputElement;
      newChecked[item_id] = target.checked;
      setChecked(newChecked);
    };

  const handleCheckedAll =
    (data: TGetListDataItem[]) => (event: SyntheticEvent<HTMLInputElement>) => {
      setGlobalChecked(!globalChecked);
      const newChecked = { ...checked };
      const newValue = !globalChecked;
      Object.values(data).forEach((d: any) => {
        newChecked[d.id] = newValue;
      });
      setChecked(newChecked);
    };

  const checkIsPartially = () => {
    if (
      checked &&
      Object.values(checked).every((i) => i === true) &&
      Object.values(checked).length === blDomains.length
    ) {
      setPartially(false);
      setGlobalChecked(true);
      return;
    }
    if (checked && Object.values(checked).every((ch) => ch === false)) {
      setPartially(false);
      setGlobalChecked(false);
      return;
    }
    if (checked && Object.values(checked).length > 0) {
      setPartially(true);
      return;
    }
    setPartially(false);
    setGlobalChecked(false);
  };

  useEffect(() => {
    setGlobalChecked(false);
  }, [fileStatus]);

  useEffect(() => {
    checkIsPartially();
  }, [checked]);

  return (
    <div
      className={cn(css.content, {
        _isGlobal: type === 'global',
        _isDeleted: fileStatus === 0,
      })}
    >
      <div
        className={cn('container resized', {
          extend: isThemeExtended,
          full: isThemeFull,
        })}
      >
        {fakePart}
        {sortedDomains.length === 0 && !isLoading && <ElementsNone />}
        {isLoading && sortedDomains.length === 0 && (
          <div className={css.list}>
            {new Array(5).fill(0).map((a, index) => (
              <LoadingItem key={index} />
            ))}
          </div>
        )}
        {sortedDomains.length > 0 && (
          <div className={css.list_header} ref={fakePartRef}>
            <div className={css.list_headerWrapper}>
              <Checkbox
                label=""
                onlyBox
                className={css.checkbox}
                isPartiallyChecked={partially}
                inputAttributes={{
                  type: 'checkbox',
                  checked: globalChecked,
                  onChange: handleCheckedAll(sortedDomains),
                }}
              />
              <TableFilter
                data="source"
                title={t(`blackLists.source`)}
                activeKey={null}
                sort={null}
                handleSort={({ key = 'source', sort = 'ASC' }) => {
                  console.log(key, sort);
                }}
              />
              <TableFilter
                data="publisher"
                title={t(`blackLists.publisher`)}
                activeKey={null}
                sort={null}
                handleSort={({ key = 'source', sort = 'ASC' }) => {
                  console.log(key, sort);
                }}
              />
              <TableFilter
                data="domain"
                title={t(`blackLists.domain`)}
                activeKey={null}
                sort={null}
                handleSort={({ key = 'source', sort = 'ASC' }) => {
                  console.log(key, sort);
                }}
              />
              <TableFilter
                data="date"
                title={t(`history_changes_page.header_date`)}
                activeKey="date"
                sort={sortType}
                isShow
                handleSort={() => {
                  setSortType(sortType === 'DESC' ? 'ASC' : 'DESC');
                }}
                tooltipText={
                  fileStatus === 1
                    ? t(`blackLists.date_add`)
                    : t(`blackLists.date_del`)
                }
              />
              {type === 'client' && (
                <TableFilter
                  data="partner"
                  title={t(`users_page.settings_modal.parthner_title`)}
                  activeKey={null}
                  sort={null}
                  handleSort={({ key = 'source', sort = 'ASC' }) => {
                    console.log(key, sort);
                  }}
                />
              )}
              {fileStatus === 0 && (
                <TableFilter
                  data="delete"
                  title={t(`blackLists.who_deleted`)}
                  activeKey={null}
                  sort={null}
                  handleSort={({ key = 'source', sort = 'ASC' }) => {
                    console.log(key, sort);
                  }}
                />
              )}
            </div>
          </div>
        )}
        {sortedDomains.length > 0 && (
          <div className={css.list}>
            <TableVirtuoso
              className={css.list_body}
              data={sortedDomains}
              itemContent={(index, bl) => (
                <Item
                  key={bl.id}
                  deleted={bl.status_in_file === '0'}
                  checked={checked}
                  handleCheckboxChange={handleCheckboxChange}
                  data={bl}
                  type={type}
                />
              )}
              endReached={loadData}
              overscan={20}
            />
          </div>
        )}
      </div>
      {checked &&
        Object.values(checked).filter((ch) => ch === true).length > 0 && (
          <div className={css.alert}>
            <div className={css.alert_text}>
              <Trans
                i18nKey="blackLists.selected_string"
                values={{
                  value: Object.values(checked).filter((ch) => ch === true)
                    .length,
                }}
                components={{ bold: <strong /> }}
              />
            </div>
            <Button
              title={
                fileStatus === 1
                  ? t(`blackLists.del_from_bl`)
                  : t(`blackLists.add_from_bl`)
              }
              icon={<FiTrash2 size={22} color="#025DFF" />}
              transparent
              buttonAttributes={{
                onClick: () => setOpenModal(true),
              }}
            />
          </div>
        )}
      {openModal && (
        <BaseModal
          onClose={() => setOpenModal(false)}
          actions={
            <>
              <Button
                title={t(`cancel_btn`)}
                transparent
                buttonAttributes={{
                  onClick: () => setOpenModal(false),
                }}
              />
              <Button
                title={fileStatus === 1 ? t(`delete_btn`) : t(`add_btn`)}
                buttonAttributes={{
                  onClick: () => {
                    setCount(0);
                    changeDomainStatus(checked);
                    setOpenModal(false);
                  },
                }}
              />
            </>
          }
          title={
            fileStatus === 1
              ? t(`blackLists.deleting_from_bl`)
              : t(`blackLists.adding_to_bl`)
          }
          isOpen
        >
          {fileStatus === 1
            ? t(`blackLists.del_from_bl_real`)
            : t(`blackLists.add_from_bl_real`)}
        </BaseModal>
      )}
    </div>
  );
};

export default List;
