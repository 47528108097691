import Avatar1 from '../../UI/UserList/Avatar/icons/Rectangle 350.png';
import Avatar2 from '../../UI/UserList/Avatar/icons/Rectangle 351.png';
import Avatar3 from '../../UI/UserList/Avatar/icons/Rectangle 352.png';
import Avatar4 from '../../UI/UserList/Avatar/icons/Rectangle 353.png';
import Avatar5 from '../../UI/UserList/Avatar/icons/Rectangle 354.png';
import Avatar6 from '../../UI/UserList/Avatar/icons/Rectangle 355.png';
import Avatar7 from '../../UI/UserList/Avatar/icons/Rectangle 356.png';
import Avatar8 from '../../UI/UserList/Avatar/icons/Rectangle 357.png';
import Avatar9 from '../../UI/UserList/Avatar/icons/Rectangle 358.png';
import Avatar10 from '../../UI/UserList/Avatar/icons/Rectangle 359.png';
import Avatar11 from '../../UI/UserList/Avatar/icons/Rectangle 360.png';
import Avatar12 from '../../UI/UserList/Avatar/icons/Rectangle 361.png';
import Avatar13 from '../../UI/UserList/Avatar/icons/Rectangle 362.png';
import Avatar14 from '../../UI/UserList/Avatar/icons/Rectangle 363.png';
import Avatar15 from '../../UI/UserList/Avatar/icons/Rectangle 364.png';
import Avatar16 from '../../UI/UserList/Avatar/icons/Rectangle 365.png';
import Avatar17 from '../../UI/UserList/Avatar/icons/Rectangle 366.png';
import Avatar18 from '../../UI/UserList/Avatar/icons/Rectangle 367.png';
import Avatar19 from '../../UI/UserList/Avatar/icons/Rectangle 368.png';
import Avatar20 from '../../UI/UserList/Avatar/icons/Rectangle 369.png';

const engAlphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
const rusAlphabet = 'абвгдежзиклмнопрстуфхцчшщыэюя'.split('');

const avatar = [
  Avatar1,
  Avatar2,
  Avatar3,
  Avatar4,
  Avatar5,
  Avatar6,
  Avatar7,
  Avatar8,
  Avatar9,
  Avatar10,
  Avatar11,
  Avatar12,
  Avatar13,
  Avatar14,
  Avatar15,
  Avatar16,
  Avatar17,
  Avatar18,
  Avatar19,
  Avatar20,
];

export default (val: string): string => {
  const eng = /^[a-z]*$/;
  let index;

  if (/^[0-9]/.test(val)) {
    return avatar[Number(val)];
  }

  if (eng.test(val)) {
    index = engAlphabet.findIndex((e) => e === val);
  } else {
    index = rusAlphabet.findIndex((e) => e === val);
  }

  return avatar[index] ?? avatar[0];
};
