import Card from 'components/UI/Card';
import React, { FC, useMemo } from 'react';
import InputAutoSave from 'components/UI/InputAutoSave';
import LabelField from 'components/UI/LabelField';
import { useTranslation } from 'react-i18next';
import MultiSelectCheckbox from 'components/UI/MultiSelectCheckbox';
import { Field, FieldProps, Form, FormikProps, FormikProvider } from 'formik';
import { CounteragentFormikProps } from 'domains/counteragent/model/types';
import { oksm } from 'domains/counteragent/model/consts/oksm';
import { useAgenciesInfo } from 'domains/agencies/model/selectors';
import { InputLengthCounteragent } from 'domains/counteragent/model/consts/inputLengthCounteragent';
import NewSelect from 'components/UI/NewSelect';
import css from '../styles.module.scss';
import i18n from '../../../../../i18n';

interface Props {
  formik: FormikProps<CounteragentFormikProps>;
  handleChangeInput: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  mode: 'update' | 'create';
  userType?: 'CLIENT' | 'AGENCY' | 'ORGANIZATION' | 'partner';
}

const foreignL = ['ffl', 'ful'];

const Parameters: FC<Props> = ({
  formik,
  userType,
  mode,
  handleChangeInput,
}) => {
  const types = useMemo(
    () => [
      {
        label: i18n.t(`ord.legal_entity`),
        value: 'ul',
      },
      {
        label: i18n.t(`ord.individual_entity`),
        value: 'fl',
      },
      {
        label: i18n.t(`ord.sole_trader`),
        value: 'ip',
      },
      {
        label: i18n.t(`ord.foreign_individual_entity`),
        value: 'ffl',
      },
      {
        label: i18n.t(`ord.foreign_legal_entity`),
        value: 'ful',
      },
    ],
    [i18n.language],
  );

  const { t } = useTranslation();
  const isUpdateMode = mode === 'update';
  const oksmList = useMemo(
    () => oksm.map((item) => ({ value: item.code, label: item.shortname })),
    [],
  );

  const { data: agencyList } = useAgenciesInfo();

  const agencyOptions = useMemo(
    () => agencyList?.map(({ title, id }) => ({ label: title, value: id })),
    [agencyList],
  );

  const currentValueOfType = types.find(
    (item) => item.value === formik?.values.type,
  );

  const labelForName =
    currentValueOfType &&
    (currentValueOfType.value === 'fl' ||
      currentValueOfType.value === 'ip' ||
      currentValueOfType.value === 'ful')
      ? t(`ord.fio`)
      : t(`ord.name_of_legal`);

  return (
    <Card
      noHover
      style={{ padding: '28px 24px' }}
      isUseAccess
      className={css.card}
    >
      <FormikProvider value={formik}>
        <Form>
          <h3 className={css.title}>{t(`ord.parameters`)}</h3>
          <div className={css.form}>
            {userType === 'ORGANIZATION' && (
              <LabelField label={t(`ord.td_agencis`)}>
                <MultiSelectCheckbox
                  isDisabled={isUpdateMode}
                  fieldName="agency_xxhash_list"
                  customHandleChange={handleChangeInput}
                  formik={formik}
                  propsValues={agencyOptions || null}
                  placeholder={t(`offers_page.select_cliient`)}
                  isError={!!formik.errors.agency_xxhash_list}
                  errorText={formik.errors.agency_xxhash_list}
                />
              </LabelField>
            )}
            <LabelField label={t(`ord.type`)}>
              <Field name="type">
                {({ field, form }: FieldProps) => (
                  <NewSelect
                    form={form}
                    validateHandler={formik.validateField}
                    field={field}
                    error={!!formik.errors.type}
                    errorText={formik.errors.type}
                    selectAttributes={{
                      isDisabled: isUpdateMode,
                      options: types,
                      placeholder: t(`project_page.forms.not_selected`),
                      value: currentValueOfType,
                    }}
                    heightCustom={32}
                    viewStyle="Horizontal"
                  />
                )}
              </Field>
            </LabelField>
            <InputAutoSave
              label={labelForName}
              value={formik.values.name}
              name="name"
              maxLength={InputLengthCounteragent.common}
              placeholder={t(`offers_page.enter_name`)}
              setFieldValue={handleChangeInput}
              isError={!!formik.errors.name}
              errorText={formik.errors.name}
            />
            <InputAutoSave
              label={t(`ord.synonyms_name_1`)}
              value={formik.values.aliases || ''}
              name="aliases"
              maxLength={InputLengthCounteragent.aliases}
              placeholder={t(`ord.synonyms_enter`)}
              setFieldValue={handleChangeInput}
              isError={!!formik.errors.aliases}
              errorText={formik.errors.aliases}
            />
            <InputAutoSave
              label={t(`ord.name_for_export`)}
              value={formik.values.exportName || ''}
              name="exportName"
              maxLength={InputLengthCounteragent.common}
              placeholder={t(`offers_page.enter_name`)}
              setFieldValue={handleChangeInput}
              isError={!!formik.errors.exportName}
              errorText={formik.errors.exportName}
            />
            {formik.values.type === 'ful' && (
              <>
                <InputAutoSave
                  label={t(`ord.registration_number`)}
                  value={formik.values.regNumber || ''}
                  name="regNumber"
                  maxLength={InputLengthCounteragent.inn}
                  placeholder={t(`offers_page.enter_name`)}
                  isDisable={
                    isUpdateMode || Boolean(formik.values.alternativeInn)
                  }
                  setFieldValue={handleChangeInput}
                  isError={!!formik.errors.regNumber}
                  errorText={formik.errors.regNumber}
                />
                <InputAutoSave
                  label={t(`ord.identification_number`)}
                  value={formik.values.alternativeInn || ''}
                  name="alternativeInn"
                  maxLength={InputLengthCounteragent.inn}
                  placeholder={t(`offers_page.enter_name`)}
                  isDisable={isUpdateMode || Boolean(formik.values.regNumber)}
                  setFieldValue={handleChangeInput}
                  isError={!!formik.errors.alternativeInn}
                  errorText={formik.errors.alternativeInn}
                />
              </>
            )}
            {['ul', 'fl', 'ip'].includes(formik.values.type) && (
              <InputAutoSave
                label={`${t(`ord.inn_or`)} *`}
                value={formik.values.inn || ''}
                name="inn"
                maxLength={InputLengthCounteragent.inn}
                placeholder={t(`offers_page.enter_name`)}
                isDisable={isUpdateMode}
                setFieldValue={handleChangeInput}
                isError={!!formik.errors.inn}
                errorText={formik.errors.inn}
              />
            )}

            {formik.values.type === 'ffl' && (
              <>
                <InputAutoSave
                  label={t(`ord.mobile_phone`)}
                  value={formik.values.mobilePhone || ''}
                  name="mobilePhone"
                  maxLength={InputLengthCounteragent.inn}
                  placeholder={t(`offers_page.enter_name`)}
                  setFieldValue={handleChangeInput}
                  isError={!!formik.errors.mobilePhone}
                  errorText={formik.errors.mobilePhone}
                  isDisable={Boolean(formik.values.epayNumber) || isUpdateMode}
                />
                <InputAutoSave
                  label="EPAY *"
                  value={formik.values.epayNumber || ''}
                  name="epayNumber"
                  maxLength={InputLengthCounteragent.inn}
                  placeholder={t(`offers_page.enter_name`)}
                  setFieldValue={handleChangeInput}
                  isError={!!formik.errors.epayNumber}
                  errorText={formik.errors.epayNumber}
                  isDisable={Boolean(formik.values.mobilePhone) || isUpdateMode}
                />
              </>
            )}
            {foreignL.includes(formik.values.type) && (
              <LabelField label={t(`ord.registration_country`)}>
                <Field name="oksmNumber">
                  {({ field, form }: FieldProps) => (
                    <NewSelect
                      form={form}
                      field={field}
                      error={!!formik.errors.oksmNumber}
                      errorText={formik.errors.oksmNumber}
                      selectAttributes={{
                        options: oksmList,
                        isDisabled: isUpdateMode,
                        placeholder: t(`project_page.forms.not_selected`),
                        value: oksmList.find(
                          (item) => item.value === formik?.values.oksmNumber,
                        ),
                      }}
                      heightCustom={32}
                      viewStyle="Horizontal"
                    />
                  )}
                </Field>
              </LabelField>
            )}
          </div>
        </Form>
      </FormikProvider>
    </Card>
  );
};

export default Parameters;
