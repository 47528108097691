import Tooltip from 'components/UI/Tooltip';
import React, { FC } from 'react';
import { FiExternalLink, FiHelpCircle } from 'react-icons/fi';
import cn from 'classnames';
import css from './styles.module.scss';
import i18n from '../../i18n';

interface HelpProp {
  title: string;
  open?: boolean;
  width?: number;
  classname?: string;
  helpattributes: {
    text?: string | JSX.Element;
    link?: string;
    sizeIcon?: number;
    colorIcon?: string;
  };
}

const Help: FC<HelpProp> = ({
  title,
  open,
  helpattributes,
  width,
  classname,
}) => (
  <div
    className={cn(css.prompt, 'prompt', classname, {
      _isShow: open,
      _isEmpty: helpattributes?.link === '',
    })}
  >
    <Tooltip
      white
      hintWidth={width || 300}
      className={css.prompt__tooltip}
      title={
        <div className={css.prompt__content}>
          <FiHelpCircle
            size={helpattributes?.sizeIcon ? helpattributes?.sizeIcon : 20}
            color={
              helpattributes?.colorIcon ? helpattributes?.colorIcon : '#222222'
            }
          />
        </div>
      }
    >
      {title && <div className={css.prompt__content_title}>{title}</div>}

      {helpattributes?.text && (
        <div
          className={css.prompt__content_text}
          style={{
            marginTop: title === '' ? '0' : '16px',
          }}
        >
          {helpattributes?.text}
        </div>
      )}

      {helpattributes?.link && (
        <div className={css.prompt__content_more}>
          <a
            href={helpattributes?.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {i18n.t(`more_btn`)}
            <div className={css.prompt__content_more_icon}>
              <FiExternalLink />
            </div>
          </a>
        </div>
      )}
    </Tooltip>
  </div>
);

export default Help;
