import React, { FC, useState } from 'react';
import cn from 'classnames';
import { getCustomerByEridParams } from 'domains/creative/types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ShowSvg } from './icons/show.svg';
import css from './styles.module.scss';

interface IShowEridProps {
  children: React.ReactNode;
  innData?: getCustomerByEridParams | string[] | null;
  erid?: string;
  use_vpaid?: boolean;
  type: string;
}

const ShowErid: FC<IShowEridProps> = ({
  children,
  erid,
  type,
  innData,
  use_vpaid,
}) => {
  const [showErid, setShowErid] = useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <div
      className={cn(css.show, {
        _isShow: showErid,
      })}
    >
      {children}
      {erid && use_vpaid && (
        <>
          <div className={css.show_block}>
            <div className={css.show_info}>
              <div className={css.show_ads}>Реклама</div>
              {!Array.isArray(innData) && type === 'VIDEO_INLINE' && (
                <>
                  <div>{innData?.name},</div>
                  <div>
                    {t(`ord.inn_or`)}: {innData?.inn}
                  </div>
                </>
              )}
              <div>Erid: {erid}</div>
              <div
                className={css.show_close}
                onClick={() => setShowErid(false)}
              >
                ×
              </div>
            </div>
          </div>
          <div className={css.show_btn} onClick={() => setShowErid(true)}>
            <ShowSvg />
          </div>
        </>
      )}
    </div>
  );
};

export default ShowErid;
