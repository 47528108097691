import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import Portal from 'components/Modals/MainPortal';
import SideMenu from 'components/SideMenu';
import { FiMenu } from 'react-icons/fi';
import HeaderAccount from 'components/HeaderAccount';
import './styles.scss';
import { useDispatchApp } from 'redux/rootSelectors';
import { setHideNavigation } from 'domains/user/reduser';
import { useProjectInfo } from '../../domains/project/hooks';
import UserList from '../../domains/wss/UI/UserList';
import { useHideNavigation, useUserInfo } from '../../domains/user/model/selectors';
import Balance from '../Balance';
import { usePartnerBalance } from '../Balance/hooks/usePartnerBalance';

const BlackHeader: FC = () => {
  const hideNavigation = useHideNavigation();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatchApp()
  const onClose = () => setOpen(false);
  const { data: project } = useProjectInfo();
  const { data: user } = useUserInfo();
  const { value, currency, handleFetcher, isLoading, name } =
    usePartnerBalance();

  useEffect(() => {
    if (user?.group.length === 0) {
      dispatch(setHideNavigation(true))
    }
  }, [user?.group.length])
  

  return (
    <div className="black-header">
      <div className={cn('black-header__nav')}>
        <div className="content">
          {(!hideNavigation) && (
            <FiMenu
              size={24}
              color="#fff"
              className="black-header__menu"
              onClick={() => setOpen(true)}
            />
          )}
          {project && project.logo_small && (
            <Link to="/main">
              <img
                src={project.logo_small}
                alt="logo"
                className="black-header__logo"
              />
            </Link>
          )}
          <div className="spacer" />
          <UserList />
          <Balance
            value={value}
            currency={currency}
            fetcher={handleFetcher}
            classname="balance"
            name={name}
            isTooltip
            isLoading={isLoading}
          />
          <HeaderAccount />
        </div>
      </div>
      
      <Portal>
        <SideMenu onClose={onClose} isOpen={open} />
      </Portal>
    </div>
  );
};

export default BlackHeader;
