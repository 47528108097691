import { TCellTypes, Tdata } from 'domains/target/model/types';
import React, { FC } from 'react';
import cn from 'classnames';
import css from '../styles.module.scss';
import cellCss from './cell.module.scss';

interface Props {
  row: Tdata;
  isCellKey: TCellTypes;
  isSourceLike: boolean;
  statisticsIsGroup: boolean;
  isUnlockBidEdit: boolean;
  use_inherit_settingsState: boolean;
  onChangeValHandler: (
    k: string,
    e: React.ChangeEvent<HTMLInputElement>,
    valType: TCellTypes,
  ) => void;
  disable?:boolean
}

export const CellBid: FC<Props> = ({
  row,
  isCellKey,
  isSourceLike,
  isUnlockBidEdit,
  statisticsIsGroup,
  use_inherit_settingsState,
  onChangeValHandler,
  disable
}) => {

  let value: string | number;
  if (isCellKey === 'bid_rate') {
    value = row?.bid_rate ?? '';
  } else if (isCellKey === 'bid_start') {
    value = row?.bid_start ?? 0;
  } else if (isCellKey === 'maxbid') {
    value = row?.maxbid ?? '';
  } else {
    value = row?.hour ?? '';
  }
  const handler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeValHandler(row.key, e, isCellKey);
  };

  return (
    <div
      className={cn(cellCss.bid, {
        _isDisable:
          (row?.key === 'n/a' && isCellKey === 'hour') ||
          (isCellKey === 'bid_start' &&
            isSourceLike &&
            row.type === 'bidfloor'),
      })}
      key={row.key}
    >
      <input
        value={value}
        disabled={disable||
          Boolean(
            !statisticsIsGroup && row?.children?.length && !isUnlockBidEdit,
          ) ||
          !use_inherit_settingsState ||
          (row?.key === 'n/a' && isCellKey === 'hour') ||
          Boolean(statisticsIsGroup && row.isChild) ||
          (isCellKey === 'bid_start' && isSourceLike && row.type === 'bidfloor')
        }
        className={css.isEdit}
        onChange={(e) => handler(e)}
        autoComplete={isCellKey === 'maxbid' ? 'maxbid' : 'off'}
      />
    </div>
  );
};
