import React, { FC, useState } from 'react';
import cn from 'classnames';
import { FiMoreHorizontal, FiEdit } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import Tooltip from 'components/UI/Tooltip';
import { everyGroups } from 'utils/statics/everyGroups';
import toArchiveSvg from '../../../assets/toarchive.svg';
import fromArchiveSvg from '../../../assets/fromarchive.svg';
import css from './styles.module.scss';
import avatarColors from '../../../utils/avatarColors';
import { RAgencies } from '../api';
import OnlyOwner from '../../../components/OnlyOwner';
import ToggleSwitch from '../../../components/UI/ToggleSwitch';
import { RerenderTypeAgencies, setStatusAgenciesAction } from '../reducer';
import ModalWithQuestion from '../../modals/modalWithQuestion';
import { useDispatchApp } from '../../../redux/rootSelectors';
import { useUserInfo, useUserTheme } from '../../user/model/selectors';
import reportSvg from '../../../components/SideMenu/report.svg';
import { ReactComponent as UsersSvg } from '../../../assets/UsersCheck.svg';

interface Props {
  /** параметры агентства */
  agency: RAgencies;
  paramsAgencies: RerenderTypeAgencies;
}

const Item: FC<Props> = ({ agency, paramsAgencies }) => {
  const { title, icon, status, id, default_icon, site } = agency;
  const { data: user } = useUserInfo();
  const dispatch = useDispatchApp();
  const { getValueFromSettingForKey } = useUserTheme();
  const [isOpenModalArchive, setOpenModalArchive] = useState(false);
  const [active, setActive] = useState(status === 'LAUNCHED' || false);
  const [toggle, setToggle] = useState(false);
  const { t } = useTranslation();

  const handleChangeArchive = () => {
    dispatch(
      setStatusAgenciesAction({
        id,
        status: status === 'ARCHIVE' ? 'STOPPED' : 'ARCHIVE',
        setter: setOpenModalArchive,
        value: isOpenModalArchive,
        reRenderParams: {
          status: paramsAgencies.status,
          alertText: {
            before: `${t('agencies_page.archive_modal.alert_from1')} `,
            title: `"${title}"`,
            after:
              status === 'ARCHIVE'
                ? ` ${t('agencies_page.archive_modal.alert_from2')}`
                : ` ${t('agencies_page.archive_modal.alert_to2')}`,
          },
          isArchive: true,
        },
      }),
    );
  };
  const handleChangeStatus = () => {
    dispatch(
      setStatusAgenciesAction({
        id,
        status: active ? 'STOPPED' : 'LAUNCHED',
        setter: setActive,
        value: active,
      }),
    );
  };

  return (
    <div className={cn(css.wrapper)}>
      <div className={css.indicator} />
      <div
        className={cn(css.item, {
          _isOpen: toggle,
          _isDeleted: status === 'DELETED',
        })}
      >
        <div
          onMouseEnter={() => {
            if (!toggle) {
              setToggle(false);
            } else {
              setToggle(true);
            }
          }}
          onMouseLeave={() => {
            if (!toggle) {
              setToggle(false);
            }
          }}
          onTouchStart={() => {
            if (!toggle) {
              setToggle(false);
            } else {
              setToggle(true);
            }
          }}
          onTouchEnd={() => {
            if (!toggle) {
              setToggle(false);
            }
          }}
          className={css.toggle__status}
        >
          <Tooltip
            hideHint
            title={
              <OnlyOwner>
                <ToggleSwitch
                  disabled={status === 'ARCHIVE' || status === 'DELETED'}
                  onChange={handleChangeStatus}
                  checked={active}
                  toggleOnly
                />
              </OnlyOwner>
            }
          >
            <span>
              {active ? t('statuses.launched') : t('statuses.stopped')}
            </span>
          </Tooltip>
        </div>
        <Link
          to={{
            pathname: '/main/clients',
            search: `?agency_id=${id}`,
            state: {
              back: {
                to: '/main/agencies',
              },
            },
          }}
          title={title}
        />
        <div className={css.item__header}>
          <Link
            to={{
              pathname: '/main/clients',
              search: `?agency_id=${id}`,
              state: {
                back: {
                  to: '/main/agencies',
                },
              },
            }}
            title={title}
          />
          {icon && !default_icon ? (
            <div
              className={css.img}
              style={{
                backgroundImage: `url('${icon}')`,
              }}
            />
          ) : (
            <div
              className={css.img}
              style={{
                backgroundColor: avatarColors(title.slice(0, 1).toLowerCase()),
              }}
            >
              {title.slice(0, 1).toUpperCase()}
            </div>
          )}
          <div className={css.text}>
            <Tooltip
              className={css.tooltip}
              hintWidth={300}
              leftArrowPosition
              title={
                <Link
                  to={{
                    pathname: '/main/clients',
                    search: `?agency_id=${id}`,
                    state: {
                      back: {
                        to: '/main/agencies',
                      },
                    },
                  }}
                  title={title}
                >
                  <strong>{title}</strong>
                </Link>
              }
            >
              <p>{title}</p>
            </Tooltip>
            <div className={css.hint}>
              <span className={css.advertiser}>{site} </span>
            </div>
          </div>
        </div>

        <div className={css.actions}>
          <div
            className={cn('toggle-dropdown', css.dropdown, {
              _isOpen: toggle,
              _isDeleted: status === 'ARCHIVE' || status === 'DELETED',
              _isNoAnimate: getValueFromSettingForKey('animation'),
            })}
            onMouseEnter={() => {
              setToggle(true);
            }}
            onMouseLeave={() => {
              setToggle(false);
            }}
          >
            <button type="button" className={css.dropdown__show}>
              <FiMoreHorizontal size={24} />
            </button>

            <div className={css.dropdown__menu}>
              {toggle && everyGroups(user, 'reports_client') && (
                <OnlyOwner>
                  <Tooltip
                    title={
                      <Link
                        to={{
                          pathname: `/main/agency/${id}/reports`,
                          state: {
                            back: {
                              to: '/main/agency',
                            },
                          },
                        }}
                      >
                        <ReactSVG src={reportSvg} />
                      </Link>
                    }
                  >
                    <span>{t(`agencies_page.agencies_report`)}</span>
                  </Tooltip>
                </OnlyOwner>
              )}
              {toggle &&
                ((everyGroups(user, 'reports_agency') &&
                  everyGroups(user, 'owner')) ||
                  (everyGroups(user, 'reports_agency') &&
                    everyGroups(user, 'self_user'))) &&
                user &&
                user.partner.type === 'ORGANIZATION' && (
                  <OnlyOwner>
                    <Tooltip
                      title={
                        <Link
                          to={{
                            pathname: `/main/agency/${id}/reports_au`,
                            state: {
                              back: {
                                to: '/main/agency',
                              },
                            },
                          }}
                        >
                          <UsersSvg />
                        </Link>
                      }
                    >
                      <span>{t(`agencies_page.report.title`)}</span>
                    </Tooltip>
                  </OnlyOwner>
                )}
              {toggle && (
                <OnlyOwner>
                  <Tooltip
                    onClick={() => setOpenModalArchive(true)}
                    title={
                      status === 'ARCHIVE' ? (
                        <ReactSVG src={fromArchiveSvg} />
                      ) : (
                        <ReactSVG src={toArchiveSvg} />
                      )
                    }
                  >
                    <span>
                      {status === 'ARCHIVE'
                        ? t('archive_title_from')
                        : t('archive_title_to')}
                    </span>
                  </Tooltip>
                </OnlyOwner>
              )}
              {toggle && (
                <OnlyOwner>
                  <Link
                    to={{
                      pathname: `/main/agency/${id}/basic_settings`,
                      state: {
                        back: {
                          to: '/main/agencies',
                        },
                      },
                    }}
                  >
                    <Tooltip title={<FiEdit size={24} />}>
                      <span>{t('clients_page.edit_icon')}</span>
                    </Tooltip>
                  </Link>
                </OnlyOwner>
              )}

              {isOpenModalArchive && (
                <OnlyOwner>
                  <ModalWithQuestion
                    onClose={() => setOpenModalArchive(false)}
                    modalName={
                      status === 'ARCHIVE'
                        ? t('agencies_page.archive_modal.title_from')
                        : t('agencies_page.archive_modal.title_to')
                    }
                    btnTitle={
                      status === 'ARCHIVE'
                        ? t('agencies_page.archive_modal.btn_from')
                        : t('agencies_page.archive_modal.btn_to')
                    }
                    subText={
                      status === 'ARCHIVE' ? (
                        <span>
                          {t('agencies_page.archive_modal.text_from1')}
                          <strong> {agency.title}</strong>
                          {t('agencies_page.archive_modal.text_from2') &&
                            ` ${t('agencies_page.archive_modal.text_from2')}`}
                        </span>
                      ) : (
                        <span>
                          {t('agencies_page.archive_modal.text_to1')}
                          <strong> {agency.title}</strong>
                          {t('agencies_page.archive_modal.text_to2') &&
                            ` ${t('agencies_page.archive_modal.text_to2')}`}
                        </span>
                      )
                    }
                    onClick={handleChangeArchive}
                  />
                </OnlyOwner>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
