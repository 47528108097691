import {
  THookStateInfo,
  TSelector,
  useShallowEqualSelector,
} from '../../../redux/rootSelectors';
import { BwListUpdateState } from '../reducer';

/** список селекторов */
export const selectorBwListModal: TSelector<
  BwListUpdateState['isShowModal']
> = ({ bwListUpdateReducer }) => bwListUpdateReducer.isShowModal;

/** Список хуков */
export const useBwListModalInfo: THookStateInfo<
  BwListUpdateState['isShowModal']
> = () => useShallowEqualSelector(selectorBwListModal);
