import { THookSelectorFetchDataInfo, TSelector, useGenFetchDataSelector } from 'redux/rootSelectors';
import { ContractItem } from './types';
import { contractState } from './reducer';

export const selectorContract: TSelector<contractState['contractData']> = ({
  contractReducer,
}) => contractReducer.contractData;

export const useContractData: THookSelectorFetchDataInfo<ContractItem> = () =>
  useGenFetchDataSelector(selectorContract);
