import { FormEvent, useEffect } from 'react';
import { FormikHelpers } from 'formik';
import { html_beautify } from 'js-beautify';
import { useDispatchApp } from 'redux/rootSelectors';
import { setFormDefault, setTempClearFileZip } from '../reducer';
import { TVariationNameFileZip } from '../types';
import { useUploadFilesZip } from '../hooks';
import { fetchZipRequest } from '../model/actions';

type Props = {
  name: TVariationNameFileZip;
  maxSizeFile: number;
  setFieldValue: FormikHelpers<string>['setFieldValue'];
  isData: boolean;
  sizes: string[];
  type: 'zip' | 'zip_banner';
};

type TFUseUploadZipHtml = (p: Props) => {
  handlerChangeInput: (v: FormEvent<HTMLInputElement>) => void;
  isLoading: ReturnType<typeof useUploadFilesZip>['isLoading'];
  error: ReturnType<typeof useUploadFilesZip>['error'];
  data: ReturnType<typeof useUploadFilesZip>['data'];
};

export const useUploadZipHtml: TFUseUploadZipHtml = ({
  name,
  maxSizeFile,
  setFieldValue,
  isData,
  sizes,
  type,
}) => {
  const dispatch = useDispatchApp();

  const { formDefault, data, error, LTU, isLoading } = useUploadFilesZip(name);

  useEffect(() => {
    if (data) {
      const { html, height, width, size } = data;

      setFieldValue(
        isData ? 'data.html_code' : 'html_code',
        html_beautify(html),
      );
      const hasSizes = sizes
        .filter((el) => el.includes('x'))
        .some((el) => {
          const sizeNew = el.split('x');
          return Number(sizeNew[0]) === width && Number(sizeNew[1]) === height;
        });
      if (hasSizes) {
        setFieldValue('size', size.replace('x', 'x'));
      } else {
        if (type === 'zip_banner') return
        setFieldValue('size', sizes[sizes.length - 1]);
        setFieldValue('width', width);
        setFieldValue('height', height);
      }
    }
  }, [LTU]);

  useEffect(() => {
    if (formDefault && (data || error.isError)) {
      dispatch(setTempClearFileZip(name));
    }
  }, [formDefault]);

  useEffect(
    () => () => {
      dispatch(setTempClearFileZip(name));
    },
    [],
  );

  /** Функция загрузки файла */
  const handlerChangeInput: ReturnType<TFUseUploadZipHtml>['handlerChangeInput'] =
    (e) => {
      const { files } = e.currentTarget;
      if (files === null) {
        e.currentTarget.setAttribute('value', '');
        return;
      }
      if (files.length === 0) {
        e.currentTarget.setAttribute('value', '');

        return;
      }
      const file = files[0];
      (<HTMLInputElement>document.getElementById(name)).value = '';
      dispatch(setFormDefault(false));
      dispatch(
        fetchZipRequest({ key: name, file, fileLimits: { maxSizeFile }, type }),
      );
    };
  return {
    handlerChangeInput,
    isLoading,
    error,
    data,
  };
};
