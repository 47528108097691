export const omitUtils = <T>(obj: T, key: keyof T): T => {
  const result: T = {} as T;

  Object.keys(obj).forEach((k) => {
    if (k !== key) {
      result[k] = obj[k];
    }
  });

  return result;
};
