import React, { FC } from 'react';
import cn from 'classnames';
import Load from 'components/UI/Load';
import css from './styles.module.scss';

export const Loader: FC = () => {
  const valueColumns = 4;
  const valueRows = 3;

  const preloaderColumns = new Array(valueColumns);

  const preloaderRows = new Array(valueRows);

  for (let i = 0; i < preloaderColumns.length; i += 1) {
    preloaderColumns[i] = (
      <div className={css.cell} key={`LoaderColumn-${i}`}>
        <Load />
      </div>
    );
  }

  for (let i = 0; i < preloaderRows.length; i += 1) {
    preloaderRows[i] = (
      <div className={css.table_row} key={`LoaderRow-${i}`}>
        <div className={css.table_row_static}>
          <div className={css.cell}>
            <span>
              <Load />
            </span>
          </div>
        </div>
        <>
          <div className={css.table_row_scroll}>
            {preloaderColumns.map((item) => item)}
          </div>
        </>
      </div>
    );
  }

  return (
    <div className={css.table_body}>
      <div className={cn(css.table_row, css.table_header)}>
        <div className={css.table_row_static}>
          <div className={css.cell}>
            <Load />
          </div>
        </div>
        <div className={css.table_row_scroll}>
          {preloaderColumns.map((item) => item)}
        </div>
      </div>
      <div className={css.table_content}>
        {preloaderRows.map((item) => item)}
      </div>
    </div>
  );
};

export default Loader;
