import {
  ChangeEvent,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Canvas, ITextOptions } from 'fabric/fabric-impl';
import { fabric } from 'fabric';
import i18n from '../../../../i18n';
import buttonBg from '../icons/buttonBg.png';
import {
  iconsBigArray,
  iconsSmallArray,
  TArrayImages,
  BUTTON_KEY,
  ILLUSTRATION_KEY,
  LOGO_KEY,
  TEXT_KEY,
} from '../shared/consts';
import { useDispatchApp } from '../../../../redux/rootSelectors';
import { fetchBusinessSphere } from '../../model/actions';
import { useBusinessInfo } from '../../model/selectors';
import { SelectField } from '../../../../types/general';

type TCanvasData = {
  canvas: Canvas | null;
  backgroundImage: string;
  illustration: string;
  buttonLabel: string;
  logo: string;
  logoName: string;
  logoError: string;
  href: string;
};

type TFUseConstructorProps = {
  width: number;
  height: number;
  handlerUploadFileLink: (url?: string | Blob) => void;
  onClose: () => void;
};

type TPositionObject = {
  textLeft: number;
  textTop: number;
  buttonLeft: number;
  buttonTop: number;
  logoLeft: number;
  logoTop: number;
  illustrationLeft: number;
  illustrationTop: number;
};

type TFUseConstructor = (props: TFUseConstructorProps) => {
  iconsArray: TArrayImages;
  openStyle: boolean;
  isOpenStyleIllustration: boolean;
  openStyleOn: () => void;
  openStyleOff: () => void;
  container: RefObject<HTMLCanvasElement>;
  canvasData: TCanvasData;
  handlerChangeBackground: (url: string) => void;
  logoRef: RefObject<HTMLDivElement>;
  textRef: RefObject<HTMLDivElement>;
  buttonRef: RefObject<HTMLDivElement>;
  uploadInputRef: RefObject<HTMLInputElement>;
  illustrationRef: RefObject<HTMLDivElement>;
  handlerAddText: () => void;
  onChangeButtons: (value: string) => void;
  mode: 'big' | 'default';
  openStyleIllustrationOn: () => void;
  openStyleIllustrationOff: () => void;
  handlerAddIllustration: (url: string) => void;
  handleRemoveBackgroundWithCanvas: () => void;
  handlerClearIllustration: () => void;
  handlerUploadImageLogo: (event: ChangeEvent<HTMLInputElement>) => void;
  triggerUpload: () => void;
  clearLogo: () => void;
  handlerSaveCanvas: () => void;
  businessList: SelectField[];
};

export const useConstructor: TFUseConstructor = ({
  width,
  height,
  handlerUploadFileLink,
  onClose,
}) => {
  const maxSizeFile = 1;

  const [canvasData, setCanvasData] = useState<TCanvasData>({
    canvas: null,
    backgroundImage: '',
    illustration: '',
    buttonLabel: '',
    logo: '',
    logoName: '',
    logoError: '',
    href: '',
  });

  const [mode] = useState<ReturnType<TFUseConstructor>['mode']>(() => {
    if (width === 1080 && height === 607) {
      return 'big';
    }
    return 'default';
  });
  const dispatch = useDispatchApp();
  const [openStyle, setOpenStyle] = useState(false);
  const [isOpenStyleIllustration, setIsOpenStyleIllustration] = useState(false);
  const container = useRef<HTMLCanvasElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const illustrationRef = useRef<HTMLDivElement>(null);
  const logoRef = useRef<HTMLDivElement>(null);
  const uploadInputRef = useRef<HTMLInputElement>(null);

  const { data: business } = useBusinessInfo();

  const businessList = useMemo<SelectField[]>(() => {
    if (!business) {
      return [];
    }
    return business.items.map((item) => ({
      value: item.key,
      label: item.title,
    }));
  }, [business]);

  const positionObject = useMemo<TPositionObject>(() => {
    if (mode === 'big') {
      return {
        illustrationLeft: width * 0.45,
        illustrationTop: 100,
        buttonLeft: 100,
        buttonTop: height - 200,
        logoLeft: 100,
        logoTop: 100,
        textLeft: 100,
        textTop: height - 300,
      };
    }
    return {
      illustrationLeft: 0,
      illustrationTop: 0,
      buttonLeft: 200,
      buttonTop: height - 200,
      logoLeft: 200,
      logoTop: 100,
      textLeft: 200,
      textTop: 314,
    };
  }, [mode, height, width]);

  const openStyleOn = useCallback(() => {
    setOpenStyle(true);
  }, []);
  const openStyleOff = useCallback(() => {
    setOpenStyle(false);
  }, []);

  const openStyleIllustrationOn = useCallback(() => {
    setIsOpenStyleIllustration(true);
  }, []);
  const openStyleIllustrationOff = useCallback(() => {
    setIsOpenStyleIllustration(false);
  }, []);

  const triggerUpload = () => {
    if (uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  };

  const clearLogo = () => {
    const { canvas } = canvasData;
    const temp = canvas?.getObjects().find(({ name }) => name === LOGO_KEY);
    if (temp) {
      canvas?.remove(temp);
    }
    setCanvasData((prev) => ({
      ...prev,
      canvas,
      logo: '',
      logoError: '',
      logoName: '',
    }));
  };

  /** функция удаляет фон canvas */
  const handleRemoveBackgroundWithCanvas: ReturnType<TFUseConstructor>['handleRemoveBackgroundWithCanvas'] =
    () => {
      const { canvas } = canvasData;
      if (canvas && canvas.backgroundImage) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        canvas.setBackgroundImage(null);
        canvas.renderAll();
        setCanvasData((prev) => ({ ...prev, backgroundImage: '', canvas }));
      }
    };

  /** функция добавляет фон для canvas */
  const handlerAddBackgroundToCanvas = useCallback(
    (url: string) => {
      const { canvas } = canvasData;
      handleRemoveBackgroundWithCanvas();
      if (canvas) {
        fabric.Image.fromURL(
          url,
          (img) => {
            canvas.setBackgroundImage(
              img,
              () => {
                canvas.renderAll();
              },
              {
                scaleX:
                  Number(canvas?.width) / (img && img.width ? img.width : 0),
                scaleY:
                  Number(canvas?.height) / (img && img.height ? img.height : 0),
              },
            );
          },
          { crossOrigin: 'anonymous' },
        );
        setCanvasData((prev) => ({ ...prev, backgroundImage: url, canvas }));
      }
    },
    [canvasData],
  );

  /** функция удаляет активный элемент */
  const deleteActiveObject = useCallback(() => {
    const { canvas } = canvasData;
    if (canvas) {
      canvas.getActiveObjects().forEach((object) => {
        if (object.name === TEXT_KEY) {
          textRef.current?.removeAttribute('hidden');
        }
        if (object.name === LOGO_KEY) {
          clearLogo();
          return;
        }
        canvas.remove(object);
      });
      setCanvasData((prev) => ({ ...prev, canvas }));
    }
  }, [canvasData]);

  /** функция вызывает удаление активного елемента по нажатия на Delete */
  const onHandleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'Delete') {
      deleteActiveObject();
    }
  };

  /** функция добавляет текст на canvas */
  const handleAddText = useCallback(
    (value: string, options: ITextOptions) => {
      const { canvas } = canvasData;
      if (canvas) {
        canvas.add(
          new fabric.IText(`${value || 'Текст'}`, {
            name: TEXT_KEY,
            fontFamily: 'Roboto',
            fill: 'white',
            fontSize: 29,
            padding: 25,
            left: 50,
            top: 50,
            borderColor: '#ddd',
            ...options,
          }),
        );
      }
      setCanvasData((prev) => ({ ...prev, canvas }));
    },
    [canvasData],
  );

  const handlerChangeBackground: ReturnType<TFUseConstructor>['handlerChangeBackground'] =
    useCallback(
      (url) => {
        handlerAddBackgroundToCanvas(url);
        openStyleOff();
      },
      [canvasData],
    );

  const handlerAddText = () => {
    handleAddText(i18n.t(`creative.constructor.write_text`), {
      left: positionObject.textLeft,
      top: positionObject.textTop,
    });
    if (textRef.current) {
      textRef.current.hidden = true;
    }
  };

  const onChangeButtons: ReturnType<TFUseConstructor>['onChangeButtons'] = (
    value,
  ) => {
    const { canvas } = canvasData;
    if (buttonRef.current) {
      buttonRef.current.hidden = true;
    }
    const temp = canvas?.getObjects().find(({ name }) => name === BUTTON_KEY);

    let PLeft = positionObject.buttonLeft;
    let PTop = positionObject.buttonTop;

    if (temp) {
      PTop = Number(temp.top);
      PLeft = Number(temp.left);
      canvas?.remove(temp);
    }

    fabric.Image.fromURL(buttonBg, (img) => {
      const text = new fabric.IText(value, {
        fill: 'black',
        padding: 25,
        fontSize: 23,
      });

      if (img && text) {
        text.left = Number(img.width) / 2 - Number(text.width) / 2;
        text.top = Number(img.height) / 2 - Number(text.height) / 2;
      }
      const group = new fabric.Group([img, text], {
        name: BUTTON_KEY,
        left: PLeft,
        top: PTop,
      });
      canvas?.add(group);
    });
    setCanvasData((prev) => ({ ...prev, buttonLabel: value, canvas }));
  };

  const handlerAddIllustration: ReturnType<TFUseConstructor>['handlerAddIllustration'] =
    useCallback(
      (url) => {
        if (illustrationRef.current) {
          illustrationRef.current.style.display = 'none';
        }
        const { canvas } = canvasData;
        const temp = canvas
          ?.getObjects()
          .find(({ name }) => name === ILLUSTRATION_KEY);

        let PLeft = positionObject.illustrationLeft;
        let PTop = positionObject.illustrationTop;

        if (temp) {
          PTop = Number(temp.top);
          PLeft = Number(temp.left);
          canvas?.remove(temp);
        }

        fabric.Image.fromURL(
          url,
          (img) => {
            canvas?.add(img);
          },
          {
            name: ILLUSTRATION_KEY,
            left: PLeft,
            top: PTop,
          },
        );

        setCanvasData((prev) => ({ ...prev, canvas, illustration: url }));
        openStyleIllustrationOff();
      },
      [canvasData, illustrationRef],
    );

  const handlerClearIllustration = useCallback(() => {
    const { canvas } = canvasData;
    if (canvas) {
      const temp = canvas
        .getObjects()
        .find(({ name }) => name === ILLUSTRATION_KEY);
      if (temp) {
        canvas.remove(temp);
      }
      setCanvasData((prev) => ({ ...prev, canvas, illustration: '' }));
    }
  }, [canvasData]);

  useEffect(() => {
    dispatch(fetchBusinessSphere({ key: 'BusinessSphere' }));
    const canvas = new fabric.Canvas('canvas', {
      backgroundColor: '#f4f5f6',
      preserveObjectStacking: true,
      height,
      width,
    });
    setCanvasData((prev) => ({ ...prev, canvas }));
  }, []);

  const handlerUploadImageLogo: ReturnType<TFUseConstructor>['handlerUploadImageLogo'] =
    ({ target }) => {
      const { canvas } = canvasData;
      if (canvas && target.files?.length) {
        const currentFile = target.files[0];
        if (currentFile) {
          if (!['image/png', 'image/jpeg'].includes(currentFile.type)) {
            setCanvasData((prev) => ({
              ...prev,
              logoError: i18n.t('errors.err2034'),
            }));
            return;
          }
          if (currentFile.size > maxSizeFile * 1024 * 1024) {
            setCanvasData((prev) => ({
              ...prev,
              logoError: `${i18n.t('errors.err2032')} 2 Mb`,
            }));
            return;
          }
        }
        if (logoRef.current) {
          logoRef.current.style.display = 'none';
        }
        const temp = canvas?.getObjects().find(({ name }) => name === LOGO_KEY);

        let PLeft = positionObject.logoLeft;
        let PTop = positionObject.logoTop;

        if (temp) {
          PTop = Number(temp.top);
          PLeft = Number(temp.left);
          canvas?.remove(temp);
        }

        const url = URL.createObjectURL(currentFile);
        fabric.Image.fromURL(
          url,
          (img) => {
            canvas.add(img);
          },
          {
            name: LOGO_KEY,
            left: PLeft,
            top: PTop,
          },
        );
        setCanvasData((prev) => ({
          ...prev,
          canvas,
          logo: url,
          logoName: currentFile.name,
          logoError: '',
        }));
      }
    };

  function b64DecodeUnicode(str: string): Blob {
    const mime = str.split(',')[0].split(':')[1].split(';')[0];
    const bytes = atob(str.split(',')[1]);
    const len = bytes.length;
    const buffer = new window.ArrayBuffer(len);
    const arr = new window.Uint8Array(buffer);

    for (let i = 0; i < len; i += 1) {
      arr[i] = bytes.charCodeAt(i);
    }

    return new Blob([arr], { type: mime });
  }

  /** функция скачаивает изображение на canvas */
  const handlerSaveCanvas = () => {
    const { canvas } = canvasData;
    if (canvas) {
      const base64 = canvas.toDataURL({
        format: 'png',
        quality: 1,
      });
      handlerUploadFileLink(b64DecodeUnicode(base64));
      onClose();
    }
  };

  useEffect(() => {
    if (canvasData.canvas) {
      document.addEventListener('keydown', onHandleKeyDown);
    }
    return () => {
      if (canvasData.canvas) {
        document.removeEventListener('keydown', onHandleKeyDown);
      }
    };
  }, [canvasData.canvas]);

  return {
    iconsArray: mode === 'big' ? iconsBigArray : iconsSmallArray,
    openStyle,
    openStyleOn,
    openStyleOff,
    container,
    canvasData,
    handlerChangeBackground,
    textRef,
    buttonRef,
    illustrationRef,
    handlerAddText,
    onChangeButtons,
    mode,
    isOpenStyleIllustration,
    openStyleIllustrationOff,
    openStyleIllustrationOn,
    handlerAddIllustration,
    handleRemoveBackgroundWithCanvas,
    handlerClearIllustration,
    handlerUploadImageLogo,
    logoRef,
    uploadInputRef,
    triggerUpload,
    clearLogo,
    handlerSaveCanvas,
    businessList,
  };
};
