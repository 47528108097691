import React, { FC } from 'react';
import Card from 'components/UI/Card';
import PriceInput from 'components/UI/PriceInput';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Loader from 'components/UI/Loader';
import { FormikProps } from 'formik';
import css from './styles.module.scss';

interface FormProps {
  from: number;
  to: number;
}

interface Params {
  isLoadingData: boolean;
  formik: FormikProps<FormProps>;
}

const CalculationCoefficientsBlock: FC<Params> = ({
  isLoadingData,
  formik,
}) => {
  const { t } = useTranslation();

  return (
    <Card noHover isUseAccess>
      {isLoadingData ? (
        <Loader />
      ) : (
        <>
          <h3 className={css.card_title}>
            {t(`campaigns_page.campaign_settings.additional.method`)}
          </h3>

          <div className={css.rows}>
            <div
              className={cn(css.period, {
                _isErrors:
                  (!!formik.errors.from && !!formik.touched.from) ||
                  (!!formik.errors.to && !!formik.touched.to),
              })}
            >
              <div className={css.label_row}>
                {t(`campaigns_page.campaign_settings.additional.range`)} *
              </div>
              <div className={css.label_field}>
                {t(`campaigns_page.campaign_settings.additional.from`)}
              </div>
              <PriceInput
                classname={css.number_format}
                formik={formik}
                decimalScale={3}
                theme="horizontal"
                allowNegative
                error={!!formik.errors.from && !!formik.touched.from}
                errortext={formik.errors.from}
                value={formik.values.from}
                handleBlur={() => formik.setFieldTouched('from', true)}
                onValueChange={({ floatValue }) => {
                  formik.setFieldValue(`from`, floatValue);
                }}
              />
              <div className={css.label_field}>
                {t(`campaigns_page.campaign_settings.additional.to`)}
              </div>
              <PriceInput
                classname={css.number_format}
                formik={formik}
                decimalScale={3}
                theme="horizontal"
                allowNegative
                error={!!formik.errors.to && !!formik.touched.to}
                errortext={formik.errors.to}
                value={formik.values.to}
                handleBlur={() => formik.setFieldTouched('to', true)}
                onValueChange={({ floatValue }) => {
                  formik.setFieldValue(`to`, floatValue);
                }}
              />
            </div>
          </div>
        </>
      )}
    </Card>
  );
};

export default CalculationCoefficientsBlock;
