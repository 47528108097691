import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Helmet } from 'react-helmet';
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import cn from 'classnames';
import CardsList from 'components/UI/CardList';
import Card from 'components/UI/Card';
import { useDispatchApp } from 'redux/rootSelectors';
import { FiAlertCircle } from 'react-icons/fi';
import CalculationCoefficientsBlock from 'components/CalculationCoefficientsBlock';
import { Trans, useTranslation } from 'react-i18next';
import { FormikErrors, useFormik } from 'formik';
import * as Yup from 'yup';
import css from './styles.module.scss';
import { useGetGroupList } from '../LimitGroups/hooks/useGetGroupList';
import { editClientSettings } from '../reduser';

interface FormProps {
  from: number;
  to: number;
}

const Settings: FC = () => {
  const { clientInfo, isLoading: isLoadingClientInfo } = useGetGroupList();
  const { t } = useTranslation();
  const dispatch = useDispatchApp();

  const submitHandler = (
    values: FormProps,
    callbacks: Record<string, Dispatch<SetStateAction<boolean>>>,
  ) => {
    if (!clientInfo) return;
    dispatch(
      editClientSettings({
        id: clientInfo.id,
        idAgency: clientInfo.parent_id,
        range_calculation_coefficients: values,
        callbacks,
      }),
    );
  };

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik<FormProps>({
    enableReinitialize: true,
    initialValues: {
      from: clientInfo?.range_calculation_coefficients?.from || 0,
      to: clientInfo?.range_calculation_coefficients?.to || 0,
    },
    validationSchema: Yup.object({
      from: Yup.number().required(t('clients_page.client_create.hint1')),
      to: Yup.number().required(t('clients_page.client_create.hint1')),
    }),
    validate: (values: FormProps) => {
      const errors: FormikErrors<FormProps> = {};

      if (values.from > values.to) {
        errors.from = t(
          `campaigns_page.campaign_settings.additional.maxToBeMax`,
        );
        errors.to = t(`campaigns_page.campaign_settings.additional.maxToBeMax`);
      }

      return errors;
    },
    onSubmit: (values) => {
      const callbacks = {
        setIsLoading,
      };
      submitHandler(values, callbacks);
    },
  });

  return (
    <div className={css.settings}>
      <Helmet>
        <title>{t(`project_page.settings`)}</title>
      </Helmet>
      <div className={css.header}>
        <div className="container">
          {clientInfo && !isLoadingClientInfo && (
            <Breadcrumbs type="Client" xxhash={clientInfo.id} />
          )}
          <div className={css.status}>
            <h1 className={css.title}>{clientInfo?.title}</h1>
          </div>
        </div>
      </div>
      <div className={css.body}>
        <div className={cn(css.container, 'container')}>
          <CardsList>
            <CalculationCoefficientsBlock
              formik={formik}
              isLoadingData={isLoading}
            />
          </CardsList>
          <CardsList>
            <Card noHover isUseAccess>
              <div className={css.info}>
                <FiAlertCircle size={20} />
                <div className={css.info_text}>
                  <p>
                    {t(`campaigns_page.campaign_settings.additional.info_1`)}
                  </p>
                  <p>
                    <Trans
                      i18nKey="campaigns_page.campaign_settings.additional.info_2"
                      values={{
                        method: 'method_recalculating_statistics_CAMPAIGN_TYPE',
                      }}
                      components={{ bold: <strong /> }}
                    />
                  </p>
                </div>
              </div>
            </Card>
          </CardsList>
        </div>
      </div>
    </div>
  );
};

export default Settings;
