import { IError, IGroupItem } from 'domains/client/types';
import React, { FC, useState } from 'react';
import { FiRotateCcw } from 'react-icons/fi';
import cn from 'classnames';
import { useIsGroup } from 'domains/user/hooks';
import { useRemoveGroup } from '../hooks/useRemoveGroup';
import Item from '../Item';
import LimitGroupsClientModal from '../modal';
import css from './styles.module.scss';
import i18n from '../../../../i18n';
import { useRemoveCustomErrors } from '../hooks/useRemoveCustomErrors';

interface groupProps {
  groupInfo: IGroupItem;
  setGropsInRemoveQueue: React.Dispatch<React.SetStateAction<string[]>>;
  error?: IError;
}

const Group: FC<groupProps> = ({ groupInfo, setGropsInRemoveQueue, error }) => {
  const [isOpenEditGroupModal, setIsOpenEditGroupModal] = useState(false);
  const { remove, counter, setCounter, setIsTimerStart, toggleRemove } =
    useRemoveGroup(groupInfo, setGropsInRemoveQueue);
  const { removeCustomErrors } = useRemoveCustomErrors();
  const { isLocked } = useIsGroup();

  return (
    <div
      className={cn(css.group, {
        isLocked,
      })}
    >
      <div className={css.group_title}>{groupInfo.group.title}</div>
      <div className={css.group_parametrs}>
        <div>
          {i18n.t(`campaigns_page.campaign_settings.per_user`)}:
          {groupInfo.group.limits.show_per_user.day || '-'}/
          {groupInfo.group.limits.show_per_user.total || '-'}
        </div>
        <div>
          {i18n.t(`campaigns_page.campaign_settings.Impressions`)}:{' '}
          {groupInfo.group.limits.show.day || '-'}/
          {groupInfo.group.limits.show.total || '-'}
        </div>
        <div>
          {i18n.t(`campaigns_page.campaign_settings.Clicks`)}:{' '}
          {groupInfo.group.limits.click.day || '-'}/
          {groupInfo.group.limits.click.total || '-'}
        </div>
        <div>
          {i18n.t(`campaigns_page.campaign_settings.Total_raw`)}, ₽:{' '}
          {groupInfo.group.limits.budget.day || '-'}/
          {groupInfo.group.limits.budget.total || '-'}
        </div>
      </div>
      <div className={css.group_items}>
        {groupInfo.campaigns.map((campaign) => (
          <Item
            id={campaign.xxhash}
            key={campaign.internal_id}
            internal_id={campaign.internal_id}
            name={campaign.title}
            readOnly
          />
        ))}
      </div>
      <div className={css.actions}>
        {remove ? (
          <div
            className={css.button}
            onClick={() => {
              setCounter(3);
              setIsTimerStart(true);
              toggleRemove();
              setGropsInRemoveQueue((prev) => [...prev, groupInfo.group.title]);
            }}
          >
            {i18n.t(`delete_btn`)}
          </div>
        ) : (
          <div
            className={css.button}
            onClick={() => {
              setCounter(3);
              setIsTimerStart(false);
              toggleRemove();
              setGropsInRemoveQueue((prev) =>
                prev.filter((item) => item !== groupInfo.group.title),
              );
            }}
          >
            <FiRotateCcw size={14} />
            {i18n.t(`clients_page.limit_groups.cancel_del`)} {`(${counter}s)`}
          </div>
        )}
        <div
          onClick={() => setIsOpenEditGroupModal(true)}
          className={css.button}
        >
          {i18n.t(`edit_btn`)}
        </div>
      </div>
      {isOpenEditGroupModal && (
        <LimitGroupsClientModal
          open={isOpenEditGroupModal}
          error={error}
          onClose={() => {
            setIsOpenEditGroupModal(false);
            removeCustomErrors();
          }}
          groupInfo={groupInfo}
        />
      )}
    </div>
  );
};

export default Group;
