export const ErrorsMap: ReadonlyMap<number, string> = new Map([
  [48, 'campaigns_page.camp_hint.hint1'],
  [40, 'errors.err40_1'],
  [41, 'errors.err41'],
  [42, 'errors.err42'],
  [43, 'errors.err43'],
  [44, 'errors.err44'],
  [45, 'errors.err45'],
  [46, 'errors.err46'],
  [47, 'errors.err47'],
  [48, 'errors.err48'],
  [49, 'errors.err49'],
  [222, 'errors.err222'],
  [223, 'errors.err223'],
  [90, 'errors.err90'],
  [91, 'errors.err91'],
  [92, 'errors.err92'],
  [93, 'errors.err93'],
  [94, 'errors.err94'],
  [100, 'errors.err100'],
  [101, 'errors.err101'],
  [102, 'errors.err102'],
  [103, 'errors.err103'],
  [104, 'errors.err104'],
  [105, 'errors.err105'],
  [109, 'errors.err109'],
  [121, 'errors.err121'],
  [201, 'errors.err201'],
  [202, 'errors.err202'],
  [220, 'errors.err220'],
  [221, 'errors.err221'],
  [203, 'errors.err203'],
  [219, 'errors.err219'],
  [223, 'errors.err223'],
  [224, 'errors.err224'],
  [225, 'errors.err225'],
  [227, 'errors.err227'],
  [900, 'errors.err900'],
  [400, 'errors.err400'],
]);

export const ErrorDescription: ReadonlyMap<string, string> = new Map([
  [
    'the days_of_week_user target is already set up',
    'errors_desc.days_of_week_user.t1',
  ],
  [
    'the days_of_week_user target is already set up in Campaign',
    'errors_desc.days_of_week_user.t2',
  ],
  [
    'the hours_of_day target is already set up',
    'errors_desc.hours_of_day.t1',
  ],
  [
    'the hours_of_day target is already set up in Campaign',
    'errors_desc.hours_of_day.t2',
  ],
  [
    'the hours_of_day target is already set up in Creative',
    'errors_desc.hours_of_day.t3',
  ],
  [
    'the days_of_week target is already set up',
    'errors_desc.days_of_week.t1',
  ],
  [
    'the days_of_week target is already set up in Campaign',
    'errors_desc.days_of_week.t2',
  ],
  [
    'the days_of_week target is already set up in Creative',
    'errors_desc.days_of_week.t3',
  ],
  [
    'the days_of_week_user target is already set up in Creative',
    'errors_desc.days_of_week_user.t3',
  ],
  [
    'the hours_of_day_user target is already set up',
    'errors_desc.hours_of_day_user.t1',
  ],
  [
    'the hours_of_day_user target is already set up in Creative',
    'errors_desc.hours_of_day_user.t3',
  ],
  [
    'the hours_of_day_user target is already set up in Campaign',
    'errors_desc.hours_of_day_user.t2',
  ],
  [
    'Организация с таким значением inn уже существует',
    `ord.errors.already_exists`,
  ],
  [
    'inn должно содержать валидный ИНН',
    `ord.errors.inn_invalid`,
  ],
  [
    'Заказчик и исполнитель должны относится к тому же ОРД что и договор',
    `ord.errors.cus_refer`,
  ],
  [
    'ФИО должно состоять из кириллицы и содержать между словами один пробел либо тире',
    `ord.errors.should_written`,
  ],
  [
    'Неподдерживаемый тип договора для ОРД',
    `ord.errors.unsupported_contract`,
  ]
]);
