import React, { FC, useRef, useState } from 'react';
import { FiDownload, FiRefreshCcw, FiSettings } from 'react-icons/fi';
import cn from 'classnames';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useUserTheme } from 'domains/user/model/selectors';
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import Tooltip from 'components/UI/Tooltip';
import DateRangeFilter from 'components/DateRangeFilter';
import { parsingDate } from 'utils/momentParse';
import moment from 'moment';
import { useShowPanels } from 'utils/useShowPanels';
import ReportTableAu from 'components/ReportTableAu/index';
import { useClickOutside } from 'utils/useClickOutside';
import { useParams } from 'react-router';
import css from './styles.module.scss';
import SettingsPanel from './SettingsPanel';
import { useReportsAu } from '../hooks/useReportsAu';
import { EClientsStatuses } from '../types';

const ReportAu: FC = () => {
  const { t } = useTranslation();
  const { isThemeExtended, isThemeFull } = useUserTheme();
  const controlRef = useRef<HTMLDivElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const filtersSettingsRef = useRef<HTMLDivElement>(null);
  const { id: partner_xxhash } = useParams<{ id: string }>();

  const {
    setStatuses,
    statuses,
    settings,
    onUpdateDate,
    totalData,
    setUpdate,
    transformReportsAu,
    getAuditTitles,
    statisticFieldList,
    getInternalIdObj,
    loadingReports,
  } = useReportsAu();

  const [openSettings, setOpenSettings] = useState<boolean>(false);

  const { positionX, positionY } = useShowPanels({
    controlRef,
    panelRef,
    openPanel: openSettings,
    closePanel: () => setOpenSettings(false),
    targetX: 'LEFT',
    targetY: 'BOTTOM',
  });

  useClickOutside({
    targetRef: filtersSettingsRef,
    closePanel: () => setOpenSettings(false),
  });

  const setStatusFilter = (filter: EClientsStatuses) => {
    setStatuses((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return prevFilters.filter((f) => f !== filter);
      }
      return [...prevFilters, filter];
    });
  };

  return (
    <div className={css.report}>
      <Helmet>
        <title>{t(`agencies_page.report.title`)}</title>
      </Helmet>
      <div className={cn(css.header)}>
        <div
          className={cn('container resized', {
            extend: isThemeExtended,
            full: isThemeFull,
          })}
        >
          <Breadcrumbs type="Client" xxhash={partner_xxhash} />
          <div className={css.status}>
            <h1 className={css.title}>{t(`agencies_page.report.title`)}</h1>
            <div className={css.actions}>
              <div className={css.update}>
                <Tooltip
                  bottom
                  title={<FiRefreshCcw size={22} />}
                  onClick={() => setUpdate(true)}
                >
                  {t(`update_btn`)}
                </Tooltip>
              </div>

              <div className={css.update}>
                <Tooltip title={<FiDownload size={22} />} bottom isDisabled>
                  {t(`download_btn`)}
                </Tooltip>
              </div>
              <div className={css.range}>
                <DateRangeFilter
                  range={{
                    from: parsingDate(settings.period.from).format(
                      'YYYY-MM-DD',
                    ),
                    to: parsingDate(settings.period.to).format('YYYY-MM-DD'),
                  }}
                  dateStart={parsingDate(
                    moment('1980-01-01').format('YYYY-MM-DD'),
                  ).toDate()}
                  dateEnd={parsingDate(new Date()).toDate()}
                  initialTitleDay={settings.type}
                  onSetDateRange={onUpdateDate}
                  hideAllTime
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={cn('container resized', {
          extend: isThemeExtended,
          full: isThemeFull,
        })}
      >
        <div className={css.filters}>
          <div className={css.filters_tabs}>
            <div
              className={cn(css.filters_tabs_item, {
                _isNoActive: !statuses.includes(EClientsStatuses.LAUNCHED),
              })}
              onClick={() => setStatusFilter(EClientsStatuses.LAUNCHED)}
            >
              {t(`campaigns_page.campaigns_black_header_main.act`)}
            </div>
            <div
              className={cn(css.filters_tabs_item, {
                _isNoActive: !statuses.includes(EClientsStatuses.STOPPED),
              })}
              onClick={() => setStatusFilter(EClientsStatuses.STOPPED)}
            >
              {t(`campaigns_page.campaigns_black_header_main.pause`)}
            </div>
            <div
              className={cn(css.filters_tabs_item, {
                _isNoActive: !statuses.includes(EClientsStatuses.ARCHIVE),
              })}
              onClick={() => setStatusFilter(EClientsStatuses.ARCHIVE)}
            >
              {t(`campaigns_page.campaigns_black_header_main.arch`)}
            </div>
          </div>
          <div
            className={cn(css.filters_settings, css.settings)}
            ref={filtersSettingsRef}
          >
            <div
              className={css.settings_btn}
              onClick={() => setOpenSettings(!openSettings)}
              ref={controlRef}
            >
              <FiSettings size={20} />
            </div>
            <SettingsPanel
              openSettings={openSettings}
              positionY={positionY}
              positionX={positionX}
              panelRef={panelRef}
              statisticFieldList={statisticFieldList}
              setOpenSettings={setOpenSettings}
            />
          </div>
        </div>
        <div className={css.content}>
          <ReportTableAu
            tableTitle={t('campaigns_page.campaign_settings.reports.stat_tit')}
            totalData={totalData}
            transformReportsAu={transformReportsAu}
            getAuditTitles={getAuditTitles}
            getInternalIdObj={getInternalIdObj}
            loadingReports={loadingReports}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportAu;
