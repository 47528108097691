import React from 'react';
import cn from 'classnames';
import { FiChevronDown } from 'react-icons/fi';
import css from './styles.module.scss';
import useDropDownSideMenu from './hooks/useDropDownSideMenu';

type Props = {
  menuIcon: JSX.Element;
  menuText: JSX.Element;
  selectedItem: string;
  showSelectedItem: boolean;
  toggleSide: boolean;
};

const Dropdown: React.FC<Props> = ({
  menuIcon,
  menuText,
  children,
  selectedItem,
  toggleSide,
  showSelectedItem = false,
}) => {
  const { isOpened, toggleOff, toggle } = useDropDownSideMenu(toggleSide);

  return (
    <div className={css.dropdown}>
      <div
        className={cn(css.menu, {
          isActive: showSelectedItem,
          isOpened,
        })}
        onClick={isOpened ? toggleOff : toggle}
      >
        <div className={css.icon}>{menuIcon}</div>
        <div className={css.text}>
          <div className={css.title}>{menuText}</div>
          {showSelectedItem && (
            <div className={css.subtitle}>{selectedItem}</div>
          )}
        </div>
        <div className={css.chevron}>
          <FiChevronDown size={24} />
        </div>
      </div>
      <div className={cn(css.list, { isOpened })}>
        {children &&
          React.Children.map(children, (child) => (
            <div className={css.item}>{child}</div>
          ))}
      </div>
    </div>
  );
};

export default Dropdown;
