

import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import { addOffer, countCreativesOffer, editOffer, getOfferInfo } from '../actions';
import { workerAddOfferSaga, workerCountCreativesOfferSaga, workerEditOfferSaga, workerGetOfferInfoSaga } from './workers';

export default function* watchOffer(): SagaIterator {
  yield takeLatest(getOfferInfo, workerGetOfferInfoSaga);
  yield takeLatest(addOffer, workerAddOfferSaga);
  yield takeLatest(editOffer, workerEditOfferSaga);
  yield takeLatest(countCreativesOffer, workerCountCreativesOfferSaga);
}