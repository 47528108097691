import React, { FC } from 'react';
import css from './styles.module.scss';
import { useLogoTypeInfo } from './hook';

const LogoType: FC = () => {
  const { logo } = useLogoTypeInfo();

  if (logo) {
    return (
      <div className={css.wrapper}>
        <div
          className={css.logo}
          style={{
            backgroundImage: `url("${logo}")`,
          }}
        />
      </div>
    );
  }
  return <></>;
};

export default LogoType;
