import React from 'react';
import copy from 'copy-to-clipboard';
import cn from 'classnames';
import { FiX, FiCopy } from 'react-icons/fi';
import ModalOverlay from 'components/Modals/ModalOverlay1';
import Tooltip from 'components/UI/Tooltip';
import i18n from '../../i18n';
import css from './styles.module.scss';
import { toastSuccess } from '../../utils/ToastUtils/toastUtils';

type ContextModalProps = {
  item: { title: string; items: string };
  onClose: () => void;
  isOpen: boolean;
  alertText: string;
};

const ContextModal: React.FC<ContextModalProps> = ({
  onClose,
  item,
  isOpen,
  alertText,
}) => (
  <ModalOverlay onCloseModal={onClose} isOpen={isOpen}>
    <div className={css.card}>
      <div className={css.header}>
        <div className={css.title}>
          <h1>{item.title}</h1>
        </div>
        <div className={cn(css.more)}>
          <Tooltip
            title={
              <FiCopy
                size={24}
                color="#222"
                onClick={() => {
                  copy(item.items);
                  toastSuccess(alertText);
                }}
              />
            }
          >
            {i18n.t(`common.modal.copy_list`)}
          </Tooltip>
        </div>
        <div className={css.close}>
          <FiX size={24} onClick={onClose} />
        </div>
      </div>
      <div className={css.content}>{item.items}</div>
    </div>
  </ModalOverlay>
);

export default ContextModal;
