import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { FiMoreHorizontal, FiTrash2 } from 'react-icons/fi';
import { RiEditLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/UI/Tooltip';
import OnlyOwner from 'components/OnlyOwner';
import moment from 'moment';
import ToggleSwitch from 'components/UI/ToggleSwitch';
import { ReactSVG } from 'react-svg';
import Load from 'components/UI/Load';
import Checkbox from 'components/UI/Checkbox/checkbox';
import i18next from 'i18next';
import { RBreadCrumbs } from 'domains/search/model/types';
import { ScrollSyncNode } from 'scroll-sync-react';
import { useSearchBreadcrumbsInfo } from 'domains/search/model/selectors';
import Translation from '../../../components/Translation/TranslationTooltip';
import Statistics from './Statistics';
import css from './styles.module.scss';
import { DataCampaignsStatisticType } from '../model/hooks/useDataCampaignsStatisticAndTranslation';
import fromArchiveSvg from '../../../assets/fromarchive.svg';
import toArchiveSvg from '../../../assets/toarchive.svg';
import ModalWithQuestion from '../../modals/modalWithQuestion';
import avatarColors from '../../../utils/avatarColors';
import {
  THandleChangeArchive,
  THandleChangeCampaignStatus,
  THandleRemoveCampaigns,
} from '../model/hooks/useFetchDataCampaigns';
import { TStatusCampaign } from '../../campaign/types';
import {
  getDateStatisticsType,
  INewDate,
  ResponseCampaignsStata,
} from '../types';
import { useUserInfo, useUserTheme } from '../../user/model/selectors';
import DuplicateSvg from '../../../assets/duplicate-icon.svg';
import CopySvg from '../../../assets/copy-icon.svg';
import { useDispatchApp } from '../../../redux/rootSelectors';
import { cloneCampaignsToClient, doubleCampaigns } from '../model/actions';
import CopyModal from '../../../components/CopyModal';
import { PSagaCopyElement } from '../../../types/general';
import { IFilterColumns } from '../model/hooks/useGetFilterForCampaigns';
import IconsModal from './IconsModal';

type Props = {
  client_id: string;
  onlyCurrent: boolean;
  item: ResponseCampaignsStata;
  isLastEdited: boolean;
  paramsCampaignsStatus: TStatusCampaign;
  toggle: string;
  setToggle: (v: string) => void;
  isOpenModalArchive: string | boolean;
  setOpenModalArchive: (v: string | boolean) => void;
  handleChangeArchive: (v: THandleChangeArchive) => void;
  openDelete: string | boolean;
  setOpenDelete: (v: string | boolean) => void;
  handleChangeCampaignStatus: (v: THandleChangeCampaignStatus) => void;
  handleRemoveCampaigns: (v: THandleRemoveCampaigns) => void;
  isFull: boolean;
  handlerCampaignChecked: (
    value: boolean,
    xxhash: string,
    title: string,
    bread_crumbs: RBreadCrumbs | null,
    internal_id?: string,
  ) => void;
  defineCheckedCampaign: (xxhash: string) => boolean;
  statAndTranlationData: DataCampaignsStatisticType;
  filterColumns: IFilterColumns[];
  sortCampaign: 'ASC' | 'DESC' | null;
  periodType: string;
};

const Item: FC<Props> = ({
  onlyCurrent,
  isLastEdited,
  client_id,
  item,
  paramsCampaignsStatus,
  setToggle,
  toggle,
  isOpenModalArchive,
  setOpenModalArchive,
  handleChangeArchive,
  openDelete,
  setOpenDelete,
  handleChangeCampaignStatus,
  handleRemoveCampaigns,
  isFull,
  handlerCampaignChecked,
  defineCheckedCampaign,
  statAndTranlationData,
  filterColumns,
  sortCampaign,
  periodType,
}) => {
  const dispatch = useDispatchApp();
  const { data: bread_crumbs } = useSearchBreadcrumbsInfo();
  const { xxhash, title, status, pay_type, internal_id, icon } = item;
  const [activeStatus, setStatus] = useState<boolean | string>(
    status === 'LAUNCHED',
  );

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isCamapingsGeneralList = !params.get('client_id');

  const [openIconsModal, setOpenIconsModal] = useState(false);
  const imgRef = useRef<HTMLDivElement | null>(null);
  const [iconEdit, setIconEdit] = useState(false);
  const colorForImg = {
    '--color-for-img': !icon
      ? avatarColors(title.trim().slice(0, 1).toLowerCase())
      : '',
  } as React.CSSProperties;

  const handlerCopyCampaignsOtherClient: (
    param: Omit<PSagaCopyElement, 'from'>,
  ) => void = useCallback((payload) => {
    dispatch(cloneCampaignsToClient({ from: [{ xxhash, title }], ...payload }));
  }, []);

  const [isModalCopy, setIsModalCopy] = useState(false);
  const [disableDuplicateCompainsBtn, setDisableDuplicateCompainsBtn] =
    useState(false);

  const valueContainersRef = useRef<HTMLDivElement>(null);
  /* При фильрации списка кампаний, принудительно скроллим влево значения таблицы */
  useEffect(() => {
    setTimeout(() => {
      if (valueContainersRef.current) {
        valueContainersRef.current.scrollLeft = 0;
      }
    }, 50);
  }, [sortCampaign]);

  const { t } = useTranslation();

  const {
    statistics,
    statisticsWeek,
    isLoadingWeek,
    isLoadingStata,
    translation,
    isError,
  } = statAndTranlationData;

  const { getValueFromSettingForKey } = useUserTheme();

  const { data: user } = useUserInfo();

  const currentStatistic = useMemo(() => {
    if (!statistics) return null;
    if (!Object.keys(statistics).length) return null;
    return statistics[xxhash];
  }, [statistics]);

  const getDateStatistics: getDateStatisticsType = (key: string) => {
    /* if (statisticsWeek && !(statisticsWeek.length > 0)) return; */

    const curr =
      statisticsWeek && statisticsWeek.find((e) => e.xxhash === xxhash);

    if (!curr) {
      // eslint-disable-next-line consistent-return
      return Array(7)
        .fill('')
        .map(() => ({
          date: '',
          count: 0,
        })) as INewDate[];
    }

    const keys: string[] = Object.keys(curr.items).sort(
      (a: string, b: string) => +moment(a) - +moment(b),
    );

    // eslint-disable-next-line consistent-return
    return keys.map((e) => ({
      date: (e as string) || '',
      count: (curr.items[e][key] as number) || 0,
    }));
  };

  const checkIsErrorToShowLoadOrToltip = () =>
    isError ? (
      <Translation data={translation && translation[xxhash]} error={isError} />
    ) : (
      <Load style={{ width: '30px', margin: '0 auto' }} />
    );

  return (
    <div className={cn(css.wrapper, { isLastEdited })}>
      <div className={css.indicator} />

      <div
        className={cn(css.item, {
          _isOpen: toggle === xxhash && status !== 'DELETED',
          _isDeleted: status === 'DELETED',
          // _isFullTitle: isFull,
          _isFull: isFull,
          _isStatus: getValueFromSettingForKey('ShowBroadcastStatus'),
        })}
      >
        <div className={css.tabelHeaderLeft}>
          <OnlyOwner>
            <Checkbox
              label=""
              className={css.checkbox}
              inputAttributes={{
                type: 'checkbox',
                checked: defineCheckedCampaign(xxhash),
                onChange: () => {
                  handlerCampaignChecked(
                    !defineCheckedCampaign(xxhash),
                    xxhash,
                    title,
                    bread_crumbs,
                    internal_id,
                  );
                },
              }}
              onlyBox
            />
          </OnlyOwner>
          <div
            className={css.toggle__status}
            onMouseEnter={() => {
              if (!toggle) {
                setToggle('');
              } else {
                setToggle(xxhash);
              }
            }}
            onMouseLeave={() => {
              if (!toggle) {
                setToggle('');
              }
            }}
          >
            <OnlyOwner>
              <ToggleSwitch
                onChange={() =>
                  handleChangeCampaignStatus({
                    setStatus,
                    activeStatus,
                    xxhash,
                  })
                }
                checked={Boolean(activeStatus)}
                toggleOnly
                disabled={status === 'ARCHIVE' || status === 'DELETED'}
              />
            </OnlyOwner>
          </div>
          {status !== 'DELETED' && (
            <Link to={`/main/campaign/${xxhash}/main`} />
          )}
          <div
            className={cn(css.item__header, {
              _isFull: isFull,
            })}
          >
            <div
              className={cn(css.img, {
                _thereIsNoIcon: !icon,
              })}
              style={colorForImg || '#ccc'}
              onClick={() => setOpenIconsModal(!openIconsModal)}
              ref={imgRef}
              onMouseEnter={() => setIconEdit(true)}
              onMouseLeave={() => setIconEdit(false)}
            >
              {
                // eslint-disable-next-line no-nested-ternary
                iconEdit ? (
                  <RiEditLine size={20} color={icon ? '#222' : '#fff'} />
                ) : icon ? (
                  <img src={icon} alt={icon} />
                ) : (
                  <span>{title.trim().slice(0, 1).toUpperCase()}</span>
                )
              }
              <div
                className={css.status}
                style={{
                  background:
                    // eslint-disable-next-line no-nested-ternary
                    status !== 'DELETED'
                      ? activeStatus
                        ? '#149C08'
                        : '#FF9C08'
                      : '#FF3636',
                }}
              />
            </div>
            <div className={css.text}>
              <Tooltip
                className={css.tooltip}
                hintWidth={300}
                leftArrowPosition
                title={
                  <Link to={`/main/campaign/${xxhash}`}>
                    <strong>{title}</strong>
                  </Link>
                }
              >
                <p>{title}</p>
              </Tooltip>

              <Link to={`/main/campaign/${xxhash}`}>
                <div className={css.hint}>
                  <span className={css.advertiser}>{pay_type}</span>
                  <span>{internal_id}</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <ScrollSyncNode group="a">
          <div
            className={cn(
              css.valuesContainer,
              `length-${filterColumns.length - 1}`,
            )}
            ref={valueContainersRef}
          >
            {filterColumns.map(
              (key, index) =>
                index > 0 && (
                  <div
                    className={cn(css.value, `size-${key.lengthTitle}`, {
                      _isWheek: onlyCurrent,
                      _isGrey: key.name.trim().toLocaleLowerCase() === 'bid',
                    })}
                    key={key.data}
                  >
                    {isLoadingStata || isLoadingWeek ? (
                      <Load />
                    ) : (
                      <Statistics
                        data={getDateStatistics(key.data)}
                        periodType={periodType}
                        currentValue={
                          (currentStatistic && currentStatistic[key.data]) ||
                          '_'
                        }
                        onlyCurrent={onlyCurrent}
                      />
                    )}
                  </div>
                ),
            )}
          </div>
        </ScrollSyncNode>

        <div className={css.tableHeaderRight}>
          {getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
            !getValueFromSettingForKey('ShowBroadcastStatus') &&
            (translation ? (
              <Translation data={translation && translation[xxhash]} />
            ) : (
              checkIsErrorToShowLoadOrToltip()
            ))}

          <div className={css.actions}>
            <div
              className={cn('toggle-dropdown', css.dropdown, {
                _isOpen: toggle === xxhash && status !== 'DELETED',
                _isDeleted: status === 'ARCHIVE' || status === 'DELETED',
                _isNoAnimate: getValueFromSettingForKey('animation'),
              })}
              onMouseEnter={() => {
                setToggle(xxhash);
              }}
              onMouseLeave={() => {
                setToggle('');
              }}
            >
              <button
                type="button"
                className={css.dropdown__show}
                disabled={!isLoadingStata || !isLoadingWeek}
              >
                <FiMoreHorizontal size={24} />
              </button>

              {status !== 'DELETED' && (
                <div className={css.dropdown__menu}>
                  {toggle === xxhash && (
                    <>
                      <OnlyOwner>
                        <Tooltip
                          onClick={() => setOpenModalArchive(xxhash)}
                          title={
                            status === 'ARCHIVE' ? (
                              <ReactSVG src={fromArchiveSvg} />
                            ) : (
                              <ReactSVG src={toArchiveSvg} />
                            )
                          }
                        >
                          <span>
                            {status === 'ARCHIVE'
                              ? t('archive_title_from')
                              : t('archive_title_to')}
                          </span>
                        </Tooltip>
                      </OnlyOwner>
                      {!isCamapingsGeneralList && (
                        <OnlyOwner>
                          <Tooltip
                            isDisabled={disableDuplicateCompainsBtn}
                            onClick={async () => {
                              setDisableDuplicateCompainsBtn(true);
                              /*                               const bread = await getBreadcrumbs({ xxhash }); */
                              dispatch(
                                doubleCampaigns({
                                  from: [{ xxhash, title }],
                                  to: [
                                    {
                                      title: bread_crumbs?.Client?.title || '',
                                      xxhash:
                                        bread_crumbs?.Client?.xxhash || '',
                                    },
                                  ],
                                }),
                              );
                            }}
                            title={<ReactSVG src={DuplicateSvg} />}
                          >
                            <span>{i18next.t('duplicate_btn')}</span>
                          </Tooltip>
                        </OnlyOwner>
                      )}
                      {user?.partner.type !== 'CLIENT' && (
                        <>
                          <OnlyOwner>
                            <Tooltip
                              onClick={() => setIsModalCopy(true)}
                              title={<ReactSVG src={CopySvg} />}
                              hintWidth={200}
                            >
                              <span>
                                {i18next.t('campaigns_page.mass_copy')}
                              </span>
                            </Tooltip>
                          </OnlyOwner>
                        </>
                      )}
                      <OnlyOwner>
                        <Tooltip
                          onClick={() => setOpenDelete(xxhash)}
                          title={<FiTrash2 size={24} />}
                        >
                          <span>{t('campaigns_page.campaign_del_icon')}</span>
                        </Tooltip>
                      </OnlyOwner>
                    </>
                  )}
                </div>
              )}

              {openDelete === xxhash && (
                <OnlyOwner>
                  <ModalWithQuestion
                    onClose={() => setOpenDelete('')}
                    onClick={() =>
                      handleRemoveCampaigns({
                        paramsCampaignsStatus,
                        client_id,
                        xxhash,
                        title,
                      })
                    }
                    title={title}
                    subText={t('campaigns_page.camp_del_quest')}
                    modalName={t('campaigns_page.camp_del_tit')}
                  />
                </OnlyOwner>
              )}
              {isOpenModalArchive === xxhash && (
                <OnlyOwner>
                  <ModalWithQuestion
                    onClose={() => setOpenModalArchive('')}
                    modalName={
                      status === 'ARCHIVE'
                        ? t('archive_title_from')
                        : t('archive_title_to')
                    }
                    title={title}
                    btnTitle={
                      status === 'ARCHIVE'
                        ? t('from_archive_btn')
                        : t('in_archive_btn')
                    }
                    subText={
                      status === 'ARCHIVE'
                        ? t('campaigns_page.camp_archive_from_text')
                        : t('campaigns_page.camp_archive_to_text')
                    }
                    onClick={() =>
                      handleChangeArchive({
                        xxhash,
                        status,
                        paramsCampaignsStatus,
                        client_id,
                        title,
                      })
                    }
                  />
                </OnlyOwner>
              )}
            </div>
          </div>
        </div>
      </div>
      {isModalCopy && bread_crumbs && (
        <CopyModal
          from={[{ xxhash, title, internal_id }]}
          xxhashParent={bread_crumbs?.Client?.xxhash || ''}
          copyStep="Agency"
          xxhash={
            bread_crumbs?.Agency?.xxhash ||
            bread_crumbs?.Organization?.xxhash ||
            ''
          }
          onCloseModal={() => setIsModalCopy(false)}
          subTitle={t(`campaigns_page.campaigns_copy_modal.select_comp`)}
          handlerCopy={handlerCopyCampaignsOtherClient}
        />
      )}
      {openIconsModal && (
        <IconsModal
          imgRef={imgRef}
          closeModal={() => setOpenIconsModal(false)}
          open={openIconsModal}
          xxhash={xxhash}
        />
      )}
    </div>
  );
};

export default Item;
