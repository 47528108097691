/* eslint-disable react/no-array-index-key */
import Portal from 'components/Portal';
import React, { FC, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import css from './styles.module.scss';

interface IGroupHintProps {
  groups: string[];
  mainRef: React.MutableRefObject<HTMLDivElement | null>;
  noHintPortal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const GroupHint: FC<IGroupHintProps> = ({ groups, mainRef, noHintPortal }) => {
  const hintRef = useRef<HTMLDivElement | null>(null);
  const [positionLeft, setPositionLeft] = useState<number>(0);
  // const [positionRight, setPositionRight] = useState<number>(0);
  const [positionTop, setPositionTop] = useState<number>(0);
  const [fixTopPadding, setFixTopPadding] = useState<boolean>(false);

  const calcPosition = () => {
    if (mainRef && mainRef.current && hintRef && hintRef.current) {
      setPositionTop(
        mainRef.current?.getBoundingClientRect().top -
          hintRef.current?.clientHeight -
          12,
      );
      setPositionLeft(mainRef.current?.getBoundingClientRect().left);
    }
  };

  useEffect(() => {
    calcPosition();
  }, []);

  useEffect(() => {
    if (mainRef && mainRef.current && hintRef && hintRef.current) {
      if (hintRef.current?.getBoundingClientRect().top < 0) {
        setFixTopPadding(true);
        // setPositionRight(mainRef.current?.getBoundingClientRect().right);
      } else setFixTopPadding(false);
    }
  }, [positionTop]);

  return (
    <Portal className="GroupHint">
      <div
        ref={hintRef}
        className={cn(css.groupHint, {
          fixTopPadding,
        })}
        style={{
          left: `${positionLeft}px`,
          top: `${positionTop}px`,
        }}
        onMouseEnter={noHintPortal ? () => noHintPortal(true) : () => null}
        onMouseLeave={noHintPortal ? () => noHintPortal(false) : () => null}
      >
        {groups.map((group, index) => (
          <span key={`hint${group}${index}`} className={css.group}>
            {group}
          </span>
        ))}
      </div>
    </Portal>
  );
};

export default GroupHint;
