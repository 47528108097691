import React, { FC } from 'react';
import cn from 'classnames';
import css from './styles.module.scss';
import Label from '../Input/Label';
import { toolTipType } from '../Input/types';

type LabelFieldProps = {
  label: string;
  isError?: boolean;
  classname?: string;
  /** тултип со ссылкой */
  tooltip?: toolTipType;
};

const LabelField: FC<LabelFieldProps> = ({
  children,
  label,
  isError,
  classname,
  tooltip,
}) => {
  if (label === '') {
    return <>{children}</>;
  }
  return (
    <div className={cn(css.inputField, 'inputField', classname)}>
      <div className={cn(css.labelWrapper, 'labelWrapper')}>
        <Label label={label} isError={isError} tooltip={tooltip}/>
      </div>
      {children}
    </div>
  );
};

export default LabelField;
