import { Field, FieldProps } from 'formik';
import React, { FC } from 'react';
import Input from 'components/UI/Input/input';
import { additional_settings_switch_tabs } from 'domains/creative/model/shared';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { SelectField } from 'types/general';
import { FiAlertCircle } from 'react-icons/fi';
import PriceInput from 'components/UI/PriceInput';
import { checkSizes } from 'domains/creative/creatives/creativeAdd/validate';
import { animated } from 'react-spring';
import RadioGroup from 'components/UI/RadioGroup';
import { useValidateVendorLInk } from 'domains/creative/model/hooks/useValidateVendorLInk';
import UploadImage from 'domains/uploads/UploadImage';
import { getParamVideo } from 'domains/uploads/helpers';
import cn from 'classnames';
import { IUseTypeProp } from 'domains/creative/creatives/creativeAdd/hooks/useType';
import { TTypeHookProps } from 'domains/creative/types';
import Checkbox from 'components/UI/Checkbox/checkbox';
import EridWarning from 'components/EridWarning';
import NewSelect from 'components/UI/NewSelect';
import i18n from '../../../../../../../../i18n';
import css from '../../../styles.module.scss';

type TVideoProp = TTypeHookProps & {
  formik: IUseTypeProp['formik'];
  defaultSizeOption: (v: string) => SelectField[] | [];
  sizeOption: SelectField[];
  helper: ReturnType<typeof useValidateVendorLInk>['helper'];
  setIsDisableSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
};

const index: FC<TVideoProp> = ({
  formik: { values, setFieldValue, errors, handleChange },
  showBlock,
  erid,
  setToggle,
  defaultSizeOption,
  setIsDisableSaveButton,
  sizeOption,
  helper,
}) => (
  <div className={css.form}>
    <UploadImage
      keyUploadFile="video"
      preview={getParamVideo(values.data, `${values.size}`)}
      name="video_url"
      setFieldValue={setFieldValue}
      label={i18n.t('creative.video.video_input.label')}
      customLinkTitle={i18n.t('creative.video.video_input.custom_link')}
      hintText={
        <span>
          {i18n.t('creative.video.video_input.hint1')}
          <br />
          {i18n.t('creative.video.video_input.hint2')}
        </span>
      }
      hasEdit
      edit
      fieldType="video"
      maxSizeFile={100}
      isData
      file_ext={['.mp4']}
      typeSection="video"
    />
    <Tabs>
      <TabList>
        <Tab
          className={cn('react-tabs__tab', {
            hasError:
              !!errors?.data?.duration ||
              !!errors?.data?.skip_duration ||
              !!errors?.data?.video_bitrate,
          })}
        >
          <span>{i18n.t('creative.video.tabs.tab1')}</span>
          {(!!errors?.data?.duration ||
            !!errors?.data?.skip_duration ||
            !!errors?.data?.video_bitrate) && (
            <FiAlertCircle size={16} className="alert-icon" />
          )}
        </Tab>
        <Tab
          className={cn('react-tabs__tab', {
            hasError: errors?.data?.audit,
          })}
        >
          <span>
            <span>{i18n.t('creative.video.tabs.tab2')}</span>
          </span>
          {!!errors?.data?.audit && (
            <FiAlertCircle size={16} className="alert-icon" />
          )}
        </Tab>
      </TabList>
      <TabPanel>
        <div className={css.form}>
          <div className={css.flex_row}>
            <div className={css.flex_row_col}>
              <PriceInput
                error={!!errors.data?.video_bitrate}
                errortext={errors.data?.video_bitrate}
                label={i18n.t('creative.video.fields.video_bitrate')}
                decimalScale={0}
                onValueChange={({ floatValue }) => {
                  setFieldValue('data.video_bitrate', floatValue);
                }}
                value={values.data.video_bitrate}
                hasZero={false}
                disabled
              />
            </div>
            <div className={css.flex_row_col}>
              <PriceInput
                error={!!errors.data?.duration}
                errortext={errors.data?.duration}
                label={i18n.t('creative.video.fields.video_duration')}
                decimalScale={3}
                onValueChange={({ floatValue }) => {
                  setFieldValue('data.duration', floatValue);
                }}
                value={values.data.duration}
                hasZero={false}
                disabled
              />
            </div>
            <div className={css.flex_row_col}>
              <PriceInput
                error={!!errors.data?.skip_duration}
                errortext={errors.data?.skip_duration}
                label={i18n.t('creative.video.fields.video_duration_pass')}
                decimalScale={0}
                onValueChange={({ floatValue }) => {
                  setFieldValue('data.skip_duration', floatValue);
                }}
                value={values.data.skip_duration}
                hasZero={false}
              />
            </div>
          </div>
          <Input
            label={i18n.t('creative.video.fields.video_description')}
            isTextarea
            showValueCount
            inputAttributes={{
              name: 'data.description',
              placeholder: '',
              maxLength: 128,
              style: { height: 128 },
              value: values.data.description,
              onChange: handleChange,
            }}
          />
          <Field name="size">
            {({ form, field }: FieldProps) => (
              <NewSelect
                label={i18n.t('creative.create_creative.Size_px')}
                form={form}
                field={field}
                selectAttributes={{
                  name: 'size',
                  placeholder: i18n.t('creative.create_creative.Please_select'),
                  options: sizeOption,
                  value: defaultSizeOption(`${values.size}`),
                  onChange: () => {
                    setToggle(checkSizes(values.size));
                    setFieldValue('size', values.size);
                  },
                }}
                viewStyle='Vertical'
              />
            )}
          </Field>
          <animated.div style={showBlock} className={css.sizes}>
            <PriceInput
              hasZero
              classname={css.rough_col}
              decimalScale={0}
              label={`${i18n.t('creative.create_creative.Width')}, PX`}
              placeholder={i18n.t('creative.create_creative.Width')}
              name="width"
              error={errors.width}
              errortext={errors.width}
              value={+values.width}
              onValueChange={({ floatValue }) => {
                if (!floatValue) {
                  setFieldValue('width', null);
                  return;
                }
                setFieldValue('width', floatValue);
              }}
            />
            <PriceInput
              hasZero
              classname={css.rough_col}
              decimalScale={0}
              label={`${i18n.t('creative.create_creative.Height')}, PX`}
              placeholder={i18n.t('creative.create_creative.Height')}
              name="height"
              error={errors.height}
              errortext={errors.height}
              value={+values.height}
              onValueChange={({ floatValue }) => {
                if (!floatValue) {
                  setFieldValue('height', null);
                  return;
                }
                setFieldValue('height', floatValue);
              }}
            />
          </animated.div>
          {erid && (
            <>
              <Input
                label={erid.title}
                showValueCount
                error={!!errors.erid}
                errortext={errors.erid}
                inputAttributes={{
                  name: 'erid',
                  maxLength: 80,
                  value: values.erid,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('erid', e.target.value.trim()),
                }}
              />
              {values.erid && (
                <EridWarning
                  withAccept
                  isChangeErid
                  setIsDisableSaveButton={setIsDisableSaveButton}
                />
              )}
            </>
          )}
          <Checkbox
            label={i18n.t('creative.use_VPAID')}
            className={css.checkbox}
            inputAttributes={{
              name: 'data.use_vpaid',
              type: 'checkbox',
              checked: values.data.use_vpaid,
              onChange: handleChange,
            }}
          />
        </div>
      </TabPanel>
      <TabPanel>
        <div className={css.form}>
          <RadioGroup
            name="additional_settings_switch"
            label=""
            className={css.switch}
            options={[
              {
                title: i18n.t('creative.video.switch.all'),
                value: 'ALL',
              },
              {
                title: i18n.t('creative.video.switch.quartiles'),
                value: 'QUARTILES',
              },
              {
                title: i18n.t('creative.video.switch.show'),
                value: 'SHOW',
              },
            ]}
            setFieldValue={setFieldValue}
            checked={values.additional_settings_switch}
          />
          {values.additional_settings_switch !== 'ALL' && (
            <>
              {Object.keys(values.data.audit).map(
                (value) =>
                  additional_settings_switch_tabs[
                    values.additional_settings_switch
                  ].includes(value) && (
                    <Input
                      key={`data.audit.${value}`}
                      error={errors.data?.audit && !!errors.data?.audit[value]}
                      errortext={
                        errors.data?.audit && errors.data?.audit[value]
                      }
                      helper={helper[value]}
                      label={i18n.t(`creative.video.fields.video_${value}`)}
                      inputAttributes={{
                        name: `data.audit.${value}`,
                        placeholder: '',
                        style: { height: 48 },
                        value: values.data.audit[value],
                        onChange: handleChange,
                      }}
                    />
                  ),
              )}
            </>
          )}
          {values.additional_settings_switch === 'ALL' && (
            <>
              {Object.keys(values.data.audit).map((value) => (
                <Input
                  key={`data.audit.${value}`}
                  error={errors.data?.audit && !!errors.data?.audit[value]}
                  errortext={errors.data?.audit && errors.data?.audit[value]}
                  helper={helper[value]}
                  label={i18n.t(`creative.video.fields.video_${value}`)}
                  inputAttributes={{
                    name: `data.audit.${value}`,
                    placeholder: '',
                    style: { height: 48 },
                    value: values.data.audit[value],
                    onChange: handleChange,
                  }}
                />
              ))}
            </>
          )}
        </div>
      </TabPanel>
    </Tabs>
  </div>
);

export default index;
