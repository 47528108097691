import ModalOverlay from 'components/Modals/ModalOverlay1';
import Button from 'components/UI/Buttons/buttons';
import Card from 'components/UI/Card';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { FiX } from 'react-icons/fi';
import css from './styles.module.scss';

type Props = {
  type: string;
  onClose: () => void;
  isOpen: boolean;
};

const ModalInfo: FC<Props> = ({ type, onClose, children, isOpen }) => {
  const { t } = useTranslation();
  const content = {
    google: {
      title: t(`creative.moderation.modal.google.title`),
      description: t(`creative.moderation.modal.google.description`),
    },
    yandex: {
      title: t(`creative.moderation.modal.yandex.title`),
      description: t(`creative.moderation.modal.yandex.description`),
    },
  };

  return (
    <ModalOverlay onCloseModal={onClose} isOpen={isOpen}>
      <Card className={css.container} noHover>
        <div className={css.header}>
          <div className={css.title}>
            <h1>{type && content[type].title}</h1>
          </div>
          <div className={css.close}>
            <FiX size={24} onClick={onClose} />
          </div>
        </div>
        <div className={css.descriptions}>
          {type && content[type].description}
          {type === 'yandex' ? (
            <a
              className={css.link}
              href="mailto:dsp-claim@yandex-team.ru"
              target="_blank"
              rel="noopener noreferrer"
            >
              dsp-claim@yandex-team.ru
            </a>
          ) : (
            ''
          )}
        </div>
        <div className={css.content}>{children}</div>
        {type === 'google' && (
          <div className={css.buttons}>
            <a
              href="https://support.google.com/authorizedbuyers/contact/ghelp_contact_form_adxbuy?visit_id=637141728388698909-4006195792&rd=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                title={t(`creative.moderation.modal.google.actionBtn`)}
                autoWidth
                buttonAttributes={{
                  style: {
                    height: 40,
                  },
                }}
              />
            </a>
          </div>
        )}
      </Card>
    </ModalOverlay>
  );
};

export default ModalInfo;
