import React, { FC } from 'react';
import cn from 'classnames';
import { FiChevronDown } from 'react-icons/fi';
import Portal from 'components/Portal';
import { TCellTypes } from 'domains/target/model/types';
import css from './styles.module.scss';
import { useItemBidType } from './hooks/useItemBidType';

type TItemBidTypeProps = {
  className?: string;
  itemKey: string;
  typeVal?: string;
  disabled: boolean;
  showHintEvent: () => void;
  onChangeCellData: (
    k: string,
    v: number | string,
    valType: TCellTypes,
  ) => void;
  setTouchedItemsToSave: React.Dispatch<React.SetStateAction<string[]>>;
};

type TKeyData = 'bidfloor' | 'bid';

type TDataItem = {
  title: string;
  key: TKeyData;
};

const data: TDataItem[] = [
  {
    title: 'BID_FLOOR',
    key: 'bidfloor',
  },
  {
    title: 'BID',
    key: 'bid',
  },
];

const ItemBidType: FC<TItemBidTypeProps> = ({
  className,
  itemKey,
  typeVal,
  disabled,
  onChangeCellData,
  showHintEvent,
  setTouchedItemsToSave,
}) => {
  const {
    dropdownMenu,
    isOpened,
    toggleOff,
    toggle,
    calcOffsetTop,
    dropdownMenuList,
    offsetLeft,
    correctionPosition,
    offsetTop,
  } = useItemBidType();

  let value = typeVal === 'bidfloor' ? data[0] : data[1];

  const handleChange = (target: TKeyData) => {
    value = data.find((item) => item.key === target) || data[1];
    onChangeCellData(itemKey, value.key, 'type');
    setTouchedItemsToSave(prev => [...prev, itemKey]);
  };

  const onOpenMenuHandler = isOpened
    ? toggleOff
    : (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (disabled) {
          showHintEvent();
          return;
        }
        toggle(e);
        calcOffsetTop();
      };

  return (
    <div className={cn(css.type, className)} ref={dropdownMenu}>
      <div
        className={css.type_btn}
        onClick={onOpenMenuHandler}
      >
        <span>{value.title}</span>
        <FiChevronDown size={12} />
      </div>
      {isOpened && !!data.length && (
        <Portal className="ItemBidType">
          <div
            className={css.list}
            ref={dropdownMenuList}
            style={{
              left: `${offsetLeft - correctionPosition}px`,
              top: `${offsetTop}px`,
            }}
          >
            {data.map((item) => (
              <div
                className={css.item}
                onClick={() => handleChange(item.key)}
                key={item.key}
              >
                {item.title}
              </div>
            ))}
          </div>
        </Portal>
      )}
    </div>
  );
};

export default ItemBidType;
