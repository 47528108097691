import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from '../../../redux/fetchedData';
import { RGetManagementUsersSList, RUserOnline, TActivityUsers } from './types';
import { TTableSort } from '../../../types/general';

export type ManagementUsersState = {
  tempManagementUsers: FetchedData<RGetManagementUsersSList>;
  usersOnline: FetchedData<RUserOnline>;
  activityUsers: FetchedData<TActivityUsers>;
  listSort: TTableSort;
};

export const initialState: ManagementUsersState = {
  tempManagementUsers: genFetchedData<RGetManagementUsersSList>(null),
  usersOnline: genFetchedData<RUserOnline>(null),
  activityUsers: genFetchedData<TActivityUsers>(null),
  listSort: {
    sort: null,
    key: null,
  },
};

const managementUsersSlice = createSlice({
  name: 'managementUsers',
  initialState,
  reducers: {
    setTempManagementUsers(
      state,
      action: PayloadAction<ManagementUsersState['tempManagementUsers']>,
    ) {
      state.tempManagementUsers = action.payload;
    },
    setTempManagementUsersSuccess(
      state,
      action: PayloadAction<ManagementUsersState['tempManagementUsers']>,
    ) {
      state.tempManagementUsers = action.payload;
    },
    setUsersOnline(
      state,
      action: PayloadAction<ManagementUsersState['usersOnline']>,
    ) {
      state.usersOnline = action.payload;
    },
    setActivityUsers(
      state,
      action: PayloadAction<ManagementUsersState['activityUsers']>,
    ) {
      state.activityUsers = action.payload;
    },
    setUsersActivitySort(
      state,
      action: PayloadAction<ManagementUsersState['listSort']>,
    ) {
      state.listSort = action.payload;
    },
  },
});

export const {
  setTempManagementUsers,
  setTempManagementUsersSuccess,
  setUsersOnline,
  setActivityUsers,
  setUsersActivitySort,
} = managementUsersSlice.actions;
export const managementUsersReducer = managementUsersSlice.reducer;
