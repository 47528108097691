import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { makeReqWithRD, TMakeReqWithRD } from 'redux/makeReqWithRD';
import { setTargetItemsData, setCreativeList } from 'domains/Analysis/reducer';
import {
  fetchTargetItemsData,
  fetchCreativeList,
} from 'domains/Analysis/actions';
import { getTargetItems, getCreativeList } from 'domains/Analysis/api';

export function* workerFetchTargetItemsData({
  payload,
}: ReturnType<typeof fetchTargetItemsData>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getTargetItems>>(makeReqWithRD, {
      fetcher: getTargetItems,
      fill: setTargetItemsData,
      parameters: payload,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* workerFetchCreativeList({
  payload,
}: ReturnType<typeof fetchCreativeList>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getCreativeList>>(makeReqWithRD, {
      fetcher: getCreativeList,
      fill: setCreativeList,
      parameters: payload,
    });
  } catch (e) {
    console.error(e);
  }
}
