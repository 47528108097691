import { FormikProps, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import deepEqual from 'deep-equal';
import { setEditCreativeRequest } from 'domains/creative/reduser';
import { useEffect } from 'react';
import { useUserTheme } from 'domains/user/model/selectors';
import { useValidateSchemeCreatives } from '../../../../validate/validate';
import { validate } from '../validateOther';
import { CreativeDetail, TCreativeVideoInline } from '../../../../../types';
import { setFormDefault } from '../../../../../../uploads/reducer';
import { useDispatchApp } from '../../../../../../../redux/rootSelectors';
import { useCreativeSize } from '../../../../../model/hooks/useCreativeSize';
import { useValidateVendorLInk } from '../../../../../model/hooks/useValidateVendorLInk';

type TParam = {
  xxhash: CreativeDetail['xxhash'];
  data: TCreativeVideoInline;
  size: CreativeDetail['size'];
  erid: string;
  setEdit: (param: boolean) => void;
  edit: boolean;
};

type FormikEditVideo = {
  size: string;
  erid?: string;
  width?: string;
  height?: string;
  data: TCreativeVideoInline;
};

type TFUseFormVideo = (p: TParam) => {
  formik: FormikProps<
    FormikEditVideo & {
      additional_settings_switch: 'ALL' | 'QUARTILES' | 'SHOW';
    }
  >;
  handlerFormikReset: () => void;
  handlerFormikSubmit: () => void;
  sizeOption: ReturnType<typeof useCreativeSize>['sizeOption'];
  defaultSizeOption: ReturnType<typeof useCreativeSize>['defaultSizeOption'];
  checkSizes: ReturnType<typeof useCreativeSize>['checkSizes'];
  helper: ReturnType<typeof useValidateVendorLInk>['helper'];
  helperError: ReturnType<typeof useValidateVendorLInk>['helperError'];
};

export const useFormVideo: TFUseFormVideo = ({
  xxhash,
  size,
  erid: eridValue,
  data: {
    audit,
    description,
    duration,
    use_vpaid,
    skip_duration,
    video_bitrate,
    video_url,
    video_poster_url,
    file_size,
  },
  edit,
  setEdit,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatchApp();
  const { dataCreativeVideoInline } = useValidateSchemeCreatives();
  const { sizeOption, defaultSizeOption, checkSizes } = useCreativeSize();
  const arSize: null | string[] = size ? size.split('x') : null;
  const { getValueFromSettingForKey } = useUserTheme();
  const formik = useFormik<
    FormikEditVideo & {
      additional_settings_switch: 'ALL' | 'QUARTILES' | 'SHOW';
    }
  >({
    enableReinitialize: true,
    validationSchema: dataCreativeVideoInline,
    validate,
    initialValues: {
      size,
      erid: eridValue,
      additional_settings_switch: 'SHOW',
      data: {
        video_bitrate,
        skip_duration,
        duration,
        description,
        audit,
        use_vpaid: use_vpaid ?? false,
        video_url,
        file_size,
        video_poster_url,
      },
      height: arSize ? arSize[1] : '',
      width: arSize ? arSize[0] : '',
    },
    onSubmit: (values) => {
      const formValueData: FormikEditVideo = {
        data: values.data,
        size: values.size,
        erid: values.erid,
      };
      if (values.size === '0x0') {
        formValueData.size = `${values.width}x${values.height}`;
      } else if (sizeOption.some((v) => v.value === values.size)) {
        formValueData.size = values.size;
      } else if (typeof values.size === 'string' && values.size !== '') {
        const sizeData = formik.values.size.split('x');
        if (values.width !== sizeData[0] || values.height !== sizeData[1]) {
          formValueData.size = `${values.width}x${values.height}`;
        } else {
          formValueData.size = values.size;
        }
      } else {
        formValueData.size = values.size || '0x0';
      }
      if (
        !deepEqual(
          {
            size,
            erid: eridValue,
            additional_settings_switch: 'SHOW',
            data: {
              video_bitrate,
              skip_duration,
              duration,
              description,
              audit,
              video_url,
              file_size,
              video_poster_url,
            },
          },
          values,
        )
      ) {
        dispatch(
          setEditCreativeRequest({
            data: { xxhash, type: 'VIDEO_INLINE', ...formValueData },
            visual: {
              setter: setEdit,
              translation:
                getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
                !getValueFromSettingForKey('ShowBroadcastStatus'),
              value: false,
              alertText: `${t('creative.type.type')}${t(
                'creative.type.video',
              )}`,
            },
          }),
        );
      } else {
        dispatch(setFormDefault(true));
        setEdit(false);
      }
    },
  });

  useEffect(() => {
    const defSize = sizeOption.filter((v) => v.value === formik.values.size);
    if (defSize.length === 0) {
      if (formik.values.size) {
        const sizeNew = formik.values.size.split('x');
        formik.setFieldValue('width', sizeNew[0]);
        formik.setFieldValue('height', sizeNew[1]);
      }
    }
  }, [edit]);

  /** Функция сбрасывает состояние формы */
  const handlerFormikReset = () => {
    setEdit(false);
    dispatch(setFormDefault(true));
    formik.handleReset({
      video_bitrate,
      skip_duration,
      duration,
      description,
      audit,
      video_url,
      size,
    });
  };

  /** Функция отправляет форму */
  const handlerFormikSubmit = () => {
    formik.handleSubmit();
  };

  const { helper, helperError } = useValidateVendorLInk({
    formik: {
      show: formik.values.data.audit.show,
      show2: formik.values.data.audit.show2,
    },
  });

  return {
    formik,
    handlerFormikReset,
    handlerFormikSubmit,
    sizeOption,
    defaultSizeOption,
    checkSizes,
    helper,
    helperError,
  };
};
