import React, { FC, useRef, useState } from 'react';
import cn from 'classnames';
import useDropDown from 'hooks/useDropDown';
import { FiSettings } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import Portal from 'components/Modals/MainPortal';
import CopyTargetingsModal from 'components/CopyTargetingsModal';
import css from './styles.module.scss';
import OnlyOwner from '../../../components/OnlyOwner';
import ModalWithQuestion from '../../modals/modalWithQuestion';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  clearTargetings: (params: { id: string }) => void;
  id: string;
  title: string;
};

const Options: FC<Props> = ({
  className,
  disabled,
  style,
  clearTargetings,
  id,
  title,
}) => {
  const dropdownMenu = useRef<HTMLDivElement | null>(null);
  const { toggle, isOpened, toggleOff } = useDropDown(dropdownMenu);
  const [openCopyTargetingsModal, setOpenCopyTargetingsModal] =
    useState<boolean>(false);

  const [isModalClear, setIsModalClear] = useState<boolean>(false);

  const clearTargets = () => {
    clearTargetings({ id });
    setIsModalClear(false);
    toggleOff();
  };
  const { t } = useTranslation();

  const copyTargets = () => {
    setOpenCopyTargetingsModal(true);
  };

  return (
    <div
      className={cn(css.options, className, {
        _isDisabled: disabled,
      })}
      ref={dropdownMenu}
      style={style}
    >
      <div
        className={cn(css.button, {
          _isOpened: isOpened,
        })}
        onClick={isOpened ? toggleOff : toggle}
      >
        <FiSettings size={24} />
      </div>

      <div className={cn(css.list, { _isOpened: isOpened })}>
        <div className={cn(css.item)} onClick={copyTargets}>
          {t('creative.cop')}
        </div>
        <div className={cn(css.item)} onClick={() => setIsModalClear(true)}>
          {t('creative.cleartarg')}
        </div>
      </div>

      {openCopyTargetingsModal && (
        <Portal>
          <CopyTargetingsModal
            onClose={() => setOpenCopyTargetingsModal(false)}
            id={id}
            isCampaign
          />
        </Portal>
      )}
      {isModalClear && (
        <OnlyOwner>
          <ModalWithQuestion
            btnTitle={t('clean_btn')}
            onClose={() => setIsModalClear(false)}
            onClick={clearTargets}
            title={title}
            subText={t('creative.clear_all_target')}
            modalName={t('just_words.confirm_action')}
          />
        </OnlyOwner>
      )}
    </div>
  );
};

export default Options;
