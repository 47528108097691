import {
  ChangeEvent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

export type TFUseTagInputProps = {
  valueDefault?: string;
  handlerClose: () => void;
  handlerAction?: (value: string) => void;
  handlerUpdateAction?: (value: string, index: number) => void;
  index?: number;
  type: 'edit' | 'created';
  showEvent: boolean;
};

type TFUseTagInput = (props: TFUseTagInputProps) => {
  value: string;
  inputRef: RefObject<HTMLInputElement>;
  wrapperRef: RefObject<HTMLDivElement>;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  clearValue: () => void;
};

const useTagInput: TFUseTagInput = ({
  valueDefault,
  handlerClose,
  handlerAction,
  handlerUpdateAction,
  type,
  index,
  showEvent,
}) => {
  const [value, setValue] = useState<string>(valueDefault ?? '');

  const clearValue = () => {
    setValue('');
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const onChange: ReturnType<TFUseTagInput>['onChange'] = ({ target }) => {
    setValue(target.value);
    if (inputRef && inputRef.current) {
      inputRef.current.style.width =
        target.value.length > 2 ? `${target.value.length * 8}px` : `15px`;
    }
  };

  const watcherEnter = useCallback(
    (event: KeyboardEvent) => {
      if (
        event.which === 9 ||
        event.which === 13 ||
        event.code === 'Enter' ||
        event.code === 'NumpadEnter'
      ) {
        if (value.trim()) {
          if (
            type === 'edit' &&
            typeof index === 'number' &&
            handlerUpdateAction
          ) {
            handlerUpdateAction(value.trim(), index);
          } else if (handlerAction) {
            handlerAction(value.trim());
          }
        }
        handlerClose();
        setValue('');
      }
    },
    [value],
  );

  const watcherListenerClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (valueDefault !== undefined) {
      setValue(valueDefault);
    }
    if (wrapperRef.current) {
      wrapperRef.current.addEventListener('click', watcherListenerClick);
    }
    if (inputRef.current) {
      inputRef.current.style.width =
        value.length > 2 ? `${value.length * 8}px` : `15px`;
      inputRef.current.focus();
    }
    return () => {
      if (wrapperRef.current) {
        wrapperRef.current.removeEventListener('click', watcherListenerClick);
      }
    };
  }, [showEvent]);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.addEventListener('keyup', watcherEnter);
    }
    return () => {
      inputRef.current?.removeEventListener('keyup', watcherEnter);
    };
  }, [value]);

  return {
    value,
    inputRef,
    onChange,
    wrapperRef,
    clearValue,
  };
};

export default useTagInput;
