type TSizeDest = {
  width: string | null;
  height: string | null;
};

export const sizeSplit = (format: string | null): TSizeDest => {
  if (format) {
    const size = format.split('x');
    return {
      width: size[0],
      height: size[1],
    };
  }
  return {
    width: null,
    height: null,
  };
};
