import { TClientStatItem } from 'domains/agency/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Column } from './useGetColumns';

type TGetClientsStatReportReturn = {
  disGetReport: boolean;
  onGetReport: () => void;
};

type TGetClientsStatReport = (
  data: TClientStatItem[],
  total: TClientStatItem | null,
  columns: Column[],
) => TGetClientsStatReportReturn;

export const useGetClientsStatReport: TGetClientsStatReport = (
  data,
  total,
  columns,
) => {
  function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  const worker = useMemo(
    () => new Worker('/workers/getClientsListStatReport.js?v=1.0'),
    [],
  );

  const [disGetReport, setDisGetReport] = useState(false);

  useEffect(() => {
    if (data.length) {
      setDisGetReport(false);
      return;
    }
    setDisGetReport(true);
  }, [data]);

  const ReportColumns = [
    {
        name: 'ID CLIENT',
        data: 'internal_id',
    },
    {
        name: 'TITLE CLIENT',
        data: 'title',
    },
    ...columns
  ];

  const onGetReport: () => void = useCallback(() => {
    setDisGetReport(true);
    worker.postMessage([data, total, ReportColumns]);
    // eslint-disable-next-line no-param-reassign
    worker.onmessage = function (e) {
      const type = isSafari() ? 'application/csv' : 'text/csv';
      const blob = new Blob(['', e.data], { type });

      const URL = window.URL || window.webkitURL;

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', 'clientListStat.tsv');
      document.body.appendChild(link);
      link.click();
      link.remove();
      setDisGetReport(false);
    };
    // eslint-disable-next-line no-param-reassign
    worker.onerror = function (e) {
      console.error({ e });
      setDisGetReport(false);
    };
  }, [data]);

  return {
    disGetReport,
    onGetReport,
  };
};
