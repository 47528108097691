import { useEffect, useState } from 'react';
import last from 'utils/last';
import { objectOptimizationWithFormik } from 'utils/objectOptimizationWithFormik/objectOptimizationWithFormik';

interface Params<T> {
  data: T | null;
  pastData: T[];
}

interface Return {
  savedField: Record<string, unknown>
}

export function useSavedField<T extends Record<string, any>>({
  data,
  pastData,
}: Params<T>): Return {
  const [savedField, setSavedField] = useState<Record<string, unknown>>({});

  useEffect(() => {
    if (pastData && data) {
      const lastPast = last(pastData);
      if (lastPast) {
        const compare = objectOptimizationWithFormik<T>(lastPast, data, true);
        if (compare) {
          setSavedField(() => compare);
          setTimeout(() => {
            setSavedField({});
          }, 4000);
        }
      }
    }
  }, [data, pastData]);

  return {
    savedField,
  };
}
