import React, { useCallback, useEffect, useState } from 'react';
import { useIsShowMLink } from '../../../modals/linkToImage/hooks';
import { fetchLoadAgencyTempFile } from '../../reducer';
import { useDispatchApp } from '../../../../redux/rootSelectors';
import {
  useAgencyImageInfo,
  useAgencyInfo,
} from '../../model/selectors';
import { FetchedDataErrors } from '../../../../types/general';
import { useIsShowMCreate } from '../modal/hooks/useIsShowMCreate';

type TFUseUploadImage = (mode: 'create' | 'edit', remove_icon?: boolean) => {
  upload: boolean;
  error: boolean;
  complete: boolean;
  imgUrl: string | null;
  onChangeFile: (v: React.FormEvent<HTMLInputElement>) => void;
  openMLinkCallback: () => void;
  tempImage: ReturnType<typeof useAgencyImageInfo>['data'];
  loadImg: string | undefined;
  tempError: FetchedDataErrors;
};

export const useUploadImage: TFUseUploadImage = (mode, remove_icon?) => {
  const dispatch = useDispatchApp();

  const onOpenCreateM = useIsShowMCreate(true);
  const onCloseCreateM = useIsShowMCreate(false);

  const openMLink = useIsShowMLink(true, mode === 'create' ? onOpenCreateM : undefined);

  const openMLinkCallback = useCallback(() => {
    if(mode === 'create') {
      onCloseCreateM();
    }
    openMLink();
  }, [onCloseCreateM, openMLink, mode]);

  const [imgUrl, setImgUrl] =
    useState<ReturnType<TFUseUploadImage>['imgUrl']>(null);
  const [upload, setUpload] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);

  const {
    data: image,
    isLoading: isLoadingImage,
    error: errorImage,
  } = useAgencyImageInfo();
  const { data: agencyInfo } = useAgencyInfo();
  const [loadImg, setLoadImg] =
    useState<ReturnType<TFUseUploadImage>['loadImg']>(undefined);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const iconLoad = agencyInfo?.icon;
    const defaultIcon = agencyInfo?.default_icon;
    if (remove_icon === true) {
      return setLoadImg(undefined);
    }
    if (typeof iconLoad === 'string' && defaultIcon === false) {
      setLoadImg(iconLoad);
    }
  }, [agencyInfo, remove_icon]);
  useEffect(() => {
    const data = image;

    setUpload(isLoadingImage);
    setError(errorImage.isError);

    if (data !== null) {
      setImgUrl(data.file_url);
      setComplete(true);
      return;
    }

    if (loadImg) {
      setImgUrl(loadImg);
      setError(false);
      return;
    }
    setImgUrl(null);
    setComplete(false);
  }, [image, loadImg, errorImage.isError, isLoadingImage]);

  const onChangeFile: ReturnType<TFUseUploadImage>['onChangeFile'] =
    useCallback(({ currentTarget }) => {
      const fileList = currentTarget.files;
      if (fileList === null) {
        currentTarget.setAttribute('value', '');
        return;
      }
      if (fileList.length === 0) {
        currentTarget.setAttribute('value', '');

        return;
      }
      const file = fileList[0];
      dispatch(fetchLoadAgencyTempFile(file));
    }, []);

  return {
    upload,
    error,
    complete,
    imgUrl,
    onChangeFile,
    openMLinkCallback,
    tempImage: image,
    loadImg,
    tempError: errorImage,
  };
};
