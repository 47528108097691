import moment from 'moment';
import { FilterCompare } from '../shared/enums';

type TFCompareFieldProps = {
  value: string;
  compareValue: string;
  compareKey: FilterCompare;
  isDate?: boolean;
};
type TFCompareField = (props: TFCompareFieldProps) => boolean;

export const compareField: TFCompareField = ({
  value,
  compareValue,
  compareKey,
  isDate = false,
}) => {
  let result;
  const format = 'YYYY.MM.DD';
  if (isDate) {
    const date = moment(value).format(format);
    switch (compareKey) {
      case FilterCompare.EQUALS:
        result = date === compareValue;
        break;
      case FilterCompare.LESS:
        result = moment(compareValue).isAfter(date, 'day');
        break;
      case FilterCompare.MORE:
        result = moment(date).isAfter(compareValue, 'day');
        break;
      case FilterCompare.LESS_AND_EQUALS:
        result =
          date === compareValue || moment(compareValue).isAfter(date, 'day');
        break;
      case FilterCompare.MORE_AND_EQUALS:
        result =
          date === compareValue || moment(date).isAfter(compareValue, 'day');
        break;
      default:
        result = false;
    }
    return result;
  }

  switch (compareKey) {
    case FilterCompare.EQUALS:
      result = +value === +compareValue;
      break;
    case FilterCompare.LESS:
      result = +value < +compareValue;
      break;
    case FilterCompare.MORE:
      result = +value > +compareValue;
      break;
    case FilterCompare.LESS_AND_EQUALS:
      result = +value <= +compareValue;
      break;
    case FilterCompare.MORE_AND_EQUALS:
      result = +value >= +compareValue;
      break;
    default:
      result = false;
  }
  return result;
};
