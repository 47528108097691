import moment from 'moment';
import { TCampaignsFilter } from '../../types';
import { CampaignFilter, FilterCompare } from '../shared/enums';

type TFEnCodeUrlFilter = (data: TCampaignsFilter[]) => string;
type TFDecodeUrlFilter = (url: string) => TCampaignsFilter[];
export const enCodeUrlFilter: TFEnCodeUrlFilter = (data) => {
  const url = data
    .map(
      ({ value, key, compare }) =>
        `k=${key}/c=${compare}/v=${moment(value).format()}`,
    )
    .join(',');
  return encodeURI(url);
};

export const decodeUrlFilter: TFDecodeUrlFilter = (url) => {
  const ar = decodeURI(url).split(',');
  const result: TCampaignsFilter[] = [];
  ar.forEach((element, index) => {
    const minAr = element.split('/');
    minAr.forEach((minValue) => {
      if (minValue.includes('k=')) {
        result[index] = {
          ...result[index],
          key: minValue.slice(2, minValue.length) as CampaignFilter,
        };
      }
      if (minValue.includes('c=')) {
        result[index] = {
          ...result[index],
          compare: minValue.slice(2, minValue.length) as FilterCompare,
        };
      }
      if (minValue.includes('v=')) {
        result[index] = {
          ...result[index],
          value: minValue.slice(2, minValue.length),
        };
      }
    });
  });

  return result.map((v, index) => ({ ...v, id: index + 1 }));
};
