import { useEffect } from 'react';
import { useDispatchApp } from '../../../../redux/rootSelectors';
import { uploadStoreWithLocalStorage } from '../actions';

export const useUploadStorage = (): void => {
  const dispatch = useDispatchApp();
  useEffect(() => {
    dispatch(uploadStoreWithLocalStorage());
  }, []);
};
