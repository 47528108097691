interface IuseClickOutsideProps {
    // ref управляющего элемента
    targetRef: React.RefObject<HTMLDivElement>;
    closePanel: () => void;
}

export const useClickOutside = ({
    targetRef,
    closePanel,
}: IuseClickOutsideProps): null => {
    const outsideClickListener = (event: any) => {
        if (targetRef && targetRef.current) {
            if (!targetRef.current.contains(event.target)) {
                closePanel()
                document.removeEventListener('click', outsideClickListener);
            }
        }
    };

    document.addEventListener('click', outsideClickListener);

    return null;
};
