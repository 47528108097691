import React, { FC } from 'react';
import InfoBlock from 'components/UI/InfoBlock';
import cn from 'classnames';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import Input from 'components/UI/Input/input';
import {
  optionsCategoryIAB,
  optionsCategoryIABEng,
  optionsLanguage,
  optionsLanguageEng,
} from 'domains/creative/model/shared';
import { selectDefault } from 'utils/selectDefault';
import { FormikProps } from 'formik';
import checkSelectOptionsTranslation from 'utils/checkSelectOptionsTranslation';
import { TExperimentalInfoField } from 'domains/campaign/types';
import { FiAlertCircle, FiRotateCcw } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import NewSelect from 'components/UI/NewSelect';
import LabelField from 'components/UI/LabelField';
import css from '../styles.module.scss';
import i18n from '../../../../../i18n';
import { ReactComponent as CleanSvg } from '../../../../../assets/clean.svg';

interface Props {
  formik: FormikProps<Record<string, string | null>>;
  eridData: TExperimentalInfoField | null;
  isShowErid: boolean;
}

interface IsFieldClearedParams {
  fieldName: string;
  values: Record<string, string | null>;
}

const Creative: FC<Props> = ({ formik, eridData, isShowErid }) => {
  const currentLanguage = i18n.language;
  const currentIABOptions = checkSelectOptionsTranslation(
    optionsCategoryIAB,
    optionsCategoryIABEng,
    currentLanguage,
  );
  const currentLangOptions = checkSelectOptionsTranslation(
    optionsLanguage,
    optionsLanguageEng,
    currentLanguage,
  );

  const isFieldCleaned = ({ fieldName, values }: IsFieldClearedParams) =>
    values[fieldName] === null;

  const { t } = useTranslation();

  const isEridClean = isFieldCleaned({
    fieldName: 'erid',
    values: formik.values,
  });

  return (
    <>
      <InfoBlock color="BLUE" text={i18n.t(`creative.will_be_changed`)} />
      <div className={css.add_panel}>
        <div className={css.settings_list}>
          <div className={cn(css.settings_item, 'isCreative')}>
            <div className={css.settings_item_link}>
              <LabelField label="IAB">
                <NewSelect
                  noOptionsMessage={() => i18n.t(`no_result`)}
                  onCustomChange={(e) => {
                    formik.setFieldValue('iab', e);
                  }}
                  selectAttributes={{
                    isDisabled: false,
                    value: selectDefault(
                      `${formik.values.iab}`,
                      currentIABOptions,
                    ),
                    options: currentIABOptions,
                    placeholder: i18n.t(
                      `creative.create_creative.select_from_list`,
                    ),
                  }}
                  heightCustom={32}
                  viewStyle="Horizontal"
                />
              </LabelField>
            </div>
          </div>
          {formik.values.iab === '24' && (
            <div className={css.warning}>
              <FiAlertCircle
                size={16}
                color="#FF9C08"
                className="warning-icon"
              />
              <span>{t('errors.additional_cat_err')}</span>
            </div>
          )}
          <div className={cn(css.settings_item, 'isCreative')}>
            <div className={css.settings_item_link}>
              <LabelField label={t(`creative.widget_text.language`)}>
                <NewSelect
                  label={t(`creative.widget_text.language`)}
                  noOptionsMessage={() => i18n.t(`no_result`)}
                  onCustomChange={(e) => {
                    formik.setFieldValue('language', e);
                  }}
                  selectAttributes={{
                    isDisabled: false,
                    value: selectDefault(
                      `${formik.values.language}`,
                      currentLangOptions,
                    ),
                    options: currentLangOptions,
                    placeholder: i18n.t(
                      `campaigns_page.campaign_settings.additional.ddmm`,
                    ),
                  }}
                  heightCustom={32}
                  viewStyle='Horizontal'
                />
              </LabelField>
            </div>
          </div>
          {eridData && isShowErid && (
            <div className={cn(css.settings_item, 'isCreative')}>
              <div className={css.settings_item_link}>
                <Input
                  label="ERID"
                  theme="horizontal"
                  error={!!formik.errors.erid}
                  errortext={formik.errors.erid}
                  disabled={isFieldCleaned({
                    fieldName: 'erid',
                    values: formik.values,
                  })}
                  inputAttributes={{
                    name: 'erid',
                    value: formik.values.erid || '',
                    placeholder: isFieldCleaned({
                      fieldName: 'erid',
                      values: formik.values,
                    })
                      ? i18n.t(`creative.the_field_be_deleted`)
                      : i18n.t(`campaigns_page.campaign_settings.Please_enter`),
                    onChange: formik.handleChange,
                  }}
                />
              </div>
              <div className={css.settings_item_actions}>
                <div className={cn(css.clean)}>
                  <TooltipPoratal
                    hint={
                      isEridClean
                        ? i18n.t(
                            `clients_page.client_edit.link_to_image_cancel`,
                          )
                        : i18n.t(`clean_btn`)
                    }
                  >
                    <div
                      onClick={() => {
                        const val = isEridClean ? '' : null;
                        formik.setFieldValue('erid', val);
                      }}
                    >
                      {isEridClean ? <FiRotateCcw size={18} /> : <CleanSvg />}
                    </div>
                  </TooltipPoratal>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Creative;
