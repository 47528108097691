import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import moment from 'moment';
import css from './styles.module.scss';
import NavigationHeader from '../../components/NavigationHeader';
import DateRangeFilter from '../../components/DateRangeFilter';
import ErrorReport from '../../components/Errors/Reports';
import Loader from '../../components/UI/Loader';
import CardsList from '../../components/UI/CardList';
import Reports from './Reports';
import { AppState } from '../../redux/rootReducer';
import {
  fetchContainerDetailByID,
  onDownloadContainerRequest,
  IContainerDetail,
} from './reducer';
import { useContainerByID } from './hooks';
import { TDates, TID } from '../../types/general';
import { useDispatchApp } from '../../redux/rootSelectors';

type THandlerDownload = (p: TID) => void;

const ContainersDetail: FC<RouteComponentProps<TID>> = ({ match }) => {
  const { t } = useTranslation();

  const { id } = match.params;

  const fetchContainerByID = useContainerByID({
    id,
    from: moment(moment().subtract(6, 'days')).format('YYYY-MM-DD'),
  });

  const dispatch = useDispatchApp();

  const onDownloadRequest: THandlerDownload = (param) => {
    dispatch(onDownloadContainerRequest(param));
  };

  const tempDetail = useSelector<AppState, IContainerDetail['tempDetail']>(
    (state) => state.containerDetail.tempDetail,
  );

  const detail = tempDetail.get('data');
  const loading = tempDetail.get('isLoading');
  const error = tempDetail.get('error');

  useEffect(() => {
    fetchContainerByID();
  }, []);

  const onSetContainerDate = (param: TDates['period']) => {
    dispatch(fetchContainerDetailByID({ id, ...param }));
  };
  return (
    <div className={css.detail}>
      <NavigationHeader
        back={{
          title: t('containers_page.back'),
          to: '/main/containers',
        }}
      />
      {detail && (
        <div className={css.header}>
          <div className="container">
            <h1 className={css.title}>{detail.title}</h1>
            <DateRangeFilter
              isError={error.isError}
              loading={loading}
              range={detail.range}
              dateStart={moment().subtract(365, 'days').toDate()}
              dateEnd={new Date()}
              onSetDateRange={onSetContainerDate}
              initialTitleDay="week"
            />
          </div>
        </div>
      )}
      {error.isError && <ErrorReport error={error} type="Reports" />}

      {loading && <Loader />}
      {!loading && detail && !error.isError && (
        <CardsList>
          <Reports
            statistics={detail.statistics}
            onDownloadRequest={onDownloadRequest}
            id={id}
          />
        </CardsList>
      )}
    </div>
  );
};
export default ContainersDetail;
