import React, { FC } from 'react';
import { ReactSVG } from 'react-svg';
import cn from 'classnames';
import defaultIcon from '../../../assets/filterIcons/default.svg';
import ascIcon from '../../../assets/filterIcons/asc.svg';
import descIcon from '../../../assets/filterIcons/desc.svg';
import css from './styles.module.scss';
import { TTableSort } from '../../../types/general';

type TableFilterProps = {
  /** ключ столбца */
  data: string;
  /** Заголовок */
  title: string;
  /** показывать или скрытвать */
  isShow?: boolean;
  /** активный ключ сортировки */
  activeKey: TTableSort['key'];
  /** тип сортировки */
  sort: TTableSort['sort'];
  /** callback для вызова сортировки */
  handleSort: (props: { key: string; sort: TTableSort['sort'] }) => void;
  /* Текст подсказки */
  tooltipText?: string;
};

const TableFilter: FC<TableFilterProps> = ({
  data,
  title,
  handleSort,
  activeKey,
  sort,
  isShow,
  tooltipText,
}) => (
  <div
    className={cn(css.item, 'TableFilterItem')}
    onClick={() => {
      handleSort({
        key: data,
        sort:
          // eslint-disable-next-line no-nested-ternary
          data === activeKey
            ? // eslint-disable-next-line no-nested-ternary
              sort === null
              ? 'DESC'
              : sort === 'DESC'
              ? 'ASC'
              : null
            : 'DESC',
      });
    }}
  >
    <div
      className={cn(css.title, 'TableFilterItemTitle')}
      title={tooltipText || title}
    >
      {title}
    </div>

    {(isShow || data === 'name') && (
      <div className={css.filter}>
        {activeKey === data ? (
          <>
            {sort === null && <ReactSVG src={defaultIcon} className="icon" />}
            {sort === 'ASC' && <ReactSVG src={ascIcon} className="icon" />}
            {sort === 'DESC' && <ReactSVG src={descIcon} className="icon" />}
          </>
        ) : (
          <ReactSVG src={defaultIcon} className="icon" />
        )}
      </div>
    )}
  </div>
);

export default TableFilter;
