import React, { FC } from 'react';
import cn from 'classnames';
import Card from 'components/UI/Card';
import Load from 'components/UI/Load';
import css from './styles.module.scss';

const PreloaderWidget: FC = () => (
  <>
    <Card noHover className={cn(css.item)}>
      <div className={css.content}>
        <div className={css.string}>
          <Load classname={css.width45} />
          <Load classname={css.width100} />
        </div>
      </div>
    </Card>
  </>
);

export default PreloaderWidget;
