import ModalOverlay from 'components/Modals/ModalOverlay1';
import Card from 'components/UI/Card';
import React, { FC, useEffect, useRef, useState } from 'react';
import Loader from 'components/UI/Loader';
import { MassEditLinkParams } from 'domains/creative/types';
import { useDispatchApp } from 'redux/rootSelectors';
import { massEditLinkAction } from 'domains/creative/model/actions';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';
import { ReactComponent as SaveSvg } from './icons/save.svg';

interface ISaveChangeModalProps {
  saveMassEditData: MassEditLinkParams;
  setCreativeCheckedList: (value: string[]) => void;
  onClose: () => void;
}

const CANCEL_TIME = 3;

const SaveChangeModal: FC<ISaveChangeModalProps> = ({
  onClose,
  setCreativeCheckedList,
  saveMassEditData,
}) => {
  const [counter, setCounter] = useState<number>(CANCEL_TIME);
  const dispatch = useDispatchApp();
  const intervalRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCounter((prev) => prev - 1);
    }, 1000);
    return () => {
      if (!intervalRef?.current) return;
      clearInterval(intervalRef?.current);
    };
  }, []);

  useEffect(() => {
    if (!intervalRef?.current || counter > 0) return;
    dispatch(
      massEditLinkAction({
        ...saveMassEditData,
        alertText: t(`creative.all_changes_saved`, {
          value: saveMassEditData.creatives_xxhashes.length,
        }),
        closeMassEditModal: onClose,
        clearCheckedList: () => setCreativeCheckedList([])
      }),
    );
    clearInterval(intervalRef?.current);
  }, [counter]);

  return (
    <ModalOverlay onCloseModal={onClose} isOpen className="SaveChangeModal">
      <div className={css.modal}>
        <Card className={css.card}>
          <SaveSvg className={css.img} />
          <div className={css.processing}>
            <Loader />
            <span>{t(`offers_page.saving_chanes`)}</span>
          </div>
          {!!counter && (
            <div className={css.cancel} onClick={onClose}>
              {`${t(`creative.discard_changes`)} (${counter}s)`}
            </div>
          )}
        </Card>
      </div>
    </ModalOverlay>
  );
};

export default SaveChangeModal;
