import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { TID } from 'types/general';
import { useDispatchApp } from 'redux/rootSelectors';
import { CreativeItemListWithStatistics, TRCreativeTypesItem } from 'domains/creative/types';
import {
  featchAllCreativeTypesAction,
  fetchCreativesList,
  resetFillCreativeList,
} from '../../../creative/reduser';
import { useCreativeListInfo } from '../../../creative/model/selectors';
import i18n from '../../../../i18n';

type TFUseExternalModeration = () => {
  tabIndex: number;
  setTabIndex: (v: number) => void;
  campaign_xxhash: string;
  creativeList: ReturnType<typeof useCreativeListInfo>['data'];
  isLoadingCreative: ReturnType<typeof useCreativeListInfo>['isLoading'];
};

export const useExternalModeration: TFUseExternalModeration = () => {
  const dispatch = useDispatchApp();

  const [tabIndex, setTabIndex] = useState(0);

  const { data: creativeList, isLoading: isLoadingCreative } =
    useCreativeListInfo();

  const {
    params: { id },
  } = useRouteMatch<TID>();

  useEffect(() => {
    dispatch(featchAllCreativeTypesAction());
    dispatch(
      fetchCreativesList({
        campaign_xxhash: id,
      }),
    );
    return () => {
      dispatch(resetFillCreativeList());
    };
  }, []);

  return {
    tabIndex,
    setTabIndex,
    campaign_xxhash: id,
    creativeList,
    isLoadingCreative,
  };
};

type TGetLinksReport = {
  disGetReport: boolean,
  onGetReport: () => void,
}

export const useGetLinksReport = (
  data: CreativeItemListWithStatistics[] | null,
  creativeTypesArr: TRCreativeTypesItem[] | null,
): TGetLinksReport => {
  function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  const worker = useMemo(() => new Worker('/workers/getLinksReport.js?v=1.0'), []);

  const [disGetReport, setDisGetReport] = useState(false);

  useEffect(() => {
    if (data?.length) {
      setDisGetReport(false);
      return;
    }
    setDisGetReport(true);
  }, [data]);

  let auditСouples: any[] = [];

  if (data) {
    data.forEach(d => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (d.audit) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        auditСouples.push(d.audit)
      } else {
        auditСouples.push(0);
      }
    })
  }

  auditСouples = auditСouples.map((audit) =>
    Object.entries(audit).map((a) => ({
      [i18n.t(`creative.video.fields.video_${a[0]}`)]: a[1],
    })),
  );

  const commonKeysArr = [
    i18n.t(`creative.widget_text.video_url`),
    i18n.t(`creative.creative_was_created`),
    i18n.t(`creative.creative_was_edited`),
    i18n.t(`creative.widget_text.link`),
    i18n.t(`creative.create_creative.Extension_link`),
    i18n.t(`creative.widget_text.adomain`),
    i18n.t(`creative.create_creative.Pixel_audit`),
    i18n.t(`campaigns_page.campaign_settings.additional.links.table.pixel_additional`),
  ];
  
  const configFieldsTitles = {
    erid: i18n.t(`creative.widget_text.ID_ERID`),
    js_code_audit: i18n.t(`creative.js_code_audit`),
    click_audit_pixel: i18n.t(`creative.click_audit_pixel`),
  }

  const onGetReport: () => void = useCallback(() => {
    setDisGetReport(true);
    worker.postMessage([
      data,
      creativeTypesArr,
      commonKeysArr,
      configFieldsTitles,
      auditСouples,
    ]);

    // eslint-disable-next-line no-param-reassign
    worker.onmessage = function (e) {
      const type = isSafari() ? 'application/csv' : 'text/csv';
      const blob = new Blob(['', e.data], { type });

      const URL = window.URL || window.webkitURL;

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', 'creoLinks.tsv');
      document.body.appendChild(link);
      link.click();
      link.remove();
      setDisGetReport(false);
    };
    // eslint-disable-next-line no-param-reassign
    worker.onerror = function (e) {
      console.error({ e });
      setDisGetReport(false);
    };
  }, [data]);

  return {
    disGetReport,
    onGetReport,
  };
};
