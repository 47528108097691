import React, { FC, useCallback, useState } from 'react';
import moment from 'moment';
import { Field, FieldProps, FormikValues } from 'formik';
import { useUserTheme } from 'domains/user/model/selectors';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../../../i18n';
import css from '../styles.module.scss';
import Help from '../../../../../../components/Help';
import DateInput from '../../../../../../components/UI/DateInput';
import { isFieldInObject } from '../../../../../../utils/isFieldInObject';
import { CampaignGeneralComponentProps } from '../../../../types';
import { useOuterElementClick } from '../../../../../../hooks/useOuterElementClick';
import EditCard from '../../../../../../components/UI/EditCard';

type PeriodProps = Pick<
  CampaignGeneralComponentProps,
  'formik' | 'autoSubmit' | 'savedField'
> & {
  showButton?: boolean;
  resetForm?: () => void;
};

const Period: FC<PeriodProps> = ({
  formik,
  autoSubmit,
  savedField,
  showButton = false,
  resetForm,
}) => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(!showButton);
  const { getValueFromSettingForKey } = useUserTheme();
  const closeBlock = useCallback(() => {
    if (resetForm) {
      resetForm();
    }
    setEdit(false);
  }, [resetForm, edit]);
  const { wrapperRef } = useOuterElementClick({
    isShow: showButton && edit,
    closeModal: closeBlock,
  });

  return (
    <EditCard
      wrapperRef={wrapperRef}
      hideButton={!showButton}
      noHover
      style={{ padding: '28px 24px' }}
      className={css.card}
      edited={edit}
      onSave={() => {
        if (showButton) {
          formik.handleSubmit();
          setEdit(!edit);
        }
      }}
      hasErrors={Object.keys(formik.errors).length > 0}
      setEdit={() => setEdit(!edit)}
      onResetForm={() => closeBlock()}
    >
      <h3>
        {i18n.t('campaigns_page.campaign_settings.Period')}
        {getValueFromSettingForKey('HideHelp') && (
          <Help
            width={150}
            title={i18n.t('campaigns_page.campaign_settings.Period')}
            helpattributes={{
              link: i18n.t(
                'campaigns_page.campaign_settings.campaign_prompt.period.link',
              ),
              sizeIcon: 18,
            }}
          />
        )}
      </h3>
      <div className={css.form}>
        <div className={css.row}>
          <div className={css.col}>
            <Field name="date_start" placeholder={`${t(`ord.enter_date`)}...`}>
              {({ field, form }: FieldProps<FormikValues>) => (
                <DateInput
                  autoSubmit={autoSubmit}
                  field={field}
                  form={form}
                  label={i18n.t('campaigns_page.campaign_settings.Start_date')}
                  error={!!formik.errors.date_start}
                  errorText={formik.errors.date_start}
                  isSaved={isFieldInObject(savedField, 'date_start')}
                  disabledDays={{
                    after: moment(formik.values.date_end).toDate(),
                  }}
                  notField={!edit}
                  theme="horizontal"
                />
              )}
            </Field>
          </div>
          <div className={css.col}>
            <Field name="date_end" placeholder={`${t(`ord.enter_date`)}...`}>
              {({ form, field }: FieldProps<FormikValues>) => (
                <DateInput
                  autoSubmit={autoSubmit}
                  form={form}
                  field={field}
                  label={i18n.t('campaigns_page.campaign_settings.End_date')}
                  error={!!formik.errors.date_end}
                  errorText={formik.errors.date_end}
                  notField={!edit}
                  isSaved={isFieldInObject(savedField, 'date_end')}
                  disabledDays={{
                    before: moment(formik.values.date_start).toDate(),
                  }}
                  theme="horizontal"
                />
              )}
            </Field>
          </div>
        </div>
      </div>
    </EditCard>
  );
};

export default Period;
