import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import CloseToastButton from 'components/CloseToastButton';
import ErrorBoundary from 'components/ErrorBoundary';
import 'react-toastify/dist/ReactToastify.css';
import { StateProvider, initialState, reducer } from 'utils/context';
import moment from 'moment-timezone';
import RootRoutes from 'domains/app/RootRoutes';
import history from './lib/history';
import i18n from './i18n';
import CurrentModals from './domains/currentModals';
import { store } from './redux/rootReducer';
import 'moment/locale/ru';

moment.tz.setDefault('Europe/Moscow');

const App: FC = () => (
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Router history={history}>
        <StateProvider initialState={initialState} reducer={reducer}>
          <ErrorBoundary>
            <RootRoutes />
          </ErrorBoundary>
          <ToastContainer
            closeButton={<CloseToastButton />}
            draggable={false}
            autoClose={3000}
            icon={false}
            hideProgressBar
          />
          <CurrentModals />
        </StateProvider>
      </Router>
    </I18nextProvider>
  </Provider>
);

export default App;
