import React, {
  useRef,
  useState,
  useCallback,
  ReactText,
  MouseEventHandler,
} from 'react';
import cn from 'classnames';
import { FiMoreHorizontal, FiPlus, FiX, FiCode } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import useDropDown from 'hooks/useDropDown';
import { ReactSVG } from 'react-svg';
import css from './styles.module.scss';
import { SortEvent } from '../../../../domains/campaign/types';
import defaultIcon from '../../../../assets/filterIcons/default.svg';
import ascIcon from '../../../../assets/filterIcons/asc.svg';
import descIcon from '../../../../assets/filterIcons/desc.svg';

export const tableEvents = ['ASCENDING', 'DESCENDING', 'DEL_SORT'] as const;
export const chartEvents = ['ADD_CHART', 'DEL_CHART'] as const;

export type TableEvents = typeof tableEvents[number];
export type ChartEvents = typeof chartEvents[number];

export type MenuEvent = TableEvents | ChartEvents;

export const isTableEvents = (param: MenuEvent): param is TableEvents =>
  tableEvents.includes(param as TableEvents);

export function isChartEvents(e: MenuEvent): e is ChartEvents {
  return chartEvents.includes(e as ChartEvents);
}

type Props = {
  title: string;
  sortType?: SortEvent['event'];
  chartType: ChartEvents;
  style?: React.CSSProperties;
  onClick: (e: MenuEvent) => void;
  noChart: boolean;
  notSort?: boolean;
  expand: number;
  setExpand: (v: number) => void;
  isShowExpand?: boolean;
  onSetFilterTable?: any;
  filterTable?: any;
  allTitles?: ReactText[];
  isUtmTags?: boolean;
  headerRef?: React.MutableRefObject<null | HTMLDivElement>;
};

const setCustomEvent = (onClick: Props['onClick'], v: MenuEvent) => () => {
  onClick(v);
};

const HeaderCell: React.FC<Props> = ({
  title,
  sortType,
  chartType,
  style,
  onClick,
  noChart,
  notSort,
  expand,
  setExpand,
  isShowExpand = false,
  onSetFilterTable,
  filterTable,
  allTitles = [],
  isUtmTags,
  headerRef,
}: Props) => {
  const dropdownMenu = useRef<HTMLDivElement | null>(null);
  const { toggle, isOpened, toggleOff } = useDropDown(dropdownMenu);

  const [listOffset, setListOffset] = useState<number>(0);

  const [expandStart, setExpandStart] = useState<boolean>(false);
  const [expandX, setExpandX] = useState<number>(0);

  const expandMouseDown = useCallback((e: React.MouseEvent) => {
    setExpandStart(true);
    setExpandX(e.clientX);
  }, []);

  const expandMouseUp = useCallback(() => {
    setExpandStart(false);
  }, []);

  const expandMouseMove = useCallback(
    (e: React.MouseEvent) => {
      if (expandStart) {
        const newExpand = expand - (expandX - e.clientX);
        if (newExpand > 240 && newExpand < 900) {
          setExpand(expand - (expandX - e.clientX));
        } else if (newExpand >= 900) setExpand(900);
        else setExpand(240);
      }
    },
    [expandX, expandStart],
  );

  const { t } = useTranslation();

  // eslint-disable-next-line consistent-return
  const handlerMore: MouseEventHandler<HTMLDivElement> = (e) => {
    if (isOpened) return toggleOff();
    toggle(e);
    if (headerRef?.current) {
      setListOffset(e.clientX - headerRef.current.getBoundingClientRect().left);
    }
  };

  return (
    <div
      className={cn(css.cell, 'HeaderCell', '_isAlignRight', {
        _isOpened: isOpened,
        _isFixed: isShowExpand,
      })}
      ref={dropdownMenu}
      style={{ ...style, width: isShowExpand ? `${expand}px` : '' }}
    >
      <div className={cn(css.button, 'HeaderCell_button')}>
        {isShowExpand && isUtmTags && (
          <>
            <div
              className={cn('checkbox', {
                isChecked: allTitles.length === filterTable.length,
                isChildChecked:
                  allTitles.length > filterTable.length &&
                  filterTable.length > 0,
              })}
              onClick={() => {
                if (filterTable.length > 0) {
                  return onSetFilterTable([]);
                }

                return onSetFilterTable(allTitles.filter((e) => e !== 'total'));
              }}
            />
          </>
        )}
        <div
          className={css.sortBtn}
          onClick={
            // eslint-disable-next-line no-nested-ternary
            !notSort && (!sortType || sortType === 'DEL_SORT')
              ? setCustomEvent(onClick, 'ASCENDING')
              : sortType === 'ASCENDING'
              ? setCustomEvent(onClick, 'DESCENDING')
              : setCustomEvent(onClick, 'DEL_SORT')
          }
        >
          <span className={cn(css.text, 'HeaderCell_text')} title={title}>
            {title === 'utm_tags' ? t('charts.utm_tags') : title}
          </span>
          <span className={css.sortIcon}>
            {sortType === 'DESCENDING' && (
              <ReactSVG src={descIcon} className="icon" />
            )}
            {sortType === 'ASCENDING' && (
              <ReactSVG src={ascIcon} className="icon" />
            )}

            {!notSort && (!sortType || sortType === 'DEL_SORT') && (
              <ReactSVG src={defaultIcon} className="icon" />
            )}
          </span>
        </div>
        {!noChart && (
          <div className={css.more} onClick={handlerMore}>
            <FiMoreHorizontal size={20} />

            <div className={css.list} style={{ left: `${listOffset}px` }}>
              {chartType === 'ADD_CHART' && (
                <div
                  className={css.item}
                  onClick={setCustomEvent(onClick, 'ADD_CHART')}
                >
                  <FiPlus size={16} />
                  <span>
                    {t(
                      'campaigns_page.campaign_settings.reports.show_fromchart',
                    )}
                  </span>
                </div>
              )}

              {chartType === 'DEL_CHART' && (
                <div
                  className={css.item}
                  onClick={setCustomEvent(onClick, 'DEL_CHART')}
                >
                  <FiX size={16} />
                  <span>
                    {t(
                      'campaigns_page.campaign_settings.reports.hide_fromchart',
                    )}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {isShowExpand && (
        <div
          className={cn(css.expand, {
            _isExpandStart: expandStart,
          })}
          onMouseDown={expandMouseDown}
          onMouseMove={expandMouseMove}
          onMouseUp={expandMouseUp}
        >
          <div className={css.button}>
            <FiCode size={12} />
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderCell;
