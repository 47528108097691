import { SagaIterator } from '@redux-saga/core';
import { takeEvery } from 'redux-saga/effects';
import {
  fetchSearchWorker,
  fetcherBreadcrumbsWorker,
  fetcherParentListWorker,
  fetcherModalBreadcrumbsWorker,
} from './workers';
import {
  fetchParentList,
  fetchBreadcrumbs,
  fetchSearchRequest,
  fetchModalBreadcrumbs,
} from '../actions';

export default function* searchWatcher(): SagaIterator {
  yield takeEvery(fetchSearchRequest, fetchSearchWorker);
  yield takeEvery(fetchBreadcrumbs, fetcherBreadcrumbsWorker);
  yield takeEvery(fetchParentList, fetcherParentListWorker);
  yield takeEvery(fetchModalBreadcrumbs, fetcherModalBreadcrumbsWorker);
}
