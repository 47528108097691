import { THookSelectorFetchDataInfo, TSelector, useGenFetchDataSelector } from 'redux/rootSelectors';
import { contractsState } from './reducer';
import { ContractsList } from './types';

export const selectorContractsList: TSelector<contractsState['contractsList']> = ({
  contractsReducer,
}) => contractsReducer.contractsList;

export const useContractsList: THookSelectorFetchDataInfo<ContractsList> = () =>
  useGenFetchDataSelector(selectorContractsList);
