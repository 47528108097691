import historyImage from '../../assets/errors/empty-history.svg';
import generalImage from '../../assets/errors/empty-general.svg';
import noResultSearchImage from '../../assets/NoResultSearch.svg';
import containersImage from '../../assets/errors/empty-containers.svg';
import NoActiveAgencySvg from '../../assets/errors/no-active-agency.svg';
import NoActiveOffersSvg from '../../assets/errors/no-active-offers.svg';
import NoContractorsSvg from '../../assets/errors/no-contractors.svg';
import NoContractsSvg from '../../assets/errors/no-active-contracts.svg';
import NoDelContractsSvg from '../../assets/errors/no-del-contracts.svg';
import NoArhivOffersSvg from '../../assets/errors/no-arhiv-offers.svg';
import NoStopAgencySvg from '../../assets/errors/no-stop-agency.svg';
import NoArhivAgencySvg from '../../assets/errors/no-arhiv-agency.svg';
import NoDeleteAgencySvg from '../../assets/errors/no-delete-agency.svg';
import NoActiveCampaignSvg from '../../assets/errors/no-active-campaign.svg';
import NoStopCampaignSvg from '../../assets/errors/no-stop-campaign.svg';
import NoArhivCampaignSvg from '../../assets/errors/no-arhiv-campaign.svg';
import NoDeleteCampaignSvg from '../../assets/errors/no-delete-campaign.svg';
import NoAllCampaignSvg from '../../assets/errors/empty-campaign.svg';
import creativeImage from '../../assets/errors/empty-creative.svg';

export const ElementsNoneImages = {
    general: generalImage,

    no_search: noResultSearchImage,

    history_search: historyImage,
    containers_empty: containersImage,
    creo_empty: creativeImage,

    clients_empty_active: NoActiveAgencySvg,
    clients_empty_stop: NoStopAgencySvg,
    clients_empty_arhiv: NoArhivAgencySvg,
    clients_empty_del: NoDeleteAgencySvg,

    agencies_empty_active: NoActiveAgencySvg,
    agencies_empty_stop: NoStopAgencySvg,
    agencies_empty_arhiv: NoArhivAgencySvg,
    agencies_empty_del: NoDeleteAgencySvg,

    campaign_empty_active: NoActiveCampaignSvg,
    campaign_empty_stop: NoStopCampaignSvg,
    campaign_empty_arhiv: NoArhivCampaignSvg,
    campaign_empty_del: NoDeleteCampaignSvg,
    campaign_empty_all: NoAllCampaignSvg,

    offers_empty_active: NoActiveOffersSvg,
    offers_empty_arhiv: NoArhivOffersSvg,
    offers_empty_all: NoActiveOffersSvg,

    cntractors_empty_all: NoContractorsSvg,

    contracts_empty_all: NoContractsSvg,
    contracts_empty_del: NoDelContractsSvg,
}