import request from '../../../lib/helpers/requestApi';
import { AllCounteragetnList, CounteragentsList, CounteragentsListParams, OrdList } from './types';

export const getCounteragentsListAPI = request<CounteragentsList, CounteragentsListParams>({
  method: 'counteragent.getList',
});

export const getAllCounteragentAPI = request<AllCounteragetnList>({
  method: 'counteragent.getAll',
});

export const getOrdListAPI = request<OrdList>({
  method: 'counteragent.getOrdList',
});

