import React, { FC, useEffect } from 'react';
import { Element, scroller, animateScroll as scroll } from 'react-scroll';
import { RouteComponentProps } from 'react-router';
import Item from '../Item';
import { CreativeItemListWithStatistics, RCreativeClone } from '../../types';
import { TFieldStatistics } from '../../../campaign/types';
import {
  PHandlerChangeStatus,
  PHandlerCloneCreative,
  PHandlerRequestCleanAllTargets,
  PHandlerRequestDelete,
} from '../hooks/useCreativesList';
import { ErrorResponse, TID } from '../../../../types/general';

type Props = {
  isLoading: boolean;
  isShowEmpty: boolean;
  lastId: string;
  creativeListView: CreativeItemListWithStatistics[];
  /** объект классов для колонки */
  colClass: Record<string, string>;
  /** список ключей статистики */
  keysFields: TFieldStatistics[];
  /** состояние при наведение */
  toggle: string;
  setToggle: (v: string) => void;
  /** функция дублирования креатива */
  handlerCloneCreative: (P: PHandlerCloneCreative) => void;
  /** состояние запроса */
  cloneResult: 'SUCCESS' | 'ERROR' | null;
  cloneResponse: RCreativeClone | ErrorResponse | null;
  /** состояние модального окна копирования */
  openCopyModal: string;
  setOpenCopyModal: (v: string) => void;
  /** состояние модального окна удаления */
  openDeleteModal: string;
  setOpenDeleteModal: (v: string) => void;
  /** состояние модального окна очистки креатива */
  openCleanModal: string;
  setOpenCleanModal: (v: string) => void;
  /** функция удаления креатива */
  handlerRequestDelete: (v: PHandlerRequestDelete) => void;
  /** функция очистки таргетов креатива */
  handlerRequestCleanAllTargets: (v: PHandlerRequestCleanAllTargets) => void;
  /** функция изменение статуса креатива */
  handlerChangeStatus: (v: PHandlerChangeStatus) => void;
  /** дублируемый креатив */
  copyCreative: string;
  isThemeExtended: boolean;
  isThemeFull: boolean;
  match: RouteComponentProps<TID>['match'];
  setCloneResult: (p: Props['cloneResult']) => void;
  setCloneResponse: React.Dispatch<
  React.SetStateAction<RCreativeClone | ErrorResponse | null>
  >;
  creativeCheckedList: string[];
  handlerSetCreativeInCheckedList: (value: CreativeItemListWithStatistics) => void;
};

const List: FC<Props> = ({
  isShowEmpty,
  creativeListView,
  toggle,
  setToggle,
  lastId,
  colClass,
  keysFields,
  handlerChangeStatus,
  copyCreative,
  handlerCloneCreative,
  cloneResult,
  setOpenDeleteModal,
  setOpenCopyModal,
  openCleanModal,
  setOpenCleanModal,
  setCloneResponse,
  handlerRequestDelete,
  handlerRequestCleanAllTargets,
  openDeleteModal,
  openCopyModal,
  cloneResponse,
  isThemeExtended,
  isThemeFull,
  // eslint-disable-next-line no-shadow
  match,
  setCloneResult,
  isLoading,
  handlerSetCreativeInCheckedList,
  creativeCheckedList,
}) => {
  useEffect(() => {
    if (lastId === '' && isShowEmpty) return;
    const lastItem = document.getElementsByName(lastId);
    if (lastItem.length === 0 && lastId !== '') {
      scroll.scrollToBottom();
    }
    scroller.scrollTo(lastId, {
      duration: 0,
      delay: 100,
      smooth: false,
      offset: -450,
    });
  }, [lastId, isShowEmpty]);
  return (
    <>
      {!isLoading &&
        creativeListView.map((e) => (
          <Element name={e.xxhash} key={e.xxhash}>
            <Item
              toggle={toggle}
              setToggle={setToggle}
              creative={e}
              colClass={colClass}
              keysFields={keysFields}
              link={`${match.url}/${e.xxhash}`}
              isLastEdited={lastId === e.xxhash}
              handlerCloneCreative={handlerCloneCreative}
              cloneResponse={cloneResponse}
              cloneResult={cloneResult}
              openCopyModal={openCopyModal}
              setOpenCopyModal={setOpenCopyModal}
              openCleanModal={openCleanModal}
              setOpenCleanModal={setOpenCleanModal}
              handlerRequestCleanAllTargets={handlerRequestCleanAllTargets}
              handlerRequestDelete={handlerRequestDelete}
              openDeleteModal={openDeleteModal}
              setOpenDeleteModal={setOpenDeleteModal}
              fullTitle={isThemeExtended || isThemeFull}
              handlerChangeStatus={handlerChangeStatus}
              copyCreative={copyCreative}
              setCloneResult={setCloneResult}
              setCloneResponse={setCloneResponse}
              handlerSetCreativeInCheckedList={handlerSetCreativeInCheckedList}
              creativeCheckedList={creativeCheckedList}
            />
          </Element>
        ))}
    </>
  );
};

export default List;
