import React, { FC, useCallback, useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { FiCopy, FiX } from 'react-icons/fi';
import Input from 'components/UI/Input/input';
import { FormikErrors, FormikProvider, useFormik } from 'formik';
import { checkedLocalization } from 'utils/checkedLocalization';
import css from './styles.module.scss';
import Button from '../../../../components/UI/Buttons/buttons';
import ModalOverlay from '../../../../components/Modals/ModalOverlay1';
import { toastSuccess } from '../../../../utils/ToastUtils/toastUtils';

type PAddAjaxListModal = {
  /** открыто */
  isOpen: boolean;
  /** при закрытии */
  onCloseModal: () => void;
  /** тип таргета */
  type: string;
  /** Если изменение */
  title: string;
  /** вызывается по кнопки добавить */
  onHandleSubmit: (v: { key: string; title: string; value: string }[]) => void;
  /** данные */
  data?: { title: string; value: string };
  /** устанавливает title & value по ключу */
  onChangeTitleVal: (k: string, t: string, v: string) => void;
  /** удаляет по ключу */
  onRemoveItem: (k: string) => void;
};
const ContextBoxM: FC<PAddAjaxListModal> = ({
  isOpen,
  onCloseModal,
  type,
  onHandleSubmit,
  data,
  onChangeTitleVal,
  onRemoveItem,
}) => {
  type TFormik = {
    name: string;
    keywords: string;
  };
  const { t } = useTranslation();

  const [typeM, setTypeM] = useState<'add' | 'change'>('add');

  const formikContext = useFormik<TFormik>({
    initialValues: {
      name: '',
      keywords: '',
    },
    // eslint-disable-next-line no-shadow
    onSubmit: (values) => {
      if (typeM === 'change') {
        onChangeTitleVal(data?.title || '', values.name, values.keywords);
        onCloseModal();
        return;
      }

      // onHandleSubmit([
      //   {
      //     key: values.name,
      //     title: values.name,
      //     value: values.keywords,
      //   },
      // ]);
      onCloseModal();
    },
    validate: (values: FormikErrors<TFormik>) => {
      const errors: FormikErrors<TFormik> = {};

      if (values.name?.length === 0) {
        errors.name = t('errors.err45');
      }
      if (values.keywords?.length === 0) {
        errors.keywords = t('errors.err45');
      }

      return errors;
    },
    validateOnMount: true,
    enableReinitialize: true,
    initialTouched: {
      name: false,
      keywords: false,
    },
  });

  const {
    values,
    errors,
    handleChange,
    setValues,
    handleSubmit,
    handleBlur,
    touched,
    setTouched,
    setFieldValue,
  } = formikContext;

  useEffect(() => {
    setValues({
      name: '',
      keywords: '',
    });
    setTouched({
      name: false,
      keywords: false,
    });
  }, [isOpen]);

  useEffect(() => {
    if (!data) {
      setTypeM('add');
      return;
    }

    setTypeM('change');
    setValues({
      name: data.title,
      keywords: data.value,
    });
  }, [data]);

  const onHandler = useCallback(() => {
    onHandleSubmit([
      {
        key: values.name,
        title: values.name,
        value: values.keywords,
      },
    ]);
    handleSubmit();
  }, [values]);

  return (
    <ModalOverlay onCloseModal={onCloseModal} isOpen={isOpen}>
      <div className={css.card}>
        <FormikProvider value={formikContext}>
          <div className={css.header}>
            <div className={css.title}>
              <h1>
                {checkedLocalization(
                  `targetings.add_popap_tit.${type}`,
                  `targetings.add_popap_tit.general`,
                )}
              </h1>
            </div>
            <div className={css.close}>
              <FiX size={24} onClick={onCloseModal} />
            </div>
          </div>
          <div className={css.content}>
            <div className={css.name}>
              <Input
                error={!!errors.name && !!touched.name}
                errortext={errors.name}
                label={t('targetings.keywords.t16')}
                showValueCount
                inputAttributes={{
                  name: 'name',
                  onBlur: handleBlur,
                  onChange: handleChange,
                  value: values.name,
                  autoComplete: 'off',
                  maxLength: 255,
                }}
              />
            </div>
            <Input
              label={t('targetings.keywords.t12')}
              showValueCount
              isTextarea
              error={!!errors.keywords && touched.keywords}
              errortext={errors.keywords}
              classname={css.textarea}
              inputAttributes={{
                name: 'keywords',
                placeholder: t('targetings.keywords.t11'),
                onBlur: (event: React.FocusEvent) => {
                  if (values.keywords) {
                    setFieldValue(
                      'keywords',
                      values.keywords
                        .split(',')
                        .map((word) => word.trim())
                        .filter((word) => word !== '')
                        .join(', '),
                    );
                  }
                  handleBlur(event);
                },
                onChange: handleChange,
                value: values.keywords,
                autoComplete: 'off',
                maxLength: 4096,
              }}
            />
          </div>

          <div className={css.actions}>
            <div className={css.cancel}>
              <Button
                transparent
                title={
                  typeM === 'change'
                    ? t(`targetings.keywords.t9`)
                    : t('cancel_btn')
                }
                height={40}
                autoWidth
                buttonAttributes={{
                  onClick: () => {
                    if (typeM === 'add') {
                      onCloseModal();
                      return;
                    }
                    onRemoveItem(data?.title ?? '');
                    onCloseModal();
                  },
                }}
              />
            </div>
            <div className={css.main}>
              <Button
                lightblue
                title={t(`common.modal.copy_list`)}
                height={40}
                autoWidth
                icon={<FiCopy size={16} />}
                buttonAttributes={{
                  disabled: !values.keywords,
                  onClick: () => {
                    copy(values.keywords);
                    toastSuccess(t('targetings.list_copy'));
                  },
                }}
              />
              <Button
                title={t('save_btn')}
                height={40}
                autoWidth
                buttonAttributes={{
                  type: 'submit',
                  disabled: !formikContext.isValid,
                  onClick: () => onHandler(),
                }}
              />
            </div>
          </div>
        </FormikProvider>
      </div>
    </ModalOverlay>
  );
};

export default ContextBoxM;
