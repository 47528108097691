import { useCallback } from 'react';
import { fetchContainerDetailByID } from './reducer';
import { FetchByIDParam } from '../../types/containers';
import { useDispatchApp } from '../../redux/rootSelectors';

type TFUseContainerByID = (props: FetchByIDParam) => () => void;

export const useContainerByID: TFUseContainerByID = (param) => {
  const dispatch = useDispatchApp();
  return useCallback(() => {
    dispatch(fetchContainerDetailByID(param));
  }, [dispatch]);
};
