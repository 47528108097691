import React, { CSSProperties, FC, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useDispatchApp } from 'redux/rootSelectors';
import { fetchBreadcrumbs } from 'domains/search/model/actions';
import Tooltip from 'components/UI/Tooltip';
import css from './styles.module.scss';
import { useUserInfo } from '../../domains/user/model/selectors';
import { useSearchBreadcrumbsInfo } from '../../domains/search/model/selectors';
import LoadingBreadcrumbs from './components/loading';

type Props = {
  xxhash?: null | string;
  type:
    | 'Creative'
    | 'Organization'
    | 'Agency'
    | 'Client'
    | 'Campaign'
    | 'Custom';
  linksCustom?: {
    title: string;
    url: string;
    type: string;
  }[];
  style?: CSSProperties;
};
type TLink = {
  title: string;
  url: string;
  type: Props['type'];
};

const Breadcrumbs: FC<Props> = ({ xxhash, type, style, linksCustom }) => {
  const dispatch = useDispatchApp();

  const { data: breadcrumbs, LTU } = useSearchBreadcrumbsInfo();
  const { data: user } = useUserInfo();

  useEffect(() => {
    if (xxhash) {
      dispatch(fetchBreadcrumbs({ xxhash }));
    }
    if(!xxhash && user && user.partner.xxhash){
      dispatch(fetchBreadcrumbs({ xxhash: user.partner.xxhash }));
    }
  }, [xxhash]);

  const links = useMemo<TLink[]>(() => {
    let arLinks: TLink[] = [];
    let keys = (breadcrumbs && Object.keys(breadcrumbs)) || [];
    if (user) {
      const keyIndex = keys.findIndex(
        (key) =>
          key.toLocaleLowerCase() === user.partner.type.toLocaleLowerCase(),
      );
      if (keyIndex + 1) {
        keys = keys.slice(keyIndex, keys.length);
      }
    }
    if (keys && breadcrumbs) {
      keys.forEach((key) => {
        if (key === 'Organization') {
          arLinks = [
            {
              title: breadcrumbs.Organization.title,
              url: `/main/agencies`,
              type: key,
            },
          ];
        }
        if (key === 'Agency') {
          arLinks = [
            ...arLinks,
            {
              title: breadcrumbs.Agency.title,
              url: `/main/clients?agency_id=${breadcrumbs.Agency.xxhash}`,
              type: key,
            },
          ];
        }
        if (key === 'Client') {
          arLinks = [
            ...arLinks,
            {
              title: breadcrumbs.Client.title,
              url: `/main/campaigns?client_id=${breadcrumbs.Client.xxhash}`,
              type: key,
            },
          ];
        }
        if (key === 'Campaign') {
          arLinks = [
            ...arLinks,
            {
              title: breadcrumbs.Campaign.title,
              url: `/main/campaign/${breadcrumbs.Campaign.xxhash}/main`,
              type: key,
            },
          ];
        }
        if (key === 'Creative') {
          arLinks = [
            ...arLinks,
            {
              title: breadcrumbs.Creative.title,
              url: `/main/campaign/${breadcrumbs.Campaign.xxhash}/creatives/${breadcrumbs.Creative.xxhash}/basic_settings`,
              type: key,
            },
          ];
        }
      });
    }
    return arLinks;
  }, [LTU, user, breadcrumbs]);

  if (linksCustom && type === 'Custom') {
    return (
      <div className={css.breadcrumbs} style={style}>
        {linksCustom.map((link) => (
          <Tooltip
            key={link.type}
            bottom
            className={cn(css.item, {
              isNotLink: link.type === 'end',
            })}
            title={
              link.type === 'end' ? (
                <span className={cn(css.link, '_noLink')}>{link.title}</span>
              ) : (
                <span className={css.link}>
                  <Link to={link.url} key={link.url}>
                    {link.title}
                  </Link>
                </span>
              )
            }
          >
            {link.title}
          </Tooltip>
        ))}
      </div>
    );
  }

  if (links.length > 0) {
    return (
      <div className={css.breadcrumbs} style={style}>
        {links.map((link) => (
          <Tooltip
            key={link.type}
            bottom
            className={cn(css.item, {
              isNotLink: link.type === type,
            })}
            title={
              link.type === type ? (
                <span className={cn(css.link, '_noLink')}>{link.title}</span>
              ) : (
                <span className={css.link}>
                  <Link to={link.url} key={link.url}>
                    {link.title}
                  </Link>
                </span>
              )
            }
          >
            {link.title}
          </Tooltip>
        ))}
      </div>
    );
  }
  return <LoadingBreadcrumbs />;
};

export default Breadcrumbs;
