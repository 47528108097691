export default {
  tooltip: { isHtml: true, trigger: 'both' },
  legend: { position: 'none' },
  animation: {
    startup: true,
    easing: 'linear',
    duration: 100,
  },
  chartArea: {
    width: '90%',
    height: '80%',
    left: 64,
    right: 64,
    backgroundColor: {
      stroke: '#ddd',
      strokeWidth: 1,
    },
  },
  vAxis: {
    format: 'short',
    gridlines: {
      color: '#ddd',
      count: 5,
    },
    minorGridlines: { count: 0, color: '#ddd' },
    baselineColor: '#ddd',
    textStyle: {
      color: '#828282',
      fontName: 'Rubik',
      fontSize: 12,
    },
    viewWindow: {
      min: 100,
    },
  },
  hAxis: {
    slantedText: false,
    maxAlternation: 1,
    minTextSpacing: 50,
    textStyle: {
      color: '#828282',
      fontName: 'Rubik',
      fontSize: 12,
    },
  },
  series: {
    0: {
      color: '#025DFF',
      areaOpacity: 0.05,
      targetAxisIndex: 0,
    },
    1: {
      color: '#40BAFF',
      areaOpacity: 0.05,
      targetAxisIndex: 1,
    },
  },
  focusTarget: 'category',
  aggregationTarget: 'none',
  // selectionMode: 'multiple',
  crosshair: {
    trigger: 'both',
    orientation: 'vertical',
    color: '#025DFF',
    opacity: 0.7,
  },
};
