import { FiTrash2, FiXCircle } from 'react-icons/fi';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/rootReducer';
import css from './styles.module.scss';
import { TUploadFile } from '../../../utils/upload';
import { ClientState, initialState, setTempImage } from '../reduser';
import ErrorDictionary from '../../../components/Errors/ErrorDictionary';

type TPInfo = {
  /** код ошибки */
  errorCode?: number;
  /** временный файл который еще не привязан к клиенту =undefined */
  tempFile?: TUploadFile;
  /** уже загруженный файл  =undefined */
  loadFile?: string;
  /** удаляет загруженный файл  =undefined */
  onDelLoadImg?: () => void;
  /** ссылка на инпут элемент для сброса значения */
  uploaderRef?: React.RefObject<HTMLInputElement>;
  setLoadImg: React.Dispatch<React.SetStateAction<string | undefined>>;
};
export default function Info({
  errorCode,
  tempFile,
  loadFile,
  uploaderRef,
  onDelLoadImg,
  setLoadImg,
}: TPInfo): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const imageInfo = useSelector<AppState, ClientState['tempImage']>(
    ({ сlientReducer }) => сlientReducer.tempImage,
  );
  const uploaderInput = uploaderRef?.current;
  const onClearTempImage = useCallback(() => {
    dispatch(setTempImage(initialState.tempImage));
  }, []);

  // вывод ошибки
  if (errorCode) {
    return (
      <span className={css.error}>
        <ErrorDictionary code={errorCode} />
        <FiXCircle
          size="20"
          onClick={() => {
            if (errorCode) {
              dispatch(
                setTempImage(
                  imageInfo.set('error', {
                    isError: false,
                    message: '',
                  }),
                ),
              );
              if (uploaderInput) {
                uploaderInput.value = '';
              }
            }
          }}
        />
      </span>
    );
  }

  // вывод  инфо по файлу временному
  if (tempFile !== undefined) {
    return (
      <span
        className={css.remove}
        onClick={() => {
          onClearTempImage();
          if (uploaderInput) {
            uploaderInput.value = '';
          }
          if (onDelLoadImg) {
            onDelLoadImg();
            return;
          }
          setLoadImg(undefined);
        }}
      >
        <FiTrash2 size="20" />
        {t('clients_page.client_edit.client_edit_delete_avatar')}
      </span>
    );
  }

  // удалить загруженный файл
  if (tempFile !== undefined || loadFile !== undefined) {
    return (
      <span
        className={css.remove}
        onClick={() => (onDelLoadImg ? onDelLoadImg() : setLoadImg(undefined))}
      >
        <FiTrash2 size="20" />
        {t('clients_page.client_edit.client_edit_delete_avatar')}
      </span>
    );
  }

  // вывод дефолтной инфы инфы
  return (
    <div className={css.description}>
      <p>{t('clients_page.client_create.client_img_formats')}</p>
      <p>{t('clients_page.client_create.client_img_size')}</p>
      <p>{t('clients_page.client_create.client_img_maxsize')}</p>
    </div>
  );
}
