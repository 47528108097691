import React, { CSSProperties, FC, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import cn from 'classnames';
import { FiChevronDown } from 'react-icons/fi';
import css from './styles.module.scss';

type Props = {
  title: string | JSX.Element;
  customOpen?: boolean;
};

const Dropdown: FC<Props> = ({ title, children, customOpen }) => {
  const [toggle, setToggle] = useState<boolean>(customOpen || false);

  const showBlock = useSpring<CSSProperties>(
    toggle
      ? {
          height: 'auto',
          visibility: 'visible',
          opacity: 1,
          transform: 'translateY(0)',
          config: { duration: 120 },
          delay: 0,
          display: 'block',
        }
      : {
          opacity: 0,
          height: 0,
          visibility: 'hidden',
          transform: 'translateY(-20px)',
          delay: 30,
          display: 'none',
        },
  );

  return (
    <div className={cn(css.dropdown, { _isToggle: toggle })}>
      <div className={css.title} onClick={() => setToggle(!toggle)}>
        <div className={css.content}>{title}</div>
        <div className={css.spacer} />
        <FiChevronDown className={css.chevron} size={24} color="#0a0a0a" />
      </div>
      <animated.div style={showBlock} className={css.children}>
        {children}
      </animated.div>
    </div>
  );
};

export default Dropdown;
