import React, { FC } from 'react';
import Card from 'components/UI/Card';
import { useTranslation } from 'react-i18next';
import Input from 'components/UI/Input/input';
import { FormikProps } from 'formik';
import { TFormikSettigns } from 'domains/campaign/types';
import CollapseCard from 'components/UI/CollapseCard';
import css from '../styles.module.scss';

interface ILinkingExternalProp {
  formik: FormikProps<TFormikSettigns>;
}

const LinkingExternal: FC<ILinkingExternalProp> = ({ formik }) => {
  const { t } = useTranslation();

  return (
    <Card noHover className={css.card}>
      <CollapseCard
        closedStart
        noUpdateHeight
        title={
          <h3 className={css.title}>
            {t('campaigns_page.campaign_settings.additional.ext_sour')}
          </h3>
        }
      >
        <div className={css.form}>
          <Input
            label={t('campaigns_page.campaign_settings.additional.ids')}
            isTextarea
            inputAttributes={{
              name: 'external_ids',
              placeholder: t(
                'campaigns_page.campaign_settings.additional.enter_date',
              ),
              onChange: formik.handleChange,
              value: formik.values.external_ids,
              style: { minHeight: 80 },
            }}
            tooltip={{
              text: t('campaigns_page.campaign_settings.additional.id_from_ss'),
            }}
          />
          <Input
            label={t(
              'campaigns_page.campaign_settings.additional.dashboard_ids',
            )}
            isTextarea
            inputAttributes={{
              name: 'dashboard_data',
              placeholder: t(
                'campaigns_page.campaign_settings.additional.enter_date',
              ),
              onChange: formik.handleChange,
              value: formik.values.dashboard_data,
              style: { minHeight: 80 },
            }}
          />
        </div>
      </CollapseCard>
    </Card>
  );
};

export default LinkingExternal;
