import { isBefore, addDays, format } from "date-fns"

export const calculateGeoDataLiveTime = (now: Date): string => {
    const nowStringFormat = format(now, 'yyyy-MM-dd')
    const morningUpdateTime = '06:00:00.000+03:00'
    const todayUpdate = `${nowStringFormat}T${morningUpdateTime}`
    const isNightTime = isBefore(now, new Date(todayUpdate))
    if (isNightTime) return todayUpdate
    const nextDayUpdate = addDays(now, 1)
    return `${format(nextDayUpdate, 'yyyy-MM-dd')}T${morningUpdateTime}`
}