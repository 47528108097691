import { chartColors } from 'components/ReportChart/model/chartColors';
import {
  StatisticsType,
  TChartDate,
  TChartItems,
  TNamingItem,
} from 'domains/campaign/types';
import { ChartStatisticItem } from 'domains/campaigns/types';
import moment from 'moment';

export default function getChartData(
  statistics: ChartStatisticItem[] | TChartDate[],
  chartItems: ReadonlyArray<TChartItems>,
  namingItem: TNamingItem,
): Array<any[]> | false {
  if (!statistics) return [];

  const chartShow = chartItems.filter((e) => !e.isOff);

  const createCustomHTMLContent = (
    element: Record<string, StatisticsType>,
  ) => `<div class='tooltip'>
        <strong>${moment(element.date).format('DD MMM YYYY')}</strong>
  
        ${chartShow.reduce((total, n, i) => {
          const obj = namingItem.get(n.name);
          const { title, color } =
            typeof obj !== 'undefined'
              ? obj
              : { title: n.name, color: chartColors[i] };
          return `${total} <span style='color: ${color}' >${title} · ${
            Math.round(+element[n.name === 'Installs' ? 'lead' : n.name] * 100 || 0) / 100 ?? '-'
          }</span>`;
        }, '')}
      </div>`;
  return (
    statistics &&
    statistics.length > 0 && [
      [
        'Дата',
        { role: 'tooltip', type: 'string', p: { html: true } },
        ...chartShow.map((i) => namingItem.get(i.name) || i),
      ],
      ...statistics.map((e) => [
        moment(e.date).format('DD.MM'),
        // Надо постараться поправить в будущем, почему-то даже отфильтровав statistics по значению child все равно ошибка
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        createCustomHTMLContent(e),
        ...chartShow.map((i) => e[i.name] || 0),
      ]),
    ]
  );
}
