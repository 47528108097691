import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import i18n from 'i18next';
import Chart from 'react-google-charts';
import { FiDownload, FiHelpCircle, FiExternalLink } from 'react-icons/fi';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import toLocaleStringSupporte from 'utils/toLocaleStringSupporte';
import Button from 'components/UI/Buttons/buttons';
import Tooltip from 'components/UI/Tooltip';
import ResizedCard from 'components/ResizedCard';
import ErrorReport from 'components/Errors/Reports';
import options from './optionsChart';
import css from './styles.module.scss';
import './styles.css';
import { ContainerByIDStatistics } from '../../../types/containers';
import { TID } from '../../../types/general';

type Props = {
  statistics: ContainerByIDStatistics[];
  id: string;
  onDownloadRequest: (param: TID) => void;
};

const Reports: FC<Props> = ({ statistics, onDownloadRequest, id }) => {
  const { t } = useTranslation();
  const createCustomHTMLContent = (
    element: ContainerByIDStatistics,
  ) => `<div className='tooltip'>
      <strong>${moment(element.date)
        .locale(i18n.language)
        .format('DD MMMM YYYY')}</strong>
      <span>${t('containers_page.size_aut')} · ${element.total}</span>
      <span>${t('containers_page.size_aut_day')} · ${element.day || '–'}</span>
    </div>`;

  const chartData = statistics && [
    [
      t('containers_page.data'),
      { role: 'tooltip', type: 'string', p: { html: true } },
      t('containers_page.size_aut'),
      t('containers_page.size_aut_day'),
    ],
    ...Object.values(statistics)
      .reverse()
      .map((e) => [
        moment(e.date).format('DD.MM'),
        createCustomHTMLContent(e),
        e.total,
        e.day ? e.day : 0,
      ]),
  ];

  if (!statistics || statistics.length === 0) {
    return (
      <ErrorReport
        error={{ code: 90, isError: true, message: 'error' }}
        type="Reports"
      />
    );
  }

  return (
    <div className="report">
      <Helmet>
        <title>Просмотр контейнера</title>
      </Helmet>
      <ResizedCard>
        <div className={css.chart}>
          <div className={css.chart__legend}>
            <div className={css.chart__item}>
              <div style={{ backgroundColor: '#025DFF' }} />
              <span>{t('containers_page.size_aut')}</span>
            </div>
            <div className={css.chart__item}>
              <div style={{ backgroundColor: '#40BAFF' }} />
              <span>{t('containers_page.size_aut_day')}</span>
            </div>
          </div>
          <Chart
            chartType="AreaChart"
            width="100%"
            height="326px"
            data={chartData}
            options={options}
          />
        </div>
      </ResizedCard>

      <ResizedCard>
        <div className={css.table}>
          <div className={css.table__header}>
            <div className={css.table__title}>
              <h3>{t('containers_page.stat_tit')}</h3>
            </div>
            <div className={css.table__actions}>
              <Button
                transparent
                title={t('containers_page.download')}
                icon={<FiDownload size={24} />}
                height={36}
                buttonAttributes={{
                  onClick: () => onDownloadRequest({ id }),
                }}
              />
            </div>
          </div>

          <table>
            <thead>
              <tr>
                <th>{t('containers_page.date')}</th>
                <th>
                  {t('containers_page.size_aut')}{' '}
                  <Tooltip white hintWidth={300} title={<FiHelpCircle size={16} />}>
                    <p>{t('containers_page.more_info')}</p>
                    <p>
                      <a
                        href="https://terratraf.readme.io/docs/td-general-containers"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('more_btn')} <FiExternalLink size={16} />
                      </a>
                    </p>
                  </Tooltip>
                </th>
                <th>
                  {t('containers_page.size_aut_day')}{' '}
                  <Tooltip
                    className="custom-class"
                    white
                    hintWidth={300}
                    title={<FiHelpCircle size={16} />}
                  >
                    <p>{t('containers_page.more_info')}</p>
                    <p>
                      <a
                        href="https://terratraf.readme.io/docs/td-general-containers"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('more_btn')} <FiExternalLink size={16} />
                      </a>
                    </p>
                  </Tooltip>
                </th>
              </tr>
            </thead>
            <tbody>
              {statistics.length > 0 &&
                statistics.map((e) => (
                  <tr key={e.date}>
                    <td>{moment(e.date).format('DD.MM.YYYY')}</td>
                    <td>{e.total ? toLocaleStringSupporte(e.total) : '–'}</td>
                    <td>{e.day ? toLocaleStringSupporte(e.day) : '–'}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </ResizedCard>
    </div>
  );
};

export default Reports;
