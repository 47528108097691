import { toast } from 'react-toastify';
import React from 'react';
import { useAppNotification } from '../../../domains/user/model/selectors';
import AlertError from '../components/Alerts/AlertError';
import AlertSuccess from '../components/Alerts/AlertSuccess';
import { TGroup } from '../../../types/general';
import AlertSuccessDouble from '../components/Alerts/AlertSuccessDouble';

export const toastErrorCopy = (): void => {
  const isAppNotification = useAppNotification();
  if (isAppNotification) {
    toast(<AlertError />, {
      type: 'error',
      delay: 500,
      autoClose: 5000,
    });
  }
};

type TFToastSuccessCopy = (
  from: TGroup,
  to: TGroup,
  isAppNotification: boolean,
) => void;
export const toastSuccessCopy: TFToastSuccessCopy = (
  from,
  to,
  isAppNotification,
) => {
  if (isAppNotification) {
    toast(<AlertSuccess fromList={from} toList={to} />, {
      className: 'wide',
      type: 'success',
      delay: 500,
      autoClose: 10000,
    });
  }
};

type TFToastSuccessDoubled = (
  from: TGroup,
  to: string[],
  isAppNotification: boolean,
) => void;

export const toastSuccessDoubled: TFToastSuccessDoubled = (
  from,
  to,
  isAppNotification,
) => {
  if (isAppNotification) {
    toast(<AlertSuccessDouble campaigns={from} newCampaigns={to} />, {
      className: 'wide',
      type: 'success',
      delay: 500,
      autoClose: 10000,
    });
  }
};
