type TFIsFieldInObject = (
  obj: Record<string, unknown> | undefined,
  key: string,
) => boolean;

export const isFieldInObject: TFIsFieldInObject = (obj, key) => {
  if (!obj) {
    return false;
  }
  if (!key.includes('.')) {
    return key in obj;
  }
  const arrayKey = key.split('.');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const recursion = (value: Record<string, any>, keys: string[]) => {
    const k = keys.shift();
    if (!(k && k in value)) {
      return false;
    }
    if (!keys.length) {
      return k in value;
    }

    return recursion(value[k], keys);
  };
  return recursion(obj, arrayKey);
};
