import { useCampaignsInfo } from 'domains/campaigns/model/selectors';
import { fetchCampaigns } from 'domains/campaigns/reducer';
import { RCampaignsArrayStata } from 'domains/campaigns/types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { TID } from 'types/general';

interface campaignsReturn {
  campaigns: RCampaignsArrayStata | null;
  isLoadingCampaigns: boolean;
}

export function useGetCampaignsList(open: boolean): campaignsReturn {
  const dispatch = useDispatch();
  const { params } = useRouteMatch<TID>();
  const { data: campaigns, isLoading: isLoadingCampaigns } = useCampaignsInfo();

  useEffect(() => {
    dispatch(
      fetchCampaigns({
        partner_xxhash: params.id,
        status: '',
      }),
    );
  }, [open]);

  return { campaigns, isLoadingCampaigns };
}
