import { useHistory } from 'react-router-dom';
import { useCampaignsFilterInfo } from '../selectors';
import { useDispatchApp } from '../../../../redux/rootSelectors';
import { setCampaignsFilter, setListSort } from '../../reducer';
import { enCodeUrlFilter } from '../utils/encodeurlFilter';

type TFUseCampaignsFilterSettings = () => {
  filters: ReturnType<typeof useCampaignsFilterInfo>;
  handleRemoveFilterItem: (id: number) => void;
  handleClearAllFilterItem: () => void;
  handleApplyFilter: (data: ReturnType<typeof useCampaignsFilterInfo>) => void;
};
export const useCampaignsFilterSettings: TFUseCampaignsFilterSettings = () => {
  const dispatch = useDispatchApp();
  const history = useHistory();
  const filters = useCampaignsFilterInfo();

  const handleRemoveFilterItem: ReturnType<TFUseCampaignsFilterSettings>['handleRemoveFilterItem'] =
    (id) => {
      const result = filters.filter((item) => id !== item.id);
      dispatch(setCampaignsFilter(result));
      dispatch(setListSort({ key: null, sort: null }));
      const url = new URL(window.location.href);
      if (result.length) {
        url.searchParams.set('filter', enCodeUrlFilter(result));
      } else {
        url.searchParams.delete('filter');
      }
      history.push(url.search);
    };

  const handleApplyFilter: ReturnType<TFUseCampaignsFilterSettings>['handleApplyFilter'] =
    (data) => {
      dispatch(setCampaignsFilter(data));
      dispatch(setListSort({ key: null, sort: null }));
      const url = new URL(window.location.href);
      if (data.length) {
        url.searchParams.set('filter', enCodeUrlFilter(data));
      } else {
        url.searchParams.delete('filter');
      }
      history.push(url.search);
    };

  const handleClearAllFilterItem: ReturnType<TFUseCampaignsFilterSettings>['handleClearAllFilterItem'] =
    () => {
      dispatch(setCampaignsFilter([]));
      const url = new URL(window.location.href);
      url.searchParams.delete('filter');
      history.push(url.search);
    };

  return {
    filters,
    handleApplyFilter,
    handleRemoveFilterItem,
    handleClearAllFilterItem,
  };
};
