import { ReactText, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { checkedColors } from '../chartColors';
import updateToken from '../../../../../utils/updateToken';
import { getReport } from '../../../api';
import {
  PCampaignReport,
  RCampaignReportIntegration,
  TChartDate,
  TItemSFilter,
  TOnSetFilterTable,
  TSeries,
} from '../../../types';

type TAvailableItemsLiteral = 'impression' | 'view' | 'click' | 'spent';

type TParamUseFilter = {
  allTitles: ReactText[];
  requestParams: PCampaignReport;
  allChartData: TChartDate[] | undefined;
};

type TData = {
  title: string;
  data: TChartDate[];
};

type TElements = {
  title: string | ReactText;
  value: number;
}[];

type PCreateHTML = {
  date: string | ReactText;
  elements: TElements;
};

export type TFilter = {
  /** активное состояние селетора */
  isActive: TAvailableItemsLiteral;
  /** Список селекторов фильтра */
  availableItems: TAvailableItemsLiteral[];
  /** Список чекбоксов на графике */
  itemsFilter: TItemSFilter[];
  /** активные колонки на графике */
  activeColumns: (string | ReactText)[];
};

type TFUseIntegrationFilter = (p: TParamUseFilter) => {
  onSetFilterTable: TOnSetFilterTable;
  filter: TFilter;
  seriesChecked: TSeries;
  onSetFilterChart: (v: TFilter) => void;
  dataChecked: any;
  isLoadingData: boolean;
};

/** хук использования фильтра на графике */
export const useIntegrationFilter: TFUseIntegrationFilter = ({
  allTitles,
  requestParams,
  allChartData = [],
}) => {
  const { t } = useTranslation();

  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [data, setData] = useState<TData[]>([]);

  /** Фильтр для графика */
  const [filter, setFilter] = useState<TFilter>({
    isActive: 'click',
    availableItems: ['impression', 'view', 'click', 'spent'],
    itemsFilter: [],
    activeColumns: [],
  });

  const onSetFilterChart: ReturnType<TFUseIntegrationFilter>['onSetFilterChart'] =
    (settings) => {
      setFilter((prev) => ({ ...prev, ...settings }));
    };

  const dataChecked = useMemo(() => {
    if (!data.length) return [];

    const filteredData = data.filter((e) =>
      filter.itemsFilter.find((el) => el.key === e.title && el.checked),
    );

    const createCustomHTMLContent = (element: PCreateHTML) => {
      const items = element.elements.map(
        (e) =>
          `<span style='color: ${
            checkedColors[allTitles.findIndex((index) => index === e.title)]
          }'>${e.title === 'total' ? t('charts.total') : e.title} · ${
            e.value
          }</span>`,
      );

      return `<div class='tooltip' style='pointer-events: none;'>
        <strong>${moment(element.date).format('DD MMMM YYYY')}</strong>
        ${items}
      </div>`.replace(/,/gi, '');
    };

    let chartElements: (string | number)[][] = [];

    if (filteredData.length > 0) {
      const dates = filteredData[0].data.map((e) => e.date);

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < dates.length; i++) {
        chartElements[i] = [];

        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < filteredData.length; ++j) {
          chartElements[i][j] = Number(
            filteredData[j].data[i][filter.isActive],
          );
        }
      }

      chartElements = chartElements.map((e, i) => {
        const elements = e.reduce(
          (acc, curr, index) => [
            ...acc,
            { title: filteredData[index].title, value: curr as number },
          ],
          <TElements>[],
        );

        return [
          moment(dates[i]).format('DD.MM'),
          createCustomHTMLContent({ date: dates[i], elements }),
          ...e,
        ];
      });
    } else {
      return [['Дата', { role: 'tooltip', type: 'string', p: { html: true } }]];
    }

    return [
      [
        'Дата',
        { role: 'tooltip', type: 'string', p: { html: true } },
        ...filteredData.map((e) => e.title),
      ],
      ...chartElements,
    ];
  }, [data, filter]);

  const seriesChecked = useMemo<TSeries>(() => {
    const { itemsFilter } = filter;

    const filtredArr = itemsFilter.filter(
      (e) => e.key !== 'utm_tags' && e.checked,
    );

    if (filtredArr.length > 0) {
      return filtredArr.map((e) => ({
        color: checkedColors[allTitles.findIndex((index) => index === e.key)],
        areaOpacity: 0.05,
        targetAxisIndex: 0,
      }));
    }

    return [];
  }, [filter]);

  const totals = useMemo(() => {
    const filtred =
      allChartData &&
      allChartData.filter((e) => {
        const parseDate = (val: string) => Date.parse(val);
        const from = parseDate(requestParams.period.from);
        const to =
          requestParams.period.to && parseDate(requestParams.period.to);
        const current = parseDate(e.date as string);

        return current < Number(to) || current > from;
      });

    return {
      title: 'total',
      data: filtred,
    };
  }, [requestParams]);

  const request = useCallback(
    // eslint-disable-next-line consistent-return
    async (payload: { title: string }) => {
      setIsLoadingData(() => true);
      try {
        const { title } = payload;
        await updateToken();

        const result: RCampaignReportIntegration = await getReport({
          ...requestParams,
          ...payload,
          mode: 'return',
        });
        return { title, data: result.date };
      } catch (err) {
        console.log('err', err);
      } finally {
        setIsLoadingData(() => false);
      }
    },
    [data],
  );

  const onSetFilterTable: TOnSetFilterTable = async (columns) => {
    let newColumns = columns;
    const filteredColumns = columns.filter((e) => e !== 'total');

    // Костыль для total
    if (!filteredColumns.length) {
      newColumns = [];
    }

    const settingFilter: TFilter = {
      ...filter,
      itemsFilter:
        newColumns.length > 0
          ? ['total', ...newColumns.filter((e) => e !== 'utm_tags')].map(
              (e) => ({
                key: e,
                checked: true,
              }),
            )
          : [],
      activeColumns:
        newColumns.length > 0 ? ['total', ...newColumns] : newColumns,
    };

    setFilter(settingFilter);

    const response: TData[] = (await Promise.all(
      columns
        .map((v) => `${v}`)
        .filter((e) => e !== 'utm_tags')
        .map((e) => {
          const dropTitle = e.split('–');
          const row = {
            title: e,
            row: {
              filters: {
                UTMCampaign: [dropTitle[0]],
                UTMSource: [dropTitle[1]],
              },
              dataFields: filter.availableItems,
            },
          };
          return request(row);
        }),
    )) as TData[];
    setData([totals, ...response]);
  };

  return {
    onSetFilterTable,
    filter,
    seriesChecked,
    onSetFilterChart,
    dataChecked,
    isLoadingData,
  };
};
