import { useEffect, useState } from 'react';

type Param = {
  use_inherit_settingsState: boolean;
};

type TFUseInheritSettingsHint = (p: Param) => {
  showHint: boolean;
  closeHintEvent: () => void;
  showHintEvent: () => void;
};

export const useInheritSettingsHint: TFUseInheritSettingsHint = ({
  use_inherit_settingsState,
}) => {
  const [showHint, setShowHint] = useState(false);

  useEffect(() => {
    let timeOutIt: NodeJS.Timeout | null = null;
    if (showHint && !use_inherit_settingsState) {
      timeOutIt = setTimeout(() => {
        setShowHint(false);
      }, 4000);
    }
    return () => {
      if (timeOutIt) {
        clearTimeout(timeOutIt);
      }
    };
  }, [use_inherit_settingsState, showHint]);

  const showHintEvent = () => {
    if (!showHint && !use_inherit_settingsState) {
      setShowHint(true);
    }
  };

  const closeHintEvent = () => {
    setShowHint(false);
  };

  return {
    showHint,
    closeHintEvent,
    showHintEvent,
  };
};
