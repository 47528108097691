import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PSetTargetingCreative } from 'domains/target/model/types';
import { ParamsCampaignsStatistics, ResponseTranslation, RStatisticsCampaigns, TotalResponse } from 'domains/campaigns/types';
import { FetchedData, genFetchedData } from '../../redux/fetchedData';
import {
  CreativeDetail,
  ModerationResultData,
  PChangeCreativeStatusOnSaga,
  PClearCreativeAllTargetOnSaga,
  PCloneCreative,
  PFetcherCreativeList,
  PGetCreativeDetail,
  PSetExternalParamSaga,
  RGetCreativeStatistics,
  TAddCreativeRequest,
  TPCreativeEditRequest,
  TPModerationSent,
  Creative,
  RAlgorithmCreativeList,
  Moderation,
  CreativeItemListWithStatistics,
  RCreativeTags,
  PChangeActiveTag,
  CreativeList,
  SetConfigParams,
  TRCreativeTypes,
  TScreenStatus,
  getCustomerByEridParams,
} from './types';
import { RResultSuccess, THASH, TInternalItem } from '../../types/general';
import { makeGenList, TMakeGenList } from '../../redux/makeGenList';
import { RPreTargeting, TExperimentalInfoField } from '../campaign/types';

export interface CreativeState {
  lastId: string;
  list: FetchedData<CreativeItemListWithStatistics[]>;
  listModal: FetchedData<CreativeList>;
  listView: TMakeGenList<CreativeItemListWithStatistics[]>;
  listFilter: Creative['status'] | '';
  listSort: {
    isShow?: boolean;
    key: string | null;
    sort: 'ASC' | 'DESC' | null;
  };
  isShowSortFilter: boolean;
  statisticsList: FetchedData<RStatisticsCampaigns>;
  statisticsSumList: FetchedData<TotalResponse>;
  statisticsCreative: FetchedData<RGetCreativeStatistics[]>;
  element: FetchedData<CreativeDetail>;
  algorithmsCPA: FetchedData<RAlgorithmCreativeList>;
  moderation: FetchedData<Moderation>;
  moderationResult: FetchedData<ModerationResultData>;
  response: FetchedData<RResultSuccess>;
  tags: FetchedData<RCreativeTags>;
  allTags: FetchedData<RCreativeTags>;
  activeTags: string[];
  vendorLinks: FetchedData<TInternalItem[]>;
  creativesTranslation: FetchedData<Record<string, ResponseTranslation>>;
  creativeTranslation: FetchedData<ResponseTranslation>;
  preTargeting: FetchedData<RPreTargeting | []>;
  config: FetchedData<TExperimentalInfoField[]>;
  creoTranslationFilter: CreativeItemListWithStatistics[] | null;
  creativeTypes: FetchedData<TRCreativeTypes>;
  screenStatus: FetchedData<TScreenStatus>;
  isOpenPreviewModal: boolean;
  currentGridEridInfo: FetchedData<getCustomerByEridParams | string[]>;
}

const initialState: CreativeState = {
  lastId: '',
  list: genFetchedData<CreativeItemListWithStatistics[]>(null),
  listModal: genFetchedData<CreativeList>(null),
  listView: makeGenList<CreativeItemListWithStatistics[]>(),
  listFilter: '',
  listSort: {
    sort: null,
    key: null,
  },
  isShowSortFilter: false,
  statisticsList: genFetchedData<RStatisticsCampaigns>(null),
  statisticsSumList: genFetchedData<TotalResponse>(null),
  statisticsCreative: genFetchedData<RGetCreativeStatistics[]>(null),
  element: genFetchedData<CreativeDetail>(null),
  algorithmsCPA: genFetchedData<RAlgorithmCreativeList>(null),
  moderation: genFetchedData<Moderation>(null),
  moderationResult: genFetchedData<ModerationResultData>(null),
  response: genFetchedData<RResultSuccess>(null),
  tags: genFetchedData<RCreativeTags>(null),
  allTags: genFetchedData<RCreativeTags>(null),
  activeTags: [],
  vendorLinks: genFetchedData<TInternalItem[]>(null),
  creativesTranslation:
    genFetchedData<Record<string, ResponseTranslation>>(null),
  creativeTranslation: genFetchedData<ResponseTranslation>(null),
  preTargeting: genFetchedData<RPreTargeting | []>(null),
  config: genFetchedData<TExperimentalInfoField[]>(null),
  creoTranslationFilter: null,
  creativeTypes: genFetchedData<TRCreativeTypes>(null),
  screenStatus: genFetchedData<TScreenStatus>(null), 
  isOpenPreviewModal: false,
  currentGridEridInfo: genFetchedData<getCustomerByEridParams | string[]>(null),
};

const creativeSlice = createSlice({
  name: 'creative',
  initialState,
  reducers: {
    setLastId(state, action: PayloadAction<CreativeState['lastId']>) {
      state.lastId = action.payload;
    },
    delLastId(state) {
      state.lastId = initialState.lastId;
    },
    setTempCreativeList(state, action: PayloadAction<CreativeState['list']>) {
      state.list = action.payload;
    },
    setTempCreativeListSuccess(
      state,
      action: PayloadAction<CreativeState['list']>,
    ) {
      state.list = action.payload;
    },
    setListModal(state, action: PayloadAction<CreativeState['listModal']>) {
      state.listModal = action.payload;
    },
    setListView(state, action: PayloadAction<CreativeState['listView']>) {
      state.listView = action.payload;
    },
    resetFillCreativeList(state) {
      state.list = initialState.list;
    },
    setTempStatisticsList(
      state,
      action: PayloadAction<CreativeState['statisticsList']>,
    ) {
      state.statisticsList = action.payload;
    },
    setTempStatisticsListSuccess(
      state,
      action: PayloadAction<CreativeState['statisticsList']>,
    ) {
      state.statisticsList = action.payload;
    },
    setListFilter(state, action: PayloadAction<CreativeState['listFilter']>) {
      state.listFilter = action.payload;
    },
    setListSort(state, action: PayloadAction<CreativeState['listSort']>) {
      state.listSort = action.payload;
    },
    setIsShowListFilter(
      state,
      action: PayloadAction<CreativeState['isShowSortFilter']>,
    ) {
      state.isShowSortFilter = action.payload;
    },
    setTempStatisticsSumList(
      state,
      action: PayloadAction<CreativeState['statisticsSumList']>,
    ) {
      state.statisticsSumList = action.payload;
    },
    setTempCreativeDetail(
      state,
      action: PayloadAction<CreativeState['element']>,
    ) {
      state.element = action.payload;
    },
    clearTempCreativeDetail(
      state,
    ) {
      state.element = initialState.element;
    },
    setTempAlgorithms(
      state,
      action: PayloadAction<CreativeState['algorithmsCPA']>,
    ) {
      state.algorithmsCPA = action.payload;
    },
    setTempModeration(
      state,
      action: PayloadAction<CreativeState['moderation']>,
    ) {
      state.moderation = action.payload;
    },
    setTempModerationResult(
      state,
      action: PayloadAction<CreativeState['moderationResult']>,
    ) {
      state.moderationResult = action.payload;
    },
    resetModeration(state) {
      state.moderation = initialState.moderation;
    },
    setResponseResult(state, action: PayloadAction<CreativeState['response']>) {
      state.response = action.payload;
    },
    setResponseResultDefault(state) {
      state.response = initialState.response;
    },
    setCreativeTags(state, action: PayloadAction<CreativeState['tags']>) {
      state.tags = action.payload;
    },
    resetCreativeTags(state) {
      state.tags = initialState.tags;
    },
    setCreativeAllTags(state, action: PayloadAction<CreativeState['allTags']>) {
      state.allTags = action.payload;
    },
    resetCreativeAllTags(state) {
      state.allTags = initialState.allTags;
    },
    setActiveTags(state, action: PayloadAction<CreativeState['activeTags']>) {
      state.activeTags = action.payload;
    },
    setVendorLinks(state, action: PayloadAction<CreativeState['vendorLinks']>) {
      state.vendorLinks = action.payload;
    },
    setCreativesTranslation(
      state,
      action: PayloadAction<CreativeState['creativesTranslation']>,
    ) {
      state.creativesTranslation = action.payload;
    },
    setCreativeTranslation(
      state,
      action: PayloadAction<CreativeState['creativeTranslation']>,
    ) {
      state.creativeTranslation = action.payload;
    },
    setCreativePretargeting(
      state,
      action: PayloadAction<CreativeState['preTargeting']>,
    ) {
      state.preTargeting = action.payload;
    },
    setFetchCreativeConfig(
      state,
      action: PayloadAction<CreativeState['config']>,
    ) {
      state.config = action.payload;
    },
    setCreoTranslationFilter(
      state,
      action: PayloadAction<CreativeState['creoTranslationFilter']>,
    ) {
      state.creoTranslationFilter = action.payload;
    },
    setFetchCreativeTypes(
      state,
      action: PayloadAction<CreativeState['creativeTypes']>,
    ) {
      state.creativeTypes = action.payload;
    },
    setCreativeStatus(
      state,
      action: PayloadAction<CreativeState['screenStatus']>,
    ) {
      state.screenStatus = action.payload;
    },
    setOpenPreviewModal(
      state,
      action: PayloadAction<CreativeState['isOpenPreviewModal']>,
    ) {
      state.isOpenPreviewModal = action.payload;
    },
    setCurrentGridEridInfo(
      state,
      action: PayloadAction<CreativeState['currentGridEridInfo']>,
    ) {
      state.currentGridEridInfo = action.payload;
    } 
  },
});

export const fetchCreativesList = createAction<PFetcherCreativeList>(
  'creative/fetchCreativesList',
);
export const fetchCreativeDetail = createAction<PGetCreativeDetail>(
  'creative/fetchCreativeDetail',
);

export const fetchCreativeStatisticsList = createAction<ParamsCampaignsStatistics>(
  'creative/fetchCreativeStatisticsList',
);

export const cleanCreativeTargeting =
  createAction<PClearCreativeAllTargetOnSaga>(
    'creative/cleanCreativeTargeting',
  );
export const creativeChangeSetStatus =
  createAction<PChangeCreativeStatusOnSaga>('creative/creativeChangeSetStatus');

export const creativeCloneRequest = createAction<PCloneCreative>(
  'creative/creativeClone',
);

export const setEditCreativeRequest = createAction<TPCreativeEditRequest>(
  'creative/setEditCreativeRequest',
);
export const fetchStatusModeration = createAction<{
  creative_id: string;
}>('creative/fetchStatusModeration');

export const sentModerationRequest = createAction<TPModerationSent>(
  'creative/sentModerationRequest',
);

export const addCreativeRequest = createAction<TAddCreativeRequest>(
  'creative/addCreativeRequest',
);

export const setExternalIdRequest = createAction<PSetExternalParamSaga>(
  'creative/setExternalIdRequest',
);

export const setTargetingsRequest = createAction<PSetTargetingCreative>(
  'creative/setTargetingsRequest',
);

export const resetCreativeList = createAction('creative/resetCreativeList');

export const fetchCreativeAlgorithmsList = createAction(
  'creative/fetchCreativeAlgorithmsList',
);
export const fetchCreativeTagsRequest = createAction<THASH>(
  'creative/fetchCreativeTagsRequest',
);
export const fetchCreativeAllTagsRequest = createAction<THASH>(
  'creative/fetchCreativeAllTagsRequest',
);
export const addActiveTagRequest = createAction<PChangeActiveTag>(
  'creative/addActiveTagRequest',
);

export const fetchCreativesTranslation = createAction<THASH>(
  'creative/fetchCreativesTranslation',
);

export const fetchCreativeTranslation = createAction<THASH>(
  'creative/fetchCreativeTranslation',
);

export const setCreativeConfigAction = createAction<SetConfigParams>(
  'creative/setCreativeConfig',
);

export const featchAllCreativeTypesAction = createAction(
  'creative/getCreativesTypes',
);

export const fetchCreativesTotal = createAction<ParamsCampaignsStatistics & { debounce?: number }>(
  'statistic/fetchCreoTotal',
);

export const {
  setTempModerationResult,
  setTempModeration,
  setLastId,
  delLastId,
  setTempCreativeList,
  setTempCreativeListSuccess,
  setListModal,
  setTempCreativeDetail,
  setTempStatisticsList,
  setTempStatisticsListSuccess,
  setTempStatisticsSumList,
  resetModeration,
  setResponseResult,
  setResponseResultDefault,
  resetFillCreativeList,
  setListFilter,
  setListSort,
  setTempAlgorithms,
  setIsShowListFilter,
  setCreativeTags,
  setCreativeAllTags,
  resetCreativeAllTags,
  setActiveTags,
  resetCreativeTags,
  setListView,
  setVendorLinks,
  setCreativePretargeting,
  setCreativesTranslation,
  setCreativeTranslation,
  setFetchCreativeConfig,
  setCreoTranslationFilter,
  setFetchCreativeTypes,
  setOpenPreviewModal,
  setCreativeStatus,
  clearTempCreativeDetail,
  setCurrentGridEridInfo,
} = creativeSlice.actions;
export const creativeReducer = creativeSlice.reducer;
