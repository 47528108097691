/* eslint-disable import/no-cycle */
import React, { FC, useEffect, useState } from 'react';
import { useOnScroll } from 'hooks/useOnScroll';
import cn from 'classnames';
import { useSubstitutionOfVoids } from 'utils/SubstitutionOfVoids';
import NavigationHeader from 'components/NavigationHeader';
import { useParams } from 'react-router';
import css from './styles.module.scss';
import Header from './Header';
import List from './List';
import { useBlackListsDomains } from '../hooks/useBlDomains';

export interface ICheck {
  [id: number]: boolean;
}

const BlackList: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { fixed } = useOnScroll(62);
  // заполняет пустоту, когда фиксируется header и заголовок таблицы, чтобы не скакал скролл
  const { fakeHeader, fakePart, fakeHeaderRef, fakePartRef } =
    useSubstitutionOfVoids({
      fixed,
    });

  const {
    blDomains,
    loadData,
    changeStatus,
    updateData,
    setCount,
    total,
    setPeriod,
    period,
    isLoading,
    setFileStatus,
    setSource,
    setPublisher,
    setDomain,
    setPartnerXxhash,
    fileStatus,
    setNeedUpdate,
  } = useBlackListsDomains({
    file_id: +id,
  });
  const [checked, setChecked] = useState<ICheck | null>(null);

  useEffect(() => {
    setChecked(null);
  }, [fileStatus])

  return (
    <div
      className={cn(css.page, {
        _isFixedHeader: fixed,
        _isShowDeletedAlert: checked,
      })}
    >
      <NavigationHeader back={{ to: '/control_panel/blackLists' }} extendable />
      <Header
        fakeHeader={fakeHeader}
        fakeHeaderRef={fakeHeaderRef}
        fixed={fixed}
        updateData={updateData}
        setCount={setCount}
        total={total}
        setPeriod={setPeriod}
        period={period}
        setFileStatus={setFileStatus}
        setSource={setSource}
        setPublisher={setPublisher}
        setDomain={setDomain}
        setPartnerXxhash={setPartnerXxhash}
        setNeedUpdate={setNeedUpdate}
        blDomains={blDomains}
      />
      <List
        fakePart={fakePart}
        fakePartRef={fakePartRef}
        checked={checked}
        setChecked={setChecked}
        blDomains={blDomains}
        loadData={loadData}
        changeStatus={changeStatus}
        isLoading={isLoading}
        fileStatus={fileStatus}
        setCount={setCount}
      />
    </div>
  );
};

export default BlackList;
