import { useTranslation } from 'react-i18next';
import { SelectField } from '../../../../types/general';

type TFUseDataAdditional = () => {
  violence: SelectField[];
  sex: SelectField[];
};

export const useDataAdditional: TFUseDataAdditional = () => {
  const { t } = useTranslation();
  const violence = [
    { value: '0', label: t('creative.create_creative.ertype.t1') },
    { value: '1', label: t('creative.create_creative.ertype.t2') },
    { value: '2', label: t('creative.create_creative.ertype.t3') },
    { value: '3', label: t('creative.create_creative.ertype.t4') },
  ];

  const sex = [
    { value: '0', label: t('creative.create_creative.ertype.t5') },
    { value: '1', label: t('creative.create_creative.ertype.t6') },
    { value: '2', label: t('creative.create_creative.ertype.t7') },
    { value: '3', label: t('creative.create_creative.ertype.t8') },
    { value: '4', label: t('creative.create_creative.ertype.t9') },
  ];
  return {
    sex,
    violence,
  };
};
