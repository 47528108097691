import { useCallback, useState } from 'react';
import { useOuterElementClick } from '../../../../../hooks/useOuterElementClick';

type TFUseCreatorTag = () => {
  isToggle: boolean;
  handlerOpenInput: () => void;
  handlerHideInput: () => void;
  wrapperRef: ReturnType<typeof useOuterElementClick>['wrapperRef'];
};

const useCreatorTag: TFUseCreatorTag = () => {
  const [isToggle, setToggle] = useState(true);

  const handlerOpenInput = useCallback(() => {
    setToggle(false);
  }, []);

  const handlerHideInput = useCallback(() => {
    setToggle(true);
  }, []);

  const { wrapperRef } = useOuterElementClick({
    isShow: !isToggle,
    closeModal: handlerHideInput,
  });

  return {
    isToggle,
    handlerHideInput,
    handlerOpenInput,
    wrapperRef,
  };
};

export default useCreatorTag;
