import { SagaIterator } from '@redux-saga/core';
import { takeEvery, takeLatest } from '@redux-saga/core/effects';
import {
  addCreativeSagaWorker,
  changeCreativeStatusSagaWorker,
  cleanCreativeAllTargetsSagaWorker,
  cloneCreativeSagaWorker,
  fetchCreativeDetailSagaWorker,
  fetchCreativesListSagaWorker,
  fetchCreativeStatisticsListSagaWorker,
  fetchStatusModerationSaga,
  sentModerationSagaWorker,
  setCreativeTargetingWorker,
  setEditCreativeDetailSagaWorker,
  setExternalIdWorker,
  resetCreativeListWorker,
  setCreativeAlgorithmsListWorker,
  sortCreativeListWorker,
  fetchCreativeTagsWorker,
  fetchCreativeAllTagsWorker,
  changeActiveTagWorker,
  loadCreativeListWorker,
  searchCreativeListWorker,
  updateCreativeStatisticsWorker,
  fetchCreativeListModalSagaWorker,
  fetchVendorLinkDataSagaWorker,
  setCreativeTagsWorker,
  revertCreativeTagsWorker,
  changeAllCreativeStatusesWorker,
  changeGroupStatusesCreativesWorker,
  clearGroupTargetingWorker,
  fetchCreativePretargetingWorker,
  setCreativePretargetingWorker,
  removeCreativePretargetingWorker,
  fetchCreativesTranslationWorker,
  fetchCreativeTranslationWorker,
  getAllCreativeTypesWorker,
  fetchTotalListCreoStatWorker,
  setCreoExperimentalWorker,
  screenStatusAPIWorker,
  createScreenWorker,
  massEditLinkWorker,
  fetchGridEridInfoWorker,
} from './workers';
import {
  addCreativeRequest,
  cleanCreativeTargeting,
  creativeChangeSetStatus,
  creativeCloneRequest,
  fetchCreativeDetail,
  fetchCreativesList,
  fetchCreativeStatisticsList,
  fetchStatusModeration,
  sentModerationRequest,
  setEditCreativeRequest,
  setExternalIdRequest,
  setTargetingsRequest,
  resetCreativeList,
  fetchCreativeAlgorithmsList,
  setListSort,
  fetchCreativeTagsRequest,
  fetchCreativeAllTagsRequest,
  addActiveTagRequest,
  fetchCreativesTranslation,
  fetchCreativeTranslation,
  featchAllCreativeTypesAction,
  fetchCreativesTotal,
} from '../reduser';
import {
  changeAllCreativeStatuses,
  changeGroupCreativesStatuses,
  clearGroupCreativesTargeting,
  createScreenAction,
  fetchCreativeListModal,
  fetchCreativePretargeting,
  fetchGridEridInfoAction,
  fetchVendorLink,
  getScreenStatus,
  loadCreativeList,
  massEditLinkAction,
  removeCreativePretargeting,
  revertCreativeTags,
  searchCreativeList,
  setCreativePretargetingAction,
  setCreoExperimentalAction,
  updateCreativeStatistics,
  updateTagCreative,
} from '../model/actions';
import {
  loadCampaignsListView,
  searchCampaignListView,
} from '../../campaigns/model/actions';
import {
  loadCampaignsListWorker,
  searchCampaignListWorker,
} from '../../campaigns/sagas/workers';

export default function* watchCreative(): SagaIterator {
  yield takeLatest(fetchCreativesList, fetchCreativesListSagaWorker);
  yield takeEvery(fetchCreativeDetail, fetchCreativeDetailSagaWorker);
  yield takeLatest(
    fetchCreativeStatisticsList,
    fetchCreativeStatisticsListSagaWorker,
  );
  yield takeLatest(fetchCreativesTranslation, fetchCreativesTranslationWorker);
  yield takeLatest(fetchCreativeTranslation, fetchCreativeTranslationWorker);
  yield takeLatest(cleanCreativeTargeting, cleanCreativeAllTargetsSagaWorker);
  yield takeLatest(creativeChangeSetStatus, changeCreativeStatusSagaWorker);
  yield takeLatest(creativeCloneRequest, cloneCreativeSagaWorker);
  yield takeLatest(setEditCreativeRequest, setEditCreativeDetailSagaWorker);
  yield takeEvery(fetchStatusModeration, fetchStatusModerationSaga);
  yield takeLatest(addCreativeRequest, addCreativeSagaWorker);
  yield takeLatest(setExternalIdRequest, setExternalIdWorker);
  yield takeEvery(setTargetingsRequest, setCreativeTargetingWorker);
  yield takeLatest(sentModerationRequest, sentModerationSagaWorker);
  yield takeLatest(resetCreativeList, resetCreativeListWorker);
  yield takeEvery(fetchCreativeAlgorithmsList, setCreativeAlgorithmsListWorker);
  yield takeLatest(setListSort, sortCreativeListWorker);
  yield takeEvery(fetchCreativeTagsRequest, fetchCreativeTagsWorker);
  yield takeEvery(fetchCreativeAllTagsRequest, fetchCreativeAllTagsWorker);
  yield takeLatest(addActiveTagRequest, changeActiveTagWorker);
  yield takeEvery(loadCreativeList, loadCreativeListWorker);
  yield takeLatest(searchCreativeList, searchCreativeListWorker);
  yield takeEvery(loadCampaignsListView, loadCampaignsListWorker);
  yield takeLatest(searchCampaignListView, searchCampaignListWorker);
  yield takeLatest(updateCreativeStatistics, updateCreativeStatisticsWorker);
  yield takeEvery(fetchCreativeListModal, fetchCreativeListModalSagaWorker);
  yield takeEvery(fetchVendorLink, fetchVendorLinkDataSagaWorker);
  yield takeLatest(updateTagCreative, setCreativeTagsWorker);
  yield takeLatest(revertCreativeTags, revertCreativeTagsWorker);
  yield takeLatest(changeAllCreativeStatuses, changeAllCreativeStatusesWorker);
  yield takeLatest(
    changeGroupCreativesStatuses,
    changeGroupStatusesCreativesWorker,
  );
  yield takeLatest(clearGroupCreativesTargeting, clearGroupTargetingWorker);
  yield takeLatest(fetchCreativePretargeting, fetchCreativePretargetingWorker);
  yield takeLatest(
    setCreativePretargetingAction,
    setCreativePretargetingWorker,
  );
  yield takeLatest(
    removeCreativePretargeting,
    removeCreativePretargetingWorker,
  );
  yield takeLatest(featchAllCreativeTypesAction, getAllCreativeTypesWorker);
  yield takeLatest(fetchCreativesTotal, fetchTotalListCreoStatWorker);
  yield takeEvery(setCreoExperimentalAction, setCreoExperimentalWorker);
  yield takeEvery(getScreenStatus, screenStatusAPIWorker);
  yield takeEvery(createScreenAction, createScreenWorker);
  yield takeLatest(massEditLinkAction, massEditLinkWorker);
  yield takeLatest(fetchGridEridInfoAction, fetchGridEridInfoWorker);
}
