import React, { FC } from 'react';
import NavigationHeader from 'components/NavigationHeader';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router';
import { TID } from 'types/general';
import { someGroups } from 'utils/statics/everyGroups';
import NoAccess from 'domains/app/Errors/noAccess';
import { useUserInfo } from 'domains/user/model/selectors';
import CreateOffer from './CreateOffer';
import EditOffer from './EditOffer';

const Offer: FC = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch<TID>();
  const { data: user } = useUserInfo();

  return (
    <>
      <NavigationHeader
        back={{
          title: t('campaigns_page.create_campaign.black_header.back'),
          to: '/main/offers',
        }}
      />
      <Switch>
        <Route
          path={`${path}/create`}
          component={
            someGroups(user, 'owner', 'self_user') ? CreateOffer : NoAccess
          }
        />
        <Route
          path={`${path}/:id/edit`}
          component={
            someGroups(user, 'owner', 'self_user') ? EditOffer : NoAccess
          }
        />
      </Switch>
    </>
  );
};

export default Offer;
