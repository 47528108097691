import React, { FC } from 'react';
import Button from 'components/UI/Buttons/buttons';
import { useTranslation } from 'react-i18next';
import css from '../../styles.module.scss';
import { ReactComponent as WrongSVG } from '../../icons/wrong.svg';

type TWrongProps = {
  showPreview: () => void;
};

const Wrong: FC<TWrongProps> = ({ showPreview }) => {
  const { t } = useTranslation();
  return (
    <div className={css.plug}>
      <WrongSVG />
      <span>{t(`creative.create_creative.went_wrong`)}</span>
      <span>{t(`creative.create_creative.try_to_refresh`)}</span>
      <Button
        title={t('update_btn')}
        buttonAttributes={{
          onClick: showPreview,
          style: {
            margin: '0 auto',
            marginTop: '30px',
          },
        }}
      />
    </div>
  );
};

export default Wrong;
