import Card from 'components/UI/Card';
import React, { FC } from 'react';
import StatiscticFields from 'components/StatisticFields';
import { useTranslation } from 'react-i18next';

const Statistics: FC = () => {
  const {t} = useTranslation();
  return (
    <Card>
      <h3>{t(`campaigns_page.campaign_stat_icon`)}</h3>
      <StatiscticFields 
        descriptionText={t(`project_page.show_columns`)}
        mode='organization'
        />
    </Card>
  )
};

export default Statistics;
