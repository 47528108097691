import { useCallback } from 'react';
import { fetchContainersAction } from './reducer';
import { useDispatchApp } from '../../redux/rootSelectors';

type TFUseFetchContainersList = () => () => void;

export const useFetchContainersList: TFUseFetchContainersList = () => {
  const dispatch = useDispatchApp();
  return useCallback(() => {
    dispatch(fetchContainersAction());
  }, [dispatch]);
};
