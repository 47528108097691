import { useCallback, useState } from "react";
import { useDispatchApp } from "redux/rootSelectors";
import { resetTargetingRequest } from "domains/target/reduser";
import { showPageAppRowHandler } from "..";
import { RGetSettings, TLeftD, TargetKey } from "../types";

type usePageContextMDataArgs = {
  leftD: TLeftD[];
  type: TargetKey;
  settings: RGetSettings | null;
};

type usePageContextMDataReturn = {
  handlerCloseIsPageContextM: () => void;
  isPageContextM: boolean;
  pageContextValue: { title: string; value: string } | undefined;
  showPageContextRow: (key: string) => void;
  showPageAppRow: (key: string) => void;
  resetTargetError: () => void;
};

type TUsePageContextMData = (args: usePageContextMDataArgs) => usePageContextMDataReturn

export const usePageContextMData: TUsePageContextMData = ({type, settings, leftD}) => {
    const dispatch = useDispatchApp();
  
    const [isPageContextM, setIsPageContextM] = useState(false);
  
    const [pageContextValue, setPageContextValue] = useState<
      { title: string; value: string } | undefined
    >(undefined);
  
    const handlerCloseIsPageContextM = () => {
      setIsPageContextM(false);
    };
  
    const showPageContextRow = useCallback(
      (key: string) => {
        setIsPageContextM(true);
        if (settings) {
          const element = settings.catalog.find(({ title }) => title === key);
          if (element && element.additional) {
            setPageContextValue({
              title: key,
              value: element.additional.join(', '),
            });
          }
        } else {
          setPageContextValue({ title: key, value: '' });
        }
      },
      [settings],
    );
  
    const resetTargetError = useCallback(() => {
      dispatch(resetTargetingRequest(type));
    }, [type]);
  
    const showPageAppRow = useCallback((key: string) =>
      showPageAppRowHandler(key, setIsPageContextM, setPageContextValue), [leftD]
    )

    return {
      handlerCloseIsPageContextM,
      isPageContextM,
      pageContextValue,
      showPageContextRow,
      showPageAppRow,
      resetTargetError,
    }
}