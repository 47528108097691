import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PartnerHashList, Period } from 'types/general';
import { genFetchedData } from '../../redux/fetchedData';
import { TUploadFile } from '../../utils/upload';
import { ClientState } from '../client/reduser';
import {
  AgencyInfo,
  AgencyState,
  ParamAddAuditDictionary,
  ParamAgencyInfo,
  ParamCreateAgency,
  ParamEditAuditDictionary,
  ParamGetAuditDictionary,
  ParamGetStatisticByAudit,
  TClientStat,
  TEditQuery,
  TGetAuditDictionary,
  TGetStatisticByAudit,
} from './types';

export const initialState: AgencyState = {
  isShowMChange: false,
  isShowMCreate: false,
  tempImage: genFetchedData<TUploadFile>(null),
  idChange: '',
  agencyInfo: genFetchedData<AgencyInfo>(null),
  lastAddAgency: genFetchedData<ParamAgencyInfo>(null),
  editAgency: genFetchedData<boolean>(null),
  clientsStat: genFetchedData<TClientStat>(null),
  auditDictionaryList: genFetchedData<TGetAuditDictionary[]>(null),
  statisticByAudit: genFetchedData<TGetStatisticByAudit>(null),
};

const AgencySlice = createSlice({
  name: 'Agency',
  initialState,
  reducers: {
    setIsShowMCreate(
      state,
      action: PayloadAction<AgencyState['isShowMCreate']>,
    ) {
      state.isShowMCreate = action.payload;
    },
    setIsShowMChange(
      state,
      action: PayloadAction<AgencyState['isShowMChange']>,
    ) {
      state.isShowMChange = action.payload;
    },
    setTempImage(state, action: PayloadAction<AgencyState['tempImage']>) {
      state.tempImage = action.payload;
    },
    setIdChange(state, action: PayloadAction<AgencyState['idChange']>) {
      state.idChange = action.payload;
    },
    setAgencyInfo(state, action: PayloadAction<AgencyState['agencyInfo']>) {
      state.agencyInfo = action.payload;
    },
    setClientsStat(state, action: PayloadAction<AgencyState['clientsStat']>) {
      state.clientsStat = action.payload;
    },
    setLastAddAgency(
      state,
      action: PayloadAction<ClientState['lastAddClient']>,
    ) {
      state.lastAddAgency = action.payload;
    },
    setEditAgency(state, action: PayloadAction<AgencyState['editAgency']>) {
      state.editAgency = action.payload;
    },
    delEditAgency(state) {
      state.editAgency = initialState.editAgency;
    },
    setAuditDictionaryList(state, action: PayloadAction<AgencyState['auditDictionaryList']>) {
      state.auditDictionaryList = action.payload;
    },
    setStatisticByAudit(state, action: PayloadAction<AgencyState['statisticByAudit']>) {
      state.statisticByAudit = action.payload;
    }
  },
});

export const fetchLoadAgencyTempFile = createAction<File>(
  'Agency/fetchLoadAgencyTempFile',
);
export const fetchAgencyInfo = createAction<ParamAgencyInfo>(
  'Agency/fetchAgencyInfo',
);
export const createAgency = createAction<ParamCreateAgency>(
  'Agency/createAgency',
);
export const fetchAuditDictionaryList = createAction<ParamGetAuditDictionary>(
  'Agency/fetchAuditDictionaryList',
);
export const fetchAddAuditDictionary = createAction<ParamAddAuditDictionary>(
  'Agency/fetchAddAuditDictionary',
);
export const fetchEditAuditDictionary = createAction<ParamEditAuditDictionary>(
  'Agency/fetchEditAuditDictionary',
);
export const fetchStatisticByAudit = createAction<ParamGetStatisticByAudit>(
  'Agency/fetchStatisticByAudit',
)

export const fetchClientsStat = createAction<
  PartnerHashList & { period: Period; target: string | null; status_list_clients?: string[];}
>('Agency/getClientsStat');

export const editAgency = createAction<TEditQuery>('Agency/editAgency');

export const {
  setIsShowMChange,
  setIsShowMCreate,
  setTempImage,
  setIdChange,
  setAgencyInfo,
  setLastAddAgency,
  delEditAgency,
  setEditAgency,
  setClientsStat,
  setAuditDictionaryList,
  setStatisticByAudit,
} = AgencySlice.actions;
export const agencyReducer = AgencySlice.reducer;
