import { useEffect, useState } from 'react';

type TResult = {
  fixed: boolean;
};

type TUseOnScroll = (scrollH: number) => TResult;

/** функция отслеживает скроллл браузера
 * @scrollH - высота в пикселях
 * */
export const useOnScroll: TUseOnScroll = (scrollH) => {
  const [fixed, setFixed] = useState<boolean>(false);

  const onScroll = (event: Event) => {
    const scroll = event.target as HTMLDocument;
    if (
      scroll.scrollingElement &&
      scroll.scrollingElement.scrollTop < scrollH
    ) {
      return setFixed(false);
    }
    return setFixed(true);
  };

  useEffect(() => {
    document.addEventListener('scroll', onScroll);

    return () => document.removeEventListener('scroll', onScroll);
  }, []);

  return {
    fixed,
  };
};
