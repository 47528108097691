import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import img from 'assets/errors/under-construction.svg';
import css from './styles.module.scss';

const UC: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={cn(css.uc, 'fullscreen-error')}>
      <div className={css.uc__form}>
        <div className={css.uc__code}>
          <img
            className="fullscreen-error__img"
            src={img}
            alt={t('error_page.errUC.title')}
          />
        </div>
        <div className={css.uc__title}>
          <span>{t('error_page.errUC.title')}</span>
        </div>
        <div className={css.uc__text}>
          <span>{t('error_page.errUC.text')}</span>
        </div>
      </div>
    </div>
  );
};

export default UC;
