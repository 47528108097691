import { fetchGroups } from 'domains/project/model/actions';
import { useEffect } from 'react';
import { fetchAgencyList } from 'domains/partners/model/actions';
import { useUserInfo } from 'domains/user/model/selectors';
import { usePartnersListInfo } from 'domains/partners/model/selector';
import { useDispatch } from 'react-redux';
import { getAPIUsersMethods } from '../actions';

interface RUseFetchUserFormData {
  isLoading: boolean;
}

const useFetchUserFormData = (): RUseFetchUserFormData => {
  const dispatch = useDispatch();
  const { data: user } = useUserInfo();
  const { isLoading: partnersIsLoading } = usePartnersListInfo();

  useEffect(() => {
    if (user?.partner.xxhash) {
      if (user.group.includes('owner') || user.group.includes('self_user')) {
        dispatch(fetchAgencyList({ partner_id: user.partner.xxhash }));
        dispatch(fetchGroups());
        dispatch(getAPIUsersMethods());
      }
    }
  }, [dispatch, user]);

  return { isLoading: partnersIsLoading };
};

export default useFetchUserFormData;
