import Load from 'components/UI/Load';
import React, { FC } from 'react';
import css from './styles.module.scss';

const LoadingMain: FC = () => (
  <div className={css.loading_main}>
    <Load
      style={{
        width: `215px`,
        height: '22px',
        marginBottom: '24px',
      }}
    />
    <Load
      style={{
        width: `100%`,
        height: '32px',
        marginBottom: '24px',
      }}
    />
    <Load
      style={{
        width: `100%`,
        height: '32px',
        marginBottom: '24px',
      }}
    />
        <Load
      style={{
        width: `204px`,
        height: '32px',
      }}
    />
  </div>
);

export default LoadingMain;
