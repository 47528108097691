import ModalOverlay from 'components/Modals/ModalOverlay1';
import Button from 'components/UI/Buttons/buttons';
import Card from 'components/UI/Card';
import React, { FC, useState } from 'react';
import {
  FiAlertCircle,
  FiChevronLeft,
  FiChevronRight,
  FiX,
} from 'react-icons/fi';
import {
  useCreativeTypes,
  useCurrentGridEridInfo,
} from 'domains/creative/model/selectors';
import Loader from 'components/UI/Loader';
import { CreativeDetail } from 'domains/creative/types';
import Tooltip from 'components/UI/Tooltip';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import {
  isGardMultiFormatType,
  isGardVideoInlineType,
  isGardVideoVastType,
} from 'domains/creative/creatives/hooks';
import { useTranslation } from 'react-i18next';
import { useSwitchNavigation } from 'hooks/useSwitchNavigation';
import { useGetFieldConfigValue } from 'domains/creative/creatives/hooks/useGetFieldConfigValue';
import css from './styles.module.scss';
import Unavailable from './ui/Errors/Unavailable';
import Wrong from './ui/Errors/Wrong';
import { useDataModal } from './model/hooks/useDataModal';
import Default from './ui/Main/Default';
import Multi from './ui/Main/Multi';
import { useBannerData } from './model/hooks/useBannerData';

type TPreviewModalProps = {
  creative: CreativeDetail;
  isOpen: boolean;
  onClose: () => void;
  show: boolean;
};

const PreviewModal: FC<TPreviewModalProps> = ({
  creative: {
    type,
    size,
    xxhash,
    title,
    internal_id,
    data,
    link,
    config_values,
  },
  onClose,
  isOpen,
  show,
}) => {
  const { data: creativeTypes } = useCreativeTypes();
  const { width, height } = useDataModal({ size });
  const [option, setOption] = useState(false);

  const { t } = useTranslation();

  const unavailable = type === 'CLICKUNDER' || type === 'PUSH';
  const multiData = (isGardMultiFormatType(data) && data) || undefined;
  const src = (isGardVideoInlineType(data) && data.video_url) || undefined;
  const poster =
    (isGardVideoInlineType(data) && data.video_poster_url) || undefined;

  const eridData = useGetFieldConfigValue('erid', 'VIDEO_INLINE');

  const { prevLink, nextLink, isDisableLink } = useSwitchNavigation({
    mode: 'creo',
  });

  const erid = eridData ? config_values[eridData.id] : '';

  const { data: innData } = useCurrentGridEridInfo();

  /*   useEffect(() => {
    if(type === 'VIDEO_INLINE' && erid && isOpen) {
      fetchInn().catch(e => console.error({e}))
    }
  }, [xxhash, isOpen, erid]) */

  const { bannerData, loading, error, showPreview, handleOnLoad } =
    useBannerData({
      xxhash,
      needData: isOpen && show,
      type,
    });

  const toggleOption = () => {
    setOption(!option);
  };

  return (
    <ModalOverlay
      onCloseModal={onClose}
      isOpen={isOpen}
      className="previewModal"
    >
      <div className={css.modal}>
        <Card className={css.card}>
          <div
            className={cn(css.header, {
              _isDefault: !(type === 'MULTI_FORMAT' && show),
            })}
          >
            <div className={css.title}>
              {creativeTypes && (
                <img
                  src={
                    creativeTypes?.filter((item) => item.key === type)[0]?.icon
                  }
                  alt=""
                />
              )}
              {type === 'MULTI_FORMAT' && show ? (
                <>
                  <span className={css.internal_id}>{internal_id}</span>
                  <Tooltip
                    title={<FiAlertCircle size={18} />}
                    bottom
                    className={css.name}
                  >
                    {title}
                  </Tooltip>
                </>
              ) : (
                <span className={css.internal_id}>
                  {creativeTypes?.filter((item) => item.key === type)[0]?.title}
                  {' · '}
                  {`[${internal_id}]`} {title}
                </span>
              )}
            </div>
            {type === 'MULTI_FORMAT' && show && (
              <div className={css.option}>
                <Button
                  title="Вариант 1"
                  lightblue={option}
                  buttonAttributes={{
                    onClick: toggleOption,
                  }}
                />
                <Button
                  title="Вариант 2"
                  lightblue={!option}
                  buttonAttributes={{
                    onClick: toggleOption,
                  }}
                />
              </div>
            )}
            <div className={css.close} onClick={onClose}>
              <FiX size={22} />
            </div>
          </div>
          {loading && (
            <div className={css.loader}>
              <Loader />
            </div>
          )}
          {error && (
            <div className={css.loader}>
              <Wrong showPreview={showPreview} />
            </div>
          )}
          {(unavailable || !show) && (
            <div className={css.loader}>
              <Unavailable text={t(`creative.create_creative.not_available`)} />
            </div>
          )}
          {!error && !unavailable && (
            <>
              {type === 'MULTI_FORMAT' && show ? (
                <Multi data={multiData} option={option} link={link} />
              ) : (
                <Default
                  loading={loading}
                  use_vpaid={isGardVideoInlineType(data) && data.use_vpaid}
                  type={type}
                  innData={innData}
                  erid={erid}
                  width={width}
                  height={height}
                  src={src}
                  poster={poster}
                  bannerData={bannerData}
                  handleOnLoad={handleOnLoad}
                  link={link}
                  xmlData={isGardVideoVastType(data) ? data : null}
                />
              )}
            </>
          )}
          {!isDisableLink.prev && prevLink !== '' && (
            <Link to={prevLink}>
              <Button
                title=""
                icon={<FiChevronLeft size={50} />}
                buttonAttributes={{
                  className: cn(css.modal_nav, css.prev),
                }}
              />
            </Link>
          )}
          {!isDisableLink.next && nextLink !== '' && (
            <Link to={nextLink}>
              <Button
                title=""
                icon={<FiChevronRight size={50} />}
                buttonAttributes={{
                  className: cn(css.modal_nav, css.next),
                }}
              />
            </Link>
          )}
        </Card>
      </div>
    </ModalOverlay>
  );
};

export default PreviewModal;
