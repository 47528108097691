/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { components } from 'react-select';

const DropdownIndicator = ({ selectProps, ...props }: any) => (
  <components.DropdownIndicator selectProps={selectProps} {...props}>
    <FiChevronDown
      size={20}
      style={{
        transform: selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: 'transform .1s',
      }}
    />
  </components.DropdownIndicator>
);

export default DropdownIndicator;
