import { FormikProps, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import deepEqual from 'deep-equal';
import { setEditCreativeRequest } from 'domains/creative/reduser';
import { useUserTheme } from 'domains/user/model/selectors';
import { useValidateSchemeCreatives } from '../../../../validate/validate';
import { validate } from '../validateOther';
import { CreativeDetail } from '../../../../../types';
import { setFormDefault } from '../../../../../../uploads/reducer';
import { useDispatchApp } from '../../../../../../../redux/rootSelectors';

type TParam = {
  xxhash: CreativeDetail['xxhash'];
  erid: string;
  setEdit: (param: boolean) => void;
  edit: boolean;
};

type FormikEditClickunder = {
  erid?: string;
};

type TFUseFormVideo = (p: TParam) => {
  formik: FormikProps<FormikEditClickunder>;
  handlerFormikReset: () => void;
  handlerFormikSubmit: () => void;
};

export const useFormClickunder: TFUseFormVideo = ({
  xxhash,
  erid: eridValue,
  setEdit,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatchApp();
  const { dataCreativeVideoInline } = useValidateSchemeCreatives();
  const { getValueFromSettingForKey } = useUserTheme();
  const formik = useFormik<FormikEditClickunder>({
    enableReinitialize: true,
    validationSchema: dataCreativeVideoInline,
    validate,
    initialValues: {
      erid: eridValue,
    },
    onSubmit: (values) => {
      const formValueData: FormikEditClickunder = {
        erid: values.erid,
      };

      if (
        !deepEqual(
          {
            erid: eridValue,
          },
          values,
        )
      ) {
        dispatch(
          setEditCreativeRequest({
            data: { xxhash, type: 'CLICKUNDER', ...formValueData },
            visual: {
              setter: setEdit,
              translation:
                getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
                !getValueFromSettingForKey('ShowBroadcastStatus'),
              value: false,
              alertText: `${t('creative.type.type')}${t(
                'creative.type.clickunder',
              )}`,
            },
          }),
        );
      } else {
        dispatch(setFormDefault(true));
        setEdit(false);
      }
    },
  });

  /** Функция сбрасывает состояние формы */
  const handlerFormikReset = () => {
    setEdit(false);
    dispatch(setFormDefault(true));
    formik.resetForm();

  };

  /** Функция отправляет форму */
  const handlerFormikSubmit = () => {
    formik.handleSubmit();
  };

  return {
    formik,
    handlerFormikReset,
    handlerFormikSubmit,
  };
};
