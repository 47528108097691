import i18next from 'i18next';
import React, { FC } from 'react';
import css from './styles.module.scss';

interface IAvatar {
  online: 'online' | 'recently' | 'offline';
  from: 'client' | 'agency' | 'organization';
  img: string;
  style?: React.CSSProperties;
}

const Avatar: FC<IAvatar> = ({ online, from, img, style }) => {
  const borderColor = {
    organization: '#025DFF',
    agency: '#FF3636',
    client: '#149C08',
  };

  const opacityUser = {
    online: '1',
    recently: '1',
    offline: '0.7',
  };

  return (
    <div
      className={css.avatar}
      style={{
        ...style,
        border: `2px solid ${borderColor[from]}`,
        opacity: `${opacityUser[online]}`,
        backgroundColor: `${borderColor[from]}`,
      }}
    >
      <img
        className={css.img}
        src={img}
        alt={i18next.t(`users_activity.users`)}
      />
      {/* {online === 'online' && <div className={css.status} />} */}
    </div>
  );
};

export default Avatar;
