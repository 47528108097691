import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { Dispatch, SetStateAction } from 'react';
import { FetchedData, genFetchedData } from '../../redux/fetchedData';
import { TUploadFile } from '../../utils/upload';
import {
  PGroupList,
  PRemoveGroups,
  RAddClient,
  ResponseInfoClient,
  PSetGroup,
  Tform,
  TGroupList,
} from './types';

export interface ClientState {
  isShowMCreate: boolean;
  isShowMChange: boolean;
  tempImage: FetchedData<TUploadFile>;
  lastAddClient: FetchedData<RAddClient>;
  idChange: string;
  clientPersonalInfoById: FetchedData<ResponseInfoClient>;
  editClient: FetchedData<boolean>;
  groupList: FetchedData<TGroupList>;
}

export const initialState: ClientState = {
  isShowMCreate: false,
  isShowMChange: false,
  tempImage: genFetchedData<TUploadFile>(null),
  lastAddClient: genFetchedData<RAddClient>(null),
  idChange: '',
  clientPersonalInfoById: genFetchedData<ResponseInfoClient>(null),
  editClient: genFetchedData<boolean>(null),
  groupList: genFetchedData<TGroupList>(null),
};

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    isShowMCreate(state, action: PayloadAction<boolean>) {
      state.isShowMCreate = action.payload;
    },
    setIsShowMChange(state, action: PayloadAction<boolean>) {
      state.isShowMChange = action.payload;
    },
    setTempImage(state, action: PayloadAction<ClientState['tempImage']>) {
      state.tempImage = action.payload;
    },
    clearTempImage(state) {
      state.tempImage = initialState.tempImage;
    },
    setLastAddClient(
      state,
      action: PayloadAction<ClientState['lastAddClient']>,
    ) {
      state.lastAddClient = action.payload;
    },
    setIdChange(state, action: PayloadAction<ClientState['idChange']>) {
      state.idChange = action.payload;
    },
    setClientPersonalInfoById(
      state,
      action: PayloadAction<ClientState['clientPersonalInfoById']>,
    ) {
      state.clientPersonalInfoById = action.payload;
    },
    clearClientPersonalInfoById(
      state,
    ) {
      state.clientPersonalInfoById = initialState.clientPersonalInfoById;
    },
    setEditClient(state, action: PayloadAction<ClientState['editClient']>) {
      state.editClient = action.payload;
    },
    delEditClient(state) {
      state.editClient = initialState.editClient;
    },
    setGroupList(state, action: PayloadAction<ClientState['groupList']>) {
      state.groupList = action.payload;
    },
  },
});

export const fetchCreateClient = createAction<{
  title: string;
  site: string;
  idAgency: string;
}>('@@client/create/modal/fetchCreateClient');
export const fetchLoadTempFile = createAction<File>(
  '@@client/create/modal/fetchLoadTempFile',
);

export const fetchClientInfoById = createAction<string>(
  '@@client/fetchClientInfoById',
);

export type TEditPayload = {
  values: Tform;
  pastValues: Record<string, string | boolean | undefined>;
  setSavedValues: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  setPastValues: React.Dispatch<
    React.SetStateAction<Record<string, string | boolean | undefined>>
  >;
};

export type TEditSettings = Pick<Tform, 'id' | 'idAgency' | 'range_calculation_coefficients'> & {
  callbacks: Record<string, Dispatch<SetStateAction<boolean>>>
};

export const fetchClientEdit = createAction<TEditPayload>(
  '@@client/fetchClientEdit',
);

export const editClientSettings = createAction<TEditSettings>(
  '@@client/editClientSettings',
);

export const fetchGroupList = createAction<PGroupList>(
  '@@client/fetchGroupList',
);

export const createGroup = createAction<PSetGroup>('@@client/createGroup');

export const deleteGroup = createAction<PRemoveGroups>('@@client/deleteGroup');

export const {
  isShowMCreate,
  setTempImage,
  setLastAddClient,
  setIsShowMChange,
  setIdChange,
  setClientPersonalInfoById,
  setEditClient,
  delEditClient,
  setGroupList,
  clearTempImage,
  clearClientPersonalInfoById,
} = clientSlice.actions;
export const clientReducer = clientSlice.reducer;
