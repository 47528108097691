import React, { useMemo, FC } from 'react';
import cn from 'classnames';
import i18next from 'i18next';
import { GeneralP, TNamingItem } from '../../../../types';
import css from '../styles.module.scss';
import { numFormat } from '../../../../../../utils/toFixedNumber';
import { TFilter } from '../../hooks/useIntegrationFilter';

type Props = {
  rowsData: GeneralP['fixedRowT'];
  namingItem: TNamingItem;
  firstCW: number;
  filterTable: TFilter['activeColumns'];
  isUtmTags?: boolean;
};

const FixedRowT: FC<Props> = ({
  rowsData,
  namingItem,
  firstCW,
  filterTable,
  isUtmTags,
}) => {
  const styleTh: React.CSSProperties = useMemo(
    () => ({
      maxWidth: `${firstCW}px`,
      width: `${firstCW}px`,
    }),
    [firstCW],
  );

  if (typeof rowsData === 'undefined') return null;

  const rows: React.ReactElement[] = [];

  rowsData.forEach((item) => {
    const cell: React.ReactElement[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const key of namingItem.keys()) {
      const cellData = namingItem.get(key);
      const value = item[key];
      if (Array.isArray(value)) {
        // eslint-disable-next-line no-continue
        continue;
      }
      if (typeof item[key] === 'undefined') {
        cell.push(
          <div className={cn(css.cell, '_isAlignRight')} key={key}>
            -
          </div>,
        );
        // eslint-disable-next-line no-continue
        continue;
      }
      // eslint-disable-next-line no-continue
      if (typeof cellData === 'undefined') continue;

      if (cellData.isIndex) {
        cell.push(
          <div className={css.cell} key={key} style={styleTh}>
            <div
              className="chart-color"
              style={{
                backgroundColor: filterTable.includes('total')
                  ? '#000'
                  : 'transparent',
              }}
            />
            {isUtmTags && (
              <div
                className={cn('checkbox', {
                  isChecked: filterTable.includes('total'),
                })}
                style={{
                  pointerEvents: 'none',
                  cursor: 'default',
                  opacity: 0.9,
                }}
              />
            )}
            {numFormat(i18next.language, value, `${value}`)}
          </div>,
        );
        // eslint-disable-next-line no-continue
        continue;
      }
      cell.push(
        <div className={cn(css.cell, '_isAlignRight')} key={key}>
          {numFormat(i18next.language, value, `${value}`)}
        </div>,
      );
    }

    rows.push(
      <div className={cn(css.row, css.result)} key={rows.length}>
        {cell.map((cellItem) => cellItem)}
      </div>,
    );
  });

  return <>{rows.map((item) => item)}</>;
};

export default FixedRowT;
