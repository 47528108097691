import React, { FC } from 'react';
import cn from 'classnames';
import { statusDict } from 'domains/contracts/model/consts';
import { format } from 'date-fns';
import { useDispatchApp } from 'redux/rootSelectors';
import { sendErir } from 'domains/contract/model/actions';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';

interface Props {
  erirStatus: 'NONE' | 'PENDING' | 'SUCCESS' | 'ERROR' | null;
  erirExportedOn?: number;
}

const ErirInfo: FC<Props> = ({ erirStatus, erirExportedOn }) => {
  const { t } = useTranslation();
  const dispatch = useDispatchApp();
  const { id: uuid }: { id: string } = useParams();

  const sendErirHandler = () => dispatch(sendErir({ uuid }));

  return (
    <div
      className={cn(css.erir, {
        _isSuccess: erirStatus === 'SUCCESS',
        _isNotSend: erirStatus === 'NONE',
        _isWillExpoert: erirStatus === 'PENDING',
        _isError: erirStatus === 'ERROR',
      })}
    >
      <div className={css.erir_status} />
      {erirStatus && (
        <div className={css.erir_text}>
          {`${t(statusDict[erirStatus])}${
            erirStatus === 'SUCCESS' && erirExportedOn
              ? ` • ${format(
                  new Date(+erirExportedOn * 1000),
                  'dd.MM.yyyy, H:mm:ss',
                )}`
              : ''
          }`}
        </div>
      )}

      <div onClick={sendErirHandler} className={css.erir_btn}>
        {t(`ord.send`)}
      </div>
    </div>
  );
};

export default ErirInfo;
