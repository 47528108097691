import { WeekStatistic } from 'domains/campaigns/types';
import { useCallback, useEffect, useState } from 'react';
import { useChartStatistic } from '../selectors';

type TParamUseGetChartPreData = {
  fixed?: boolean;
  filterColumns: {
    name: string;
    data: string;
  }[];
};

type TFUseGetChartPreData = (p: TParamUseGetChartPreData) => {
  showReport: boolean;
  chartLegend: {
    name: string;
    isOff: boolean;
  }[];
  chartData: WeekStatistic | null;
  chartDataIsLoading: boolean;
  onClickChartLegend: (item: string) => () => void;
  showReportHandler: () => void;
};

export const useGetChartPreData: TFUseGetChartPreData = ({
  fixed,
  filterColumns,
}) => {
  const [showReport, setShowReport] = useState(() => {
    const storageData = localStorage.getItem('isOpenReport');
    if (storageData) {
      const convertedValue: boolean = JSON.parse(storageData);
      return convertedValue;
    }
    return false;
  });

  const getChartLegend = (arr: TParamUseGetChartPreData['filterColumns']) => arr.filter((item) => item.data !== 'name').map((item) => ({
    name: item.data === 'lead' ? 'Installs' : item.data,
    isOff: item.data !== 'show',
  }))

  const [chartLegend, setChartLegend] = useState(getChartLegend(filterColumns));

  useEffect(() => {
    if (filterColumns) {
      setChartLegend(getChartLegend(filterColumns))
    }
  }, [filterColumns])
  

  const { data: chartData, isLoading: chartDataIsLoading } =
    useChartStatistic();

  useEffect(() => {
    localStorage.setItem('isOpenReport', JSON.stringify(showReport));
  }, [showReport]);

  const onClickChartLegend = useCallback(
    (item: string) => () => {
      setChartLegend(
        chartLegend.map((i) => {
          if (i.name === item) return { name: i.name, isOff: !i.isOff };
          return i;
        }),
      );
    },
    [chartLegend],
  );

  const showReportHandler = () => {
    setShowReport((prev) => !prev);
    if (!showReport && fixed) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };
  return {
    showReport,
    chartLegend,
    chartData,
    chartDataIsLoading,
    onClickChartLegend,
    showReportHandler,
  };
};
