import { useSelector } from 'react-redux';
import { TExperimentalInfoField } from 'domains/campaign/types';
import { AppState } from '../../../../redux/rootReducer';
import { CampaignState } from '../types';

interface ReturnType {
  experimentalInfo: TExperimentalInfoField[] | null;
}

export const useCampainExperimentalInfo = (): ReturnType => {
  const { experimental } = useSelector<AppState, CampaignState>(
    (state) => state.campaignReducer,
  );
  const experimentalInfo = experimental.get('data');

  return { experimentalInfo };
};
