import React from 'react';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom';
import SaveChangesModal from 'components/SaveChangesModal';

const getUserConfirmation = (
  message: string,
  callback: (ok: boolean) => void,
) => {
  const node = document.getElementById('custom-prompt');

  const cleanUp = (answer: boolean) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ReactDOM.unmountComponentAtNode(node!);
    callback(answer);
  };

  ReactDOM.render(
    <SaveChangesModal message={message} cleanUp={cleanUp} />,
    node,
  );
};

export default createBrowserHistory({ getUserConfirmation });
