import i18next from 'i18next';
import { FormikErrors } from 'formik';
import { AdditionalFormik } from '../../../../types';

type TFValidation = (p: AdditionalFormik) => FormikErrors<AdditionalFormik>;

export const validate: TFValidation = (values) => {
  const errors: FormikErrors<typeof values> = {};
  const url =
    /^((https?:\/\/|www\.))[a-z0-9а-я]+([\-_.]{1}[a-z0-9а-я]+)*\.[a-zа-я]{2,10}(:[0-9]{1,5})?(\/?.*)?$/m;
  const macrosReg = /^(\{\w+\}\s?)+$/gi;
  const macrosReg2 = /^(\{\w+\}\s?)+$/gi;

  if (values.link && !url.test(values.link)) {
    if (!macrosReg.test(values.link)) {
      errors.link = i18next.t('errors.err_http_lorem');
    }
  }
  if (values.link && values.link.length >= 1024) {
    errors.link = i18next.t('errors.limitsym');
  }

  if (values.extension_link && !url.test(values.extension_link)) {
    if (!macrosReg2.test(values.extension_link)) {
      errors.extension_link = i18next.t('errors.err_http_lorem');
    }
  }
  if (values.extension_link && values.extension_link.length >= 1024) {
    errors.extension_link = i18next.t('errors.limitsym');
  }

  if (values.adomain && values.adomain.length >= 1024) {
    errors.adomain = i18next.t('errors.limitsym');
  }

  if (values.pixel && values.pixel.length > 0 && !url.test(values.pixel)) {
    errors.pixel = i18next.t('errors.err_http_lorem2');
  }

  if (values.pixel && values.pixel.length > 0 && values.pixel.length >= 1024) {
    errors.pixel = i18next.t('errors.limitsym');
  }

  if (
    values.data.show_html &&
    values.data.show_html.length > 0 &&
    !url.test(values.data.show_html)
  ) {
    if (!errors.data) {
      errors.data = {};
    }
    errors.data.show_html = i18next.t('errors.err_http_lorem2');
  }

  if (
    values.data.show_html &&
    values.data.show_html.length > 0 &&
    values.data.show_html.length >= 1024
  ) {
    if (!errors.data) {
      errors.data = {};
    }
    errors.data.show_html = i18next.t('errors.limitsym');
  }

  if (
    values.click_audit_pixel &&
    values.click_audit_pixel.length > 0 &&
    !url.test(values.click_audit_pixel)
  ) {
    errors.click_audit_pixel = i18next.t('errors.err_http_lorem2');
  }

  if (
    values.click_audit_pixel &&
    values.click_audit_pixel.length > 0 &&
    values.click_audit_pixel.length >= 1024
  ) {
    errors.click_audit_pixel = i18next.t('errors.limitsym');
  }

  if (values.iurl && values.iurl.length > 0 && !url.test(values.iurl)) {
    errors.iurl = i18next.t('errors.err_http_lorem2');
  }

  if (values.iurl && values.iurl.length > 0 && values.iurl.length >= 1024) {
    errors.iurl = i18next.t('errors.limitsym');
  }

  if (
    values.pixel_additional &&
    values.pixel_additional.length > 0 &&
    !url.test(values.pixel_additional)
  ) {
    errors.pixel_additional = i18next.t('errors.err_http_lorem2');
  }

  if (
    values.pixel_additional &&
    values.pixel_additional.length > 0 &&
    values.pixel_additional.length >= 1024
  ) {
    errors.pixel_additional = i18next.t('errors.limitsym');
  }

  if (
    values.algorithm_cpc.max &&
    values.algorithm_cpc.min &&
    values.algorithm_cpc.max < values.algorithm_cpc.min
  ) {
    errors.algorithm_cpc = { min: 'true' };
  }

  return errors;
};
