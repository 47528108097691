import { FiTrash2 } from 'react-icons/fi';
import React, { useCallback } from 'react';
import { MdInfoOutline } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { TUploadFile } from 'utils/upload';
import css from './styles.module.scss';
import { initialState, setTempImage } from '../../reducer';
import { FetchedDataObj } from '../../../../redux/fetchedData';
import ErrorDictionary from '../../../../components/Errors/ErrorDictionary';

type TPInfo = {
  /** Объект ошибки =undefined */
  error: FetchedDataObj<null>['error'];
  /** временный файл который еще не привязан к клиенту =undefined */
  tempFile?: TUploadFile;
  /** уже загруженный файл  =undefined */
  loadFile?: string;
  /** удаляет загруженный файл  =undefined */
  onDelLoadImg?: () => void;
};
export default function Info({
  error,
  tempFile,
  loadFile,
  onDelLoadImg,
}: TPInfo): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onClearTempImage = useCallback(() => {
    dispatch(setTempImage(initialState.tempImage));
  }, []);

  // вывод ошибки
  if (error.isError) {
    return (
      <span className={css.error}>
        <MdInfoOutline size="20" />
        <ErrorDictionary code={error.code} />
      </span>
    );
  }

  // вывод  инфо по файлу временному или
  // удалить загруженный файл
  if (tempFile !== undefined || loadFile !== undefined) {
    return (
      <span
        className={css.remove}
        onClick={tempFile !== undefined ? onClearTempImage : onDelLoadImg}
      >
        <FiTrash2 size="20" />
        {t('clients_page.client_edit.client_edit_delete_avatar')}
      </span>
    );
  }

  // вывод дефолтной инфы инфы
  return (
    <div className={css.description}>
      <p>{t('clients_page.client_create.client_img_formats')}</p>
      <p>{t('clients_page.client_create.client_img_size')}</p>
      <p>{t('clients_page.client_create.client_img_maxsize')}</p>
    </div>
  );
}
