import React, { FC } from 'react';
import cn from 'classnames';
import css from '../../styles.module.scss';
import i18n from '../../../../i18n';

type ProgressProps = {
  isActive: boolean;
  progress: number;
};

const Progress: FC<ProgressProps> = ({ isActive, progress }) => (
  <div
    className={cn(css.copying, {
      isActive,
    })}
  >
    <div className={css.title}>
      {i18n.t('creative.copy_creative.copy_set.t3')}
    </div>
    <div className={css.progress}>
      <div className={css.bar} style={{ width: `${progress}%` }} />
    </div>
    <div className={css.hint}>
      {i18n.t('creative.copy_creative.copy_set.t4')}
    </div>
  </div>
);
export default Progress;
