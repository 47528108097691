import { FormikProps, useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import deepEqual from 'deep-equal';
import { useDispatchApp } from 'redux/rootSelectors';
import { format } from 'date-fns';
import {
  getAllCounteragentsList,
  getOrdList,
} from 'domains/counteragents/model/actions';
import { fetchClients } from 'domains/clients/reducer';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { removeTopLevelsOfJson, TResult } from 'utils/removeTopLevelsOfJson/removeTopLevelsOfJson';
import { genFetchedData } from 'redux/fetchedData';
import {
  ContractFormikProps,
  ContractItem,
  CreateContractAction,
} from '../types';
import { createContract } from '../actions';
import data from '../../../../okved/okved.json';
import { useContractData } from '../selectors';
import { setContractData } from '../reducer';

interface UseCreateContractReturn {
  formik: FormikProps<ContractFormikProps>;
  handleChange: (
    name: string,
    value: string | number | boolean | undefined,
  ) => void;
  isPrompt: boolean;
  isLoadingCreate: boolean;
  options: TResult[];
  error: ReturnType<typeof useContractData>['error'];
}

export const useCreateContract = (): UseCreateContractReturn => {
  const { t } = useTranslation();

  const { error } = useContractData();

  const [disablePrompt, setDisablePrompt] = useState(false);

  const dispatch = useDispatchApp();

  const [isLoadingCreate, setIsLoading] = useState(false);

  const history = useHistory();

  const options = useMemo(() => removeTopLevelsOfJson(data), [data]);

  const goBack = () => {
    setDisablePrompt(true);
    history.push('/main/contracts');
  };

  useEffect(() => {
    dispatch(getAllCounteragentsList());
    dispatch(fetchClients({}));
    dispatch(getOrdList());
  }, []);

  const formik = useFormik<ContractFormikProps>({
    initialValues: {
      client_xxhash_list: null,
      ord: 4, // Основная ОРД
      externalId: '', // Идентификатор договора в ORD
      customer: '', // Заказчик
      executor: '', // Исполнитель
      number: '', // Номер договора
      date: null, // Дата договора
      type: 'Intermediary', // Тип договора
      actionType: 'Other', // Действия, осуществляемые посредником-представителем
      subjectType: 'Other', // Сведения о предмете договора
      amount: 0, // Сумма договора (руб)
      isVat: false, // С НДС - выпадающий список
      executorIsObligedForRegistration: false, // Обязанность регистрировать и репортировать креативы исполнителем
      isInvoiceContract: false, // Договор отчета. По умолчанию - false
      category: 'Initial', // Категория договора.
      agentActingForPublisher: null, // Направление денежных средств.
      creativeOkveds: null, // ОКВЭД
      /* finalContract: '', */ // Доходный договор.
      /* parent: '', */ // Родительский договор.
      /* useNetworkStat: false, */
    },
    validationSchema: Yup.object().shape(
      {
        client_xxhash_list: Yup.array()
          .nullable()
          .min(1, i18next.t(`ord.errors.required_field`))
          .required(i18next.t(`ord.errors.required_field`)),
        ord: Yup.number().required(i18next.t(`ord.errors.required_field`)),
        externalId: Yup.string(),
        customer: Yup.string()
          .when(['executor', 'type'], ([executor, type], schema) => {
            if (type === 'SelfPromotion') {
              return schema.test({
                test: (customer) => customer === executor,
                message: i18next.t(`ord.errors.customer_contract_type`),
              });
            }
            return schema.test({
              test: (customer) => customer !== executor,
              message: i18next.t(`ord.errors.should_not_match`),
            });
          })
          .nullable()
          .required(i18next.t(`ord.errors.required_field`)),

        executor: Yup.string()
          .when(['customer', 'type'], ([customer, type], schema) => {
            if (type === 'SelfPromotion') {
              return schema.test({
                test: (executor) => customer === executor,
                message: i18next.t(`ord.errors.customer_contract_type`),
              });
            }
            return schema.test({
              test: (executor) => executor !== customer,
              message: i18next.t(`ord.errors.should_not_match`),
            });
          })
          .nullable()
          .required(i18next.t(`ord.errors.required_field`)),
        number: Yup.string().required(i18next.t(`ord.errors.required_field`)),
        date: Yup.date()
          .typeError(i18next.t(`ord.errors.incorrect_value`))
          .nullable()
          .required(t('campaigns_page.campaign_settings.date_hint.h2')),
        type: Yup.string().required(i18next.t(`ord.errors.required_field`)),

        actionType: Yup.string().required(
          i18next.t(`ord.errors.required_field`),
        ),
        subjectType: Yup.string().required(
          i18next.t(`ord.errors.required_field`),
        ),
        amount: Yup.number()
          .min(0, i18next.t(`ord.errors.min_value_0`))
          .max(1e16, i18next.t(`ord.errors.max_value_exceeded`))
          .typeError(i18next.t(`ord.errors.incorrect_value`))
          .required(i18next.t(`ord.errors.required_field`)),
        /* isVat: Yup.number(), */
        executorIsObligedForRegistration: Yup.boolean(),
        isInvoiceContract: Yup.boolean(),
        category: Yup.string()
          .nullable()
          .required(i18next.t(`ord.errors.required_field`)),
        creativeOkveds: Yup.array().nullable().notRequired(),
        agentActingForPublisher: Yup.boolean()
          .nullable()
          .required(i18next.t(`ord.errors.required_field`)),
        /*         finalContract: Yup.string()
          .when(['ord', 'category'], ([ord, category], schema) =>
            schema.test({
              test: (value) => !(ord === 3 && category === 'Initial' && !value),
              message: i18next.t(`ord.errors.required_field`),
            }),
          )
          .nullable(), */
        /*         parent: Yup.string()
          .required()
          .when('type', ([type], schema) =>
            schema.test({
              test: (parent) => !(!parent && type === 'Additional'),
              message: i18next.t(`ord.errors.required_field`),
            }),
          )
          .nullable(), */
        /* useNetworkStat: Yup.number(), */
      },
      [['customer', 'executor']],
    ),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: (values) => {
      const { client_xxhash_list, ...objectCuster } = values;
      const { creativeOkveds } = objectCuster;

      const resultOkveds: string[] = [];

      if (creativeOkveds) {
        Object.values(creativeOkveds).forEach((okved) => {
          Object.keys(okved).forEach((ok) => {
            if (ok === 'label') {
              resultOkveds.push(okved[ok]);
            }
          });
        });
      }

      const params: CreateContractAction = {
        client_xxhash_list: client_xxhash_list?.map((item) => item.value) || [],
        objectCuster: {
          ...objectCuster,
          creativeOkveds: resultOkveds || [],
          date: values.date ? format(values.date, 'yyyy-MM-dd') : undefined,
        },
        callbacks: {
          goBack,
          setIsLoading,
        },
      };

      dispatch(createContract(params));
    },
  });

  const isPrompt =
    !disablePrompt && !deepEqual(formik.values, formik.initialValues);

  const handleChange = useCallback(
    (name: string, value: string | number | boolean | undefined) => {
      let newRD = genFetchedData<ContractItem>(null);
      newRD = newRD.set('error', {
        isError: false,
        message: '',
        code: 0,
      });
      dispatch(setContractData(newRD));

      formik.setFieldValue(name, value).then(() => {
        if (name === 'customer' || name === 'executor') {
          formik.validateField('customer');
          formik.validateField('executor');
        } else {
          formik.validateField(name);
        }
      });

      if (
        name === 'type' &&
        value === 'Intermediary' &&
        formik.values.actionType === 'None'
      ) {
        formik.setFieldValue('actionType', '');
      }
    },
    [formik],
  );

  useEffect(() => () => {
    let newRD = genFetchedData<ContractItem>(null);
    newRD = newRD.set('error', {
      isError: false,
      message: '',
      code: 0,
    });
    dispatch(setContractData(newRD));
  }, []);

  return {
    formik,
    handleChange,
    isPrompt,
    isLoadingCreate,
    options,
    error,
  };
};
