import React, { FC } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
  IBannerData,
  TCreativeVideoVast,
  getCustomerByEridParams,
} from 'domains/creative/types';
import cn from 'classnames';
import ShowErid from 'components/ShowErid';
import css from '../../styles.module.scss';
import Banner from '../Type/Banner';
import Video from '../Type/Video';
import { ReactComponent as Variant1SVG } from '../../../../../../assets/1080.svg';
import XmlPlayer from '../XmlPlayer';

type TDefaultProps = {
  type: string;
  width: number;
  height: number;
  erid?: string;
  innData?: getCustomerByEridParams | string[] | null;
  src?: string;
  poster?: string;
  bannerData?: IBannerData;
  handleOnLoad: () => void;
  loading: boolean;
  use_vpaid: boolean;
  link: string;
  xmlData: TCreativeVideoVast | null;
};

const Default: FC<TDefaultProps> = ({
  type,
  width,
  height,
  erid,
  innData,
  src,
  poster,
  bannerData,
  handleOnLoad,
  loading,
  use_vpaid,
  link,
  xmlData,
}) => (
  <Tabs className={css.tabs}>
    <div className={css.body}>
      <div className={css.side}>
        <TabList className={css.tabs_tablist}>
          <Tab className={css.tabs_tab}>
            <div className={css.size}>
              <Variant1SVG className={css.size_icon} />
              <span>{`${width}px x ${height}px`}</span>
            </div>
          </Tab>
        </TabList>
      </div>
      <TabPanel className={css.tabs_panel}>
        <div className={css.scroll}>
          <div
            className={cn(css.main, {
              _isHide: loading,
            })}
          >
            {type === 'HTML' && (
              <Banner
                width={width}
                height={height}
                bannerData={bannerData}
                handleOnLoad={handleOnLoad}
              />
            )}
            {/* COMPANION под вопросом */}
            {(type === 'VIDEO_INLINE' || type === 'COMPANION') && (
              <ShowErid
                erid={erid}
                innData={innData}
                use_vpaid={use_vpaid}
                type={type}
              >
                <Video src={src} poster={poster} link={link} />
              </ShowErid>
            )}
            {type === 'VIDEO_VAST' && xmlData && (
              <XmlPlayer
                xmlUrl={xmlData.vast_url}
              />
            )}
          </div>
        </div>
      </TabPanel>
    </div>
  </Tabs>
);

export default Default;
