import {
  THookSelectorFetchDataInfo,
  THookStateInfo,
  TSelector,
  useGenFetchDataSelector,
  useSelectorTypes,
  useShallowEqualSelector,
} from '../../../redux/rootSelectors';
import { AgencyInfo, AgencyState, TClientStat, TGetAuditDictionary } from '../types';
import { TUploadFile } from '../../../utils/upload';

/** список селекторов */
export const selectorAgencyGetTempImage: TSelector<
  AgencyState['tempImage']
> = ({ agencyReducer }) => agencyReducer.tempImage;

export const selectorAgency: TSelector<AgencyState['agencyInfo']> = ({
  agencyReducer,
}) => agencyReducer.agencyInfo;

export const selectorAgencyEdit: TSelector<AgencyState['editAgency']> = ({
  agencyReducer,
}) => agencyReducer.editAgency;

export const selectorAgencyIdChange: TSelector<AgencyState['idChange']> = ({
  agencyReducer,
}) => agencyReducer.idChange;

export const selectorClientsStat: TSelector<AgencyState['clientsStat']> = ({
  agencyReducer,
}) => agencyReducer.clientsStat;

export const selectorAuditDictionaryList: TSelector<AgencyState['auditDictionaryList']> = ({
  agencyReducer,
}) => agencyReducer.auditDictionaryList;

export const selectorAgencyIsShowMChange: TSelector<
  AgencyState['isShowMChange']
> = ({ agencyReducer }) => agencyReducer.isShowMChange;

export const selectorImageAgency: TSelector<AgencyState['tempImage']> = ({
  agencyReducer,
}) => agencyReducer.tempImage;

/** список хуков */
export const useAgencyInfo: THookSelectorFetchDataInfo<AgencyInfo> = () =>
  useGenFetchDataSelector(selectorAgency);

export const useAgencyEditInfo: THookSelectorFetchDataInfo<boolean> = () =>
  useGenFetchDataSelector(selectorAgencyEdit);

export const useAgencyIdChangeInfo: THookStateInfo<string> = () =>
  useSelectorTypes(selectorAgencyIdChange);

export const useAgencyIsShowMChangeInfo: THookStateInfo<boolean> = () =>
  useShallowEqualSelector(selectorAgencyIsShowMChange);

export const useAgencyImageInfo: THookSelectorFetchDataInfo<TUploadFile> = () =>
  useGenFetchDataSelector(selectorImageAgency);

export const useClientsStat: THookSelectorFetchDataInfo<TClientStat> = () =>
  useGenFetchDataSelector(selectorClientsStat);

export const useAuditDictionaryList: THookSelectorFetchDataInfo<TGetAuditDictionary[]> = () =>
  useGenFetchDataSelector(selectorAuditDictionaryList);
