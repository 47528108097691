import { SagaIterator } from 'redux-saga';
import {
  put,
  call,
  SagaReturnType,
  select,
  delay,
  take,
  all,
} from 'redux-saga/effects';
import i18next from 'i18next';
import {
  fetchCreativesList,
  setTempCreativeListSuccess,
} from 'domains/creative/reduser';
import { calculateGeoDataLiveTime } from 'utils/calculateGeoDataLiveTime';
import { Ttarget } from 'domains/campaign/types';
import { unsetCreativeTargeting } from 'domains/creative/api';
import { checkedLocalization } from 'utils/checkedLocalization';
import {
  setList,
  TargetState,
  setSettings,
  setStatisticTarget,
  setGeoTree,
  setGeoPopular,
  setTargetingRequest,
  setGeoSearchData,
  setAuModalCatalog,
  setTargetUserSettingsData,
} from '../reduser';
import {
  makeReqWithRD,
  TMakeReqWithRD,
  updateFillFetchedData,
} from '../../../redux/makeReqWithRD';
import {
  clearAllTargetItems,
  fetchClearTarget,
  fetchGetList,
  fetchGetListInCreative,
  fetchSettings,
  fetchStatistics,
  fetchTargetData,
  onSetTargeting,
  removeUserSettings,
  toggleInheritSettings,
  updateAu,
  updateTarget,
} from '../actions';
import {
  extractGenFetchData,
  FetchedData,
  genFetchedData,
} from '../../../redux/fetchedData';

import updateToken from '../../../utils/updateToken';
import { fetchPDataByTarget } from '../../campaign/model/actions';
import {
  RGeo,
  RGeoPopular,
  RGetList,
  RGetSettings,
  RGetStatisticTarget,
  TGeoCity,
  TGeoItemGeneral,
  TGeoSectionName,
  TGeoSubdivisions,
  TPopular,
  TargetKey,
  Tree,
} from '../model/types';
import { fetchGeoPopular, fetchMyTargetGeo, geoSearch, resetTargetType } from '../model/actions';
import {
  selectorGeoPopular,
  selectorGeoTree,
  selectorGetSettingsR,
  selectorGetStatisticTargetR,
  selectorSetTargetingRequest,
  selectorTargetStatistics,
  selectorTargetUserSettings,
} from '../model/selectors';
import Сategories_rtb from '../../../___mock___/categories_rtb/categories_rtb.json';
import { selectorCreativeList } from '../../creative/model/selectors';
import { getAllPopular, getGeoTree, getSearchGeo } from '../model/api';
import {
  getSingleTargetUserSettingsAPI,
  catalogItemsUpdate,
  clearTarget,
  getList,
  getSettings,
  getStatisticTarget,
  getTargetUserSettingsAPI,
  setTargeting,
  removeUserSettingAPI,
} from '../api';
import { CreativeItemListWithStatistics } from '../../creative/types';
import { toastSuccess } from '../../../utils/ToastUtils/toastUtils';

export function* workerFetchGetList({
  payload,
}: ReturnType<typeof fetchGetList>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getList>>(makeReqWithRD, {
      fetcher: getList,
      fill: (v) => {
        const data = v.get('data');
        const newData = data
          ? data.sort((a, b) => (a.sort > b.sort ? 1 : -1))
          : [];
        return setList(
          updateFillFetchedData<RGetList, RGetList>({
            fillFetchedData: v,
            newData,
          }),
        );
      },
      parameters: { xxhash: payload },
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* workerFetchGetListInCreative({
  payload,
}: ReturnType<typeof fetchGetListInCreative>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getList>>(makeReqWithRD, {
      fetcher: getList,
      fill: (v) => {
        const data = v.get('data');
        const newData = data
          ? data.sort((a, b) => (a.sort > b.sort ? 1 : -1))
          : [];
        return setList(
          updateFillFetchedData<RGetList, RGetList>({
            fillFetchedData: v,
            newData,
          }),
        );
      },
      parameters: { xxhash: payload },
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* workerFetchSettings({
  payload,
}: ReturnType<typeof fetchSettings>): SagaIterator<void> {
  let oldSettings: TargetState['settings'] = yield select(
    selectorGetStatisticTargetR,
  );
  const { targetName, campaign_xxhash } = payload;
  const isAu = targetName === 'au';
  let receivedData = genFetchedData<RGetSettings>(null).set('isLoading', true);
  oldSettings = yield select(selectorGetSettingsR);
  yield put(setSettings({ ...oldSettings, [targetName]: receivedData }));
  try {
    yield call(updateToken);

    const result: SagaReturnType<typeof getSettings> = yield call(getSettings, {
      key: targetName,
      campaign_xxhash,
    });

    const resultSort = result;
    resultSort.catalog = Object.values(result.catalog).sort((a, b) => {
      if (a.sort === b.sort) {
        return a.title > b.title ? 1 : -1;
      }
      return a.sort > b.sort ? 1 : -1;
    });
    receivedData = receivedData.set('data', resultSort);
    oldSettings = yield select(selectorGetSettingsR);
    yield put(setSettings({ ...oldSettings, [targetName]: receivedData }));
    if (isAu) {
      yield put(
        setAuModalCatalog({ ...oldSettings, [targetName]: receivedData }),
      );
    }
  } catch (error) {
    console.error({ error });
    receivedData = receivedData.set('error', {
      isError: true,
      message: error.message,
      code: error?.code,
    });
    oldSettings = yield select(selectorGetSettingsR);
    yield put(setSettings({ ...oldSettings, [targetName]: receivedData }));
  } finally {
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    oldSettings = yield select(selectorGetSettingsR);
    yield put(setSettings({ ...oldSettings, [targetName]: receivedData }));
    if (isAu) {
      yield put(
        setAuModalCatalog({ ...oldSettings, [targetName]: receivedData }),
      );
    }
  }
}

export function* workerRemoveUserSettings({
  payload,
}: ReturnType<typeof removeUserSettings>): SagaIterator<void> {
  try {
    const {targeting_data} = payload;
    const params = {
      xxhash: payload.xxhash,
      target_key: payload.target_key,
    };

    yield call(removeUserSettingAPI, {
      ...params,
      targeting_data,
    });

    const savedSettings: FetchedData<Record<TargetKey, Ttarget>> = yield select(
      selectorTargetUserSettings,
    );

    const savedData = savedSettings.get('data');

    const targetUserSettingsData: Ttarget = yield call(
      getSingleTargetUserSettingsAPI,
      payload,
    );
    if (savedData) {
      savedData[payload.target_key] = targetUserSettingsData;
    }
    const newData = savedSettings.set('data', savedData).set('LTU', Date.now());
    yield put(setTargetUserSettingsData(newData));
  } catch (e) {
    console.error({ e });
  }
}

export function* workerStatisticTarget({
  payload,
}: ReturnType<typeof fetchStatistics>): SagaIterator<void> {
  let receivedData = genFetchedData<RGetStatisticTarget>(null).set(
    'isLoading',
    true,
  );
  let oldStatisticTarget: TargetState['statisticTarget'] = yield select(
    selectorGetStatisticTargetR,
  );
  const { targetName } = payload;
  yield put(
    setStatisticTarget({ ...oldStatisticTarget, [targetName]: receivedData }),
  );
  try {
    yield call(updateToken);
    const result: SagaReturnType<typeof getStatisticTarget> = yield call(
      getStatisticTarget,
      {
        ...payload,
      },
    );

    receivedData = receivedData.set('data', result);

    oldStatisticTarget = yield select(selectorGetStatisticTargetR);
    yield put(
      setStatisticTarget({ ...oldStatisticTarget, [targetName]: receivedData }),
    );
  } catch (error) {
    console.error({ error });
    receivedData = receivedData.set('error', {
      isError: true,
      message: error.message,
      code: error?.code,
    });
    oldStatisticTarget = yield select(selectorGetStatisticTargetR);
    yield put(
      setStatisticTarget({ ...oldStatisticTarget, [targetName]: receivedData }),
    );
  } finally {
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    oldStatisticTarget = yield select(selectorGetStatisticTargetR);
    yield put(
      setStatisticTarget({ ...oldStatisticTarget, [targetName]: receivedData }),
    );
  }
}

export function* workerFetchTargetData({
  payload,
}: ReturnType<typeof fetchTargetData>): SagaIterator<void> {
  const {
    targetName,
    xxhash,
    campaign_xxhash,
    period,
    isStatistics,
    isSettings,
  } = payload;
  try {
    if (isStatistics) {
      yield put(fetchStatistics({ targetName, xxhash, period }));
    }
    if (isSettings) {
      yield put(fetchSettings({ targetName, campaign_xxhash }));
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* workerClearTarget({
  payload,
}: ReturnType<typeof fetchClearTarget>): SagaIterator<void> {
  try {
    const { alert, xxhash } = payload;
    yield call(updateToken);
    const result = yield call(clearTarget, { xxhash });
    if (result) {
      toastSuccess(alert);
    }

    yield put(fetchPDataByTarget({ id: xxhash }));
  } catch (e) {
    console.error({ e });
  }
}

export function* workerFetchPDataByTarget({
  payload,
}: ReturnType<typeof fetchPDataByTarget>): SagaIterator<void> {
  try {
    const savedSettings = yield select(selectorTargetUserSettings);
    const savedData = savedSettings.get('data');
    yield call<TMakeReqWithRD<typeof getTargetUserSettingsAPI>>(makeReqWithRD, {
      fetcher: getTargetUserSettingsAPI,
      fill: setTargetUserSettingsData,
      parameters: { xxhash: payload.id },
      preData: savedData,
    });
  } catch (e) {
    console.error({ e });
  }
}

// ТУТ
export function* workerSetTargeting({
  payload,
}: ReturnType<typeof onSetTargeting>): SagaIterator<void> {
  const { setLoading, setEdit, blockTitle } = payload;
  let receivedData = genFetchedData<RGetStatisticTarget>(null).set(
    'isLoading',
    true,
  );
  try {
    yield call(updateToken);

    const { deletedItemsArr, ...restPayload } = payload;

    const oldSavedSettings: FetchedData<Record<TargetKey, Ttarget>> =
      yield select(selectorTargetUserSettings);
    const oldSavedData = oldSavedSettings.get('data');

    const params = {
      xxhash: payload.xxhash,
      target_key: payload.target_key,
    };

    const isChangeInverMode =
      oldSavedData?.[payload.target_key].settings.is_invert_mode !==
      payload.targeting_data.is_invert_mode;

    const isChangeTargetItem =
      Object.keys(payload.targeting_data.items).length !== 0;

    const removeParams = {
      ...params,
      targeting_data: deletedItemsArr,
    };

    const promises = []

    if (deletedItemsArr.length) {
      promises.push(yield call(removeUserSettingAPI, removeParams))
    }

    if (isChangeInverMode || isChangeTargetItem) {
      promises.push(call(setTargeting, restPayload))
    }

    yield all(promises)

    const savedSettings: FetchedData<Record<TargetKey, Ttarget>> = yield select(
      selectorTargetUserSettings,
    );
    const savedData = savedSettings.get('data');

    const targetUserSettingsData: Ttarget = yield call(
      getSingleTargetUserSettingsAPI,
      params,
    );
    if (savedData) {
      savedData[payload.target_key] = targetUserSettingsData;
    }
    const newData = savedSettings.set('data', savedData).set('LTU', Date.now());
    yield put(setTargetUserSettingsData(newData));

    toastSuccess({
      before: `${i18next.t('targetings.saveblock.hint1')}`,
      title: `"${blockTitle}"`,
      after: `${i18next.t('targetings.saveblock.hint2')}`,
    });
    setEdit(false);
  } catch (e) {
    const old = yield select(selectorSetTargetingRequest);
    const { isLoading } = extractGenFetchData(
      yield select(selectorCreativeList),
    );

    if (isLoading) {
      yield take(setTempCreativeListSuccess);
    }
    const { data: list } = extractGenFetchData(
      yield select(selectorCreativeList),
    );

    if (!list) {
      yield put(fetchCreativesList({ campaign_xxhash: payload.xxhash }));
      yield take(setTempCreativeListSuccess);
    }

    const { data } = extractGenFetchData<CreativeItemListWithStatistics[]>(
      yield select(selectorCreativeList),
    );
    let creatives:
      | { xxhash: string; title: string; campaign_xxhash: string }[]
      | undefined = [];
    if (e?.data?.fields?.creatives) {
      creatives = data
        ?.filter(({ xxhash }) => e?.data?.fields?.creatives.includes(xxhash))
        ?.map(({ xxhash, title, campaign_xxhash }) => ({
          xxhash,
          title,
          campaign_xxhash,
        }));
    }

    receivedData = receivedData.set('error', {
      isError: true,
      message: e.message,
      code: e?.code,
      description: e?.data.description,
      mnemonic: e?.data.mnemonic,
      creatives: creatives ?? [],
    });
    yield put(
      setTargetingRequest({ ...old, [payload.target_key]: receivedData }),
    );
  } finally {
    if (payload.deletedItemsArr.length) {
      payload.clearDeletedArr();
    }
    const old = yield select(selectorSetTargetingRequest);
    receivedData = receivedData.set('isLoading', false).set('LTU', Date.now());
    yield put(
      setTargetingRequest({ ...old, [payload.target_key]: receivedData }),
    );
    setLoading(false);
  }
}

export function* fetchGeoTreeWorker(): SagaIterator<void> {
  try {
    /* const oldData = yield select(selectorGeoTree); */

    /*     openRequest.onupgradeneeded = function () {
      const db = openRequest.result;
      if (!db.objectStoreNames.contains('treeStore')) {
        // если хранилище "books" не существует
        db.createObjectStore('treeStore', { keyPath: 'id' }); // создаём хранилище
      }
    };

    openRequest.onerror = function () {
      console.error('Error', openRequest.error);
    };

    openRequest.onsuccess = function () {
      const db = openRequest.result;
      const transaction = db.transaction(['treeStore']);
      const objectStore = transaction.objectStore('treeStore');
      const request = objectStore.get('tree');
      request.onsuccess = function () {
        console.log('done')
        if(request.result) {
          const tree = request.result.data
          setGeoTree(tree)
          
        }
        console.log(request.result)
      }
      request.onerror = function () {
        console.log('Ошибка в запросе по индексу "tree"')
      }
    }; */

    yield call<TMakeReqWithRD<typeof getGeoTree>>(makeReqWithRD, {
      fetcher: getGeoTree,
      fill: (v) => {
        const dataTree = v.get('data');

        const NAItem = {
          geoname_id: -1,
          code: 'n/a',
          name_ru: 'OTHER',
          name_en: 'OTHER',
          sort: 0,
        };

        let newData: Tree[] = [];
        if (dataTree) {
          const continentKeys = dataTree
            .sort((a, b) => (a.sort > b.sort ? 1 : -1))
            .map(({ geoname_id }) => geoname_id);
          continentKeys.forEach((geoIdContinent) => {
            const continent = dataTree.find(
              ({ geoname_id }) => geoIdContinent === geoname_id,
            );
            if (continent) {
              // добавляем страны
              let countryState: Tree[] = [];
              const countryObj = continent.countries;
              const countryKeys = Object.keys(countryObj);
              let dataCountry: TGeoSubdivisions[] = [];
              countryKeys.forEach((geoIDCountry) => {
                dataCountry = [...dataCountry, countryObj[geoIDCountry]];
              });
              dataCountry = dataCountry.sort((a, b) =>
                a.sort > b.sort ? 1 : -1,
              );
              dataCountry.forEach((country) => {
                // добавляем регионы
                let dataSubDivision: TGeoCity[] = [];
                let subdivisionsState: Tree[] = [];
                if (country.subdivisions) {
                  const subdivisionsKeys = Object.keys(country.subdivisions);
                  subdivisionsKeys.forEach((geoIDSubdivision) => {
                    if (!country.subdivisions) return;
                    dataSubDivision = [
                      ...dataSubDivision,
                      country.subdivisions[geoIDSubdivision],
                    ];
                  });
                  dataSubDivision = dataSubDivision.sort((a, b) =>
                    a.sort > b.sort ? 1 : -1,
                  );
                }

                dataSubDivision.push(NAItem);

                dataSubDivision.forEach((sub) => {
                  let dataCites: TGeoItemGeneral[] = [];
                  if (sub.cites) {
                    const dataCitesKeys = Object.keys(sub.cites);
                    dataCitesKeys.forEach((geoIDCity) => {
                      if (!sub.cites) return;
                      dataCites = [...dataCites, sub.cites[geoIDCity]];
                    });
                    dataCites = dataCites.sort((a, b) =>
                      a.sort > b.sort ? 1 : -1,
                    );
                  }

                  dataCites.push(NAItem);

                  subdivisionsState = [
                    ...subdivisionsState,
                    {
                      code: sub.code,
                      geoname_id: sub.geoname_id,
                      isChecked: sub.geoname_id === -1 ? 'disable' : 'inherit',
                      type: 'subdivisions',
                      sort: sub.sort,
                      name_ru: sub.name_ru,
                      name_en: sub.name_en,
                      isToggle: false,
                      parent: {
                        cites: null,
                        subdivisions: sub.geoname_id,
                        countries: country.geoname_id,
                        continents: continent.geoname_id,
                      },
                      children:
                        sub.geoname_id !== -1
                          ? [
                            ...dataCites.map(
                              (city) =>
                              ({
                                code: city.code,
                                sort: city.sort,
                                geoname_id: city.geoname_id,
                                isChecked:
                                  city.geoname_id === -1
                                    ? 'disable'
                                    : 'inherit',
                                name_ru: city.name_ru,
                                name_en: city.name_en,
                                type: 'cites',
                                parent: {
                                  subdivisions: sub.geoname_id,
                                  countries: country.geoname_id,
                                  continents: continent.geoname_id,
                                },
                              } as Tree),
                            ),
                          ]
                          : [],
                    },
                  ];
                });

                countryState = [
                  ...countryState,
                  {
                    code: country.code,
                    sort: country.sort,
                    geoname_id: country.geoname_id,
                    isChecked: 'inherit',
                    type: 'countries',
                    name_ru: country.name_ru,
                    name_en: country.name_en,
                    isToggle: false,
                    parent: {
                      cites: null,
                      countries: country.geoname_id,
                      continents: continent.geoname_id,
                      subdivisions: null,
                    },
                    children:
                      country.geoname_id !== -1 ? [...subdivisionsState] : [],
                  },
                ];
              });
              // добавляем континенты
              newData = [
                ...newData,
                {
                  code: continent.code,
                  sort: continent.sort,
                  geoname_id: continent.geoname_id,
                  isChecked: 'inherit',
                  isToggle: false,
                  type: 'continents',
                  name_ru: continent.name_ru,
                  name_en: continent.name_en,
                  parent: {
                    cites: null,
                    continents: continent.geoname_id,
                    countries: null,
                    subdivisions: null,
                  },
                  children: [...countryState],
                },
              ];
            }
          });
        }

        const transformedData = {
          /* ...oldData, */
          geo_id: updateFillFetchedData<RGeo, Tree[]>({
            fillFetchedData: v,
            newData,
          }),
        };

        if (newData.length) {
          const openRequest = indexedDB.open('store', 1);

          openRequest.onsuccess = function () {
            const db = openRequest.result;
            // Транзакция для записи актуального объекта в db
            const transaction = db.transaction('treeStore', 'readwrite');
            const treeStore = transaction.objectStore('treeStore');
            const storedItem = {
              id: 'tree',
              liveTime: calculateGeoDataLiveTime(new Date()),
              data: newData,
            };

            const request = treeStore.add(storedItem);

            request.onsuccess = function () {
              db.close();
            };

            request.onerror = function () {
              console.log('Ошибка при добавлении в db: ', request.error);
            };
          };
        }

        return setGeoTree(transformedData);
      },
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* fetchGeoPopularWorker(): SagaIterator<void> {
  try {
    const oldPopular = yield select(selectorGeoPopular);

    yield call<TMakeReqWithRD<typeof getAllPopular>>(makeReqWithRD, {
      fetcher: getAllPopular,
      fill: (v) => {
        const data = v.get('data');
        let newData: TPopular[] = [];
        if (data) {
          const arKey: TGeoSectionName[] = [
            'cites',
            'countries',
            'subdivisions',
          ];
          arKey.forEach((k) => {
            if (data[k].length) {
              newData = [
                ...newData,
                ...data[k].map((geo) => ({
                  title:
                    geo[
                    `name_${i18next.language === 'de' ? 'en' : i18next.language
                    }`
                    ],
                  sort: geo.sort,
                  geoname_id: geo.geoname_id,
                  isChecked: false,
                })),
              ];
            }
          });
          newData = newData.sort((a, b) => (a.sort > b.sort ? 1 : -1));
        }
        return setGeoPopular({
          ...oldPopular,
          geo_id: updateFillFetchedData<RGeoPopular, TPopular[]>({
            fillFetchedData: v,
            newData,
          }),
        });
      },
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* resetTargetTypeWorker({
  payload,
}: ReturnType<typeof resetTargetType>): SagaIterator<void> {
  try {
    const statisticsOld: Record<
      string,
      FetchedData<RGetStatisticTarget>
    > = yield select(selectorTargetStatistics);
    const newStatistics: Record<string, FetchedData<RGetStatisticTarget>> = {};

    Object.entries(statisticsOld).forEach(([k, v]) => {
      if (k !== payload) {
        newStatistics[k] = v;
      }
    });

    yield put(setStatisticTarget(newStatistics));
  } catch (e) {
    console.error({ e });
  }
}

export function* fetchGeoMyTargetWorker({
  payload,
}: ReturnType<typeof fetchMyTargetGeo>): SagaIterator<void> {
  let tree: Tree[] = [];
  const categoriesRTB = Сategories_rtb.categories;
  try {
    const oldData = yield select(selectorGeoTree);
    if (payload === 'categories_rtb') {
      categoriesRTB.forEach((item) => {
        tree = [
          ...tree,
          {
            geoname_id: Number(item.id),
            type: 'continents',
            isChecked: 'inherit',
            isToggle: false,
            name_ru: item.name,
            sort: 1,
            code: item.name,
            name_en: item.name,
            children: [],
            parent: {
              cites: null,
              continents: Number(item.id),
              subdivisions: null,
              countries: null,
            },
          },
        ];
      });
      // добавили континенты
      tree.forEach((continents, continentIndex) => {
        const childrenContinent = categoriesRTB[continentIndex]?.chld;
        const continent = tree[continentIndex];
        if (childrenContinent) {
          childrenContinent.forEach((el) => {
            tree[continentIndex].children.push({
              geoname_id: Number(el.id),
              type: 'countries',
              isChecked: 'inherit',
              isToggle: false,
              name_ru: el.name,
              sort: 1,
              code: el.name,
              name_en: el.name,
              children: [],
              parent: {
                cites: null,
                continents: continent.geoname_id,
                subdivisions: null,
                countries: Number(el.id),
              },
            });
          });
          //  добавили страны
          continents.children.forEach((countries, countriesIndex) => {
            const country = continent.children[countriesIndex];
            const childrenCountries = childrenContinent[countriesIndex]?.chld;
            if (childrenCountries) {
              childrenCountries.forEach((el) => {
                tree[continentIndex].children[countriesIndex].children.push({
                  geoname_id: Number(el.id),
                  type: 'subdivisions',
                  isChecked: 'inherit',
                  isToggle: false,
                  name_ru: el.name,
                  sort: 1,
                  code: el.name,
                  name_en: el.name,
                  children: [],
                  parent: {
                    cites: null,
                    continents: continent.geoname_id,
                    subdivisions: Number(el.id),
                    countries: country.geoname_id,
                  },
                });
              });
            }
          });
        }
      });
    }

    let data = genFetchedData<Tree[]>(null).set('isLoading', true);
    yield put(setGeoTree({ [payload]: data }));
    yield delay(1000);
    data = data
      .set('data', tree)
      .set('LTU', Date.now())
      .set('isLoading', false);
    yield put(setGeoTree({ ...oldData, [payload]: data }));
  } catch (e) {
    console.log({ e });
  }
}

export function* workerSearchGeo({
  payload,
}: ReturnType<typeof geoSearch>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getSearchGeo>>(makeReqWithRD, {
      fetcher: getSearchGeo,
      fill: setGeoSearchData,
      parameters: payload,
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* workerUpdateAu({
  payload,
}: ReturnType<typeof updateAu>): SagaIterator<void> {
  const { callbacks, targetName, campaign_xxhash } = payload;

  try {
    callbacks.setIsLoading(true);
    const result = yield call(catalogItemsUpdate, { target_key: 'au' });

    if (result) {
      /* yield put(fetchSettings(params)) */
      let oldSettings: TargetState['settings'] = yield select(
        selectorGetStatisticTargetR,
      );
      let receivedData = genFetchedData<RGetSettings>(null);
      const data: SagaReturnType<typeof getSettings> = yield call(getSettings, {
        key: targetName,
        campaign_xxhash,
      });

      const resultSort = data;
      resultSort.catalog = Object.values(data.catalog).sort((a, b) => {
        if (a.sort === b.sort) {
          return a.title > b.title ? 1 : -1;
        }
        return a.sort > b.sort ? 1 : -1;
      });
      receivedData = receivedData.set('data', resultSort);
      oldSettings = yield select(selectorGetSettingsR);
      yield put(setSettings({ ...oldSettings, [targetName]: receivedData }));
      yield put(
        setAuModalCatalog({ ...oldSettings, [targetName]: receivedData }),
      );
      if (callbacks.setNotFound) {
        callbacks.setNotFound([]);
      }
    }
  } catch (e) {
    console.error({ e });
  } finally {
    callbacks.setIsLoading(false);
  }
}

export function* workerUpdateTarget({
  payload,
}: ReturnType<typeof updateTarget>): SagaIterator<void> {
  const { targetName } = payload;

  try {
    const result = yield call(catalogItemsUpdate, { target_key: targetName });

    if (result) {
      yield put(fetchSettings(payload));
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* workerClearAllTargetItems({
  payload,
}: ReturnType<typeof clearAllTargetItems>): SagaIterator<void> {

  const { reCreate, deletedArr, hideAlert, setisLoadingClearTarget, ...params } = payload
  setisLoadingClearTarget(true)
  try {
    let result = false

    if (reCreate && deletedArr) {
      result = yield call(removeUserSettingAPI, {
        ...params,
        targeting_data: deletedArr
      })
    } else {
      result = yield call(unsetCreativeTargeting, params);
    }

    if (params.target_key === 'geo_id') {
      yield put(fetchGeoPopular());
    }

    const savedSettings: FetchedData<Record<TargetKey, Ttarget>> = yield select(
      selectorTargetUserSettings,
    );
    const savedData = savedSettings.get('data');

    const targetUserSettingsData: Ttarget = yield call(
      getSingleTargetUserSettingsAPI,
      payload,
    );
    if (savedData) {
      savedData[payload.target_key] = targetUserSettingsData;
    }
    const newData = savedSettings.set('data', savedData).set('LTU', Date.now());
    yield put(setTargetUserSettingsData(newData));

    if (result) {
      if (hideAlert) return
      toastSuccess({
        before: `${i18next.t('targetings.saveblock.hint1')}`,
        title: `"${checkedLocalization(
          `common.targetings.${params.target_key}`,
          params.target_key,
        )}"`,
        after: ` ${i18next.t('common.targetings.clean_target_success')}`,
      });
    }

  } catch (e) {
    console.error({ e });
  } finally {
    setisLoadingClearTarget(false)
  }
}

export function* toggleInheritSettingsWorker({
  payload,
}: ReturnType<typeof toggleInheritSettings>): SagaIterator<void> {

  const { setIsLoading, ...params } = payload

  try {
    setIsLoading(true)
    yield call(setTargeting, params)
    const targetUserSettingsData: Ttarget = yield call(
      getSingleTargetUserSettingsAPI,
      params,
    );
    const savedSettings: FetchedData<Record<TargetKey, Ttarget>> =
      yield select(selectorTargetUserSettings);
    const savedData = savedSettings.get('data');
    if (savedData) {
      savedData[payload.target_key] = targetUserSettingsData;
    }
    const newData = savedSettings
      .set('data', savedData)
      .set('LTU', Date.now());
    yield put(setTargetUserSettingsData(newData));
  } catch (e) {
    console.error({ e });
  } finally {
    setIsLoading(false)
  }
}