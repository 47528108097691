import Card from 'components/UI/Card';
import CardList from 'components/UI/CardList';
import React, { FC, useState } from 'react';
import cn from 'classnames';
import { FiCopy } from 'react-icons/fi';
import { GrUpdate } from 'react-icons/gr';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/UI/Tooltip';
import css from './styles.module.scss';

interface Props {
  updateToken: () => void;
  token?: string;
}

const Token: FC<Props> = ({ updateToken, token }) => {
  const [copied, setCopied] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <CardList>
      <Card noHover className={cn(css.card, css.token)}>
        <div className={css.header}>
          <div className={css.title}>{t(`users_api.token`)}</div>
          <div className={css.actions}>
            <Tooltip title={<GrUpdate size={18} onClick={updateToken} />}>
              {t(`update_btn`)}
            </Tooltip>
            <Tooltip
              title={
                <FiCopy
                  size={20}
                  className={cn(css.copy_icon, {
                    isDisabled: copied,
                  })}
                  onClick={() => {
                    setCopied(true);
                    copy(`Bearer ${token}`);
                    setTimeout(() => {
                      setCopied(false);
                    }, 1500);
                  }}
                />
              }
            >
              {t(`creative.moderation.modal.labelBtn`)}
            </Tooltip>
          </div>
        </div>
        <div className={css.body}>{`Bearer ${token}`}</div>
      </Card>
    </CardList>
  );
};

export default Token;
