import { RAuthToken } from '../../domains/user/types';
import { userNotificationPush } from '../../domains/user/model/hooks';

type TFGetToken = () => RAuthToken | null;
type TFSetToken = (v: RAuthToken) => void;
type TFRemoveToken = () => void;

export const getToken: TFGetToken = () => {
  const tokenStr = localStorage.getItem('$token');
  if (tokenStr) {
    return JSON.parse(tokenStr);
  }

  return tokenStr as null;
};

export const setToken: TFSetToken = (token) =>
  localStorage.setItem('$token', JSON.stringify(token));

export const removeToken: TFRemoveToken = async () => {
  const { messaging } = userNotificationPush();
  if (messaging) {
    await messaging.deleteToken();
    localStorage.removeItem('$push-token');
  }
  localStorage.removeItem('$token');
};
