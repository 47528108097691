export default {
  translations: {
    common: {
      reports: {
        day: 'Genel rapor',
        tech_fee: 'Genel rapor. Teknoloji ücretine göre gruplandırılmıştır',
        metrika: 'Yandex.Metrika.',
        consolidated_report: 'Konsolide rapor',
        nmi_report: 'NMI raporu',
        integr_report: 'Entegre rapor',
        soc_dem_report: 'Sosyo-demografik rapor',
        geo_report: 'Coğrafi rapor',
        frequency_shows: 'Frekans raporu',
        extended_report: 'Genişletilmiş rapor',
      },
    },
    form: {
      common: {
        login: 'Oturum aç',
        signin: 'Oturum aç',
        email: 'E-posta',
        password: 'Şifre',
      },
    },
    top: {
      partner_managed: 'adlı ajans tarafından yönetiliyor',
      exit: 'Çıkış ',
      menu: {
        clients: 'Müşteriler',
        campaigns: 'Kampanyalar',
        containers: 'Kapsayıcılar',
        load_data: 'Veri yükle ',
        account: 'Hesap',
        bwlists: 'Siyah ve Beyaz listeler',
      },
    },
    login_page: {
      error_email: 'Geçersiz e-posta adresi',
      error_password: 'E-posta veya şifre yanlış',
      language_tit: 'Dil',
    },
    clients_page: {
      clients_tit: 'Müşteriler',
      add_client: 'Müşteri ekle',
      client_name: 'İsim',
      clients_search: 'Arama',
      active_clients: 'Çalıştırılıyor',
      archive_clients: 'Arşivi',
      edit_icon: 'Duraklatıldı',
      add_arc_icon: 'Добавить в архив',
      rem_arc_icon: 'Извлечь из архива',
      client_create: {
        client_create_tit: 'Müşteri ekle ',
        client_load_img: 'Resim yükle',
        client_img_link: 'Resmin bağlantısı',
        client_img_formats: 'İzin verilen biçimler: png, jpeg, jpg, svg.',
        client_img_size: 'Maksimum boyut: 1 Mb',
        client_img_maxsize: 'Maksimum çözünürlük: 2.000 × 2.000',
        client_name: 'Ad*',
        client_site: 'İnternet sitesi',
        client_reports: 'Raporlar',
        client_use_systemset: 'Sistem ayarlarını kullan',
        client_report_list:
          'Mevcut raporların listesi müşteri eklendikten sonra değiştirilebilir',
      },
      client_edit: {
        client_edit_tit: 'Müşteri ekle',
        client_edit_delete_avatar: 'Удалить',
        client_edit_reports: 'Reklamlar',
        client_edit_use_settings: 'Sistem ayarlarını kullan',
        day: 'Genel rapor',
        tech_fee: 'Genel rapor. Teknoloji ücretine göre gruplandırılmıştır',
        metrika: 'Yandex.Metrika.',
        consolidated_report: 'Konsolide rapor',
        nmi_report: 'NMI raporu',
        integr_report: 'Entegre rapor',
        soc_dem_report: 'Sosyo-demografik rapor',
        geo_report: 'Coğrafi rapor',
        frequency_shows: 'Frekans raporu',
        extended_report: 'Genişletilmiş rapor',
        included: 'Etkin',
        turned_off: 'Devre dışı',
        available: 'Kullanılabilir',
        moderation_creatives: 'Yaratıcı denetleme',
        update_hand: 'Reklam öğelerini manuel olarak onayla',
        update_auto: 'Reklam öğelerini otomatik olarak onayla',
        categories_creatives: 'Reklam öğesi kategorileri',
        switch_points:
          'Etkinleştirilen reklamlar Kategori sekmesinde gösterilir',
        animation: 'Animasyon',
        jest: 'Şiddet',
        erotic: 'Erotik',
        link_to_image: 'Resmin bağlantısı',
        link_to_image_link: 'Bağlantı',
        link_to_image_add: 'Kaydet',
        link_to_image_cancel: 'İptal et',
      },
    },
    campaigns_page: {
      campaigns: 'Kampanyanın',
      show_full_name: 'Kampanyanın tam adı',
      show_short_name: 'Kampanyanın kısa adı',
      create_btn: 'Kampanya oluşturma',
      campaigns_black_header_main: {
        act: 'Koşu',
        pause: 'Duraklatıldı',
        del: 'Silindi',
        all: 'Tüm Kampanyalar',
      },
      campaigns_header_main: {
        name: ' Ad',
        shows: 'Gösterimler',
        clicks: 'Tıklamalar',
        spent: 'Harcanan tutar',
        budget: 'Bütçe',
        status: 'Durum',
        cpc: 'CPC',
        cpm: 'CPM',
        ctr: 'CTR',
      },
      campaigns_status: {
        act: 'Çalışıyor',
        pause: 'Duraklatıldı',
        del: 'Silindi',
      },
      campaign_stat_icon: 'İstatistik',
      campaign_del_icon: 'Sil',
      campaign_stat_modal: {
        tit: 'İstatistik',
        shows: 'Gösterimler',
        clicks: 'Tıklamalar',
        spent: 'Harcanan tutar',
        budget: 'Bütçe',
      },
      empty: {
        act: 'Etkin kampanyanız yok',
        no1: 'Henüz kampanyanız yok.',
        no2: 'Yeni bir tane eklemek için tıklayın.',
      },
      create_campaign: {
        black_header: {
          back: 'Geri',
          set: 'Ayarlar',
          targ: 'Hedefleme',
        },
        tit: 'Kampanya oluşturma',
        client: 'Müşteri',
        adv_name: 'Reklamveren adı',
        name: 'Kampanya adı',
        period: 'Dönem',
        data_start: 'Başlangıç tarihi',
        date_stop: 'Bitiş tarihi',
        budget_tit: 'Bütçe',
        budget_text:
          'Bütçe bittiğinde kampanyanın durdurulacağını lütfen unutmayın. Bütçeyi istediğiniz zaman artırabilirsiniz.',
        budget: 'Bütçe',
        price: 'Maliyetler',
        enter: 'Lütfen girin…',
        without_limit: 'Sınırsız',
        limits: 'Sınırlar',
        clicks: 'Tıklamalar',
        shows: 'Gösterimler',
        shows_one: 'Kullanıcı başına gösterim',
        limits_budget: 'Bütçe',
        hour: 'Saat',
        day: 'Gün',
        hour_rub: 'Saat',
        day_rub: 'Gün',
        all: 'Toplam',
        create_btn: 'Oluştur',
        cancel_btn: 'İptal et',
        create_text:
          'İşlem Masası platformumuzu kullanarak yeni reklam kampanyaları oluşturun. Tüm kampanyalar için çok çeşitli işlem olanakları sunuyoruz. Kampanyalarınızın durumlarını her zaman izleyebilir, istatistikleri ve ayrıntılı raporları düzenli olarak görüntüleyebilirsiniz.',
        create_text2:
          ' Herhangi bir sorunuz varsa veya ek bilgiye ihtiyacınız olursa, lütfen TD ',
        create_text_link: ' belgelerini okuyun',
      },
      pretarg_text: 'Hedef ayarlamak için lütfen kampanya oluşturun',
      targeting_word: 'Hedefleme',
      campaign_settings: {
        black_menu: {
          Main: 'Main',
          Back: 'Geri',
          Main_settings: 'Ana ayarlar',
          Targeting: 'Hedefleme',
          Creatives: 'Reklamlar',
          Reports: 'Raporlar',
          Settings: 'Ayarlar',
          Settings_aditional: 'Ek ayarlar',
        },
        Client: 'Müşteri',
        Advertiser_name: 'Reklamveren adı',
        Campaign_name: 'Kampanya adı',
        Running: 'Koşu',
        Paused: 'Duraklatıldı',
        Edit: 'Düzenle',
        Period: 'Dönem',
        Start_date: 'Başlangıç tarihi ',
        End_date: 'Bitiş tarihi',
        Budget: 'Bütçe',
        Please_note:
          'Bütçe bittiğinde kampanyanın durdurulacağını lütfen unutmayın. Bütçeyi istediğiniz zaman artırabilirsiniz. ',
        cost: 'Maliyetler',
        Please_enter: 'Lütfen girin… ',
        Unlimited: 'Sınırsız',
        Limits: 'Sınırlar',
        Clicks: 'Tıklamalar',
        Impressions: 'Gösterimler',
        per_user: 'Kullanıcı başına gösterim',
        Hour: 'Saat',
        Day: 'Gün',
        Total: 'Toplam',
      },
      stat_chart: {
        tit: 'İstatistik',
        shows: 'Gösterimler',
        clicks: 'Tıklamalar',
      },
      camp_del_tit: 'Bir kampanyayı silme',
      camp_del_quest: 'Gerçekten silmek istiyor musun',
    },
    save_btn: 'Kaydet',
    cancel_btn: 'İptal et',
    edit_btn: 'Düzenle',
    add_btn: 'Ekle',
    yes: 'Evet',
    no: 'Hayır',
    date_pick: {
      days30: 'Son 30 gün',
      month: 'Bu ay ',
      week: 'Bu hafta',
      day: 'Bugün',
      select: 'Dönem seçin',
    },
    profile: {
      profile_word: 'Profil',
      balance: 'Denge',
      partner_managed: 'ajans tarafından yönetiliyor',
      ava_funds: 'Kullanılabilir fonlar',
      settings_tit: 'Ayarlar',
      main: 'Ana',
    },
    alerts: {
      view_mode: 'Görüntüleme modu.',
      view_mode_cont: ' Sayfaları düzenleyemezsiniz',
    },
    targetings: {
      show: 'Göstermek · ',
      all: 'herşey',
      custom: 'Özelleştirilmiş',
      search: 'Arama',
      source: {
        tit: 'Kaynaklar',
        name: ' Ad ',
      },
      geo_country: 'Coğrafi · Ülkeler',
      geo_regions_and_city: {
        tit: 'Coğrafi Bölgeler ve şehirler',
        show_select_loc: 'Seçilen konumlarda göster ',
        show_select_punсt:
          'Bu, reklamların seçilen yerlerde gösterileceği anlamına gelir',
        show_select_locnot: 'Seçilen yerler hariç her yerde göster',
        show_select_punсtnot:
          'Bu, reklamların seçilen yerler hariç her yerde gösterileceği anlamına gelir',
        not_select_loc: 'Listede seçilen konumunuz yok ',
        not_select_loc_add:
          'Listede seçilen konumunuz yok. Eklemek için tıklayın',
        loc_add: 'Konum ekleyin',
      },
    },
    creative: {
      header_menu: {
        back: 'Geri',
        settings: 'Ayarlar',
        targetings: 'Hedefleme',
      },
      create_btn: 'Reklam öğesi oluştur ',
      search: 'Arama...',
      Name: 'İsim',
      Impressions: 'Gösterimler',
      Clicks: 'Tıklamalar',
      Amount_spent: 'Harcanan tutar',
      Moderation: 'Denetleme',
      Status: 'Durum',
      stat_act: 'Çalıştırılıyor',
      stat_pause: 'Duraklatıldı',
      create_creative: {
        create_tit: 'Reklam öğesi oluştur ',
        Advertiser_name: 'Reklamveren adı ',
        Please_fill:
          'Reklam öğesini Google onay sürecine göndermek için lütfen bu alanı doldurun ',
        Name: 'Ad',
        Creative_name: 'Reklam öğesinin adı ',
        symbols: ' sembolleri ',
        type_tit: 'Türü',
        type_tit_html: 'HTML',
        File: 'Dosya',
        Supported_format:
          'Desteklenen biçim: zip arşivi. Maksimum boyut: 6 Mb ',
        Max_size: '',
        Add_archive: 'Arşiv ekle',
        HTML_Code: 'HTML Kodu',
        Actions: 'İşlemler',
        Convert_code: 'Kodu dönüştür',
        Add_macros: 'Makro ekle',
        Size_px: 'Boyut, piksel',
        Please_select: 'Lütfen seçin ',
        Set_sizes: 'Boyutları ayarla ',
        Width: 'Genişlik',
        Height: 'Yükseklik',
        Additional_settings: 'Ek ayarlar',
        URL_settings: 'URL ayarları',
        Navigation_link: 'Gezinme bağlantısı',
        Link: 'Bağlantı',
        Domain: 'Alan adı',
        Domain_address: 'Alan adı adresi',
        Optional_URL: 'İsteğe bağlı ayarları',
        Pixel_audit: 'Piksel denetimi',
        Optional_pixel: 'İsteğe bağlı piksel denetimi ',
        Categories: 'Kategoriler',
        e_g: 'Örneğin. 14-23 ',
        Language: 'Dil',
        eg_cz: 'Örneğin. ru ',
        Creative_preview: 'Reklam ön izleme ',
        Preview: 'Ön izleme ',
        To_preview:
          'Ön izlemek için reklam öğesi oluşturmanız gerekir. Oluşturduğunuzda bu düğme etkinleşir ',
        This_format:
          'Bu biçim, reklam öğesi oluşturmak için 2 çeşidi destekler: ',
        Upload_zip: 'Zip arşivini yükle',
        Import_html: 'HTML kodunu içe aktar',
        animation: 'Animasyon',
        type_jest: 'Şiddet',
        type_erotick: 'Erotik',
      },
    },
  },
};
