import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import { workerGetAllCounteragent, workerGetCounteragentsList, workerGetOrdList } from './workers';
import { getAllCounteragentsList, getCounteragentsList, getOrdList } from '../actions';

export default function* watchCounteragents(): SagaIterator {
  yield takeLatest(getCounteragentsList, workerGetCounteragentsList);
  yield takeLatest(getAllCounteragentsList, workerGetAllCounteragent);
  yield takeLatest(getOrdList, workerGetOrdList);
}
