import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';

const Header: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={css.header}>
      <div className="container">
        <div className={css.title}>
          <h1>{t('profile.profile_word')}</h1>
        </div>
      </div>
    </div>
  );
};

export default Header;
