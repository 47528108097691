import { SParthner } from 'domains/users/model/types';
import { FormikProps } from 'formik';
import React, { useEffect } from 'react';
import Select from 'react-select';
import { CustomOptionsType } from 'types/general';
import i18n from '../../../i18n';
import ErrorText from '../ErrorText';

type TInputSelectCustom<T, M extends CustomOptionsType[]> = {
  propsValues?: M;
  placeholder?: string;
  customComponents?: any;
  customValue?: SParthner | null;
  fieldName: string;
  clearable?: boolean;
  formik?: FormikProps<T>;
  isError?: boolean;
  errorText?: string;
  className?: string;
  classNamePrefix?: string;
  isSearchable?: boolean;
  customFilterOption?: (
    option: {
      label: string;
      value: string;
    },
    rawInput: string,
  ) => boolean;
};

function index<T, M extends CustomOptionsType[]>({
  propsValues,
  placeholder,
  customValue,
  fieldName,
  formik,
  clearable,
  isError,
  errorText,
  customFilterOption,
  isSearchable = true,
  className,
  classNamePrefix = 'new_select',
  customComponents,
}: TInputSelectCustom<T, M>): JSX.Element {
  const handleChange = (selected: Record<string, string>) => {
    formik?.setFieldValue(fieldName, selected);
  };

  useEffect(() => {
    formik?.setFieldValue(fieldName, customValue);
  }, []);

  return (
    <>
      <Select
        className={className}
        options={propsValues}
        closeMenuOnSelect
        components={{
          ...customComponents,
        }}
        classNamePrefix={classNamePrefix}
        noOptionsMessage={() => i18n.t(`no_result`)}
        onChange={handleChange}
        allowSelectAll
        value={formik?.values && formik.values[fieldName]}
        placeholder={placeholder}
        filterOption={customFilterOption}
        isClearable={clearable}
        isSearchable={isSearchable}
        menuPortalTarget={document.getElementById('select-body-portal')}
      />
      {isError && (
        <div className="errorText">
          <ErrorText hideIcon text={errorText} />
        </div>
      )}
    </>
  );
}

export default index;
