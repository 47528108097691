import deepEqual from 'deep-equal';

type TValue<T> = T[keyof T];
type Optional<T> = Record<string, TValue<T>>;

/**
 * Функция возвращает только изменившиеся значенеи в объекте
 * @stateOld -> Первоначальное состояние
 * @stateNew -> Новое состояние формы
 * @isFullResult -> Возвращает полный результат
 * */
export const objectOptimizationWithFormik = <
  T extends Record<string, TValue<T>>,
>(
  stateOld: T,
  stateNew: T,
  isLevelCompare = false,
): Optional<T> | false => {
  if (deepEqual(stateNew, stateOld)) {
    return false;
  }

  const keys = Object.keys(stateOld);
  const stateCompare: Optional<T> = {};
  let counter = 0;
  while (counter < keys.length) {
    try {
      if (stateOld[keys[counter]] !== stateNew[keys[counter]]) {
        if (typeof stateOld[keys[counter]] === 'object') {
          if (
            JSON.stringify(stateOld[keys[counter]]) !==
            JSON.stringify(stateNew[keys[counter]])
          ) {
            if (!isLevelCompare || Array.isArray(stateOld[keys[counter]])) {
              stateCompare[keys[counter]] = stateNew[keys[counter]];
            } else {
              const compare = objectOptimizationWithFormik<any>(
                stateOld[keys[counter]],
                stateNew[keys[counter]],
                true,
              );
              if (compare) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                stateCompare[keys[counter]] = compare;
              }
            }
          }
        } else if (stateNew[keys[counter]] !== undefined) {
          stateCompare[keys[counter]] = stateNew[keys[counter]];
        } else {
          stateCompare[keys[counter]] = stateOld[keys[counter]];
        }
      }
    } catch (e) {
      if (!stateNew) {
        stateCompare[keys[counter]] = stateOld[keys[counter]];
      }
    }
    counter += 1;
  }
  return stateCompare;
};
