/* eslint-disable jsx-a11y/media-has-caption */
import React, { FC, useEffect, useRef } from 'react';
import { toastError } from 'utils/ToastUtils/toastUtils';
import { runWaterfall, VideoAdUnit } from 'video-ad-sdk';
import css from './styles.module.scss';

type TPlayer = {
  xmlUrl: string;
};

const XmlPlayer: FC<TPlayer> = ({ xmlUrl }) => {
  const videoAdContainer = useRef<any>(null);
  let adUnit: VideoAdUnit;

  const onAdReady = (newAdUnit: VideoAdUnit): void => {
    adUnit = newAdUnit;

    const eventHandler = (event: any): void => {
      if (event.type === 'complete') {
        adUnit.cancel();
      }
    };

    [
      'finish',
      'impression',
      'start',
      'firstQuartile',
      'midpoint',
      'thirdQuartile',
      'complete',
    ].forEach((eventType) => {
      adUnit.on(eventType, eventHandler);
    });
  };

  const requestAdRun = (): void => {
    const adTag = xmlUrl;
    const onError = (error: Error): void => {
      toastError(error.message);
    };

    runWaterfall(adTag, videoAdContainer.current, {
      onAdReady,
      onError,
    });
  };

  useEffect(() => {
    requestAdRun();
  }, [xmlUrl]);

  return (
    <div className={css.xml_player}>
      <div ref={videoAdContainer} className="video-ad-container" />
    </div>
  );
};

export default XmlPlayer;
