import { SagaIterator } from '@redux-saga/core';
import { call, put } from '@redux-saga/core/effects';
import {
  fetchAgencies,
  setStatusAgenciesAction,
  setTempAgencies,
} from '../reducer';
import { makeReqWithRD, TMakeReqWithRD } from '../../../redux/makeReqWithRD';
import { getAgencies, setStatusAgencies } from '../api';
import updateToken from '../../../utils/updateToken';
import { toastSuccess } from '../../../utils/ToastUtils/toastUtils';

export function* workerFetchAgenciesSaga({
  payload,
}: ReturnType<typeof fetchAgencies>): SagaIterator {
  try {
    yield call<TMakeReqWithRD<typeof getAgencies>>(makeReqWithRD, {
      fetcher: getAgencies,
      fill: setTempAgencies,
      parameters: payload.status ? { status: payload.status } : {},
    });
  } catch (e) {
    console.log({ e });
  }
}
export function* workerSetStatusAgencies({
  payload,
}: ReturnType<typeof setStatusAgenciesAction>): SagaIterator {
  try {
    const { value, setter, status, id, reRenderParams } = payload;
    yield call(updateToken);
    const result = yield call(setStatusAgencies, { status, id });
    if (result) {
      setter(!value);
      if (reRenderParams) {
        const { status: statusAgency, isArchive, alertText } = reRenderParams;
        if (isArchive) {
          toastSuccess(alertText);

          yield put(
            fetchAgencies(statusAgency ? { status: statusAgency } : {}),
          );
        }
      }
    }
  } catch (e) {
    console.log({ e });
  }
}
