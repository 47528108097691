import i18next from 'i18next';
import { FormikErrors } from 'formik';

export type TForm = {
  erid?: string;
};

type TFValidate = (v: TForm) => FormikErrors<TForm>;

export const validate: TFValidate = (values) => {
  const errors: FormikErrors<TForm> = {};

  if (values.erid) {
    const regExp = /^[a-zA-Z0-9]+$/
    if(!regExp.test(values.erid)) {
      errors.erid = i18next.t('errors.err_value')
    }
  }

  return errors;
};