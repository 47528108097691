import { useSelector } from 'react-redux';
import { AppState } from 'redux/rootReducer';
import { CreativeState } from '../../reduser';
import { ResponseTranslation } from '../../../campaigns/types';

export type DataCreativesTranslation = {
  translation: Record<string,ResponseTranslation> | null;
  isError: boolean;
}

export const useDataCreativesTranslation = (): DataCreativesTranslation => {
  const { creativesTranslation } = useSelector<AppState, CreativeState>(
    (state) => state.creativeReducer,
  );

  const translation = creativesTranslation.get('data');
  const { isError } = creativesTranslation.get('error');

  return { translation, isError };
};
