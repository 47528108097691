import { ContractsList } from 'domains/contracts/model/types';
import { TOffersList } from 'domains/offers/model/types';
import { useTranslation } from 'react-i18next';
import { SelectField } from 'types/general';

export function offersOptionsFunc(list: TOffersList | null): SelectField[] {
  const { t } = useTranslation();
  const optionsArr = [
    { label: t(`project_page.forms.not_selected`), value: 'null' },
  ];
  list?.forEach((offer) =>
    optionsArr.push({ label: offer.title, value: String(offer.id) }),
  );
  return optionsArr;
}

export function contractsOptionsFunc(
  list: ContractsList | null,
): SelectField[] {
  const contractsArr: SelectField[] = [];
  list?.forEach((contract) =>
    contractsArr.push({
      label: `№ ${contract.number} • ${contract.ord_title} • ${contract.customerInn} • ${contract.executorInn}`,
      value: String(contract.uuid),
      status: contract.status
    }),
  );
  return contractsArr;
}
