import { useCallback, useMemo, useState } from 'react';
import { Creative, CreativeDetail } from '../../domains/creative/types';
import { TStepLiteral } from '../../domains/creative/creatives/CopyCreativeModal/useCopy';
import { AgenciesList, RAgencies } from '../../domains/agencies/api';
import { TItem } from '../../types/general';
import { RClients } from '../../domains/clients/types';
import { RGetPersonal } from '../../domains/campaign/types';

type Props = {
  clients: RClients[] | null;
  campaigns: RGetPersonal[] | null;
  creatives: Creative[] | null;
  agencies: AgenciesList | null;
  isCampaign?: boolean;
  agenciesLTU: number;
  clientsLTU: number;
  campaignsLTU: number;
  creativesLTU: number;
};

export type ParamsOnClick =
  | { campaign_id: string }
  | { id: string }
  | { partner_id: string }
  | { campaign_xxhash: string };

export const isGardTypeOnClick = (
  param: ParamsOnClick,
): param is { campaign_xxhash: string } => true;

export type General = RClients[] | RGetPersonal[] | Creative[] | AgenciesList;
export type TGeneral = (RClients | RGetPersonal | CreativeDetail | RAgencies)[];

export type GeneralType = RClients | RGetPersonal | Creative;

export const isGardClient = (param: GeneralType): param is RClients => true;
export const isGardCampaign = (param: GeneralType): param is RGetPersonal =>
  true;
export const isGardCreative = (param: GeneralType): param is Creative => true;

export const isGardSearchData = (
  param: TGeneral,
): param is (CreativeDetail | RGetPersonal)[] => true;

export const isGardClients = (param: General): param is RClients[] => true;
export const isGardCampaigns = (param: General): param is RGetPersonal[] =>
  true;

export const isGardAgencies = (param: General): param is AgenciesList => true;

type TFUseTargetS = (p: Props) => {
  data: TGeneral;
  setStep: (v: TStepLiteral) => void;
  step: TStepLiteral;
  isActives: TItem[];
  setIsActives: (v: TItem[]) => void;
  search: string;
  setSearch: (v: string) => void;
  getCheckedCount: () => {
    checkedCount: number;
    allItemsCount: number;
  };
  handlerToggleAll: () => void;
};

const useTargets: TFUseTargetS = ({
  clients,
  campaigns,
  creatives,
  agencies,
  isCampaign,
  creativesLTU,
  campaignsLTU,
  clientsLTU,
  agenciesLTU,
}) => {
  const [search, setSearch] = useState<string>('');
  const [isActives, setIsActives] = useState<TItem[]>([]);
  const [step, setStep] = useState<TStepLiteral>(
    !isCampaign ? 'creatives' : 'campaigns',
  );

  const data = useMemo(() => {
    if (step === 'agencies') {
      return agencies;
    }
    if (step === 'clients') {
      return clients;
    }

    if (step === 'campaigns') {
      return campaigns;
    }

    if (step === 'creatives') {
      return creatives;
    }

    return [];
  }, [agenciesLTU, creativesLTU, campaignsLTU, clientsLTU, step]);

  const searchData = useMemo<TGeneral>(() => {
    if (data && !data.length) return [];

    let newData: TGeneral = (data as TGeneral) || [];

    if (step === 'clients' && data && isGardClients(data)) {
      newData = data.filter(
        (e) => e.status === 'STOPPED' || e.status === 'LAUNCHED',
      );
    }
    if (step === 'campaigns' && data && isGardCampaigns(data)) {
      newData = data.filter(
        (e) => e.status === 'STOPPED' || e.status === 'LAUNCHED',
      );
    }
    return newData.filter(({ title }) =>
      title.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
    );
  }, [search, data, step]);

  const handlerToggleAll = () => {
    if (isGardSearchData(searchData)) {
      if (isActives.length) {
        const isActivesXxhash = isActives.map((item) => item.xxhash);
        if (
          searchData.some((item) => isActivesXxhash.includes(item.xxhash || ''))
        ) {
          setIsActives(
            isActives.filter(
              (item) =>
                !searchData
                  .map((itemData) =>
                    isGardCampaign(itemData) || isGardCreative(itemData)
                      ? itemData.xxhash
                      : item,
                  )
                  .includes(item.xxhash),
            ),
          );
          return;
        }
        setIsActives((prev) => [
          ...prev,
          ...searchData.map((item) => ({
            xxhash: item.xxhash || '',
            title: item.title,
          })),
        ]);
        return;
      }
      setIsActives(
        searchData.map((item) => ({
          xxhash: item.xxhash || '',
          title: item.title,
        })),
      );
    }
  };

  const getCheckedCount: ReturnType<TFUseTargetS>['getCheckedCount'] =
    useCallback(
      () => ({
        checkedCount: isActives.filter((item) =>
          searchData
            .map((itemData) =>
              isGardCampaign(itemData) || isGardCreative(itemData)
                ? itemData.xxhash
                : item,
            )
            .includes(item.xxhash),
        ).length,
        allItemsCount: searchData.length || 0,
      }),
      [isActives, searchData],
    );

  return {
    data: searchData,
    setStep,
    step,
    isActives,
    setIsActives,
    search,
    setSearch,
    getCheckedCount,
    handlerToggleAll,
  };
};

export default useTargets;
