import {
  useModerationInfo,
  useModerationResultInfo,
} from '../../../model/selectors';

type TFUseModeration = (p: string) => {
  width: number | '';
  height: number | '';
  moderationResult: ReturnType<typeof useModerationResultInfo>['data'];
  moderation: ReturnType<typeof useModerationInfo>['data'];
};

export const useModeration: TFUseModeration = (size) => {
  const { data: moderationResult } = useModerationResultInfo();
  const { data: moderation } = useModerationInfo();

  const sizeAr = size && size.split('x');

  return {
    width: size && Number(sizeAr[0]),
    height: size && Number(sizeAr[1]),
    moderationResult,
    moderation,
  };
};
