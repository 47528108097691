export const findEridMetaTeg = (str: string | null): string | null => {
  if (!str) return null;
  const reg =
    /<.*?content=["|'](.*?)["|'].*(ad\.erid)[^>]?>|<[^>]*(ad\.erid).*?content=["|'](.*?)["|']*?>/gm;
  const contentsData = reg.exec(str);
  if (contentsData) {
    return contentsData[4];
  }
  return null;
};
