import { FormikErrors } from 'formik';
import { TFormikCreativeAdd } from 'domains/creative/types';
import i18n from '../../../../i18n';

type TFValidate = (p: TFormikCreativeAdd) => FormikErrors<TFormikCreativeAdd>;
type TFCheckSize = (p?: string) => boolean;

export const checkSizes: TFCheckSize = (size) =>
  size ? !size.includes('0x0') : false;

export const validate: TFValidate = (values) => {
  const errors: FormikErrors<TFormikCreativeAdd> = {};
  const url =
    /^((https?:\/\/|www\.))[a-z0-9а-я]+([\-_.]{1}[a-z0-9а-я]+)*\.[a-zа-я]{2,20}(:[0-9]{1,5})?(\/?.*)?$/m;
  const macrosReg = /^(\{\w+\}\s?)+$/gi;
  const macrosReg2 = /^(\{\w+\}\s?)+$/gi;

  if (!(values.title.length > 0)) {
    errors.title = i18n.t('creative.creative_requare');
  }

  /*   if (isGardHTMLType(values.data) && isGardHTMLTypeError(errors.data)) {
    if (values.data.html_code && values.data.html_code.length > 4096) {
      errors.data.html_code = i18n.t('errors.limitsym');
    }
  } */
  if (
    values.type === 'HTML' ||
    values.type === 'VIDEO_VAST' ||
    values.type === 'VIDEO_INLINE' ||
    /* Под вопросом COMPANION */
    values.type === 'COMPANION'
  ) {

    if (!checkSizes(values.size)) {
      if (Number(values.width) > 10000) {
        errors.width = `${i18n.t('errors.limit_symbol.start')}10000${i18n.t(
          'errors.limit_symbol.pixel',
        )}`;
      }
      if (Number(values.height) > 10000) {
        errors.height = `${i18n.t('errors.limit_symbol.start')}10000${i18n.t(
          'errors.limit_symbol.pixel',
        )}`;
      }
      if (Number(values.width) === 0 && values.size === '0x0') {
        errors.width = i18n.t('errors.err45');
      }
      if (Number(values.height) === 0 && values.size === '0x0') {
        errors.height = i18n.t('errors.err45');
      }
    } else if (values.size) {
      const size = values.size.split('x');

      if (Number(size[0]) > 10000 || Number(size[1]) > 10000) {
        errors.size = `${i18n.t('errors.limit_symbol.start')}10000${i18n.t(
          'errors.limit_symbol.pixel',
        )}`;
      }
    }
  }

  if (values.erid) {
    const regExp = /^[a-zA-Z0-9]+$/;
    if (!regExp.test(values.erid)) {
      errors.erid = i18n.t('errors.err_value');
    }
  }

  if (values.link && !url.test(values.link)) {
    if (!macrosReg.test(values.link)) {
      errors.link = i18n.t('errors.err_http_lorem');
    }
  }
  if (values.link && values.link.length >= 1024) {
    errors.link = i18n.t('errors.limitsym');
  }

  if (values.extension_link && !url.test(values.extension_link)) {
    if (!macrosReg2.test(values.extension_link)) {
      errors.extension_link = i18n.t('errors.err_http_lorem');
    }
  }
  if (values.extension_link && values.extension_link.length >= 1024) {
    errors.extension_link = i18n.t('errors.limitsym');
  }

  if (values.adomain && values.adomain.length >= 1024) {
    errors.adomain = i18n.t('errors.limitsym');
  }

  if (values.pixel && values.pixel.length > 0 && !url.test(values.pixel)) {
    errors.pixel = i18n.t('errors.err_http_lorem2');
  }

  if (values.pixel && values.pixel.length > 0 && values.pixel.length >= 1024) {
    errors.pixel = i18n.t('errors.limitsym');
  }

  if (
    values.data.show_html &&
    values.data.show_html.length > 0 &&
    !url.test(values.data.show_html)
  ) {
    if (!errors.data) {
      errors.data = {};
    }
    errors.data.show_html = i18n.t('errors.err_http_lorem2');
  }

  if (
    values.data.show_html &&
    values.data.show_html.length > 0 &&
    values.data.show_html.length >= 1024
  ) {
    if (!errors.data) {
      errors.data = {};
    }
    errors.data.show_html = i18n.t('errors.limitsym');
  }

  if (
    values.click_audit_pixel &&
    values.click_audit_pixel.length > 0 &&
    !url.test(values.click_audit_pixel)
  ) {
    errors.click_audit_pixel = i18n.t('errors.err_http_lorem2');
  }

  if (
    values.click_audit_pixel &&
    values.click_audit_pixel.length > 0 &&
    values.click_audit_pixel.length >= 1024
  ) {
    errors.click_audit_pixel = i18n.t('errors.limitsym');
  }

  if (values.iurl && values.iurl.length > 0 && !url.test(values.iurl)) {
    errors.iurl = i18n.t('errors.err_http_lorem2');
  }

  if (values.iurl && values.iurl.length > 0 && values.iurl.length >= 1024) {
    errors.iurl = i18n.t('errors.limitsym');
  }

  if (
    values.pixel_additional &&
    values.pixel_additional.length > 0 &&
    !url.test(values.pixel_additional)
  ) {
    errors.pixel_additional = i18n.t('errors.err_http_lorem2');
  }

  if (
    values.pixel_additional &&
    values.pixel_additional.length > 0 &&
    values.pixel_additional.length >= 1024
  ) {
    errors.pixel_additional = i18n.t('errors.limitsym');
  }

  if (
    values.algorithm_cpc.max &&
    values.algorithm_cpc.min &&
    values.algorithm_cpc.max < values.algorithm_cpc.min
  ) {
    errors.algorithm_cpc = { min: 'true' };
  }

  return errors;
};
