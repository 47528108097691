import { useCampainExperimentalInfo } from 'domains/campaign/model/hooks/useCampaingExperimentalInfo';
import { RGetPersonal } from 'domains/campaign/types';
import React, { FC, useMemo } from 'react';
import { createConfiValues } from 'utils/createConfigValues';
import { getCurrentVariableField } from 'utils/getCurrentVariableField';
import Optimisation from './Optimisation';

interface Props {
  campaign: RGetPersonal;
}

const OptimisationWidget: FC<Props> = ({ campaign }) => {
  const { experimentalInfo } = useCampainExperimentalInfo();

  const optimus = useMemo(
    () => getCurrentVariableField(experimentalInfo, 'optimus'),
    [experimentalInfo],
  );

  const optimus_hb = useMemo(
    () => getCurrentVariableField(experimentalInfo, 'optimus_hb'),
    [experimentalInfo],
  );

  const configValues = useMemo(
    () =>
      createConfiValues(experimentalInfo, campaign?.config_values || {}, [
        'optimus',
        'optimus_hb',
      ]),
    [experimentalInfo, campaign?.config_values],
  );

  return (
    <>
      {campaign && (optimus || optimus_hb) && (
        <Optimisation
          optimus={optimus}
          optimus_hb={optimus_hb}
          configValues={configValues}
          xxhash={campaign.xxhash}
        />
      )}
    </>
  );
};

export default OptimisationWidget;
