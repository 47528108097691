import { ChangeEvent, RefObject, useState } from 'react';
import copy from 'copy-to-clipboard';
import { FieldProps } from 'formik';

type TFUseInputProps = {
  inputValue: string;
  inputRef: RefObject<HTMLInputElement>;
  setInputValue: FieldProps['form']['setFieldValue'];
  name: string;
};

type TFUseInput = (param: TFUseInputProps) => {
  handlerCopyValue: () => void;
  handlerChangeInput: (event: ChangeEvent<HTMLInputElement>) => void;
  handlerClearInput: () => void;
  copied: boolean;
};

export const useInput: TFUseInput = ({
  inputValue,
  setInputValue,
  inputRef,
  name,
}) => {
  const [copied, setCopied] = useState(false);

  const handlerChangeInput: ReturnType<TFUseInput>['handlerChangeInput'] = ({
    target,
  }) => {
    setInputValue(name, target.value);
  };

  const handlerClearInput = () => {
    setInputValue(name, '');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handlerCopyValue = () => {
    if (inputValue) {
      copy(`${inputValue}`);
    }
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return {
    copied,
    handlerCopyValue,
    inputValue,
    handlerChangeInput,
    handlerClearInput,
  };
};
