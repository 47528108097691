import { getPreviewCreative } from 'domains/creative/api';
import { CreativeDetail, IBannerData } from 'domains/creative/types';
import { useEffect, useState } from 'react';

type TProps = Pick<CreativeDetail, 'type' | 'xxhash'> & {
  needData: boolean;
};

type TReturnVal = {
  bannerData?: IBannerData;
  loading: boolean;
  error: boolean;
  showPreview: () => void;
  handleOnLoad: () => void;
};

export const useBannerData = ({
  type,
  needData,
  xxhash,
}: TProps): TReturnVal => {
  const [loading, setLoading] = useState(false);
  const [bannerData, setBannerData] = useState<IBannerData>();
  const [error, setError] = useState(false);

  const handleOnLoad = () => {
    setLoading(false);
  };

  const getBanner = async () => {
    try {
      setLoading(true);
      const result = await getPreviewCreative({ xxhash });
      if (result) {
        setBannerData(result);
        if (type !== 'HTML') {
          setLoading(false);
        }
      }
    } catch (err) {
      console.error({ err });
      setLoading(false);
      setError(true);
    }
  };

  const showPreview = () => {
    if (type === 'HTML') {
      getBanner();
    }
  };

  useEffect(() => {
    if (type === 'HTML') {
      if(needData) {
        getBanner();
      } else {
        setBannerData(undefined)
      }
    }
  }, [needData]);

  return {
    bannerData,
    loading,
    error,
    showPreview,
    handleOnLoad,
  };
};
