import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { isShowMCreate } from '../reduser';
/** возврощает функцию по вызову которой открываается/закрывается модальное окно создать клиента */
export function useIsShowMCreate(v: boolean): () => void {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(isShowMCreate(v));
  }, []);
}
