import React, { FC } from 'react';
import Card from 'components/UI/Card';
import Load from 'components/UI/Load';
import css from '../styles.module.scss';

interface Props {
  mode: 'create' | 'edit';
}

const LoaderInfo: FC<Props> = ({ mode }) => (
  <Card isUseAccess className={css.card} noHover>
    <div className={css.info}>
      <Load
        style={{
          width: '100%',
          height: '119px',
        }}
      />
      {mode === 'edit' && (
        <>
          <Load
            style={{
              width: '100%',
              height: '19px',
            }}
          />
          <Load
            style={{
              width: '100%',
              height: '22px',
            }}
          />
        </>
      )}
    </div>
  </Card>
);

export default LoaderInfo;
