type TDataLogo = {
  id: string;
  icon: string;
};
type TSetLogoInTheProject = (id: string, icon: string) => void;

/**
 * Утилита для установления логотипа проекта при авторизации
 * @xxhash - id типа партнера
 * icon - url для картинки клиента или организации
 *
 *  */
export const setLogoInTheProject: TSetLogoInTheProject = (id, icon) => {
  const data = { id, icon };
  localStorage.setItem('logoProject', JSON.stringify(data));
};

export const getLogoInTheProject = (): string | null => {
  const data = localStorage.getItem('logoProject');
  if (data) {
    const { icon } = JSON.parse(data) as TDataLogo;
    return icon;
  }
  return null;
};
