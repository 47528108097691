import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Card from 'components/UI/Card';
import CardList from 'components/UI/CardList';
import { RouteComponentProps } from 'react-router-dom';
import CreateCampaignForm from 'domains/campaign/ui/Main/CreateCampaign';
import css from './styles.module.scss';

const Settings: FC<RouteComponentProps> = () => {
  const { t } = useTranslation();

  return (
    <div className={css.settings}>
      <div className={cn(css.container, 'container')}>
        <CreateCampaignForm />
        <CardList>
          <Card noHover style={{ padding: '28px 22px' }} className={css.widget}>
            <p>{t('campaigns_page.create_campaign.create_text')}</p>
            <p>
              {t('campaigns_page.create_campaign.create_text2')}
              <a
                href="https://terratraf.readme.io/docs/td-general"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('campaigns_page.create_campaign.create_text_link')}
              </a>
            </p>
          </Card>
        </CardList>
      </div>
    </div>
  );
};

export default Settings;
