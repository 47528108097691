import Card from 'components/UI/Card';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Input from 'components/UI/Input/input';
import { FormikProps } from 'formik';
import { TFormikSettigns } from 'domains/campaign/types';
import CollapseCard from 'components/UI/CollapseCard';
import css from '../styles.module.scss';

interface IExtendedReportProp {
  formik: FormikProps<TFormikSettigns>;
}

const ExtendedReport: FC<IExtendedReportProp> = ({ formik }) => {
  const { t } = useTranslation();
  return (
    <Card noHover className={css.card}>
      <CollapseCard
        closedStart
        title={
          <h3 className={css.title}>
            {t('campaigns_page.campaign_settings.additional.extend_rep')}
          </h3>
        }
      >
        <div className={css.form}>
          <Input
            label={t('campaigns_page.campaign_settings.additional.data')}
            isTextarea
            inputAttributes={{
              name: 'extended_report',
              style: { minHeight: 246 },
              placeholder: t(
                'campaigns_page.campaign_settings.additional.enter_date',
              ),
              onChange: formik.handleChange,
              value: formik.values.extended_report,
            }}
            tooltip={{
              text: t('campaigns_page.campaign_settings.additional.data_based'),
            }}
          />
          <p className={css.info}>
            {t('campaigns_page.campaign_settings.additional.use_special')}
          </p>
        </div>
      </CollapseCard>
    </Card>
  );
};

export default ExtendedReport;
