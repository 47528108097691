import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  fetchCampaignPreTargeting,
  setPreTargetingRequest,
} from '../model/actions';
import { RPreTargeting } from '../types';
import { useDispatchApp } from '../../../redux/rootSelectors';
import { useCampaignInfo, useCampaignPreTargeting } from '../model/selectors';

type TFUseCampaignAdditional = () => {
  error: ReturnType<typeof useCampaignInfo>['error'];
  isLoading: ReturnType<typeof useCampaignInfo>['isLoading'];
  campaign: ReturnType<typeof useCampaignInfo>['data'];
  headerTitle: string | null;
  title: string;
  preTargeting: ReturnType<typeof useCampaignPreTargeting>['data'];
  isLoadingPreTarget: ReturnType<typeof useCampaignPreTargeting>['isLoading'];
  handlerSetPretargeting: (data: RPreTargeting) => void;
};

export const useCampaignAdditional: TFUseCampaignAdditional = () => {
  const { t } = useTranslation();
  const dispatch = useDispatchApp();
  const {
    data: campaign,
    isLoading,
    error,
  } = useCampaignInfo();

  const { data: preTargeting, isLoading: isLoadingPreTarget } =
    useCampaignPreTargeting();

  useEffect(() => {
    if (campaign) {
      dispatch(fetchCampaignPreTargeting({ xxhash: campaign.xxhash }));
    }
  }, [campaign?.xxhash]);

  const handlerSetPretargeting: ReturnType<TFUseCampaignAdditional>['handlerSetPretargeting'] =
    useCallback(
      (data) => {
        if (campaign) {
          dispatch(
            setPreTargetingRequest({
              xxhash: campaign.xxhash,
              data,
            }),
          );
        }
      },
      [campaign],
    );

  const title = campaign
    ? campaign.title
    : t('campaigns_page.campaign_settings.black_menu.Settings_aditional');

  const headerTitle =
    campaign &&
    `${campaign.internal_id ? `[${campaign.internal_id}] ` : ''}${
      campaign.title
    }`;

  return {
    error,
    isLoading,
    campaign,
    headerTitle,
    title,
    preTargeting,
    isLoadingPreTarget,
    handlerSetPretargeting,
  };
};
