import React, { useRef, FC, ReactNode, useEffect, useState } from 'react';
import cn from 'classnames';
import { ScrollSyncNode } from 'scroll-sync-react';
import css from './styles.module.scss';

type Props = {
  expand: number;
  children: ReactNode;
  header?: ReactNode;
  sizeItems?: number;
};

const Table: FC<Props> = ({ header, children, expand, sizeItems }) => {
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const [isHorisontHidden, setIsHorisontHidden] = useState<boolean>(false);
  const bodyRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      bodyRef.current &&
      bodyRef.current?.scrollHeight >= window.innerHeight - 350
    ) {
      setIsHidden(false);
    } else setIsHidden(true);

    if (
      sizeItems &&
      bodyRef.current &&
      sizeItems * 140 < bodyRef.current.scrollWidth - 240
    ) {
      setIsHorisontHidden(true);
    } else {
      setIsHorisontHidden(false);
    }
  }, [bodyRef, expand]);

  return (
    <div className={css.table}>
      {header}
      <div
        className={css.body}
        style={{ paddingLeft: `${expand}px` }}
        ref={bodyRef}
      >
        <ScrollSyncNode>
          <div className={cn(css.content, { isHidden })}>{children}</div>
        </ScrollSyncNode>
      </div>
      <div
        className={cn(css.scrollbar, { isHidden: isHorisontHidden })}
        style={{ paddingLeft: `${expand}px` }}
      >
        <ScrollSyncNode>
          <div className={css.wrapper}>
            <div className={css.bar} />
          </div>
        </ScrollSyncNode>
      </div>
    </div>
  );
};

export default Table;
