/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import css from './styles.module.scss';

interface IThProps {
  empty?: boolean;
  children?: string | ReactNode;
  name?: boolean;
  unityRows?: number;
  title?: string;
}

const Th: FC<IThProps> = ({ empty, children, name, unityRows = 1, title }) => {
  if (empty) {
    return <th />;
  }

  return (
    <th
      className={cn({
        [css.first]: name,
      })}
      rowSpan={unityRows}
      title={title}
    >
      {children}
    </th>
  );
};

export default Th;
