import React from 'react';

type TFDrawSearchText = (text: string, arg?: string) => string | JSX.Element;

export const drawSearchText: TFDrawSearchText = (text, arg) => {
  if (!arg || !text.toLowerCase().includes(arg.toLowerCase())) {
    return text;
  }
  const start = text.toLowerCase().indexOf(arg.toLowerCase());
  const end = start + arg.length;
  return (
    <>
      {text.slice(0, start)}
      <b>{text.slice(start, end)}</b>
      {text.slice(end, text.length)}
    </>
  );
};
