import { useDispatch } from 'react-redux';
import { useGroupList } from '../../model/selectors';
import { setGroupList } from '../../reduser';

interface removeCustomErrorsReturn {
  removeCustomErrors: () => void;
}

export function useRemoveCustomErrors(): removeCustomErrorsReturn {
  const dispatch = useDispatch();
  let fetchedData = useGroupList();

  const removeCustomErrors = () => {
    fetchedData = fetchedData.set('error', {
      isError: false,
      message: '',
      code: 0,
      fields: [],
    });
    dispatch(setGroupList(fetchedData));
  };

  return { removeCustomErrors };
}
