import request from '../../../lib/helpers/requestApi';
import {
  ContractItem,
  CreateContractParams,
  GetContractParams,
  UpdateContractParams,
  getStatusResponse,
} from './types';

export const getContractAPI = request<ContractItem, GetContractParams>({
  method: 'contract.get',
});

export const createContractAPI = request<boolean, CreateContractParams>({
  method: 'contract.create',
});

export const updateContractAPI = request<boolean, UpdateContractParams>({
  method: 'contract.update',
});

export const sendErirAPI = request<boolean, GetContractParams>({
  method: 'contract.sendErir',
});

export const checkErirStatusAPI = request<getStatusResponse, GetContractParams>({
  method: 'contract.getStatus',
});

