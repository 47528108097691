import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastUtilsSuccessParams } from 'types/general';
import { FetchedData, genFetchedData } from '../../redux/fetchedData';
import {
  AgenciesList,
  ParamAgencies,
  Status,
  ParamSetStatusAgencies,
} from './api';

export type RerenderTypeAgencies = {
  status: Status | null;
  alertText?: string | ToastUtilsSuccessParams;
  isArchive?: boolean;
};

export type AgenciesState = {
  tempAgencies: FetchedData<AgenciesList>;
};
type ParamSetStatusAgenciesSaga = ParamSetStatusAgencies & {
  /** dispatch обновления локального состояния */
  setter: (param: boolean) => void;
  /** Начальное состояние */
  value: boolean;
  /** Параметры для перерисовки списка агенств */
  reRenderParams?: RerenderTypeAgencies;
};

const initialState: AgenciesState = {
  tempAgencies: genFetchedData<AgenciesList>(null),
};

const AgenciesSlice = createSlice({
  name: 'Agencies',
  initialState,
  reducers: {
    setTempAgencies(
      state,
      action: PayloadAction<AgenciesState['tempAgencies']>,
    ) {
      state.tempAgencies = action.payload;
    },
  },
});

export const fetchAgencies = createAction<ParamAgencies>(
  'Agencies/fetchAgencies',
);
export const setStatusAgenciesAction = createAction<ParamSetStatusAgenciesSaga>(
  'Agencies/setStatusAgency',
);
export const { setTempAgencies } = AgenciesSlice.actions;
export const agenciesReducer = AgenciesSlice.reducer;
