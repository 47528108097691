import request from '../../lib/helpers/requestApi';
import { ParamDeleteItem, ParamGetList, TGetListData } from './types';

export const getDomainsList = request<TGetListData, ParamGetList>({
  method: 'dmpgBlList.getList',
});

export const changeStatusFields = request<boolean, ParamDeleteItem>({
  method: 'dmpgBlList.changeStatusFields',
});
