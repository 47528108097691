import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { StorageState } from './types';

export const initialState: StorageState = {
  usersTabs: 'allUsers',
  agenciesTabs: 'LAUNCHED',
  clientsTabs: 'LAUNCHED',
  campaignsTabs: 'LAUNCHED',
  activityUsersDate: {
    period: {
      from: moment(new Date()).format('YYYY-MM-DD'),
      to: moment(new Date()).format('YYYY-MM-DD'),
    },
    type: 'day',
  },
  isShowTargetStatistics: true,
  collapseWrappers: [],
};

const storageSlice = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    setUsersTabs(state, action: PayloadAction<StorageState['usersTabs']>) {
      state.usersTabs = action.payload;
    },
    setAgenciesTabs(
      state,
      action: PayloadAction<StorageState['agenciesTabs']>,
    ) {
      state.agenciesTabs = action.payload;
    },
    setClientsTabs(state, action: PayloadAction<StorageState['clientsTabs']>) {
      state.clientsTabs = action.payload;
    },
    setCampaignsTabs(
      state,
      action: PayloadAction<StorageState['campaignsTabs']>,
    ) {
      state.campaignsTabs = action.payload;
    },
    setStorageActivityUsersDate(
      state,
      action: PayloadAction<StorageState['activityUsersDate']>,
    ) {
      state.activityUsersDate = action.payload;
    },
    setIsShowTargetStatistics(
      state,
      action: PayloadAction<StorageState['isShowTargetStatistics']>,
    ) {
      state.isShowTargetStatistics = action.payload;
    },
    setCollapseWrappers(
      state,
      actions: PayloadAction<StorageState['collapseWrappers']>,
    ) {
      state.collapseWrappers = actions.payload;
    },
  },
});

export const {
  setUsersTabs,
  setCampaignsTabs,
  setClientsTabs,
  setAgenciesTabs,
  setStorageActivityUsersDate,
  setIsShowTargetStatistics,
  setCollapseWrappers,
} = storageSlice.actions;
export const storageReducer = storageSlice.reducer;
