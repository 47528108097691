import React, { FC, ReactNode } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import cn from 'classnames';
import { FiTrash2 } from 'react-icons/fi';
import ErrorText from 'components/UI/ErrorText';
import css from './styles.module.scss';

type TSelectStyleProps = {
  openStyle: () => void;
  text: string;
  label?: string;
  url: string;
  logoDefault: ReactNode;
  handlerClear?: () => void;
  error?: string;
};

const SelectStyle: FC<TSelectStyleProps> = ({
  openStyle,
  text,
  logoDefault,
  url,
  label,
  handlerClear,
  error,
}) => (
  <div
    className={cn(css.togglePanel, {
      loaded: !!url,
    })}
  >
    {label && <div className={css.titlePanel}>{label}</div>}
    <div className={css.button} onClick={openStyle}>
      <div className={css.buttonIcon}>
        {url ? <img src={url} alt="" /> : logoDefault}
      </div>
      <span className={css.buttonText}>{text}</span>
      <div className={css.buttonArrow}>
        {url ? (
          <FiTrash2
            size={20}
            color="#025DFF"
            onClick={(event) => {
              event.stopPropagation();
              if (handlerClear) {
                handlerClear();
              }
            }}
          />
        ) : (
          <MdKeyboardArrowRight size={24} color="#222222" />
        )}
      </div>
    </div>
    {error && <ErrorText text={error} />}
  </div>
);

export default SelectStyle;
