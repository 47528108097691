import React, { useRef, useEffect, MutableRefObject } from 'react';
import useDropDown from 'hooks/useDropDown';
import cn from 'classnames';
import { FiChevronDown } from 'react-icons/fi';
import { checkedLocalization } from 'utils/checkedLocalization';
import css from './styles.module.scss';

interface Props {
  className?: string;
  type: string;
  view?: number;
  selected: boolean;
  onChange: (val: boolean) => void;
  onSetActive: (
    val: boolean,
    ref: MutableRefObject<HTMLDivElement | null>,
  ) => void;
  disabled: boolean;
  is_wo_available: boolean;
  showHintEvent: () => void;
}

const ExceptionFilter: React.FC<Props> = ({
  className,
  type,
  view,
  selected,
  onChange,
  onSetActive,
  disabled,
  is_wo_available,
  showHintEvent,
}) => {
  const dropdownMenu = useRef<HTMLDivElement | null>(null);
  const { toggle, isOpened, toggleOff } = useDropDown(dropdownMenu);
  const onSelected = (val: boolean) => {
    onChange(val);
    toggleOff();
  };

  useEffect(() => {
    onSetActive(isOpened, dropdownMenu);
  }, [isOpened]);

  if (!is_wo_available) {
    return null;
  }

  return (
    <div
      className={cn(css.filter, className, {
        isToggle: toggle,
        isOpened,
        isDisabled: disabled || !is_wo_available,
      })}
      ref={dropdownMenu}
      onClick={showHintEvent}
    >
      <div className={cn(css.active)} onClick={isOpened ? toggleOff : toggle}>
        <span>
          {(view === 2 || 4) &&
            !selected &&
            checkedLocalization(
              `targetings.${type}.t1`,
              `targetings.noselected`,
            )}
          {(view === 2 || 4) &&
            selected &&
            checkedLocalization(`targetings.${type}.t2`, `targetings.selected`)}
        </span>
        {!disabled && <FiChevronDown size={22} color="#222" />}
      </div>

      <div className={cn(css.list, { isOpened })}>
        <div
          className={cn(css.item, { isActive: !selected })}
          onClick={() => onSelected(false)}
        >
          <div className={css.point} />
          <div className={css.description}>
            <div className={css.title}>
              {checkedLocalization(
                `targetings.${type}.t1`,
                `targetings.noselected`,
              )}
            </div>
            <div className={css.hint}>
              {checkedLocalization(
                `targetings.${type}.t3`,
                `targetings.noselectedprompt`,
              )}
            </div>
          </div>
        </div>

        <div
          className={cn(css.item, { isActive: selected })}
          onClick={() => onSelected(true)}
        >
          <div className={css.point} />
          <div className={css.description}>
            <div className={css.title}>
              {checkedLocalization(
                `targetings.${type}.t2`,
                `targetings.selected`,
              )}
            </div>
            <div className={css.hint}>
              {checkedLocalization(
                `targetings.${type}.t4`,
                `targetings.selectedprompt`,
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExceptionFilter;
