import { useCallback, useEffect, useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useParams } from 'react-router';
import {
  initialState,
  setAgencyInfo,
  fetchAgencyInfo,
  delEditAgency,
  editAgency,
} from '../reducer';
import { useClearLinkImage } from '../../modals/linkToImage/useClearLinkImage';
import {
  AgencyInfo,
  TEditBase,
} from '../types';
import { useDispatchApp } from '../../../redux/rootSelectors';
import {
  useAgencyInfo,
  useAgencyIsShowMChangeInfo,
} from '../model/selectors';

type TFUseChangeAgencyModal = () => {
  formik: FormikProps<TEditBase>;
  onDelLoadImg: () => void;
  isLoadData: boolean;
  AgencyData: AgencyInfo | null;
};

export const useEditAgencyMainSettings: TFUseChangeAgencyModal = () => {
  const { t } = useTranslation();

  const clearImage = useClearLinkImage();
  const dispatch = useDispatchApp();
  const [isLoadData, setLoadData] = useState<boolean>(true);

  const { id } = useParams<{ id: string }>();

  const isShowMChange = useAgencyIsShowMChangeInfo();
  const {
    data: AgencyData,
    isLoading: isLoadingAgency,
    LTU: LTUAgency,
  } = useAgencyInfo();

  const formik = useFormik<TEditBase>({
    initialValues: {
      id: '',
      title: '',
      site: '',
      check_ERID: false,
      remove_icon: false,
    },
    onSubmit: (values) => {
      dispatch(editAgency(values));
    },
    validationSchema: Yup.object({
      title: Yup.string().required(t('clients_page.client_create.hint1')),
    }),
  });
  const closeReset = useCallback(() => {
    formik.resetForm();
    clearImage();
    dispatch(setAgencyInfo(initialState.agencyInfo));
    dispatch(delEditAgency());
  }, [formik]);

  const onDelLoadImg = useCallback(() => {
    formik.setFieldValue('remove_icon', true);
  }, [formik]);

  useEffect(() => {
    if (AgencyData === null) return;
    formik.setValues({
      id: AgencyData.id,
      title: AgencyData.title,
      site: AgencyData.site,
      remove_icon: AgencyData.remove_icon,
      check_ERID: !!AgencyData.check_ERID.AGENCY,
    });
  }, [LTUAgency, isShowMChange]);

  useEffect(() => {
    if (LTUAgency < 1) {
      setLoadData(true);
    }
    setLoadData(isLoadingAgency);
  }, [isLoadingAgency]);

  useEffect(() => {
    if (id === '') return;
    dispatch(fetchAgencyInfo({ id }));
  }, [id]);

  useEffect(
    () => () => {
      closeReset();
    },
    [],
  );

  return {
    formik,
    onDelLoadImg,
    AgencyData,
    isLoadData,
  };
};
