import useDropDown from "hooks/useDropDown";
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";

type Clicked = Event | SyntheticEvent;

type TUseItemBidType = {
    dropdownMenu: React.MutableRefObject<HTMLDivElement | null>,
    isOpened: boolean,
    toggleOff: () => void,
    toggle: (e: Clicked) => void,
    calcOffsetTop: () => void,
    dropdownMenuList: React.MutableRefObject<HTMLDivElement | null>,
    offsetLeft: number,
    correctionPosition: number,
    offsetTop: number,
}

export const useItemBidType = (): TUseItemBidType => {
    const dropdownMenu = useRef<HTMLDivElement | null>(null);
    const { toggle, isOpened, toggleOff } = useDropDown(dropdownMenu);

    const dropdownMenuList = useRef<HTMLDivElement | null>(null);
    const bodyRect = document.body.getBoundingClientRect();
    const positionListLeft = dropdownMenu.current?.getBoundingClientRect().right;
    const offsetLeft = positionListLeft ? positionListLeft - bodyRect.left : 0;
    const [offsetTop, setOffsetTop] = useState<number>(0);
    const [correctionPosition, setcCorrectionPosition] = useState<number>(0);

    const calcOffsetTop = useCallback(() => {
        const positionListTop =
            dropdownMenu.current?.getBoundingClientRect().bottom;
        setOffsetTop(positionListTop || 0);
    }, []);

    useEffect(() => {
        setcCorrectionPosition(dropdownMenuList.current?.offsetWidth || 0);
    }, [isOpened]);

    useEffect(() => {
        document.addEventListener('scroll', toggleOff);

        return () => document.removeEventListener('scroll', toggleOff);
    }, []);

    return {
        dropdownMenu,
        isOpened,
        toggleOff,
        toggle,
        calcOffsetTop,
        dropdownMenuList,
        offsetLeft,
        correctionPosition,
        offsetTop,
    }
}