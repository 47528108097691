import moment from 'moment';
import i18next from 'i18next';
import { Column } from 'domains/campaigns/Reports/model/useGetColumns';
import { TItemIndx, TNamingItem, TReportItem } from '../../types';
import { chartColors } from './chartColors';
import toLocaleStringSupporte from '../../../../utils/toLocaleStringSupporte';

interface Params {
  priceType: string;
  typeReport: TReportItem['type'] | null;
  isGaSessions?: boolean;
  total: Record<string, number> | null;
  currency?: string;
  columnsDict?: Map<Column[keyof Column], keyof Column>;
}

/** функция генерирует имена Item
 * @priceType - тип цены кампании
 * @typeReport - тип отчета
 * @isGaSessions - сессия
 * @total - total для отчета
 * @currency - значок валюты
 * */
export function getGenerateNamingItem({
  priceType,
  typeReport,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isGaSessions,
  total,
  currency,
  columnsDict,
}: Params): TNamingItem {
  const price = priceType ? priceType.toUpperCase() : 'price';

  const allColumns = total
    ? Object.keys(total).filter((item) => item !== 'xxhash' && item !== 'day')
    : [];

  const dictItems = (v: string): string => {
    const obj: Record<string, string> = {
      actions: i18next.t('common.reports.general.actions'),
      show: i18next.t('common.reports.general.t1'),
      click: i18next.t('common.reports.general.t3'),
      ctr: 'CTR %',
      cpc: `CPC, ${currency}`,
      cpm: `CPM, ${currency}`,
      price,
      spent: `${i18next.t('common.reports.general.t4')} ${currency}`,
      spent_1: `${i18next.t('common.reports.general.t5')}traffic (82,5%)`,
      spent_2: `${i18next.t('common.reports.general.t5')}tech fee (10%)`,
      spent_3: `${i18next.t('common.reports.general.t5')}data cost (7,5%)`,
      price_1: `${price}: traffic (82,5%)`,
      price_2: `${price}: tech fee (10%)`,
      price_3: `${price}: data cost (7,5%)`,
      minus_bid: 'Bid Sum',
      bid_rate_r: 'BidRateR',
      bid_vertica: 'Bid',
      lead: 'Installs',
    };
    if (obj[v]) {
      return obj[v];
    }
    if (columnsDict && columnsDict.has(v)) {
      return columnsDict.get(v) || ''
    }
    return v;
  };

  const namingItem: TNamingItem = new Map();
  if (typeReport === 'day' || typeReport === 'day_techfee') {
    namingItem.set('date', {
      title: i18next.t('common.reports.general.t2'),
      isIndex: true,
      noChart: true,
      formatCell: (
        item: Record<string, string | number | Date | TItemIndx[]>,
      ) => {
        if (typeof item === 'undefined') {
          return '_';
        }
        if ('date' in item) {
          return moment(item.date as string).format('DD.MM.YYYY');
        }
        return '';
      },
    });
  }
  if (typeReport === 'geo_city' || typeReport === 'shownum') {
    namingItem.set('main_field', {
      title:
        typeReport === 'geo_city'
          ? i18next.t('common.reports.general.t6')
          : i18next.t('common.reports.general.t7'),
      isIndex: true,
      noChart: true,
    });
  }
  if (total && Object.keys(total).length) {
    allColumns.forEach((key, index) => {
      namingItem.set(key, {
        title: dictItems(key),
        color: chartColors[index],
        formatCell: (item: TItemIndx) => {
          if (typeof item === 'undefined') {
            return '_';
          }
          if (Array.isArray(item[key])) {
            return '_';
          }
          return toLocaleStringSupporte(item[key] as string);
        },
      });
    });
  }

  return namingItem;
}
