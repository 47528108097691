import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from '../../redux/fetchedData';
import { TUploadFile } from '../../utils/upload';
import {
  FileDataZip,
  TRFileZip,
  TTempFile,
  TVariationNameFileZip,
} from './types';
import { TInternalList } from '../../types/general';

export type UploadState = {
  tempFile: Record<TTempFile['key'], TTempFile['file']>;
  tempHTML: string | null;
  tempFileZip: Record<TVariationNameFileZip, FetchedData<TRFileZip>>;
  formDefault: boolean;
  business: FetchedData<TInternalList>;
};

const initialState: UploadState = {
  tempFile: {
    imgMask: genFetchedData<TUploadFile>(null),
    push: genFetchedData<TUploadFile>(null),
    image: genFetchedData<TUploadFile>(null),
    teaser: genFetchedData<TUploadFile>(null),
    iconMask: genFetchedData<TUploadFile>(null),
    yandex_html: genFetchedData<TUploadFile>(null),
    zip: genFetchedData<TUploadFile>(null),
    zip_yandex_html: genFetchedData<TUploadFile>(null),
    img_yandex_html: genFetchedData<TUploadFile>(null),
    video_url: genFetchedData<TUploadFile>(null),
    background: genFetchedData<TUploadFile>(null),
    logo_small: genFetchedData<TUploadFile>(null),
    logo_medium: genFetchedData<TUploadFile>(null),
    logo_email: genFetchedData<TUploadFile>(null),
    background_email: genFetchedData<TUploadFile>(null),
    logotype: genFetchedData<TUploadFile>(null),
    icon: genFetchedData<TUploadFile>(null),
    'images.icon': genFetchedData<TUploadFile>(null),
    'images.image_1': genFetchedData<TUploadFile>(null),
    'images.image_2': genFetchedData<TUploadFile>(null),
    offer: genFetchedData<TUploadFile>(null),
    offer_en: genFetchedData<TUploadFile>(null),
  },
  tempHTML: null,
  tempFileZip: {
    zip: genFetchedData<TRFileZip>(null),
  },
  formDefault: true,
  business: genFetchedData<TInternalList>(null),
};

const uploadsSlice = createSlice({
  name: 'uploads',
  initialState,
  reducers: {
    setFormDefault(state, action: PayloadAction<UploadState['formDefault']>) {
      state.formDefault = action.payload;
    },
    setTempFile(state, action: PayloadAction<TTempFile>) {
      state.tempFile[action.payload.key] = action.payload.file;
    },
    setTempHTML(state, action: PayloadAction<string>) {
      state.tempHTML = action.payload;
    },
    setTempClearFile(state, action: PayloadAction<TTempFile['key']>) {
      state.tempFile[action.payload] = initialState.tempFile[action.payload];
    },
    setTempFileZip(state, action: PayloadAction<FileDataZip>) {
      state.tempFileZip[action.payload.key] = action.payload.file;
    },
    setTempClearFileZip(state, action: PayloadAction<FileDataZip['key']>) {
      state.tempFileZip[action.payload] =
        initialState.tempFileZip[action.payload];
    },
    setBusiness(state, action: PayloadAction<UploadState['business']>) {
      state.business = action.payload;
    },
  },
});

export const {
  setFormDefault,
  setTempClearFile,
  setTempFile,
  setTempHTML,
  setTempClearFileZip,
  setTempFileZip,
  setBusiness,
} = uploadsSlice.actions;
export const uploadReducer = uploadsSlice.reducer;
