import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { FixedSizeList } from 'react-window';
import { ILimits } from 'domains/target/types';
import { FetchedData, genFetchedData } from 'redux/fetchedData';
import { setGeoTree } from 'domains/target/reduser';
import { isAfter } from 'date-fns';
import { clearAllTargetItems } from 'domains/target/actions';
import { useDispatchApp } from '../../../../../redux/rootSelectors';
import {
  fetchGeoTree,
  fetchMyTargetGeo,
  fetchGeoPopular,
} from '../../../model/actions';
import {
  useGeoPopularInfo,
  useGeoTreeInfo,
  useSettingsTargetInfo,
  useTargetUserSettings,
} from '../../../model/selectors';
import {
  Tree,
  TPopular,
  TargetKey,
  SearchCursor,
  GeoResult,
  Tdata,
  RGetStatisticTarget,
  TItems,
} from '../../../model/types';
import {
  recursionActiveElement,
  recursionCheckIsSettingsElement,
  recursionChecked,
  recursionChildPopular,
  recursionDisableElement,
  recursionIsActiveElement,
  recursionIsDisableElement,
  recursionSearchElementById,
  recursionServerChecked,
  recursionToggle,
  recursiveCheckAllInArr,
  recursiveRemoveAllLimitsFromChildrenTree,
  recursiveRemoveLimitsFromTree,
  recursiveUpdateChildrenBidRate,
} from '../../../model/utils/recursionChecked';
import { arTargetTree } from '../../../model/shared';

// ставлю значения увсех детей
function recursionSetTreeVal(
  t: Tree[] | undefined,
  name: string,
  val: string | number,
) {
  if (!t) return;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < t.length; ++i) {
    const item = t[i];
    // eslint-disable-next-line no-continue
    if (item.geoname_id === -1) continue;
    const temp = { ...item };
    // eslint-disable-next-line no-param-reassign
    t[i] = {
      ...temp,
      [name]: val,
      isChecked: 'inherit',
      isToggle: false,
      isSettings: false,
    };
    if (t[i].children) {
      recursionSetTreeVal(t[i].children, name, val);
    }
  }
}

let isStopSetTreeVal = false;
function setTreeVal(t: Tree[], id: number, name: string, val: string | number) {
  if (isStopSetTreeVal) return;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < t.length; ++i) {
    if (t[i].geoname_id === +id) {
      const temp = { ...t[i] };
      // eslint-disable-next-line no-param-reassign
      t[i] = {
        ...temp,
        [name]: val,
        isChecked: +val > 0 ? 'active' : 'disable',
        isToggle: true,
        isSettings: true,
      };

      if (t[i].children?.length) {
        recursionSetTreeVal(t[i].children, name, val);
      }

      isStopSetTreeVal = true;
      break;
    }
    if (t[i].children?.length) {
      setTreeVal(t[i].children, id, name, val);
    }
  }
}

type TUseDataTreeProps = {
  toggle: boolean;
  xxhash: string;
  type: TargetKey;
  isCreative: boolean;
  deletedItemsArr: React.MutableRefObject<string[]>;
  setIsLoading: (p: boolean) => void;
  setShowSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
  showSaveButton: boolean;
  onlyChecked: boolean;
  statisticTarget: FetchedData<RGetStatisticTarget> | undefined;
  searchRefresh: () => void;
  searchRefresher: boolean;
};

export type TreeAndStat = Tree & {
  statistic: Tdata;
};

export const useDataTree = ({
  toggle,
  type,
  xxhash,
  isCreative,
  setIsLoading,
  setShowSaveButton,
  deletedItemsArr,
  showSaveButton,
  onlyChecked,
  statisticTarget,
  searchRefresh,
  searchRefresher,
}: TUseDataTreeProps): {
  geoSearchCursor: SearchCursor;
  targetGeoColumnData: React.RefObject<FixedSizeList>;
  setGeoSearchCursor: (
    value: ((prevState: SearchCursor) => SearchCursor) | SearchCursor,
  ) => void;
  handleClearAll: (reCreate?: boolean) => void;
  setisLoadingClearTargetTree: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void;
  handleToggle: (item: Tree, isHard?: boolean) => void;
  geoRes: GeoResult[];
  onChangeLimitsTree: (k: string, limitsObj: ILimits) => void;
  isErrorSettings: boolean;
  handleChangeCheckbox: (item: Tree, isInherit: boolean) => void;
  isLoadingClearTargetTree: boolean;
  removeLimitsFromTree: (k: string) => void;
  updateTree: (id: number, isCheck: boolean) => void;
  targetGeoColumnLeft: React.RefObject<FixedSizeList>;
  popular: TPopular[];
  checkAllInArr: (
    geoIdArr: number[],
    checkArr: string[],
    uncheckArr: string[],
  ) => void;
  searchAll: Tree[];
  tree: Tree[];
  verifyPartiallyChecked: (paramItems: Tree) => boolean;
  updateItem: (id: string, val: string, name: string) => void;
  searchGeo: string;
  popularGeoID: number | null;
  setSearchGeo: (value: ((prevState: string) => string) | string) => void;
  itemsOnSave: GeoResult[];
  verifyCheckbox: ({
    isChecked,
    type: typeElement,
    parent: { continents, subdivisions, countries },
  }: Tree) => boolean;
} => {
  const { data: dataPopular, LTU: LTUPopular } = useGeoPopularInfo(
    arTargetTree.includes(type) ? type : undefined,
  );

  const { isLoading: isLoadingSettings } = useSettingsTargetInfo(type);

  const {
    data: dataTree,
    LTU: LTUTree,
    isLoading: isLoadingTree,
  } = useGeoTreeInfo(arTargetTree.includes(type) ? type : undefined);

  const dispatch = useDispatchApp();

  useEffect(() => {
    if (toggle && type === 'geo_id' && !showSaveButton) {
      dispatch(fetchGeoPopular());
      if (!dataTree) {
        const openRequest = indexedDB.open('store', 1);

        openRequest.onupgradeneeded = function () {
          // Открытие indexed db
          const db = openRequest.result;
          if (!db.objectStoreNames.contains('treeStore')) {
            // если хранилище "treeStore" не существует
            db.createObjectStore('treeStore', { keyPath: 'id' }); // создаём хранилище
          }
        };

        openRequest.onerror = function () {
          console.error('Error', openRequest.error);
        };

        openRequest.onsuccess = function () {
          const db = openRequest.result;
          // создаем транзакцию для получения стора\удаления итема
          const transaction = db.transaction('treeStore', 'readwrite');
          // получаем object store
          const objectStore = transaction.objectStore('treeStore');
          // получаем хранимый в сторе объект
          const request = objectStore.get('tree');
          request.onsuccess = function () {
            if (request.result) {
              // проверяем пора ли перезаписать объект(1 раз в сутки)
              const treeLiveTime = new Date(request.result.liveTime);
              const needUpdate = isAfter(new Date(), treeLiveTime);
              if (needUpdate) {
                // удаление устраревшего объекта
                const requestDel = objectStore.delete('tree');
                requestDel.onsuccess = function () {
                  // экшен для получения обновленных данных
                  dispatch(fetchGeoTree());
                  db.close();
                };
                requestDel.onerror = function () {
                  console.error(
                    'Ошибка при удалении отчета geo tree: ',
                    request.error,
                  );
                  db.close();
                };
              } else {
                // если объект гео отчета ещё актуален(сутки не прошли), то запхиваем его в редакс стор вместо запроса
                const tree: Tree[] = request.result.data;
                const treeData = genFetchedData<Tree[]>(tree).set(
                  'LTU',
                  Date.now(),
                );
                dispatch(setGeoTree({ geo_id: treeData }));
                db.close();
              }
            } else {
              db.close();
              dispatch(fetchGeoTree());
            }
          };
          request.onerror = function () {
            console.error(
              'Ошибка в запросе db по индексу "tree": ',
              request.error,
            );
          };
        };
      }
    }
    if (toggle && !dataTree && ['categories_rtb'].includes(type)) {
      dispatch(fetchMyTargetGeo(type));
    }
  }, [toggle]);

  const { data: userSettings, LTU: userSettingsLTU } = useTargetUserSettings();

  const items = useMemo(
    () =>
      userSettings ? userSettings[type]?.items : ({} as Record<string, TItems>),
    [isCreative, userSettingsLTU, userSettings],
  );

  /** Дерево городов */
  const [tree, setTree] = useState<Tree[]>([]);
  const [middleTree, setMiddleTree] = useState<Tree[]>([]);
  const [popular, setPopular] = useState<TPopular[]>([]);

  const targetGeoColumnLeft = createRef<FixedSizeList>();
  const targetGeoColumnData = createRef<FixedSizeList>();

  const createPopularData = () => {
    const keys = Object.keys(items);
    let arKeys: {
      key: number;
      is_checked: boolean;
      elements: number[];
    }[] = [];
    keys.forEach((k) => {
      const arSearch = [recursionSearchElementById(+k, tree) as Tree];
      arKeys = [
        ...arKeys,
        {
          key: +k,
          is_checked: items[k].is_checked,
          elements: [...recursionChildPopular(arSearch[0], dataPopular || [])],
        },
      ];
    });

    const newPopular = dataPopular?.map((value) => {
      const item: TPopular = value;
      arKeys.forEach(({ key, elements, is_checked }) => {
        if (
          key === value.geoname_id ||
          (elements.includes(value.geoname_id) && !items[`${value.geoname_id}`])
        ) {
          item.isChecked = is_checked;
        }
      });
      return item;
    });
    if (newPopular) {
      setPopular(() => newPopular);
    }
  };

  useEffect(() => {
    if (
      toggle &&
      dataPopular &&
      items &&
      arTargetTree.includes(type) &&
      !showSaveButton
    ) {
      createPopularData();
    }
  }, [LTUPopular, userSettingsLTU, tree, showSaveButton]);

  /**
   * список активных элементов дерева для строки
   * */
  const [geoRes, setGeoRes] = useState<GeoResult[]>([]);

  /**
   * список итемов для сохранения
   * */
  const [itemsOnSave, setItemsOnSave] = useState<GeoResult[]>([]);

  /**
   * ID популярного города для скролла
   * */
  const [popularGeoID, setPopularGeoID] = useState<number | null>(null);

  const onScanTree = (param: Tree[]) => {
    const arData: GeoResult[] = [];
    const onSaveData: GeoResult[] = [];
    param.forEach((item) => {
      const activeElement = recursionActiveElement(item);
      activeElement.forEach((value) => {
        let data: GeoResult = {
          name_ru: value.name_ru,
          name_en: value.name_en,
          geoname_id: value.geoname_id,
          parent: value.parent,
          isChecked: value.isChecked !== 'disable',
        };
        if (value.children && value.children.length) {
          data = {
            ...data,
            childDisable: recursionDisableElement(value.children)
              .map((v) => ({
                name_ru: v.name_ru,
                name_en: v.name_en,
                parent: value.parent,
                geoname_id: v.geoname_id,
                isChecked: false,
              }))
              .filter((child) => child.geoname_id !== -1),
          };
        }
        // потому что дублируется хз где оно тут везде дублируется
        if (!arData.find((it) => it.geoname_id === data.geoname_id)) {
          arData.push(data);
        }
      });

      const isSettingsElements = recursionCheckIsSettingsElement(item);
      isSettingsElements.forEach((value) => {
        const data: GeoResult = {
          name_ru: value.name_ru,
          name_en: value.name_en,
          geoname_id: value.geoname_id,
          parent: value.parent,
          isSettings: true,
          isChecked: value.isChecked !== 'disable',
        };
        onSaveData.push(data);
      });
    });
    setItemsOnSave(onSaveData);
    setGeoRes(arData);
  };

  const removeItemFromDeletedArr = (
    id: number,
    arr: React.MutableRefObject<string[]>,
  ) => {
    // eslint-disable-next-line no-param-reassign
    arr.current = arr.current.filter((item) => item !== String(id));
  };

  const isErrorSettings = useMemo<boolean>(() => {
    let result = false;
    let children: GeoResult[] = [];
    geoRes.forEach(({ childDisable }) => {
      if (childDisable && childDisable.length) {
        children = [...children, ...childDisable];
      }
    });
    geoRes.forEach(({ parent }) => {
      Object.entries(parent).forEach(([, val]) => {
        if (
          val &&
          children.findIndex(({ geoname_id }) => geoname_id === +val) > -1
        ) {
          result = true;
        }
      });
    });
    return result;
  }, [geoRes]);

  const startScan = debounce(
    (data: Tree[]) => {
      onScanTree(data);
    },
    500,
    { trailing: true, maxWait: 500 },
  );
  /**
   * Устанавливает лимиты для конкретного итема в дереве, ставит галку, добавляет в популярные
   * */
  const onChangeLimitsTree = (k: string, limitsObj: ILimits) => {
    let currentTreeItem: Tree | null = null;

    const recursionAddLimitsById = (
      id: number,
      item: Tree,
      limits: ILimits,
      getCheckboxStatus: (p: Tree) => boolean,
    ): Tree => {
      if (item.geoname_id === id) {
        removeItemFromDeletedArr(id, deletedItemsArr);
        const isChecked = getCheckboxStatus(item);
        if (isChecked) {
          return {
            ...item,
            bid_rate: item.bid_rate,
            isSettings: true,
            limits,
          };
        }
        currentTreeItem = item;
        return {
          ...item,
          bid_rate: typeof limits === 'object' || item.isChecked === 'active' ? 1 : 0,
          isChecked: 'active',
          isSettings: true,
          children: item.children.map((child) => recursiveUpdateChildrenBidRate(child)),
          limits,
        };
      }
      if (item.children && item.children.length) {
        return {
          ...item,
          children: item.children.map((child) =>
            recursionAddLimitsById(id, child, limits, getCheckboxStatus),
          ),
        };
      }
      return item;
    };

    setShowSaveButton(true);
    setTree((prev) =>
      prev.map((treeItem) =>
        recursionAddLimitsById(+k, treeItem, limitsObj, verifyCheckbox),
      ),
    );

    if (dataPopular && currentTreeItem) {
      const childPopular = recursionChildPopular(currentTreeItem, dataPopular);

      setPopular((prev) =>
        prev.map((value) =>
          childPopular.includes(value.geoname_id)
            ? { ...value, isChecked: true }
            : value,
        ),
      );
    }
  };

  /**
   * убирает лимиты у конкретного таргета
   * */
  const removeLimitsFromTree = (k: string) => {
    setTree((prev) =>
      prev.map((treeItem) => recursiveRemoveLimitsFromTree(treeItem, k)),
    );
    setShowSaveButton(true);
  };

  const [isLoadingClearTarget, setisLoadingClearTarget] = useState(false);

  /** функция сбрасывает все города и страны */
  const handleClearAll = useCallback(
    (reCreate?: boolean) => {
      const deletedKeys: string[] = [];
      setTree((d) => recursionChecked(d, deletedKeys));
      dispatch(
        clearAllTargetItems({
          xxhash,
          target_key: type,
          reCreate,
          setisLoadingClearTarget,
          deletedArr: deletedKeys,
        }),
      );
      setShowSaveButton(false);
      if (dataPopular) {
        setPopular((prev) =>
          prev.map((value) => ({ ...value, isChecked: false })),
        );
      }
      searchRefresh();
    },
    [tree, popular],
  );

  useEffect(() => {
    if (toggle && dataTree && items) {
      setMiddleTree(() => recursionServerChecked(dataTree, items));
    }
  }, [LTUTree, toggle, userSettingsLTU]);

  useEffect(() => {
    if (middleTree.length) {
      if (onlyChecked) {
        setTree(() =>
          recursionToggle(middleTree, onlyChecked, showOnlyChecked),
        );
      } else {
        setTree(middleTree);
      }
    }
    searchRefresh();
  }, [middleTree]);

  useEffect(() => {
    if (
      !statisticTarget?.get('isLoading') &&
      !isLoadingSettings &&
      dataTree &&
      !isLoadingTree &&
      arTargetTree.includes(type)
    ) {
      setIsLoading(false);
    }
  }, [dataTree, statisticTarget, isLoadingSettings]);

  const updatePopular = (item: Tree, isChecked: boolean) => {
    if (dataPopular) {
      const childPopular = recursionChildPopular(item, dataPopular);

      setPopular((prev) =>
        prev.map((value) =>
          childPopular.includes(value.geoname_id)
            ? { ...value, isChecked: !isChecked }
            : value,
        ),
      );
    }
  };

  /** функция открытия вложенности чекбоксов */
  const handleToggle = useCallback(
    (item: Tree, isHard?: boolean) => {
      setTree((d) => {
        if (item.type === 'continents') {
          const index = tree.findIndex(
            ({ geoname_id }) => geoname_id === item.geoname_id,
          );
          if (index < 0) return d;
          const temp = { ...d[index] };
          const copy = [...d];
          copy[index] = { ...temp, isToggle: isHard ?? !item.isToggle };
          return copy;
        }
        if (item.type === 'countries') {
          const indexContinent = tree.findIndex(
            ({ geoname_id }) => geoname_id === item.parent.continents,
          );
          const indexCountry = tree[indexContinent].children.findIndex(
            ({ geoname_id }) => item.geoname_id === geoname_id,
          );
          const temp = { ...d[indexContinent].children[indexCountry] };
          const copy = [...d];
          copy[indexContinent].children[indexCountry] = {
            ...temp,
            isToggle: isHard ?? !item.isToggle,
          };
          return copy;
        }
        if (item.type === 'subdivisions') {
          const indexContinent = tree.findIndex(
            ({ geoname_id }) => geoname_id === item.parent.continents,
          );
          const indexCountry = tree[indexContinent].children.findIndex(
            ({ geoname_id }) => geoname_id === item.parent.countries,
          );
          const indexSubdivision = tree[indexContinent].children[
            indexCountry
          ].children.findIndex(
            ({ geoname_id }) => item.geoname_id === geoname_id,
          );
          const temp = {
            ...d[indexContinent].children[indexCountry].children[
              indexSubdivision
            ],
          };
          const copy = [...d];
          copy[indexContinent].children[indexCountry].children[
            indexSubdivision
          ] = {
            ...temp,
            isToggle: isHard ?? !item.isToggle,
          };
          return copy;
        }
        if (item.type === 'cites' && item.children && item.children.length) {
          const indexContinent = tree.findIndex(
            ({ geoname_id }) => geoname_id === item.parent.continents,
          );
          const indexCountry = tree[indexContinent].children.findIndex(
            ({ geoname_id }) => geoname_id === item.parent.countries,
          );
          const indexSubdivision = tree[indexContinent].children[
            indexCountry
          ].children.findIndex(
            ({ geoname_id }) => item.parent.subdivisions === geoname_id,
          );
          const indexCity = tree[indexContinent].children[
            indexCountry
          ].children[indexSubdivision].children.findIndex(
            ({ geoname_id }) => item.geoname_id === geoname_id,
          );
          const temp = {
            ...d[indexContinent].children[indexCountry].children[
              indexSubdivision
            ].children[indexCity],
          };
          const copy = [...d];
          copy[indexContinent].children[indexCountry].children[
            indexSubdivision
          ].children[indexCity] = {
            ...temp,
            isToggle: isHard ?? !item.isToggle,
          };
          return copy;
        }
        return d;
      });
    },
    [tree],
  );

  /**
   * функция выставляет значение чекбокса
   * */
  const verifyCheckbox = useCallback(
    ({
      isChecked,
      type: typeElement,
      parent: { continents, subdivisions, countries },
    }: Tree): boolean => {
      const currentTree = tree.length ? tree : middleTree;
      if (isChecked === 'inherit') {
        if (typeElement === 'countries') {
          const element = currentTree.find((e) => e.geoname_id === continents);
          return element?.isChecked === 'active';
        }
        if (typeElement === 'subdivisions') {
          const indexContinent = currentTree.findIndex(
            (e) => e.geoname_id === continents,
          );
          const indexCountry = currentTree[indexContinent].children.findIndex(
            (e) => e.geoname_id === countries,
          );
          if (
            currentTree[indexContinent].children[indexCountry].isChecked ===
            'inherit'
          ) {
            return currentTree[indexContinent].isChecked === 'active';
          }
          return (
            currentTree[indexContinent].children[indexCountry].isChecked ===
            'active'
          );
        }
        if (typeElement === 'cites') {
          const indexContinent = currentTree.findIndex(
            (e) => e.geoname_id === continents,
          );
          const indexCountry = currentTree[indexContinent].children.findIndex(
            (e) => e.geoname_id === countries,
          );
          const indexSubdivision = currentTree[indexContinent].children[
            indexCountry
          ].children.findIndex((e) => e.geoname_id === subdivisions);
          if (
            currentTree[indexContinent].children[indexCountry].children[
              indexSubdivision
            ].isChecked === 'inherit'
          ) {
            if (
              currentTree[indexContinent].children[indexCountry].isChecked ===
              'inherit'
            ) {
              return currentTree[indexContinent].isChecked === 'active';
            }
            return (
              currentTree[indexContinent].children[indexCountry].isChecked ===
              'active'
            );
          }
          return (
            currentTree[indexContinent].children[indexCountry].children[
              indexSubdivision
            ].isChecked === 'active'
          );
        }
      }
      return isChecked === 'active';
    },
    [tree, middleTree],
  );

  /** функция изменения checkbox
   * @item -> елемент дерева
   * @isInherit -> текущее значение
   * */
  const handleChangeCheckbox = useCallback(
    (item: Tree, isInherit: boolean) => {
      setTree((d) => {
        if (item.type === 'continents') {
          const index = tree.findIndex(
            ({ geoname_id }) => geoname_id === item.geoname_id,
          );
          if (index < 0) return d;
          const temp = { ...d[index] };
          const copy = [...d];
          copy[index] = {
            ...temp,
            isChecked: isInherit ? 'disable' : 'active',
            isToggle: true,
            isSettings: true,
            children: recursionChecked(temp.children, deletedItemsArr.current, isInherit),
          };
          removeItemFromDeletedArr(temp.geoname_id, deletedItemsArr);
          return copy;
        }
        if (item.type === 'countries') {
          const indexContinent = tree.findIndex(
            ({ geoname_id }) => geoname_id === item.parent.continents,
          );
          const indexCountry = tree[indexContinent].children.findIndex(
            ({ geoname_id }) => item.geoname_id === geoname_id,
          );
          const temp = { ...d[indexContinent].children[indexCountry] };
          const copy = [...d];
          const childrenArr = isInherit
            ? recursiveRemoveAllLimitsFromChildrenTree(temp.children)
            : temp.children;
          copy[indexContinent].children[indexCountry] = {
            ...temp,
            bid_rate: isInherit ? 0 : 1,
            isToggle: true,
            isChecked: isInherit ? 'disable' : 'active',
            isSettings: true,
            children: recursionChecked(childrenArr, deletedItemsArr.current, isInherit),
          };
          removeItemFromDeletedArr(temp.geoname_id, deletedItemsArr);
          return copy;
        }
        if (item.type === 'subdivisions') {
          const indexContinent = tree.findIndex(
            ({ geoname_id }) => geoname_id === item.parent.continents,
          );
          const indexCountry = tree[indexContinent].children.findIndex(
            ({ geoname_id }) => geoname_id === item.parent.countries,
          );
          const indexSubdivision = tree[indexContinent].children[
            indexCountry
          ].children.findIndex(
            ({ geoname_id }) => item.geoname_id === geoname_id,
          );
          const temp = {
            ...d[indexContinent].children[indexCountry].children[
              indexSubdivision
            ],
          };
          const withoutLimits = recursiveRemoveLimitsFromTree(
            temp,
            String(item.geoname_id),
          );
          const copy = [...d];
          const childrenArr = isInherit
            ? recursiveRemoveAllLimitsFromChildrenTree(withoutLimits.children)
            : withoutLimits.children;
          copy[indexContinent].children[indexCountry].children[
            indexSubdivision
          ] = {
            ...withoutLimits,
            bid_rate: isInherit ? 0 : 1,
            isToggle: true,
            isSettings: true,
            isChecked: isInherit ? 'disable' : 'active',
            children: recursionChecked(childrenArr, deletedItemsArr.current, isInherit),
          };
          removeItemFromDeletedArr(withoutLimits.geoname_id, deletedItemsArr);
          return copy;
        }
        if (item.type === 'cites') {
          const indexContinent = tree.findIndex(
            ({ geoname_id }) => geoname_id === item.parent.continents,
          );
          const indexCountry = tree[indexContinent].children.findIndex(
            ({ geoname_id }) => geoname_id === item.parent.countries,
          );
          const indexSubdivision = tree[indexContinent].children[
            indexCountry
          ].children.findIndex(
            ({ geoname_id }) => item.parent.subdivisions === geoname_id,
          );
          const indexCity = tree[indexContinent].children[
            indexCountry
          ].children[indexSubdivision].children.findIndex(
            ({ geoname_id }) => item.geoname_id === geoname_id,
          );
          const temp = {
            ...d[indexContinent].children[indexCountry].children[
              indexSubdivision
            ].children[indexCity],
          };
          const withoutLimits = recursiveRemoveLimitsFromTree(
            temp,
            String(item.geoname_id),
          );
          const copy = [...d];

          copy[indexContinent].children[indexCountry].children[
            indexSubdivision
          ].children[indexCity] = {
            ...withoutLimits,
            bid_rate: isInherit ? 0 : 1,
            isChecked: isInherit ? 'disable' : 'active',
            isSettings: true,
          };
          removeItemFromDeletedArr(withoutLimits.geoname_id, deletedItemsArr);
          return copy;
        }
        return d;
      });
      searchRefresh();
      updatePopular(item, isInherit);

      setShowSaveButton(true);
    },
    [tree],
  );

  const checkAllInArr = (
    geoIdArr: number[],
    checkArr: string[],
    uncheckArr: string[],
  ) => {
    setTree((prev) =>
      prev.map((item) =>
        recursiveCheckAllInArr(
          item,
          geoIdArr,
          checkArr,
          uncheckArr,
          updatePopular,
        ),
      ),
    );
    searchRefresh();
    setShowSaveButton(true);
  };

  /**
   * функция возвращает массив иерархии родителей элемета по убыванию
   * @tree -> элемент дерева
   * */
  const hierarchyParentTree = useCallback(
    (item: Tree): Tree[] => {
      let parent: Tree[] = [item];
      const currentData = tree.length ? tree : middleTree;
      if (item.type === 'countries') {
        const indexContinent = currentData.findIndex(
          ({ geoname_id }) => geoname_id === item.parent.continents,
        );
        parent = [currentData[indexContinent], ...parent];
      }
      if (item.type === 'subdivisions') {
        const indexContinent = currentData.findIndex(
          ({ geoname_id }) => geoname_id === item.parent.continents,
        );
        const indexCountry = currentData[indexContinent].children.findIndex(
          ({ geoname_id }) => geoname_id === item.parent.countries,
        );
        parent = [
          currentData[indexContinent],
          currentData[indexContinent].children[indexCountry],
          ...parent,
        ];
      }
      if (item.type === 'cites') {
        const indexContinent = currentData.findIndex(
          ({ geoname_id }) => geoname_id === item.parent.continents,
        );
        const indexCountry = currentData[indexContinent].children.findIndex(
          ({ geoname_id }) => geoname_id === item.parent.countries,
        );
        const indexSubdivision = currentData[indexContinent].children[
          indexCountry
        ].children.findIndex(
          ({ geoname_id }) => geoname_id === item.parent.subdivisions,
        );
        parent = [
          currentData[indexContinent],
          currentData[indexContinent].children[indexCountry],
          currentData[indexContinent].children[indexCountry].children[
            indexSubdivision
          ],
          ...parent,
        ];
      }
      return parent;
    },
    [tree, middleTree],
  );

  /**
   * функция обновляет дерево по клику на популярные
   * @id -> нормер элемента
   * @isCheck -> статус чекбокса
   * */
  const updateTree = useCallback(
    (id: number, isCheck: boolean) => {
      setPopularGeoID(null);
      let res: Tree | null = null;

      function recursion(tree1: Tree[], geoID: number): void {
        if (res) return;

        for (let i = 0; i < tree1.length; i += 1) {
          if (res) break;
          const f = tree1.find((it) => it.geoname_id === geoID);

          if (f) {
            res = f;
            break;
          }

          if (tree1[i].geoname_id === geoID) {
            res = tree1[i];
            break;
          }
          if (!tree1[i].children) break;
          recursion(tree1[i].children, geoID);
        }
      }
      recursion(tree, id);

      if (res) {
        const data = res as Tree;
        handleChangeCheckbox(data, isCheck);
        hierarchyParentTree(data).forEach((item) => {
          handleToggle(item, true);
        });
        updatePopular(data, isCheck);
        setPopularGeoID(data.geoname_id);
      }
    },
    [tree, popular],
  );

  const updateItem = useCallback(
    (id: string, val: string, name: string) => {
      setTree((d) => {
        isStopSetTreeVal = false;
        setTreeVal(d, +id, name, val);
        return [...d];
      });
      setShowSaveButton(true);
    },
    [tree, popular],
  );

  /** хранилище поиска */
  const [searchGeo, setSearchGeo] = useState('');
  /**
   * количество найденных элементов
   * */
  const [searchAll, setSearchAll] = useState<Tree[]>([]);

  const [geoSearchCursor, setGeoSearchCursor] = useState<SearchCursor>({
    index: 1,
    all: 0,
  });

  function showOnlyChecked(item: Tree) {
    const checked = verifyCheckbox(item);
    const partially = verifyPartiallyChecked(item);
    if (checked || partially) {
      return true;
    }
    return false;
  }

  function recursionSearch(
    search1: string,
    searchTree: Tree[],
    resultArr: Tree[],
  ): void {
    const middleData =
      onlyChecked && type === 'geo_id'
        ? searchTree.filter((treeItem) => showOnlyChecked(treeItem))
        : searchTree;
    middleData.forEach((item) => {
      if (
        item.name_ru.toLowerCase().includes(search1.toLowerCase()) ||
        item.name_en.toLowerCase().includes(search1.toLowerCase())
      ) {
        resultArr.push(item);
      }
      if (item.children) {
        recursionSearch(search1, item.children, resultArr);
      }
    });
  }

  const searchElementInTheTree = (search: string, searchTree: Tree[]) => {
    const result: Tree[] = [];

    recursionSearch(search, searchTree, result);

    if (search) {
      setSearchAll(() => result);
      setGeoSearchCursor(() => ({ index: 1, all: result.length }));
    }
  };

  const onSearch = () => {
    searchElementInTheTree(searchGeo, tree);
  };

  useEffect(() => {
    if (arTargetTree.includes(type)) {
      if (searchGeo) {
        onSearch();
      } else {
        setSearchAll(() => []);
        setGeoSearchCursor(() => ({ index: 1, all: 0 }));
        setTree((d) => recursionToggle(d, onlyChecked, showOnlyChecked));
      }
    }
  }, [searchGeo, onlyChecked]);

  useEffect(() => {
    if (arTargetTree.includes(type)) {
      if (searchGeo) {
        onSearch();
      }
    }
  }, [searchRefresher]);

  useEffect(() => {
    if (arTargetTree.includes(type)) {
      if (searchAll.length > 0) {
        setTree((d) => recursionToggle(d));
        const parent = hierarchyParentTree(
          searchAll[geoSearchCursor.index - 1],
        );
        for (let i = 0; i < parent.length; i += 1) {
          handleToggle(parent[i], true);
        }
      }
    }
  }, [geoSearchCursor]);

  useEffect(() => {
    if (arTargetTree.includes(type)) {
      if (toggle) {
        startScan(tree);
      }
    }
  }, [tree]);

  useEffect(() => {
    if (arTargetTree.includes(type)) {
      if (!showSaveButton) {
        if (toggle && dataTree && items) {
          setTree(() => recursionServerChecked(dataTree, items));
        }
      }
    }
  }, [showSaveButton]);

  /**
   * функция возвращает - для чебокса
   * */

  // ниже зафикшенный вариант работы verifyPartiallyChecked? но пока его не подтвердили
  // лучше не удалять, но не обработаны кейсы где родитель выключен умышленно, а дочки вкл

  /*   const verifyPartiallyChecked = useCallback(
    (paramItems: Tree) => {
      const arParent = hierarchyParentTree(paramItems).filter(
        ({ isChecked }) => isChecked === 'active',
      );
      const { activeElement } = recursionIsActiveElement(paramItems.children);
      if (arParent.length > 0) {
        if (paramItems.children && paramItems.children.length) {
          return recursionIsDisableElement(paramItems.children);
        }
      }
      if (activeElement) {
        if (paramItems.isChecked === 'active') {
          if (paramItems.children && paramItems.children.length) {
            return recursionIsDisableElement(paramItems.children);
          }
          return false
        }
        if (paramItems.isChecked === 'disable') {
          return true;
        }
        return true;
      }
      return false;
    },
    [tree],
  ); */

  // классический вариант написанный давно

  const verifyPartiallyChecked = useCallback(
    (paramItems: Tree) => {
      let res = false;
      const arParent = hierarchyParentTree(paramItems).filter(
        ({ isChecked }) => isChecked === 'active',
      );
      const { activeElement } = recursionIsActiveElement(paramItems.children);
      if (arParent.length > 0 || activeElement) {
        if (paramItems.children && paramItems.children.length) {
          res = recursionIsDisableElement(paramItems.children, res);
        }
      }
      if (activeElement) {
        res = true;
      }
      return res;
    },
    [tree, middleTree],
  );

  return {
    popular,
    tree,
    handleToggle,
    verifyCheckbox,
    verifyPartiallyChecked,
    handleChangeCheckbox,
    handleClearAll,
    updateTree,
    searchGeo,
    setSearchGeo,
    onChangeLimitsTree,
    setGeoSearchCursor,
    setisLoadingClearTargetTree: setisLoadingClearTarget,
    geoSearchCursor,
    geoRes,
    targetGeoColumnData,
    isLoadingClearTargetTree: isLoadingClearTarget,
    targetGeoColumnLeft,
    searchAll,
    itemsOnSave,
    popularGeoID,
    isErrorSettings,
    removeLimitsFromTree,
    checkAllInArr,
    updateItem,
  };
};
