import React, { FC } from 'react';
import LoadingItem from './LoadingItem';
import css from '../../modal/styles.module.scss';

const LoadingCampaigns: FC = () => (
  <div className={css.loading}>
    {Array(5)
      .fill(0)
      .map((e,inx) => (
        // eslint-disable-next-line react/no-array-index-key
        <LoadingItem key={`loadingItem-${inx}`}/>
      ))}
  </div>
);

export default LoadingCampaigns;
