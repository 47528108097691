type TCounterSecond = (str: string) => number;

export const counterSecond: TCounterSecond = (str) => {
  const arStr = str.split(':');
  let min = arStr[0];
  const secunds = arStr[1].split('.');
  const sec = secunds[0];
  const miliSec = secunds[1] ? `.${secunds[1]}` : '';
  const slicedMiliSec = miliSec.length > 4 ? miliSec.slice(0, 5) : miliSec;
  if (+min[0] === 0) {
    min = min.substr(1);
  }
  return Number(+min[0] * 60 + +sec + slicedMiliSec);
};

type TVideoStringDuring = (count: number) => string;

export const videoStringDuring: TVideoStringDuring = (count) => {
  if (count) {
    const minut = Math.trunc(count / 60);
    const more = count % 60;
    return `${minut < 10 ? `0${minut}` : minut}:${
      more < 10 ? `0${more}` : more
    }`;
  }
  return `0`;
};
