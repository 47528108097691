import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { wssChangePage, wssChangeStatus } from '../actions';
import { useDispatchApp } from '../../../../redux/rootSelectors';

export const useSocket = (): void => {
  const location = useLocation();
  const dispatch = useDispatchApp();

  const genUrl = (url: string): string => {
    if (url.includes('&highlights')) {
      return url.split('&highlights')[0];
    }
    if (url.includes('?highlights')) {
      return url.split('?highlights')[0];
    }
    return url;
  };

  useEffect(() => {
    if (location.pathname !== '/main') {
      dispatch(
        wssChangePage({
          path: genUrl(window.location.href),
          location: location.pathname,
        }),
      );
    }
  }, [location]);

  const onFocusWindow = () => {
    dispatch(wssChangeStatus(true));
  };

  const onBlurWindow = () => {
    dispatch(wssChangeStatus(false));
  };

  useEffect(() => {
    window.addEventListener('blur', onBlurWindow);
    window.addEventListener('focus', onFocusWindow);
    return () => {
      window.removeEventListener('blur', onBlurWindow);
      window.removeEventListener('focus', onFocusWindow);
    };
  }, []);
};
