import { useEffect, useState } from 'react';
import { useOuterElementClick } from '../../../hooks/useOuterElementClick';

export type TFUseModalSettingsProps = {
  closeOutside?: boolean;
};

type TFUseModalSettings = (p: TFUseModalSettingsProps) => {
  open: boolean;
  openAction: () => void;
  closeAction: () => void;
  wrapperRef: ReturnType<typeof useOuterElementClick>['wrapperRef'];
};

export const useModalSettings: TFUseModalSettings = ({ closeOutside }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (closeOutside) {
      closeAction();
    }
  }, [closeOutside]);

  const openAction = () => {
    setOpen(true);
  };

  const closeAction = () => {
    setOpen(false);
  };

  const { wrapperRef } = useOuterElementClick({
    closeModal: closeAction,
    isShow: open,
  });

  return {
    open,
    openAction,
    closeAction,
    wrapperRef,
  };
};
