type TFormatterTime = (countMinute: number) => string;

export const formatterMinuteForHour: TFormatterTime = (countMinute) => {
  if (!countMinute) {
    return `${countMinute}`;
  }
  const whole = Math.floor(countMinute / 60);
  const remainder = countMinute % 60;
  if (!whole) {
    return `${remainder}m`;
  }
  if (!remainder) {
    return `${whole}h`;
  }
  return `${whole}h ${remainder}m`;
};
