import { useEffect, useState } from 'react';

interface IUseShowPanels {
    positionX: number;
    positionY: number;
}

interface IUseShowPanelsProps {
    // ref управляющего элемента
    controlRef: React.RefObject<HTMLDivElement>;
    panelRef?: React.RefObject<HTMLDivElement>;
    openPanel: boolean;
    closePanel: () => void;
    targetY: 'TOP' | 'BOTTOM',
    targetX: 'LEFT' | 'RIGHT',
}

export const useShowPanels = ({
    controlRef,
    panelRef,
    openPanel,
    closePanel,
    targetY,
    targetX,
}: IUseShowPanelsProps): IUseShowPanels => {
    const [positionX, setpositionX] = useState<number>(0);
    const [positionY, setPositionY] = useState<number>(0);

    const calcPosition = () => {
        if (controlRef && controlRef.current && panelRef && panelRef.current) {
            if (targetY === 'BOTTOM') {
                setPositionY(controlRef.current?.getBoundingClientRect().bottom);
            } else {
                setPositionY(controlRef.current?.getBoundingClientRect().top - panelRef.current.clientHeight);
            }

            if (targetX === 'LEFT') {
                setpositionX(controlRef.current?.getBoundingClientRect().left - panelRef.current.clientWidth + controlRef.current?.clientWidth)
            } else {
                setpositionX(controlRef.current?.getBoundingClientRect().left)
            }
        }
    };

    useEffect(() => {
        calcPosition();
    }, [openPanel]);

    useEffect(() => {
        document.addEventListener('scroll', () => {
            calcPosition();
            closePanel();
        });
        return () => {
            document.removeEventListener('scroll', () => {
                calcPosition();
                closePanel();
            });
        };
    }, []);

    return {
        positionX,
        positionY,
    };
};
