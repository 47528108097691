import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import cn from 'classnames';
import Loader from 'components/UI/Loader';
import { FiCheck, FiSearch, FiX } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Button from 'components/UI/Buttons/buttons';
import ModalOverlay from 'components/Modals/ModalOverlay1';
import { useAgenciesInfo } from 'domains/agencies/model/selectors';
import { useCreativeListInfo } from 'domains/creative/model/selectors';
import Checkbox from 'components/UI/Checkbox/checkbox';
import { RGetPersonal } from 'domains/campaign/types';
import Breadcrumbs from './Breadcrumbs';
import Error from './Error';
import Client from './Item/Client';
import Campaign from './Item/Campaign';
import useCopy from './useCopy';
import useClone from './useClone';
import css from './styles.module.scss';
import { THASH } from '../../../../types/general';
import { fetchClients } from '../../../clients/reducer';
import { ParamRequestClone, RCreativeClone, TBreadcrumbs } from '../../types';
import { useDispatchApp } from '../../../../redux/rootSelectors';
import { useAppNotification, useUserInfo } from '../../../user/model/selectors';
import { useClientsInfo } from '../../../clients/model/selectors';
import { useSearchModalBreadcrumbs } from '../../../search/model/selectors';
import { fetchModalBreadcrumbs } from '../../../search/model/actions';
import { setTempModalBreadcrumbsDefault } from '../../../search/model/reducer';
import { fetchCampaignsListModal } from '../../../campaigns/model/actions';
import { useCampaignsListModalInfo } from '../../../campaigns/model/selectors';

type Props = {
  onClose: () => void;
  modalProps: {
    partner_id: string;
    campaign_id: string;
    id: string | string[];
    title: string;
    callback?: () => void;
  };
  isOpen: boolean;
};

const CopyCreativeModal: FC<Props> = ({ onClose, modalProps, isOpen }) => {
  const dispatch = useDispatchApp();
  const { data: modalBreadcrumbs, LTU } = useSearchModalBreadcrumbs();

  const { data: user } = useUserInfo();

  const isAppNotification = useAppNotification();
  const { data: clientsGeneral, isLoading: isLoadingClients } =
    useClientsInfo();
  const { data: campaignsGeneral, isLoading: isLoadingCampaigns } =
    useCampaignsListModalInfo();
  const { data: agenciesGeneral, isLoading: isLoadingAgencies } =
    useAgenciesInfo();
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();

  const agencies =
    agenciesGeneral &&
    agenciesGeneral.filter(
      (camp) => camp.status === 'LAUNCHED' || camp.status === 'STOPPED',
    );

  const campaigns =
    campaignsGeneral &&
    campaignsGeneral.filter(
      (camp) =>
        (camp.status === 'LAUNCHED' || camp.status === 'STOPPED') &&
        camp.xxhash !== id,
    );

  const clients =
    clientsGeneral &&
    clientsGeneral.filter(
      (cli) => cli.status === 'STOPPED' || cli.status === 'LAUNCHED',
    );

  const breadcrumbs = useMemo<TBreadcrumbs[]>(() => {
    let arLinks: TBreadcrumbs[] = [];
    let keys = (modalBreadcrumbs && Object.keys(modalBreadcrumbs)) || [];
    if (user) {
      const keyIndex = keys.findIndex(
        (key) =>
          key.toLocaleLowerCase() === user.partner.type.toLocaleLowerCase(),
      );
      if (keyIndex + 1) {
        keys = keys.slice(keyIndex, keys.length);
      }
    }
    if (keys && modalBreadcrumbs) {
      keys.forEach((key) => {
        if (key === 'Organization') {
          arLinks = [
            {
              title: modalBreadcrumbs.Organization.title,
              xxhash: modalBreadcrumbs.Organization.xxhash,
              type: key,
            },
          ];
        }
        if (key === 'Agency') {
          arLinks = [
            ...arLinks,
            {
              title: modalBreadcrumbs.Agency.title,
              xxhash: modalBreadcrumbs.Agency.xxhash,
              type: key,
            },
          ];
        }
        if (key === 'Client') {
          arLinks = [
            ...arLinks,
            {
              title: modalBreadcrumbs.Client.title,
              xxhash: modalBreadcrumbs.Client.xxhash,
              type: key,
            },
          ];
        }
      });
    }
    return arLinks;
  }, [LTU, user]);

  const {
    onRequestForcedClone,
    onRequestClone,
    copying,
    setCopying,
    progress,
    warning,
    warnList,
    error,
    warningHide,
    link,
  } = useClone();

  const {
    data,
    setStep,
    step,
    isActives,
    setIsActives,
    search,
    setSearch,
    handlerToggleAll,
    getCheckedCount,
  } = useCopy({
    campaigns,
    clients,
    agencies,
  });

  const { data: creativeInfo } = useCreativeListInfo();
  const [currentCampaign, setCurrentCampaign] = useState<RGetPersonal[]>([]);

  useEffect(() => {
    const currentCampaignInfo = campaignsGeneral?.filter(
      (camp) => camp.xxhash === id,
    );
    if (currentCampaignInfo?.length) {
      setCurrentCampaign(currentCampaignInfo);
    }
  }, [campaignsGeneral]);

  useEffect(() => {
    dispatch(fetchModalBreadcrumbs({ xxhash: modalProps.partner_id }));

    dispatch(
      fetchCampaignsListModal({
        partner_xxhash: modalProps.partner_id,
        status: '',
      }),
    );

    return () => {
      dispatch(setTempModalBreadcrumbsDefault());
    };
  }, []);

  const { t } = useTranslation();

  const ToastSuccess = ({
    result,
    paramRequest: { campaign_xxhash_list, creative_xxhash_list },
  }: /*     isActive, */
  {
    result: RCreativeClone[];
    paramRequest: ParamRequestClone;
    /*     isActive: { xxhash: string; title: string }[]; */
  }) => {
    /*     const findCampaign = campaigns?.find(
      (e) => campaign_xxhash_list[0] === e.xxhash,
    ); */

    if (modalProps.callback) {
      modalProps.callback();
    }
    return (
      <div className={css.toast}>
        <div className={cn(css.icon, '_isSuccess')}>
          <FiCheck size={16} color="#fff" />
        </div>
        <div className={css.text}>
          {creative_xxhash_list.length === 1 ||
          typeof modalProps.id === 'string' ? (
            <>
              {t('creative.copy_creative.succes.t1')}{' '}
              <>
                <b>{`"${
                  creativeInfo?.filter((item) => {
                    if (Array.isArray(modalProps.id)) {
                      return item.xxhash === modalProps.id[0];
                    }
                    return item.xxhash === modalProps.id;
                  })[0].title
                }"`}</b>{' '}
              </>
              {t('creative.copy_creative.succes.t2')}
              <div className={css.links}>
                {result.length > 1 && (
                  <>{t('creative.copy_creative.succes.t3')}</>
                )}
              </div>
            </>
          ) : (
            <>
              {t('creative.copy_creative.succes.t4')}{' '}
              {t('creative.copy_creative.succes.t5')}{' '}
            </>
          )}
          <>{t('creative.copy_creative.succes.t3')}</>
          <div className={css.links}>
            {campaign_xxhash_list.map((hash, index) => {
              const searchCampaign = isActives?.find(
                (camp) => camp.xxhash === hash,
              );
              return (
                <Link
                  to={`/main/campaign/${searchCampaign?.xxhash}/main`}
                  rel="noopener noreferrer"
                  target="_blank"
                  key={hash}
                >
                  <strong>{`${searchCampaign?.title}`}</strong>
                  {index === campaign_xxhash_list.length - 1 ? '' : ', '}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    );

    /* return (
      <div className={css.toast}>
        <div className={cn(css.icon, '_isSuccess')}>
          <FiCheck size={16} color="#fff" />
        </div>
        <div className={css.text}>
          <>
            {t('creative.copy_creative.succes.t1')}{' '}
            <>
              <b>{`"${modalProps.title}"`}</b>{' '}
            </>
            {t('creative.copy_creative.succes.t2')}
          </>
          <div className={css.links}>
            {result.length === 1 && findCampaign && (
              <>
                <Link
                  to={`/main/campaign/${findCampaign.xxhash}/creatives/${result[0].xxhash_new_creative}/`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <strong>{`${modalProps.title}_copy`}</strong>
                </Link>
                {t('creative.copy_creative.succes.t3')}
                <Link
                  to={`/main/campaign/${findCampaign.xxhash}/main`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <strong>{`${findCampaign.title}`}</strong>
                </Link>
              </>
            )}
            {result.length > 1 && <>{t('creative.copy_creative.succes.t3')}</>}
            {result.length > 1 &&
              (result as Array<RCreativeClone>).map(
                ({ xxhash_creative: creativeID }, index: number) => {
                  const findCampaignSome =
                    isActive &&
                    isActive.find(
                      (e) => e.xxhash === campaign_xxhash_list[index],
                    );
                  return (
                    <>
                      <Link
                        to={`/main/campaign/${campaign_xxhash_list[index]}/creatives?highlights=${creativeID}`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <strong>{`${
                          findCampaignSome && findCampaignSome.title
                        }`}</strong>
                        {index === campaign_xxhash_list.length - 1 ? '' : ', '}
                      </Link>
                    </>
                  );
                },
              )}
          </div>
        </div>
      </div>
    ); */
  };

  const ToastError = () => (
    <div className={css.toast}>
      <div className={cn(css.icon, '_isError')}>
        <FiX size={16} color="#fff" />
      </div>
      <div className={cn(css.text, '_isError')}>
        {t('creative.copy_creative.err1')}
      </div>
    </div>
  );

  const onSearchChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSearch(target.value);
  };

  const onClick = ({
    type,
    params,
  }: {
    type: string;
    params: { campaign_id: string } | { id: string } | { partner_id: string };
  }) => {
    setSearch('');

    if (type === 'campaigns') {
      dispatch(
        fetchCampaignsListModal({
          ...params,
          status: '',
        }),
      );
      setStep(type);
      return;
    }

    // eslint-disable-next-line consistent-return
    return setStep('clients');
  };

  const handlerParentClick = (param: THASH, type: 'CLIENT' | 'AGENCY') => {
    setStep(type === 'AGENCY' ? 'clients' : 'campaigns');
    if (type === 'AGENCY') {
      dispatch(fetchClients({ idAgency: param.xxhash }));
    }
    if (type === 'CLIENT') {
      dispatch(
        fetchCampaignsListModal({
          partner_xxhash: param.xxhash,
          status: '',
        }),
      );
    }
    dispatch(fetchModalBreadcrumbs(param));
  };

  const getLoading = useMemo<boolean>(() => {
    if (step === 'campaigns') {
      return !isLoadingCampaigns;
    }
    if (step === 'agencies') {
      return !isLoadingAgencies;
    }
    return !isLoadingClients;
  }, [isLoadingClients, isLoadingAgencies, isLoadingCampaigns]);

  const renderData = () => {
    if (!data.length) return <p>{t('search_none.none_1')}</p>;

    return data.map((e: any) => {
      if (step === 'clients' || step === 'agencies') {
        return <Client key={e.id} {...e} handleClick={handlerParentClick} />;
      }

      return (
        <Campaign
          key={e.xxhash}
          {...e}
          onClick={onClick}
          isActives={isActives}
          setIsActives={setIsActives}
        />
      );
    });
  };

  const onForcedClone = async () => {
    const response = await onRequestForcedClone({
      fromId:
        typeof modalProps.id === 'string' ? [modalProps.id] : modalProps.id,
      toIds: isActives.map((item) => item.xxhash),
    });

    if (response) {
      onClose();
      if (isAppNotification) {
        toast(
          <ToastSuccess
            paramRequest={{
              creative_xxhash_list: Array.isArray(modalProps.id)
                ? modalProps.id
                : [modalProps.id],
              campaign_xxhash_list: [link.campaignId],
            }}
            result={response}
            /*             isActive={isActives} */
          />,
          {
            className: 'wide',
            type: 'success',
            delay: 500,
            autoClose: 10000,
          },
        );
      }
      return;
    }

    if (isAppNotification) {
      toast(<ToastError />, {
        type: 'error',
        delay: 500,
        autoClose: 5000,
      });
    }
  };

  const onCopyClick = async () => {
    setCopying(true);

    const response = await onRequestClone({
      creative_xxhash_list: Array.isArray(modalProps.id)
        ? modalProps.id
        : [modalProps.id],
      campaign_xxhash_list: isActives.map(({ xxhash }) => xxhash),
    });

    // setIsActives([]);

    if (response && !isLoadingCampaigns) {
      onClose();
      if (isAppNotification) {
        toast(
          <ToastSuccess
            /*             isActive={isActives} */
            result={response.result}
            paramRequest={{
              creative_xxhash_list: response.creative_xxhash_list,
              campaign_xxhash_list: response.campaign_xxhash_list,
            }}
          />,
          {
            className: 'wide',
            type: 'success',
            delay: 500,
            autoClose: 10000,
          },
        );
      }
    }
  };

  const getHeader = () => {
    if (warning) {
      return (
        <div className={css.title}>
          <h1>{t('creative.copy_creative.errors.tit')}</h1>
          {warningHide && (
            <p>{t('campaigns_page.campaigns_copy_modal.limit_copy')}</p>
          )}
        </div>
      );
    }

    return (
      <div className={css.title}>
        <h1>{t('creative.copy_creative.copying_tit')}</h1>
        {(currentCampaign?.length === 1 && !(Array.isArray(modalProps.id) && modalProps.id.length > 1)) && (
          <div className={css.name}>
            {`${currentCampaign[0].title} / ${`[${
              Array.isArray(modalProps.id) && modalProps.id.length === 1
                ? creativeInfo?.find((item) => item.xxhash === modalProps.id[0])?.internal_id
                : creativeInfo?.find((item) => item.xxhash === modalProps.id)?.internal_id
            }]`} ${
              Array.isArray(modalProps.id) && modalProps.id.length === 1
                ? creativeInfo?.find((item) => item.xxhash === modalProps.id[0])?.title
                : modalProps.title
            }`}
          </div>
        )}
        <p>{t('creative.copy_creative.text_creative')}</p>
      </div>
    );
  };

  return (
    <ModalOverlay onCloseModal={onClose} isOpen={isOpen}>
      <div className={css.card}>
        <div
          className={cn(css.copying, {
            isActive: copying,
          })}
        >
          <div className={css.title}>
            {t('creative.copy_creative.copying_tit')}
          </div>
          <div className={css.progress}>
            <div className={css.bar} style={{ width: `${progress}%` }} />
          </div>
          <div className={css.hint}>
            {t('creative.copy_creative.copying_text')}
          </div>
        </div>

        <div className={css.form}>
          <div className={css.header}>
            {getHeader()}
            <div className={css.close}>
              <FiX size={24} onClick={onClose} />
            </div>
          </div>

          {warning ? (
            <div className={css.content}>
              <div className={css.errors}>
                <div className={css.list}>
                  {!warningHide && (
                    <Error
                      title={t('creative.copy_creative.errors.tit')}
                      text={JSON.stringify(error, null, 2)}
                    />
                  )}
                  {warnList.length > 0 &&
                    warnList.map((e) => (
                      <Error
                        title={modalProps.title}
                        text={e.message}
                        key={e.xxhash_creative}
                        targetingInfo={e.targeting_info}
                      />
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <div className={css.content}>
              <div className={css.breadcrumbs}>
                <Breadcrumbs setStep={setStep} breadCrumbs={breadcrumbs} />
              </div>
              <div className={css.select}>
                {step === 'campaigns' && (
                  <Checkbox
                    className={css.select_checkbox}
                    label={`${t(
                      'campaigns_page.campaign_settings.additional.statistics.selected',
                    )} ${getCheckedCount().checkedCount} ${t(
                      'campaigns_page.campaign_settings.additional.statistics.of',
                    )} ${getCheckedCount().allItemsCount}`}
                    isPartiallyChecked={
                      getCheckedCount().checkedCount !== 0 &&
                      getCheckedCount().checkedCount !==
                        getCheckedCount().allItemsCount
                    }
                    inputAttributes={{
                      type: 'checkbox',
                      checked:
                        getCheckedCount().allItemsCount !== 0 &&
                        getCheckedCount().checkedCount ===
                          getCheckedCount().allItemsCount,
                      onChange: handlerToggleAll,
                    }}
                  />
                )}

                <div className={css.search}>
                  <label htmlFor="searchCopyModalCreative">
                    <input
                      id="searchCopyModalCreative"
                      type="text"
                      placeholder={`${t('targetings.search')}...`}
                      autoComplete="off"
                      onChange={onSearchChange}
                      value={search}
                    />
                    <div className={css.search_icon}>
                      <FiSearch size={24} />
                    </div>
                  </label>
                </div>
              </div>
              <div className={css.list}>
                {getLoading ? renderData() : <Loader />}
              </div>
            </div>
          )}

          <div className={css.actions}>
            <Button
              transparent
              title={t('cancel_btn')}
              autoWidth
              buttonAttributes={{
                onClick: onClose,
              }}
            />
            {!warning && (
              <Button
                title={t('copy_btn')}
                autoWidth
                buttonAttributes={{
                  onClick: onCopyClick,
                  disabled: !isActives.length,
                }}
              />
            )}
            {warning && warningHide && (
              <Button
                title={t('continue_btn')}
                autoWidth
                buttonAttributes={{
                  onClick: onForcedClone,
                  disabled: warning,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </ModalOverlay>
  );
};

export default CopyCreativeModal;
