import { useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useSearchBreadcrumbsInfo } from 'domains/search/model/selectors';
import { clearTempCreativeDetail, fetchCreativeDetail } from '../../../reduser';
import { fetchClientInfoById } from '../../../../client/reduser';
import { useDispatchApp } from '../../../../../redux/rootSelectors';
import { useCampaignInfo } from '../../../../campaign/model/selectors';
import { useCreativeDetailInfo } from '../../../model/selectors';

type TFUseCreativeDetail = () => {
  location: ReturnType<typeof useLocation>;
  id: string;
  path: string;
  item: ReturnType<typeof useCreativeDetailInfo>['data'];
  isLoading: ReturnType<typeof useCreativeDetailInfo>['isLoading'];
  error: ReturnType<typeof useCreativeDetailInfo>['error'];
  campaign: ReturnType<typeof useCampaignInfo>['data'];
};

export const useCreativeDetail: TFUseCreativeDetail = () => {
  const dispatch = useDispatchApp();

  const location = useLocation();
  const { params, path } = useRouteMatch<{ id: string }>();

  const { data: creativeDetail, error, isLoading } = useCreativeDetailInfo();

  const { data: campaign } = useCampaignInfo();

  const { data: bread_crumbs } = useSearchBreadcrumbsInfo();

  useEffect(() => {
    dispatch(fetchCreativeDetail({ xxhash: params.id }));
  }, [params.id]);

  useEffect(() => () => {
      dispatch(clearTempCreativeDetail())
    }, [])
  

  useEffect(() => {
    if (bread_crumbs?.Client?.xxhash) {
      dispatch(fetchClientInfoById(bread_crumbs.Client.xxhash));
    }
  }, [bread_crumbs?.Client?.xxhash, params.id])
  

  return {
    location,
    id: params.id,
    path,
    item: creativeDetail,
    isLoading,
    error,
    campaign,
  };
};
