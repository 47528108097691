import { useCallback, useEffect, useRef, useState } from 'react';
import { usePartnerStatisticFieldList } from 'domains/campaigns/model/selectors';
import {
  useCreativeListInfo,
  useCreativeListViewInfo,
  useCreativesTotal,
} from '../selectors';
import { useCampaignInfo } from '../../../campaign/model/selectors';
import { TDates } from '../../../../types/general';
import { extractMakeGenList } from '../../../../redux/makeGenList';

type Props = {
  campaign: ReturnType<typeof useCampaignInfo>['data'];
  period: TDates['period'];
  search: string;
};

type TFUseCreativeDownloadReportStatisticsList = (v: Props) => {
  handleDownload: () => void;
  isDisabled: boolean;
};

export const useCreativeDownloadReportStatisticsList: TFUseCreativeDownloadReportStatisticsList =
  ({ campaign, period, search }) => {
    const WorkerRef = useRef<Worker | null>(null);

    const { data: keysFields, LTU: LTU_KEYS } = usePartnerStatisticFieldList();
    const { data: list } = useCreativeListInfo();
    const { data: sumStatistics } = useCreativesTotal()

    const { listView } = extractMakeGenList(useCreativeListViewInfo());

    const [disGetReport, setDisGetReport] = useState(true);

    useEffect(() => {
      if (campaign && LTU_KEYS && list && keysFields && sumStatistics) {
        setDisGetReport(false);
        if (!WorkerRef.current) {
          WorkerRef.current = new Worker(
            '/workers/getReportCreativeListStatistics.js?v=0.1.2',
          );
        }
      }
    }, [campaign, LTU_KEYS, keysFields, list, sumStatistics]);

    function isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    const handleDownload: ReturnType<TFUseCreativeDownloadReportStatisticsList>['handleDownload'] =
      useCallback(() => {
        if (WorkerRef.current) {
          WorkerRef.current.postMessage([
            keysFields?.filter(key => key.checked),
            list,
            listView,
            sumStatistics?.total,
            search,
          ]);
          // eslint-disable-next-line no-param-reassign
          WorkerRef.current.onmessage = function (e) {
            const type = isSafari() ? 'application/csv' : 'text/csv';
            const blob = new Blob(['', e.data], { type });

            const URL = window.URL || window.webkitURL;

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute(
              'download',
              `${campaign?.title}_list_creatives_${campaign?.external_ids}_from_${period.from}_to_${period.to}.tsv`,
            );
            document.body.appendChild(link);
            link.click();
            link.remove();
            setDisGetReport(false);
          };
          // eslint-disable-next-line no-param-reassign
          WorkerRef.current.onerror = function (e) {
            console.error({ e });
            setDisGetReport(false);
          };
        }
      }, [sumStatistics, list, listView, search, campaign, WorkerRef]);

    return {
      handleDownload,
      isDisabled: disGetReport,
    };
  };
