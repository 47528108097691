import { SelectField, HistorySelectedField } from '../../../../types/general';
import { FilterCompare } from './enums';

export const filterObjectOptions: HistorySelectedField[] = [
  {
    label: 'history_changes_page.all_objects',
    value: null,
  },
  {
    label: 'creative.creative_text',
    value: 'CREATIVE',
  },
  {
    label: `just_words.w1`,
    value: 'CAMPAIGN',
  },
];

export const filterActionsOptions: HistorySelectedField[] = [
  {
    label: 'history_changes_page.all_actions',
    value: null,
  },
  {
    label: 'history_changes_page.add',
    value: 'Add',
  },
  {
    label: 'history_changes_page.edit',
    value: 'Edit',
  },
  {
    label: 'history_changes_page.copy',
    value: 'Clone',
  },
  {
    label: 'creative.mass_editing',
    value: 'Mass_edit',
  },
];

export const filterParametrsChangeOptions: SelectField[] = [
  {
    label: 'creative.Status',
    value: 'status',
  },
  {
    label: 'history_changes_page.targets',
    value: 'targetings',
  },
  {
    label: 'containers_page.name',
    value: 'title',
  },
  {
    label: 'history_changes_page.campaign_start_date',
    value: 'date_start',
  },
  {
    label: 'history_changes_page.campaign_end_date',
    value: 'date_end',
  },
  {
    label: 'campaigns_page.create_campaign.limits',
    value: 'limits',
  },
];

export const filterOptionsCompare: SelectField[] = [
  {
    label: '>=',
    value: FilterCompare.MORE_AND_EQUALS,
  },
  {
    label: '<=',
    value: FilterCompare.LESS_AND_EQUALS,
  },
  {
    label: '=',
    value: FilterCompare.EQUALS,
  },
  {
    label: '>',
    value: FilterCompare.MORE,
  },
  {
    label: '<',
    value: FilterCompare.LESS,
  },
];
