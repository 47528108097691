import React, {
  useMemo,
  useEffect,
  useState,
  useRef,
  RefObject,
  FC,
} from 'react';
import cn from 'classnames';
import Portal from 'components/Modals/MainPortal';
import DateRangeModal from 'components/DateRangeModal';
import { FiChevronDown, FiCalendar } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { parsingDate } from 'utils/momentParse';
import css from './styles.module.scss';
import { PeriodEnum } from '../../types/containers';
import { initialSettings } from '../../utils/context';
import { TDates, TOnSetDateRange } from '../../types/general';

type Props = {
  range: TDates['period'];
  onSetDateRange?: TOnSetDateRange;
  onSetActive?: (toggle: boolean, dropMenu: RefObject<HTMLDivElement>) => void;
  dateStart: Date;
  dateEnd: Date;
  hideAllTime?: boolean;
  className?: string;
  isTarget?: boolean;
  loading?: boolean;
  initialTitleDay?: TDates['type'];
  isError?: boolean;
  disabled?: boolean;
};

const DateRangeFilter: FC<Props> = ({
  range,
  onSetDateRange,
  onSetActive,
  dateStart,
  dateEnd,
  loading,
  hideAllTime,
  className,
  isTarget,
  isError,
  initialTitleDay = 'day',
  disabled,
}) => {
  const [toggle, setToggle] = useState(false);
  const [dayTitle, setDayTitle] = useState<TDates['type']>(initialTitleDay);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const onHide = (event: MouseEvent) => {
    if (event.target instanceof Element) {
      const targetContainer = event.target.closest(
        `.${css['form-group__select']}`,
      );

      if (targetContainer) {
        return;
      }
    }

    setToggle(false);
  };

  useEffect(() => {
    document.addEventListener('click', onHide);
    initialSettings();
    onSetDate(initialTitleDay);
    return () => document.removeEventListener('click', onHide);
  }, []);

  const dropdownMenu = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setDayTitle(initialTitleDay);
  }, [initialTitleDay]);

  useEffect(() => {
    if (onSetActive) {
      onSetActive(toggle, dropdownMenu);
    }
  }, [toggle]);

  const onSetDate = (type: TDates['type']) => {
    const newRange: TDates['period'] = {
      from: parsingDate(moment().subtract(6, 'days')).format('YYYY-MM-DD'),
    };

    if (type === PeriodEnum.DAYS30) {
      newRange.from = parsingDate(moment().subtract(29, 'days')).format(
        'YYYY-MM-DD',
      );
      newRange.to = parsingDate(new Date()).format('YYYY-MM-DD');
    }

    if (type === PeriodEnum.MONTH) {
      newRange.from = parsingDate(new Date())
        .startOf('month')
        .format('YYYY-MM-DD');
      newRange.to = parsingDate(new Date()).format('YYYY-MM-DD');
    }

    if (type === PeriodEnum.WEEK) {
      newRange.from = parsingDate(moment().subtract(6, 'days')).format(
        'YYYY-MM-DD',
      );
      newRange.to = parsingDate(new Date()).format('YYYY-MM-DD');
    }

    if (type === PeriodEnum.DAY) {
      newRange.from = parsingDate(new Date()).format('YYYY-MM-DD');
      newRange.to = parsingDate(new Date()).format('YYYY-MM-DD');
    }

    if (type === PeriodEnum.YESTERDAY) {
      newRange.from = parsingDate(moment().subtract(1, 'days')).format(
        'YYYY-MM-DD',
      );
      newRange.to = parsingDate(moment().subtract(1, 'days')).format(
        'YYYY-MM-DD',
      );
    }

    if (type === PeriodEnum.ALL_TIME) {
      newRange.from = parsingDate(dateStart).format('YYYY-MM-DD');
      newRange.to = parsingDate(dateEnd).format('YYYY-MM-DD');
    }

    if (type === PeriodEnum.PERIOD) {
      newRange.from = parsingDate(range.from || dateStart || new Date()).format(
        'YYYY-MM-DD',
      );
      newRange.to = parsingDate(range.to || dateEnd || new Date()).format(
        'YYYY-MM-DD',
      );
    }

    setDayTitle(type);
    if (typeof onSetDateRange === 'function') {
      onSetDateRange(newRange, type);
    }
    setToggle(() => false);
  };

  const currentDay = useMemo(() => {
    if (dayTitle === PeriodEnum.DAY) {
      return t('date_pick.day');
    }

    if (dayTitle === PeriodEnum.MONTH) {
      return t('date_pick.month');
    }

    if (dayTitle === PeriodEnum.DAYS30) {
      return t('date_pick.days30');
    }

    if (dayTitle === PeriodEnum.WEEK) {
      return t('date_pick.week');
    }
    if (dayTitle === PeriodEnum.YESTERDAY) {
      return t('date_pick.yesterday');
    }
    if (dayTitle === PeriodEnum.ALL_TIME) {
      return t('date_pick.all_time');
    }
    if (isError) {
      return t('date_pick.select');
    }
    if (range) {
      return `${moment(range.from || new Date()).format('DD.MM.YYYY')} -
    ${moment(range.to || new Date()).format('DD.MM.YYYY')}`;
    }
    return t('date_pick.select');
  }, [range, initialTitleDay, dayTitle, isError]);

  return (
    <div className={cn(className)} ref={dropdownMenu}>
      <div
        className={cn(css['form-group'], {
          _isTarget: isTarget,
          _isDisabled: disabled,
        })}
      >
        <div className={css['form-group__select']}>
          <div
            className={cn(css['form-group__active'], {
              _isOpened: toggle,
            })}
            onClick={() => setToggle(!toggle)}
          >
            {!loading && (
              <>
                <FiCalendar
                  className={css['form-group__calendar']}
                  size={isTarget ? 20 : 24}
                />
                <span>{currentDay}</span>
                <FiChevronDown
                  className={css['form-group__chevron']}
                  size={isTarget ? 20 : 24}
                />
              </>
            )}
          </div>
          <div
            className={cn(css['form-group__dropdown'], { _isOpened: toggle })}
          >
            <div
              className={cn(css['form-group__item'])}
              onClick={() => onSetDate(PeriodEnum.DAYS30)}
            >
              <span>{t('date_pick.days30')}</span>
            </div>

            <div
              className={cn(css['form-group__item'])}
              onClick={() => onSetDate(PeriodEnum.MONTH)}
            >
              <span>{t('date_pick.month')}</span>
            </div>

            <div
              className={cn(css['form-group__item'])}
              onClick={() => onSetDate(PeriodEnum.WEEK)}
            >
              <span>{t('date_pick.week')}</span>
            </div>

            <div
              className={cn(css['form-group__item'])}
              onClick={() => onSetDate(PeriodEnum.DAY)}
            >
              <span>{t('date_pick.day')}</span>
            </div>

            <div
              className={cn(css['form-group__item'])}
              onClick={() => onSetDate(PeriodEnum.YESTERDAY)}
            >
              <span>{t('date_pick.yesterday')}</span>
            </div>

            {!hideAllTime && <div
              className={cn(css['form-group__item'])}
              onClick={() => onSetDate(PeriodEnum.ALL_TIME)}
            >
              <span>{t('date_pick.all_time')}</span>
            </div>}


            <div
              className={cn(css['form-group__item'], { _isDisabled: false })}
              onClick={() => {
                setToggle(false);
                setOpen(true);
              }}
            >
              <span>{t('date_pick.select')}</span>
            </div>
            {open && (
              <Portal
                modalRoot={
                  document.getElementById('second-portal') as HTMLElement
                }
              >
                <DateRangeModal
                  isOpen={open}
                  dateStart={dateStart}
                  dateEnd={dateEnd}
                  dateRange={range}
                  onCallback={(date: TDates['period']) => {
                    if (typeof onSetDateRange === 'function') {
                      onSetDateRange(
                        {
                          from: date.from,
                          to: date.to,
                        },
                        'period',
                      );
                      setDayTitle('');
                    }
                  }}
                  onClose={() => setOpen(false)}
                />
              </Portal>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateRangeFilter;
