import React, { FC } from 'react';
import Tooltip from 'components/UI/Tooltip';
import { FiRefreshCcw } from 'react-icons/fi';
import css from '../styles.module.scss';
import i18n from '../../../../i18n';

interface Props {
  onClickHandler?: () => void;
  children: JSX.Element;
}

const TranslationInfoWrapper: FC<Props> = ({ children, onClickHandler }) => (
  <div className={css.translations}>
    <div className={css.actions}>
      <Tooltip
        onClick={onClickHandler}
        title={
          <div className={css.update}>
            <FiRefreshCcw size={18} />
          </div>
        }
      >
        {i18n.t(`update_btn`)}
      </Tooltip>
    </div>
    <div className={css.content}>{children}</div>
  </div>
);

export default TranslationInfoWrapper;
