import { TStatisticsFields } from 'domains/campaign/types';
import { fetchStatisticFieldList } from 'domains/campaigns/model/actions';
import { usePartnerStatisticFieldList } from 'domains/campaigns/model/selectors';
import { resetStatisticFieldList } from 'domains/campaigns/reducer';
import { useCurrency } from 'domains/project/hooks';
import { useEffect, useMemo } from 'react';
import { useDispatchApp } from 'redux/rootSelectors';
import { checkedLocalization } from 'utils/checkedLocalization';

export interface Column {
  name: string;
  data: string;
}

interface GetColumnsParams {
  partner_xxhash: string;
  language: string;
  queryArr: string[];
}

interface GetColumnsReturn {
  columns: Column[];
}

export const useGetColumns = ({
  partner_xxhash,
  language,
  queryArr,
}: GetColumnsParams): GetColumnsReturn => {
  const { currency } = useCurrency();

  const dispatch = useDispatchApp();

  const { data: statisticFieldList } = usePartnerStatisticFieldList();

  useEffect(() => {
    dispatch(
      fetchStatisticFieldList({
        xxhash: partner_xxhash,
        types: queryArr,
      }),
    );
  }, []);

  useEffect(
    () => () => {
      dispatch(resetStatisticFieldList());
    },
    [],
  );

  const creatreColumns = (data: TStatisticsFields | null): Column[] => {
    if (!data) return [];
    const columnsForCurrency = ['cpc', 'cpm', 'spent', 'budget'];
    const columns = data
      .filter((item) => item.checked)
      .sort((a, b) => a.order - b.order)
      .map(({ key, title }) => {
        const name = `${checkedLocalization(
          `campaigns_page.campaigns_header_main.${key}`,
          title,
        )}${columnsForCurrency.includes(key) ? ` ,${currency}` : ''}`;
        return {
          name,
          data: key,
        };
      });
    return columns;
  };

  const columns = useMemo(
    () => creatreColumns(statisticFieldList),
    [statisticFieldList, currency, language],
  );

  return {
    columns,
  };
};
