import React, { FC, useState } from 'react';
import {
  CreativeItemListWithStatistics,
  MassEditLinkParams,
} from 'domains/creative/types';
import MassEditModal from '../MassEditModal';
import SaveChangeModal from '../SaveChangeModal';

interface Props {
  isMassEditModal: boolean;
  creativeCheckedList: string[];
  currentCheckedItems: React.MutableRefObject<CreativeItemListWithStatistics[]>;
  setIsMassEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  setCreativeCheckedList: (value: string[]) => void;
}

const MassEdit: FC<Props> = ({
  isMassEditModal,
  creativeCheckedList,
  currentCheckedItems,
  setCreativeCheckedList,
  setIsMassEditModal,
}) => {
  const [isSaveMassEditData, setIsSaveMassEditData] =
    useState<MassEditLinkParams | null>(null);

  return (
    <>
      {isMassEditModal && (
        <MassEditModal
          onClose={() => {
            setIsMassEditModal(false);
          }}
          setIsSaveMassEditData={setIsSaveMassEditData}
          creativeCheckedList={creativeCheckedList}
          checkedTypes={[
            ...new Set(currentCheckedItems.current.map((item) => item.type)),
          ]}
        />
      )}
      {isSaveMassEditData && (
        <SaveChangeModal
          setCreativeCheckedList={setCreativeCheckedList}
          saveMassEditData={isSaveMassEditData}
          onClose={() => setIsSaveMassEditData(null)}
        />
      )}
    </>
  );
};

export default MassEdit;
