import React, { FC } from 'react';
import './styles.scss';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { NavbarElementProps } from 'react-day-picker/types/Props';

const CalendarNavbar: FC<Partial<NavbarElementProps>> = ({
  onPreviousClick,
  onNextClick,
}) => (
  <div className="calendar-navbar">
    {onPreviousClick && (
      <button onClick={() => onPreviousClick()} type="button">
        <FiChevronLeft size={24} color="#222" />
      </button>
    )}
    {onNextClick && (
      <button onClick={() => onNextClick()} type="button">
        <FiChevronRight size={24} color="#222" />
      </button>
    )}
  </div>
);

export default CalendarNavbar;
