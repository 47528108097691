import { TDates } from "types/general";

  // метод получения параметров: period, type
  export const setAndReturnParamsPeriod = (): { paramsPeriod: TDates['period'], paramsType: string } => {
    const filterUrl = new URL(window.location.href).searchParams.get('filter');
    let paramsType = '';
    const paramsPeriod: TDates['period'] = {
      from: '',
      to: '',
    };
    if (filterUrl) {
      const filterParams = decodeURI(filterUrl).split('/');
      filterParams.forEach((param) => {
        if (param.includes('from=')) {
          const prefixLength = 'from='.length;
          paramsPeriod.from = param.slice(prefixLength, param.length);
        }
        if (param.includes('to=')) {
          const prefixLength = 'to='.length;
          paramsPeriod.to = param.slice(prefixLength, param.length);
        }
        if (param.includes('type=')) {
          const prefixLength = 'type='.length;
          paramsType = param.slice(prefixLength, param.length);
        }
      });
    }
    return { paramsPeriod, paramsType };
  };