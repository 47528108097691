import { RCampaignsArray } from 'domains/campaigns/types';
import { useState, useMemo, useCallback } from 'react';
import { AgenciesList } from '../../../agencies/api';
import { ClientsList } from '../../../clients/types';

export type TStepLiteral = 'clients' | 'campaigns' | 'agencies' | 'creatives';

type Props = {
  clients: ClientsList | null;
  agencies: AgenciesList | null;
  campaigns: RCampaignsArray | null;
};
type TIsActives = { xxhash: string; title: string }[];

type TFUseCopy = (p: Props) => {
  data: { title: string }[];
  setStep: (v: TStepLiteral) => void;
  step: TStepLiteral;
  isActives: TIsActives;
  setIsActives: (v: TIsActives) => void;
  search: string;
  setSearch: (v: string) => void;
  getCheckedCount: () => {
    checkedCount: number;
    allItemsCount: number;
  };
  handlerToggleAll: () => void;
};

const useCopy: TFUseCopy = ({ clients, campaigns, agencies }) => {
  const [search, setSearch] = useState<string>('');
  const [isActives, setIsActives] = useState<TIsActives>([]);
  const [step, setStep] = useState<TStepLiteral>('campaigns');

  const data = useMemo<
    ClientsList | AgenciesList | RCampaignsArray | []
  >(() => {
    if (step === 'agencies' && agencies) {
      return agencies;
    }
    if (step === 'clients' && clients) {
      return clients;
    }

    if (step === 'campaigns' && campaigns) {
      return campaigns;
    }

    return [];
  }, [clients, campaigns, agencies, step]);

  const searchData = useMemo(() => {
    if (!data) return [];

    const newData: { title: string; xxhash?: string }[] = data;

    return newData.filter((e) =>
      e.title.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
    );
  }, [search, data, step]);

  const handlerToggleAll = () => {
    if (step === 'campaigns') {
      if (isActives.length) {
        const isActivesXxhash = isActives.map((item) => item.xxhash);
        if (
          searchData.some((item) => isActivesXxhash.includes(item.xxhash || ''))
        ) {
          setIsActives(
            isActives.filter(
              (item) =>
                !searchData
                  .map((itemData) => itemData.xxhash)
                  .includes(item.xxhash),
            ),
          );
          return;
        }
        setIsActives((prev) => [
          ...prev,
          ...searchData.map((item) => ({
            xxhash: item.xxhash || '',
            title: item.title,
          })),
        ]);
        return;
      }
      setIsActives(
        searchData.map((item) => ({
          xxhash: item.xxhash || '',
          title: item.title,
        })),
      );
    }
  };

  const getCheckedCount: ReturnType<TFUseCopy>['getCheckedCount'] = useCallback(
    () => ({
      checkedCount: isActives.filter((item) =>
        searchData.map((itemData) => itemData.xxhash).includes(item.xxhash),
      ).length,
      allItemsCount: searchData.length || 0,
    }),
    [isActives, searchData],
  );

  return {
    data: searchData,
    setStep,
    step,
    isActives,
    setIsActives,
    search,
    setSearch,
    getCheckedCount,
    handlerToggleAll,
  };
};

export default useCopy;
