import React, { FC } from 'react';
import { FiCheck, FiMinusCircle } from 'react-icons/fi';
import i18next from 'i18next';
import css from '../styles.module.scss';
import { CreativeDetail } from '../../../../types';
import { TField, TPrefix } from '../../../../model/shared/moderation';

const getModerationChecklistIcon = (condition: boolean) => {
  if (condition) {
    return <FiCheck color="#149C08" size={16} />;
  }
  return <FiMinusCircle color="#828282" size={16} />;
};

type Props = {
  field: TField;
  prefix: TPrefix;
  isData: boolean;
  creativeData: CreativeDetail;
};

const generateList = ({ isData, creativeData, field, prefix }: Props) => {

  if (isData && prefix) {
    return getModerationChecklistIcon(!!creativeData.data[prefix][field]);
  }
  if (!isData && prefix && prefix === 'categories') {
    return getModerationChecklistIcon(!!creativeData[prefix][field]);
  }
  if (isData && !prefix) {
    return getModerationChecklistIcon(!!creativeData.data[field]);
  }

  return getModerationChecklistIcon(!!creativeData[field]);
};

const CheckListItem: FC<Props> = ({ prefix, field, isData, creativeData }) => (
  <div className={css.item}>
    <div className={css.icon}>
      {generateList({ isData, creativeData, prefix, field })}
    </div>
    <div className={css.text}>
      <span>{i18next.t(`creative.widget_text.${field}`)}</span>
    </div>
  </div>
);

export default CheckListItem;
