import React, { FC } from 'react';
import Card from 'components/UI/Card';
import CardList from 'components/UI/CardList';
import Button from 'components/UI/Buttons/buttons';
import { FormikProps } from 'formik';
import { FormikValues } from 'domains/usersAPI/model/types';
import css from './styles.module.scss';
import Methods from '../../components/Methods';
import Main from '../../components/Main';
import i18n from '../../../../../i18n';

interface Props {
  formik: FormikProps<FormikValues>;
  mod: 'create' | 'edit';
  isLoadingCreate?: boolean;
  tagValidationError: string;
}

const Settings: FC<Props> = ({ formik, mod, isLoadingCreate, tagValidationError }) => (
  <CardList>
    <div>
      <Card noHover>
        <Main formik={formik} mod={mod} tagValidationError={tagValidationError} />
      </Card>
      <Card noHover>
        <Methods formik={formik} />
      </Card>
    </div>
    <div className={css.create}>
      <Button
        title={i18n.t('campaigns_page.create_campaign.create_btn')}
        height={44}
        autoWidth
        buttonAttributes={{
          type: 'submit',
          onClick: () => formik.handleSubmit(),
          disabled:
            !!(formik.touched.data?.partners && formik.errors.data?.partners) ||
            (isLoadingCreate && mod === 'create') ||
            (formik.touched.data?.password && !!formik.errors?.data?.password),
        }}
      />
    </div>
  </CardList>
);

export default Settings;
