import React, { FC } from 'react';
import Button from 'components/UI/Buttons/buttons';
import BaseModal from 'components/UI/BaseModal';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import InputSelectBalance from 'components/UI/InputSelectBalance';
import { useCurrency } from 'domains/project/hooks';
import Loader from 'components/UI/Loader';
import PriceInput from 'components/UI/PriceInput';
import { useSearchBreadcrumbsInfo } from 'domains/search/model/selectors';
import css from './styles.module.scss';
import { ReactComponent as UpdateSvg } from '../../assets/update.svg';
import './balanceSelect.scss';
import {
  FormikBalanceVals,
  SelectOption,
  useFormikBalance,
} from './hooks/useFormikBalance';
import { useFetchBalanceData } from './hooks/useFetchBalanceData';

interface IBalanceModal {
  onClose: () => void;
}

const BalanceModal: FC<IBalanceModal> = ({ onClose }) => {
  const { t } = useTranslation();

  const { data: breadcrumbs } = useSearchBreadcrumbsInfo();
  const { currency } = useCurrency();
  const idAgency = breadcrumbs?.Agency.xxhash;

  const { formik, isLoading, swapXxhashValues } = useFormikBalance({
    idAgency,
    currency,
    onClose,
  });
  const { selectOptions, isLoadingModal, customFilterOption } =
    useFetchBalanceData({
      idAgency,
      currency,
      breadcrumbs,
    });

  return (
    <BaseModal
      onClose={onClose}
      isOpen
      title={t(`partners_page.table.balance`)}
      actions={
        <Button
          title={t(`balance_modal.transfer`)}
          height={40}
          buttonAttributes={{
            disabled:
              !formik.values.fromXxhash ||
              !formik.values.toXxhash ||
              !formik.isValid ||
              isLoading ||
              !formik.values.amount,
            onClick: () => formik.handleSubmit(),
          }}
        />
      }
    >
      {isLoadingModal ? (
        <Loader />
      ) : (
        <div className={css.form}>
          <div className={cn(css.fromTo)}>
            <div className={css.select}>
              <div className={css.label}>{t(`balance_modal.from`)}</div>
              <InputSelectBalance<FormikBalanceVals, SelectOption[]>
                className="balance_select"
                fieldName="fromXxhash"
                formik={formik}
                customFilterOption={customFilterOption}
                clearable
                propsValues={selectOptions}
                placeholder={t(`balance_modal.select_client`)}
                customValue={null}
              />
            </div>

            <div className={css.changeBtn} onClick={swapXxhashValues}>
              <UpdateSvg />
            </div>
            <div className={css.select}>
              <div className={css.label}>{t(`balance_modal.to`)}</div>
              <InputSelectBalance<FormikBalanceVals, SelectOption[]>
                className="balance_select"
                fieldName="toXxhash"
                formik={formik}
                customFilterOption={customFilterOption}
                clearable
                propsValues={selectOptions}
                placeholder={t(`balance_modal.select_count`)}
                customValue={null}
              />
            </div>
          </div>
          <PriceInput
            placeholder={t(`balance_modal.enter_am`)}
            error={formik.errors.amount}
            errortext={formik.errors.amount}
            value={formik.values.amount}
            maxLength={80}
            label={t(`balance_modal.enter_am`)}
            onValueChange={({ floatValue }) => {
              formik.setFieldValue('amount', floatValue);
            }}
            hasZero={false}
            notField={false}
            isSaved={false}
          />
        </div>
      )}
    </BaseModal>
  );
};

export default BalanceModal;
