import { setEditPersonalCampaign } from 'domains/campaign/model/actions';
import { useSavedField } from 'domains/campaign/model/hooks/useSavedField';
import { useCampaignInfo } from 'domains/campaign/model/selectors';
import { PSetEditPersonalCampaign } from 'domains/campaign/types';
import { FormikProps, useFormik } from 'formik';
import { useDispatchApp } from 'redux/rootSelectors';

interface SpecialFormik {
  lead_wait_time: number;
}

interface SpecialReturn {
  savedField: Record<string, unknown>;
  formik: FormikProps<SpecialFormik>
  onKeyDown: (keyboardEvent: React.KeyboardEvent) => void;
}

export const useSpecial = (): SpecialReturn => {
  const { data: campaign, past: pastCampaign } = useCampaignInfo();

  const { savedField } = useSavedField({
    data: campaign,
    pastData: pastCampaign.data,
  });

  const dispatch = useDispatchApp();

  const initialValues = {
    lead_wait_time: campaign?.lead_wait_time || 1,
  };

  const formik = useFormik<SpecialFormik>({
    initialValues,
    onSubmit: (values) => {
      if (campaign) {
        const params: PSetEditPersonalCampaign = {
          xxhash: campaign.xxhash,
          lead_wait_time: +values.lead_wait_time,
        };
        dispatch(setEditPersonalCampaign(params));
      }
    },
  });

  const onKeyDown = (keyboardEvent: React.KeyboardEvent) => {
    const { charCode, keyCode } = keyboardEvent;
    if ((charCode || keyCode) === 13) {
      keyboardEvent.preventDefault();
      formik.handleSubmit();
    }
  };

  return {
    savedField,
    formik,
    onKeyDown,
  };
};
