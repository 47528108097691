import React from 'react';
import { FiX, FiAlertCircle } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import ModalOverlay from 'components/Modals/ModalOverlay1';
import Card from 'components/UI/Card';
import Button from 'components/UI/Buttons/buttons';
import Input from 'components/UI/Input/input';
import ToggleSwitch from 'components/UI/ToggleSwitch';
import { Field, FieldProps, FormikProvider, FormikValues } from 'formik';
import css from './styles.module.scss';
import UploadImage from '../../UploadImage';
import { everyGroups } from '../../../../utils/statics/everyGroups';
import { useCreateClientModal } from './hooks';
import MultiSelect from '../../../../components/UI/MultySelect';

export default function CreateClientModal(): JSX.Element {
  const { t } = useTranslation();

  const { closeReset, isShowMCreate, formik, user } = useCreateClientModal();

  return (
    <ModalOverlay onCloseModal={closeReset} isOpen={isShowMCreate}>
      <Card className={cn(css.container)} noHover style={{ padding: '28px' }}>
        <div className={css.header}>
          <div className={css.title}>
            <h1>{t('clients_page.client_create.client_create_tit')}</h1>
          </div>
          <div className={css.close}>
            <FiX size={24} onClick={closeReset} />
          </div>
        </div>
        <div className={css.form}>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <UploadImage />
              <Field name="idAgency">
                {({ field, form }: FieldProps<FormikValues>) => (
                  <MultiSelect
                    type="AGENCIES"
                    field={field}
                    form={form}
                    label={t('clients_page.client_create.client_agency')}
                  />
                )}
              </Field>
              <Input
                label={t('clients_page.client_create.client_name')}
                error={!!formik.errors.title}
                errortext={
                  typeof formik.errors?.title === 'string'
                    ? formik.errors?.title
                    : ''
                }
                showValueCount
                inputAttributes={{
                  name: 'title',
                  onChange: formik.handleChange,
                  value: formik.values.title,
                  autoComplete: 'off',
                  maxLength: 120,
                }}
              />

              <Input
                label={t('clients_page.client_create.client_site')}
                showValueCount
                inputAttributes={{
                  name: 'site',
                  maxLength: 128,
                  onChange: formik.handleChange,
                  value: formik.values.site,
                  autoComplete: 'off',
                }}
              />

              {everyGroups(user, 'owner') && (
                <div className={css.reports}>
                  <h4>{t('clients_page.client_create.client_reports')}</h4>

                  <ToggleSwitch
                    checked
                    notField
                    disabled
                    activateText={t(
                      'clients_page.client_create.client_use_systemset',
                    )}
                    deactivateText={t(
                      'clients_page.client_create.client_use_systemset',
                    )}
                  />

                  <div className={css.edit}>
                    <FiAlertCircle size={20} color="#FF9C08" />
                    <p>{t('clients_page.client_create.client_report_list')}</p>
                  </div>
                </div>
              )}

              <div className={css.buttons}>
                <Button
                  transparent
                  title={t('cancel_btn')}
                  autoWidth
                  buttonAttributes={{
                    style: {
                      height: 36,
                    },
                    onClick: closeReset,
                  }}
                />
                <Button
                  title={t('save_btn')}
                  autoWidth
                  buttonAttributes={{
                    type: 'submit',
                    style: {
                      height: 36,
                    },
                    disabled: !formik.isValid || formik.isSubmitting,
                  }}
                />
              </div>
            </form>
          </FormikProvider>
        </div>
      </Card>
    </ModalOverlay>
  );
}
