import { RouteComponentProps, useRouteMatch } from 'react-router-dom';
import { useIsRegistration, useProjectInfo } from '../../project/hooks';

type TFUseAuthDetail = () => {
  project: ReturnType<typeof useProjectInfo>['data'];
  isLoading: boolean;
  isRegistration: boolean;
  getToRoute: () => string;
  match: RouteComponentProps['match'];
};

export const useAuthDetail: TFUseAuthDetail = () => {
  const { data: project, isLoading } = useProjectInfo();

  const match = useRouteMatch();

  /** параметр Регистрации */
  const { isRegistration } = useIsRegistration();

  const userUpdate = localStorage.getItem('$user_update') || '';
  const params = new URLSearchParams(userUpdate);

  /** функция формирования роутера */
  const getToRoute: ReturnType<TFUseAuthDetail>['getToRoute'] = () => {
    const param = params.get('a');

    if (param === 'confirmation') {
      return '/auth/sign-in';
    }

    if (param === 'confirmationCreate') {
      return '/auth/sign-up';
    }

    if (param === 'restore') {
      return `${match.path}/change-password`;
    }

    return '/auth/sign-in';
  };

  return {
    match,
    project,
    isLoading,
    isRegistration,
    getToRoute,
  };
};
