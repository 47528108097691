import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import reportError from 'assets/errors/report-error.svg';
import Error404 from 'domains/app/Errors/Error404';
import css from './styles.module.scss';
import { ErrorCodeReports } from './ErrorCodeReports';
import { FetchedDataObj } from '../../../redux/fetchedData';

type Props = {
  error?: null | FetchedDataObj<null>['error'];
  type?: 'Container' | 'Reports' | '404';
};

const Reports: FC<Props> = ({ error = null, type = '404' }) => {
  const { t } = useTranslation();

  const textError = {
    err90: {
      title: t('campaigns_page.campaign_settings.reports.nodata'),
    },
    err154: {
      title: t('errors.err154_title'),
      desc: t('errors.err154_desc'),
    },
    errAll: {
      title: t('errors.errAll'),
    },
  };
  if (type === 'Reports') {
    return (
      <div className={cn('container', css.container)}>
        <div className={cn(css.error, 'isError')}>
          <div className={css.img}>
            <img src={reportError} alt="error" />
          </div>
          <div className={css.content}>
            <ErrorCodeReports
              error={error || { code: 90, isError: true, message: 'error' }}
              text={textError}
            />
          </div>
        </div>
      </div>
    );
  }

  return <Error404 />;
};

export default Reports;
