import React, { useState, FC, ChangeEvent } from 'react';
import { Form, FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import EditCard from 'components/UI/EditCard';
import Input from 'components/UI/Input/input';
import { isEmptyObject } from 'utils/isEmptyObject';
import css from '../styles.module.scss';
import { TCreativeYandex } from '../../../../../types';
import { useEditYandex } from './hooks';
import UploadImage from '../../../../../../uploads/UploadImage';

type Props = {
  data: TCreativeYandex;
  xxhash: string;
};

const FormType: FC<Props> = ({ data, xxhash }) => {
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();
  const { formik, handleFormikSubmit, handleFormikReset } = useEditYandex({
    setEdit,
    xxhash,
    data,
  });
  return (
    <FormikProvider value={formik}>
      <Form>
        <EditCard
          className={css.card}
          edited={edit}
          noHover
          noShadow
          hasErrors={!isEmptyObject(formik.errors)}
          onResetForm={handleFormikReset}
          onSave={handleFormikSubmit}
          setEdit={setEdit}
          isAccessDenied
        >
          <div className={css.form}>
              <UploadImage
                keyUploadFile="zip_yandex_html"
                name="zip_yandex_html"
                label={t('creative.yandex_html_text.t1')}
                customLinkTitle={t('creative.yandex_html_text.t4')}
                hintText={<span>{t('creative.yandex_html_text.t3')}</span>}
                fieldType="zip"
                hasEdit
                setFieldValue={formik.setFieldValue}
                edit={edit}
                preview={
                  typeof formik.values.zip_yandex_html === 'string'
                    ? {
                        file_url: formik.values.zip_yandex_html,
                        client_name: formik.values.zip_yandex_html,
                      }
                    : formik.values.zip_yandex_html
                }
                maxSizeFile={0.15}
                file_ext={['.zip']}
                sizeForError={t('creative.yandex_html_text.t6')}
                tooltip={{
                  text: t('creative.yandex_html_text.t5'),
                  link: 'https://yandex.by/adv/requirements/html5',
                }}
                typeSection="zip_yandex_htm"
              />
              <UploadImage
                keyUploadFile="img_yandex_html"
                name="img_yandex_html"
                label={t('creative.yandex_html_text.t2')}
                customLinkTitle={t('creative.yandex_html_text.t10')}
                hintText={
                  <span>
                    {t('creative.yandex_html_text.t11')}
                    <br />
                    {t('creative.yandex_html_text.t12')}
                  </span>
                }
                setFieldValue={formik.setFieldValue}
                preview={
                  typeof formik.values.img_yandex_html === 'string'
                    ? {
                        file_url: formik.values.img_yandex_html,
                        client_name: formik.values.img_yandex_html,
                      }
                    : formik.values.img_yandex_html
                }
                hasEdit
                edit={edit}
                file_ext={['.svg', '.jpg', '.jpeg', '.png', '.gif']}
                maxSizeFile={0.15}
                sizeForError={t('creative.yandex_html_text.t6')}
                typeSection="yandex_html"
              />
              <Input
                label={t('creative.yandex_html_text.t7')}
                notField={edit}
                isTextarea
                showValueCount
                inputAttributes={{
                  name: 'alt_text',
                  type: 'textarea',
                  value: formik.values.alt_text,
                  maxLength: 255,
                  placeholder: '',
                  onChange: formik.handleChange,
                  style: { height: 128 },
                }}
              />
              <Input
                label={t('creative.yandex_html_text.t8')}
                error={!!formik.errors.yandex_brand}
                errortext={t('errors.err47')}
                notField={edit}
                showValueCount
                inputAttributes={{
                  name: 'yandex_brand',
                  type: 'text',
                  onChange: formik.handleChange,
                  placeholder: '',
                  value: formik.values.yandex_brand,
                  maxLength: 80,
                  style: { height: 48 },
                }}
              />
              <Input
                label={t('creative.yandex_html_text.t9')}
                notField={edit}
                error={!!formik.errors.yandex_category}
                errortext={t('errors.err47')}
                showValueCount
                inputAttributes={{
                  name: 'yandex_category',
                  placeholder: '',
                  maxLength: 80,
                  style: { height: 48 },
                  value: formik.values.yandex_category,
                  onChange: (event: ChangeEvent<HTMLInputElement>) => {
                    const { value } = event.target;
                    const reg = /^[-0-9\,]+$/gi;

                    if (value === '') {
                      formik.setFieldValue('yandex_category', value);
                      return;
                    }

                    if (reg.test(value)) {
                      formik.setFieldValue('yandex_category', value);
                    }
                  },
                }}
              />
          </div>
        </EditCard>
      </Form>
    </FormikProvider>
  );
};

export default FormType;
