import { TCreativeVideoInline } from 'domains/creative/types';

export type TSizes = {
  file_url: string;
  client_name: string;
  image_width: string;
  image_height: string;
};
export type TSizesZip = {
  file_url: string;
  client_name: string;
};

export const getSizes = (img: string, format: string[]): TSizes => {
  const size = format[0]?.split('x');

  if (typeof img === 'string') {
    return {
      file_url: img,
      client_name: img,
      image_width: size?.length ? size[0] : '',
      image_height: size?.length ? size[1] : '',
    };
  }

  return img;
};

export const checkResize = (creoType: string): string[] => {
  if (creoType.toLowerCase() === 'push') {
    return ['360x240', '492x328'];
  }
  return [];
};

export type TParamVideo = {
  image_width: number;
  image_height: number;
  file_size: number;
  client_name: string;
  video_poster_url: string;
  file_url: string;
  duration: number;
};

export const getParamVideo = (
  data: Omit<TCreativeVideoInline, 'use_vpaid'>,
  format: string,
): TParamVideo => {
  const { video_url, duration, file_size, video_poster_url } = data;
  const size = format ? format.split('x') : ['', ''];
  return {
    client_name: video_url,
    image_width: +size[0],
    image_height: +size[1],
    file_size,
    video_poster_url,
    file_url: video_url,
    duration,
  };
};
