import React, { FC } from 'react';
import cn from 'classnames';
import { useUserTheme } from 'domains/user/model/selectors';
import { Helmet } from 'react-helmet';
import Tooltip from 'components/UI/Tooltip';
import { FiDownload } from 'react-icons/fi';
import { ReactSVG } from 'react-svg';
import css from '../styles.module.scss';
import i18n from '../../../../i18n';
import campFilterSvg from '../../../../assets/campFilter.svg';

type TFHeaderProp = {
  fixed: boolean;
  setOpenFilters: React.Dispatch<React.SetStateAction<boolean>>;
  downloadHandler: () => void;
  openFilers: boolean;
};

const Header: FC<TFHeaderProp> = ({
  fixed,
  setOpenFilters,
  openFilers,
  downloadHandler,
}) => {
  const { isThemeExtended, isThemeFull } = useUserTheme();

  return (
    <>
      <div
        className={cn(css.header_plag, {
          _isFixedScroll: fixed,
        })}
      />
      <div
        className={cn(css.header, {
          _isFixed: fixed,
        })}
      >
        <Helmet>
          <title>{i18n.t(`analysis_page.title`)}</title>
        </Helmet>
        <div
          className={cn('container resized', {
            extend: isThemeExtended,
            full: isThemeFull,
          })}
        >
          <div className={css.header_row}>
            <h1>{i18n.t(`analysis_page.title`)}</h1>
            {fixed && (
              <div className={css.buttons}>
                <Tooltip
                  className={css.filterTooltip}
                  onClick={() => setOpenFilters(!openFilers)}
                  title={
                    <div
                      className={cn(css.filterBtn, {
                        _isFound: false,
                      })}
                    >
                      <ReactSVG src={campFilterSvg} />
                    </div>
                  }
                >
                  {i18n.t('filter.customize')}
                </Tooltip>
                <Tooltip
                  title={<FiDownload size={24} />}
                  onClick={downloadHandler}
                  className={css.download_btn}
                >
                  {i18n.t(`download_btn`)}
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
