import React, { FC } from 'react';
import cn from 'classnames';
import css from './styles.module.scss';
import { useIsGroup } from '../../domains/user/hooks';

type Props = {
  children: any;
  hide?: boolean;
};

const OnlyOwner: FC<Props> = ({ hide, children }) => {
  const { isOwner, isSelfUser } = useIsGroup();

  const classNames = children && children.props && children.props.className;

  return (
    <div
      className={cn(css.owner, {
        isLocked: !(isOwner || isSelfUser),
        isHide: !(isOwner || isSelfUser) && hide,
      })}
    >
      <div className={css.notEvents}>
        {React.cloneElement(children, {
          disabled: !(isOwner || isSelfUser),
          className: cn(classNames, {
            isDisabled: !(isOwner || isSelfUser),
          }),
        })}
      </div>
    </div>
  );
};

export default OnlyOwner;
