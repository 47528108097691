import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { TMakeReqWithRD, makeReqWithRD } from 'redux/makeReqWithRD';
import { setAllCounteragentList, setCounteragentsList, setOrdList } from '../reducer';
import { getAllCounteragentAPI, getCounteragentsListAPI, getOrdListAPI } from '../api'
import { getCounteragentsList } from '../actions';

export function* workerGetCounteragentsList({
  payload,
}: ReturnType<typeof getCounteragentsList>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getCounteragentsListAPI>>(makeReqWithRD, {
      fetcher: getCounteragentsListAPI,
      fill: setCounteragentsList,
      parameters: payload,
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* workerGetAllCounteragent(): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getAllCounteragentAPI>>(makeReqWithRD, {
      fetcher: getAllCounteragentAPI,
      fill: setAllCounteragentList,
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* workerGetOrdList(): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getOrdListAPI>>(makeReqWithRD, {
      fetcher: getOrdListAPI,
      fill: setOrdList,
    });
  } catch (e) {
    console.error({ e });
  }
}