import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectCreativeTypeModal from 'components/SelectCreativeTypeModal';
import AccessUserRight from 'components/AccessUserRight';
import Button from '../UI/Buttons/buttons';
import css from './styles.module.scss';
import { ElementsNoneImages } from './Images';

type TCreativeNoneProps = {
  /* От этого параметра меняетсятся текст */
  search?: boolean | null;
};

const CreativeNone: FC<TCreativeNoneProps> = ({ search }) => {
  const { t } = useTranslation();
  const [creativeModal, setCreativeModal] = useState<boolean>(false);

  const onCloseCreativeModal = () => {
    setCreativeModal(false);
  };

  return (
    <div className={css.empty}>
      <div className={css.img}>
        {search ? (
          <img
            src={ElementsNoneImages.no_search}
            alt={ElementsNoneImages.no_search}
          />
        ) : (
          <img
            src={ElementsNoneImages.creo_empty}
            alt={ElementsNoneImages.creo_empty}
          />
        )}
      </div>
      {search ? (
        <div className={css.search}>
          <div className={css.header}>{`${t('search_none.none_1')}`}</div>
          <div className={css.text}>
            <span>{`${t('search_none.none_2')}`}</span>{' '}
            <span>{`${t('search_none.none_3')}`}</span>
          </div>
        </div>
      ) : (
        <>
          <div className={css.title}>
            <div>{t('creative.none.none_text')}</div>
            <span>{t('creative.none.none_text_of')}</span>
          </div>
          <div className={css.actions}>
            <AccessUserRight>
              {(props) => (
                <Button
                  title={t('creative.create_btn')}
                  buttonAttributes={{
                    className: css.link,
                    onClick: () => setCreativeModal(true),
                    disabled: props.isDisable,
                  }}
                />
              )}
            </AccessUserRight>
          </div>
        </>
      )}
      {creativeModal && (
        <SelectCreativeTypeModal
          isOpen={creativeModal}
          onClose={onCloseCreativeModal}
        />
      )}
    </div>
  );
};
export default CreativeNone;
