import { fetchHistoryData } from 'domains/historyChanges/actions';
import { useHistoryDataSelector } from 'domains/historyChanges/selectors';
import {
  IHistoryPeriod,
  IHistoryFilter,
} from 'domains/historyChanges/types';
import { useUserInfo } from 'domains/user/model/selectors';
import { useEffect, useState } from 'react';
import { useDispatchApp } from 'redux/rootSelectors';
import { useStateValue, KeysSettings } from 'utils/context';
import isEqual from 'lodash/isEqual';
import { IDispatchHistoryParametres } from 'domains/historyChanges/ui/HistoryFilter';
import { useHistoryMakeUrl } from './useHistoryMakeUrl';
import { setAndReturnParamsPeriod } from '../utils/setAndReturnParamsPeriod';

type TUseHistoryData = {
  historyFilters: IHistoryFilter;
  isLoading: boolean;
  filterData: (period: IHistoryPeriod, type: string) => void;
  applyHistoryFilters: () => void;
  setHistoryFilters: React.Dispatch<React.SetStateAction<IHistoryFilter>>;
  setLastHistoryFilters: React.Dispatch<React.SetStateAction<IHistoryFilter>>;
  getHistoryItemsByLimits: (value: number) => void;
};

export const useHistoryData = (
  settingsPeriod: IHistoryPeriod,
  settingsType: string,
  search: string,
  setisCollapseAll: React.Dispatch<React.SetStateAction<boolean>>,
  limitItems: number,
): TUseHistoryData => {
  const { data: user } = useUserInfo();
  const [firstClick, setFirstClick] = useState(false);
  const dispatch = useDispatchApp();
  const { dispatch: dispatchContext } = useStateValue();
  const { isLoading } = useHistoryDataSelector();
  const { decodeHistoryUrl, setHistoryUrlFilters } = useHistoryMakeUrl();

  const historyParams = localStorage.getItem('localHistoryParams');
  const parsedHistoryParams: IDispatchHistoryParametres = historyParams && JSON.parse(historyParams);
  const isLocalHistory = localStorage.getItem('isLocalHistory');

  // стейт для селект полей фильтров, который потом передаем в запрос фильтрации на сервере
  const [historyFilters, setHistoryFilters] = useState<IHistoryFilter>(
    decodeHistoryUrl({
      period: settingsPeriod,
      limit: { from: 0, to: limitItems },
    }),
  );

  // стейт для отслеживания были ли внесены изменения в фильтры
  const [lastHistoryFilters, setLastHistoryFilters] = useState<IHistoryFilter>(
    decodeHistoryUrl({
      period: settingsPeriod,
      limit: { from: 0, to: limitItems },
    }),
  );

  const { paramsPeriod, paramsType } = setAndReturnParamsPeriod();

  useEffect(() => {
    const cleanLocalStorage = () => {
      localStorage.removeItem('localHistoryParams');
      localStorage.removeItem('isLocalHistory');
    };
    window.addEventListener('beforeunload', cleanLocalStorage);
    return () => {
      localStorage.removeItem('localHistoryParams');
      localStorage.removeItem('isLocalHistory');
      window.removeEventListener('beforeunload', cleanLocalStorage);
    };
  }, []);

  useEffect(() => {
    setHistoryFilters({ ...historyFilters, period: settingsPeriod, limit: { from: 0, to: limitItems }});
    setLastHistoryFilters({ ...historyFilters, period: settingsPeriod, limit: { from: 0, to: limitItems }});
  }, [settingsPeriod]);

  useEffect(() => {
    if (paramsPeriod.from) {
      dispatchContext({
        type: 'setSettings',
        payload: {
          item: {
            type: paramsType,
            period: paramsPeriod,
          },
          key: KeysSettings.HISTORY,
        },
      });
    }
    return () => localStorage.removeItem('changeType')
  }, []);

  const getHistoryItemsByLimits = (value: number) => {
    const updatedFilters = {
      ...parsedHistoryParams,
      limit: { from: 0, to: value },
    };
    localStorage.setItem('localHistoryParams', JSON.stringify({ ...parsedHistoryParams, limit: {'from': 0, 'to': value} }))
    dispatch(
      fetchHistoryData({
        partner_xxhash: user?.partner.xxhash,
        filter: parsedHistoryParams && isLocalHistory ? updatedFilters : {
          ...historyFilters,
          search: historyFilters.search || search || null,
          period: paramsPeriod.from ? paramsPeriod : settingsPeriod,
          limit: {'from': 0, 'to': value},
        },
      }),
    );
  }

  // фильтруем дату на сервере при указании фильтра периода + вносим актуальный период из фильтра в контекст
  const filterData = (period: IHistoryPeriod, type: string) => {
    if (firstClick) {
      localStorage.setItem('localHistoryParams', JSON.stringify({ ...parsedHistoryParams, period}));
      dispatch(
        fetchHistoryData({
          partner_xxhash: user?.partner.xxhash,
          filter: parsedHistoryParams && !!isLocalHistory ? {
            ...parsedHistoryParams,
            period,
            search: parsedHistoryParams.search || search || null,
            limit: parsedHistoryParams.limit || {'from': 0, 'to': limitItems},
          } : { 
            ...historyFilters,
            period,
            search: historyFilters.search || search || null,
            limit: {'from': 0, 'to': limitItems},
          },
        }),
      );
      dispatchContext({
        type: 'setSettings',
        payload: {
          item: {
            type,
            period,
          },
          key: KeysSettings.HISTORY,
        },
      });
      setHistoryUrlFilters(historyFilters, settingsPeriod, type, period);
    }
    setFirstClick(true);
  };

  // Фильтруем данные на сервере при выборе фильтров и нажатии кнопки применить или клике вне попапа
  const applyHistoryFilters = () => {
    if (!isEqual(historyFilters, lastHistoryFilters)) {
      setHistoryUrlFilters(historyFilters, settingsPeriod, settingsType);
      setisCollapseAll(false);
      dispatch(
        fetchHistoryData({
          partner_xxhash: user?.partner.xxhash,
          filter: parsedHistoryParams && !!isLocalHistory ? {
            ...parsedHistoryParams,
            search: parsedHistoryParams.search || search || null,
            limit: parsedHistoryParams.limit || { from: 0, to: limitItems },
          } : {
            ...historyFilters,
            search: historyFilters.search || search || null,
            limit: { from: 0, to: limitItems },
          },
        }),
      );
    } else {
      dispatch(
        fetchHistoryData({
          partner_xxhash: user?.partner.xxhash,
          filter: parsedHistoryParams && !!isLocalHistory ? {
            ...parsedHistoryParams,
            search: parsedHistoryParams.search || search || null,
            limit: parsedHistoryParams.limit || { from: 0, to: limitItems },
          } : {
            ...historyFilters,
            period: settingsPeriod,
            search: historyFilters.search || search || null,
            limit: { from: 0, to: limitItems },
          },
        }),
      );
    }
    setLastHistoryFilters(historyFilters);
  };

  return {
    historyFilters,
    isLoading,
    filterData,
    applyHistoryFilters,
    setHistoryFilters,
    setLastHistoryFilters,
    getHistoryItemsByLimits,
  };
};
