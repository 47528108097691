import React, { FC } from 'react';
import { useDispatchApp } from 'redux/rootSelectors';
import css from './styles.module.scss';
import { TStepLiteral } from '../useCopy';
import { fetchClients } from '../../../../clients/reducer';
import { fetchAgencies } from '../../../../agencies/reducer';
import { TBreadcrumbs } from '../../../types';
import { fetchModalBreadcrumbs } from '../../../../search/model/actions';
import { fetchCampaignsListModal } from '../../../../campaigns/model/actions';

type Props = {
  breadCrumbs: TBreadcrumbs[];
  setStep: (v: TStepLiteral) => void;
};

const Breadcrumbs: FC<Props> = ({ setStep, breadCrumbs }) => {
  const dispatch = useDispatchApp();

  return (
    <div className={css.breadcrumbs}>
      {breadCrumbs.length > 0 &&
        breadCrumbs.map(({ title, xxhash, type }) => (
          <div
            className={css.item}
            key={xxhash}
            onClick={() => {
              dispatch(fetchModalBreadcrumbs({ xxhash }));
              if (type === 'Agency') {
                setStep('clients');
                dispatch(fetchClients({ status: null, idAgency: xxhash }));
              }
              if (type === 'Organization') {
                setStep('agencies');
                dispatch(fetchAgencies({ status: null }));
              }
              if (type === 'Client') {
                setStep('campaigns');
                dispatch(
                  fetchCampaignsListModal({
                    partner_xxhash: xxhash,
                    status: '',
                  }),
                );
              }
            }}
          >
            {title}
          </div>
        ))}
    </div>
  );
};

export default Breadcrumbs;
