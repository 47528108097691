import { useState } from 'react';
import { TCampaignsFilters } from '../../../domains/campaigns/types';

type TFUseFilterResultProps = {
  data: TCampaignsFilters;
};
type TFUseFilterResult = (props: TFUseFilterResultProps) => {
  isCollapse: boolean;
  showBtnCollapse: boolean;
  items: TCampaignsFilters;
  toggleCollapse: (v: boolean) => void;
};

export const useFilterResult: TFUseFilterResult = ({ data }) => {
  const [isCollapse, setCollapse] = useState<boolean>(true);

  const toggleCollapse: ReturnType<TFUseFilterResult>['toggleCollapse'] = (v) =>
    setCollapse(v);

  return {
    isCollapse,
    items: isCollapse ? data.filter((value, index) => index < 4) : data,
    showBtnCollapse: data.length > 4,
    toggleCollapse,
  };
};
