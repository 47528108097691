import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';
import css from '../styles.module.scss';
import { ErrorResponse } from '../../../../types/general';

type Props = {
  error: {
    code: number;
  };
};

export const isCloneResponseError = (
  v: unknown | ErrorResponse,
): v is ErrorResponse => true;

const ToastError: FC<Props> = ({ error }) => {
  const { t } = useTranslation();

  const textError = (code: number): JSX.Element => {
    if (code === 43) {
      return <>{t('errors.limitsym')}</>;
    }
    return <>Неизвестная ошибка</>;
  };

  return (
    <div className={css.toast}>
      <div className={cn(css.icon, '_isError')}>
        <FiX size={16} color="#fff" />
      </div>
      <div className={cn(css.text, '_isError')}>
        {
          error.code === 94 ? 
          <div>{t('errors.err94')}</div> :
          <div>{t('errors.err_valid')} {textError(error.code)}</div> 
        }
      </div>
    </div>
  );
};

export default ToastError;
