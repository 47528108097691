import { deleteGroup } from 'domains/client/reduser';
import { IGroupItem } from 'domains/client/types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { TID } from 'types/general';

interface removeGroupReturn {
  remove: boolean;
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
  setIsTimerStart: React.Dispatch<React.SetStateAction<boolean>>;
  toggleRemove: () => void;
}

export function useRemoveGroup(
  groupInfo: IGroupItem,
  setGropsInRemoveQueue: React.Dispatch<React.SetStateAction<string[]>>,
): removeGroupReturn {
  const [remove, setRemove] = useState(true);
  const [counter, setCounter] = useState(3);
  const [isTimerStart, setIsTimerStart] = useState(false);
  const dispatch = useDispatch();
  const { params } = useRouteMatch<TID>();

  const toggleRemove = () => {
    setRemove(!remove);
  };

  function removeGroupHandler(title_list: string[]): void {
    dispatch(
      deleteGroup({
        xxhash: params.id,
        title_list,
      }),
    );
  }

  useEffect(() => {
    let removeGroupTimeout: NodeJS.Timeout | null = null;
    if (isTimerStart) {
      if (counter > 0) {
        removeGroupTimeout = setTimeout(() => {
          setCounter((prev) => prev - 1);
        }, 1000);
      }
      if (counter === 0) {
        removeGroupHandler([groupInfo.group.title]);
        setGropsInRemoveQueue((prev) => [...prev, groupInfo.group.title]);
      }
    }
    return () => {
      if (removeGroupTimeout) {
        clearTimeout(removeGroupTimeout);
      }
    };
  }, [counter, isTimerStart]);

  return {
    remove,
    counter,
    setCounter,
    setIsTimerStart,
    toggleRemove,
  };
}
