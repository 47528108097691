/* eslint-disable prefer-const */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Button from '../UI/Buttons/buttons';
import css from './styles.module.scss';
import AccessUserRight from '../AccessUserRight';
import { ElementsNoneImages } from './Images';

type TContractorsNoneProps = {
  /* От этого параметра меняетсятся текст */
  search?: boolean | null;
};

const ContractorsNone: FC<TContractorsNoneProps> = ({ search }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const createOffers = () =>
    history.replace({
      pathname: `counteragent/create`,
    });

  return (
    <>
      <div className={css.empty}>
        <div className={css.img}>
          {search ? (
            <img
              src={ElementsNoneImages.no_search}
              alt={ElementsNoneImages.no_search}
            />
          ) : (
            <img
              src={ElementsNoneImages.cntractors_empty_all}
              alt={ElementsNoneImages.cntractors_empty_all}
            />
          )}
        </div>
        {search ? (
          <div className={css.search}>
            <div className={css.header}>{`${t('search_none.none_1')}`}</div>
            <div className={css.text}>
              <span>{`${t('search_none.none_2')}`}</span>{' '}
              <span>{`${t('search_none.none_3')}`}</span>
            </div>
          </div>
        ) : (
          <div className={css.title}>
            <>
              <span
                style={{
                  fontWeight: 400,
                }}
              >
                {t(`ord.сounterparties_none`)}
              </span>
              <div className={css.actions}>
                <AccessUserRight>
                  {(props) => (
                    <Button
                      title={t(`ord.counterparte_create`)}
                      buttonAttributes={{
                        className: css.link,
                        onClick: createOffers,
                        disabled: props.isDisable,
                      }}
                    />
                  )}
                </AccessUserRight>
              </div>
            </>
          </div>
        )}
      </div>
    </>
  );
};
export default ContractorsNone;
