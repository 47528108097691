import {
  fetchCreativeList,
  fetchTargetItemsData,
} from 'domains/Analysis/actions';
import { setTargetItemsData } from 'domains/Analysis/reducer';
import { useTargetItemsDataSelector } from 'domains/Analysis/selectors';
import {
  TGetCreativeListParams,
  TTargetItemsResponse,
} from 'domains/Analysis/types';
import { fetchAgencyList } from 'domains/partners/model/actions';
import { usePartnersListInfo } from 'domains/partners/model/selector';
import { IPartners } from 'domains/partners/types';
import { useUserInfo } from 'domains/user/model/selectors';
import { FormikErrors, FormikProps, useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { genFetchedData } from 'redux/fetchedData';
import { useDispatchApp } from 'redux/rootSelectors';
import i18n from '../../../../i18n';

export type TPartnerData = {
  value: string;
  label: string;
  additional: IPartners;
};

export type TFormikInitialVal = {
  key: string;
  partner_data: TPartnerData | null;
  targets: TGetCreativeListParams['targets'];
  ariaText: string;
};

type TFiltersDataR = {
  partnerOptions?: TPartnerData[];
  targetingOption: Record<string, string>[];
  formik: FormikProps<TFormikInitialVal>;
  targetItemsListView: TTargetItemsResponse | null;
  isLoadingTargetItems: boolean;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  checkCheckboxCount: number;
  isCheckPartially: boolean;
  isCheckAll: boolean;
  generalCheckboxHandler: () => void;
  tabIndex: number;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  targetItemsList: TTargetItemsResponse | null;
};

export const useFiltersData = (
  setCurrentTarget: React.Dispatch<React.SetStateAction<string>>,
  setOpenFilters: React.Dispatch<React.SetStateAction<boolean>>,
  fixed: boolean,
  setIsCollapse: React.Dispatch<React.SetStateAction<boolean>>,
): TFiltersDataR => {
  const { data: user } = useUserInfo();
  const { data: partners } = usePartnersListInfo();
  const { data: targetItemsList, isLoading: isLoadingTargetItems } =
    useTargetItemsDataSelector();

  const dispatch = useDispatchApp();

  const [search, setSearch] = useState<string>('');
  const [targetItemsListView, setTargetItemsListView] =
    useState<TTargetItemsResponse | null>(targetItemsList);
  const [tabIndex, setTabIndex] = useState(0);

  const targetingOption = useMemo(
    () => [
      {
        label: i18n.t(`analysis_page.au`),
        value: 'au',
      },
      {
        label: i18n.t(`analysis_page.dir`),
        value: 'private_deals',
      },
      {
        label: i18n.t(`analysis_page.au_shop`),
        value: 'au_shop',
      },
    ],
    [],
  );

  const partnerOptions = useMemo(
    () =>
      partners
        /* ?.filter((item) => item.type === 'CLIENT') */
        ?.map((partner) => ({
          value: partner.xxhash,
          label: partner.title,
          additional: partner,
        })),
    [partners],
  );

  const validate = (values: TFormikInitialVal) => {
    const errors: FormikErrors<TFormikInitialVal> = {};
    if (values.ariaText.includes(' ')) {
      errors.ariaText = i18n.t(`analysis_page.invalid_input_1`);
    }

    if (values.key === 'au' && values.ariaText !== '') {
      const rex = /^[\d|\n]+$/;
      const isValid = rex.test(values.ariaText);
      if (!isValid) {
        errors.ariaText = i18n.t(`analysis_page.invalid_input_2`);
      }
    }

    if (!values.partner_data) {
      errors.partner_data = i18n.t(`errors.err45`);
    }

    return errors;
  };

  const formik = useFormik<TFormikInitialVal>({
    initialValues: {
      partner_data: null,
      key: 'au',
      targets: { au: [] },
      ariaText: '',
    },
    validate,
    onSubmit: (values) => {
      dispatch(
        fetchCreativeList({
          partner_xxhash: values.partner_data?.value || '',
          targets: values.targets,
        }),
      );
    },
  });

  useEffect(() => {
    if (!fixed) {
      setOpenFilters(false);
    } else {
      setIsCollapse(false);
    }
  }, [fixed]);

  useEffect(() => {
    formik.setFieldValue(`targets.${formik.values.key}`, []);
    formik.setFieldValue('ariaText', '');
  }, [tabIndex]);

  useEffect(() => {
    if (user?.partner.xxhash) {
      dispatch(fetchAgencyList({ partner_id: user?.partner.xxhash }));
    }
  }, [user?.partner.xxhash]);

  useEffect(() => {
    if (targetItemsList?.length) {
      setTargetItemsListView(
        targetItemsList.filter((item) =>
          item.title.toLowerCase().includes(search.toLowerCase()),
        ),
      );
      return;
    }
    setTargetItemsListView(null);
  }, [targetItemsList, search]);

  useEffect(() => {
    const itemArr =
      formik.values.ariaText === '' ? [] : formik.values.ariaText.split('\n');
    formik.setFieldValue('targets', { [formik.values.key]: itemArr });
    setCurrentTarget(formik.values.key);
  }, [formik.values.key, formik.values.partner_data, formik.values.ariaText]);

  useEffect(() => {
    if (formik.values.partner_data && formik.values.key) {
      dispatch(
        fetchTargetItemsData({
          partner_xxhash: formik.values.partner_data.value,
          key: formik.values.key,
        }),
      );
      return;
    }
    dispatch(setTargetItemsData(genFetchedData<TTargetItemsResponse>(null)));
  }, [formik.values.partner_data, formik.values.key]);

  useEffect(() => {
    formik.setFieldValue('ariaText', '');
  }, [formik.values.key]);

  const checkCheckboxCount = useMemo(
    () =>
      formik.values.targets[formik.values.key].filter((item) =>
        item.includes(search),
      ).length,
    [search, formik.values.targets],
  );

  const isCheckPartially = useMemo(
    () =>
      Boolean(
        checkCheckboxCount !== 0 &&
          targetItemsListView?.length &&
          checkCheckboxCount < targetItemsListView?.length,
      ),
    [checkCheckboxCount, targetItemsListView],
  );

  const isCheckAll = useMemo(
    () =>
      Boolean(
        checkCheckboxCount !== 0 &&
          targetItemsListView?.length &&
          checkCheckboxCount === targetItemsListView?.length,
      ),
    [checkCheckboxCount, targetItemsListView],
  );

  const generalCheckboxHandler = useCallback(() => {
    const viewKeys = targetItemsListView?.map((targetItem) => targetItem.key);
    if (isCheckPartially || isCheckAll) {
      formik.setFieldValue(
        `targets.${formik.values.key}`,
        formik.values.targets[formik.values.key].filter(
          (item) => !viewKeys?.includes(item),
        ),
      );
      return;
    }
    const checkedArr: string[] = [];
    viewKeys?.forEach((key) => {
      if (!formik.values.targets[formik.values.key].includes(key)) {
        checkedArr.push(key);
      }
    });
    formik.setFieldValue(`targets.${formik.values.key}`, [
      ...formik.values.targets[formik.values.key],
      ...checkedArr,
    ]);
  }, [search, targetItemsListView, isCheckPartially, isCheckAll]);

  return {
    partnerOptions,
    targetingOption,
    formik,
    targetItemsListView,
    isLoadingTargetItems,
    search,
    setSearch,
    checkCheckboxCount,
    isCheckPartially,
    isCheckAll,
    generalCheckboxHandler,
    tabIndex,
    setTabIndex,
    targetItemsList,
  };
};
