import React, { FC, useState } from 'react';
import { animated } from 'react-spring';
import { FiX } from 'react-icons/fi';
import DayPicker, { DateUtils, RangeModifier } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment';
import 'moment/locale/ru';
import CalendarNavbar from 'components/UI/CalendarNavbar';
import Button from 'components/UI/Buttons/buttons';
import { parsingDate } from 'utils/momentParse';
import 'react-day-picker/lib/style.css';
import './styles.scss';
import { Modifiers } from 'react-day-picker/types/Modifiers';
import { useUserInfo } from 'domains/user/model/selectors';
import ModalOverlay from '../Modals/ModalOverlay1';
import { TDates } from '../../types/general';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onCallback: (param: TDates['period']) => void;
  dateRange: TDates['period'];
  dateStart: Date;
  dateEnd: Date;
};

const DateRangeModal: FC<Props> = ({
  isOpen,
  onClose,
  onCallback,
  dateRange,
  dateEnd,
  dateStart,
}) => {
  const [range, setRange] = useState<RangeModifier>({
    from: parsingDate(dateRange ? dateRange.from : new Date()).toDate(),
    to: parsingDate(dateRange ? dateRange.to : new Date()).toDate(),
  });
  const { data: user } = useUserInfo();

  const { t } = useTranslation();

  const modifiers: Partial<Modifiers> = {
    start: range.from as Date,
    end: range.to as Date,
  };

  const onSetDate = () => {
    const checkedRange = {
      from: range.from || moment().toDate(),
      to: range.to || moment().toDate(),
    };

    const date: TDates['period'] = {
      from: parsingDate(checkedRange.from).format('YYYY-MM-DD'),
      to: parsingDate(checkedRange.to).format('YYYY-MM-DD'),
    };

    onCallback(date);
    onClose();
  };

  return (
    <ModalOverlay onCloseModal={onClose} isWide isOpen={isOpen}>
      <animated.div className="date-range-modal__card">
        <div className="header">
          <div className="header__title">
            <h1>{t('date_pick.select')}</h1>
          </div>
          <div className="header__close">
            <FiX size={24} onClick={onClose} />
          </div>
        </div>
        <div className="calendar">
          <DayPicker
            numberOfMonths={3}
            selectedDays={[{ from: range.from, to: range.to }]}
            modifiers={modifiers}
            onDayClick={(day) => {
              const date = DateUtils.addDayToRange(day, range);
              setRange(() => date);
            }}
            navbarElement={<CalendarNavbar />}
            locale={user?.lang || 'ru'}
            localeUtils={MomentLocaleUtils}
            disabledDays={{
              before: parsingDate(moment(dateStart)).toDate(),
              after: parsingDate(moment(dateEnd)).toDate() || new Date(),
            }}
            month={range.from as Date}
          />
        </div>
        <div className="actions">
          <Button
            transparent
            title={t('cancel_btn')}
            height={36}
            autoWidth
            buttonAttributes={{
              onClick: onClose,
            }}
          />
          <Button
            title={t('save_btn')}
            height={36}
            autoWidth
            buttonAttributes={{
              onClick: onSetDate,
            }}
          />
        </div>
      </animated.div>
    </ModalOverlay>
  );
};

export default DateRangeModal;
