import React, { CSSProperties, ReactNode, RefObject } from 'react';
import { Prompt } from 'react-router-dom';
import { FiEdit, FiSave, FiLock, FiX } from 'react-icons/fi';
import cn from 'classnames';
import { useUserTheme } from 'domains/user/model/selectors';
import Button from '../Buttons/buttons';
import css from './styles.module.scss';
import { useIsGroup } from '../../../domains/user/hooks';
import i18n from '../../../i18n';

interface IProps {
  buttons?: ReactNode;
  className?: string;
  hasErrors?: boolean;
  style?: CSSProperties;
  noHover?: boolean;
  noShadow?: boolean;
  edited: boolean;
  onSave: () => void;
  setEdit: (val: boolean) => void;
  notEqual?: boolean;
  onResetForm: () => void;
  isAccessAll?: boolean;
  isAccessDenied?: boolean;
  hideButton?: boolean;
  wrapperRef?: RefObject<HTMLDivElement>;
  textHint?: React.ReactNode;
  showHint?: boolean;
  closeHint?: () => void;
  hintClassName?: string;
}

const EditCard: React.FC<IProps> = ({
  children,
  buttons,
  className,
  style,
  noHover,
  noShadow,
  onSave,
  setEdit,
  edited,
  notEqual,
  onResetForm,
  hasErrors,
  isAccessAll = false,
  isAccessDenied = false,
  hideButton = false,
  wrapperRef,
  textHint,
  showHint,
  closeHint,
  hintClassName,
}) => {
  const { isOwner, isSelfUser } = useIsGroup();
  const locked = !(isOwner || isSelfUser || !!isAccessAll);
  const { getValueFromSettingForKey } = useUserTheme();

  return (
    <div
      ref={wrapperRef}
      className={cn(
        css.card,
        className,
        { isNoHover: noHover },
        { isNoShadow: noShadow },
        { isLocked: locked || isAccessDenied },
      )}
      style={style}
    >
      {!hideButton && (
        <Prompt
          when={edited && notEqual}
          message={i18n.t('just_words.exit_page_currect')}
        />
      )}

      {(locked || isAccessDenied) && (
        <div className={css.lock}>
          <FiLock size={24} />
          <div className={css.lock__info}>
            <span>{i18n.t(`disable_edit`)}</span>
          </div>
        </div>
      )}

      {!(locked || isAccessDenied) && (
        <div className={cn(css.helpers, { _active: edited })}>
          {!hideButton && (
            <>
              {buttons}
              <>
                <div className={cn(css.buttons, { isShow: edited })}>
                  <Button
                    title={i18n.t('cancel_btn')}
                    height={32}
                    autoWidth
                    lightgrey
                    buttonAttributes={{
                      style: {
                        fontSize: '13px',
                      },
                      onClick: onResetForm,
                    }}
                  />

                  <Button
                    title={i18n.t('save_btn')}
                    icon={<FiSave size={20} />}
                    height={32}
                    lightblue
                    autoWidth
                    buttonAttributes={{
                      style: {
                        padding: `10px`,
                        fontSize: '13px',
                        marginLeft: '10px',
                      },
                      onClick: onSave,
                      disabled: hasErrors,
                    }}
                  />
                </div>
                <div className={cn(css.buttons, { isShow: !edited })}>
                  <Button
                    title={i18n.t('edit_btn')}
                    icon={<FiEdit size={18} />}
                    height={32}
                    lightgrey
                    autoWidth
                    buttonAttributes={{
                      style: {
                        padding: `10px`,
                        fontSize: '13px',
                      },
                      onClick: () => setEdit(true),
                    }}
                  />
                </div>
              </>
            </>
          )}
        </div>
      )}

      <div>{children}</div>

      {showHint &&
        textHint &&
        getValueFromSettingForKey('ShowHint') !== null &&
        !getValueFromSettingForKey('ShowHint') && (
          <div
            className={cn(css.card__hint, 'edit_card_hint', hintClassName, {
              _isClose: !showHint,
            })}
          >
            <span>{textHint}</span>
            <div className={css.card__hintClose} onClick={closeHint}>
              <FiX size={24} />
            </div>
          </div>
        )}
    </div>
  );
};

export default EditCard;
