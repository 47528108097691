import { useProjectInfo } from 'domains/project/hooks';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CardsList from 'components/UI/CardList';
import Header from '../components/Header';
import css from './styles.module.scss';
import Statistics from './Forms/Statistics';

const Additional: FC = () => {
  const { t } = useTranslation();
  const { data: project } = useProjectInfo();

  return (
    <div>
      <Helmet>
        <title>{`${t('project_page.settings')} ${
          project && project.title
        }`}</title>
      </Helmet>

      {project && <Header title={project.title} />}

      <div className="container">
        <CardsList className={css.cards}>
          <Statistics />
        </CardsList>
      </div>
    </div>
  );
};

export default Additional;
