import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/UI/Buttons/buttons';
import css from './styles.module.scss';

interface Props {
  screenLink: string | null,
  creoTitle: string | undefined;
}

const SuccessScreenshot: FC<Props> = ({ screenLink, creoTitle }) => {
  const { t } = useTranslation();

  const saveScreenHandler = () => {
    if(!screenLink) return
    const imageUrl = screenLink;
    const fileName = `${creoTitle}.png`;
  
    fetch(imageUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    });
  }

  return (
    <div className={css.screenshot_wrapper}>
      <img className={css.screen_img} src={screenLink || ''} alt='creative screenshot' />
      <Button
        title={t('creative.screenshot.download')}
        height={44}
        buttonAttributes={{
          className: css.download,
          onClick: saveScreenHandler,
        }}
      />
    </div>
  );
};

export default SuccessScreenshot;
