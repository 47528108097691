import {
  useState,
  useEffect,
  MutableRefObject,
  useCallback,
  SyntheticEvent,
} from 'react';

type Clicked = Event | SyntheticEvent;

type TFUseDropDown = (
  dropdownMenu: MutableRefObject<HTMLElement | null>,
  deActiveShow?: boolean,
) => {
  isOpened: boolean;
  toggleOff: () => void;
  toggle: (e: Clicked) => void;
};

const useDropdown: TFUseDropDown = (dropdownMenu, deActiveShow) => {
  const [isOpened, setIsOpened] = useState(false);

  const toggle = useCallback(
    (e: Clicked) => {
      if (deActiveShow && isOpened) return;
      // eslint-disable-next-line consistent-return
      if (!isOpened) return setIsOpened(true);
      if (!dropdownMenu.current) return;

      if (
        dropdownMenu.current &&
        !dropdownMenu.current.contains(e.target as Node)
      ) {
        setIsOpened(false);
      }
    },
    [dropdownMenu, isOpened, deActiveShow],
  );

  const toggleOff = () => setIsOpened(false);

  useEffect(() => {
    if (isOpened) document.addEventListener('click', toggle);

    return () => document.removeEventListener('click', toggle);
  }, [isOpened, toggle]);

  return { toggle, isOpened, toggleOff };
};

export default useDropdown;
