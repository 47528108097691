import Card from 'components/UI/Card';
import UploadImage from 'domains/client/UploadImage';
import React, { FC } from 'react';

interface Props {
  uploadHandler?: ((url: string | null) => void) | undefined;
  imgData?: string;
  disabled?: boolean;
}

const UploadImg: FC<Props> = ({ uploadHandler, imgData, disabled }) => (
  <Card noHover style={{ padding: '28px 24px' }} isUseAccess>
    <UploadImage uploadHandler={uploadHandler} imgData={imgData} disabled={disabled}/>
  </Card>
);

export default UploadImg;
