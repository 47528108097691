import React, { FC } from 'react';
import { FiX } from 'react-icons/fi';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import { IHistoryFilter } from 'domains/historyChanges/types';
import { HistorySelectedField } from 'types/general';
import css from './styles.module.scss';
import { IDispatchHistoryParametres } from '../HistoryFilter';

interface ISelectedFiltersProps {
  filterObj: HistorySelectedField;
  historyFilters: IHistoryFilter;
  value: any;
  tooltip: string;
  deleteFilterByValue: (val: string | null, obj: IHistoryFilter) => void;
}

const SelectedFilters: FC<ISelectedFiltersProps> = ({
  filterObj,
  value,
  tooltip,
  historyFilters,
  deleteFilterByValue,
}) => {
  const historyParams = localStorage.getItem('localHistoryParams');
  const parsedHistoryParams: IDispatchHistoryParametres =
    historyParams && JSON.parse(historyParams);

  return (
    <TooltipPoratal hint={tooltip}>
      <div className={css.selectItem}>
        <div>{value}</div>
        <FiX
          className={css.close}
          onClick={() => deleteFilterByValue(filterObj.value, parsedHistoryParams || historyFilters)}
        />
      </div>
    </TooltipPoratal>
  );
};
export default SelectedFilters;
