import { call, put } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { TUsersTab } from '../../../users/model/types';
import {
  setStorageActivityUsersDate,
  setAgenciesTabs,
  setCampaignsTabs,
  setClientsTabs,
  setCollapseWrappers,
  setIsShowTargetStatistics,
  setUsersTabs,
} from '../reducer';
import { TStatusCampaign } from '../../../campaign/types';
import { Status } from '../../../agencies/api';
import { dateFilter } from '../../../../utils/dateFilter';
import { TDates } from '../../../../types/general';
import { staticDates, StorageEnumKeys } from '../shared';
import { setCampaignsFilter } from '../../../campaigns/reducer';

export function* uploadStoreWithLocalStorageWorker(): SagaIterator<void> {
  const usersTabs: TUsersTab | null = yield call(
    [localStorage, 'getItem'],
    'pageUserListTabs',
  );

  const agenciesTabs: Status | 'all' | null = yield call(
    [localStorage, 'getItem'],
    'pageAgenciesTabs',
  );

  const clientsTabs: Status | 'all' | null = yield call(
    [localStorage, 'getItem'],
    'pageClientsTabs',
  );

  const campaignsTabs: TStatusCampaign | null = yield call(
    [localStorage, 'getItem'],
    'pageCampaignsTabs',
  );

  const isStatisticsTarget: string | null = yield call(
    [localStorage, 'getItem'],
    'pageTargetIsShowStatistics',
  );

  const collapseWrappers: string | null = yield call(
    [localStorage, 'getItem'],
    StorageEnumKeys.COLLAPSE_WRAPPERS,
  );

  const filterCampaigns: string | null = yield call(
    [localStorage, 'getItem'],
    StorageEnumKeys.CAMPAIGNS_FILTERS,
  );

  const activityUsers: string | null = yield call(
    [localStorage, 'getItem'],
    StorageEnumKeys.ACTIVITY_USERS,
  );

  if (usersTabs) {
    yield put(setUsersTabs(usersTabs));
  }
  if (agenciesTabs) {
    yield put(setAgenciesTabs(agenciesTabs === 'all' ? null : agenciesTabs));
  }
  if (clientsTabs) {
    yield put(setClientsTabs(clientsTabs === 'all' ? null : clientsTabs));
  }
  if (campaignsTabs !== null) {
    yield put(setCampaignsTabs(campaignsTabs));
  }
  if (collapseWrappers) {
    yield put(setCollapseWrappers(JSON.parse(collapseWrappers)));
  }
  if (filterCampaigns) {
    yield put(setCampaignsFilter(JSON.parse(filterCampaigns)));
  }
  if (activityUsers) {
    let data: TDates = JSON.parse(activityUsers) as TDates;
    if (staticDates.includes(data.type)) {
      data = dateFilter(data.type, data);
    }
    yield put(setStorageActivityUsersDate(data));
  }

  if (isStatisticsTarget) {
    const data: boolean = JSON.parse(isStatisticsTarget) as boolean;
    yield put(setIsShowTargetStatistics(data));
  }
}

export function* setStorageUsersListTabs({
  payload,
}: ReturnType<typeof setUsersTabs>): SagaIterator<void> {
  yield call([localStorage, localStorage.setItem], 'pageUserListTabs', payload);
}

export function* setStorageAgenciesTabs({
  payload,
}: ReturnType<typeof setAgenciesTabs>): SagaIterator<void> {
  yield call(
    [localStorage, localStorage.setItem],
    'pageAgenciesTabs',
    payload || 'all',
  );
}

export function* setStorageClientsTabs({
  payload,
}: ReturnType<typeof setClientsTabs>): SagaIterator<void> {
  yield call(
    [localStorage, localStorage.setItem],
    'pageClientsTabs',
    payload || 'all',
  );
}

export function* setStorageCampaignsTabs({
  payload,
}: ReturnType<typeof setCampaignsTabs>): SagaIterator<void> {
  yield call(
    [localStorage, localStorage.setItem],
    'pageCampaignsTabs',
    payload,
  );
}

export function* setStorageTargetIsShowStatistics({
  payload,
}: ReturnType<typeof setIsShowTargetStatistics>): SagaIterator<void> {
  yield call(
    [localStorage, localStorage.setItem],
    'pageTargetIsShowStatistics',
    JSON.stringify(payload),
  );
}

export function* setStorageCollapseWrappersWorker({
  payload,
}: ReturnType<typeof setCollapseWrappers>): SagaIterator<void> {
  yield call(
    [localStorage, localStorage.setItem],
    StorageEnumKeys.COLLAPSE_WRAPPERS,
    JSON.stringify(payload),
  );
}

export function* setStorageCampaignsFilterWorker({
  payload,
}: ReturnType<typeof setCampaignsFilter>): SagaIterator<void> {
  yield call(
    [localStorage, localStorage.setItem],
    StorageEnumKeys.CAMPAIGNS_FILTERS,
    JSON.stringify(payload),
  );
}

export function* setStorageActivityUsersWorker({
  payload,
}: ReturnType<typeof setStorageActivityUsersDate>): SagaIterator<void> {
  yield call(
    [localStorage, localStorage.setItem],
    StorageEnumKeys.ACTIVITY_USERS,
    JSON.stringify(payload),
  );
}
