import React, { CSSProperties, FC, ReactNode } from 'react';
import ModalOverlay from 'components/Modals/ModalOverlay1';
import { FiX } from 'react-icons/fi';
import css from './styles.module.scss';
import Card from '../Card';

interface IBaseModalProsp {
  onClose: () => void;
  isOpen: boolean;
  actions: ReactNode;
  title: string | ReactNode;
  customWidth?: number;
  style?: CSSProperties;
}

const BaseModal: FC<IBaseModalProsp> = ({
  onClose,
  isOpen,
  children,
  actions,
  title,
  customWidth,
  style,
}) => (
  <ModalOverlay onCloseModal={onClose} isOpen={isOpen}>
    <Card
      className={css.card}
      noHover
      style={{ padding: '30px', maxWidth: `${customWidth}px`, ...style }}
    >
      <div className={css.card_header}>
        <div className={css.card_title}>{title}</div>
        <div className={css.card_close} onClick={onClose}>
          <FiX size={24} />
        </div>
      </div>
      <div className={css.card_form}>{children}</div>
      <div className={css.card_actions}>{actions}</div>
    </Card>
  </ModalOverlay>
);

export default BaseModal;
