import { SagaIterator } from 'redux-saga';
import { genFetchedData } from 'redux/fetchedData';
import { call, put } from 'redux-saga/effects';
import { fetchBlFiles, setBlFiles } from '../reducer';
import { TFileList } from '../types';
import { getFileList } from '../api';

export function* workerFetchGetBlFileList({
  payload,
}: ReturnType<typeof fetchBlFiles>): SagaIterator<void> {
  let fetchedData = genFetchedData<TFileList[]>(null).set('isLoading', true);
  yield put(setBlFiles(fetchedData));
  try {
    const res = yield call(getFileList, payload);
    fetchedData = fetchedData.set('data', res);
  } catch (e) {
    console.error({ e });
  } finally {
    fetchedData = fetchedData.set('isLoading', false);
    yield put(setBlFiles(fetchedData));
  }
}
