import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData } from 'redux/fetchedData';
import { genFetchedData } from '../../redux/fetchedData';
import { TGetCreativeListResponse, TTargetItemsResponse } from './types';

export interface AnalysisState {
  targetItemsData: FetchedData<TTargetItemsResponse>;
  creativeList: FetchedData<TGetCreativeListResponse>;
}

export const initialState: AnalysisState = {
  targetItemsData: genFetchedData<TTargetItemsResponse>(null),
  creativeList: genFetchedData<TGetCreativeListResponse>(null),
};

const analysisSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    setTargetItemsData(
      state,
      action: PayloadAction<AnalysisState['targetItemsData']>,
    ) {
      state.targetItemsData = action.payload;
    },
    setCreativeList(
      state,
      action: PayloadAction<AnalysisState['creativeList']>,
    ) {
      state.creativeList = action.payload;
    },
  },
});

export const { setTargetItemsData, setCreativeList } = analysisSlice.actions;

export const analysisReducer = analysisSlice.reducer;
