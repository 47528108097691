import { TTableSort } from '../../types/general';

type Email = {
  id: number;
  email: string;
  statistics: Record<string, number> | null;
}[];

type TFArraySortUtilsProps<T> = {
  sort: TTableSort['sort'];
  dataArray: T;
  key: TTableSort['key'];
};

export const arraySortUtils = <T extends Email>({
  sort,
  dataArray,
  key,
}: TFArraySortUtilsProps<T>): TFArraySortUtilsProps<T>['dataArray'] => {
  if (!sort) {
    return dataArray.sort((a, b) => (a.id > b.id ? 1 : -1));
  }
  if (key === 'name') {
    if (sort === 'ASC') {
      return dataArray.sort((a, b) => {
        const titleA = a.email.toLowerCase();
        const titleB = b.email.toLowerCase();
        return titleA < titleB ? -1 : 1;
      });
    }
    return dataArray.sort((a, b) => {
      const titleA = a.email.toLowerCase();
      const titleB = b.email.toLowerCase();
      return titleA > titleB ? -1 : 1;
    });
  }
  if (key) {
    if (sort === 'DESC') {
      return dataArray.sort((a, b) =>
        a.statistics && b.statistics && a.statistics[key] < +b.statistics[key]
          ? 1
          : -1,
      );
    }
    return dataArray.sort((a, b) =>
      a.statistics && b.statistics && a.statistics[key] > b.statistics[key]
        ? 1
        : -1,
    );
  }

  return dataArray;
};
