import {
  THookSelectorFetchDataInfo,
  THookStateInfo,
  TSelector,
  useGenFetchDataSelector,
  useGenFetchDataShallowEqualSelector,
  useSelectorTypes,
} from '../../../redux/rootSelectors';
import { SearchState } from './reducer';
import { RBreadCrumbs, RSearch } from './types';

/** Список селекторов */
export const selectorCampaignsTags: TSelector<SearchState['tagsCampaigns']> = ({
  searchReducer,
}) => searchReducer.tagsCampaigns;

export const selectorCampaignsActive: TSelector<
  SearchState['tagCampaignsActive']
> = ({ searchReducer }) => searchReducer.tagCampaignsActive;

export const selectorSearch: TSelector<SearchState['tempSearch']> = ({
  searchReducer,
}) => searchReducer.tempSearch;

export const selectorSearchIsShow: TSelector<SearchState['isShowModal']> = ({
  searchReducer,
}) => searchReducer.isShowModal;

export const selectorBreadcrumbs: TSelector<SearchState['tempBreadcrumbs']> = ({
  searchReducer,
}) => searchReducer.tempBreadcrumbs;

export const selectorModelBreadcrumbs: TSelector<
  SearchState['tempModalBreadcrumbs']
> = ({ searchReducer }) => searchReducer.tempModalBreadcrumbs;

export const selectorAccessParent: TSelector<
  SearchState['tempAccessParent']
> = ({ searchReducer }) => searchReducer.tempAccessParent;

/** Список хуков */
export const useSearchInfo: THookSelectorFetchDataInfo<RSearch> = () =>
  useGenFetchDataShallowEqualSelector(selectorSearch);

export const useSearchShowModalInfo: THookStateInfo<
  SearchState['isShowModal']
> = () => useSelectorTypes(selectorSearchIsShow);

export const useSearchCampaignsTagsActive: THookStateInfo<
  SearchState['tagCampaignsActive']
> = () => useSelectorTypes(selectorCampaignsActive);

export const useSearchBreadcrumbsInfo: THookSelectorFetchDataInfo<
  RBreadCrumbs
> = () => useGenFetchDataSelector(selectorBreadcrumbs);

export const useSearchModalBreadcrumbs: THookSelectorFetchDataInfo<
  RBreadCrumbs
> = () => useGenFetchDataSelector(selectorModelBreadcrumbs);

export const useSearchAccessParent: THookSelectorFetchDataInfo<
  RBreadCrumbs
> = () => useGenFetchDataShallowEqualSelector(selectorAccessParent);
