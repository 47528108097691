import { TDates } from './general';

export type ResponseContainers = {
  id: string;
  title: string;
  bgColor?: string;
  statistics: {
    total: number;
    previous_day: number;
  };
};

export type Range = {
  from: string | Date;
  to?: string | Date;
};

export interface ResponseStatisticsContainer {
  total: number;
  previous_day: number;
}

export interface ResponseContainer {
  id: string;
  title: string;
  bgColor?: string;
  statistics: ResponseStatisticsContainer[];
}

export interface RequestContainer {
  id: string;
  from: string;
}

export interface Period {
  from?: string;
  to?: string;
}
export type ParamsContainer = {
  id: string;
  period?: {
    from?: string;
    to?: string;
  };
  mode: string;
  source: string;
};
export interface ContainerByIDStatistics {
  date: string;
  total: number;
  day: null | number;
}
export interface ContainerByID {
  id: string;
  title: string;
  range: TDates['period'];
  statistics: ContainerByIDStatistics[];
}
export interface FetchByIDParam {
  id: string;
  from?: string;
  to?: string;
}
// eslint-disable-next-line no-shadow
export enum PeriodEnum {
  DAYS30 = '30days',
  WEEK = 'week',
  YESTERDAY = 'yesterday',
  MONTH = 'month',
  DAY = 'day',
  ALL_TIME = 'all_time',
  PERIOD = 'period',
}
