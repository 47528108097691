import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from '../../../redux/fetchedData';
import { TUploadFile } from '../../../utils/upload';

export interface MlinkToImageState {
  isShow: boolean;
  onCanseled: null | (() => void);
  loadImage: FetchedData<TUploadFile>;
}

const initialState: MlinkToImageState = {
  isShow: false,
  onCanseled: null,
  loadImage: genFetchedData<TUploadFile>(null),
};

const MlinkToImageSlice = createSlice({
  name: 'm/linkToImage',
  initialState,
  reducers: {
    isShow(state, action: PayloadAction<boolean>) {
      state.isShow = action.payload;
    },
    setOnCanseled(
      state,
      action: PayloadAction<MlinkToImageState['onCanseled']>,
    ) {
      state.onCanseled = action.payload;
    },
    setLoadImage(state, action: PayloadAction<MlinkToImageState['loadImage']>) {
      state.loadImage = action.payload;
    },
    setLoadImageDefault(state) {
      state.loadImage = initialState.loadImage;
    },
  },
});

export const { isShow, setOnCanseled, setLoadImage, setLoadImageDefault } =
  MlinkToImageSlice.actions;
export const MlinkToImageR = MlinkToImageSlice.reducer;
