import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { makeReqWithRD, TMakeReqWithRD } from 'redux/makeReqWithRD';
import { fetchHistoryData } from '../actions';
import { setHistoryData } from '../reducer';
import { getHistoryList } from '../api';

export function* workerFetchHistory({
  payload,
}: ReturnType<typeof fetchHistoryData>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getHistoryList>>(makeReqWithRD, {
      fetcher: getHistoryList,
      fill: setHistoryData,
      parameters: {
        partner_xxhash: payload.partner_xxhash,
        filter: payload?.filter,
      },
    });
  } catch (e) {
    console.error(e);
  }
}
