import request from '../../../lib/helpers/requestApi';
import {
  PGetManagementUsersList,
  RUserOnline,
  RGetManagementUsersList,
  RActivityUsers,
  PActivityUsers,
  PEditUser,
  PCreate,
  PSendConfirmation,
} from './types';

export const getUserList = request<
  RGetManagementUsersList[],
  PGetManagementUsersList
>({
  method: 'management.getUserList',
});

export const getUserListOnline = request<RUserOnline>({
  method: 'user.getAllOnline',
});

export const userCreate = request<boolean, PCreate>({
  method: 'user.create',
});

export const userEdit = request<boolean, PEditUser>({
  method: 'user.editFromOwner',
});

export const sendConfirmation = request<boolean, PSendConfirmation>({
  method: 'user.sendConfirmationEmail',
});

export const getActivesUsers = request<RActivityUsers, PActivityUsers>({
  method: 'management.getActivityUsers',
});
