import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../styles.module.scss';
import Error from './Error';
import { TCloneTarget, TItem } from '../../../../types/general';

type WarningListProps = {
  warnList: TCloneTarget[] | null;
  fromList: TItem[];
};

const WarningList: FC<WarningListProps> = ({ warnList, fromList }) => {
  const { t } = useTranslation();
  return (
    <div className={css.content}>
      <div className={css.errors}>
        <div className={css.list}>
          {warnList &&
            !!warnList.length &&
            warnList.map((e) => {
              const findTitle = fromList.some(
                ({ xxhash }) => xxhash === e.xxhash,
              )
                ? fromList.find(({ xxhash }) => xxhash === e.xxhash)?.title
                : e.xxhash;
              return e.xxhash ? (
                <Error
                  title={findTitle as string}
                  text={e.message}
                  data={e.data}
                  key={e.xxhash}
                />
              ) : (
                <Error
                  title={t('creative.copy_creative.errors.tit')}
                  data={{}}
                  text={JSON.stringify(e, null, 2)}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default WarningList;
