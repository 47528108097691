import React, { useState, useEffect, FC, useMemo } from 'react';
import cn from 'classnames';
import { useMeasure } from 'react-use';
import { useSpring, animated, config } from 'react-spring';
import { FiChevronDown } from 'react-icons/fi';
import i18n from '../../../../../i18n';
import css from './styles.module.scss';

type ErrorProps = {
  title: string;
  text: string;
  data: Record<string, string[]>;
};

const Error: FC<ErrorProps> = ({ title, text, data }) => {
  const desc = useMemo<string>(() => {
    let obj: Record<string, string[]> = {};
    const keys = Object.keys(data);
    keys.forEach((key) => {
      obj = { ...obj, [i18n.t(`common.targetings.${key}`)]: data[key] };
    });
    let str = '';
    Object.keys(obj).forEach((key) => {
      str = `${str} ${key}:[${obj[key]}]\n`;
    });
    return str;
  }, [data]);

  const [toggle, setToggle] = useState<boolean>(true);
  const [contentHeight, setContentHeight] = useState<number>(0);

  const [ref, { height }] = useMeasure<HTMLDivElement>();

  const props = useSpring({
    config: config.default,
    height: toggle ? `${contentHeight}px` : '0px',
  });

  useEffect(() => {
    setContentHeight(height);
  }, [height]);

  return (
    <div className={cn(css.error, { _isToggle: toggle })}>
      <div
        className={css.title}
        onClick={() => {
          setToggle(!toggle);
        }}
      >
        <span>{title}</span>
        <FiChevronDown size={16} color="#0a0a0a" />
      </div>
      <animated.div className={css.text} style={props}>
        <div ref={ref}>
          <pre>
            {text}
            {desc}
          </pre>
        </div>
      </animated.div>
    </div>
  );
};

export default Error;
