import React from 'react';
import Error from './Error';

export interface State {
  hasError: boolean;
  error?: Error;
}
// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

class ErrorBoundary extends React.Component<Props, State> {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      error,
    };
  }

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
    this.changeHasErrorState = this.changeHasErrorState.bind(this);
  }

  changeHasErrorState(): void {
    this.setState({
      hasError: false,
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public render() {
    const { children } = this.props;
    const { hasError, error } = this.state;

    if (hasError) {
      return (
        <Error error={error} changeHasErrorState={this.changeHasErrorState} />
      );
    }

    return children;
  }
}
export default ErrorBoundary;
