import { shallowEqual, useSelector } from 'react-redux';
import { AppState } from '../../../redux/rootReducer';
import { setFormDefault, UploadState } from '../reducer';
import { TRFileZip, TVariationNameFileZip } from '../types';
import { useDispatchApp } from '../../../redux/rootSelectors';
import { FetchedDataErrors } from '../../../types/general';

type TFUseUploadFilesZip = (p: TVariationNameFileZip) => {
  data: TRFileZip | null;
  isLoading: boolean;
  error: FetchedDataErrors;
  LTU: number;
  formDefault: boolean;
};
type TFUseFormResetUploadDefault = (p: TVariationNameFileZip) => {
  isReset: () => void;
};

export const useUploadFilesZip: TFUseUploadFilesZip = (name) => {
  const tempFile = useSelector<AppState, UploadState['tempFileZip']>(
    ({ uploadReducer }) => uploadReducer.tempFileZip,
    shallowEqual,
  );

  const data = tempFile[name].get('data');
  const isLoading = tempFile[name].get('isLoading');
  const error = tempFile[name].get('error');
  const LTU = tempFile[name].get('LTU');

  const { formDefault } = useSelector<AppState, UploadState>(
    ({ uploadReducer }) => uploadReducer,
    shallowEqual,
  );

  return {
    data,
    isLoading,
    error,
    LTU,
    formDefault,
  };
};

/** функция проверяет состояние загрузки файлов в сторе и сбрасывает их по умолчанию  */
export const useFormResetUploadDefault: TFUseFormResetUploadDefault = (
  name,
) => {
  const dispatch = useDispatchApp();
  const { formDefault, error, data } = useUploadFilesZip(name);
  return {
    isReset: () => {
      if (!formDefault && (data || error.isError)) {
        dispatch(setFormDefault(true));
      }
    },
  };
};
