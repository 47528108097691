import React, { FC } from 'react';
import Card from 'components/UI/Card';
import Load from 'components/UI/Load';
import css from '../styles.module.scss';

const LoadParameters: FC = () => (
  <Card
    noHover
    style={{ padding: '28px 24px' }}
    isUseAccess
    className={css.card}
  >
    <Load
      style={{
        width: '140px',
      }}
    />
    <div className={css.form}>
      <Load
        style={{
          width: '100%',
          height: '42px',
        }}
      />
      <Load
        style={{
          width: '100%',
          height: '42px',
        }}
      />
      <Load
        style={{
          width: '100%',
          height: '42px',
        }}
      />
      <Load
        style={{
          width: '100%',
          height: '42px',
        }}
      />
      <Load
        style={{
          width: '100%',
          height: '42px',
        }}
      />
      <Load
        style={{
          width: '100%',
          height: '42px',
        }}
      />
      <Load
        style={{
          width: '100%',
          height: '42px',
        }}
      />
      <Load
        style={{
          width: '100%',
          height: '42px',
        }}
      />
    </div>
  </Card>
);

export default LoadParameters;
