import React, { FC, useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import {
  FiMoreHorizontal,
  FiTrash2,
  FiEdit,
  FiUserCheck,
} from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/UI/Tooltip';
import ToggleSwitch from 'components/UI/ToggleSwitch';
import { ReactSVG } from 'react-svg';
import { useDispatchApp } from 'redux/rootSelectors';
import { editUser, sendConfirmationEmail } from 'domains/users/model/actions';
import { useUserInfo } from 'domains/user/model/selectors';
import { someGroups } from 'utils/statics/everyGroups';
import GroupHint from 'components/UI/GroupHint';
import css from './styles.module.scss';
import { GetManagementUsersList, TUsersTab } from '../../model/types';
import avatarColors from '../../../../utils/avatarColors';
import { activityUserKeys } from '../../model/shared';
import { TFUseDataUsers } from '../../model/hooks';
import { formatterMinuteForHour } from '../../model/utils/formatterTime';
import lockSvg from '../../../../assets/lock.svg';
import unlockSvg from '../../../../assets/unlock.svg';
import EditUserModal from '../modal/settingsUser';
import noDoneRegstrSVG from '../icons/noDoneRegstr.svg';

interface Props {
  /** параметры пользователя */
  user: GetManagementUsersList;
  /** вкладка страницы пользователей */
  tabs: TUsersTab;
  /** функция получения статистики */
  getStatisticsUser: ReturnType<TFUseDataUsers>['getStatisticsUser'];
}

const Item: FC<Props> = ({ user, tabs, getStatisticsUser }) => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(false);
  const itemRef = useRef<HTMLDivElement>(null);
  const { status, email, groups, partner, need_confirmation } = user;
  const [activeStatus, setStatus] = useState(status === 'active');
  const groupsCollapsedDisplay = 3;
  const [edit, setEdit] = useState(false);
  const [registDone, setRegistrDone] = useState(false);
  const { data: userInfo } = useUserInfo();
  const dispatch = useDispatchApp();
  const mainRef = useRef<HTMLDivElement | null>(null);
  const [openGroups, setOpenGroups] = useState(false);
  const counter = groups.length - groupsCollapsedDisplay > 0;

  const toggleHandler = () => {
    dispatch(
      editUser({
        email: user.email,
        is_active: !activeStatus,
        callbacks: {
          onToggle: setStatus,
        },
      }),
    );
  };

  const confirmationEmail = () => {
    if (user?.email) {
      dispatch(
        sendConfirmationEmail({
          email: user.email,
          domain: window.location.host,
          callbacks: {
            onConfirm: setRegistrDone,
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (status === 'active') {
      setStatus(true);
    }
  }, [status]);

  return (
    <div className={cn(css.wrapper)}>
      <div className={css.indicator} />
      <div
        className={cn(css.item, {
          _isOpen: toggle,
          _isOnline: tabs === 'online',
          _isStatistics: tabs === 'statistics',
        })}
        ref={itemRef}
      >
        <div className={css.item__header}>
          <div
            className={css.img}
            style={
              partner.icon
                ? {
                    backgroundImage: `url(${partner.icon})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }
                : {
                    backgroundColor: avatarColors(
                      email.slice(0, 1).toLowerCase(),
                    ),
                  }
            }
          >
            {partner.icon ? '' : email.slice(0, 1).toUpperCase()}
            <div
              className={css.status}
              style={{
                background: activeStatus ? '#149C08' : '#FF3636',
              }}
            />
          </div>
          <div className={css.text}>
            <Tooltip
              className={css.tooltip}
              hintWidth={300}
              leftArrowPosition
              title={<strong>{email}</strong>}
            >
              <p>{email}</p>
            </Tooltip>
          </div>
        </div>

        {tabs === 'allUsers' && (
          <div
            className={css.value}
            ref={mainRef}
            onMouseEnter={() => setOpenGroups(true)}
            onMouseLeave={() => setOpenGroups(false)}
          >
            {groups.map(
              (group, index) =>
                index < groupsCollapsedDisplay && (
                  <span key={group} className={css.group}>
                    {group}
                  </span>
                ),
            )}
            {counter && (
              <>
                <span className={css.group}>
                  +{groups.length - groupsCollapsedDisplay}
                </span>
              </>
            )}
          </div>
        )}

        {tabs !== 'statistics' && (
          <>
            <div className={css.value}>
              <Tooltip
                className={css.login}
                hintWidth={300}
                leftArrowPosition
                title={
                  <>
                    <span>
                      <strong>{partner.title}</strong>
                    </span>
                    <span className={css.xxhash}>{`(${
                      partner.internal_id ? `${partner.internal_id} ` : ''
                    }${partner.xxhash})`}</span>
                  </>
                }
              >
                <p>
                  <span className={css.tooltipHint}>
                    <strong>{partner.title}</strong>
                  </span>
                  <span
                    className={css.tooltipHint}
                  >{`${partner?.internal_id} ${partner.xxhash}`}</span>
                </p>
              </Tooltip>
            </div>
            <div className={css.value}>{t(`type_parther.${partner.type}`)}</div>

            <div className={cn(css.value, 'isStatus')}>
              {activeStatus ? (
                <ReactSVG src={unlockSvg} />
              ) : (
                <ReactSVG src={lockSvg} />
              )}
            </div>
          </>
        )}

        {tabs === 'statistics' && (
          <>
            {activityUserKeys.map((k) => (
              <div key={k} className={css.value}>
                {typeof getStatisticsUser(email, k) === 'boolean' ? (
                  <div className={css.loadItem} />
                ) : (
                  <>
                    {k === 'active_time'
                      ? formatterMinuteForHour(
                          getStatisticsUser(email, k) as number,
                        )
                      : getStatisticsUser(email, k)}
                  </>
                )}
              </div>
            ))}
          </>
        )}

        <div
          className={cn('toggle-dropdown', css.dropdown, { _isOpen: toggle })}
          onMouseEnter={() => {
            setToggle(true);
          }}
          onMouseLeave={() => {
            setToggle(false);
          }}
        >
          <button type="button" className={css.dropdown__show}>
            <FiMoreHorizontal size={24} />
          </button>

          <div className={css.dropdown__menu}>
            {need_confirmation ? (
              <Tooltip
                isDisabled={!someGroups(userInfo, 'owner', 'self_user')}
                title={
                  registDone ? (
                    <div className="icon">
                      <FiUserCheck size={24} />
                    </div>
                  ) : (
                    <ReactSVG src={noDoneRegstrSVG} className="icon" />
                  )
                }
                onClick={confirmationEmail}
              >
                <span>
                  {registDone
                    ? t('users_page.settings_modal.registration_done')
                    : t('users_page.settings_modal.complete_registration')}
                </span>
              </Tooltip>
            ) : (
              <></>
            )}

            <Tooltip
              isDisabled={!someGroups(userInfo, 'owner', 'self_user')}
              title={<FiEdit size={24} />}
              onClick={() => setEdit(true)}
            >
              <span>{t('users_page.table.edit_user')}</span>
            </Tooltip>

            <Tooltip isDisabled title={<FiTrash2 size={24} />}>
              <span>{t('users_page.table.delete_user')}</span>
            </Tooltip>

            <Tooltip
              isDisabled={!someGroups(userInfo, 'owner', 'self_user')}
              title={
                <ToggleSwitch
                  disabled={!someGroups(userInfo, 'owner', 'self_user')}
                  onChange={toggleHandler}
                  checked={activeStatus}
                  toggleOnly
                />
              }
            >
              <span>
                {activeStatus
                  ? t('users_page.statuses.active')
                  : t('users_page.statuses.blocked')}
              </span>
            </Tooltip>
          </div>
        </div>
      </div>
      {edit && (
        <EditUserModal
          state={edit}
          mode="edit"
          onClose={() => setEdit(false)}
          item={user}
        />
      )}
      {openGroups && counter && <GroupHint groups={groups} mainRef={mainRef} noHintPortal={setOpenGroups}/>}
    </div>
  );
};

export default Item;
