import { SagaIterator } from '@redux-saga/core';
import { call } from '@redux-saga/core/effects';
import { makeReqWithRD, TMakeReqWithRD } from '../../../redux/makeReqWithRD';
import {
  getNotificationMessages,
  getNotificationSubscription,
  removeNotificationSubscription,
  setNotificationSubscription,
} from '../api';
import {
  setNotificationMessages,
  setNotificationSubscriptions,
  editSubscriptionRequest,
} from '../reducer';

export function* fetchNotificationMessagesSagaWorker(): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getNotificationMessages>>(makeReqWithRD, {
      fetcher: getNotificationMessages,
      fill: setNotificationMessages,
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* fetchNotificationSubscriptionsSagaWorker(): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getNotificationSubscription>>(
      makeReqWithRD,
      {
        fetcher: getNotificationSubscription,
        fill: setNotificationSubscriptions,
      },
    );
  } catch (e) {
    console.error({ e });
  }
}
export function* changeSubscripbeSagaWorker({
  payload,
}: ReturnType<typeof editSubscriptionRequest>): SagaIterator<void> {
  try {
    const { active, entity_id, entity_type } = payload;

    if (active) {
      yield call(setNotificationSubscription, { entity_type, entity_id });
    } else {
      yield call(removeNotificationSubscription, { entity_type, entity_id });
    }
    yield call(fetchNotificationSubscriptionsSagaWorker);
  } catch (e) {
    console.error({ e });
  }
}
