import Load from 'components/UI/Load';
import React, { FC } from 'react';
import css from './styles.module.scss';

const Loading: FC = () => (
  <div className={css.loading}>
    <Load
      style={{
        width: `100px`,
        height: '24px',
        marginBottom: '24px',
      }}
    />
    <Load
      style={{
        width: `280px`,
        height: '22px',
        marginBottom: '24px',
      }}
    />
    {Array(9)
      .fill(0)
      .map((item, index) => (
        <Load
          key={`${item + index}`}
          style={{
            width: `100%`,
            height: '36px',
          }}
        />
      ))}
  </div>
);

export default Loading;
