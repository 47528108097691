import React, { FC } from 'react';
import Help from 'components/Help';
import { checkedLocalization } from 'utils/checkedLocalization';
import { useUserTheme } from 'domains/user/model/selectors';
import FormTypeHTML from './HTML';
import Push from './PUSH';
import Yandex from './Yandex';
import Teaser from './Teaser';
import Video from './Video';
import VideoVAST from './VideoVAST';
import css from './styles.module.scss';
import {
  Creative,
  CreativeDetail,
  CreativeDetailHTML,
  CreativeDetailTeaser,
  TCreativeClickUnder,
  TCreativeCompanion,
  TCreativeMultiFormat,
  TCreativePush,
  TCreativeVideoInline,
  TCreativeVideoVast,
  TCreativeYandex,
} from '../../../../types';
import MultiFormat from './MultiFormat';
import i18n from '../../../../../../i18n';
import Companion from './Companion';
import Clickunder from './Clickunder';

type Props = {
  creative: CreativeDetail;
};

const Type: FC<Props> = ({ creative }) => {
  const title: Record<Creative['type'], string> = {
    HTML: `${i18n.t('creative.type.type')}HTML`,
    PUSH: `${i18n.t('creative.type.type')}Push`,
    YANDEX: `${i18n.t('creative.type.type')}Yandex HTML`,
    TEASER: `${i18n.t('creative.type.type')}${i18n.t('creative.type.teaser')}`,
    VIDEO_INLINE: `${i18n.t('creative.type.type')}${i18n.t(
      'creative.type.video',
    )}`,
    VIDEO_VAST: `${i18n.t('creative.type.type')}${i18n.t(
      'creative.type.video_vast',
    )}`,
    CLICKUNDER: `${i18n.t('creative.type.type')}${i18n.t(
      'creative.type.clickunder',
    )}`,
    MULTI_FORMAT: `${i18n.t('creative.type.type')}${i18n.t(
      'creative.create_menu.MULTI_FORMAT',
    )}`,
    COMPANION: `${i18n.t('creative.type.type')}Companion`,
  };
  
  const { getValueFromSettingForKey } = useUserTheme();
  return (
    <div className={css.customCard}>
      <h3 className={css.title}>
        {title[creative.type] ?? creative.type}
        {getValueFromSettingForKey('HideHelp') && (
          <Help
            width={200}
            title={`${title[creative.type]}`}
            helpattributes={{
              link: checkedLocalization(
                `creative.creative_prompt.type.${creative.type}.link`,
                `#`,
              ),
              sizeIcon: 18,
            }}
          />
        )}
      </h3>
      {creative.type === 'HTML' && (
        <FormTypeHTML
          creative={creative}
          data={creative.data as CreativeDetailHTML['data']}
        />
      )}

      {creative.type === 'PUSH' && (
        <Push data={creative.data as TCreativePush} creative={creative} />
      )}

      {creative.type === 'YANDEX' && (
        <Yandex
          xxhash={creative.xxhash}
          data={creative.data as TCreativeYandex}
        />
      )}
      {creative.type === 'TEASER' && (
        <Teaser
          xxhash={creative.xxhash}
          data={creative.data as CreativeDetailTeaser['data']}
          config_values={creative.config_values}
        />
      )}
      {creative.type === 'VIDEO_INLINE' && (
        <Video
          xxhash={creative.xxhash}
          size={creative.size}
          data={creative.data as TCreativeVideoInline}
          config_values={creative.config_values}
        />
      )}
      {creative.type === 'CLICKUNDER' && (
        <Clickunder
          xxhash={creative.xxhash}
          data={creative.data as TCreativeClickUnder}
          config_values={creative.config_values}
        />
      )}
      {creative.type === 'VIDEO_VAST' && (
        <VideoVAST
          xxhash={creative.xxhash}
          size={creative.size}
          data={creative.data as TCreativeVideoVast}
          config_values={creative.config_values}
        />
      )}
      {creative.type === 'MULTI_FORMAT' && (
        <MultiFormat
          creativeName={creative.title}
          xxhash={creative.xxhash}
          data={creative.data as TCreativeMultiFormat}
        />
      )}
      {creative.type === 'COMPANION' && (
        <Companion
          xxhash={creative.xxhash}
          size={creative.size}
          data={creative.data as TCreativeCompanion}
          config_values={creative.config_values}
        />
      )}
    </div>
  );
};

export default Type;
