import { useState, useCallback, useEffect, useRef } from 'react';
import { copySettingsPretargeting, copySettingsTargeting } from 'domains/target/api';
import { RCopyTarget } from '../../domains/target/model/types';

type TFUseClone = () => {
  onRequestClone: (params: {
    from_xxhash: string;
    to_xxhash_list: string[];
  }) => Promise<
    | {
        success: RCopyTarget[];
        errors: RCopyTarget[];
      }
    | undefined
  >;
  progress: number;
  copying: boolean;
  setCopying: (v: boolean) => void;
  warning: boolean;
  setWarning: (v: boolean) => void;
  onRequestForcedClone: (params: {
    from_xxhash: string;
    to_xxhash_list: string[];
  }) => Promise<RCopyTarget[] | undefined>;
  warnList: RCopyTarget[];
  links: string[];
  isCopyPretargets: boolean;
  setIsCopyPretargets: React.Dispatch<React.SetStateAction<boolean>>;
};

const useClone: TFUseClone = () => {
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);
  const [copying, setCopying] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [warnList, setWarnList] = useState<RCopyTarget[]>([]);
  const [links, setLinks] = useState<string[]>([]);

  const setTimer = useCallback(() => setProgress(progress + 1), [progress]);

  useEffect(() => {
    if (loading && progress !== 80) {
      interval.current = setInterval(setTimer, 100);
    } else if (interval.current) {
      clearInterval(interval.current);
    }

    return function () {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [loading, progress]);

  useEffect(
    () => () => {
      setWarnList([]);
      setWarning(false);
    },
    [],
  );

  const showResult = () => {
    setLoading(false);
    setProgress(100);

    // tslint:disable-next-line: no-inferred-empty-object-type
    return new Promise<void>((res) =>
      setTimeout(() => {
        setCopying(false);
        setProgress(0);
        res();
      }, 400),
    );
  };

  const [isCopyPretargets, setIsCopyPretargets] = useState(false);

  const onRequestClone: ReturnType<TFUseClone>['onRequestClone'] =
    async (params: {
      from_xxhash: string;
      to_xxhash_list: string[];
      // eslint-disable-next-line consistent-return
    }) => {
      try {
        setLoading(true);
        setCopying(true);

        const promiseArr: Promise<any>[] = []
        promiseArr.push(copySettingsTargeting({ ...params }))
        if (isCopyPretargets) {
          promiseArr.push(copySettingsPretargeting({ ...params }))
        }
        const result = await Promise.all(promiseArr);

        const [copyTargetData] = result as [RCopyTarget[], boolean]

        const errorsResult = copyTargetData.filter(({ status }) => !status);
        const successResult = copyTargetData.filter(({ status }) => status);

        if (errorsResult.length > 0) {
          setLinks(params.to_xxhash_list);
          await showResult();
          setWarning(true);
          setWarnList(errorsResult);
        }

        await showResult();
        return {
          success: successResult,
          errors: errorsResult,
        };
      } catch (err) {
        await showResult();
        console.log('err', err);
      }
    };

  const onRequestForcedClone: ReturnType<TFUseClone>['onRequestForcedClone'] =
    async (params: {
      from_xxhash: string;
      to_xxhash_list: string[];
      // eslint-disable-next-line consistent-return
    }) => {
      try {
        setLoading(true);
        const promiseArr: Promise<any>[] = []
        promiseArr.push(copySettingsTargeting({ ...params }))
        if (isCopyPretargets) {
          promiseArr.push(copySettingsPretargeting({ ...params }))
        }
        const result: RCopyTarget[] = await Promise.all(promiseArr)[0];

        await showResult();
        return result;
      } catch (err) {
        await showResult();
        console.log('err', err);
      }
    };

  return {
    onRequestClone,
    progress,
    copying,
    setCopying,
    setIsCopyPretargets,
    warning,
    setWarning,
    onRequestForcedClone,
    warnList,
    links,
    isCopyPretargets,
  };
};

export default useClone;
