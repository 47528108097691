import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from '../../../redux/fetchedData';
import { TOffersList } from './types';

export type offersState = {
    offersList: FetchedData<TOffersList>;
};

export const initialState: offersState = {
    offersList: genFetchedData<TOffersList>(null),
};

const offersSlice = createSlice({
  name: 'Offers',
  initialState,
  reducers: {
    setOffersList(
      state,
      action: PayloadAction<offersState['offersList']>,
    ) {
      state.offersList = action.payload;
    },
  },
});

export const {
  setOffersList,
} = offersSlice.actions;
export const offersReducer = offersSlice.reducer;