import { createAction } from '@reduxjs/toolkit';
import { TOfferAddAction, TOfferEditAction } from './types';

export const getOfferInfo = createAction<{ id: number }>(
  'offer/fetchOfferInfo',
);

export const addOffer = createAction<TOfferAddAction>('offer/addOffer');

export const editOffer = createAction<TOfferEditAction>('offer/editOffer');

export const countCreativesOffer = createAction<{
  id: number;
  callbacks: { setCreoCount: React.Dispatch<React.SetStateAction<number>> };
}>('offer/countCreativesOffer');
