import { FormikProps } from 'formik';
import { useEffect } from 'react';
import { useIsGroup } from 'domains/user/hooks';
import { TCampaignForm } from '../../../../../types';
import { useCard } from '../../Optimization/model/useCard';

type TFUseCard = (formik: FormikProps<TCampaignForm>) => {
  showHint: boolean;
  closeHint: () => void;
};

export const useEditCard: TFUseCard = (formik) => {
  const { showHint, openHint, closeHint } = useCard(true);
  const { isRawState } = useIsGroup();

  const dataObj = formik.values.limits;
  const clickArr = Object.values(dataObj.click);
  const showArr = Object.values(dataObj.show);
  const showPerUserArr = Object.values(dataObj.show_per_user);
  const budgetArr = Object.values(dataObj.budget);
  const budgetRawArr = dataObj.budget_raw
    ? Object.values(dataObj.budget_raw)
    : [];

  useEffect(() => {
    const dataArr = isRawState
      ? [
          ...clickArr,
          ...showArr,
          ...showPerUserArr,
          ...budgetArr,
          ...budgetRawArr,
        ]
      : [...clickArr, ...showArr, ...showPerUserArr, ...budgetArr];
    const someEmpty = dataArr.some((item) => !item);
    if (
      someEmpty ||
      (isRawState && dataArr.length < 15) ||
      (!isRawState && dataArr.length < 12)
    ) {
      openHint();
    } else {
      closeHint();
    }
  }, [formik.values.limits]);

  return {
    showHint,
    closeHint,
  };
};
