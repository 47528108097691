import { useCallback, useEffect, useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { isShow, setOnCanseled, setLoadImageDefault } from './reduser';
import { fetchImage } from './actions';
import { useDispatchApp } from '../../../redux/rootSelectors';
import {
  useLinkToImageInfo,
  useLinkToIsShowInfo,
  useLinkToOnCanseledInfo,
} from './model/selectors';

type TFormUrl = {
  url: string;
};

type TFUseIsShowMLink = (v: boolean, onCanseled?: () => void) => () => void;
type TFUseLinkToImage = () => {
  errors: ReturnType<typeof useLinkToImageInfo>['error'];
  isShow: boolean;
  formik: FormikProps<TFormUrl>;
  onClose: () => void;
};

/** возврощает функцию по вызову которой открываается/закрывается модальное окно для линка */
export const useIsShowMLink: TFUseIsShowMLink = (v, onCanseled) => {
  const dispatch = useDispatchApp();
  const [wasOpened, setWasOpened] = useState<boolean>(false);
  const dataIsShow = useLinkToIsShowInfo();
  const canseled = useLinkToOnCanseledInfo();
  useEffect(() => {
    if (onCanseled === undefined) {
      dispatch(setOnCanseled(null));
      return;
    }
    dispatch(setOnCanseled(onCanseled));
  }, [onCanseled]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (dataIsShow) {
      return setWasOpened(true);
    }
    if (canseled !== null && !dataIsShow && wasOpened) {
      canseled();
      setWasOpened(false);
    }
  }, [dataIsShow, canseled]);
  return useCallback(() => dispatch(isShow(v)), []);
};

export const useLinkToImage: TFUseLinkToImage = () => {
  const dispatch = useDispatchApp();
  const { t } = useTranslation();
  const formik = useFormik<TFormUrl>({
    initialValues: {
      url: '',
    },
    onSubmit: (values) => {
      dispatch(fetchImage(values.url));
    },
    validationSchema: Yup.object({
      url: Yup.string()
        .url(t('clients_page.client_create.hint13'))
        .required(t('clients_page.client_create.hint1')),
    }),
  });
  const {
    data: tempImage,
    error: errors,
    isLoading: isLoadingImage,
    LTU,
  } = useLinkToImageInfo();

  const isShowInfo = useLinkToIsShowInfo();

  useEffect(() => {
    if (errors.isError) {
      dispatch(setLoadImageDefault());
    }
  }, [formik.values.url]);

  const onClose = useIsShowMLink(false);
  useEffect(() => {
    if (!isShowInfo) {
      dispatch(setOnCanseled(null));
      formik.resetForm();
    }
    return () => {
      dispatch(setLoadImageDefault());
    };
  }, [isShowInfo]);
  useEffect(() => {
    formik.setSubmitting(isLoadingImage);

    if (tempImage !== null) {
      onClose();
    }
  }, [LTU]);

  return {
    errors,
    isShow: isShowInfo,
    formik,
    onClose,
  };
};
