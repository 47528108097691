import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';
import { RClients } from '../../../../domains/clients/types';
import avatarColors from '../../../../utils/avatarColors';
import { THASH } from '../../../../types/general';

interface Props {
  client: RClients;
  type: 'CLIENT' | 'AGENCY';
  handleClick: (param: THASH, type: Props['type']) => void;
}

const Client: FC<Props> = ({
  client: { title, id, icon, default_icon },
  handleClick,
  type,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={css.client}
      onClick={() => {
        handleClick({ xxhash: id }, type);
      }}
    >
      {!default_icon ? (
        <div className={css.icon} style={{ backgroundImage: `url(${icon})` }} />
      ) : (
        <div
          className={cn(css.icon, '_isDefault')}
          style={{
            backgroundColor: avatarColors(title.slice(0, 1).toLowerCase()),
          }}
        >
          {title.slice(0, 1).toUpperCase()}
        </div>
      )}
      <div className={css.data}>
        <div className={css.title}>{title}</div>
        <div className={css.type}>
          {type === 'CLIENT' && t('just_words.w3')}
          {type === 'AGENCY' && t('just_words.w5')}
        </div>
      </div>
    </div>
  );
};

export default Client;
