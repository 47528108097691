import React, { FC, ReactNode } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import cn from 'classnames';
import css from './styles.module.scss';

interface IInfoBlockProps {
  color: 'BLUE';
  text: string | ReactNode;
}

const InfoBlock: FC<IInfoBlockProps> = ({ color, text }) => (
  <div
    className={cn(css.info, 'InfoBlock', {
      _isBlue: color === 'BLUE',
    })}
  >
    <FiAlertCircle size={20} />
    <div className={cn(css.info_text, 'InfoBlock_text')}>{text}</div>
  </div>
);

export default InfoBlock;
