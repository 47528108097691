const engAlphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
const rusAlphabet = 'абвгдежзиклмнопрстуфхцчшщыэюя'.split('');

const colors = [
  '#FF6665',
  '#3779BC',
  '#9D65A9',
  '#C44F69',
  '#6C65A9',
  '#A5C94B',
  '#59CD9C',
  '#FD9060',
  '#6FC3E0',
  '#FFBB5A',
  '#61E6C6',
  '#768FE9',
  '#B891F8',
  '#36CED7',
  '#63A421',
  '#C862EC',
  '#EF8BD9',
  '#5F6DE4',
  '#3137BD',
  '#F44F6D',
  '#35D274',
  '#464DE8',
  '#26A3FD',
  '#623EC8',
  '#72C221',
  '#A31ED2',
  '#EE4A4A',
  '#F2AE28',
  '#898DE9',
  '#0BBFB4',
];

export default (val: string): string => {
  const eng = /^[a-z]*$/;
  let index;

  if (/^[0-9]/.test(val)) {
    return colors[Number(val)];
  }

  if (eng.test(val)) {
    index = engAlphabet.findIndex((e) => e === val);
  } else {
    index = rusAlphabet.findIndex((e) => e === val);
  }

  if (index === -1) {
    index = colors.length - 1
  }

  return colors[index];
};
