import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { UnregisterCallback } from 'history';
import { FetchedData, genFetchedData } from '../../redux/fetchedData';
import {
  ResponseUser,
  ParamUserEdit,
  ParamSetPushTokenNotification,
  PSingIn,
  RAuthToken,
  PSingUp,
  TConfirm,
  TForGot,
  TForGotChangePassword,
  SettingConfig,
  TInfoByHash,
  PEditPasswordPayload,
} from './types';

export interface UserState {
  personalInfo: FetchedData<ResponseUser>;
  auth: FetchedData<RAuthToken>;
  authResponse: FetchedData<boolean>;
  hideNavigation: boolean;
  unblockSanfor: UnregisterCallback | null;
  settings: FetchedData<SettingConfig[]>;
  infoByHash: FetchedData<TInfoByHash>;
}

export const initialState: UserState = {
  personalInfo: genFetchedData<ResponseUser>(null),
  hideNavigation: false,
  unblockSanfor: null,
  auth: genFetchedData<RAuthToken>(null),
  authResponse: genFetchedData<boolean>(null),
  settings: genFetchedData<SettingConfig[]>(null),
  infoByHash: genFetchedData<TInfoByHash>(null),
};
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setPersonalInfo(state, action: PayloadAction<UserState['personalInfo']>) {
      state.personalInfo = action.payload;
    },
    setUserInfoSuccess(
      state,
      action: PayloadAction<UserState['personalInfo']>,
    ) {
      state.personalInfo = action.payload;
    },
    setUserInfoFinally(
      state,
      action: PayloadAction<UserState['personalInfo']>,
    ) {
      state.personalInfo = action.payload;
    },
    delPersonalInfo(state) {
      state.personalInfo = initialState.personalInfo;
    },
    setTempSingAuth(state, action: PayloadAction<UserState['auth']>) {
      state.auth = action.payload;
    },
    setTempSingAuthSuccess(state, action: PayloadAction<UserState['auth']>) {
      state.auth = action.payload;
    },
    setAuthReset(state) {
      state.auth = initialState.auth;
    },
    setAuthResponse(state, action: PayloadAction<UserState['authResponse']>) {
      state.authResponse = action.payload;
    },
    setAuthResponseReset(state) {
      state.authResponse = initialState.authResponse;
    },
    setHideNavigation(
      state,
      action: PayloadAction<UserState['hideNavigation']>,
    ) {
      state.hideNavigation = action.payload;
    },
    setUnblockSanfor(state, action: PayloadAction<UserState['unblockSanfor']>) {
      state.unblockSanfor = action.payload;
    },
    setSettingsUser(state, action: PayloadAction<UserState['settings']>) {
      state.settings = action.payload;
    },
    setInfoByHash(state, action: PayloadAction<UserState['infoByHash']>) {
      state.infoByHash = action.payload;
    },
  },
});

export const setEditPersonal = createAction<ParamUserEdit>('user/editPersonal');
export const sentPushNotificationRequest =
  createAction<ParamSetPushTokenNotification>(
    'user/sentPushNotificationRequest',
  );
export const fetchDCOUrl = createAction('user/fetchDCOUrl');

export const singInRequest = createAction<PSingIn>('user/singIn');

export const singUpRequest = createAction<PSingUp>('user/singUp');

export const setConfirmRequest = createAction<TConfirm>('user/userConfirm');

export const forGotPasswordRequest = createAction<TForGot>(
  'user/forgotPassword',
);

export const forGotChangeRequest = createAction<TForGotChangePassword>(
  'user/userChangePassword',
);

export const getTokenRequest = createAction('user/getToken');

export const refreshTokenRequest = createAction('user/refreshToken');

export const openUrlByToken = createAction<string>('user/openUrlByToken');

export const editPasswordAction = createAction<PEditPasswordPayload>('user/editPassword');

export const {
  setTempSingAuth,
  setAuthResponse,
  setPersonalInfo,
  setUserInfoSuccess,
  setHideNavigation,
  setUnblockSanfor,
  setTempSingAuthSuccess,
  setUserInfoFinally,
  setAuthResponseReset,
  setAuthReset,
  setSettingsUser,
  setInfoByHash,
} = userSlice.actions;
export const userReducer = userSlice.reducer;
