import cn from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/UI/Tooltip';
import css from '../styles.module.scss';

type PChartLegend = {
  backgroundColor: string;
  name: string;
  isOff?: boolean;
  onClick: () => () => void;
};

const ChartLegend: FC<PChartLegend> = ({
  backgroundColor,
  name,
  isOff,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <div className={cn(css.chart_item, { isOff })} onClick={onClick()}>
      <Tooltip
        className={css.tooltip}
        leftArrowPosition
        hintWidth={300}
        title={
          <>
            <div className={css.circle} style={{ backgroundColor }} />
            <span className={css.name}>{name}</span>
          </>
        }
      >
        {isOff
          ? t('campaigns_page.campaign_settings.reports.show_fromchart')
          : t('campaigns_page.campaign_settings.reports.hide_fromchart')}
      </Tooltip>
    </div>
  );
};

export default ChartLegend;
