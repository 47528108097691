import { TGetTechnicalInfo } from 'types/general';
import request from '../../../lib/helpers/requestApi';
import {
  CreateApiUser,
  TAPIMethods,
  /*   PEditUser,
  PCreate, */
  TAPIUser,
  TUserManipulationParams,
  editParams,
} from './types';

export const userAPICreate = request<boolean, CreateApiUser>({
  method: 'user.createTechnicalUser',
});

/* export const userEdit = request<boolean, PEditUser>({
  method: 'user.editFromOwner',
}); */

export const getAPIUserList = request<TAPIUser[]>({
  method: 'user.getTechnicalList',
});

export const deleteAPIUserAPI = request<boolean, TUserManipulationParams>({
  method: 'user.removeTechnicalUser',
});

export const refreshTokenAPIUserAPI = request<string, TUserManipulationParams>({
  method: 'user.updateTechnicalUserToken',
});

export const editAPIUserAPI = request<boolean, editParams>({
  method: 'user.editTechnical',
});

export const getAPIMethods = request<TAPIMethods>({
  method: 'user.getAllMethodsApi2',
});

export const getTechnicalInfo = request<TGetTechnicalInfo, { email: string }>({
  method: 'user.getTechnicalInfo',
});
