import React, { useEffect, useRef } from 'react';
import { ScrollSyncNode } from 'scroll-sync-react';
import HeaderCell from 'components/UI/Table/HeaderCell';
import {
  GeneralPParent,
  IonClickHeaderM,
  SortEvent,
  TNamingItem,
  TOnSetFilterTable,
} from '../../../../types';
import {
  istableEvents,
  MenuEvent,
} from '../../../../../../components/UI/ReportTable/SortHeader';

import css from './styles.module.scss';
import { TFilter } from '../../hooks/useIntegrationFilter';

type PHeader = {
  item: GeneralPParent['statistics'][0];
  namingItem: TNamingItem;
  setSortEvent: IonClickHeaderM['setSortEvent'];
  chartItems: IonClickHeaderM['chartItems'];
  setChartItems: IonClickHeaderM['setChartItems'];
  sortEvent: SortEvent;
  nochart: boolean;
  expand: number;
  setExpand: (v: number) => void;
  onSetFilterTable: TOnSetFilterTable;
  filterTable: TFilter['activeColumns'];
  allTitles?: string[];
  hScroll?: number;
  isUtmTags: boolean;
};

const onClickHeaderM =
  ({
    item,
    setSortEvent,
    chartItems,
    setChartItems,
    onSetFilterTable,
  }: // eslint-disable-next-line consistent-return
  IonClickHeaderM) =>
  // eslint-disable-next-line consistent-return
  (e: MenuEvent) => {
    if (istableEvents(e)) {
      return setSortEvent({ item, event: e });
    }

    if (e === 'ADD_CHART') {
      onSetFilterTable([]);
      return setChartItems([...chartItems, { name: item, isOff: false }]);
    }
    if (e === 'DEL_CHART') {
      onSetFilterTable([]);
      return setChartItems(chartItems.filter((i) => i.name !== item));
    }
  };

const Header: React.FC<PHeader> = ({
  namingItem,
  item,
  setSortEvent,
  chartItems,
  setChartItems,
  sortEvent,
  nochart,
  expand,
  setExpand,
  onSetFilterTable,
  filterTable,
  allTitles,
  isUtmTags,
  hScroll,
}) => {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollRef.current && hScroll) {
      scrollRef.current.scrollLeft = hScroll;
    }
  }, [hScroll]);

  const cell: React.ReactElement[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const key of namingItem.keys()) {
    const cellData = namingItem.get(key);
    // eslint-disable-next-line no-continue
    if (typeof cellData === 'undefined') continue;
    // eslint-disable-next-line no-continue
    if (cellData.noRender) continue;
    // eslint-disable-next-line no-continue
    if (item && Array.isArray(item[key])) continue;

    const nochartItem: boolean = nochart ? true : cellData.noChart === true;

    cell.push(
      <HeaderCell
        noChart={nochartItem}
        notSort={cellData.notSort}
        key={key}
        onClick={onClickHeaderM({
          item: key,
          setSortEvent,
          chartItems,
          setChartItems,
          onSetFilterTable,
        })}
        isShowExpand={cellData.isIndex}
        title={cellData.title}
        chartType={
          chartItems.find((i) => i.name === key) ? 'DEL_CHART' : 'ADD_CHART'
        }
        sortType={sortEvent.item === key ? sortEvent.event : undefined}
        expand={expand}
        setExpand={setExpand}
        onSetFilterTable={onSetFilterTable}
        filterTable={filterTable}
        allTitles={allTitles}
        isUtmTags={isUtmTags}
        headerRef={headerRef}
      />,
    );
  }

  return (
    <div className={css.header} ref={headerRef}>
      <div className={css.fixed} style={{ width: `${expand}px` }}>
        {cell.shift()}
      </div>
      <ScrollSyncNode>
        <div
          className={css.scroll}
          ref={scrollRef}
          style={{ width: `calc(100% - ${expand}px)` }}
        >
          {cell.map((cellItem) => cellItem)}
        </div>
      </ScrollSyncNode>
    </div>
  );
};

export default Header;
