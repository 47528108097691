import { takeEvery, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
  fetchClientInfoById,
  fetchCreateClient,
  fetchLoadTempFile,
  fetchClientEdit,
  fetchGroupList,
  createGroup,
  deleteGroup,
  editClientSettings,
} from '../reduser';
import {
  workerCreateClient,
  workerfetchClientEdit,
  workerfetchClientInfoById,
  workerfetchLoadTempFile,
  workerGetGroupList,
  workerCreateGroupSaga,
  workerRemoveGroupSaga,
  workerEditClient,
} from './workers';

export default function* watchClient(): SagaIterator {
  yield takeLatest(fetchCreateClient, workerCreateClient);
  yield takeEvery<typeof fetchLoadTempFile>(
    fetchLoadTempFile,
    workerfetchLoadTempFile,
  );
  yield takeEvery<typeof fetchClientInfoById>(
    fetchClientInfoById,
    workerfetchClientInfoById,
  );
  yield takeLatest(fetchClientEdit, workerfetchClientEdit);
  yield takeLatest(fetchGroupList, workerGetGroupList);
  yield takeLatest(createGroup, workerCreateGroupSaga);
  yield takeEvery(deleteGroup, workerRemoveGroupSaga);
  yield takeLatest(editClientSettings, workerEditClient);  
}
