import React, { createContext, useContext, useReducer, Dispatch } from 'react';
import moment from 'moment';
import { parsingDate } from './momentParse';

// eslint-disable-next-line no-shadow
export enum KeysSettings {
  CAMPAIGN = 'CAMPAIGN',
  ALL_CAMPAIGNS = 'ALL_CAMPAIGNS',
  HISTORY = 'HISTORY',
  AGENCY_REPORT = 'AGENCY_REPORT',
  CLIENT_REPORT = 'CLIENT_REPORT',
  BLACK_LIST = 'BLACK_LIST',
  DEFAULT = '',
}

export type InitialStateType = {
  theme: { containerToggle: boolean };
  settings: Record<string, any>;
};

export type IState = {
  theme: { containerToggle: boolean };
  settings: Record<string, any>;
};

type Reducer<S, A> = (state: S, action: A) => S;

type Action =
  | { type: 'toggleContainer'; payload: boolean }
  | { type: 'setSettings'; payload: Record<string, any> };

type IStateProvider = {
  reducer: Reducer<IState, Action>;
  children: React.ReactNode;
  initialState: IState;
};
type TFUseStateValue = () => {
  state: IState;
  dispatch: Dispatch<Action>;
};

function compare(settings: any, items: Record<string, any>): any {
  const keys: string[] = Object.keys(settings || {});
  if (typeof settings !== 'object') return items;

  return keys.reduce((acc, curr) => {
    if (!items[curr]) return { ...acc, [curr]: settings[curr] };

    return { ...acc, [curr]: compare(settings[curr], items[curr]) };
  }, {});
}

export const getSettings = (): any =>
  JSON.parse(localStorage.getItem('$settings') || '{}');

export const setSettings = (settings: Record<string, any>): void =>
  localStorage.setItem('$settings', JSON.stringify(settings));

export const initialSettings = (): any => {
  const items: Record<string, any> = getSettings();

  const rangeWeek = {
    from: parsingDate(moment(new Date()).subtract(6, 'days')).format(
      'YYYY-MM-DD',
    ),
    to: parsingDate(new Date()).format('YYYY-MM-DD'),
  };

  const rangeDay = {
    from: parsingDate(new Date()).format('YYYY-MM-DD'),
    to: parsingDate(new Date()).format('YYYY-MM-DD'),
  };

  const rangeYesterday = {
    from: parsingDate(moment().subtract(1, 'days')).format('YYYY-MM-DD'),
    to: parsingDate(moment().subtract(1, 'days')).format('YYYY-MM-DD'),
  };

  const range30Days = {
    from: parsingDate(moment().subtract(29, 'days')).format('YYYY-MM-DD'),
    to: parsingDate(new Date()).format('YYYY-MM-DD'),
  };

  const rangeMonth = {
    from: parsingDate(new Date()).startOf('month').format('YYYY-MM-DD'),
    to: parsingDate(new Date()).format('YYYY-MM-DD'),
  };

  const settings: Record<string, any> = {
    [KeysSettings.HISTORY]: {
      period: rangeWeek,
      fullTitle: false,
      type: 'week',
      limit: {
        from: 0,
        to: 200,
      },
    },
    [KeysSettings.CAMPAIGN]: {
      period: rangeWeek,
      fullTitle: false,
      type: 'week',
    },
    [KeysSettings.ALL_CAMPAIGNS]: {
      period: rangeWeek,
      fullTitle: false,
      type: 'week',
    },
    [KeysSettings.AGENCY_REPORT]: {
      period: rangeWeek,
      fullTitle: false,
      type: 'week',
    },
    [KeysSettings.CLIENT_REPORT]: {
      period: rangeWeek,
      fullTitle: false,
      type: 'week',
    },
    [KeysSettings.BLACK_LIST]: {
      period: rangeDay,
      fullTitle: false,
      type: 'day',
    },
  };

  const newItems = compare(settings, items);

  const filtredItems = Object.keys(newItems).reduce((acc: any, curr: any) => {
    const currType = newItems[curr].type;

    if (currType === 'day') {
      return {
        ...acc,
        [curr]: {
          ...newItems[curr],
          period: {
            ...rangeDay,
          },
        },
      };
    }

    if (currType === 'week') {
      return {
        ...acc,
        [curr]: {
          ...newItems[curr],
          period: {
            ...rangeWeek,
          },
        },
      };
    }

    if (currType === 'yesterday') {
      return {
        ...acc,
        [curr]: {
          ...newItems[curr],
          period: {
            ...rangeYesterday,
          },
        },
      };
    }

    if (currType === '30days') {
      return {
        ...acc,
        [curr]: {
          ...newItems[curr],
          period: {
            ...range30Days,
          },
        },
      };
    }

    if (currType === 'month') {
      return {
        ...acc,
        [curr]: {
          ...newItems[curr],
          period: {
            ...rangeMonth,
          },
        },
      };
    }

    return {
      ...acc,
      [curr]: {
        ...newItems[curr],
      },
    };
  }, {});

  localStorage.setItem('$settings', JSON.stringify(filtredItems));

  return filtredItems;
};

export const initialState: IState = {
  theme: { containerToggle: false },
  settings: {
    ...initialSettings(),
  },
};

export const StateContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const StateProvider: React.FC<IStateProvider> = ({
  reducer,
  // eslint-disable-next-line no-shadow
  initialState,
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateValue: TFUseStateValue = () => useContext(StateContext);

export const reducer = (state: IState, action: Action): IState => {
  switch (action.type) {
    case 'toggleContainer':
      return {
        ...state,
        theme: {
          ...state,
          containerToggle: action.payload,
        },
      };

    case 'setSettings':
      // eslint-disable-next-line no-case-declarations
      const { key, item } = action.payload;
      // eslint-disable-next-line no-case-declarations
      const settings = {
        ...state.settings,
        [key]: {
          ...state.settings[key],
          ...item,
        },
      };

      setSettings(settings);

      return {
        ...state,
        settings,
      };

    default:
      return state;
  }
};
