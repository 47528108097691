import UploadImage from 'domains/uploads/UploadImage';
import React, { FC } from 'react';
import Input from 'components/UI/Input/input';
import { getSizes } from 'domains/uploads/helpers';
import { TTypeHookProps } from 'domains/creative/types';
import EridWarning from 'components/EridWarning';
import i18n from '../../../../../../../../i18n';
import css from '../../../styles.module.scss';
import { IUseTypeProp } from '../../../../hooks/useType';

type TTeaserProps = IUseTypeProp &
  TTypeHookProps & {
    setIsDisableSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
  };

const index: FC<TTeaserProps> = ({
  formik: { values, errors, setFieldValue, handleChange },
  erid,
  setIsDisableSaveButton,
}) => (
  <div className={css.form}>
    <UploadImage
      keyUploadFile="teaser"
      preview={getSizes(values.data.iconMask, values.data.iconFormats)}
      name="iconMask"
      setFieldValue={setFieldValue}
      label={i18n.t('creative.creasmall_set.icon_word')}
      customLinkTitle={i18n.t('creative.creasmall_set.linktoicon_word')}
      hintText={
        <span>
          {i18n.t('creative.creasmall_set.teaser_formats1')}
          <br />
          {i18n.t('creative.creasmall_set.teaser_formats2')}
        </span>
      }
      hasEdit
      edit
      isData
      file_ext={['.png', '.jpeg', '.jpg']}
      typeSection="teaser"
    />
    <UploadImage
      keyUploadFile="teaser"
      preview={getSizes(values.data.imgMask, values.data.imgFormats)}
      name="imgMask"
      setFieldValue={setFieldValue}
      label={i18n.t('creative.creasmall_set.image_word')}
      customLinkTitle={i18n.t('creative.creasmall_set.linktoimage_word')}
      hintText={
        <span>
          {i18n.t('creative.creasmall_set.teaser_formats3')}
          <br />
          {i18n.t('creative.creasmall_set.teaser_formats4')}
        </span>
      }
      hasEdit
      edit
      isData
      minResolution={100}
      file_ext={['.png', '.jpeg', '.jpg']}
      typeSection="teaser"
    />

    <Input
      label={i18n.t('creative.creasmall_set.title_word')}
      showValueCount
      inputAttributes={{
        name: 'data.title',
        placeholder: '',
        maxLength: 100,
        style: { height: 48 },
        value: values.data.title,
        onChange: handleChange,
      }}
    />

    <Input
      label={i18n.t('creative.creasmall_set.text_word')}
      isTextarea
      showValueCount
      inputAttributes={{
        name: 'data.text',
        placeholder: '',
        maxLength: 200,
        style: { height: 128 },
        value: values.data.text,
        onChange: handleChange,
      }}
    />

    {erid && (
      <>
        <Input
          label={erid.title}
          showValueCount
          error={!!errors.erid}
          errortext={errors.erid}
          inputAttributes={{
            name: 'erid',
            maxLength: 80,
            value: values.erid,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('erid', e.target.value.trim()),
          }}
        />
        {values.erid && (
          <EridWarning
            withAccept
            isChangeErid
            setIsDisableSaveButton={setIsDisableSaveButton}
          />
        )}
      </>
    )}
  </div>
);

export default index;
