import React, { FC } from 'react';
import cn from 'classnames';
import { FormikProps } from 'formik';
import { FormikValues } from 'domains/usersAPI/model/types';
import { useAPIMethods } from 'domains/usersAPI/model/selectors';
import { useTranslation } from 'react-i18next';
import Help from 'components/Help';
import css from './styles.module.scss';
import Item from './Item';

interface Props {
  formik: FormikProps<FormikValues>;
}

const Methods: FC<Props> = ({ formik }) => {
  const { data } = useAPIMethods();
  const { t } = useTranslation();

  return (
    <div className={css.methods}>
      <div className={css.title}>
        {t(`users_api.methods`)}{' '}
        <Help
          width={200}
          title={t(`users_api.methods`)}
          helpattributes={{
            link: `https://terratraf.readme.io/docs/api-2`,
            sizeIcon: 18,
          }}
        />
      </div>
      <div
        className={cn(css.checkboxes, {
          _isScroll: data ? data?.length > 7 : false,
        })}
      >
        {data?.map((item) => (
          <Item
            method={item.method}
            name={item.description}
            key={item.method}
            formik={formik}
          />
        ))}
      </div>
    </div>
  );
};

export default Methods;
