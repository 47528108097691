import React, { FC, useEffect, useLayoutEffect } from 'react';
import Loader from 'components/UI/Loader';
import { useLocation } from 'react-router-dom';
import {
  useBlockedAd,
  useFetchProject,
  useProjectInfo,
} from '../../project/hooks';
import { getTokenRequest } from '../../user/reduser';
import './styles.scss';
import { fetchProjectTechnicalWorks } from '../../project/reducer';
import { useDispatchApp } from '../../../redux/rootSelectors';

const ConfigWrap: FC = ({ children }) => {
  const dispatch = useDispatchApp();
  const location = useLocation();
  const paramHash = new URLSearchParams(location.search);
  const { isLoading } = useProjectInfo();
  const fetchProject = useFetchProject({
    domain: window.location.host,
    xxhashPartner: paramHash.get('u'),
  });

  useLayoutEffect(() => {
    const idInterval = setInterval(() => {
      if (document.hidden) return undefined;
      dispatch(fetchProjectTechnicalWorks({ domain: window.location.host }));
      return undefined;
    }, 120000);
    return () => {
      clearInterval(idInterval);
    };
  }, []);
  useEffect(() => {
    fetchProject();
    dispatch(getTokenRequest());

    if (process.env.REACT_APP_DEV_API === 'true') {
      const customApi = localStorage.getItem('CUSTOM_API_URL');

      localStorage.setItem(
        'CUSTOM_API_URL',
        customApi || process.env.REACT_APP_API || '',
      );
    }
    if (paramHash.get('a')) {
      localStorage.setItem('$user_update', paramHash.toString());
    }
  }, [fetchProject]);

  const { link, refLink } = useBlockedAd();

  return (
    <div>
      <a
        ref={refLink}
        href={link}
        className="link-style"
        target="_blank"
        rel="noopener noreferrer"
      >
        {link}
      </a>
      {isLoading && (
        <div className="loading">
          <Loader />
        </div>
      )}
      {children}
    </div>
  );
};

export default ConfigWrap;
