import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import css from './styles.module.scss';

interface ITrProps {
  total?: boolean;
  children: ReactNode;
  classname?: string;
}

const Tr: FC<ITrProps> = ({ total, children, classname }) => (
  <tr
    className={cn(classname, {
      [css.total]: total,
    })}
  >
    {children}
  </tr>
);

export default Tr;
