import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import InputAutoSave from 'components/UI/InputAutoSave';
import { FiTrash2 } from 'react-icons/fi';
import BaseModal from 'components/UI/BaseModal';
import Button from 'components/UI/Buttons/buttons';
import { Trans, useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { TGetAuditDictionary } from 'domains/agency/types';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import css from '../styles.module.scss';

interface IItemProps {
  data: TGetAuditDictionary;
  handleClickOpen: (id: string | number) => void;
  handleClose: () => void;
  openModal: (string | number) | null;
  name: string;
  formik: FormikProps<TGetAuditDictionary[]>;
  errorText: string;
  deleteItem: () => void;
  handlerInput: (currentSubmitHandler: void) => void;
  setCurrentIntermediateData?: Dispatch<
    SetStateAction<TGetAuditDictionary | undefined>
  >;
}

const Item: FC<IItemProps> = ({
  data,
  handleClickOpen,
  handleClose,
  openModal,
  name,
  formik,
  errorText,
  deleteItem,
  handlerInput,
  setCurrentIntermediateData,
}) => {
  const { t } = useTranslation();
  const isFocused = localStorage.getItem('isFocused');
  const [isGreen, setIsgreen] = useState<boolean>();
  const isLastItemAdded = localStorage.getItem('isLastItemAdded');
  const isAuditEdited = localStorage.getItem('isAuditEdited');

  const setGreenState = () => {
    setIsgreen(true);
    setTimeout(() => {
      setIsgreen(false);
    }, 3000);
  };

  useEffect(() => {
    if (isAuditEdited && JSON.parse(isAuditEdited) === data.id) {
      setGreenState();
    }
    localStorage.removeItem('isAuditEdited');
  }, [formik, data.id]);

  useEffect(() => {
    if (
      formik.values &&
      formik.values.length &&
      formik.values[formik.values.length - 1].id === data.id &&
      isLastItemAdded !== null
    ) {
      setGreenState();
    }
    localStorage.removeItem('isLastItemAdded');
  }, [formik.values, data.id]);

  const returnInputAutoSave = () => (
    <InputAutoSave
      isSaved={isGreen}
      autoFocus={isFocused ? JSON.parse(isFocused) === data.id : undefined}
      maxLength={20}
      handlerInputEnter={handlerInput}
      value={data.title}
      name={name}
      setFieldValue={setFieldValue}
      isError={!!errorText}
      errorText={errorText}
      label=""
      placeholder={t(`offers_page.enter_name`)}
      data={formik}
    />
  );

  const handleDelete = () => {
    const hasNumberId = formik.values.some(
      (item) => typeof item.id === 'number',
    );
    if (hasNumberId) {
      deleteItem();
    } else {
      const updatedData = formik.values.filter((item) => item.id !== data.id);
      formik.setValues(updatedData);
    }
  };

  const setFieldValue = (field: string, value: string) => {
    formik.setFieldValue(field, value);
    const obj = {
      id: data.id,
      title: value,
      status: 'LAUNCHED',
    };
    if (setCurrentIntermediateData) {
      setCurrentIntermediateData(obj);
    }
  };

  return (
    <div className={css.item}>
      {returnInputAutoSave()}
      <TooltipPoratal hint={t(`delete_btn`)}>
        <FiTrash2
          size={20}
          onClick={() => {
            if (typeof data.id === 'number') {
              handleClickOpen(data.id);
              return;
            }
            handleDelete();
          }}
        />
      </TooltipPoratal>
      {openModal === data.id && (
        <BaseModal
          onClose={() => handleClose()}
          actions={
            <>
              <Button
                title={t(`cancel_btn`)}
                transparent
                buttonAttributes={{
                  onClick: () => handleClose(),
                }}
              />
              <Button
                title={t(`delete_btn`)}
                buttonAttributes={{
                  onClick: () => {
                    handleDelete();
                    handleClose();
                  },
                }}
              />
            </>
          }
          title={
            <Trans
              i18nKey="agencies_page.report.del_au"
              values={{ title: data.title }}
              components={{ bold: <strong /> }}
            />
          }
          isOpen
        >
          <Trans
            i18nKey="agencies_page.report.del_ask"
            values={{ title: data.title }}
            components={{ bold: <strong /> }}
          />
        </BaseModal>
      )}
    </div>
  );
};

export default Item;
