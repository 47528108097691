import { useFormik, FormikHelpers, FormikErrors, FormikProps } from 'formik';
import { useDispatchApp } from 'redux/rootSelectors';
import { useHistory } from 'react-router';
import { useState } from 'react';
import { CreateApiUserPayload, FormikValues } from '../types';
import { createNewAPIUser } from '../actions';
import i18n from "../../../../i18n";

interface RCreateUserFormik {
  formik: FormikProps<FormikValues>;
  isLoadingCreate: boolean;
  tagValidationError: string;
}

const useCreateUserFormik = (): RCreateUserFormik => {
  const dispatch = useDispatchApp();

  const history = useHistory();

  const [isLoadingCreate, setisLoadingCreate] = useState(false);

  const [tagValidationError, setTagValidationError] = useState<string>('');  

  const goBack = () => {
    history.push('/control_panel/users_api');
  };

  const initialValues: FormikValues = {
    email: '',
    data: {
      password: '',
      partners: [],
      tags: [],
      groups: [],
      methods: [],
      isActive: true,
    },
  };

  const validate = (values: FormikValues) => {
    const errors: FormikErrors<FormikValues> = {};

    if (!values.data?.partners?.length) {
      if (!errors.data) {
        errors.data = {};
      }
      errors.data.partners = i18n.t(`creative.creative_requare`);
    }

    if (values.data?.password?.length && values.data?.password?.length <= 3) {
      if (!errors.data) {
        errors.data = {};
      }
      errors.data.password = i18n.t(`users_api.passwordHelp`);
    }

    return errors;
  };

  const handleSubmit = (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>,
  ) => {
    const { groups, partners, methods, ...restValues } = values.data;
    const params: CreateApiUserPayload = {
      data: restValues,
      callbacks: {
        goBack,
        setisLoading: setisLoadingCreate,
        onError: setTagValidationError,
      },
    };
    if (groups?.length) {
      params.data.groups = groups.map((group) => +group.value);
    }
    if (partners?.length) {
      params.data.partners = partners.map((partner) => partner.value);
    }
    if (methods?.length) {
      params.data.methods = methods;
    }
    dispatch(createNewAPIUser(params));

    actions.setSubmitting(false);
  };

  const formik = useFormik<FormikValues>({
    initialValues,
    validate,
    onSubmit: handleSubmit
  });

  return {
    formik,
    isLoadingCreate,
    tagValidationError,
  };
};

export default useCreateUserFormik;
