import i18next from 'i18next';
import { Tree, TPopular, RSearchGeoItem, TItems } from '../../types';

/**
 * рекусивная функция обновляет переданное дерево и его потомков на inherit
 * */
export const recursionChecked = (
  item: Tree[],
  deletedItemsArr?: string[],
  isInherit?: boolean,
): Tree[] => {
  if (item && item.length) {
    return item.map((e) => {
      if (e.geoname_id === -1) {
        return {
          ...e,
          isChecked: 'disable',
          children: recursionChecked(e.children, deletedItemsArr, isInherit),
        };
      }
      if (e.isChecked !== 'inherit' && deletedItemsArr) {
        deletedItemsArr.push(String(e.geoname_id));
      }
      return {
        ...e,
        bid_rate: isInherit ? 0 : 1,
        isSettings: false,
        isChecked: 'inherit',
        children: recursionChecked(e.children, deletedItemsArr, isInherit),
      };
    });
  }
  return [];
};

/**
 * рекусивная функция обновляет сбрасывает тоggle в дереве
 * */
export const recursionToggle = (
  item: Tree[],
  onlyChecked?: boolean,
  showOnlyChecked?: (arg: Tree) => boolean,
): Tree[] => {
  if (item && item.length) {
    return item.map((e) => {
      const toggleVal = onlyChecked && showOnlyChecked && showOnlyChecked(e);
      return e.children && e.children.length && e.type !== 'subdivisions'
        ? {
            ...e,
            isToggle: toggleVal,
            children: recursionToggle(e.children, onlyChecked, showOnlyChecked),
          }
        : e;
    });
  }
  return [];
};

/**
 * рекусивная функция обновляет дерево по настроенным таргетам
 * */
export const recursionServerChecked = (
  tree: Tree[],
  items: Record<string, TItems>,
): Tree[] => {
  const keys = Object.keys(items);
  if (tree && tree.length) {
    return tree.map((e) => {
      const bidRate = items?.[e.geoname_id]?.bid_rate;

      let parentBitrate =
        items?.[e.parent.subdivisions || '-1']?.bid_rate ??
        items?.[e.parent.cites || '-1']?.bid_rate ??
        items?.[e.parent.countries || '-1']?.bid_rate ??
        items?.[e.parent.continents || '-1']?.bid_rate;

      parentBitrate = parentBitrate !== undefined ? +parentBitrate : undefined;

      const isOTHER = e.geoname_id === -1;

      // eslint-disable-next-line no-nested-ternary
      const endBitrate = isOTHER
        ? undefined
        : bidRate !== undefined
        ? +bidRate
        : parentBitrate;

      if (keys.includes(`${e.geoname_id}`) && items[e.geoname_id].limits) {
        return {
          ...e,
          isChecked: items[e.geoname_id].is_checked ? 'active' : 'disable',
          bid_rate: endBitrate,
          limits: items[e.geoname_id].limits,
          children: recursionServerChecked(e.children, items),
        };
      }
      if (keys.includes(`${e.geoname_id}`)) {
        return {
          ...e,
          bid_rate: endBitrate,
          isChecked: items[e.geoname_id].is_checked ? 'active' : 'disable',
          children: recursionServerChecked(e.children, items),
        };
      }
      return {
        ...e,
        bid_rate: endBitrate,
        children: recursionServerChecked(e.children, items),
      };
    });
  }
  return [];
};

/**
 * функция проверяет есть ди у дочерних элементов активные элементы
 * */
export const recursionIsActiveElement = (
  items: Tree[],
  flag?: Tree | null,
): {
  activeElement: Tree | null;
} => {
  let result: Tree | null = flag || null;

  if (items && items.length) {
    for (let i = 0; i < items.length; i += 1) {
      if (items[i].isChecked === 'active') {
        result = items[i];
        break;
      } else {
        result = recursionIsActiveElement(
          items[i].children,
          result,
        ).activeElement;
      }
    }
  }
  return {
    activeElement: result,
  };
};

/**
 * функция проверяет есть ди у дочерних элементов отключенные элементы
 * */
export const recursionIsDisableElement = (
  items: Tree[],
  flag?: boolean,
): boolean => {
  let result = flag ?? false;

  if (items && items.length) {
    for (let i = 0; i < items.length; i += 1) {
      if (items[i].isChecked === 'disable' && items[i].geoname_id !== -1) {
        result = true;
        break;
      } else {
        result = recursionIsDisableElement(items[i].children, result);
      }
    }
  }
  return result;
};

/**
 * рукурсия находит все выключенные элементы в дочерних элементах
 * */
export const recursionDisableElement = (items: Tree[]): Tree[] => {
  let data: Tree[] = [];
  if (items && items.length) {
    items.forEach((item) => {
      if (item.isChecked === 'disable') {
        data = [...data, item];
      }
      if (item.isChecked !== 'active') {
        data = [...data, ...recursionDisableElement(item.children)];
      }
    });
  }

  return data;
};

/**
 * Рекурсия возвращает все активные элементы дерева
 * */
export const recursionActiveElement = (tree: Tree): Tree[] => {
  let data: Tree[] = [];
  if (tree.isChecked === 'active') {
    data = [...data, tree];
  }
  if (tree.children && tree.children.length) {
    tree.children.forEach((v) => {
      data = [...data, ...recursionActiveElement(v)];
    });
  }

  return data;
};

/**
 * Рекурсия возвращает все настроенные в текущей сессии редактирования элементы дерева
 * */
export const recursionCheckIsSettingsElement = (tree: Tree): Tree[] => {
  let data: Tree[] = [];
  if (tree.isSettings) {
    data.push(tree);
  }
  if (tree.children && tree.children.length) {
    tree.children.forEach((v) => {
      data = [...data, ...recursionCheckIsSettingsElement(v)];
    });
  }

  return data;
};

/**
 * функция возвращает массив дочерних элементов которые находятся в популярных
 * */
export const recursionChildPopular = (
  element: Tree,
  arPopular: TPopular[],
): number[] => {
  let data: number[] = [];

  if (arPopular.find(({ geoname_id }) => element?.geoname_id === geoname_id)) {
    data = [...data, element.geoname_id];
  }

  if (element?.children) {
    for (let i = 0; i < element.children.length; i += 1) {
      data = [
        ...data,
        ...recursionChildPopular(element.children[i], arPopular),
      ];
    }
  }

  return data;
};

/**
 * функция поиска елемента по geoname_id
 * */
export const recursionSearchElementById = (
  id: number,
  items: Tree[],
  flag?: Tree | null,
): Tree | null => {
  let data: Tree | null = flag ?? null;

  for (let i = 0; i < items.length; i += 1) {
    if (items[i].geoname_id === id) {
      data = items[i];
      break;
    } else if (items[i].children && items[i].children.length) {
      data = recursionSearchElementById(id, items[i].children, data);
    }
  }

  return data;
};

/**
 * функция удаляет лимиты у конкретного айтема
 * */

export const recursiveRemoveLimitsFromTree = (
  treeItem: Tree,
  k: string,
): Tree => {
  if (treeItem.geoname_id === +k) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { limits, ...restStat } = treeItem;
    restStat.isSettings = true;
    return restStat;
  }
  if (treeItem.children && treeItem.children.length) {
    return {
      ...treeItem,
      children: treeItem.children.map((child) =>
        recursiveRemoveLimitsFromTree(child, k),
      ),
    };
  }
  return treeItem;
};

/**
 * функция удаляет лимиты у всех дочерних айтемов
 * */

export const recursiveRemoveAllLimitsFromChildrenTree = (
  treeData: Tree[],
): Tree[] =>
  treeData.map((item) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { limits, ...restStat } = item;
    if (restStat.children && restStat.children.length) {
      return {
        ...restStat,
        children: recursiveRemoveAllLimitsFromChildrenTree(restStat.children),
      };
    }
    return restStat;
  });

const getItemCheckedStatus = (
  titleArr: string[],
  checkArr: string[],
  uncheckArr: string[],
) => {
  const lowTitles = titleArr.map((title) => title.toLowerCase());
  if (lowTitles.some((title) => checkArr.includes(title))) {
    return 'active';
  }
  if (lowTitles.some((title) => uncheckArr.includes(title))) {
    return 'disable';
  }
  return 'inherit';
};

export const recursiveCheckAllInArr = (
  treeItem: Tree,
  geoNameArr: number[],
  checkArr: string[],
  uncheckArr: string[],
  updatePopular: (item: Tree, isChecked: boolean) => void,
): Tree => {
  const titlesArr = [treeItem.name_ru, treeItem.name_en];
  if (treeItem.children && treeItem.children.length) {
    if (geoNameArr.includes(treeItem.geoname_id)) {
      const newCheckboxVal = getItemCheckedStatus(
        titlesArr,
        checkArr,
        uncheckArr,
      );
      updatePopular(treeItem, !(newCheckboxVal === 'active'));
      return {
        ...treeItem,
        isChecked: newCheckboxVal,
        isSettings: true,
        children: treeItem.children.map((item) =>
          recursiveCheckAllInArr(
            item,
            geoNameArr,
            checkArr,
            uncheckArr,
            updatePopular,
          ),
        ),
      };
    }
    return {
      ...treeItem,
      children: treeItem.children.map((item) =>
        recursiveCheckAllInArr(
          item,
          geoNameArr,
          checkArr,
          uncheckArr,
          updatePopular,
        ),
      ),
    };
  }
  if (geoNameArr.includes(treeItem.geoname_id)) {
    const newCheckboxVal = getItemCheckedStatus(
      titlesArr,
      checkArr,
      uncheckArr,
    );
    updatePopular(treeItem, !(newCheckboxVal === 'active'));
    return {
      ...treeItem,
      isChecked: newCheckboxVal,
      isSettings: true,
    };
  }
  return treeItem;
};

export const recursivePushLocations = (
  geoItem: RSearchGeoItem,
  arr: string[] = [],
): void => {
  const currentLang = i18next.language;
  const { name_ru, name_en, parent } = geoItem;
  const currentTitle =
    currentLang === 'ru' ? name_ru || name_en : name_en || name_ru;
  arr.push(currentTitle);
  if (parent) {
    recursivePushLocations(parent, arr);
  }
};

/**
 * Функция обновляет bid_rate у дочек когда родителю добавляем лимиты
 * */
export const recursiveUpdateChildrenBidRate = (item: Tree): Tree => {
  const updatedItem: Tree = { 
      ...item,
      bid_rate: item.bid_rate || 1,
    };

  if (item.children) {
      const updatedChildren = item.children.map((child) => recursiveUpdateChildrenBidRate(child));
      updatedItem.children = updatedChildren;
  }

  return updatedItem;
};
