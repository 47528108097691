import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatchApp } from 'redux/rootSelectors';
import { RProject } from 'domains/project/types';
import { TFormikExtract } from 'types/general';
import { useProjectInfo } from 'domains/project/hooks';
import { objectOptimizationWithFormik } from 'utils/objectOptimizationWithFormik/objectOptimizationWithFormik';
import { editProjectRequest } from 'domains/project/reducer';
import { setFormDefault } from 'domains/uploads/reducer';

type TProjectBrandingForm = {
  slogan: RProject['slogan'];
  background: RProject['background'];
  logotype: RProject['logotype'];
  logo_small: RProject['logo_small'];
  logo_medium: RProject['logo_medium'];
  logo_email: RProject['logo_email'];
  background_email: RProject['background_email'];
};

type TUseProjectBranding = TFormikExtract<TProjectBrandingForm> & {
  edit: boolean;
  setEdit: (p: boolean) => void;
  handleFormikSubmit: () => void;
  handleFormikReset: () => void;
};

export const useProjectBranding = (): TUseProjectBranding => {
  const { t } = useTranslation();
  const dispatch = useDispatchApp();

  const { data: project, LTU } = useProjectInfo();

  const [edit, setEdit] = useState(false);

  const formik = useFormik<TProjectBrandingForm>({
    enableReinitialize: true,
    initialValues: {
      slogan: '',
      logo_medium: '',
      logo_small: '',
      background: '',
      logotype: '',
      logo_email: '',
      background_email: '',
    },
    onSubmit: (values) => {
      if (project) {
        const param = objectOptimizationWithFormik<TProjectBrandingForm>(
          {
            slogan: project.slogan,
            logo_medium: project.logo_medium,
            logo_small: project.logo_small,
            background: project.background,
            logotype: project.logotype,
            background_email: project.background_email,
            logo_email: project.logo_email,
          },
          values,
        );
        if (param) {
          dispatch(
            editProjectRequest({
              data: { domain: window.location.host, ...param },
              value: false,
              setter: setEdit,
              alertText: t('project_page.forms.branding_title'),
            }),
          );
        } else {
          setEdit(false);
        }
      }
    },
  });
  useEffect(() => {
    if (project) {
      formik.setValues({
        slogan: project.slogan,
        logo_medium: project.logo_medium,
        logo_small: project.logo_small,
        background: project.background,
        logotype: project.logotype,
        background_email: project.background_email,
        logo_email: project.logo_email,
      });
    }
  }, [LTU]);

  /** функция отправки формы */
  const handleFormikSubmit = () => {
    formik.handleSubmit();
  };

  /** функция сброса формы */
  const handleFormikReset = () => {
    dispatch(setFormDefault(true));
    if (project) {
      formik.setValues({
        slogan: project.slogan,
        logo_medium: project.logo_medium,
        logo_small: project.logo_small,
        background: project.background,
        logotype: project.logotype,
        background_email: project.background_email,
        logo_email: project.logo_email,
      });
      setEdit(false);
    }
  };

  return {
    edit,
    setEdit,
    handleFormikSubmit,
    handleFormikReset,
    formikContext: formik,
    ...formik,
  };
};
