import React, { FC } from 'react';
import cn from 'classnames';
import Checkbox from 'components/UI/Checkbox/checkbox';
import Load from 'components/UI/Load';
import css from '../styles.module.scss';

const LoadingItem: FC = () => (
  <div className={cn(css.item, css.loading)}>
    <Checkbox
      label=""
      onlyBox
      className={css.checkbox}
      inputAttributes={{
        type: 'checkbox',
        defaultChecked: false,
        readOnly: true,
      }}
    />
    <Load
      style={{
        width: '100%',
      }}
    />
    <Load
      style={{
        width: '100%',
      }}
    />
    <Load
      style={{
        width: '100%',
      }}
    />
    <Load
      style={{
        width: '100%',
      }}
    />
    <Load
      style={{
        width: '100%',
      }}
    />
  </div>
);

export default LoadingItem;
