import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useUserInfo } from '../../../user/model/selectors';
import { GroupUser } from '../../../user/types';
import { usePartnerBalanceInfo } from '../../../partners/model/selector';
import { useDispatchApp } from '../../../../redux/rootSelectors';
import {
  fetcherPartnerBalance,
  fetchLinkBalance,
} from '../../../partners/model/actions';
import i18next from '../../../../i18n';
import { toastSuccess } from '../../../../utils/ToastUtils/toastUtils';

type TFUseUserBalance = () => {
  isShowBalance: boolean;
  handleFetchBalance: () => void;
  isLoading: ReturnType<typeof usePartnerBalanceInfo>['isLoading'];
  balance: ReturnType<typeof usePartnerBalanceInfo>['data'];
  amount: string;
  onChangeAmount: (e: ChangeEvent<HTMLInputElement>) => void;
  handleAmount: () => void;
};
export const useUserBalance: TFUseUserBalance = () => {
  const accessGroup: GroupUser = ['self_user', 'owner'];

  const dispatch = useDispatchApp();

  const location = useLocation();

  const history = useHistory();

  const param = new URLSearchParams(location.search);

  const [amount, setAmount] = useState<string>('');

  const { data: user, LTU } = useUserInfo();

  const onChangeAmount: ReturnType<TFUseUserBalance>['onChangeAmount'] = ({
    target,
  }) => {
    const val = target.value;
    const rex = /^(\d+\.?\d{0,2}|\.\d{0,2})$/;
    if (val.length) {
      if (rex.test(val)) {
        setAmount(target.value);
      }
    } else {
      setAmount(target.value);
    }
  };

  const isShowBalance = useMemo<boolean>(
    () =>
      !!(
        user &&
        user.partner.type === 'CLIENT' &&
        user.group.some((role) => accessGroup.includes(role))
      ),
    [LTU],
  );

  const { data: balance, isLoading } = usePartnerBalanceInfo();

  const handleFetchBalance = () => {
    if (user) {
      dispatch(fetcherPartnerBalance({ partner_xxhash: user.partner.xxhash }));
    }
  };

  const handleAmount = () => {
    if (user) {
      dispatch(
        fetchLinkBalance({ partner_xxhash: user.partner.xxhash, sum: +amount }),
      );
    }
  };

  useEffect(() => {
    if (user && isShowBalance) {
      dispatch(fetcherPartnerBalance({ partner_xxhash: user.partner.xxhash }));
    }
  }, [LTU]);

  useEffect(() => {
    const result = param.get('result');
    if (result === 'success') {
      toastSuccess(i18next.t(`profile.top_up_success`));
      history.push(location.pathname);
    }
    if (result === 'fail') {
      toastSuccess(i18next.t(`profile.top_up_error`));
      history.push(location.pathname);
    }
  }, [location]);

  return {
    balance,
    isLoading,
    isShowBalance,
    handleFetchBalance,
    amount,
    onChangeAmount,
    handleAmount,
  };
};
