import React, { FC } from 'react';
import { useUserTheme } from 'domains/user/model/selectors';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Helmet } from 'react-helmet';
import css from './styles.module.scss';
import { ReactComponent as NoAccessSvg } from '../../../../assets/errors/no-access.svg';

const NoAccess: FC = () => {
const { t } = useTranslation();

  const { isThemeExtended, isThemeFull } =
    useUserTheme();

  return (
    <div className={css.errorNoAcess}>
      <Helmet>
        <title>{t(`errors.err_access.owner`)}</title>
      </Helmet>
        <div
          className={cn('container resized', {
            extend: isThemeExtended,
            full: isThemeFull,
          })}
        >
          <div className={css.plug}>
            <NoAccessSvg />
            <span>{t(`errors.err_access.owner`)}</span>
          </div>
        </div>
    </div>
  );
};
export default NoAccess;