import { IBannerData } from 'domains/creative/types';
import React, { FC } from 'react';

interface IBannerProps {
  width: number;
  height: number;
  bannerData?: IBannerData;
  handleOnLoad: () => void;
}

const Banner: FC<IBannerProps> = ({
  width,
  height,
  bannerData,
  handleOnLoad,
}) => (
  <div>
    {bannerData && bannerData.data_url && (
      <iframe
        title="video_preview"
        src={bannerData.data_url}
        width={width}
        height={height}
        onLoad={handleOnLoad}
        style={{
          verticalAlign: 'middle',
        }}
      />
    )}
  </div>
);
export default Banner;
