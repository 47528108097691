import React from 'react';
import { components } from 'react-select';
import { Components } from 'react-select/src/components';
import css from '../../styles.module.scss';

const LimitedChipsContainer: Components['ValueContainer'] = ({
    children,
    hasValue,
    ...props
  }: any) => {
    if (!hasValue) {
      return (
        <components.ValueContainer
          {...props}
          className="partner_checkbox_select__value-container-empty"
        >
          {children}
        </components.ValueContainer>
      );
    }

    const CHIPS_LIMIT = 2;
    const [chips, otherChildren] = children;
    const overflowCounter = chips.slice(CHIPS_LIMIT).length;

    const displayChips = chips.slice(
      overflowCounter,
      overflowCounter + CHIPS_LIMIT,
    );

    return (
      <components.ValueContainer {...props} className={css.partner_container}>
        {displayChips}

        {overflowCounter > 0 && (
          <span className={css.incriment}>+{`${overflowCounter}`}</span>
        )}

        {otherChildren}
      </components.ValueContainer>
    );
  };

export default LimitedChipsContainer;