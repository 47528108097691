export const targetingDescription = {
  source: 'Источники',
  geo: 'Гео · страны',
  geomytarget: 'Гео · регионы и города',
  geo_id: 'Регионы показа',
  geo_raw: 'ГЕО +',
  os: 'Операционные системы',
  browser: 'Браузеры',
  city: 'Гео · регионы и города',
  browser_lang: 'Язык интерфейса браузера',
  au: 'Аудитории',
  au_bin_8: 'bin_au: Aidata (8)',
  au_bin_15: 'bin_au: UID 2.0 (15)',
  au_bin_20: 'bin_au: Аудитории пользователей клиента (20)',
  isp: 'Интернет—провайдеры',
  app: 'Приложения',
  private_deals: 'Прямые сделки',
  viewability: 'Viewability',
  last_domain: 'LastDomain',
  last_bundle: 'LastBundle',
  uniq_show_creative: 'По номеру показа креатива пользователю',
  uniq_show_creative_hour: 'По номеру показа креатива пользователю в час',
  uniq_show_creative_day: 'По номеру показа креатива пользователю в день',
  days_of_week: 'По дням недели · UTC +3',
  days_of_week_user: 'По дням недели · UTC пользователя',
  hours_of_day: 'По времени дня · UTC +3',
  hours_of_day_user: 'По времени дня · UTC пользователя',
  site_domain: 'Домены',
  site_uri: 'URL',
  adgroup: 'AdGroup · Пресеты Google',
  page_context: 'Контекст (сайты)',
  socdem: 'Соц.дем',
  au_bin_info: 'Аудитории',
  au_bin_lada: 'Аудитории',
  au_shop: 'Аудитории магазинов',
  uroven_dohoda: 'Уровень дохода',
  actions: 'Статистика по событиям в видеорекламе',
  actions_domain: 'Действия по доменам',
  au_bin_21: 'Аудитория площадок по ССП uid (21)',
  video_type: 'По формату видео-рекламы',
  semeinoe_polozhenie: 'Семейное положение',
  interesy: 'Интересы',
  clean_all_targeting_creative: 'Все таргеты креатива',
  clean_all_targeting_campaign: 'Все таргеты кампании',
  clean_all_targeting_success: 'успешно очищены',
  uniq_show_camp: 'По номеру показа кампании пользователю',
  uniq_show_camp_hour: 'По номеру показа кампании пользователю в час',
  uniq_show_camp_day: 'По номеру показа кампании пользователю в день',
  keywords: 'По ключевым словам и фразам',
  video_size: 'Размеры видео',
  sex: 'Пол',
  age: 'Возраст',
  sex_rtb: 'Пол RTB',
  categories_rtb: 'Интересы RTB',
  age_rtb: 'Возраст RTB',
  adn: 'Рекламные сети',
  publisher: 'По паблишерам',
  os_versions: 'По версии ОС',
  spotid: 'ID блока',
  rewarded: 'Реклама за вознаграждение',
  video_size_site_domain: 'Размер видео в разбивке по доменам',
};
