import fetchJsonp from 'fetch-jsonp';
import {
  PCreateLeftData,
  RSettingsItem,
  TLeftD,
  Tdata,
  TCreateData,
  RGetStatisticTarget,
  TItunesApiType,
  TItems,
} from './types';
import i18n from '../../../i18n';
import { mapKeysTurn } from '../const';

// Функция модифицирующая айтемы левой части таргета в зависимости от условий
export function createLeftD({
  k,
  title,
  is_checked = false,
  _isSettings,
  value,
  isChild = false,
  isToggle,
  sort = 500,
  children,
  isSourceLike,
  limits,
  parentData,
  padding,
}: PCreateLeftData): TLeftD {
  const currentItemData = {
    bid: (isSourceLike && parentData?.bid) || '',
    bid_start: (isSourceLike && parentData?.bid_start) || '',
    limits,
    bid_rate: (isSourceLike && parentData?.bid_rate) || '',
    maxbid: (isSourceLike && parentData?.maxbid) || '',
    type: (isSourceLike && parentData?.type) || '',
    hour: (isSourceLike && parentData?.hour) || '',
    is_checked: (isSourceLike && parentData?.is_checked) || is_checked,
  };

  return {
    ...currentItemData,
    sort,
    isToggle,
    isChild,
    parentData,
    key: k,
    value,
    title,
    _isSettings,
    children,
    paddingScale: padding ?? 1,
  };
}

// Рекурсивная функция ходит по дереву итемов таргета и модифицирует все левые айтемы
export const transformLeftDItemsNew = (
  item: RSettingsItem,
  padding: number,
  targetType: string,
  onlyCheckedVal: boolean,
  isSourceLike: boolean,
  savedItems?: Record<string, TItems>,
  parentData?: TLeftD,
  spotDict?: Map<string, string> | null,
): TLeftD => {
  if (savedItems && savedItems[item.key]) {
    const parentInfo = {
      key: item.key,
      sort: item.sort,
      ...savedItems[item.key],
      title: item.title,
      paddingScale: padding,
    };

    return {
      ...savedItems[item.key],
      key: item.key,
      sort: item?.sort ?? 500,
      title:
        item.key === 'n/a'
          ? 'OTHER'
          : (targetType === 'placement' && spotDict?.get(item.key)) ||
            item.title,
      _isSettings: false,
      isToggle: onlyCheckedVal,
      parentData,
      children:
        item.children &&
        Object.values(item.children).map((childItem) =>
          transformLeftDItemsNew(
            childItem,
            padding + 1,
            targetType,
            onlyCheckedVal,
            isSourceLike,
            savedItems,
            parentInfo,
          ),
        ),
      paddingScale: padding,
    };
  }

  return createLeftD({
    k: item.key,
    title: item.key === 'n/a' ? 'OTHER' : item.title,
    sort: item.sort,
    parentData,
    isSourceLike,
    isToggle: !item.children,
    children:
      item.children &&
      Object.values(item.children).map((childItem) =>
        transformLeftDItemsNew(
          childItem,
          padding + 1,
          targetType,
          onlyCheckedVal,
          isSourceLike,
          savedItems,
        ),
      ),
    padding,
  });
};

// Рекурсивная функция модифицирующая айтемы правой части таргета в зависимости от условий
export function createData({
  k,
  title,
  bid,
  bid_start,
  bid_rate,
  hour,
  maxbid,
  type,
  value,
  isSourceLike,
  children,
  savedItems,
  childrenStatistic,
  limits,
  parentData,
  minus_win_raw,
  ...restStat
}: TCreateData): Tdata {
  let transformedChildren: Tdata[] = [];

  // if(title === 'Shagonar'){
  //   // eslint-disable-next-line no-debugger
  //   debugger
  // }
  const parentValues = {
    bid: bid ?? (isSourceLike ? parentData?.bid || 0 : 0),
    bid_start: bid_start ?? (isSourceLike ? parentData?.bid_start || 0 : 0),
    bid_rate: bid_rate ?? (isSourceLike ? parentData?.bid_rate || 1 : 1),
    hour: hour ?? (isSourceLike ? parentData?.hour || '' : ''),
    maxbid: maxbid ?? (isSourceLike ? parentData?.maxbid || '' : ''),
    type: type ?? (isSourceLike ? parentData?.type || '' : ''),
    value: value ?? (isSourceLike ? parentData?.value || '' : ''),
  };

  // Возможно необходимо будет переделать логику, так как при сохранении теперь не все элементы попадают в массив savedItems
  if (children) {
    transformedChildren = children.map((item) =>
      savedItems && savedItems[item.key]
        ? createData({
            ...(childrenStatistic &&
              (childrenStatistic[item.key]?.total ||
                childrenStatistic[item.key])),
            k: item.key,
            title: item.title,
            bid: savedItems[item.key]?.bid,
            bid_start: savedItems[item.key]?.bid_start,
            bid_rate: savedItems[item.key]?.bid_rate,
            isSourceLike,
            hour: savedItems[item.key]?.hour,
            maxbid: savedItems[item.key]?.maxbid,
            type: savedItems[item.key]?.type,
            limits: savedItems[item.key]?.limits,
            value: savedItems[item.key]?.value,
            parentData: parentValues,
            savedItems,
            children: item.children && Object.values(item.children),
            childrenStatistic: childrenStatistic && childrenStatistic[item.key],
          })
        : createData({
            ...(childrenStatistic &&
              (childrenStatistic[item.key]?.total ||
                childrenStatistic[item.key])),
            k: item.key,
            title: item.key === 'n/a' ? 'OTHER' : item.title,
            isSourceLike,
            parentData: parentValues,
            bid_rate,
            children: item.children && Object.values(item.children),
            childrenStatistic: childrenStatistic && childrenStatistic[item.key],
          }),
    );
  }

  const stat = {} as Record<keyof typeof restStat, number>;
  const statKeys = Object.keys(restStat);

  statKeys.forEach((key) => {
    stat[key] = restStat[key] || 0;
  });



  return {
    ...stat,
    ...parentValues,
    minus_win_raw: minus_win_raw || 0,
    sdomain: '',
    key: k,
    title,
    parentData,
    limits,
    children: transformedChildren,
  };
}

// Функция ходит по дереву итемов таргета и модифицирует все правые айтемы
export const transformDataItemsNew = (
  item: RSettingsItem,
  targetType: string,
  isSourceLike: boolean,
  savedItems?: Record<string, TItems>,
  statisticData?: RGetStatisticTarget | null,
  spotDict?: Map<string, string> | null,
): Tdata => {
  const statistic =
    (statisticData && statisticData[item.key]?.total) ||
    (statisticData && statisticData[item.key]) ||
    [];
  const currentSavedData =
    savedItems && savedItems[item.key] ? savedItems[item.key] : ({} as TItems);

  const parentData = savedItems
    ? {
        key: item.key,
        title: item.title,
        sort: item.sort,
        bid: savedItems[item.key]?.bid,
        bid_start: savedItems[item.key]?.bid_start,
        bid_rate: savedItems[item.key]?.bid_rate,
        hour: savedItems[item.key]?.hour,
        maxbid: savedItems[item.key]?.maxbid,
        type: savedItems[item.key]?.type,
        limits: savedItems[item.key]?.limits,
        value: savedItems[item.key]?.value,
      }
    : undefined;

  return createData({
    ...statistic,
    ...currentSavedData,
    k: item.key,
    parentData,
    title: mapKeysTurn.has(item.key)
      ? mapKeysTurn.get(item.key) || ''
      : (targetType === 'placement' && spotDict?.get(item.key)) || item.title,
    children: item.children && Object.values(item.children),
    savedItems,
    childrenStatistic: statisticData && statisticData[item.key],
    isSourceLike,
  });
};

export const sortList = (
  dataL: TLeftD[],
  dataR: Tdata[],
): { leftSort: TLeftD[]; rightSort: Tdata[] } => {
  let leftSort: TLeftD[] = [];
  let rightSort: Tdata[] = [];
  const mapped: { index: number; value: number | undefined }[] = dataL.map(
    (el, i) => ({ index: i, value: el.sort }),
  );
  const mappedSort = mapped.sort((a, b) =>
    Number(a.value) >= Number(b.value) ? 1 : -1,
  );
  mappedSort.forEach((item) => {
    leftSort.push(dataL[item.index]);
    rightSort.push(dataR[item.index]);
  });
  if (leftSort.some((ld) => ld.children)) {
    rightSort = rightSort.map((rd, index) => {
      if (leftSort[index]?.children && rd?.children) {
        const { rightSort: childRightSort } = sortList(
          leftSort[index].children || [],
          rd.children,
        );
        return { ...rd, children: childRightSort };
      }
      return rd;
    });
    leftSort = leftSort.map((ld, index) => {
      if (ld?.children) {
        const { leftSort: childLeftSort } = sortList(
          ld.children,
          rightSort[index]?.children || [],
        );
        return { ...ld, children: childLeftSort };
      }
      return ld;
    });
  }
  return {
    leftSort,
    rightSort,
  };
};

export const showPageAppRowHandler = async (
  key: string,
  setOpenModal: (value: React.SetStateAction<boolean>) => void,
  setValCallback: (
    value: React.SetStateAction<
      | {
          title: string;
          value: string;
        }
      | undefined
    >,
  ) => void,
): Promise<void> => {
  const url = new URL('https://itunes.apple.com/lookup');
  url.searchParams.set('id', key);
  const fetchJsonpItunesLookupInst = (country?: string) => {
    if (country) {
      url.searchParams.set('country', country);
    }
    return fetchJsonp(url.toString(), { timeout: 60000 });
  };
  try {
    let itunesResponse: fetchJsonp.Response =
      await fetchJsonpItunesLookupInst();
    let responseData: TItunesApiType = await itunesResponse.json();
    if (!responseData.results.length) {
      itunesResponse = await fetchJsonpItunesLookupInst('ru');
      responseData = await itunesResponse.json();
    }
    const appName = responseData.results[0]?.trackName;
    const appContentRating = responseData.results[0]?.trackContentRating;
    const apiNotFound = i18n.t(
      `common.targetings_error.app.resource_not_found`,
    );
    setOpenModal(true);
    if (appName && appContentRating) {
      setValCallback({
        title: key,
        value: `${appName} ${appContentRating}`,
      });
    } else {
      setValCallback({ title: key, value: apiNotFound });
    }
  } catch (e) {
    console.error({ e });
  }
};

// Разворачивание/сворачивание настроенных итемов
export const recursiveToggle = (item: TLeftD, toggleVal: boolean): TLeftD => {
  if (item.children) {
    return {
      ...item,
      isToggle: toggleVal,
      children: item.children.map((child) => recursiveToggle(child, toggleVal)),
    };
  }
  return {
    ...item,
    isToggle: toggleVal,
  };
};
