import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FiAlertCircle, FiSave } from 'react-icons/fi';
import Button from '../../../components/UI/Buttons/buttons';
import OnlyOwner from '../../../components/OnlyOwner';
import css from './styles.module.scss';

type PButtons = {
  showSaveButton: boolean;
  onCancel: () => void;
  onSave: () => void;
  read_only?: boolean;
  isValidatedTarget: boolean;
  isDisabled: boolean;
};
export const Buttons: FC<PButtons> = ({
  showSaveButton,
  onCancel,
  onSave,
  read_only,
  isValidatedTarget,
  isDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {showSaveButton && (
        <div className={css.info_save}>
          <FiAlertCircle size={20} />
          <span>{t('targetings.there_are_changes')}</span>
        </div>
      )}
      {!read_only && showSaveButton && (
        <OnlyOwner>

          <div className={css.actions}>
            <Button
              title={t('cancel_btn')}
              height={32}
              buttonAttributes={{
                style: {
                  fontSize: '13px',
                },
                onClick: onCancel,
                disabled: isDisabled,
              }}
              autoWidth
              lightgrey
            />
            <Button
              title={t('save_btn')}
              icon={<FiSave size={20} />}
              height={32}
              buttonAttributes={{
                style: {
                  padding: '10px',
                  fontSize: '13px',
                },
                disabled: !isValidatedTarget || isDisabled,
                onClick: ()=>{
                  onSave()
                },
              }}
              lightblue
              autoWidth
            />
          </div>
        </OnlyOwner>
      )}
    </>
  );
};
