import { Ttarget } from 'domains/campaign/types';
import request from '../../lib/helpers/requestApi';
import {
  PCopyTarget,
  PGetStatisticTarget,
  PSetTargeting,
  PTargetGetSettings,
  RCopyTarget,
  RGetList,
  RGetSettings,
  RGetStatisticTarget,
  TargetKey,
} from './model/types';
import { THASH } from '../../types/general';
import { RemoveUserSettings, TParams } from './types';

export const getList = request<RGetList, THASH>({
  method: 'targeting.getList',
});

export const getSettings = request<RGetSettings, PTargetGetSettings>({
  method: 'targeting.getSettings',
});

export const getStatisticTarget = request<
  RGetStatisticTarget,
  PGetStatisticTarget
>({
  method: 'statistic.getTarget',
});

/** Метод очистки таргетов у кампании/креатива */
export const clearTarget = request<boolean, THASH>({
  method: 'targeting.removeAll',
});

/** Задание таргетинга кампании */
export const setTargeting = request<boolean, PSetTargeting>({
  method: 'targeting.setUserSetting',
  showError: false,
});

/** Копирование настроек таргетинга */
export const copySettingsTargeting = request<RCopyTarget[], PCopyTarget>({
  method: 'targeting.clone',
});

/** Копирование настроек претаргетинга */
export const copySettingsPretargeting = request<boolean, PCopyTarget>({
  method: 'cloning.pretarget',
});

/* метод обновления справочника(каталога) таргета  */
export const catalogItemsUpdate = request<boolean, { target_key: string }>({
  method: 'targeting.update',
});

export const getTargetUserSettingsAPI = request<
  Record<TargetKey, Ttarget>,
  THASH
>({
  method: 'targeting.getUserSettingsData',
});

export const getSingleTargetUserSettingsAPI = request<Ttarget, TParams>({
  method: 'targeting.getUserSetting',
});

export const removeUserSettingAPI = request<boolean, RemoveUserSettings>({
  method: 'targeting.removeUserSetting',
});
