import React, { FC, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import css from './styles.module.scss';
import i18n from '../../../../i18n';

type TLimitGroupsItem = {
  id: string;
  internal_id?: string;
  name: string;
  readOnly?: boolean;
  campaignsXxhash?: string[];
  setCampaignsXxhash?: React.Dispatch<React.SetStateAction<string[]>>;
  isResetCampaigns?: boolean;
  setIsResetCampaigns?: React.Dispatch<React.SetStateAction<boolean>>;
};

const Item: FC<TLimitGroupsItem> = ({
  id,
  internal_id,
  name,
  readOnly,
  campaignsXxhash,
  setCampaignsXxhash,
  isResetCampaigns,
  setIsResetCampaigns,
}) => {
  const [add, setAdd] = useState(() => !campaignsXxhash?.includes(id));

  useEffect(() => {
    if (isResetCampaigns) {
      setAdd(true);
    }
  }, [isResetCampaigns]);

  const addRemoveClickHandler = () => {
    if (setCampaignsXxhash) {
      if (add) {
        setCampaignsXxhash((prev) => [...prev, id]);
        setAdd(!add);
        if (setIsResetCampaigns) {
          setIsResetCampaigns(false);
        }
        return;
      }
      setCampaignsXxhash((prev) => prev.filter((item) => item !== id));
      setAdd(!add);
    }
  };

  return (
    <div
      className={cn(css.limit_groups_item, {
        _isAdded: !add,
        _isReadOnly: readOnly,
      })}
    >
      <NavLink
        className={css.link}
        target="_blank"
        to={{
          pathname: `/main/campaign/${id}/main`,
        }}
      >
        {internal_id}
      </NavLink>
      <div className={css.action_wrapper} onClick={addRemoveClickHandler}>
        <div className={css.name}>{name}</div>
        {setCampaignsXxhash && (
          <div className={css.actions}>
            {add ? (
              <div className={css.actions__add}>{i18n.t(`add_btn`)}</div>
            ) : (
              <div className={css.actions__remove}>{i18n.t(`delete_btn`)}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Item;
