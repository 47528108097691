import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import css from './styles.module.scss';
import ShowMorePopup from './ShowMorePopup';

interface IShowMoreListProsp {
  data: any[];
  leftPart: ReactNode;
  groupsCollapsedDisplay?: number;
  list?: ReactNode;
}

const ShowMoreList: FC<IShowMoreListProsp> = ({
  data,
  leftPart,
  groupsCollapsedDisplay = 1,
  list,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [openList, setOpenList] = useState(false);
  const counter = data.length - groupsCollapsedDisplay > 0;

  const closeList = () => {
    setOpenList(false);
  };

  useEffect(() => {
    if (openList) {
      document.addEventListener('scroll', closeList);
    }

    return () => {
      document.removeEventListener('scroll', closeList);
    };
  }, [openList]);

  return (
    <div
      className={css.showMore}
      onMouseEnter={() => setOpenList(true)}
      onMouseLeave={closeList}
    >
      {leftPart}
      {counter && (
        <>
          <span className={css.total} ref={ref}>
            +{data.length - groupsCollapsedDisplay}
          </span>
        </>
      )}
      {openList && counter && <ShowMorePopup mainRef={ref} list={list} />}
    </div>
  );
};

export default ShowMoreList;
