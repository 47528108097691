import { TGetCreativeListResponse } from 'domains/Analysis/types';
import { useCallback, useEffect, useMemo, useState } from 'react';

type TFUseGetReport = {
  disGetReport: boolean;
  onGetReport: () => void;
};

export const useGetCreoReport = (
  keysFields: Record<string, string>[],
  data: TGetCreativeListResponse | null,
  currentTarget: string,
): TFUseGetReport => {
  function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  const worker = useMemo(() => new Worker('/workers/getCreoList.js?v=1.0'), []);

  const [disGetReport, setDisGetReport] = useState(false);

  useEffect(() => {
    if (data?.length) {
      setDisGetReport(false);
      return;
    }
    setDisGetReport(true);
  }, [data]);

  const onGetReport: () => void = useCallback(() => {
    setDisGetReport(true);
    worker.postMessage([
      data,
      keysFields.map(({ title }) => title),
      currentTarget,
    ]);
    // eslint-disable-next-line no-param-reassign
    worker.onmessage = function (e) {
      const type = isSafari() ? 'application/csv' : 'text/csv';
      const blob = new Blob(['', e.data], { type });

      const URL = window.URL || window.webkitURL;

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', 'creoList.xls');
      document.body.appendChild(link);
      link.click();
      link.remove();
      setDisGetReport(false);
    };
    // eslint-disable-next-line no-param-reassign
    worker.onerror = function (e) {
      console.error({ e });
      setDisGetReport(false);
    };
  }, [data]);

  return {
    disGetReport,
    onGetReport,
  };
};
