import Checkbox from 'components/UI/Checkbox/checkbox';
import {
  RSearchGeoItem,
  TRepeatedCheckboxItem,
} from 'domains/target/model/types';
import { recursivePushLocations } from 'domains/target/model/utils/recursionChecked';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../styles.module.scss';

interface Props {
  title: string;
  itemsArr: TRepeatedCheckboxItem[];
  repeatedCheckboxHandler: (geoSearchItem: TRepeatedCheckboxItem) => void;
}

const RepeatItem: FC<Props> = ({
  title,
  itemsArr,
  repeatedCheckboxHandler,
}) => {
  const { t } = useTranslation();
  const createLabel = (item: RSearchGeoItem) => {
    const namesArr: string[] = [];
    recursivePushLocations(item, namesArr);
    return namesArr.reverse().join('/');
  };

  return (
    <div className={css.item}>
      <div className={css.item_title}>{`${title} (${itemsArr.length} ${
        itemsArr.length <= 4
          ? t(`targetings.geo_id.matches4`)
          : t(`targetings.geo_id.matches5`)
      })`}</div>
      <div className={css.item_checkboxes}>
        {itemsArr.map((item) => (
          <Checkbox
            key={item.geoname_id}
            label={createLabel(item)}
            inputAttributes={{
              type: 'checkbox',
              checked: item.isChecked,
              onChange: () => repeatedCheckboxHandler(item),
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default RepeatItem;
