import { ManagementUsersState } from './reducer';
import {
  THookSelectorFetchDataInfo,
  THookStateInfo,
  TSelector,
  useGenFetchDataSelector,
  useSelectorTypes,
} from '../../../redux/rootSelectors';
import { RGetManagementUsersSList, RUserOnline, TActivityUsers } from './types';

/** Список селекторов */
export const selectorManagementUsers: TSelector<
  ManagementUsersState['tempManagementUsers']
> = ({ managementUsersReducer }) => managementUsersReducer.tempManagementUsers;

export const selectorsUsersOnline: TSelector<
  ManagementUsersState['usersOnline']
> = ({ managementUsersReducer }) => managementUsersReducer.usersOnline;

export const selectorsActivityUsers: TSelector<
  ManagementUsersState['activityUsers']
> = ({ managementUsersReducer }) => managementUsersReducer.activityUsers;

export const selectorSortActivityUsers: TSelector<
  ManagementUsersState['listSort']
> = ({ managementUsersReducer }) => managementUsersReducer.listSort;

/** Список хуков */
export const useManagementUsersInfo: THookSelectorFetchDataInfo<
  RGetManagementUsersSList
> = () => useGenFetchDataSelector(selectorManagementUsers);

export const useManagementUsersOnline: THookSelectorFetchDataInfo<
  RUserOnline
> = () => useGenFetchDataSelector(selectorsUsersOnline);

export const useManagementActivityUsersInfo: THookSelectorFetchDataInfo<
  TActivityUsers
> = () => useGenFetchDataSelector(selectorsActivityUsers);

export const useManagementActivitySortUsersInfo: THookStateInfo<
  ManagementUsersState['listSort']
> = () => useSelectorTypes(selectorSortActivityUsers);
