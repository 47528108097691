import { FormikState } from 'formik';
import { FormikErrors } from 'formik/dist/types';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreativeDetail,
  TCreativeCompanion,
  TCreativeHTML,
  TCreativeMultiFormat,
  TCreativePush,
  TCreativeTeaser,
  TCreativeVideoFormik,
  TCreativeVideoVast,
  TCreativeYandex,
  TFormikCreativeAdd,
} from '../../types';
import { fetchCreativeAlgorithmsList } from '../../reduser';
import { SelectField } from '../../../../types/general';
import { useDispatchApp } from '../../../../redux/rootSelectors';
import { useCreativeAlgorithmsCPAInfo } from '../../model/selectors';

type TFUseCreativeAlgorithmsInfo = () => {
  algorithmsSelect: SelectField[];
  algorithms: ReturnType<typeof useCreativeAlgorithmsCPAInfo>['data'];
  isLoading: ReturnType<typeof useCreativeAlgorithmsCPAInfo>['isLoading'];
  error: ReturnType<typeof useCreativeAlgorithmsCPAInfo>['error'];
  LTU: ReturnType<typeof useCreativeAlgorithmsCPAInfo>['LTU'];
};

export const isGardHTMLType = (
  param: CreativeDetail['data'],
): param is TCreativeHTML => true;

export const isGardHTMLTypeError = (
  param: FormikState<TFormikCreativeAdd>['errors']['data'],
): param is FormikErrors<TCreativeHTML> => true;

export const isGardCompanionTypeError = (
  param: FormikState<TFormikCreativeAdd>['errors']['data'],
): param is FormikErrors<TCreativeCompanion> => true;

export const isGardYandexType = (
  param: CreativeDetail['data'],
): param is TCreativeYandex => true;
export const isGardYandexTypeError = (
  param: FormikState<TFormikCreativeAdd>['errors']['data'],
): param is FormikErrors<TCreativeYandex> => true;

export const isGardPushType = (
  param: CreativeDetail['data'],
): param is TCreativePush => true;
export const isGardTeaserType = (
  param: CreativeDetail['data'],
): param is TCreativeTeaser => true;

export const isGardVideoInlineType = (
  param: CreativeDetail['data'],
): param is TCreativeVideoFormik => true;

export const isGardCompanionType = (
  param: CreativeDetail['data'],
): param is TCreativeCompanion => true;

export const isGardVideoVastType = (
  param: CreativeDetail['data'],
): param is TCreativeVideoVast => true;

export const isGardVideoInlineTypeError = (
  param: FormikState<TFormikCreativeAdd>['errors']['data'],
): param is FormikErrors<TCreativeVideoFormik> => true;

export const isGardMultiFormatType = (
  param: CreativeDetail['data'],
): param is TCreativeMultiFormat => true;

export const useCreativeAlgorithmsInfo: TFUseCreativeAlgorithmsInfo = () => {
  const dispatch = useDispatchApp();
  const { t } = useTranslation();
  const {
    data: algorithms,
    isLoading,
    error,
    LTU,
  } = useCreativeAlgorithmsCPAInfo();

  const algorithmsSelect = useMemo<SelectField[]>(() => {
    if (!algorithms) return [];
    return algorithms.map(({ key, id, title }) => {
      const label = t(`creative.create_creative.optimization.${key}`);
      return {
        value: `${id}`,
        label:
          label.indexOf('creative.create_creative.optimization.') + 1
            ? title
            : label,
      };
    });
  }, [algorithms]);

  useEffect(() => {
    if (!algorithms) {
      dispatch(fetchCreativeAlgorithmsList());
    }
  }, []);

  return {
    algorithmsSelect,
    algorithms,
    isLoading,
    error,
    LTU,
  };
};
