import React, { FC } from 'react';
import cn from 'classnames';
import checkbox from './styles.module.scss';

type Props = {
  /** Текст лейбла =undefined */
  label: string;
  /** marginTop */
  mt?: number;
  /** устанавливает disabled в инпут применяется к стилям =false */
  disabled?: boolean;
  /** скрывает с помощью display =false */
  notField?: boolean;
  /** атрибуты инпута =undefined */
  inputAttributes?: React.InputHTMLAttributes<HTMLInputElement>;
  /** Дополнительный class для обертки */
  className?: string;
  /** строка поиска */
  search?: string;
  /** Частичный чек */
  isPartiallyChecked?: boolean;
  /* Без лейбла */
  onlyBox?: boolean;
};

const Checkbox: FC<Props> = ({
  label,
  mt,
  notField = false,
  disabled = false,
  onlyBox = false,
  inputAttributes,
  className,
  search,
  isPartiallyChecked,
}) => {
  const searchLabel = (title: string, arg: string): JSX.Element => {
    const start = title.toLowerCase().indexOf(arg.toLowerCase());
    const end = start + arg.length;
    return (
      <span>
        <b>{title.slice(0, start)}</b>
        <span className={checkbox.searchTitle}>{title.slice(start, end)}</span>
        <b>{title.slice(end, title.length)}</b>
      </span>
    );
  };

  return (
    <div
      className={cn(
        checkbox['form-group'],
        {
          isDisabled: inputAttributes?.disabled || disabled,
          partially: isPartiallyChecked,
          isEmpty:
            (inputAttributes?.disabled || disabled) && !isPartiallyChecked,
        },
        className,
        'chackboxField'
      )}
      style={{ marginTop: `${mt}px`, display: notField ? 'none' : 'block' }}
    >
      <label htmlFor={inputAttributes?.id || inputAttributes?.name}>
        <input
          disabled={inputAttributes?.disabled || disabled}
          {...inputAttributes}
          id={inputAttributes?.id || inputAttributes?.name}
        />
        {search && label.toLowerCase().includes(search.toLowerCase()) ? (
          <span
            className={cn(checkbox.body, {
              partially: isPartiallyChecked,
            })}
          >
            {searchLabel(label, search)}
          </span>
        ) : (
          <span
            className={cn(checkbox.body, {
              partially: isPartiallyChecked,
              onlyBox,
            })}
            title={label}
          >
            {label}
          </span>
        )}
      </label>
    </div>
  );
};

export default Checkbox;
