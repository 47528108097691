import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../UI/Buttons/buttons';
import { useIsShowMCreate } from '../../domains/client/create/hooks';
import css from './styles.module.scss';
import AccessUserRight from '../AccessUserRight';
import { useStorageClientsTabs } from '../../domains/storage/model/selectors';
import { ElementsNoneImages } from './Images';

type TClientNoneProps = {
  /* От этого параметра меняетсятся текст */
  search?: boolean | null;
};

const ClientNone: FC<TClientNoneProps> = ({ search }) => {
  const { t } = useTranslation();
  const status = useStorageClientsTabs();
  const openMCreateCl = useIsShowMCreate(true);

  return (
    <div className={css.empty}>
      <div className={css.img}>
        {search ? (
          <img
          src={ElementsNoneImages.no_search}
          alt={ElementsNoneImages.no_search}
          />
        ) : (
          <>
            {status === 'LAUNCHED' && (
              <img
                src={ElementsNoneImages.clients_empty_active}
                alt={ElementsNoneImages.clients_empty_active}
              />
            )}
            {status === 'STOPPED' && (
              <img
                src={ElementsNoneImages.clients_empty_stop}
                alt={ElementsNoneImages.clients_empty_stop}
              />
            )}
            {status === 'ARCHIVE' && (
              <img
                src={ElementsNoneImages.clients_empty_arhiv}
                alt={ElementsNoneImages.clients_empty_arhiv}
              />
            )}
            {status === 'DELETED' && (
              <img
                src={ElementsNoneImages.clients_empty_del}
                alt={ElementsNoneImages.clients_empty_del}
              />
            )}
            {status === null && (
              <img
                src={ElementsNoneImages.general}
                alt={ElementsNoneImages.general}
              />
            )}
          </>
        )}
      </div>
      {search ? (
        <div className={css.search}>
          <div className={css.header}>{`${t('search_none.none_1')}`}</div>
          <div className={css.text}>
            <span>{`${t('search_none.none_2')}`}</span>{' '}
            <span>{`${t('search_none.none_3')}`}</span>
          </div>
        </div>
      ) : (
        <div className={css.title}>
          {status === 'LAUNCHED' && t('clients_page.clients_none.empty1')}
          {status === 'STOPPED' && t('clients_page.clients_none.empty2')}
          {status === 'ARCHIVE' && t('clients_page.clients_none.empty3')}
          {status === 'DELETED' && t('clients_page.clients_none.empty4')}
          {status === null && (
            <>
              <div>{t('clients_page.clients_none.empty5')}</div>
              <span  style={{
                    fontWeight: 400,
                  }}>{t('clients_page.clients_none.empty6')}</span>
              <div className={css.actions}>
                <AccessUserRight>
                  {(props) => (
                    <Button
                      title={t('clients_page.add_client')}
                      buttonAttributes={{
                        className: css.link,
                        onClick: openMCreateCl,
                        disabled: props.isDisable,
                      }}
                    />
                  )}
                </AccessUserRight>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default ClientNone;
