import { TGetTechnicalInfo } from 'types/general';
import { APIUsersState } from './reducer';
import {
  THookSelectorFetchDataInfo,
  TSelector,
  useGenFetchDataSelector,
} from '../../../redux/rootSelectors';
import { TAPIMethods, TAPIUser } from './types';

/** Список селекторов */
export const selectorAPIUsers: TSelector<APIUsersState['tempAPIUsers']> = ({
  APIUsersReducer,
}) => APIUsersReducer.tempAPIUsers;

export const selectorAPIMethods: TSelector<APIUsersState['APIMethods']> = ({
  APIUsersReducer,
}) => APIUsersReducer.APIMethods;

export const selectorGetTechnicalInfo: TSelector<
  APIUsersState['userTechnicalInfo']
> = ({ APIUsersReducer }) => APIUsersReducer.userTechnicalInfo;

/** Список хуков */
export const useAPIUsersInfo: THookSelectorFetchDataInfo<TAPIUser[]> = () =>
  useGenFetchDataSelector(selectorAPIUsers);

export const useAPIMethods: THookSelectorFetchDataInfo<TAPIMethods> = () =>
  useGenFetchDataSelector(selectorAPIMethods);

export const useGetTechnicalInfo: THookSelectorFetchDataInfo<
  TGetTechnicalInfo
> = () => useGenFetchDataSelector(selectorGetTechnicalInfo);
