import { genFetchedData } from 'redux/fetchedData';
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { BlState, TFileList, ParamGetFileList } from './types';

export const initialState: BlState = {
  blFile: genFetchedData<TFileList[]>(null),
};

const BlSlice = createSlice({
  name: 'BlFiles',
  initialState,
  reducers: {
    setBlFiles(state, action: PayloadAction<BlState['blFile']>) {
      state.blFile = action.payload;
    },
  },
});

export const fetchBlFiles = createAction<ParamGetFileList>(
  'BlFiles/fetchBlFiles',
);

export const { setBlFiles } = BlSlice.actions;
export const blReducer = BlSlice.reducer;
