import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { geoSearch } from "domains/target/model/actions";
import { useGeoSearch } from "domains/target/model/selectors";
import { PAddAjaxListModal, RSearchGeo, RSearchGeoItem, TRepeatedCheckboxItem } from "domains/target/model/types";
import { resetGeoSearchData } from 'domains/target/reduser';
import i18next from "i18next";
import { useCallback, useEffect, useState } from "react";


type TUseGeoIdSearchListParams = Omit<PAddAjaxListModal, 'isOpen' | 'is_wo_available' | 'catalog' | 'onHandleSubmit'> & {
    value: string,
    dispatch: Dispatch<AnyAction>,
    setError: (str: string) => void,
} 

type TUseGeoIdSearchList = (params: TUseGeoIdSearchListParams) => {
    searchData: RSearchGeo | null,
    repeatedTitles: string[],
    repeatedItems: TRepeatedCheckboxItem[]
    cleanSearchData: RSearchGeoItem[],
    isShowRepeated: boolean,
    isLoading: boolean,
    isGeo: boolean,
    repeatedCheckboxHandler: (geoSearchItem: TRepeatedCheckboxItem) => void,
    onSubmitGeo: () => void,
} 

export const useGeoIdSearchList: TUseGeoIdSearchList = ({dispatch, type, value, onCloseModal, onHandleGeo, setError}) => {
    const [repeatedTitles, setRepeatedTitles] = useState<string[]>([]);
    const [repeatedItems, setRepeatedItems] = useState<TRepeatedCheckboxItem[]>([]);
    const [cleanSearchData, setCleanSearchData] = useState<RSearchGeoItem[]>([]);
    const [checkedLists, setCheckedLists] = useState<Record<string, string[]>>({
      checked: [],
      unchecked: [],
    });
  
    const { data: searchData, isLoading } = useGeoSearch();
  
    const isGeo = type === 'geo_id'
    const isShowRepeated = isGeo && !!repeatedTitles.length;
  
    const repeatedCheckboxHandler = (geoSearchItem: TRepeatedCheckboxItem) => {
      const {isChecked, ...restData} = geoSearchItem
      const currentCheckboxVal = !isChecked
      setRepeatedItems(prev => prev.map(item => {
        if(item.geoname_id === geoSearchItem.geoname_id) {
          return {...item, isChecked: currentCheckboxVal}
        }
        return item
      }))
      if (currentCheckboxVal) {
        setCleanSearchData(prev => [...prev, restData])
      } else {
        setCleanSearchData(prev => prev.filter(item => item.geoname_id !== restData.geoname_id))
      }
    }
  
    const onSubmitGeo = useCallback(() => {
      const checkArr: string[] = []
      const uncheckArr: string[] = []
      if (value && value.trim()) {
        const dataList = value
          .trim()
          .split('\n')
          .filter((e: string) => e.length > 0)
          .map(item => {
            const lowTitle = item.toLowerCase()
            if (lowTitle[0] === '-') {
              const sliceMinus = lowTitle.slice(1)
              uncheckArr.push(sliceMinus)
              return sliceMinus
            }
            checkArr.push(lowTitle)
            return item
          });
        setCheckedLists({
          checked: checkArr,
          unchecked: uncheckArr,
        })
        if (searchData && !searchData.notFound) return
        dispatch(geoSearch({ item: dataList }));
      }
    }, [value, searchData]);
  
    useEffect(() => {
      if (searchData) {
        const { result, notFound } = searchData;
        if (notFound) return
        if (result) {
          const repeatData = [
            ...new Set(
              searchData.result
                ?.map((geoItem) => i18next.language === 'ru' ? geoItem.name_ru : geoItem.name_en)
                .filter((item, index, arr) => arr.indexOf(item) !== index),
            ),
          ];
          setRepeatedTitles(repeatData);
        }
      }
    }, [searchData]);
  
    useEffect(() => {
      if (!searchData?.result) return
      if (repeatedTitles.length) {
        const repeatedArr: RSearchGeoItem[] = [];
        const uniqueItems: RSearchGeoItem[] = [];
        searchData.result.forEach((item) =>
          repeatedTitles.includes(i18next.language === 'ru' ? item.name_ru : item.name_en)
            ? repeatedArr.push(item)
            : uniqueItems.push(item),
        );
        setRepeatedItems(repeatedArr.map(item => ({...item, isChecked: false})))
        setCleanSearchData(uniqueItems);
        if (repeatedTitles.every(item => cleanSearchData.find(cleanItem => cleanItem.name_ru === item))) {
          onHandleGeo(cleanSearchData.map(item => item.geoname_id), checkedLists.checked, checkedLists.unchecked)
          onCloseModal()
        }
        return
      }
      onHandleGeo(searchData.result.map(item => item.geoname_id), checkedLists.checked, checkedLists.unchecked)
      onCloseModal()
    }, [repeatedTitles, checkedLists]);

    useEffect(() => {
      if (isShowRepeated) {
        dispatch(resetGeoSearchData())
        setError('')
        setRepeatedTitles([])
        setCleanSearchData([])
      }
    }, [value])     

    return {
        searchData,
        repeatedTitles,
        cleanSearchData,
        repeatedItems,
        isShowRepeated,
        isLoading,
        isGeo,
        repeatedCheckboxHandler,
        onSubmitGeo
    }
}