import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Form, FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import Button from 'components/UI/Buttons/buttons';
import Input from 'components/UI/Input/input';
import Card from 'components/UI/Card';
import { FiAlertCircle } from 'react-icons/fi';
import cn from 'classnames';
import css from '../styles.module.scss';
import { useForgotPasswordDetail } from '../hooks/useForgotPasswordDetail';

const ForgotPassword: FC = () => {
  const { t } = useTranslation();

  const {
    formikContext,
    values,
    handleChange,
    handleBlur,
    submitCount,
    errors,
    touched,
    confirm,
  } = useForgotPasswordDetail();

  return (
    <>
      <Helmet>
        <title>{t('form.common.forgot')}</title>
      </Helmet>

      <div className={css.changeform}>
        {t('form.common.have_acc')}{' '}
        <Link to="/auth/sign-in">{t('form.common.signin')}</Link>
      </div>

      <FormikProvider value={formikContext}>
        <div className={css.content}>
          <Card noHover className={css.card}>
            <Form className={css.form}>
              <h1 className={css.title}>{t('login_page.forgotYourPass')}</h1>

              {typeof confirm === 'string' && (
                <div className={css.error}>
                  <FiAlertCircle size={20} />
                  <span>{confirm}</span>
                </div>
              )}

              <Input
                label={t('form.common.email')}
                error={!!submitCount && !!touched.email && !!errors.email}
                errortext={errors.email}
                inputAttributes={{
                  name: 'email',
                  value: values.email,
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
              />

              <Button
                title={t('login_page.resetPass')}
                buttonAttributes={{
                  type: 'submit',
                  style: {
                    fontWeight: 700,
                    marginTop: 16,
                  },
                }}
              />

              <div className={cn(css.changeform, 'nodesktop')}>
                {t('form.common.have_acc')}{' '}
                <Link to="/auth/sign-in">{t('form.common.signin')}</Link>
              </div>
            </Form>
          </Card>
        </div>
      </FormikProvider>
    </>
  );
};

export default ForgotPassword;
