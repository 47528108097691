import { useClientsAllInfo } from 'domains/clients/model/selectors';
import { fetchAllClients } from 'domains/clients/reducer';
import { ClientsList } from 'domains/clients/types';
import { useOuterElementClick } from 'hooks/useOuterElementClick';
import { useEffect, useState } from 'react';
import { useDispatchApp } from 'redux/rootSelectors';

interface IUseFilterProps {
  iconRef: React.RefObject<HTMLDivElement>;
  fixed: boolean;
  returnPrevFilters: () => void;
}

type TUseFilter = (props: IUseFilterProps) => {
  wrapperRef: React.RefObject<HTMLDivElement>;
  isOpenPanel: boolean;
  setIsOpenPanel: React.Dispatch<React.SetStateAction<boolean>>;
  topPosition: number;
  heightPanel: number;
  clientsList: ClientsList | null;
};

export const useFilter: TUseFilter = ({ iconRef, fixed, returnPrevFilters }) => {
  const [isOpenPanel, setIsOpenPanel] = useState<boolean>(false);
  const [topPosition, setTopPosition] = useState<number>(0);
  const [heightPanel, setHeightPanel] = useState<number>(0);
  const { data: clientsList } = useClientsAllInfo();
  const dispatch = useDispatchApp();

  // контроль клика вне фильтра
  const { wrapperRef } = useOuterElementClick({
    isShow: isOpenPanel,
    closeModal: () => {
      setIsOpenPanel(false);
      returnPrevFilters();
    },
  });

  const calcTopPositionPanel = () => {
    if (iconRef.current) {
      setTopPosition(iconRef.current?.getBoundingClientRect().bottom);
      setHeightPanel(document.body.clientHeight - 270);
    }
  };

  // рассчет положения панели на экране
  useEffect(() => {
    calcTopPositionPanel();
  }, []);

  useEffect(() => {
    dispatch(fetchAllClients({ status: 'LAUNCHED' }));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      calcTopPositionPanel();
    }, 500);
  }, [fixed]);

  useEffect(() => {
    document.addEventListener('scroll', () => setIsOpenPanel(false));

    return () => {
      document.removeEventListener('scroll', () => setIsOpenPanel(false));
    };
  }, []);

  return {
    wrapperRef,
    isOpenPanel,
    setIsOpenPanel,
    topPosition,
    heightPanel,
    clientsList,
  };
};
