import React, { FC } from "react";
import ToggleSwitch from "components/UI/ToggleSwitch";
import { TExperimentalInfoField ,  PExperimental } from "domains/campaign/types";
import { FormikProps } from "formik";
import css from '../styles.module.scss';

interface Props {
    item: TExperimentalInfoField,
    formik: FormikProps<PExperimental>,
}

const BoolInput: FC<Props> = ({item, formik}) => (
    <div className={css.toggle}>
    <div className={css.toggle_label}>
      {item.title}
    </div>
    <ToggleSwitch
      toggleOnly
      onChange={() => {
        formik.setFieldValue(
          'value',
          !formik.values.value,
        );
        formik.handleSubmit();
      }}
      checked={Boolean(formik.values.value)}
    />
  </div>
    )

export default BoolInput;