import React from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { Components } from 'react-select/src/components';
import { components } from 'react-select';

export const DropdownIndicator: Components['DropdownIndicator'] = ({
  selectProps,
  ...props
}) => (
    <components.DropdownIndicator selectProps={selectProps} {...props}>
      <FiChevronDown
        size={20}
        style={{
          transform: selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'transform .1s',
        }}
      />
    </components.DropdownIndicator>
  );
