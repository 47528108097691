import { useCurrency } from 'domains/project/hooks';
import { useRouteMatch } from 'react-router-dom';
import { format, subDays } from 'date-fns';
import { useDispatchApp } from '../../../redux/rootSelectors';
import {
  fetchPersonalStatistics,
  fetchCampaignTranslation,
} from '../model/actions';
import {
  useCampaignInfo,
  useCampaignReportInfo,
  useDefChartItemInfo,
} from '../model/selectors';
import { TID } from '../../../types/general';

type TFUseCampaignGeneral = () => {
  campaign: ReturnType<typeof useCampaignInfo>['data'];
  pastCampaign: ReturnType<typeof useCampaignInfo>['past'];
  isLoading: ReturnType<typeof useCampaignInfo>['isLoading'];
  currency: ReturnType<typeof useCurrency>['currency'];
  error: ReturnType<typeof useCampaignInfo>['error'];
  handlerFetchStatistics: () => void;
  handlerFetchTranslation: () => void;
};

type TFUseViewReportInfo = () => {
  report: ReturnType<typeof useCampaignReportInfo>['data'];
  isLoading: ReturnType<typeof useCampaignReportInfo>['isLoading'];
  error: ReturnType<typeof useCampaignReportInfo>['error'];
  LTU: ReturnType<typeof useCampaignReportInfo>['LTU'];
  isDateReport: boolean;
  defChartItemsGen: string[];
};

export const useCampaignGeneral: TFUseCampaignGeneral = () => {
  const {
    isLoading,
    error,
    data: campaign,
    past: pastCampaign,
  } = useCampaignInfo();
  const dispatch = useDispatchApp();
  const { params } = useRouteMatch<TID>();

  const handlerFetchStatistics = () => {
    const period = {
      from: format(subDays(new Date(), 4), 'yyyy-MM-dd'),
      to: format(new Date(), 'yyyy-MM-dd')
    }
    dispatch(fetchPersonalStatistics({ xxhash_list: [params.id], period }));
  };

  const handlerFetchTranslation = () => {
    dispatch(fetchCampaignTranslation({ xxhash: params.id }));
  };

  const { currency } = useCurrency();

  return {
    pastCampaign,
    campaign,
    isLoading,
    currency,
    error,
    handlerFetchStatistics,
    handlerFetchTranslation,
  };
};

export const useViewReportInfo: TFUseViewReportInfo = () => {
  const { data: report, isLoading, error, LTU } = useCampaignReportInfo();

  const defChartItemsGen = useDefChartItemInfo();

  const isDate = report && report.isDate;

  return {
    report,
    isLoading,
    error,
    LTU,
    isDateReport: isDate || false,
    defChartItemsGen,
  };
};
