import React, { useEffect, useState } from 'react';
import { KeysSettings, useStateValue } from 'utils/context';
import { IDispatchHistoryParametres } from 'domains/historyChanges/ui/HistoryFilter';
import { IHistoryFilter } from '../../types';
import { useHistoryMakeUrl } from './useHistoryMakeUrl';

type TUseClearHistoryFilters = {
  clearFilters: () => void;
  filtersCount: number;
};

export const useHistoryFiltersClear = (
  type: React.RefObject<any>,
  action: React.RefObject<any>,
  field: React.RefObject<any>,
  email: React.RefObject<HTMLInputElement>,
  search: React.RefObject<HTMLInputElement>,
  setHistoryFilters:
    | React.Dispatch<React.SetStateAction<IHistoryFilter>>
    | undefined,
  setLastHistoryFilters:
    | React.Dispatch<React.SetStateAction<IHistoryFilter>>
    | undefined,
  applyHistoryFilters: (() => void) | undefined,
  open: boolean,
): TUseClearHistoryFilters => {
  // получаем из контекста сохраенные данные по периоду фильтрации
  const { state } = useStateValue();
  const settings = state.settings[KeysSettings.HISTORY];
  // проверяем сколько фильтров в данный момент выбраны
  const checkFilters = () => {
    const emailValue = email.current && email.current.value;
    const searchValue = search.current && search.current.value;
    return [
      type.current?.select.state.selectValue[0],
      action.current?.select.state.selectValue[0],
      field.current?.select.state.selectValue[0],
      emailValue,
      searchValue,
    ].filter((val) => !!val).length;
  };
  const [filtersCount, setfiltersCount] = useState(checkFilters());

  const { removeHistoryUrlFilters } = useHistoryMakeUrl();
  const historyParams = localStorage.getItem('localHistoryParams');
  const parsedHistoryParams: IDispatchHistoryParametres =
    historyParams && JSON.parse(historyParams);
  const isLocalHistory = localStorage.getItem('isLocalHistory');

  // отправляем запрос с текущеми фильтрами при закрытии попапа и изменении последних фильтров
  useEffect(() => {
    if (applyHistoryFilters) {
      applyHistoryFilters();
    }
  }, [open]);

  useEffect(() => {
    setfiltersCount(checkFilters());
  }, [
    type.current?.select.state,
    action.current?.select.state,
    field.current?.select.state,
    email.current?.value,
    search?.current?.value,
  ]);

  // очищаем фильтры
  const clearFilters = () => {
    type.current.select.clearValue();
    action.current.select.clearValue();
    field.current.select.clearValue();
    removeHistoryUrlFilters();
    localStorage.setItem('changeType', '');
    if (email.current) {
      // eslint-disable-next-line no-param-reassign
      email.current.value = '';
    }
    if (search.current) {
      // eslint-disable-next-line no-param-reassign
      search.current.value = '';
    }
    if (parsedHistoryParams && setLastHistoryFilters && isLocalHistory) {
      localStorage.setItem('localHistoryParams', JSON.stringify({ period: parsedHistoryParams.period }));
      setLastHistoryFilters({ period: parsedHistoryParams.period });
    }
    if (setHistoryFilters && setLastHistoryFilters) {
      setHistoryFilters({ period: settings.period });
      setLastHistoryFilters({ period: settings.period });
    }
  };
  return {
    clearFilters,
    filtersCount,
  };
};
