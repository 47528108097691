import { takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { takeLatest } from '@redux-saga/core/effects';
import { fetchPDataByTarget } from 'domains/campaign/model/actions';
import {
  fetchGeoMyTargetWorker,
  fetchGeoTreeWorker,
  fetchGeoPopularWorker,
  resetTargetTypeWorker,
  workerClearTarget,
  workerFetchGetList,
  workerFetchGetListInCreative,
  workerFetchSettings,
  workerFetchTargetData,
  workerSetTargeting,
  workerStatisticTarget,
  workerSearchGeo,
  workerUpdateAu,
  workerUpdateTarget,
  workerFetchPDataByTarget,
  workerClearAllTargetItems,
  toggleInheritSettingsWorker,
  workerRemoveUserSettings,
} from './workers';
import {
  fetchClearTarget,
  fetchGetList,
  fetchGetListInCreative,
  fetchTargetData,
  onSetTargeting,
  fetchStatistics,
  fetchSettings,
  updateAu,
  updateTarget,
  clearAllTargetItems,
  toggleInheritSettings,
  removeUserSettings,
} from '../actions';
import {
  fetchGeoTree,
  fetchGeoPopular,
  fetchMyTargetGeo,
  resetTargetType,
  geoSearch,
} from '../model/actions';

export default function* watchTarget(): SagaIterator {
  yield takeEvery(fetchClearTarget, workerClearTarget);
  yield takeEvery(fetchGetList, workerFetchGetList);
  yield takeEvery<typeof onSetTargeting>(onSetTargeting, workerSetTargeting);
  yield takeEvery(fetchTargetData, workerFetchTargetData);
  yield takeEvery(fetchStatistics, workerStatisticTarget);
  yield takeEvery(fetchSettings, workerFetchSettings);
  yield takeEvery(geoSearch, workerSearchGeo);
  yield takeEvery(removeUserSettings, workerRemoveUserSettings);
  yield takeLatest(fetchGeoTree, fetchGeoTreeWorker);
  yield takeLatest(fetchGeoPopular, fetchGeoPopularWorker);
  yield takeLatest(resetTargetType, resetTargetTypeWorker);
  yield takeLatest(fetchMyTargetGeo, fetchGeoMyTargetWorker);
  yield takeLatest(fetchGetListInCreative, workerFetchGetListInCreative);
  yield takeLatest(updateTarget, workerUpdateTarget);
  yield takeLatest(updateAu, workerUpdateAu);
  yield takeLatest(fetchPDataByTarget, workerFetchPDataByTarget)
  yield takeLatest(clearAllTargetItems, workerClearAllTargetItems)
  yield takeLatest(toggleInheritSettings, toggleInheritSettingsWorker)
}

