import { createSelector } from '@reduxjs/toolkit';
import { WSSState } from './reducer';
import { TSelector } from '../../../redux/rootSelectors';

/** список селекторов */
export const selectorsWssUserList: TSelector<WSSState['userList']> = ({
  wssReducer,
}) => wssReducer.userList;

/** список хуков */
export const WSSUserList = createSelector(
  selectorsWssUserList,
  ({ notActive, active }) => ({
    users: [
      ...active.map((value) => ({ ...value, isActive: true })),
      ...notActive.map((value) => ({ ...value, isActive: false })),
    ],
  }),
);
