import React, { FC } from 'react';
import cn from 'classnames';
import { RouteComponentProps } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Loader from 'components/UI/Loader';
import css from './styles.module.scss';
import ErrorReport from '../../../../../components/Errors/Reports';
import { useCreativeTargetingList } from '../hooks/useCreativeTargeting';
import Item from '../../../../target/item';
import { TDates, TID } from '../../../../../types/general';
import { useUserTheme } from '../../../../user/model/selectors';

type Props = RouteComponentProps<TID> & {
  periodCommon: TDates['period'];
  commonTypePeriod: TDates['type'];
  isShowStatistics: boolean;
};

const Targeting: FC<Props> = ({
  periodCommon,
  commonTypePeriod,
  isShowStatistics,
}) => {
  const { isThemeExtended, isThemeFull } = useUserTheme();

  const { listTarget, isLoading, id, creativeDetail, error } =
    useCreativeTargetingList();

  const { t } = useTranslation();

  if (error.isError) {
    return <ErrorReport error={error} />;
  }

  return (
    <div className={css.targeting}>
      <Helmet>
        <title>Таргетинг</title>
      </Helmet>

      <div className={css.content}>
        {isLoading && (
          <div className={css.center}>
            <Loader />
          </div>
        )}
        {listTarget &&
          listTarget.length > 0 &&
          listTarget.map((target) => (
            <div key={target.key}>
              <div
                className={cn('container resized', {
                  extend: isThemeExtended,
                  full: isThemeFull,
                })}
              >
                {creativeDetail && (
                  <div key={target.key} className={css.form}>
                    <Item
                      title={
                        t(`common.targetings.${target.key}`) ===
                        `common.targetings.${target.key}`
                          ? target.title
                          : t(`common.targetings.${target.key}`)
                      }
                      isShowStatistic={isShowStatistics}
                      type={target.key}
                      view={target.type}
                      id={id}
                      isCreative
                      isCompain={false}
                      campaign_xxhash={creativeDetail.campaign_xxhash}
                      period={periodCommon}
                      isSourceLikeLogic={target.is_tree_target}
                      initCommonTypePeriod={commonTypePeriod}
                      is_wo_available={target.is_wo_available}
                      is_dynamic_catalog={target.is_dynamic_catalog}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Targeting;
