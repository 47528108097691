import React, { useState, useRef, useEffect } from 'react';
import {
  getFormatting,
  formattingShowMethodsThisContext,
} from 'domains/creative/api';
import i18next from 'i18next';
import useDropDawn from 'hooks/useDropDown';
import cn from 'classnames';
import { ReactSVG } from 'react-svg';
import Tooltip from 'components/UI/Tooltip';
import stick from './stick.svg';
import css from './styles.module.scss';
import { RFormatting } from '../../../../domains/creative/types';

interface Props {
  html: string;
  disabled?: boolean;
  setFieldValue: (html: string) => void;
}

const dictMethods = {
  html: 'creative.create_creative.Convert_code',
  sizmek: 'Sizmek auto complete',
  macros: 'creative.create_creative.Add_macros',
};

const ActionsButton: React.FC<Props> = ({ html, setFieldValue, disabled }) => {
  const ref = useRef(null);
  const [methods, setMethods] = useState<RFormatting['methods']>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { toggle, isOpened, toggleOff } = useDropDawn(ref);

  useEffect(() => {
    getMethods();
  }, []);

  const getMethods = async () => {

    const result = await formattingShowMethodsThisContext({ html: html || '' });
    setMethods(() => result.methods);
  };

  const onFormating = async (method: string) => {
    try {
      setLoading(true);
      toggleOff();
      const { result } = await getFormatting({ html, method });

      setFieldValue(result);
      setLoading(false);
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <div
      className={cn(css.actions, {
        _isLoading: loading,
        _isShowActions: isOpened,
        _isDisabled: disabled,
      })}
      ref={ref}
    >
      <div className={css.loader}>
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle r="17" cx="18" cy="18" stroke="#025DFF" strokeWidth="1.5" />
        </svg>
      </div>
      <Tooltip
        className={css.tooltip}
        left
        title={
          <div
            className={css.button}
            onClick={(event) => (isOpened ? toggleOff() : toggle(event))}
          >
            <ReactSVG src={stick} />
          </div>
        }
      >
        <span>{i18next.t('creative.create_creative.Actions')}</span>
      </Tooltip>
      <div className={css.list}>
        {methods.length > 0 &&
          methods.map((e) => (
            <div className={css.item} onClick={() => onFormating(e)} key={e}>
              <span>{i18next.t(dictMethods[e])}</span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ActionsButton;
