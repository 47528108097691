import React, { useState, FC, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import cn from 'classnames';
import Tooltip from 'components/UI/Tooltip';
import OnlyOwner from 'components/OnlyOwner';
import { ErrorResponse } from 'types/general';
import { TFieldStatistics } from 'domains/campaign/types';
import ToggleSwitch from 'components/UI/ToggleSwitch';
import Checkbox from 'components/UI/Checkbox/checkbox';
import { useCreativeTypes } from 'domains/creative/model/selectors';
import css from './styles.module.scss';
import ToastError, {
  isCloneResponseError,
} from '../../../../components/UI/Toast/ToastError';
import ToastSuccess from '../../../../components/UI/Toast/ToastSuccess';
import ModalWithQuestion from '../../../modals/modalWithQuestion';
import { CreativeItemListWithStatistics, RCreativeClone } from '../../types';
import CopyCreativeModal from '../CopyCreativeModal';
import {
  PHandlerChangeStatus,
  PHandlerCloneCreative,
  PHandlerRequestCleanAllTargets,
  PHandlerRequestDelete,
} from '../hooks/useCreativesList';
import StatisticsItem from './StatisticsItem';
import Options from './Options';
import {
  useAppNotification,
  useUserTheme,
} from '../../../user/model/selectors';

type Props = {
  /** данные креатива */
  creative: CreativeItemListWithStatistics;
  /** ссылка */
  link: string;
  /** объект классов для колонки */
  colClass: Record<string, string>;
  /** последний ли креатив */
  isLastEdited: boolean;
  /** список ключей статистики */
  keysFields: TFieldStatistics[];
  /** состояние при наведение */
  toggle: string;
  setToggle: (v: string) => void;
  /** функция дублирования креатива */
  handlerCloneCreative: (P: PHandlerCloneCreative) => void;
  /** состояние запроса */
  cloneResult: 'SUCCESS' | 'ERROR' | null;
  cloneResponse: RCreativeClone | ErrorResponse | null;
  /** состояние модального окна копирования */
  openCopyModal: string;
  setOpenCopyModal: (v: string) => void;
  /** состояние модального окна удаления */
  openDeleteModal: string;
  setOpenDeleteModal: (v: string) => void;
  /** состояние модального окна очистки креатива */
  openCleanModal: string;
  setOpenCleanModal: (v: string) => void;
  /** функция удаления креатива */
  handlerRequestDelete: (v: PHandlerRequestDelete) => void;
  /** функция очистки таргетов креатива */
  handlerRequestCleanAllTargets: (v: PHandlerRequestCleanAllTargets) => void;
  /** функция изменение статуса креатива */
  handlerChangeStatus: (v: PHandlerChangeStatus) => void;
  /** использование полных заголовков */
  fullTitle: boolean;
  /** дублируемый креатив */
  copyCreative: string;
  setCloneResult: (p: Props['cloneResult']) => void;
  setCloneResponse: React.Dispatch<
    React.SetStateAction<RCreativeClone | ErrorResponse | null>
  >;
  creativeCheckedList: string[];
  handlerSetCreativeInCheckedList: (value: CreativeItemListWithStatistics) => void;
};

const Item: FC<Props> = ({
  creative,
  link,
  isLastEdited,
  keysFields,
  colClass,
  toggle,
  setToggle,
  handlerCloneCreative,
  cloneResult,
  cloneResponse,
  openCopyModal,
  setOpenCopyModal,
  openDeleteModal,
  setOpenDeleteModal,
  openCleanModal,
  setOpenCleanModal,
  handlerRequestDelete,
  handlerRequestCleanAllTargets,
  handlerChangeStatus,
  fullTitle,
  copyCreative,
  setCloneResult,
  setCloneResponse,
  creativeCheckedList,
  handlerSetCreativeInCheckedList,
}) => {
  const {
    title,
    type,
    campaign_xxhash,
    status,
    creative_id,
    partner_xxhash,
    xxhash,
    use_inherit_settings,
    pretargeting_setting,
  } = creative
  const [activeStatus, setStatus] = useState<boolean>(status === 'LAUNCHED');
  const { data: creativeTypes } = useCreativeTypes();
  const creativeTypesArr = creativeTypes && Object.values(creativeTypes);
  const { isThemeFull } = useUserTheme();
  const isAppNotification = useAppNotification();

  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (xxhash === copyCreative && isAppNotification) {
      if (cloneResult && cloneResult === 'SUCCESS' && cloneResponse) {
        toast(
          <ToastSuccess
            targetingInfo={cloneResponse[0]}
            title={title}
            campaign_id={campaign_xxhash}
          />,
          {
            className: 'wide',
            type: 'success',
            delay: 500,
            autoClose: 10000,
          },
        );
      } else if (
        cloneResult &&
        cloneResult === 'ERROR' &&
        isCloneResponseError(cloneResponse)
      ) {
        toast(<ToastError error={cloneResponse} />, {
          type: 'error',
          delay: 500,
          autoClose: 2000,
        });
      }
    } else if (
      cloneResult &&
      cloneResult === 'ERROR' &&
      copyCreative === '' &&
      isCloneResponseError(cloneResponse)
    ) {
      toast(<ToastError error={cloneResponse} />, {
        type: 'error',
        delay: 500,
        autoClose: 2000,
      });
    }
    if (cloneResult && cloneResponse) {
      setCloneResult(null);
      setCloneResponse(null);
    }
  }, [cloneResult, cloneResponse]);

  return (
    <>
      <div
        id={xxhash}
        className={cn(
          css.row,
          'row',
          { isDeleted: status === 'DELETED' },
          { isActive: toggle === xxhash },
          { isLastEdited },
          {
            fullTitle,
          },
          fullTitle && `height-${Math.floor(title.length / 55)}`,
        )}
      >
        <div className={cn('fixed', css.fixed)}>
          <div className={css.indicator} />
          <OnlyOwner>
            <Checkbox
              label=""
              className={css.checkbox}
              inputAttributes={{
                type: 'checkbox',
                checked: creativeCheckedList.includes(xxhash),
                onChange: () => handlerSetCreativeInCheckedList(creative),
              }}
              onlyBox
            />
          </OnlyOwner>

          <Link to={link}>
            <div
              className={cn(css.number, {
                _isUsualTheme: !isThemeFull,
                _customSetTarget: !use_inherit_settings,
                _customSetPreTarget: pretargeting_setting,
                _customSetTargetAndPre:
                  !use_inherit_settings && pretargeting_setting,
              })}
            >
              <Tooltip
                className={css.value}
                title={creative_id}
                hintWidth={230}
                hideHint={use_inherit_settings && !pretargeting_setting}
              >
                {!use_inherit_settings && !pretargeting_setting && (
                  <span>{t('creative.targeting_settings')}</span>
                )}
                {pretargeting_setting && use_inherit_settings && (
                  <span>{t('creative.pretargeting_settings')}</span>
                )}
                {!use_inherit_settings && pretargeting_setting && (
                  <span>{t('creative.targetAndPretarget_settings')}</span>
                )}
              </Tooltip>
            </div>
          </Link>
          <div className={css.toggle__status}>
            <OnlyOwner>
              <ToggleSwitch
                onChange={() =>
                  handlerChangeStatus({ xxhash, activeStatus, setStatus })
                }
                checked={activeStatus}
                toggleOnly
              />
            </OnlyOwner>
          </div>
          <Link to={link}>
            <div className={css.img}>
              {creativeTypesArr
                ?.filter(({ key }) => key === type)
                .map(({ icon, key, id }) => (
                  <Tooltip
                    title={<img src={icon} className="icon" alt={key} />}
                    key={id}
                  >
                    <p>{t(`creative.create_menu.${key}`)}</p>
                  </Tooltip>
                ))}
            </div>
            <div className={css.text}>
              <Tooltip
                hideHint={fullTitle}
                className={css.tooltip}
                hintWidth={300}
                leftArrowPosition
                title={
                  <Link
                    to={link}
                    className={cn(css.title, {
                      fullTitle,
                    })}
                  >
                    {title}
                  </Link>
                }
              >
                <p>{title}</p>
              </Tooltip>
            </div>
          </Link>
        </div>
        <StatisticsItem
          keysFields={keysFields}
          colClass={colClass}
          xxhash={xxhash}
        />
        <Options
          xxhash={xxhash}
          type={type}
          campaign_xxhash={campaign_xxhash}
          setToggle={setToggle}
          setOpenCopyModal={setOpenCopyModal}
          setOpenCleanModal={setOpenCleanModal}
          setOpenDeleteModal={setOpenDeleteModal}
          toggle={toggle}
          handlerCloneCreative={handlerCloneCreative}
        />
        <div className={css.hover} />
      </div>

      {openCleanModal === xxhash && (
        <OnlyOwner>
          <ModalWithQuestion
            btnTitle={t('clean_btn')}
            onClose={() => setOpenCleanModal('')}
            onClick={() => handlerRequestCleanAllTargets({ xxhash, title })}
            title={title}
            subText={t('creative.clear_done_targ')}
            modalName={t('just_words.confirm_action')}
          />
        </OnlyOwner>
      )}
      {openDeleteModal === xxhash && (
        <OnlyOwner>
          <ModalWithQuestion
            onClose={() => setOpenDeleteModal('')}
            onClick={() =>
              handlerRequestDelete({ xxhash, title, campaign_xxhash })
            }
            title={title}
            subText={t('creative.delete_creative_modal.delete_quest')}
            modalName={t('creative.delete_creative_modal.delete_tit')}
          />
        </OnlyOwner>
      )}
      {openCopyModal === xxhash && (
        <CopyCreativeModal
          onClose={() => setOpenCopyModal('')}
          modalProps={{
            partner_id: partner_xxhash,
            campaign_id: partner_xxhash,
            id: xxhash,
            title,
          }}
          isOpen={openCopyModal === xxhash}
        />
      )}
    </>
  );
};
export default Item;
