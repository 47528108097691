import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from './rootReducer';
import {
  extractGenFetchData,
  FetchedData,
  FetchedDataObj,
} from './fetchedData';

type TFUseDispatchApp = () => AppDispatch;

export type TSelector<T> = (p: AppState) => T;

export type THookStateInfo<P> = () => P;

export type THookSelectorFetchDataInfo<T> = (key?: string) => FetchedDataObj<T>;

type TSelectorTypes = <T>(selector: TSelector<T>) => T;

type TGenFetchSelector = <
  T extends FetchedData<D> | Record<string, FetchedData<D>>,
  D,
>(
  selector: TSelector<T>,
  keyElement?: string | number | undefined,
) => FetchedDataObj<D>;

/** функция возвращает часть стора */
export const useSelectorTypes: TSelectorTypes = <T>(selector: TSelector<T>) =>
  useSelector<AppState, T>(selector);

/**
 * функция возвращает часть стора с shallowEqual
 * */
export const useShallowEqualSelector: TSelectorTypes = <T>(
  selector: TSelector<T>,
) => useSelector<AppState, T>(selector, shallowEqual);

/** функция возвращает данные из стейта с genFetchData
 * @ data
 * @ isLoading
 * @ error
 * @ LTU
 */
export const useGenFetchDataSelector: TGenFetchSelector = <
  T extends FetchedData<D> | Record<string, FetchedData<D>>,
  D,
>(
  selector: TSelector<T>,
  keyElement: string | number | undefined,
) => {
  if (keyElement) {
    const option = useSelectorTypes(selector)[keyElement] as FetchedData<D>;
    return { ...extractGenFetchData(option) };
  }
  const data = useSelectorTypes(selector) as FetchedData<D>;
  return {
    ...extractGenFetchData(data),
  };
};

/** функция возвращает данные из стейта GenFetchData с  shallowEqual
 * @ data
 * @ isLoading
 * @ error
 * @ LTU
 * */
export const useGenFetchDataShallowEqualSelector: TGenFetchSelector = <
  T extends FetchedData<D> | Record<string, FetchedData<D>>,
  D,
>(
  selector: TSelector<T>,
  keyElement: string | number | undefined,
) => {
  if (keyElement) {
    const option = useShallowEqualSelector(selector)[
      keyElement
    ] as FetchedData<D>;
    return { ...extractGenFetchData(option) };
  }
  const data = useSelectorTypes(selector) as FetchedData<D>;
  return {
    ...extractGenFetchData(data),
  };
};

/**
 * функция возвращает типизированный useDispatch
 * */
export const useDispatchApp: TFUseDispatchApp = () =>
  useDispatch<AppDispatch>();
