import { takeEvery, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
  DCOUrlWorker,
  editUserPersonalSagaWorker,
  sentPushNoticationSagaWorker,
  workerFetchPersonalInfo,
  singInWorker,
  singUpWorker,
  confirmWorker,
  forgotPasswordWorker,
  changePasswordWorker,
  getTokenWorker,
  refreshTokenWorker,
  openUrlByTokenWorker,
  fetchUserInfoWorker,
  userCreateWorker,
  editPasswordWorker,
} from './workers';
import {
  fetchPersonalInfo,
  fetchUserInfo,
  fetchConfirmCreate,
} from '../actions';
import {
  sentPushNotificationRequest,
  setEditPersonal,
  fetchDCOUrl,
  singInRequest,
  singUpRequest,
  setConfirmRequest,
  forGotPasswordRequest,
  forGotChangeRequest,
  getTokenRequest,
  refreshTokenRequest,
  openUrlByToken,
  editPasswordAction,
} from '../reduser';

export default function* watchUser(): SagaIterator {
  yield takeEvery(fetchPersonalInfo, workerFetchPersonalInfo);
  yield takeLatest(setEditPersonal, editUserPersonalSagaWorker);
  yield takeEvery(sentPushNotificationRequest, sentPushNoticationSagaWorker);
  yield takeLatest(fetchDCOUrl, DCOUrlWorker);
  yield takeLatest(singInRequest, singInWorker);
  yield takeLatest(singUpRequest, singUpWorker);
  yield takeLatest(fetchUserInfo, fetchUserInfoWorker);
  yield takeEvery(setConfirmRequest, confirmWorker);
  yield takeEvery(fetchConfirmCreate, userCreateWorker);
  yield takeLatest(forGotPasswordRequest, forgotPasswordWorker);
  yield takeLatest(forGotChangeRequest, changePasswordWorker);
  yield takeEvery(getTokenRequest, getTokenWorker);
  yield takeEvery(refreshTokenRequest, refreshTokenWorker);
  yield takeEvery(refreshTokenRequest, refreshTokenWorker);
  yield takeLatest(openUrlByToken, openUrlByTokenWorker);
  yield takeLatest(editPasswordAction, editPasswordWorker);
}
