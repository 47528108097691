import React, { FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Container from './container';
import { useAuthDetail } from './hooks/useAuthDetail';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import ChangePassword from './ChangePassword';

const Auth: FC = () => {
  const { match, getToRoute } = useAuthDetail();
  return (
    <Container>
      <Switch>
        <Route exact path={`${match.path}/sign-in`} component={SignIn} />
        <Route path={`${match.path}/sign-up`} component={SignUp} />
        <Route
          path={`${match.path}/forgot-password`}
          component={ForgotPassword}
        />
        <Route
          path={`${match.path}/change-password`}
          component={ChangePassword}
        />
        <Redirect from="/auth" to={getToRoute()} />
      </Switch>
    </Container>
  );
};

export default Auth;
