import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { TMakeReqWithRD, makeReqWithRD } from 'redux/makeReqWithRD';
import { getOffersList } from 'domains/offers/model/actions';
import { toastSuccessBold } from 'utils/ToastUtils/toastSuccessBold';
import {
  addOffer,
  countCreativesOffer,
  editOffer,
  getOfferInfo,
} from '../actions';
import {
  addOfferAPI,
  countCreativesOfferAPI,
  editOfferAPI,
  getOfferInfoAPI,
} from '../api';
import { setOfferInfo } from '../reducer';

export function* workerGetOfferInfoSaga({
  payload,
}: ReturnType<typeof getOfferInfo>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getOfferInfoAPI>>(makeReqWithRD, {
      fetcher: getOfferInfoAPI,
      fill: setOfferInfo,
      parameters: payload,
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* workerAddOfferSaga({
  payload,
}: ReturnType<typeof addOffer>): SagaIterator<void> {
  const { callbacks, ...params } = payload
  try {
    const result: boolean = yield call(addOfferAPI, params);
    if (result) {
      toastSuccessBold(`offers_page.offer_has_created`, {
        title: params.data.title
      });
      if (callbacks) {
        callbacks?.setEdited(false);
        callbacks?.goBack();
      }
    }
  } catch (e) {
    console.error({ e });
  } finally {
    if (callbacks) {
      callbacks?.setisLoadingCreate(false);
    }
  }
}

export function* workerEditOfferSaga({
  payload,
}: ReturnType<typeof editOffer>): SagaIterator<void> {
  const { mode, currentFilter, callbacks, ...params } = payload;
  try {
    const result: boolean = yield call(editOfferAPI, params);
    if (result) {
      if (mode === 'list') {
        if (params.data.status) {
          if (params.data.status === 'ARCHIVE') {
            toastSuccessBold('offers_page.offer_archived',
              { title: params.data.title })
          }
          if (params.data.status === 'LAUNCHED') {
            toastSuccessBold(`offers_page.offer_launched`, {
              title: params.data.title
            });
          }
        }
        const getListParams = currentFilter?.length
          ? { partner_xxhash_list: currentFilter }
          : {};
        yield put(getOffersList(getListParams));
      }
      if (mode === 'item') {
        const count: number = yield call(countCreativesOfferAPI, params);
        callbacks?.setCreoCount(count)
        callbacks?.onSuccess()
        yield put(getOfferInfo({ id: params.id }))
      }
    }
  } catch (e) {
    if (mode === 'item') {
      callbacks?.onError(e.message)
      yield put(getOfferInfo({ id: params.id }))
    }
    console.error({ e });
  }
}

export function* workerCountCreativesOfferSaga({
  payload,
}: ReturnType<typeof countCreativesOffer>): SagaIterator<void> {
  const { callbacks, ...params } = payload
  try {
    const count: number = yield call(countCreativesOfferAPI, params);
    if (typeof count === 'number') {
      callbacks.setCreoCount(count)
    }
  } catch (e) {
    console.error({ e });
  }
}
