import React, { FC, useCallback, useEffect, useState } from 'react';
import { NavLink, Switch, Route, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NavigationHeader from 'components/NavigationHeader';
import { useSetLastIdCreative } from 'domains/creative/hooks';
import { useIsGroup } from 'domains/user/hooks';
import { useDispatchApp } from 'redux/rootSelectors';
import { KeysSettings, useStateValue } from 'utils/context';
import { fetchPDataByTarget } from 'domains/campaign/model/actions';
import { clearClientPersonalInfoById } from 'domains/client/reduser';
import Targeting from './Targeting';
import Settings from './Settings';
import BasicSettings from './BasicSettings';
import css from './styles.module.scss';
import Header from './Header';
import { useCreativeDetail } from './hooks/useCreativeDetail';
import ErrorReport from '../../../../components/Errors/Reports';
import { useStorageIsShowStatistics } from '../../../storage/model/selectors';
import { TDates } from '../../../../types/general';
import { setIsShowTargetStatistics } from '../../../storage/model/reducer';
import { useFetchVendorLinks } from '../../model/hooks/useValidateVendorLInk';
import Loading from './Header/Loading';
import Experimental from './Experimental';

const CreativeEdit: FC = () => {
  const dispatch = useDispatchApp();
  const { state } = useStateValue();
  const settings = state.settings[KeysSettings.CAMPAIGN];
  const isShowStatisticsStorage = useStorageIsShowStatistics();

  const { location, id, path, item, error, campaign } = useCreativeDetail();

  const [tab, setTab] = useState(location.pathname.split('/').pop());
  const { t } = useTranslation();

  const [period, setPeriod] = useState<TDates['period']>(() => settings.period);
  const [commonTypePeriod, setCommonTypePeriod] = useState<TDates['type']>(
    () => settings.type,
  );

  const [showStatistic, setShowStatistic] = useState(
    () => isShowStatisticsStorage,
  );

  const onSetIsShowStatistics = useCallback(() => {
    dispatch(setIsShowTargetStatistics(!showStatistic));
    setShowStatistic(!showStatistic);
  }, [showStatistic]);

  useFetchVendorLinks();

  useEffect(() => {
    if (campaign) {
      setCommonTypePeriod(() => settings.type);
      setPeriod(() => settings.period);
    }
  }, [campaign, settings]);

  useEffect(() => {
    dispatch(fetchPDataByTarget({ id }));
  }, [id]);

  useEffect(() => {
    setShowStatistic(() => isShowStatisticsStorage);
  }, [isShowStatisticsStorage]);

  useEffect(
    () => () => {
      dispatch(clearClientPersonalInfoById());
    },
    [],
  );

  useSetLastIdCreative();
  const {
    isWithTargets,
    isWithCreatives,
    isOwner,
    isSelfUser,
    isEperimentalOwner,
  } = useIsGroup();

  if (error.isError) {
    return <ErrorReport error={error} />;
  }

  const navLinkClickHandler = (val: string) => {
    setTab(val);
    document.scrollingElement?.scrollTo({ top: 0 });
  };

  return (
    <div className={css.creative}>
      <>
        {item && (
          <NavigationHeader
            disabled={!!item && !!item.campaign_xxhash}
            back={{
              title: t('creative.header_menu.back'),
              to: item.campaign_xxhash
                ? `/main/campaign/${item.campaign_xxhash}/creatives`
                : '/main/campaigns',
            }}
          >
            {(isWithCreatives || isWithTargets || isOwner || isSelfUser) && (
              <NavLink
                to={{
                  ...location,
                  pathname: `/main/campaign/${item.campaign_xxhash}/creatives/${id}/basic_settings`,
                }}
                activeClassName="isActive"
                onClick={() => navLinkClickHandler('basic_settings')}
              >
                {t('creative.header_menu.basic_settings')}
              </NavLink>
            )}

            {(isWithTargets || isOwner || isSelfUser) && (
              <NavLink
                to={{
                  ...location,
                  pathname: `/main/campaign/${item.campaign_xxhash}/creatives/${id}/targeting`,
                }}
                activeClassName="isActive"
                onClick={() => navLinkClickHandler('targeting')}
                // className="isDisabled"
              >
                {t('creative.header_menu.targetings')}
              </NavLink>
            )}

            {isOwner && (
              <NavLink
                to={{
                  ...location,
                  pathname: `/main/campaign/${item.campaign_xxhash}/creatives/${id}/settings`,
                }}
                activeClassName="isActive"
                onClick={() => navLinkClickHandler('settings')}
              >
                {t('creative.header_menu.settings')}
              </NavLink>
            )}

            {isEperimentalOwner && (
              <NavLink
                to={{
                  ...location,
                  pathname: `/main/campaign/${item.campaign_xxhash}/creatives/${id}/experimental`,
                }}
                activeClassName="isActive"
                onClick={() => navLinkClickHandler('experimental')}
              >
                Experimental
              </NavLink>
            )}
          </NavigationHeader>
        )}

        {campaign ? (
          <Header
            showStatistic={showStatistic}
            onSetIsShowStatistics={onSetIsShowStatistics}
            creative={item}
            tab={`${tab}`}
            period={period}
            type={commonTypePeriod}
            setType={setCommonTypePeriod}
            setPeriod={setPeriod}
            data_start={campaign.date_start.date}
            data_end={campaign.date_end.date}
            fullView={tab === 'targeting'}
          />
        ) : (
          <Loading fullView={tab === 'targeting'} />
        )}

        <Switch>
          <Route path={`${path}/basic_settings`} component={BasicSettings} />
          <Route
            path={`${path}/targeting`}
            render={(routerProps) => (
              <Targeting
                {...routerProps}
                isShowStatistics={showStatistic}
                periodCommon={period}
                commonTypePeriod={commonTypePeriod}
              />
            )}
          />
          {isOwner && <Route path={`${path}/settings`} component={Settings} />}
          {isEperimentalOwner && (
            <Route path={`${path}/experimental`} component={Experimental} />
          )}
          {item && (
            <Redirect
              from={path}
              to={{
                ...location,
                pathname: `/main/campaign/${
                  item && item.campaign_xxhash
                }/creatives/${id}/basic_settings`,
              }}
            />
          )}
        </Switch>
      </>
    </div>
  );
};

export default CreativeEdit;
