import { RefObject, useLayoutEffect, useRef } from 'react';

type TFUseOuterElementClickProps = {
  isShow: boolean;
  closeModal: () => void;
  callback?: () => void;
  hideDefaultsEvents?: boolean;
};

type TFUseOuterElementClick = (props: TFUseOuterElementClickProps) => {
  wrapperRef: RefObject<HTMLDivElement>;
};

/** функция скрывает элемент если соверщить клик вне его
 * @isShow состояние показа елемента
 * @setIsShow функция изменения состояния
 * @classnameWrapper класс блока в не котором отслеживать клик
 * */
export const useOuterElementClick: TFUseOuterElementClick = ({
  isShow,
  closeModal,
  callback,
  hideDefaultsEvents = false,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (!hideDefaultsEvents) {
        event.stopPropagation();
        event.preventDefault();
      }
      const { target } = event;
      if (!(target instanceof Element)) return;
      if (
        target.className &&
        typeof target.className === 'string' &&
        target.className.includes('-option') &&
        target.className.includes('css-')
      ) {
        return;
      }
      if (
        wrapperRef?.current &&
        wrapperRef.current.contains(target) &&
        callback
      ) {
        callback();
      }

      if (wrapperRef?.current && !wrapperRef.current.contains(target)) {
        closeModal();
      }
    }

    if (isShow) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [wrapperRef, isShow]);

  return {
    wrapperRef,
  };
};
