import React, { FC, useMemo } from 'react';
import PageHeader from 'components/PageHeader';
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import cn from 'classnames';
import Loader from 'components/UI/Loader';
import useCreateUserFormik from 'domains/usersAPI/model/hooks/useCreateUserFormik';
import useFetchUserFormData from 'domains/usersAPI/model/hooks/useFetchUserFormData';
import { useTranslation } from 'react-i18next';
import NavigationHeader from 'components/NavigationHeader';
import i18n from '../../../../i18n';
import css from './styles.module.scss';
import Info from '../components/Info';
import Settings from './Settings';

const Create: FC = () => {
  const { isLoading } = useFetchUserFormData();
  const { formik, isLoadingCreate, tagValidationError } = useCreateUserFormik();
  const { t } = useTranslation();

  const links = useMemo(
    () => [
      {
        title: i18n.t(`users_page.users_api`),
        url: `/control_panel/users_api`,
        type: 'home',
      },
      {
        title: i18n.t(`users_api.create_title`),
        url: `заглушка`,
        type: 'end',
      },
    ],
    [i18n.language],
  );

  return (
    <>
      <NavigationHeader
        back={{
          to: `/control_panel/users_api`,
        }}
      />
      <div className={css.form_user}>
        <PageHeader title={t(`users_api.create_title`)}>
          <Breadcrumbs type="Custom" linksCustom={links} />
        </PageHeader>
        {isLoading ? (
          <div className={css.loader}>
            <Loader />
          </div>
        ) : (
          <div className={cn(css.container, 'container')}>
            <Settings formik={formik} tagValidationError={tagValidationError} isLoadingCreate={isLoadingCreate} mod="create" />
            <Info />
          </div>
        )}
      </div>
    </>
  );
};

export default Create;
