import { TCampaignsFilter } from 'domains/campaigns/types';
import React, { FC, RefObject } from 'react';
import cn from 'classnames';
import { SelectField } from 'types/general';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment';
import { ReactSVG } from 'react-svg';
import { FiX } from 'react-icons/fi';
import NewSelect from 'components/UI/NewSelect';
import css from '../../styles.module.scss';
import dateSVG from '../../icon/date.svg';
import i18next from '../../../../i18n';

interface ICampaignModeProps {
  filters: TCampaignsFilter[];
  fieldRef: RefObject<HTMLDivElement>;
  filterOptions: SelectField[];
  selectDefault(value: string, options: SelectField[]): SelectField[];
  changeItem: (id: number, value: string, k: keyof TCampaignsFilter) => void;
  filterOptionsCompare: SelectField[];
  isValidItem: (
    id: number,
    ar: TCampaignsFilter[],
    isCount?: boolean | undefined,
  ) => boolean;
  handleRemoveItemFilter: (id: number) => void;
}

const CampaignMode: FC<ICampaignModeProps> = ({
  filters,
  fieldRef,
  filterOptions,
  selectDefault,
  changeItem,
  filterOptionsCompare,
  isValidItem,
  handleRemoveItemFilter,
}) => (
  <>
    {filters.map(({ value, key, compare, id }) => (
      <div key={id} className={css.settingsItem} ref={fieldRef}>
        <NewSelect
          selectAttributes={{
            placeholder: i18next.t('creative.create_creative.sel_type'),
            options: filterOptions,
            value: selectDefault(key, filterOptions),
          }}
          onChangeCallback={{
            id,
            key: 'key',
            callback: changeItem,
          }}
          className={cn(css.select, css.date)}
          heightCustom={40}
          viewStyle="Vertical"
        />
        <NewSelect
          selectAttributes={{
            placeholder: i18next.t('creative.create_creative.sel_type'),
            options: filterOptionsCompare,
            value: selectDefault(compare, filterOptionsCompare),
          }}
          onChangeCallback={{
            id,
            key: 'compare',
            callback: changeItem,
          }}
          heightCustom={40}
          className={cn(css.select, css.params)}
          viewStyle="Vertical"
        />
        <div
          className={cn(css.calendar, {
            _isWrond: isValidItem(id, filters, true),
          })}
        >
          <DayPickerInput
            formatDate={MomentLocaleUtils.formatDate}
            parseDate={MomentLocaleUtils.parseDate}
            placeholder={i18next.t(`filter.format__date`)}
            dayPickerProps={{
              localeUtils: MomentLocaleUtils,
              locale: i18next.language || 'ru',
            }}
            value={value ? moment(value).format('DD.MM.YYYY') : ''}
            onDayChange={(val) => {
              const valueDay = val || null;
              if (valueDay) {
                changeItem(id, `${valueDay}`, 'value');
              } else {
                changeItem(id, ``, 'value');
              }
            }}
          />
          <ReactSVG className={css.icon} src={dateSVG} />
        </div>
        <div
          className={css.close_wrap}
          onClick={(event) => {
            event.stopPropagation();
            handleRemoveItemFilter(id);
          }}
        >
          <FiX size={17} className={css.close} />
        </div>
      </div>
    ))}
  </>
);

export default CampaignMode;
