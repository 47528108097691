import { createAction } from '@reduxjs/toolkit';
import { TForGot } from 'domains/user/types';
import {
  PActivityUsers,
  PGetManagementUsersList,
  PUserCreate,
  PUserEdit,
  TSendConfirmationPayload,
} from './types';

export const fetchManagementUsers = createAction<PGetManagementUsersList>(
  'managementUsers/fetchManagementUsers',
);

export const fetchUsersOnline = createAction(
  'managementUsers/fetchUsersOnline',
);

export const fetchActivityUsers = createAction<PActivityUsers>(
  'managementUsers/fetchActivityUsers',
);

export const createNewUser = createAction<PUserCreate>('user/createNewUser');

export const editUser = createAction<PUserEdit>('user/editUser');

export const resetPasswordRequest = createAction<TForGot>('user/resetPassword');

export const sendConfirmationEmail = createAction<TSendConfirmationPayload>(
  'user/sendConfirmationEmail',
);
