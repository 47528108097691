import { useMemo } from 'react';
import i18n from '../i18n';

type TUseTranslateConstObject = (obj: any) => any[];

/* Функция отдает объект с функцией перевода label и еще и кешируется до сменя языка. 
  Нужно, чтобы при смене языка и возврате на предыдущую страницу, применялся перевод для констант, которые импортируются в компонент, а не находятся в нем
  */
export const useTranslateConstObject: TUseTranslateConstObject = (obj) =>
  useMemo(
    () => [
      ...obj.map((item: any) => ({
        ...item,
        label: i18n.t(item.label),
      })),
    ],
    [i18n.language],
  );
