import Card from 'components/UI/Card';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FiExternalLink } from 'react-icons/fi';
import Tooltip from 'components/UI/Tooltip';
import { FormikProps } from 'formik';
import { TFormikProps } from 'domains/offer/model/types';
import Input from 'components/UI/Input/input';
import css from '../styles.module.scss';

interface Props {
  formik: FormikProps<TFormikProps>;
}

const Links: FC<Props> = ({ formik }) => {
  const { t } = useTranslation();

  return (
    <Card
      noHover
      style={{ padding: '28px 24px' }}
      isUseAccess
      className={css.card}
    >
      <h3 className={css.title}>
        {t(`campaigns_page.campaign_settings.additional.links.tabs.links`)}
      </h3>
      <div className={css.form}>
        <div>
          <Input
            label={t(
              `campaigns_page.campaign_settings.additional.links.table.link`,
            )}
            error={!!(formik.touched.link && formik.errors.link)}
            errortext={formik.errors.link}
            inputAttributes={{
              placeholder: t(`creative.create_creative.Link`),
              name: 'link',
              value: formik.values.link || '',
              onChange: formik.handleChange,
            }}
            theme="horizontal"
            isTextarea
            classname="exactly"
          />
          <div className={css.hint}>
            <Tooltip
              white
              hintWidth={300}
              title="{CAMP_ID}"
              className={css.tooltip}
            >
              <h6 style={{ marginBottom: 0 }}>
                {t('creative.create_creative.tizmacr.m1.t1')}
              </h6>
              <p style={{ marginBottom: 0 }}>
                {t('creative.create_creative.tizmacr.m1.t2')}
              </p>
              <p
                style={{
                  marginBottom: 0,
                  color: '#828282',
                }}
              >
                https://www.example.com.ru/?camp_id=6596f2bb147e5638
              </p>
              <a
                style={{
                  display: 'block',
                  marginTop: '16px',
                }}
                href="https://terratraf.readme.io/docs/td-general-macros"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('more_btn')} <FiExternalLink size={16} />
              </a>
            </Tooltip>
            &nbsp;
            <Tooltip
              white
              hintWidth={300}
              title="{CR_ID}"
              className={css.tooltip}
            >
              <h6 style={{ marginBottom: 0 }}>
                {t('creative.create_creative.tizmacr.m2.t1')}
              </h6>
              <p style={{ marginBottom: 0 }}>
                {t('creative.create_creative.tizmacr.m2.t2')}
              </p>
              <p
                style={{
                  marginBottom: 0,
                  color: '#828282',
                }}
              >
                https://www.example.com.ru/?cr_id=CgiaFuHAXVmrZxDSkJq5ChiGuBSC1vKXuBQ
              </p>
              <a
                style={{
                  display: 'block',
                  marginTop: '16px',
                }}
                href="https://terratraf.readme.io/docs/td-general-macros"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('more_btn')} <FiExternalLink size={16} />
              </a>
            </Tooltip>
            &nbsp;
            <Tooltip
              white
              hintWidth={300}
              title="{SRC_ID}"
              className={css.tooltip}
            >
              <h6 style={{ marginBottom: 0 }}>
                {t('creative.create_creative.tizmacr.m3.t1')}
              </h6>
              <p style={{ marginBottom: 0 }}>
                {t('creative.create_creative.tizmacr.m3.t2')}
              </p>
              <p
                style={{
                  marginBottom: 0,
                  color: '#828282',
                }}
              >
                https://www.example.com.ru/src_id=CgiaFuHAXVmrZxDSkJq5ChiGuBSC1vKXuBQ
              </p>
              <a
                style={{
                  display: 'block',
                  marginTop: '16px',
                }}
                href="https://terratraf.readme.io/docs/td-general-macros"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('more_btn')} <FiExternalLink size={16} />
              </a>
            </Tooltip>
            &nbsp;
            <Tooltip
              white
              hintWidth={300}
              title="{TR_KEY} "
              className={css.tooltip}
            >
              <h6 style={{ marginBottom: 0 }}>
                {t('creative.create_creative.tizmacr.m4.t1')}
              </h6>
              <p style={{ marginBottom: 0 }}>
                {t('creative.create_creative.tizmacr.m4.t2')}
              </p>
              <p
                style={{
                  marginBottom: 0,
                  color: '#828282',
                }}
              >
                https://www.example.com.ru/?tr_key=CgjifzCW8PhxyRCEt7flBhj-4L3vBQ
              </p>
              <a
                style={{
                  display: 'block',
                  marginTop: '16px',
                }}
                href="https://terratraf.readme.io/docs/td-general-macros"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('more_btn')} <FiExternalLink size={16} />
              </a>
            </Tooltip>
          </div>
        </div>
        <Input
          label={t(`creative.widget_text.adomain`)}
          error={!!(formik.touched.adomain && formik.errors.adomain)}
          errortext={formik.errors.adomain}
          inputAttributes={{
            placeholder: t(`creative.create_creative.Link`),
            name: 'adomain',
            value: formik.values.adomain || '',
            onChange: formik.handleChange,
          }}
          theme="horizontal"
          isTextarea
          classname="exactly"
        />
        <Input
          label={t(
            `campaigns_page.campaign_settings.additional.links.table.pixel`,
          )}
          error={!!(formik.touched.pixel && formik.errors.pixel)}
          errortext={formik.errors.pixel}
          inputAttributes={{
            placeholder: t(`creative.create_creative.Link`),
            name: 'pixel',
            value: formik.values.pixel || '',
            onChange: formik.handleChange,
          }}
          theme="horizontal"
          isTextarea
          classname="exactly"
        />
      </div>
    </Card>
  );
};

export default Links;
