import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import css from '../../styles.module.scss';

interface PartnerInfo {
  title: string;
  link: string;
}
interface PartnerItemProps {
  partnerInfo: PartnerInfo;
  title: string;
}

export const PartnerItem: FC<PartnerItemProps> = ({ partnerInfo, title }) => (
  <div className="showMorePopup_item">
    <>{partnerInfo.title}</>{' '}
    <strong>
      <NavLink
        className={css.link}
        target="_blank"
        to={{
          pathname: partnerInfo.link,
        }}
      >
        {title}
      </NavLink>
    </strong>
  </div>
);
