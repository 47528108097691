import i18next from 'i18next';

type TCheckedLocalization = (
  path: string,
  exit: string,
  isExitPath?: boolean,
) => string;

/** функция проверяется есть ли локализация по пути и возвращает либо перевод либо заготовленную строку
 * @ path -> путь в локализационном объекте
 * @ exit -> строка на выходе либо путь
 * @ isExitPath -> является ли выходное начение path или нет
 *
 * */
export const checkedLocalization: TCheckedLocalization = (
  path,
  exit,
  isExitPath = true,
) => {
  if (i18next.t(path) === path) {
    if (isExitPath) {
      return i18next.t(exit);
    }
    return exit;
  }
  return i18next.t(path);
};
