import React, { FC } from 'react';
import cn from 'classnames';
import CardsList from 'components/UI/CardList';
import Card from 'components/UI/Card';
import Help from 'components/Help';
import ExperimentalInputField from 'components/ExperimentalInputField';
import HeaderCampaign from '../HeaderCampaign';
import { useCampaignExperimental } from './hooks';
import css from './styles.module.scss';

const Experimental: FC = () => {
  const { campaign, isLoading, experimentalInfo } = useCampaignExperimental();

  const fieldsDataArr = experimentalInfo?.filter(
    (fieldData) => fieldData.page === 'experimental',
  );

  return (
    <>
      <HeaderCampaign campaign={campaign} isLoading={isLoading} />
      <div className={cn(css.container, 'container')}>
        <CardsList>
          <Card noHover isUseAccess>
            {fieldsDataArr?.length ? (
              fieldsDataArr.map(
                (field) =>
                  field.type !== 'select' && (
                    <div
                      className={cn(css.item, {
                        _isPriceHorisontal: field.type === 'float',
                      })}
                      key={field.id}
                    >
                      <ExperimentalInputField
                        item={field}
                        mode="campaign"
                        config_values={campaign?.config_values}
                        xxhash={campaign?.xxhash}
                      />
                      {field.description !== '' && (
                        <Help
                          title=""
                          helpattributes={{
                            text: field.description,
                          }}
                        />
                      )}
                    </div>
                  ),
              )
            ) : (
              <p className={css.text}>Нет полей для отображения</p>
            )}
          </Card>
        </CardsList>
        <CardsList>
          <Card noHover isUseAccess>
            <p className={css.text}>
              Для пользователей с правами администратора доступен раздел с
              широким спектром настроек с целью проведения тестов.
            </p>
          </Card>
        </CardsList>
      </div>
    </>
  );
};

export default Experimental;
