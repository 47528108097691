import axios from 'axios';
import request from '../../lib/helpers/requestApi';
import {
  RProject,
  PPartnerIcon,
  PProject,
  PProjectEdit,
  RProjectRoles,
  TParamSetFile,
  TParamSetFiles,
  ProjectRolesParams,
} from './types';

/** функция получает данные о проекте */
export const getProjectInfo = request<RProject, PProject>({
  method: 'project.getInfo',
  showError: false,
});

/** функция получает иконку партнера */
export const getPartnerIcon = request<string, PPartnerIcon>({
  method: 'project.getPartnerIcon',
  showError: false,
});

/** Функция редактирования проекта */
export const editProject = request<boolean, PProjectEdit>({
  method: 'project.edit',
});

/** Функция получаетл список ролей пользователей */
export const getGroups = request<RProjectRoles[], ProjectRolesParams>({
  method: 'project.getGroups',
});

export const setFileProject = request<boolean, TParamSetFile>({
  method: 'project.setFile',
});

export const setFilesProject = request<boolean, TParamSetFiles>({
  method: 'project.setFiles',
});

export const getBlock = async (): Promise<boolean> => {
  try {
    let url = `https://tdm4.adcloud.org/static/maintenance.json`;
    if (process.env.REACT_APP_DEV_API === 'true') {
      const devApi =
        localStorage.getItem('CUSTOM_API_URL')?.slice(0, -4) ||
        process.env.REACT_APP_API?.slice(0, -4);
      url = `${devApi}static/maintenance.json`;
    }
    const result = await axios.get(url, {
      headers: { 'Cache-Control': 'no-cache, no-store, must-revalidate' },
    });
    return !!result?.data?.text;
  } catch (e) {
    return false;
  }
};
