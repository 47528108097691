import { useOuterElementClick } from 'hooks/useOuterElementClick';
import { useEffect, useState } from 'react';

interface IUseListFilterProps {
  iconRef: React.RefObject<HTMLDivElement>;
  fixed: boolean;
  returnPrevFilters: () => void;
}

type TUseListFilter = (props: IUseListFilterProps) => {
  wrapperRef: React.RefObject<HTMLDivElement>;
  isOpenPanel: boolean;
  setIsOpenPanel: React.Dispatch<React.SetStateAction<boolean>>;
  topPosition: number;
  rightPosition: number;
  heightPanel: number;
};

export const useListFilter: TUseListFilter = ({
  iconRef,
  fixed,
  returnPrevFilters,
}) => {
  const [isOpenPanel, setIsOpenPanel] = useState<boolean>(false);
  const [topPosition, setTopPosition] = useState<number>(0);
  const [rightPosition, setRightPosition] = useState<number>(0);
  const [heightPanel, setHeightPanel] = useState<number>(0);

  // контроль клика вне фильтра
  const { wrapperRef } = useOuterElementClick({
    isShow: isOpenPanel,
    closeModal: () => {
      setIsOpenPanel(false);
      returnPrevFilters();
    },
  });

  const calcTopPositionPanel = () => {
    if (iconRef.current) {
      setTopPosition(iconRef.current?.getBoundingClientRect().bottom);
      setRightPosition(
        document.body.clientWidth -
          (iconRef.current?.getBoundingClientRect().left +
            iconRef.current.clientWidth),
      );
      setHeightPanel(
        document.documentElement.clientHeight -
          iconRef.current?.getBoundingClientRect().bottom,
      );
    }
  };

  // рассчет положения панели на экране
  useEffect(() => {
    calcTopPositionPanel();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      calcTopPositionPanel();
    }, 500);
  }, [fixed]);

  // useEffect(() => {
  //   document.addEventListener('scroll', () => setIsOpenPanel(false));

  //   return () => {
  //     document.removeEventListener('scroll', () => setIsOpenPanel(false));
  //   };
  // }, []);

  return {
    wrapperRef,
    isOpenPanel,
    setIsOpenPanel,
    topPosition,
    rightPosition,
    heightPanel,
  };
};
