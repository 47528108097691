import React, { CSSProperties, FC } from 'react';
import cn from 'classnames';

type LoadProp = {
  style?: CSSProperties;
  classname?: string;
};

const Load: FC<LoadProp> = ({ style, classname }) => (
  <div className={cn('load-item', classname)} style={{ ...style }} />
);

export default Load;
