import WarningHtml from 'components/WarningHtml';
import { useCurrentGridEridInfo } from 'domains/creative/model/selectors';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  withAccept: boolean;
  setIsDisableSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
  isChangeErid?: boolean;
}

const EridWarning: FC<Props> = ({
  withAccept,
  isChangeErid,
  setIsDisableSaveButton,
}) => {
  const { data: gridData, isLoading: isLoadingGridData } =
    useCurrentGridEridInfo();
  const { t } = useTranslation();

  const isEmptyEridData =
    gridData && !isLoadingGridData && Array.isArray(gridData);

  useEffect(() => {
    if (gridData && !isLoadingGridData && !Array.isArray(gridData)) {
      setIsDisableSaveButton(false);
    }
  }, [gridData, isLoadingGridData]);

  useEffect(
    () => () => {
      setIsDisableSaveButton(false);
    },
    [],
  );

  const showWarningContent = () => {
    if (withAccept) {
      if (isChangeErid) {
        return (
          <WarningHtml
            close="button"
            setIsDisableSaveButton={setIsDisableSaveButton}
          >
            {t(`creative.eridWarning_confirm`)}
          </WarningHtml>
        );
      }
      return <></>;
    }
    return (
      <WarningHtml key={String(withAccept)}>
        {t(`creative.eridWarning_what_show`)}
      </WarningHtml>
    );
  };

  return <>{isEmptyEridData && showWarningContent()}</>;
};

export default EridWarning;
