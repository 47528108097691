import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CardsList from 'components/UI/CardList';
import Header from './Header';
import { Balance } from './Sidebar';
import css from './styles.module.scss';
import { Settings } from './Forms';

const Profile: FC = (props) => {
  const { t } = useTranslation();
  return (
    <div className={css.profile}>
      <Helmet>
        <title>{t('profile.profile_word')}</title>
      </Helmet>
      <Header />

      <div className="container">
        <div className={css.content}>
          <CardsList className={css.list}>
            <Settings {...props} />
          </CardsList>
          <CardsList className={css.list}>
            <Balance />
          </CardsList>
        </div>
      </div>
    </div>
  );
};

export default Profile;
