import { useClientsAllInfo } from 'domains/clients/model/selectors';
import { fetchAllClients } from 'domains/clients/reducer';
import { ClientsList } from 'domains/clients/types';
import { fetcherPartnersBalance } from 'domains/partners/model/actions';
import { usePartnersBalanceInfo } from 'domains/partners/model/selector';
import { RBreadCrumbs } from 'domains/search/model/types';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatchApp } from 'redux/rootSelectors';
import { SelectOption } from './useFormikBalance';

interface useFetchBalanceDataParams {
  idAgency?: string;
  currency: string;
  breadcrumbs: RBreadCrumbs | null;
}

interface useFetchBalanceDataReturn {
    isLoadingModal: boolean,
    selectOptions: SelectOption[],
    customFilterOption: (option: {
      label: string;
      value: string;
  }, rawInput: string) => boolean,
  }

export const useFetchBalanceData = ({
  idAgency,
  currency,
  breadcrumbs,
}: useFetchBalanceDataParams): useFetchBalanceDataReturn => {
  const dispatch = useDispatchApp();
  const { t } = useTranslation();

  const { data: clientList, isLoading: isLoadingClients } = useClientsAllInfo();
  const { data: balanceData, isLoading: isLoadingBalance } =
    usePartnersBalanceInfo();

  const balanceObj = useMemo(() => {
    const obj = {};
    balanceData?.forEach((item) => {
      const { xxhash, ...rest } = item;
      obj[xxhash] = rest;
    });
    return obj;
  }, [balanceData]);

  const createSelectOptions = (arr: ClientsList | null) => {
    if (!arr) return [];
    if (idAgency && breadcrumbs && !arr.find((item) => item.id === idAgency)) {
      arr.unshift({
        id: idAgency,
        internal_id: '',
        type: '',
        is_deleted: false,
        title: breadcrumbs.Agency.title,
        icon: '',
        default_icon: true,
        site: '',
        status: 'STOPPED',
      });
    }

    return arr
      .filter((item) => item.status !== 'DELETED')
      .map((client) => ({
        value: client.id,
        label: `${
          client.id === idAgency ? t(`just_words.w5`) : t(`just_words.w3`)
        }*/-/*${client.title}`,
        additional: {
          current: `${balanceObj[client.id]?.balance} ${currency}`,
        },
      }));
  };

  const selectOptions = createSelectOptions(clientList);

  useEffect(() => {
    dispatch(fetchAllClients({ idAgency }));
  }, []);

  useEffect(() => {
    if (clientList) {
      const partner_xxhash_list = clientList?.map((item) => item.id);
      if (idAgency) partner_xxhash_list.push(idAgency);
      dispatch(fetcherPartnersBalance({ partner_xxhash_list }));
    }
  }, [clientList]);

  const customFilterOption = (option: {label: string, value: string}, rawInput: string) => {
    const inputValue = rawInput.toLowerCase();
    const label = option.label.toLowerCase();
    const value = option.value.toLowerCase();

    const isLabel = label.includes(inputValue)
    if(isLabel) return true

    const isValue = value.includes(inputValue)
    if(isValue) return true

    const currentInternalId = clientList?.find(item => item.id === option.value)?.internal_id
    const isInternalId = currentInternalId?.includes(inputValue)
    if(isInternalId) return true  
      
    return false;
  };

  return {
    isLoadingModal: isLoadingClients || isLoadingBalance,
    selectOptions,
    customFilterOption,
  };
};
