import { TargetKey } from '../types';

/** Таргеты типа дерево */
export const arTargetTree: TargetKey[] = [
  'geo_id',
  'categories_rtb',
];

export const limitsTreeTargets = ['geo_id']

export const contextTarget: TargetKey[] = ['keywords'];

export type TRowLimitKey = 'site_domain' | 'site_uri';

export const includeValidLimit: TRowLimitKey[] = ['site_domain', 'site_uri'];

export const countLimit: Record<TRowLimitKey, number> = {
  site_domain: 1000,
  site_uri: 100,
};
