import React from 'react';
import { MdInfoOutline } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import Button from 'components/UI/Buttons/buttons';
import { getPreviewCreative } from 'domains/creative/api';
import { useDispatch } from 'react-redux';
import { setOpenPreviewModal } from 'domains/creative/reduser';
import { useUserInfo } from 'domains/user/model/selectors';
import { everyGroups } from 'utils/statics/everyGroups';
import css from './styles.module.scss';
import { CreativeDetail } from '../../types';
import { isGardCompanionType, isGardVideoInlineType } from '../hooks';
import { videoStringDuring } from '../../../../utils/duringVideo/countSec';
import { toastSuccess } from '../../../../utils/ToastUtils/toastUtils';

type Props = {
  show: boolean;
  creative: CreativeDetail;
};

const PreviewWidget: React.FC<Props> = ({
  show,
  creative: { type, xxhash, data },
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: user } = useUserInfo();

  const removeSymbolsFromHtml = (str: string) => str.replace(/[\n\t\r]/g, '');

  const onCopy = async () => {
    const result = await getPreviewCreative({ xxhash });
    copy(result.embed_code);
    toastSuccess(t('alerts.a1'));
  };

  const onCopyPreview = async () => {
    const result = await getPreviewCreative({ xxhash });
    copy(removeSymbolsFromHtml(result.data));
    toastSuccess(t('alerts.a4'));
  };

  return (
    <>
      {!show ? (
        <div className={css.widget}>
          <div className={css.body}>
            <div className={css.actions}>
              <Button
                title={t('creative.create_creative.Preview')}
                buttonAttributes={{
                  disabled: true,
                }}
                height={40}
              />
            </div>
            <div className={css.hint}>
              {type !== 'TEASER' &&
                type !== 'VIDEO_INLINE' &&
                type !== 'COMPANION' && (
                  <MdInfoOutline size="24" color="#0a0a0a" />
                )}
              <div>
                {type === 'HTML' && t('creative.crea_statuses.s15')}
                {type === 'PUSH' && t('creative.crea_statuses.s16')}
                {type === 'MULTI_FORMAT' &&
                  `${t('creative.crea_statuses.noAccessPreview')} ${t(
                    'creative.create_menu.MULTI_FORMAT',
                  )}"`}
                {type === 'YANDEX' && t('creative.crea_statuses.s17')}
                {type === 'VIDEO_INLINE' && t('creative.crea_statuses.s18')}
                {type === 'VIDEO_VAST' && t('creative.crea_statuses.s21')}
                {type === 'COMPANION' && t('creative.crea_statuses.s20')}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={css.widget}>
          <div className={css.body}>
            {((type === 'VIDEO_INLINE' && isGardVideoInlineType(data)) ||
              (type === 'COMPANION' && isGardCompanionType(data))) && (
              <div
                className={css.video}
                style={{ backgroundImage: `url(${data.video_poster_url})` }}
              >
                <div className={css.video__duration}>
                  {videoStringDuring(Math.round(data.duration))}
                </div>
              </div>
            )}
            <div className={css.actions}>
              <Button
                title={t('creative.create_creative.Preview')}
                height={40}
                buttonAttributes={{
                  onClick: () => dispatch(setOpenPreviewModal(true)),
                  style: {
                    marginBottom: '8px',
                  },
                }}
              />

              <div className={css.copy_btns}>
                {type !== 'PUSH' &&
                  type !== 'YANDEX' &&
                  type !== 'COMPANION' &&
                  type !== 'VIDEO_INLINE' &&
                  type !== 'VIDEO_VAST' &&
                  type !== 'MULTI_FORMAT' && (
                    <>
                      <Button
                        title={t('creative.crea_statuses.s11')}
                        transparent
                        height={40}
                        buttonAttributes={{
                          onClick: onCopy,
                        }}
                      />
                      {everyGroups(user, 'owner') && (
                        <Button
                          title={t(`creative.html.copy_code_preview`)}
                          transparent
                          height={40}
                          buttonAttributes={{
                            onClick: onCopyPreview,
                          }}
                        />
                      )}
                    </>
                  )}
              </div>
            </div>
            <div className={css.hint}>
              {type !== 'TEASER' &&
                type !== 'VIDEO_INLINE' &&
                type !== 'VIDEO_VAST' &&
                type !== 'COMPANION' && (
                  <MdInfoOutline size="24" color="#0a0a0a" />
                )}
              <div>
                {type === 'HTML' && t('creative.crea_statuses.s12')}
                {type === 'PUSH' && t('creative.crea_statuses.s13')}
                {type === 'MULTI_FORMAT' &&
                  t('creative.crea_statuses.MULTI_FORMAT')}
                {type === 'YANDEX' && t('creative.crea_statuses.s14')}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PreviewWidget;
