import i18next from 'i18next';
import { numFormat } from 'utils/toFixedNumber';

type TFFormatStatistics = (
  param: string | null | number | undefined | Date,
  isNumber?: boolean,
) => string | number;

export const formatStatistics: TFFormatStatistics = (
  param,
  isNumber = false,
) => {
  if (typeof param === 'string' || typeof param === 'number') {
    return numFormat(i18next.language, param);
  }
  if (isNumber) {
    return 0;
  }
  return '_';
};