import { StoreEnhancer } from '@reduxjs/toolkit';
import { userNotificationPush } from '../../domains/user/model/hooks';

type TResetStore = () => StoreEnhancer;

export const resetStore: TResetStore =
  () => (next) => (reducer, initialState) => {
    const enhanceReducer = (state: any, action: any) => {
      let newState = state;
      if (action.type === 'AUTH_LOGOUT_SUCCESS') {
        sessionStorage.clear();
        const { messaging } = userNotificationPush();
        if (messaging) {
          messaging.deleteToken();
          localStorage.removeItem('$push-token');
        }
        localStorage.removeItem('$user');
        localStorage.removeItem('$token');
        newState = {
          projectReducer: { ...state.projectReducer },
        };
      }

      return reducer(newState, action);
    };
    return next(enhanceReducer, initialState);
  };
