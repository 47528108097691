export type ParamsRPC = Record<string, unknown> | string;

export type TJsonRPC<T> = {
  id: string;
  jsonrpc: string;
  method: string;
  params: T;
};

type TFSentWssRpc = <T>(ws: WebSocket, method: string, params: T) => void;

export const sentWssRpc: TFSentWssRpc = (ws, method, params) => {
  const data: TJsonRPC<typeof params> = {
    id: '1',
    method,
    jsonrpc: '2.0',
    params,
  };
  ws.send(JSON.stringify(data));
};
