import ModalOverlay from 'components/Modals/ModalOverlay1';
import randomAvatar from 'domains/wss/model/utils/random';
import React, { FC } from 'react';
import { FiX } from 'react-icons/fi';
import i18next from 'i18next';
import Avatar from '../Avatar';
import css from './styles.module.scss';
import { objLiteralWSS, WSSUserInfo } from '../../../model/types';

interface IUsersModal {
  users: WSSUserInfo[];
  toggleModal: () => void;
}

const UsersModal: FC<IUsersModal> = ({ users, toggleModal }) => (
  <ModalOverlay onCloseModal={toggleModal} isOpen>
    <div className={css.card}>
      <div className={css.card__header}>
        <div className={css.card__title}>
          <h1>{i18next.t(`users_activity.users`)}</h1>
        </div>
        <div className={css.card__close}>
          <FiX size={24} onClick={toggleModal} />
        </div>
      </div>
      <div className={css.card__users}>
        {users.map(({ email, type, isActive }) => (
          <div className={css.card__users_item} key={email}>
            <div className={css.user__main}>
              <Avatar
                key={email}
                online={isActive ? 'online' : 'offline'}
                from={type}
                img={randomAvatar(email.slice(0, 1))}
              />
              <span className={css.user__email}>{email}</span>
            </div>
            <span>{i18next.t(`users_activity.${objLiteralWSS[type]}`)}</span>
          </div>
        ))}
      </div>
    </div>
  </ModalOverlay>
);
export default UsersModal;
