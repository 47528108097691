import { ReportClient, TERIDObject } from 'domains/client/types';
import { WeekStatisticItem } from 'domains/campaigns/types';
import { Dispatch, SetStateAction } from 'react';
import { IHistoryPeriod } from 'domains/historyChanges/types';
import { FetchedData } from '../../../redux/fetchedData';
import { TUploadFile } from '../../../utils/upload';

// eslint-disable-next-line no-shadow
export enum EClientsStatuses {
  STOPPED = 'STOPPED',
  LAUNCHED = 'LAUNCHED',
  DELETED = 'DELETED',
  ARCHIVE = 'ARCHIVE'
}

export type AgencyState = {
  isShowMCreate: boolean;
  isShowMChange: boolean;
  tempImage: FetchedData<TUploadFile>;
  idChange: string;
  agencyInfo: FetchedData<AgencyInfo>;
  lastAddAgency: FetchedData<ParamAgencyInfo>;
  editAgency: FetchedData<boolean>;
  clientsStat: FetchedData<TClientStat>;
  auditDictionaryList: FetchedData<TGetAuditDictionary[]>;
  statisticByAudit: FetchedData<TGetStatisticByAudit>;
};

export type ParamAgencyInfo = {
  id: string;
};
export type ParamCreateAgency = {
  title: string;
  site?: string;
  icon?: string;
};

export type ParamGetAuditDictionary = {
  partner_xxhash: string;
};

export type ParamAddAuditDictionary = {
  data: {
    title: string[];
    status: string;
    partner_xxhash: string;
  };
};

export type ParamEditAuditDictionary = {
  id: number;
  data: {
    title: string;
    status: string;
    partner_xxhash: string;
  };
};

export type ParamGetStatisticByAudit = {
  partner_xxhash: string;
  period: IHistoryPeriod;
  status_list_clients: string[];
}

export type AgencyInfo = {
  /** ID креатива */
  id: string;
  /** Статус Агенства. */
  status: 'STOPPED' | 'LAUNCHED' | 'DELETED' | 'ARCHIVE';
  /** ID Агенства */
  parent_id: string;
  /** Название клиента или агентства */
  title: string;
  /** URL иконки клиента */
  icon: string;
  /** Удалить ранее загруженную иконку? */
  remove_icon: boolean;
  /** Является ли иконка дефолтной? */
  default_icon: boolean;
  /** Сайт клиента */
  site: string;
  /** Клиент в Архиве */
  is_deleted: boolean;
  /** Включена автомодерация? */
  is_automoderation_enabled: boolean;
  /** Включена настройка типа эротики? */
  is_erotic_setting_enabled: boolean;
  /** Включена настройка типа жести? */
  is_violence_setting_enabled: boolean;
  /** Включена настройка типа анимации? */
  is_animation_setting_enabled: boolean;
  /** Включена проверка на ерид? */
  check_ERID: TERIDObject;
  /** Настройки видимости отчетов */
  reports: {
    /** Использовать ли настройки по-умолчанию?  */
    use_inherit_settings: boolean;
    /** Список настроек отчетов */
    items: ReportClient[];
  };
  /** Тип клиента: CLIENT, partner */
  type: 'CLIENT' | 'partner';
  range_calculation_coefficients: {
    from: number;
    to: number;
  };
};

export type ParamEditAgency = {
  id: string;
  title?: string;
  icon?: string;
  remove_icon?: boolean;
  removeIcon?: boolean;
  site?: string;
  registration?: boolean;
};

export type TSettingsForm = {
  from: number;
  to: number;
};

export type TEditBase = Partial<TSettingsForm> & {
  id: string;
  title?: string;
  site?: string;
  check_ERID?: boolean;
  remove_icon?: boolean;
};

export type TEditQuery = {
  id: string;
  title?: string;
  site?: string;
  check_ERID?: boolean;
  remove_icon?: boolean;
  range_calculation_coefficients?: TSettingsForm;
  callbacks?: Record<string, Dispatch<SetStateAction<boolean>>>;
};

export type TClientStatItem = WeekStatisticItem & {
  internal_id: string;
  title: string;
};

export type TClientStat = Record<string, TClientStatItem>;

export type TGetAuditDictionary = {
  id: number | string;
  title: string;
  status: string;
};

export type TGetStatisticByAudit = {
  auditoryId: {
    map(arg0: (i: any) => JSX.Element): unknown;
    clientId: {
      show: number;
      click: number;
      cpc: number;
      cpm: number;
      ctr: number;
      spent: number;
      bid_statistic: number;
      minus_bid: number;
      fclick: number;
      accept: number;
      convs_val: number;
      cpo: number;
      win_rate: number;
      minus_win_raw_tt: number;
      margin: number;
      cpm_bid: number;
      client_title: string;
      audit_dictionary_title: string;
    };
  };
};
