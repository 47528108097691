import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import { ru, en, de, tr } from './translations';
import 'moment/locale/de';
import 'moment/locale/tr';

i18n.use(LanguageDetector).init({
  resources: {
    ru,
    en,
    de,
    tr,
  },

  fallbackLng: 'en',
  whitelist: ['en', 'ru', 'de', 'tr'],
  load: 'languageOnly',
  nonExplicitWhitelist: true,

  // debug: true,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  // keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },

  react: {
    wait: true,
  },
});
i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
});
export default i18n;
