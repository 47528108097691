import React, { useRef, useCallback, useMemo, FC } from 'react';
import cn from 'classnames';
import { FiChevronDown, FiHelpCircle } from 'react-icons/fi';
import useDropDown from 'hooks/useDropDown';
import Tooltip from '../Tooltip';
import css from './styles.module.scss';
import ErrorText from '../ErrorText';

export type Value = string | number | undefined;
export type Option = [string | number, string];
export type Options = Option[];

type Props = {
  value: Value;
  label?: string;
  customChange: (v: string | number) => void;
  error?: boolean;
  errorText?: string;
  tooltip?: string;
  disabled?: boolean;
  options: Options;
  uppercase?: boolean;
  placeholder?: string;
  notField?: boolean;
  className?: string;
  withInput?: boolean;
  style?: React.CSSProperties;
};

const SelectV2: FC<Props> = ({
  value,
  label,
  error,
  errorText,
  disabled,
  tooltip,
  options,
  placeholder,
  uppercase,
  notField,
  className,
  customChange,
  withInput,
  style,
}) => {
  const dropdownMenu = useRef<HTMLDivElement | null>(null);
  const { toggle, isOpened, toggleOff } = useDropDown(dropdownMenu);

  const onChange = useCallback(
    // eslint-disable-next-line consistent-return
    (val: Value) => () => {
      if (typeof val === 'undefined') {
        return null;
      }
      customChange(val);
      toggleOff();
    },
    [customChange],
  );

  const optionsMemo = useMemo(() => new Map(options), [options]);
  // eslint-disable-next-line no-nested-ternary
  const labelVal =
    // eslint-disable-next-line no-nested-ternary
    value === undefined
      ? placeholder
      : optionsMemo.get(value) === undefined
      ? placeholder
      : optionsMemo.get(value);

  return (
    <div
      className={cn(css['form-group'], error ? css['form-group_error'] : '', {
        isDisabled: disabled,
        isUppercase: uppercase,
        withInput,
      })}
      ref={dropdownMenu}
      style={style}
    >
      {label && (
        <label htmlFor={label}>
          {label}
          {tooltip && (
            <Tooltip
              className={css['form-group__tooltip']}
              title={<FiHelpCircle size={16} color="#222" />}
              white
              hintWidth={300}
            >
              <p>{tooltip}</p>
            </Tooltip>
          )}
        </label>
      )}

      {notField ? (
        <div className={css.title}>
          {value !== undefined ? optionsMemo.get(value) : ''}
        </div>
      ) : (
        <div className={cn(css['form-group__select'], className)}>
          {withInput ? (
            <div
              className={cn(css['form-group__active'], {
                _isOpened: isOpened,
                _isError: error,
              })}
              title={labelVal}
            >
              <input
                type="text"
                placeholder={labelVal}
                onFocus={isOpened ? toggleOff : toggle}
              />
            </div>
          ) : (
            <div
              className={cn(css['form-group__active'], {
                _isOpened: isOpened,
                _isError: error,
              })}
              onClick={isOpened ? toggleOff : toggle}
              title={labelVal}
            >
              <span>{labelVal}</span>
              <FiChevronDown size={24} />
            </div>
          )}

          <div
            className={cn(css['form-group__dropdown'], {
              _isOpened: isOpened,
            })}
          >
            {Array.from(optionsMemo).map(([k, v]) => (
              <div
                className={cn(css['form-group__item'], {
                  _current: value === k,
                })}
                key={k}
                onClick={onChange(k)}
              >
                <span>{v}</span>
              </div>
            ))}
          </div>
        </div>
      )}

      {error && <ErrorText text={errorText} />}
    </div>
  );
};

export default SelectV2;
