import { TExperimentalInfoField } from 'domains/campaign/types';
import { checkedLocalization } from 'utils/checkedLocalization';

interface IOptionsItem {
  label: string;
  value: string;
}

export const createOptionsForConfigSelect = (
  fieldData?: TExperimentalInfoField,
): IOptionsItem[] | undefined =>
  fieldData?.configFieldsList?.map((item) => ({
    label: checkedLocalization(
      `campaigns_page.campaign_category.${item.field}`,
      item.title,
    ),
    value: String(item.id),
  }));
