import Load from 'components/UI/Load';
import React, { FC } from 'react';
import css from './styles.module.scss';

const LoadingWidget: FC = () => (
  <div className={css.loading_widget}>
    <div className={css.top}>
      <Load
        style={{
          width: `60px`,
          height: '60px',
          borderRadius: '100%',
        }}
      />
      <div className={css.left}>
        <Load
          style={{
            width: `100%`,
            height: '16px',
            marginBottom: '10px',
          }}
        />
        <Load
          style={{
            width: `100%`,
            height: '16px',
            marginBottom: '14px',
          }}
        />
        <Load
          style={{
            width: `100%`,
            height: '90px',
          }}
        />
      </div>
    </div>
  </div>
);

export default LoadingWidget;
