/* eslint-disable no-continue */
import { useEffect, useMemo } from 'react';
import { useCreativeVendorLinksInfo } from '../selectors';
/* import { TInternalItem } from '../../../../types/general'; */
import { useDispatchApp } from '../../../../redux/rootSelectors';
import { fetchVendorLink } from '../actions';
import i18n from '../../../../i18n';

export type THelper = {
  status: 'success' | 'error' | null;
  helperText: string;
};
type TState = {
  link?: string | null;
  pixel?: string | null;
  pixel_additional?: string | null;
  show?: string | null;
  show2?: string | null;
  extension_link?: string | null;
  js_code_audit?: string | null;
  click_audit_pixel?: string | null;
  show_html?: string | null;
};

type TFUseValidateVendorLInk = (p: { formik: TState; adomain?: string | null }) => {
  helper: () => Record<keyof TState, THelper>;
  helperError: boolean;
};

type TFUseFetchVendorLinks = () => void;

function cleanUp(url: string): string {
  let res: RegExpMatchArray | null | string = url.trim();
  if (res.search(/^https?:\/\//) !== -1)
    res = res.match(/^https?:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
  else res = res.match(/^([^\/?#]+)(?:[\/?#]|$)/i);
  return res ? res[1] : url;
}

export const useValidateVendorLInk: TFUseValidateVendorLInk = ({
  formik,
  adomain,
}) => {
  /*   const { data } = useCreativeVendorLinksInfo(); */

  const arWithDomain = ['link', 'extension_link'];

  const arLInk = Object.keys(formik);

  const checkStrk = (src: string) => {
    const temp = 'LineID=$$';
    const startIndex = src.indexOf(temp);
    if (startIndex < 0) return false;
    const endIndex = src.indexOf('$$', startIndex + temp.length);
    if (endIndex < 0) return false;
    const params = src.substring(startIndex + temp.length, endIndex);
    return new URLSearchParams(params).get('s_trk') !== null;
  };

  const helper = (/* mode?: string */) => {
    let result: Record<keyof TState, THelper> = {
      link: { helperText: '', status: null },
      pixel_additional: { helperText: '', status: null },
      pixel: { helperText: '', status: null },
      show_html: { helperText: '', status: null },
      show: { helperText: '', status: null },
      show2: { helperText: '', status: null },
      extension_link: { helperText: '', status: null },
      js_code_audit: { helperText: '', status: null },
      click_audit_pixel: { helperText: '', status: null },
    };
    for (let i = 0; i < arLInk.length; i += 1) {
      const key = arLInk[i];
      if (formik[key]) {
        if (key === 'js_code_audit') {
          if (formik[key]?.includes('js.onef.pro/static/reg1f_v1.js')) {
            if (formik[key]?.includes('show_id=')) {
              if (!formik[key]?.includes('show_id={RID}')) {
                result = {
                  ...result,
                  [key]: {
                    helperText: i18n.t('errors.parameter_d_n_match'),
                    status: 'error',
                  },
                };
              }
            } else {
              result = {
                ...result,
                [key]: {
                  helperText: i18n.t('errors.f1_d_n_cont_id'),
                  status: 'error',
                },
              };
            }
          }
          if (formik[key]?.includes('bs.serving-sys.ru')) {
            const str = formik[key];
            if (str) {
              const strkVal = checkStrk(str);
              if (!strkVal) {
                result = {
                  ...result,
                  [key]: {
                    helperText: 'Проверьте параметр LineID',
                    status: 'error',
                  },
                };
              }
            }
          }
          continue;
        }
        if (
          arWithDomain.includes(key) &&
          adomain &&
          formik[key] &&
          !formik[key]?.includes('{LINK_UNESC}') &&
          cleanUp(formik[key]) === cleanUp(adomain)
        ) {
          result = {
            ...result,
            [key]: {
              helperText: '',
              status: null,
            },
          };
          continue;
        }
        // отказываемся от логики которая выводит подсказку unknown_vendor. Временно?
        /*         if (mode !== 'MULTI_FORMAT') {
          const vendor: null | TInternalItem =
            formik[key] &&
            data &&
            data.find(
              // eslint-disable-next-line no-shadow
              ({ key: domain }) => cleanUp(formik[key]) === domain,
            );
          if (vendor) {
            result = {
              ...result,
              [key]: {
                helperText: `${vendor.title}`,
                status: 'success',
              },
            };
          } else {
            result = {
              ...result,
              [key]: {
                helperText: 'unknown_vendor',
                status: 'error',
              },
            };
          }
        } */
        if (
          (key === 'link' || key === 'extension_link') &&
          formik[key]?.includes('{LINK_UNESC}')
        ) {
          result = {
            ...result,
            [key]: {
              helperText: i18n.t(`creative.valid_vendor`),
              status: 'error',
            },
          };
        }
      } else if (result[key]) {
        result = {
          ...result,
          [key]: {
            helperText: '',
            status: null,
          },
        };
      }
    }
    return result;
  };

  const helperError = useMemo(() => {
    let isError = false;
    Object.entries(helper).forEach(([, v]) => {
      if (v.status === 'error') {
        isError = true;
      }
    });
    return isError;
  }, [helper]);

  return {
    helper,
    helperError,
  };
};

export const useFetchVendorLinks: TFUseFetchVendorLinks = () => {
  const dispatch = useDispatchApp();
  const { data } = useCreativeVendorLinksInfo();
  useEffect(() => {
    if (!data) {
      dispatch(fetchVendorLink());
    }
  }, []);
};
