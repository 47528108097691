import { useState } from 'react';

type TFUseCard = (bool: boolean) => {
  showHint: boolean;
  closeHint: () => void;
  openHint: () => void;
};

export const useCard: TFUseCard = (bool: boolean) => {
  const [showHint, setShowHint] = useState<boolean>(bool);

  const closeHint = () => {
    setShowHint(false);
  };

  const openHint = () => {
    setShowHint(true);
  };

  return {
    showHint,
    closeHint,
    openHint,
  };
};
