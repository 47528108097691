import request from '../../lib/helpers/requestApi';
import {
  ResponseUser,
  ParamSetPushTokenNotification,
  ParamUserEdit,
  TSingIn,
  RAuthToken,
  PSingUp,
  TConfirm,
  TForGot,
  TForGotChangePassword,
  PToken,
  UserConfig,
  TPUserConfig,
  TInfoByHash,
  PInfoByHash,
  PConfirmCreate,
  PEditPassword,
} from './types';

/** Функция получения информации о пользователе */
export const getPersonalInfo = request<ResponseUser>({
  method: 'user.getInfo',
});

/** функция отправки PUSH токена на сервер */
export const setPushTokenNotification = request<
  boolean,
  ParamSetPushTokenNotification
>({
  method: 'user.setPushToken',
});

/** функция изменения данных пользователя */
export const editUserPersonal = request<boolean, ParamUserEdit['user']>({
  method: 'user.edit',
});

/** функция возвращает ссылку на переход в личный кабинет DCO */
export const getDcoUrl = request<string>({
  method: 'user.showDCO',
});

/** функция авторизации */
export const userAuth = request<RAuthToken, TSingIn>({
  method: 'user.auth',
  showError: false,
});
/** функция для регистрации пользователей */
export const userSingUp = request<boolean, PSingUp>({
  method: 'user.registrationClient',
  showError: false,
});
/** функция подтверждения почты */
export const userConfirm = request<boolean, TConfirm>({
  method: 'user.confirmationClient',
});
/** функция  восстановления доступа */
export const userForGot = request<boolean, TForGot>({
  method: 'user.forgotPassword',
});
/** функция смены пароля */
export const userForGotChangePassword = request<boolean, TForGotChangePassword>(
  {
    method: 'user.changePassword',
  },
);
/** функция обновления токена */
export const userRefreshToken = request<RAuthToken, PToken>({
  method: 'user.tokenRefresh',
});

export const getUserConfigs = request<UserConfig>({
  method: 'user.getConfigs',
});

export const setUserConfigs = request<boolean, TPUserConfig>({
  method: 'user.setConfigs',
});

export const getInfoByHesh = request<TInfoByHash, PInfoByHash>({
  method: 'user.getInfoByHash',
});

export const getConfirmCreate = request<boolean, PConfirmCreate>({
  method: 'user.confirmationCreate',
});

export const editPassword = request<boolean, PEditPassword>({
  method: 'user.editPassword',
});
