import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiAlertCircle } from 'react-icons/fi';
import css from './styles.module.scss';

const Targeting: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={css.targeting}>
      <div className={css.empty_cap}>
        <div className={css.title}>
          <FiAlertCircle size={24} color="#0a0a0a" />
          <span>{t('creative.creasmall_set.notarg_text')}</span>
        </div>
        <div className={css.actions}>
          {/* <Button
            title="Создать"
            height={40}
            autoWidth
            onClick={() => history.goBack()}
          /> */}
        </div>
      </div>
      {/* <div className="container">
        <Formik
          onSubmit={values => {}}
          validate={values => {}}
          initialValues={{}}
          render={({ values, setFieldValue }) => <Form className={css.form} />}
        />
      </div> */}
    </div>
  );
};

export default Targeting;
