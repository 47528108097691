import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import CardList from 'components/UI/CardList';
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import Button from 'components/UI/Buttons/buttons';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';
import { useCreateCounteragent } from 'domains/counteragent/model/hooks/useCreateCounteragent';
import { useOrdList } from 'domains/counteragents/model/selectors';
import css from '../styles.module.scss';
import Parameters from '../components/Parameters';
import Info from '../components/Info';
import UploadImg from '../components/UploadImg';
import LoaderInfo from '../components/Info/LoaderInfo';

const CreateContractor: FC = () => {
  const { t } = useTranslation();

  const {
    formik,
    links,
    isLoading,
    userData,
    isPrompt,
    goBack,
    handleChangeInput,
  } = useCreateCounteragent();

  const { data: ordList, isLoading: isLoadingOrdList } = useOrdList();

  return (
    <>
      <Helmet>
        <title>{t(`ord.counterparte_creating`)}</title>
      </Helmet>

      <div className={css.header}>
        <div className="container">
          <Breadcrumbs type="Custom" linksCustom={links} />
          <div className={css.status}>
            <h1 className={css.title}>{t(`ord.counterparte_creating`)}</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className={css.container}>
          <CardList>
            <div>
              <Parameters
                formik={formik}
                handleChangeInput={handleChangeInput}
                userType={userData?.partner.type}
                mode="create"
              />
              <div className={css.actions}>
                <Button
                  title={t('campaigns_page.create_campaign.cancel_btn')}
                  transparent
                  height={40}
                  buttonAttributes={{
                    type: 'button',
                    onClick: goBack,
                  }}
                />
                <Button
                  title={t('campaigns_page.create_campaign.create_btn')}
                  height={40}
                  buttonAttributes={{
                    disabled: isLoading || !formik.isValid,
                    type: 'submit',
                    onClick: () => formik.handleSubmit(),
                  }}
                />
              </div>
            </div>
          </CardList>
          <CardList>
            <div>
              {isLoadingOrdList ? (
                <LoaderInfo mode="create" />
              ) : (
                <Info edit={false} formik={formik} ordList={ordList} />
              )}
              <UploadImg disabled />
            </div>
          </CardList>
        </div>
      </div>

      <Prompt when={isPrompt} message={t('just_words.exit_page_currect')} />
    </>
  );
};

export default CreateContractor;
