import { createAction } from '@reduxjs/toolkit';
import { TFetchFileRequest, TFileZipSaga } from '../types';
import { TKEY } from '../../../types/general';

export const fetchImageRequest =
  createAction<TFetchFileRequest>('uploads/fetchImage');

export const fetchZipRequest = createAction<TFileZipSaga>(
  'uploads/fetchZipRequest',
);

export const fetchBusinessSphere = createAction<TKEY>(
  'uploads/fetchBusinessSphere',
);
