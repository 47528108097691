import React, { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getToken } from 'lib/helpers/token';

type Props = {
  component: FC;
  exact?: boolean;
  path: string;
};

const PublicRoute: FC<Props> = ({ component: Component, path, exact }) => (
  <Route
    path={path}
    exact={exact}
    render={() => (getToken() ? <Redirect to="/" /> : <Component />)}
  />
);

export default PublicRoute;
