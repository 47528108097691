import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { FiChevronDown, FiAlertCircle, FiXCircle } from 'react-icons/fi';
import useDropDown from 'hooks/useDropDown';
import Portal from 'components/Portal';
import { useOnScroll } from 'hooks/useOnScroll';
import CheckCircle from 'assets/check-circle.svg';
import { ReactSVG } from 'react-svg';
import i18n from '../../i18n';
import css from './styles.module.scss';

type Props = {
  currentFilter: string;
  filterHandler: ((key: string) => void) | undefined;
  className?: string;
  showReport?: boolean;
};
interface ISelectedCreativeList {
  key: string;
  title: string;
  icon: React.ReactNode | null;
}

const TranslationsListFilter: FC<Props> = ({
  className,
  filterHandler,
  currentFilter,
  showReport,
}) => {
  const dropdownMenu = useRef<HTMLDivElement | null>(null);
  const dropdownMenuList = useRef<HTMLDivElement | null>(null);
  const { toggle, isOpened, toggleOff } = useDropDown(dropdownMenuList, false);

  const selectedCreativeList: ISelectedCreativeList[] = [
    {
      key: 'isAll',
      title: i18n.t('targetings.all'),
      icon: null,
    },
    {
      key: 'isTranslate',
      title: i18n.t(`translations.broadcast`),
      icon: <ReactSVG src={CheckCircle} className={css.checkCircle} />,
    },
    {
      key: 'isNoTranslate',
      title: i18n.t(`translations.no_broadcast`),
      icon: <FiXCircle size={18} color="#FF3636" />,
    },
    {
      key: 'isUnknown',
      title: i18n.t(`creative.crea_statuses.s4`),
      icon: <FiAlertCircle size={18} color="#FF9C08" />,
    },
  ];

  const onItemClickHandler = () => {
    toggleOff();
  };

  const bodyRect = document.body.getBoundingClientRect();
  const [offsetTop, setOffsetTop] = useState(0);

  const positionListRight = dropdownMenu.current?.getBoundingClientRect().right;
  const offsetRight = positionListRight
    ? bodyRect.width - positionListRight
    : 0;
  const { fixed } = useOnScroll(120);

  const calcOffsetTop = useCallback(() => {
    const positionListTop = dropdownMenu.current?.getBoundingClientRect().top;
    setOffsetTop(positionListTop || 0);
  }, []);

  useEffect(() => {
    if (isOpened) {
      document.addEventListener('scroll', onItemClickHandler);
    }
    document.addEventListener('scroll', calcOffsetTop);
    return () => {
      document.removeEventListener('scroll', onItemClickHandler);
      document.removeEventListener('scroll', calcOffsetTop);
    };
  }, [isOpened]);

  useEffect(() => {
    /* Рассчет с задержкой из-за анимации появления графика */
    setTimeout(() => {
      calcOffsetTop();
    }, 200);
  }, [showReport]);

  useEffect(() => {
    calcOffsetTop();
  }, []);

  return (
    <div className={cn(css.filter, className)} ref={dropdownMenu}>
      <div
        className={cn(css.active, { isOpened })}
        onClick={isOpened ? toggleOff : toggle}
      >
        <div className={cn(css.type, `${currentFilter}`)} />
        <div className={css.chevron}>
          <FiChevronDown size={24} strokeWidth={3} />
        </div>
      </div>

      <Portal className="filterList2">
        <div
          ref={dropdownMenuList}
          className={cn(css.list, { isOpened, fixed })}
          style={{
            right: `${offsetRight - 30}px`,
            top: `${offsetTop + 30}px`,
          }}
          onClick={onItemClickHandler}
        >
          {selectedCreativeList.map(({ key, title, icon }) => (
            <div
              key={key}
              className={cn(css.item, key, {
                isSelected: key === currentFilter,
              })}
              onClick={() => {
                toggleOff();
                if (filterHandler) {
                  filterHandler(key);
                }
              }}
            >
              {key === 'isAll' ? (
                <div className={css.type} />
              ) : (
                <div className={cn(css.type, 'no_bg')}>{icon}</div>
              )}
              <div className={css.text}>{title}</div>
            </div>
          ))}
        </div>
      </Portal>
    </div>
  );
};

export default TranslationsListFilter;
