import React, { FC } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import cn from 'classnames';
import Button from 'components/UI/Buttons/buttons';
import { TCreativeMultiFormat } from 'domains/creative/types';
import css from '../../styles.module.scss';
import { ReactComponent as Variant1SVG } from '../../../../../../assets/1080.svg';
import { ReactComponent as Variant2SVG } from '../../../../../../assets/600.svg';

type TMultiProps = {
  data?: TCreativeMultiFormat;
  option: boolean;
  link?: string;
};

const Multi: FC<TMultiProps> = ({ data, option, link }) => (
  <Tabs className={css.tabs}>
    <div className={css.body}>
      <div className={css.side}>
        <TabList className={cn(css.tabs_tablist, 'clickable')}>
          <Tab className={css.tabs_tab}>
            <div className={css.size}>
              <Variant1SVG className={css.size_icon} />
              <span>1080px × 607px</span>
            </div>
          </Tab>
          <Tab className={css.tabs_tab}>
            <div className={css.size}>
              <Variant2SVG className={css.size_icon} />
              <span>600px × 600px</span>
            </div>
          </Tab>
        </TabList>
      </div>
      <TabPanel className={css.tabs_panel}>
        <div className={css.scroll}>
          {data && (
            <div className={css.main}>
              {!option ? (
                <div className={cn(css.option, 'rectangle', 'option1')}>
                  <div className={css.option_header}>
                    <img
                      className={css.option_header_icon}
                      src={data.images.icon}
                      alt="icon"
                    />
                    <div className={css.option_header_info}>
                      {data.title && (
                        <div className={css.option_header_title}>
                          {data.title}
                        </div>
                      )}
                      {link && (
                        <a
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={css.option_header_link}
                        >
                          {link}
                        </a>
                      )}
                    </div>
                  </div>
                  {data.text && (
                    <div className={css.option_text}>{data.text}</div>
                  )}
                  <div
                    className={css.option_image}
                    style={{
                      backgroundImage: `url(${data.images.image_2})`,
                    }}
                  />
                  {data?.button && (
                    <Button
                      title={data?.button}
                      transparent
                      height={30}
                      buttonAttributes={{
                        className: css.option_btn,
                      }}
                    />
                  )}
                </div>
              ) : (
                <div className={cn(css.option, 'rectangle', 'option2')}>
                  <div
                    className={css.option_image}
                    style={{
                      backgroundImage: `url(${data.images.image_2})`,
                    }}
                  />
                  {data.title && (
                    <div className={css.option_header_title}>{data.title}</div>
                  )}
                  {link && (
                    <a
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={css.option_header_link}
                    >
                      {link}
                    </a>
                  )}
                  {data.text && (
                    <div className={css.option_text}>{data.text}</div>
                  )}
                  {data?.button && (
                    <Button
                      title={data?.button}
                      transparent
                      height={30}
                      buttonAttributes={{
                        className: css.option_btn,
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </TabPanel>
      <TabPanel className={css.tabs_panel}>
        <div className={css.scroll}>
          {data && (
            <div className={css.main}>
              {!option ? (
                <div className={cn(css.option, 'square', 'option1')}>
                  {data.title && (
                    <div className={css.option_header_title}>{data.title}</div>
                  )}
                  {link && (
                    <a
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={css.option_header_link}
                    >
                      {link}
                    </a>
                  )}
                  {data.text && (
                    <div className={css.option_text}>{data.text}</div>
                  )}
                  <div
                    className={css.option_image}
                    style={{
                      backgroundImage: `url(${data.images.image_1})`,
                    }}
                  />
                  {data?.button && (
                    <Button
                      title={data?.button}
                      transparent
                      height={30}
                      buttonAttributes={{
                        className: css.option_btn,
                      }}
                    />
                  )}
                </div>
              ) : (
                <div className={cn(css.option, 'square', 'option2')}>
                  <div
                    className={css.option_image}
                    style={{
                      backgroundImage: `url(${data.images.image_1})`,
                    }}
                  />
                  <div className={css.option_vertical}>
                    {data.title && (
                      <div className={css.option_header_title}>
                        {data.title}
                      </div>
                    )}
                    {link && (
                      <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={css.option_header_link}
                      >
                        {link}
                      </a>
                    )}
                    {data.text && (
                      <div className={css.option_text}>{data.text}</div>
                    )}
                    {data?.button && (
                      <Button
                        title={data?.button}
                        transparent
                        height={30}
                        buttonAttributes={{
                          className: css.option_btn,
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </TabPanel>
    </div>
  </Tabs>
);

export default Multi;
