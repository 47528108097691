import { GroupUser, ResponseUser } from 'domains/user/types';

/** функция проверяет состоит ли пользователь в группах
 *
 * @user - объект пользователя
 * ...@ название групп пользователей
 *
 * */
export function everyGroups(
  user: ResponseUser | null,
  ...groups: GroupUser
): boolean {
  if (!user) {
    return false;
  }
  const userGroups = user?.group;
  if (!Array.isArray(userGroups)) return false;
  return groups.every((group) => userGroups.includes(group));
}

export function someGroups(
  user: ResponseUser | null,
  ...groups: GroupUser
): boolean {
  if (!user) {
    return false;
  }
  const userGroups = user?.group;
  if (!Array.isArray(userGroups)) return false;
  return groups.some((group) => userGroups.includes(group));
}
