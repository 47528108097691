import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import MainSearch from 'components/MainSearch';
import css from './styles.module.scss';

type Props = {
  toggleSearch: boolean;
  onOpenSearch: () => void;
  search: string;
  onChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  onCloseSearch: () => void;
  disabled?: boolean;
  searchMatch: number;
  isShowMatch: boolean;
};

const HeaderActions: FC<Props> = ({
  children,
  toggleSearch,
  onOpenSearch,
  search,
  onChangeSearch,
  inputRef,
  onCloseSearch,
  disabled,
  searchMatch,
  isShowMatch,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(css.actions, {
        isSearchOpened: toggleSearch,
      })}
    >
      <MainSearch
        toggleSearch={toggleSearch}
        match={searchMatch}
        onOpenSearch={onOpenSearch}
        value={search}
        onChangeSearch={onChangeSearch}
        searchRef={inputRef}
        onCloseSearch={onCloseSearch}
        placeholder={t('creative.search')}
        disabled={disabled}
        className={css.search}
        isShowMatch={isShowMatch}
      />
      <div className={css.buttons}>{children}</div>
    </div>
  );
};

export default HeaderActions;
