import React, { FC } from 'react';
import { TExperimentalInfoField, PExperimental } from 'domains/campaign/types';
import PriceInput from 'components/UI/PriceInput';
import { FormikProps } from 'formik';
import { checkedLocalization } from 'utils/checkedLocalization';
import css from '../styles.module.scss';

interface Props {
  item: TExperimentalInfoField;
  formik: FormikProps<PExperimental>;
  success: boolean;
  error: boolean;
  errortext: string;
  decimalScale?: number;
  setField: (name: string, value: string | number | undefined) => void;
}

const NumberInput: FC<Props> = ({
  item,
  formik,
  success,
  setField,
  decimalScale,
  error,
  errortext,
}) => (
  <PriceInput
    classname={css.price}
    isWatchEnter
    setField={setField}
    decimalScale={decimalScale}
    error={error}
    errortext={errortext}
    theme="horizontal"
    label={checkedLocalization(
      `campaigns_page.experimental.${item.type}`,
      item.title,
    )}
    isSaved={success}
    value={String(formik.values.value)}
    onValueChange={({ floatValue }) => {
      formik.setFieldValue('value', floatValue);
    }}
  />
);

export default NumberInput;
