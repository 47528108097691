import axios, { AxiosResponse } from 'axios';

export type TUploadFile = {
  client_name: string;
  width: number;
  height: number;
  imageType: string;
  resolution: string;
  size: number;
  originalName: string;
  extension: string;
  type: string;
  name: string;
  rawName: string;
  file_url: string;
  audioCodec?: string;
  bitrate?: number;
  duration?: string;
  videoCodec?: string;
  video_poster_url?: string;
};

export type TResult<T> = {
  result: T;
};
export type TUploadErr = {
  error: {
    code: number;
    message: string;
  };
};

export function isTUploadErr(v: unknown | TUploadErr): v is TUploadErr {
  return Object.prototype.hasOwnProperty.call(v, 'error');
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default async <T = unknown>(url: string, data: unknown) => {
  const response: AxiosResponse<TResult<T> | TUploadErr> = await axios({
    method: 'post',
    url,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  });
  return response.data;
};
