import { useTranslation } from 'react-i18next';
import React from 'react';
import { ReactSVG } from 'react-svg';
import { checkedLocalization } from 'utils/checkedLocalization';
import emptyList from '../icons/empty-list.svg';
import css from './styles.module.scss';

interface Props {
  editing: boolean;
  type?: string;
  view?: number;
  showStatistic?: boolean;
}

const EmptyList: React.FC<Props> = ({ editing, type, view, showStatistic }) => {
  const { t } = useTranslation();
  return (
    <div className={css.empty}>
      {editing ? (
        <div>
          <div className={css.icon}>
            <ReactSVG src={emptyList} />
          </div>
          <div className={css.text}>
            <div className={css.text}>
              {showStatistic
                ? checkedLocalization(
                    `targetings.no_list.${type}`,
                    `targetings.no_list.general`,
                  )
                : t(`targetings.no_list.general`)}
            </div>
          </div>
        </div>
      ) : (
        <div className={css.text}>
          <div className={css.text}>
            {showStatistic
              ? checkedLocalization(
                  view === 1
                    ? `targetings.no_data`
                    : `targetings.no_list.${type}`,
                  `targetings.no_list.general`,
                )
              : t(`targetings.statistic_off`)}
          </div>
        </div>
      )}
    </div>
  );
};

export default EmptyList;
