import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from '../../../redux/fetchedData';
import { CounteragentItem } from './types';

export type counteragentsState = {
  counteragentData: FetchedData<CounteragentItem>;
};

export const initialState: counteragentsState = {
  counteragentData: genFetchedData<CounteragentItem>(null),
};

const counteragentSlice = createSlice({
  name: 'Counteragent',
  initialState,
  reducers: {
    setCounteragentData(
      state,
      action: PayloadAction<counteragentsState['counteragentData']>,
    ) {
      state.counteragentData = action.payload;
    },
  },
});

export const { setCounteragentData } = counteragentSlice.actions;
export const counteragentReducer = counteragentSlice.reducer;
