import { FormikProps } from 'formik';
import { useEffect } from 'react';
import { TCampaignForm } from '../../../../../types';
import { useCard } from './useCard';

type TFUseCard = (formik: FormikProps<TCampaignForm>) => {
  showHint: boolean;
  closeHint: () => void;
};

export const useCardHint: TFUseCard = (formik) => {
  const { showHint, openHint, closeHint } = useCard(true);

  useEffect(() => {
    if (!formik.values.pay_price) {
      openHint();
    } else {
      closeHint();
    }
  }, [formik.values.pay_price]);

  return {
    showHint,
    closeHint,
  };
};
