import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CommentState = {
  commentEdited: number[];
  commentDeleted: number[];
  isShowAllComments: boolean;
  isError: boolean;
};

export const initialState: CommentState = {
  commentEdited: [],
  commentDeleted: [],
  isShowAllComments: false,
  isError: false,
};

const commentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    setCommentEdited(
      state,
      action: PayloadAction<CommentState['commentEdited']>,
    ) {
      state.commentEdited = action.payload;
    },
    setFilterCommentEdited(state, action: PayloadAction<number>) {
      state.commentEdited = [
        ...state.commentEdited.filter((value) => value !== action.payload),
      ];
    },
    setCommentDeleted(
      state,
      action: PayloadAction<CommentState['commentDeleted']>,
    ) {
      state.commentDeleted = action.payload;
    },
    setFilterCommentDeleted(state, action: PayloadAction<number>) {
      state.commentDeleted = [
        ...state.commentDeleted.filter((value) => value !== action.payload),
      ];
    },
    setIsShowAllComments(
      state,
      action: PayloadAction<CommentState['isShowAllComments']>,
    ) {
      state.isShowAllComments = action.payload;
    },
    setErrorComment(state, action: PayloadAction<CommentState['isError']>) {
      state.isError = action.payload;
    },
  },
});

export const {
  setCommentDeleted,
  setCommentEdited,
  setFilterCommentEdited,
  setIsShowAllComments,
  setErrorComment,
  setFilterCommentDeleted,
} = commentSlice.actions;
export const commentReducer = commentSlice.reducer;
