import { SagaIterator } from '@redux-saga/core';
import { takeEvery, takeLatest } from 'redux-saga/effects';
import { uploadStoreWithLocalStorage } from '../actions';
import {
  setStorageUsersListTabs,
  uploadStoreWithLocalStorageWorker,
  setStorageCampaignsTabs,
  setStorageAgenciesTabs,
  setStorageClientsTabs,
  setStorageTargetIsShowStatistics,
  setStorageCollapseWrappersWorker,
  setStorageCampaignsFilterWorker,
  setStorageActivityUsersWorker,
} from './workers';
import {
  setUsersTabs,
  setAgenciesTabs,
  setCampaignsTabs,
  setClientsTabs,
  setIsShowTargetStatistics,
  setCollapseWrappers,
  setStorageActivityUsersDate,
} from '../reducer';
import { setCampaignsFilter } from '../../../campaigns/reducer';

export default function* watchStorage(): SagaIterator<void> {
  yield takeEvery(
    uploadStoreWithLocalStorage,
    uploadStoreWithLocalStorageWorker,
  );
  yield takeLatest(setUsersTabs, setStorageUsersListTabs);
  yield takeLatest(setAgenciesTabs, setStorageAgenciesTabs);
  yield takeLatest(setClientsTabs, setStorageClientsTabs);
  yield takeLatest(setCampaignsTabs, setStorageCampaignsTabs);
  yield takeLatest(setIsShowTargetStatistics, setStorageTargetIsShowStatistics);
  yield takeLatest(setCollapseWrappers, setStorageCollapseWrappersWorker);
  yield takeLatest(setCampaignsFilter, setStorageCampaignsFilterWorker);
  yield takeLatest(setStorageActivityUsersDate, setStorageActivityUsersWorker);
}
