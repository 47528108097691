import React, { FC } from 'react';
import i18n from '../../../../i18n';
import { THandlerForItem, TListCopy } from '../../types';
import Client from './Item/Client';
import { isClientTypes, isAgencyTypes } from '../../utils/gards';
import { RBreadCrumbsKey } from '../../../../domains/search/model/types';

type ListProps = THandlerForItem & {
  listElement: TListCopy;
  defaultStep: RBreadCrumbsKey;
};

const List: FC<ListProps> = ({
  listElement,
  defaultStep,
  handlerClick,
  getValueToggle,
  handlerToggle,
}) => {
  if (!listElement.data?.length) return <p>{i18n.t('search_none.none_1')}</p>;

  return (
    <>
      {isAgencyTypes(listElement) &&
        listElement.data.map((item) => (
          <Client
            key={item.id}
            client={item}
            type={listElement.type}
            getValueToggle={getValueToggle}
            handlerClick={handlerClick}
            handlerToggle={handlerToggle}
          />
        ))}
      {isClientTypes(listElement) &&
        listElement.data.map((item) => (
          <Client
            key={item.id}
            client={item}
            type={listElement.type}
            isToggle={defaultStep === 'Agency'}
            handlerToggle={handlerToggle}
            handlerClick={handlerClick}
            getValueToggle={getValueToggle}
          />
        ))}
    </>
  );
};

export default List;
