import { useMemo } from 'react';
import { useSearchModalBreadcrumbs } from '../../../../domains/search/model/selectors';
import { useUserInfo } from '../../../../domains/user/model/selectors';
import { TBreadcrumbsTarget } from '../../../CopyTargetingsModal/types';
import { useDispatchApp } from '../../../../redux/rootSelectors';
import { fetchModalBreadcrumbs } from '../../../../domains/search/model/actions';

type TFUseBreadcrumbsCopyModal = () => {
  breadcrumbsList: TBreadcrumbsTarget[];
  handlerClickBreadcrumbs: (xxhash: string) => void;
};

export const useBreadcrumbsCopyModal: TFUseBreadcrumbsCopyModal = () => {
  const dispatch = useDispatchApp();
  const { data: modalBreadcrumbs, LTU: LTUBreadcrumbs } =
    useSearchModalBreadcrumbs();

  const { data: user } = useUserInfo();

  const breadcrumbsList = useMemo<TBreadcrumbsTarget[]>(() => {
    const arLinks: TBreadcrumbsTarget[] = [];
    let keys =
      (modalBreadcrumbs &&
        (Object.keys(modalBreadcrumbs) as TBreadcrumbsTarget['type'][])) ||
      [];
    if (user) {
      const keyIndex = keys.findIndex(
        (key) =>
          key.toLocaleLowerCase() === user.partner.type.toLocaleLowerCase(),
      );
      if (keyIndex + 1) {
        keys = keys.slice(keyIndex, keys.length);
      }
    }
    if (keys && modalBreadcrumbs) {
      keys.forEach((type) => {
        arLinks.push({
          title: modalBreadcrumbs[type].title,
          xxhash: modalBreadcrumbs[type].xxhash,
          type,
        });
      });
    }
    return arLinks;
  }, [LTUBreadcrumbs, user]);

  const handlerClickBreadcrumbs: ReturnType<TFUseBreadcrumbsCopyModal>['handlerClickBreadcrumbs'] =
    (xxhash) => {
      dispatch(fetchModalBreadcrumbs({ xxhash }));
    };

  return {
    breadcrumbsList,
    handlerClickBreadcrumbs,
  };
};
