import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactText, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  fetchClientEdit,
  fetchClientInfoById,
  ClientState,
  initialState,
  setIsShowMChange,
  setIdChange,
  setClientPersonalInfoById,
  delEditClient,
} from '../reduser';
import { Options } from '../../../components/UI/SelectV2';
import { useClearLinkImage } from '../../modals/linkToImage/useClearLinkImage';
import { Tform } from '../types';
import { useUserInfo } from '../../user/model/selectors';
import { useDispatchApp } from '../../../redux/rootSelectors';
import {
  useClientIdChangeInfo,
  useClientInfo,
  useClientIsShowMChangeInfo,
} from './selectors';

type TFUseChangeClientModal = () => {
  closeReset: () => void;
  isShowMChange: boolean;
  formik: FormikProps<Tform>;
  user: ReturnType<typeof useUserInfo>['data'];
  savedValues: Record<string, boolean>;
  pastValues: Record<string, string | boolean | undefined>;
  onSwitchInheritSettings: () => void;
  reportsOpt: Options;
  onChangeReports: (v: number) => (p: ReactText) => void;
  onSwitchAutomoderation: () => void;
  onSwitchCheckErid: () => void;
  onDelLoadImg: () => void;
  onKeyHandler: (keyboardEvent: React.KeyboardEvent) => void;
};

export function useSetIsShowMChange(
  v: true,
  id: ClientState['idChange'],
): () => void;
export function useSetIsShowMChange(v: false): () => void;
export function useSetIsShowMChange(
  v: boolean,
  id?: ClientState['idChange'],
): () => void {
  const dispatch = useDispatchApp();
  return useCallback(() => {
    dispatch(setIsShowMChange(v));
    if (v) {
      dispatch(setIdChange(id ?? ''));
    }
    if (!v) {
      dispatch(setIdChange(initialState.idChange));
      dispatch(setClientPersonalInfoById(initialState.clientPersonalInfoById));
    }
  }, [v, id]);
}

export function item2Opt({
  is_enabled,
  is_default,
}: {
  is_enabled: boolean;
  is_default: boolean;
}): string {
  if (is_enabled && is_default) {
    return 'included';
  }
  if (!is_enabled && !is_default) {
    return 'turned_off';
  }
  return 'available';
}

export const useChangeClient: TFUseChangeClientModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatchApp();
  const clearImage = useClearLinkImage();
  const [savedValues, setSavedValues] = useState<Record<string, boolean>>({});

  const reportsOpt: Options = [
    ['included', t('clients_page.client_edit.included')],
    ['turned_off', t('clients_page.client_edit.turned_off')],
    ['available', t('clients_page.client_edit.available')],
  ];

  const { data: clientPersonalInfoById, LTU: clientPersonaLTU } =
    useClientInfo();

  const [pastValues, setPastValues] = useState<
    Record<string, string | boolean | undefined>
  >({
    use_inherit_settings: clientPersonalInfoById?.reports.use_inherit_settings,
    title: clientPersonalInfoById?.title,
    site: clientPersonalInfoById?.site,
  });

  const formik = useFormik<Tform>({
    initialValues: {
      id: '',
      idAgency: '',
      title: '',
      site: '',
      remove_icon: false,
      is_automoderation_enabled: false,
      is_erotic_setting_enabled: false,
      is_violence_setting_enabled: false,
      is_animation_setting_enabled: false,
      check_ERID: false,
      reports: {
        use_inherit_settings: false,
        items: [],
      },
    },
    onSubmit: (values) => {
      dispatch(
        fetchClientEdit({ values, pastValues, setSavedValues, setPastValues }),
      );
    },
    validationSchema: Yup.object({
      title: Yup.string().required(t('clients_page.client_create.hint1')),
    }),
  });

  const isShowMChange = useClientIsShowMChangeInfo();

  const idChange = useClientIdChangeInfo();

  const { data: user } = useUserInfo();

  const onClose = useSetIsShowMChange(false);

  const onKeyHandler = (keyboardEvent: React.KeyboardEvent) => {
    const { charCode, keyCode } = keyboardEvent;
    if ((charCode || keyCode) === 13) {
      keyboardEvent.preventDefault();
      if (
        formik.values.title !== pastValues.title ||
        formik.values.site !== pastValues.site
      ) {
        formik.handleSubmit();
      }
    }
  };

  const closeReset = useCallback(() => {
    formik.resetForm();
    onClose();
    clearImage();
    dispatch(delEditClient());
  }, [formik]);

  const onDelLoadImg = useCallback(() => {
    formik.setFieldValue('remove_icon', true);
    formik.handleSubmit();
  }, [formik]);

  const onChangeReports: ReturnType<TFUseChangeClientModal>['onChangeReports'] =
    useCallback(
      (i) => (v) => {
        const data = formik.values.reports.items[i];
        const field = `reports.items[${i}]`;
        if (v === 'included') {
          data.is_default = true;
          data.is_enabled = true;
          formik.setFieldValue(field, data);
          formik.handleSubmit();
          return;
        }
        if (v === 'turned_off') {
          data.is_default = false;
          data.is_enabled = false;
          formik.setFieldValue(field, data);
          formik.handleSubmit();
          return;
        }
        data.is_default = false;
        data.is_enabled = true;
        formik.setFieldValue(field, data);
        formik.handleSubmit();
      },
      [formik],
    );

  useEffect(
    () => () => {
      closeReset();
    },
    [],
  );

  useEffect(() => {
    if (clientPersonalInfoById === null) return;
    formik.setValues({
      id: clientPersonalInfoById.id,
      idAgency: clientPersonalInfoById.parent_id,
      site: clientPersonalInfoById.site,
      title: clientPersonalInfoById.title,
      remove_icon: clientPersonalInfoById.remove_icon,
      is_automoderation_enabled:
        clientPersonalInfoById.is_automoderation_enabled,
      is_erotic_setting_enabled:
        clientPersonalInfoById.is_erotic_setting_enabled,
      is_violence_setting_enabled:
        clientPersonalInfoById.is_violence_setting_enabled,
      is_animation_setting_enabled:
        clientPersonalInfoById.is_animation_setting_enabled,
      check_ERID: clientPersonalInfoById.check_ERID.CLIENT || false,
      reports: clientPersonalInfoById.reports,
    });
    setPastValues({
      use_inherit_settings:
        clientPersonalInfoById?.reports.use_inherit_settings,
      title: clientPersonalInfoById?.title,
      site: clientPersonalInfoById?.site,
    });
  }, [clientPersonaLTU]);

  useEffect(() => {
    if (idChange === '') return;
    dispatch(fetchClientInfoById(idChange));
  }, [idChange]);

  const onSwitchInheritSettings = useCallback(() => {
    formik.setFieldValue(
      'reports.use_inherit_settings',
      !formik.values.reports.use_inherit_settings,
    );
    formik.handleSubmit();
  }, [formik.values.reports.use_inherit_settings]);

  const onSwitchAutomoderation = useCallback(() => {
    formik.setFieldValue(
      'is_automoderation_enabled',
      !formik.values.is_automoderation_enabled,
    );
    formik.handleSubmit();
  }, [formik.values.is_automoderation_enabled]);

  const onSwitchCheckErid = useCallback(() => {
    formik.setFieldValue('check_ERID', !formik.values.check_ERID);
    formik.handleSubmit();
  }, [formik.values.check_ERID]);

  return {
    closeReset,
    isShowMChange,
    formik,
    user,
    savedValues,
    pastValues,
    onSwitchInheritSettings,
    reportsOpt,
    onChangeReports,
    onSwitchAutomoderation,
    onSwitchCheckErid,
    onDelLoadImg,
    onKeyHandler,
  };
};
