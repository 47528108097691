import React from 'react';
import { toast } from 'react-toastify';
import cn from 'classnames';
import { FiCheck, FiX } from 'react-icons/fi';
import { ToastUtilsSuccessParams } from 'types/general';
import css from './styles.module.scss';

type ToastUtilsSuccess = (
  str: string | ToastUtilsSuccessParams | undefined,
) => void;
type ToastUtilsError = (str: string) => void;

export const toastSuccess: ToastUtilsSuccess = (str) => {
  toast(
    str ? (
      <div className={css.toastText}>
        {typeof str === 'string' ? (
          <span>{str}</span>
        ) : (
          <>
            <span>{str.before}</span>
            <strong>{str.title}</strong>
            <span>{str.after}</span>
          </>
        )}
      </div>
    ) : (
      <div className={css.toastText}>Неизвестное оповещение</div>
    ),
    {
      icon: (
        <div className={cn(css.icon, '_isSuccess')}>
          <FiCheck size={16} color="#fff" />
        </div>
      ),
      bodyClassName: css.toast__body,
      position: toast.POSITION.TOP_RIGHT,
      className: 'Toastify__toast--success',
    },
  );
};

export const toastError: ToastUtilsError = (str) => {
  toast(str, {
    icon: (
      <div className={cn(css.icon, '_isError')}>
        <FiX size={16} color="#fff" />
      </div>
    ),
    bodyClassName: css.toast__body,
    position: toast.POSITION.TOP_RIGHT,
    className: 'Toastify__toast--error',
  });
};
