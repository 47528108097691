/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import Checkbox from 'components/UI/Checkbox/checkbox';
import { components } from 'react-select';
import { Components } from 'react-select/src/components';
import cn from 'classnames';
import css from '../../styles.module.scss';

const Option: Components['Option'] = (props: any) => (
    <components.Option
      {...props}
      className={cn(css.partner_option, 'partner_checkbox_select_option')}
    >
      <label className={css.label}>
        <Checkbox
          label=""
          onlyBox
          inputAttributes={{
            type: 'checkbox',
            checked: props.isSelected,
            readOnly: true,
          }}
        />

        <div className={css.content}>
          <div className={css.name}>{props.label}</div>
          <div className={css.hash}>{props.value}</div>
        </div>
      </label>
    </components.Option>
  );

export default Option;