import React, { FC, RefObject, useEffect, useState } from 'react';
import cn from 'classnames';
import { useUserTheme } from 'domains/user/model/selectors';
import OnlyOwner from 'components/OnlyOwner';
import TranslationsListFilter from 'components/TranslationsListFilter';
import { TGetCampaignsChecked } from 'domains/campaigns/model/hooks/useFetchDataCampaigns';
import { IFilterColumns } from 'domains/campaigns/model/hooks/useGetFilterForCampaigns';
import { ScrollSyncNode } from 'scroll-sync-react';
import { useCampaignListSortInfo } from 'domains/campaigns/model/hooks/useCampaignListSortInfo';
import allSvg from './all.svg';
import css from './styles.module.scss';
import TableFilter from '../TableFilter';
import Checkbox from '../Checkbox/checkbox';
import filterSvg from '../../TranslationsListFilter/all.svg';

interface IProps {
  /* Высота появления фиксированной плашки с заголовками таблицы */
  scrollH?: number;
  filters: IFilterColumns[];
  refObject: RefObject<HTMLInputElement>;
  fixed: boolean;
  setFixed: (v: boolean) => void;
  handlerAllCheckedCampaigns: (value: boolean) => void;
  /** Вкладка на которой находимся */
  tab?: string;
  filterCreoTranslation?: (key: string) => void;
  currentFilter: string;
  isLoadingTranslationFilter: boolean;
  getCampaignsChecked: TGetCampaignsChecked;
  /* Для данных за неделю выравнивание по левому краю, в остальном по правому краю */
  onlyCurrent: boolean;
}

const FixedTableHeader: FC<IProps> = ({
  children,
  filters,
  refObject,
  fixed,
  setFixed,
  scrollH = 65,
  getCampaignsChecked,
  handlerAllCheckedCampaigns,
  filterCreoTranslation,
  tab,
  currentFilter,
  isLoadingTranslationFilter,
  onlyCurrent,
}) => {
  const { isThemeExtended, isThemeFull } = useUserTheme();

  const onScroll = (event: Event) => {
    const scroll = event.target as HTMLDocument;
    if (
      refObject.current &&
      scroll.scrollingElement &&
      scroll.scrollingElement.scrollTop > scrollH
    ) {
      return setFixed(true);
    }
    return setFixed(false);
  };

  useEffect(() => {
    document.addEventListener('scroll', onScroll);

    return () => document.removeEventListener('scroll', onScroll);
  }, [scrollH]);

  const {
    handlerSort: handlerCampaign,
    key: activeKeyCampaign,
    sort: sortCampaign,
    /* А зачем нам вот этот параметр?  */
    // isShowSortFilter,
  } = useCampaignListSortInfo();

  const { getValueFromSettingForKey } = useUserTheme();
  const [scrollLeft, setScrollLeft] = useState(0);

  const setLeftStyle = () => {
    setScrollLeft(window.pageXOffset);
  };

  useEffect(() => {
    window.addEventListener('scroll', setLeftStyle);
    return () => window.removeEventListener('scroll', setLeftStyle);
  }, []);

  return (
    <>
      {children}
      <div
        className={cn(css.filtersWrapper, {
          _isShow: fixed,
        })}
        key="filters"
        style={{
          left: -scrollLeft,
        }}
      >
        <div
          className={cn('container resized', {
            extend: isThemeExtended,
            full: isThemeFull,
          })}
        >
          <div
            className={cn(css.filters, {
              _isFullScreen: isThemeExtended || isThemeFull,
              _isWeekView: onlyCurrent,
            })}
          >
            <div className={css.filters_left}>
              <OnlyOwner>
                <Checkbox
                  label=""
                  className={css.checkbox}
                  inputAttributes={{
                    type: 'checkbox',
                    checked: getCampaignsChecked.all,
                    onChange: () =>
                      handlerAllCheckedCampaigns(
                        getCampaignsChecked.all ||
                          getCampaignsChecked.partially,
                      ),
                    disabled: tab === 'DELETED',
                  }}
                  onlyBox
                  isPartiallyChecked={
                    getCampaignsChecked.partially &&
                    !getCampaignsChecked.all
                  }
                />
              </OnlyOwner>
              <div className={css.filter}>
                <img src={allSvg} alt="all" />
              </div>
              <TableFilter
                title={filters[0].name}
                data={filters[0].data}
                activeKey={activeKeyCampaign}
                sort={sortCampaign}
                handleSort={handlerCampaign}
                isShow
              />
            </div>
            <ScrollSyncNode group="a">
              <div
                className={cn(
                  css.filters_center,
                  `length-${filters.length - 1}`,
                )}
              >
                {filters.map(
                  ({ data, name, lengthTitle }, index) =>
                    index > 0 && (
                      <div
                        key={name}
                        className={cn(css.filters_item, `size-${lengthTitle}`, {
                          _isHide: name.trim().toLocaleLowerCase() === 'bid',
                        })}
                      >
                        <TableFilter
                          title={name}
                          data={data}
                          activeKey={activeKeyCampaign}
                          sort={sortCampaign}
                          handleSort={handlerCampaign}
                          isShow
                        />
                      </div>
                    ),
                )}
              </div>
            </ScrollSyncNode>
            <div className={css.filters_right}>
              {getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
                !getValueFromSettingForKey('ShowBroadcastStatus') && (
                  <div className={cn(css.item, 'center')}>
                    <div className={css.title}>
                      {isLoadingTranslationFilter ? (
                        <img
                          src={filterSvg}
                          className={cn('icon', css.filterList)}
                          alt="filter all"
                        />
                      ) : (
                        <TranslationsListFilter
                          currentFilter={currentFilter}
                          filterHandler={filterCreoTranslation}
                        />
                      )}
                    </div>
                  </div>
                )}
              <div className={css.options} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FixedTableHeader;
