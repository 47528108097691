import { SagaIterator } from 'redux-saga';
import { put, call, SagaReturnType, select, delay } from 'redux-saga/effects';
import i18next from 'i18next';
import moment from 'moment';
import {
  tagsGetpartner,
  tagsGetCampaign,
  fetchStatisticsWeekApi,
} from 'domains/campaigns/api';
import {
  statisticsReportFormatter,
  statisticsReportFormatterGeo,
  statisticsReportFormatterIntegration,
  statisticsReportFormatterShowNum,
} from 'utils/statisticsReportFormatter';
import history from 'lib/history';
import { TCommentList } from 'domains/comment/model/types';
import { PreResponseStatisticsCampaignsWeek } from 'domains/campaigns/types';
import { RBreadCrumbs } from 'domains/search/model/types';
import { selectorBreadcrumbs } from 'domains/search/model/selectors';
import { setTempBreadcrumbs } from 'domains/search/model/reducer';
import {
  fetchPersonalData,
  fetchExternalModerationRequest,
  fetchReportType,
  fetchTagsGetCompany,
  fetchTagsGetPartner,
  setSRMethodRequest,
  fetchDownloadReport,
  setEditPersonalCampaign,
  fetchCampaignPreTargeting,
  setPreTargetingRequest,
  fetchPersonalStatistics,
  fetchCampaignComments,
  addCampaignComment,
  editCampaignComment,
  deleteCampaignComment,
  delayUpdateComment,
  updateTagCampaign,
  revertCampaignTags,
  fetchCampaignTranslation,
  setCampaignExperimentalAction,
  linkCampaignToOffer,
  unlinkCampaignToOffer,
} from '../model/actions';
import {
  editPersonalCampaign,
  getPreTargeting,
  setPreTargetingApi,
  getListMethodRecalculatingStatistics,
  tagsGetPartner,
  getReport,
  getCampaignInfo,
  getExternalModeration,
  getAdvancedOptimizationLists,
  getCampaignComments,
  editCampaignCommentApi,
  addCampaignCommentApi,
  removeCampaignCommentApi,
  tagsSetInCampaign,
  fetchCampaingTranslationApi,
  fetchCampaingExperimentalApi,
  getOptimizationListApi,
  linkCampaign,
  unlinkCampaign,
} from '../api';
import {
  setPersonalData,
  setPreTargeting,
  setPersonalStatistics,
  setCompanyTags,
  setListSRMethods,
  setPartnerTags,
  setTempViewReport,
  setDefChartItemsGenDefault,
  setExternalModeration,
  setPersonalDataSuccess,
  setAdvancedOptimizationLists,
  setCommentsCampaign,
  setFetchCampaingTranslation,
  setFetchCampaingExperimental,
  setOptimisationList,
} from '../reduser';

import {
  makeReqWithRD,
  TMakeReqWithRD,
  updateFillFetchedData,
} from '../../../redux/makeReqWithRD';
import updateToken from '../../../utils/updateToken';
import { FetchedData, genFetchedData } from '../../../redux/fetchedData';
import {
  RCampaignReport,
  RCampaignReportIntegration,
  RCampaignStatistics,
  RFrontStatistics,
  RGetPersonal,
  TReportData,
  ListSRMethods,
  TExperimentalInfoField,
} from '../types';
import { wssCloseConnect } from '../../wss/model/actions';
import {
  CompanyTags,
  selectorCampaign,
  selectorCampaignComments,
  selectorPreTargeting,
} from '../model/selectors';
import {
  setCommentDeleted,
  setCommentEdited,
  setFilterCommentDeleted,
  setFilterCommentEdited,
} from '../../comment/model/reducer';
import {
  selectorCommentDeleted,
  selectorCommentEdited,
} from '../../comment/model/selectors';
import { genTimePeriod } from '../../../utils/genTimePeriod';
import { toastError, toastSuccess } from '../../../utils/ToastUtils/toastUtils';
import { RExternalModeration } from '../../creative/types';

export function* workerFetchCampaign({
  payload,
}: ReturnType<typeof fetchPersonalData>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getCampaignInfo>>(makeReqWithRD, {
      fetcher: getCampaignInfo,
      fill: setPersonalData,
      fillSuccess: setPersonalDataSuccess,
      parameters: { xxhash: payload },
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* workerFetchPersonalStatistics({
  payload,
}: ReturnType<typeof fetchPersonalStatistics>): SagaIterator<void> {
  const arPeriod = genTimePeriod(5);
  const mockStatistics = {
    bid_statistic: 0,
    click: 0,
    cpc: 0,
    cpm: 0,
    ctr: 0,
    show: 0,
    spent: 0,
  };
  try {
    yield call<TMakeReqWithRD<typeof fetchStatisticsWeekApi>>(makeReqWithRD, {
      fetcher: fetchStatisticsWeekApi,
      fill: (result) => {
        const resultData = result.get('data');
        const stata: RCampaignStatistics['items'] = {};
        if (!resultData)
          return setPersonalStatistics(
            genFetchedData<RFrontStatistics>(null).set('isLoading', true),
          );
        if (/* Array.isArray(resultData) ||  */(Object.keys(resultData).length === 1 && 'total' in resultData)) {
          return setPersonalStatistics(
            genFetchedData<RFrontStatistics>(null).set('isLoading', false),
          );
        }
        const keys = Object.keys(resultData).filter((key) => key !== 'total');
        const [ currentKey ] = keys
        arPeriod.forEach((date) => {
          if (resultData && resultData[currentKey] && date in resultData[currentKey]) {
            stata[date] = resultData[currentKey][date];
          } else {
            stata[date] = mockStatistics;
          }
        });

        const newResultData =
          resultData && Object.keys(resultData[currentKey])
            ? { ...resultData, items: stata }
            : resultData;
        const convertItems = (element = 'show') =>
          newResultData &&
          Object.keys(newResultData.items)
            .sort((a, b) => +moment(a) - +moment(b))
            .map((e) => [
              moment(e).format('DD.MM'),
              newResultData.items[e][element],
            ]);

        const items: RFrontStatistics = {
          show: [['', 'Показы'], ...(convertItems('show') || [])],
          clicks: [['', 'Клики'], ...(convertItems('click') || [])],
        };
        const newResult = updateFillFetchedData<
          PreResponseStatisticsCampaignsWeek,
          RFrontStatistics
        >({
          fillFetchedData: result,
          newData: items,
        });
        return setPersonalStatistics(newResult);
      },
      parameters: payload,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* workerEditPersonalCampaign({
  payload,
}: ReturnType<typeof setEditPersonalCampaign>): SagaIterator<void> {
  const { blockTitle = '', blockGetQuery = false, translation, ...rest } = payload;
  try {
    yield call(updateToken);
    payload.callback?.setIsLoading(true);
    const res = yield call(editPersonalCampaign, rest);

    if (res) {
      if(rest.title) {
        const breadCrumbsMap: FetchedData<RBreadCrumbs> = yield select(selectorBreadcrumbs)
        const breadCrumbsData = breadCrumbsMap.get('data')
        if(breadCrumbsData) {
          breadCrumbsData.Campaign.title = rest.title
          const newBreadCrumbsMap = breadCrumbsMap.set('data', breadCrumbsData).set('LTU', Date.now())
          yield put(setTempBreadcrumbs(newBreadCrumbsMap))
        }
      }
      if (blockTitle) {
        payload.callback?.setIsLoading(false);
        toastSuccess(
          blockTitle
            ? {
                before: `${i18next.t(
                  'campaigns_page.campaign_settings.on_edit.hint1',
                )}`,
                title: blockTitle.includes('"')
                  ? ` ${blockTitle} `
                  : ` "${blockTitle}" `,
                after: `${i18next.t(
                  'campaigns_page.campaign_settings.on_edit.hint2',
                )}`,
              }
            : i18next.t('campaigns_page.campaign_settings.on_edit.hint3'),
        );
      }
      if (!blockGetQuery) {
        const oldDataTemp = yield select(selectorCampaign);
        const oldData = oldDataTemp.get('data');
        const oldPast = oldDataTemp.get('past');
        oldPast.data.push(oldData);
        oldPast.LTU = Date.now();
        
        if (!oldDataTemp.get('isLoading')) {
          const campaign = yield call(getCampaignInfo, { xxhash: rest.xxhash });

          yield put(
            setPersonalDataSuccess(
              oldDataTemp
                .set('data', campaign)
                .set('past', oldPast)
                .set('LTU', Date.now())
                .set('error', { isError: false, message: '', code: 0 }),
            ),
          );
        } 
      }
      if (translation) {
        yield put(fetchCampaignTranslation({ xxhash: rest.xxhash }));
      }
    }
  } catch (e) {
    console.error(e);
    toastError(i18next.t('errors.unknown_error'));
    payload.callback?.setIsLoading(false);
    if (e?.code === 91) {
      yield put(wssCloseConnect());
      yield put({ type: 'AUTH_LOGOUT_SUCCESS' });
      document.location.href = '/';
    }
  }
}

export function* getCampaignPreTargetingWorker({
  payload,
}: ReturnType<typeof fetchCampaignPreTargeting>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getPreTargeting>>(makeReqWithRD, {
      fetcher: getPreTargeting,
      fill: setPreTargeting,
      parameters: payload,
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* setCampaignPreTargetingWorker({
  payload,
}: ReturnType<typeof setPreTargetingRequest>): SagaIterator<void> {
  try {
    const { data, xxhash } = payload;
    yield call(updateToken);
    const result = yield call(setPreTargetingApi, { xxhash, data });
    if (result) {
      const newData = yield call(getPreTargeting, { xxhash });
      let oldData = yield select(selectorPreTargeting);
      oldData = oldData.set('data', newData).set('LTU', Date.now());
      yield put(setPreTargeting(oldData));
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* workerFetchCampaignTranslationWorker({
  payload,
}: ReturnType<typeof fetchCampaignTranslation>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof fetchCampaingTranslationApi>>(
      makeReqWithRD,
      {
        fetcher: fetchCampaingTranslationApi,
        fill: setFetchCampaingTranslation,
        parameters: { ...payload },
      },
    );
  } catch (e) {
    console.error({ e });
  }
}

export function* fetchCampaignExperimentalWorker(): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof fetchCampaingExperimentalApi>>(
      makeReqWithRD,
      {
        fetcher: fetchCampaingExperimentalApi,
        fill: (result) => {
          const data = result.get('data');
          const arrayResult = data && Object.values(data);
          const newResult = updateFillFetchedData<
            Record<string, TExperimentalInfoField>,
            TExperimentalInfoField[]
          >({
            fillFetchedData: result,
            newData: arrayResult || [],
          });
          return setFetchCampaingExperimental(newResult);
        },
        parameters: {entity_type: 'CAMPAIGN'}
      },
    );
  } catch (e) {
    console.error({ e });
  }
}

export function* setCampaignExperimentalWorker({
  payload,
}: ReturnType<typeof setCampaignExperimentalAction>): SagaIterator<void> {
  try {
    const { callbacks, ...rest } = payload;
    const config_values: Record<string, string | number | boolean> = {};
    config_values[rest.id] = rest.value;
    let result = null;
    if (rest.xxhash) {
      const params = {
        xxhash: rest.xxhash,
        config_values,
      };
      result = yield call(editPersonalCampaign, params);
    }
    if (result) {
      callbacks.onSuccess(true);
      const campaignInfo = yield select(selectorCampaign);
      const campaignData: RGetPersonal = campaignInfo.get('data');
      const newData = {
        ...campaignData,
        config_values: { ...campaignData.config_values, [rest.id]: rest.value },
      };
      yield put(setPersonalData(campaignInfo.set('data', newData)));
      setTimeout(() => {
        callbacks.onSuccess(false);
      }, 4000);
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* getListSRMethodsWorker(): SagaIterator<void> {
  let fetchedData = genFetchedData<ListSRMethods>(null).set('isLoading', true);
  yield put(setListSRMethods(fetchedData));
  try {
    yield call(updateToken);
    const result = yield call(getListMethodRecalculatingStatistics);
    const options: ListSRMethods = result.map((item: string) => [
      item,
      i18next.t(`campaigns_page.campaign_settings.ListSRMethods.${item}`),
    ]);
    fetchedData = fetchedData.set('data', options);
  } catch (err) {
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: err.message,
    });
  } finally {
    fetchedData = fetchedData.delete('isLoading').set('LTU', Date.now());
    yield put(setListSRMethods(fetchedData));
  }
}

export function* fetchTagsgetCompanyWorker({
  payload,
}: ReturnType<typeof fetchTagsGetCompany>): SagaIterator<void> {
  let fetchedTags = genFetchedData<string[]>(null).set('isLoading', true);
  try {
    yield put(setCompanyTags(fetchedTags));
    yield call(updateToken);
    const tags: SagaReturnType<typeof tagsGetpartner> = yield call(
      tagsGetCampaign,
      { xxhash: payload },
    );
    fetchedTags = fetchedTags.set('data', tags);
  } catch (e) {
    console.error({ e });
    fetchedTags = fetchedTags.set('error', {
      isError: true,
      message: e?.message,
    });
    yield put(setCompanyTags(fetchedTags));
  } finally {
    fetchedTags = fetchedTags.delete('isLoading').set('LTU', Date.now());
    yield put(setCompanyTags(fetchedTags));
  }
}

export function* fetchTagsPartnerWorker({
  payload,
}: ReturnType<typeof fetchTagsGetPartner>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof tagsGetPartner>>(makeReqWithRD, {
      fetcher: tagsGetPartner,
      fill: setPartnerTags,
      parameters: { xxhash: payload },
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* setSRMethodWorker({
  payload,
}: ReturnType<typeof setSRMethodRequest>): SagaIterator<void> {
  try {
    yield call(updateToken);
    yield call(editPersonalCampaign, {
      xxhash: payload.id,
      title: payload.title,
      method_recalculating_statistics: payload.SRMethod,
    });
    yield put(fetchPersonalData(payload.id));
    toastSuccess({
      before: `${i18next.t('common.savedBlock_part1')}`,
      title: `"${payload.title}"`,
      after: `${i18next.t('common.savedBlock_part2')}`,
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* onDownloadReport({
  payload,
}: ReturnType<typeof fetchDownloadReport>): SagaIterator<void> {
  try {
    yield call(updateToken);
    const result = yield call(getReport, payload);
    const link = document.createElement('a');
    link.download = 'file';
    link.href = result.report_url;
    link.click();
  } catch (e) {
    console.error({ e });
  }
}

export function* fetchReportByTypeWorker({
  payload,
}: ReturnType<typeof fetchReportType>): SagaIterator<void> {
  let fetchedData = genFetchedData<TReportData>(null).set('isLoading', true);
  yield put(setTempViewReport(fetchedData));

  try {
    const { period, type } = payload;
    const result = yield call(getReport, payload);
    let data: TReportData = {
      statistics: [],
      total: {},
      isDate: false,
    };

    if (type === 'geo_city') {
/*       const objTitle = yield call(() =>
        axios
          .get<Record<string, string>>(
            'https://td.mediasniper.ru/api/dictationary/?lang=ru&name=report_geo_cities',
          )
          .then(({ data: City }) => City),
      ); */
      data = statisticsReportFormatterGeo(result/* , objTitle */);
    } else if (type === 'integration') {
      yield put(setDefChartItemsGenDefault(['impression', 'view', 'click']));
      data =
        statisticsReportFormatterIntegration<RCampaignReportIntegration>(
          result,
        );
    } else if (type === 'shownum') {
      data = statisticsReportFormatterShowNum(result);
    } else {
      data = statisticsReportFormatter<RCampaignReport>(result);
    }
    fetchedData = fetchedData.set('data', data);
    history.replace({
      ...history.location,
      search: `?date_from=${period.from}&date_to=${period.to}&type=${type}`,
    });
  } catch (e) {
    console.log({ e });
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: e.message,
    });
  } finally {
    fetchedData = fetchedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setTempViewReport(fetchedData));
  }
}

export function* getExternalModerationWorker({
  payload,
}: ReturnType<typeof fetchExternalModerationRequest>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getExternalModeration>>(makeReqWithRD, {
      fetcher: getExternalModeration,
      fill: (v) => {
        const data = v.get('data');
        const newData: RExternalModeration = {
          google: data
            ? data.google.sort(
                (
                  a: { creativeData: { creative_id: number } },
                  b: { creativeData: { creative_id: number } },
                ) =>
                  a.creativeData.creative_id > b.creativeData.creative_id
                    ? 1
                    : -1,
              )
            : [],
          yandex: data
            ? data.yandex.sort(
                (
                  a: { creativeData: { creative_id: number } },
                  b: { creativeData: { creative_id: number } },
                ) =>
                  a.creativeData.creative_id > b.creativeData.creative_id
                    ? 1
                    : -1,
              )
            : [],
        };
        return setExternalModeration(
          updateFillFetchedData<RExternalModeration, RExternalModeration>({
            fillFetchedData: v,
            newData,
          }),
        );
      },
      parameters: payload,
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* fetchAdvancedOptimizationWorker(): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getAdvancedOptimizationLists>>(
      makeReqWithRD,
      {
        fetcher: getAdvancedOptimizationLists,
        fill: setAdvancedOptimizationLists,
      },
    );
  } catch (e) {
    console.error(e);
  }
}

export function* fetchCampaignCommentsSagaWorker({
  payload,
}: ReturnType<typeof fetchCampaignComments>): SagaIterator<void> {
  try {
    const { xxhash, clear } = payload;
    yield call<TMakeReqWithRD<typeof getCampaignComments>>(makeReqWithRD, {
      fetcher: getCampaignComments,
      fill: setCommentsCampaign,
      parameters: { xxhash },
    });
    if (!clear) {
      const edited = yield select(selectorCommentEdited);
      const deleted = yield select(selectorCommentEdited);
      if (edited) {
        yield put(setCommentEdited([]));
      }
      if (deleted) {
        yield put(setCommentDeleted([]));
      }
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* addCampaignCommentSagaWorker({
  payload,
}: ReturnType<typeof addCampaignComment>): SagaIterator<void> {
  try {
    const { xxhash } = payload;

    const response = yield call(addCampaignCommentApi, payload);
    if (response) {
      yield put(fetchCampaignComments({ xxhash }));
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* editCampaignCommentSagaWorker({
  payload,
}: ReturnType<typeof editCampaignComment>): SagaIterator<void> {
  try {
    const { callback, comment_id, text, xxhash } = payload;
    const response = yield call(editCampaignCommentApi, { comment_id, text });
    if (response) {
      const data: TCommentList = yield call(getCampaignComments, { xxhash });
      const oldData = yield select(selectorCampaignComments);
      const newData = oldData.set('data', data);
      yield put(setCommentsCampaign(newData.set('LTU', Date.now())));
      const edited = yield select(selectorCommentEdited);
      yield put(setCommentEdited([...edited, comment_id]));
      callback();
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* deleteCampaignCommentSagaWorker({
  payload,
}: ReturnType<typeof deleteCampaignComment>): SagaIterator<void> {
  try {
    const { callback, comment_id } = payload;
    const response = yield call(removeCampaignCommentApi, { comment_id });
    if (response) {
      const deleted = yield select(selectorCommentDeleted);
      yield put(setCommentDeleted([...deleted, comment_id]));
      callback();
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* delayUpdateCommentSagaWorker({
  payload,
}: ReturnType<typeof delayUpdateComment>): SagaIterator<void> {
  try {
    const { comment_id, type } = payload;
    yield delay(5000);
    if (type === 'edited') {
      yield put(setFilterCommentEdited(comment_id));
    }
    if (type === 'deleted') {
      const oldData = yield select(selectorCampaignComments);
      const data: TCommentList = oldData.get('data');
      const newData = oldData.set(
        'data',
        data.filter((e) => e.id !== comment_id),
      );
      yield put(setCommentsCampaign(newData));
      yield put(setFilterCommentDeleted(comment_id));
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* setCampaignTagsWorker({
  payload,
}: ReturnType<typeof updateTagCampaign>): SagaIterator<void> {
  try {
    yield call(updateToken);
    const result = yield call(tagsSetInCampaign, payload);
    if (result) {
      const oldDataTemp = yield select(CompanyTags);
      const oldData = oldDataTemp.get('data');
      const oldPast = oldDataTemp.get('past');
      oldPast.data.push(oldData);
      oldPast.LTU = Date.now();
      const tags: SagaReturnType<typeof tagsGetCampaign> = yield call(
        tagsGetCampaign,
        { xxhash: payload.xxhash },
      );
      yield put(
        setCompanyTags(
          oldDataTemp
            .set('data', tags)
            .set('past', oldPast)
            .set('LTU', Date.now())
            .set('error', { isError: false, message: '', code: 0 }),
        ),
      );
    }
  } catch (e) {
    let oldDataTemp = yield select(CompanyTags);
    oldDataTemp = oldDataTemp.set('error', {
      isError: true,
      message: e.message,
      code: e?.code,
      fields: Object.entries(e.data.fields).map(([, tag]) => tag),
    });
    yield put(setCompanyTags(oldDataTemp));
    console.error(e);
  }
}

export function* revertCampaignTagsWorker({
  payload,
}: ReturnType<typeof revertCampaignTags>): SagaIterator<void> {
  try {
    const oldDataTemp = yield select(CompanyTags);
    const oldPast = oldDataTemp.get('past');
    const oldPastData = oldPast.data as string[][];
    if (oldPastData.length) {
      const old = oldPastData.pop() as string[];
      const result = yield call(tagsSetInCampaign, {
        xxhash: payload.xxhash,
        tags: old,
      });
      if (result) {
        const tags: SagaReturnType<typeof tagsGetCampaign> = yield call(
          tagsGetCampaign,
          { xxhash: payload.xxhash },
        );
        oldPast.data = oldPastData;
        yield put(
          setCompanyTags(
            oldDataTemp
              .set('data', tags)
              .set('past', oldPast)
              .set('LTU', Date.now()),
          ),
        );
      }
    }
  } catch (e) {
    console.error(e);
  }
}

export function* fetchOptimizationListWorker(): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getOptimizationListApi>>(makeReqWithRD, {
      fetcher: getOptimizationListApi,
      fill: setOptimisationList,
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* linkCampaignWorker({
  payload,
}: ReturnType<typeof linkCampaignToOffer>): SagaIterator<void>  {
  try {
    const result = yield call(linkCampaign, payload)
    if(result) {
      toastSuccess('Оффер привязан')
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* unlinkCampaignWorker({
  payload,
}: ReturnType<typeof unlinkCampaignToOffer>): SagaIterator<void>  {
  try {
    const result = yield call(unlinkCampaign, payload)
    if(result) {
      const oldDataTemp = yield select(selectorCampaign);
      const oldData = oldDataTemp.get('data');
      const oldPast = oldDataTemp.get('past');
      oldPast.data.push(oldData);
      oldPast.LTU = Date.now();
      
      if (!oldDataTemp.get('isLoading')) {
        const campaign = yield call(getCampaignInfo, { xxhash: payload.campaigns_xxhash[0] });

        yield put(
          setPersonalDataSuccess(
            oldDataTemp
              .set('data', campaign)
              .set('past', oldPast)
              .set('LTU', Date.now())
              .set('error', { isError: false, message: '', code: 0 }),
          ),
        );
      }
    }
  } catch (e) {
    console.error({ e });
  }
}
