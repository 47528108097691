import { ErrorsMap } from '../shared/dictionaryErrors';
import i18next from '../i18n';

type TGetErrorText = (code: number | undefined) => string;
const getErrorText: TGetErrorText = (code) => {
  if (!code) {
    return 'Неизвестная ошибка';
  }
  const message = ErrorsMap.get(code);
  return message ? i18next.t(message) : 'Неизвестная ошибка';
};

export default getErrorText;
