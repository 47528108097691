import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { FetchedData, genFetchedData } from 'redux/fetchedData';
import { toastSuccess } from 'utils/ToastUtils/toastUtils';
import updateToken from 'utils/updateToken';
import { TGetTechnicalInfo } from 'types/general';
import { setAPIMethods, setTechnicalInfo, setTempAPIUsers } from '../reducer';
import {
  makeReqWithRD,
  TMakeReqWithRD,
  updateFillFetchedData,
} from '../../../../redux/makeReqWithRD';
import {
  deleteAPIUserAPI,
  editAPIUserAPI,
  getAPIMethods,
  getAPIUserList,
  getTechnicalInfo,
  refreshTokenAPIUserAPI,
  userAPICreate,
  /*   userCreate,
  userEdit, */
} from '../api';
import {
  deleteAPIUser,
  editAPIUser,
  fetchAPIUsers,
  refreshTokenAPIUser,
  createNewAPIUser,
  fetchGetTechnicalInfo,
} from '../actions';
import i18n from '../../../../i18n';
import { TAPIUser } from '../types';
import { selectorAPIUsers } from '../selectors';

export function* workerFetchAPIUsers({
  payload,
}: ReturnType<typeof fetchAPIUsers>): SagaIterator<void> {
  const { isSavePreData } = payload;
  try {
    const currentData = yield select(selectorAPIUsers);
    yield call<TMakeReqWithRD<typeof getAPIUserList>>(makeReqWithRD, {
      fetcher: getAPIUserList,
      fill: (v) => {
        const data = v.get('data');
        let newData: TAPIUser[] = [];
        if (data) {
          newData = data.map((item, i) => ({
            ...item,
            id: i,
            statistics: null,
          }));
        }

        return setTempAPIUsers(
          updateFillFetchedData({ fillFetchedData: v, newData }),
        );
      },
      preData: isSavePreData && currentData.get('data'),
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* deleteAPIUsersWorker({
  payload,
}: ReturnType<typeof deleteAPIUser>): SagaIterator<void> {
  try {
    const result: boolean = yield call(deleteAPIUserAPI, payload);
    if (result) {
      const userList: FetchedData<TAPIUser[]> = yield select(selectorAPIUsers);
      const userListData = userList.get('data');
      if (userListData) {
        const newData = userListData.filter(
          (item) => item.email !== payload.email,
        );
        const newList = userList.set('data', newData);
        yield put(setTempAPIUsers(newList));
        toastSuccess({
          before: `${i18n.t(`users_page.userDel`)} `,
          title: `${payload.email}`,
          after: ` ${i18n.t(`users_page.userHBD`)}`,
        });
      }
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* refreshTokenAPIUsersWorker({
  payload,
}: ReturnType<typeof refreshTokenAPIUser>): SagaIterator<void> {
  try {
    const result: string = yield call(refreshTokenAPIUserAPI, payload);
    if (result) {
      const userList: FetchedData<TAPIUser[]> = yield select(selectorAPIUsers);
      const userListData = userList.get('data');
      if (userListData) {
        const newData = userListData.map((item) =>
          item.email === payload.email ? { ...item, auth_token: result } : item,
        );
        const newList = userList.set('data', newData);
        yield put(setTempAPIUsers(newList));
        toastSuccess(i18n.t(`users_page.token_has_update`));
      }
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* workerAPIUserEdit({
  payload,
}: ReturnType<typeof editAPIUser>): SagaIterator<void> {
  const { callbacks, blockRefresh, ...rest } = payload;
  try {
    if (callbacks?.setIsLoading) {
      callbacks?.setIsLoading(true);
    }
    let result: boolean | null = null;
    result = yield call(editAPIUserAPI, rest);

    if (result) {
      const { email } = rest;
      toastSuccess({
        before: 'Пользователь ',
        title: email,
        after: ' успешно изменен',
      });
      if (!blockRefresh) {
        yield put(fetchAPIUsers({ isSavePreData: true }));
      }
      if (callbacks?.goBack) {
        callbacks?.goBack();
      }
      if (callbacks?.onToggle) {
        callbacks?.onToggle((prev) => !prev);
      }
    }
  } catch (e) {
    console.error({ e });
    if (e.code === 40 && callbacks?.onError) {
      callbacks?.onError(e.data.description);
    }
    if (callbacks?.setIsLoading) {
      callbacks?.setIsLoading(false);
    }
  }
}

export function* workerUserCreateAPISaga({
  payload,
}: ReturnType<typeof createNewAPIUser>): SagaIterator<void> {
  const { callbacks, ...restPayload } = payload;
  try {
    if (callbacks?.setisLoading) {
      callbacks?.setisLoading(true);
    }
    yield call(updateToken);
    let result: { email: string } | null = null;
    result = yield call(userAPICreate, restPayload);

    if (result) {
      toastSuccess({
        before: `${i18n.t(`just_words.w7`)} `,
        title: result.email,
        after: ` ${i18n.t(`just_words.one_is_successful_create`)}`,
      });

      if (callbacks?.goBack) {
        callbacks?.goBack();
      }
      yield put(fetchAPIUsers({}));
    }
  } catch (e) {
    if (e.code === 40 && callbacks?.onError) {
      callbacks?.onError(e.data.description);
    }
    console.error({ e });
  } finally {
    if (callbacks?.setisLoading) {
      callbacks?.setisLoading(false);
    }
  }
}

export function* workerGetMethodsUserAPISaga(): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getAPIMethods>>(makeReqWithRD, {
      fetcher: getAPIMethods,
      fill: setAPIMethods,
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* workerGetTechnicalInfo({
  payload,
}: ReturnType<typeof fetchGetTechnicalInfo>): SagaIterator<void> {
  let fetchedData = genFetchedData<TGetTechnicalInfo>(null).set(
    'isLoading',
    true,
  );
  yield put(setTechnicalInfo(fetchedData));
  try {
    const res = yield call(getTechnicalInfo, payload);
    fetchedData = fetchedData.set('data', res);
  } catch (e) {
    console.error({ e });
  } finally {
    fetchedData = fetchedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setTechnicalInfo(fetchedData));
  }
}
