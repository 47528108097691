import React, { FC, useMemo } from 'react';
import PageHeader from 'components/PageHeader';
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import cn from 'classnames';
import Loader from 'components/UI/Loader';
import useFetchUserEditFormData from 'domains/usersAPI/model/hooks/useFetchUserEditFormData';
import useEditUserFormik from 'domains/usersAPI/model/hooks/useEditUserFormik';
import NavigationHeader from 'components/NavigationHeader';
import Error404 from 'domains/app/Errors/Error404';
import css from './styles.module.scss';
import Settings from './Settings';
import Token from '../components/Token';
import i18n from '../../../../i18n';

const Edit: FC = () => {
  const { currentUser, isLoading, email, updateToken } =
    useFetchUserEditFormData();

  const { formik, tagValidationError } = useEditUserFormik({ currentUser });

  const links = useMemo(
    () => [
      {
        title: i18n.t(`users_page.users_api`),
        url: `/control_panel/users_api`,
        type: 'home',
      },
      {
        title: `${email}`,
        url: `заглушка`,
        type: 'end',
      },
    ],
    [],
  );

  if(!currentUser && !isLoading) return <Error404 />

  return (
    <>
      <NavigationHeader
        back={{
          to: `/control_panel/users_api`,
        }}
      />
      <div className={css.form_user}>
        <PageHeader title={email}>
          <Breadcrumbs type="Custom" linksCustom={links} />
        </PageHeader>
        {isLoading ? (
          <div className={css.loader}>
            <Loader />
          </div>
        ) : (
          <div className={cn(css.container, 'container')}>
            <Settings formik={formik} tagValidationError={tagValidationError} mod="edit" />
            <Token updateToken={updateToken} token={currentUser?.auth_token} />
          </div>
        )}
      </div>
    </>
  );
};

export default Edit;
