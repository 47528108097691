import { FormikProps, useFormik } from 'formik';
import { TCreativeYandex } from 'domains/creative/types';
import deepEqual from 'deep-equal';
import { useTranslation } from 'react-i18next';
import { useDispatchApp } from 'redux/rootSelectors';
import { useUserTheme } from 'domains/user/model/selectors';
import { useValidateSchemeCreatives } from '../../../../validate/validate';
import { setEditCreativeRequest } from '../../../../../reduser';
import { setFormDefault } from '../../../../../../uploads/reducer';

type Props = {
  data: TCreativeYandex;
  xxhash: string;
  setEdit: (param: boolean) => void;
};
type TFUseEditYandex = (p: Props) => {
  formik: FormikProps<TCreativeYandex>;
  handleFormikReset: () => void;
  handleFormikSubmit: () => void;
};

export const useEditYandex: TFUseEditYandex = ({
  data: {
    alt_text,
    img_yandex_html,
    yandex_brand,
    yandex_category,
    zip_yandex_html,
  },
  xxhash,
  setEdit,
}) => {
  const { yandexSchema } = useValidateSchemeCreatives();
  const dispatch = useDispatchApp();
  const { t } = useTranslation();
  const { getValueFromSettingForKey } = useUserTheme();

  const formik = useFormik<TCreativeYandex>({
    enableReinitialize: true,
    validationSchema: yandexSchema,
    initialValues: {
      zip_yandex_html,
      yandex_category,
      yandex_brand,
      img_yandex_html,
      alt_text,
    },
    onSubmit: (values) => {
      if (
        !deepEqual(
          {
            zip_yandex_html,
            yandex_category,
            yandex_brand,
            img_yandex_html,
            alt_text,
          },
          values,
        )
      ) {
        dispatch(
          setEditCreativeRequest({
            data: { xxhash, data: values },
            visual: {
              setter: setEdit,
              translation:
                getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
                !getValueFromSettingForKey('ShowBroadcastStatus'),
              value: false,
              alertText: t('creative.crea_type.yandex'),
            },
          }),
        );
      } else {
        dispatch(setFormDefault(true));
        setEdit(false);
      }
    },
  });

  /** Функция сбрасывает форму */
  const handleFormikReset = () => {
    dispatch(setFormDefault(true));
    formik.resetForm();
    setEdit(false);
  };
  /** Функция отправляет форму */
  const handleFormikSubmit = () => {
    formik.handleSubmit();
  };

  return {
    formik,
    handleFormikReset,
    handleFormikSubmit,
  };
};
