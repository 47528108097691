import { SagaIterator } from '@redux-saga/core';
import { call, put } from '@redux-saga/core/effects';
import {
  fetchAllClients,
  fetchClients,
  setStatusClientAction,
  setTempAllClients,
  setTempClients,
} from '../reducer';
import { makeReqWithRD, TMakeReqWithRD } from '../../../redux/makeReqWithRD';
import { getClients, setStatusClient } from '../api';
import updateToken from '../../../utils/updateToken';
import { ParamClients } from '../types';
import { toastSuccess } from '../../../utils/ToastUtils/toastUtils';

export function* workerFetchClientsSaga({
  payload,
}: ReturnType<typeof fetchClients>): SagaIterator<void> {
  try {
    const { status, idAgency } = payload;
    const param: ParamClients = {};
    if (status) {
      param.status = status;
    }
    if (idAgency) {
      param.idAgency = idAgency;
    }
    yield call<TMakeReqWithRD<typeof getClients>>(makeReqWithRD, {
      fetcher: getClients,
      fill: setTempClients,
      parameters: param,
    });
  } catch (e) {
    console.log({ e });
  }
}

export function* workerFetchAllClientsSaga({
  payload,
}: ReturnType<typeof fetchAllClients>): SagaIterator<void> {
  try {
    const { idAgency, status } = payload;
    const param: ParamClients = {};
    if (idAgency) {
      param.idAgency = idAgency;
    }
    if (status) {
      param.status = status;
    }
    yield call<TMakeReqWithRD<typeof getClients>>(makeReqWithRD, {
      fetcher: getClients,
      fill: setTempAllClients,
      parameters: param,
    });
  } catch (e) {
    console.log({ e });
  }
}

export function* workerSetStatusClient({
  payload,
}: ReturnType<typeof setStatusClientAction>): SagaIterator {
  try {
    const { value, setter, status, id, reRenderParams } = payload;
    yield call(updateToken);
    const result = yield call(setStatusClient, { status, id });
    if (result) {
      setter(!value);
      if (reRenderParams) {
        const {
          idAgency,
          status: statusClients,
          isArchive,
          alertText,
        } = reRenderParams;
        if (isArchive) {
          const param: ParamClients = {};
          if (statusClients) {
            param.status = statusClients;
          }
          if (idAgency) {
            param.idAgency = idAgency;
          }
          yield put(fetchClients(param));
          toastSuccess(alertText);
        }
      }
    }
  } catch (e) {
    console.log({ e });
  }
}
