import { PartnerHashList, Period } from 'types/general';
import request from '../../lib/helpers/requestApi';
import {
  AgencyInfo,
  ParamAddAuditDictionary,
  ParamAgencyInfo,
  ParamCreateAgency,
  ParamEditAgency,
  ParamEditAuditDictionary,
  ParamGetAuditDictionary,
  ParamGetStatisticByAudit,
  TClientStat,
  TGetAuditDictionary,
  TGetStatisticByAudit,
} from './types';

export const getAgencyInfo = request<AgencyInfo, ParamAgencyInfo>({
  method: 'partner.getInfo',
});

export const createAgencyApi = request<ParamAgencyInfo, ParamCreateAgency>({
  method: 'partner.addAgency',
});

export const editAgencyApi = request<boolean, ParamEditAgency>({
  method: 'partner.editAgency',
});

export const getClientsStatApi = request<TClientStat, PartnerHashList & {period: Period, status_list_clients?: string[]}>({
  method: 'statistic.getByClients',
});

export const getAuditDictionaryList = request<TGetAuditDictionary[], ParamGetAuditDictionary>({
  method: 'auditDictionary.getList',
});

export const addAuditDictionary = request<boolean, ParamAddAuditDictionary>({
  method: 'auditDictionary.add',
});

export const editAuditDictionary = request<boolean, ParamEditAuditDictionary>({
  method: 'auditDictionary.edit',
});

export const getStatisticByAudit = request<TGetStatisticByAudit, ParamGetStatisticByAudit>({
  method: 'statistic.getStatisticByAudit',
});
