/* eslint-disable no-extra-boolean-cast */
import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import Card from 'components/UI/Card';
import { FiX } from 'react-icons/fi';
import MainSearch from 'components/MainSearch';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { TGetAuditDictionary } from 'domains/agency/types';
import LoadingItem from 'domains/client/LimitGroups/ui/Loading/LoadingItem';
import css from './styles.module.scss';
import Item from './Item';
import { TAddAuditData } from '../Settings/hook/useSettings';
import { ReactComponent as EmptySvg } from '../../../../assets/errors/empty-analysis.svg';
import { useAudiencesMl } from './hooks/useAudiencesMl';

interface IProps {
  formik: FormikProps<TGetAuditDictionary[]>;
  intermediateFormik: FormikProps<TGetAuditDictionary[]>;
  editAuditDictionary: (id: number, editedData: TAddAuditData) => void;
  handlerInput: (currentSubmitHandler: () => void, title?: string) => void;
  setCurrentIntermediateData: Dispatch<
    SetStateAction<TGetAuditDictionary | undefined>
  >;
  auditLoading: boolean;
  auditDictionaryList: TGetAuditDictionary[] | null;
}

const AudiencesMl: FC<IProps> = ({
  formik,
  intermediateFormik,
  editAuditDictionary,
  handlerInput,
  setCurrentIntermediateData,
  auditLoading,
  auditDictionaryList,
}) => {
  const { t } = useTranslation();
  const {
    openSearch,
    valuesState,
    audienceBlockRef,
    searchQuery,
    handleClickOpen,
    handleClose,
    openModal,
    returnErrorText,
    deleteItem,
    addIntermediateAudits,
    setOpenSearch,
    setSearchQuery,
    searchRef,
    intermediateState,
    emptySearch,
  } = useAudiencesMl({
    editAuditDictionary,
    intermediateFormik,
    formik,
  });

  const setEmptyNode = useCallback(
    (search?: boolean) => (
      <div className={css.empty}>
        <EmptySvg />
        <p>
          {search ? (
            t(`search_none.none_1`)
          ) : (
            <Trans
              i18nKey="agencies_page.report.au_empty"
              components={{ br: <br /> }}
            />
          )}
        </p>
      </div>
    ),
    [],
  );

  return (
    <Card className={css.audiences}>
      <div
        className={cn(css.header, {
          _isOpenSearch: openSearch,
        })}
      >
        <h3>{t(`agencies_page.report.title`)}</h3>
        {valuesState && (
          <MainSearch
            toggleSearch={openSearch}
            onOpenSearch={() => {
              setOpenSearch(true);
              setSearchQuery('');
            }}
            value={searchQuery}
            onChangeSearch={(e) => {
              setSearchQuery(e.target.value);
            }}
            onCloseSearch={() => {
              setOpenSearch(false);
              setSearchQuery('');
            }}
            placeholder={t(`targetings.keywords.t16`)}
            match={0}
            isShowMatch={false}
            searchRef={searchRef}
            audienceBlockRef={audienceBlockRef}
          />
        )}
      </div>

      <div ref={audienceBlockRef} className={css.body}>
        {formik.values
          .filter((data) =>
            data.title.toLowerCase().includes(searchQuery.toLocaleLowerCase()),
          )
          .map((data, index) => (
            <Item
              key={data.id}
              data={data}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              openModal={openModal}
              formik={formik}
              name={`[${index}].title`}
              errorText={returnErrorText(formik.errors, index)}
              deleteItem={() => deleteItem(data.id, data.title)}
              handlerInput={() => handlerInput(formik.handleSubmit)}
              setCurrentIntermediateData={setCurrentIntermediateData}
            />
          ))}
        {intermediateFormik.values.map((data, index) => (
          <Item
            key={data.id}
            data={data}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            openModal={openModal}
            formik={intermediateFormik}
            name={`[${index}].title`}
            errorText={returnErrorText(intermediateFormik.errors, index)}
            deleteItem={() => deleteItem(data.id, data.title)}
            handlerInput={() =>
              handlerInput(intermediateFormik.handleSubmit, data.title)
            }
          />
        ))}
        {auditDictionaryList === null || auditLoading ? (
          <>
            {[...Array(4)].map((_, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <LoadingItem key={i} />
            ))}
          </>
        ) : (
          auditDictionaryList !== null && !(valuesState || intermediateState) && setEmptyNode()
        )}
        {emptySearch && setEmptyNode(true)}
      </div>
      <button
        type="button"
        disabled={!!intermediateFormik.values.length}
        className={css.add_btn}
        onClick={() => addIntermediateAudits()}
        title={!!intermediateFormik.values.length ? 'Сохраните аудиторию' : ''}
      >
        <FiX size={16} />
        <span>{t(`agencies_page.report.add_au`)}</span>
      </button>
    </Card>
  );
};

export default AudiencesMl;
