import React, { FC } from 'react';
import Input from 'components/UI/Input/input';
import { TTypeHookProps } from 'domains/creative/types';
import EridWarning from 'components/EridWarning';
import css from '../../../styles.module.scss';
import { IUseTypeProp } from '../../../../hooks/useType';

type TClickunderProps = Omit<IUseTypeProp, 'defaultSizeOption'> &
  Omit<TTypeHookProps, 'showBlock' | 'setToggle'> & {
    setIsDisableSaveButton: React.Dispatch<React.SetStateAction<boolean>>
  }

const index: FC<TClickunderProps> = ({
  formik: { values, errors, setFieldValue },
  erid,
  setIsDisableSaveButton,
}) => (
  <div className={css.form}>
    {erid && (
      <>
        <Input
          label={erid.title}
          showValueCount
          error={!!errors.erid}
          errortext={errors.erid}
          inputAttributes={{
            name: 'erid',
            maxLength: 80,
            value: values.erid,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('erid', e.target.value.trim()),
          }}
        />
        {values.erid && (
          <EridWarning
            withAccept
            isChangeErid
            setIsDisableSaveButton={setIsDisableSaveButton}
          />
        )}
      </>
    )}
  </div>
);

export default index;
