import {
  ChangeEvent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { VariableSizeList } from 'react-window';
import { RSettingsCatalog } from 'domains/target/model/types';
import { useDispatchApp } from 'redux/rootSelectors';
import { updateAu } from 'domains/target/actions';
import { TmapCatalog } from '../types';

interface IUseCheckBoxMProps {
  catalog: RSettingsCatalog;
  onHandleSubmit: (
    v: {
      key: string;
      title: string;
    }[],
  ) => void;
  type: string;
  isOpen: boolean;
  onCloseModal: () => void;
  campaign_xxhash: string;
}

interface IUseCheckBoxMReturn {
  search: string;
  changeSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  getItemSize: (index: number) => number;
  mapCatalogF: TmapCatalog[];
  refList: RefObject<VariableSizeList<any>>;
  onSubmit: () => void;
  onToggle: (
    index: number,
  ) => (v: boolean, ref: RefObject<HTMLDivElement>) => void;
  onOpen: (index: number, val: boolean) => () => void;
  onCheked: (e: ChangeEvent<HTMLInputElement>) => void;
  isLoadingUpdateAu: boolean;
  onUpdateAu: () => void;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  notFound: string[];
}

export const useCheckBoxM = ({
  catalog,
  onHandleSubmit,
  type,
  isOpen,
  onCloseModal,
  campaign_xxhash,
}: IUseCheckBoxMProps): IUseCheckBoxMReturn => {
  const HIGHT = 40;
  const [search, setSearch] = useState<string>('');
  const [mapCatalog, setMapCatalog] = useState<TmapCatalog[]>([]);
  const [mapCatalogView, setMapCatalogView] = useState<TmapCatalog[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [value, setValue] = useState('');
  const [notFound, setNotFound] = useState<string[]>([]);

  const dispatch = useDispatchApp();

  const changeSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    [setSearch, type, isOpen],
  );

  const onUpdateAu = () => {
    if (isLoading) return;
    dispatch(
      updateAu({
        targetName: 'au',
        campaign_xxhash,
        callbacks: {
          setIsLoading,
          setNotFound,
        },
      }),
    );
  };

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (type === 'au') {
      const val = e.target.value;
      const rex = /[^0-9\n]/;
      if (val.length) {
        if (!rex.test(val)) {
          setValue(e.target.value);
        }
      } else {
        setValue(e.target.value);
      }
    } else {
      setValue(e.target.value);
    }
  };

  const onCheked = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = e.currentTarget;

      return setMapCatalog((s) => {
        const copy = [...s];
        const index = copy.findIndex((item) => item.key === name);
        if (index < 0) return s;
        copy[index].selected = checked;
        return copy;
      });
    },
    [mapCatalog],
  );
  useEffect(() => {
    setSearch('');
  }, [isOpen]);

  useEffect(() => {
    const cat: TmapCatalog[] = catalog.map((item: any) => ({
      ...item,
      hight: HIGHT,
      selected: false,
      toggle: false,
    }));
    setMapCatalog(cat);
  }, [catalog, isOpen]);

  const refList = useRef<VariableSizeList>(null);
  const getItemSize = useCallback(
    (index: number) => mapCatalog[index].hight,
    [mapCatalog],
  );

  const onToggle = useCallback(
    (index: number) => (v: boolean, ref: RefObject<HTMLDivElement>) => {
      if (!refList.current) return;
      const h = (ref.current?.clientHeight ?? HIGHT) + HIGHT;

      refList.current.resetAfterIndex(index);
      setMapCatalog((s) => {
        const copy = [...s];
        if (index < 0) return s;
        copy[index].hight = v ? h : HIGHT;
        return copy;
      });
    },
    [mapCatalog, refList],
  );

  const onOpen = useCallback(
    (index: number, val: boolean) => () => {
      setMapCatalogView((s) => {
        const copy = [...s];
        if (index < 0) return s;
        copy[index].toggle = val;
        return copy;
      });
    },
    [mapCatalogView],
  );

  const onSubmit = useCallback(() => {
    const notFoundArr: string[] = [];
    const cheked: { key: string; title: string }[] = [];
    let auKeyList: string[] = [];
    if (value && type === 'au') {
      auKeyList = value
        .trim()
        .split('\n')
        .filter((e: string) => e.length > 0);
    }
    mapCatalog.forEach(({ title, key, selected }) => {
      if (auKeyList.length && type === 'au') {
        if (auKeyList.includes(key)) {
          cheked.push({ key, title });
          return;
        }
      }
      if (selected) {
        cheked.push({ key, title });
      }
    });
    auKeyList.forEach((key) => {
      if (cheked.findIndex((item) => item.key === key) < 0)
        notFoundArr.push(key);
    });
    if (notFoundArr.length && type === 'au') {
      setNotFound(notFoundArr);
      return;
    }
    onHandleSubmit(cheked);
    onCloseModal();
  }, [mapCatalog, value]);

  // const mapCatalogF = useMemo(
  //   () =>
  //     mapCatalog.filter(({ title }) =>
  //       title.toUpperCase().includes(search.toUpperCase()),
  //     ),
  //   [search, mapCatalog],
  // );

  useEffect(() => {
    setMapCatalogView(
      mapCatalog.filter(({ title }) =>
        title.toUpperCase().includes(search.toUpperCase()),
      ),
    );
  }, [search, mapCatalog]);

  return {
    search,
    changeSearch,
    getItemSize,
    mapCatalogF: mapCatalogView,
    refList,
    onSubmit,
    onToggle,
    onOpen,
    onCheked,
    onUpdateAu,
    isLoadingUpdateAu: isLoading,
    value,
    notFound,
    onChange,
  };
};
