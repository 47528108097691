import React, { ButtonHTMLAttributes, FC } from 'react';
import cn from 'classnames';
import './styles.scss';

type Props = {
  title: string;
  icon?: JSX.Element;
  buttonAttributes?: ButtonHTMLAttributes<HTMLButtonElement>;
  transparent?: boolean;
  grey?: boolean;
  lightgrey?: boolean;
  lightblue?: boolean;
  autoWidth?: boolean;
  white?: boolean;
  noBorder?: boolean;
  height?: number;
  red?: boolean;
};

const Button: FC<Props> = ({
  title,
  buttonAttributes,
  icon,
  autoWidth,
  grey,
  lightblue,
  lightgrey,
  white,
  noBorder,
  transparent,
  height,
  red,
}) => (
  <button
    type="button"
    {...buttonAttributes}
    className={cn('button', buttonAttributes?.className, {
      button_transparent: transparent,
      button_white: white,
      button_grey: grey,
      button_lightgrey: lightgrey,
      button_lightForDomain: window.location.hostname === 'my.turbotargeting.io',
      button_lightblue: lightblue,
      button_red: red,
      button_hasIcon: icon,
      button_autoWidth: autoWidth,
      button_noBorder: noBorder,
    })}
    style={{ height: `${height}px`, ...buttonAttributes?.style }}
  >
    {' '}
    {icon && <span className="icon">{icon}</span>}
    {title && <span>{title}</span>}
  </button>
);

export default Button;
