type TOptions = {
  label: string;
  value: string;
}[];

export const checkSelectOptionsTranslation = (
  ruOptions: TOptions,
  engOptions: TOptions,
  currentLang: string,
): TOptions => {
  const currentOptionsTranslation =
    currentLang === 'ru' ? ruOptions : engOptions;
  return currentOptionsTranslation;
};

export default checkSelectOptionsTranslation;
