import { SagaIterator } from 'redux-saga';
import { call, put } from '@redux-saga/core/effects';
import { UploadFilesBwListUpdate, setBwListLoadFiles } from '../reducer';
import updateToken from '../../../utils/updateToken';
import uploadImage, { TResult } from '../../../utils/upload';
import { genFetchedData } from '../../../redux/fetchedData';
import { RFilesBwListUpdate, FilesBwUpdate } from '../types';
import { getUploadFileUrl } from '../../uploads/model/api';

export function* workerFetchFiles({
  payload,
}: ReturnType<typeof UploadFilesBwListUpdate>): SagaIterator<void> {
  let fetchedData = genFetchedData<RFilesBwListUpdate>(null).set(
    'isLoading',
    true,
  );
  yield put(setBwListLoadFiles(fetchedData));
  try {
    yield call(updateToken);
    const requestUpload = yield call(getUploadFileUrl, { type: 'pretarget' });
    let dataResult: RFilesBwListUpdate = [];
    const formData = new FormData();
    let size = 0;
    const sortPayload = payload.sort((a, b) => a.size - b.size);
    sortPayload.forEach((file) => {
      size += +file.size;
      if (size > 314572800) {
        dataResult = [
          ...dataResult,
          {
            res: { error: { code: 413, message: 'error limit files' } },
            file,
          },
        ];
        return;
      }
      if (+file.size < 209715200) {
        formData.append('file[]', file);
        return;
      }
      dataResult = [
        ...dataResult,
        {
          res: { error: { code: 2032, message: 'error limit file' } },
          file,
        },
      ];
    });

    if (formData.getAll('file[]').length) {
      const data: TResult<FilesBwUpdate> = yield call(
        uploadImage,
        requestUpload,
        formData,
      );
      const filterPayload = sortPayload.filter((file) => file.size < 209715200);
      Object.keys(data.result).forEach((key, index) => {
        if (typeof data.result[key] === 'string') {
          dataResult = [
            ...dataResult,
            {
              res: { result: 'ok' },
              file: filterPayload[index],
            },
          ];
        } else {
          dataResult = [
            ...dataResult,
            {
              res: { error: data.result[key] },
              file: filterPayload[index],
            },
          ];
        }
      });
    }
    fetchedData = fetchedData.set('data', dataResult);
    yield put(setBwListLoadFiles(fetchedData));
  } catch (e) {
    console.error(e);
    const code = e?.code ?? 0;
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: e.message ?? '',
      code,
    });
    yield put(setBwListLoadFiles(fetchedData));
  } finally {
    fetchedData = fetchedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setBwListLoadFiles(fetchedData));
  }
}
