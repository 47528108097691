import { shallowEqual, useSelector } from 'react-redux';
import { FormikProps, useFormik } from 'formik';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useDispatchApp } from 'redux/rootSelectors';
import { AppState } from '../../../../../redux/rootReducer';
import { initialState, createAgency, setLastAddAgency } from '../../../reducer';
import { useIsShowMCreate } from './useIsShowMCreate';
import { useClearLinkImage } from '../../../../modals/linkToImage/useClearLinkImage';
import { AgencyState } from '../../../types';

type TForm = {
  title: string;
  site: string;
};

type TFUseDataMCreate = () => {
  isShowMCreate: boolean;
  formik: FormikProps<TForm>;
  closeReset: () => void;
  onClose: () => void;
};

export const useDataMCreate: TFUseDataMCreate = () => {
  const { t, i18n } = useTranslation();

  const clearImage = useClearLinkImage();

  const dispatch = useDispatchApp();

  const isShowMCreate = useSelector<AppState, AgencyState['isShowMCreate']>(
    ({ agencyReducer }) => agencyReducer.isShowMCreate,
    shallowEqual,
  );
  const lastAddAgency = useSelector<AppState, AgencyState['lastAddAgency']>(
    ({ agencyReducer }) => agencyReducer.lastAddAgency,
    shallowEqual,
  );

  const formik = useFormik<TForm>({
    initialValues: {
      title: t('agencies_page.agency_new'),
      site: '',
    },
    onSubmit: (values) => {
      dispatch(createAgency(values));
    },
    validationSchema: Yup.object({
      title: Yup.string().required(t('clients_page.client_create.hint1')),
    }),
  });
  useEffect(() => {
    formik.setFieldValue('title', t('agencies_page.agency_new'));
  }, [i18n.language]);

  const onClose: ReturnType<TFUseDataMCreate>['onClose'] =
    useIsShowMCreate(false);

  const closeReset: ReturnType<TFUseDataMCreate>['closeReset'] =
    useCallback(() => {
      formik.resetForm();
      onClose();
      clearImage();
      dispatch(setLastAddAgency(initialState.lastAddAgency));
    }, [formik]);

  useEffect(() => {
    if (lastAddAgency.get('LTU') === 0) return;
    if (lastAddAgency.get('error').isError) {
      formik.setSubmitting(false);
    }
    closeReset();
  }, [lastAddAgency]);

  return {
    isShowMCreate,
    onClose,
    closeReset,
    formik,
  };
};
