import { FiX } from 'react-icons/fi';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalOverlay from '../../../components/Modals/ModalOverlay1';
import css from './styles.module.scss';
import Card from '../../../components/UI/Card';
import Input from '../../../components/UI/Input/input';
import Button from '../../../components/UI/Buttons/buttons';
import { useLinkToImage } from './hooks';

export default function LinkToImage(): JSX.Element {
  const { t } = useTranslation();
  const { isShow, formik, onClose, errors } = useLinkToImage();
  return (
    <ModalOverlay onCloseModal={onClose} className={css.modal} isOpen={isShow}>
      <div className="container maxWidth_774">
        <Card noHover style={{ padding: '28px' }}>
          <div className={css.header}>
            <div className={css.title}>
              <h1>{t('clients_page.client_edit.link_to_image')}</h1>
            </div>

            <div className={css.close}>
              <FiX size={24} onClick={onClose} />
            </div>
          </div>
          <div className={css.form}>
            <form onSubmit={formik.handleSubmit}>
              <Input
                label={t('clients_page.client_edit.link_to_image_link')}
                errortext={formik.errors.url}
                error={!!formik.errors.url}
                errorData={errors}
                inputAttributes={{
                  name: 'url',
                  placeholder:
                    'http://lamcdn.net/lookatme.ru/post_image-image/PesKV_97Qe0EEKBo1OtjsQ-article.png',
                  onChange: formik.handleChange,
                  value: formik.values.url,
                  autoComplete: 'off',
                  maxLength: 250,
                }}
              />

              <div className={css.buttons}>
                <Button
                  transparent
                  title={t('clients_page.client_edit.link_to_image_cancel')}
                  height={36}
                  autoWidth
                  buttonAttributes={{
                    onClick: onClose,
                  }}
                />
                <Button
                  title={t('clients_page.client_edit.link_to_image_add')}
                  height={36}
                  autoWidth
                  buttonAttributes={{
                    type: 'submit',
                    disabled: !formik.isValid || formik.isSubmitting,
                    onClick: () => formik.handleSubmit,
                  }}
                />
              </div>
            </form>
          </div>
        </Card>
      </div>
    </ModalOverlay>
  );
}
