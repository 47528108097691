import { useEffect } from 'react';
import { FormikProps, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useCreativeAlgorithmsInfo } from 'domains/creative/creatives/hooks';
import { useClientInfo } from 'domains/client/model/selectors';
import { useUserTheme } from 'domains/user/model/selectors';
import { TExperimentalInfoField } from 'domains/campaign/types';
import { useCampaignInfo } from 'domains/campaign/model/selectors';
import { useSearchBreadcrumbsInfo } from 'domains/search/model/selectors';
import { getOffersList } from 'domains/offers/model/actions';
import { useOffersList } from 'domains/offers/model/selectors';
import { fetchClientInfoById } from '../../../../../client/reduser';
import { useValidateSchemeCreatives } from '../../../validate/validate';
import { validate } from './validate';
import { objectOptimizationWithFormik } from '../../../../../../utils/objectOptimizationWithFormik/objectOptimizationWithFormik';
import { setEditCreativeRequest } from '../../../../reduser';
import {
  AdditionalFormik,
  CreativeDetail,
  TCreativeHTML,
} from '../../../../types';
import { useDispatchApp } from '../../../../../../redux/rootSelectors';
import { SelectField } from '../../../../../../types/general';
import {
  selectDefault,
  TSelectDefault,
} from '../../../../../../utils/selectDefault';
import { ResponseInfoClient } from '../../../../../client/types';
import { useDataAdditional } from '../../../../model/hooks/useDataAdditional';
import { useValidateVendorLInk } from '../../../../model/hooks/useValidateVendorLInk';

type ParamProps = {
  xxhash: CreativeDetail['xxhash'];
  partner_xxhash: CreativeDetail['partner_xxhash'];
  link: CreativeDetail['link'];
  iurl: TExperimentalInfoField | null;
  click_audit_pixel: TExperimentalInfoField | null;
  jsAudit: TExperimentalInfoField | null;
  language: CreativeDetail['language'];
  pixel_additional: CreativeDetail['pixel_additional'];
  pixel: CreativeDetail['pixel'];
  categories: CreativeDetail['categories'];
  algorithm_cpc: CreativeDetail['algorithm_cpc'];
  adomain: CreativeDetail['adomain'];
  extension_link: CreativeDetail['extension_link'];
  show_html: string;
  setEdit: (param: boolean) => void;
  edit: boolean;
  HTMLCreoData: TCreativeHTML | null;
  type: string;
  config_values?: Record<string, string>;
};

type TFUseCreativeEditAdditional = (props: ParamProps) => {
  algorithmsSelect: SelectField[];
  client: ResponseInfoClient | null;
  isLoadingClient: boolean;
  formik: FormikProps<AdditionalFormik>;
  helperError: (...ar: string[]) => boolean;
  onResetForm: () => void;
  violence: SelectField[];
  sex: SelectField[];
  selectDefault: TSelectDefault;
  helper: ReturnType<typeof useValidateVendorLInk>['helper'];
  offerId?: number | null;
  currentOfferTitle?: string;
};

export const useCreativeEditAdditional: TFUseCreativeEditAdditional = ({
  xxhash,
  partner_xxhash,
  link,
  iurl,
  click_audit_pixel,
  categories,
  show_html,
  language,
  pixel,
  jsAudit: js_code_audit,
  pixel_additional,
  adomain,
  extension_link,
  setEdit,
  algorithm_cpc,
  type,
  config_values,
}) => {
  const { t } = useTranslation();
  const { additional } = useValidateSchemeCreatives();

  const { algorithmsSelect } = useCreativeAlgorithmsInfo();
  const { violence, sex } = useDataAdditional();
  const dispatch = useDispatchApp();
  const { getValueFromSettingForKey } = useUserTheme();

  const { data: campaign } = useCampaignInfo()

  const { data: client, isLoading: isLoadingClient } = useClientInfo();

  useEffect(() => {
    dispatch(fetchClientInfoById(partner_xxhash));
  }, []);

  const { data: bread_crumbs } = useSearchBreadcrumbsInfo();

  const { data: offersList } = useOffersList();
  const currentOfferTitle = offersList?.find(offer => offer.id === campaign?.offer_id)?.title

  useEffect(() => {
    if (bread_crumbs?.Client?.xxhash) {
      dispatch(getOffersList({partner_xxhash_list: [bread_crumbs.Client.xxhash]}))
    }
  }, [bread_crumbs?.Client?.xxhash]);

  const formik = useFormik<AdditionalFormik>({
    initialValues: {
      link: link || '',
      iurl: (iurl && config_values && config_values[iurl.id]) || '',
      click_audit_pixel: (click_audit_pixel && config_values && config_values[click_audit_pixel.id]) || '',
      data: {
        show_html: show_html || '',
      },
      js_code_audit:
        (js_code_audit &&
          config_values &&
          config_values[js_code_audit.id]) ||
        '',
      adomain: adomain || '',
      pixel: pixel || '',
      pixel_additional: pixel_additional || '',
      extension_link: extension_link || '',
      categories: {
        erotic_rank: categories.erotic_rank,
        iab: categories.iab,
        is_animated: categories.is_animated,
        violence_rank: categories.violence_rank,
      },
      algorithm_cpc,
      language: language || '',
    },
    onSubmit: (values) => {
      const param = objectOptimizationWithFormik<AdditionalFormik>(
        {
          link: link || '',
          iurl: (iurl && config_values && String(config_values[iurl.id])) || '',
          click_audit_pixel: (click_audit_pixel && config_values && String(config_values[click_audit_pixel.id])) || '',
          js_code_audit:
            (js_code_audit &&
              config_values &&
              String(config_values[js_code_audit.id])) ||
            '',
          adomain: adomain || '',
          pixel: pixel || '',
          pixel_additional: pixel_additional || '',
          extension_link: extension_link || '',
          categories: {
            erotic_rank: categories.erotic_rank,
            iab: categories.iab,
            is_animated: categories.is_animated,
            violence_rank: categories.violence_rank,
          },
          algorithm_cpc,
          data: {
            show_html: '',
          },
          language: language || '',
        },
        values,
      );
      if (param) {
        dispatch(
          setEditCreativeRequest({
            data: {
              type,
              xxhash,
              ...param,
              algorithm_cpc: {
                ...values.algorithm_cpc,
                max:
                  +values.algorithm_cpc.id !== 1
                    ? +values.algorithm_cpc.max || 0
                    : 0,
                min:
                  +values.algorithm_cpc.id !== 1
                    ? +values.algorithm_cpc.min || 0
                    : 0,
              },
            },
            visual: {
              setter: setEdit,
              translation:
                getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
                !getValueFromSettingForKey('ShowBroadcastStatus'),
              alertText: t('creative.create_creative.Additional_settings'),
              value: false,
            },
          }),
        );
      } else {
        setEdit(false);
      }
    },
    validationSchema: additional,
    enableReinitialize: true,
    validate,
  });
  const helperError: ReturnType<TFUseCreativeEditAdditional>['helperError'] = (
    ...names: string[]
  ) => names.some((e) => formik.errors[e] && formik.touched[e]);

  const onResetForm: ReturnType<TFUseCreativeEditAdditional>['onResetForm'] =
    () => {
      formik.handleReset(formik.values);
      setEdit(false);
    };

  const { helper } = useValidateVendorLInk({
    formik: {
      link: formik.values.link,
      pixel: formik.values.pixel,
      pixel_additional: formik.values.pixel_additional,
      extension_link: formik.values.extension_link,
      js_code_audit: formik.values?.js_code_audit,
    },
    adomain: formik.values.adomain,
  });

  return {
    algorithmsSelect,
    client,
    isLoadingClient,
    formik,
    helperError,
    onResetForm,
    violence,
    sex,
    selectDefault,
    helper,
    offerId: campaign?.offer_id,
    currentOfferTitle,
  };
};
