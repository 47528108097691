import { FetchedDataObj } from '../redux/fetchedData';

type TIsErrorFields = (
  error: FetchedDataObj<null>['error'],
  name: string,
) => boolean;

export const isErrorFields: TIsErrorFields = (error, name) => {
  const { isError, fields } = error;
  if (isError && fields?.length) {
    return fields.some((v) => v === name);
  }
  return false;
};
