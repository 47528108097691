import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RBreadCrumbs, RSearch } from './types';
import { FetchedData, genFetchedData } from '../../../redux/fetchedData';

export type SearchState = {
  isShowModal: boolean;
  tempSearch: FetchedData<RSearch>;
  tempAccessParent: FetchedData<RBreadCrumbs>;
  tempBreadcrumbs: FetchedData<RBreadCrumbs>;
  tempModalBreadcrumbs: FetchedData<RBreadCrumbs>;
  tagsCampaigns: string[];
  tagCampaignsActive: string[];
};
const initialState: SearchState = {
  isShowModal: false,
  tempSearch: genFetchedData<RSearch>(null),
  tempAccessParent: genFetchedData<RBreadCrumbs>(null),
  tempBreadcrumbs: genFetchedData<RBreadCrumbs>(null),
  tempModalBreadcrumbs: genFetchedData<RBreadCrumbs>(null),
  tagCampaignsActive: [],
  tagsCampaigns: [],
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setIsShowModal(state, action: PayloadAction<SearchState['isShowModal']>) {
      state.isShowModal = action.payload;
    },
    setTempAccessParent(
      state,
      action: PayloadAction<SearchState['tempAccessParent']>,
    ) {
      state.tempAccessParent = action.payload;
    },
    setTempAccessParentDefault(state) {
      state.tempAccessParent = initialState.tempAccessParent;
    },
    setTempSearch(state, action: PayloadAction<SearchState['tempSearch']>) {
      state.tempSearch = action.payload;
    },
    setTempSearchDefault(state) {
      state.tempSearch = initialState.tempSearch;
    },
    setTempBreadcrumbs(
      state,
      action: PayloadAction<SearchState['tempBreadcrumbs']>,
    ) {
      state.tempBreadcrumbs = action.payload;
    },
    setTempModalBreadcrumbs(
      state,
      action: PayloadAction<SearchState['tempModalBreadcrumbs']>,
    ) {
      state.tempModalBreadcrumbs = action.payload;
    },
    setTempModalBreadcrumbsDefault(state) {
      state.tempModalBreadcrumbs = initialState.tempModalBreadcrumbs;
    },
    setTagsCampaigns(
      state,
      action: PayloadAction<SearchState['tagsCampaigns']>,
    ) {
      state.tagsCampaigns = action.payload;
    },
    setTagCampaignActive(
      state,
      action: PayloadAction<SearchState['tagCampaignsActive']>,
    ) {
      state.tagCampaignsActive = action.payload;
    },
  },
});

export const {
  setIsShowModal,
  setTempSearch,
  setTempSearchDefault,
  setTempBreadcrumbs,
  setTempAccessParent,
  setTempAccessParentDefault,
  setTempModalBreadcrumbs,
  setTempModalBreadcrumbsDefault,
  setTagCampaignActive,
  setTagsCampaigns,
} = searchSlice.actions;

export const searchReducer = searchSlice.reducer;
