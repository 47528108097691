import { useState } from 'react';
import { useOuterElementClick } from '../../../../../hooks/useOuterElementClick';

type TFUseTag = () => {
  edit: boolean;
  handlerOpenEdit: () => void;
  handlerHideEdit: () => void;
  wrapperRef: ReturnType<typeof useOuterElementClick>['wrapperRef'];
};

const useTag: TFUseTag = () => {
  const [edit, setEdit] = useState(false);

  const handlerOpenEdit = () => {
    setEdit(true);
  };

  const handlerHideEdit = () => {
    setEdit(false);
  };

  const { wrapperRef } = useOuterElementClick({
    isShow: edit,
    closeModal: handlerHideEdit,
  });

  return {
    edit,
    handlerHideEdit,
    handlerOpenEdit,
    wrapperRef,
  };
};

export default useTag;
