import React from 'react';
import TreeNode from '../TreeNode';
import { checkedColors } from '../../chartColors';
import { PTreeNodes } from '../../hooks/useReportGeneral';

const TreeNodes: React.FC<PTreeNodes> = ({
  nodes,
  namingItem,
  firstCW,
  onSetFilterTable,
  filterTable,
  isUtmTags,
}) => (
  <>
    {nodes.map((e, i) => (
      <TreeNode
        node={e}
        namingItem={namingItem}
        key={JSON.stringify(e)}
        level={0}
        firstCW={firstCW}
        onSetFilterTable={onSetFilterTable}
        filterTable={filterTable}
        backgroundColor={checkedColors[i + 1]}
        isUtmTags={isUtmTags}
      />
    ))}
  </>
);

export default TreeNodes;
