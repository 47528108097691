import { Moderation as TModeration } from '../../types';

export type TPrefix = 'categories' | '' | 'images';
export type TField =
  | 'html_code'
  | 'title'
  | 'text'
  | 'icon'
  | 'iconMask'
  | 'image_1'
  | 'image_2'
  | 'image'
  | 'link'
  | 'iab'
  | 'language'
  | 'adomain'
  | 'size'
  | 'advertiser_name'
  | 'zip_yandex_html'
  | 'img_yandex_html'
  | 'alt_text'
  | 'yandex_brand'
  | 'yandex_category'
  | 'video_url'
  | 'duration'
  | 'skip_duration'
  | 'video_bitrate'
  | 'vast_url'
  | 'imgMask';
type TCheckedLIst = { isData: boolean; field: TField; prefix: TPrefix }[];

export const dict: Record<keyof TModeration, string> = {
  internal: 'Trading Desk',
  google: 'Google',
  yandex: 'Yandex',
  my_target: 'myTarget',
};

export const categoryAndLanguage: TCheckedLIst = [
  {
    field: 'iab',
    prefix: 'categories',
    isData: false,
  },
  {
    field: 'language',
    prefix: '',
    isData: false,
  },
];
export const linkAndDomainList: TCheckedLIst = [
  {
    field: 'link',
    prefix: '',
    isData: false,
  },
  {
    field: 'adomain',
    prefix: '',
    isData: false,
  },
];

export const generalList: TCheckedLIst = [
  ...linkAndDomainList,
  ...categoryAndLanguage,
];

export const pushList: TCheckedLIst = [
  {
    field: 'title',
    isData: true,
    prefix: '',
  },
  {
    isData: true,
    field: 'text',
    prefix: '',
  },
  {
    isData: true,
    field: 'iconMask',
    prefix: '',
  },
  {
    isData: true,
    field: 'imgMask',
    prefix: '',
  },
  ...generalList,
];

export const teaserList: TCheckedLIst = [
  {
    isData: true,
    field: 'title',
    prefix: '',
  },
  {
    isData: true,
    field: 'imgMask',
    prefix: '',
  },
  ...generalList,
];

export const yandexYandexList: TCheckedLIst = [
  {
    field: 'zip_yandex_html',
    prefix: '',
    isData: true,
  },
  {
    field: 'img_yandex_html',
    prefix: '',
    isData: true,
  },
  {
    isData: true,
    field: 'alt_text',
    prefix: '',
  },
  {
    isData: true,
    field: 'yandex_brand',
    prefix: '',
  },
  {
    isData: true,
    field: 'yandex_category',
    prefix: '',
  },
  ...linkAndDomainList,
];

export const yandexInternalList: TCheckedLIst = [
  ...yandexYandexList,
  ...categoryAndLanguage,
];

export const videoGeneralGoogleList: TCheckedLIst = [
  {
    isData: false,
    field: 'advertiser_name',
    prefix: '',
  },
];

export const generalVideoInline: TCheckedLIst = [
  {
    isData: true,
    field: 'video_url',
    prefix: '',
  },
  {
    isData: true,
    field: 'duration',
    prefix: '',
  },
  {
    isData: true,
    field: 'skip_duration',
    prefix: '',
  },
  {
    isData: true,
    field: 'video_bitrate',
    prefix: '',
  },
];

export const generalCompanion: TCheckedLIst = [
  ...generalVideoInline,
  {
    isData: true,
    field: 'html_code',
    prefix: '',
  }
];

export const generalVideoVast: TCheckedLIst = [
  {
    isData: true,
    field: 'vast_url',
    prefix: '',
  },
  {
    isData: true,
    field: 'duration',
    prefix: '',
  },
  {
    isData: true,
    field: 'skip_duration',
    prefix: '',
  },
];

export const htmlListInternal: TCheckedLIst = [
  {
    isData: true,
    field: 'html_code',
    prefix: '',
  },
];

export const htmlList: TCheckedLIst = [
  {
    isData: false,
    field: 'advertiser_name',
    prefix: '',
  },
  {
    isData: true,
    field: 'html_code',
    prefix: '',
  },
];

export const multiFormatList: TCheckedLIst = [
  {
    isData: true,
    field: 'title',
    prefix: '',
  },
  {
    isData: true,
    field: 'text',
    prefix: '',
  },
  {
    isData: true,
    field: 'icon',
    prefix: 'images',
  },
  {
    isData: true,
    field: 'image_1',
    prefix: 'images',
  },
  {
    isData: true,
    field: 'image_2',
    prefix: 'images',
  },
  {
    isData: false,
    field: 'link',
    prefix: '',
  },
];
