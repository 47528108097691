import React, { FC } from 'react';
import { FiX } from 'react-icons/fi';
import i18next from 'i18next';
import Button from 'components/UI/Buttons/buttons';
import css from './styles.module.scss';
import ModalOverlay from '../Modals/ModalOverlay1';

type Props = {
  message: string;
  cleanUp: (ok: boolean) => void;
};

const SaveChangesModal: FC<Props> = ({ cleanUp, message }) => (
  <ModalOverlay onCloseModal={() => cleanUp(false)} isOpen>
    <div className={css.card}>
      <div className={css.header}>
        <div className={css.title}>
          <h1>{i18next.t('just_words.exit_page')}</h1>
        </div>
        <div className={css.close}>
          <FiX size={24} onClick={() => cleanUp(false)} />
        </div>
      </div>
      <div className={css.text}>{message}</div>
      <div className={css.actions}>
        <Button
          transparent
          title={i18next.t('just_words.exit_page_no')}
          height={40}
          autoWidth
          buttonAttributes={{
            onClick: () => cleanUp(false),
          }}
        />
        <Button
          title={i18next.t('just_words.exit_page_yes')}
          height={40}
          autoWidth
          buttonAttributes={{
            onClick: () => cleanUp(true),
          }}
        />
      </div>
    </div>
  </ModalOverlay>
);

export default SaveChangesModal;
