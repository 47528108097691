import request from '../../lib/helpers/requestApi';
import { NotificationMessages, Subscripbe, Subscriptions } from './types';

/** Функция получет список уведомлений пользователя */
export const getNotificationMessages = request<NotificationMessages>({
  method: 'notification.getMessages',
});
/** Функция получает список подписок пользователя */
export const getNotificationSubscription = request<Subscriptions>({
  method: 'notification.getSubscriptions',
});
/** Функция устанавливает подписку */
export const setNotificationSubscription = request<boolean, Subscripbe>({
  method: 'notification.setSubscription',
});
/** Функция удаляает подписку */
export const removeNotificationSubscription = request<boolean, Subscripbe>({
  method: 'notification.removeSubscription',
});
