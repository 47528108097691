import { RAdvancedOptimizationLists } from '../types';
import { TDH } from '../../types';

type TFTransformDateAdvancedOptimization = (
  ids: number[] | undefined,
  keys: RAdvancedOptimizationLists,
) => Record<string, number>;
/**
 * функция возвращает объект для трансформации списков
 *  */
export const transformDateAdvancedOptimization: TFTransformDateAdvancedOptimization =
  (ids, keys) => {
    const data: Record<string, number> = {};
    if (keys) {
      keys.forEach(({ items, id }) => {
        if (items.length) {
          data[id] = items[0].id;
        }
        ids?.forEach((i) => {
          if (items.findIndex((value) => value.id === i) + 1) {
            data[id] = i;
          }
        });
      });
    }
    return data;
  };

type TFHasValuesLimits = (obj: TDH) => boolean;

export const hasValuesLimits: TFHasValuesLimits = (obj) =>
  Object.values(obj).filter((e) => !!e).length > 0;
