import request from '../../../lib/helpers/requestApi';
import { TOfferAddParams, TOfferEditParams, TOfferItem } from './types';

export const getOfferInfoAPI = request<TOfferItem, { id: number }>({
  method: 'offer.getInfo',
});

export const addOfferAPI = request<boolean, TOfferAddParams>({
  method: 'offer.add',
});

export const editOfferAPI = request<boolean, TOfferEditParams>({
  method: 'offer.edit',
  showError: false,
});

export const countCreativesOfferAPI = request<number, { id: number }>({
    method: 'offer.countCreatives',
  });
