import React, { useRef, MutableRefObject, FC, ReactText } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import useDropDown from 'hooks/useDropDown';
import cn from 'classnames';
import { checkedColors } from '../../chartColors';
import css from './styles.module.scss';
import { TFilter } from '../../hooks/useIntegrationFilter';

type Props = {
  filter: TFilter;
  onSetFilterChart: (p: TFilter) => void;
  allTitles: ReactText[];
};

const ChartFilter: FC<Props> = ({ filter, onSetFilterChart, allTitles }) => {
  const { availableItems, isActive, itemsFilter } = filter;
  const dropdownMenu: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const { toggle, isOpened, toggleOff } = useDropDown(dropdownMenu);

  return (
    <div className={css.filter}>
      <div
        className={cn(css.dropdown, {
          _isOpened: isOpened,
        })}
        ref={dropdownMenu}
      >
        <div className={css.selected} onClick={isOpened ? toggleOff : toggle}>
          <span>{isActive}</span>
          <FiChevronDown size={16} />
        </div>
        <div className={css.list}>
          {availableItems.map((e) => (
            <div
              className={cn(css.item, { _isActive: isActive === e })}
              onClick={() => {
                toggleOff();
                onSetFilterChart({
                  ...filter,
                  isActive: e,
                });
              }}
              key={e}
            >
              <span>{e}</span>
            </div>
          ))}
        </div>
      </div>

      <div className={css.items}>
        {itemsFilter.length > 0 &&
          itemsFilter.map((e) => (
            <div
              className={css.chart__item}
              key={e.key}
              onClick={() => {
                const newItems = itemsFilter.map((el) =>
                  el.key === e.key
                    ? { ...el, checked: !el.checked }
                    : { ...el },
                );
                return onSetFilterChart({
                  ...filter,
                  itemsFilter: newItems,
                });
              }}
            >
              <div
                className={cn(css.checkbox, { _active: e.checked })}
                style={{
                  backgroundColor:
                    checkedColors[
                      allTitles.findIndex((index) => index === e.key)
                    ],
                }}
              />
              <span className={css.name}>{e.key}</span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ChartFilter;
