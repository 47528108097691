import { ChartStatisticItem } from 'domains/campaigns/types';
import { useEffect, useState } from 'react';

interface IProps {
  transformedStatistic: ChartStatisticItem[];
}

interface IReturnType {
  currentSort: 'ASC' | 'DESC' | null;
  currentColumn: string | null;
  tableStatistic: ChartStatisticItem[];
  handleSort: (p: { key: string; sort: 'ASC' | 'DESC' | null }) => void;
}

export const useSortTableData = ({
  transformedStatistic,
}: IProps): IReturnType => {
  const [currentSort, setCurrentSort] = useState<'ASC' | 'DESC' | null>(null);
  const [currentColumn, setCurrentColumn] = useState<string | null>(null);
  const [tableStatistic, setTableStatistic] =
    useState<ChartStatisticItem[]>(transformedStatistic);

  useEffect(() => {
    setTableStatistic(transformedStatistic);
  }, [transformedStatistic]);

  const handleSort = (p: { key: string; sort: 'ASC' | 'DESC' | null }) => {
    const { sort, key } = p;
    setCurrentColumn(key);
    setCurrentSort(sort);
    let sortedArr = [...transformedStatistic];
    if (sort === 'ASC') {
      sortedArr?.sort((a, b) => {
        if (key === 'date') {
          return +new Date(a[key] || '') - +new Date(b[key] || '');
        }
        return a[key] - b[key];
      });
    } else if (sort === 'DESC') {
      sortedArr?.sort((a, b) => {
        if (key === 'date') {
          return +new Date(b[key] || '') - +new Date(a[key] || '');
        }
        return b[key] - a[key];
      });
    } else {
      sortedArr = [...transformedStatistic];
    }
    setTableStatistic(sortedArr);
  };

  return { currentSort, currentColumn, tableStatistic, handleSort };
};
