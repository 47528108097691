import React, { useCallback, useEffect, useState } from 'react';
import { useIsShowMLink } from '../../modals/linkToImage/hooks';
import { clearTempImage, fetchLoadTempFile } from '../reduser';
import { useDispatchApp } from '../../../redux/rootSelectors';
import {
  useClientImageInfo,
  useClientInfo,
} from '../model/selectors';
import { FetchedDataErrors } from '../../../types/general';

type TFUseUploadImage = (
  p?: boolean,
  imgData?: string,
  withSubmit?: (e?: React.FormEvent<HTMLFormElement> | undefined) => void,
  uploadHandler?: (url: string | null) => void
) => {
  upload: boolean;
  error: boolean;
  complete: boolean;
  imgUrl: string | null;
  onChangeFile: (v: React.FormEvent<HTMLInputElement>) => void;
  openMLinkCallback: () => void;
  tempImage: ReturnType<typeof useClientImageInfo>['data'];
  loadImg: string | undefined;
  errorImage: FetchedDataErrors;
  setLoadImg: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const useUploadImage: TFUseUploadImage = (remove_icon?, imgData?, withSubmit?, uploadHandler?) => {
  const dispatch = useDispatchApp();

  const openMLink = useIsShowMLink(true);

  const openMLinkCallback = useCallback(() => {
    openMLink();
  }, [openMLink]);

  const [imgUrl, setImgUrl] = useState<string | null>(null);
  const [upload, setUpload] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);

  const {
    data: tempImage,
    isLoading: isLoadingImage,
    error: errorImage,
  } = useClientImageInfo();
  const { data: clientPersonalInfoById } = useClientInfo();

  const [loadImg, setLoadImg] = useState<string | undefined>(undefined);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // Надо зарефакторить, компонент стал общим для офферов и клиентов
    // Внутри компонента импортируется инфа о клиенте
    // Данные по иконке офферов приходят снаружи
    const iconLoad = imgData || clientPersonalInfoById?.icon;
    const defaultIcon = imgData ? false : clientPersonalInfoById?.default_icon;
    if (remove_icon === true) {
      return setLoadImg(undefined);
    }
    if (typeof iconLoad === 'string' && defaultIcon === false) {
      setLoadImg(iconLoad);
    }
  }, [clientPersonalInfoById, remove_icon, imgData]);

  useEffect(() => {
    setUpload(isLoadingImage);
    setError(errorImage.isError);

    if (!upload) {
      setComplete(false);
    }

    if (tempImage !== null) {
      setImgUrl(tempImage.file_url);
      setLoadImg(tempImage.file_url);
      setComplete(true);
      return;
    }

    if (loadImg) {
      setImgUrl(loadImg);
      setError(false);
      return;
    }
    setImgUrl(null);
    setComplete(false);
  }, [tempImage, loadImg, errorImage.isError, isLoadingImage]);

  useEffect(() => {
    if(uploadHandler) {
      uploadHandler(imgUrl)
    }
  }, [imgUrl])

  const onChangeFile: ReturnType<TFUseUploadImage>['onChangeFile'] =
    useCallback((e) => {
      const fileList = e.currentTarget.files;
      if (fileList === null) {
        e.currentTarget.setAttribute('value', '');
        return;
      }
      if (fileList.length === 0) {
        e.currentTarget.setAttribute('value', '');

        return;
      }
      const file = fileList[0];
      dispatch(fetchLoadTempFile(file));
    }, []);

  useEffect(() => {
    if (withSubmit && tempImage) {
      withSubmit();
    }
  }, [tempImage]);

  useEffect(() => () => {
      dispatch(clearTempImage())
    }, [])
  

  return {
    upload,
    error,
    complete,
    imgUrl,
    onChangeFile,
    openMLinkCallback,
    tempImage,
    loadImg,
    errorImage,
    setLoadImg,
  };
};
