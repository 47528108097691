import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.scss';
import firebase from 'firebase';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { sagaMiddleware } from './redux/rootReducer';
import { rootSaga } from './redux/rootSaga';

const firebaseConfig = {
  apiKey: 'AIzaSyA4b_519jU60_ycN6QinHPV9Uj2JRh2WnM',
  authDomain: 'terratraf-td.firebaseapp.com',
  projectId: 'terratraf-td',
  storageBucket: 'terratraf-td.appspot.com',
  messagingSenderId: '419268518854',
  appId: '1:419268518854:web:959d9703da85f831dc88c7',
};

firebase.initializeApp(firebaseConfig);
sagaMiddleware.run(rootSaga);
ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
