import { SagaIterator } from 'redux-saga';
import { takeEvery, takeLatest } from '@redux-saga/core/effects';
import {
  fetchNotificationSubscriptionsSagaWorker,
  fetchNotificationMessagesSagaWorker,
  changeSubscripbeSagaWorker,
} from './workers';
import {
  editSubscriptionRequest,
  fetchNotificationMessageRequest,
  fetchNotificationSubscriptionsRequest,
} from '../reducer';

export default function* watcherNotification(): SagaIterator {
  yield takeEvery(
    fetchNotificationMessageRequest,
    fetchNotificationMessagesSagaWorker,
  );
  yield takeEvery(
    fetchNotificationSubscriptionsRequest,
    fetchNotificationSubscriptionsSagaWorker,
  );
  yield takeLatest(editSubscriptionRequest, changeSubscripbeSagaWorker);
}
