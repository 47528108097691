import Statistics from 'domains/campaigns/Item/Statistics';
import { ResponseStatisticsCampaigns } from 'domains/campaigns/types';
import React, { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IFilterColumns } from 'domains/campaigns/model/hooks/useGetFilterForCampaigns';
import cn from 'classnames';
import { ScrollSyncNode } from 'scroll-sync-react';
import css from '../../domains/campaigns/styles.module.scss';

interface Props {
  totalData?: ResponseStatisticsCampaigns | null;
  filterColumns: IFilterColumns[];
  sortCampaign: 'ASC' | 'DESC' | null;
  settings: any
}

const CampaignsTotal: FC<Props> = ({
  totalData,
  filterColumns,
  sortCampaign,
  settings,
}) => {
  const { t } = useTranslation();
  const totalContainersRef = useRef<HTMLDivElement>(null);
  /* При фильрации списка кампаний, принудительно скроллим влево значения таблицы */
  useEffect(() => {
    setTimeout(() => {
      if (totalContainersRef.current) {
        totalContainersRef.current.scrollLeft = 0;
      }
    }, 50);
  }, [sortCampaign, settings.period]);

  return (
    <>
      <div className={css.tabelHeaderLeft}>
        {t(`campaigns_page.campaigns_header_main.total`)}
      </div>
      <ScrollSyncNode group="a">
        <div
          className={cn(
            css.totalStats_values,
            `length-${filterColumns.length - 1}`,
          )}
          ref={totalContainersRef}
        >
          {filterColumns.map(
            (item, index) =>
              index > 0 && (
                <Statistics
                  data={[]}
                  onlyCurrent={false}
                  currentValue={totalData && totalData[item.data]}
                  key={item.data}
                  className={cn(`size-${item.lengthTitle}`, {
                    _isGrey: item.name.trim().toLocaleLowerCase() === 'bid',
                  })}
                />
              ),
          )}
        </div>
      </ScrollSyncNode>
    </>
  );
};

export default CampaignsTotal;
