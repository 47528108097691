import React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { FiX } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { useCreativeTypes } from 'domains/creative/model/selectors';
import { checkedLocalization } from 'utils/checkedLocalization';
import css from './styles.module.scss';
import { TID } from '../../types/general';
import ModalOverlay from '../Modals/ModalOverlay1';

interface IProps extends RouteComponentProps<TID> {
  onClose: () => void;
  isOpen: boolean;
}

const SelectCreativeTypeModal: React.FC<IProps> = ({
  onClose,
  isOpen,
  match,
}) => {
  const { t } = useTranslation();
  const { data: creativeTypes } = useCreativeTypes();
  return (
    <ModalOverlay onCloseModal={onClose} isOpen={isOpen}>
      <div className={css.card}>
        <div className={css.header}>
          <div className={css.title}>
            <h1>{t('creative.create_menu.title')}</h1>
          </div>
          <div className={css.close}>
            <FiX size={24} onClick={onClose} />
          </div>
        </div>
        <div className={css.content}>
          <div className={css.list}>
            {creativeTypes?.map(({ key, icon }) => (
              <Link
                className={css.item}
                key={key}
                to={{
                  pathname: `/main/campaign/${match.params.id}/creative-add`,
                  state: {
                    type: key,
                  },
                }}
              >
                {icon === '' ? (
                  <div className={css.plag_img} />
                ) : (
                  <img src={icon} alt="icon" />
                )}
                <span>
                  {' '}
                  {checkedLocalization(`creative.create_menu.${key}`, key)}
                </span>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </ModalOverlay>
  );
};

export default withRouter(SelectCreativeTypeModal);
