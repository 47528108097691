import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import ElementsNone from 'components/ElementsNone';
import Loader from '../../components/UI/Loader';
import Item from './Item';
import { useFetchContainersList } from './hooks';
import { ContainersState } from './reducer';
import { AppState } from '../../redux/rootReducer';
import './styles.scss';
import { ElementsNoneType } from '../../types/general';

const Containers: FC = () => {
  const { t } = useTranslation();

  const fetchContainersList = useFetchContainersList();

  const [toggle, setToggle] = useState<string>('');

  const tempContainersList = useSelector<
    AppState,
    ContainersState['tempContainersList']
  >((state) => state.containers.tempContainersList, shallowEqual);
  const containersList = tempContainersList.get('data');
  const isLoading = tempContainersList.get('isLoading');

  useEffect(() => {
    if (!containersList) {
      fetchContainersList();
    }
  }, [containersList]);

  const onToggle = (id: string) => {
    if (id === toggle) {
      return setToggle('');
    }

    return setToggle(id);
  };

  if (isLoading) {
    return (
      <div className="listings">
        <Helmet>
          <title>{t('containers_page.tit')}</title>
        </Helmet>

        <div className="listings__header">
          <div className="container">
            <h1 className="title">{t('containers_page.tit')}</h1>
          </div>
        </div>

        <div className="listings__table">
          <div className="container">
            <div className="listings__loader">
              <Loader />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="listings">
      <Helmet>
        <title>{t('containers_page.tit')}</title>
      </Helmet>

      <div className="listings__header">
        <div className="container">
          <h1 className="title">{t('containers_page.tit')}</h1>
        </div>
      </div>
      <div className="listings__table">
        <div className="container">
          {containersList && containersList.length > 0 ? (
            <div className="table">
              <div className="table__header">
                <div>{t('containers_page.name')}</div>
                <div>{t('containers_page.size_aut')}</div>
                <div>{t('containers_page.size_aut_day')}</div>
              </div>

              <div className="table__body">
                {containersList &&
                  containersList.map((item) => (
                    <Item
                      item={item}
                      key={item.id}
                      onSetToggle={onToggle}
                      isActive={toggle === item.id}
                    />
                  ))}
              </div>
            </div>
          ) : (
            <ElementsNone type={ElementsNoneType.CONTAINERS} />
          )}
        </div>
      </div>
    </div>
  );
};
export default Containers;
