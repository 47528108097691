import React, { useLayoutEffect, useRef, RefObject, ReactNode } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';

interface IProps {
  /** className */
  className?: string;
  /** title */
  title: ReactNode | string;
  /** children */
  children: ReactNode;
  /** style */
  style?: React.CSSProperties;
  /** событие сработает после закрытия открытия */
  onToggle?: (t: boolean, ref: RefObject<HTMLDivElement>) => void;
  /** закрыть открыть */
  toggle: boolean;
  /** событие клик */
  onClick: () => void;
}

const Dropdown: React.FC<IProps> = ({
  title,
  children,
  style,
  className,
  onToggle,
  toggle,
  onClick,
}) => {
  const { t } = useTranslation();

  const chaldReff = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (typeof onToggle === 'function') {
      onToggle(toggle, chaldReff);
    }
  }, [toggle]);
  return (
    <div className={cn(css.dropdown, className)} style={style}>
      <div className={css.title}>
        {title}
        <div className={css.spacer} />
        <div className={css.toggle} onClick={onClick}>
          <span>{toggle ? t('targetings.hide') : t('targetings.uncover')}</span>
        </div>
      </div>
      <div className={cn(css.body, { isToggle: toggle })} ref={chaldReff}>
        {toggle ? children : null}
      </div>
    </div>
  );
};

export default Dropdown;
