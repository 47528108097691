/* eslint-disable import/no-cycle */
import { useEffect, useState } from 'react';
import { useDispatchApp } from 'redux/rootSelectors';
import { Period } from 'types/general';
import { KeysSettings, useStateValue } from 'utils/context';
import { parsingDate } from 'utils/momentParse';
import { useBlDomains } from '../model/selectors';
import { ParamFieldList, TGetListDataItem } from '../types';
import {
  fetchBlDomains,
  fetchChangeStatusFields,
  setDefaultVal,
} from '../reducer';
import { ICheck } from '../ui';

interface IProps {
  file_id: number;
}

type TUseBlackListsDomains = ({ file_id }: IProps) => {
  blDomains: TGetListDataItem[];
  isLoading: boolean;
  loadData: () => void;
  setCount: React.Dispatch<React.SetStateAction<number>>;
  total: number;
  changeStatus: (checked_items: ICheck) => void;
  updateData: () => void;
  setPeriod: React.Dispatch<React.SetStateAction<Period>>;
  period: Period;
  setFileStatus: React.Dispatch<React.SetStateAction<number>>;
  setSource: React.Dispatch<React.SetStateAction<string[]>>;
  setPublisher: React.Dispatch<React.SetStateAction<string[]>>;
  setDomain: React.Dispatch<React.SetStateAction<string[]>>;
  setPartnerXxhash: React.Dispatch<React.SetStateAction<string[]>>;
  fileStatus: number;
  setNeedUpdate: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useBlackListsDomains: TUseBlackListsDomains = ({ file_id }) => {
  const dispatch = useDispatchApp();
  const { state } = useStateValue();
  const settings = state.settings[KeysSettings.BLACK_LIST];
  const { data, isLoading } = useBlDomains();
  const [blDomains, setBlDomains] = useState<TGetListDataItem[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [period, setPeriod] = useState<Period>({
    from: parsingDate(settings.period.from).format('YYYY-MM-DD'),
    to: parsingDate(settings.period.to).format('YYYY-MM-DD'),
  });
  const [fileStatus, setFileStatus] = useState<number>(1);
  const [source, setSource] = useState<string[]>([]);
  const [publisher, setPublisher] = useState<string[]>([]);
  const [domain, setDomain] = useState<string[]>([]);
  const [partnerXxhash, setPartnerXxhash] = useState<string[]>([]);
  const [needUpdate, setNeedUpdate] = useState<boolean>(false);

  const changeStatus = (checked_items: ICheck) => {
    const checkItemId = Object.keys(checked_items).filter(
      (ch) => checked_items[ch] === true,
    );
    const result: ParamFieldList = {};
    checkItemId.forEach((ch) => {
      const blDomain = blDomains.find((bl) => +bl.id === +ch);
      if (blDomain) {
        result[ch] = fileStatus === 1 ? 0 : 1;
      }
    });
    setBlDomains([]);
    
    dispatch(
      fetchChangeStatusFields({
        file_id,
        fields_list: result,
        filter: {
          limit: 100,
          offset: 0,
          status_in_file: fileStatus,
          publisher,
          domain,
          source,
          partner_xxhash: partnerXxhash,
          period,
        },
      }),
    );
    setCount((prev) => prev + 100);
  };

  const updateData = () => {
    const newFilter = {
      limit: 100,
      period,
      status_in_file: fileStatus,
      publisher,
      domain,
      source,
      partner_xxhash: partnerXxhash,
      offset: count,
    };
    setBlDomains([]);
    dispatch(fetchBlDomains({ file_id, filter: newFilter }));
  };

  const loadData = () => {
    setNeedUpdate(false);

    const filterObj = {
      limit: 100,
      offset: count,
      period,
      status_in_file: fileStatus,
      publisher,
      domain,
      source,
      partner_xxhash: partnerXxhash,
    };

    if (count <= total) {
      if (needUpdate) {
        setBlDomains([]);
      }
      dispatch(fetchBlDomains({ file_id, filter: filterObj }));
      setCount((prev) => prev + 100);
    }
  };

  useEffect(() => {
    loadData();
  }, [partnerXxhash, domain, publisher, period, fileStatus, source]);

  useEffect(() => {
    if (data) {
      const newBlDomains: TGetListDataItem[] = [];
      Object.keys(data).forEach((d) => {
        if (d !== 'total') {
          const index = blDomains.findIndex((el) => el.id === data[d].id);
          if (index >= 0) {
            blDomains[index] = data[d];
          } else {
            newBlDomains.push(data[d]);
          }
        }
      });
      setBlDomains([...blDomains, ...newBlDomains]);
      setTotal(+data.total);
    }
  }, [data]);

  useEffect(
    () => () => {
      dispatch(setDefaultVal());
      setBlDomains([]);
    },
    [],
  );

  return {
    blDomains,
    isLoading,
    loadData,
    setCount,
    total,
    changeStatus,
    updateData,
    setPeriod,
    period,
    setFileStatus,
    setSource,
    setPublisher,
    setDomain,
    setPartnerXxhash,
    fileStatus,
    setNeedUpdate,
  };
};
