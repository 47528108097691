import React from 'react';
import CreateAgencyModal from 'domains/agency/ui/modal';
import EditClientImageModal from '../client/create/modal';
import LinkToImage from '../modals/linkToImage';
import UploadBWListModal from '../bw_lists_update/modal';

export default function CurrentModals(): JSX.Element {
  return (
    <>
      <CreateAgencyModal />
      <EditClientImageModal />
      <LinkToImage />
      <UploadBWListModal />
    </>
  );
}
