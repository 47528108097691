import { Record } from 'immutable';

export type TMakeGenListObj<T> = {
  listView: T | [];
  isLoading: boolean;
  isBlocked: boolean;
};

export type TMakeGenList<T> = Record<TMakeGenListObj<T>>;

/**
 * функция генериррует объект для ленты, содержит поля
 * @listView -> массив объектов списка
 * @isLoading -> булевый лоадинг
 * @isBlocked -> блокировка подгрузки
 * */
export const makeGenList = <T>(list?: T): TMakeGenList<T> => {
  const init: TMakeGenListObj<T> = {
    listView: list ?? [],
    isLoading: false,
    isBlocked: false,
  };
  const DataObj: Record.Factory<TMakeGenListObj<T>> = Record(init);
  return new DataObj();
};

/**
 * деструкторизация объекта Списка ленты, возвращает
 * @listView -> массив объектов списка
 * @isLoading -> булевый лоадинг
 * @isBlocked -> блокировка подгрузки
 * */
export function extractMakeGenList<T>(
  obj: TMakeGenList<T>,
): TMakeGenListObj<T> {
  return {
    listView: obj.get('listView'),
    isLoading: obj.get('isLoading'),
    isBlocked: obj.get('isBlocked'),
  };
}
