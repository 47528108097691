export const chartColors = [
  "#8ee724",
  "#52adfa",
  "#0a61a4",
  "#c1f151",
  "#a08943",
  "#334929",
  "#f87d9b",
  "#69f2dd",
  "#c9cbee",
  "#5bb876",
  "#771196",
  "#146754",
  "#2965fb",
  "#a15cc9",
  "#a8023d",
  "#b3d7b7",
  "#7d936f",
  "#a57e0a",
  "#e610fe",
  "#1844b8",
  "#43c1ea",
  "#3d3260",
  "#0b0932",
  "#042331",
  "#f3992c",
  "#1bdbc0",
  "#a82ca3",
  "#4c2b49",
  "#376112",
  "#b5af5e"

];

export const checkedColors = [
  '#0a0a0a',
  '#E82C59',
  '#3B29A8',
  '#52C94F',
  '#DF51A6',
  '#F6AC55',
  '#A043E9',
  '#EEE061',
  '#6443E9',
  '#B5D62F',
  '#E244BF',
  '#DDB271',
  '#593D87',
  '#E7431F',
  '#251A50',
];