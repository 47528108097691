import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { fetchCreativesList } from 'domains/creative/reduser';
import { RNavigation } from 'types/general';
import { useDispatchApp } from 'redux/rootSelectors';
import {
  useCreativeDetailInfo,
  useCreativeListInfo,
} from 'domains/creative/model/selectors';
import { RGetPersonal } from 'domains/campaign/types';
import {
  CreativeDetail,
  CreativeItemListWithStatistics,
} from 'domains/creative/types';
import { RCampaignsArrayStata } from 'domains/campaigns/types';
import { FetchedDataObj } from 'redux/fetchedData';
import { useCampaignsInfo } from 'domains/campaigns/model/selectors';
import { useCampaignInfo } from 'domains/campaign/model/selectors';
import { fetchCampaigns } from 'domains/campaigns/reducer';
import { useStorageCampaignsTabs } from 'domains/storage/model/selectors';
import { useSearchBreadcrumbsInfo } from 'domains/search/model/selectors';

interface IArgs {
  mode: 'creo' | 'campaign',
  isCycle?: boolean,
}

export const useSwitchNavigation = ({mode, isCycle}: IArgs): RNavigation => {
  const dispatch = useDispatchApp();
  const location = useLocation();

  const { data: bread_crumbs } = useSearchBreadcrumbsInfo();

  const currentItemData: (FetchedDataObj<CreativeDetail>
    | FetchedDataObj<RGetPersonal>) = mode === 'creo' ? useCreativeDetailInfo() : useCampaignInfo()
  const currentListData: (FetchedDataObj<CreativeItemListWithStatistics[]>
    | FetchedDataObj<RCampaignsArrayStata>) = mode === 'creo' ? useCreativeListInfo() : useCampaignsInfo()

  const status = mode === 'campaign' ? useStorageCampaignsTabs() : null;

  const isGardCreoData = (
    param: CreativeDetail | RGetPersonal,
  ): param is CreativeDetail => true;

  const isGardCampaignData = (
    param: CreativeDetail | RGetPersonal,
  ): param is RGetPersonal => true;

  useEffect(() => {
    if (currentItemData.data && currentListData.data === null && bread_crumbs) {
      if (isGardCreoData(currentItemData.data) && currentItemData.data.campaign_xxhash) {
        dispatch(
            fetchCreativesList({ campaign_xxhash: currentItemData.data.campaign_xxhash }),
          );
      }
      if (isGardCampaignData(currentItemData.data) && status && bread_crumbs?.Client) {
        dispatch(
            fetchCampaigns({
              partner_xxhash: bread_crumbs.Client.xxhash,
              filters: {
                status: status ? currentItemData.data.status : status,
              },
            }),
          );
      }
    }
  }, [currentItemData.LTU, bread_crumbs]);

  const showNavigation = useMemo<boolean>(
    () => !!(currentListData.data && currentListData.data?.length > 1),
    [currentListData.LTU, currentItemData.LTU],
  );

  const isDisableLink = useMemo<RNavigation['isDisableLink']>(() => {
    const listLength = currentListData.data?.length
    const isDisable = {
      next: false,
      prev: false,
    }
    if (showNavigation) {
      const itemIndex = currentListData.data?.findIndex(
        (item) => item.xxhash === currentItemData.data?.xxhash,
      );
      if (itemIndex === 0) {
        isDisable.prev = true;
      }
      if (listLength && itemIndex === listLength - 1) {
        isDisable.next = true;
      }
    }
    return isDisable;
  }, [currentListData.isLoading, currentItemData.isLoading, currentListData.LTU]);

  const prevLink = useMemo<string>(() => {
    if (showNavigation && currentListData) {
      const dataListLength = currentListData.data?.length || 0;
      const itemIndex =
        currentListData.data &&
        currentListData.data.findIndex((item) => item.xxhash === currentItemData.data?.xxhash);
      if (itemIndex !== null && itemIndex !== -1) {
        let prevIndex = itemIndex - 1;
        if (prevIndex < 0 && isCycle) {
          prevIndex = dataListLength - 1;
        }
        const prevItemID = currentListData.data && currentListData.data[prevIndex];
        const arr = location.pathname.split('/');
        if (mode === 'creo') {
            arr[5] = prevItemID ? prevItemID.xxhash : '';
        }
        if (mode === 'campaign') {
            arr[3] = prevItemID ? prevItemID.xxhash : '';
        } 
        return arr.join('/');
      }
    }
    return '';
  }, [currentListData.LTU, currentItemData.LTU, location]);

  const nextLink = useMemo<string>(() => {
    if (showNavigation && currentListData) {
      const dataListLength = currentListData.data?.length || 0;
      const itemIndex =
        currentListData.data &&
        currentListData.data?.findIndex((item) => item.xxhash === currentItemData.data?.xxhash);
      if (itemIndex !== null && itemIndex !== -1) {
        let nextIndex = itemIndex + 1;
        if (nextIndex > dataListLength - 1 && isCycle) {
          nextIndex = 0;
        }
        const nextItemID = currentListData.data && currentListData.data[nextIndex];
        const arr = location.pathname.split('/');
        if (mode === 'creo') {
            arr[5] = nextItemID ? nextItemID.xxhash : '';
        }
        if (mode === 'campaign') {
            arr[3] = nextItemID ? nextItemID.xxhash : '';
        }        
        return arr.join('/');
      }
    }
    return '';
  }, [currentListData.LTU, currentItemData.LTU, location]);

  return {
    count: currentListData.data?.length ?? 0,
    showNavigation,
    prevLink,
    nextLink,
    isLoading: currentItemData.isLoading,
    isDisableLink,
  };
};
