import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import cn from 'classnames';
import { useUserTheme } from 'domains/user/model/selectors';
import Tooltip from 'components/UI/Tooltip';
import { FiRefreshCcw } from 'react-icons/fi';
import { useCurrency } from 'domains/project/hooks';
import css from './styles.module.scss';
import { useReportByID } from '../../hooks/useReportByID';
import NavigationHeader from '../../../../components/NavigationHeader';
import Breadcrumbs from '../../../../components/Breadcrumbs/breadcrumbs';
import DateRangeFilter from '../../../../components/DateRangeFilter';
import Loader from '../../../../components/UI/Loader';
import ErrorReport from '../../../../components/Errors/Reports';
import CardsList from '../../../../components/UI/CardList';
import General from './General';
import { getGenerateNamingItem } from './getNamingItem';
import { setDefChartItemsGenDefault } from '../../reduser';
import { useDispatchApp } from '../../../../redux/rootSelectors';

const Report: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatchApp();

  useEffect(
    () => () => {
      dispatch(setDefChartItemsGenDefault(['show']));
    },
    [],
  );

  const [fixed, setFixed] = React.useState<boolean>(false);
  const pageScrollHandler = ({ target }: Event) => {
    const scroll = target as HTMLDocument;

    if (scroll.scrollingElement && scroll.scrollingElement.scrollTop < 156) {
      return setFixed(false);
    }

    return setFixed(true);
  };

  useEffect(() => {
    document.addEventListener('scroll', pageScrollHandler);

    return () => document.removeEventListener('scroll', pageScrollHandler);
  }, []);

  const {
    errorCampaign,
    title,
    id,
    campaign,
    initialTitleDay,
    range,
    dateEnd,
    dateStart,
    handlerDateFilter,
    isLoading,
    error,
    isDateReport,
    hideNavigation,
    report,
    typeReport,
    getType,
    requestParams,
    statisticsIntegration,
    oneIntegrationKey,
    genIntegrationNaming,
    defChartItemsGen,
  } = useReportByID();

  const { isThemeExtended, isThemeFull } = useUserTheme();
  const { currency } = useCurrency();

  // eslint-disable-next-line consistent-return
  const renderReport = () => {
    // флаг показывает или скрывает actions
    const isGaSessions =
      !!report?.statistics &&
      Object.keys(report?.statistics[0] ?? {}).some((e) => e.includes('ga'));
    if (report) {
      if (typeReport === 'geo_city' || typeReport === 'shownum') {
        return (
          <General
            report={report}
            defChartItems={defChartItemsGen}
            namingItem={getGenerateNamingItem({
              priceType: getType,
              typeReport,
              isGaSessions,
              total: report.total,
              currency,
           } )}
            fixedRowT={[
              { ...report.total, main_field: `${t('charts.total')}` },
              {
                ...report.other,
                main_field: `${t('common.reports.general.t8')}`,
              },
            ]}
            requestParams={requestParams}
            statistics={report.statistics}
          />
        );
      }
      if (typeReport === 'integration') {
        return (
          <General
            report={report}
            defChartItems={['impression', 'view', 'click']}
            namingItem={genIntegrationNaming}
            fixedRowT={[
              { ...report.total, [oneIntegrationKey]: `${t('charts.total')}` },
            ]}
            requestParams={requestParams}
            statistics={statisticsIntegration}
            chartDataP={report.statisticsIntegration?.date}
            isUtmTags
          />
        );
      }

      return (
        <General
          chartDataP={report.statistics}
          statistics={report.statistics}
          requestParams={requestParams}
          fixedRowT={[{ ...report.total, date: `${t('charts.total')}` }]}
          defChartItems={defChartItemsGen}
          report={report}
          namingItem={getGenerateNamingItem({
            priceType: getType,
            typeReport,
            isGaSessions,
            total: report.total,
            currency,
          })}
        />
      );
    }
  };

  return (
    <div className={css.detail}>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {!hideNavigation && (
        <NavigationHeader
          back={{
            title: t('campaigns_page.campaign_settings.reports.back'),
            to: `/main/campaign/${id}/reports`,
          }}
        />
      )}
      <div className={css.wrapper}>
        <div className={cn(css.header, { isFixed: fixed })}>
          <div
            className={cn('container resized', {
              extend: isThemeExtended,
              full: isThemeFull,
            })}
          >
            {campaign && (
              <Breadcrumbs xxhash={campaign.xxhash} type="Campaign" />
            )}
            <div className={css.status}>
              <h1 className={css.title}>{title}</h1>
              <div className={css.actions}>
                <div className={css.update} onClick={() => handlerDateFilter(range, initialTitleDay)}>
                  <Tooltip title={<FiRefreshCcw size={22} />}>
                    {t(`update_btn`)}
                  </Tooltip>
                </div>
                {campaign && (
                  <DateRangeFilter
                    range={range}
                    dateStart={dateStart}
                    dateEnd={dateEnd}
                    initialTitleDay={initialTitleDay}
                    onSetDateRange={handlerDateFilter}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!errorCampaign.isError && isLoading && <Loader />}
      {errorCampaign.isError && <ErrorReport type="404" />}
      {!isLoading && (error || !isDateReport) && !errorCampaign.isError && (
        <ErrorReport type="Reports" />
      )}
      {!isLoading && isDateReport && !errorCampaign.isError && (
        <CardsList>{renderReport()}</CardsList>
      )}
    </div>
  );
};
export default Report;
