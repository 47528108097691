import React, { FC, useEffect } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import cn from 'classnames';
import BlackHeader from 'components/BlackHeader';
import ScrollToTopButton from 'components/ScrollToTopButton';
import { useStateValue } from 'utils/context';
import { useTranslation } from 'react-i18next';
import Loader from 'components/UI/Loader';
import Users from 'domains/users/ui';
import HistoryChanges from 'domains/historyChanges/ui';
import Analysis from 'domains/Analysis/ui';
import Agency from 'domains/partners';
import Project from 'domains/project';
import { fetchPersonalInfo } from 'domains/user/actions';
import Error404 from 'domains/app/Errors/Error404';
import NC from 'domains/app/Errors/NoConnection';
import ProjectSettings from 'domains/project/ui/Settings';
import ProjectAdditional from 'domains/project/ui/Additional';
import UsersAPI from 'domains/usersAPI/ui';
import CreateUserAPI from 'domains/usersAPI/ui/Create';
import EditUserAPI from 'domains/usersAPI/ui/Edit';
import BlackLists from 'domains/blackLists/ui';
import BlackList from 'domains/blackList/ui';
import NoAccess from 'domains/app/Errors/noAccess';
import css from './styles.module.scss';
import { everyGroups } from '../../utils/statics/everyGroups';
import SearchByIdPopup from '../../components/SearchByIdPopup';
import { useUserInfo } from '../../domains/user/model/selectors';
import { useDispatchApp } from '../../redux/rootSelectors';
import { setIsShowModal } from '../../domains/search/model/reducer';

const ControlPanel: FC = () => {
  const match = useRouteMatch();

  const dispatch = useDispatchApp();
  const { t } = useTranslation();
  useEffect(() => {
    const viewModeHide = localStorage.getItem('$viewModeHide');
    const Text = () => (
      <div>
        {t('alerts.view_mode')}
        <br />
        {t('alerts.view_mode_cont')}
      </div>
    );

    if (!viewModeHide) {
      toast(<Text />, {
        type: 'info',
        delay: 500,
        autoClose: 5000,
        onClose: () => {
          localStorage.setItem('$viewModeHide', 'true');
        },
      });
    }

    if (user === null) {
      dispatch(fetchPersonalInfo());
    }

    return () => localStorage.setItem('$viewModeHide', 'true');
  }, []);

  document.addEventListener('keydown', (event) => {
    if (event.code === 'KeyK' && (event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      dispatch(setIsShowModal(true));
    }
  });

  const { state } = useStateValue();
  const { data: user, isLoading } = useUserInfo();

  return (
    <div className={cn(css.wrapper, { extend: state.theme.containerToggle })}>
      {isLoading && <Loader />}
      {!isLoading && user && (
        <>
          <BlackHeader />
          <SearchByIdPopup />
          <Switch>
            {everyGroups(user, 'manager') && (
              <Route exact path={`${match.path}/users`} component={Users} />
            )}
            {everyGroups(user, 'manager', 'owner') && (
              <Route
                exact
                path={`${match.path}/users_api`}
                component={UsersAPI}
              />
            )}
            {everyGroups(user, 'manager', 'owner') && (
              <Route
                exact
                path={`${match.path}/users_api/create`}
                component={CreateUserAPI}
              />
            )}
            {everyGroups(user, 'manager', 'owner') && (
              <Route
                exact
                path={`${match.path}/users_api/edit`}
                component={EditUserAPI}
              />
            )}
            {everyGroups(user, 'manager') && (
              <Route exact path={`${match.path}/partners`} component={Agency} />
            )}

            {everyGroups(user, 'manager', 'owner') && (
              <Route exact path={`${match.path}/project`} component={Project} />
            )}
            {everyGroups(user, 'manager', 'owner') && (
              <Route
                exact
                path={`${match.path}/project/settings`}
                component={ProjectSettings}
              />
            )}
            {everyGroups(user, 'manager', 'owner') && (
              <Route
                exact
                path={`${match.path}/project/additional`}
                component={ProjectAdditional}
              />
            )}
            {everyGroups(user, 'manager') && (
              <Route
                exact
                path={`${match.path}/history_changes`}
                component={HistoryChanges}
              />
            )}
            {everyGroups(user, 'manager', 'owner') && (
              <Route
                exact
                path={`${match.path}/analysis`}
                component={Analysis}
              />
            )}
            <Route
              exact
              path={`${match.path}/blackLists`}
              component={
                everyGroups(user, 'manager', 'owner') ? BlackLists : NoAccess
              }
            />
            <Route
              exact
              path={`${match.path}/blackLists/:id`}
              component={
                everyGroups(user, 'manager', 'owner') ? BlackList : NoAccess
              }
            />
            <Redirect exact from="/control_panel/" to="users" />
            <Route path={`${match.path}/error/nc`} component={NC} />
            <Route component={Error404} />
          </Switch>
          <ScrollToTopButton />
        </>
      )}
    </div>
  );
};

export default ControlPanel;
