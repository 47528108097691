/* eslint-disable react/no-array-index-key */
import React, { FC, useState } from 'react';
import cn from 'classnames';
import { useUserTheme } from 'domains/user/model/selectors';
import { ReactSVG } from 'react-svg';
import TableFilter from 'components/UI/TableFilter';
import { useOnScroll } from 'hooks/useOnScroll';
import css from './styles.module.scss';
import emptySvg from '../../../assets/errors/empty-analysis.svg';
import Header from './Header';
import Filters from './Filters';
import LoadingTable from './Loading/LoadingTable';
import { useCreoList } from '../model/hooks/useCreoList';
import { useGetCreoReport } from '../model/hooks/useGetCreoReport';
import i18n from '../../../i18n';

const Analysis: FC = () => {
  const { isThemeExtended, isThemeFull } = useUserTheme();
  const { fixed } = useOnScroll(600);
  const [openFilers, setOpenFilters] = useState<boolean>(false);

  const {
    creativeListView,
    currentColumn,
    currentAdditionalColumn,
    currentSort,
    currentTarget,
    isLoadingCreativeList,
    creativeList,
    headerTitles,
    handleSort,
    checkCurrentColumn,
    setCurrentTarget,
  } = useCreoList();

  const { onGetReport, disGetReport } = useGetCreoReport(
    headerTitles,
    creativeListView,
    currentTarget,
  );

  return (
    <div className={css.content}>
      <Header
        fixed={fixed}
        setOpenFilters={setOpenFilters}
        openFilers={openFilers}
        downloadHandler={() => {
          if (!disGetReport) onGetReport();
        }}
      />
      <div
        className={cn('container resized', {
          extend: isThemeExtended,
          full: isThemeFull,
        })}
      >
        <Filters
          openFilers={openFilers}
          setOpenFilters={setOpenFilters}
          setCurrentTarget={setCurrentTarget}
          onGetReport={onGetReport}
          disGetReport={disGetReport}
          fixed={fixed}
          currentTarget={currentTarget}
          checkCurrentColumn={checkCurrentColumn}
        />
        {(creativeList !== null || isLoadingCreativeList) && (
          <div className={css.body}>
            {creativeListView?.length === 0 && !isLoadingCreativeList ? (
              <div className={css.empty}>
                <ReactSVG src={emptySvg} className="icon" />
                <div className={css.empty_text}>
                  <strong>{i18n.t(`search_none.none_1`)}</strong>
                  <span>{i18n.t(`search_none.none_3`)}</span>
                </div>
              </div>
            ) : (
              <div className={css.table}>
                {isLoadingCreativeList ? (
                  <LoadingTable extend={isThemeExtended} full={isThemeFull} />
                ) : (
                  <>
                    <div
                      className={cn(css.table_header_plag, {
                        _isFixedScroll: fixed,
                      })}
                    />
                    <div
                      className={cn(css.table_header, {
                        _isFixed: fixed,
                      })}
                    >
                      <div
                        className={cn(css.wrapper, {
                          container: fixed,
                          resized: fixed,
                          extend: isThemeExtended,
                          full: isThemeFull,
                        })}
                      >
                        {headerTitles.map((item) => (
                          <TableFilter
                            key={item.title}
                            data={item.data}
                            title={item.title}
                            activeKey={currentColumn}
                            sort={currentSort}
                            handleSort={handleSort}
                            isShow
                          />
                        ))}
                      </div>
                    </div>
                    <div className={css.table_body}>
                      {creativeListView?.map((item, index) => (
                        <div
                          className={css.table_row}
                          key={item.title + item.internal_id + index}
                        >
                          <div title={item.campaign.title}>
                            {item.campaign.title}
                          </div>
                          <div title={item.campaign.internal_id}>
                            {item.campaign.internal_id}
                          </div>
                          <div title={item.title}>{item.title}</div>
                          <div title={item.internal_id}>{item.internal_id}</div>
                          <div
                            title={
                              item.targets[currentAdditionalColumn] &&
                              Object.keys(
                                item.targets[currentAdditionalColumn],
                              )[0]
                            }
                          >
                            {item.targets[currentAdditionalColumn] &&
                              Object.keys(
                                item.targets[currentAdditionalColumn],
                              )[0]}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Analysis;
