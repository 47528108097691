import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useStorageCampaignsTabs } from 'domains/storage/model/selectors';
import { useHistory, useLocation } from 'react-router';
import Button from '../UI/Buttons/buttons';
import css from './styles.module.scss';
import AccessUserRight from '../AccessUserRight';
import { ElementsNoneImages } from './Images';

type TСampaignsNoneProps = {
  /* От этого параметра меняетсятся текст */
  search?: boolean | null;
  /* Фильтры перед списком кампаний */
  campaignFilters?: React.ReactNode;
};

const СampaignsNone: FC<TСampaignsNoneProps> = ({
  search,
  campaignFilters,
}) => {
  const { t } = useTranslation();
  const status = useStorageCampaignsTabs();
  const location = useLocation();
  const history = useHistory();

  const createCampaigns = () =>
    history.push({
      pathname: `${location.pathname}/create-campaign`,
      search: location.search,
    });

  return (
    <>
      {campaignFilters && (
        <div className="container resized">
          <div className={css.campaignFilters}>{campaignFilters}</div>
        </div>
      )}
      <div className={css.empty}>
        <div className={css.img}>
          {search ? (
            <img
              src={ElementsNoneImages.no_search}
              alt={ElementsNoneImages.no_search}
            />
          ) : (
            <>
              {status === 'LAUNCHED' && (
                <img
                  src={ElementsNoneImages.campaign_empty_active}
                  alt={ElementsNoneImages.campaign_empty_active}
                />
              )}
              {status === 'STOPPED' && (
                <img
                  src={ElementsNoneImages.campaign_empty_stop}
                  alt={ElementsNoneImages.campaign_empty_stop}
                />
              )}
              {status === 'ARCHIVE' && (
                <img
                  src={ElementsNoneImages.campaign_empty_arhiv}
                  alt={ElementsNoneImages.campaign_empty_arhiv}
                />
              )}
              {status === 'DELETED' && (
                <img
                  src={ElementsNoneImages.campaign_empty_del}
                  alt={ElementsNoneImages.campaign_empty_del}
                />
              )}
              {status === '' && (
                <img
                  src={ElementsNoneImages.campaign_empty_all}
                  alt={ElementsNoneImages.campaign_empty_all}
                />
              )}
            </>
          )}
        </div>
        {search ? (
          <div className={css.search}>
            <div className={css.header}>{`${t('search_none.none_1')}`}</div>
            <div className={css.text}>
              <span>{`${t('search_none.none_2')}`}</span>{' '}
              <span>{`${t('search_none.none_3')}`}</span>
            </div>
          </div>
        ) : (
          <div className={css.title}>
            {status === 'LAUNCHED' && t('campaigns_page.empty.act')}
            {status === 'STOPPED' && t('campaigns_page.empty.no3')}
            {status === 'ARCHIVE' && t('campaigns_page.empty.no5')}
            {status === 'DELETED' && t('campaigns_page.empty.no4')}
            {status === '' && (
              <>
                <div>{t('campaigns_page.empty.no1')}</div>
                <span
                  style={{
                    fontWeight: 400,
                  }}
                >
                  {t('campaigns_page.empty.no2')}
                </span>
                <div className={css.actions}>
                  <AccessUserRight>
                    {(props) => (
                      <Button
                        title={t('campaigns_page.create_btn')}
                        buttonAttributes={{
                          className: css.link,
                          onClick: createCampaigns,
                          disabled: props.isDisable,
                        }}
                      />
                    )}
                  </AccessUserRight>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default СampaignsNone;
