/* eslint-disable import/no-cycle */
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClientsList } from 'domains/clients/types';
import NewSelect from 'components/UI/NewSelect';
import { Field, FieldProps, FormikProvider, useFormik } from 'formik';
import { TFormikInitialVal } from 'domains/blackList/types';
import { ISelectOption } from '../Filter';
import Option from './components/Option';
import LimitedChipsContainer from './components/LimitedChipsContainer';
import css from './styles.module.scss';

interface IProps {
  clear: boolean;
  clientsList: ClientsList | null;
  setPartners: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedOption: React.Dispatch<React.SetStateAction<ISelectOption[]>>;
}

const PartnersSelect: FC<IProps> = ({
  clear,
  clientsList,
  setPartners,
  setSelectedOption,
}) => {
  const { t } = useTranslation();
  const [parthnersOptions, setParthnersOptions] = useState<ISelectOption[]>([]);

  useEffect(() => {
    if (clientsList) {
      const arr: ISelectOption[] = [];
      Object.values(clientsList).forEach((client) => {
        const obj: ISelectOption = { label: '', value: '', partner_xxhash: '' };
        Object.keys(client).forEach((c) => {
          if (c === 'title') {
            obj.label = client[c];
          }
          if (c === 'internal_id') {
            obj.value = client[c];
          }
          if (c === 'id') {
            obj.partner_xxhash = client[c];
          }
        });
        if (obj.label && obj.value) {
          arr.push(obj);
        }
      });
      setParthnersOptions(arr);
    }
  }, [clientsList]);

  const formik = useFormik<TFormikInitialVal>({
    initialValues: {
      partners: '',
    },
    onSubmit: (values) => {
      console.log(values, 'values');
    },
  });

  const parthnersValue = useMemo<ISelectOption[]>(() => {
    let data: ISelectOption[] = [];
    let partner_xxhashes:string[] = [];
    
    if (!parthnersOptions || !formik.values.partners) {
      return data;
    }
    if (clear) {
      setSelectedOption([]);
      setPartners([]);
      formik.resetForm();
      return data;
    }
    data = parthnersOptions.filter((e) =>
      formik.values.partners.split(',').includes(e.value),
    );

    partner_xxhashes = data.map(item=>item.partner_xxhash);

    setPartners(partner_xxhashes);
    setSelectedOption(data);

    return data;
  }, [formik.values, parthnersOptions, clear]);

  return (
    <div className={css.partner_select}>
      <FormikProvider value={formik}>
        <Field name="partners">
          {({ form, field }: FieldProps) => (
            <NewSelect
              field={field}
              form={form}
              customOptions={{
                Option,
                ValueContainer: LimitedChipsContainer,
              }}
              selectAttributes={{
                isMulti: true,
                placeholder: t(`users_page.settings_modal.parthner_title`),
                isClearable: false,
                options: parthnersOptions,
                closeMenuOnScroll: () => false,
                value: parthnersValue,
              }}
              viewStyle="Vertical"
            />
          )}
        </Field>
      </FormikProvider>
    </div>
  );
};

export default PartnersSelect;
