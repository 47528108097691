import request from '../../lib/helpers/requestApi';
import {
  PAddClient,
  PeditClient,
  PEditGroup,
  PgetClient,
  PGroupList,
  PSetGroup,
  RAddClient,
  ResponseInfoClient,
  TGroupList,
  PRemoveGroups,
} from './types';

export const addClient = request<RAddClient, PAddClient>({
  method: 'partner.addClient',
});

export const getClient = request<ResponseInfoClient, PgetClient>({
  method: 'partner.getInfo',
});

export const editClient = request<boolean, PeditClient>({
  method: 'partner.editClient',
});

export const setGroup = request<boolean, PSetGroup>({
  method: 'partner.setGroup',
  showError: false,
});

export const getGroupList = request<TGroupList, PGroupList>({
  method: 'partner.getGroupList',
});

export const editGroup = request<boolean, PEditGroup>({
  method: 'partner.editGroup',
  showError: false,
});

export const removeGroup = request<boolean, PRemoveGroups>({
  method: 'partner.removeGroups',
});
