import React, { FC, useState } from 'react';
import cn from 'classnames';
import Loader from 'components/UI/Loader';
import { ReactSVG } from 'react-svg';
import BalanceModal from 'components/BalanceModal';
import { useLocation } from 'react-router';
import { someGroups } from 'utils/statics/everyGroups';
import { useUserInfo } from 'domains/user/model/selectors';
import css from './styles.module.scss';
import Tooltip from '../UI/Tooltip';
import updateSVG from './icons/update.svg';
import { ReactComponent as WalletSvg } from '../../assets/wallet.svg';

type BalanceProps = {
  classname?: string;
  white?: boolean;
  transparent?: boolean;
  value: null | number;
  currency: null | string;
  name?: string;
  isLoading: boolean;
  fetcher?: () => void;
  isTooltip?: boolean;
  revert?: boolean;
};

const Balance: FC<BalanceProps> = ({
  classname,
  white,
  transparent,
  value,
  fetcher,
  currency,
  isLoading,
  name,
  isTooltip = false,
  revert,
}) => {
  const [modal, setModal] = useState(false);
  const { data: userInfo } = useUserInfo();
  const location = useLocation();

  const isClientsList = location.pathname === '/main/clients';
  const isAccount = someGroups(userInfo, 'account');

  const openModalClickHandler = () => {
    if (isClientsList && isAccount) {
      setModal(true);
    }
  };

  return (
    <>
      <div
        className={cn(css.balance, classname, {
          _bg: value !== null,
          _isRedText: Number(value) < 0,
          _isWhite: white,
          _isTransparent: transparent,
          _isRevert: revert,
          _isEmpty: !isLoading && value === null,
        })}
      >
        {isLoading ? (
          <>
            <div className={css.loader}>
              <div className={cn(css.loaderItem, css.item1)} />
              <div className={cn(css.loaderItem, css.item2)} />
              <div className={cn(css.loaderItem, css.item3)} />
              <div className={cn(css.loaderItem, css.item4)} />
              <div className={cn(css.loaderItem, css.item5)} />
              <div className={cn(css.loaderItem, css.item6)} />
              <div className={cn(css.loaderItem, css.item7)} />
            </div>
            <Loader
              transparent
              white={!white}
              grey={white}
              style={{
                width: '20px',
                height: '20px',
              }}
            />
          </>
        ) : (
          <>
            {value !== null ? (
              <>
                {isTooltip ? (
                  <Tooltip
                    className={css.tooltip}
                    classNameHint={css.tooltipHint}
                    bottom
                    white
                    onClick={openModalClickHandler}
                    title={
                      <div className={cn(css.balanceValue, 'balanceValue')}>
                        {isClientsList && isAccount && <WalletSvg />}
                        <div>
                          {String(value).replace(
                            /(\d)(?=(\d\d\d)+([^\d]|$))/g,
                            '$1 ',
                          )}{' '}
                          {currency}
                        </div>
                      </div>
                    }
                  >
                    <p>{name}</p>
                  </Tooltip>
                ) : (
                  <div className={cn(css.balanceValue, 'balanceValue')}>
                    {String(value).replace(
                      /(\d)(?=(\d\d\d)+([^\d]|$))/g,
                      '$1 ',
                    )}{' '}
                    {currency}
                  </div>
                )}
                {fetcher && (
                  <ReactSVG
                    className={css.balanceUpdate}
                    onClick={fetcher}
                    src={updateSVG}
                  />
                )}
              </>
            ) : (
              '-'
            )}
          </>
        )}
      </div>
      {modal && <BalanceModal onClose={() => setModal(false)} />}
    </>
  );
};

export default Balance;
