import React, { FC, useState } from 'react';
import cn from 'classnames';
import { Form, FormikProvider } from 'formik';
import { MdInfoOutline } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import Loader from 'components/UI/Loader';
import Button from 'components/UI/Buttons/buttons';
import Card from 'components/UI/Card';
import CardList from 'components/UI/CardList';
import HintWidget from '../../HintWidget';
import VastImg from './img/video_vast.svg';
import ClickunderImg from './img/clickunder.svg';
import css from './styles.module.scss';
import { useCreativeAdd } from '../hooks/useCreativeAdd';
import { isEmptyObject } from '../../../../../utils/isEmptyObject';
import MainForm from './Forms/Main';
import Additionally from './Forms/Additionally';
import Type from './Forms/Type';
import CollapseWrapper from '../../../../../components/CollapseWrapper';

const Settings: FC = () => {
  const { t } = useTranslation();
  const [isDisableSaveButton, setIsDisableSaveButton] = useState(false);

  const {
    handlerCopyHtml,
    isLoadingResponse,
    error,
    isLoading,
    location,
    formik,
    goBack,
    violence,
    selectDefault,
    sex,
    algorithmsSelect,
    defaultSizeOption,
    sizeOption,
    tags,
    updateTags,
    lState,
    helper,
  } = useCreativeAdd();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={css['create-campaign']}>
      <div className={css.list}>
        <div className={cn(css.container, 'container')}>
          <CardList style={{ width: '100%' }}>
            <FormikProvider value={formik}>
              <Form
                onKeyDown={(keyboardEvent) => {
                  const { charCode, keyCode } = keyboardEvent;
                  if ((charCode || keyCode) === 13) {
                    keyboardEvent.preventDefault();
                  }
                }}
              >
                <MainForm
                  formik={formik}
                  tags={tags}
                  setTags={updateTags}
                />

                <Type
                  defaultSizeOption={defaultSizeOption}
                  sizeOption={sizeOption}
                  formik={formik}
                  error={error}
                  helper={helper}
                  handlerCopyHtml={handlerCopyHtml}
                  setIsDisableSaveButton={setIsDisableSaveButton}
                />

                <Additionally
                  formik={formik}
                  sex={sex}
                  helper={helper}
                  algorithmsSelect={algorithmsSelect}
                  violence={violence}
                  selectDefault={selectDefault}
                />

                <div className={css.actions}>
                  <Button
                    title={t('cancel_btn')}
                    autoWidth
                    height={40}
                    transparent
                    buttonAttributes={{
                      onClick: () => {
                        goBack();
                      },
                    }}
                  />
                  <Button
                    title={t('creative.create_btn')}
                    height={40}
                    buttonAttributes={{
                      disabled:
                        isLoadingResponse || !isEmptyObject(formik.errors) || isDisableSaveButton,
                      type: 'submit',
                    }}
                  />
                </div>
              </Form>
            </FormikProvider>
          </CardList>
          <CardList>
            <div>
              {lState.type !== 'CLICKUNDER' && (
                <CollapseWrapper
                  title={t('creative.create_creative.Creative_preview')}
                  nameWrapper="CREATIVE_PREVIEW"
                >
                  <Card noHover className={cn(css.widget, '_isExpend')}>
                    <div className={css.actions}>
                      <Button
                        title={t('creative.create_creative.Preview')}
                        buttonAttributes={{
                          style: {
                            height: 40,
                          },
                          disabled: true,
                        }}
                      />
                    </div>
                    {lState && lState.type !== 'TEASER' && (
                      <div className={css.hint}>
                        <MdInfoOutline size="24" color="#0a0a0a" />
                        <p>
                          {location.state &&
                            lState.type === 'HTML' &&
                            t('creative.creasmall_set.type_html_hint')}
                          {lState &&
                            lState.type === 'PUSH' &&
                            t('creative.creasmall_set.type_push_hint')}
                          {lState &&
                            lState.type === 'YANDEX' &&
                            t('creative.creasmall_set.type_yandex_hint')}
                          {lState &&
                            lState.type === 'VIDEO_INLINE' &&
                            t('creative.creasmall_set.type_video_hint')}
                          {lState &&
                            lState.type === 'VIDEO_VAST' &&
                            t('creative.creasmall_set.type_video_vast_hint')}
                          {lState &&
                            lState.type === 'MULTI_FORMAT' &&
                            t('creative.creasmall_set.type_multiformat_hint')}
                          {lState &&
                            lState.type === 'COMPANION' &&
                            t('creative.creasmall_set.type_companion_hint')}
                        </p>
                      </div>
                    )}
                  </Card>
                </CollapseWrapper>
              )}

              {lState && lState.type === 'HTML' && (
                <HintWidget>
                  <p>{t('creative.create_creative.This_format')}</p>
                  <ul>
                    <li>{t('creative.create_creative.Upload_zip')}</li>
                    <li>{t('clients_page.client_create.client_load_img')}</li>
                    <li>{t('creative.create_creative.Import_html')}</li>
                  </ul>
                </HintWidget>
              )}
              {lState && lState.type === 'VIDEO_VAST' && (
                <HintWidget noTopLine>
                  <div className="image">
                    <img
                      src={VastImg}
                      alt={t('creative.vast.create_hint.title')}
                    />
                  </div>
                  <h4>{t('creative.vast.create_hint.title')}</h4>
                  <p>{t('creative.vast.create_hint.text')}</p>
                </HintWidget>
              )}
              {lState && lState.type === 'CLICKUNDER' && (
                <HintWidget noTopLine>
                  <div className="image">
                    <img
                      src={ClickunderImg}
                      alt={t('creative.vast.create_hint.title')}
                    />
                  </div>
                  <h4>{t('creative.clickunder.create_hint.title')}</h4>
                  <p>{t('creative.clickunder.create_hint.text')}</p>
                </HintWidget>
              )}
            </div>
          </CardList>
        </div>
      </div>
    </div>
  );
};

export default Settings;
