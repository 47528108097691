import { getSizes } from 'domains/uploads/helpers';
import UploadImage from 'domains/uploads/UploadImage';
import { Field, FieldProps } from 'formik';
import React, { FC } from 'react';
import Input from 'components/UI/Input/input';
import { optionsButtonCreativeMulti } from 'domains/creative/model/shared';
import { isGardMultiFormatType } from 'domains/creative/creatives/hooks';
import { IUseTypeProp } from 'domains/creative/creatives/creativeAdd/hooks/useType';
import NewSelect from 'components/UI/NewSelect';
import i18n from '../../../../../../../../i18n';
import css from '../../../styles.module.scss';

interface IMultiformatProp {
  formik: IUseTypeProp['formik'];
}

const index: FC<IMultiformatProp> = ({
  formik: { values, setFieldValue, handleChange },
}) => {
  if (!isGardMultiFormatType(values.data)) {
    return null;
  }

  return (
    <div className={css.form}>
      <UploadImage
        keyUploadFile="multiFormat"
        preview={getSizes(values.data.images.icon, ['256x256'])}
        name="images.icon"
        setFieldValue={setFieldValue}
        label={i18n.t('creative.creasmall_set.multiformat.icon')}
        customLinkTitle={i18n.t('creative.creasmall_set.linktoicon_word')}
        hintText={
          <span>
            {i18n.t('creative.creasmall_set.multuformat_formats1')}
            <br />
            {i18n.t('creative.creasmall_set.multuformat_formats2')}
          </span>
        }
        hasEdit
        edit
        minResolution={256}
        isData
        maxSizeFile={2}
        file_ext={['.png', '.jpeg', '.jpg']}
        typeSection="multiFormat"
      />
      <UploadImage
        keyUploadFile="multiFormat"
        preview={getSizes(values.data.images.image_1, ['600x600'])}
        name="images.image_1"
        setFieldValue={setFieldValue}
        label={i18n.t('creative.creasmall_set.multiformat.image_1')}
        customLinkTitle={i18n.t('creative.creasmall_set.linktoimage_word')}
        hintText={
          <span>
            {i18n.t('creative.creasmall_set.multuformat_formats1')}
            <br />
            {i18n.t('creative.creasmall_set.multuformat_formats3')}
          </span>
        }
        hasEdit
        edit
        isData
        minResolution={600}
        maxSizeFile={2}
        file_ext={['.png', '.jpeg', '.jpg']}
        typeSection="multiFormat"
        constructorImage
        constructorTitle={values.title}
        imageWidth={600}
        imageHeight={600}
      />
      <UploadImage
        keyUploadFile="multiFormat"
        preview={getSizes(values.data.images.image_2, ['1080x607'])}
        name="images.image_2"
        setFieldValue={setFieldValue}
        label={i18n.t('creative.creasmall_set.multiformat.image_2')}
        customLinkTitle={i18n.t('creative.creasmall_set.linktoimage_word')}
        hintText={
          <span>
            {i18n.t('creative.creasmall_set.multuformat_formats1')}
            <br />
            {i18n.t('creative.creasmall_set.multuformat_formats4')}
          </span>
        }
        hasEdit
        edit
        minResolution={607}
        isData
        maxSizeFile={2}
        file_ext={['.png', '.jpeg', '.jpg']}
        typeSection="multiFormat"
        constructorImage
        constructorTitle={values.title}
        imageWidth={1080}
        imageHeight={607}
      />
      <Input
        label={i18n.t('creative.creasmall_set.title_word')}
        showValueCount
        inputAttributes={{
          name: 'data.title',
          placeholder: '',
          maxLength: 25,
          style: { height: 48 },
          value: values.data.title,
          onChange: handleChange,
        }}
        tooltip={{
          text: i18n.t('creative.creasmall_set.multiformat.title_hint'),
        }}
      />
      <Input
        label={i18n.t('creative.creasmall_set.text_word')}
        showValueCount
        isTextarea
        inputAttributes={{
          name: 'data.text',
          style: { height: 128 },
          maxLength: 90,
          value: values.data.text,
          onChange: handleChange,
        }}
        tooltip={{
          text: i18n.t('creative.creasmall_set.multiformat.text_hint'),
        }}
      />
      <Input
        label={i18n.t('creative.creasmall_set.multiformat.legal_info_title')}
        showValueCount
        isTextarea
        inputAttributes={{
          name: 'data.legal_information',
          style: { height: 128 },
          maxLength: 115,
          value: values.data.legal_information,
          onChange: handleChange,
        }}
        tooltip={{
          text: i18n.t('creative.creasmall_set.multiformat.legal_info'),
        }}
      />
      <Field name="data.button">
        {({ form, field }: FieldProps) => (
          <NewSelect
            label={i18n.t('creative.creasmall_set.multiformat.type_of_btn')}
            form={form}
            field={field}
            selectAttributes={{
              name: 'data.button',
              placeholder: i18n.t('creative.create_creative.Please_select'),
              options: optionsButtonCreativeMulti,
              defaultValue: optionsButtonCreativeMulti[0],
              onChange: () => {
                setFieldValue('button', values.data.button);
              },
            }}
            labelTooltip={{
              text: i18n.t('creative.creasmall_set.multiformat.type_btn_hint'),
            }}
            viewStyle="Vertical"
          />
        )}
      </Field>
    </div>
  );
};

export default index;
