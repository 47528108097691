import {
  THookSelectorFetchDataInfo,
  TSelector,
  useGenFetchDataSelector,
} from '../../../redux/rootSelectors';
import { PartnersState } from '../reducer';
import { IPartnersList, TPartnersBalance } from '../types';
import { TBalance } from '../../campaigns/types';

export const selectorPartnersList: TSelector<PartnersState['tempPartners']> = ({
  partnersReducer,
}) => partnersReducer.tempPartners;

export const selectorPartnersBalance: TSelector<PartnersState['balances']> = ({
  partnersReducer,
}) => partnersReducer.balances;

export const selectorPartnerBalance: TSelector<PartnersState['balance']> = ({
  partnersReducer,
}) => partnersReducer.balance;

export const usePartnersListInfo: THookSelectorFetchDataInfo<
  IPartnersList
> = () => useGenFetchDataSelector(selectorPartnersList);

export const usePartnersBalanceInfo: THookSelectorFetchDataInfo<
  TPartnersBalance
> = () => useGenFetchDataSelector(selectorPartnersBalance);

export const usePartnerBalanceInfo: THookSelectorFetchDataInfo<TBalance> = () =>
  useGenFetchDataSelector(selectorPartnerBalance);
