import React, { useState, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Chart from 'react-google-charts';
import Loader from 'components/UI/Loader';
import noDataImg from 'assets/no-chart-data.svg';
import { FiRefreshCcw } from 'react-icons/fi';
import Tooltip from 'components/UI/Tooltip';
import css from './styles.module.scss';
import './styles.css';
import { useCampaignStatisticsInfo } from '../../model/selectors';

interface Props {
  handlerFetchStatistics: () => void;
}

const Statistics: FC<Props> = ({handlerFetchStatistics}) => {
  const { isLoading, data: statistics } = useCampaignStatisticsInfo();
  const [toggle, setToggle] = useState(false);
  const [chartDataType, setChartDataType] = useState<string[][]>([]);
  const { t } = useTranslation();
  const createCustomHTMLContent = (
    element: string[],
  ) => `<div className="tooltip">
      <strong>${element[0]}</strong>
      <span>${
        !toggle
          ? t('campaigns_page.stat_chart.shows')
          : t('campaigns_page.stat_chart.clicks')
      } · ${element[1]}</span>
    </div>`;

  useEffect(() => {
    if (!isLoading) {
      if (toggle) {
        setChartDataType(statistics ? statistics.clicks : []);
      } else {
        setChartDataType(statistics ? statistics.show : []);
      }
    }
  }, [isLoading, toggle]);

  const chartData = chartDataType.length > 0 && [
    ['Дата', { role: 'tooltip', type: 'string', p: { html: true } }, 'Показы'],
    ...chartDataType
      .slice(1)
      .map((e) => [e[0], createCustomHTMLContent(e), e[1]]),
  ];

  return isLoading ? (
    <div className={css.loader}>
      <Loader />
    </div>
  ) : (
    <div className={css.body}>
      <div className={css.actions}>
        <Tooltip
          title={
            <div className={css.update} onClick={handlerFetchStatistics}>
              <FiRefreshCcw size={18} />
            </div>
          }
        >
          {t(`update_btn`)}
        </Tooltip>
      </div>
      <div className={css['charts-nav']}>
        <button
          type="button"
          onClick={() => setToggle(false)}
          className={cn({ _isActive: !toggle })}
        >
          {t('campaigns_page.stat_chart.shows')}
        </button>
        <button
          type="button"
          onClick={() => setToggle(true)}
          className={cn({ _isActive: toggle })}
        >
          {t('campaigns_page.stat_chart.clicks')}
        </button>
      </div>

      {statistics &&
      statistics.show.filter((e, i: number) => i > 0).some((e) => +e[1] > 0) ? (
        <div className="charts">
          <Chart
            chartType="ColumnChart"
            width="100%"
            height="280px"
            data={chartData}
            options={{
              bar: { groupWidth: '40%' },
              legend: { position: 'none' },
              animation: {
                startup: true,
                easing: 'ease',
                duration: 400,
              },
              chartArea: {
                width: '90%',
                height: '80%',
                left: '10%',
                top: '10%',
                backgroundColor: {
                  stroke: 'transparent',
                  strokeWidth: 1,
                },
              },
              vAxis: {
                format: 'short',
                gridlines: {
                  color: '#ddd',
                },
                baselineColor: '#ddd',
                textStyle: {
                  color: '#828282',
                },
                viewWindow: {
                  min: 0,
                },
              },
              hAxis: {
                textStyle: {
                  color: '#828282',
                },
              },
              series: {
                0: { color: '#025DFF' },
              },
              focusTarget: 'category',
              tooltip: {
                isHtml: true,
              },
            }}
          />
        </div>
      ) : (
        <div className={css['no-data']}>
          <div className={css.icon}>
            <img
              src={noDataImg}
              alt={t('campaigns_page.campaign_settings.nostat')}
            />
          </div>
          <div className={css.text}>
            <span>{t('campaigns_page.campaign_settings.nostat')}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Statistics;
