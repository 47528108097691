// eslint-disable-next-line no-shadow
export enum CampaignFilter {
  CAMPAIGN_START = 'start',
  CAMPAIGN_FINISH = 'end',
}

/* export enum HistoryObjectFilter {
  HISTORY_CREATIVE = 'CREATIVE',
  HISTORY_CAMPAIGN = 'CAMPAIGN',
} */

// eslint-disable-next-line no-shadow
export enum FilterCompare {
  MORE = 'more',
  LESS = 'less',
  EQUALS = 'equals',
  MORE_AND_EQUALS = 'moreAndEquals',
  LESS_AND_EQUALS = 'lessAndEquals',
}
