import { loadCampaignsListView } from 'domains/campaigns/model/actions';
import {
  fetchCampaignsTotal,
  setCampaignTranslationFilter,
  setListSort as setListSortCampaigns,
} from 'domains/campaigns/reducer';
import {
  ResponseCampaignsStata,
  ResponseTranslation,
} from 'domains/campaigns/types';
import { loadCreativeList } from 'domains/creative/model/actions';
import {
  fetchCreativesTotal,
  setCreoTranslationFilter,
  setListSort as setListSortCreo,
} from 'domains/creative/reduser';

import { CreativeItemListWithStatistics } from 'domains/creative/types';
import { useEffect, useState } from 'react';
import { useDispatchApp } from 'redux/rootSelectors';
import { Period } from 'types/general';

interface IReturnFiltredList {
  currentFilter: string;
  filterCreoTranslation: (key: string) => void;
  currentTranslationXxhashArr: string[];
}

export const useFilterCreoListByTranslation = (
  list: CreativeItemListWithStatistics[] | ResponseCampaignsStata[] | null,
  translationData: Record<string, ResponseTranslation> | null,
  mode: 'creo' | 'campaign',
  search: string,
  period?: Period,
): IReturnFiltredList => {
  const [currentFilter, setCurrentFilter] = useState('isAll');
  const dispatch = useDispatchApp();
  const [currentTranslationXxhashArr, setcurrentTranslationXxhashArr] =
    useState<string[]>([]);

  const isGardCreoList = (
    newList: CreativeItemListWithStatistics[] | ResponseCampaignsStata[],
  ): newList is CreativeItemListWithStatistics[] => true;

  const isGardCampaignList = (
    newList: CreativeItemListWithStatistics[] | ResponseCampaignsStata[],
  ): newList is ResponseCampaignsStata[] => true;

  useEffect(() => {
    setCurrentFilter('isAll');
    if (list) {
      if (mode === 'creo' && isGardCreoList(list)) {
        dispatch(setCreoTranslationFilter(list));
        return;
      }
      if (isGardCampaignList(list))
        dispatch(setCampaignTranslationFilter(list));
    }
  }, [list?.length]);

  const filterTranslation = (item: ResponseTranslation, key: string) => {
    const {
      client_status,
      agency_status,
      organization_status,
      campaign_status,
      creative_status,
      moderation_status,
      campaign_period,
      campaign_costs,
      balance,
      creative_data,
      target_geo,
      target_source,
      dsp_decision,
    } = item;

    const creoTranslationData = [
      client_status,
      agency_status,
      organization_status,
      campaign_status,
      creative_status,
      moderation_status,
      campaign_period,
      campaign_costs,
      balance,
      creative_data,
      target_geo,
      target_source,
    ];

    const campaignTranslationData = [
      client_status,
      agency_status,
      organization_status,
      campaign_status,
      campaign_period,
      campaign_costs,
      balance,
    ];

    const ApiTranslationData =
      mode === 'creo' ? creoTranslationData : campaignTranslationData;
    if (
      ((ApiTranslationData.every((status) => status === 'ok') &&
        dsp_decision !== 'ok') ||
        (ApiTranslationData.some((status) => status !== 'ok') &&
          dsp_decision === 'ok')) &&
      key === 'isUnknown'
    ) {
      return true;
    }
    if (
      key === 'isTranslate' &&
      ApiTranslationData.every((status) => status === 'ok') &&
      dsp_decision === 'ok'
    ) {
      return true;
    }
    if (
      key === 'isNoTranslate' &&
      ApiTranslationData.some((status) => status !== 'ok') &&
      dsp_decision !== 'ok'
    ) {
      return true;
    }
    return false;
  };

  const filterCreoTranslation = (key: string) => {
    setCurrentFilter(key);
    if (mode === 'campaign') {
      dispatch(setListSortCampaigns({ key: null, sort: null }));
    }
    if (mode === 'creo') {
      dispatch(setListSortCreo({ key: null, sort: null }));
    }
    if (translationData && key !== 'isAll') {
      const filtredTranslation = Object.entries(translationData)
        .filter((item) => filterTranslation(item[1], key))
        .map((itemTranslation) => itemTranslation[0]);
      setcurrentTranslationXxhashArr(filtredTranslation);
      const filtredList =
        list && isGardCreoList(list)
          ? list.filter((item: { xxhash: string }) =>
              filtredTranslation.includes(item.xxhash),
            )
          : list?.filter((item: { xxhash: string }) =>
              filtredTranslation.includes(item.xxhash),
            );
      if (mode === 'creo' && filtredList && isGardCreoList(filtredList)) {
        dispatch(setCreoTranslationFilter(filtredList));
        dispatch(
          fetchCreativesTotal({
            xxhash_list: filtredList
              ?.filter((item) =>
                search === ''
                  ? true
                  : item.title.toLowerCase().includes(search.toLowerCase()),
              )
              ?.map((item) => item.xxhash),
            period,
          }),
        );
        dispatch(loadCreativeList({ isHard: true }));
      }
      if (
        mode === 'campaign' &&
        filtredList &&
        isGardCampaignList(filtredList)
      ) {
        dispatch(setCampaignTranslationFilter(filtredList));
        dispatch(
          fetchCampaignsTotal({
            xxhash_list: filtredList
              ?.filter((item) =>
                search === ''
                  ? true
                  : item.title.toLowerCase().includes(search.toLowerCase()),
              )
              ?.map((item) => item.xxhash),
            period,
          }),
        );
        dispatch(loadCampaignsListView({ isHard: true }));
      }
      return;
    }
    if (mode === 'creo' && list && isGardCreoList(list)) {
      setcurrentTranslationXxhashArr([]);
      dispatch(setCreoTranslationFilter(null));
      dispatch(
        fetchCreativesTotal({
          xxhash_list: list
            .filter((item) =>
              search === ''
                ? true
                : item.title.toLowerCase().includes(search.toLowerCase()),
            )
            ?.map((item) => item.xxhash),
          period,
        }),
      );
      dispatch(loadCreativeList({ isHard: true }));
    }
    if (mode === 'campaign' && list && isGardCampaignList(list)) {
      setcurrentTranslationXxhashArr([]);
      dispatch(setCampaignTranslationFilter(null));
      dispatch(
        fetchCampaignsTotal({
          xxhash_list: list
            .filter((item) =>
              search === ''
                ? true
                : item.title.toLowerCase().includes(search.toLowerCase()),
            )
            ?.map((item) => item.xxhash),
          period,
        }),
      );
      dispatch(loadCampaignsListView({ isHard: true }));
    }
  };

  return {
    currentFilter,
    currentTranslationXxhashArr,
    filterCreoTranslation,
  };
};
