import React from 'react';
import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import { FiCheck } from 'react-icons/fi';
import cn from 'classnames';
import css from './styles.module.scss';

type toastSuccessBoldProps = (i18nKey: string, values: any) => any;

export const toastSuccessBold: toastSuccessBoldProps = (i18nKey, values) =>
  toast(
    <Trans
      i18nKey={i18nKey}
      values={values}
      components={{ bold: <strong /> }}
    />,
    {
      icon: (
        <div className={cn(css.icon, '_isSuccess')}>
          <FiCheck size={16} color="#fff" />
        </div>
      ),
      bodyClassName: css.toast__body,
      position: toast.POSITION.TOP_RIGHT,
      className: 'Toastify__toast--success',
    },
  );
