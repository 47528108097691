import { TExperimentalInfoField } from 'domains/campaign/types';

export const createConfiValues = (
  fieldsObj: TExperimentalInfoField[] | null | undefined,
  savedFields: Record<string, string | boolean | number>,
  ValueKeys: string[],
): Record<string, string | boolean> => {
  const configObj = {};
  ValueKeys.forEach((key) => {
    const currentField = fieldsObj?.find((item) => item.field === key);
    if (currentField) {
      configObj[currentField.id] =
        savedFields[currentField.id] || currentField.default_value;
    }
  });
  return configObj;
};
