/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { TParthner } from 'domains/usersAPI/model/types';
import { useTranslation } from 'react-i18next';
import ShowMoreList from 'components/ShowMoreList';
import css from '../styles.module.scss';
import { PartnerItem } from './PartnersItem';

interface PartnersListProps {
  partners: TParthner[];
}

export const PartnerList: FC<PartnersListProps> = ({ partners }) => {
  const { t } = useTranslation();

  const partnerInfo = (partner: TParthner) => {
    if (partner.type === 'CLIENT') {
      return {
        title: t(`type_parther.CLIENT`),
        link: `/main/campaigns?client_id=${partner.xxhash}`,
      };
    }
    if (partner.type === 'AGENCY') {
      return {
        title: t(`type_parther.AGENCY`),
        link: `/main/clients?agency_id=${partner.xxhash}`,
      };
    }
    return {
      title: t(`type_parther.ORGANIZATION`),
      link: '/main/agencies',
    };
  };

  return (
    <div className={css.value} onClick={(e) => e.stopPropagation()}>
      <div className={css.login}>
        <ShowMoreList
          data={partners}
          leftPart={
            <div>
              <>{t(`type_parther.${partners[0].type}`)}</>{' '}
              <strong>
                <NavLink
                  className={css.link}
                  target="_blank"
                  to={{
                    pathname: partnerInfo(partners[0]).link,
                  }}
                >
                  {partners[0].title}
                </NavLink>
              </strong>
            </div>
          }
          list={partners.map((partner, index) => (
            <PartnerItem
              partnerInfo={partnerInfo(partner)}
              title={partner.title}
              key={`${partner.title}${index}`}
            />
          ))}
        />
      </div>
    </div>
  );
};
