import { TFieldStatistics } from 'domains/campaign/types';
import { useCallback, useState } from 'react';
import { Tdata, TDownloadStata } from '../types';

type Param = {
  worker: Worker;
  downloadState: React.MutableRefObject<TDownloadStata>;
  title: string;
  total: Tdata | null;
  keysFields: TFieldStatistics[];
};

type TFUseGetReport = (p: Param) => {
  disGetReport: boolean;
  onGetReport: () => void;
};

export const useGetReport: TFUseGetReport = ({
  worker,
  title,
  downloadState,
  total,
  keysFields,
}) => {
  function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  const [disGetReport, setDisGetReport] = useState(false);

  const onGetReport: ReturnType<TFUseGetReport>['onGetReport'] =
    useCallback(() => {
      const data = downloadState.current?.right ?? [];
      const leftD = downloadState.current?.left ?? [];

      setDisGetReport(true);

      worker.postMessage([
        data,
        leftD,
        total,
        keysFields.map(({ key }) => key),
      ]);
      // eslint-disable-next-line no-param-reassign
      worker.onmessage = function (e) {
        const type = isSafari() ? 'application/csv' : 'text/csv';
        const blob = new Blob(['', e.data], { type });

        const URL = window.URL || window.webkitURL;

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', `${title}.tsv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setDisGetReport(false);
      };
      // eslint-disable-next-line no-param-reassign
      worker.onerror = function (e) {
        console.error({ e });
        setDisGetReport(false);
      };
    }, [downloadState.current, total]);

  return {
    disGetReport,
    onGetReport,
  };
};
