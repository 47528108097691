import React, { FC } from 'react';
import cn from 'classnames';
import Load from 'components/UI/Load';
import css from '../styles.module.scss';

const LoadingReportChart: FC = () => (
  <div className={cn(css.card, 'LoadingReportChart')}>
    <div className={css.children}>
      <div className={css.chart}>
        <Load
          style={{
            width: '400px',
            height: '14px',
            marginBottom: '16px',
          }}
        />
        <div className={css.loadingGrid}>
          <Load
            style={{
              width: '100%',
            }}
          />
          <Load
            style={{
              width: '100%',
              height: '100%',
            }}
          />
          <Load
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </div>
      </div>
    </div>
  </div>
);

export default LoadingReportChart;
