import { ILimits } from 'domains/target/types';
import i18next from 'i18next';

export function numFormat(
  locale = i18next.language,
  num: undefined | null | number | string | Date | ILimits,
  def = '0',
): string {
  if (typeof num === 'string') {
    const pNum = parseFloat(num.replace(/\s+/g, '').replace(/\,/g, '.'));
    if (Number.isNaN(pNum)) {
      return def;
    }
    return Intl.NumberFormat(locale, { maximumFractionDigits: 2 }).format(pNum);
  }
  if (typeof num === 'number')
    return Intl.NumberFormat(locale, { maximumFractionDigits: 2 }).format(num);

  return def;
}
