import {
  createScreenAction,
  getScreenStatus,
} from 'domains/creative/model/actions';
import { useScreenStatus } from 'domains/creative/model/selectors';
import { CreativeDetail, TScreenStatus } from 'domains/creative/types';
import { useEffect, useRef, useState } from 'react';
import { useDispatchApp } from 'redux/rootSelectors';

interface ScreenShotDataParams {
  creativeDetail: CreativeDetail | null; 
}

interface ScreenShotDataReturn {
  updateScreenStatusHandler: () => void;
  createScreenHandler: () => void;
  screenStatus: TScreenStatus | null;
  creoTitle: string | undefined;
  isDisableCreate: boolean;
  textError: string;
}

type TUseScreenShotData = (
  params: ScreenShotDataParams,
) => ScreenShotDataReturn;

const getStatusInterval = 60000;
const loadingScreenshotStatuses: (TScreenStatus['status'] | undefined)[] = [
    'PENDING',
    'PROCESSING',
    'SENDED',
  ];

export const useScreenShotData: TUseScreenShotData = ({
  creativeDetail,
}) => {
  const dispatch = useDispatchApp();

  const { data: screenStatus } = useScreenStatus();
  const [textError, setTextError] = useState('');

  const isDisableCreate = loadingScreenshotStatuses.includes(screenStatus?.status)

  const getStatusTimerId = useRef<ReturnType<typeof setInterval>>();

  const createScreenHandler = () => {
    if (!creativeDetail?.xxhash) return;
    setTextError('')
    dispatch(createScreenAction({ xxhash: creativeDetail?.xxhash, callbacks: {setTextError} }));
  };

  const updateScreenStatusHandler = () => {
    if (!creativeDetail?.xxhash) return;
    setTextError('')
    dispatch(getScreenStatus({ xxhash: creativeDetail?.xxhash, callbacks: {setTextError}}));
  };

  useEffect(() => {
    updateScreenStatusHandler();
  }, [creativeDetail?.xxhash]);

  useEffect(() => {
    if (
      (!loadingScreenshotStatuses.includes(screenStatus?.status) || textError) &&
      getStatusTimerId.current
    ) {
      clearTimeout(getStatusTimerId.current);
      return;
    }
    getStatusTimerId.current = setTimeout(
      updateScreenStatusHandler,
      getStatusInterval,
    );
  }, [screenStatus?.status]);

  useEffect(
    () => () => {
      if (getStatusTimerId.current) {
        clearTimeout(getStatusTimerId.current);
      }
    },
    [creativeDetail?.xxhash],
  );

  return {
    updateScreenStatusHandler,
    createScreenHandler,
    screenStatus,
    isDisableCreate,
    creoTitle: creativeDetail?.title,
    textError,
  };
};
