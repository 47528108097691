import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from '../../../redux/fetchedData';
import { ContractItem } from './types';

export type contractState = {
  contractData: FetchedData<ContractItem>;
};

export const initialState: contractState = {
  contractData: genFetchedData<ContractItem>(null),
};

const contractSlice = createSlice({
  name: 'Contract',
  initialState,
  reducers: {
    setContractData(
      state,
      action: PayloadAction<contractState['contractData']>,
    ) {
      state.contractData = action.payload;
    },
  },
});

export const { setContractData } = contractSlice.actions;
export const contractReducer = contractSlice.reducer;