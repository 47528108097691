import React, { useEffect, useState, ReactNode, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import cn from 'classnames';
import {
  FiCheck,
  FiCheckCircle,
  FiInfo,
  FiPlusCircle,
  FiX,
  FiXCircle,
} from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FilesBwUpdate } from 'domains/bw_lists_update/types';
import { AppDispatch, AppState } from '../../../redux/rootReducer';
import {
  BwListUpdateState,
  UploadFilesBwListUpdate,
} from '../../../domains/bw_lists_update/reducer';
import css from './styles.module.scss';
import { isTUploadErr } from '../../../utils/upload';
import avatarColors from '../../../utils/avatarColors';
import Loader from '../Loader';

interface Props {
  dropzoneText: ReactNode;
  acceptedTypes: string;
}

const MultipleUpload: React.FC<Props> = ({ dropzoneText, acceptedTypes }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const tempLoadFiles = useSelector<AppState, BwListUpdateState['loadFiles']>(
    ({ bwListUpdateReducer }) => bwListUpdateReducer.loadFiles,
  );
  const loadFiles = tempLoadFiles.get('data');
  const isLoading = tempLoadFiles.get('isLoading');
  const [files, setFiles] = useState<FilesBwUpdate>([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedTypes,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const filterError = (code: number): string => {
    if (code === 2032) {
      return t('errors.err2032_200');
    }
    if (code === 2034) {
      return t('errors.err2034');
    }
    if (code === 40) {
      return t('errors.err40');
    }
    if (code === 413) {
      return 'Размер всех файлов превышает 300 Мб.';
    }
    return t('errors.err_none');
  };

  let errorCount = 0;

  const thumbNew =
    loadFiles &&
    loadFiles.map(({ res, file }) => {
      const error = isTUploadErr(res) ? res.error : undefined;
      if (error) {
        errorCount += 1;
      }
      const result = isTUploadErr(res) ? undefined : res.result;
      const status = result ? 'isSuccess' : 'isError';
      return (
        <div className={cn(css.thumb, status)} key={file.name}>
          <div className={css.inner}>
            <div
              className={css.preview}
              style={{
                backgroundImage: avatarColors(
                  file.name.slice(0, 1).toLowerCase(),
                ),
              }}
            >
              <div className={css.type}>
                {file.name.lastIndexOf('.') + 1
                  ? file.name.substr(file.name.lastIndexOf('.') + 1)
                  : 'file'}
              </div>
              <div className={css.status}>
                {status === 'isError' && <FiX size={10} color="#fff" />}
                {status === 'isSuccess' && <FiCheck size={10} color="#fff" />}
              </div>
            </div>
            <div className={css.text}>
              <div className={css.filename}>{file.name}</div>
              <div className={css.error}>
                <FiInfo size={16} />
                <span>
                  {t('errors.err_file')}
                  <br />
                  {error && filterError(error?.code)}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    // files.forEach((file) => URL.revokeObjectURL(file.preview));
    if (files.length > 0) {
      dispatch(UploadFilesBwListUpdate(files));
    }
  }, [files]);

  const errorList = useMemo<boolean>(() => errorCount <= 0, [errorCount]);

  return (
    <section className={css.wrapper}>
      <div
        {...getRootProps()}
        className={cn(css.dropzone, { isDisable: isLoading })}
      >
        <input {...getInputProps()} />
        <FiPlusCircle size={24} />
        <p className={css.text}>{dropzoneText}</p>
      </div>
      {!isLoading ? (
        <>
          {thumbNew && <aside className={css.container}>{thumbNew}</aside>}
          {loadFiles && (
            <div
              className={cn(css.result, errorList ? 'isSuccess' : 'isError')}
            >
              {errorList ? (
                <>
                  <FiCheckCircle size={16} color="#fff" />
                  <span>{t('multiple_upload.status.all')}</span>
                </>
              ) : (
                <>
                  <FiXCircle size={16} color="#fff" />
                  <span>{t('multiple_upload.status.not_all')}</span>
                </>
              )}
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </section>
  );
};

export default MultipleUpload;
