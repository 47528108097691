/* eslint-disable react/destructuring-assignment */
import { SParthner } from 'domains/users/model/types';
import { FormikProps } from 'formik';
import React, { FC } from 'react';
import avatarColors from 'utils/avatarColors';
import { components } from 'react-select';
import cn from 'classnames';
import { CustomOptionsType } from 'types/general';
import css from './styles.module.scss';
import InputSelectCustom from '../InputSelectCustom';
import SelectWrapper from '../NewSelect/components/SelectWrapper';
import { ClearIndicator } from '../NewSelect/components/ClearIndicator';
import { DropdownIndicator } from '../NewSelect/components/DropdownIndicator';

type TInputSelectPartner<T, M> = {
  propsValues?: M;
  placeholder?: string;
  customValue?: SParthner | null;
  fieldName: string;
  clearable?: boolean;
  formik?: FormikProps<T>;
  isError?: boolean;
  errorText?: string;
  isVerticalStyle?: boolean;
};

const Option: FC = (props: any) => (
  <components.Option
    {...props}
    className={cn(css.partner_option, 'partner_option', {
      _isNotColor: Boolean(props.data.additional.icon),
    })}
  >
    <div className={cn(css.partner_option_left, 'partner_option_left')}>
      <div
        className={css.icon}
        style={
          props.data.additional.icon
            ? {
                backgroundImage: `url(${props.data.additional.icon})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }
            : {
                backgroundColor: avatarColors(
                  props.data.additional.title.slice(0, 1).toLowerCase(),
                ),
              }
        }
      >
        {props.data.additional.title.trim().slice(0, 1).toUpperCase()}
      </div>
      <div
        className={cn(css.title, 'title')}
        title={props.data.additional.title}
      >
        {props.data.additional.title}
      </div>
    </div>
    <div className={cn(css.partner_option_right, 'partner_option_right')}>
      <div className={css.internal_id}>{props.data.additional.internal_id}</div>
      <div className={css.xxhash}>{props.data.additional.xxhash}</div>
    </div>
  </components.Option>
);

const ValueContainer: FC = (props: any) => {
  if (
    !props.hasValue ||
    !Object.prototype.hasOwnProperty.call(props.selectProps.value, 'additional')
  ) {
    return (
      <components.ValueContainer {...props}>
        {props.children}
      </components.ValueContainer>
    );
  }
  return (
    <components.ValueContainer {...props}>
      {props.children.map((child: any) =>
        !props.hasValue || child?.type === components.Input ? child : null,
      )}

      {props.hasValue && props.children[0] !== null && (
        <div className={cn(css.partner_option, css.selected)}>
          <div className={cn(css.partner_option_left, 'partner_option_left')}>
            <div
              className={css.icon}
              style={
                props.selectProps.value.additional.icon
                  ? {
                      backgroundImage: `url(${props.selectProps.value.additional.icon})`,
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                    }
                  : {
                      backgroundColor: avatarColors(
                        props.selectProps.value.additional.title
                          .slice(0, 1)
                          .toLowerCase(),
                      ),
                    }
              }
            >
              {props.selectProps.value.additional.title
                .trim()
                .slice(0, 1)
                .toUpperCase()}
            </div>
            <div
              className={cn(css.title, 'title')}
              title={props.selectProps.value.additional.title}
            >
              {props.selectProps.value.additional.title}
            </div>
          </div>
          <div className={cn(css.partner_option_right, 'partner_option_right')}>
            <div className={css.internal_id}>
              {props.selectProps.value.additional.internal_id}
            </div>
            <div className={css.xxhash}>
              {props.selectProps.value.additional.xxhash}
            </div>
          </div>
        </div>
      )}
    </components.ValueContainer>
  );
};

function index<T, M extends CustomOptionsType[]>(
  props: TInputSelectPartner<T, M>,
): JSX.Element {
  return (
    <SelectWrapper
      isVerticalStyle={props.isVerticalStyle}
      isError={props.isError}
    >
      <InputSelectCustom<T, M>
        {...props}
        customComponents={{
          IndicatorSeparator: null,
          Option,
          ValueContainer,
          ClearIndicator,
          DropdownIndicator,
        }}
      />
    </SelectWrapper>
  );
}

export default index;
