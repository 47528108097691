import Load from 'components/UI/Load';
import React, { FC } from 'react';
import css from '../../styles.module.scss';

const LoadingCheckbox: FC = () => {
  const params = new Array(12);

  for (let i = 0; i < params.length; i += 1) {
    params[i] = (
      <div className={css.loading_item} key={i}>
        <Load
          style={{
            width: '20px',
            height: '20px',
            marginRight: '16px',
          }}
        />
        <Load
          style={{
            width: '107px',
            height: '20px',
          }}
        />
      </div>
    );
  }

  return <>{params}</>;
};

export default LoadingCheckbox;
