import { TComment, TCommentComponent } from '../types';

type TFHelperCommentPosition = (
  data: TCommentComponent[],
) => TCommentComponent[];
type TFHelperCommentTransform = (
  comments: TComment[],
  deletedID: number[],
  user: string,
) => TCommentComponent[];
type TFHelperHeightText = (text: string) => number;

export const helperHeightText: TFHelperHeightText = (text) => {
  let counterHeight = 0;
  const arString: string[][] = [];
  const RANGE_ROW = 42;
  const recurse = (str: string) => {
    if (!str.length) {
      return;
    }
    if (str.length >= RANGE_ROW) {
      const sub = str.substring(0, RANGE_ROW);
      arString.push(sub.split(''));
      const rest = str.slice(RANGE_ROW, str.length);
      recurse(rest);
    } else {
      arString.push(str.split(''));
    }
  };
  recurse(text);
  arString.forEach((element) => {
    if (element.every((i) => i.match(/[A-Z0-9]+$/))) {
      counterHeight += 24;
    } else {
      counterHeight += 22;
    }
  });

  return counterHeight;
};

/** функция выстраивает позиции элементов в списке */
export const helperCommentPosition: TFHelperCommentPosition = (data) => {
  const result: TCommentComponent[] = [];
  let counterTop = 0;
  data.forEach((value, index, array) => {
    if (index !== 0) {
      counterTop += array[index - 1].height;
    }
    result.push({ ...value, top: counterTop });
  });
  return result;
};

/** функция рассчитывает высоту комментариев */
export const helperCommentTransform: TFHelperCommentTransform = (
  comments,
  deletedID,
  user,
) =>
  comments.map((data) => {
    const newObj: TCommentComponent = {
      ...data,
      height: 51,
      top: 0,
    };
    if (data.text.length > 150) {
      newObj.isCollapse = false;
      newObj.height += 66;
    } else {
      newObj.height += helperHeightText(newObj.text);
    }
    if (deletedID.includes(data.id)) {
      newObj.height = 30;
    }
    if (newObj.isCollapse !== undefined || newObj.user_email === user) {
      newObj.height += 22;
    }
    return newObj;
  });

/** функция рассчитывает высоту строки истории изменеия */
export const helperHistoryTransform: any = (
  list: any,
) =>
  list.map((data: any) => {
    const newObj: TCommentComponent = {
      ...data,
      height: 95,
      top: 0,
    };
    // Считаем количество строк, при наличи переноса в тексте \n
    const enterCount = (data.text.match(/\n/g) || []).length;
    // Если строк уже больше 4, то сразу ставим, что этот блок будет обрезан и появится кнопка подробнее
    // Если строк мельше 4, но символов больше 150, то делаем тоже самое
    if (enterCount >= 4 || data.text.length > 150) {
      newObj.isCollapse = true;
      newObj.height = 160;
    }
    // если нет переноса строк, то обычной формулой считаем для данного типа вывода 
    // и корректируем в зависимости от количества строк
    else {
      newObj.height = 50 + helperHeightText(newObj.text) + enterCount * 4;
    }

    return newObj;
  });