import { createAction } from '@reduxjs/toolkit';
import {
  CreateContractAction,
  GetContractParams,
  UpdateContractAction,
} from './types';

export const getContract = createAction<GetContractParams>(
  'contract/getContract',
);

export const createContract = createAction<CreateContractAction>(
  'contract/createContract',
);

export const updateContract = createAction<UpdateContractAction>(
  'contract/updateContract',
);

export const sendErir = createAction<GetContractParams>(
  'contract/sendErir',
);
