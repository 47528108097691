import { createAction } from '@reduxjs/toolkit';
import { PayloadExperimental } from 'domains/campaign/types';
import { Period, TagsParam, THASH, TLoadList } from '../../../types/general';
import {
  MassEditPayload,
  PGetCreative,
  TPClearGroupCreativeTargetingAction,
  TPCreativeGroupStatusAction,
  TPCreativesAllStatus,
  TPSetCreativePretargeting,
} from '../types';

export const loadCreativeList = createAction<TLoadList>(
  'creative/loadCreativeList',
);

interface searchCreoArgs {
  search: string;
  period?: Period;
  callbacks?: {
    setIsLoadingSearch: React.Dispatch<React.SetStateAction<boolean>>;
  };
}

export const searchCreativeList = createAction<
  searchCreoArgs & { blockTotalRequest?: boolean }
>('creative/searchCreativeList');

export const updateCreativeStatistics = createAction<boolean>(
  'creative/updateCreativeStatisticsCalendar',
);

export const fetchCreativeListModal = createAction<PGetCreative>(
  'creative/fetchCreativeListModal',
);

export const fetchVendorLink = createAction('creative/fetchInternalData');

export const updateTagCreative = createAction<TagsParam>(
  'creative/updateTagCampaign',
);

export const revertCreativeTags = createAction<THASH>(
  'creative/revertCampaignTags',
);

export const changeAllCreativeStatuses = createAction<
  TPCreativesAllStatus & { callback: (p: boolean) => void }
>('creative/changeAllCreativeStatuses');

export const changeGroupCreativesStatuses =
  createAction<TPCreativeGroupStatusAction>(
    'creative/changeGroupCreativesStatuses',
  );

export const clearGroupCreativesTargeting =
  createAction<TPClearGroupCreativeTargetingAction>(
    'creative/clearGroupCreativesTargeting',
  );

export const fetchCreativePretargeting = createAction<THASH>(
  'creative/fetchCreativePretargeting',
);

export const setCreativePretargetingAction =
  createAction<TPSetCreativePretargeting>(
    'creative/setCreativePretargetingAction',
  );

export const removeCreativePretargeting = createAction<THASH>(
  'creative/removeCreativePretargeting',
);

export const setCreoExperimentalAction = createAction<PayloadExperimental>(
  'creative/setCreoExperimentalAction',
);

export const createScreenAction = createAction<
  THASH & {
    callbacks: { setTextError: React.Dispatch<React.SetStateAction<string>> };
  }
>('creative/createScreenAction');

export const getScreenStatus = createAction<
  THASH & {
    callbacks: { setTextError: React.Dispatch<React.SetStateAction<string>> };
  }
>('creative/getScreenStatus');

export const massEditLinkAction = createAction<MassEditPayload>('creative/massEditLinkAction');

export const fetchGridEridInfoAction = createAction<{erid: string}>('creative/fetchGridEridInfo');


