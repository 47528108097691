import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'components/UI/Card';
import LabelField from 'components/UI/LabelField';
import { Field, FieldProps, FormikProps } from 'formik';
import MultiSelectCheckbox from 'components/UI/MultiSelectCheckbox';
import cn from 'classnames';
import { ContractFormikProps } from 'domains/contract/model/types';
import i18next from 'i18next';
import { useContractData } from 'domains/contract/model/selectors';
import Tooltip from 'components/UI/Tooltip';
import { TResult } from 'utils/removeTopLevelsOfJson/removeTopLevelsOfJson';
import NewSelect from 'components/UI/NewSelect';
import css from '../styles.module.scss';
import i18n from '../../../../../i18n';

interface IAdditionalProps {
  formik: FormikProps<ContractFormikProps>;
  handleChange: (
    name: string,
    value: string | number | boolean | undefined,
  ) => void;
  options: TResult[];
  mode?: 'EDIT' | 'CREATE';
  error: ReturnType<typeof useContractData>['error'];
}

const Additional: FC<IAdditionalProps> = memo(
  ({ formik, handleChange, options, mode = 'CREATE', error }) => {
    const booleanOptions = useMemo(
      () => [
        {
          label: i18n.t(`ord.yes`),
          value: true,
        },
        {
          label: i18n.t(`ord.no`),
          value: false,
        },
      ],
      [],
    );
    const agentActingForPublisherOptions = useMemo(
      () => [
        {
          label: i18n.t(`ord.agent_acts`),
          value: true,
        },
        {
          label: i18n.t(`ord.agent_acts_behalf`),
          value: false,
        },
      ],
      [],
    );

    const actionTypeOptions = useMemo(
      () => [
        {
          label: i18n.t(`ord.actions_for_dist`),
          value: 'Distribution',
        },
        {
          label: i18n.t(`ord.contracts_concl`),
          value: 'Conclude',
        },
        {
          label: i18n.t(`ord.commercial_rep`),
          value: 'Commercial',
        },
        {
          label: i18n.t(`ord.other`),
          value: 'Other',
        },
        {
          label: i18n.t(`no`),
          value: 'None',
        },
      ],
      [i18n.language],
    );
    const { t } = useTranslation();

    return (
      <Card isUseAccess className={cn(css.card, css.data)} noHover>
        <h3 className={css.title}>{t(`ord.additional_contract_data`)}</h3>
        <Tooltip
          right
          white
          hintWidth={360}
          hideHint={mode === 'EDIT'}
          title={
            <LabelField label={t(`ord.contracts.title`)}>
              <MultiSelectCheckbox
                propsValues={options || []}
                customHandleChange={handleChange}
                isError={
                  !!formik.errors.creativeOkveds || error?.isError || false
                }
                placeholder={t(
                  `campaigns_page.campaign_settings.additional.ddmm`,
                )}
                formik={formik}
                fieldName="creativeOkveds"
                customContainer
                errorText={
                  formik.errors.creativeOkveds ||
                  i18next.t(`ord.errors.okved_field`)
                }
                chipsLimit={5}
              />
            </LabelField>
          }
        >
          <ul className={css.list}>
            <li>{t(`ord.contracts.code`)}</li>
            <li>{t(`ord.contracts.hint1`)}</li>
            <li>{t(`ord.contracts.hint2`)}</li>
          </ul>
        </Tooltip>
        <LabelField
          label={`${t(`ord.transferring_money`)}${
            formik.values.type === 'Intermediary' ? ' *' : ''
          }`}
        >
          <Field name="agentActingForPublisher">
            {({ field, form }: FieldProps) => (
              <NewSelect
                form={form}
                field={field}
                error={!!formik.errors.agentActingForPublisher}
                validateHandler={formik.validateField}
                errorText={formik.errors.agentActingForPublisher}
                selectAttributes={{
                  isDisabled: false,
                  value: agentActingForPublisherOptions.find(
                    (opt) =>
                      opt.value === formik.values.agentActingForPublisher,
                  ),
                  options: agentActingForPublisherOptions,
                  placeholder: t(`ord.select_transfer`),
                }}
                heightCustom={32}
                viewStyle="Horizontal"
              />
            )}
          </Field>
        </LabelField>
        <LabelField label={t(`ord.actions`)}>
          <Field name="actionType">
            {({ field, form }: FieldProps) => (
              <NewSelect
                form={form}
                field={field}
                validateHandler={formik.validateField}
                error={!!formik.errors.actionType}
                errorText={formik.errors.actionType}
                selectAttributes={{
                  isDisabled: false,
                  value: actionTypeOptions.find(
                    (opt) => opt.value === formik.values.actionType,
                  ),
                  options: actionTypeOptions,
                  placeholder: t(`campaigns_page.choose_action`),
                }}
                heightCustom={32}
                viewStyle="Horizontal"
              />
            )}
          </Field>
        </LabelField>
        <LabelField label={t(`ord.report_contract`)}>
          <Field name="isInvoiceContract">
            {({ field, form }: FieldProps) => (
              <NewSelect
                form={form}
                field={field}
                validateHandler={formik.validateField}
                error={!!formik.errors.isInvoiceContract}
                errorText={formik.errors.isInvoiceContract}
                selectAttributes={{
                  isDisabled: false,
                  options: booleanOptions,
                  value: booleanOptions.find(
                    (opt) => opt.value === formik.values.isInvoiceContract,
                  ),
                  placeholder: t(
                    `campaigns_page.campaign_settings.additional.ddmm`,
                  ),
                }}
                heightCustom={32}
                viewStyle="Horizontal"
              />
            )}
          </Field>
        </LabelField>
        <LabelField label={t(`ord.obligation_to_register`)}>
          <Field name="executorIsObligedForRegistration">
            {({ field, form }: FieldProps) => (
              <NewSelect
                form={form}
                field={field}
                validateHandler={formik.validateField}
                error={!!formik.errors.executorIsObligedForRegistration}
                errorText={formik.errors.executorIsObligedForRegistration}
                selectAttributes={{
                  isDisabled: false,
                  options: booleanOptions,
                  value: booleanOptions.find(
                    (opt) =>
                      opt.value ===
                      formik.values.executorIsObligedForRegistration,
                  ),
                  placeholder: t(`no`),
                }}
                heightCustom={32}
                viewStyle="Horizontal"
              />
            )}
          </Field>
        </LabelField>
      </Card>
    );
  },
);

export default Additional;
