import { useEffect } from 'react';
import { fetchGetListInCreative } from 'domains/target/actions';
import { useParams } from 'react-router-dom';
import { delSettings, delStatisticTarget } from 'domains/target/reduser';
import { useDispatchApp } from 'redux/rootSelectors';
import { useCreativeDetailInfo } from 'domains/creative/model/selectors';
import { TID } from 'types/general';
import { useTargetListInfo } from 'domains/target/model/selectors';

type TFUseCreativeTargetingList = () => {
  id: string;
  error: ReturnType<typeof useCreativeDetailInfo>['error'];
  creativeDetail: ReturnType<typeof useCreativeDetailInfo>['data'];
  listTarget: ReturnType<typeof useTargetListInfo>['data'];
  isLoading: ReturnType<typeof useCreativeDetailInfo>['isLoading'];
};

export const useCreativeTargetingList: TFUseCreativeTargetingList = () => {
  const { id } = useParams<TID>();
  const dispatch = useDispatchApp();

  const { data: listTarget, isLoading } = useTargetListInfo();
  const { data: creativeDetail, error } = useCreativeDetailInfo();

  useEffect(() => {
    if (creativeDetail) {
      dispatch(fetchGetListInCreative(creativeDetail.xxhash));
    }
    return () => {
      dispatch(delSettings());
      dispatch(delStatisticTarget());
    };
  }, [creativeDetail?.xxhash]);

  return {
    id,
    error,
    creativeDetail,
    listTarget,
    isLoading,
  };
};
