import { SagaIterator } from 'redux-saga';
import { genFetchedData } from 'redux/fetchedData';
import { call, put } from 'redux-saga/effects';
import {
  fetchBlDomains,
  fetchChangeStatusFields,
  setBlDomains,
} from '../reducer';
import { TGetListData } from '../types';
import { changeStatusFields, getDomainsList } from '../api';

export function* workerFetchGetBlDomainsList({
  payload,
}: ReturnType<typeof fetchBlDomains>): SagaIterator<void> {
  let fetchedData = genFetchedData<TGetListData>(null).set('isLoading', true);
  yield put(setBlDomains(fetchedData));
  try {
    const res = yield call(getDomainsList, payload);
    fetchedData = fetchedData.set('data', res);
  } catch (e) {
    console.error({ e });
  } finally {
    fetchedData = fetchedData.set('isLoading', false);
    yield put(setBlDomains(fetchedData));
  }
}

export function* workerFetchChangeStatusFields({
  payload,
}: ReturnType<typeof fetchChangeStatusFields>): SagaIterator<void> {
  const { file_id, fields_list, filter } = payload;
  let fetchedData = genFetchedData<TGetListData>(null).set('isLoading', true);
  yield put(setBlDomains(fetchedData));
  try {
    yield call(changeStatusFields, { file_id, fields_list });
    const res = yield call(getDomainsList, { file_id, filter });
    fetchedData = fetchedData.set('data', res);
  } catch (e) {
    console.error({ e });
  } finally {
    fetchedData = fetchedData.set('isLoading', false);
    yield put(setBlDomains(fetchedData));
  }
}
