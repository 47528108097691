import { useState, useEffect } from 'react';

type TFUseDropDown = (toggleSide: boolean) => {
  isOpened: boolean;
  toggleOff: () => void;
  toggle: () => void;
};

const useDropDownSideMenu: TFUseDropDown = (toggleSide) => {
  const [isOpened, setIsOpened] = useState(false);
  const toggleOff = () => {
    setIsOpened(false);
  };

  const toggle = () => {
    setIsOpened(!isOpened);
  };

  useEffect(() => {
    if (!toggleSide) {
      toggleOff();
    }
  }, [toggleSide]);

  return { toggle, isOpened, toggleOff };
};

export default useDropDownSideMenu;
