import { ChangeEvent, RefObject, useEffect, useState } from 'react';
import { TCommentList } from '../types';
import { useCommentIsErrorInfo } from '../selectors';
import { useDispatchApp } from '../../../../redux/rootSelectors';
import { setErrorComment } from '../reducer';

type ModalCommentProps = {
  onCloseModal: () => void;
  type: 'add' | 'edit';
  comment_id: RefObject<number | null>;
  handlerAddComment: (v: string) => void;
  handleEditComment: (
    comment_id: number,
    text: string,
    callback: () => void,
  ) => void;
  comments: TCommentList;
  updateTimeoutCommentList: (id: number, type: 'edited' | 'deleted') => void;
};

type TFUseCommentModal = (p: ModalCommentProps) => {
  isDisabled: () => boolean;
  handlerSendForm: () => void;
  onChangeInput: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  isError: boolean;
};
export const useCommentModal: TFUseCommentModal = ({
  comments,
  comment_id,
  type,
  handleEditComment,
  handlerAddComment,
  onCloseModal,
  updateTimeoutCommentList,
}) => {
  const dispatch = useDispatchApp();
  const isError = useCommentIsErrorInfo();

  const [value, setValue] = useState(
    type === 'edit'
      ? comments.find(({ id }) => comment_id.current === id)?.text ?? ''
      : '',
  );

  const isDisabled: ReturnType<TFUseCommentModal>['isDisabled'] = () => {
    let result = false;
    if (!value.length) {
      result = true;
      return result;
    }
    if (type === 'edit') {
      const el = comments.find(({ id }) => comment_id.current === id)?.text;
      if (value === el) {
        result = true;
        return result;
      }
    }
    return result;
  };

  const handlerSendForm = () => {
    if (!value) {
      return;
    }
    if (type === 'add') {
      handlerAddComment(value);
    }
    if (type === 'edit' && comment_id.current) {
      handleEditComment(comment_id.current, value, () => {
        onCloseModal();
        updateTimeoutCommentList(Number(comment_id.current), 'edited');
      });
    }
  };

  const onChangeInput: ReturnType<TFUseCommentModal>['onChangeInput'] = ({
    target,
  }) => {
    setValue(target.value);
  };

  useEffect(
    () => () => {
      if (isError) {
        dispatch(setErrorComment(false));
      }
    },
    [],
  );

  return {
    isDisabled,
    handlerSendForm,
    onChangeInput,
    value,
    isError,
  };
};
