import {
  PSetStatisticsFields,
  TStatisticsFields,
} from 'domains/campaign/types';
import requestApi from 'lib/helpers/requestApi';
import { PartnerHash, THASH, TIconsList } from 'types/general';
import {
  ParamsAddCampaigns,
  ParamsCampaigns,
  ParamsCampaignsStatistics,
  ParamSetStatusCampaign,
  PExportItemsToExcel,
  PSetStatusesInCampaignList,
  PSetStatusesInPartnerCampaigns,
  ResponseCampaignsSaga,
  RStatisticsCampaigns,
  ResponseTranslation,
  TBalance,
  TPCloneCampaigns,
  TRCloneCampaigns,
  WeekStatistic,
  PreResponseStatisticsCampaignsWeek,
  ResponseStatisticsCampaigns,
} from './types';

export const fetchCampaignsApi = requestApi<
  ResponseCampaignsSaga,
  ParamsCampaigns
>({
  method: 'campaign.getList',
});

export const fetchStatisticsApi = requestApi<
  RStatisticsCampaigns,
  ParamsCampaignsStatistics
>({
  method: 'statistic.getPeriod',
});

export const fetchChartStatisticApi = requestApi<
  WeekStatistic,
  ParamsCampaignsStatistics
>({
  method: 'statistic.getSumByDays',
});

export const fetchTranslationApi = requestApi<
  Record<string, ResponseTranslation>,
  ParamsCampaignsStatistics
>({
  method: 'campaign.checkListExport',
});

export const fetchStatisticsWeekApi = requestApi<
  PreResponseStatisticsCampaignsWeek,
  ParamsCampaignsStatistics
>({
  method: 'statistic.getDaily',
  showError: false,
});

export const setCampingChangeStatus = requestApi<
  boolean,
  ParamSetStatusCampaign
>({
  method: 'campaign.setStatus',
});

export const addCampaign = requestApi<string, ParamsAddCampaigns>({
  method: 'campaign.add',
});

type PtagsSetInCampaign = {
  xxhash: string;
  tags: string[];
};
export const tagsSetInCampaign = requestApi<boolean, PtagsSetInCampaign>({
  method: 'tags.setInCampaign',
});

export const tagsGetpartner = requestApi<string[], THASH>({
  method: 'tags.getpartner',
});
export const tagsGetCampaign = requestApi<string[], THASH>({
  method: 'tags.getCampaign',
});

export const partnerGetBalance = requestApi<TBalance, PartnerHash>({
  method: 'statistic.getPartnerBalance',
});

export const partnerGetStatisticFieldList = requestApi<
  TStatisticsFields,
  THASH
>({
  method: 'statisticField.getList',
});

export const setPartnerStatisticsApi = requestApi<
  boolean,
  PSetStatisticsFields
>({
  method: 'statisticField.setList',
});

export const setStatusesInCampaignList = requestApi<
  boolean,
  PSetStatusesInCampaignList
>({
  method: 'partner.setStatusesInCampaignList',
});

export const setStatusesInPartnerCampaigns = requestApi<
  boolean,
  PSetStatusesInPartnerCampaigns
>({
  method: 'partner.setStatusesInPartnerCampaigns',
});

export const campaignClone = requestApi<TRCloneCampaigns, TPCloneCampaigns>({
  method: 'campaign.clone',
});

export const targetingExportItemsToExcel = requestApi<
  string,
  PExportItemsToExcel
>({
  method: 'targeting.exportItemsToExcel',
});

export const geoExportItemsToCSV = requestApi<string>({
  method: 'geo.getInCSV',
});

interface TotalResponse {
  total: ResponseStatisticsCampaigns;
}

export const getTotalListStat = requestApi<
  TotalResponse,
  ParamsCampaignsStatistics
>({
  method: 'statistic.getTotal',
});

export const getIconsList = requestApi<TIconsList>({
  method: 'internalList.getIconsList',
});
