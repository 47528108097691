import React, { FC } from 'react';
import { FiMinimize2 } from 'react-icons/fi';
import i18next from 'i18next';
import Tooltip from 'components/UI/Tooltip';
import Card from 'components/UI/Card';
import Code from 'components/UI/Code';
import css from './styles.module.scss';
import ModalOverlay from '../../../Modals/ModalOverlay1';
import { TCodeComponentProps } from '../types';

type Props = TCodeComponentProps & {
  isOpen: boolean;
  onClose: () => void;
};

const FullscreenCodeModal: FC<Props> = ({ onClose, isOpen, ...rest }) => (
  <ModalOverlay onCloseModal={onClose} isOpen={isOpen} isWide>
    <Card className={css.card} noHover style={{ padding: '0px' }}>
      <div className={css.close} onClick={onClose}>
        <Tooltip
          left
          title={
            <div className={css.button}>
              <FiMinimize2 size={21} color="#0a0a0a" />
            </div>
          }
        >
          <span>{i18next.t('expand_role.ex3')}</span>
        </Tooltip>
      </div>
      <div className={css.content}>
        <Code
          {...rest}
          useFullscreen={false}
          modalView
          label={undefined}
          maxCount={undefined}
        />
      </div>
    </Card>
  </ModalOverlay>
);
export default FullscreenCodeModal;
