import { transferMoney } from "domains/partners/model/actions";
import { FormikErrors, FormikProps, useFormik } from "formik";
import { useState } from "react";
import { useDispatchApp } from "redux/rootSelectors";
import { useTranslation } from "react-i18next";

export type SelectOption = {
  value: string;
  label: string;
  additional: {
    current: string;
  };
}

export interface FormikBalanceVals {
  fromXxhash: SelectOption | null;
  toXxhash: SelectOption | null;
  amount?: number;
}

interface UseFormikBalanceParams {
  idAgency?: string,
  currency: string,
  onClose: () => void,
}

interface UseFormikBalanceR {
  formik: FormikProps<FormikBalanceVals>,
  swapXxhashValues: () => void,
  isLoading: boolean,
}



export const useFormikBalance = ({
  idAgency,
  currency,
  onClose,
}: UseFormikBalanceParams): UseFormikBalanceR => {

  const dispatch = useDispatchApp();

  const { t } = useTranslation();

  const [isLoading, setisLoading] = useState(false);

  const initialValues = {
    fromXxhash: null,
    toXxhash: null,
    amount: undefined,
  };

  const validate = (values: FormikBalanceVals) => {
    const errors: FormikErrors<FormikBalanceVals> = {};

    if (
      values.fromXxhash &&
      Number(values.amount) > +values.fromXxhash.additional.current.split(' ')[0]
    ) {
      errors.amount = t(`balance_modal.not_enough`);
    }

    return errors;
  };

  const formik = useFormik<FormikBalanceVals>({
    initialValues,
    validate,
    onSubmit: (values) => {
      const params = {
        fromXxhash: values.fromXxhash?.value || '',
        toXxhash: values.toXxhash?.value || '',
        amount: values.amount || 0,
        partner_xxhash: idAgency || '',
        alertText: t(`balance_modal.success_1`, {
          label: values.toXxhash?.label.replace('*/-/*', ' '),
          amount: values.amount,
          currency,
        }),
        callbacks: {
          onClose,
          setisLoading,
        },
      };

      dispatch(transferMoney(params));
    },
  });

  const swapXxhashValues = (): void => {
    const updatedValues = {
      ...formik.values,
      toXxhash: formik.values.fromXxhash,
      fromXxhash: formik.values.toXxhash,
    };

    formik.setValues(updatedValues);
  };

  return {
    formik,
    isLoading,
    swapXxhashValues,
  }
}