import React, { FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CardList from 'components/UI/CardList';
import Button from 'components/UI/Buttons/buttons';
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import { TFormikProps } from 'domains/offer/model/types';
import { Form, FormikProvider, useFormik } from 'formik';
import { fetchAllClients } from 'domains/clients/reducer';
import { useDispatchApp } from 'redux/rootSelectors';
import { useClientsAllInfo } from 'domains/clients/model/selectors';
import { addOffer } from 'domains/offer/model/actions';
import { Prompt, useHistory } from 'react-router';
import { useValidateOffer } from 'domains/offer/model/hooks/useValidateOffer';
import deepEqual from 'deep-equal';
import css from '../styles.module.scss';
import MainSettings from '../components/MainSettings';
import Links from '../components/Links';
import UploadImg from '../components/UploadImg';

const CreateOffer: FC = () => {
  const [edited, setEdited] = useState(true);

  const [isLoadingCreate, setisLoadingCreate] = useState(false);

  const history = useHistory();

  const goBack = () => {
    history.push('/main/offers');
  };

  const { t, i18n } = useTranslation();
  const dispatch = useDispatchApp();
  const { data: clientsList } = useClientsAllInfo();

  const { validate } = useValidateOffer();

  const initialValues: TFormikProps = {
    title: '',
    clients: [],
    status: '',
    link: '',
    adomain: '',
    pixel: '',
    icon: '',
  };

  const formik = useFormik<TFormikProps>({
    initialValues,
    validate,
    onSubmit: (values) => {
      setisLoadingCreate(true)
      const params = {
        data: {
          ...values,
          clients: values.clients.map((client) => client.value),
        },
        callbacks: {
          goBack,
          setEdited,
          setisLoadingCreate,
        },
      };

      const valuesKeys = Object.keys(params.data);

      valuesKeys.forEach(
        (key) => params.data[key] === '' && delete params.data[key],
      );
      dispatch(addOffer(params));
    },
  });

  const uploadHandler = (url: string | null) => {
    formik.setFieldValue('icon', url || '');
  };

  useEffect(() => {
    if (clientsList) return;
    dispatch(fetchAllClients({}));
  }, []);

  const links = useMemo(
    () => [
      {
        title: t(`offers_page.offers`),
        url: `/main/offers`,
        type: 'home',
      },
      {
        title: t(`offers_page.creating_offer`),
        url: `заглушка`,
        type: 'end',
      },
    ],
    [i18n.language],
  );

  return (
    <>
      <Helmet>
        <title>{t(`offers_page.creating_offer`)}</title>
      </Helmet>

      <div className={css.header}>
        <div className="container">
          <Breadcrumbs type="Custom" linksCustom={links} />
          <div className={css.status}>
            <h1 className={css.title}>{t(`offers_page.creating_offer`)}</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className={css.container}>
          <CardList>
            <FormikProvider value={formik}>
              <Form>
                <MainSettings formik={formik} />
                <Links formik={formik} />
                <div className={css.actions}>
                  <Button
                    title={t('campaigns_page.create_campaign.cancel_btn')}
                    transparent
                    height={40}
                    buttonAttributes={{
                      onClick: () => goBack(),
                      type: 'button',
                    }}
                  />
                  <Button
                    title={t('campaigns_page.create_campaign.create_btn')}
                    height={40}
                    buttonAttributes={{
                      disabled: isLoadingCreate || Boolean(
                        (formik.touched.title && formik.errors.title) ||
                          (formik.touched.clients && formik.errors.clients) ||
                          (formik.touched.link && formik.errors.link) ||
                          (formik.touched.adomain && formik.errors.adomain) ||
                          (formik.touched.pixel && formik.errors.pixel),
                      ),
                      onClick: () => formik.handleSubmit(),
                      type: 'button',
                    }}
                  />
                </div>
              </Form>
            </FormikProvider>
          </CardList>
          <CardList>
            <div>
              <UploadImg uploadHandler={uploadHandler} />
            </div>
          </CardList>
        </div>
        <Prompt
          when={edited && !deepEqual(formik.values, initialValues)}
          message={t('just_words.exit_page_currect')}
        />
      </div>
    </>
  );
};

export default CreateOffer;
