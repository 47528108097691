import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import cn from 'classnames';
import { FiArrowLeft } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { useOnScroll } from 'hooks/useOnScroll';
import { useUserTheme } from 'domains/user/model/selectors';
import css from './styles.module.scss';
import { TGoBack } from '../../types/general';

type Props = RouteComponentProps & {
  back?: TGoBack;
  /** разрешает переход по ссылке */
  disabled?: boolean;
  /* Разрешить расширяться в зависимости от настроек экрана */
  extendable?: boolean;
};

const NavigationHeader: FC<Props> = ({
  history,
  back = null,
  children,
  disabled = true,
  extendable = false,
}) => {
  const { t } = useTranslation();
  const { fixed } = useOnScroll(40);
  const { isThemeExtended, isThemeFull } = useUserTheme();

  return (
    <div
      className={cn(css['block-header'], {
        isFixed: fixed,
      })}
    >
      <div className={css.header}>
        <div
          className={cn('container resized', {
            isCenter: !back,
            extend: isThemeExtended && extendable,
            full: isThemeFull && extendable,
          })}
        >
          {back && (
            <button
              type="button"
              onClick={() => {
                if (disabled) {
                  if (!back.back) {
                    history.push(back.to);
                  } else {
                    history.goBack();
                  }
                }
              }}
              className={css.back}
            >
              <FiArrowLeft size={24} color="#fff" />
              <span>
                {back.title ||
                  t('campaigns_page.campaign_settings.black_menu.Back')}
              </span>
            </button>
          )}
          {!back && <div />}
          <div className={css.tabs}>{children}</div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(NavigationHeader);
