import React, { FC, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
  FiAlertCircle,
  FiXCircle,
  FiClock,
  FiRefreshCw,
  FiExternalLink,
  FiCheck,
  FiMinusCircle,
  FiMaximize2,
} from 'react-icons/fi';
import { ReactSVG } from 'react-svg';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Button from 'components/UI/Buttons/buttons';
import Loader from 'components/UI/Loader';
import Tooltip from 'components/UI/Tooltip';
import CheckCircle from 'assets/check-circle.svg';
import Input from 'components/UI/Input/input';
import { useDispatchApp } from 'redux/rootSelectors';
import i18next from 'i18next';
import copy from 'copy-to-clipboard';
import Dropdown from './Dropdown';
import { useStatuses } from '../../statuses';
import css from './styles.module.scss';
import { CreativeDetail, Moderation as TModeration } from '../../../types';
import { useModeration } from './hooks';
import { sentModerationRequest } from '../../../reduser';
import {
  isGardHTMLType,
  isGardVideoInlineType,
  isGardYandexType,
  isGardVideoVastType,
  isGardPushType,
  isGardTeaserType,
  isGardMultiFormatType,
  isGardCompanionType,
} from '../../hooks';
import ModalInfo from './ModalInfo';
import CheckListItem from './CheckListItem';
import FullscreenCodeModal from '../../../../../components/UI/Code/FullscreenCodeModal';
import { toastSuccess } from '../../../../../utils/ToastUtils/toastUtils';
import {
  htmlListInternal,
  htmlList,
  yandexInternalList,
  yandexYandexList,
  generalList,
  generalVideoInline,
  generalVideoVast,
  pushList,
  videoGeneralGoogleList,
  linkAndDomainList,
  teaserList,
  categoryAndLanguage,
  multiFormatList,
  dict,
  generalCompanion,
} from '../../../model/shared/moderation';
import { useGetFieldConfigValue } from '../../hooks/useGetFieldConfigValue';

interface Props {
  creative: CreativeDetail;
  getCreative: () => void;
  loading: boolean;
  allStatusIsLoading: boolean;
}

const Moderation: FC<Props> = ({
  creative,
  allStatusIsLoading,
  loading,
  getCreative,
}) => {
  const { type, size, data, xxhash, check_ERID } = creative;

  const { statusModeration } = useStatuses();
  const dispatch = useDispatchApp();
  const { height, width, moderation, moderationResult } = useModeration(size);

  const getTdStatusIcon = (key: keyof TModeration) => {
    if (
      !moderation ||
      moderation[key].status === 'AWAITING_DATA_COMPLETION' ||
      moderation[key].status === 'UNKNOWN' ||
      moderation[key].status === 'NOT_SENT' ||
      moderation[key].status === 'READY'
    ) {
      return <FiAlertCircle size={22} color="#FF9C08" />;
    }

    if (moderation[key].status === 'ON_MODERATION') {
      return <FiClock size={22} color="#FF9C08" />;
    }

    if (moderation[key].status === 'APPROVED') {
      return <ReactSVG src={CheckCircle} className={css.CheckCircleIcon} />;
    }

    return <FiXCircle size={22} color="#FF3636" />;
  };

  const isShowErid = useMemo(
    () => !!(check_ERID && Object.values(check_ERID).every((item) => item)),
    [check_ERID],
  );

  const getModerationChecklistIcon = (condition: boolean) => {
    if (condition) {
      return <FiCheck color="#149C08" size={16} />;
    }
    return <FiMinusCircle color="#828282" size={16} />;
  };

  const keysModeration = moderation ? Object.keys(moderation) : [];

  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [hideBtnMore, setHideBtnMore] = useState<boolean>(false);
  const [restrictions, setRestrictions] = useState<string | undefined>('');
  const [minimized, setMinimized] = useState<boolean>(false);
  const [moderationType, setModerationType] = useState<{
    isShowModal: boolean;
    type: string;
    external_id: string;
  }>({ isShowModal: false, type: '', external_id: '' });
  const erid = useGetFieldConfigValue('erid', type);
  const eridId = erid?.id;

  useEffect(() => {
    keysModeration.forEach((key) => {
      if (moderation) {
        if (key === 'yandex') {
          if (moderation[key].status === 'DISAPPROVED') {
            setHideBtnMore(true);
            setRestrictions(() => moderation[key].restrictions);
          }
        }
        if (key === 'google') {
          setHideBtnMore(true);
          setRestrictions(() => moderation[key].restrictions);
        }
        setModerationType(() => ({
          isShowModal: false,
          type: key,
          external_id:
            moderation[key] && moderation[key].external_id
              ? moderation[key].external_id
              : '',
        }));

        if (moderation[key].message && moderation[key].message.length > 90) {
          setMinimized(true);
        }
      }
    });
  }, [moderation]);

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onCloseModalHelp = () => {
    setModerationType(() => ({
      ...moderationType,
      isShowModal: false,
    }));
  };

  return (
    <div className={css.moderation}>
      <div className={css.header}>
        {loading || allStatusIsLoading ? (
          <div className={css.loader}>
            <Loader />
          </div>
        ) : (
          <Tooltip title={<FiRefreshCw size={16} />} onClick={getCreative}>
            <p>{t('update_btn')}</p>
          </Tooltip>
        )}
      </div>
      <div className={css.list}>
        {keysModeration.length > 0 &&
          keysModeration.map((key) => (
            <div className={css.line} key={key}>
              {loading ? (
                <div className={css.preloader}>
                  <div className={css.string} />
                  <br />
                  <div className={css.string} />
                  <div className={css.string} style={{ width: '50%' }} />
                </div>
              ) : (
                <Dropdown
                  customOpen={
                    !!moderationResult &&
                    moderationResult.type.toLocaleLowerCase() === key
                  }
                  title={
                    <div className={css.title}>
                      <span>{dict[key]}</span>{' '}
                      {getTdStatusIcon(key as keyof TModeration)}
                    </div>
                  }
                >
                  <div>
                    <div className={css.description}>
                      <div className={css.text}>
                        <div className={css.status}>
                          <span>
                            {moderation &&
                              moderation[key].status &&
                              statusModeration(moderation[key].status).title}
                          </span>
                        </div>
                        <div className={css.date}>
                          <span>
                            {moderation &&
                              moderation &&
                              moment(moderation[key].date).format(
                                'HH:mm/ DD.MM.YYYY',
                              )}
                          </span>
                          {moderation && moderation[key].external_id && (
                            <>
                              <br />
                              <span>
                                {moderation && moderation[key].external_id}
                              </span>
                            </>
                          )}
                        </div>
                        {moderation &&
                          isGardHTMLType(data) &&
                          type === 'HTML' &&
                          moderation[key].status ===
                            'AWAITING_DATA_COMPLETION' &&
                          key === 'internal' && (
                            <div className={css.checklist}>
                              {htmlListInternal.map(
                                ({ prefix, field, isData }) => (
                                  <CheckListItem
                                    isData={isData}
                                    key={field}
                                    field={field}
                                    prefix={prefix}
                                    creativeData={creative}
                                  />
                                ),
                              )}
                              <div className={css.item}>
                                <div className={css.icon}>
                                  {getModerationChecklistIcon(
                                    !!width && !!height,
                                  )}
                                </div>
                                <div className={css.text}>
                                  <span>{t('creative.widget_text.size')}</span>
                                </div>
                              </div>
                              {isShowErid && (
                                <div className={css.item}>
                                  <div className={css.icon}>
                                    {getModerationChecklistIcon(
                                      eridId
                                        ? !!creative.config_values[eridId]
                                        : false,
                                    )}
                                  </div>
                                  <div className={css.text}>
                                    <span>
                                      {t('creative.widget_text.ID_ERID')}
                                    </span>
                                  </div>
                                </div>
                              )}
                              {generalList.map(({ prefix, field, isData }) => (
                                <CheckListItem
                                  isData={isData}
                                  key={field}
                                  field={field}
                                  prefix={prefix}
                                  creativeData={creative}
                                />
                              ))}
                            </div>
                          )}
                        {moderation &&
                          type === 'YANDEX' &&
                          isGardYandexType(data) &&
                          moderation[key].status ===
                            'AWAITING_DATA_COMPLETION' &&
                          key === 'internal' && (
                            <div className={css.checklist}>
                              {yandexInternalList.map(
                                ({ prefix, field, isData }) => (
                                  <CheckListItem
                                    key={field}
                                    isData={isData}
                                    field={field}
                                    prefix={prefix}
                                    creativeData={creative}
                                  />
                                ),
                              )}
                            </div>
                          )}
                        {moderation &&
                          type === 'PUSH' &&
                          moderation[key].status ===
                            'AWAITING_DATA_COMPLETION' &&
                          isGardPushType(data) && (
                            <div className={css.checklist}>
                              {key === 'internal' && (
                                <>
                                  {pushList.map(({ prefix, field, isData }) => (
                                    <CheckListItem
                                      key={field}
                                      isData={isData}
                                      field={field}
                                      prefix={prefix}
                                      creativeData={creative}
                                    />
                                  ))}
                                </>
                              )}
                            </div>
                          )}
                        {moderation &&
                          type === 'MULTI_FORMAT' &&
                          moderation[key].status ===
                            'AWAITING_DATA_COMPLETION' &&
                          isGardMultiFormatType(data) && (
                            <div className={css.checklist}>
                              {key === 'internal' && (
                                <>
                                  {multiFormatList.map(
                                    ({ prefix, field, isData }) => (
                                      <CheckListItem
                                        key={field}
                                        isData={isData}
                                        field={field}
                                        prefix={prefix}
                                        creativeData={creative}
                                      />
                                    ),
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        {moderation &&
                          type === 'TEASER' &&
                          moderation[key].status ===
                            'AWAITING_DATA_COMPLETION' &&
                          isGardTeaserType(data) && (
                            <div className={css.checklist}>
                              {key === 'internal' && (
                                <>
                                  {isShowErid && (
                                    <div className={css.item}>
                                      <div className={css.icon}>
                                        {getModerationChecklistIcon(
                                          eridId
                                            ? !!creative.config_values[eridId]
                                            : false,
                                        )}
                                      </div>
                                      <div className={css.text}>
                                        <span>
                                          {t('creative.widget_text.ID_ERID')}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {teaserList.map(
                                    ({ prefix, field, isData }) => (
                                      <CheckListItem
                                        key={field}
                                        isData={isData}
                                        field={field}
                                        prefix={prefix}
                                        creativeData={creative}
                                      />
                                    ),
                                  )}
                                </>
                              )}
                            </div>
                          )}

                        {moderation &&
                          type === 'VIDEO_INLINE' &&
                          isGardVideoInlineType(data) &&
                          moderation[key].status ===
                            'AWAITING_DATA_COMPLETION' &&
                          (key === 'internal' || key === 'google') && (
                            <div className={css.checklist}>
                              {key === 'google' && (
                                <>
                                  {videoGeneralGoogleList.map(
                                    ({ prefix, field, isData }) => (
                                      <CheckListItem
                                        key={field}
                                        isData={isData}
                                        field={field}
                                        prefix={prefix}
                                        creativeData={creative}
                                      />
                                    ),
                                  )}
                                </>
                              )}
                              {isShowErid && (
                                <div className={css.item}>
                                  <div className={css.icon}>
                                    {getModerationChecklistIcon(
                                      eridId
                                        ? !!creative.config_values[eridId]
                                        : false,
                                    )}
                                  </div>
                                  <div className={css.text}>
                                    <span>
                                      {t('creative.widget_text.ID_ERID')}
                                    </span>
                                  </div>
                                </div>
                              )}
                              {generalVideoInline.map(
                                ({ prefix, field, isData }) => (
                                  <CheckListItem
                                    key={field}
                                    isData={isData}
                                    field={field}
                                    prefix={prefix}
                                    creativeData={creative}
                                  />
                                ),
                              )}
                              <div className={css.item}>
                                <div className={css.icon}>
                                  {getModerationChecklistIcon(
                                    !!width && !!height,
                                  )}
                                </div>
                                <div className={css.text}>
                                  <span>{t('creative.widget_text.size')}</span>
                                </div>
                              </div>
                              {linkAndDomainList.map(
                                ({ prefix, field, isData }) => (
                                  <CheckListItem
                                    key={field}
                                    isData={isData}
                                    field={field}
                                    prefix={prefix}
                                    creativeData={creative}
                                  />
                                ),
                              )}
                              {key === 'internal' && (
                                <>
                                  {categoryAndLanguage.map(
                                    ({ prefix, field, isData }) => (
                                      <CheckListItem
                                        key={field}
                                        isData={isData}
                                        field={field}
                                        prefix={prefix}
                                        creativeData={creative}
                                      />
                                    ),
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        {moderation &&
                          type === 'COMPANION' &&
                          isGardCompanionType(data) &&
                          moderation[key].status ===
                            'AWAITING_DATA_COMPLETION' &&
                          (key === 'internal' || key === 'google') && (
                            <div className={css.checklist}>
                              {key === 'google' && (
                                <>
                                  {videoGeneralGoogleList.map(
                                    ({ prefix, field, isData }) => (
                                      <CheckListItem
                                        key={field}
                                        isData={isData}
                                        field={field}
                                        prefix={prefix}
                                        creativeData={creative}
                                      />
                                    ),
                                  )}
                                </>
                              )}
                              {isShowErid && (
                                <div className={css.item}>
                                  <div className={css.icon}>
                                    {getModerationChecklistIcon(
                                      eridId
                                        ? !!creative.config_values[eridId]
                                        : false,
                                    )}
                                  </div>
                                  <div className={css.text}>
                                    <span>
                                      {t('creative.widget_text.ID_ERID')}
                                    </span>
                                  </div>
                                </div>
                              )}
                              {generalCompanion.map(
                                ({ prefix, field, isData }) => (
                                  <CheckListItem
                                    key={field}
                                    isData={isData}
                                    field={field}
                                    prefix={prefix}
                                    creativeData={creative}
                                  />
                                ),
                              )}
                              <div className={css.item}>
                                <div className={css.icon}>
                                  {getModerationChecklistIcon(
                                    !!width && !!height,
                                  )}
                                </div>
                                <div className={css.text}>
                                  <span>{t('creative.widget_text.size')}</span>
                                </div>
                              </div>
                              {linkAndDomainList.map(
                                ({ prefix, field, isData }) => (
                                  <CheckListItem
                                    key={field}
                                    isData={isData}
                                    field={field}
                                    prefix={prefix}
                                    creativeData={creative}
                                  />
                                ),
                              )}
                              {key === 'internal' && (
                                <>
                                  {categoryAndLanguage.map(
                                    ({ prefix, field, isData }) => (
                                      <CheckListItem
                                        key={field}
                                        isData={isData}
                                        field={field}
                                        prefix={prefix}
                                        creativeData={creative}
                                      />
                                    ),
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        {moderation &&
                          type === 'VIDEO_VAST' &&
                          isGardVideoVastType(data) &&
                          moderation[key].status ===
                            'AWAITING_DATA_COMPLETION' &&
                          (key === 'internal' || key === 'google') && (
                            <div className={css.checklist}>
                              {key === 'google' && (
                                <>
                                  {videoGeneralGoogleList.map(
                                    ({ prefix, field, isData }) => (
                                      <CheckListItem
                                        key={field}
                                        isData={isData}
                                        field={field}
                                        prefix={prefix}
                                        creativeData={creative}
                                      />
                                    ),
                                  )}
                                </>
                              )}
                              {isShowErid && (
                                <div className={css.item}>
                                  <div className={css.icon}>
                                    {getModerationChecklistIcon(
                                      eridId
                                        ? !!creative.config_values[eridId]
                                        : false,
                                    )}
                                  </div>
                                  <div className={css.text}>
                                    <span>
                                      {t('creative.widget_text.ID_ERID')}
                                    </span>
                                  </div>
                                </div>
                              )}
                              {generalVideoVast.map(
                                ({ prefix, field, isData }) => (
                                  <CheckListItem
                                    key={field}
                                    isData={isData}
                                    field={field}
                                    prefix={prefix}
                                    creativeData={creative}
                                  />
                                ),
                              )}
                              <div className={css.item}>
                                <div className={css.icon}>
                                  {getModerationChecklistIcon(
                                    !!width && !!height,
                                  )}
                                </div>
                                <div className={css.text}>
                                  <span>{t('creative.widget_text.size')}</span>
                                </div>
                              </div>
                              {linkAndDomainList.map(
                                ({ prefix, field, isData }) => (
                                  <CheckListItem
                                    key={field}
                                    isData={isData}
                                    field={field}
                                    prefix={prefix}
                                    creativeData={creative}
                                  />
                                ),
                              )}
                              {key === 'internal' && (
                                <>
                                  {categoryAndLanguage.map(
                                    ({ prefix, field, isData }) => (
                                      <CheckListItem
                                        key={field}
                                        isData={isData}
                                        field={field}
                                        prefix={prefix}
                                        creativeData={creative}
                                      />
                                    ),
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        {moderation &&
                          type === 'CLICKUNDER' &&
                          moderation[key].status ===
                            'AWAITING_DATA_COMPLETION' &&
                          (key === 'internal' || key === 'google') && (
                            <div className={css.checklist}>
                              {isShowErid && (
                                <div className={css.item}>
                                  <div className={css.icon}>
                                    {getModerationChecklistIcon(
                                      eridId
                                        ? !!creative.config_values[eridId]
                                        : false,
                                    )}
                                  </div>
                                  <div className={css.text}>
                                    <span>
                                      {t('creative.widget_text.ID_ERID')}
                                    </span>
                                  </div>
                                </div>
                              )}
                              {linkAndDomainList.map(
                                ({ prefix, field, isData }) => (
                                  <CheckListItem
                                    key={field}
                                    isData={isData}
                                    field={field}
                                    prefix={prefix}
                                    creativeData={creative}
                                  />
                                ),
                              )}
                              {key === 'internal' && (
                                <>
                                  {categoryAndLanguage.map(
                                    ({ prefix, field, isData }) => (
                                      <CheckListItem
                                        key={field}
                                        isData={isData}
                                        field={field}
                                        prefix={prefix}
                                        creativeData={creative}
                                      />
                                    ),
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        {moderation &&
                          type === 'HTML' &&
                          isGardHTMLType(data) &&
                          moderation[key].status ===
                            'AWAITING_DATA_COMPLETION' &&
                          key === 'google' && (
                            <div className={css.checklist}>
                              {htmlList.map(({ prefix, field, isData }) => (
                                <CheckListItem
                                  key={field}
                                  isData={isData}
                                  field={field}
                                  prefix={prefix}
                                  creativeData={creative}
                                />
                              ))}
                              <div className={css.item}>
                                <div className={css.icon}>
                                  {getModerationChecklistIcon(
                                    !!width && !!height,
                                  )}
                                </div>
                                <div className={css.text}>
                                  <span>{t('creative.widget_text.size')}</span>
                                </div>
                              </div>
                              {linkAndDomainList.map(
                                ({ prefix, field, isData }) => (
                                  <CheckListItem
                                    key={field}
                                    isData={isData}
                                    field={field}
                                    prefix={prefix}
                                    creativeData={creative}
                                  />
                                ),
                              )}
                            </div>
                          )}
                        {moderation &&
                          isGardYandexType(data) &&
                          moderation[key].status ===
                            'AWAITING_DATA_COMPLETION' &&
                          key === 'yandex' && (
                            <div className={css.checklist}>
                              {yandexYandexList.map(
                                ({ prefix, field, isData }) => (
                                  <CheckListItem
                                    key={field}
                                    isData={isData}
                                    field={field}
                                    prefix={prefix}
                                    creativeData={creative}
                                  />
                                ),
                              )}
                            </div>
                          )}

                        {moderation &&
                          moderation[key].status === 'DISAPPROVED' && (
                            <>
                              <div className={css.hint}>
                                <span>
                                  {minimized
                                    ? `${moderation[key].message.slice(
                                        0,
                                        90,
                                      )}... `
                                    : moderation[key].message}
                                  {moderation[key].message.length > 90 &&
                                    (minimized ? (
                                      <div
                                        className={css.open}
                                        onClick={() => {
                                          setMinimized(false);
                                        }}
                                      >
                                        {t(`creative.moderation.modal.expand`)}
                                      </div>
                                    ) : (
                                      <div
                                        className={cn(css.open, css.block)}
                                        onClick={() => {
                                          setMinimized(true);
                                        }}
                                      >
                                        {t(
                                          `creative.moderation.modal.collapse`,
                                        )}
                                      </div>
                                    ))}
                                </span>
                              </div>
                            </>
                          )}
                        {hideBtnMore &&
                          key !== 'internal' &&
                          (key === 'google' ||
                            (moderation &&
                              moderation &&
                              moderation[key].status === 'DISAPPROVED' &&
                              key === 'yandex')) && (
                            <div className={css.more}>
                              <div
                                className={css.link}
                                onClick={() => setOpenModal(true)}
                              >
                                <span>{t('more_btn')}</span>
                                <FiMaximize2 size={16} />
                              </div>
                            </div>
                          )}

                        {((moderation &&
                          key === 'yandex' &&
                          moderation[key].status === 'DISAPPROVED') ||
                          (moderation &&
                            key === 'google' &&
                            (moderation[key].status === 'DISAPPROVED' ||
                              moderation[key].status ===
                                'CONDITIONALLY_APPROVED'))) && (
                          <>
                            {key === 'google' && (
                              <a
                                href="https://support.google.com/authorizedbuyers/answer/6272857?hl=ru"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={css.link}
                              >
                                <span>{t('creative.DocumentationGoogle')}</span>
                                <FiExternalLink size={16} />
                              </a>
                            )}
                            <div
                              onClick={() =>
                                setModerationType(() => ({
                                  ...moderationType,
                                  isShowModal: true,
                                }))
                              }
                              className={css.link}
                            >
                              <span className={css.help}>
                                {t(`creative.moderation.modal.needHelp`)}
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {moderationResult &&
                      moderationResult.data.message &&
                      moderationResult.type.toLocaleLowerCase() === key && (
                        <div className={css.code}>
                          <CodeMirror
                            autoCursor={false}
                            autoScroll={false}
                            className={css.editor}
                            value={moderationResult.data.message}
                            editorDidMount={(ed) => ed.refresh()}
                            options={{
                              mode: 'javascript',
                              lineNumbers: false,
                              lineWrapping: true,
                              readOnly: true,
                              autocorrect: true,
                            }}
                          />
                        </div>
                      )}
                  </div>

                  {moderation &&
                    moderation[key].status === 'READY' &&
                    type !== 'VIDEO_INLINE' &&
                    type !== 'HTML' &&
                    type !== 'YANDEX' &&
                    type !== 'VIDEO_VAST' &&
                    type !== 'COMPANION' && (
                      <div className={css.button}>
                        <Button
                          title={t('creative.crea_statuses.s10')}
                          height={40}
                          transparent
                          buttonAttributes={{
                            onClick: () => {
                              dispatch(
                                sentModerationRequest({
                                  creative_id: xxhash,
                                  services: dict[key],
                                  type,
                                }),
                              );
                            },
                          }}
                        />
                      </div>
                    )}
                </Dropdown>
              )}
            </div>
          ))}
      </div>
      {openModal && (
        <FullscreenCodeModal
          isOpen={openModal}
          onClose={onCloseModal}
          field={{
            name: 'moderation',
            onChange: () => undefined,
            onBlur: () => undefined,
            value: restrictions,
          }}
          disabled
          handlerCopy={() => {
            copy(`${restrictions}`);
            toastSuccess(i18next.t(`alerts.a2`));
          }}
        />
      )}
      {moderationType.isShowModal && (
        <ModalInfo
          type={moderationType.type}
          onClose={onCloseModalHelp}
          isOpen={moderationType.isShowModal}
        >
          <Input
            label={t(`creative.moderation.modal.labelInput`)}
            inputAttributes={{
              name: 'title',
              value: moderationType.external_id,
            }}
            disabled
            isCopy
          />
        </ModalInfo>
      )}
    </div>
  );
};

export default Moderation;
