import { shallowEqual, useSelector } from 'react-redux';
import { useDispatchApp } from 'redux/rootSelectors';
import { CreativeState } from 'domains/creative/reduser';
import { AppState } from 'redux/rootReducer';
import { CampaignsState, setListSort } from 'domains/campaigns/reducer';

type TFUseCampaignListSortInfo = () => {
  isShowSortFilter: boolean;
  sort: CampaignsState['listSort']['sort'];
  key: CampaignsState['listSort']['key'];
  handlerSort: (p: CreativeState['listSort']) => void;
};

export const useCampaignListSortInfo: TFUseCampaignListSortInfo = () => {
  const dispatch = useDispatchApp();

  const {
    listSort: { sort, key },
    isShowSortFilter,
  } = useSelector<AppState, CampaignsState>(
    ({ campaignsReducer }) => campaignsReducer,
    shallowEqual,
  );

  const handlerSort = (p: CreativeState['listSort']) => {
    dispatch(setListSort(p));
  };

  return {
    isShowSortFilter,
    sort,
    key,
    handlerSort,
  };
};
