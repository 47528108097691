import { createAction } from '@reduxjs/toolkit';
import { TGetCreativeListParams, TTargetItemsParams } from './types';

export const fetchTargetItemsData = createAction<TTargetItemsParams>(
  'analysis/fetchTargetItemsData',
);

export const fetchCreativeList = createAction<TGetCreativeListParams>(
  'analysis/fetchCreativeList',
);
