import { TGetListDataItem } from 'domains/blackList/types';
import { useEffect, useState } from 'react';
import { TTableSort } from 'types/general';

interface IProps {
  blDomains: TGetListDataItem[];
  fileStatus: number;
  sortType: TTableSort['sort'];
}

interface IReturnData {
  sortedDomains: TGetListDataItem[];
}

export const useSortDomains = ({
  blDomains,
  fileStatus,
  sortType,
}: IProps): IReturnData => {
  const [sortedDomains, setSortedDomains] = useState<TGetListDataItem[]>([]);

  const sortArr = (key: string, sort: 'ASC' | 'DESC' | null) => {
    let sortedArr = [...blDomains];
    if (sort === 'ASC') {
      sortedArr?.sort((a, b) => {
        if (key === 'date_create' || key === 'date_delete') {
          return +new Date(a[key] || '') - +new Date(b[key] || '');
        }
        return a[key] - b[key];
      });
    } else if (sort === 'DESC') {
      sortedArr?.sort((a, b) => {
        if (key === 'date_create' || key === 'date_delete') {
          return +new Date(b[key] || '') - +new Date(a[key] || '');
        }
        return b[key] - a[key];
      });
    } else {
      sortedArr = [...blDomains];
    }
    setSortedDomains(sortedArr);
  };

  useEffect(() => {
    sortArr(fileStatus === 1 ? 'date_create' : 'date_delete', sortType);
  }, [sortType, blDomains, sortType]);
  return {
    sortedDomains,
  };
};
