import React, { FC } from 'react';
import { ScrollSyncNode } from 'scroll-sync-react';
import cn from 'classnames';
import { ChartStatisticItem, WeekStatisticItem } from 'domains/campaigns/types';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { numFormat } from 'utils/toFixedNumber';
import css from './styles.module.scss';
import { tableProps } from './types';

type TFRow = {
  totalData?: WeekStatisticItem | null;
  statData?: ChartStatisticItem;
  columns: {
    name: string;
    data: string;
  }[];
  paddingScroll?: boolean;
  mode: tableProps['mode'];
  /* Ширина первого столбца */
  extend?: number;
  /* Голова дерева */
  group?: boolean;
  /* Содержимое дерева */
  tree?: boolean;
};

const Row: FC<TFRow> = ({
  statData,
  columns,
  totalData,
  paddingScroll,
  mode,
  extend,
  group,
  tree,
}) => {
  const { t, i18n } = useTranslation();
  const getStaticColumnVal = (data: ChartStatisticItem | undefined) => {
    if (data?.date) {
      return format(new Date(data.date), 'dd.MM.yyyy');
    }
    if (data?.internal_id) {
      return data.internal_id;
    }
    return null;
  };

  return (
    <div
      className={cn(css.table_row, {
        _isTotal: totalData,
        _isPaddingScroll: paddingScroll,
        _isGroup: group,
        _isTree: tree,
      })}
    >
      <div
        className={css.table_row_static}
        style={{
          width: extend,
        }}
      >
        <div className={css.cell}>
          {mode === 'agency' ? (
            <span title={statData?.title}>
              {statData?.title || t(`charts.total`)}
            </span>
          ) : (
            <span>{getStaticColumnVal(statData) || t(`charts.total`)}</span>
          )}
        </div>
      </div>
      <ScrollSyncNode group="a">
        <div className={css.table_row_scroll}>
          {mode === 'agency' && (
            <div
              className={cn(css.cell, {
                _isLongForAgency: mode === 'agency',
              })}
              title={getStaticColumnVal(statData) || t(`charts.total`)}
            >
              <span>{getStaticColumnVal(statData)}</span>
            </div>
          )}
          {columns.map((column) => (
            <div className={cn(css.cell, '_isAlignRight')} key={column.data}>
              {statData && (
                <span>
                  {numFormat(i18n.language, statData[column.data]) === '0'
                    ? '-'
                    : numFormat(i18n.language, statData[column.data])}
                </span>
              )}
              {totalData && (
                <span>
                  {numFormat(i18n.language, totalData[column.data]) === '0'
                    ? '-'
                    : numFormat(i18n.language, totalData[column.data])}
                </span>
              )}
            </div>
          ))}
        </div>
      </ScrollSyncNode>
    </div>
  );
};

export default Row;
