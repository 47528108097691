import { useCallback, useState } from 'react';

type TFUseTooltip = () => {
  fixTopPadding: boolean;
  hintRef: (node: HTMLDivElement | null) => void;
  setSidePadding: () => void;
  fixLeftPadding: boolean;
  fixRightPadding: boolean;
  fixbottomPadding: boolean;
};

export const useToolTip: TFUseTooltip = () => {
  const [hintElement, setHintElement] = useState<HTMLDivElement | null>(null);
  const [fixLeftPadding, setFixLeftPadding] = useState<boolean>(false);
  const [fixRightPadding, setFixRightPadding] = useState<boolean>(false);
  const [fixTopPadding, setFixTopPadding] = useState<boolean>(false);
  const [fixbottomPadding, setFixbottomPadding] = useState<boolean>(false);

  const hintRef = useCallback((node: HTMLDivElement | null) => {
    setHintElement(node);
  }, []);

  const setSidePadding = () => {
    if (hintElement) {
      if (!fixbottomPadding) {
        if (hintElement.getBoundingClientRect().bottom > window.innerHeight) {
          setFixbottomPadding(true);
          setFixTopPadding(false);
        } else setFixbottomPadding(false);
      }
      if (!fixTopPadding) {
        if (hintElement.getBoundingClientRect().top < 0) {
          setFixTopPadding(true);
          setFixbottomPadding(false);
        } else setFixTopPadding(false);
      }
      if (!fixLeftPadding) {
        if (hintElement.getBoundingClientRect().left < 20) {
          setFixLeftPadding(true);
          setFixRightPadding(false);
        } else setFixLeftPadding(false);
      }
      if (!fixRightPadding) {
        if (
          hintElement.getBoundingClientRect().right + 20 >
          window.innerWidth
        ) {
          setFixRightPadding(true);
          setFixLeftPadding(false);
        } else setFixRightPadding(false);
      }
    }
  };

  return {
    fixTopPadding,
    fixLeftPadding,
    fixRightPadding,
    fixbottomPadding,
    hintRef,
    setSidePadding,
  };
};
