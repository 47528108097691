import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { useUserTheme } from 'domains/user/model/selectors';
import NewSelect from 'components/UI/NewSelect';
import LabelField from 'components/UI/LabelField';
import i18n from '../../../../../../i18n';
import css from '../styles.module.scss';
import Card from '../../../../../../components/UI/Card';
import Help from '../../../../../../components/Help';
import ToggleSwitch from '../../../../../../components/UI/ToggleSwitch';
import { checkedLocalization } from '../../../../../../utils/checkedLocalization';
import { selectDefault } from '../../../../../../utils/selectDefault';
import { CampaignGeneralComponentProps } from '../../../../types';
import { useCampaignAdvancedOptimizationInfo } from '../../../../model/selectors';

type AdvancedOptimizationProps = Pick<
  CampaignGeneralComponentProps,
  'formik' | 'setField' | 'savedField' | 'autoSubmit'
> & {
  advancedList: ReturnType<typeof useCampaignAdvancedOptimizationInfo>['data'];
};

const AdvancedOptimization: FC<AdvancedOptimizationProps> = ({
  formik,
  advancedList,
  setField,
  autoSubmit,
}) => {
  const { getValueFromSettingForKey } = useUserTheme();

  return (
    <Card
      noHover
      style={{ padding: '28px 24px' }}
      className={css.card}
      isUseAccess
    >
      <h3>
        {i18n.t('campaigns_page.campaign_settings.moreOptimisation.title')}{' '}
        {getValueFromSettingForKey('HideHelp') && (
          <Help
            width={250}
            title={i18n.t(
              'campaigns_page.campaign_settings.moreOptimisation.title',
            )}
            helpattributes={{
              link: i18n.t(
                'campaigns_page.campaign_settings.campaign_prompt.moreOptimisation.link',
              ),
              sizeIcon: 18,
            }}
          />
        )}
      </h3>
      <ToggleSwitch
        activateText="CPA"
        deactivateText={i18n.t(`clients_page.client_edit.turned_off`)}
        onChange={() => {
          setField('cpa_optimizations', !formik.values.cpa_optimizations);
        }}
        checked={formik.values.cpa_optimizations}
      />
      {formik.values.cpa_optimizations && (
        <div className={css.lists}>
          {advancedList &&
            advancedList.map((item) => (
              <Field
                name={`advanced_optimizations.${item.id}`}
                key={`advanced_optimizations.${item.id}`}
              >
                {({ field, form }: FieldProps) => (
                  <LabelField
                    label={checkedLocalization(
                      `campaigns_page.campaign_settings.moreOptimisation.${item.key}`,
                      item.title,
                    )}
                  >
                    <NewSelect
                      className={css.shortSelect}
                      autoSubmit={autoSubmit}
                      form={form}
                      field={field}
                      selectAttributes={{
                        placeholder: '-',
                        options: [
                          ...item.items.map((i) => ({
                            value: `${i.id}`,
                            label: `${i.title}`,
                          })),
                        ],
                        value: selectDefault(
                          `${formik.values.advanced_optimizations[item.id]}`,
                          item.items.map((i) => ({
                            value: `${i.id}`,
                            label: `${i.title}`,
                          })),
                        ),
                      }}
                      heightCustom={32}
                      viewStyle="Horizontal"
                    />
                  </LabelField>
                )}
              </Field>
            ))}
        </div>
      )}
    </Card>
  );
};
export default AdvancedOptimization;
