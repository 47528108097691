import cn from 'classnames';
import { FiImage } from 'react-icons/fi';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';
import Icons from './Icons';
import Info from './Info';
import { useUploadImage } from './hooks';

export type PUploadImage = {
  mode: 'create' | 'edit';
  onDelLoadImg?: () => void;
  remove_icon?: boolean;
};

const UploadImage: FC<PUploadImage> = ({ mode, onDelLoadImg, remove_icon }) => {
  const { t } = useTranslation();
  const {
    upload,
    error,
    complete,
    imgUrl,
    onChangeFile,
    openMLinkCallback,
    tempImage,
    loadImg,
    tempError,
  } = useUploadImage(mode, remove_icon);

  return (
    <div className={css.upload}>
      <div
        className={cn(css['file-loader'], {
          isUpload: upload,
          isError: error,
          isComplete: complete && !error,
        })}
      >
        {imgUrl ? (
          <div
            className={css['file-loader__image']}
            style={{
              backgroundImage: `url('${imgUrl}')`,
            }}
          />
        ) : (
          <div className={cn(css['file-loader__image'], 'empty')}>
            <FiImage size={24} />
          </div>
        )}
        <Icons />
      </div>

      <div className={css.text}>
        <div className={css.links}>
          <label className={css.file} htmlFor="file">
            <input
              id="file"
              onChange={onChangeFile}
              accept="image/jpeg, image/jpg, image/png, image/svg"
              type="file"
            />
            {t('clients_page.client_create.client_load_img')}
          </label>
          <span>·</span>
          <button type="button" onClick={openMLinkCallback}>
            {t('clients_page.client_create.client_img_link')}
          </button>
        </div>

        <Info
          error={tempError}
          tempFile={tempImage || undefined}
          loadFile={loadImg}
          onDelLoadImg={onDelLoadImg}
        />
      </div>
    </div>
  );
};

export default UploadImage;
