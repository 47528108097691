import { helperCommentPosition, helperHistoryTransform } from "domains/comment/model/helper";
import { IUpdatedHistory } from "domains/historyChanges/model/hooks/useTransformHistoryData";
import { useEffect, useState } from "react";

type TFUseHistoryChangeWidget = (props: {
    list: IUpdatedHistory<string>[],
}) => {
    isShowAllHistory: boolean,
    setIsShowAllHistory: React.Dispatch<React.SetStateAction<boolean>>,
    historyList: any[],
    heightBOX: () => any,
    isShowBtnAllComment: () => boolean
}

export const useHistoryChangeWidget: TFUseHistoryChangeWidget = ({
    list,
}) => {
    /* Сколько по умолчанию видно строк */
    const BASIC_LIMIT_ROW = 5;
    /* Максимальная высота виджета */
    const MAX_SIZE_BOX = 662;

    const [isShowAllHistory, setIsShowAllHistory] = useState(false);

    const [historyList, setHistoryList] = useState<any[]>(() =>
        helperCommentPosition(
            helperHistoryTransform(list),
        ),
    );

    useEffect(() => {
        setHistoryList(helperCommentPosition(
            helperHistoryTransform(list),
        ))
    }, [])

    const heightBOX = () => {
        if (list.length > BASIC_LIMIT_ROW) {
            if (isShowAllHistory) {
                return MAX_SIZE_BOX;
            }
            return historyList.reduce(
                (sum, el, index) => (index < BASIC_LIMIT_ROW ? sum + el.height : sum),
                0,
            ) > MAX_SIZE_BOX
                ? MAX_SIZE_BOX
                : historyList.reduce(
                    (sum, el, index) => (index < BASIC_LIMIT_ROW ? sum + el.height : sum),
                    0,
                );
        }

        return historyList.reduce((sum, el) => sum + el.height, 0) > MAX_SIZE_BOX
            ? MAX_SIZE_BOX
            : historyList.reduce((sum, el) => sum + el.height, 0);
    };

    const isShowBtnAllComment = () => {
        if (!list.length) {
            return true;
        }
        if (historyList.length <= BASIC_LIMIT_ROW && list.length) {
            return false;
        }
        return list.length > BASIC_LIMIT_ROW;
    };

    return {
        isShowAllHistory,
        setIsShowAllHistory,
        historyList: isShowAllHistory
            ? historyList
            : historyList.filter((v, index) => index < BASIC_LIMIT_ROW),
        heightBOX,
        isShowBtnAllComment,
    }
}