import React, { FC } from 'react';
import MultiSelectCheckbox from 'components/UI/MultiSelectCheckbox';
import { FormikProps } from 'formik';
import cn from 'classnames';
import { useClientsAllInfo } from 'domains/clients/model/selectors';
import { SelectField } from 'types/general';
import { useTranslation } from 'react-i18next';
import css from '../../styles.module.scss';

interface IOffersModeProps {
  formik: FormikProps<{ partner_xxhash_list: SelectField[] }>;
}

const OffersMode: FC<IOffersModeProps> = ({ formik }) => {
  const { t } = useTranslation();
  const { data: clientsList } = useClientsAllInfo();

  const offerOptions =
    clientsList?.map((client) => ({
      label: client.title,
      value: client.id,
    })) || null;

  return (
    <>
      <div className={cn(css.settingsItem, css.offers)}>
        <MultiSelectCheckbox
          fieldName="partner_xxhash_list"
          isError={false}
          formik={formik}
          propsValues={offerOptions}
          placeholder={t(`offers_page.select_cliient`)}
        />
      </div>
    </>
  );
};

export default OffersMode;
