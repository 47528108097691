import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from '../../../redux/fetchedData';
import { ContractsList } from './types';

export type contractsState = {
  contractsList: FetchedData<ContractsList>;
};

export const initialState: contractsState = {
  contractsList: genFetchedData<ContractsList>(null),
};

const contractsSlice = createSlice({
  name: 'Contracts',
  initialState,
  reducers: {
    setContractsList(
      state,
      action: PayloadAction<contractsState['contractsList']>,
    ) {
      state.contractsList = action.payload;
    },
  },
});

export const { setContractsList } = contractsSlice.actions;
export const contractsReducer = contractsSlice.reducer;
