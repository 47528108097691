import React, { FC } from 'react';
import { IFilterColumns } from 'domains/campaigns/model/hooks/useGetFilterForCampaigns';
import PreloaderString from '../String';
import css from './styles.module.scss';

interface Props {
  filterColumns?: IFilterColumns[];
  type:
    | 'campaign'
    | 'agency'
    | 'client'
    | 'creative'
    | 'partner'
    | 'user'
    | 'usersApi'
    | 'offer'
    | 'contracts'
    | 'counteragents'
    | 'history';
}

const PreloaderList: FC<Props> = ({ type, filterColumns }) => {
  const COL_RAWS = 4;

  const preloaderStrings = new Array(COL_RAWS);

  for (let i = 0; i < preloaderStrings.length; i += 1) {
    preloaderStrings[i] = (
      <PreloaderString key={i} type={type} filterColumns={filterColumns} />
    );
  }

  return (
    <div className={css.list}>{preloaderStrings.map((string) => string)}</div>
  );
};

export default PreloaderList;
