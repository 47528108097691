import { useEffect, useState } from 'react';

type TUseCampaignHint = (val: boolean) => {
  showHint: boolean;
  closeHint: () => void;
};

export const useCampaignHint: TUseCampaignHint = (val) => {
  const [showHint, setShowHint] = useState<boolean>(val);

  useEffect(() => {
    setShowHint(val);
  }, [val]);

  const closeHint = () => {
    setShowHint(false);
  };

  return {
    showHint,
    closeHint,
  };
};
