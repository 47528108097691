import { useCallback, useEffect, useRef, useState } from 'react';
import { PeriodEnum } from 'types/containers';
import { useDataCampaignsStatisticAndTranslation } from 'domains/campaigns/model/hooks/useDataCampaignsStatisticAndTranslation';
import {
  useCampaignsFilterInfo,
  useCampaignsInfo,
  useCampaignsViewList,
} from '../selectors';
import { TDates } from '../../../../types/general';
import {
  useSearchBreadcrumbsInfo,
  useSearchCampaignsTagsActive,
} from '../../../search/model/selectors';
import { extractMakeGenList } from '../../../../redux/makeGenList';

type TFUseCampaignsDownloadStatisticsProps = {
  period: TDates['period'];
  filtersLabel: Array<{ name: string; data: string }>;
  periodType: string;
  search: string;
};

type TFUseCampaignsDownloadStatistics = (
  args: TFUseCampaignsDownloadStatisticsProps,
) => {
  isDisabled: boolean;
  handleDownload: () => void;
};

export const useCampaignsDownloadStatistics: TFUseCampaignsDownloadStatistics =
  ({ period, periodType, filtersLabel, search }) => {
    const { data: campaignsServer, LTU: LTUCampaigns } = useCampaignsInfo();
    const { listView } = extractMakeGenList(useCampaignsViewList());
    const { data: breadcrumbs, LTU: LTUBreadcrumbs } =
      useSearchBreadcrumbsInfo();

    const { statisticsWeek } = useDataCampaignsStatisticAndTranslation();    

    // начало костыля
    let isKostil = false;
    if (
      breadcrumbs &&
      'Client' in breadcrumbs &&
      breadcrumbs.Client.xxhash === '6566B07B891EE6A0'
    ) {
      isKostil = true;
    }
    // конец костыля

    const filters = useCampaignsFilterInfo();
    const tags = useSearchCampaignsTagsActive();
    const WorkerRef = useRef<Worker | null>(null);

    const [disGetReport, setDisGetReport] = useState(true);

    useEffect(() => {
      if (campaignsServer && breadcrumbs) {
        setDisGetReport(false);
        if (!WorkerRef.current) {
          WorkerRef.current = new Worker(
            '/workers/getReportCampaignsStatistics.js?v=0.02',
          );
        }
      }
    }, [LTUCampaigns, LTUBreadcrumbs]);

    function isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    const handleDownload: ReturnType<TFUseCampaignsDownloadStatistics>['handleDownload'] =
      useCallback(() => {
        const currentData =
          periodType === PeriodEnum.WEEK
            ? campaignsServer?.map((item) => ({
                ...item,
                statistics:
                  statisticsWeek?.find(
                    (statItem) => statItem.xxhash === item.xxhash,
                  )?.items.total || {},
              }))
            : campaignsServer;
        if (WorkerRef.current) {
          WorkerRef.current.postMessage([
            filtersLabel,
            listView,
            currentData,
            filters.length,
            search,
            tags,
            isKostil,
          ]);
          // eslint-disable-next-line no-param-reassign
          WorkerRef.current.onmessage = function (e) {
            const type = isSafari() ? 'application/csv' : 'text/csv';
            const blob = new Blob(['', e.data], { type });

            const URL = window.URL || window.webkitURL;

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute(
              'download',
              `${breadcrumbs?.Client?.title}_list_campains_from_${period.from}_to_${period.to}.tsv`,
            );
            document.body.appendChild(link);
            link.click();
            link.remove();
            setDisGetReport(false);
          };
          // eslint-disable-next-line no-param-reassign
          WorkerRef.current.onerror = function (e) {
            console.error({ e });
            setDisGetReport(false);
          };
        }
      }, [WorkerRef, campaignsServer, listView, tags, search, isKostil, statisticsWeek, periodType]);

    return {
      isDisabled: disGetReport,
      handleDownload,
    };
  };
