import { useEffect, useState } from 'react';
import { ResponseTranslation } from '../../../domains/campaigns/types';

export interface IReturnTranslationData {
  isOrganizationStatus: boolean;
  isAgencyStatus: boolean;
  isClientStatus: boolean;
  isCampaignStatus: boolean;
  isCreativeStatus?: boolean;
  isModerationStatus?: boolean;
  isCampaignPeriod: boolean;
  isCampaignCosts: boolean;
  isBalance: boolean;
  isCreativeData?: boolean;
  isTargetGeo?: boolean;
  isTargetSource?: boolean;
  isCreo: boolean | null;
  isTranslation: boolean | null;
}

export interface IDSPReturnData {
  isDSPCamp: boolean;
  isDSPCreative: boolean;
  isSDPPartner: boolean;
}

type TReturnType = {
  translationData: IReturnTranslationData;
  DSPTranslationData: IDSPReturnData;
};

export const useIsTranslation = (
  data: ResponseTranslation | null,
): TReturnType => {
  const [translationData, setTranslationData] = useState({
    isOrganizationStatus: data?.organization_status === 'ok',
    isAgencyStatus: data?.agency_status === 'ok',
    isClientStatus: data?.client_status === 'ok',
    isCampaignStatus: data?.campaign_status === 'ok',
    isCreativeStatus:
      data?.creative_status === undefined || data?.creative_status === 'ok',
    isModerationStatus:
      data?.moderation_status === undefined || data?.moderation_status === 'ok',
    isCampaignPeriod: data?.campaign_period === 'ok',
    isCampaignCosts: data?.campaign_costs === 'ok',
    isBalance: data?.balance === 'ok',
    isCreativeData:
      data?.creative_data === undefined || data?.creative_data === 'ok',
    isTargetGeo: data?.target_geo === undefined || data?.target_geo === 'ok',
    isTargetSource:
      data?.target_source === undefined || data?.target_source === 'ok',
    isCreo: data && Object.values(data).length >= 12,
    isTranslation: data?.dsp_decision === 'ok',
  });

  const [DSPTranslationData, setDSPTranslationData] = useState({
    isDSPCamp: data?.dsp_camp === 'ok',
    isDSPCreative:
      data?.dsp_creative === undefined || data?.dsp_creative === 'ok',
    isSDPPartner: data?.dsp_partner === 'ok',
  });

  useEffect(() => {
    setTranslationData({
      isOrganizationStatus: data?.organization_status === 'ok',
      isAgencyStatus: data?.agency_status === 'ok',
      isClientStatus: data?.client_status === 'ok',
      isCampaignStatus: data?.campaign_status === 'ok',
      isCampaignPeriod: data?.campaign_period === 'ok',
      isCreativeStatus:
        data?.creative_status === undefined || data?.creative_status === 'ok',
      isModerationStatus:
        data?.moderation_status === undefined ||
        data?.moderation_status === 'ok',
      isCampaignCosts: data?.campaign_costs === 'ok',
      isBalance: data?.balance === 'ok',
      isCreativeData:
        data?.creative_data === undefined || data?.creative_data === 'ok',
      isTargetGeo: data?.target_geo === undefined || data?.target_geo === 'ok',
      isTargetSource:
        data?.target_source === undefined || data?.target_source === 'ok',
      isCreo: data && Object.values(data).length >= 12,
      isTranslation: data?.dsp_decision === 'ok',
    });
    setDSPTranslationData({
      isDSPCamp: data?.dsp_camp === 'ok',
      isDSPCreative:
        data?.dsp_creative === undefined || data?.dsp_creative === 'ok',
      isSDPPartner: data?.dsp_partner === 'ok',
    });
  }, [data]);

  return { translationData, DSPTranslationData };
};
