import { TDates } from '../../../../types/general';
// eslint-disable-next-line no-shadow
export enum StorageEnumKeys {
  COLLAPSE_WRAPPERS = 'COLLAPSE_WRAPPERS',
  CAMPAIGNS_FILTERS = 'CAMPAIGNS_FILTERS',
  ACTIVITY_USERS = 'ACTIVITY_USERS',
}

export const staticDates: TDates['type'][] = [
  'day',
  'week',
  'yesterday',
  '30days',
  'month',
];
