import Load from 'components/UI/Load';
import React, { FC } from 'react';
import cn from 'classnames';
import { IFilterColumns } from 'domains/campaigns/model/hooks/useGetFilterForCampaigns';
import { ScrollSyncNode } from 'scroll-sync-react';
import css from '../../domains/campaigns/styles.module.scss';

interface ILoadingCampaignsTotalProps {
  filterColumns: IFilterColumns[];
}

const LoadingCampaignsTotal: FC<ILoadingCampaignsTotalProps> = ({
  filterColumns,
}) => (
  <>
    <div className={css.tabelHeaderLeft}>
      <Load
        style={{
          width: '100%',
        }}
      />
    </div>
    <ScrollSyncNode group="a">
      <div
        className={cn(
          css.totalStats_values,
          `length-${filterColumns.length - 1}`,
        )}
      >
        {filterColumns.map(
          (item, index) =>
            index > 0 && (
              <div className={cn(css.loadingItem, `size-${item.lengthTitle}`)} key={item.data}>
                <Load />
              </div>
            ),
        )}
      </div>
    </ScrollSyncNode>
    <div className={css.actions} />
  </>
);

export default LoadingCampaignsTotal;
