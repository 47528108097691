import React, { FC } from 'react';
import Card from 'components/UI/Card';
import cn from 'classnames';
import { FiAlertCircle } from 'react-icons/fi';
import CardList from 'components/UI/CardList';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';

const Info: FC = () => {
  const { t } = useTranslation();
  return (
    <CardList>
      <Card noHover className={cn(css.card, css.info)}>
        <FiAlertCircle size={20} />
        <div className={css.info_text}>
          <p>
            {`${t(`users_api.info_1`)} `}
            <a
              href="https://terratraf.readme.io/docs/api-2"
              target="_blank"
              rel="noreferrer"
            >
              {t(`translations.title_hint_link`)}
            </a>
          </p>
          <ul>
            <li>
              <p>{`${t(`users_api.select_partners`)}`}</p>
            </li>
            <li>
              <p>{`${t(`users_api.set_groups`)}`}</p>
            </li>
            <li>
              <p>{`${t(`users_api.use_tegs`)}`}</p>
            </li>
            <li>
              <p>
                {`${t(`users_api.methods_list`)} `}
                <a
                  href="https://td-dev.terratraf.com/api/v2/swagger/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {`${t(`users_api.see_methods_info`)}`}
                </a>
              </p>
            </li>
          </ul>
        </div>
      </Card>
    </CardList>
  );
};

export default Info;
