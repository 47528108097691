import React, { FC } from 'react';
import { FiPlus } from 'react-icons/fi';
import css from './styles.module.scss';

type CreatorProps = {
  onClick: () => void;
};

const Creator: FC<CreatorProps> = ({ onClick }) => (
  <div className={css.creator} onClick={onClick}>
    <FiPlus size={24} />
  </div>
);

export default Creator;
