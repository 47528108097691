import React, { FC } from 'react';
import cn from 'classnames';
import css from './styles.module.scss';

interface IPageHeaderProps {
  title?: string;
}

const PageHeader: FC<IPageHeaderProps> = ({ title, children }) => (
  <div className={css.header}>
    <div className={cn('container')}>
      {children}
      {title && <div className={css.title}>{title}</div>}
    </div>
  </div>
);

export default PageHeader;
