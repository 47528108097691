/* eslint-disable react/no-array-index-key */
import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import cn from 'classnames';
import { useOnScroll } from 'hooks/useOnScroll';
import ElementsNone from 'components/ElementsNone';
import { useUserTheme } from 'domains/user/model/selectors';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import css from './styles.module.scss';
import Header from './Header';
import Item from './Item';
import LoadingItem from './Item/loadingItem';
import { useBlackLists } from '../hooks/useBlackLists';
import { TFileList } from '../types';

const BlackLists: FC = () => {
  const { t } = useTranslation();
  const { blFiles, setUpdate, setType, isLoading } = useBlackLists();
  const { fixed } = useOnScroll(110);
  const [filteredData, setFilteredData] = useState<TFileList[]>([]);
  const [search, setSearch] = useState<string | null>(null);
  const { isThemeExtended, isThemeFull } = useUserTheme();
  const history = useHistory();

  useEffect(() => {
    setFilteredData(blFiles);
  }, [blFiles]);

  useEffect(() => () => {
    localStorage.removeItem('blTotal');
    localStorage.removeItem('selectedPartner');
    localStorage.removeItem('filters');
  });

  useEffect(() => {
    const unlisten = history.listen(() => {
      localStorage.removeItem('filter');
    });

    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    const onBeforeUnload = (e: any) => {
      if (e.currentTarget.performance.navigation.type === 1) {
        localStorage.removeItem('filter');
      }
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);

  return (
    <div
      className={cn(css.blackLists, {
        isFixedHeader: fixed,
      })}
    >
      <Helmet>
        <title>{t(`blackLists.title`)}</title>
      </Helmet>
      <Header
        setFilteredData={setFilteredData}
        listData={blFiles}
        setUpdate={setUpdate}
        setType={setType}
        search={search}
        setSearch={setSearch}
      />
      <div className={css.content}>
        <div
          className={cn('container resized', {
            extend: isThemeExtended,
            full: isThemeFull,
          })}
        >
          <div className={css.list}>
            {filteredData.length === 0 && search && <ElementsNone search />}
            {filteredData.length === 0 && !search && !isLoading && (
              <ElementsNone />
            )}
            {isLoading &&
              new Array(5)
                .fill(0)
                .map((_, index) => <LoadingItem key={index} />)}
            {!isLoading &&
              filteredData.length > 0 &&
              filteredData.map((item) => (
                <Item data={item} key={item.file_name} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlackLists;
