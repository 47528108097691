import React, { FC } from 'react';
import Load from 'components/UI/Load';
import cn from 'classnames';
import { formatStatistics } from 'domains/campaigns/model/utils/formatStatistics';
import { TFieldStatistics } from 'domains/campaign/types';
import { useCreativeStatisticsListInfo } from 'domains/creative/model/selectors';
import css from '../styles.module.scss';

type Props = {
  /** список ключей статистики */
  keysFields: TFieldStatistics[];
  /** объект классов для колонки */
  colClass: Record<string, string>;
  xxhash: string;
};

const StatisticsItem: FC<Props> = ({ keysFields, colClass, xxhash }) => {
  const { data, isLoading } = useCreativeStatisticsListInfo();
  return (
    <>
      {keysFields.map(({ key, title }) => {
        const currentDataObj = data && data[xxhash] ? data[xxhash] : {}
        const currentVal = key in currentDataObj ? currentDataObj[key] : {}
        return <div
          className={cn(
            css.item,
            colClass[key] !== undefined
              ? colClass[key]
              : `size-${
                  keysFields.filter((field) => field.title === title)[0]
                    .isLengthTitle
                }`,
            {
              _isGrey: title.trim().toLocaleLowerCase() === 'bid',
            },
          )}
          key={key}
        >
          {!isLoading &&
          data ? (
            <>
              <span>
                {formatStatistics(
                  currentVal,
                  true,
                )}
              </span>
            </>
          ) : (
            <Load />
          )}
        </div>
      })}
    </>
  );
};
export default StatisticsItem;
