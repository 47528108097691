import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import last from 'utils/last';
import { isGardClient } from 'components/CopyTargetingsModal/useTargets';
import { useDispatchApp } from '../../../redux/rootSelectors';
import { fetchModalBreadcrumbs } from '../../../domains/search/model/actions';
import { setTempModalBreadcrumbsDefault } from '../../../domains/search/model/reducer';
import { useSearchModalBreadcrumbs } from '../../../domains/search/model/selectors';
import { RBreadCrumbsKey } from '../../../domains/search/model/types';
import { useAgenciesInfo } from '../../../domains/agencies/model/selectors';
import { useClientsInfo } from '../../../domains/clients/model/selectors';
import { useCampaignsListModalInfo } from '../../../domains/campaigns/model/selectors';
import { useCreativeListModalInfo } from '../../../domains/creative/model/selectors';
import { THandlerForItem, TListCopy } from '../types';
import { fetchAgencies } from '../../../domains/agencies/reducer';
import { fetchClients } from '../../../domains/clients/reducer';
import { fetchCampaignsListModal } from '../../../domains/campaigns/model/actions';
import { fetchCreativeListModal } from '../../../domains/creative/model/actions';
import {
  PSagaCopyElement,
  TCloneTarget,
  TGroup,
  TItem,
} from '../../../types/general';
import { useAppNotification } from '../../../domains/user/model/selectors';
import { toastSuccessCopy } from '../utils/toastError';

type TFUseCopyModalProps = {
  /** текущий xxhash сущности */
  xxhash: string;
  /** xxhash для родителя */
  xxhashParent: string;
  copyStep: RBreadCrumbsKey;
  handlerCopy: (value: PSagaCopyElement) => void;
  from: TGroup;
  onCloseModal: () => void;
};

type TFUseCopyModal = (param: TFUseCopyModalProps) => THandlerForItem & {
  isWarning: boolean;
  search: string;
  handlerChangeSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  listElement: TListCopy;
  isListLoading: boolean;
  isActives: TItem[];
  progress: number;
  copying: boolean;
  handlerCopyCampaigns: (isForced?: boolean) => void;
  handlerToggleAll: () => void;
  getCheckedCount: () => {
    checkedCount: number;
    allItemsCount: number;
  };
  warningList: TCloneTarget[] | null;
  isAppNotification: boolean;
  step: RBreadCrumbsKey;
};

export const useCopyModal: TFUseCopyModal = ({
  xxhash,
  copyStep,
  xxhashParent,
  handlerCopy,
  from,
  onCloseModal,
}) => {
  const dispatch = useDispatchApp();

  const {
    LTU: LTUBreadCrumbs,
    data: breadcrumbs,
    isLoading: isLoadingBreadcrumbs,
  } = useSearchModalBreadcrumbs();

  const { data: agencies, isLoading: isLoadingAgencies } = useAgenciesInfo();
  const { data: clients, isLoading: isLoadingClients } = useClientsInfo();
  const { data: campaigns, isLoading: isLoadingCampaigns } =
    useCampaignsListModalInfo();
  const { data: creatives, isLoading: isLoadingCreatives } =
    useCreativeListModalInfo();
  const isAppNotification = useAppNotification();
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);
  const [isActives, setIsActives] = useState<TItem[]>([]);
  const [isWarning, setIsWarning] = useState(false);
  const [warningList, setWarningList] = useState<TCloneTarget[] | null>(null);
  const [step, setStep] = useState<RBreadCrumbsKey>(copyStep);
  const [search, setSearch] = useState('');
  const [progress, setProgress] = useState(0);
  const [copying, setCopying] = useState(false);

  const filterStatus: (status: string) => boolean = (status) =>
    status === 'STOPPED' || status === 'LAUNCHED';

  const listElement = useMemo<TListCopy>(() => {
    if (step === 'Organization' && agencies) {
      return {
        type: step,
        data: agencies
          .filter(({ status }) => filterStatus(status as string))
          .filter(({ title }) =>
            title.toLowerCase().includes(search.toLowerCase()),
          ),
      };
    }
    if (step === 'Agency' && clients) {
      return {
        type: step,
        data: clients
          .filter(
            ({ status, id }) =>
              filterStatus(status as string) && id !== xxhashParent,
          )
          .filter(({ title }) =>
            title.toLowerCase().includes(search.toLowerCase()),
          ),
      };
    }
    if (step === 'Client' && campaigns) {
      return {
        type: step,
        data: campaigns
          .filter(
            ({ status, xxhash: campaignXHash }) =>
              filterStatus(status as string) && campaignXHash !== xxhashParent,
          )
          .filter(({ title }) =>
            title.toLowerCase().includes(search.toLowerCase()),
          ),
      };
    }
    if (step === 'Campaign' && creatives) {
      return {
        type: step,
        data: creatives
          .filter(({ xxhash: creativeXHash }) => creativeXHash !== xxhashParent)
          .filter(({ title }) =>
            title.toLowerCase().includes(search.toLowerCase()),
          ),
      };
    }
    return {
      data: null,
      type: null,
    };
  }, [step, agencies, clients, campaigns, creatives, search]);

  const isListLoading = useMemo<boolean>(() => {
    if (isLoadingBreadcrumbs) {
      return isLoadingBreadcrumbs;
    }
    if (step === 'Organization') {
      return isLoadingAgencies;
    }
    if (step === 'Agency') {
      return isLoadingClients;
    }
    if (step === 'Client') {
      return isLoadingCampaigns;
    }
    if (step === 'Campaign') {
      return isLoadingCreatives;
    }
    return isLoadingBreadcrumbs;
  }, [
    step,
    isLoadingClients,
    isLoadingAgencies,
    isLoadingCampaigns,
    isLoadingCreatives,
    isLoadingBreadcrumbs,
  ]);

  const handlerChangeSearch: ReturnType<TFUseCopyModal>['handlerChangeSearch'] =
    useCallback((event) => {
      setSearch(event.target.value);
    }, []);

  const handlerClick: ReturnType<TFUseCopyModal>['handlerClick'] = useCallback(
    (hash) => {
      dispatch(fetchModalBreadcrumbs({ xxhash: hash }));
    },
    [],
  );

  const handlerToggle: ReturnType<TFUseCopyModal>['handlerToggle'] =
    useCallback(
      (hash, title) => {
        if (isActives.some((value) => value.xxhash === hash)) {
          setIsActives((prev) => [
            ...prev.filter((value) => value.xxhash !== hash),
          ]);
        } else {
          setIsActives((prev) => [...prev, { xxhash: hash, title }]);
        }
      },
      [isActives],
    );

  const handlerToggleAll = () => {
    if (listElement.data && listElement.type === 'Agency') {
      if (isActives.length) {
        const isActivesXxhash = isActives.map((item) => item.xxhash);
        if (
          listElement.data.some((item) => isActivesXxhash.includes(item.id))
        ) {
          setIsActives(
            isActives.filter(
              (item) =>
                !listElement.data
                  .map((itemData) =>
                    isGardClient(itemData) ? itemData.id : item,
                  )
                  .includes(item.xxhash),
            ),
          );
          return;
        }
        setIsActives((prev) => [
          ...prev,
          ...listElement.data.map((item) => ({
            xxhash: item.id,
            title: item.title,
          })),
        ]);
        return;
      }
      setIsActives(
        listElement.data.map((item) => ({
          xxhash: item.id,
          title: item.title,
        })),
      );
    }
  };

  const getCheckedCount: ReturnType<TFUseCopyModal>['getCheckedCount'] =
    useCallback(
      () => ({
        checkedCount: isActives.filter((item) =>
          listElement.data
            ?.map((itemData) => (isGardClient(itemData) ? itemData.id : item))
            .includes(item.xxhash),
        ).length,
        allItemsCount: listElement?.data?.length || 0,
      }),
      [isActives, listElement],
    );

  const getValueToggle: ReturnType<TFUseCopyModal>['getValueToggle'] =
    useCallback(
      (value) => isActives.some((item) => item.xxhash === value),
      [isActives],
    );

  const handlerCopyCampaigns: ReturnType<TFUseCopyModal>['handlerCopyCampaigns'] =
    useCallback(
      (isForced = false) => {
        setCopying(true);
        handlerCopy({
          from,
          to: isActives.map((item) => item.xxhash),
          setWarningList,
          setCopying,
          setProgress,
          setIsWarning,
          forced: isForced,
        });
      },
      [isActives, warningList],
    );

  useEffect(() => {
    dispatch(fetchModalBreadcrumbs({ xxhash }));
    return () => {
      dispatch(setTempModalBreadcrumbsDefault());
    };
  }, []);

  useEffect(() => {
    if (breadcrumbs) {
      const lastValue = last(<RBreadCrumbsKey[]>Object.keys(breadcrumbs));
      if (lastValue) {
        setStep(lastValue);
        if (lastValue === 'Organization') {
          dispatch(fetchAgencies({ status: null }));
        }
        if (lastValue === 'Agency') {
          dispatch(
            fetchClients({
              status: null,
              idAgency: breadcrumbs[lastValue].xxhash,
            }),
          );
        }
        if (lastValue === 'Client') {
          dispatch(
            fetchCampaignsListModal({
              partner_xxhash: breadcrumbs[lastValue].xxhash,
              status: '',
            }),
          );
        }
        if (lastValue === 'Campaign') {
          dispatch(
            fetchCreativeListModal({
              campaign_xxhash: breadcrumbs[lastValue].xxhash,
            }),
          );
        }
      }
    }
  }, [LTUBreadCrumbs]);

  const setTimer = useCallback(() => setProgress(progress + 1), [progress]);

  useEffect(() => {
    if (copying && progress !== 80) {
      interval.current = setInterval(setTimer, 100);
    } else if (interval.current) {
      clearInterval(interval.current);
    }

    return function () {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [copying, progress]);

  useEffect(() => {
    if (warningList && !warningList.length && !isWarning) {
      if (isAppNotification) {
        toastSuccessCopy(from, isActives, isAppNotification);
      }
      onCloseModal();
    }
  }, [warningList]);

  return {
    isWarning,
    search,
    handlerChangeSearch,
    listElement,
    isListLoading,
    handlerToggle,
    handlerClick,
    getValueToggle,
    isActives,
    progress,
    copying,
    handlerCopyCampaigns,
    warningList,
    isAppNotification,
    handlerToggleAll,
    getCheckedCount,
    step,
  };
};
