/* eslint-disable react/destructuring-assignment */
import { FormikProps } from 'formik';
import React from 'react';
import {
  GroupTypeBase,
  OptionTypeBase,
  components,
  OptionProps,
  ValueContainerProps,
  Props,
} from 'react-select';
import cn from 'classnames';
import { CustomOptionsType } from 'types/general';
import css from './styles.module.scss';
import InputSelectCustom from '../InputSelectCustom';
import { ClearIndicator } from '../NewSelect/components/ClearIndicator';
import { DropdownIndicator } from '../NewSelect/components/DropdownIndicator';
import SelectWrapper from '../NewSelect/components/SelectWrapper';

type TInputSelectBalance<T, M> = {
  propsValues?: M;
  placeholder?: string;
  customValue?: any;
  fieldName: string;
  parentXxhash?: string;
  clearable?: boolean;
  formik?: FormikProps<T>;
  isError?: boolean;
  errorText?: string;
  className?: string;
  classNamePrefix?: string;
  customFilterOption?: (
    option: {
      label: string;
      value: string;
    },
    rawInput: string,
  ) => boolean;
};

interface IOption
  extends OptionProps<OptionTypeBase, false, GroupTypeBase<OptionTypeBase>> {
  label: string;
  data: {
    additional: {
      current: string;
    };
  };
}

interface IValueContainer
  extends ValueContainerProps<
    OptionTypeBase,
    false,
    GroupTypeBase<OptionTypeBase>
  > {
  hasValue: boolean;
  label?: string;
  selectProps: Props<any, false, GroupTypeBase<OptionTypeBase>>;
}

const Option: React.ComponentType<IOption> = (props) => {
  const splitLabel = props.label.split('*/-/*');
  return (
    <components.Option
      {...props}
      className={cn(css.balance_option, 'balance_option')}
    >
      <div
        className={cn(css.balance_option_label, 'balance_option_label')}
        title={props.label}
      >
        {splitLabel[0]} <strong>{splitLabel[1]}</strong>
      </div>
      <div className={cn(css.balance_option_price, 'balance_option_price')}>
        {props.data.additional.current}
      </div>
    </components.Option>
  );
};

const ValueContainer: React.ComponentType<IValueContainer> = (props) => {
  if (
    !props.hasValue ||
    !Object.prototype.hasOwnProperty.call(props.selectProps.value, 'additional')
  ) {
    return (
      <components.ValueContainer {...props}>
        {props.children}
      </components.ValueContainer>
    );
  }
  const splitLabel = props.selectProps.value.label.split('*/-/*');
  return (
    <components.ValueContainer {...props}>
      {props.children &&
        Array.isArray(props.children) &&
        props.children.map((child: any) =>
          !props.hasValue || child?.type === components.Input ? child : null,
        )}

      {props.hasValue && props.children && props.children[0] !== null && (
        <div className={css.balance_option}>
          <div
            className={cn(css.balance_option_label, 'balance_option_label')}
            title={props.label}
          >
            {splitLabel[0]} <span>{splitLabel[1]}</span>
          </div>
          <div className={cn(css.balance_option_price, 'balance_option_price')}>
            {props.selectProps.value.additional.current}
          </div>
        </div>
      )}
    </components.ValueContainer>
  );
};

function index<T, M extends CustomOptionsType[]>(
  props: TInputSelectBalance<T, M>,
): JSX.Element {
  return (
    <SelectWrapper isVerticalStyle>
      <InputSelectCustom<T, M>
        {...props}
        customComponents={{
          IndicatorSeparator: null,
          Option,
          ValueContainer,
          ClearIndicator,
          DropdownIndicator,
        }}
      />
    </SelectWrapper>
  );
}

export default index;
