import { RefObject, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { fetchCreativesTotal } from 'domains/creative/reduser';
import { useDispatchApp } from 'redux/rootSelectors';
import { CreativeItemListWithStatistics } from 'domains/creative/types';
import { KeysSettings, useStateValue } from '../../../../utils/context';
import { useCreativeComponentState } from './useCreativeComponentState';
import {
  TDates,
  TID,
  TonSetActive,
  TOnSetDateRange,
} from '../../../../types/general';

type TFUseCreativesHeader = ({search}: {search: string, currentTranslationXxhashArr: string[];}) => {
  creativeList: ReturnType<typeof useCreativeComponentState>['creativeList'];
  isLoadingCreativeList: ReturnType<
    typeof useCreativeComponentState
  >['isLoadingCreativeList'];
  currency: ReturnType<typeof useCreativeComponentState>['currency'];
  campaign: ReturnType<typeof useCreativeComponentState>['campaign'];
  active: boolean;
  period: TDates['period'];
  type: TDates['type'];
  fetchStatistics: ReturnType<
    typeof useCreativeComponentState
  >['fetchStatistics'];
  onUpdateStatistic: TOnSetDateRange;
  onSetActive: TonSetActive;
  match: RouteComponentProps<TID>['match']; 
};

export const useCreativesHeader: TFUseCreativesHeader = ({search, currentTranslationXxhashArr}) => {
  const {
    campaign,
    isLoadingCreativeList,
    creativeList,
    match,
    history,
    location,
    fetchStatistics,
    currency,
    updateStatisticsAction,
  } = useCreativeComponentState();

  // Header start
  const [active, setActive] = useState<boolean>(false);
  const [activator, setActivator] = useState<RefObject<HTMLElement> | null>(
    null,
  );

  const { state, dispatch } = useStateValue();

  const dispatchRedux = useDispatchApp()

  const { period, type } = state.settings[KeysSettings.CAMPAIGN] as TDates;

  const searchFilter = (serverListData: CreativeItemListWithStatistics[] | null, searchVal: string): string[] | null => {
    if (!serverListData) return null
    if (search) return serverListData.filter(item => item.title.toLowerCase().includes(searchVal.toLowerCase())).map((e) => e.xxhash)
    return serverListData.map((e) => e.xxhash)
  }

  const translationFilter = (translationData: string[], xxhashListData: string[] | null) => {
    if (!xxhashListData) return null
    if (translationData.length) return xxhashListData.filter(item =>translationData.includes(item))
    return xxhashListData
  }

  const onUpdateStatistic: ReturnType<TFUseCreativesHeader>['onUpdateStatistic'] =
    (periodDates, typeDate) => {
      const xxhash_list = creativeList ? creativeList.map((e) => e.xxhash) : [];
      const search_xxhash_list = translationFilter(currentTranslationXxhashArr, searchFilter(creativeList, search))
      if (xxhash_list.length) {
        const params = {
          xxhash_list,
          period: periodDates,
        }
        fetchStatistics(params);
        dispatchRedux(fetchCreativesTotal({
          xxhash_list: search_xxhash_list?.length ? search_xxhash_list : xxhash_list,
          period: periodDates,
        }))
      }
      updateStatisticsAction();
      dispatch({
        type: 'setSettings',
        payload: {
          key: KeysSettings.CAMPAIGN,
          item: {
            period: periodDates,
            type: typeDate,
          },
        },
      });
    };

  const onSetActive: ReturnType<TFUseCreativesHeader>['onSetActive'] = (
    val,
    ref,
  ) => {
    if (val) {
      setActive(val);
      setActivator(ref);
    } else if (ref === activator) {
      setActive(val);
      setActivator(ref);
    }
  };

  return {
    creativeList,
    isLoadingCreativeList,
    currency,
    campaign,
    active,
    period,
    type,
    fetchStatistics,
    onUpdateStatistic,
    onSetActive,
    location,
    history,
    match,
  };
};
