import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

export type TModertionStatusLiteral =
  | 'APPROVED'
  | 'UNKNOWN'
  | 'ON_MODERATION'
  | 'DISAPPROVED'
  | 'READY'
  | 'NOT_SENT'
  | 'AWAITING_DATA_COMPLETION'
  | 'CONDITIONALLY_APPROVED';

type TModerationDescription = {
  title: string;
  icon: string;
  color: string;
};
export type TModeration = Record<
  TModertionStatusLiteral,
  TModerationDescription
>;

type TFUseStatuses = () => {
  statusModeration: (key: TModertionStatusLiteral) => TModerationDescription;
};

export const useStatuses: TFUseStatuses = () => {
  const { t, i18n } = useTranslation();

  const dict: TModeration = {
    APPROVED: {
      title: t('creative.crea_statuses.s1'),
      icon: 'FiCheckCircle',
      color: 'green',
    },
    UNKNOWN: {
      title: t('creative.crea_statuses.s4'),
      icon: 'FiClock',
      color: 'yellow',
    },
    ON_MODERATION: {
      title: t('creative.crea_statuses.s5'),
      icon: 'FiClock',
      color: 'yellow',
    },
    DISAPPROVED: {
      title: t('creative.crea_statuses.s6'),
      icon: 'FiXCircle',
      color: 'red',
    },
    READY: {
      title: t('creative.crea_statuses.s7'),
      icon: 'FiClock',
      color: 'yellow',
    },
    NOT_SENT: {
      title: t('creative.crea_statuses.NOT_SENT'),
      icon: 'FiClock',
      color: 'yellow',
    },
    AWAITING_DATA_COMPLETION: {
      title: t('creative.crea_statuses.s8'),
      icon: 'FiClock',
      color: 'yellow',
    },
    CONDITIONALLY_APPROVED: {
      title: t('creative.crea_statuses.s9'),
      icon: 'FiAlertCircle',
      color: 'blue',
    },
  };

  const [statuses, setStatuses] = useState<TModeration>(() => dict);
  useEffect(() => {
    setStatuses(() => dict);
  }, [i18n.language]);
  return {
    statusModeration: (key) => statuses[key] || statuses.UNKNOWN,
  };
};
