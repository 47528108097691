import request from '../../lib/helpers/requestApi';
import {
  RIPartners,
  PIPartners,
  RPartnersBalance,
  PPaymentLink,
  PTransferMoney,
} from './types';
import { PartnerHash, PartnerHashList } from '../../types/general';
import { TBalance } from '../campaigns/types';

export const getOrganizationStructure = request<RIPartners, PIPartners>({
  method: 'management.getOrganizationStructure',
});

export const getPartnersBalance = request<RPartnersBalance, PartnerHashList>({
  method: 'statistic.getPartnerBalanceByList',
});

export const getPartnerBalance = request<TBalance, PartnerHash>({
  method: 'statistic.getPartnerBalance',
});

export const getPaymentLink = request<string, PPaymentLink>({
  method: 'payment.getPaymentLink',
});

export const transferMoneyApi = request<boolean, PTransferMoney>({
  method: 'partner.transferMoney',
});

