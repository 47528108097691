import React, { useState } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { INewDate } from 'domains/campaigns/types';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import { PeriodEnum } from 'types/containers';
import { formatStatistics } from 'domains/campaigns/model/utils/formatStatistics';
import css from './styles.module.scss';

interface Props {
  data: INewDate[] | undefined;
  currentValue: string | number;
  onlyCurrent: boolean;
  periodType?: string;
  className?: string;
}

const Statistics: React.FC<Props> = ({
  data,
  onlyCurrent,
  currentValue,
  periodType,
  className,
}) => {
  const [count, setCount] = useState<null | number>(null);

  if (!data) {
    return null;
  }

  const getSize = (value: number) => {
    let max = Math.floor(data[1].count);

    data.filter((item) => item.date !== 'total').forEach((element) => {
      if (max < element.count) max = Math.floor(element.count);
    });

    const percent = Math.floor((value * 100) / max);
    const px = (16 / 100) * percent;

    return px > 1 ? px : 2;
  };
  if (!onlyCurrent) {
    return (
      <div className={cn('Statistics_item', className)}>
        {formatStatistics(
          periodType === PeriodEnum.WEEK
            ? data.find((item) => item.date === 'total')?.count
            : currentValue,
          true,
        )}
      </div>
    );
  }

  if (data.length === 0) {
    return <div>—</div>;
  }

  return (
    <div className={cn(css.statistics, className)}>
      <div className={css.chart}>
        {data
          .filter((item) => item.date !== 'total')
          .map((element) => {
            if (element.date) {
              return (
                <TooltipPoratal
                  classname={css.item}
                  key={element.date}
                  hint={
                    <span>{moment(element.date).format('dd, DD MMMM')}</span>
                  }
                >
                  <div
                    className={cn(css.bar)}
                    style={{
                      height: `${getSize(element.count)}px`,
                    }}
                    onMouseEnter={() => setCount(element.count)}
                    onMouseLeave={() => setCount(null)}
                  />
                </TooltipPoratal>
              );
            }
            return null;
          })}
      </div>

      <div className={css.count}>
        {typeof count !== 'number'
          ? formatStatistics(
              periodType === PeriodEnum.WEEK
                ? data.find((item) => item.date === 'total')?.count
                : currentValue,
              true,
            )
          : formatStatistics(count, true)}
      </div>
    </div>
  );
};

export default Statistics;
