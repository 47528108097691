import React, { ComponentProps, FC } from 'react';
import { FiX } from 'react-icons/fi';
import i18n from '../../i18n';
import css from './styles.module.scss';
import ModalOverlay from '../Modals/ModalOverlay1';
import Progress from './components/Progress';
import Header from './components/Header';
import { useCopyModal } from './hook/useCopyModal';
import WarningList from './components/WarningList';
import Content from './components/Content';
import Button from '../UI/Buttons/buttons';

type CopyModalProps = Pick<
  ComponentProps<typeof ModalOverlay>,
  'onCloseModal'
> &
  Pick<ComponentProps<typeof Header>, 'subTitle'> &
  Parameters<typeof useCopyModal>[0];

const CopyModal: FC<CopyModalProps> = ({
  onCloseModal,
  subTitle,
  xxhash,
  copyStep,
  xxhashParent,
  handlerCopy,
  from,
}) => {
  const {
    isWarning,
    handlerChangeSearch,
    search,
    listElement,
    isListLoading,
    handlerToggle,
    handlerClick,
    getValueToggle,
    isActives,
    progress,
    copying,
    handlerCopyCampaigns,
    handlerToggleAll,
    getCheckedCount,
    warningList,
    step,
  } = useCopyModal({
    from,
    xxhash,
    copyStep,
    handlerCopy,
    xxhashParent,
    onCloseModal,
  });

  return (
    <ModalOverlay onCloseModal={onCloseModal} isOpen>
      <div className={css.card}>
        <Progress isActive={copying} progress={progress} />

        <div className={css.form}>
          <div className={css.header}>
            <Header
              isWarning={isWarning}
              subTitle={subTitle}
              from={from}
            />
            <div className={css.close}>
              <FiX size={24} onClick={onCloseModal} />
            </div>
          </div>

          {isWarning ? (
            <WarningList warnList={warningList} fromList={from} />
          ) : (
            <Content
              getValueToggle={getValueToggle}
              handlerClick={handlerClick}
              handlerToggle={handlerToggle}
              defaultStep={copyStep}
              isLoading={isListLoading}
              listElement={listElement}
              handlerChangeSearch={handlerChangeSearch}
              handlerToggleAll={handlerToggleAll}
              getCheckedCount={getCheckedCount}
              search={search}
              step={step}
            />
          )}
        </div>
        <div className={css.actions}>
          <Button
            transparent
            title={i18n.t('cancel_btn')}
            height={40}
            autoWidth
            buttonAttributes={{
              onClick: onCloseModal,
            }}
          />
          {!isWarning && (
            <Button
              title={i18n.t('copy_btn')}
              height={40}
              autoWidth
              buttonAttributes={{
                onClick: () => handlerCopyCampaigns(),
                disabled: !isActives.length,
              }}
            />
          )}
          {isWarning && (
            <Button
              title={i18n.t('continue_btn')}
              height={40}
              autoWidth
              buttonAttributes={{
                onClick: () => handlerCopyCampaigns(true),
                disabled: isWarning,
              }}
            />
          )}
        </div>
      </div>
    </ModalOverlay>
  );
};

export default CopyModal;
