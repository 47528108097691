import { SagaIterator } from '@redux-saga/core';
import { takeEvery, takeLatest } from 'redux-saga/effects';
import { workerFetchAllClientsSaga, workerFetchClientsSaga, workerSetStatusClient } from './workers';
import { fetchAllClients, fetchClients, setStatusClientAction } from '../reducer';

export default function* watchClients(): SagaIterator {
  yield takeEvery(fetchClients, workerFetchClientsSaga);
  yield takeEvery(fetchAllClients, workerFetchAllClientsSaga);
  yield takeLatest(setStatusClientAction, workerSetStatusClient);
}
