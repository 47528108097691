import React, { ReactNode, useEffect, useRef, useState } from 'react';

type TFUseSubstitutionOfVoidsProps = {
  fixed: boolean;
};

type TFUseSubstitutionOfVoids = (param: TFUseSubstitutionOfVoidsProps) => {
  fakeHeader: ReactNode;
  fakePart: ReactNode;
  fakeHeaderRef: React.RefObject<HTMLDivElement>;
  fakePartRef: React.RefObject<HTMLDivElement>;
};

export const useSubstitutionOfVoids: TFUseSubstitutionOfVoids = ({ fixed }) => {
  const [heightHeader, setHeightHeader] = useState<number>(0);
  const [heightPart, setHeightPart] = useState<number>(0);

  const fakeHeaderRef = useRef<HTMLDivElement>(null);
  const fakePartRef = useRef<HTMLDivElement>(null);

  const caclcHeight = () => {
    if (fakeHeaderRef.current) {
      setHeightHeader(fakeHeaderRef.current.clientHeight);
    }
    if (fakePartRef.current) {
      setHeightPart(fakePartRef.current.clientHeight);
    }
  };

  useEffect(() => {
    caclcHeight();
  }, [fixed]);

  useEffect(() => {
    caclcHeight();
  }, []);

  const fakeHeader = (
    <div
      style={{
        height: `${heightHeader}px`,
        display: fixed ? `block` : 'none',
      }}
    />
  );

  const fakePart = (
    <div
      style={{
        height: `${heightPart}px`,
        display: fixed ? `block` : 'none',
      }}
    />
  );

  return {
    fakeHeader,
    fakePart,
    fakeHeaderRef,
    fakePartRef,
  };
};
