import React, { FC, useRef, useState } from 'react';
import cn from 'classnames';
import avatarColors from 'utils/avatarColors';
import Tooltip from 'components/UI/Tooltip';
import { FiEdit, FiMoreHorizontal } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import GroupHint from 'components/UI/GroupHint';
import { TOfferItem } from 'domains/offer/model/types';
import { useHistory } from 'react-router-dom';
import { useFixedHeightItem } from 'utils/useFixedHeightItem';
import css from '../styles.module.scss';
import fromArchiveSvg from '../../../../assets/fromarchive.svg';
import toArchiveSvg from '../../../../assets/toarchive.svg';

interface IItemProps {
  offer: TOfferItem;
  currentTab: 'LAUNCHED' | 'ARCHIVE';
  openModal: () => void;
}

const Item: FC<IItemProps> = ({ offer, currentTab, openModal }) => {
  const groupsCollapsedDisplay = 4;
  const [toggle, setToggle] = useState(false);
  const { t } = useTranslation();
  const showAllClientsRef = useRef<HTMLDivElement | null>(null);
  const [openClientsList, setOpenClientsList] = useState(false);
  const history = useHistory();
  const itemRef = useRef<HTMLDivElement>(null);
  const heightItem = useFixedHeightItem(itemRef);

  const counter = offer.clients.length - groupsCollapsedDisplay > 0;

  return (
    <div className={cn(css.item_wrapper)}>
      <div className={css.indicator} />
      <div
        onClick={() => history.push(`/main/offer/${offer.id}/edit`)}
        className={cn(css.item, {
          _isOpen: toggle,
        })}
        ref={itemRef}
        style={{
          height: toggle ? `${heightItem}px` : `auto`,
        }}
      >
        <div className={css.item__header}>
          <div
            className={css.img}
            style={
              offer.icon
                ? {
                    backgroundImage: `url(${offer.icon})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: 'white',
                  }
                : {
                    backgroundColor: avatarColors(
                      offer.title.slice(0, 1).toLowerCase(),
                    ),
                  }
            }
          >
            {offer.icon ? '' : offer.title.slice(0, 1).toUpperCase()}
          </div>
          <div className={css.text}>
            <Tooltip
              className={css.tooltip}
              hintWidth={300}
              leftArrowPosition
              title={<strong>{offer.title}</strong>}
            >
              <p>{offer.title}</p>
            </Tooltip>
          </div>
        </div>
        <div
          className={css.value}
          ref={showAllClientsRef}
          onMouseEnter={() => setOpenClientsList(true)}
          onMouseLeave={() => setOpenClientsList(false)}
        >
          {offer.clients.map(
            (client, index) =>
              index < groupsCollapsedDisplay && (
                <span
                  key={client.internal_id}
                  className={css.group}
                  title={client.title}
                >
                  {client.title}
                </span>
              ),
          )}
          {counter && (
            <>
              <span className={css.group}>
                +{offer.clients.length - groupsCollapsedDisplay}
              </span>
            </>
          )}
        </div>
        <div
          className={cn('toggle-dropdown', css.dropdown, { _isOpen: toggle })}
          onMouseEnter={() => {
            setToggle(true);
          }}
          onMouseLeave={() => {
            setToggle(false);
          }}
        >
          <button type="button" className={css.dropdown__show}>
            <FiMoreHorizontal size={24} />
          </button>

          <div
            className={css.dropdown__menu}
            onClick={(e) => e.stopPropagation()}
          >
            <Tooltip
              title={<FiEdit size={24} />}
              onClick={() => history.push(`/main/offer/${offer.id}/edit`)}
            >
              <span>{t('users_page.table.edit_user')}</span>
            </Tooltip>
            <Tooltip
              onClick={openModal}
              title={
                currentTab === 'ARCHIVE' ? (
                  <ReactSVG src={fromArchiveSvg} />
                ) : (
                  <ReactSVG src={toArchiveSvg} />
                )
              }
            >
              <span>
                {currentTab === 'ARCHIVE'
                  ? t('archive_title_from')
                  : t('archive_title_to')}
              </span>
            </Tooltip>
          </div>
        </div>
      </div>

      {openClientsList && counter && (
        <GroupHint
          groups={offer.clients.map((client) => client.title)}
          mainRef={showAllClientsRef}
          noHintPortal={setOpenClientsList}
        />
      )}
    </div>
  );
};

export default Item;
