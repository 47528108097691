import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { setTempContainersList } from '../reducer';
import { getContainers } from '../services';
import {
  ResponseContainers,
  ResponseContainers as Response,
} from '../../../types/containers';
import avatarColors from '../../../utils/avatarColors';
import updateToken from '../../../utils/updateToken';
import { genFetchedData } from '../../../redux/fetchedData';

export function* fetchContainersSaga(): SagaIterator<void> {
  let fetchedData = genFetchedData<ResponseContainers[]>(null).set(
    'isLoading',
    true,
  );
  yield put(setTempContainersList(fetchedData));
  try {
    yield call(updateToken);
    const { result } = yield call(getContainers);

    const items = result.items.map((e: Response) => ({
      ...e,
      bgColor: avatarColors(e.title.slice(0, 1).toLowerCase()),
    }));

    fetchedData = fetchedData.set('data', items);
    yield put(setTempContainersList(fetchedData));
  } catch (e) {
    const code = e?.code ?? 0;
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: e.message ?? '',
      code,
    });
    yield put(setTempContainersList(fetchedData));
  } finally {
    fetchedData = fetchedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setTempContainersList(fetchedData));
  }
}
