import { useUserTheme } from 'domains/user/model/selectors';
import React, { FC } from 'react';
import cn from 'classnames';
import PreloaderList from 'components/Preloaders/List';
import Load from 'components/UI/Load';
import css from '../../styles.module.scss';

const LoadingStatisticsTableHeader: FC = () => {
  const { isThemeExtended, isThemeFull } = useUserTheme();
  return (
    <div
      className={cn('container resized', {
        extend: isThemeExtended,
        full: isThemeFull,
      })}
    >
      <div className={css.preloader}>
        <div>
          <Load
            style={{
              display: 'block',
              width: '100%',
              height: '19px',
              marginBottom: '16px',
            }}
          />
          <Load
            style={{
              width: '100%',
              height: '48px',
              marginBottom: '16px',
            }}
          />
        </div>
        <PreloaderList type="creative" />
      </div>
    </div>
  );
};

export default LoadingStatisticsTableHeader;
