import { createAction } from '@reduxjs/toolkit';
import { PIPartners, PPaymentLink, TEditPartnerAction, TransferMoneyPayload } from '../types';
import { PartnerHash, PartnerHashList } from '../../../types/general';

export const editPartnerAction = createAction<TEditPartnerAction>(
  'partners/editPartnerAction',
);

export const fetchAgencyList = createAction<PIPartners>(
  'partners/fetchAgencyList',
);

export const fetcherPartnersBalance = createAction<PartnerHashList>(
  'partners/fetcherPartnersBalance',
);

export const fetcherPartnerBalance = createAction<PartnerHash>(
  'partners/fetcherPartnerBalance',
);

export const fetchLinkBalance = createAction<PPaymentLink>(
  'partners/fetchLinkBalance',
);

export const transferMoney = createAction<TransferMoneyPayload>(
  'partners/transferMoney',
);
