/* eslint-disable react/no-danger */
import React, { FC, useRef } from 'react';
import Tooltip from 'components/UI/Tooltip';
import { useTranslation } from 'react-i18next';
import { FiRefreshCcw } from 'react-icons/fi';
import cn from 'classnames';
import PreloaderFormList from 'components/Preloaders/FormList';
import {
  VariableSizeList as List,
  ListChildComponentProps,
} from 'react-window';
import FormScroll from 'components/Preloaders/FormScroll';
import { Link } from 'react-router-dom';
import { IUpdatedHistory } from 'domains/historyChanges/model/hooks/useTransformHistoryData';
import { IHistoryFilter } from 'domains/historyChanges/types';
import { getLastTwoMonthsDate } from 'utils/genTimePeriod';
import { useSearchBreadcrumbsInfo } from 'domains/search/model/selectors';
import css from './styles.module.scss';
import { ReactComponent as EmptySvg } from './icons/empty.svg';
import { useHistoryChangeWidget } from './hooks/useHistoryChangeWidget';

interface IHistoryChangeWidgetProps {
  list: IUpdatedHistory<string>[];
  getHistory: () => void;
}

const HistoryChangeWidget: FC<IHistoryChangeWidgetProps> = ({
  list,
  getHistory,
}) => {
  const { t } = useTranslation();
  const currentItem = useRef(null);

  const { data: breadcrumbs } = useSearchBreadcrumbsInfo();

  const InnerElement: FC = ({ children }) => (
    <div
      style={{
        height: `${heightBOX()}px`,
        width: `100%`,
      }}
    >
      {children}
    </div>
  );

  const { historyList, heightBOX, isShowBtnAllComment } =
    useHistoryChangeWidget({
      list,
    });

  const changeDateFormat = (prevDate: string): string => {
    const slicedDate = prevDate.replace(/-/g, '.').slice(0, 10);
    const slicedTime = prevDate.slice(10, 16);
    const date = new Date(slicedDate);
    const formattedDate = `${String(date.getDate()).padStart(2, '0')}.${String(
      date.getMonth() + 1,
    ).padStart(2, '0')}.${date.getFullYear()} ${slicedTime}`;
    return formattedDate;
  };

  const getCurrentType = (
    currCamp: IUpdatedHistory<string> | null,
    isShowGlobalHistory?: boolean,
  ) => {
    const id = currCamp?.item_type === 'CREATIVE'
      ? currCamp?.creative?.internal_id
      : currCamp?.campaign?.internal_id;

    const globalId = breadcrumbs && Object.keys(breadcrumbs).includes('Creative')
      ? breadcrumbs?.Creative.internal_id
      : breadcrumbs?.Campaign.internal_id;

    let historyParamsObj: IHistoryFilter = {
      period: getLastTwoMonthsDate(),
      search: isShowGlobalHistory ? globalId : id,
    }
    if (isShowGlobalHistory) {
      historyParamsObj = {
        ...historyParamsObj,
        limit: { from: 0, to: 200 },
      };
      localStorage.setItem(
        'localHistoryParams',
        JSON.stringify(historyParamsObj),
      );
      localStorage.setItem('isLocalHistory', 'true');
    } else {
      historyParamsObj = {
        ...historyParamsObj,
        limit: { from: 0, to: 6 },
      };
      let field;
      if (currCamp?.data.targetings) {
        field = 'targetings';
      }
      if (currCamp?.data.title) {
        field = 'title';
      }
      if (currCamp?.data.limits) {
        field = 'limits';
      }
      localStorage.setItem(
        'localHistoryParams',
        JSON.stringify({...historyParamsObj, field: field ? [field] : undefined}),
      );
      localStorage.setItem('isLocalHistory', 'true');
    }
  };

  const Row: FC<ListChildComponentProps<ReturnType<any>['historyList']>> =
    React.useCallback(
      ({ index, data, style, isScrolling }) => {
        const row = data[index];
        currentItem.current = row;

        if (isScrolling) {
          return (
            <div
              style={{
                ...style,
                height: `${row.height}px`,
                top: `${row.top}px`,
              }}
            >
              <FormScroll />
            </div>
          );
        }

        return (
          <div
            className={cn(css.history_row, css.row)}
            style={{
              ...style,
              height: `${row.height}px`,
              top: `${row.top}px`,
            }}
          >
            <div className={css.row_header}>
              <div className={css.row_email} title={row.user_email}>
                <strong>{row.user_email}</strong>
              </div>
              <div className={css.row_date}>
                {changeDateFormat(row.datetime.date)}
              </div>
            </div>
            <div
              className={cn(css.row_text, {
                _isCollapseText: row.isCollapse,
              })}
            >
              {row.text}
            </div>
            {row.isCollapse && (
              <Link
                to="/control_panel/history_changes"
                target="_blank"
                className={css.row_action}
                onClick={() => getCurrentType(row)}
              >
                {t(`more_btn`)}
              </Link>
            )}
          </div>
        );
      },
      [historyList],
    );

  return (
    <div className={css.history}>
      <div className={css.history_header}>
        <Tooltip
          title={
            <div className={css.history_update}>
              <FiRefreshCcw size={24} />
            </div>
          }
          onClick={() => getHistory()}
        >
          {t(`update_btn`)}
        </Tooltip>
      </div>
      <div className={css.history_body}>
        {list.length === 0 ? (
          <div className={css.history_empty}>
            <EmptySvg />
            <span>{t(`history_changes_widget.no_changes`)}</span>
          </div>
        ) : (
          <div className={css.history_content}>
            {false && <PreloaderFormList />}
            {true && (
              <List
                height={heightBOX()}
                itemCount={historyList.length}
                itemSize={(index) => historyList[index].height}
                width="100%"
                itemData={historyList}
                innerElementType={InnerElement}
                style={{
                  overflowY: 'scroll',
                  willChange: 'initial',
                }}
              >
                {Row}
              </List>
            )}
            {isShowBtnAllComment() && !!historyList.length && (
              <Link
                to="/control_panel/history_changes"
                target="_blank"
                className={css.history_btn}
                onClick={() => getCurrentType(currentItem.current, true)}
              >
                {t(`history_changes_widget.look_all_changes`)}
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default HistoryChangeWidget;
