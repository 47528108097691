import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { TMakeReqWithRD, makeReqWithRD } from 'redux/makeReqWithRD';
import { getOffersList } from '../actions';
import { getOffersListAPI } from '../api';
import { setOffersList } from '../reducer';

export function* workerGetOffersListSaga({
  payload,
}: ReturnType<typeof getOffersList>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getOffersListAPI>>(makeReqWithRD, {
      fetcher: getOffersListAPI,
      fill: setOffersList,
      parameters: payload,
    });
  } catch (e) {
    console.error({ e });
  }
}