import {
  ParamsContainer,
  ResponseStatisticsContainer,
} from '../../types/containers';
import request from '../../lib/helpers/requestApi';

export const getContainerStatistics = request<
  ResponseStatisticsContainer,
  ParamsContainer
>({
  method: 'container.getContainerById',
});
