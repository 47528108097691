import React, { FC } from 'react';
import cn from 'classnames';
import { useUserTheme } from 'domains/user/model/selectors';
import Load from 'components/UI/Load';
import css from '../styles.module.scss';

interface ILoadingProp {
  fullView?: boolean;
}

const Loading: FC<ILoadingProp> = ({ fullView }) => {
  const { isThemeExtended, isThemeFull } = useUserTheme();
  return (
    <div className={css.header}>
      <div
        className={cn(css.container, 'container resized', {
          extend: isThemeExtended && fullView,
          full: isThemeFull && fullView,
        })}
      >
        <Load
          style={{
            width: '550px',
            height: '14px',
            marginBottom: '10px',
            gridArea: 'breadcrumbs',
          }}
        />
        <Load
          style={{
            width: '550px',
            height: '40px',
            gridArea: 'info',
          }}
        />

        <Load
          style={{
            width: '500px',
            height: '40px',
            gridArea: 'actions',
          }}
        />
      </div>
    </div>
  );
};

export default Loading;
