import React, {
  CSSProperties,
  ReactText,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import {
  FiMoreHorizontal,
  FiArrowDown,
  FiArrowUp,
  FiPlus,
  FiMinus,
  FiX,
  FiCode,
} from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import useDropDown from 'hooks/useDropDown';
import css from './styles.module.scss';
import {
  TableEvents,
  ChartEvents,
  tableEvents,
  chartEvents,
  TOnSetFilterTable,
} from '../../../../domains/campaign/types';
import { TFilter } from '../../../../domains/campaign/reports/reportStatististic/hooks/useIntegrationFilter';

export function istableEvents(e: MenuEvent): e is TableEvents {
  return tableEvents.includes(e);
}

export function ischartEvents(e: MenuEvent): e is ChartEvents {
  return chartEvents.includes(e);
}

export type MenuEvent = TableEvents | ChartEvents;

type Props = {
  title: string;
  sortType?: TableEvents | undefined;
  chartType: ChartEvents;
  style?: CSSProperties;
  onClick: (e: MenuEvent) => void;
  nochart: boolean;
  notSort?: boolean;
  expand: number;
  setExpand: (v: number) => void;
  isShowExpand?: boolean;
  isFixed?: boolean;
  onSetFilterTable: TOnSetFilterTable;
  filterTable: TFilter['activeColumns'];
  allTitles: ReactText[];
  isUtmTags?: boolean;
};

const setCustomEvent = (onClick: Props['onClick'], v: MenuEvent) => () => {
  onClick(v);
};

const SortHeader: React.FC<Props> = ({
  title,
  sortType,
  chartType,
  style,
  onClick,
  nochart,
  notSort,
  expand,
  setExpand,
  isShowExpand = false,
  isFixed,
  onSetFilterTable,
  filterTable,
  allTitles,
  isUtmTags,
}) => {
  const dropdownMenu = useRef<HTMLTableHeaderCellElement | null>(null);
  const { toggle, isOpened, toggleOff } = useDropDown(dropdownMenu);

  const [expandElement, setExpandElement] = useState<HTMLDivElement | null>(
    null,
  );

  const [expandStart, setExpandStart] = useState<boolean>(false);
  const [expandX, setExpandX] = useState<number>(0);

  const expandRef = useCallback((node: HTMLDivElement | null) => {
    setExpandElement(node);
  }, []);

  const expandMouseDown = useCallback((e: MouseEvent) => {
    setExpandStart(true);
    setExpandX(e.clientX);
  }, []);
  const expandMouseUp = useCallback(() => {
    setExpandStart(false);
  }, []);
  const expandMouseMove = useCallback(
    (e: MouseEvent) => {
      if (expandStart) {
        const newExpand = expand - (expandX - e.clientX);
        if (newExpand > 200 && newExpand < 900) {
          setExpand(expand - (expandX - e.clientX));
        } else if (newExpand >= 900) setExpand(900);
        else setExpand(200);
      }
    },
    [expandX, expandStart],
  );

  useEffect(() => {
    if (expandElement) {
      expandElement.addEventListener('mousedown', expandMouseDown);
      document.addEventListener('mouseup', expandMouseUp);
      document.addEventListener('mousemove', expandMouseMove);
    }

    return () => {
      if (expandElement) {
        expandElement.removeEventListener('mousedown', expandMouseDown);
        document.removeEventListener('mouseup', expandMouseUp);
        document.removeEventListener('mousemove', expandMouseMove);
      }
    };
  }, [expandElement, expandMouseDown, expandMouseUp, expandMouseMove]);

  const { t } = useTranslation();

  return (
    <th
      className={cn(css.header, {
        _isOpened: isOpened,
      })}
      ref={dropdownMenu}
      style={{
        ...style,
        width: isShowExpand ? `${expand}px` : 'auto',
        left: isShowExpand && isFixed ? `-${expand}px` : 'auto',
      }}
    >
      <div className={cn(css.button)}>
        {isShowExpand && isUtmTags && (
          <>
            <div
              className={cn('checkbox', {
                isChecked: allTitles.length === filterTable.length,
                isChildChecked:
                  allTitles.length > filterTable.length &&
                  filterTable.length > 0,
              })}
              onClick={() => {
                if (filterTable.length > 0) {
                  return onSetFilterTable([]);
                }

                return onSetFilterTable(allTitles.filter((e) => e !== 'total'));
              }}
            />
          </>
        )}
        <span className={css.text}>
          {title === 'utm_tags' ? t('charts.utm_tags') : title}
        </span>

        <div className={css.spacer} />
        <div className={css.more} onClick={isOpened ? toggleOff : toggle}>
          <FiMoreHorizontal size={20} />

          <div className={css.list}>
            {!nochart && (
              <>
                {chartType === 'ADD_CHART' && (
                  <div
                    className={css.item}
                    onClick={setCustomEvent(onClick, 'ADD_CHART')}
                  >
                    <FiPlus size={16} />
                    <span>
                      {t(
                        'campaigns_page.campaign_settings.reports.show_fromchart',
                      )}
                    </span>
                  </div>
                )}

                {chartType === 'DEL_CHART' && (
                  <div
                    className={css.item}
                    onClick={setCustomEvent(onClick, 'DEL_CHART')}
                  >
                    <FiX size={16} />
                    <span>
                      {t(
                        'campaigns_page.campaign_settings.reports.hide_fromchart',
                      )}
                    </span>
                  </div>
                )}
              </>
            )}

            {!notSort && (
              <>
                <div
                  className={cn(css.item, {
                    _isActive: sortType === 'ASCENDING',
                  })}
                  onClick={setCustomEvent(onClick, 'ASCENDING')}
                >
                  <FiArrowUp size={16} />
                  <span>
                    {t('campaigns_page.campaign_settings.reports.sort_high')}
                  </span>
                </div>
                <div
                  className={cn(css.item, {
                    _isActive: sortType === 'DESCENDING',
                  })}
                  onClick={setCustomEvent(onClick, 'DESCENDING')}
                >
                  <FiArrowDown size={16} />
                  <span>
                    {t('campaigns_page.campaign_settings.reports.sort_low')}
                  </span>
                </div>
                {(sortType === 'DESCENDING' || sortType === 'ASCENDING') && (
                  <div
                    className={css.item}
                    onClick={setCustomEvent(onClick, 'DEL_SORT')}
                  >
                    <FiMinus size={16} />
                    <span>
                      {t(
                        'campaigns_page.campaign_settings.reports.sort_cancel',
                      )}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className={css.sortIcon}>
          {sortType === 'DESCENDING' && <FiArrowDown size={16} />}
          {sortType === 'ASCENDING' && <FiArrowUp size={16} />}
        </div>
      </div>
      {isShowExpand && (
        <div
          className={cn(css.expand, {
            _isExpandStart: expandStart,
          })}
          ref={expandRef}
        >
          <div className={css.button}>
            <FiCode size={12} />
          </div>
        </div>
      )}
    </th>
  );
};

export default SortHeader;
