import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import cn from 'classnames';
import CardsList from 'components/UI/CardList';
import PreloaderFormList from 'components/Preloaders/FormList';
import Translations from 'components/Translation/TranslationWidget';
import PreloaderWidget from 'components/Preloaders/Widget';
import { useUserInfo, useUserTheme } from 'domains/user/model/selectors';
import PreviewModal from 'domains/creative/ui/PreviewModal';
import { useDispatch, useSelector } from 'react-redux';
import { selectorIsOpenPreviewModal } from 'domains/creative/model/selectors';
import { setOpenPreviewModal } from 'domains/creative/reduser';
import { useSavedField } from 'domains/campaign/model/hooks/useSavedField';
import { everyGroups } from 'utils/statics/everyGroups';
import HistoryChangeWidget from 'components/HistoryChangeWidget';
import { useDataCreativeTranslation } from '../../../model/hooks/useDataCreativeTranslation';
import PreviewWidget from '../../PreviewWidget';
import Moderation from '../Moderation';
import Creative from '../Forms/Creative';
import Additional from '../Forms/Additional';
import Type from '../Forms/Type';
import css from './styles.module.scss';
import { useBasicSettings } from './hooks';
import Subscription from '../../../../../components/Subscription';
import { CreativeDetail } from '../../../types';
import {
  isGardCompanionType,
  isGardHTMLType,
  isGardMultiFormatType,
  isGardPushType,
  isGardVideoInlineType,
  isGardVideoVastType,
  isGardYandexType,
} from '../../hooks';
import CollapseWrapper from '../../../../../components/CollapseWrapper';
import i18next from '../../../../../i18n';
import ScreenshotWidget from '../../ScreenshotWidget';

const Settings: FC = () => {
  const { getValueFromSettingForKey } = useUserTheme();
  const openModal = useSelector(selectorIsOpenPreviewModal);
  const dispatch = useDispatch();

  const onCloseModal = () => {
    dispatch(setOpenPreviewModal(false));
  };

  const { data: user } = useUserInfo();

  const {
    isLoadingCreativeDetail,
    isLoading,
    creativeDetail,
    handlerFetchModeration,
    allStatusModerationIsLoading,
    pastCreativeDetail,
    updatedCreativeHistory,
    getCreativeHistory,
    isCreativeHistoryLoading,
  } = useBasicSettings({user});

  const { savedField } = useSavedField({
    data: creativeDetail,
    pastData: pastCreativeDetail.data,
  });

  const hasTypeCreative = (element: CreativeDetail) => {
    const { type, size } = element;

    if (
      type === 'HTML' &&
      size &&
      isGardHTMLType(element.data) &&
      element.data.html_code
    ) {
      return true;
    }

    if (
      type === 'PUSH' &&
      isGardPushType(element.data) &&
      element.data.title &&
      element.data.text &&
      element.data.iconMask &&
      element.data.imgMask
    ) {
      return true;
    }

    if (
      type === 'YANDEX' &&
      isGardYandexType(element.data) &&
      element.data.img_yandex_html
    ) {
      return true;
    }

    if (
      type === 'VIDEO_VAST' &&
      isGardVideoVastType(element.data) &&
      element.data.vast_url &&
      size !== '0x0'
    ) {
      return true;
    }

    if (
      type === 'MULTI_FORMAT' &&
      isGardMultiFormatType(element.data) &&
      element.data.images.icon &&
      element.data.images.image_1 &&
      element.data.images.image_2
    ) {
      return true;
    }
    /* COMPANION под вопросом */
    if (
      type === 'COMPANION' &&
      isGardCompanionType(element.data) &&
      element.data.video_url &&
      element.data.video_poster_url &&
      element.data.html_code
    ) {
      return true;
    }

    return !!(
      type === 'VIDEO_INLINE' &&
      isGardVideoInlineType(element.data) &&
      element.data.video_url &&
      element.data.video_poster_url
    );
  };

  const {
    translationInfo,
    isLoading: isLoadingTranslation,
    handlerFetchTranslation,
    time,
    isError,
  } = useDataCreativeTranslation();

  useEffect(
    () => () => {
      localStorage.removeItem('localHistoryParams');
      localStorage.removeItem('changeType');
    },
    [],
  );

  return (
    <>
      <Helmet>
        <title>{`Настройки ${creativeDetail && creativeDetail.title}`}</title>
      </Helmet>

      <div className={css.content}>
        <div className={cn(css.container, 'container')}>
          <CardsList className={css.cards}>
            {(isLoadingCreativeDetail || creativeDetail === null) && (
              <PreloaderFormList />
            )}
            {!isLoadingCreativeDetail && creativeDetail && (
              <>
                <Creative creative={creativeDetail} savedField={savedField} />
                <Type creative={creativeDetail} />
                <Additional creative={creativeDetail} />
              </>
            )}
          </CardsList>

          <CardsList>
            <div>
              {creativeDetail && (
                <Subscription
                  entity_id={creativeDetail.xxhash}
                  entity_type="Creative"
                />
              )}            
              {getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
                !getValueFromSettingForKey('ShowBroadcastStatus') && (
                  <CollapseWrapper
                    title={i18next.t('translations.title_widget')}
                    nameWrapper="CREATIVE_TRANSLATIONS"
                    fetcher={handlerFetchTranslation}
                    isLoading={isLoading}
                    classname={css.translation}
                  >
                    {!isLoading ? (
                      <Translations
                        onClickHandler={handlerFetchTranslation}
                        isLoading={isLoadingTranslation}
                        translationInfo={translationInfo}
                        time={time}
                        isError={isError}
                      />
                    ) : (
                      <PreloaderWidget />
                    )}
                  </CollapseWrapper>
                )}
              {creativeDetail && (
                <CollapseWrapper
                  title={i18next.t('creative.Moderation')}
                  nameWrapper="CREATIVE_MODERATION"
                  fetcher={handlerFetchModeration}
                >
                  <Moderation
                    creative={creativeDetail}
                    loading={isLoading}
                    getCreative={handlerFetchModeration}
                    allStatusIsLoading={allStatusModerationIsLoading}
                  />
                </CollapseWrapper>
              )}
              {creativeDetail &&
                creativeDetail.type !== 'CLICKUNDER' && (
                  <CollapseWrapper
                    title={i18next.t(
                      'creative.create_creative.Creative_preview',
                    )}
                    nameWrapper="CREATIVE_PREVIEW"
                  >
                    <PreviewWidget
                      show={hasTypeCreative(creativeDetail)}
                      creative={creativeDetail}
                    />
                  </CollapseWrapper>
                )}
              {creativeDetail?.type === 'HTML' &&
                everyGroups(user, 'owner') && (
                  <ScreenshotWidget creativeDetail={creativeDetail} />
                )}
              {user?.group.includes('owner') && (
                <CollapseWrapper
                  title={i18next.t(`history_changes_page.title`)}
                  nameWrapper="CAMPAIGN_HISTORY"
                >
                  {updatedCreativeHistory && !isCreativeHistoryLoading ? (
                    <HistoryChangeWidget
                      list={updatedCreativeHistory}
                      getHistory={getCreativeHistory}
                    />
                  ) : (
                    <PreloaderWidget />
                  )}
                </CollapseWrapper>
              )}
            </div>
          </CardsList>
        </div>
      </div>
      {creativeDetail && (
        <PreviewModal
          creative={creativeDetail}
          isOpen={openModal}
          onClose={onCloseModal}
          show={hasTypeCreative(creativeDetail)}
        />
      )}
    </>
  );
};

export default Settings;
