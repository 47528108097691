/* eslint-disable no-underscore-dangle */
import { shallowEqual, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import i18next from 'i18next';
import { Ttarget } from 'domains/campaign/types';
import { FetchedData } from '../../../redux/fetchedData';
import { clearAllTargetItems, fetchTargetData, updateTarget } from '../actions';
import { AppState } from '../../../redux/rootReducer';
import { RGetStatisticTarget, TargetKey, Tdata, TLeftD } from '../model/types';
import { isGardStatisticsGroup, isGardStatistics } from '../model/hooks';
import { useDispatchApp } from '../../../redux/rootSelectors';
import { DateRange } from '../types';
import i18n from '../../../i18n';
import { useCreativeDetailInfo } from '../../creative/model/selectors';
import { arTargetTree, countLimit, includeValidLimit } from '../model/shared';
import { CreativeDetail } from '../../creative/types';
import {
  useSettingsTargetInfo,
  useTargetRequestInfo,
  useTargetUserSettings,
} from '../model/selectors';
import { mapKeysTurn } from '../const';
import {
  createLeftD,
  recursiveToggle,
  sortList,
  transformDataItemsNew,
  transformLeftDItemsNew,
} from '../model';

type PUseDataGrid = {
  /** Таргет креатива или кампании */
  isCreative: boolean;
  /** тип таргета */
  type: TargetKey;
  /** ID Креатива/кампании */
  xxhash: string;
  /** Айди Клиента */
  campaign_xxhash: string;
  /** Период */
  period: DateRange;
  /** переключатель */
  toggle: boolean;
  /** флаг отмены */
  isCancel: boolean;
  /** Тема для таргета */
  view: number;
  /** показывать статистику */
  isShowStatistics: boolean;
  showSaveButton: boolean;
  searchRefresh: () => void;
  setShowSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
  onlyChecked: boolean;
  isSourceLikeLogic: boolean;
};
type RUseDataGrid = {
  /** настройки таргета */
  settings: ReturnType<typeof useSettingsTargetInfo>['data'];
  /** строка тотал */
  total: Tdata | null;
  /** data */
  data: Tdata[];
  /** левый столбец */
  leftD: TLeftD[];
  setLeftD: React.Dispatch<React.SetStateAction<TLeftD[]>>;
  /** подготовка данных */
  isLoading: boolean;
  /** есть ли данные */
  isEmpty: boolean;
  //* активный */
  active: boolean;
  /** Использовать ли настройки по-умолчанию?  */
  use_inherit_settings: boolean;
  /** сбрасывает добавленые/удаленные в таблицу поля
   * @clear очищает */
  setMiddeleData: (clear?: boolean) => void;
  /** Режим инвертирования.
   * При false (по-умолчанию) работает в режиме whitelist,
   * true - blacklist */
  isInvertMode: boolean;
  /** установить Режим инвертирования. */
  setIsInvertMode: (v: boolean) => void;
  /** хэндлер для обновления каталога */
  updateCatalogHandler: () => void;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  items: Ttarget;
  statisticsIsGroup: boolean;
  userSettingsLTU: number;
  creativeLTU: number;
  isError?: boolean;
  fetchStatistics: () => void;
  isInvertModeServe: boolean | undefined;
  search: string;
  setSearch: (v: string) => void;
  refreshTargetDataHandler: () => void;
  typeCreative: CreativeDetail['type'] | undefined;
  isValidatedTarget: boolean;
  errorValidateText: string;
  setErrorValidateText: React.Dispatch<React.SetStateAction<string>>;
  error: ReturnType<typeof useTargetRequestInfo>['error'];
  setData: React.Dispatch<React.SetStateAction<Tdata[]>>;
  statisticTarget: FetchedData<RGetStatisticTarget> | undefined;
  deletedItemsArr: React.MutableRefObject<string[]>;
  deleteAllTargetItems: () => void;
  clearDeletedArr: () => void;
  setisLoadingClearTarget: React.Dispatch<React.SetStateAction<boolean>>;
  setTouchedItemsToSave: React.Dispatch<React.SetStateAction<string[]>>;
  touchedItemsToSave: string[];
};

export function useDataGrid({
  type,
  xxhash,
  campaign_xxhash,
  period,
  toggle,
  isCancel,
  isCreative,
  isSourceLikeLogic,
  view,
  isShowStatistics,
  showSaveButton,
  searchRefresh,
  setShowSaveButton,
  onlyChecked,
}: PUseDataGrid): RUseDataGrid {
  const dispatch = useDispatchApp();

  const [statisticsIsGroup, setStatisticsIsGroup] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(() =>
    [1, 2, 3, 4].includes(view) ? isShowStatistics : true,
  );
  const [total, setTotal] = useState<Tdata | null>(null);
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const [isInvertMode, setIsInvertMode] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [touchedItemsToSave, setTouchedItemsToSave] = useState<string[]>([]);

  const [errorValidateText, setErrorValidateText] = useState('');

  const { error } = useTargetRequestInfo(type);

  const statisticTarget = useSelector<
    AppState,
    FetchedData<RGetStatisticTarget> | undefined
  >(({ targetReducer }) => targetReducer.statisticTarget?.[type], shallowEqual);

  const { data: settings, isLoading: isLoadingSettings } =
    useSettingsTargetInfo(type);

  const updateCatalogHandler = () => {
    setIsLoading(true);
    setShowSaveButton(false);
    dispatch(updateTarget({ targetName: type, campaign_xxhash }));
  };

  const refreshTargetDataHandler = () => {
    if (toggle && !showSaveButton && !isLoading) {
      setIsLoading(true);
      setTotal(null);
      dispatch(
        fetchTargetData({
          xxhash,
          targetName: type,
          campaign_xxhash,
          period,
          isSettings: true,
          isStatistics: isShowStatistics,
        }),
      );
    }
  };

  const fetchStatistics = useCallback(() => {
    setTotal(null);
    dispatch(
      fetchTargetData({
        xxhash,
        targetName: type,
        campaign_xxhash,
        period,
        isSettings: false,
        isStatistics: isShowStatistics,
      }),
    );
  }, [isShowStatistics, period]);

  interface SpotItem {
    id: number;
    placementId: string;
    title: string;
    status: string;
    platformDomain: string;
  }

  type SpotsData = SpotItem[];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoadingSpot, setIsLoadingSpot] = useState(false);
  const [spotDict, setSpotDict] = useState<Map<string, string> | null>(null);

  const fetchSpots = async (): Promise<void> => {
    const url = 'https://adm.spotsnetwork.com/exec/export/spots-all.json';
    try {
      setIsLoadingSpot(true);
      const spotsResponse: Response = await fetch(url, {
        cache: 'no-cache',
      });
      const result: SpotsData = await spotsResponse.json();
      const spotDictMap: Map<string, string> = new Map();
      result.forEach((item) => {
        if (item.status === 'active') {
          spotDictMap.set(item.placementId, `${item.platformDomain} ${item.title}`);
        }
      });
      setSpotDict(spotDictMap);
    } catch (e) {
      console.error({ e });
    } finally {
      setIsLoadingSpot(false);
    }
  };

  useEffect(() => {
    if (!showSaveButton) {
      if (toggle) {
        setTotal(null);
        dispatch(
          fetchTargetData({
            xxhash,
            targetName: type,
            campaign_xxhash,
            period,
            isSettings: true,
            isStatistics: isShowStatistics,
          }),
        );
        if (type === 'placement') {
          fetchSpots();
        }
      }
    }
  }, [toggle]);

  useEffect(() => {
    if (toggle) {
      setTotal(null);
      dispatch(
        fetchTargetData({
          xxhash,
          targetName: type,
          campaign_xxhash,
          period,
          isSettings: true,
          isStatistics: isShowStatistics,
        }),
      );
      if (type === 'placement') {
        fetchSpots();
      }
    }
  }, [xxhash, type, period, campaign_xxhash]);

  useEffect(() => {
    if (toggle) {
      setTotal(null);
      dispatch(
        fetchTargetData({
          xxhash,
          targetName: type,
          campaign_xxhash,
          period,
          isSettings: false,
          isStatistics: isShowStatistics,
        }),
      );
      if (type === 'placement') {
        fetchSpots();
      }
    }
  }, [isShowStatistics]);

  const { data: userSettings, LTU: userSettingsLTU } = useTargetUserSettings();
  const { data: creativeDetail, LTU: creativeLTU } = useCreativeDetailInfo();

  const items: Ttarget = useMemo(
    () => (userSettings ? userSettings[type] : ({} as Ttarget)),
    [isCreative, userSettingsLTU, creativeLTU],
  );

  const typeCreative = useMemo(() => {
    if (isCreative) {
      return creativeDetail?.type;
    }
    return undefined;
  }, [creativeLTU]);

  const use_inherit_settings = !!(
    userSettings && userSettings[type]?.settings?.use_inherit_settings
  );

  const [leftD, setLeftD] = useState<TLeftD[]>([]);

  const [data, setData] = useState<Tdata[]>([]);


  const [active, setAactive] = useState<boolean>(false);

  const deletedItemsArr = useRef<string[]>([])

  const [isLoadingClearTarget, setisLoadingClearTarget] = useState(false);

  const recursiveLeftDIsChecked = (item: TLeftD, deletedArr: string[]) => {
    const isStatic = settings?.type ? [1, 5].includes(settings.type) : false;
    if (!item?.children?.length && isStatic || isSourceLikeLogic || !isStatic || statisticsIsGroup) {
      if(items.items[item.key]) {
        deletedArr.push(item.key);
      }
    }
    if (item?.children && item?.children.length && !statisticsIsGroup) {
      item.children.forEach((child) => recursiveLeftDIsChecked(child, deletedArr));
    }
  };

  const deleteAllTargetItems = (reCreate?: boolean) => {
    const deletedKeys: string[] = []
    if(reCreate) {
      leftD.forEach(item => recursiveLeftDIsChecked(item, deletedKeys))
    }
    dispatch(clearAllTargetItems({ xxhash, target_key: type, reCreate, deletedArr: deletedKeys, setisLoadingClearTarget }))
  }

  const clearDeletedArr = () => {
    // eslint-disable-next-line no-param-reassign
    deletedItemsArr.current = []
  }

  useEffect(() => {
    if (!arTargetTree.includes(type)) {
      if (onlyChecked) {
        setLeftD((leftData) =>
          leftData.map((item) => recursiveToggle(item, true)),
        );
        return;
      }
      setLeftD((leftData) =>
        leftData.map((item) => recursiveToggle(item, false)),
      );
    }
  }, [onlyChecked]);

  useEffect(() => {
    if (!items) return;
    setAactive(items.settings?.has_active_items);
    setIsInvertMode(items.settings?.is_invert_mode);
  }, [items]);
  // функция проходит по дереву итемов и устанавливает is_checked флаг на итеме по которому произошел клик
  const recursiveCeck = useCallback(
    (item: TLeftD, k: string, v: boolean): TLeftD => {
      if (item.key === k) {
        return {
          ...item,
          is_checked: v,
          _isSettings: true,
        };
      }
      if (item.children) {
        return {
          ...item,
          children: item.children.map((child) => recursiveCeck(child, k, v)),
        };
      }
      return item;
    },
    [leftD],
  );

  useEffect(() => {
    if (!settings) return setIsEmpty(true);
    if (statisticTarget?.get('error').isError) {
      setIsLoading(() => false);
    }
    if (!isShowStatistics && settings) {
      return setIsEmpty(true);
    }
    if (isShowStatistics) {
      if (!statisticTarget) return setIsEmpty(true);
      const dataT = statisticTarget.get('data');
      if (!dataT) return setIsEmpty(true);
      if (Object.keys(dataT).length === 0) return setIsEmpty(true);
      const keys = Object.keys(dataT);
      for (let i = 0; i < keys.length; i += 1) {
        if (
          'total' in dataT[keys[i]] &&
          !settings.catalog.some((item) => item.children)
        ) {
          setStatisticsIsGroup(true);
          break;
        } else {
          setStatisticsIsGroup(false);
        }
      }
    }
    return setIsEmpty(false);
  }, [statisticTarget, isShowStatistics, isLoadingSettings]);

  const setMiddleData = useCallback(
    (clear = false) => {
      const recordDataChild: Record<string, Tdata[]> = {};
      const recordLeftChild: Record<string, TLeftD[]> = {};
      const arr: Tdata[] = [];
      const leftDAr: TLeftD[] = [];
      const exitLWay = (arrD: Tdata[], arrL: TLeftD[]) => {
        if (view === 5) {
          const { leftSort, rightSort } = sortList(arrL, arrD);
          setData(rightSort);
          setLeftD(leftSort);
        } else {
          setData(arrD);
          setLeftD(arrL);
        }
      };
      const exitRWay = (arrD: Tdata[], arrL: TLeftD[]) => {
        if (view === 5) {
          const { leftSort, rightSort } = sortList(arrL, arrD);
          setData(rightSort);
          setLeftD(leftSort);
        } else {
          setData(arrD);
          setLeftD(arrL);
        }
        if (settings && !arTargetTree.includes(type)) {
          if (!statisticTarget) {
            setIsLoading(() => false);
          } else if (
            !statisticTarget.get('isLoading') &&
            statisticTarget.get('data') &&
            !isLoadingSettings
          ) {
            setIsLoading(() => false);
          }
        }
      };

      if (!items) return exitLWay(arr, leftDAr);
      if (!settings) return exitLWay(arr, leftDAr);
      if (!settings) return exitLWay(arr, leftDAr);
      const itemsKeys = clear ? [] : Object.keys(items.items);
      const addKeys: string[] = [];
      const addKeysD: string[] = [];

      const dataT = statisticTarget && statisticTarget.get('data');

      // Этот кусок логики используемый для таргетов в которых дерево строится от групп статы
      if (statisticsIsGroup) {
        if (isShowStatistics && !statisticTarget?.get('error').isError) {
          if (!dataT) return exitLWay(arr, leftDAr);
          Object.keys(dataT).forEach((k) => {
            recordDataChild[k] = [];
            recordLeftChild[k] = [];
          });
        }
      }

      if (isShowStatistics && !statisticTarget?.get('error').isError) {
        if (!dataT) return exitLWay(arr, leftDAr);
        Object.entries(dataT).forEach(([k, v]) => {
          const cat = settings.catalog.find(({ key }) => key === k);
          const title =
            (type === 'days_of_week' || type === 'days_of_week_user') &&
            i18next.exists(`common.target_items.${cat?.key}`)
              ? i18next.t(`common.target_items.${cat?.key}`)
              : cat?.title || (k === 'n/a' ? 'OTHER' : k);

          if (k === 'total') {
            return;
          }  
          if (itemsKeys.includes(k)) {
            if (statisticsIsGroup && isGardStatisticsGroup(v)) {
              leftDAr.push({
                ...items.items[k],
                key: k,
                title:
                  type === 'placement' ? spotDict?.get(title) || title : title,
                sort: cat?.sort ?? 500,
                _isSettings: false,
                isToggle: false,
                paddingScale: 1,
              });
            }
            if (
              !statisticsIsGroup &&
              isGardStatistics(v) &&
              [1, 2, 3, 4].includes(settings.type)
            ) {
              leftDAr.push({
                ...items.items[k],
                key: k,
                title:
                  type === 'placement' ? spotDict?.get(title) || title : title,
                sort: cat?.sort ?? 500,
                _isSettings: false,
                paddingScale: 1,
              });
            }
            addKeys.push(k);
          } else {
            if (
              !statisticsIsGroup &&
              isGardStatistics(v) &&
              [1, 2, 3, 4].includes(settings.type)
            ) {
              leftDAr.push(
                createLeftD({
                  k,
                  title:
                    type === 'placement'
                      ? spotDict?.get(title) || title
                      : title,
                  sort: cat?.sort ?? 500,
                }),
              );       
            }
            if (statisticsIsGroup && isGardStatisticsGroup(v)) {
              leftDAr.push(
                createLeftD({
                  k,
                  title:
                    type === 'placement'
                      ? spotDict?.get(title) || title
                      : title,
                  _isSettings: false,
                  isToggle: false,
                  value: undefined,
                  isChild: false,
                  sort: cat?.sort ?? 500,
                }),
              );
            }
          }
          if (statisticsIsGroup && isGardStatisticsGroup(v)) {
            arr.push({
              ...v.total,
              aclick: v.total?.aclick ?? 0,
              bid: items.items[k]?.bid ?? 0,
              bid_start: items.items[k]?.bid_start ?? 0,
              bid_rate: items.items[k]?.bid_rate ?? 1,
              hour: items.items[k]?.hour ?? '',
              maxbid: items.items[k]?.maxbid ?? '',
              type: items.items[k]?.type ?? '',
              value: items.items[k]?.value ?? '',
              key: k,
              title:
                type === 'placement' ? spotDict?.get(title) || title : title,
            });
            Object.entries(v).forEach(([key, value]) => {
              if (key !== 'total') {
                recordDataChild[k].push({
                  ...value,
                  isChild: 1,
                  aclick: value?.aclick ?? 0,
                  bid: items.items[k]?.bid ?? 0,
                  bid_start: items.items[k]?.bid_start ?? 0,
                  bid_rate: items.items[k]?.bid_rate ?? 1,
                  hour: items.items[k]?.hour ?? '',
                  maxbid: items.items[k]?.maxbid ?? '',
                  type: items.items[k]?.type ?? '',
                  value: items.items[k]?.value ?? '',
                  key,
                  title: key === 'n/a' ? 'OTHER' : key,
                });
              }
            });
            Object.entries(v).forEach(([key, value]) => {
              if (key !== 'total') {
                recordLeftChild[k].push({
                  ...value,
                  sort: 0,
                  maxbid: 0,
                  bid_rate: 0,
                  is_checked: false,
                  key,
                  title: key === 'n/a' ? 'OTHER' : key,
                  _isSettings: false,
                  isChild: true,
                  paddingScale: 1,
                });
              }
            });
          }
          if (
            !statisticsIsGroup &&
            isGardStatistics(v) &&
            [1, 2, 3, 4].includes(settings.type)
          ) {
            arr.push({
              ...v,
              aclick: v.aclick ?? 0,
              bid: items.items[k]?.bid ?? 0,
              bid_start: items.items[k]?.bid_start ?? 0,
              bid_rate: items.items[k]?.bid_rate ?? 1,
              hour: items.items[k]?.hour ?? '',
              maxbid: items.items[k]?.maxbid ?? '',
              type: items.items[k]?.type ?? '',
              value: items.items[k]?.value ?? '',
              limits: clear ? {} : items.items[k]?.limits,
              key: k,
              title: type === 'placement' ? spotDict?.get(title) || title : title,
            });
          }
          if ([1, 2, 3, 4].includes(settings.type) || statisticsIsGroup) {
            addKeysD.push(k);
          }
        });
      }

      if (dataT && dataT.total && isGardStatistics(dataT.total)) {
        setTotal({
          ...dataT.total,
          aclick: dataT.total.aclick || 0,
          key: 'total',
          title: 'total',
        });
      }
      // Пуш модифицированных итемов дерева по массивам для отрисовки левой и правой части таргета
      if ([1, 5].includes(settings.type)) {
        settings.catalog.forEach((item) => {
          if (!addKeysD.includes(item.key)) {
            if (
              (type === 'days_of_week' || type === 'days_of_week_user') &&
              i18next.exists(`common.target_items.${item.key}`)
            ) {
              // eslint-disable-next-line no-param-reassign
              item = {
                ...item,
                title: i18next.t(`common.target_items.${item.key}`),
              };
            }
            const savedItems = clear ? {} : items.items;
            leftDAr.push(
              transformLeftDItemsNew(item, 1, type, onlyChecked, isSourceLikeLogic, savedItems),
            );
            arr.push(transformDataItemsNew(item, type,isSourceLikeLogic, savedItems, dataT));
          }
        });
      }

      if ([2, 3, 4].includes(settings.type)) {
        const dynamicArr = clear
          ? []
          : Object.keys(items.items).map((k) => {
              const cat = settings.catalog.find(({ key }) => key === k);
              const title =
                type === 'placement'
                  ? spotDict?.get(k) ?? k
                  : cat?.title || (mapKeysTurn.get(k) ?? k);
              return {
                key: k,
                title,
                sort: 500,
              };
            });
        dynamicArr.forEach((item) => {
          if (!addKeysD.includes(item.key)) {
            if (
              (type === 'days_of_week' || type === 'days_of_week_user') &&
              i18next.exists(`common.target_items.${item.key}`)
            ) {
              // eslint-disable-next-line no-param-reassign
              item = {
                ...item,
                title: i18next.t(`common.target_items.${item.key}`),
              };
            }
            leftDAr.push(
              transformLeftDItemsNew(
                item,
                1,
                type,
                onlyChecked,
                isSourceLikeLogic,
                items.items,
                undefined,
                spotDict,
              ),
            );
            arr.push(
              transformDataItemsNew(item, type, isSourceLikeLogic, items.items, dataT, spotDict),
            );
          }
        });
      }
      const finalLeftDAr = leftDAr.map((leftItem) =>
        recordLeftChild[leftItem.key]
          ? {
              ...leftItem,
              children: recordLeftChild[leftItem.key],
            }
          : leftItem,
      );
      const finalRightArr = arr.map((rightItem) =>
        recordDataChild[rightItem.key]
          ? { ...rightItem, children: recordDataChild[rightItem.key] }
          : rightItem,
      );
      return exitRWay(finalRightArr, finalLeftDAr);
    },
    [
      statisticTarget,
      items,
      settings,
      type,
      isCancel,
      statisticsIsGroup,
      isLoadingSettings,
      spotDict,
    ],
  );

  // Сейчас функция запускается при операциях с гео таргетами
  // для них она готовит стату и тотал
  // на будущее стоит вынести эту логику в отдельную функцию

  useEffect(() => {
    setMiddleData();
  }, [
    statisticTarget,
    items,
    type,
    isLoadingSettings,
    statisticsIsGroup,
    spotDict,
  ]);

  useEffect(() => {
    setMiddleData();
    if (type === 'geo_id') {
      searchRefresh();
    }
  }, [isCancel]);

  const isValidatedTarget = useMemo<boolean>(() => {
    if (includeValidLimit.findIndex((v) => v === type) > -1) {
      if (
        leftD.filter(({ is_checked }) => is_checked).length >
        countLimit[`${type}`]
      ) {
        setErrorValidateText(i18n.t(`common.targetings_error.${type}.count`));
        return false;
      }
    }
    if (errorValidateText) {
      setErrorValidateText('');
    }
    return true;
  }, [leftD]);

  return {
    statisticsIsGroup,
    items,
    settings,
    total,
    data,
    leftD,
    isLoading: isLoading || isLoadingClearTarget,
    isEmpty,
    active,
    use_inherit_settings: use_inherit_settings ?? false,
    setMiddeleData: setMiddleData,
    isInvertModeServe: items?.settings?.is_invert_mode,
    isInvertMode,
    setIsInvertMode,
    setIsLoading,
    userSettingsLTU,
    creativeLTU,
    isError: statisticTarget?.get('error').isError,
    fetchStatistics,
    refreshTargetDataHandler,
    updateCatalogHandler,
    search,
    setSearch,
    typeCreative,
    isValidatedTarget,
    errorValidateText,
    deletedItemsArr,
    setErrorValidateText,
    deleteAllTargetItems,
    setisLoadingClearTarget,
    error,
    setLeftD,
    setData,
    statisticTarget,
    clearDeletedArr,
    setTouchedItemsToSave,
    touchedItemsToSave,
  };
}
