import { IAddLimitsModalProp } from 'domains/target/model/types';
import { ILimitItem, ILimits } from 'domains/target/types';
import { FormikErrors, FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';

export type TFUseLimitsFormik = (
  onChangeLimits: IAddLimitsModalProp['onChangeLimits'],
  k: string,
  limits?: ILimits,
) => {
  formik: FormikProps<ILimits>;
};

type TFValidate = (values: ILimits) => FormikErrors<ILimits>;

export const useLimitsFormik: TFUseLimitsFormik = (
  onChangeLimits,
  k,
  limits,
) => {
  const initialValues = {
    show: limits?.show || {},
    /* show_per_user: limits?.show_per_user || {}, */
  };

  function isTotal(obj: ILimitItem): obj is { total: number } {
    return Object.prototype.hasOwnProperty.call(obj, 'total');
  }

  const tabs = ['click', 'show', 'show_per_user', 'budget', 'budget_raw'];

  const validateSchemeTarget = Yup.object().shape({
    budget: Yup.object().shape({
      total: Yup.number().integer().max(2147483647).nullable(),
      day: Yup.number().integer().max(2147483647).nullable(),
    }),
    click: Yup.object().shape({
      total: Yup.number().integer().max(2147483647).nullable(),
      day: Yup.number().integer().max(2147483647).nullable(),
    }),
    show: Yup.object().shape({
      total: Yup.number().integer().max(2147483647).nullable(),
      day: Yup.number().integer().max(2147483647).nullable(),
    }),
    show_per_user: Yup.object().shape({
      total: Yup.number().integer().max(2147483647).nullable(),
      day: Yup.number().integer().max(2147483647).nullable(),
    }),
    budget_raw: Yup.object().shape({
      total: Yup.number().integer().max(2147483647).nullable(),
      day: Yup.number().integer().max(2147483647).nullable(),
    }),
  });

  const validateFormTarget: TFValidate = (values) => {
    let errors: FormikErrors<ILimits> = {};

    tabs.forEach((tab) => {
      if (tab in values) {
        const { day } = values[tab];
        const obj = values[tab];
        let total = 0;
        if (isTotal(obj)) {
          total = obj.total ? +obj.total : 0;
        }
        const input: {
          total: string | undefined;
          day: string | undefined;
        } = {
          total: undefined,
          day: undefined,
        };

        if (typeof day === 'number') {
          input.day = day && day > total && total ? 'true' : undefined;
        }
        if (typeof total === 'number') {
          input.total = total && total < day && day ? 'true' : undefined;
        }

        if (Object.values(input).includes('true')) {
          if (errors === undefined) {
            errors = {};
          }
          errors[tab] = input;
        }
      }
    });

    return errors;
  };

  const formik = useFormik<ILimits>({
    initialValues,
    validate: validateFormTarget,
    validationSchema: validateSchemeTarget,
    onSubmit: (values) => {
      onChangeLimits(k, values);
    },
  });

  return {
    formik,
  };
};
