import React, { FC } from 'react';
import i18next from 'i18next';
import img from 'assets/errors/adblocker.svg';
import css from './styles.module.scss';

const AdBlocker: FC = () => (
  <div className={css.errorAB__wrapper}>
    <div className={css.errorAB__img}>
      <img src={img} alt="Ошибка блокировщика рекламы" />
    </div>
    <h1 className={css.errorAB__title}>
      {i18next.t('error_page.errAB.title')}
    </h1>
    <p className={css.errorAB__text}>{i18next.t('error_page.errAB.text')}</p>
  </div>
);

export default AdBlocker;
