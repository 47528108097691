import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-use';
import { useCampaignsInfo } from 'domains/campaigns/model/selectors';
import {
  clearCampaignsPeriod,
  clearChartStatistic,
  setClearCampaignsList,
} from 'domains/campaigns/reducer';
import { AppState } from '../../../redux/rootReducer';
import { ClientsState, fetchClients } from '../reducer';
import { FetchedDataErrors } from '../../../types/general';
import { useStorageClientsTabs } from '../../storage/model/selectors';
import { setClientsTabs } from '../../storage/model/reducer';
import { useDispatchApp } from '../../../redux/rootSelectors';
import { ClientsList, Status } from '../types';

type IUseDataClients = {
  isLoading: boolean;
  clients: ClientsList;
  status: Status | null;
  setStatusAction: (param: Status | null) => void;
  idAgency: string | null;
  error: FetchedDataErrors;
};
export const useDataClients = (): IUseDataClients => {
  const dispatch = useDispatchApp();
  const { tempClients } = useSelector<AppState, ClientsState>(
    (state) => state.clientsReducer,
  );
  const { data: campaignsList } = useCampaignsInfo();
  const status = useStorageClientsTabs();
  const isLoading = tempClients.get('isLoading');
  const clients = tempClients.get('data') || [];
  const error = tempClients.get('error');

  const location = useLocation();
  const idAgency = new URLSearchParams(location.search).get('agency_id');

  useEffect(() => {
    if (status) {
      dispatch(fetchClients({ status, idAgency }));
    } else {
      dispatch(fetchClients({ status: null, idAgency }));
    }
  }, [status, idAgency]);

  useEffect(() => {
    if (campaignsList) {
      dispatch(setClearCampaignsList());
      dispatch(clearCampaignsPeriod());
    }
    dispatch(clearChartStatistic());
  }, []);

  const setStatusAction = (param: Status | null) => {
    dispatch(setClientsTabs(param));
  };

  return {
    isLoading,
    clients,
    status,
    setStatusAction,
    idAgency,
    error,
  };
};
