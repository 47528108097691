import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { FormikProps } from "formik";
import { TGetAuditDictionary } from "domains/agency/types";
import { TAddAuditData } from "../../Settings/hook/useSettings";

interface IUseAudiencesMlProps {
    editAuditDictionary: (id: number, editedData: TAddAuditData) => void;
    intermediateFormik: FormikProps<TGetAuditDictionary[]>;
    formik: FormikProps<TGetAuditDictionary[]>;
}

type TFUseAudiencesMl = (props: IUseAudiencesMlProps) => {
    openSearch: boolean,
    valuesState: boolean,
    audienceBlockRef: React.RefObject<HTMLDivElement>,
    searchQuery: string,
    handleClickOpen: (id: string | number) => void,
    handleClose: () => void,
    openModal: string | number | null,
    returnErrorText: (errObj: any, index: any) => any,
    deleteItem: (currId: number | string, inputValue: string) => void,
    addIntermediateAudits: () => void,
    setOpenSearch: React.Dispatch<React.SetStateAction<boolean>>,
    setSearchQuery: React.Dispatch<React.SetStateAction<string>>,
    searchRef: React.RefObject<HTMLInputElement>,
    intermediateState: boolean,
    emptySearch: boolean,
}

export const useAudiencesMl: TFUseAudiencesMl = ({ editAuditDictionary, intermediateFormik, formik }) => {
    const [openSearch, setOpenSearch] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const searchRef = useRef<HTMLInputElement>(null);
    const audienceBlockRef = useRef<HTMLDivElement>(null);
    const [openModal, setOpenModal] = useState<(string | number) | null>(null);
    const [emptySearch, setEmptySearch] = useState<boolean>(false);

    const handleClickOpen = (id: string | number) => {
        setOpenModal(id);
    };

    const handleClose = () => {
        setOpenModal(null);
    };

    const deleteItem = (currId: number | string, inputValue: string) => {
        localStorage.setItem('deleteAudience', 'true');
        editAuditDictionary(+currId, { title: inputValue, status: 'DELETED' });
    };

    const returnErrorText = (errObj: any, index: any) => {
        const { hasOwnProperty } = Object;
        if (hasOwnProperty.call(errObj, index)) {
            return errObj[index].title;
        }
        return undefined;
    };

    const addIntermediateAudits = () => {
        const id = uuidv4();
        const newObj = {
            id,
            title: '',
            status: 'LAUNCHED',
        };
        const currentArr = intermediateFormik.values;
        const updatedArr = [...currentArr, newObj];
        intermediateFormik.setValues(updatedArr);
        localStorage.setItem('isFocused', JSON.stringify(id));
        scrollToBottom();
    };

    const intermediateState = !!intermediateFormik.values.length;
    const valuesState = !!formik.values.length;

    const scrollToBottom = () => {
        setTimeout(() => {
            if (audienceBlockRef && audienceBlockRef.current) {
                audienceBlockRef.current.scrollTo(0, audienceBlockRef.current.scrollHeight);
            }
        }, 100)
    }

    useEffect(() => {
        if (searchQuery !== '' && formik.values
            .filter((data) =>
                data.title.toLowerCase().includes(searchQuery.toLocaleLowerCase()),
            ).length === 0) {
            setEmptySearch(true)
        } else {
            setEmptySearch(false)
        }
    }, [searchQuery])

    return {
        openSearch,
        valuesState,
        audienceBlockRef,
        searchQuery,
        handleClickOpen,
        handleClose,
        openModal,
        returnErrorText,
        deleteItem,
        addIntermediateAudits,
        setOpenSearch,
        setSearchQuery,
        searchRef,
        intermediateState,
        emptySearch,
    }
}