import { TModertionStatusLiteral } from '../../creatives/statuses';

export const additional_settings_switch_tabs = {
  SHOW: ['show', 'show2'],
  QUARTILES: ['view_25', 'view_50', 'view_75', 'view_100'],
} as const;

export const statusDisapprovedGroup: TModertionStatusLiteral[] = [
  'DISAPPROVED',
  'CONDITIONALLY_APPROVED',
];

export const optionsButtonCreativeMulti = [
  { label: 'Перейти', value: 'Перейти' },
  { label: 'В магазин', value: 'В магазин' },
  { label: 'Вступить', value: 'Вступить' },
  { label: 'Выбрать', value: 'Выбрать' },
  { label: 'Забронировать', value: 'Забронировать' },
  { label: 'Загрузить', value: 'Загрузить' },
  { label: 'Заказать', value: 'Заказать' },
  { label: 'Записаться', value: 'Записаться' },
  { label: 'Зарегистрироваться', value: 'Зарегистрироваться' },
  { label: 'Играть', value: 'Играть' },
  { label: 'Купить', value: 'Купить' },
  { label: 'Купить билет', value: 'Купить билет' },
  { label: 'Меню', value: 'Меню' },
  { label: 'Начать', value: 'Начать' },
  { label: 'Открыть', value: 'Открыть' },
  { label: 'Подать заявку', value: 'Подать заявку' },
  { label: 'Подробнее', value: 'Подробнее' },
  { label: 'Позвонить', value: 'Позвонить' },
  { label: 'Получить', value: 'Получить' },
  { label: 'Попробовать', value: 'Попробовать' },
  { label: 'Связаться', value: 'Связаться' },
  { label: 'Слушать', value: 'Слушать' },
  { label: 'Смотреть', value: 'Смотреть' },
  { label: 'Создать', value: 'Создать' },
  { label: 'Узнать', value: 'Узнать' },
];

export const optionsLanguage = [
  { label: 'Абхазский', value: 'ab' },
  { label: 'Аварский', value: 'av' },
  {
    label: 'Авестийский',
    value: 'ae',
  },
  { label: 'Азербайджанский', value: 'az' },
  { label: 'Аймара', value: 'ay' },
  {
    label: 'Акан',
    value: 'ak',
  },
  { label: 'Албанский', value: 'sq' },
  { label: 'Амхарский', value: 'am' },
  {
    label: 'Английский',
    value: 'en',
  },
  { label: 'Арабский', value: 'ar' },
  { label: 'Армянский', value: 'hy' },
  {
    label: 'Ассамский',
    value: 'as',
  },
  { label: 'Афарский', value: 'aa' },
  { label: 'Африкаанс', value: 'af' },
  {
    label: 'Бамбара',
    value: 'bm',
  },
  { label: 'Баскский', value: 'eu' },
  { label: 'Башкирский', value: 'ba' },
  {
    label: 'Белорусский',
    value: 'be',
  },
  { label: 'Бенгальский', value: 'bn' },
  { label: 'Бирманский', value: 'my' },
  {
    label: 'Бислама',
    value: 'bi',
  },
  { label: 'Болгарский', value: 'bg' },
  { label: 'Боснийский', value: 'bs' },
  {
    label: 'Бретонский',
    value: 'br',
  },
  { label: 'Валлийский', value: 'cy' },
  { label: 'Венгерский', value: 'hu' },
  {
    label: 'Венда',
    value: 've',
  },
  { label: 'Волапюк', value: 'vo' },
  { label: 'Волоф', value: 'wo' },
  {
    label: 'Вьетнамский',
    value: 'vi',
  },
  { label: 'Галисийский', value: 'gl' },
  { label: 'Ганда', value: 'lg' },
  {
    label: 'Гереро',
    value: 'hz',
  },
  { label: 'Гренландский', value: 'kl' },
  {
    label: 'Греческий (новогреческий)',
    value: 'el',
  },
  { label: 'Грузинский', value: 'ka' },
  { label: 'Гуарани', value: 'gn' },
  {
    label: 'Гуджарати',
    value: 'gu',
  },
  { label: 'Гэльский', value: 'gd' },
  { label: 'Датский', value: 'da' },
  {
    label: 'Дзонг-кэ',
    value: 'dz',
  },
  { label: 'Дивехи (Мальдивский)', value: 'dv' },
  { label: 'Зулу', value: 'zu' },
  {
    label: 'Иврит',
    value: 'he',
  },
  { label: 'Игбо', value: 'ig' },
  { label: 'Идиш', value: 'yi' },
  {
    label: 'Индонезийский',
    value: 'id',
  },
  { label: 'Интерлингва', value: 'ia' },
  { label: 'Интерлингве', value: 'ie' },
  {
    label: 'Инуктитут',
    value: 'iu',
  },
  { label: 'Инупиак', value: 'ik' },
  { label: 'Ирландский', value: 'ga' },
  {
    label: 'Исландский',
    value: 'is',
  },
  { label: 'Испанский', value: 'es' },
  { label: 'Итальянский', value: 'it' },
  {
    label: 'Йоруба',
    value: 'yo',
  },
  { label: 'Казахский', value: 'kk' },
  { label: 'Каннада', value: 'kn' },
  {
    label: 'Канури',
    value: 'kr',
  },
  { label: 'Каталанский', value: 'ca' },
  { label: 'Кашмири', value: 'ks' },
  {
    label: 'Кечуа',
    value: 'qu',
  },
  { label: 'Кикуйю', value: 'ki' },
  { label: 'Киньяма', value: 'kj' },
  {
    label: 'Киргизский',
    value: 'ky',
  },
  { label: 'Китайский', value: 'zh' },
  { label: 'Коми', value: 'kv' },
  {
    label: 'Конго',
    value: 'kg',
  },
  { label: 'Корейский', value: 'ko' },
  { label: 'Корнский', value: 'kw' },
  {
    label: 'Корсиканский',
    value: 'co',
  },
  { label: 'Коса', value: 'xh' },
  { label: 'Курдский', value: 'ku' },
  {
    label: 'Кхмерский',
    value: 'km',
  },
  { label: 'Лаосский', value: 'lo' },
  { label: 'Латинский', value: 'la' },
  {
    label: 'Латышский',
    value: 'lv',
  },
  { label: 'Лингала', value: 'ln' },
  { label: 'Литовский', value: 'lt' },
  {
    label: 'Луба-катанга',
    value: 'lu',
  },
  { label: 'Люксембургский', value: 'lb' },
  {
    label: 'Македонский',
    value: 'mk',
  },
  { label: 'Малагасийский', value: 'mg' },
  { label: 'Малайский', value: 'ms' },
  {
    label: 'Малаялам',
    value: 'ml',
  },
  { label: 'Мальтийский', value: 'mt' },
  { label: 'Маори', value: 'mi' },
  {
    label: 'Маратхи',
    value: 'mr',
  },
  { label: 'Маршалльский', value: 'mh' },
  { label: 'Молдавский', value: 'mo' },
  {
    label: 'Монгольский',
    value: 'mn',
  },
  { label: 'Мэнский (Мэнкский)', value: 'gv' },
  { label: 'Навахо', value: 'nv' },
  {
    label: 'Науру',
    value: 'na',
  },
  { label: 'Ндебеле северный', value: 'nd' },
  { label: 'Ндебеле южный', value: 'nr' },
  {
    label: 'Ндунга',
    value: 'ng',
  },
  { label: 'Немецкий', value: 'de' },
  {
    label: 'Непальский',
    value: 'ne',
  },
  { label: 'Нидерландский (Голландский)', value: 'nl' },
  {
    label: 'Норвежский [Norsk (bokmål)]‬',
    value: 'no',
  },
  { label: 'Ньянджа', value: 'ny' },
  {
    label: 'Нюнорск (новонорвежский) [Norsk (nynorsk)]‬',
    value: 'nn',
  },
  { label: 'Оджибве', value: 'oj' },
  { label: 'Окситанский', value: 'oc' },
  {
    label: 'Ория',
    value: 'or',
  },
  { label: 'Оромо', value: 'om' },
  { label: 'Осетинский', value: 'os' },
  {
    label: 'Пали',
    value: 'pi',
  },
  { label: 'Пенджабский', value: 'pa' },
  { label: 'Персидский', value: 'fa' },
  {
    label: 'Польский',
    value: 'pl',
  },
  { label: 'Португальский', value: 'pt' },
  { label: 'Пушту', value: 'ps' },
  {
    label: 'Ретороманский',
    value: 'rm',
  },
  { label: 'Руанда', value: 'rw' },
  { label: 'Румынский', value: 'ro' },
  {
    label: 'Рунди',
    value: 'rn',
  },
  { label: 'Русский', value: 'ru' },
  { label: 'Самоанский', value: 'sm' },
  {
    label: 'Санго',
    value: 'sg',
  },
  { label: 'Санскрит', value: 'sa' },
  { label: 'Сардинский', value: 'sc' },
  {
    label: 'Свази',
    value: 'ss',
  },
  { label: 'Сербский', value: 'sr' },
  { label: 'Сингальский', value: 'si' },
  {
    label: 'Синдхи',
    value: 'sd',
  },
  { label: 'Словацкий', value: 'sk' },
  { label: 'Словенский', value: 'sl' },
  {
    label: 'Сомали',
    value: 'so',
  },
  { label: 'Сото южный', value: 'st' },
  { label: 'Суахили', value: 'sw' },
  {
    label: 'Сунданский',
    value: 'su',
  },
  { label: 'Тагальский', value: 'tl' },
  { label: 'Таджикский', value: 'tg' },
  {
    label: 'Тайский',
    value: 'th',
  },
  { label: 'Таитянский', value: 'ty' },
  { label: 'Тамильский', value: 'ta' },
  {
    label: 'Татарский',
    value: 'tt',
  },
  { label: 'Тви', value: 'tw' },
  { label: 'Телугу', value: 'te' },
  {
    label: 'Тибетский',
    value: 'bo',
  },
  { label: 'Тигринья', value: 'ti' },
  { label: 'Тонганский', value: 'to' },
  {
    label: 'Тсвана',
    value: 'tn',
  },
  { label: 'Тсонга', value: 'ts' },
  { label: 'Турецкий', value: 'tr' },
  {
    label: 'Туркменский',
    value: 'tk',
  },
  { label: 'Узбекский', value: 'uz' },
  { label: 'Уйгурский', value: 'ug' },
  {
    label: 'Украинский',
    value: 'uk',
  },
  { label: 'Урду', value: 'ur' },
  { label: 'Фарерский', value: 'fo' },
  {
    label: 'Фиджи',
    value: 'fj',
  },
  { label: 'Филиппинский', value: 'fl' },
  {
    label: 'Финский (Suomi)',
    value: 'fi',
  },
  { label: 'Французский', value: 'fr' },
  { label: 'Фризский', value: 'fy' },
  {
    label: 'Фулах',
    value: 'ff',
  },
  { label: 'Хауса', value: 'ha' },
  { label: 'Хинди', value: 'hi' },
  {
    label: 'Хиримоту',
    value: 'ho',
  },
  { label: 'Хорватский', value: 'hr' },
  {
    label: 'Церковнославянский (Старославянский)',
    value: 'cu',
  },
  { label: 'Чаморро', value: 'ch' },
  { label: 'Чеченский', value: 'ce' },
  {
    label: 'Чешский',
    value: 'cs',
  },
  { label: 'Чжуанский', value: 'za' },
  { label: 'Чувашский', value: 'cv' },
  {
    label: 'Шведский',
    value: 'sv',
  },
  { label: 'Шона', value: 'sn' },
  { label: 'Эве', value: 'ee' },
  {
    label: 'Эсперанто',
    value: 'eo',
  },
  { label: 'Эстонский', value: 'et' },
  { label: 'Яванский', value: 'jv' },
  {
    label: 'Японский',
    value: 'ja',
  },
];

export const optionsCategoryIAB = [
  { label: '1: Искусство и Развлечения', value: '1' },
  { label: '1-1: Искусство и Развлечения - Книги и литература', value: '1-1' },
  {
    label: '1-2: Искусство и Развлечения - Celebrity Fan/Gossip',
    value: '1-2',
  },
  { label: '1-3: Искусство и Развлечения - Fine Art', value: '1-3' },
  { label: '1-4: Искусство и Развлечения - Юмор', value: '1-4' },
  { label: '1-5: Искусство и Развлечения - Видео', value: '1-5' },

  { label: '1-6: Искусство и Развлечения - Музыка', value: '1-6' },

  { label: '1-7: Искусство и Развлечения - Телевидение', value: '1-7' },

  { label: '2: Автомобили', value: '2' },
  { label: '2-1: Автомобили - Автозапчасти', value: '2-1' },

  { label: '2-2: Автомобили - Ремонт автомобилей', value: '2-2' },

  { label: '2-3: Автомобили - Покупка/Продажа автомобилей', value: '2-3' },

  { label: '2-4: Автомобили - Культура машин', value: '2-4' },
  {
    label: '2-5: Автомобили - Сертифицированные автомобили с пробегом',
    value: '2-5',
  },
  { label: '2-6: Автомобили - Кабриолеты', value: '2-6' },
  { label: '2-7: Автомобили - Coupe', value: '2-7' },
  { label: '2-8: Автомобили - Crossover', value: '2-8' },
  { label: '2-9: Автомобили - Diesel', value: '2-9' },
  { label: '2-10: Автомобили - Электромобили', value: '2-10' },
  { label: '2-11: Автомобили - Hatchback', value: '2-11' },
  { label: '2-12: Автомобили - Hybrid', value: '2-12' },
  { label: '2-13: Автомобили - Luxury', value: '2-13' },
  { label: '2-14: Автомобили - MiniVan', value: '2-14' },
  { label: '2-15: Автомобили - Мотоциклы', value: '2-15' },
  { label: '2-16: Автомобили - Внедорожники', value: '2-16' },
  {
    label: '2-17: Автомобили - Производительность транспортных средств',
    value: '2-17',
  },
  { label: '2-18: Автомобили - Pickup', value: '2-18' },
  { label: '2-19: Автомобили - Дорожная помощь', value: '2-19' },
  { label: '2-20: Автомобили - Sedan', value: '2-20' },
  {
    label: '2-21: Автомобили - Грузовые автомобили и аксессуары',
    value: '2-21',
  },
  { label: '2-22: Автомобили - Винтажные машины', value: '2-22' },
  { label: '2-23: Автомобили - Вагоны', value: '2-23' },
  { label: '3: Бизнес', value: '3' },
  { label: '3-1: Бизнес - Реклама', value: '3-1' },
  { label: '3-2: Бизнес - Сельское хозяйство', value: '3-2' },
  { label: '3-3: Бизнес - Биотехника/Биомедицина', value: '3-3' },
  { label: '3-4: Бизнес - Business Software', value: '3-4' },
  { label: '3-5: Бизнес - Строительство', value: '3-5' },
  { label: '3-6: Бизнес - Лесное хозяйство', value: '3-6' },
  { label: '3-7: Бизнес - Государство', value: '3-7' },
  { label: '3-8: Бизнес - Green Solutions', value: '3-8' },
  { label: '3-9: Бизнес - Человеческие ресурсы', value: '3-9' },

  { label: '3-10: Бизнес - Логистика', value: '3-10' },
  { label: '3-11: Бизнес - Маркетинг', value: '3-11' },

  { label: '3-12: Бизнес - Металлы', value: '3-12' },
  { label: '4: Карьера', value: '4' },
  { label: '4-1: Карьера - Планирование карьеры', value: '4-1' },

  { label: '4-2: Карьера - Университеты', value: '4-2' },
  { label: '4-3: Карьера - Финансовая помощь', value: '4-3' },
  { label: '4-4: Карьера - Job Fairs', value: '4-4' },
  { label: '4-5: Карьера - Поиск работы', value: '4-5' },

  { label: '4-6: Карьера - Подача резюме/Советы', value: '4-6' },
  { label: '4-7: Карьера - Сиделки', value: '4-7' },
  { label: '4-8: Карьера - Стипендия', value: '4-8' },
  { label: '4-9: Карьера - Дистанционная работа', value: '4-9' },
  { label: '4-10: Карьера - Американские военные', value: '4-10' },
  { label: '4-11: Карьера - Career Advice', value: '4-11' },
  { label: '5: Образование', value: '5' },
  { label: '5-1: Образование - Образование 7-12', value: '5-1' },
  { label: '5-2: Образование - Образование для старших', value: '5-2' },
  { label: '5-3: Образование - История искусства', value: '5-3' },
  { label: '5-4: Образование - Администрация университета', value: '5-4' },
  { label: '5-5: Образование - Жизнь в университете', value: '5-5' },
  { label: '5-6: Образование - Дистанционное обучение', value: '5-6' },
  { label: '5-7: Образование - Английский как 2-ой язык', value: '5-7' },
  { label: '5-8: Образование - Изучение языка', value: '5-8' },

  { label: '5-9: Образование - Гимназии', value: '5-9' },
  { label: '5-10: Образование - Обучение на дому', value: '5-10' },
  {
    label: '5-11: Образование - Домашняя работа/Исследовательские советы',
    value: '5-11',
  },

  { label: '5-12: Образование - Педагоги К-6', value: '5-12' },
  { label: '5-13: Образование - Частные школы', value: '5-13' },
  { label: '5-14: Образование - Специальное образование', value: '5-14' },

  { label: '5-15: Образование - Бизнес–обучение', value: '5-15' },
  { label: '6: Семья и воспитание', value: '6' },
  { label: '6-1: Семья и воспитание - Усыновление', value: '6-1' },
  { label: '6-2: Семья и воспитание - Младенцы и малыши', value: '6-2' },

  {
    label: '6-3: Семья и воспитание - Дневное/Дошкольное образование',
    value: '6-3',
  },
  { label: '6-4: Семья и воспитание - Семейный интернет', value: '6-4' },
  { label: '6-5: Семья и воспитание - Воспитание детей К6', value: '6-5' },
  { label: '6-6: Семья и воспитание - Воспитание подростков', value: '6-6' },
  { label: '6-7: Семья и воспитание - Беременность', value: '6-7' },

  {
    label: '6-8: Семья и воспитание - Дети с особыми потребностями',
    value: '6-8',
  },
  { label: '6-9: Семья и воспитание - Уход за пожилыми', value: '6-9' },
  { label: '7: Здоровье и Фитнес', value: '7' },
  { label: '7-1: Здоровье и Фитнес - Упражнения', value: '7-1' },

  { label: '7-2: Здоровье и Фитнес - A.D.D.', value: '7-2' },
  { label: '7-3: Здоровье и Фитнес - ВИЧ/СПИД', value: '7-3' },
  { label: '7-4: Здоровье и Фитнес - Аллергия', value: '7-4' },
  { label: '7-5: Здоровье и Фитнес - Альтернативная медицина', value: '7-5' },

  { label: '7-6: Здоровье и Фитнес - Артрит', value: '7-6' },

  { label: '7-7: Здоровье и Фитнес - Астма', value: '7-7' },
  { label: '7-8: Здоровье и Фитнес - Аутизм / PDD', value: '7-8' },
  { label: '7-9: Здоровье и Фитнес - Биполярное расстройство', value: '7-9' },
  { label: '7-10: Здоровье и Фитнес - Опухоли головного мозга', value: '7-10' },
  { label: '7-11: Здоровье и Фитнес - Рак', value: '7-11' },
  { label: '7-12: Здоровье и Фитнес - Холестерин', value: '7-12' },

  {
    label: '7-13: Здоровье и Фитнес - Синдром хронической усталости',
    value: '7-13',
  },
  { label: '7-14: Здоровье и Фитнес - Хроническая боль', value: '7-14' },

  { label: '7-15: Здоровье и Фитнес - Холод и грипп', value: '7-15' },

  { label: '7-16: Здоровье и Фитнес - Глухота', value: '7-16' },

  { label: '7-17: Здоровье и Фитнес - Dental Care', value: '7-17' },

  { label: '7-18: Здоровье и Фитнес - Депрессия', value: '7-18' },

  { label: '7-19: Здоровье и Фитнес - Дерматология', value: '7-19' },

  { label: '7-20: Здоровье и Фитнес - Диабет', value: '7-20' },

  { label: '7-21: Здоровье и Фитнес - Эпилепсия', value: '7-21' },
  { label: '7-22: Здоровье и Фитнес - Изжога', value: '7-22' },
  { label: '7-23: Здоровье и Фитнес - Головная боль/Мигрень', value: '7-23' },
  { label: '7-24: Здоровье и Фитнес - Болезни сердца', value: '7-24' },

  { label: '7-25: Здоровье и Фитнес - Целебные травы', value: '7-25' },

  { label: '7-26: Здоровье и Фитнес - Целостное исцеление', value: '7-26' },
  { label: '7-27: Здоровье и Фитнес - Болезнь Крона', value: '7-27' },
  {
    label: '7-28: Здоровье и Фитнес - Инцест/Злоупотребление поддержкой',
    value: '7-28',
  },
  { label: '7-29: Здоровье и Фитнес - Недержание', value: '7-29' },

  { label: '7-30: Здоровье и Фитнес - Бесплодие', value: '7-30' },

  { label: '7-31: Здоровье и Фитнес - Мужское здоровье', value: '7-31' },

  { label: '7-32: Здоровье и Фитнес - Питание', value: '7-32' },
  { label: '7-33: Здоровье и Фитнес - Ортопедия', value: '7-33' },

  {
    label: '7-34: Здоровье и Фитнес - Паника/Тревожные расстройства',
    value: '7-34',
  },

  { label: '7-35: Здоровье и Фитнес - Педиатрия', value: '7-35' },
  { label: '7-36: Здоровье и Фитнес - Физиотерапия', value: '7-36' },

  { label: '7-37: Здоровье и Фитнес - Психология/Психиатрия', value: '7-37' },

  { label: '7-38: Здоровье и Фитнес - Senor Health', value: '7-38' },
  { label: '7-39: Здоровье и Фитнес - Сексология', value: '7-39' },
  { label: '7-40: Здоровье и Фитнес - Нарушения сна', value: '7-40' },

  { label: '7-41: Здоровье и Фитнес - Прекращение курения', value: '7-41' },

  {
    label:
      '7-42: Здоровье и Фитнес - Злоупотребление алкоголем или наркотиками',
    value: '7-42',
  },

  {
    label: '7-43: Здоровье и Фитнес - Заболевания щитовидной железы',
    value: '7-43',
  },
  { label: '7-44: Здоровье и Фитнес - Похудение', value: '7-44' },

  { label: '7-45: Здоровье и Фитнес - Женское здоровье', value: '7-45' },

  { label: '8: Еда и напитки', value: '8' },
  { label: '8-1: Еда и напитки - Американская кухня', value: '8-1' },
  { label: '8-2: Еда и напитки - Барбекю и гриль', value: '8-2' },
  { label: '8-3: Еда и напитки - Креольская кухня', value: '8-3' },
  { label: '8-4: Еда и напитки - Китайская кухня', value: '8-4' },
  { label: '8-5: Еда и напитки - Коктейли/Пиво', value: '8-5' },
  { label: '8-6: Еда и напитки - Кофе/Чай', value: '8-6' },

  { label: '8-7: Еда и напитки - Специфическая кухня', value: '8-7' },

  { label: '8-8: Еда и напитки - Десерты и выпечка', value: '8-8' },
  { label: '8-9: Еда и напитки - Ужин в ресторане', value: '8-9' },

  { label: '8-10: Еда и напитки - Пищевая аллергия', value: '8-10' },
  { label: '8-11: Еда и напитки - Французская кухня', value: '8-11' },
  { label: '8-12: Еда и напитки - Здоровое питание', value: '8-12' },

  { label: '8-13: Еда и напитки - Итальянская кухня', value: '8-13' },
  { label: '8-14: Еда и напитки - Японская кухня', value: '8-14' },
  { label: '8-15: Еда и напитки - Мексиканская кухня', value: '8-15' },
  { label: '8-16: Еда и напитки - Вегетарианская кухня', value: '8-16' },
  { label: '8-17: Еда и напитки - Овощная кухня', value: '8-17' },
  { label: '8-18: Еда и напитки - Вино', value: '8-18' },
  { label: '9: Хобби и интересы', value: '9' },
  { label: '9-1: Хобби и интересы - Технологии искусства', value: '9-1' },
  { label: '9-2: Хобби и интересы - Искусство и ремесло', value: '9-2' },
  { label: '9-3: Хобби и интересы - Вышивка бисером', value: '9-3' },
  { label: '9-4: Хобби и интересы - Наблюдение за птицами', value: '9-4' },
  { label: '9-5: Хобби и интересы - Настольные игры/Пазлы', value: '9-5' },
  { label: '9-6: Хобби и интересы - Лепка свечей и мыловарение', value: '9-6' },
  { label: '9-7: Хобби и интересы - Карточные игры', value: '9-7' },
  { label: '9-8: Хобби и интересы - Шахматы', value: '9-8' },
  { label: '9-9: Хобби и интересы - Сигары', value: '9-9' },
  { label: '9-10: Хобби и интересы - Коллекционирование', value: '9-10' },
  { label: '9-11: Хобби и интересы - Комиксы', value: '9-11' },
  { label: '9-12: Хобби и интересы - Рисование/эскизы', value: '9-12' },
  { label: '9-13: Хобби и интересы - Свободное написание', value: '9-13' },
  { label: '9-14: Хобби и интересы - Генеалогия', value: '9-14' },
  { label: '9-15: Хобби и интересы - Публикации', value: '9-15' },
  { label: '9-16: Хобби и интересы - Гитара', value: '9-16' },
  { label: '9-17: Хобби и интересы - Домашняя запись', value: '9-17' },
  { label: '9-18: Хобби и интересы - Инвесторы и патенты', value: '9-18' },
  { label: '9-19: Хобби и интересы - Украшения', value: '9-19' },
  { label: '9-20: Хобби и интересы - Магия и иллюзии', value: '9-20' },

  { label: '9-21: Хобби и интересы - Рукоделие', value: '9-21' },
  { label: '9-22: Хобби и интересы - Рисование', value: '9-22' },
  { label: '9-23: Хобби и интересы - Фотографии', value: '9-23' },
  { label: '9-24: Хобби и интересы - Радио', value: '9-24' },
  { label: '9-25: Хобби и интересы - Ролевые игры', value: '9-25' },

  { label: '9-26: Хобби и интересы - Фэнтези', value: '9-26' },
  { label: '9-27: Хобби и интересы - Scrapbooking', value: '9-27' },
  { label: '9-28: Хобби и интересы - Сценарии', value: '9-28' },
  { label: '9-29: Хобби и интересы - Марки и монеты', value: '9-29' },
  {
    label: '9-30: Хобби и интересы - Видео и компьютерные игры',
    value: '9-30',
  },

  { label: '9-31: Хобби и интересы - Деревообработка', value: '9-31' },
  { label: '10: Дом и сад', value: '10' },
  { label: '10-1: Дом и сад - Техника', value: '10-1' },

  { label: '10-2: Дом и сад - Развлечения', value: '10-2' },

  { label: '10-3: Дом и сад - Экологическая безопасность', value: '10-3' },
  { label: '10-4: Дом и сад - Садоводство', value: '10-4' },

  { label: '10-5: Дом и сад - Ремонт дома', value: '10-5' },

  { label: '10-6: Дом и сад - Домашний кинотеатр', value: '10-6' },
  { label: '10-7: Дом и сад - Декорирование интерьера', value: '10-7' },

  { label: '10-8: Дом и сад - Ландшафтный дизайн', value: '10-8' },
  { label: '10-9: Дом и сад - Реконструкция и строительство', value: '10-9' },
  { label: '11: Закон, правительство и политика', value: '11' },
  {
    label: '11-1: Закон, правительство и политика - Иммиграция',
    value: '11-1',
  },

  {
    label: '11-2: Закон, правительство и политика - Правовые вопросы',
    value: '11-2',
  },
  {
    label: '11-3: Закон, правительство и политика - Управление ресурсами США',
    value: '11-3',
  },
  { label: '11-4: Закон, правительство и политика - Политика', value: '11-4' },
  {
    label: '11-5: Закон, правительство и политика - Комментарии',
    value: '11-5',
  },
  { label: '12: Новости', value: '12' },
  { label: '12-1: Новости - Международные новости', value: '12-1' },

  { label: '12-2: Новости - Национальные новости', value: '12-2' },

  { label: '12-3: Новости - Местные новости', value: '12-3' },

  { label: '13: Личные финансы', value: '13' },
  { label: '13-1: Личные финансы - Начало инвестирования', value: '13-1' },
  { label: '13-2: Личные финансы - Кредиты/долги', value: '13-2' },

  { label: '13-3: Личные финансы - Финансовые новости', value: '13-3' },
  { label: '13-4: Личные финансы - Финансовое планирование', value: '13-4' },

  { label: '13-5: Личные финансы - Хэдж-фонд', value: '13-5' },
  { label: '13-6: Личные финансы - Страхование', value: '13-6' },

  { label: '13-7: Личные финансы - Инвестирование', value: '13-7' },

  {
    label: '13-8: Личные финансы - Взаимо-предоставляемые фонды',
    value: '13-8',
  },
  { label: '13-9: Личные финансы - Опции', value: '13-9' },

  { label: '13-10: Личные финансы - Пенсионное планирование', value: '13-10' },
  { label: '13-11: Личные финансы - Акции', value: '13-11' },
  { label: '13-12: Личные финансы - Планирование налогов', value: '13-12' },
  { label: '14: Общество', value: '14' },
  { label: '14-1: Общество - Датировка', value: '14-1' },

  { label: '14-2: Общество - Развод поддержки', value: '14-2' },
  { label: '14-3: Общество - Гей-жизнь', value: '14-3' },

  { label: '14-4: Общество - Брак', value: '14-4' },
  { label: '14-5: Общество - Senior Living', value: '14-5' },
  { label: '14-6: Общество - Подростки', value: '14-6' },
  { label: '14-7: Общество - Свадьбы', value: '14-7' },
  { label: '14-8: Общество - Этнические особенности', value: '14-8' },
  { label: '15: Наука', value: '15' },
  { label: '15-1: Наука - Астрология', value: '15-1' },

  { label: '15-2: Наука - Биология', value: '15-2' },
  { label: '15-3: Наука - Химия', value: '15-3' },
  { label: '15-4: Наука - Геология', value: '15-4' },
  { label: '15-5: Наука - Паранормальные явления', value: '15-5' },
  { label: '15-6: Наука - Физика', value: '15-6' },
  { label: '15-7: Наука - Космос/Астрономия', value: '15-7' },
  { label: '15-8: Наука - География', value: '15-8' },
  { label: '15-9: Наука - Ботаника', value: '15-9' },
  { label: '15-10: Наука - Погода', value: '15-10' },
  { label: '16: Домашние животные', value: '16' },
  { label: '16-1: Домашние животные - Аквариум', value: '16-1' },
  { label: '16-2: Домашние животные - Птицы', value: '16-2' },
  { label: '16-3: Домашние животные - Кошки', value: '16-3' },

  { label: '16-4: Домашние животные - Собаки', value: '16-4' },
  { label: '16-5: Домашние животные - Крупные животные', value: '16-5' },
  { label: '16-6: Домашние животные - Рептилии', value: '16-6' },
  { label: '16-7: Домашние животные - Ветеринарная медицина', value: '16-7' },

  { label: '17: Спорт', value: '17' },
  { label: '17-1: Спорт - Auto Racing', value: '17-1' },
  { label: '17-2: Спорт - Бейсбол', value: '17-2' },
  { label: '17-3: Спорт - Велосипед', value: '17-3' },
  { label: '17-4: Спорт - Бодибилдинг', value: '17-4' },
  { label: '17-5: Спорт - Бокс', value: '17-5' },
  { label: '17-6: Спорт - Каноэ/байдарки', value: '17-6' },
  { label: '17-7: Спорт - Cheerleading', value: '17-7' },
  { label: '17-8: Спорт - Climbing', value: '17-8' },
  { label: '17-9: Спорт - Крикет', value: '17-9' },
  { label: '17-10: Спорт - Фигурное катание', value: '17-10' },
  { label: '17-11: Спорт - Fly Fishing', value: '17-11' },
  { label: '17-12: Спорт - Футбол', value: '17-12' },
  { label: '17-13: Спорт - Пресноводная рыбалка', value: '17-13' },

  { label: '17-14: Спорт - Игры и рыба', value: '17-14' },
  { label: '17-15: Спорт - Гольф', value: '17-15' },
  { label: '17-16: Спорт - Верховая езда', value: '17-16' },
  { label: '17-17: Спорт - Лошади', value: '17-17' },
  { label: '17-18: Спорт - Охота/Стрельба', value: '17-18' },
  { label: '17-19: Спорт - Катание на роликах', value: '17-19' },
  { label: '17-20: Спорт - Боевые искусства', value: '17-20' },
  { label: '17-21: Спорт - Горные велосипеды', value: '17-21' },
  { label: '17-22: Спорт - NASCAR Racing', value: '17-22' },
  { label: '17-23: Спорт - Олимпийские игры', value: '17-23' },
  { label: '17-24: Спорт - Paintball', value: '17-24' },
  { label: '17-25: Спорт - Мотоциклы', value: '17-25' },
  { label: '17-26: Спорт - Pro Basketball', value: '17-26' },
  { label: '17-27: Спорт - Pro Ice Hockey', value: '17-27' },
  { label: '17-28: Спорт - Родео', value: '17-28' },
  { label: '17-29: Спорт - Регби', value: '17-29' },
  { label: '17-30: Спорт - Бег/Йога', value: '17-30' },
  { label: '17-31: Спорт - Парусный спорт', value: '17-31' },
  { label: '17-32: Спорт - Морская рыбалка', value: '17-32' },
  { label: '17-33: Спорт - Scuba Diving', value: '17-33' },
  { label: '17-34: Спорт - Скейтборд', value: '17-34' },
  { label: '17-35: Спорт - Лыжи', value: '17-35' },
  { label: '17-36: Спорт - Сноуборд', value: '17-36' },
  { label: '17-37: Спорт - Серфинг', value: '17-37' },
  { label: '17-38: Спорт - Плавание', value: '17-38' },
  { label: '17-39: Спорт - Настольный теннис/Пинг-понг', value: '17-39' },
  { label: '17-40: Спорт - Теннис', value: '17-40' },
  { label: '17-41: Спорт - Волейбол', value: '17-41' },
  { label: '17-42: Спорт - Спортивная ходьба', value: '17-42' },
  { label: '17-43: Спорт - Водные лыжи', value: '17-43' },
  { label: '17-44: Спорт - World Soccer', value: '17-44' },
  { label: '18: Стиль и Мода', value: '18' },
  { label: '18-1: Стиль и Мода - Красота', value: '18-1' },

  { label: '18-2: Стиль и Мода - Body Art', value: '18-2' },

  { label: '18-3: Стиль и Мода - Мода', value: '18-3' },

  { label: '18-4: Стиль и Мода - Ювелирные изделия', value: '18-4' },

  { label: '18-5: Стиль и Мода - Одежда', value: '18-5' },

  { label: '18-6: Стиль и Мода - Аксессуары', value: '18-6' },

  { label: '19: Технологии и вычисление', value: '19' },
  { label: '19-1: Технологии и вычисление - 3D Графика', value: '19-1' },
  { label: '19-2: Технологии и вычисление - Анимация', value: '19-2' },
  {
    label:
      '19-3: Технологии и вычисление - Антивирусное программное обеспечение',
    value: '19-3',
  },

  { label: '19-4: Технологии и вычисление - C/C++', value: '19-4' },
  {
    label: '19-5: Технологии и вычисление - Фотокамеры и видеокамеры',
    value: '19-5',
  },

  { label: '19-6: Технологии и вычисление - Сотовые телефоны', value: '19-6' },

  {
    label: '19-7: Технологии и вычисление - Компьютерная сертификация',
    value: '19-7',
  },
  { label: '19-8: Технологии и вычисление - Компьютерные сети', value: '19-8' },
  {
    label: '19-9: Технологии и вычисление - Компьютерная периферия',
    value: '19-9',
  },

  {
    label: '19-10: Технологии и вычисление - Компьютерные отзывы',
    value: '19-10',
  },
  {
    label: '19-11: Технологии и вычисление - Центры обработки данных',
    value: '19-11',
  },
  { label: '19-12: Технологии и вычисление - Базы данных', value: '19-12' },

  {
    label: '19-13: Технологии и вычисление - Настольная издательская система',
    value: '19-13',
  },
  { label: '19-14: Технологии и вычисление - Desktop Video', value: '19-14' },
  { label: '19-15: Технологии и вычисление - E-mail', value: '19-15' },
  {
    label: '19-16: Технологии и вычисление - Графика программы',
    value: '19-16',
  },
  { label: '19-17: Технологии и вычисление - DVD', value: '19-17' },
  {
    label: '19-18: Технологии и вычисление - Интернет технологии',
    value: '19-18',
  },
  { label: '19-19: Технологии и вычисление - Java', value: '19-19' },
  { label: '19-20: Технологии и вычисление - JavaScript', value: '19-20' },
  { label: '19-21: Технологии и вычисление - Mac Support', value: '19-21' },
  { label: '19-22: Технологии и вычисление - MP3/MIDI', value: '19-22' },
  {
    label: '19-23: Технологии и вычисление - Сетевые конференции',
    value: '19-23',
  },
  {
    label: '19-24: Технологии и вычисление - Сети для начинающих',
    value: '19-24',
  },
  {
    label: '19-25: Технологии и вычисление - Сетевая безопасность',
    value: '19-25',
  },

  { label: '19-26: Технологии и вычисление - Платформы/PDAs', value: '19-26' },
  { label: '19-27: Технологии и вычисление - Поддержка ПК', value: '19-27' },
  { label: '19-28: Технологии и вычисление - Портативные', value: '19-28' },

  { label: '19-29: Технологии и вычисление - Развлечения', value: '19-29' },

  {
    label: '19-30: Технологии и вычисление - Shareware/Freeware',
    value: '19-30',
  },
  { label: '19-31: Технологии и вычисление - Юникс', value: '19-31' },
  { label: '19-32: Технологии и вычисление - Visual Basic', value: '19-32' },
  { label: '19-33: Технологии и вычисление - Web Clip Art', value: '19-33' },
  {
    label: '19-34: Технологии и вычисление - Веб-дизайн / HTML',
    value: '19-34',
  },
  { label: '19-35: Технологии и вычисление - Веб-поиск', value: '19-35' },

  { label: '19-36: Технологии и вычисление - Windows', value: '19-36' },
  { label: '20: Путешествия', value: '20' },
  { label: '20-1: Путешествия - Приключения', value: '20-1' },

  { label: '20-2: Путешествия - Африка', value: '20-2' },
  { label: '20-3: Путешествия - Air Travel', value: '20-3' },
  { label: '20-4: Путешествия - Австралия и Новая Зеландия', value: '20-4' },
  { label: '20-5: Путешествия - Питание и проживание', value: '20-5' },
  { label: '20-6: Путешествия - Бюджетное путешествие', value: '20-6' },

  { label: '20-7: Путешествия - Деловое путешествие', value: '20-7' },
  { label: '20-8: Путешествия - By US Locale', value: '20-8' },
  { label: '20-9: Путешествия - Кэмпинг', value: '20-9' },

  { label: '20-10: Путешествия - Канада', value: '20-10' },

  { label: '20-11: Путешествия - Карибы', value: '20-11' },
  { label: '20-12: Путешествия - Круизы', value: '20-12' },
  { label: '20-13: Путешествия - Восточная Европа', value: '20-13' },
  { label: '20-14: Путешествия - Европа', value: '20-14' },
  { label: '20-15: Путешествия - Франция', value: '20-15' },
  { label: '20-16: Путешествия - Греция', value: '20-16' },
  { label: '20-17: Путешествия - Медовый месяц', value: '20-17' },
  { label: '20-18: Путешествия - Отели', value: '20-18' },
  { label: '20-19: Путешествия - Италия', value: '20-19' },
  { label: '20-20: Путешествия - Япония', value: '20-20' },
  {
    label: '20-21: Путешествия - Мексика и Центральная Америка',
    value: '20-21',
  },
  { label: '20-22: Путешествия - Национальные парки', value: '20-22' },
  { label: '20-23: Путешествия - Южная Америка', value: '20-23' },
  { label: '20-24: Путешествия - Spas', value: '20-24' },
  { label: '20-25: Путешествия - Тематические парки', value: '20-25' },
  { label: '20-26: Путешествия - Путешествие с детьми', value: '20-26' },
  { label: '20-27: Путешествия - Великобритания', value: '20-27' },
  { label: '21: Недвижимость', value: '21' },
  { label: '21-1: Недвижимость - Апартаменты', value: '21-1' },
  { label: '21-2: Недвижимость - Архитекторы', value: '21-2' },
  { label: '21-3: Недвижимость - Покупка/Продажа домов', value: '21-3' },

  { label: '22: Покупки', value: '22' },
  { label: '22-1: Покупки - Конкурсы и распродажи', value: '22-1' },

  { label: '22-2: Покупки - Купоны', value: '22-2' },

  { label: '22-3: Покупки - Сравнение', value: '22-3' },
  { label: '22-4: Покупки - Двигатели', value: '22-4' },
  { label: '23: Религия и духовность', value: '23' },
  {
    label: '23-1: Религия и духовность - Альтернативные религии',
    value: '23-1',
  },

  { label: '23-2: Религия и духовность - Атеизм/Агностицизм', value: '23-2' },
  { label: '23-3: Религия и духовность - Буддизм', value: '23-3' },
  { label: '23-4: Религия и духовность - Католичество', value: '23-4' },
  { label: '23-5: Религия и духовность - Христианство', value: '23-5' },

  { label: '23-6: Религия и духовность - Индуизм', value: '23-6' },
  { label: '23-7: Религия и духовность - Ислам', value: '23-7' },
  { label: '23-8: Религия и духовность - Иудаизм', value: '23-8' },
  { label: '23-9: Религия и духовность - Latter-Day Saints', value: '23-9' },
  { label: '23-10: Религия и духовность - Pagan/Wiccan', value: '23-10' },
  { label: '24: Без рубрики', value: '24' },
  { label: '25: Нестандартный контент', value: '25' },
  { label: '25-1: Нестандартный контент - Немодерируемый UGC', value: '25-1' },
  {
    label: '25-2: Нестандартный контент - Extreme Graphic/Explicit Violence',
    value: '25-2',
  },
  { label: '25-3: Нестандартный контент - Порнография', value: '25-3' },

  { label: '25-4: Нестандартный контент - Profane Content', value: '25-4' },
  { label: '25-5: Нестандартный контент - Hate Content', value: '25-5' },
  { label: '25-6: Нестандартный контент - Under Construction', value: '25-6' },
  { label: '25-7: Нестандартный контент - Incentivized', value: '25-7' },
  { label: '26: Незаконный контент', value: '26' },
  { label: '26-1: Незаконный контент - Незаконный контент', value: '26-1' },
  { label: '26-2: Незаконный контент - Warez', value: '26-2' },
  { label: '26-3: Незаконный контент - Spyware/Malware', value: '26-3' },

  {
    label: '26-4: Незаконный контент - Нарушение авторских прав',
    value: '26-4',
  },
];

export const optionsLanguageEng = [
  { label: 'Abkhaz', value: 'ab' },
  { label: 'Avar', value: 'av' },
  {
    label: 'Avestan',
    value: 'ae',
  },
  { label: 'Azerbaijani', value: 'az' },
  { label: 'Aymara', value: 'ay' },
  {
    label: 'Akan',
    value: 'ak',
  },
  { label: 'Albanian', value: 'sq' },
  { label: 'Amharic', value: 'am' },
  {
    label: 'English',
    value: 'en',
  },
  { label: 'Arabic', value: 'ar' },
  { label: 'Armenian', value: 'hy' },
  {
    label: 'Assamese',
    value: 'as',
  },
  { label: 'Afar', value: 'aa' },
  { label: 'Afrikaans', value: 'af' },
  {
    label: 'Bambara',
    value: 'bm',
  },
  { label: 'Basque', value: 'eu' },
  { label: 'Bashkir', value: 'ba' },
  {
    label: 'Belarusian',
    value: 'be',
  },
  { label: 'Bengali', value: 'bn' },
  { label: 'Burmese', value: 'my' },
  {
    label: 'Bislama',
    value: 'bi',
  },
  { label: 'Bulgarian', value: 'bg' },
  { label: 'Bosnian', value: 'bs' },
  {
    label: 'Breton',
    value: 'br',
  },
  { label: 'Welsh', value: 'cy' },
  { label: 'Hungarian', value: 'hu' },
  {
    label: 'Venda',
    value: 've',
  },
  { label: 'Volapyuk', value: 'vo' },
  { label: 'Wolof', value: 'wo' },
  {
    label: 'Vietnamese',
    value: 'vi',
  },
  { label: 'Galician', value: 'gl' },
  { label: 'Ganda', value: 'lg' },
  {
    label: 'Herero',
    value: 'hz',
  },
  { label: 'Greenlandic', value: 'kl' },
  {
    label: 'Greek (Modern Greek)',
    value: 'el',
  },
  { label: 'Georgian', value: 'ka' },
  { label: 'Guarani', value: 'gn' },
  {
    label: 'Gujarati',
    value: 'gu',
  },
  { label: 'Gaelic', value: 'gd' },
  { label: 'Danish', value: 'da' },
  {
    label: 'Dzongkha',
    value: 'dz',
  },
  { label: 'Divehi (Maldivian)', value: 'dv' },
  { label: 'Zulu', value: 'zu' },
  {
    label: 'Hebrew',
    value: 'he',
  },
  { label: 'Igbo', value: 'ig' },
  { label: 'Yiddish', value: 'yi' },
  {
    label: 'Indonesian',
    value: 'id',
  },
  { label: 'Interlingua', value: 'ia' },
  { label: 'Interlingue', value: 'ie' },
  {
    label: 'Inuktitut',
    value: 'iu',
  },
  { label: 'Inupiac', value: 'ik' },
  { label: 'Irish', value: 'ga' },
  {
    label: 'Icelandic',
    value: 'is',
  },
  { label: 'Spanish', value: 'es' },
  { label: 'Italian', value: 'it' },
  {
    label: 'Yoruba',
    value: 'yo',
  },
  { label: 'Kazakh', value: 'kk' },
  { label: 'Kannada', value: 'kn' },
  {
    label: 'Canuri',
    value: 'kr',
  },
  { label: 'Catalan', value: 'ca' },
  { label: 'Kashmiri', value: 'ks' },
  {
    label: 'Quechua',
    value: 'qu',
  },
  { label: 'Kikuyu', value: 'ki' },
  { label: 'Kinyama', value: 'kj' },
  {
    label: 'Kyrgyz',
    value: 'ky',
  },
  { label: 'Chinese', value: 'zh' },
  { label: 'Komi', value: 'kv' },
  {
    label: 'Congo',
    value: 'kg',
  },
  { label: 'Korean', value: 'ko' },
  { label: 'Cornish', value: 'kw' },
  {
    label: 'Corsican',
    value: 'co',
  },
  { label: 'Xhosa', value: 'xh' },
  { label: 'Kurdish', value: 'ku' },
  {
    label: 'Khmer',
    value: 'km',
  },
  { label: 'Lao', value: 'lo' },
  { label: 'Latin', value: 'la' },
  {
    label: 'Latvian',
    value: 'lv',
  },
  { label: 'Lingala', value: 'ln' },
  { label: 'Lithuanian', value: 'lt' },
  {
    label: 'Luba-katanga',
    value: 'lu',
  },
  { label: 'Luxembourgish', value: 'lb' },
  {
    label: 'Macedonian',
    value: 'mk',
  },
  { label: 'Malagasy', value: 'mg' },
  { label: 'Malay', value: 'ms' },
  {
    label: 'Malayalam',
    value: 'ml',
  },
  { label: 'Maltese', value: 'mt' },
  { label: 'Maori', value: 'mi' },
  {
    label: 'Marathi',
    value: 'mr',
  },
  { label: 'Marshall', value: 'mh' },
  { label: 'Moldovan', value: 'mo' },
  {
    label: 'Mongolian',
    value: 'mn',
  },
  { label: 'Manx', value: 'gv' },
  { label: 'Navajo', value: 'nv' },
  {
    label: 'Nauru',
    value: 'na',
  },
  { label: 'Ndebele North', value: 'nd' },
  { label: 'Ndebele South', value: 'nr' },
  {
    label: 'Ndunga',
    value: 'ng',
  },
  { label: 'German', value: 'de' },
  {
    label: 'Nepali',
    value: 'ne',
  },
  { label: 'Dutch (Nederlands)', value: 'nl' },
  {
    label: 'Norwegian [Norsk (bokmål)]',
    value: 'no',
  },
  { label: 'Nyanja', value: 'ny' },
  {
    label: 'Nynorsk (New Norwegian)‬',
    value: 'nn',
  },
  { label: 'Ojibwe', value: 'oj' },
  { label: 'Occitan', value: 'oc' },
  {
    label: 'Oriya',
    value: 'or',
  },
  { label: 'Oromo', value: 'om' },
  { label: 'Ossetian', value: 'os' },
  {
    label: 'Pali',
    value: 'pi',
  },
  { label: 'Punjabi', value: 'pa' },
  { label: 'Persian', value: 'fa' },
  {
    label: 'Polish',
    value: 'pl',
  },
  { label: 'Portuguese', value: 'pt' },
  { label: 'Pashto', value: 'ps' },
  {
    label: 'Romansh',
    value: 'rm',
  },
  { label: 'Rwanda', value: 'rw' },
  { label: 'Romanian', value: 'ro' },
  {
    label: 'Rundi',
    value: 'rn',
  },
  { label: 'Russian', value: 'ru' },
  { label: 'Samoan', value: 'sm' },
  {
    label: 'Sango',
    value: 'sg',
  },
  { label: 'Sanskrit', value: 'sa' },
  { label: 'Sardinian', value: 'sc' },
  {
    label: 'Swazi',
    value: 'ss',
  },
  { label: 'Serbian', value: 'sr' },
  { label: 'Sinhalese', value: 'si' },
  {
    label: 'Sindhi',
    value: 'sd',
  },
  { label: 'Slovak', value: 'sk' },
  { label: 'Slovenian', value: 'sl' },
  {
    label: 'Somali',
    value: 'so',
  },
  { label: 'Soto Southern', value: 'st' },
  { label: 'Swahili', value: 'sw' },
  {
    label: 'Sundanese',
    value: 'su',
  },
  { label: 'Tagalog', value: 'tl' },
  { label: 'Tajik', value: 'tg' },
  {
    label: 'Thai',
    value: 'th',
  },
  { label: 'Tahitian', value: 'ty' },
  { label: 'Tamil', value: 'ta' },
  {
    label: 'Tatar',
    value: 'tt',
  },
  { label: 'Tvi', value: 'tw' },
  { label: 'Telugu', value: 'te' },
  {
    label: 'Tibetan',
    value: 'bo',
  },
  { label: 'Tigrinya', value: 'ti' },
  { label: 'Tongan', value: 'to' },
  {
    label: 'Tswana',
    value: 'tn',
  },
  { label: 'Tsonga', value: 'ts' },
  { label: 'Turkish', value: 'tr' },
  {
    label: 'Turkmen',
    value: 'tk',
  },
  { label: 'Uzbek', value: 'uz' },
  { label: 'Uyghur', value: 'ug' },
  {
    label: 'Ukrainian',
    value: 'uk',
  },
  { label: 'Urdu', value: 'ur' },
  { label: 'Faroese', value: 'fo' },
  {
    label: 'Fijian',
    value: 'fj',
  },
  { label: 'Filipino', value: 'fl' },
  {
    label: 'Finnish (Suomi)',
    value: 'fi',
  },
  { label: 'French', value: 'fr' },
  { label: 'Frisian', value: 'fy' },
  {
    label: 'Fulah',
    value: 'ff',
  },
  { label: 'Hausa', value: 'ha' },
  { label: 'Hindi', value: 'hi' },
  {
    label: 'Hirimotu',
    value: 'ho',
  },
  { label: 'Croatian', value: 'hr' },
  {
    label: 'Church Slavonic (Old Slavonic)',
    value: 'cu',
  },
  { label: 'Chamorro', value: 'ch' },
  { label: 'Chechen', value: 'ce' },
  {
    label: 'Czech',
    value: 'cs',
  },
  { label: 'Zhuang', value: 'za' },
  { label: 'Chuvash', value: 'cv' },
  {
    label: 'Swedish',
    value: 'sv',
  },
  { label: 'Shona', value: 'sn' },
  { label: 'Ewe', value: 'ee' },
  {
    label: 'Esperanto',
    value: 'eo',
  },
  { label: 'Estonian', value: 'et' },
  { label: 'Javanese', value: 'jv' },
  {
    label: 'Japanese',
    value: 'ja',
  },
];

export const optionsCategoryIABEng = [
  { label: '1: Arts & Entertainment', value: '1' },
  { label: '1-1: Arts & Entertainment - Books & Literature', value: '1-1' },
  {
    label: '1-2: Arts & Entertainment - Celebrity Fan/Gossip',
    value: '1-2',
  },
  { label: '1-3: Arts & Entertainment - Fine Art', value: '1-3' },
  { label: '1-4: Arts & Entertainment - Humor', value: '1-4' },
  { label: '1-5: Arts & Entertainment - Movies', value: '1-5' },

  { label: '1-6: Arts & Entertainment - Music', value: '1-6' },

  { label: '1-7: Arts & Entertainment - Television', value: '1-7' },

  { label: '2: Automotive', value: '2' },
  { label: '2-1: Automotive - Auto Parts', value: '2-1' },

  { label: '2-2: Automotive - Auto Repair', value: '2-2' },

  { label: '2-3: Automotive - Buying/Selling Cars', value: '2-3' },

  { label: '2-4: Automotive - Car Culture', value: '2-4' },
  {
    label: '2-5: Automotive - Certified Pre-Owned',
    value: '2-5',
  },
  { label: '2-6: Automotive - Convertible', value: '2-6' },
  { label: '2-7: Automotive - Coupe', value: '2-7' },
  { label: '2-8: Automotive - Crossover', value: '2-8' },
  { label: '2-9: Automotive - Diesel', value: '2-9' },
  { label: '2-10: Automotive - Electric Vehicle', value: '2-10' },
  { label: '2-11: Automotive - Hatchback', value: '2-11' },
  { label: '2-12: Automotive - Hybrid', value: '2-12' },
  { label: '2-13: Automotive - Luxury', value: '2-13' },
  { label: '2-14: Automotive - MiniVan', value: '2-14' },
  { label: '2-15: Automotive - Mororcycles', value: '2-15' },
  { label: '2-16: Automotive - Off-Road Vehicles', value: '2-16' },
  {
    label: '2-17: Automotive - Performance Vehicles',
    value: '2-17',
  },
  { label: '2-18: Automotive - Pickup', value: '2-18' },
  { label: '2-19: Automotive - Road-Side Assistance', value: '2-19' },
  { label: '2-20: Automotive - Sedan', value: '2-20' },
  {
    label: '2-21: Automotive - Trucks & Accessories',
    value: '2-21',
  },
  { label: '2-22: Automotive - Vintage Cars', value: '2-22' },
  { label: '2-23: Automotive - Wagon', value: '2-23' },
  { label: '3: Business', value: '3' },
  { label: '3-1: Business - Advertising', value: '3-1' },
  { label: '3-2: Business - Agriculture', value: '3-2' },
  { label: '3-3: Business - Biotech/Biomedical', value: '3-3' },
  { label: '3-4: Business - Business Software', value: '3-4' },
  { label: '3-5: Business - Construction', value: '3-5' },
  { label: '3-6: Business - Forestry', value: '3-6' },
  { label: '3-7: Business - Government', value: '3-7' },
  { label: '3-8: Business - Green Solutions', value: '3-8' },
  { label: '3-9: Business - Human Resources', value: '3-9' },

  { label: '3-10: Business - Logistics', value: '3-10' },
  { label: '3-11: Business - Marketing', value: '3-11' },

  { label: '3-12: Business - Metals', value: '3-12' },
  { label: '4: Careers', value: '4' },
  { label: '4-1: Careers - Career Planning', value: '4-1' },

  { label: '4-2: Careers - College', value: '4-2' },
  { label: '4-3: Careers - Financial Aid', value: '4-3' },
  { label: '4-4: Careers - Job Fairs', value: '4-4' },
  { label: '4-5: Careers - Job Search', value: '4-5' },

  { label: '4-6: Careers - Resume Writing/Advice', value: '4-6' },
  { label: '4-7: Careers - Nursing', value: '4-7' },
  { label: '4-8: Careers - Scholarships', value: '4-8' },
  { label: '4-9: Careers - Telecommuting', value: '4-9' },
  { label: '4-10: Careers - U.S. Military', value: '4-10' },
  { label: '4-11: Careers - Career Advice', value: '4-11' },
  { label: '5: Education', value: '5' },
  { label: '5-1: Education - 7-12 Education', value: '5-1' },
  { label: '5-2: Education - Adult Education', value: '5-2' },
  { label: '5-3: Education - Art History', value: '5-3' },
  { label: '5-4: Education - Colledge Administration', value: '5-4' },
  { label: '5-5: Education - College Life', value: '5-5' },
  { label: '5-6: Education - Distance Learning', value: '5-6' },
  { label: '5-7: Education - English as a 2nd Language', value: '5-7' },
  { label: '5-8: Education - Language Learning', value: '5-8' },

  { label: '5-9: Education - Graduate School', value: '5-9' },
  { label: '5-10: Education - Homeschooling', value: '5-10' },
  {
    label: '5-11: Education - Homework/Study Tips',
    value: '5-11',
  },

  { label: '5-12: Education - K-6 Educators', value: '5-12' },
  { label: '5-13: Education - Private School', value: '5-13' },
  { label: '5-14: Education - Special Education', value: '5-14' },

  { label: '5-15: Education - Studying Business', value: '5-15' },
  { label: '6: Family & Parenting', value: '6' },
  { label: '6-1: Family & Parenting - Adoption', value: '6-1' },
  { label: '6-2: Family & Parenting - Babies & Toddlers', value: '6-2' },

  {
    label: '6-3: Family & Parenting - Daycare/Pre School',
    value: '6-3',
  },
  { label: '6-4: Family & Parenting - Family Internet', value: '6-4' },
  { label: '6-5: Family & Parenting - Parenting - K-6 Kids', value: '6-5' },
  { label: '6-6: Family & Parenting - Parenting teens', value: '6-6' },
  { label: '6-7: Family & Parenting - Pregnancy', value: '6-7' },

  {
    label: '6-8: Family & Parenting - Special Needs Kids',
    value: '6-8',
  },
  { label: '6-9: Family & Parenting - Eldercare', value: '6-9' },
  { label: '7: Health & Fitness', value: '7' },
  { label: '7-1: Health & Fitness - Exercise', value: '7-1' },

  { label: '7-2: Health & Fitness - A.D.D.', value: '7-2' },
  { label: '7-3: Health & Fitness - AIDS/HIV', value: '7-3' },
  { label: '7-4: Health & Fitness - Allergies', value: '7-4' },
  { label: '7-5: Health & Fitness - Alternative Medicine', value: '7-5' },

  { label: '7-6: Health & Fitness - Arthritis', value: '7-6' },

  { label: '7-7: Health & Fitness - Asthma', value: '7-7' },
  { label: '7-8: Health & Fitness - utism/PDD', value: '7-8' },
  { label: '7-9: Health & Fitness - Bipolar Disorder', value: '7-9' },
  { label: '7-10: Health & Fitness - Brain Tumor', value: '7-10' },
  { label: '7-11: Health & Fitness - Cancer', value: '7-11' },
  { label: '7-12: Health & Fitness - Cholesterol', value: '7-12' },

  {
    label: '7-13: Health & Fitness - Chronic Fatigue Syndrome',
    value: '7-13',
  },
  { label: '7-14: Health & Fitness - Chronic Pain', value: '7-14' },

  { label: '7-15: Health & Fitness - Cold & Flu', value: '7-15' },

  { label: '7-16: Health & Fitness - Deafness', value: '7-16' },

  { label: '7-17: Health & Fitness - Dental Care', value: '7-17' },

  { label: '7-18: Health & Fitness - Depression', value: '7-18' },

  { label: '7-19: Health & Fitness - Dermatology', value: '7-19' },

  { label: '7-20: Health & Fitness - Diabetes', value: '7-20' },

  { label: '7-21: Health & Fitness - Epilepsy', value: '7-21' },
  { label: '7-22: Health & Fitness - GERD/Acid Reflux', value: '7-22' },
  { label: '7-23: Health & Fitness - Headaches/Migraines', value: '7-23' },
  { label: '7-24: Health & Fitness - Heart Disease', value: '7-24' },

  { label: '7-25: Health & Fitness - Herbs for Health', value: '7-25' },

  { label: '7-26: Health & Fitness - Holistic Healing', value: '7-26' },
  { label: '7-27: Health & Fitness - IBS/Crohn`s Disease', value: '7-27' },
  {
    label: '7-28: Health & Fitness - Incest/Abuse Support',
    value: '7-28',
  },
  { label: '7-29: Health & Fitness - Incontinence', value: '7-29' },

  { label: '7-30: Health & Fitness - Infertility', value: '7-30' },

  { label: '7-31: Health & Fitness - Men`s Health', value: '7-31' },

  { label: '7-32: Health & Fitness - Nutrition', value: '7-32' },
  { label: '7-33: Health & Fitness - Orthopedics', value: '7-33' },

  {
    label: '7-34: Health & Fitness - Panic/Anxiety Disorders',
    value: '7-34',
  },

  { label: '7-35: Health & Fitness - Pediatrics', value: '7-35' },
  { label: '7-36: Health & Fitness - Physical Therapy', value: '7-36' },

  { label: '7-37: Health & Fitness - Psychology/Psychiatry', value: '7-37' },

  { label: '7-38: Health & Fitness - Senor Health', value: '7-38' },
  { label: '7-39: Health & Fitness - Sexuality', value: '7-39' },
  { label: '7-40: Health & Fitness - Sleep Disorders', value: '7-40' },

  { label: '7-41: Health & Fitness - Smoking Cessation', value: '7-41' },

  {
    label: '7-42: Health & Fitness - Substance Abuse',
    value: '7-42',
  },

  {
    label: '7-43: Health & Fitness - Thyroid Disease',
    value: '7-43',
  },
  { label: '7-44: Health & Fitness - Weight Loss', value: '7-44' },

  { label: '7-45: Health & Fitness - Women`s Health', value: '7-45' },

  { label: '8: Food & Drink', value: '8' },
  { label: '8-1: Food & Drink - American Cuisine', value: '8-1' },
  { label: '8-2: Food & Drink - Barbecues & Grilling', value: '8-2' },
  { label: '8-3: Food & Drink - Cajun/Creole', value: '8-3' },
  { label: '8-4: Food & Drink - Chinese Cuisine', value: '8-4' },
  { label: '8-5: Food & Drink - Cocktails/Beer', value: '8-5' },
  { label: '8-6: Food & Drink - Coffee/Tea', value: '8-6' },

  { label: '8-7: Food & Drink - Cuisine-Specific', value: '8-7' },

  { label: '8-8: Food & Drink - Desserts & Baking', value: '8-8' },
  { label: '8-9: Food & Drink - Dining Out', value: '8-9' },

  { label: '8-10: Food & Drink - Food Allergies', value: '8-10' },
  { label: '8-11: Food & Drink - French Cuisine', value: '8-11' },
  { label: '8-12: Food & Drink - Health/Lowfat Cooking', value: '8-12' },

  { label: '8-13: Food & Drink - Italian Cuisine', value: '8-13' },
  { label: '8-14: Food & Drink - Japanese Cuisine', value: '8-14' },
  { label: '8-15: Food & Drink - Mexican Cuisine', value: '8-15' },
  { label: '8-16: Food & Drink - Vegan', value: '8-16' },
  { label: '8-17: Food & Drink - Vegetarian', value: '8-17' },
  { label: '8-18: Food & Drink - Wine', value: '8-18' },
  { label: '9: Hobbies & Interests', value: '9' },
  { label: '9-1: Hobbies & Interests - Art/Technology', value: '9-1' },
  { label: '9-2: Hobbies & Interests - Arts & Crafts', value: '9-2' },
  { label: '9-3: Hobbies & Interests - Beadwork', value: '9-3' },
  { label: '9-4: Hobbies & Interests - Birdwatching', value: '9-4' },
  { label: '9-5: Hobbies & Interests - Board Games/Puzzles', value: '9-5' },
  { label: '9-6: Hobbies & Interests - Candle & Soap Making', value: '9-6' },
  { label: '9-7: Hobbies & Interests - Card Games', value: '9-7' },
  { label: '9-8: Hobbies & Interests - Chess', value: '9-8' },
  { label: '9-9: Hobbies & Interests - Cigars', value: '9-9' },
  { label: '9-10: Hobbies & Interests - Collecting', value: '9-10' },
  { label: '9-11: Hobbies & Interests - Comic Books', value: '9-11' },
  { label: '9-12: Hobbies & Interests - Drawing/Sketching', value: '9-12' },
  { label: '9-13: Hobbies & Interests - Freelance Writing', value: '9-13' },
  { label: '9-14: Hobbies & Interests - Genealogy', value: '9-14' },
  { label: '9-15: Hobbies & Interests - Getting Published', value: '9-15' },
  { label: '9-16: Hobbies & Interests - Guitar', value: '9-16' },
  { label: '9-17: Hobbies & Interests - Home Recording', value: '9-17' },
  { label: '9-18: Hobbies & Interests - Investors & Patents', value: '9-18' },
  { label: '9-19: Hobbies & Interests - Jewelry Making', value: '9-19' },
  { label: '9-20: Hobbies & Interests - Magic & Illusion', value: '9-20' },

  { label: '9-21: Hobbies & Interests - Needlework', value: '9-21' },
  { label: '9-22: Hobbies & Interests - Painting', value: '9-22' },
  { label: '9-23: Hobbies & Interests - Photography', value: '9-23' },
  { label: '9-24: Hobbies & Interests - Radio', value: '9-24' },
  { label: '9-25: Hobbies & Interests - Roleplaying Games', value: '9-25' },

  { label: '9-26: Hobbies & Interests - Sci-Fi & Fantasy', value: '9-26' },
  { label: '9-27: Hobbies & Interests - Scrapbooking', value: '9-27' },
  { label: '9-28: Hobbies & Interests - Screenwriting', value: '9-28' },
  { label: '9-29: Hobbies & Interests - Stamps & Coins', value: '9-29' },
  {
    label: '9-30: Hobbies & Interests - Video & Computer Games',
    value: '9-30',
  },

  { label: '9-31: Hobbies & Interests - Woodworking', value: '9-31' },
  { label: '10: Home & Garden', value: '10' },
  { label: '10-1: Home & Garden - Appliances', value: '10-1' },

  { label: '10-2: Home & Garden - Entertaining', value: '10-2' },

  { label: '10-3: Home & Garden - Environmental Safety', value: '10-3' },
  { label: '10-4: Home & Garden - Gardening', value: '10-4' },

  { label: '10-5: Home & Garden - Home Repair', value: '10-5' },

  { label: '10-6: Home & Garden - Home Theater', value: '10-6' },
  { label: '10-7: Home & Garden - Interior Decorating', value: '10-7' },

  { label: '10-8: Home & Garden - Landscaping', value: '10-8' },
  { label: '10-9: Home & Garden - Remodeling & Construction', value: '10-9' },
  { label: '11: Law, Gov`t & Politics', value: '11' },
  {
    label: '11-1: Law, Gov`t & Politics - Immigration',
    value: '11-1',
  },

  {
    label: '11-2: Law, Gov`t & Politics - Legal Issues',
    value: '11-2',
  },
  {
    label: '11-3: Law, Gov`t & Politics - U.S. Government Resources',
    value: '11-3',
  },
  { label: '11-4: Law, Gov`t & Politics - Politics', value: '11-4' },
  {
    label: '11-5: Law, Gov`t & Politics - Commentary',
    value: '11-5',
  },
  { label: '12: News', value: '12' },
  { label: '12-1: News - International News', value: '12-1' },

  { label: '12-2: News - National News', value: '12-2' },

  { label: '12-3: News - Local News', value: '12-3' },

  { label: '13: Personal Finance', value: '13' },
  { label: '13-1: Personal Finance - Beginning Investing', value: '13-1' },
  { label: '13-2: Personal Finance - Credit/Debt & Loans', value: '13-2' },

  { label: '13-3: Personal Finance - Financial News', value: '13-3' },
  { label: '13-4: Personal Finance - Financial Planning', value: '13-4' },

  { label: '13-5: Personal Finance - Hedge Fund', value: '13-5' },
  { label: '13-6: Personal Finance - Insurance', value: '13-6' },

  { label: '13-7: Personal Finance - Investing', value: '13-7' },

  {
    label: '13-8: Personal Finance - Mutual Funds',
    value: '13-8',
  },
  { label: '13-9: Personal Finance - Options', value: '13-9' },

  { label: '13-10: Personal Finance - Retirement Planning', value: '13-10' },
  { label: '13-11: Personal Finance - Stocks', value: '13-11' },
  { label: '13-12: Personal Finance - Tax Planning', value: '13-12' },
  { label: '14: Society', value: '14' },
  { label: '14-1: Society - Dating', value: '14-1' },

  { label: '14-2: Society - Divorce Support', value: '14-2' },
  { label: '14-3: Society - Gay Life', value: '14-3' },

  { label: '14-4: Society - Marriage', value: '14-4' },
  { label: '14-5: Society - Senior Living', value: '14-5' },
  { label: '14-6: Society - Teens', value: '14-6' },
  { label: '14-7: Society - Weddings', value: '14-7' },
  { label: '14-8: Society - Ethnic Specific', value: '14-8' },
  { label: '15: Science', value: '15' },
  { label: '15-1: Science - Astrology', value: '15-1' },

  { label: '15-2: Science - Biology', value: '15-2' },
  { label: '15-3: Science - Chemistry', value: '15-3' },
  { label: '15-4: Science - Geology', value: '15-4' },
  { label: '15-5: Science - Paranormal Phenomena', value: '15-5' },
  { label: '15-6: Science - Physics', value: '15-6' },
  { label: '15-7: Science - Space/Astronomy', value: '15-7' },
  { label: '15-8: Science - Geography', value: '15-8' },
  { label: '15-9: Science - Botany', value: '15-9' },
  { label: '15-10: Science - Weather', value: '15-10' },
  { label: '16: Pets', value: '16' },
  { label: '16-1: Pets - Aquariums', value: '16-1' },
  { label: '16-2: Pets - Birds', value: '16-2' },
  { label: '16-3: Pets - Cats', value: '16-3' },

  { label: '16-4: Pets - Dogs', value: '16-4' },
  { label: '16-5: Pets - Large Animals', value: '16-5' },
  { label: '16-6: Pets - Reptiles', value: '16-6' },
  { label: '16-7: Pets - Veterinary Medicine', value: '16-7' },

  { label: '17: Sports', value: '17' },
  { label: '17-1: Sports - Auto Racing', value: '17-1' },
  { label: '17-2: Sports - Baseball', value: '17-2' },
  { label: '17-3: Sports - Bicycling', value: '17-3' },
  { label: '17-4: Sports - Bodybuilding', value: '17-4' },
  { label: '17-5: Sports - Boxing', value: '17-5' },
  { label: '17-6: Sports - Canoeing/Kayaking', value: '17-6' },
  { label: '17-7: Sports - Cheerleading', value: '17-7' },
  { label: '17-8: Sports - Climbing', value: '17-8' },
  { label: '17-9: Sports - Cricket', value: '17-9' },
  { label: '17-10: Sports - Figure Skating', value: '17-10' },
  { label: '17-11: Sports - Fly Fishing', value: '17-11' },
  { label: '17-12: Sports - Football', value: '17-12' },
  { label: '17-13: Sports - Freshwater Fishing', value: '17-13' },

  { label: '17-14: Sports - Game & Fish', value: '17-14' },
  { label: '17-15: Sports - Golf', value: '17-15' },
  { label: '17-16: Sports - Horse Racing', value: '17-16' },
  { label: '17-17: Sports - Horses', value: '17-17' },
  { label: '17-18: Sports - Hunting/Shooting', value: '17-18' },
  { label: '17-19: Sports - Inline Skating', value: '17-19' },
  { label: '17-20: Sports - Martial Arts', value: '17-20' },
  { label: '17-21: Sports - Mountain Biking', value: '17-21' },
  { label: '17-22: Sports - NASCAR Racing', value: '17-22' },
  { label: '17-23: Sports - Olympics', value: '17-23' },
  { label: '17-24: Sports - Paintball', value: '17-24' },
  { label: '17-25: Sports - Power & Motorcycles', value: '17-25' },
  { label: '17-26: Sports - Pro Basketball', value: '17-26' },
  { label: '17-27: Sports - Pro Ice Hockey', value: '17-27' },
  { label: '17-28: Sports - Rodeo', value: '17-28' },
  { label: '17-29: Sports - Rugby', value: '17-29' },
  { label: '17-30: Sports - Running/Jogging', value: '17-30' },
  { label: '17-31: Sports - Sailing', value: '17-31' },
  { label: '17-32: Sports - Saltwater Fishing', value: '17-32' },
  { label: '17-33: Sports - Scuba Diving', value: '17-33' },
  { label: '17-34: Sports - Skateboarding', value: '17-34' },
  { label: '17-35: Sports - Skiing', value: '17-35' },
  { label: '17-36: Sports - Snowboarding', value: '17-36' },
  { label: '17-37: Sports - Surfing/Bodyboarding', value: '17-37' },
  { label: '17-38: Sports - Swimming', value: '17-38' },
  { label: '17-39: Sports - Table Tennis/Ping-Pong', value: '17-39' },
  { label: '17-40: Sports - Tennis', value: '17-40' },
  { label: '17-41: Sports - Volleyball', value: '17-41' },
  { label: '17-42: Sports - Walking', value: '17-42' },
  { label: '17-43: Sports - Waterski/Wakeboard', value: '17-43' },
  { label: '17-44: Sports - World Soccer', value: '17-44' },
  { label: '18: Style & Fashion', value: '18' },
  { label: '18-1: Style & Fashion - Beauty', value: '18-1' },

  { label: '18-2: Style & Fashion - Body Art', value: '18-2' },

  { label: '18-3: Style & Fashion - Fashion', value: '18-3' },

  { label: '18-4: Style & Fashion - Jewelry', value: '18-4' },

  { label: '18-5: Style & Fashion - Clothing', value: '18-5' },

  { label: '18-6: Style & Fashion - Accessories', value: '18-6' },

  { label: '19: Technology & Computing', value: '19' },
  { label: '19-1: Technology & Computing - 3-D Graphics', value: '19-1' },
  { label: '19-2: Technology & Computing - Animation', value: '19-2' },
  {
    label: '19-3: Technology & Computing - Antivirus Software',
    value: '19-3',
  },

  { label: '19-4: Technology & Computing - C/C++', value: '19-4' },
  {
    label: '19-5: Technology & Computing - Cameras & Camcorders',
    value: '19-5',
  },

  { label: '19-6: Technology & Computing - Cell Phones', value: '19-6' },

  {
    label: '19-7: Technology & Computing - Computer Certification',
    value: '19-7',
  },
  {
    label: '19-8: Technology & Computing - Computer Networking',
    value: '19-8',
  },
  {
    label: '19-9: Technology & Computing - Computer Peripherals',
    value: '19-9',
  },

  {
    label: '19-10: Technology & Computing - Computer Reviews',
    value: '19-10',
  },
  {
    label: '19-11: Technology & Computing - Data Centers',
    value: '19-11',
  },
  { label: '19-12: Technology & Computing - Databases', value: '19-12' },

  {
    label: '19-13: Technology & Computing - Desktop Publishing',
    value: '19-13',
  },
  { label: '19-14: Technology & Computing - Desktop Video', value: '19-14' },
  { label: '19-15: Technology & Computing - Email', value: '19-15' },
  {
    label: '19-16: Technology & Computing - Graphics Software',
    value: '19-16',
  },
  { label: '19-17: Technology & Computing - Home Video/DVD', value: '19-17' },
  {
    label: '19-18: Technology & Computing - Internet Technology',
    value: '19-18',
  },
  { label: '19-19: Technology & Computing - Java', value: '19-19' },
  { label: '19-20: Technology & Computing - JavaScript', value: '19-20' },
  { label: '19-21: Technology & Computing - Mac Support', value: '19-21' },
  { label: '19-22: Technology & Computing - MP3/MIDI', value: '19-22' },
  {
    label: '19-23: Technology & Computing - Net Conferencing',
    value: '19-23',
  },
  {
    label: '19-24: Technology & Computing - Net for Beginners',
    value: '19-24',
  },
  {
    label: '19-25: Technology & Computing - Network Security',
    value: '19-25',
  },

  { label: '19-26: Technology & Computing - Palmtops/PDAs', value: '19-26' },
  { label: '19-27: Technology & Computing - PC Support', value: '19-27' },
  { label: '19-28: Technology & Computing - Portable', value: '19-28' },

  { label: '19-29: Technology & Computing - Entertainment', value: '19-29' },

  {
    label: '19-30: Technology & Computing - Shareware/Freeware',
    value: '19-30',
  },
  { label: '19-31: Technology & Computing - Unix', value: '19-31' },
  { label: '19-32: Technology & Computing - Visual Basic', value: '19-32' },
  { label: '19-33: Technology & Computing - Web Clip Art', value: '19-33' },
  {
    label: '19-34: Technology & Computing - Web Design/HTML',
    value: '19-34',
  },
  { label: '19-35: Technology & Computing - Web Search', value: '19-35' },

  { label: '19-36: Technology & Computing - Windows', value: '19-36' },
  { label: '20: Travel', value: '20' },
  { label: '20-1: Travel - Adventure Travel', value: '20-1' },

  { label: '20-2: Travel - Africa', value: '20-2' },
  { label: '20-3: Travel - Air Travel', value: '20-3' },
  { label: '20-4: Travel - Australia & New Zealand', value: '20-4' },
  { label: '20-5: Travel - Bed & Breakfasts', value: '20-5' },
  { label: '20-6: Travel - Budget Travel', value: '20-6' },

  { label: '20-7: Travel - Business Travel', value: '20-7' },
  { label: '20-8: Travel - By US Locale', value: '20-8' },
  { label: '20-9: Travel - Camping', value: '20-9' },

  { label: '20-10: Travel - Canada', value: '20-10' },

  { label: '20-11: Travel - Caribbean', value: '20-11' },
  { label: '20-12: Travel - Cruises', value: '20-12' },
  { label: '20-13: Travel - Eastern Europe', value: '20-13' },
  { label: '20-14: Travel - Europe', value: '20-14' },
  { label: '20-15: Travel - France', value: '20-15' },
  { label: '20-16: Travel - Greece', value: '20-16' },
  { label: '20-17: Travel - Honeymoons/Getaways', value: '20-17' },
  { label: '20-18: Travel - Hotels', value: '20-18' },
  { label: '20-19: Travel - Italy', value: '20-19' },
  { label: '20-20: Travel - Japan', value: '20-20' },
  {
    label: '20-21: Travel - Mexico & Central America',
    value: '20-21',
  },
  { label: '20-22: Travel - National Parks', value: '20-22' },
  { label: '20-23: Travel - South America', value: '20-23' },
  { label: '20-24: Travel - Spas', value: '20-24' },
  { label: '20-25: Travel - Theme Parks', value: '20-25' },
  { label: '20-26: Travel - Traveling with Kids', value: '20-26' },
  { label: '20-27: Travel - United Kingdom', value: '20-27' },
  { label: '21: Real Estate', value: '21' },
  { label: '21-1: Real Estate - Apartments', value: '21-1' },
  { label: '21-2: Real Estate - Architects', value: '21-2' },
  { label: '21-3: Real Estate - Buying/Selling Homes', value: '21-3' },

  { label: '22: Shopping', value: '22' },
  { label: '22-1: Shopping - Contests & Freebies', value: '22-1' },

  { label: '22-2: Shopping - Couponing', value: '22-2' },

  { label: '22-3: Shopping - Comparison', value: '22-3' },
  { label: '22-4: Shopping - Engines', value: '22-4' },
  { label: '23: Religion & Spirituality', value: '23' },
  {
    label: '23-1: Religion & Spirituality - Alternative Religions',
    value: '23-1',
  },

  {
    label: '23-2: Religion & Spirituality - Atheism/Agnosticism',
    value: '23-2',
  },
  { label: '23-3: Religion & Spirituality - Buddhism', value: '23-3' },
  { label: '23-4: Religion & Spirituality - Catholicism', value: '23-4' },
  { label: '23-5: Religion & Spirituality - Christianity', value: '23-5' },

  { label: '23-6: Religion & Spirituality - Hinduism', value: '23-6' },
  { label: '23-7: Religion & Spirituality - Islam', value: '23-7' },
  { label: '23-8: Religion & Spirituality - Judaism', value: '23-8' },
  { label: '23-9: Religion & Spirituality - Latter-Day Saints', value: '23-9' },
  { label: '23-10: Religion & Spirituality - Pagan/Wiccan', value: '23-10' },
  { label: '24: Uncategorized', value: '24' },
  { label: '25: Non-Standard Content', value: '25' },
  { label: '25-1: Non-Standard Content - Unmoderated UGC', value: '25-1' },
  {
    label: '25-2: Non-Standard Content - Extreme Graphic/Explicit Violence',
    value: '25-2',
  },
  { label: '25-3: Non-Standard Content - Pornography', value: '25-3' },

  { label: '25-4: Non-Standard Content - Profane Content', value: '25-4' },
  { label: '25-5: Non-Standard Content - Hate Content', value: '25-5' },
  { label: '25-6: Non-Standard Content - Under Construction', value: '25-6' },
  { label: '25-7: Non-Standard Content - Incentivized', value: '25-7' },
  { label: '26: Illegal Content', value: '26' },
  { label: '26-1: Illegal Content - Illegal Content', value: '26-1' },
  { label: '26-2: Illegal Content - Warez', value: '26-2' },
  { label: '26-3: Illegal Content - Spyware/Malware', value: '26-3' },

  {
    label: '26-4: Illegal Content - Copyright Infringement',
    value: '26-4',
  },
];
