import { TActivityUser, TUsersTab } from '../types';

export const tabsCollection: TUsersTab[] = ['allUsers', 'online', 'statistics'];

export const activityUserKeys: (keyof Omit<TActivityUser, 'user_email'>)[] = [
  'active_time',
  'count_get',
  'count_pages',
  'count_edit',
];
