import React, { FC } from 'react';
import cn from 'classnames';
import css from './styles.module.scss';

type TGridItemProps = {
  icon: string;
  stateActive?: boolean;
  onclick: () => void;
};

const GridItem: FC<TGridItemProps> = ({ icon, stateActive, onclick }) => (
  <div
    className={cn(css.gridItem, {
      isActive: stateActive,
    })}
    key={icon}
    onClick={onclick}
  >
    <img src={icon} alt="pieSvg" />
  </div>
);

export default GridItem;
