import { shallowEqual, useSelector } from 'react-redux';
import { RefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ParamProject, RProject } from '../types';
import { AppState } from '../../../redux/rootReducer';
import {
  changeLangRequest,
  fetchLangRequest,
  fetchProjectAction,
  ProjectState,
  setBlockedAd,
} from '../reducer';
import {
  THookSelectorFetchDataInfo,
  useDispatchApp,
  useGenFetchDataSelector,
} from '../../../redux/rootSelectors';
import {
  selectorProject,
  useLangGroupInfo,
  useLangInfo,
  useProjectLogoTypeInfo,
} from '../model/selectors';

type TFUseFetchProject = (props: ParamProject) => () => void;
type TFUseCurrency = () => { currency: string };
type TFUseIsRegistration = () => { isRegistration: boolean };
type TFuseLangGroup = () => {
  langGroup: ProjectState['langGroup'];
  lang: ProjectState['lang'];
  fetchLang: () => void;
  changeLang: (param: ProjectState['lang']) => void;
};
type TFUseLogoProject = () => {
  logoType: string | null;
  isLoading: boolean;
};

/** Функция получает состояние проекта из стейта */
export const useProjectInfo: THookSelectorFetchDataInfo<RProject> = () =>
  useGenFetchDataSelector(selectorProject);

/** Функция запрашивает настройки проекта */
export const useFetchProject: TFUseFetchProject = (param) => {
  const dispatch = useDispatchApp();
  return useCallback(() => {
    dispatch(fetchProjectAction(param));
  }, [dispatch]);
};

/** Функция возвращает значание валюты для домена */
export const useCurrency: TFUseCurrency = () => {
  const { data } = useProjectInfo();

  let currency = '';
  if (data && data.currency) {
    if (data.currency === 'RUB') {
      currency = '₽';
    }
    if (data.currency === 'USD') {
      currency = '$';
    }
  }

  return { currency };
};

/** Функция возвращает разрешена ли регистрация в проекте */
export const useIsRegistration: TFUseIsRegistration = () => {
  const { data: project, isLoading } = useProjectInfo();

  const isRegistration = useMemo<boolean>(() => {
    if (project && !isLoading) {
      return project.is_available_registration;
    }
    return false;
  }, [isLoading]);
  return { isRegistration };
};
/** функция возвращает список языков и активный язык */
export const useLangGroup: TFuseLangGroup = () => {
  const dispatch = useDispatchApp();
  const lang = useLangInfo();
  const langGroup = useLangGroupInfo();

  const fetchLang: ReturnType<TFuseLangGroup>['fetchLang'] = () => {
    dispatch(fetchLangRequest());
  };
  const changeLang: ReturnType<TFuseLangGroup>['changeLang'] = (v) => {
    dispatch(changeLangRequest(v));
  };

  return { langGroup, lang, fetchLang, changeLang };
};

export const useLogoProject: TFUseLogoProject = () => {
  const { data, isLoading, error } = useProjectLogoTypeInfo();

  return {
    logoType: error.isError ? '' : data,
    isLoading,
  };
};

/** функция проверяет установлен ли блокировщик рекламы */
type TUseBlockedAd = () => {
  link: string;
  isBlocked: boolean;
  refLink: RefObject<HTMLAnchorElement>;
};
export const useBlockedAd: TUseBlockedAd = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatchApp();
  const refLink = useRef<HTMLAnchorElement>(null);
  const {
    adBlock: { isBlocked, link },
  } = useSelector<AppState, ProjectState>(
    ({ projectReducer }) => projectReducer,
    shallowEqual,
  );

  useEffect(() => {
    if (refLink && refLink.current) {
      if (!refLink?.current?.offsetHeight) {
        dispatch(setBlockedAd(true));
      } else {
        dispatch(setBlockedAd(false));
      }
    }
  }, [refLink?.current?.offsetHeight]);

  useEffect(() => {
    if (!isBlocked && location.pathname === '/error/ad_blocker') {
      history.push('/main');
    }
  }, [isBlocked]);

  return {
    link,
    isBlocked,
    refLink,
  };
};
