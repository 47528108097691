import React, { FC } from 'react';
import PreloaderForm from '../Form';

const PreloaderFormList: FC = () => (
  <div>
    <PreloaderForm />
    <PreloaderForm />
    <PreloaderForm />
  </div>
);

export default PreloaderFormList;
