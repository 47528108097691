import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { FiX } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';
import { TID } from '../../types/general';

const NotificationTarget: React.FC<RouteComponentProps<TID>> = ({ match }) => {
  const storageNotification = JSON.parse(
    localStorage.getItem('$showNotificationTarget') || 'null',
  );
  const [show, setShow] = useState<boolean | null>(storageNotification);

  useEffect(() => {
    if (storageNotification === null) {
      setShow(true);
      localStorage.setItem('$showNotificationTarget', 'true');
    }
  }, []);

  const onClick = () => {
    setShow(false);
    localStorage.setItem('$showNotificationTarget', 'false');
  };
  const { t } = useTranslation();
  return show ? (
    <div className={css.notification}>
      <div className="container">
        <div className={css.content}>
          <svg
            width="28"
            height="24"
            viewBox="0 0 28 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.1493 2.64169C17.1312 0.988963 14.5674 0 11.7768 0C5.27266 0 0 5.37258 0 12C0 18.6274 5.27266 24 11.7768 24C18.281 24 23.5536 18.6274 23.5536 12C23.5536 11.2798 23.4914 10.5744 23.372 9.88912L22.0027 10.6947C22.0547 11.1223 22.0815 11.558 22.0815 12C22.0815 17.799 17.468 22.5 11.7768 22.5C6.08568 22.5 1.4721 17.799 1.4721 12C1.4721 6.20101 6.08568 1.5 11.7768 1.5C14.0108 1.5 16.0787 2.22432 17.7667 3.45505L19.1493 2.64169ZM11.7764 9C10.1504 9 8.83224 10.3431 8.83224 12C8.83224 13.6569 10.1504 15 11.7764 15C13.4025 15 14.7207 13.6569 14.7207 12C14.7207 11.695 14.676 11.4006 14.5929 11.1232L20.0109 7.93586L22.1246 8.87103C22.3813 8.98461 22.6757 8.9675 22.918 8.82492L27.5586 6.09492C28.1868 5.72534 28.1342 4.78437 27.4688 4.48997L25.7163 3.71462L25.9336 1.78053C26.016 1.04614 25.2426 0.529185 24.6144 0.898772L19.9738 3.62877C19.7315 3.77135 19.5698 4.02254 19.5379 4.30586L19.2762 6.63604L13.8235 9.84381C13.2937 9.32144 12.5719 9 11.7764 9ZM11.7769 4.5C13.5171 4.5 15.1163 5.11539 16.3763 6.14425L14.9809 6.96515C14.0588 6.35464 12.9585 6 11.7769 6C8.5248 6 5.88847 8.68629 5.88847 12C5.88847 15.3137 8.5248 18 11.7769 18C15.029 18 17.6653 15.3137 17.6653 12C17.6653 11.7947 17.6552 11.5918 17.6354 11.3918L19.0066 10.5851C19.0925 11.0433 19.1374 11.5163 19.1374 12C19.1374 16.1421 15.842 19.5 11.7769 19.5C7.71178 19.5 4.41636 16.1421 4.41636 12C4.41636 7.85786 7.71178 4.5 11.7769 4.5ZM20.7585 6.63128L20.9668 4.77669L24.3381 2.79337L24.2042 3.98562C24.16 4.37909 24.3734 4.75575 24.7299 4.91349L25.8102 5.39145L22.4389 7.37477L20.7585 6.63128Z"
              fill="#025DFF"
            />
          </svg>
          <p>
            {t('campaigns_page.campaign_settings.creative_hint')}{' '}
            <Link to={`/main/campaign/${match.params.id}/`}>
              {t('campaigns_page.campaign_settings.creative_hint2')}
            </Link>{' '}
            {t('campaigns_page.campaign_settings.creative_hint3')}
          </p>
        </div>

        <div className={css.button} onClick={onClick}>
          <FiX />
        </div>
      </div>
    </div>
  ) : null;
};

export default withRouter(NotificationTarget);
