import React, { FC } from 'react';
import Card from 'components/UI/Card';
import { FormikProps } from 'formik';
import { CounteragentFormikProps } from 'domains/counteragent/model/types';
import { useTranslation } from 'react-i18next';
import { OrdList } from 'domains/counteragents/model/types';
import css from '../styles.module.scss';

interface IInfoProps {
  edit?: boolean;
  externalId?: string;
  formik: FormikProps<CounteragentFormikProps>;
  ordList: OrdList | null;
}

const Info: FC<IInfoProps> = ({ edit = false, externalId, formik, ordList }) => {
  const { t } = useTranslation();
  return (
    <Card
      noHover
      style={{ padding: '28px 24px' }}
      isUseAccess
      className={css.card}
    >
      <h3 className={css.title}>{t(`ord.contragents_info`)}</h3>
      {edit && <div className={css.subtitle}>{externalId}</div>}
      <div className={css.string_wrapper}>
        <strong>{t(`ord.registered`)}</strong>
        <span>{ordList?.find(({ id }) => id === formik.values.ord)?.title || 'Неизвестная ОРД'}</span>
      </div>
    </Card>
  );
};

export default Info;
