import * as React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { FiX, FiMoreHorizontal, FiTrash2 } from 'react-icons/fi';
import toLocaleStringSupporte from 'utils/toLocaleStringSupporte';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';
import { ResponseContainers } from '../../../types/containers';

interface Props {
  item: ResponseContainers;
  isActive: boolean;
  onSetToggle: (listing_id: string) => void;
}

const Item: React.FC<Props> = ({ isActive, onSetToggle, item }) => {
  const { t } = useTranslation();

  const { id, bgColor, statistics, title } = item;
  return (
    <div>
      <div className={cn(css.item, { _isOpen: isActive })}>
        <Link to={`/main/container-detail/${id}`} />
        <div className={css.item__header}>
          <div style={{ backgroundColor: bgColor }}>
            {title.slice(0, 1).toUpperCase()}
          </div>
          <div>
            <strong>{title}</strong>
          </div>
        </div>

        <div>{toLocaleStringSupporte(statistics.total)}</div>

        <div>{toLocaleStringSupporte(statistics.previous_day)}</div>

        <div
          className={cn('toggle-dropdown', css.dropdown, {
            _isOpen: isActive,
          })}
        >
          <button
            type="button"
            className={css.dropdown__show}
            onClick={() => onSetToggle(id)}
          >
            <FiMoreHorizontal size={24} />
            <FiX size={24} />
          </button>

          <div className={css.dropdown__menu}>
            <div className="disabled">
              <span>{t('delete_btn')}</span>
              <FiTrash2 size={24} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
