import { SagaIterator } from '@redux-saga/core';
import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  deleteAPIUser,
  editAPIUser,
  fetchAPIUsers,
  refreshTokenAPIUser,
  createNewAPIUser,
  getAPIUsersMethods,
  fetchGetTechnicalInfo,
} from '../actions';
import {
  deleteAPIUsersWorker,
  refreshTokenAPIUsersWorker,
  workerAPIUserEdit,
  workerFetchAPIUsers,
  workerGetMethodsUserAPISaga,
  workerGetTechnicalInfo,
  workerUserCreateAPISaga,
} from './workers';

export default function* watchAPIUsers(): SagaIterator {
  yield takeLatest(fetchAPIUsers, workerFetchAPIUsers);
  yield takeEvery(refreshTokenAPIUser, refreshTokenAPIUsersWorker);
  yield takeEvery(fetchGetTechnicalInfo, workerGetTechnicalInfo);
  yield takeEvery(deleteAPIUser, deleteAPIUsersWorker);
  yield takeLatest(createNewAPIUser, workerUserCreateAPISaga);
  yield takeLatest(editAPIUser, workerAPIUserEdit);
  yield takeLatest(getAPIUsersMethods, workerGetMethodsUserAPISaga);
}
