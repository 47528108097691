import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from '../../redux/fetchedData';
import { FilesBwUpdate, RFilesBwListUpdate } from './types';

export type BwListUpdateState = {
  isShowModal: boolean;
  loadFiles: FetchedData<RFilesBwListUpdate>;
};
const initialState: BwListUpdateState = {
  isShowModal: false,
  loadFiles: genFetchedData<RFilesBwListUpdate>(null),
};

const bwListUpdate = createSlice({
  name: 'bwListUpdate',
  initialState,
  reducers: {
    isShowModalChangeBwListUpdate(
      state,
      action: PayloadAction<BwListUpdateState['isShowModal']>,
    ) {
      state.isShowModal = action.payload;
    },
    setBwListLoadFiles(
      state,
      action: PayloadAction<BwListUpdateState['loadFiles']>,
    ) {
      state.loadFiles = action.payload;
    },
    setCleanLoadList(state) {
      state.loadFiles = initialState.loadFiles;
    },
  },
});

export const UploadFilesBwListUpdate = createAction<FilesBwUpdate>(
  'bwListUpdate/UploadFilesBwListUpdate',
);

export const {
  isShowModalChangeBwListUpdate,
  setBwListLoadFiles,
  setCleanLoadList,
} = bwListUpdate.actions;
export const bwListUpdateReducer = bwListUpdate.reducer;
