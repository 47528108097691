import { useCallback } from 'react';
import { ILimits } from 'domains/target/types';
import { TCellTypes, TLeftD, Tdata } from '../types';
import { createData, createLeftD } from '..';

type UseRecursiveGridEventHandlersArgs = {
  leftD: TLeftD[];
  deletedItemsArr: React.MutableRefObject<string[]>;
  isSourceLike: boolean;
  setLeftD: React.Dispatch<React.SetStateAction<TLeftD[]>>;
  data: Tdata[];
  setData: React.Dispatch<React.SetStateAction<Tdata[]>>;
  /** задаем state показа кнопки при любом изменении в итемах */
  setShowSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
};

type UseRecursiveGridEventHandlersReturn = {
  /** функция меняет св-во чекед */
  onChekedLD: (k: string, v: boolean) => void;
  /** функция меняет св-во из TCellTypes */
  onChangeCellData: (
    k: string,
    v: number | string,
    valType: TCellTypes,
  ) => void;
  /** функция меняет объект limits */
  onChangeLimits: (k: string, limits: ILimits) => void;
  /** функция удаляет объект limits */
  onRemoveLimits: (k: string) => void;
  /** удаляет по ключу */
  onRemoveItem: (k: string) => void;
  /** устанавливает значение is_checked по массиву ключей */
  onChekedLD4arr: (
    keys: { key: string; title: string; value?: string }[],
  ) => void;
  /** устанавливает title & value по ключу */
  onChangeTitleVal: (k: string, t: string, v: string) => void;
  checkItemLimits: (limitsObj: ILimits | undefined) => boolean;
  onChangeValHandler: (k: string, e: React.ChangeEvent<HTMLInputElement>, valType: TCellTypes) => void;
  deletedItemsArr: React.MutableRefObject<string[]>;
};

type TUseRecursiveGridEventHandlers = (
  arg: UseRecursiveGridEventHandlersArgs,
) => UseRecursiveGridEventHandlersReturn;

export const useRecursiveGridEventHandlers: TUseRecursiveGridEventHandlers = ({
  leftD,
  data,
  isSourceLike,
  deletedItemsArr,
  setLeftD,
  setData,
  setShowSaveButton,
}) => {

  // функция проходит по дереву итемов и устанавливает is_checked флаг на итеме по которому произошел клик
  const recursiveCheck = useCallback(
    (item: TLeftD, k: string, v: boolean): TLeftD => {
      if (item.key === k) {
        if (item.children && item.children.length) {
          // fillDeletedItemsArr(item);
          return {
            ...item,
            is_checked: v,
            _isSettings: true,
            children: item.children.map((child) => ({
              ...child,
              is_checked: v,
              _isSettings: true,
            })),
          };
        }
        return {
          ...item,
          is_checked: v,
          _isSettings: true,
        };
      }
      if (item.children) {
        return {
          ...item,
          children: item.children.map((child) =>
            recursiveCheck(child, k, v),
          ),
        };
      }
      return item;
    },
    [leftD],
  );

  /** устанавливает значение is_checked по ключу */
  const onChekedLD = useCallback(
    (k: string, v: boolean) => {
      setShowSaveButton(true);
      if (!v && isSourceLike) {
        onChangeCellData(k, 0, 'bid_start');
      }
      setLeftD((d) => d.map((item) => recursiveCheck(item, k, v)));
    },
    [leftD],
  );
  /** устанавливает значение is_checked по ключу */
  const onChekedLD4arr = useCallback(
    (k: { key: string; title: string; value?: string }[]) => {
      const arr4Data: { key: string; title: string; value?: string }[] = [];
      setLeftD((d) => {
        const copy = [...d];
        k.forEach((item) => {
          const index = copy.findIndex((i) => i.key === item.key);
          if (index < 0) {
            // eslint-disable-next-line no-param-reassign
            deletedItemsArr.current = deletedItemsArr.current.filter((key) => key !== item.key);
            copy.push(
              createLeftD({
                k: item.key,
                title: item.title,
                value: item.value,
                is_checked: true,
                _isSettings: true,
              }),
            );
            arr4Data.push(item);
          } else {
            copy[index] = {
              ...copy[index],
              is_checked: true,
              _isSettings: true,
            };
          }
        });
        return copy;
      });
      setShowSaveButton(true);
      if (!arr4Data.length) return;
      setData((d) => {
        const copy = [...d];
        arr4Data.forEach((item) => {
          copy.push(createData({ k: item.key, title: item.title }));
        });
        return copy;
      });
    },
    [leftD, data],
  );
  //* удаляет по ключу */
  const onRemoveItem = useCallback(
    (k: string) => {
      setShowSaveButton(true);
      deletedItemsArr.current.push(k)
      setLeftD((d) => {
        const index = d.findIndex((item) => item.key === k);
        const copy = [...d];
        copy.splice(index, 1);
        return copy;
      });
      setData((d) => {
        const index = d.findIndex((item) => item.key === k);
        const copy = [...d];
        copy.splice(index, 1);
        return copy;
      });
    },
    [leftD, data, deletedItemsArr.current],
  );

  const recursionChangeLeftVal = (
    item: TLeftD,
    v: number | string,
    valType: TCellTypes,
  ): TLeftD => {
    if (item.children?.length) {
      return {
        ...item,
        bid_start: v === 'bidfloor' ? 0 : item.bid_start,
        [valType]: v,
        is_checked: true,
        _isSettings: true,
        children: item.children.map((child) =>
          recursionChangeLeftVal(child, v, valType),
        ),
      };
    }
    if (item.type === 'bidfloor' && valType === 'bid_start') {
      return {
        ...item,
        is_checked: true,
        bid_start: 0,
        _isSettings: true,
      };
    }

    return {
      ...item,
      is_checked: true,
      _isSettings: true,
      bid_start: v === 'bidfloor' ? 0 : item.bid_start,
      [valType]: v,
    };
  };

  const recursionChangeRightVal = (
    item: Tdata,
    v: number | string,
    valType: TCellTypes,
  ): Tdata => {
    if (item.children?.length) {
      return {
        ...item,
        bid_start: v === 'bidfloor' ? 0 : item.bid_start,
        [valType]: v,
        children: item.children.map((child) =>
          recursionChangeRightVal(child, v, valType),
        ),
      };
    }
    if (item.type === 'bidfloor' && valType === 'bid_start') {
      return {
        ...item,
        bid_start: 0,
      };
    }
    return {
      ...item,
      bid_start: v === 'bidfloor' ? 0 : item.bid_start,
      [valType]: v,
    };
  };

  const recursiveChangeCellValLeft = useCallback(
    (
      item: TLeftD,
      k: string,
      v: number | string,
      valType: TCellTypes,
    ): TLeftD => {
      if (item.key === k) {
        return recursionChangeLeftVal(item, v, valType);
      }
      if (item.children) {
        return {
          ...item,
          children: item.children.map((child) =>
            recursiveChangeCellValLeft(child, k, v, valType),
          ),
        };
      }
      return item;
    },
    [leftD],
  );
  // Сократить
  const recursiveChangeCellValRight = useCallback(
    (
      item: Tdata,
      k: string,
      v: number | string,
      valType: TCellTypes,
    ): Tdata => {
      if (item.key === k) {
        return recursionChangeRightVal(item, v, valType);
      }
      if (item.children) {
        return {
          ...item,
          children: item.children.map((child) =>
            recursiveChangeCellValRight(child, k, v, valType),
          ),
        };
      }
      return item;
    },
    [leftD],
  );
  //* устанавливает значение из TCellTypes по ключу */
  const onChangeCellData = useCallback(
    (k: string, v: number | string, valType: TCellTypes) => {
      setShowSaveButton(true);
      setLeftD((d) =>
        d.map((item) => recursiveChangeCellValLeft(item, k, v, valType)),
      );
      setData((d) =>
        d.map((item) => recursiveChangeCellValRight(item, k, v, valType)),
      );
    },
    [leftD],
  );

  const onChangeValHandler = (
    k: string,
    e: React.ChangeEvent<HTMLInputElement>,
    valType: TCellTypes,
  ) => {

    const val = e.target.value;
    const isHour = valType === 'hour';
    const rex = isHour ? /^[0-9]\d*$/ : /^(\d+\.?\d{0,3}|\.\d{0,3})$/;
    if (
      (val.length && !rex.test(val)) ||
      Number(val.replace('.', ',')) >= 100000
    ) {
      return;
    }
    if (valType) {
      const finalVal = isHour ? Number(val) : val;
      onChangeCellData(k, finalVal, valType);
    }
  };

  //* устанавливает title & value по ключу */
  const onChangeTitleVal = useCallback(
    (k: string, t: string, v: string) => {
      setShowSaveButton(true);
      setLeftD((d) => {
        const index = d.findIndex((item) => item.key === k);
        if (index < 0) return d;
        const temp = { ...d[index] };
        const copy = [...d];

        copy[index] = {
          ...temp,
          key: t,
          title: t,
          value: v,
          is_checked: true,
          _isSettings: true,
        };
        return copy;
      });
      setData((d) => {
        const index = d.findIndex((item) => item.key === k);
        if (index < 0) return d;
        const temp = { ...d[index] };
        const copy = [...d];
        copy[index] = { ...temp, key: t, title: t, value: v };
        return copy;
      });
    },
    [leftD],
  );

  const checkItemLimits = useCallback(
    (limitsObj: ILimits | undefined) => {
      if (limitsObj && Object.keys(limitsObj).length !== 0) {
        const limitsKeys = Object.keys(limitsObj);
        return limitsKeys.some((key) =>
          limitsObj && limitsObj[key]
            ? Object.keys(limitsObj[key]).length > 0
            : false,
        );
      }
      return false;
    },
    [leftD],
  );
  // Функции изменяющая значение limits у конкретного итема из дерева
  // Сократить
  const recursiveChangeLimitsLeft = useCallback(
    (item: TLeftD, k: string, limits: ILimits): TLeftD => {
      if (item.key === k) {
        return checkItemLimits(limits)
          ? {
              ...item,
              is_checked: true,
              limits,
              _isSettings: true,
            }
          : {
              ...item,
              limits,
              _isSettings: true,
            };
      }
      if (item.children) {
        return {
          ...item,
          children: item.children.map((child) =>
            recursiveChangeLimitsLeft(child, k, limits),
          ),
        };
      }
      return item;
    },
    [leftD],
  );
  // Сократить
  const recursiveChangeLimitsRight = useCallback(
    (item: Tdata, k: string, limits: ILimits): Tdata => {
      if (item.key === k) {
        return {
          ...item,
          limits,
        };
      }
      if (item.children) {
        return {
          ...item,
          children: item.children.map((child) =>
            recursiveChangeLimitsRight(child, k, limits),
          ),
        };
      }
      return item;
    },
    [leftD],
  );

  const onChangeLimits = useCallback(
    (k: string, limits: ILimits) => {
      setShowSaveButton(true);
      setLeftD((d) =>
        d.map((item) => recursiveChangeLimitsLeft(item, k, limits)),
      );
      setData((d) =>
        d.map((item) => recursiveChangeLimitsRight(item, k, limits)),
      );
    },
    [leftD],
  );

  // Функции удаляющая значение limits у конкретного итема из дерева
  // Сократить
  const recursiveRemoveLimitsLeft = (item: TLeftD, k: string): TLeftD => {
    if (item.key === k) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { limits, ...itemWithoutLimits } = item;
      return {
        ...itemWithoutLimits,
        _isSettings: true,
      };
    }
    if (item.children) {
      return {
        ...item,
        children: item.children.map((child) =>
          recursiveRemoveLimitsLeft(child, k),
        ),
      };
    }
    return item;
  };
  // Сократить
  const recursiveRemoveLimitsRight = (item: Tdata, k: string): Tdata => {
    if (item.key === k) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { limits, ...itemWithoutLimits } = item;
      return {
        ...itemWithoutLimits,
      };
    }
    if (item.children) {
      return {
        ...item,
        children: item.children.map((child) =>
          recursiveRemoveLimitsRight(child, k),
        ),
      };
    }
    return item;
  };

  const onRemoveLimits = useCallback(
    (k: string) => {
      setShowSaveButton(true);
      setLeftD((d) => d.map((item) => recursiveRemoveLimitsLeft(item, k)));
      setData((d) => d.map((item) => recursiveRemoveLimitsRight(item, k)));
    },
    [leftD],
  );
  return {
    onChekedLD,
    onChangeCellData,
    onChangeValHandler,
    onChangeLimits,
    onRemoveLimits,
    onRemoveItem,
    onChekedLD4arr,
    onChangeTitleVal,
    checkItemLimits,
    deletedItemsArr,
  };
};
