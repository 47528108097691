import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useUserInfo, useUserTheme } from 'domains/user/model/selectors';
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import Tooltip from 'components/UI/Tooltip';
import { FiDownload, FiRefreshCcw } from 'react-icons/fi';
import DateRangeFilter from 'components/DateRangeFilter';
import { parsingDate } from 'utils/momentParse';
import moment from 'moment';
import LoadingReportChart from 'components/ReportChart/components/LoadingReportChart';
import ReportChart from 'components/ReportChart';
import { useReportChart } from 'components/ReportChart/hook/useReportChart';
import NavigationHeader from 'components/NavigationHeader';
import GeneralReportTable from 'components/GeneralReportTable';
import css from './styles.module.scss';
import { useGetChartPreData } from '../model/hooks/useGetChartPreData';
import { useCampaignsPeriodInfo } from '../model/selectors';
import { useUpdateChartData } from './model/useUpdateChartData';
import ErrorContainer from '../../../components/Errors/Reports';
import { useGetColumns } from './model/useGetColumns';
import { useGetClientReport } from './model/useGetClientReport';

const Reports: FC = () => {
  const { t, i18n } = useTranslation();
  const { isThemeExtended, isThemeFull } = useUserTheme();

  const { data: user } = useUserInfo();

  const campaignsPeriod = useCampaignsPeriodInfo();

  const {
    onUpdateDate,
    onUpdateStatistic,
    settings,
    partner_xxhash,
    isLoadingCampaigns,
    campaignsList,
  } = useUpdateChartData();

  const { columns } = useGetColumns({
    partner_xxhash,
    language: i18n.language,
    queryArr: ['All', 'Campaign'],
  });

  const { chartLegend, chartData, chartDataIsLoading, onClickChartLegend } =
    useGetChartPreData({ filterColumns: columns });

  const {
    dataForChart,
    series,
    namingItem,
    transformedStatistic,
    totalValues,
    isLoadingChartView,
  } = useReportChart({
    columns,
    chartData,
    chartDataLegend: chartLegend,
  });

  const { disGetReport, onGetReport } = useGetClientReport(
    transformedStatistic,
    totalValues,
    columns,
  );

  return (
    <div className={css.reports}>
      <Helmet>
        <title>{t(`clients_page.client_report`)}</title>
      </Helmet>
      {user?.partner.type === 'CLIENT' && (
        <NavigationHeader
          back={{
            title: t(`campaigns_page.campaign_settings.black_menu.Back`),
            to: `/main/campaigns`,
          }}
        />
      )}
      <div className={cn(css.header)}>
        <div
          className={cn('container resized', {
            extend: isThemeExtended,
            full: isThemeFull,
          })}
        >
          <Breadcrumbs type="Client" xxhash={partner_xxhash} />
          <div className={css.status}>
            <h1 className={css.title}>{t(`clients_page.client_report`)}</h1>
            <div className={css.actions}>
              <div className={css.update} onClick={() => null}>
                <Tooltip
                  title={<FiDownload size={22} />}
                  left
                  isDisabled={disGetReport || isLoadingCampaigns || chartDataIsLoading || isLoadingChartView}
                  onClick={onGetReport}
                >
                  {t(`download_btn`)}
                </Tooltip>
              </div>
              <div
                className={css.update}
                onClick={() => onUpdateStatistic(settings.period)}
              >
                <Tooltip bottom title={<FiRefreshCcw size={22} />}>
                  {t(`update_btn`)}
                </Tooltip>
              </div>
              <div className={css.range}>
                <DateRangeFilter
                  range={{
                    from: parsingDate(settings.period.from).format(
                      'YYYY-MM-DD',
                    ),
                    to: parsingDate(settings.period.to).format('YYYY-MM-DD'),
                  }}
                  dateStart={parsingDate(
                    campaignsPeriod.from || moment().subtract(365, 'days'),
                  ).toDate()}
                  dateEnd={parsingDate(
                    campaignsPeriod.to || new Date(),
                  ).toDate()}
                  onSetDateRange={onUpdateDate}
                  initialTitleDay={settings.type}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {!isLoadingCampaigns &&
      !chartDataIsLoading &&
      !isLoadingChartView &&
      ((chartData && Object.keys(chartData).length === 0) ||
        (campaignsList && campaignsList.length === 0)) ? (
        <ErrorContainer type="Reports" />
      ) : (
        <div
          className={cn('container resized', {
            extend: isThemeExtended,
            full: isThemeFull,
          })}
        >
          <div className={css.content}>
            {isLoadingCampaigns || chartDataIsLoading || isLoadingChartView ? (
              <LoadingReportChart />
            ) : (
              <ReportChart
                chartDataLegend={chartLegend}
                onClickChartLegend={onClickChartLegend}
                dataForChart={dataForChart}
                series={series}
                namingItem={namingItem}
              />
            )}
            <GeneralReportTable
              tableTitle={t(
                'campaigns_page.campaign_settings.reports.stat_tit',
              )}
              isLoadingTableData={isLoadingCampaigns || chartDataIsLoading}
              columns={columns}
              totalValues={totalValues}
              transformedStatistic={transformedStatistic}
              mode="client"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Reports;
