import { createAction } from '@reduxjs/toolkit';
import { PSagaSetStatisticsFields } from 'domains/campaign/types';
import {
  PartnerHash,
  Period,
  PSagaCopyElement,
  PSagaDoubleElement,
  THASH,
  TLoadList,
} from '../../../types/general';
import {
  ParamsCampaigns,
  PChangeGroupCampaigns,
  PExportItemsToExcel,
} from '../types';

export const loadCampaignsListView = createAction<TLoadList>(
  'campaigns/loadCampaignsListView',
);

interface ISearch {
  search: string,
  callbacks?: {
    setIsLoadingSearch: React.Dispatch<React.SetStateAction<boolean>>,
  }
}

export const searchCampaignListView = createAction<ISearch & {period?: Period, blockTotalRequest?: boolean}>(
  'campaigns/searchCampaignListView',
);

export const fetchCampaignsListModal = createAction<ParamsCampaigns>(
  'campaigns/fetchCampaignsListModal',
);

export const fetchPartnerBalance = createAction<PartnerHash>(
  'campaigns/fetchPartnerBalance',
);

export const changeGroupCampaigns = createAction<PChangeGroupCampaigns>(
  'campaigns/changeGroupCampaigns',
);
export const cloneCampaignsToClient = createAction<PSagaCopyElement>(
  'campaigns/cloneCampaignsToClient',
);

export const doubleCampaigns = createAction<PSagaDoubleElement>(
  'campaigns/doubleCampaigns',
);

export const exportItemsToExcel = createAction<PExportItemsToExcel>(
  'campaigns/exportItemsToExcel',
);

export const fetchStatisticFieldList = createAction<THASH & {types: string[]}>(
  'partner/fetchStatisticFieldList',
);

export const setPartnerStatisticsFields = createAction<PSagaSetStatisticsFields>(
  'partner/setPartnerStatisticsFields',
);