import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Chart from 'react-google-charts';
import { ScrollSync } from 'scroll-sync-react';
import ChartLegend from 'components/ReportChart/components/ChartLegend';
import ElementsNone from 'components/ElementsNone';
import ResizedCard from '../../../../../components/ResizedCard';
import css from './styles.module.scss';
import './styles.css';
import {
  TReportData,
  TNamingItem,
  TItem,
  PCampaignReport,
  TChartDate,
} from '../../../types';
import Loader from '../../../../../components/UI/Loader';
import { useReportGeneral } from '../hooks/useReportGeneral';
import { useIntegrationFilter } from '../hooks/useIntegrationFilter';
import ChartFilter from './ChartFilter';
import { chartColors } from '../chartColors';
import Table from '../../../../../components/UI/Table';
import FixedRowT from './FixedRowT/FixedRowT';
import TreeNodes from './TreeNodes';
import Header from './Header';
import emptyTable from '../../../../../assets/errors/empty-report.svg';

type Props = {
  report: TReportData | null;
  defChartItems: ReadonlyArray<string>;
  namingItem: TNamingItem;
  fixedRowT: TItem[];
  requestParams: PCampaignReport;
  isUtmTags?: boolean;
  statistics: TItem[];
  chartDataP?: TChartDate[];
};

const GeneralReport: FC<Props> = ({
  report,
  defChartItems,
  namingItem,
  fixedRowT,
  requestParams,
  isUtmTags = false,
  statistics,
  chartDataP = [],
}) => {
  const titles =
    report && report.statistics
      ? report.statistics.map((e) => e.utm_tags || e.date)
      : [];
  const allTitles = ['total', ...titles];

  const { t, i18n } = useTranslation();

  const {
    isRenderChart,
    chartItems,
    expand,
    series,
    dataForChart,
    memoizedStatistics,
    onClickChartLegend,
    setSortEvent,
    sortEvent,
    setExpand,
    chartData,
    setChartItems,
  } = useReportGeneral({
    chartData: chartDataP,
    defChartItems,
    namingItem,
    statistics,
  });

  const {
    filter,
    onSetFilterChart,
    seriesChecked,
    dataChecked,
    onSetFilterTable,
    isLoadingData,
  } = useIntegrationFilter({
    allTitles,
    requestParams,
    allChartData: chartDataP,
  });

  return (
    <div>
      {isRenderChart && statistics.length !== 0 && (
        <ResizedCard>
          <div className={css.chart}>
            {filter.itemsFilter.length ? (
              <ChartFilter
                filter={filter}
                onSetFilterChart={onSetFilterChart}
                allTitles={allTitles}
              />
            ) : (
              <div className={css.chart__legend}>
                {chartItems.map((item, i) => {
                  const obj = namingItem.get(item.name);
                  const { title, color } =
                    typeof obj !== 'undefined'
                      ? obj
                      : { title: item.name, color: chartColors[i] };

                  return (
                    <ChartLegend
                      key={item.name}
                      onClick={() => onClickChartLegend(item.name)}
                      backgroundColor={color || chartColors[i]}
                      name={title}
                      isOff={item.isOff}
                    />
                  );
                })}
              </div>
            )}
            <>
              <Chart
                chartLanguage={i18n.language ?? 'ru'}
                chartType="AreaChart"
                width="100%"
                height="326px"
                data={
                  !isLoadingData && filter.itemsFilter.length
                    ? dataChecked
                    : dataForChart
                }
                loader={<Loader />}
                options={{
                  tooltip: { isHtml: true, trigger: 'both' },
                  legend: { position: 'none' },
                  animation: {
                    startup: true,
                    easing: 'linear',
                    duration: 100,
                  },
                  chartArea: {
                    height: '80%',
                    left: 60,
                    right: 32,
                    backgroundColor: {
                      stroke: 'transparent',
                      strokeWidth: 1,
                    },
                  },
                  vAxis: {
                    format: 'short',
                    gridlines: {
                      color: '#ddd',
                      count: 5,
                      lineStyle: 'dashed',
                    },
                    minorGridlines: {
                      count: 0,
                      color: '#ddd',
                    },
                    baselineColor: '#ddd',
                    textStyle: {
                      color: '#828282',
                      fontName: 'Rubik',
                      fontSize: 12,
                    },
                    viewWindow: {},
                  },
                  hAxis: {
                    slantedText: false,
                    textStyle: {
                      color: '#828282',
                      fontName: 'Rubik',
                      fontSize: 12,
                    },
                  },
                  series:
                    !isLoadingData && filter.itemsFilter.length
                      ? seriesChecked
                      : series,
                  focusTarget: 'category',
                  aggregationTarget: 'none',
                  crosshair: {
                    trigger: 'both',
                    orientation: 'vertical',
                    color: '#025DFF',
                    opacity: 0.7,
                  },
                }}
              />
            </>
          </div>
        </ResizedCard>
      )}
      {statistics.length !== 0 ? (
        <ResizedCard>
          <div className={css.table}>
            <div className={css.table__header}>
              <div className={css.table__title}>
                <h3>
                  {t('campaigns_page.campaign_settings.reports.stat_tit')}
                </h3>
              </div>
            </div>
            <ScrollSync>
              {report && (
                <>
                  <Table
                    // changeHScrollHandler={changeHScrollHandler}
                    expand={expand}
                    header={
                      <Header
                        namingItem={namingItem}
                        item={memoizedStatistics[0]}
                        setSortEvent={setSortEvent}
                        chartItems={chartItems}
                        setChartItems={setChartItems}
                        sortEvent={sortEvent}
                        nochart={!chartData.length}
                        expand={expand}
                        setExpand={setExpand}
                        onSetFilterTable={onSetFilterTable}
                        filterTable={filter.activeColumns}
                        allTitles={[
                          ...memoizedStatistics.map((e: any) => e.utm_tags),
                          'utm_tags',
                          'total',
                        ]}
                        isUtmTags={isUtmTags}
                        // hScroll={hScroll}
                      />
                    }
                    sizeItems={namingItem.size - 1}
                  >
                    {fixedRowT && (
                      <FixedRowT
                        rowsData={fixedRowT}
                        namingItem={namingItem}
                        firstCW={expand}
                        filterTable={filter.activeColumns}
                        isUtmTags={isUtmTags}
                      />
                    )}
                    <TreeNodes
                      nodes={memoizedStatistics}
                      namingItem={namingItem}
                      firstCW={expand}
                      onSetFilterTable={onSetFilterTable}
                      filterTable={filter.activeColumns}
                      isUtmTags={isUtmTags}
                    />
                  </Table>
                </>
              )}
            </ScrollSync>
          </div>
        </ResizedCard>
      ) : (
        <ElementsNone
          customImg={emptyTable}
          customTitle={t(`common.reports.no_data`)}
        />
      )}
    </div>
  );
};

export default GeneralReport;
