/* eslint-disable no-continue */
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import CardList from 'components/UI/CardList';
import Button from 'components/UI/Buttons/buttons';
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import { TFormikProps, TOfferEditAction } from 'domains/offer/model/types';
import { Form, FormikProvider, useFormik } from 'formik';
import { useDispatchApp } from 'redux/rootSelectors';
import { TID } from 'types/general';
import { Prompt, useHistory, useParams } from 'react-router';
import { editOffer, getOfferInfo } from 'domains/offer/model/actions';
import { useOfferInfo } from 'domains/offer/model/selectors';
import Loader from 'components/UI/Loader';
import { fetchAllClients } from 'domains/clients/reducer';
import { useValidateOffer } from 'domains/offer/model/hooks/useValidateOffer';
import { useClientsAllInfo } from 'domains/clients/model/selectors';
import deepEqual from 'deep-equal';
import { objectOptimizationWithFormik } from 'utils/objectOptimizationWithFormik/objectOptimizationWithFormik';
import css from '../styles.module.scss';
import MainSettings from '../components/MainSettings';
import Links from '../components/Links';
import UploadImg from '../components/UploadImg';
import SaveChangeModal from '../components/SaveChangeModal';

const offerShowCreoCountFields = ['pixel', 'adomain', 'link'];

const EditOffer: FC = () => {
  const { t, i18n } = useTranslation();
  const [openSaveModal, setOpenSaveModal] = useState(false);

  const [baseContent, setBaseContent] = useState<boolean>(false);
  const [savingProcess, setSavingProcess] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [showCreoCount, setShowCreoCount] = useState(false);

  const dispatch = useDispatchApp();

  const { id } = useParams<TID>();

  const { data: offerData, isLoading } = useOfferInfo();

  const [creoCount, setCreoCount] = useState<number>(0);

  useEffect(() => {
    dispatch(getOfferInfo({ id: +id }));
  }, []);

  const { data: clientsList } = useClientsAllInfo();

  useEffect(() => {
    if (clientsList) return;
    dispatch(fetchAllClients({}));
  }, []);

  const initialValues: TFormikProps = {
    title: offerData?.title || '',
    clients:
      offerData?.clients.map((client) => ({
        label: client.title,
        value: client.xxhash,
      })) || [],
    status: offerData?.status || '',
    link: offerData?.link || '',
    adomain: offerData?.adomain || '',
    pixel: offerData?.pixel || '',
    icon: offerData?.icon || '',
  };

  const { validate } = useValidateOffer();

  const formik = useFormik<TFormikProps>({
    enableReinitialize: true,
    initialValues,
    validate,
    onSubmit: (values) => {
      const paramsData = objectOptimizationWithFormik(
        {
          ...initialValues,
          clients: initialValues.clients.map((client) => client.value),
        },
        {
          ...values,
          clients: values.clients.map((client) => client.value),
        },
      );

      if (!paramsData) {
        setBaseContent(false);
        return
      }

      setBaseContent(false);
      setSavingProcess(true);

      const params: TOfferEditAction = {
        mode: 'item',
        id: +id,
        data: paramsData,
        callbacks: {
          onSuccess: () => setSuccess(true),
          onError: (str) => setError(str),
          setCreoCount: (count) => setCreoCount(count),
        },
      };

      for (let i = 0; i < offerShowCreoCountFields.length; i += 1) {
        if (params.data[offerShowCreoCountFields[i]] === undefined) {
          if(!showCreoCount) continue
          setShowCreoCount(false)
          continue
        };
        setShowCreoCount(true);
        break;
      }

      dispatch(editOffer(params));
    },
  });

  const uploadHandler = (url: string | null) => {
    formik.setFieldValue('icon', url || '');
  };

  const history = useHistory();

  const goBack = () => {
    history.push('/main/offers');
  };

  const links = useMemo(
    () => [
      {
        title: t(`offers_page.offers`),
        url: `/main/offers`,
        type: 'home',
      },
      {
        title: offerData?.title || '',
        url: `заглушка`,
        type: 'end',
      },
    ],
    [i18n.language, offerData?.title],
  );

  return (
    <>
      <Helmet>
        <title>{t(`offers_page.change_offer`)}</title>
      </Helmet>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={css.header}>
            <div className="container">
              <Breadcrumbs type="Custom" linksCustom={links} />
              <div className={css.status}>
                <h1 className={css.title} title={offerData?.title}>
                  {offerData?.title}
                </h1>
              </div>
            </div>
          </div>
          <div className="container">
            <div className={css.container}>
              <CardList>
                <FormikProvider value={formik}>
                  <Form>
                    <MainSettings formik={formik} />
                    <Links formik={formik} />
                    <div className={css.actions}>
                      <Button
                        title={t('campaigns_page.create_campaign.cancel_btn')}
                        transparent
                        height={40}
                        buttonAttributes={{
                          type: 'button',
                          onClick: () => goBack(),
                        }}
                      />
                      <Button
                        title={t(`form.common.saveChanges`)}
                        height={40}
                        buttonAttributes={{
                          disabled: Boolean(
                            (formik.touched.title && formik.errors.title) ||
                              (formik.touched.clients &&
                                formik.errors.clients) ||
                              (formik.touched.link && formik.errors.link) ||
                              (formik.touched.adomain &&
                                formik.errors.adomain) ||
                              (formik.touched.pixel && formik.errors.pixel),
                          ),
                          type: 'button',
                          onClick: () => {
                            const touched = {};
                            Object.keys(formik.values).forEach((field) => {
                              touched[field] = true;
                            });
                            formik.setTouched(touched);

                            formik.validateForm().then(() => {
                              if (!formik.isValid) return;
                              setBaseContent(true);
                              setOpenSaveModal(true);
                            });
                          },
                        }}
                      />
                    </div>
                  </Form>
                </FormikProvider>
              </CardList>
              <CardList>
                <div>
                  <UploadImg
                    uploadHandler={uploadHandler}
                    imgData={offerData?.icon || ''}
                  />
                </div>
              </CardList>
            </div>
          </div>
        </>
      )}
      {openSaveModal && (
        <SaveChangeModal
          onClose={() => {
            setOpenSaveModal(false);
            setError('');
            setSuccess(false);
            setSavingProcess(false);
          }}
          baseContent={baseContent}
          goBack={goBack}
          error={error}
          savingProcess={savingProcess}
          success={success}
          showCreoCount={showCreoCount}
          title={t(`offers_page.saving_chanes`)}
          innerText={t(`offers_page.save_chanes`)}
          actions={
            <>
              <Button
                title={t(`cancel_btn`)}
                height={40}
                transparent
                buttonAttributes={{
                  onClick: () => setOpenSaveModal(false),
                }}
              />
              <Button
                title={t(`offers_page.confirm`)}
                height={40}
                buttonAttributes={{
                  onClick: () => {
                    formik.handleSubmit();
                  },
                }}
              />
            </>
          }
          infoBlockText={
            <p>
              <Trans
                i18nKey="offers_page.links_will_chaned"
                values={{ creoCount, offer: offerData?.title }}
                components={{ bold: <strong /> }}
              />
            </p>
          }
          creoCount={creoCount}
        />
      )}
      <Prompt
        when={!deepEqual(formik.values, initialValues)}
        message={t('just_words.exit_page_currect')}
      />
    </>
  );
};

export default EditOffer;
