import { genFetchedData } from 'redux/fetchedData';
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { ParamDeleteItem, ParamGetList, TBlDomainsState, TGetListData } from './types';

export const initialState: TBlDomainsState = {
  blDomains: genFetchedData<TGetListData>(null),
};

const BlDomainsSlice = createSlice({
  name: 'BlDomains',
  initialState,
  reducers: {
    setBlDomains(state, action: PayloadAction<TBlDomainsState['blDomains']>) {
      state.blDomains = action.payload;
    },
    setDefaultVal(state) {
      state.blDomains = genFetchedData<TGetListData>(null);
    }
  },
});

export const fetchBlDomains = createAction<ParamGetList>(
  'BlDomains/fetchBlDomains',
);

export const fetchChangeStatusFields = createAction<ParamDeleteItem>(
  'BlDomains/fetchChangeStatusFields',
);

export const { setBlDomains, setDefaultVal } = BlDomainsSlice.actions;
export const blDomainsReducer = BlDomainsSlice.reducer;
