import React, { useCallback, useEffect, useRef, useState } from 'react';
import useDropDown from 'hooks/useDropDown';
import cn from 'classnames';
import { FiMoreHorizontal } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import Portal from 'components/Portal';
import css from './styles.module.scss';

interface Props {
  disabled?: boolean;
  className?: string;
  onRemove: null | (() => void);
  limitsClickHandler?:
    | null
    | ((currentKey: string | number, currentTitle: string) => void);
  onChange?: () => void;
  currentItemKey: string | number;
  currentItemTitle: string;
  /*   scrollCalc: number; */
  setSomeIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ItemActions: React.FC<Props> = ({
  disabled,
  className,
  onRemove,
  limitsClickHandler,
  onChange,
  currentItemKey,
  currentItemTitle,
  setSomeIsOpen,
}) => {
  const dropdownMenu = useRef<HTMLDivElement | null>(null);
  const { toggle, isOpened, toggleOff } = useDropDown(dropdownMenu);

  const { t } = useTranslation();

  const dropdownMenuList = useRef<HTMLDivElement | null>(null);
  const bodyRect = document.body.getBoundingClientRect();
  const positionListLeft = dropdownMenu.current?.getBoundingClientRect().right;
  const offsetLeft = positionListLeft ? positionListLeft - bodyRect.left : 0;
  const [offsetTop, setOffsetTop] = useState<number>(0);
  const [correctionPosition, setcCorrectionPosition] = useState<number>(0);

  const calcOffsetTop = useCallback(() => {
    const positionListTop =
      dropdownMenu.current?.getBoundingClientRect().bottom;
    setOffsetTop(positionListTop || 0);
  }, []);

  useEffect(() => {
    setcCorrectionPosition(dropdownMenuList.current?.offsetWidth || 0);
  }, [isOpened]);

  useEffect(() => {
    document.addEventListener('scroll', toggleOff);

    return () => document.removeEventListener('scroll', toggleOff);
  }, []);

  return (
    <div
      className={cn(css.options, className, {
        isToggle: toggle,
        isDisabled: disabled,
      })}
      ref={dropdownMenu}
    >
      {isOpened && (
        <Portal className="optionList">
          <div
            className={css.list}
            ref={dropdownMenuList}
            style={{
              left: `${offsetLeft - correctionPosition}px`,
              top: `${offsetTop}px`,
            }}
          >
            {typeof limitsClickHandler === 'function' && (
              <div
                className={css.item}
                onClick={() => {
                  limitsClickHandler(currentItemKey, currentItemTitle);
                  toggleOff();
                }}
              >
                {t('targetings.limits.set_limits')}
              </div>
            )}
            {onRemove && (
              <div
                className={css.item}
                onClick={() => {
                  onRemove();
                  toggleOff();
                }}
              >
                {t('delete_btn')}
              </div>
            )}
            {typeof onChange === 'function' && (
              <div
                className={cn(css.item)}
                onClick={() => {
                  onChange();
                  toggleOff();
                }}
              >
                {t('change_btn')}
              </div>
            )}
          </div>
        </Portal>
      )}

      <div
        className={cn(css.active, 'options_active')}
        onClick={
          isOpened
            ? toggleOff
            : (e) => {
                toggle(e);
                if (setSomeIsOpen) {
                  setSomeIsOpen(true);
                }
                calcOffsetTop();
              }
        }
      >
        <FiMoreHorizontal size={20} color="#828282" />
      </div>
    </div>
  );
};

export default ItemActions;
