import Portal from 'components/Portal';
import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';
import { useIconsModal } from './hooks/useIconsModal';

interface IIconsModalProps {
  imgRef: React.MutableRefObject<HTMLDivElement | null>;
  closeModal: () => void;
  open: boolean;
  xxhash: string;
}

const IconsModal: FC<IIconsModalProps> = ({
  imgRef,
  closeModal,
  open,
  xxhash,
}) => {
  const { t } = useTranslation();
  const hintRef = useRef<HTMLDivElement | null>(null);

  const {
    positionRight,
    positionTop,
    campaignsIcons,
    onClickIcons,
    lastIcons,
    onClearIcon,
  } = useIconsModal({
    hintRef,
    imgRef,
    closeModal,
    open,
    xxhash,
  });

  return (
    <Portal className="TooltipPoratal">
      <div
        ref={hintRef}
        className={css.icons}
        style={{
          left: `${positionRight + 10}px`,
          top: `${positionTop}px`,
        }}
      >
        <div className={css.icons_header}>
          <div className={css.icons_title}>{t(`creative.push_text.t1`)}</div>
          <div className={css.icons_btn} onClick={onClearIcon}>
            {t(`clean_btn`)}
          </div>
        </div>
        <div className={css.icons_body}>
          <div className={css.icons_label}>{t(`campaigns_page.popular`)}</div>
          <div className={css.icons_last}>
            {lastIcons.map((icon) => (
              <button key={icon} onClick={onClickIcons(icon)} type="button">
                <img src={icon} alt="" />
              </button>
            ))}
          </div>
          <div className={css.icons_label}>{t(`campaigns_page.icons`)}</div>
          <div className={css.icons_icons}>
            {campaignsIcons.map((icon) => (
              <button key={icon} onClick={onClickIcons(icon)} type="button">
                <img src={icon} alt="" />
              </button>
            ))}
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default IconsModal;
