import {
  RCampaignReport,
  RCampaignReportIntegration,
  TReportData,
} from '../../domains/campaign/types';

export const statisticsReportFormatter = <T extends RCampaignReport>({
  items,
  total,
}: T): TReportData => {
  if (!Object.keys(items).length && !Object.keys(total).length) {
    return {
      total: {},
      statistics: [],
      isDate: false,
    };
  }
  const keysDate = Object.keys(items).sort((prev, next) =>
    prev > next ? 1 : -1,
  );
  let statistics: TReportData['statistics'] = [];
  keysDate.forEach((date) => {
    if (items[date].vartica_id) {
      // eslint-disable-next-line no-param-reassign
      delete items[date].vartica_id;
    }
    statistics = [...statistics, { date, ...items[date] }];
  });

  const keysDelete = ['date', 'vartica_id'] as const;

  keysDelete.forEach((key) => {
    if (key in total) {
      // eslint-disable-next-line no-param-reassign
      delete total[key];
    }
  });
  return { total: { ...total }, statistics, isDate: !!statistics.length };
};

export const statisticsReportFormatterGeo = (
  { items, total, other }: RCampaignReport,
  /* objTitle: Record<string, string>, */
): TReportData => {
  const keys = Object.keys(items);

  let statistics: TReportData['statistics'] = [];

  keys.forEach((key) => {
    if (items[key].vartica_id) {
      // eslint-disable-next-line no-param-reassign
      delete items[key].vartica_id;
    }
    statistics = [
      ...statistics,
      { main_field: /* objTitle[key] ?? */ key, ...items[key] },
    ];
  });
  if ('vartica_id' in total) {
    // eslint-disable-next-line no-param-reassign
    delete total.vartica_id;
  }
  if (other && 'vartica_id' in other) {
    // eslint-disable-next-line no-param-reassign
    delete other.vartica_id;
  }

  return {
    statistics,
    other: { ...other },
    isDate: !!statistics.length,
    total: { ...total },
  };
};

export const statisticsReportFormatterIntegration = <
  T extends RCampaignReportIntegration,
>({
  utm,
  date,
  total,
}: T): TReportData => {
  const removeKey = ['date'] as const;
  removeKey.forEach((key) => {
    if (key in total) {
      // eslint-disable-next-line no-param-reassign
      delete total[key];
    }
  });
  utm.forEach((item) => {
    removeKey.forEach((key) => {
      // eslint-disable-next-line no-param-reassign
      delete item[key];
    });
  });

  return {
    total,
    statistics: [],
    statisticsIntegration: {
      date,
      utm,
    },
    isDate: !!date.length || !!utm.length || false,
  };
};

export const statisticsReportFormatterShowNum = ({
  items,
  total,
  other,
}: RCampaignReport): TReportData => {
  const keys = Object.keys(items);
  let statistics: TReportData['statistics'] = [];

  keys.forEach((key) => {
    if (items[key].vartica_id) {
      // eslint-disable-next-line no-param-reassign
      delete items[key].vartica_id;
    }
    statistics = [...statistics, { main_field: key, ...items[key] }];
  });
  if ('vartica_id' in total) {
    // eslint-disable-next-line no-param-reassign
    delete total.vartica_id;
  }
  if (other && 'vartica_id' in other) {
    // eslint-disable-next-line no-param-reassign
    delete other.vartica_id;
  }

  return {
    statistics,
    other: { ...other },
    isDate: !!statistics.length,
    total: { ...total },
  };
};
