import { FormikContextType, FormikProps, useFormik } from 'formik';
import { FormikErrors } from 'formik/dist/types';
import { useEffect, useState } from 'react';
import { forGotChangeRequest } from 'domains/user/reduser';
import { useDispatchApp } from 'redux/rootSelectors';
import { useTranslation } from 'react-i18next';
import { getError } from '../../user/helpers';
import { useAuthResponseData } from '../../user/model/selectors';

type ChangeFields = 'new_password' | 'confirm';

type TFormChangePassword = Record<ChangeFields, string>;

type RUseForgotPasswordDetail = FormikProps<TFormChangePassword> & {
  formikContext: FormikContextType<TFormChangePassword>;
  formFields: ChangeFields[];
  error: string;
  isLoading: boolean;
  openPass: {
    new_password: boolean;
    confirm: boolean;
  };
  handleToggleOpenPass: (target: string) => void;
};

export const useChangePasswordDetail = (): RUseForgotPasswordDetail => {
  const dispatch = useDispatchApp();
  const { t } = useTranslation();

  const [error, setError] = useState<string>('');
  const [openPass, setOpenPass] = useState({
    new_password: false,
    confirm: false,
  });

  const { error: errorResponse, isLoading } = useAuthResponseData();

  useEffect(
    () =>
      function () {
        localStorage.removeItem('$user_update');
      },
    [],
  );
  useEffect(() => {
    if (errorResponse.isError) {
      setError(() => getError(errorResponse));
    }
  }, [errorResponse]);

  const formFields: ChangeFields[] = ['new_password', 'confirm'];

  const validate = (values: TFormChangePassword) => {
    const errors: FormikErrors<TFormChangePassword> = {};

    if (values.new_password.length < 6) {
      errors.new_password = t(`form.common.password_rules`);
    }

    if (values.confirm !== values.new_password) {
      errors.confirm = t(`form.common.passMiss`);
    }

    return errors;
  };

  const handleToggleOpenPass = (target: string) => {
    setOpenPass({
      ...openPass,
      [target]: !openPass[target]
    })
  };

  const formik = useFormik<TFormChangePassword>({
    initialValues: {
      confirm: '',
      new_password: '',
    },
    validate,
    onSubmit: ({ new_password }) => {
      const userUpdate = localStorage.getItem('$user_update') || '';
      const params = new URLSearchParams(userUpdate);
      const hash = params.get('hash');
      if (hash) {
        dispatch(forGotChangeRequest({ password: new_password, hash }));
      }
    },
  });
  return {
    isLoading,
    error,
    formFields,
    openPass,
    handleToggleOpenPass,
    formikContext: formik,
    ...formik,
  };
};
