import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  MutableRefObject,
} from 'react';
import ToastError from 'components/UI/Toast/ToastError';
import { toast } from 'react-toastify';
import { cloneCreative } from '../../api';
import { ParamRequestClone, RCreativeClone } from '../../types';

type ErrorCopy = {
  code: number;
  message?: string;
  data?: {
    description: string;
    fields: string[];
    location: string;
    mnemonic: string;
  };
};

type TFUseClone = () => {
  onRequestForcedClone: (params: {
    fromId: string[];
    toIds: string[];
  }) => Promise<RCreativeClone[] | undefined>;
  onRequestClone: (
    v: ParamRequestClone,
  ) => Promise<(ParamRequestClone & { result: RCreativeClone[] }) | undefined>;
  progress: number;
  copying: boolean;
  setCopying: (v: boolean) => void;
  warning: boolean;
  setWarning: (v: boolean) => void;
  warnList: RCreativeClone[];
  warningHide: boolean;
  error: ErrorCopy;
  link: any;
};

const useClone: TFUseClone = () => {
  const interval: MutableRefObject<null | any> = useRef(null);
  const [copying, setCopying] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState<boolean>(false);
  const [warningHide, setWarningHide] = useState<boolean>(true);
  const [error, setError] = useState<ErrorCopy>({ code: 0 });
  const [progress, setPropgress] = useState<number>(0);
  const [warnList, setWarnList] = useState<RCreativeClone[]>([]);
  const [linkCampaign, setLinkCampaign] = useState<any>();

  const setTimer = useCallback(() => setPropgress(progress + 1), [progress]);

  useEffect(() => {
    if (loading && progress !== 80) {
      interval.current = setInterval(setTimer, 100);
    } else {
      // eslint-disable-next-line no-unused-expressions
      interval.current && clearInterval(interval.current);
    }

    return () => interval.current && clearInterval(interval.current);
  }, [loading, progress]);

  const showResult = () => {
    setLoading(false);
    setPropgress(100);

    return new Promise<void>((res) =>
      setTimeout(() => {
        setCopying(false);
        setPropgress(0);
        res();
      }, 400),
    );
  };

  // eslint-disable-next-line consistent-return
  const errorFilterCloneCreativeCampaign = (param: ErrorCopy) => {
    if (param.code !== 43) {
      return toast(<ToastError error={param} />, {
        type: 'error',
        delay: 500,
        autoClose: 2000,
      });
    }
  };

  const onRequestClone: ReturnType<TFUseClone>['onRequestClone'] = async (
    params,
  ) => {
    try {
      setLoading(true);
      setCopying(true);

      const result = await cloneCreative(params);

      const filteredResult = result.filter((e) => !e.status);

      if (filteredResult.length > 0) {
        setLinkCampaign({ ...params });
        await showResult();
        setWarning(true);
        setWarnList(filteredResult);
        return;
      }

      await showResult();

      // eslint-disable-next-line consistent-return
      return {
        result,
        ...params,
      };
    } catch (err) {
      const data = await showResult();
      console.log('err', err, data);
      setWarning(true);
      setError(() => err);
      setWarningHide(false);
      errorFilterCloneCreativeCampaign(err);
    }
  };

  const onRequestForcedClone: ReturnType<TFUseClone>['onRequestForcedClone'] =
    async (params: {
      fromId: string[];
      toIds: string[];
      // eslint-disable-next-line consistent-return
    }) => {
      try {
        setLoading(true);
        const result = await cloneCreative({
          creative_xxhash_list: params.fromId,
          campaign_xxhash_list: params.toIds,
          forced: true,
        });

        await showResult();
        return result;
      } catch (err) {
        await showResult();
        console.log('err', err);
      }
    };

  return {
    onRequestForcedClone,
    onRequestClone,
    progress,
    copying,
    setCopying,
    warning,
    setWarning,
    warnList,
    warningHide,
    error,
    link: linkCampaign,
  };
};

export default useClone;
