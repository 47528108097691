import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from '../../../redux/fetchedData';
import { TOfferItem } from './types';

export type offerState = {
    offerInfo: FetchedData<TOfferItem>;
};

export const initialState: offerState = {
    offerInfo: genFetchedData<TOfferItem>(null),
};

const offerSlice = createSlice({
  name: 'Offer',
  initialState,
  reducers: {
    setOfferInfo(
      state,
      action: PayloadAction<offerState['offerInfo']>,
    ) {
      state.offerInfo = action.payload;
    },
  },
});

export const {
  setOfferInfo,
} = offerSlice.actions;
export const offerReducer = offerSlice.reducer;