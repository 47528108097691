import React, { FC, useCallback, useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import EditCard from 'components/UI/EditCard';
import { isFieldInObject } from 'utils/isFieldInObject';
import cn from 'classnames';
import { useUserTheme } from 'domains/user/model/selectors';
import i18n from '../../../../../../i18n';
import css from '../styles.module.scss';
import Help from '../../../../../../components/Help';
import PriceInput from '../../../../../../components/UI/PriceInput';
import { hasValuesLimits } from '../../../../model/utils/utilsForm';
import Checkbox from '../../../../../../components/UI/Checkbox/checkbox';
import { CampaignGeneralComponentProps } from '../../../../types';
import { useOuterElementClick } from '../../../../../../hooks/useOuterElementClick';
import { useEditCard } from './model/useEditCard';
import { hasErrorsLimits } from '../../../../model/utils/hasErrorsLimits';

type LimitsProps = Pick<
  CampaignGeneralComponentProps,
  'formik' | 'currency' | 'savedField'
> & {
  isRawState: boolean;
  showButton?: boolean;
  resetForm?: () => void;
};

const Limits: FC<LimitsProps> = ({
  formik,
  currency,
  isRawState,
  showButton = false,
  resetForm,
  savedField,
}) => {
  const [edit, setEdit] = useState(!showButton);
  const closeBlock = useCallback(() => {
    if (resetForm) {
      resetForm();
    }
    setEdit(false);
  }, [resetForm, edit]);
  const { wrapperRef } = useOuterElementClick({
    isShow: showButton && edit,
    closeModal: closeBlock,
    hideDefaultsEvents: true,
  });

  const { showHint, closeHint } = useEditCard(formik);
  const { getValueFromSettingForKey } = useUserTheme();
  
  return (
    <EditCard
      wrapperRef={wrapperRef}
      hideButton={!showButton}
      noHover
      style={{ padding: '28px 24px' }}
      className={css.card}
      edited={edit}
      showHint={showHint}
      closeHint={closeHint}
      hasErrors={hasErrorsLimits(formik.errors)}
      onSave={() => {
        if (showButton) {
          formik.handleSubmit();
          setEdit(!edit);
        }
      }}
      setEdit={() => setEdit(!edit)}
      onResetForm={() => closeBlock()}
      textHint={
        <>
          <strong>
            {i18n.t(
              'campaigns_page.campaign_settings.hint_for_card.limit_part1',
            )}
          </strong>
          {i18n.t('campaigns_page.campaign_settings.hint_for_card.limit_part2')}
        </>
      }
    >
      <h3>
        {i18n.t('campaigns_page.campaign_settings.Limits')}
        {getValueFromSettingForKey('HideHelp') && (
          <Help
            width={150}
            title={i18n.t('campaigns_page.campaign_settings.Limits')}
            helpattributes={{
              link: i18n.t(
                'campaigns_page.campaign_settings.campaign_prompt.limits.link',
              ),
              sizeIcon: 18,
            }}
          />
        )}
      </h3>

      <div className={css.form}>
        <div className={css.row}>
          <div
            className={cn(css.col, css.fixWidth, {
              error: formik.errors.limits?.click,
            })}
          >
            <span className={css.title}>
              {i18n.t('campaigns_page.campaign_settings.Clicks')}
              {hasValuesLimits(formik.values.limits.click) &&
                !formik.errors.limits?.click && <span className="dot" />}
              {formik.errors.limits?.click && (
                <FiAlertCircle size={16} className="alert-icon" />
              )}
            </span>
          </div>
          <div className={css.col}>
            <PriceInput
              theme="horizontal"
              error={formik.errors.limits?.click?.hour}
              value={formik.values.limits.click.hour}
              label={i18n.t('campaigns_page.campaign_settings.Hour')}
              placeholder={i18n.t(
                'campaigns_page.campaign_settings.Please_enter',
              )}
              decimalScale={0}
              onValueChange={({ floatValue }) => {
                formik.setFieldValue('limits.click.hour', floatValue);
              }}
              hasZero={false}
              notField={!edit}
              isSaved={isFieldInObject(savedField, 'limits.click.hour')}
            />
          </div>
          <div className={css.col}>
            <PriceInput
              theme="horizontal"
              error={formik.errors.limits?.click?.day}
              value={formik.values.limits.click.day}
              label={i18n.t('campaigns_page.campaign_settings.Day')}
              placeholder={i18n.t(
                'campaigns_page.campaign_settings.Please_enter',
              )}
              decimalScale={0}
              onValueChange={({ floatValue }) => {
                formik.setFieldValue('limits.click.day', floatValue);
              }}
              notField={!edit}
              isSaved={isFieldInObject(savedField, 'limits.click.day')}
            />
          </div>
          <div className={css.col}>
            <PriceInput
              theme="horizontal"
              error={formik.errors.limits?.click?.total}
              value={formik.values.limits.click.total}
              label={i18n.t('campaigns_page.campaign_settings.Total')}
              placeholder={i18n.t(
                'campaigns_page.campaign_settings.Please_enter',
              )}
              decimalScale={0}
              onValueChange={({ floatValue }) => {
                formik.setFieldValue('limits.click.total', floatValue);
              }}
              notField={!edit}
              isSaved={isFieldInObject(savedField, 'limits.click.total')}
            />
          </div>
        </div>
        <div className={css.row}>
          <div
            className={cn(css.col, css.fixWidth, {
              error: formik.errors.limits?.show,
            })}
          >
            <span className={css.title}>
              {i18n.t('campaigns_page.campaign_settings.Impressions')}
              {hasValuesLimits(formik.values.limits.show) &&
                !formik.errors.limits?.show && <span className="dot" />}
              {formik.errors.limits?.show && (
                <FiAlertCircle size={16} className="alert-icon" />
              )}
            </span>
          </div>
          <div className={css.col}>
            <PriceInput
              theme="horizontal"
              error={formik.errors.limits?.show?.hour}
              value={formik.values.limits.show.hour}
              label={i18n.t('campaigns_page.campaign_settings.Hour')}
              placeholder={i18n.t(
                'campaigns_page.campaign_settings.Please_enter',
              )}
              decimalScale={0}
              onValueChange={({ floatValue }) => {
                formik.setFieldValue('limits.show.hour', floatValue);
              }}
              notField={!edit}
              isSaved={isFieldInObject(savedField, 'limits.show.hour')}
            />
          </div>
          <div className={css.col}>
            <PriceInput
              theme="horizontal"
              error={formik.errors.limits?.show?.day}
              value={formik.values.limits.show.day}
              label={i18n.t('campaigns_page.campaign_settings.Day')}
              placeholder={i18n.t(
                'campaigns_page.campaign_settings.Please_enter',
              )}
              decimalScale={0}
              onValueChange={({ floatValue }) => {
                formik.setFieldValue('limits.show.day', floatValue);
              }}
              notField={!edit}
              isSaved={isFieldInObject(savedField, 'limits.show.day')}
            />
          </div>
          <div className={css.col}>
            <PriceInput
              theme="horizontal"
              error={formik.errors.limits?.show?.total}
              value={formik.values.limits.show.total}
              label={i18n.t('campaigns_page.campaign_settings.Total')}
              placeholder={i18n.t(
                'campaigns_page.campaign_settings.Please_enter',
              )}
              decimalScale={0}
              onValueChange={({ floatValue }) => {
                formik.setFieldValue('limits.show.total', floatValue);
              }}
              notField={!edit}
              isSaved={isFieldInObject(savedField, 'limits.show.total')}
            />
          </div>
        </div>
        <div className={css.row}>
          <div
            className={cn(css.col, css.fixWidth, {
              error: formik.errors.limits?.show_per_user,
            })}
          >
            <span className={css.title}>
              {i18n.t('campaigns_page.campaign_settings.per_user')}
              {hasValuesLimits(formik.values.limits.show_per_user) &&
                !formik.errors.limits?.show_per_user && (
                  <span className="dot" />
                )}
              {formik.errors.limits?.show_per_user && (
                <FiAlertCircle size={16} className="alert-icon" />
              )}
            </span>
          </div>
          <div className={css.col}>
            <PriceInput
              theme="horizontal"
              error={formik.errors.limits?.show_per_user?.hour}
              value={formik.values.limits.show_per_user.hour}
              label={i18n.t('campaigns_page.campaign_settings.Hour')}
              placeholder={i18n.t(
                'campaigns_page.campaign_settings.Please_enter',
              )}
              decimalScale={0}
              onValueChange={({ floatValue }) => {
                formik.setFieldValue('limits.show_per_user.hour', floatValue);
              }}
              notField={!edit}
              isSaved={isFieldInObject(savedField, 'limits.show_per_user.hour')}
            />
          </div>
          <div className={css.col}>
            <PriceInput
              theme="horizontal"
              error={formik.errors.limits?.show_per_user?.day}
              value={formik.values.limits.show_per_user.day}
              label={i18n.t('campaigns_page.campaign_settings.Day')}
              placeholder={i18n.t(
                'campaigns_page.campaign_settings.Please_enter',
              )}
              decimalScale={0}
              onValueChange={({ floatValue }) => {
                formik.setFieldValue('limits.show_per_user.day', floatValue);
              }}
              notField={!edit}
              isSaved={isFieldInObject(savedField, 'limits.show_per_user.day')}
            />
          </div>
          <div className={css.col}>
            <PriceInput
              theme="horizontal"
              error={formik.errors.limits?.show_per_user?.total}
              value={formik.values.limits.show_per_user.total}
              label={i18n.t('campaigns_page.campaign_settings.Total')}
              placeholder={i18n.t(
                'campaigns_page.campaign_settings.Please_enter',
              )}
              decimalScale={0}
              onValueChange={({ floatValue }) => {
                formik.setFieldValue('limits.show_per_user.total', floatValue);
              }}
              notField={!edit}
              isSaved={isFieldInObject(
                savedField,
                'limits.show_per_user.total',
              )}
            />
          </div>
        </div>
        <div className={css.row}>
          <div className={css.additionl}>
            <div className={css.additionl__label}>
              {i18n.t(
                'campaigns_page.campaign_settings.container_block.additionally',
              )}
            </div>
            <Checkbox
              label={i18n.t(
                'campaigns_page.campaign_settings.Limit_display_Adriver',
              )}
              disabled={!edit}
              inputAttributes={{
                name: 'limits.use_adriver_frequency_cap',
                type: 'checkbox',
                checked: !!formik.values.limits.use_adriver_frequency_cap,
                onChange: () => {
                  formik.setFieldValue(
                    'limits.use_adriver_frequency_cap',
                    !formik.values.limits.use_adriver_frequency_cap,
                  );
                },
              }}
            />
          </div>
        </div>
        <div className={css.row}>
          <div
            className={cn(css.col, css.fixWidth, {
              error: formik.errors.limits?.budget,
            })}
          >
            <span className={css.title}>
              {i18n.t('campaigns_page.campaign_settings.Budget')}
              {hasValuesLimits({
                hour: formik.values.limits.budget.hour,
                day: formik.values.limits.budget.day,
                total: formik.values.limits.budget.total,
              }) &&
                !formik.errors.limits?.budget && <span className="dot" />}
              {formik.errors.limits?.budget && (
                <FiAlertCircle size={16} className="alert-icon" />
              )}
            </span>
          </div>
          <div className={css.col}>
            <PriceInput
              theme="horizontal"
              error={formik.errors.limits?.budget?.hour}
              value={formik.values.limits.budget.hour}
              label={`${i18n.t(
                `campaigns_page.campaign_settings.Hour`,
              )}, ${currency}`}
              placeholder={i18n.t(
                'campaigns_page.campaign_settings.Please_enter',
              )}
              decimalScale={0}
              onValueChange={({ floatValue }) => {
                formik.setFieldValue('limits.budget.hour', floatValue);
              }}
              notField={!edit}
              isSaved={isFieldInObject(savedField, 'limits.budget.hour')}
            />
          </div>
          <div className={css.col}>
            <PriceInput
              theme="horizontal"
              error={formik.errors.limits?.budget?.day}
              value={formik.values.limits.budget.day}
              label={`${i18n.t(
                `campaigns_page.campaign_settings.Day`,
              )}, ${currency}`}
              placeholder={i18n.t(
                'campaigns_page.campaign_settings.Please_enter',
              )}
              decimalScale={0}
              onValueChange={({ floatValue }) => {
                formik.setFieldValue('limits.budget.day', floatValue);
              }}
              notField={!edit}
              isSaved={isFieldInObject(savedField, 'limits.budget.day')}
            />
          </div>
          <div className={css.col}>
            <PriceInput
              theme="horizontal"
              error={formik.errors.limits?.budget?.total}
              value={formik.values.limits.budget.total}
              label={`${i18n.t(
                'campaigns_page.campaign_settings.Total',
              )}, ${currency}`}
              placeholder={i18n.t(
                'campaigns_page.campaign_settings.Please_enter',
              )}
              decimalScale={0}
              onValueChange={({ floatValue }) => {
                formik.setFieldValue('limits.budget.total', floatValue);
              }}
              notField={!edit}
              isSaved={isFieldInObject(savedField, 'limits.budget.total')}
            />
          </div>
        </div>
        {isRawState && !!formik.values.limits?.budget_raw && (
          <div className={css.row}>
            <div
              className={cn(css.col, css.fixWidth, {
                error: formik.errors.limits?.budget_raw,
              })}
            >
              <span className={css.title}>
                {i18n.t('campaigns_page.campaign_settings.Budget_RAW')}
                {hasValuesLimits({
                  hour: formik.values.limits?.budget_raw?.hour,
                  day: formik.values.limits?.budget_raw?.day,
                  total: formik.values.limits?.budget_raw?.total,
                }) &&
                  !formik.errors.limits?.budget_raw && <span className="dot" />}
                {formik.errors.limits?.budget_raw && (
                  <FiAlertCircle size={16} className="alert-icon" />
                )}
              </span>
            </div>
            <div className={css.col}>
              <PriceInput
                theme="horizontal"
                error={formik.errors.limits?.budget_raw?.hour}
                value={formik.values.limits.budget_raw.hour}
                label={`${i18n.t(
                  `campaigns_page.campaign_settings.Hour`,
                )}, ${currency}`}
                placeholder={i18n.t(
                  'campaigns_page.campaign_settings.Please_enter',
                )}
                decimalScale={0}
                onValueChange={({ floatValue }) => {
                  formik.setFieldValue('limits.budget_raw.hour', floatValue);
                }}
                notField={!edit}
                isSaved={isFieldInObject(savedField, 'limits.budget_raw.hour')}
              />
            </div>
            <div className={css.col}>
              <PriceInput
                theme="horizontal"
                error={formik.errors.limits?.budget_raw?.day}
                value={formik.values.limits.budget_raw.day}
                label={`${i18n.t(
                  `campaigns_page.campaign_settings.Day`,
                )}, ${currency}`}
                placeholder={i18n.t(
                  'campaigns_page.campaign_settings.Please_enter',
                )}
                decimalScale={0}
                onValueChange={({ floatValue }) => {
                  formik.setFieldValue('limits.budget_raw.day', floatValue);
                }}
                notField={!edit}
                isSaved={isFieldInObject(savedField, 'limits.budget_raw.day')}
              />
            </div>
            <div className={css.col}>
              <PriceInput
                theme="horizontal"
                error={formik.errors.limits?.budget_raw?.total}
                value={formik.values.limits.budget_raw.total}
                label={`${i18n.t(
                  'campaigns_page.campaign_settings.Total',
                )}, ${currency}`}
                placeholder={i18n.t(
                  'campaigns_page.campaign_settings.Please_enter',
                )}
                decimalScale={0}
                onValueChange={({ floatValue }) => {
                  formik.setFieldValue('limits.budget_raw.total', floatValue);
                }}
                notField={!edit}
                isSaved={isFieldInObject(savedField, 'limits.budget_raw.total')}
              />
            </div>
          </div>
        )}
      </div>
    </EditCard>
  );
};
export default Limits;
