/* eslint-disable no-nested-ternary */
import React, { FC, memo } from 'react';
import { FiX } from 'react-icons/fi';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Chart from 'react-google-charts';
import { TNamingItem, TSeries } from 'domains/campaign/types';
import css from './styles.module.scss';
import './styles.css';
import ChartLegend from './components/ChartLegend';
import { chartColors } from './model/chartColors';

type TFReportChart = {
  onClickChartLegend: (item: string) => () => void;
  dataForChart: false | (string | number)[][];
  series: TSeries;
  namingItem: TNamingItem;
  chartDataLegend: {
    name: string;
    isOff: boolean;
  }[];
  showReportHandler?: () => void;
};

const ReportChart: FC<TFReportChart> = ({
  chartDataLegend,
  dataForChart,
  series,
  namingItem,
  onClickChartLegend,
  showReportHandler,
}) => {
  const { i18n, t } = useTranslation();

  return (
    <div
      className={cn(css.card, 'ReportChart', {
        // _isEmpty: !dataForChart,
      })}
    >
      <div className={css.children}>
        <div className={css.chart}>
          {dataForChart ? (
            <>
              <div className={css.chart_top}>
                <div className={css.chart_legend}>
                  {chartDataLegend.map((item, i) => {
                    const obj = namingItem.get(item.name);
                    const { title, color } =
                      typeof obj !== 'undefined'
                        ? obj
                        : { title: item.name, color: chartColors[i] };

                    return (
                      <ChartLegend
                        key={item.name}
                        onClick={() => onClickChartLegend(item.name)}
                        backgroundColor={color || chartColors[i]}
                        name={title}
                        isOff={item.isOff}
                      />
                    );
                  })}
                </div>
                {showReportHandler && (
                  <div className={css.chart_close}>
                    <FiX size={24} onClick={showReportHandler} />
                  </div>
                )}
              </div>
              <div className={css.chart_wrapper}>
                <Chart
                  chartLanguage={i18n.language ?? 'ru'}
                  chartType="AreaChart"
                  width="100%"
                  height="326px"
                  data={dataForChart}
                  options={{
                    tooltip: { isHtml: true, trigger: 'both' },
                    legend: { position: 'none' },
                    animation: {
                      startup: true,
                      easing: 'linear',
                      duration: 100,
                    },
                    chartArea: {
                      height: '80%',
                      left: 60,
                      right: 30,
                      backgroundColor: {
                        stroke: 'transparent',
                        strokeWidth: 1,
                      },
                    },
                    vAxis: {
                      minValue: 0,
                      format: 'short',
                      gridlines: {
                        color: '#ddd',
                        count: 5,
                        lineStyle: 'dashed',
                      },
                      minorGridlines: {
                        count: 0,
                        color: '#ddd',
                      },
                      baselineColor: '#ddd',
                      textStyle: {
                        color: '#828282',
                        fontName: 'Rubik',
                        fontSize: 12,
                      },
                      viewWindow: {},
                    },
                    hAxis: {
                      slantedText: false,
                      textStyle: {
                        color: '#828282',
                        fontName: 'Rubik',
                        fontSize: 12,
                      },
                    },
                    series,
                    focusTarget: 'category',
                    aggregationTarget: 'none',
                    crosshair: {
                      trigger: 'both',
                      orientation: 'vertical',
                      color: '#025DFF',
                      opacity: 0.7,
                    },
                  }}
                />
              </div>
            </>
          ) : (
            <div>{t(`campaigns_page.chart.no_stats`)}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(ReportChart);
