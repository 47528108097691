import React, { FC, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import Button from 'components/UI/Buttons/buttons';
import LabelField from 'components/UI/LabelField';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import NewSelect from 'components/UI/NewSelect';
import { useDetectBlockScroll } from 'utils/useDetectBlockScroll';
import { useListFilter } from './hook/useListFilter';
import css from './styles.module.scss';
import { ReactComponent as CampFilterSvg } from '../../../../assets/campFilter.svg';
import i18n from '../../../../i18n';

interface IListFilterProps {
  fixed: boolean;
  setType: React.Dispatch<React.SetStateAction<string>>;
  setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IOptions {
  label: string;
  value: string;
}

const ListFilter: FC<IListFilterProps> = ({ fixed, setType, setUpdate }) => {
  const filterFromStorage = localStorage.getItem('filter');
  const parsedFiltersFromStorage: IOptions =
    filterFromStorage &&
    filterFromStorage !== 'undefined' &&
    JSON.parse(filterFromStorage);
  const iconRef = useRef<HTMLDivElement>(null);
  const [val, setVal] = useState<string>('');

  const options: IOptions[] = useMemo(
    () => [
      {
        label: i18n.t(`blackLists.global`),
        value: 'Global',
      },
      {
        label: i18n.t(`blackLists.clients`),
        value: 'Client',
      },
    ],
    [i18n.language],
  );

  const [defaultVal, setDefaultVal] = useState<IOptions | null>(null);
  const [filterCount, setFilterCount] = useState<number>(0);
  const [typeObj, setTypeObj] = useState<IOptions>();
  const [isClearFilter, setIsClearFilter] = useState<boolean>(false);

  const returnPrevFilters = () => {
    setDefaultVal(parsedFiltersFromStorage);
    setVal('');
    setTypeObj(options[0]);
  };

  const {
    wrapperRef,
    isOpenPanel,
    setIsOpenPanel,
    topPosition,
    rightPosition,
    heightPanel,
  } = useListFilter({
    iconRef,
    fixed,
    returnPrevFilters,
  });

  const findObjByValue = (array: IOptions[], v: string) =>
    array.find((obj) => obj.value === v);

  const changeType = (data: string) => {
    if (data !== 'Not_Selected') {
      const obj = findObjByValue(options, data);
      if (obj) {
        setTypeObj(obj);
        setDefaultVal(obj);
      }
      setVal(data);
    } else {
      setTypeObj(options[0]);
      setVal('');
      setDefaultVal(options[0]);
    }
  };

  const countOfFilters = () => {
    let count = 0;
    if (val !== 'Not_Selected') {
      count += 1;
    }
    if (!val || val === '' || val === undefined) {
      count = 0;
    }
    setFilterCount(count);
  };

  const clearFilters = () => {
    setType('');
    setVal('');
    setDefaultVal(null);
    setIsClearFilter(true);
    localStorage.removeItem('filter');
  };

  const applyFilters = () => {
    setType(val);
    setIsOpenPanel(false);
    countOfFilters();
    setUpdate(true);
    if (!isClearFilter) {
      localStorage.setItem('filter', JSON.stringify(typeObj));
      setIsClearFilter(false);
    }
  };

  const { endScrollingBlock } = useDetectBlockScroll(true);

  return (
    <div ref={wrapperRef}>
      <div
        className={cn(css.listFilter, {
          _isShowPanel: isOpenPanel,
          _isHighlightIcon: filterCount > 0,
        })}
        ref={iconRef}
      >
        {filterCount > 0 && <span>{filterCount}</span>}
        <TooltipPoratal
          hint={i18n.t('filter.customize')}
          onClick={() => setIsOpenPanel(!isOpenPanel)}
        >
          <CampFilterSvg />
        </TooltipPoratal>
      </div>
      <div
        className={css.listFilter_panel}
        style={{
          top: `${topPosition + 50}px`,
          right: `${rightPosition}px`,
          maxHeight: `${heightPanel}px`,
        }}
      >
        <div className={css.parametrs}>
          <div className={css.item}>
            <LabelField label={i18n.t(`blackLists.file_type`)} />
            <NewSelect
              selectAttributes={{
                placeholder: i18n.t(`project_page.forms.not_selected`),
                options,
                value: defaultVal,
                closeMenuOnScroll:
                  !endScrollingBlock && endScrollingBlock !== null
                    ? () => true
                    : () => false,
              }}
              onCustomChange={(data) => changeType(data)}
              classname={cn(css.select)}
              viewStyle="Vertical"
            />
          </div>
        </div>
        <div className={css.actions}>
          <Button
            lightgrey
            title={i18n.t(`clean_btn`)}
            height={40}
            buttonAttributes={{
              onClick: clearFilters,
              style: {
                color: '#222222',
              },
            }}
          />
          <Button
            title={i18n.t('apply_btn')}
            height={40}
            buttonAttributes={{
              className: css.apply,
              onClick: applyFilters,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ListFilter;
