import React, { useRef, useEffect, FC, CSSProperties, RefObject } from 'react';
import cn from 'classnames';
import useDropDown from 'hooks/useDropDown';
import css from './styles.module.scss';

interface Props {
  isDisable?: boolean;
  children?: JSX.Element[];
  header: JSX.Element;
  className?: string;
  style?: CSSProperties;
  onSetActive?: (val: boolean, ref: RefObject<HTMLElement>) => void;
}

const Dropdown: FC<Props> = ({
  isDisable = false,
  header,
  children,
  className,
  style,
  onSetActive,
}) => {
  const dropdownMenu = useRef<HTMLDivElement>(null);
  const { toggle, isOpened, toggleOff } = useDropDown(dropdownMenu);

  useEffect(() => {
    if (onSetActive) {
      onSetActive(isOpened, dropdownMenu);
    }
  }, [isOpened]);

  return (
    <div
      className={cn(css.dropdown, className)}
      ref={dropdownMenu}
      style={style}
    >
      <div
        className={cn(css.header, {
          _isOpened: isOpened && !isDisable,
        })}
        onClick={isOpened ? toggleOff : toggle}
      >
        {header}
      </div>

      <div className={cn(css.list, { _isOpened: isOpened && !isDisable })}>
        {children &&
          children.map((item) => (
            <div className={cn(css.item)} key={item.key}>
              {item}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Dropdown;
